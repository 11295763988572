// File: frontend/src/pages/SourceCode/SourceCode.js

import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import axios from 'axios';
import ProjectMindMap from '../../components/SourceCode/ProjectMindMap';
import AICodeAssistant from '../../components/SourceCode/AICodeAssistant';
import PageTitle from '../../components/layout/DashboardLayout/PageTitle';
import styles from './SourceCode.module.css';

const SourceCode = () => {
  const { t } = useLanguage();
  const [stats, setStats] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await axios.get('/api/source-code/stats');
        setStats(response.data);
      } catch (error) {
        console.error('Error fetching stats:', error);
        setError('Failed to fetch project statistics');
      } finally {
        setIsLoading(false);
      }
    };

    fetchStats();
  }, []);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className={styles.container}>
      <PageTitle title={t('source_code')}/>
      <h1>Source Code Management</h1>
      
      {stats && (
        <div className={styles.stats}>
          <h2>Project Statistics</h2>
          <p>Total Files: {stats.totalFiles}</p>
          <p>Total Lines of Code: {stats.totalLines}</p>
          <h3>File Types:</h3>
          <ul>
            {Object.entries(stats.fileTypes).map(([ext, count]) => (
              <li key={ext}>{ext}: {count}</li>
            ))}
          </ul>
        </div>
      )}

      <div className={styles.mindMap}>
        <h2>Project Structure</h2>
        <ProjectMindMap />
      </div>

      <div className={styles.aiAssistant}>
        <h2>AI Code Assistant</h2>
        <AICodeAssistant />
      </div>
    </div>
  );
};

export default SourceCode;