// File: frontend/src/pages/AI1/components/ChatInput/ChatInput.js

// File: frontend/src/pages/AI1/components/ChatInput/ChatInput.js

import React, { useState, useEffect, forwardRef } from 'react';
import styles from './ChatInput.module.css';
import { useUser } from '../../../../contexts/UserContext';
import AIHeader from '../AIHeader/AIHeader';

const ChatInput = forwardRef(({
  onSendMessage,
  selectedTemplate,
  onTemplateClick,
  selectedAgent,
  onAgentClick,
  disabled,
  isMessageSending,
  chatId,
}, ref) => {
  const [message, setMessage] = useState('');
  const { user } = useUser();

  // Helper function to get greeting based on time of day
  const getGreeting = () => {
    const hour = new Date().getHours();
    if (hour < 12) return 'Good morning';
    if (hour < 18) return 'Good afternoon';
    return 'Good evening';
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (message.trim()) {
      onSendMessage(message);
      setMessage('');
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  return (
    <div className={styles.inputContainer}>
      {!chatId && !isMessageSending && (
        <div className={styles.welcomeSection}>
          <AIHeader />
          <div className={styles.greeting}>
            {`${getGreeting()}, ${user?.first_name || 'there'}`}
          </div>
        </div>
      )}
      
      <form onSubmit={handleSubmit} className={styles.form}>
        <textarea
          ref={ref}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="How can AI-1 help you today?"
          disabled={disabled}
          className={styles.input}
          rows={1}
        />
        {message.trim() && (
          <button 
            type="submit" 
            className={styles.sendButton}
            disabled={disabled}
          >
            <img src="/icons/18/misc/arrow-right.svg" alt="Send" />
          </button>
        )}
      </form>

      {!chatId && !isMessageSending && (
        <div className={styles.configText}>
          Using{' '}
          <button 
            onClick={onTemplateClick}
            className={styles.inlineSelector}
          >
            {selectedTemplate.name}
          </button>
          {' '}template with{' '}
          <button 
            onClick={onAgentClick}
            className={styles.inlineSelector}
          >
            {selectedAgent.name}
          </button>
          {' '}agent
        </div>
      )}
    </div>
  );
});

ChatInput.displayName = 'ChatInput';

export default ChatInput;