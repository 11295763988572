// File: frontend/src/pages/Quote/components/ChatInterface/components/ChatInput.js

import React, { useRef, useEffect } from 'react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from '../ChatInterface.module.css';

const ChatInput = ({ 
  inputValue, 
  setInputValue, 
  handleSendMessage, 
  isTyping, 
  isLocked, 
  isLoading 
}) => {
  const { t } = useLanguage();
  const inputRef = useRef(null);
  
  // Focus input when component mounts
  useEffect(() => {
    if (!isLocked && !isLoading) {
      inputRef.current?.focus();
    }
  }, [isLocked, isLoading]);

  return (
    <form onSubmit={handleSendMessage} className={styles.inputForm}>
      <input
        ref={inputRef}
        type="text"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        placeholder={isLocked ? t("Chat is locked") : t("Type your message here...")}
        className={styles.chatInput}
        disabled={isTyping || isLocked || isLoading}
      />
      <button 
        type="submit" 
        className={styles.sendButton}
        disabled={isTyping || !inputValue.trim() || isLocked || isLoading}
      >
        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z" fill="currentColor" />
        </svg>
      </button>
    </form>
  );
};

export default ChatInput;