// src/components/TripDetails/UserFeedback.js
import React from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import styles from './UserFeedback.module.css';

const UserFeedback = ({ feedbackData }) => {
  const { t } = useLanguage();

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      weekday: 'short',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
  };

  const renderFeedbackItem = (label, value, multiValue = false) => (
    <div className={styles.cardStandartContentA}>
      <div className={styles.cardStandartContentA1}>
        <div className={styles.div}>{t[label]}</div>
        <div className={styles.div}>:</div>
      </div>
      {multiValue ? (
        <div className={styles.cardStandartContentAParent}>
          {Array.isArray(value) ? value.map((item, index) => (
            <div key={index} className={styles.cardStandartContentA2}>
              <div className={styles.div}>{item || 'N/A'}</div>
            </div>
          )) : (
            <div className={styles.cardStandartContentA2}>
              <div className={styles.div}>{value || 'N/A'}</div>
            </div>
          )}
        </div>
      ) : (
        <div className={styles.cardStandartContentA2}>
          <div className={styles.div}>{value || 'N/A'}</div>
        </div>
      )}
    </div>
  );

  const [dateTime, time] = (formatDate(feedbackData?.timestampLocalAtFeedback) || 'N/A, N/A').split(', ');

  return (
    <div className={styles.card}>
      <div className={styles.cardStandartTitleRow}>
        <div className={styles.text}>{t('user_feedback_trip')}</div>
        <div className={styles.button}>
          <img className={styles.icons18MoreHorizontal} alt="" src="/icons/18/misc/show-more-horizontal-three-dots.svg" />
        </div>
      </div>
      <div className={styles.cardStandartContent}>
        {renderFeedbackItem('date', [dateTime, time], true)}
        {renderFeedbackItem('system', [
          feedbackData?.systemNameAtFeedback,
          feedbackData?.systemVersionAtFeedback,
          feedbackData?.deviceBrandAtFeedback
        ], true)}
        {renderFeedbackItem('app_version', feedbackData?.appVersionAtFeedback)}
        {renderFeedbackItem('build_version', 'N/A')}
        {renderFeedbackItem('language', feedbackData?.languageAtFeedback)}
        {renderFeedbackItem('battery_level', feedbackData?.batteryLevelAtFeedback ? `${feedbackData.batteryLevelAtFeedback}%` : 'N/A')}
        {renderFeedbackItem('device_window_dimension', feedbackData?.windowDimensionAtFeedback)}
        {renderFeedbackItem('feedback_topic', feedbackData?.feedbackTopicAtFeedback)}
      </div>
    </div>
  );
};

export default UserFeedback;