// File: frontend/src/pages/Home/Home.js

import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLanguage } from '../../contexts/LanguageContext';
import { useSEO } from '../../contexts/SEOContext';
import HeroSection from '../../components/Home/HeroSection';
import InsuranceManagementSection from '../../components/Home/InsuranceManagementSection';
import MigrationSection from '../../components/Home/MigrationSection';
import ProductDevSection from '../../components/Home/ProductDevSection';
import IntroSection from '../../components/Home/IntroSection';
import styles from './Home.module.css';

const Home = () => {
  const { t, language, supportedLanguages } = useLanguage();
  const { seoData, updateSEO } = useSEO();

  useEffect(() => {
    const structuredData = {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      '@id': 'https://www.allegory.app',
      name: 'Allegory Insurance Technologies',
      url: 'https://www.allegory.app',
      logo: 'https://www.allegory.app/logo.png',
      sameAs: [
        'https://www.linkedin.com/company/allegory',
        'https://twitter.com/allegory',
        'https://facebook.com/allegory'
      ],
      description: t('home_page_description'),
      address: {
        '@type': 'PostalAddress',
        addressCountry: 'CA'
      },
      offers: {
        '@type': 'AggregateOffer',
        availableLanguage: supportedLanguages.map(lang => ({
          '@type': 'Language',
          name: lang
        }))
      },
      potentialAction: {
        '@type': 'ViewAction',
        target: [
          {
            '@type': 'EntryPoint',
            urlTemplate: 'https://www.allegory.app/sign-up',
            actionPlatform: [
              'http://schema.org/DesktopWebPlatform',
              'http://schema.org/MobileWebPlatform'
            ]
          }
        ]
      }
    };

    const softwareData = {
      '@context': 'https://schema.org',
      '@type': 'SoftwareApplication',
      name: 'Allegory Insurance Platform',
      applicationCategory: 'BusinessApplication',
      operatingSystem: 'Web-based',
      offers: {
        '@type': 'Offer',
        price: '0',
        priceCurrency: 'USD'
      }
    };

    updateSEO({
      title: t('home_page_title'),
      description: t('home_page_description'),
      keywords: t('home_page_keywords'),
      canonicalUrl: `https://www.allegory.app/${language}`,
      ogImage: 'https://www.allegory.app/images/og-home.jpg',
      structuredData: [structuredData, softwareData]
    });
  }, [updateSEO, t, language, supportedLanguages]);

  const baseUrl = 'https://www.allegory.app';

  return (
    <>
      <Helmet>
        {/* Basic Meta Tags */}
        <html lang={language} />
        <title>{seoData.title}</title>
        <meta name="description" content={seoData.description} />
        <meta name="keywords" content={seoData.keywords} />
        <meta name="author" content="Allegory Insurance Technologies" />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        
        {/* Canonical and Language Alternates */}
        <link rel="canonical" href={seoData.canonicalUrl} />
        {supportedLanguages.map(lang => (
          <link 
            key={lang}
            rel="alternate" 
            hreflang={lang} 
            href={`${baseUrl}/${lang}`}
          />
        ))}
        <link 
          rel="alternate" 
          hreflang="x-default" 
          href={`${baseUrl}/en-ca`}
        />

        {/* Open Graph Tags */}
        <meta property="og:title" content={seoData.title} />
        <meta property="og:description" content={seoData.description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={seoData.canonicalUrl} />
        <meta property="og:image" content={seoData.ogImage} />
        <meta property="og:site_name" content="Allegory" />
        <meta property="og:locale" content={language} />
        {supportedLanguages
          .filter(lang => lang !== language)
          .map(lang => (
            <meta 
              key={lang}
              property="og:locale:alternate" 
              content={lang} 
            />
          ))}

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@allegory" />
        <meta name="twitter:title" content={seoData.title} />
        <meta name="twitter:description" content={seoData.description} />
        <meta name="twitter:image" content={seoData.ogImage} />

        {/* PWA/Mobile Tags */}
        <meta name="mobile-web-app-capable" content="yes" />
        <link rel="manifest" href="/manifest.json" />
        <meta name="theme-color" content="#2348ED" />

        {/* Structured Data */}
        {Array.isArray(seoData.structuredData) && seoData.structuredData.map((data, index) => (
          <script key={index} type="application/ld+json">
            {JSON.stringify(data)}
          </script>
        ))}
      </Helmet>

      <div className={`${styles.homePage} ${styles.fadeIn}`}>
        <HeroSection />
        <InsuranceManagementSection />
        <ProductDevSection />
        <MigrationSection />
        <IntroSection />
      </div>
    </>
  );
};

export default Home;