// File: frontend/src/pages/InvestorPitch/InvestorPitch.js

import React, { useEffect } from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import { AnimatePresence, motion } from 'framer-motion';
import useSlideNavigation from './hooks/useSlideNavigation';
import SlideNavigation from './components/navigation/SlideNavigation';
import SlideQuickNav from './components/navigation/SlideQuickNav';
import { slides, getSlides } from './constants/slideData';
import styles from './InvestorPitch.module.css';

const InvestorPitch = () => {
  const { t } = useLanguage();
  const translatedSlides = React.useMemo(() => getSlides(t), [t]);
  const slideData = translatedSlides || slides;

  const {
    currentSlide,
    direction,
    slideVariants,
    goToNextSlide,
    goToPreviousSlide,
    goToSlide,
    handleKeyboardNavigation
  } = useSlideNavigation(slideData.length);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyboardNavigation);
    return () => window.removeEventListener('keydown', handleKeyboardNavigation);
  }, [handleKeyboardNavigation]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <div className={styles.slideNavigation}>
            <SlideQuickNav
              currentSlide={currentSlide}
              totalSlides={slideData.length}
              slides={slideData}
              onSlideSelect={goToSlide}
            />
          </div>
        </div>
      </div>

      <main className={styles.main}>
        <AnimatePresence mode="wait" custom={direction}>
          <motion.div
            key={currentSlide}
            custom={direction}
            variants={slideVariants}
            initial="enter"
            animate="center"
            exit="exit"
            className={styles.slideContainer}
          >
            {slideData[currentSlide].content}
          </motion.div>
        </AnimatePresence>
      </main>

      <footer className={styles.footer}>
        <SlideNavigation
          currentSlide={currentSlide}
          totalSlides={slideData.length}
          onNext={goToNextSlide}
          onPrevious={goToPreviousSlide}
        />
        <div className={styles.confidentialInfo}>
          <span className={styles.confidential}>
            {t('investor_pitch.master.common.confidential')}
          </span>
          <span className={styles.separator}>•</span>
          <span>
            {t('investor_pitch.master.common.prepared_for')} {process.env.REACT_APP_ENTERPRISE_NAME}
          </span>
          <span className={styles.separator}>•</span>
          <span className={styles.enterprise_id}>
            ID: {process.env.REACT_APP_ENTERPRISE_ID}
          </span>
        </div>
        <div className={styles.copyright}>
          © {new Date().getFullYear()} {t('investor_pitch.master.common.company')}
        </div>
      </footer>
    </div>
  );
};

export default InvestorPitch;