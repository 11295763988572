// File: frontend/src/pages/InvestorPitch/components/slides/AnimatedHiringSlide/AnimatedHiringSlide.js

import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import { ANIMATION_VARIANTS } from '../../../constants/animations';
import { MapPin } from 'lucide-react';

// Example data arrays – adjust paths or actual data as needed:
import { locations, positions, compensationItems } from './data/hiringData';

import styles from './AnimatedHiringSlide.module.css';

const AnimatedHiringSlide = () => {
  const { t } = useLanguage();
  const [activeSection, setActiveSection] = useState(0);

  // Automatically rotate sections every 5 seconds
  useEffect(() => {
    const sectionDuration = 3000; // 5 seconds
    const timer = setInterval(() => {
      setActiveSection((prev) => (prev + 1) % 4);
    }, sectionDuration);

    return () => clearInterval(timer);
  }, []);

  // Standard "page" transition variants for each rotating section
  const pageTransitionVariants = {
    initial: {
      opacity: 0,
      scale: 0.98,
      filter: 'blur(4px)',
    },
    animate: {
      opacity: 1,
      scale: 1,
      filter: 'blur(0px)',
      transition: {
        duration: 0.8,
        ease: [0.6, -0.05, 0.01, 0.99],
      },
    },
    exit: {
      opacity: 0,
      scale: 1.02,
      filter: 'blur(4px)',
      transition: {
        duration: 0.5,
      },
    },
  };

  // For simple staggering of child elements
  const staggerContainer = {
    animate: {
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.3,
      },
    },
  };
  const staggerItem = {
    initial: { opacity: 0, y: 20 },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
      },
    },
  };

  return (
    <div className={styles.container}>
      {/* 
        1) The new header is placed ABOVE AnimatePresence so it remains fixed 
           and does not get unmounted with each activeSection change.
      */}
      <motion.div
        className={styles.header}
        variants={ANIMATION_VARIANTS.fadeIn}
        initial="hidden"
        animate="visible"
      >
        <h2 className={styles.title}>
          {t('investor_pitch.hiring.title', 'Join Our Mission')}
        </h2>
        <p className={styles.subtitle}>
          {t('investor_pitch.hiring.subtitle', 'We’re building the future of insurance.')}
        </p>
      </motion.div>

      {/* 
        2) AnimatePresence cycles through 4 sections (0..3). 
           The header stays in place above them.
      */}
      <AnimatePresence mode="wait">
        {activeSection === 0 && (
          <motion.div
            key="titleSection"
            className={styles.titleSection}
            variants={pageTransitionVariants}
            initial="initial"
            animate="animate"
            exit="exit"
          >
            <motion.h1 
              variants={staggerContainer} 
              initial="initial" 
              animate="animate"
            >
              <motion.span className={styles.titleGreen} variants={staggerItem}>
                Redefine Risk
              </motion.span>
              {' '}
              <motion.span className={styles.titleBlue} variants={staggerItem}>
                Management
              </motion.span>
            </motion.h1>

            <motion.h2 variants={staggerItem}>
              Through Safe Super Intelligence
            </motion.h2>

            {/* 3D Button Example */}
            <motion.div
              className={styles.buttonWrapper}
              variants={{
                initial: { opacity: 0, y: 20 },
                animate: {
                  opacity: 1,
                  y: 0,
                  transition: { delay: 0.5 },
                },
              }}
            >
              <motion.a
                href="https://allegory.app/join"
                className={styles.ctaButton3D}
                whileHover={{
                  translateY: -5,
                  rotateX: 10,
                  scale: 1.02,
                }}
                whileTap={{
                  translateY: 0,
                  rotateX: 0,
                  scale: 0.98,
                }}
              >
                <span className={styles.buttonContent}>
                  Apply Now: allegory.app/join
                </span>
                <div className={styles.buttonShadow} />
                <div className={styles.buttonGlow} />
              </motion.a>
            </motion.div>
          </motion.div>
        )}

        {activeSection === 1 && (
          <motion.div
            key="locationsSection"
            className={styles.locationsSection}
            variants={pageTransitionVariants}
            initial="initial"
            animate="animate"
            exit="exit"
          >
            <motion.div
              className={styles.locationsGrid}
              variants={staggerContainer}
            >
              {locations.map((location) => (
                <motion.div
                  key={location.city}
                  className={`${styles.locationCard} ${styles[location.gradient]}`}
                  variants={staggerItem}
                  whileHover={{
                    scale: 1.05,
                    transition: {
                      type: 'spring',
                      stiffness: 300,
                      damping: 20,
                    },
                  }}
                >
                  <div className={styles.locationIcon}>
                    <MapPin size={20} />
                  </div>
                  <h3>{location.city}</h3>
                  <p>{location.type}</p>
                  <motion.div
                    className={styles.locationPulse}
                    animate={{
                      scale: [1, 1.2, 1],
                      opacity: [0.5, 0.8, 0.5],
                    }}
                    transition={{
                      duration: 2,
                      repeat: Infinity,
                    }}
                  />
                </motion.div>
              ))}
            </motion.div>
          </motion.div>
        )}

        {activeSection === 2 && (
          <motion.div
            key="positionsSection"
            className={styles.positionsSection}
            variants={pageTransitionVariants}
            initial="initial"
            animate="animate"
            exit="exit"
          >
            <motion.h2 variants={staggerItem}>
              <motion.span
                className={styles.counter}
                animate={{
                  scale: [1, 1.2, 1],
                  transition: { duration: 0.5 },
                }}
              >
                54
              </motion.span>
              {' '}Open Positions
            </motion.h2>
            <motion.div
              className={styles.positionsGrid}
              variants={staggerContainer}
            >
              {positions.map((position, index) => (
                <motion.div
                  key={position.title}
                  className={`${styles.positionCard} ${styles[position.gradient]}`}
                  variants={staggerItem}
                  whileHover={{
                    scale: 1.05,
                    transition: {
                      type: 'spring',
                      stiffness: 300,
                      damping: 20,
                    },
                  }}
                  custom={index}
                >
                  <motion.span className={styles.positionIcon}>
                    {position.icon}
                  </motion.span>
                  <motion.span 
                    className={styles.positionTitle}
                    variants={staggerItem}
                  >
                    {position.title}
                  </motion.span>
                </motion.div>
              ))}
            </motion.div>
          </motion.div>
        )}

        {activeSection === 3 && (
          <motion.div
            key="compensationSection"
            className={styles.compensationSection}
            variants={pageTransitionVariants}
            initial="initial"
            animate="animate"
            exit="exit"
          >
            <motion.h2 variants={staggerItem}>
              Compensation & Benefits
            </motion.h2>
            <motion.div
              className={styles.compensationGrid}
              variants={staggerContainer}
            >
              {compensationItems.map((item, index) => (
                <motion.div
                  key={item.title}
                  className={styles.compensationCard}
                  variants={staggerItem}
                  whileHover={{
                    y: -10,
                    transition: {
                      type: 'spring',
                      stiffness: 300,
                      damping: 20,
                    },
                  }}
                  custom={index}
                >
                  <motion.div
                    animate={{
                      scale: [1, 1.1, 1],
                      transition: {
                        duration: 2,
                        repeat: Infinity,
                        ease: 'easeInOut',
                      },
                    }}
                  >
                    {item.icon}
                  </motion.div>
                  <motion.h3 variants={staggerItem}>
                    {item.title}
                  </motion.h3>
                  <motion.div
                    className={styles.compensationRange}
                    variants={staggerItem}
                  >
                    {item.range}
                  </motion.div>
                  <motion.p variants={staggerItem}>
                    {item.details}
                  </motion.p>
                  <motion.ul
                    variants={staggerContainer}
                    className={styles.compensationHighlights}
                  >
                    {item.highlights.map((highlight, i) => (
                      <motion.li key={i} variants={staggerItem}>
                        {highlight}
                      </motion.li>
                    ))}
                  </motion.ul>
                </motion.div>
              ))}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default AnimatedHiringSlide;
