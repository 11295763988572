// File: frontend/src/components/Button/LandingButton.js

import React from 'react';
import PropTypes from 'prop-types';
import { useLanguage } from '../../contexts/LanguageContext';
import Button from './Button';

const LandingButton = ({ children, onClick, href, disabled, className, showArrow = true, ariaLabel }) => {
  const { t } = useLanguage();

  return (
    <Button
      variant="primary"
      size="large"
      onClick={onClick}
      href={href}
      disabled={disabled}
      className={className}
      showArrow={showArrow}
      ariaLabel={ariaLabel || t('landing_button_aria_label', { content: children })}
    >
      {children}
    </Button>
  );
};

LandingButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  href: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  showArrow: PropTypes.bool,
  ariaLabel: PropTypes.string,
};

export default LandingButton;