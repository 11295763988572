// File: frontend/src/pages/QMS/QuoteDetail/components/QuoteProducts/index.js

import React, { useState } from 'react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import AutoDetails from './AutoDetails';
import HomeDetails from './HomeDetails';
import TenantDetails from './TenantDetails';
import styles from './QuoteProducts.module.css';

const QuoteProducts = ({ quote }) => {
  const { t } = useLanguage();
  const [activeProduct, setActiveProduct] = useState(null);
  
  // Get product types from quote
  const productTypes = quote.meta?.product_types || [];
  
  // Set default active product if not set yet
  React.useEffect(() => {
    if (productTypes.length > 0 && !activeProduct) {
      setActiveProduct(productTypes[0]);
    }
  }, [productTypes, activeProduct]);

  if (productTypes.length === 0) {
    return (
      <div className={styles.noProducts}>
        <p>{t('no_product_information')}</p>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.productTabs}>
        {productTypes.map(type => (
          <button
            key={type}
            className={`${styles.productTab} ${activeProduct === type ? styles.activeProductTab : ''}`}
            onClick={() => setActiveProduct(type)}
          >
            {t(type)}
          </button>
        ))}
      </div>
      
      <div className={styles.productContent}>
        {activeProduct === 'auto' && <AutoDetails quote={quote} />}
        {activeProduct === 'home' && <HomeDetails quote={quote} />}
        {activeProduct === 'tenant' && <TenantDetails quote={quote} />}
      </div>
    </div>
  );
};

export default QuoteProducts;