// File: frontend/src/pages/InvestorPitch/components/slides/MarketImpactSlide/ImpactMetric.js

import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import * as LucideIcons from 'lucide-react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from './ImpactMetric.module.css';

const ImpactMetric = ({ title, value, icon, description, color }) => {
  const { t } = useLanguage();
  const Icon = LucideIcons[icon];

  return (
    <motion.div 
      className={styles.container}
      whileHover={{ scale: 1.05 }}
      transition={{ type: 'spring', stiffness: 300, damping: 30 }}
    >
      <div 
        className={styles.iconWrapper}
        style={{ backgroundColor: `${color}15`, color }}
      >
        <Icon 
          className={styles.icon} 
          aria-label={t('investor_pitch.market_impact.metric.icon_aria', { title })}
        />
      </div>
      <div className={styles.content}>
        <h4 className={styles.title}>{title}</h4>
        <div 
          className={styles.value} 
          style={{ color }}
          aria-label={t('investor_pitch.market_impact.metric.value_aria', { 
            title,
            value 
          })}
        >
          {value}
        </div>
        <p className={styles.description}>{description}</p>
      </div>
    </motion.div>
  );
};

ImpactMetric.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default ImpactMetric;