// File: frontend/src/pages/InvestorPitch/components/slides/TechnicalArchitectureSlide/DeploymentOption.js

import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import * as LucideIcons from 'lucide-react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from './DeploymentOption.module.css';

const DeploymentOption = ({ title, description, icon, gradient }) => {
  const { t } = useLanguage();
  const Icon = LucideIcons[icon];

  return (
    <motion.div 
      className={styles.container}
      whileHover={{ scale: 1.05 }}
      transition={{ type: 'spring', stiffness: 300, damping: 30 }}
      aria-label={t('investor_pitch.technical.deployment.option.aria_label', { title })}
    >
      <div 
        className={styles.iconWrapper}
        style={{ background: gradient }}
      >
        <Icon className={styles.icon} aria-hidden="true" />
      </div>
      <h4 className={styles.title}>{title}</h4>
      <p className={styles.description}>{description}</p>
    </motion.div>
  );
};

DeploymentOption.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  gradient: PropTypes.string.isRequired,
};

export default DeploymentOption;