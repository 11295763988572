// File: frontend/src/pages/SuperAdmin/components/SubscriptionManagement/SubscriptionList/index.js

import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import SuperAdminTable from '../../common/SuperAdminTable';
import SuperAdminSearch from '../../common/SuperAdminSearch';
import SuperAdminPagination from '../../common/SuperAdminPagination';
import styles from './SubscriptionList.module.css';

const SubscriptionList = ({ onViewDetail, onViewTiers }) => {
  const { t } = useLanguage();
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('all');
  const [filterTier, setFilterTier] = useState('all');

  useEffect(() => {
    const fetchSubscriptions = async () => {
      setLoading(true);
      setError(null);
      
      try {
        // This would be replaced with an actual API call
        // const response = await subscriptionService.listSubscriptions({
        //   page: currentPage,
        //   search: searchTerm,
        //   status: filterStatus !== 'all' ? filterStatus : undefined,
        //   tier: filterTier !== 'all' ? filterTier : undefined
        // });
        
        // Mock data for now
        await new Promise(resolve => setTimeout(resolve, 800));
        
        const mockSubscriptions = [
          {
            subscription_id: 'subs-12345',
            enterprise_id: 'ent-12345',
            organization_name: 'Acme Corporation',
            subscription_status: 'active',
            subscription_tier: 'enterprise',
            billing_frequency: 'monthly',
            subscription_start_date: '2023-01-15',
            subscription_end_date: '2024-01-14',
            last_billing_date: '2023-06-15',
            next_billing_date: '2023-07-15',
            created_at: '2023-01-10T15:35:00Z'
          },
          {
            subscription_id: 'subs-23456',
            enterprise_id: 'ent-23456',
            organization_name: 'Global Industries',
            subscription_status: 'active',
            subscription_tier: 'professional',
            billing_frequency: 'annually',
            subscription_start_date: '2023-02-01',
            subscription_end_date: '2024-01-31',
            last_billing_date: '2023-02-01',
            next_billing_date: '2024-02-01',
            created_at: '2023-01-25T11:45:00Z'
          },
          {
            subscription_id: 'subs-34567',
            enterprise_id: 'ent-34567',
            organization_name: 'Tech Solutions',
            subscription_status: 'active',
            subscription_tier: 'basic',
            billing_frequency: 'monthly',
            subscription_start_date: '2023-03-15',
            subscription_end_date: '2023-09-14',
            last_billing_date: '2023-06-15',
            next_billing_date: '2023-07-15',
            created_at: '2023-03-10T09:20:00Z'
          },
          {
            subscription_id: 'subs-45678',
            enterprise_id: 'ent-45678',
            organization_name: 'Innovative Systems',
            subscription_status: 'expired',
            subscription_tier: 'professional',
            billing_frequency: 'monthly',
            subscription_start_date: '2023-01-01',
            subscription_end_date: '2023-06-30',
            last_billing_date: '2023-06-01',
            next_billing_date: null,
            created_at: '2022-12-20T14:10:00Z'
          },
          {
            subscription_id: 'subs-56789',
            enterprise_id: 'ent-56789',
            organization_name: 'Digital Solutions',
            subscription_status: 'pending',
            subscription_tier: 'enterprise',
            billing_frequency: 'quarterly',
            subscription_start_date: '2023-07-01',
            subscription_end_date: '2024-06-30',
            last_billing_date: null,
            next_billing_date: '2023-07-01',
            created_at: '2023-06-15T10:30:00Z'
          }
        ];
        
        // Filter based on search term, status, and tier
        let filteredSubscriptions = mockSubscriptions;
        
        if (searchTerm) {
          const searchLower = searchTerm.toLowerCase();
          filteredSubscriptions = filteredSubscriptions.filter(subscription => 
            subscription.organization_name.toLowerCase().includes(searchLower) ||
            subscription.subscription_id.toLowerCase().includes(searchLower)
          );
        }
        
        if (filterStatus !== 'all') {
          filteredSubscriptions = filteredSubscriptions.filter(subscription => 
            subscription.subscription_status === filterStatus
          );
        }
        
        if (filterTier !== 'all') {
          filteredSubscriptions = filteredSubscriptions.filter(subscription => 
            subscription.subscription_tier === filterTier
          );
        }
        
        setSubscriptions(filteredSubscriptions);
        setTotalPages(Math.ceil(filteredSubscriptions.length / 10)); // Assuming 10 items per page
      } catch (err) {
        console.error('Error fetching subscriptions:', err);
        setError(t('failed_to_load_subscriptions'));
      } finally {
        setLoading(false);
      }
    };
    
    fetchSubscriptions();
  }, [currentPage, searchTerm, filterStatus, filterTier, t]);

  const handleSearch = (term) => {
    setSearchTerm(term);
    setCurrentPage(1);
  };

  const handleStatusFilterChange = (e) => {
    setFilterStatus(e.target.value);
    setCurrentPage(1);
  };

  const handleTierFilterChange = (e) => {
    setFilterTier(e.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    }).format(date);
  };

  const getStatusBadgeClass = (status) => {
    switch (status) {
      case 'active':
        return styles.statusActive;
      case 'expired':
        return styles.statusExpired;
      case 'pending':
        return styles.statusPending;
      default:
        return '';
    }
  };

  const getTierBadgeClass = (tier) => {
    switch (tier) {
      case 'enterprise':
        return styles.tierEnterprise;
      case 'professional':
        return styles.tierProfessional;
      case 'basic':
        return styles.tierBasic;
      default:
        return '';
    }
  };

  const columns = [
    {
      header: t('subscription_id'),
      accessor: 'subscription_id',
      cell: (row) => <span className={styles.idCell}>{row.subscription_id}</span>
    },
    {
      header: t('organization'),
      accessor: 'organization_name',
      cell: (row) => <span className={styles.nameCell}>{row.organization_name}</span>
    },
    {
      header: t('tier'),
      accessor: 'subscription_tier',
      cell: (row) => (
        <span className={`${styles.tierBadge} ${getTierBadgeClass(row.subscription_tier)}`}>
          {row.subscription_tier}
        </span>
      )
    },
    {
      header: t('status'),
      accessor: 'subscription_status',
      cell: (row) => (
        <span className={`${styles.statusBadge} ${getStatusBadgeClass(row.subscription_status)}`}>
          {row.subscription_status}
        </span>
      )
    },
    {
      header: t('billing_frequency'),
      accessor: 'billing_frequency',
      cell: (row) => <span className={styles.billingCell}>{row.billing_frequency}</span>
    },
    {
      header: t('start_date'),
      accessor: 'subscription_start_date',
      cell: (row) => <span>{formatDate(row.subscription_start_date)}</span>
    },
    {
      header: t('end_date'),
      accessor: 'subscription_end_date',
      cell: (row) => <span>{formatDate(row.subscription_end_date)}</span>
    },
    {
      header: t('next_billing'),
      accessor: 'next_billing_date',
      cell: (row) => <span>{formatDate(row.next_billing_date)}</span>
    },
    {
      header: t('actions'),
      accessor: 'actions',
      cell: (row) => (
        <div className={styles.actions}>
          <button 
            className={styles.actionButton}
            onClick={() => onViewDetail(row.subscription_id)}
          >
            <i className="fas fa-eye"></i>
            <span>{t('view')}</span>
          </button>
        </div>
      )
    }
  ];

  return (
    <div className={styles.container}>
      <div className={styles.toolbar}>
        <div className={styles.filters}>
          <SuperAdminSearch 
            placeholder={t('search_subscriptions')}
            onSearch={handleSearch}
          />
          
          <div className={styles.filterGroup}>
            <label htmlFor="statusFilter" className={styles.filterLabel}>
              {t('status')}:
            </label>
            <select
              id="statusFilter"
              className={styles.filterSelect}
              value={filterStatus}
              onChange={handleStatusFilterChange}
            >
              <option value="all">{t('all_statuses')}</option>
              <option value="active">{t('active')}</option>
              <option value="expired">{t('expired')}</option>
              <option value="pending">{t('pending')}</option>
            </select>
          </div>
          
          <div className={styles.filterGroup}>
            <label htmlFor="tierFilter" className={styles.filterLabel}>
              {t('tier')}:
            </label>
            <select
              id="tierFilter"
              className={styles.filterSelect}
              value={filterTier}
              onChange={handleTierFilterChange}
            >
              <option value="all">{t('all_tiers')}</option>
              <option value="enterprise">{t('enterprise')}</option>
              <option value="professional">{t('professional')}</option>
              <option value="basic">{t('basic')}</option>
            </select>
          </div>
        </div>
        
        <div className={styles.actionButtons}>
          <button className={styles.tiersButton} onClick={onViewTiers}>
            <i className="fas fa-layer-group"></i>
            <span>{t('manage_tiers')}</span>
          </button>
          <button className={styles.createButton}>
            <i className="fas fa-plus"></i>
            <span>{t('create_subscription')}</span>
          </button>
        </div>
      </div>
      
      {error && <div className={styles.error}>{error}</div>}
      
      <SuperAdminTable
        columns={columns}
        data={subscriptions}
        loading={loading}
        emptyMessage={t('no_subscriptions_found')}
      />
      
      <div className={styles.pagination}>
        <SuperAdminPagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default SubscriptionList;