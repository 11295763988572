import React from 'react';
import { motion } from 'framer-motion';
import { ANIMATION_VARIANTS } from '../../../../constants/animations';
import styles from './ModelParametersSlide.module.css';

const ModelParametersSlide = () => {
    const statistics = {
        distribution: {
            kurtosis: 10.49,
            skewness: 2.17,
            peakRatios: "0.3 : 0.3 : 0.2"
        },
        market: {
            totalGWP: "$3.2B",
            expenseRatio: "33.6%",
            portfolioMix: "Auto: 45%, Home: 40%, Commercial: 15%"
        },
        peaks: {
            simple: "$2.5k",
            medium: "$10k",
            complex: "$30k",
            tail: "$100k"
        }
    };

    return (
        <motion.div
            className={styles.container}
            variants={ANIMATION_VARIANTS.stagger.container}
            initial="hidden"
            animate="visible"
        >
            <motion.div 
                className={styles.header}
                variants={ANIMATION_VARIANTS.fadeIn}
            >
                <h1 className={styles.title}>Model Parameters</h1>
                {/* <p className={styles.subtitle}>
                    Statistical Validation of Our Distribution Model
                </p> */}
            </motion.div>

            <motion.div 
                className={styles.grid}
                variants={ANIMATION_VARIANTS.stagger.container}
            >
                <motion.div 
                    className={styles.card}
                    variants={ANIMATION_VARIANTS.stagger.item}
                >
                    <h2 className={styles.cardTitle}>Distribution Parameters</h2>
                    <div className={styles.cardContent}>
                        <div className={styles.parameter}>
                            <span className={styles.label}>Leptokurtic:</span>
                            <span className={styles.value}>{statistics.distribution.kurtosis}</span>
                            {/* <span className={styles.note}>(leptokurtic - heavy tails)</span> */}
                        </div>
                        <div className={styles.parameter}>
                            <span className={styles.label}>Right-skewed:</span>
                            <span className={styles.value}>{statistics.distribution.skewness}</span>
                            {/* <span className={styles.note}>(right-skewed)</span> */}
                        </div>
                        <div className={styles.parameter}>
                            <span className={styles.label}>Peak Ratios:</span>
                            <span className={styles.value}>{statistics.distribution.peakRatios}</span>
                        </div>
                        <div className={styles.parameter}>
                            <span className={styles.label}>Tail Weight:</span>
                            <span className={styles.value}>20%</span>
                        </div>
                        <div className={styles.parameter}>
                            <span className={styles.label}>Shape Parameter:</span>
                            <span className={styles.value}>0.3</span>
                        </div>
                        <div className={styles.parameter}>
                            <span className={styles.label}>Spread Parameter:</span>
                            <span className={styles.value}>$500k</span>
                        </div>
                    </div>
                </motion.div>

                <motion.div 
                    className={styles.card}
                    variants={ANIMATION_VARIANTS.stagger.item}
                >
                    <h2 className={styles.cardTitle}>Portfolio Composition & Geography</h2>
                    <div className={styles.cardContent}>
                        <div className={styles.parameter}>
                            <span className={styles.label}>Total GWP:</span>
                            <span className={styles.value}>{statistics.market.totalGWP}</span>
                        </div>
                        <div className={styles.parameter}>
                            <span className={styles.label}>Expense Ratio:</span>
                            <span className={styles.value}>{statistics.market.expenseRatio}</span>
                        </div>
                        <div className={styles.parameter}>
                            <span className={styles.label}>Portfolio Mix (Auto):</span>
                            <span className={styles.value}>45%</span>
                        </div>
                        <div className={styles.parameter}>
                            <span className={styles.label}>Portfolio Mix (Home):</span>
                            <span className={styles.value}>40%</span>
                        </div>
                        <div className={styles.parameter}>
                            <span className={styles.label}>Portfolio Mix (Commercial):</span>
                            <span className={styles.value}>15%</span>
                        </div>
                        <div className={styles.parameter}>
                            <span className={styles.label}>Geography:</span>
                            <span className={styles.value}>🇺🇸 & 🇨🇦 - 9:1 </span>
                        </div>
                    </div>
                </motion.div>

                <motion.div 
                    className={styles.card}
                    variants={ANIMATION_VARIANTS.stagger.item}
                >
                    <h2 className={styles.cardTitle}>Multi-Modal Gaussian Mixture</h2>
                    <div className={styles.cardContent}>
                        <div className={styles.parameter}>
                            {/* <span className={styles.label}>Simple Operations:</span> */}
                            <span className={styles.valueNew}>For the main peaks (i = 1,2,3):
                            f_i(x) = p_i * (1/(x*σ_i√(2π))) * exp(-(ln(x) - ln(μ_i))²/(2σ_i²))</span>
                        </div>
                        <div className={styles.parameter}>
                            {/* <span className={styles.label}>Simple Operations:</span> */}
                            <span className={styles.valueNew}>μ₁ = $2,500, σ₁ = 0.4, p₁ = 0.30</span>
                        </div>
                        <div className={styles.parameter}>
                            {/* <span className={styles.label}>Medium Complexity:</span> */}
                            <span className={styles.valueNew}>μ₂ = $10,000, σ₂ = 0.45, p₂ = 0.30</span>
                        </div>
                        <div className={styles.parameter}>
                            {/* <span className={styles.label}>High Complexity:</span> */}
                            <span className={styles.valueNew}>μ₃ = $30,000, σ₃ = 0.5, p₃ = 0.20</span>
                        </div>
                        <div className={styles.parameter}>
                            {/* <span className={styles.label}>Tail Threshold:</span> */}
                            <span className={styles.valueNew}>For the heavy tail:
                            f_t(x) = 0.20 * (1/500000) * (1 + (0.3*(x-100000))/500000)^(-4.33)</span>
                        </div>
                    </div>
                </motion.div>
            </motion.div>

            <motion.div 
                className={styles.footer}
                variants={ANIMATION_VARIANTS.fadeIn}
            >
                <p className={styles.footerText}>
                    Our model achieves high statistical significance across all key parameters,
                    validating the robustness of our AI-driven cost reduction projections.
                </p>
            </motion.div>
        </motion.div>
    );
};

export default ModelParametersSlide;