// File: frontend/src/components/layout/DashboardLayout/DashboardLayout.js

import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../../contexts/LanguageContext';
import { useHeader } from '../../../contexts/HeaderContext';
import Sidebar from '../../Sidebar/Sidebar';
import Header from '../../Header/Header';
import styles from './DashboardLayout.module.css';

const DashboardLayout = ({ children }) => {
  const { t } = useLanguage();
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const { chatControls } = useHeader();

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth <= 768;
      setIsMobile(mobile);
      
      // Reset states when switching between mobile and desktop
      if (mobile) {
        setIsSidebarExpanded(false);
      } else {
        setIsMobileMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSidebarMouseEnter = () => {
    if (!isMobile) {
      setIsSidebarExpanded(true);
    }
  };

  const handleSidebarMouseLeave = () => {
    if (!isMobile) {
      setIsSidebarExpanded(false);
    }
  };

  const handleMobileMenuToggle = () => {
    if (isMobile) {
      const newState = !isMobileMenuOpen;
      setIsMobileMenuOpen(newState);
      setIsSidebarExpanded(newState);
      
      // Toggle body scroll
      // if (newState) {
      //   document.body.style.overflow = 'hidden';
      // } else {
      //   document.body.style.overflow = '';
      // }
    }
  };

  // Cleanup effect
  useEffect(() => {
    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  return (
    <div 
      className={`${styles.dashboardLayout} ${
        isSidebarExpanded ? styles.sidebarExpanded : ''
      } ${isMobileMenuOpen ? styles.mobileMenuOpen : ''}`}
    >
      <div 
        className={styles.sidebarWrapper}
        onMouseEnter={!isMobile ? handleSidebarMouseEnter : undefined}
        onMouseLeave={!isMobile ? handleSidebarMouseLeave : undefined}
      >
        <Sidebar 
          isExpanded={isSidebarExpanded}
          setIsExpanded={setIsSidebarExpanded}
          isMobileMenuOpen={isMobileMenuOpen}
          setIsMobileMenuOpen={setIsMobileMenuOpen}
          isMobile={isMobile}
        />
      </div>
      <div className={styles.mainContent}>
        <Header 
          isDashboard={true}
          onMobileMenuToggle={handleMobileMenuToggle}
          isMobileMenuOpen={isMobileMenuOpen}
          {...chatControls}
        />
        <main className={styles.contentArea}>
          <div className={styles.contentWrapper}>
            {children}
          </div>
        </main>
      </div>
      {/* Add backdrop for mobile that closes the menu */}
      {isMobileMenuOpen && (
        <div 
          className={styles.backdrop}
          onClick={() => handleMobileMenuToggle()}
          aria-hidden="true"
        />
      )}
    </div>
  );
};

export default DashboardLayout;