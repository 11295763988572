// File: frontend/src/pages/SuperAdmin/index.js

import React, { useState } from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import SuperAdminHeader from './components/common/SuperAdminHeader';
import SuperAdminSidebar from './components/common/SuperAdminSidebar';
import SuperAdminBreadcrumbs from './components/common/SuperAdminBreadcrumbs';
import Dashboard from './components/Dashboard';
import EnterpriseManagement from './components/EnterpriseManagement';
import UserManagement from './components/UserManagement';
import ProductManagement from './components/ProductManagement';
import AgreementManagement from './components/AgreementManagement';
import SubscriptionManagement from './components/SubscriptionManagement';
import RbacManagement from './components/RbacManagement';
import CommunicationManagement from './components/CommunicationManagement';
import DatasetManagement from './components/DatasetManagement';
import ReportManagement from './components/ReportManagement';
import AuditLogs from './components/AuditLogs';
import SystemSettings from './components/SystemSettings';
import PitchAccessManager from './components/PitchAccessManager';
import styles from './SuperAdmin.module.css';

const SuperAdmin = () => {
  const { t } = useLanguage();
  const [activeSection, setActiveSection] = useState('dashboard');
  const [breadcrumbs, setBreadcrumbs] = useState([{ label: 'Dashboard', path: '/super-admin' }]);

  // Handle navigation between sections
  const handleNavigation = (section) => {
    setActiveSection(section);
    
    // Update breadcrumbs based on section
    switch (section) {
      case 'dashboard':
        setBreadcrumbs([{ label: 'Dashboard', path: '/super-admin' }]);
        break;
      case 'enterprises':
        setBreadcrumbs([
          { label: 'Dashboard', path: '/super-admin' },
          { label: 'Enterprise Management', path: '/super-admin/enterprises' }
        ]);
        break;
      case 'users':
        setBreadcrumbs([
          { label: 'Dashboard', path: '/super-admin' },
          { label: 'User Management', path: '/super-admin/users' }
        ]);
        break;
      case 'products':
        setBreadcrumbs([
          { label: 'Dashboard', path: '/super-admin' },
          { label: 'Product Management', path: '/super-admin/products' }
        ]);
        break;
      case 'agreements':
        setBreadcrumbs([
          { label: 'Dashboard', path: '/super-admin' },
          { label: 'Agreement Management', path: '/super-admin/agreements' }
        ]);
        break;
      case 'subscriptions':
        setBreadcrumbs([
          { label: 'Dashboard', path: '/super-admin' },
          { label: 'Subscription Management', path: '/super-admin/subscriptions' }
        ]);
        break;
      case 'rbac':
        setBreadcrumbs([
          { label: 'Dashboard', path: '/super-admin' },
          { label: 'RBAC Management', path: '/super-admin/rbac' }
        ]);
        break;
      case 'communications':
        setBreadcrumbs([
          { label: 'Dashboard', path: '/super-admin' },
          { label: 'Communication Management', path: '/super-admin/communications' }
        ]);
        break;
      case 'datasets':
        setBreadcrumbs([
          { label: 'Dashboard', path: '/super-admin' },
          { label: 'Dataset Management', path: '/super-admin/datasets' }
        ]);
        break;
      case 'reports':
        setBreadcrumbs([
          { label: 'Dashboard', path: '/super-admin' },
          { label: 'Report Management', path: '/super-admin/reports' }
        ]);
        break;
      case 'audit-logs':
        setBreadcrumbs([
          { label: 'Dashboard', path: '/super-admin' },
          { label: 'Audit Logs', path: '/super-admin/audit-logs' }
        ]);
        break;
      case 'settings':
        setBreadcrumbs([
          { label: 'Dashboard', path: '/super-admin' },
          { label: 'System Settings', path: '/super-admin/settings' }
        ]);
        break;
      case 'pitch-access':
        setBreadcrumbs([
          { label: 'Dashboard', path: '/super-admin' },
          { label: 'Pitch Access Manager', path: '/super-admin/pitch-access' }
        ]);
        break;
      default:
        setBreadcrumbs([{ label: 'Dashboard', path: '/super-admin' }]);
    }
  };

  // Render the active section
  const renderActiveSection = () => {
    switch (activeSection) {
      case 'dashboard':
        return <Dashboard />;
      case 'enterprises':
        return <EnterpriseManagement updateBreadcrumbs={setBreadcrumbs} />;
      case 'users':
        return <UserManagement updateBreadcrumbs={setBreadcrumbs} />;
      case 'products':
        return <ProductManagement updateBreadcrumbs={setBreadcrumbs} />;
      case 'agreements':
        return <AgreementManagement updateBreadcrumbs={setBreadcrumbs} />;
      case 'subscriptions':
        return <SubscriptionManagement updateBreadcrumbs={setBreadcrumbs} />;
      case 'rbac':
        return <RbacManagement updateBreadcrumbs={setBreadcrumbs} />;
      case 'communications':
        return <CommunicationManagement updateBreadcrumbs={setBreadcrumbs} />;
      case 'datasets':
        return <DatasetManagement updateBreadcrumbs={setBreadcrumbs} />;
      case 'reports':
        return <ReportManagement updateBreadcrumbs={setBreadcrumbs} />;
      case 'audit-logs':
        return <AuditLogs updateBreadcrumbs={setBreadcrumbs} />;
      case 'settings':
        return <SystemSettings updateBreadcrumbs={setBreadcrumbs} />;
      case 'pitch-access':
        return <PitchAccessManager updateBreadcrumbs={setBreadcrumbs} />;
      default:
        return <Dashboard />;
    }
  };

  return (
    <div className={styles.container}>
      <SuperAdminHeader />
      <div className={styles.content}>
        <SuperAdminSidebar 
          activeSection={activeSection} 
          onNavigate={handleNavigation} 
        />
        <main className={styles.mainContent}>
          <SuperAdminBreadcrumbs breadcrumbs={breadcrumbs} />
          <div className={styles.sectionContent}>
            {renderActiveSection()}
          </div>
        </main>
      </div>
    </div>
  );
};

export default SuperAdmin;