// File: frontend/src/pages/InvestorPitch/components/slides/MarketEntrySlide/EntryPhase.js

import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import * as LucideIcons from 'lucide-react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from './EntryPhase.module.css';

const EntryPhase = ({ title, description, steps, gradient }) => {
  const { t } = useLanguage();

  return (
    <motion.div 
      className={styles.container}
      whileHover={{ scale: 1.02 }}
      transition={{ type: 'spring', stiffness: 300, damping: 30 }}
    >
      <div 
        className={styles.header}
        style={{ background: gradient }}
      >
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.description}>{description}</p>
      </div>

      <div className={styles.steps}>
        {steps.map((step, index) => {
          const Icon = LucideIcons[step.icon];
          return (
            <div key={step.title} className={styles.step}>
              <div 
                className={styles.iconWrapper}
                style={{ color: step.color }}
              >
                <Icon 
                  className={styles.icon} 
                  aria-label={t('investor_pitch.market_entry.phases.step_icon.aria_label', { title: step.title })}
                />
              </div>
              <div className={styles.stepContent}>
                <h4 className={styles.stepTitle}>{step.title}</h4>
                <p className={styles.stepDescription}>{step.description}</p>
              </div>
            </div>
          );
        })}
      </div>
    </motion.div>
  );
};

EntryPhase.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    })
  ).isRequired,
  gradient: PropTypes.string.isRequired,
};

export default EntryPhase;