// File: frontend/src/pages/EmbeddedInsurance/data/jurisdictions.js

// Complete jurisdictions data
export const jurisdictions = [
  // United States - States
  { id: 'us-al', name: 'Alabama', code: 'AL', country: 'US', flag: '🇺🇸' },
  { id: 'us-ak', name: 'Alaska', code: 'AK', country: 'US', flag: '🇺🇸' },
  { id: 'us-az', name: 'Arizona', code: 'AZ', country: 'US', flag: '🇺🇸' },
  { id: 'us-ar', name: 'Arkansas', code: 'AR', country: 'US', flag: '🇺🇸' },
  { id: 'us-ca', name: 'California', code: 'CA', country: 'US', flag: '🇺🇸' },
  { id: 'us-co', name: 'Colorado', code: 'CO', country: 'US', flag: '🇺🇸' },
  { id: 'us-ct', name: 'Connecticut', code: 'CT', country: 'US', flag: '🇺🇸' },
  { id: 'us-de', name: 'Delaware', code: 'DE', country: 'US', flag: '🇺🇸' },
  { id: 'us-fl', name: 'Florida', code: 'FL', country: 'US', flag: '🇺🇸' },
  { id: 'us-ga', name: 'Georgia', code: 'GA', country: 'US', flag: '🇺🇸' },
  { id: 'us-hi', name: 'Hawaii', code: 'HI', country: 'US', flag: '🇺🇸' },
  { id: 'us-id', name: 'Idaho', code: 'ID', country: 'US', flag: '🇺🇸' },
  { id: 'us-il', name: 'Illinois', code: 'IL', country: 'US', flag: '🇺🇸' },
  { id: 'us-in', name: 'Indiana', code: 'IN', country: 'US', flag: '🇺🇸' },
  { id: 'us-ia', name: 'Iowa', code: 'IA', country: 'US', flag: '🇺🇸' },
  { id: 'us-ks', name: 'Kansas', code: 'KS', country: 'US', flag: '🇺🇸' },
  { id: 'us-ky', name: 'Kentucky', code: 'KY', country: 'US', flag: '🇺🇸' },
  { id: 'us-la', name: 'Louisiana', code: 'LA', country: 'US', flag: '🇺🇸' },
  { id: 'us-me', name: 'Maine', code: 'ME', country: 'US', flag: '🇺🇸' },
  { id: 'us-md', name: 'Maryland', code: 'MD', country: 'US', flag: '🇺🇸' },
  { id: 'us-ma', name: 'Massachusetts', code: 'MA', country: 'US', flag: '🇺🇸' },
  { id: 'us-mi', name: 'Michigan', code: 'MI', country: 'US', flag: '🇺🇸' },
  { id: 'us-mn', name: 'Minnesota', code: 'MN', country: 'US', flag: '🇺🇸' },
  { id: 'us-ms', name: 'Mississippi', code: 'MS', country: 'US', flag: '🇺🇸' },
  { id: 'us-mo', name: 'Missouri', code: 'MO', country: 'US', flag: '🇺🇸' },
  { id: 'us-mt', name: 'Montana', code: 'MT', country: 'US', flag: '🇺🇸' },
  { id: 'us-ne', name: 'Nebraska', code: 'NE', country: 'US', flag: '🇺🇸' },
  { id: 'us-nv', name: 'Nevada', code: 'NV', country: 'US', flag: '🇺🇸' },
  { id: 'us-nh', name: 'New Hampshire', code: 'NH', country: 'US', flag: '🇺🇸' },
  { id: 'us-nj', name: 'New Jersey', code: 'NJ', country: 'US', flag: '🇺🇸' },
  { id: 'us-nm', name: 'New Mexico', code: 'NM', country: 'US', flag: '🇺🇸' },
  { id: 'us-ny', name: 'New York', code: 'NY', country: 'US', flag: '🇺🇸' },
  { id: 'us-nc', name: 'North Carolina', code: 'NC', country: 'US', flag: '🇺🇸' },
  { id: 'us-nd', name: 'North Dakota', code: 'ND', country: 'US', flag: '🇺🇸' },
  { id: 'us-oh', name: 'Ohio', code: 'OH', country: 'US', flag: '🇺🇸' },
  { id: 'us-ok', name: 'Oklahoma', code: 'OK', country: 'US', flag: '🇺🇸' },
  { id: 'us-or', name: 'Oregon', code: 'OR', country: 'US', flag: '🇺🇸' },
  { id: 'us-pa', name: 'Pennsylvania', code: 'PA', country: 'US', flag: '🇺🇸' },
  { id: 'us-ri', name: 'Rhode Island', code: 'RI', country: 'US', flag: '🇺🇸' },
  { id: 'us-sc', name: 'South Carolina', code: 'SC', country: 'US', flag: '🇺🇸' },
  { id: 'us-sd', name: 'South Dakota', code: 'SD', country: 'US', flag: '🇺🇸' },
  { id: 'us-tn', name: 'Tennessee', code: 'TN', country: 'US', flag: '🇺🇸' },
  { id: 'us-tx', name: 'Texas', code: 'TX', country: 'US', flag: '🇺🇸' },
  { id: 'us-ut', name: 'Utah', code: 'UT', country: 'US', flag: '🇺🇸' },
  { id: 'us-vt', name: 'Vermont', code: 'VT', country: 'US', flag: '🇺🇸' },
  { id: 'us-va', name: 'Virginia', code: 'VA', country: 'US', flag: '🇺🇸' },
  { id: 'us-wa', name: 'Washington', code: 'WA', country: 'US', flag: '🇺🇸' },
  { id: 'us-wv', name: 'West Virginia', code: 'WV', country: 'US', flag: '🇺🇸' },
  { id: 'us-wi', name: 'Wisconsin', code: 'WI', country: 'US', flag: '🇺🇸' },
  { id: 'us-wy', name: 'Wyoming', code: 'WY', country: 'US', flag: '🇺🇸' },
  
  // United States - Territories
  { id: 'us-dc', name: 'District of Columbia', code: 'DC', country: 'US', flag: '🇺🇸' },
  { id: 'us-as', name: 'American Samoa', code: 'AS', country: 'US', flag: '🇺🇸' },
  { id: 'us-gu', name: 'Guam', code: 'GU', country: 'US', flag: '🇺🇸' },
  { id: 'us-mp', name: 'Northern Mariana Islands', code: 'MP', country: 'US', flag: '🇺🇸' },
  { id: 'us-pr', name: 'Puerto Rico', code: 'PR', country: 'US', flag: '🇺🇸' },
  { id: 'us-vi', name: 'U.S. Virgin Islands', code: 'VI', country: 'US', flag: '🇺🇸' },
  
  // Canada - Provinces
  { id: 'ca-ab', name: 'Alberta', code: 'AB', country: 'CA', flag: '🇨🇦' },
  { id: 'ca-bc', name: 'British Columbia', code: 'BC', country: 'CA', flag: '🇨🇦' },
  { id: 'ca-mb', name: 'Manitoba', code: 'MB', country: 'CA', flag: '🇨🇦' },
  { id: 'ca-nb', name: 'New Brunswick', code: 'NB', country: 'CA', flag: '🇨🇦' },
  { id: 'ca-nl', name: 'Newfoundland and Labrador', code: 'NL', country: 'CA', flag: '🇨🇦' },
  { id: 'ca-ns', name: 'Nova Scotia', code: 'NS', country: 'CA', flag: '🇨🇦' },
  { id: 'ca-on', name: 'Ontario', code: 'ON', country: 'CA', flag: '🇨🇦' },
  { id: 'ca-pe', name: 'Prince Edward Island', code: 'PE', country: 'CA', flag: '🇨🇦' },
  { id: 'ca-qc', name: 'Quebec', code: 'QC', country: 'CA', flag: '🇨🇦' },
  { id: 'ca-sk', name: 'Saskatchewan', code: 'SK', country: 'CA', flag: '🇨🇦' },
  
  // Canada - Territories
  { id: 'ca-nt', name: 'Northwest Territories', code: 'NT', country: 'CA', flag: '🇨🇦' },
  { id: 'ca-nu', name: 'Nunavut', code: 'NU', country: 'CA', flag: '🇨🇦' },
  { id: 'ca-yt', name: 'Yukon', code: 'YT', country: 'CA', flag: '🇨🇦' },
  
  // United Kingdom
  { id: 'uk-eng', name: 'England', code: 'ENG', country: 'UK', flag: '🇬🇧' },
  { id: 'uk-sct', name: 'Scotland', code: 'SCT', country: 'UK', flag: '🇬🇧' },
  { id: 'uk-wls', name: 'Wales', code: 'WLS', country: 'UK', flag: '🇬🇧' },
  { id: 'uk-nir', name: 'Northern Ireland', code: 'NIR', country: 'UK', flag: '🇬🇧' },
  
  // Spain - Autonomous Communities
  { id: 'es-an', name: 'Andalusia', code: 'AN', country: 'ES', flag: '🇪🇸' },
  { id: 'es-ar', name: 'Aragon', code: 'AR', country: 'ES', flag: '🇪🇸' },
  { id: 'es-as', name: 'Asturias', code: 'AS', country: 'ES', flag: '🇪🇸' },
  { id: 'es-cn', name: 'Canary Islands', code: 'CN', country: 'ES', flag: '🇪🇸' },
  { id: 'es-cb', name: 'Cantabria', code: 'CB', country: 'ES', flag: '🇪🇸' },
  { id: 'es-cl', name: 'Castile and León', code: 'CL', country: 'ES', flag: '🇪🇸' },
  { id: 'es-cm', name: 'Castile-La Mancha', code: 'CM', country: 'ES', flag: '🇪🇸' },
  { id: 'es-ct', name: 'Catalonia', code: 'CT', country: 'ES', flag: '🇪🇸' },
  { id: 'es-ex', name: 'Extremadura', code: 'EX', country: 'ES', flag: '🇪🇸' },
  { id: 'es-ga', name: 'Galicia', code: 'GA', country: 'ES', flag: '🇪🇸' },
  { id: 'es-ib', name: 'Balearic Islands', code: 'IB', country: 'ES', flag: '🇪🇸' },
  { id: 'es-ri', name: 'La Rioja', code: 'RI', country: 'ES', flag: '🇪🇸' },
  { id: 'es-md', name: 'Madrid', code: 'MD', country: 'ES', flag: '🇪🇸' },
  { id: 'es-mc', name: 'Murcia', code: 'MC', country: 'ES', flag: '🇪🇸' },
  { id: 'es-nc', name: 'Navarre', code: 'NC', country: 'ES', flag: '🇪🇸' },
  { id: 'es-pv', name: 'Basque Country', code: 'PV', country: 'ES', flag: '🇪🇸' },
  { id: 'es-vc', name: 'Valencia', code: 'VC', country: 'ES', flag: '🇪🇸' },
  { id: 'es-ce', name: 'Ceuta', code: 'CE', country: 'ES', flag: '🇪🇸' },
  { id: 'es-ml', name: 'Melilla', code: 'ML', country: 'ES', flag: '🇪🇸' },
  
  // Mexico - States
  { id: 'mx-agu', name: 'Aguascalientes', code: 'AGU', country: 'MX', flag: '🇲🇽' },
  { id: 'mx-bcn', name: 'Baja California', code: 'BCN', country: 'MX', flag: '🇲🇽' },
  { id: 'mx-bcs', name: 'Baja California Sur', code: 'BCS', country: 'MX', flag: '🇲🇽' },
  { id: 'mx-cam', name: 'Campeche', code: 'CAM', country: 'MX', flag: '🇲🇽' },
  { id: 'mx-chp', name: 'Chiapas', code: 'CHP', country: 'MX', flag: '🇲🇽' },
  { id: 'mx-chh', name: 'Chihuahua', code: 'CHH', country: 'MX', flag: '🇲🇽' },
  { id: 'mx-cmx', name: 'Mexico City', code: 'CMX', country: 'MX', flag: '🇲🇽' },
  { id: 'mx-coa', name: 'Coahuila', code: 'COA', country: 'MX', flag: '🇲🇽' },
  { id: 'mx-col', name: 'Colima', code: 'COL', country: 'MX', flag: '🇲🇽' },
  { id: 'mx-dur', name: 'Durango', code: 'DUR', country: 'MX', flag: '🇲🇽' },
  { id: 'mx-gua', name: 'Guanajuato', code: 'GUA', country: 'MX', flag: '🇲🇽' },
  { id: 'mx-gro', name: 'Guerrero', code: 'GRO', country: 'MX', flag: '🇲🇽' },
  { id: 'mx-hid', name: 'Hidalgo', code: 'HID', country: 'MX', flag: '🇲🇽' },
  { id: 'mx-jal', name: 'Jalisco', code: 'JAL', country: 'MX', flag: '🇲🇽' },
  { id: 'mx-mex', name: 'Mexico State', code: 'MEX', country: 'MX', flag: '🇲🇽' },
  { id: 'mx-mic', name: 'Michoacán', code: 'MIC', country: 'MX', flag: '🇲🇽' },
  { id: 'mx-mor', name: 'Morelos', code: 'MOR', country: 'MX', flag: '🇲🇽' },
  { id: 'mx-nay', name: 'Nayarit', code: 'NAY', country: 'MX', flag: '🇲🇽' },
  { id: 'mx-nle', name: 'Nuevo León', code: 'NLE', country: 'MX', flag: '🇲🇽' },
  { id: 'mx-oax', name: 'Oaxaca', code: 'OAX', country: 'MX', flag: '🇲🇽' },
  { id: 'mx-pue', name: 'Puebla', code: 'PUE', country: 'MX', flag: '🇲🇽' },
  { id: 'mx-que', name: 'Querétaro', code: 'QUE', country: 'MX', flag: '🇲🇽' },
  { id: 'mx-roo', name: 'Quintana Roo', code: 'ROO', country: 'MX', flag: '🇲🇽' },
  { id: 'mx-slp', name: 'San Luis Potosí', code: 'SLP', country: 'MX', flag: '🇲🇽' },
  { id: 'mx-sin', name: 'Sinaloa', code: 'SIN', country: 'MX', flag: '🇲🇽' },
  { id: 'mx-son', name: 'Sonora', code: 'SON', country: 'MX', flag: '🇲🇽' },
  { id: 'mx-tab', name: 'Tabasco', code: 'TAB', country: 'MX', flag: '🇲🇽' },
  { id: 'mx-tam', name: 'Tamaulipas', code: 'TAM', country: 'MX', flag: '🇲🇽' },
  { id: 'mx-tla', name: 'Tlaxcala', code: 'TLA', country: 'MX', flag: '🇲🇽' },
  { id: 'mx-ver', name: 'Veracruz', code: 'VER', country: 'MX', flag: '🇲🇽' },
  { id: 'mx-yuc', name: 'Yucatán', code: 'YUC', country: 'MX', flag: '🇲🇽' },
  { id: 'mx-zac', name: 'Zacatecas', code: 'ZAC', country: 'MX', flag: '🇲🇽' },
  
  // Netherlands - Provinces
  { id: 'nl-dr', name: 'Drenthe', code: 'DR', country: 'NL', flag: '🇳🇱' },
  { id: 'nl-fl', name: 'Flevoland', code: 'FL', country: 'NL', flag: '🇳🇱' },
  { id: 'nl-fr', name: 'Friesland', code: 'FR', country: 'NL', flag: '🇳🇱' },
  { id: 'nl-ge', name: 'Gelderland', code: 'GE', country: 'NL', flag: '🇳🇱' },
  { id: 'nl-gr', name: 'Groningen', code: 'GR', country: 'NL', flag: '🇳🇱' },
  { id: 'nl-li', name: 'Limburg', code: 'LI', country: 'NL', flag: '🇳🇱' },
  { id: 'nl-nb', name: 'North Brabant', code: 'NB', country: 'NL', flag: '🇳🇱' },
  { id: 'nl-nh', name: 'North Holland', code: 'NH', country: 'NL', flag: '🇳🇱' },
  { id: 'nl-ov', name: 'Overijssel', code: 'OV', country: 'NL', flag: '🇳🇱' },
  { id: 'nl-ut', name: 'Utrecht', code: 'UT', country: 'NL', flag: '🇳🇱' },
  { id: 'nl-ze', name: 'Zeeland', code: 'ZE', country: 'NL', flag: '🇳🇱' },
  { id: 'nl-zh', name: 'South Holland', code: 'ZH', country: 'NL', flag: '🇳🇱' },
  
  // Turkey - Provinces
  { id: 'tr-01', name: 'Adana', code: '01', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-02', name: 'Adıyaman', code: '02', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-03', name: 'Afyonkarahisar', code: '03', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-04', name: 'Ağrı', code: '04', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-05', name: 'Amasya', code: '05', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-06', name: 'Ankara', code: '06', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-07', name: 'Antalya', code: '07', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-08', name: 'Artvin', code: '08', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-09', name: 'Aydın', code: '09', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-10', name: 'Balıkesir', code: '10', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-11', name: 'Bilecik', code: '11', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-12', name: 'Bingöl', code: '12', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-13', name: 'Bitlis', code: '13', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-14', name: 'Bolu', code: '14', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-15', name: 'Burdur', code: '15', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-16', name: 'Bursa', code: '16', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-17', name: 'Çanakkale', code: '17', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-18', name: 'Çankırı', code: '18', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-19', name: 'Çorum', code: '19', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-20', name: 'Denizli', code: '20', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-21', name: 'Diyarbakır', code: '21', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-22', name: 'Edirne', code: '22', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-23', name: 'Elazığ', code: '23', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-24', name: 'Erzincan', code: '24', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-25', name: 'Erzurum', code: '25', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-26', name: 'Eskişehir', code: '26', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-27', name: 'Gaziantep', code: '27', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-28', name: 'Giresun', code: '28', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-29', name: 'Gümüşhane', code: '29', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-30', name: 'Hakkari', code: '30', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-31', name: 'Hatay', code: '31', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-32', name: 'Isparta', code: '32', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-33', name: 'Mersin', code: '33', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-34', name: 'Istanbul', code: '34', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-35', name: 'Izmir', code: '35', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-36', name: 'Kars', code: '36', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-37', name: 'Kastamonu', code: '37', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-38', name: 'Kayseri', code: '38', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-39', name: 'Kırklareli', code: '39', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-40', name: 'Kırşehir', code: '40', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-41', name: 'Kocaeli', code: '41', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-42', name: 'Konya', code: '42', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-43', name: 'Kütahya', code: '43', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-44', name: 'Malatya', code: '44', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-45', name: 'Manisa', code: '45', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-46', name: 'Kahramanmaraş', code: '46', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-47', name: 'Mardin', code: '47', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-48', name: 'Muğla', code: '48', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-49', name: 'Muş', code: '49', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-50', name: 'Nevşehir', code: '50', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-51', name: 'Niğde', code: '51', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-52', name: 'Ordu', code: '52', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-53', name: 'Rize', code: '53', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-54', name: 'Sakarya', code: '54', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-55', name: 'Samsun', code: '55', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-56', name: 'Siirt', code: '56', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-57', name: 'Sinop', code: '57', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-58', name: 'Sivas', code: '58', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-59', name: 'Tekirdağ', code: '59', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-60', name: 'Tokat', code: '60', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-61', name: 'Trabzon', code: '61', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-62', name: 'Tunceli', code: '62', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-63', name: 'Şanlıurfa', code: '63', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-64', name: 'Uşak', code: '64', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-65', name: 'Van', code: '65', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-66', name: 'Yozgat', code: '66', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-67', name: 'Zonguldak', code: '67', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-68', name: 'Aksaray', code: '68', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-69', name: 'Bayburt', code: '69', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-70', name: 'Karaman', code: '70', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-71', name: 'Kırıkkale', code: '71', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-72', name: 'Batman', code: '72', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-73', name: 'Şırnak', code: '73', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-74', name: 'Bartın', code: '74', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-75', name: 'Ardahan', code: '75', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-76', name: 'Iğdır', code: '76', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-77', name: 'Yalova', code: '77', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-78', name: 'Karabük', code: '78', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-79', name: 'Kilis', code: '79', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-80', name: 'Osmaniye', code: '80', country: 'TR', flag: '🇹🇷' },
  { id: 'tr-81', name: 'Düzce', code: '81', country: 'TR', flag: '🇹🇷' },
  
  // Germany - States
  { id: 'de-bw', name: 'Baden-Württemberg', code: 'BW', country: 'DE', flag: '🇩🇪' },
  { id: 'de-by', name: 'Bavaria', code: 'BY', country: 'DE', flag: '🇩🇪' },
  { id: 'de-be', name: 'Berlin', code: 'BE', country: 'DE', flag: '🇩🇪' },
  { id: 'de-bb', name: 'Brandenburg', code: 'BB', country: 'DE', flag: '🇩🇪' },
  { id: 'de-hb', name: 'Bremen', code: 'HB', country: 'DE', flag: '🇩🇪' },
  { id: 'de-hh', name: 'Hamburg', code: 'HH', country: 'DE', flag: '🇩🇪' },
  { id: 'de-he', name: 'Hesse', code: 'HE', country: 'DE', flag: '🇩🇪' },
  { id: 'de-mv', name: 'Mecklenburg-Vorpommern', code: 'MV', country: 'DE', flag: '🇩🇪' },
  { id: 'de-ni', name: 'Lower Saxony', code: 'NI', country: 'DE', flag: '🇩🇪' },
  { id: 'de-nw', name: 'North Rhine-Westphalia', code: 'NW', country: 'DE', flag: '🇩🇪' },
  { id: 'de-rp', name: 'Rhineland-Palatinate', code: 'RP', country: 'DE', flag: '🇩🇪' },
  { id: 'de-sl', name: 'Saarland', code: 'SL', country: 'DE', flag: '🇩🇪' },
  { id: 'de-sn', name: 'Saxony', code: 'SN', country: 'DE', flag: '🇩🇪' },
  { id: 'de-st', name: 'Saxony-Anhalt', code: 'ST', country: 'DE', flag: '🇩🇪' },
  { id: 'de-sh', name: 'Schleswig-Holstein', code: 'SH', country: 'DE', flag: '🇩🇪' },
  { id: 'de-th', name: 'Thuringia', code: 'TH', country: 'DE', flag: '🇩🇪' },
  
  // Full country entries
  { id: 'us', name: 'United States', code: 'US', country: 'US', flag: '🇺🇸' },
  { id: 'ca', name: 'Canada', code: 'CA', country: 'CA', flag: '🇨🇦' },
  { id: 'es', name: 'Spain', code: 'ES', country: 'ES', flag: '🇪🇸' },
  { id: 'mx', name: 'Mexico', code: 'MX', country: 'MX', flag: '🇲🇽' },
  { id: 'uk', name: 'United Kingdom', code: 'UK', country: 'UK', flag: '🇬🇧' },
  { id: 'nl', name: 'Netherlands', code: 'NL', country: 'NL', flag: '🇳🇱' },
  { id: 'tr', name: 'Turkey', code: 'TR', country: 'TR', flag: '🇹🇷' },
  { id: 'de', name: 'Germany', code: 'DE', country: 'DE', flag: '🇩🇪' }
];