// File: frontend/src/pages/SuperAdmin/components/ProductManagement/ProductCreate/index.js

import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from './ProductCreate.module.css';

const ProductCreate = ({ onBack, onSuccess }) => {
  const { t } = useLanguage();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [enterprises, setEnterprises] = useState([]);
  const [formData, setFormData] = useState({
    enterprise_id: '',
    product_name: '',
    product_description: '',
    product_category: 'analytics',
    product_status: 'development',
    base_price: '',
    currency: 'USD',
    billing_cycle: 'monthly',
    setup_fee: '',
    platform: 'web',
    requirements: '',
    api_access: true,
    data_storage: '',
    uptime_sla: '',
    backup_frequency: ''
  });
  
  useEffect(() => {
    const fetchEnterprises = async () => {
      try {
        // This would be replaced with an actual API call
        // const response = await enterpriseService.listEnterprises();
        
        // Mock data for now
        await new Promise(resolve => setTimeout(resolve, 500));
        
        const mockEnterprises = [
          {
            enterprise_id: 'ent-12345',
            enterprise_name: 'Acme Corporation',
            enterprise_status: 'active'
          },
          {
            enterprise_id: 'ent-23456',
            enterprise_name: 'Global Industries',
            enterprise_status: 'active'
          },
          {
            enterprise_id: 'ent-34567',
            enterprise_name: 'Tech Solutions',
            enterprise_status: 'active'
          },
          {
            enterprise_id: 'ent-45678',
            enterprise_name: 'Innovative Systems',
            enterprise_status: 'active'
          }
        ];
        
        setEnterprises(mockEnterprises);
      } catch (err) {
        console.error('Error fetching enterprises:', err);
        setError(t('failed_to_load_enterprises'));
      }
    };
    
    fetchEnterprises();
  }, [t]);
  
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    
    try {
      // This would be replaced with an actual API call
      // const response = await productService.createProduct({
      //   enterprise_id: formData.enterprise_id,
      //   product_name: formData.product_name,
      //   product_description: formData.product_description,
      //   product_category: formData.product_category,
      //   product_status: formData.product_status,
      //   pricing_details: {
      //     base_price: parseFloat(formData.base_price),
      //     currency: formData.currency,
      //     billing_cycle: formData.billing_cycle,
      //     setup_fee: parseFloat(formData.setup_fee) || 0
      //   },
      //   technical_specifications: {
      //     platform: formData.platform,
      //     requirements: formData.requirements,
      //     api_access: formData.api_access,
      //     data_storage: formData.data_storage,
      //     uptime_sla: formData.uptime_sla,
      //     backup_frequency: formData.backup_frequency
      //   }
      // });
      
      // Mock response for now
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      const mockResponse = {
        success: true,
        product_id: `prod-${Math.random().toString(36).substring(2, 10)}`
      };
      
      // Call the success callback with the new product ID
      onSuccess(mockResponse.product_id);
    } catch (err) {
      console.error('Error creating product:', err);
      setError(t('failed_to_create_product'));
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <button className={styles.backButton} onClick={onBack} disabled={loading}>
          <i className="fas fa-arrow-left"></i>
          <span>{t('back')}</span>
        </button>
      </div>
      
      {error && (
        <div className={styles.error}>
          <i className="fas fa-exclamation-circle"></i>
          <span>{error}</span>
        </div>
      )}
      
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.formSection}>
          <h3 className={styles.sectionTitle}>{t('basic_information')}</h3>
          <div className={styles.formGrid}>
            <div className={styles.formGroup}>
              <label htmlFor="enterprise_id" className={styles.label}>
                {t('enterprise')} <span className={styles.required}>*</span>
              </label>
              <select
                id="enterprise_id"
                name="enterprise_id"
                className={styles.select}
                value={formData.enterprise_id}
                onChange={handleChange}
                required
                disabled={loading}
              >
                <option value="">{t('select_enterprise')}</option>
                {enterprises.map((enterprise) => (
                  <option key={enterprise.enterprise_id} value={enterprise.enterprise_id}>
                    {enterprise.enterprise_name}
                  </option>
                ))}
              </select>
            </div>
            
            <div className={styles.formGroup}>
              <label htmlFor="product_name" className={styles.label}>
                {t('product_name')} <span className={styles.required}>*</span>
              </label>
              <input
                type="text"
                id="product_name"
                name="product_name"
                className={styles.input}
                value={formData.product_name}
                onChange={handleChange}
                required
                disabled={loading}
              />
            </div>
            
            <div className={styles.formGroup}>
              <label htmlFor="product_category" className={styles.label}>
                {t('category')} <span className={styles.required}>*</span>
              </label>
              <select
                id="product_category"
                name="product_category"
                className={styles.select}
                value={formData.product_category}
                onChange={handleChange}
                required
                disabled={loading}
              >
                <option value="analytics">{t('analytics')}</option>
                <option value="risk">{t('risk')}</option>
                <option value="compliance">{t('compliance')}</option>
                <option value="portal">{t('portal')}</option>
                <option value="data">{t('data')}</option>
              </select>
            </div>
            
            <div className={styles.formGroup}>
              <label htmlFor="product_status" className={styles.label}>
                {t('status')} <span className={styles.required}>*</span>
              </label>
              <select
                id="product_status"
                name="product_status"
                className={styles.select}
                value={formData.product_status}
                onChange={handleChange}
                required
                disabled={loading}
              >
                <option value="development">{t('development')}</option>
                <option value="active">{t('active')}</option>
                <option value="inactive">{t('inactive')}</option>
              </select>
            </div>
            
            <div className={styles.formGroupFull}>
              <label htmlFor="product_description" className={styles.label}>
                {t('description')} <span className={styles.required}>*</span>
              </label>
              <textarea
                id="product_description"
                name="product_description"
                className={styles.textarea}
                value={formData.product_description}
                onChange={handleChange}
                rows={4}
                required
                disabled={loading}
              ></textarea>
            </div>
          </div>
        </div>
        
        <div className={styles.formSection}>
          <h3 className={styles.sectionTitle}>{t('pricing_information')}</h3>
          <div className={styles.formGrid}>
            <div className={styles.formGroup}>
              <label htmlFor="base_price" className={styles.label}>
                {t('base_price')} <span className={styles.required}>*</span>
              </label>
              <input
                type="number"
                id="base_price"
                name="base_price"
                className={styles.input}
                value={formData.base_price}
                onChange={handleChange}
                min="0"
                step="0.01"
                required
                disabled={loading}
              />
            </div>
            
            <div className={styles.formGroup}>
              <label htmlFor="currency" className={styles.label}>
                {t('currency')} <span className={styles.required}>*</span>
              </label>
              <select
                id="currency"
                name="currency"
                className={styles.select}
                value={formData.currency}
                onChange={handleChange}
                required
                disabled={loading}
              >
                <option value="USD">USD</option>
                <option value="EUR">EUR</option>
                <option value="GBP">GBP</option>
                <option value="CAD">CAD</option>
                <option value="AUD">AUD</option>
              </select>
            </div>
            
            <div className={styles.formGroup}>
              <label htmlFor="billing_cycle" className={styles.label}>
                {t('billing_cycle')} <span className={styles.required}>*</span>
              </label>
              <select
                id="billing_cycle"
                name="billing_cycle"
                className={styles.select}
                value={formData.billing_cycle}
                onChange={handleChange}
                required
                disabled={loading}
              >
                <option value="monthly">{t('monthly')}</option>
                <option value="quarterly">{t('quarterly')}</option>
                <option value="annually">{t('annually')}</option>
              </select>
            </div>
            
            <div className={styles.formGroup}>
              <label htmlFor="setup_fee" className={styles.label}>
                {t('setup_fee')}
              </label>
              <input
                type="number"
                id="setup_fee"
                name="setup_fee"
                className={styles.input}
                value={formData.setup_fee}
                onChange={handleChange}
                min="0"
                step="0.01"
                disabled={loading}
              />
            </div>
          </div>
        </div>
        
        <div className={styles.formSection}>
          <h3 className={styles.sectionTitle}>{t('technical_specifications')}</h3>
          <div className={styles.formGrid}>
            <div className={styles.formGroup}>
              <label htmlFor="platform" className={styles.label}>
                {t('platform')} <span className={styles.required}>*</span>
              </label>
              <select
                id="platform"
                name="platform"
                className={styles.select}
                value={formData.platform}
                onChange={handleChange}
                required
                disabled={loading}
              >
                <option value="web">{t('web')}</option>
                <option value="mobile">{t('mobile')}</option>
                <option value="desktop">{t('desktop')}</option>
                <option value="hybrid">{t('hybrid')}</option>
              </select>
            </div>
            
            <div className={styles.formGroup}>
              <label htmlFor="api_access" className={styles.label}>
                {t('api_access')}
              </label>
              <div className={styles.checkboxContainer}>
                <input
                  type="checkbox"
                  id="api_access"
                  name="api_access"
                  className={styles.checkbox}
                  checked={formData.api_access}
                  onChange={handleChange}
                  disabled={loading}
                />
                <label htmlFor="api_access" className={styles.checkboxLabel}>
                  {t('has_api_access')}
                </label>
              </div>
            </div>
            
            <div className={styles.formGroup}>
              <label htmlFor="data_storage" className={styles.label}>
                {t('data_storage')}
              </label>
              <input
                type="text"
                id="data_storage"
                name="data_storage"
                className={styles.input}
                value={formData.data_storage}
                onChange={handleChange}
                placeholder="e.g., 50GB per account"
                disabled={loading}
              />
            </div>
            
            <div className={styles.formGroup}>
              <label htmlFor="uptime_sla" className={styles.label}>
                {t('uptime_sla')}
              </label>
              <input
                type="text"
                id="uptime_sla"
                name="uptime_sla"
                className={styles.input}
                value={formData.uptime_sla}
                onChange={handleChange}
                placeholder="e.g., 99.9%"
                disabled={loading}
              />
            </div>
            
            <div className={styles.formGroup}>
              <label htmlFor="backup_frequency" className={styles.label}>
                {t('backup_frequency')}
              </label>
              <input
                type="text"
                id="backup_frequency"
                name="backup_frequency"
                className={styles.input}
                value={formData.backup_frequency}
                onChange={handleChange}
                placeholder="e.g., Daily"
                disabled={loading}
              />
            </div>
            
            <div className={styles.formGroupFull}>
              <label htmlFor="requirements" className={styles.label}>
                {t('requirements')}
              </label>
              <textarea
                id="requirements"
                name="requirements"
                className={styles.textarea}
                value={formData.requirements}
                onChange={handleChange}
                rows={3}
                placeholder="e.g., Modern browser with JavaScript enabled"
                disabled={loading}
              ></textarea>
            </div>
          </div>
        </div>
        
        <div className={styles.formActions}>
          <button 
            type="button" 
            className={styles.cancelButton} 
            onClick={onBack}
            disabled={loading}
          >
            {t('cancel')}
          </button>
          <button 
            type="submit" 
            className={styles.submitButton}
            disabled={loading}
          >
            {loading ? (
              <>
                <span className={styles.spinner}></span>
                <span>{t('creating')}</span>
              </>
            ) : (
              t('create_product')
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ProductCreate;