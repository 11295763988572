// File: frontend/src/pages/Status/components/StatusOverview.js

import React from 'react';
import styles from './StatusOverview.module.css';
import { formatDateTime } from '../utils/metrics';

const StatusOverview = ({ currentStatus, isLoading }) => {
  // console.log('StatusOverview render:', { currentStatus, isLoading });
  
  if (isLoading) {
    // console.log('StatusOverview: Loading state');
    return <div className={styles.loading}>Loading system status...</div>;
  }
  
  if (!currentStatus) {
    console.log('StatusOverview: No current status');
    return <div className={styles.loading}>No status data available...</div>;
  }
  
  // console.log('StatusOverview: Rendering with data', currentStatus);

  const getStatusClass = (status) => {
    switch (status?.toUpperCase()) {
      case 'OK':
        return styles.statusOk;
      case 'DEGRADED':
        return styles.statusDegraded;
      case 'ERROR':
        return styles.statusError;
      default:
        return styles.statusUnknown;
    }
  };

  const getStatusMessage = (status) => {
    switch (status?.toUpperCase()) {
      case 'OK':
        return 'All Systems Operational';
      case 'DEGRADED':
        return 'Partial System Degradation';
      case 'ERROR':
        return 'System Disruption';
      default:
        return 'Status Unknown';
    }
  };

  return (
    <div className={styles.overview}>
      <div className={styles.header}>
        <div className={styles.statusInfo}>
          <h1>System Status</h1>
          <div className={`${styles.status} ${getStatusClass(currentStatus.status)}`}>
            <span className={styles.statusIndicator} />
            <span className={styles.statusText}>
              {getStatusMessage(currentStatus.status)}
            </span>
          </div>
        </div>
        <div className={styles.metadata}>
          <div className={styles.metadataItem}>
            <span className={styles.label}>Environment:</span>
            <span className={styles.value}>{currentStatus.environment}</span>
          </div>
          <div className={styles.metadataItem}>
            <span className={styles.label}>Region:</span>
            <span className={styles.value}>{currentStatus.region}</span>
          </div>
          <div className={styles.metadataItem}>
            <span className={styles.label}>Last Updated:</span>
            <span className={styles.value}>
              {formatDateTime(currentStatus.timestamp)}
            </span>
          </div>
          <div className={styles.metadataItem}>
            <span className={styles.label}>Check ID:</span>
            <span className={styles.value}>{currentStatus.checkId}</span>
          </div>
          <div className={styles.metadataItem}>
            <span className={styles.label}>Execution Time:</span>
            <span className={styles.value}>{currentStatus.executionTime}ms</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatusOverview;