// File: frontend/src/pages/InvestorPitch/components/slides/SavingHasNoLanguageSlide/SavingHasNoLanguageSlide.js

import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import { ANIMATION_VARIANTS } from '../../../constants/animations';
import styles from './SavingHasNoLanguageSlide.module.css';

const SavingHasNoLanguageSlide = () => {
  const { t } = useLanguage();

  // Reference to the video element
  const videoRef = useRef(null);
  const containerRef = useRef(null);

  // Track video loading and playback states
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [videoError, setVideoError] = useState(false);

  // Detect mobile devices
  useEffect(() => {
    const checkMobile = () => {
      const mobile = window.innerWidth <= 768;
      setIsMobile(mobile);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  // Handle video loading
  const handleVideoLoaded = () => {
    setVideoLoaded(true);
    setVideoError(false);
  };

  // Handle video error
  const handleVideoError = () => {
    console.error('Video failed to load');
    setVideoError(true);
    setVideoLoaded(false);
  };

  // Handle play/pause toggle
  const togglePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play().catch(err => {
          console.warn('Playback failed:', err);
        });
      }
    }
  };

  // Update play state when video plays or pauses
  const handlePlay = () => setIsPlaying(true);
  const handlePause = () => setIsPlaying(false);

  // Intersection Observer to play video when in view
  useEffect(() => {
    if (!videoRef.current || !containerRef.current) return;

    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting && videoLoaded) {
          videoRef.current.play().catch(err => {
            console.warn('Autoplay blocked:', err);
          });
        } else if (!entry.isIntersecting && isPlaying) {
          videoRef.current.pause();
        }
      });
    }, options);

    observer.observe(containerRef.current);

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, [videoLoaded, isPlaying]);

  // Set up video once loaded
  useEffect(() => {
    if (videoRef.current && videoLoaded) {
      videoRef.current.loop = true;
      
      // Add event listeners for play/pause state
      videoRef.current.addEventListener('play', handlePlay);
      videoRef.current.addEventListener('pause', handlePause);
      
      return () => {
        if (videoRef.current) {
          videoRef.current.removeEventListener('play', handlePlay);
          videoRef.current.removeEventListener('pause', handlePause);
        }
      };
    }
  }, [videoLoaded]);

  // Determine video source based on device
  const getVideoSource = () => {
    const baseUrl = "https://allegory-public.s3.ca-central-1.amazonaws.com/pitch";
    
    if (isMobile) {
      return `${baseUrl}/pitch-products-saving-calculator-mobile.mp4`;
    }
    
    return `${baseUrl}/pitch-products-saving-calculator.mp4`;
  };

  return (
    <motion.div
      className={styles.container}
      variants={ANIMATION_VARIANTS.stagger.container}
      initial="hidden"
      animate="visible"
      ref={containerRef}
    >
      {/* Header */}
      <motion.div
        className={styles.header}
        variants={ANIMATION_VARIANTS.fadeIn}
      >
        <h2 className={styles.title}>
          {t('investor_pitch.saving_has_no_language.title', `'Saving' Has No Language`)}
        </h2>
        <p className={styles.subtitle}>
          {t(
            'investor_pitch.saving_has_no_language.subtitle',
            'From auto to life to home and commercial, AI-1 seamlessly serves customers across 10 countries in 6 languages while guaranteeing at least a 25% discount.'
          )}
        </p>
      </motion.div>

      {/* Video Container */}
      <motion.div 
        className={styles.videoContainer}
        variants={ANIMATION_VARIANTS.fadeIn}
      >
        <div className={styles.videoWrapper} onClick={togglePlayPause}>
          {videoError ? (
            <div className={styles.errorMessage}>
              <p>Video could not be loaded</p>
              <button 
                className={styles.retryButton}
                onClick={(e) => {
                  e.stopPropagation();
                  if (videoRef.current) {
                    videoRef.current.load();
                  }
                  setVideoError(false);
                }}
              >
                Retry
              </button>
            </div>
          ) : (
            <>
              <video
                ref={videoRef}
                className={styles.video}
                src={getVideoSource()}
                muted
                playsInline
                preload="auto"
                poster="https://allegory-public.s3.ca-central-1.amazonaws.com/pitch/pitch-products-saving-calculator-poster.jpg"
                onLoadedData={handleVideoLoaded}
                onError={handleVideoError}
              />
              {!videoLoaded && (
                <div className={styles.loadingOverlay}>
                  <div className={styles.loadingSpinner} />
                </div>
              )}
              {videoLoaded && !isPlaying && (
                <div className={styles.playOverlay}>
                  <div className={styles.playButton}>
                    <svg width="48" height="48" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8 5V19L19 12L8 5Z" fill="white"/>
                    </svg>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </motion.div>

      {/* CTA */}
      <motion.div
        className={styles.ctaSection}
        variants={ANIMATION_VARIANTS.fadeIn}
      >
        <motion.a
          href="https://allegory.app/quote"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.ctaButton}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.97 }}
        >
          {t('investor_pitch.saving_has_no_language.cta', 'Save 25% with Allegory Now')}
        </motion.a>
      </motion.div>
    </motion.div>
  );
};

export default SavingHasNoLanguageSlide;