// File: frontend/src/pages/AnimationGeneration/AnimationGeneration.js

import React, { useEffect } from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import { useSEO } from '../../contexts/SEOContext';
import HeroSection from './HeroSection';
import InsuranceManagementSection from './InsuranceManagementSection';
import MigrationSection from './MigrationSection';
import ProductDevSection from './ProductDevSection';
import IntroSection from './IntroSection';
import InsuranceModel from './InsuranceModel';
import styles from './AnimationGeneration.module.css';

const AnimationGeneration = () => {
  const { t, language, supportedLanguages } = useLanguage();
  const { seoData, updateSEO } = useSEO();

  useEffect(() => {
    const structuredData = {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      '@id': 'https://www.allegory.app',
      name: 'Allegory Insurance Technologies',
      url: 'https://www.allegory.app',
      logo: 'https://www.allegory.app/logo.png',
      sameAs: [
        'https://www.linkedin.com/company/allegory',
        'https://twitter.com/allegory',
        'https://facebook.com/allegory'
      ],
      description: t('home_page_description'),
      address: {
        '@type': 'PostalAddress',
        addressCountry: 'CA'
      },
      offers: {
        '@type': 'AggregateOffer',
        availableLanguage: supportedLanguages.map(lang => ({
          '@type': 'Language',
          name: lang
        }))
      },
      potentialAction: {
        '@type': 'ViewAction',
        target: [
          {
            '@type': 'EntryPoint',
            urlTemplate: 'https://www.allegory.app/sign-up',
            actionPlatform: [
              'http://schema.org/DesktopWebPlatform',
              'http://schema.org/MobileWebPlatform'
            ]
          }
        ]
      }
    };

    const softwareData = {
      '@context': 'https://schema.org',
      '@type': 'SoftwareApplication',
      name: 'Allegory Insurance Platform',
      applicationCategory: 'BusinessApplication',
      operatingSystem: 'Web-based',
      offers: {
        '@type': 'Offer',
        price: '0',
        priceCurrency: 'USD'
      }
    };

    updateSEO({
      title: t('home_page_title'),
      description: t('home_page_description'),
      keywords: t('home_page_keywords'),
      canonicalUrl: `https://www.allegory.app/${language}`,
      ogImage: 'https://www.allegory.app/images/og-home.jpg',
      structuredData: [structuredData, softwareData]
    });
  }, [updateSEO, t, language, supportedLanguages]);

  const baseUrl = 'https://www.allegory.app';

  return (
    <>
      <div className={`${styles.homePage} ${styles.fadeIn}`}>
        <InsuranceModel />
        <HeroSection />
        {/* <InsuranceManagementSection /> */}
        {/* <ProductDevSection /> */}
        {/* <MigrationSection /> */}
        {/* <IntroSection /> */}
      </div>
    </>
  );
};

export default AnimationGeneration;