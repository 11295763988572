// File: frontend/src/pages/RouteSafe/RouteSafe.js

import React from 'react';
import styles from './RouteSafe.module.css';
import { useAuth } from '../../contexts/AuthContextV2';
import { useLanguage } from '../../contexts/LanguageContext';
import PageTitle from '../../components/layout/DashboardLayout/PageTitle';

const RouteSafe = () => {
  const { user } = useAuth();
  const { t } = useLanguage();

  return (
    <div className={styles.RouteSafeContainer}>
      <PageTitle title={t('route_safe')} />
    </div>
  );
};

export default RouteSafe;