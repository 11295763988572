// File: frontend/src/components/layout/DashboardLayout/PageTitleControls.js

import React from 'react';
import { useLanguage } from '../../../contexts/LanguageContext';
import styles from './PageTitleControls.module.css';

const PageTitleControls = ({ children }) => {
  return <div className={styles.controlsContainer}>{children}</div>;
};

const ControlGroup = ({ label, children }) => {
  return (
    <div className={styles.controlGroup}>
      {label && <div className={styles.controlLabel}>{label}</div>}
      <div className={styles.controlContent}>{children}</div>
    </div>
  );
};

const Select = ({ options, value, onChange, placeholder }) => {
  const { t } = useLanguage();
  
  return (
    <div className={styles.selectWrapper}>
      <select 
        className={styles.select}
        value={value}
        onChange={onChange}
      >
        {placeholder && <option value="">{placeholder}</option>}
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <div className={styles.selectArrow}>
        <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 1L5 5L9 1" stroke="#6E7179" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </div>
    </div>
  );
};

const Button = ({ icon, label, onClick, primary, disabled }) => {
  return (
    <button 
      className={`${styles.button} ${primary ? styles.primaryButton : ''}`}
      onClick={onClick}
      disabled={disabled}
    >
      {icon && (
        <span className={styles.buttonIcon}>
          {typeof icon === 'string' ? (
            <img src={icon} alt="" />
          ) : (
            icon
          )}
        </span>
      )}
      {label}
    </button>
  );
};

const RefreshButton = ({ onClick, loading }) => {
  const { t } = useLanguage();
  
  return (
    <button 
      className={styles.refreshButton}
      onClick={onClick}
      disabled={loading}
    >
      <span className={`${styles.refreshIcon} ${loading ? styles.spinning : ''}`}>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.6667 8.00001C14.6667 11.68 11.68 14.6667 8.00001 14.6667C4.32001 14.6667 2.00001 11.68 2.00001 8.00001C2.00001 4.32001 4.32001 1.33334 8.00001 1.33334" stroke="#14151A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M14.6667 2.66667L8.00001 9.33334" stroke="#14151A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M11.3333 2.66667H14.6667V6.00001" stroke="#14151A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </span>
      {t('refresh')}
    </button>
  );
};

const ExportButton = ({ onClick }) => {
  const { t } = useLanguage();
  
  return (
    <button 
      className={styles.exportButton}
      onClick={onClick}
    >
      <span className={styles.exportIcon}>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.00001 11.3333V2.66666" stroke="#14151A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M3.33334 6.66666L8.00001 2L12.6667 6.66666" stroke="#14151A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M2 14H14" stroke="#14151A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </span>
      {t('export')}
    </button>
  );
};

PageTitleControls.ControlGroup = ControlGroup;
PageTitleControls.Select = Select;
PageTitleControls.Button = Button;
PageTitleControls.RefreshButton = RefreshButton;
PageTitleControls.ExportButton = ExportButton;

export default PageTitleControls;