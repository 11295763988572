// File: frontend/src/contexts/AuthContextV2.js

import React, { createContext, useContext, useState, useCallback, useMemo, useEffect } from 'react';
import { authService } from '../services/auth';
import { userService } from '../services/userService';
import { tokenService, TokenStatus } from '../services/tokenService';
import { formatName } from '../utils/validation';

// =============================================
// Constants
// =============================================
export const AUTH_FLOW_STATES = {
  SIGNUP: {
    INITIAL: 'signup_initial',
    MOBILE_VERIFICATION: 'signup_mobile_verification',
    EMAIL_VERIFICATION: 'signup_email_verification',
    COMPLETE_SIGNUP: 'signup_complete',
    ACCOUNT_CREATION: 'signup_account_creation'
  },
  SIGNIN: {
    INITIAL: 'signin_initial',
    OTP_VERIFICATION: 'signin_otp_verification',
    CHANGE_PASSWORD: 'signin_change_password'
  }
};

const AuthContext = createContext();

// =============================================
// Base Flow Management Hook
// =============================================
const useAuthFlow = () => {
  const [flowState, setFlowState] = useState(null);
  const [flowData, setFlowData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const updateFlowState = useCallback((newState, data = null) => {
    setFlowState(newState);
    if (data) {
      setFlowData(prev => ({ ...prev, ...data }));
    }
  }, []);

  const clearFlow = useCallback(() => {
    setFlowState(null);
    setFlowData(null);
    setError(null);
  }, []);

  return {
    flowState,
    flowData,
    loading,
    error,
    updateFlowState,
    clearFlow,
    setLoading,
    setError
  };
};

// =============================================
// Sign In Flow Hook
// =============================================
const useSignInFlow = (authFlow, sessionManager) => {
  const initiateSignIn = useCallback(async (identifier) => {
    authFlow.setLoading(true);
    authFlow.setError(null);
    try {
        const normalizedIdentifier = typeof identifier === 'string' 
            ? identifier.toLowerCase() 
            : identifier.identifier.toLowerCase();

        const response = await authService.initiateSignin(normalizedIdentifier);
        
        authFlow.updateFlowState(AUTH_FLOW_STATES.SIGNIN.OTP_VERIFICATION, {
            identifier: normalizedIdentifier,
            channels: response.channels,
            timestamp: Date.now()
        });
        
        return response;
    } catch (error) {
        console.log('SignIn Error:', error);
        if (error.code === 'HTTP_403' && error.message === 'Account not approved') {
            console.log('Redirecting to account creation...');
            authFlow.updateFlowState(AUTH_FLOW_STATES.SIGNUP.ACCOUNT_CREATION, {
                identifier: typeof identifier === 'string' 
                    ? identifier.toLowerCase() 
                    : identifier.identifier.toLowerCase()
            });
            return { 
                redirectTo: 'account-creation',
                error: error
            };
        }
        authFlow.setError(error);
        throw error;
    } finally {
        authFlow.setLoading(false);
    }
  }, [authFlow]);

  const verifySignIn = useCallback(async (identifier, otp) => {
    authFlow.setLoading(true);
    authFlow.setError(null);
    try {
        const response = await authService.verifySignin(identifier, otp);
        
        if (!response.tokens || !response.user) {
            throw new Error('Invalid sign in response');
        }

        if (response.user.status === 'pending_approval') {
            authFlow.updateFlowState(AUTH_FLOW_STATES.SIGNUP.ACCOUNT_CREATION);
            return { redirectTo: 'account-creation', user: response.user };
        }

        // Initialize session AND just store tokens
        const tokenStored = await tokenService.setTokens(response.tokens);
        if (!tokenStored) {
            throw new Error('Failed to store tokens');
        }

        // Set up token refresh
        tokenService.setupTokenRefresh(
          response.tokens,
          authService.refreshTokens,
          sessionManager.signOut
        );

        // Store user data first (we need to ensure this happens synchronously)
        const userObj = {
          ...response.user,
          email_address: response.user.email_address.toLowerCase()
        };

        // Update user and trigger auth status update
        await sessionManager.setUser(userObj);
        sessionManager.updateAuthStatus(); // Add this line
        
        // Clear flow state after successful authentication
        authFlow.clearFlow();

        // Return the data, let parent component handle session initialization
        return { 
          success: true,
          user: userObj,
          tokens: response.tokens 
        };

    } catch (error) {
        authFlow.setError(error);
        throw error;
    } finally {
        authFlow.setLoading(false);
    }
  }, [authFlow, sessionManager]);

  const resendSignInOTP = useCallback(async (identifier) => {
    authFlow.setLoading(true);
    authFlow.setError(null);
    try {
      const response = await authService.resendSigninOTP(identifier);
      
      // Update timestamp in flow data after resend
      if (authFlow.flowData?.identifier === identifier) {
        authFlow.updateFlowState(authFlow.flowState, {
          ...authFlow.flowData,
          timestamp: Date.now()
        });
      }
      
      return response;
    } catch (error) {
      authFlow.setError(error);
      throw error;
    } finally {
      authFlow.setLoading(false);
    }
  }, [authFlow]);

  return {
    initiateSignIn,
    verifySignIn,
    resendSignInOTP
  };
};

// =============================================
// Sign Up Flow Hook
// =============================================
const useSignUpFlow = (authFlow) => {
  const initiateSignUp = useCallback(async (userData) => {
    authFlow.setLoading(true);
    authFlow.setError(null);
    try {
      const formattedData = {
        email_address: userData.email_address.toLowerCase(),
        first_name: formatName(userData.first_name),
        last_name: formatName(userData.last_name),
        mobile_phone_number: `${userData.country_phone_code}${userData.area_code}${userData.phone_number}`.replace(/\s+/g, ''),
        country_phone_code: userData.country_phone_code,
        area_code: userData.area_code,
        phone_number: userData.phone_number
      };

      const response = await authService.initiateSignup(formattedData);
      
      authFlow.updateFlowState(AUTH_FLOW_STATES.SIGNUP.MOBILE_VERIFICATION, {
        ...userData,  // Keep all original form data
        ...formattedData,  // Add formatted data
        signup_token: response.signup_token,
        mobile_phone_number: response.mobile_phone_number
      });

      return response;
    } catch (error) {
      console.error('Signup error:', error);
      authFlow.setError(error);
      throw error;
    } finally {
      authFlow.setLoading(false);
    }
  }, [authFlow]);

  const verifySignUpMobile = useCallback(async (otp) => {
    authFlow.setLoading(true);
    authFlow.setError(null);
    try {
      const { mobile_phone_number } = authFlow.flowData;
      if (!mobile_phone_number) {
        throw new Error('Mobile number not found');
      }
  
      const response = await authService.verifyMobile({
        mobile_phone_number,
        otp
      });
  
      // Preserve all previous data when moving to complete signup
      authFlow.updateFlowState(AUTH_FLOW_STATES.SIGNUP.COMPLETE_SIGNUP, {
        ...authFlow.flowData,  // Keep all previous data
        mobile_verified: true,
        verification_status: response.verification_status
      });
  
      return response;
    } catch (error) {
      authFlow.setError(error);
      throw error;
    } finally {
      authFlow.setLoading(false);
    }
  }, [authFlow]);

  const resendSignUpOTP = useCallback(async () => {
    authFlow.setLoading(true);
    authFlow.setError(null);
    try {
      const { mobile_phone_number, signup_token } = authFlow.flowData;
      if (!mobile_phone_number) {
        throw new Error('Mobile number not found');
      }

      // Call verifyMobile without OTP to trigger resend
      const response = await authService.verifyMobile({
        mobile_phone_number,
        signup_token
      });

      // Update timestamp in flow data after resend
      authFlow.updateFlowState(authFlow.flowState, {
        ...authFlow.flowData,
        timestamp: Date.now()
      });

      return response;
    } catch (error) {
      authFlow.setError(error);
      throw error;
    } finally {
      authFlow.setLoading(false);
    }
  }, [authFlow]);

  const completeSignUp = useCallback(async (companyData) => {
    authFlow.setLoading(true);
    authFlow.setError(null);
    try {
      const { mobile_phone_number, email_address } = authFlow.flowData;
      if (!mobile_phone_number) {
        throw new Error('Missing required data');
      }

      // Ensure all required fields are present and properly formatted
      const payload = {
        mobile_phone_number,
        organization_name: companyData.organization_name.trim(),
        organization_size_id: parseInt(companyData.organization_size_id),
        primary_language_id: companyData.primary_language_id,
        dataset_region_id: parseInt(companyData.dataset_region_id)
      };

      const response = await authService.completeSignup(payload);

      // Store email for verification page
      authFlow.updateFlowState(AUTH_FLOW_STATES.SIGNUP.EMAIL_VERIFICATION, {
        ...authFlow.flowData,
        email_address
      });

      return response;
    } catch (error) {
      authFlow.setError(error);
      throw error;
    } finally {
      authFlow.setLoading(false);
    }
  }, [authFlow]);

  const verifyEmail = useCallback(async (token) => {
    authFlow.setLoading(true);
    authFlow.setError(null);
    try {
        if (!token) {
            throw new Error('Verification token is required');
        }

        const response = await authService.verifyEmail(token);
        
        // Handle already verified case
        if (response.message === 'Email already verified') {
            authFlow.updateFlowState(AUTH_FLOW_STATES.SIGNUP.ACCOUNT_CREATION, {
                ...authFlow.flowData,
                email_verified: true,
                verification_status: 'verified',
                verification_timestamp: new Date().toISOString()
            });
            return {
                isFullyVerified: true,
                alreadyVerified: true
            };
        }

        // Handle successful verification
        if (response.isFullyVerified) {
            authFlow.updateFlowState(AUTH_FLOW_STATES.SIGNUP.ACCOUNT_CREATION, {
                ...authFlow.flowData,
                email_verified: true,
                verification_status: 'verified',
                verification_timestamp: new Date().toISOString()
            });
        } else {
            // Handle case where email is verified but account needs more steps
            authFlow.updateFlowState(AUTH_FLOW_STATES.SIGNUP.EMAIL_VERIFICATION, {
                ...authFlow.flowData,
                email_verified: true,
                verification_status: 'pending',
                verification_timestamp: new Date().toISOString()
            });
        }

        return {
            ...response,
            verification_timestamp: new Date().toISOString()
        };

    } catch (error) {
        // Special handling for already verified case
        if (error.code === 'HTTP_400' && error.message === 'Email already verified') {
            authFlow.updateFlowState(AUTH_FLOW_STATES.SIGNUP.ACCOUNT_CREATION, {
                ...authFlow.flowData,
                email_verified: true,
                verification_status: 'verified',
                verification_timestamp: new Date().toISOString()
            });
            return {
                isFullyVerified: true,
                alreadyVerified: true
            };
        }

        authFlow.setError(error);
        throw error;
    } finally {
        authFlow.setLoading(false);
    }
  }, [authFlow]);

  const resendVerificationEmail = useCallback(async (email) => {
    authFlow.setLoading(true);
    authFlow.setError(null);
    try {
      if (!email) {
        throw new Error('Email address is required');
      }

      const response = await authService.resendVerificationEmail(email);
      
      // Update flow state with new timestamp
      authFlow.updateFlowState(AUTH_FLOW_STATES.SIGNUP.EMAIL_VERIFICATION, {
        ...authFlow.flowData,
        email_address: email,
        verification_sent_at: Date.now()
      });

      return response;
    } catch (error) {
      authFlow.setError(error);
      throw error;
    } finally {
      authFlow.setLoading(false);
    }
  }, [authFlow]);

  return {
    initiateSignUp,
    verifySignUpMobile,
    resendSignUpOTP,
    completeSignUp,
    verifyEmail,
    resendVerificationEmail
  };
};

// =============================================
// Session Management Hook
// =============================================
const useSessionManagement = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  // Add this to track token status changes
  const [authStatus, setAuthStatus] = useState(() => ({
    isAuthenticated: tokenService.hasValidTokens(),
    lastUpdate: Date.now()
  }));

  // Update isAuthenticated when tokens change
  const updateAuthStatus = useCallback(() => {
    setAuthStatus({
        isAuthenticated: tokenService.hasValidTokens(),
        lastUpdate: Date.now()
    });
  }, []);

  // Use this instead of the useMemo
  const isAuthenticated = authStatus.isAuthenticated;

  // Update user setting to trigger auth status update
  const setUserAndAuth = useCallback(async (userData) => {
    setUser(userData);
    if (userData) {
        await userService.setUser(userData);
        updateAuthStatus();
    }
  }, [updateAuthStatus]);

  // This effect now handles session restoration and token refresh setup
  useEffect(() => {
    const restoreSession = async () => {
        try {
            const tokens = tokenService.getTokens();
            console.log('Session restoration:', {
                tokenStatus: tokens?.status,
                hasRefreshToken: !!tokens?.refresh_token
            });

            if (tokens?.status === TokenStatus.NEEDS_REFRESH && tokens.refresh_token) {
                try {
                    const cognitoSub = tokenService.getCognitoSub();
                    const newTokens = await authService.refreshTokens(tokens.refresh_token, cognitoSub);
                    if (newTokens) {
                        tokenService.setTokens(newTokens);
                        // Set up refresh timer for the new tokens
                        tokenService.setupTokenRefresh(
                          newTokens, 
                          authService.refreshTokens,
                          handleSessionEnd
                        );
                        const storedUser = userService.getUser();
                        if (storedUser) {
                            setUser(storedUser);
                        }
                    }
                } catch (error) {
                    console.error('Token refresh failed:', error);
                    await handleSessionEnd();
                }
            } else if (tokens?.status === TokenStatus.VALID) {
                // Set up refresh timer for valid tokens
                tokenService.setupTokenRefresh(
                  tokens,
                  authService.refreshTokens,
                  handleSessionEnd
                );
                const storedUser = userService.getUser();
                if (storedUser) {
                    setUser(storedUser);
                }
            }
        } catch (error) {
            console.error('Session restoration error:', error);
            await handleSessionEnd();
        } finally {
            setLoading(false);
        }
    };

    restoreSession();
  }, []);

  const handleSessionEnd = useCallback(async () => {
    setLoading(true);
    try {
      await authService.signOut();
    } catch (error) {
      console.error('Sign out error:', error);
    } finally {
      tokenService.clearTokens();
      userService.clearUser();
      setUser(null);
      updateAuthStatus();
      setLoading(false);
    }
  }, [updateAuthStatus]);

  const signOut = useCallback(async () => {
      setLoading(true);
      try {
          await authService.signOut();
      } catch (error) {
          console.error('Sign out failed:', error);
      } finally {
          userService.clearUser();
          tokenService.clearTokens();
          setUser(null);
          updateAuthStatus();
          setLoading(false);
      }
  }, [updateAuthStatus]);

  return {
      user,
      setUser: setUserAndAuth, // Use the new function
      loading,
      signOut: handleSessionEnd,
      isAuthenticated,
      updateAuthStatus
  };
};

// =============================================
// Main Auth Provider
// =============================================
export const AuthProvider = ({ children }) => {
  const authFlow = useAuthFlow();
  const sessionManager = useSessionManagement();
  const signInFlow = useSignInFlow(authFlow, sessionManager);
  const signUpFlow = useSignUpFlow(authFlow);

  const contextValue = useMemo(() => ({
    // Session state
    user: sessionManager.user,
    isAuthenticated: sessionManager.isAuthenticated,
    signOut: sessionManager.signOut,
    
    // Flow state
    flowState: authFlow.flowState,
    flowData: authFlow.flowData,
    loading: authFlow.loading || sessionManager.loading,
    error: authFlow.error,
    
    // Sign In methods
    initiateSignIn: signInFlow.initiateSignIn,
    verifySignIn: signInFlow.verifySignIn,
    resendSignInOTP: signInFlow.resendSignInOTP,
    
    // Sign Up methods
    initiateSignUp: signUpFlow.initiateSignUp,
    verifySignUpMobile: signUpFlow.verifySignUpMobile,  // Renamed
    resendSignUpOTP: signUpFlow.resendSignUpOTP,        // New
    completeSignUp: signUpFlow.completeSignUp,          // New
    verifyEmail: signUpFlow.verifyEmail, 
    resendVerificationEmail: signUpFlow.resendVerificationEmail, 
    
    // Utility methods
    updateFlowState: authFlow.updateFlowState,
    clearFlow: authFlow.clearFlow,
    clearError: () => authFlow.setError(null)
  }), [
    sessionManager,
    authFlow,
    signInFlow,
    signUpFlow
  ]);

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

// =============================================
// Custom Hook
// =============================================
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export default AuthContext;