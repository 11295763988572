// File: frontend/src/contexts/QueryCacheContext.js

import React, { createContext, useState, useContext } from 'react';

const QueryCacheContext = createContext();

export const QueryCacheProvider = ({ children }) => {
  const [cache, setCache] = useState({});

  const setCacheItem = (key, data, expirationTime = 5 * 60 * 1000) => {
    setCache(prev => ({
      ...prev,
      [key]: {
        data,
        expiration: Date.now() + expirationTime
      }
    }));
  };

  const getCacheItem = (key) => {
    const item = cache[key];
    if (item && item.expiration > Date.now()) {
      return item.data;
    }
    return null;
  };

  return (
    <QueryCacheContext.Provider value={{ setCacheItem, getCacheItem }}>
      {children}
    </QueryCacheContext.Provider>
  );
};

export const useQueryCache = () => {
  const context = useContext(QueryCacheContext);
  if (context === undefined) {
    throw new Error('useQueryCache must be used within a QueryCacheProvider');
  }
  return context;
};