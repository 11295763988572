// File: frontend/src/pages/AutoInsight/components/LatestReports/index.js

import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import styles from './LatestReports.module.css';

const LatestReports = () => {
  const { t } = useLanguage();
  const [loading, setLoading] = useState(true);
  const [reports, setReports] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(6);

  useEffect(() => {
    const fetchReports = async () => {
      setLoading(true);
      
      try {
        // This would be replaced with an actual API call
        // const response = await reportsService.getLatestReports();
        
        // Mock data for now
        await new Promise(resolve => setTimeout(resolve, 500));
        
        const mockReports = [
          {
            id: 1,
            name: 'VIN Portfolio 2023',
            date: 'Jan 25, 2024',
            time: '05:20 AM',
            status: 'in_progress',
            downloadUrl: null
          },
          {
            id: 2,
            name: '2024-Q1 ADAS Report',
            date: 'June 25, 2024',
            time: '1:25 PM',
            status: 'ready',
            downloadUrl: '/reports/adas-q1-2024.csv'
          },
          {
            id: 3,
            name: '2024-H1 ADAS & Loss Ratio Report',
            date: 'July 1, 2024',
            time: '12:00 PM',
            status: 'in_progress',
            downloadUrl: null
          },
          {
            id: 4,
            name: '2025 Renewal Data',
            date: 'July 1, 2024',
            time: '12:00 PM',
            status: 'ready',
            downloadUrl: '/reports/renewal-data-2025.csv'
          },
          {
            id: 5,
            name: '2024-Q4 ADAS Report',
            date: 'March 19, 2025',
            time: '1:25 PM',
            status: 'ready',
            downloadUrl: '/reports/adas-q4-2025.csv'
          },
        ];
        
        setReports(mockReports);
      } catch (error) {
        console.error('Error fetching reports:', error);
      } finally {
        setLoading(false);
      }
    };
    
    fetchReports();
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2 className={styles.title}>{t('latest_reports')}</h2>
        <div className={styles.searchContainer}>
          <input
            type="text"
            className={styles.searchInput}
            placeholder={t('search_reports')}
          />
        </div>
      </div>
      
      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>{t('uploaded_csv_name')}</th>
              <th>{t('date_and_time')}</th>
              <th>{t('status')}</th>
              <th>{t('download')}</th>
            </tr>
          </thead>
          <tbody>
            {reports.map((report) => (
              <tr key={report.id}>
                <td>{report.name}</td>
                <td>
                  <div>{report.date}</div>
                  <div>{report.time}</div>
                </td>
                <td>
                  <span className={`${styles.statusBadge} ${styles[report.status]}`}>
                    {report.status === 'in_progress' ? t('in_progress') : t('ready')}
                  </span>
                </td>
                <td>
                  {report.status === 'ready' ? (
                    <a href={report.downloadUrl} className={styles.downloadLink}>
                      {t('download_data')}
                    </a>
                  ) : (
                    <span className={styles.pendingMessage}>
                      {t('we_will_send_you_an_email')}
                    </span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      
      <div className={styles.pagination}>
        <button 
          className={styles.paginationArrow}
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          &lt;
        </button>
        
        {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
          <button
            key={page}
            className={`${styles.pageNumber} ${currentPage === page ? styles.activePage : ''}`}
            onClick={() => handlePageChange(page)}
          >
            {page}
          </button>
        ))}
        
        <button 
          className={styles.paginationArrow}
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          &gt;
        </button>
      </div>
    </div>
  );
};

export default LatestReports;