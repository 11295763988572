// File: frontend/src/hooks/useQuoteDetail.js

import { useState, useEffect } from 'react';
import { quoteService } from '../services/quote';

const useQuoteDetail = (quoteId) => {
  const [quote, setQuote] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchQuoteDetails = async () => {
      if (!quoteId) {
        setLoading(false);
        return;
      }

      setLoading(true);
      setError(null);

      try {
        const quoteData = await quoteService.getQuote(quoteId);
        setQuote(quoteData);
      } catch (err) {
        console.error('Error fetching quote details:', err);
        
        if (err.isQuoteNotFoundError) {
          setError('Quote not found. Please check the quote ID and try again.');
        } else {
          setError('Failed to load quote details. Please try again later.');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchQuoteDetails();
  }, [quoteId]);

  return { quote, loading, error };
};

export default useQuoteDetail;