// File: frontend/src/utils/mapHelpers.js

export const parseRouteData = (tripEventsData) => {
    if (!tripEventsData || !tripEventsData.tripFilteredLocationsList || tripEventsData.tripFilteredLocationsList.length === 0) {
      console.log('Invalid tripEventsData structure:', tripEventsData);
      return [];
    }
  
    const locationData = tripEventsData.tripFilteredLocationsList[0];
    const latitudes = locationData.filteredLat.replace(/[{}]/g, '').split(',').map(Number);
    const longitudes = locationData.filteredLong.replace(/[{}]/g, '').split(',').map(Number);
  
    const coordinates = latitudes.map((lat, index) => ({
      lat,
      lng: longitudes[index]
    })).filter(coord => !isNaN(coord.lat) && !isNaN(coord.lng));
  
    console.log('Parsed coordinates:', coordinates);
  
    return coordinates;
  };