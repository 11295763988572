// File: frontend/src/utils/chartHelpers.js

export const hexToRGBA = (hex, transparency) => {
    let r = parseInt(hex.slice(1, 3), 16);
    let g = parseInt(hex.slice(3, 5), 16);
    let b = parseInt(hex.slice(5, 7), 16);
    let alpha = transparency;
  
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };

  export const formatPercentage = (value) => {
    return `${value.toFixed(2)}%`;
  };

  export const getColorScale = () => {
    return [
      [0.0, '#38C793'],
      [0.2, '#F2AE40'],
      [0.4, '#F17B2C'],
      [0.6, '#DF1C41'],
      [0.8, '#14151A'],
      [1.0, '#14151A']
    ];
  };
  
  export const formatChartData = (data) => {
    // Add any common data formatting logic here
    return data;
  };
  
  export const getChartColors = () => {
    // Define and return a set of colors for charts
    return ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b', '#e377c2', '#7f7f7f', '#bcbd22', '#17becf'];
  };
