// File: frontend/src/components/form/NewsletterForm/NewsletterForm.js

import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLanguage } from '../../../contexts/LanguageContext';
import { useNewsletter } from '../../../hooks/useNewsletter';
import { validateEmail } from '../../../utils/validation';
import FormGroup from '../FormGroup/FormGroup';
import Input from '../Input/Input';
import Button from '../../Button/Button';
import Alert from '../../Alert/Alert';
import styles from './NewsletterForm.module.css';

const NewsletterForm = ({ onSuccess }) => {
  const { t } = useLanguage();
  const { subscribe, isLoading, error, success, clearError } = useNewsletter();
  
  const [formData, setFormData] = useState({
    emailAddress: '',
    firstName: '',
    lastName: ''
  });
  const [showNameFields, setShowNameFields] = useState(false);
  const [showEmailError, setShowEmailError] = useState(false);

  // Memoize the email validation result
  const isEmailValid = useMemo(() => {
    return validateEmail(formData.emailAddress.trim());
  }, [formData.emailAddress]);

  const handleChange = (field) => (value) => {
    setFormData(prev => ({ ...prev, [field]: value }));
    // Clear email error when user starts typing again
    if (field === 'emailAddress') {
      setShowEmailError(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate email before proceeding
    if (!formData.emailAddress || !isEmailValid) {
      setShowEmailError(true);
      return;
    }
    
    try {
      if (!showNameFields) {
        // First step - just validate email and show name fields
        setShowNameFields(true);
        return;
      }

      // Second step - submit everything
      const result = await subscribe(formData);
      
      // Reset form
      setFormData({ emailAddress: '', firstName: '', lastName: '' });
      setShowNameFields(false);
      setShowEmailError(false); // Clear email error
      
      // Notify parent component
      if (onSuccess) {
        onSuccess(result);
      }
    } catch (err) {
      // Error handling is done in the hook
    }
  };

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      {error && (
        <Alert 
          type="error" 
          titleKey="newsletter.error.title"
          messageKey={`newsletter.errors.${error}`}
          onClose={clearError}
        />
      )}
      
      {success ? (
        <Alert 
          type="success" 
          titleKey="newsletter.success.title"
          messageKey="newsletter.success.message"
        />
      ) : (
        <>
          <FormGroup>
            <Input
              id="newsletter-email"
              type="email"
              labelKey="your_email_address"
              value={formData.emailAddress}
              onChange={handleChange('emailAddress')}
              required
              placeholderKey="auth.signIn.emailPlaceholder"
              error={showEmailError && (!formData.emailAddress || !isEmailValid)}
              errorKey={showEmailError && (!formData.emailAddress || !isEmailValid) ? 
                "newsletter.errors.invalid_email" : undefined}
            />
          </FormGroup>

          {showNameFields && (
            <>
              <FormGroup>
                <Input
                  id="newsletter-firstname"
                  type="text"
                  labelKey="auth.signUp.firstNameLabel"
                  value={formData.firstName}
                  onChange={handleChange('firstName')}
                  placeholderKey="auth.signUp.firstNamePlaceholder"
                />
              </FormGroup>
              
              <FormGroup>
                <Input
                  id="newsletter-lastname"
                  type="text"
                  labelKey="auth.signUp.lastNameLabel"
                  value={formData.lastName}
                  onChange={handleChange('lastName')}
                  placeholderKey="auth.signUp.lastNamePlaceholder"
                />
              </FormGroup>
            </>
          )}

          <Button 
            type="submit" 
            variant="primary" 
            size="medium" 
            isLoading={isLoading}
            showArrow
          >
            {t(showNameFields ? 'subscribe' : 'auth.common.continue')}
          </Button>
        </>
      )}
    </form>
  );
};

NewsletterForm.propTypes = {
  onSuccess: PropTypes.func
};

export default NewsletterForm;