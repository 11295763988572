// File: frontend/src/pages/InvestorPitch/components/slides/CompetitiveAdvantageSlide/DifferentiatorCard.js

import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import * as LucideIcons from 'lucide-react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from './DifferentiatorCard.module.css';

const DifferentiatorCard = ({ title, comparison, description, icon, metrics, gradient }) => {
  const Icon = LucideIcons[icon];
  const { t } = useLanguage();

  return (
    <motion.div 
      className={styles.container}
      whileHover={{ scale: 1.02 }}
      transition={{ type: 'spring', stiffness: 300, damping: 30 }}
      aria-label={t('investor_pitch.differentiator.aria.card')}
    >
      <div 
        className={styles.header}
        style={{ background: gradient }}
      >
        <div className={styles.iconWrapper}>
          <Icon 
            className={styles.icon} 
            aria-label={t('investor_pitch.differentiator.aria.feature_icon')}
          />
        </div>
        <h4 className={styles.title}>{title}</h4>
        <p className={styles.comparison}>{comparison}</p>
      </div>

      <div className={styles.content}>
        <p className={styles.description}>{description}</p>
        
        <div 
          className={styles.metrics}
          aria-label={t('investor_pitch.differentiator.aria.metrics_section')}
        >
          {metrics.map((metric, index) => (
            <div key={index} className={styles.metric}>
              <div className={styles.metricLabel}>{metric.label}</div>
              <div className={styles.metricValue}>{metric.value}</div>
            </div>
          ))}
        </div>
      </div>
    </motion.div>
  );
};

DifferentiatorCard.propTypes = {
  title: PropTypes.string.isRequired,
  comparison: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  metrics: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  gradient: PropTypes.string.isRequired,
};

export default DifferentiatorCard;