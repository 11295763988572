// File: frontend/src/pages/InvestorPitch/components/slides/MarketingSlides/InsuranceFutureSlide/DistributionModelSlide.js

import React, { useState, useCallback } from 'react';
import { motion } from 'framer-motion';
import { 
  ComposedChart, Area, Line, XAxis, YAxis, CartesianGrid, 
  Tooltip, Legend, ResponsiveContainer, ReferenceLine 
} from 'recharts';
import { ANIMATION_VARIANTS } from '../../../../constants/animations';
import styles from './DistributionModelSlide.module.css';

// Distribution Parameters from your code
const DISTRIBUTION_PARAMS = {
    TOTAL_EXPENSE_AMOUNT: 1074e6, // Will be calculated based on market data
    TOLERANCE_PERCENTAGE: 0.005,

    PEAK_1_AMOUNT: 2500,
    PEAK_1_PROPORTION: 0.30,
    PEAK_1_SPREAD: 0.4,

    PEAK_2_AMOUNT: 10000,
    PEAK_2_PROPORTION: 0.30,
    PEAK_2_SPREAD: 0.45,

    PEAK_3_AMOUNT: 30000,
    PEAK_3_PROPORTION: 0.20,
    PEAK_3_SPREAD: 0.5,

    TAIL_THRESHOLD: 100000,
    TAIL_PROPORTION: 0.20,
    TAIL_SPREAD: 500000,
    TAIL_SHAPE: 0.3,

    AI_IMPACTS: {
        SEGMENT_1: { REDUCTION: 0.999, COLOR: 'var(--color-blue-210)' },
        SEGMENT_2: { REDUCTION: 0.80, COLOR: 'var(--color-blue-310)' },
        SEGMENT_3: { REDUCTION: 0.75, COLOR: 'var(--color-blue-410)' },
        SEGMENT_4: { REDUCTION: 0.50, COLOR: 'var(--color-blue-510)' }
    },

    MAX_X_VALUE: 1000000,
    GRANULARITY_LEVELS: {
        VERY_FINE: { threshold: 10000, step: 500 },
        FINE: { threshold: 50000, step: 1000 },
        MEDIUM: { threshold: 100000, step: 2500 },
        COARSE: { threshold: 25000000, step: 50000 }
    }
};

const formatCurrency = (value) => {
    if (value >= 1000000) {
        const millions = value / 1000000;
        return `$${millions < 10 ? millions.toFixed(1) : Math.round(millions)}M`;
    } else if (value >= 1000) {
        const thousands = value / 1000;
        return `$${thousands < 10 ? thousands.toFixed(1) : Math.round(thousands)}k`;
    }
    return `$${Math.round(value)}`;
};

const DistributionModelSlide = () => {
    // Mixture model function with safeguards
    const mixtureModel = useCallback((x, params, aiReduction = 0) => {
        if (x <= 0) return 0;
        const scale = 1 - aiReduction;

        try {
            // First peak (around $5K)
            const peak1 = DISTRIBUTION_PARAMS.PEAK_1_PROPORTION * 
                        (1/(x * DISTRIBUTION_PARAMS.PEAK_1_SPREAD * Math.sqrt(2*Math.PI))) * 
                        Math.exp(-Math.pow(Math.log(x) - Math.log(DISTRIBUTION_PARAMS.PEAK_1_AMOUNT), 2)/
                        (2 * Math.pow(DISTRIBUTION_PARAMS.PEAK_1_SPREAD, 2)));

            // Second peak (around $15K)
            const peak2 = DISTRIBUTION_PARAMS.PEAK_2_PROPORTION * 
                        (1/(x * DISTRIBUTION_PARAMS.PEAK_2_SPREAD * Math.sqrt(2*Math.PI))) * 
                        Math.exp(-Math.pow(Math.log(x) - Math.log(DISTRIBUTION_PARAMS.PEAK_2_AMOUNT), 2)/
                        (2 * Math.pow(DISTRIBUTION_PARAMS.PEAK_2_SPREAD, 2)));

            // Third peak (around $100K)
            const peak3 = DISTRIBUTION_PARAMS.PEAK_3_PROPORTION * 
                        (1/(x * DISTRIBUTION_PARAMS.PEAK_3_SPREAD * Math.sqrt(2*Math.PI))) * 
                        Math.exp(-Math.pow(Math.log(x) - Math.log(DISTRIBUTION_PARAMS.PEAK_3_AMOUNT), 2)/
                        (2 * Math.pow(DISTRIBUTION_PARAMS.PEAK_3_SPREAD, 2)));

            // Heavy tail component
            const tailComponent = x > DISTRIBUTION_PARAMS.TAIL_THRESHOLD ? 
                                DISTRIBUTION_PARAMS.TAIL_PROPORTION * (1/DISTRIBUTION_PARAMS.TAIL_SPREAD) * 
                                Math.pow(1 + (DISTRIBUTION_PARAMS.TAIL_SHAPE*(x-DISTRIBUTION_PARAMS.TAIL_THRESHOLD))/
                                DISTRIBUTION_PARAMS.TAIL_SPREAD, -(1/DISTRIBUTION_PARAMS.TAIL_SHAPE + 1)) : 0;

            const result = (peak1 + peak2 + peak3 + tailComponent) * scale;
            return isNaN(result) ? 0 : result;
        } catch (error) {
            console.error('Error in mixtureModel:', error);
            return 0;
        }
    }, []);

    // Calculate scaling factor
    const calculateScalingFactor = useCallback(() => {
        let totalArea = 0;
        let x = 100;

        while (x <= DISTRIBUTION_PARAMS.MAX_X_VALUE) {
            let dx;
            if (x < DISTRIBUTION_PARAMS.GRANULARITY_LEVELS.VERY_FINE.threshold) {
                dx = DISTRIBUTION_PARAMS.GRANULARITY_LEVELS.VERY_FINE.step;
            } else if (x < DISTRIBUTION_PARAMS.GRANULARITY_LEVELS.FINE.threshold) {
                dx = DISTRIBUTION_PARAMS.GRANULARITY_LEVELS.FINE.step;
            } else if (x < DISTRIBUTION_PARAMS.GRANULARITY_LEVELS.MEDIUM.threshold) {
                dx = DISTRIBUTION_PARAMS.GRANULARITY_LEVELS.MEDIUM.step;
            } else {
                dx = DISTRIBUTION_PARAMS.GRANULARITY_LEVELS.COARSE.step;
            }

            const y = mixtureModel(x, null, 0);
            if (!isNaN(y)) {
                totalArea += y * x * dx;
            }
            x += dx;
        }

        return totalArea > 0 ? DISTRIBUTION_PARAMS.TOTAL_EXPENSE_AMOUNT / totalArea : 1;
    }, [mixtureModel]);

    const [scalingFactor] = useState(calculateScalingFactor());

    // Generate data points
    const generateData = useCallback(() => {
        const data = [];
        let x = DISTRIBUTION_PARAMS.GRANULARITY_LEVELS.VERY_FINE.step;
        
        while (x <= DISTRIBUTION_PARAMS.MAX_X_VALUE) {
            const baselineFreq = mixtureModel(x, null, 0) * scalingFactor;
            
            const segment1Impact = baselineFreq * (DISTRIBUTION_PARAMS.AI_IMPACTS.SEGMENT_1.REDUCTION);
            const segment2Impact = baselineFreq * (DISTRIBUTION_PARAMS.AI_IMPACTS.SEGMENT_2.REDUCTION);
            const segment3Impact = baselineFreq * (DISTRIBUTION_PARAMS.AI_IMPACTS.SEGMENT_3.REDUCTION);
            const segment4Impact = baselineFreq * (DISTRIBUTION_PARAMS.AI_IMPACTS.SEGMENT_4.REDUCTION);

            if (!isNaN(baselineFreq)) {
                data.push({
                    x,
                    baseline: baselineFreq,
                    aiImpact1: x <= DISTRIBUTION_PARAMS.PEAK_1_AMOUNT ? segment1Impact : 0,
                    aiImpact2: (x > DISTRIBUTION_PARAMS.PEAK_1_AMOUNT && x <= DISTRIBUTION_PARAMS.PEAK_2_AMOUNT) ? segment2Impact : 0,
                    aiImpact3: (x > DISTRIBUTION_PARAMS.PEAK_2_AMOUNT && x <= DISTRIBUTION_PARAMS.PEAK_3_AMOUNT) ? segment3Impact : 0,
                    aiImpact4: x > DISTRIBUTION_PARAMS.PEAK_3_AMOUNT ? segment4Impact : 0
                });
            }
            
            let step;
            if (x < DISTRIBUTION_PARAMS.GRANULARITY_LEVELS.VERY_FINE.threshold) {
                step = DISTRIBUTION_PARAMS.GRANULARITY_LEVELS.VERY_FINE.step;
            } else if (x < DISTRIBUTION_PARAMS.GRANULARITY_LEVELS.FINE.threshold) {
                step = DISTRIBUTION_PARAMS.GRANULARITY_LEVELS.FINE.step;
            } else if (x < DISTRIBUTION_PARAMS.GRANULARITY_LEVELS.MEDIUM.threshold) {
                step = DISTRIBUTION_PARAMS.GRANULARITY_LEVELS.MEDIUM.step;
            } else {
                step = DISTRIBUTION_PARAMS.GRANULARITY_LEVELS.COARSE.step;
            }
            x += step;
        }
        
        return data;
    }, [mixtureModel, scalingFactor]);

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className={styles.tooltip}>
                    <p className={styles.tooltipLabel}>Cost: {formatCurrency(label)}</p>
                    {payload.map((entry) => (
                        <p key={entry.name} style={{ color: entry.color }}>
                            {entry.name}: {Number(entry.value).toLocaleString(undefined, {
                                maximumFractionDigits: 2
                            })}
                        </p>
                    ))}
                </div>
            );
        }
        return null;
    };

    return (
        <motion.div
            className={styles.container}
            variants={ANIMATION_VARIANTS.stagger.container}
            initial="hidden"
            animate="visible"
        >
            <motion.div 
                className={styles.header}
                variants={ANIMATION_VARIANTS.fadeIn}
            >
                <h1 className={styles.title}>We have modelled all expense-bearing operations from 1 cent to $25M</h1>
                {/* <p className={styles.highlight}>
                    We have modelled all expense operations from $0 to $25M
                </p> */}

                <ResponsiveContainer width="100%" height={450}>
                    <ComposedChart
                        data={generateData()}
                        margin={{ top: 30, right: 30, left: 30, bottom: 30 }}
                        background="transparent"
                    >
                        <CartesianGrid 
                            strokeDasharray="3 3" 
                            stroke="rgba(255, 255, 255, 0.1)"
                            vertical={false}
                        />
                        {[
                            DISTRIBUTION_PARAMS.PEAK_1_AMOUNT,
                            DISTRIBUTION_PARAMS.PEAK_2_AMOUNT,
                            DISTRIBUTION_PARAMS.PEAK_3_AMOUNT
                        ].map((peak) => (
                            <ReferenceLine
                                key={peak}
                                x={peak}
                                stroke="rgba(255, 255, 255, 0.2)"
                                strokeDasharray="3 3"
                                label={{
                                    value: formatCurrency(peak),
                                    position: 'top',
                                    fill: 'var(--color-gray-300)',
                                    fontSize: 12,
                                    fontWeight: 500
                                }}
                            />
                        ))}
                        <XAxis
                            dataKey="x"
                            scale="log"
                            domain={['auto', 'auto']}
                            tickFormatter={formatCurrency}
                            stroke="var(--color-gray-300)"
                            tick={{ fill: 'var(--color-gray-300)', fontSize: 12 }}
                            axisLine={{ stroke: 'var(--color-gray-600)' }}
                            tickLine={{ stroke: 'var(--color-gray-600)' }}
                            label={{
                                value: 'Expense Amount in US Dollars',
                                position: 'bottom',
                                offset: 20,
                                style: {
                                    fill: '#9CA3AF',
                                    fontSize: 14,
                                    fontWeight: 500,
                                    opacity: 0.8
                                }
                            }}
                        />
                        <YAxis
                            stroke="var(--color-gray-300)"
                            tick={{ fill: 'var(--color-gray-300)', fontSize: 12 }}
                            axisLine={{ stroke: 'var(--color-gray-600)' }}
                            tickLine={{ stroke: 'var(--color-gray-600)' }}
                            name= 'Frequency'
                            label={{
                                value: 'Modelled Frequency',
                                angle: -90,
                                position: 'outsideRight',
                                offset: -10,
                                style: {
                                    fill: '#9CA3AF',
                                    fontSize: 14,
                                    fontWeight: 500,
                                    opacity: 0.8
                                }
                            }}
                        />
                        <Tooltip 
                            content={<CustomTooltip />}
                            cursor={{ fill: 'rgba(255, 255, 255, 0.05)' }}
                        />
                        <Legend 
                            verticalAlign="bottom"
                            iconType="circle"
                            iconSize={10}
                            wrapperStyle={{
                                paddingTop: '20px',
                                margin: '-50px 30px',
                                // backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                borderRadius: '8px',
                                padding: '12px'
                            }}
                            formatter={(value) => (
                                <span className="text-white font-medium text-sm pl-2">
                                    {value}
                                </span>
                            )}
                        />
                        <Line
                            type="monotone"
                            dataKey="baseline"
                            stroke="var(--color-red-310)"
                            name="OPEX Distribution"
                            dot={false}
                            strokeWidth={2}
                        />
                        <Area
                            type="monotone"
                            dataKey="aiImpact1"
                            fill="var(--color-blue-210)"
                            stroke="none"
                            fillOpacity={0.4}
                            name="Simple Operations Impact"
                        />
                        <Area
                            type="monotone"
                            dataKey="aiImpact2"
                            fill="var(--color-blue-310)"
                            stroke="none"
                            fillOpacity={0.4}
                            name="Medium Complexity Impact"
                        />
                        <Area
                            type="monotone"
                            dataKey="aiImpact3"
                            fill="var(--color-blue-410)"
                            stroke="none"
                            fillOpacity={0.4}
                            name="High Complexity Impact"
                        />
                        <Area
                            type="monotone"
                            dataKey="aiImpact4"
                            fill="var(--color-blue-510)"
                            stroke="none"
                            fillOpacity={0.4}
                            name="Strategic Operations Impact"
                        />
                    </ComposedChart>
                </ResponsiveContainer>
            </motion.div>
        </motion.div>
    );
};

export default DistributionModelSlide;