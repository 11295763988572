// File: frontend/src/components/UploadSection/UploadSection.js

import React, { useState } from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import UploadArea from './UploadArea';
import UploadAreaFile from './UploadAreaFile';
import Alert from '../Alert/Alert';
import styles from './UploadSection.module.css';

const UploadSection = ({ labelKey, acceptedFileTypes, onFileChange, error: externalError }) => {
  const { t } = useLanguage();
  const [file, setFile] = useState(null);
  const [internalError, setInternalError] = useState(null);

  const handleFileChange = (selectedFile) => {
    if (selectedFile) {
      // Validate file type
      const fileExtension = `.${selectedFile.name.split('.').pop().toLowerCase()}`;
      if (!acceptedFileTypes.includes(fileExtension)) {
        setInternalError(t('uploadArea.errors.invalidFileType'));
        return;
      }

      // Validate file size (5MB)
      if (selectedFile.size > 5 * 1024 * 1024) {
        setInternalError(t('uploadArea.errors.fileTooLarge'));
        return;
      }
    }

    setFile(selectedFile);
    setInternalError(null);
    onFileChange(selectedFile);
  };

  const handleFileRemove = () => {
    setFile(null);
    setInternalError(null);
    onFileChange(null);
  };

  const error = externalError || internalError;

  return (
    <div className={styles.uploadSection}>
      <label className={styles.label}>{t(labelKey)}</label>
      {file ? (
        <UploadAreaFile
          file={file}
          onFileChange={handleFileChange}
          onFileRemove={handleFileRemove}
          acceptedFileTypes={acceptedFileTypes}
        />
      ) : (
        <UploadArea 
          onFileChange={handleFileChange} 
          acceptedFileTypes={acceptedFileTypes}
        />
      )}
      {error && (
        <Alert
          type="error"
          title={t('uploadArea.errors.title')}
          message={error}
          onClose={() => setInternalError(null)}
        />
      )}
    </div>
  );
};

export default UploadSection;