// File: frontend/src/pages/AIAgents/components/CategorySection.js

import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { useLanguage } from '../../../contexts/LanguageContext';
import AgentCard from './AgentCard';
import styles from './CategorySection.module.css';

const CategorySection = ({ id, title, description, agents, index }) => {
  const { t } = useLanguage();

  return (
    <motion.section
      className={styles.container}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: index * 0.2 }}
    >
      <div className={styles.header}>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.description}>{description}</p>
      </div>
      <div className={styles.agentsGrid}>
        {agents.map((agent, agentIndex) => (
          <AgentCard
            key={agent.id}
            {...agent}
            index={agentIndex}
          />
        ))}
      </div>
    </motion.section>
  );
};

CategorySection.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  agents: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    capabilities: PropTypes.arrayOf(PropTypes.string).isRequired,
    gradient: PropTypes.string.isRequired,
  })).isRequired,
  index: PropTypes.number.isRequired,
};

export default CategorySection;