// File: src/contexts/LanguageContext.js

import React, { createContext, useState, useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Import configurations
import {
  SUPPORTED_LOCALES,
  DEFAULT_LOCALE,
  isLocalizablePath,
  getLocaleFromPath,
  addLocaleToPath,
  getDefaultLocaleForLanguage
} from '../config/i18n.config';

// Import translations (keep your existing imports)
import deTranslations from '../locales/de.json';
import enTranslations from '../locales/en.json';
import esTranslations from '../locales/es.json';
import frTranslations from '../locales/fr.json';
import nlTranslations from '../locales/nl.json';
import trTranslations from '../locales/tr.json';

import deCapabilities from '../locales/capabilities_de.json';
import enCapabilities from '../locales/capabilities_en.json';
import esCapabilities from '../locales/capabilities_es.json';
import frCapabilities from '../locales/capabilities_fr.json';
import nlCapabilities from '../locales/capabilities_nl.json';
import trCapabilities from '../locales/capabilities_tr.json';

// Keep your existing translations object
const translations = {
  'de-de': { ...deTranslations, capabilities: deCapabilities },
  'en-ca': { ...enTranslations, capabilities: enCapabilities },
  'en-gb': { ...enTranslations, capabilities: enCapabilities },
  'en-us': { ...enTranslations, capabilities: enCapabilities },
  'es-es': { ...esTranslations, capabilities: esCapabilities },
  'es-mx': { ...esTranslations, capabilities: esCapabilities },
  'fr-ca': { ...frTranslations, capabilities: frCapabilities },
  'fr-fr': { ...frTranslations, capabilities: frCapabilities },
  'nl-nl': { ...nlTranslations, capabilities: nlCapabilities },
  'tr-tr': { ...trTranslations, capabilities: trCapabilities }
};

const LanguageContext = createContext();

const getBrowserLanguage = () => {
  if (typeof window === 'undefined') return DEFAULT_LOCALE;
  
  const browserLang = navigator.language || navigator.userLanguage;
  const shortLang = browserLang.split('-')[0];
  
  // Check if the exact browser language is supported
  if (SUPPORTED_LOCALES[browserLang]) {
    return browserLang;
  }
  
  // Get the default locale for this language
  const defaultForLanguage = getDefaultLocaleForLanguage(shortLang);
  if (defaultForLanguage) {
    return defaultForLanguage;
  }
  
  return DEFAULT_LOCALE;
};

// Keep your existing interpolateString function
const interpolateString = (str, params) => {
  if (!params || typeof str !== 'string') return str;
  return str.replace(/\{\{(\w+)\}\}/g, (_, key) => {
    return params[key] !== undefined ? params[key] : `{{${key}}}`;
  });
};

export const LanguageProvider = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  
  // Initialize language with a more resilient approach
  const [language, setLanguage] = useState(() => {
    // If we have router context, check URL
    if (location) {
      const pathLocale = getLocaleFromPath(location.pathname);
      if (pathLocale) return pathLocale;
    }
    
    // Check localStorage
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage && translations[savedLanguage]) return savedLanguage;
    
    // Use browser language
    return getBrowserLanguage();
  });
  
  const [currentTranslations, setCurrentTranslations] = useState(translations[language]);

  // Effect for URL and language synchronization
  useEffect(() => {
    if (!location || !navigate) return;
    
    const currentLocale = getLocaleFromPath(location.pathname);
    
    if (isLocalizablePath(location.pathname) && !currentLocale) {
      const newPath = addLocaleToPath(location.pathname, language);
      navigate(newPath, { replace: true });
    }
    else if (currentLocale && currentLocale !== language) {
      setLanguage(currentLocale);
    }
  }, [location, language, navigate]);

  useEffect(() => {
    setCurrentTranslations(translations[language]);
    if (typeof window !== 'undefined') {
      localStorage.setItem('language', language);
      document.documentElement.lang = SUPPORTED_LOCALES[language].languageCode;
    }
  }, [language]);

  const changeLanguage = (newLanguage) => {
    if (!translations[newLanguage]) {
      console.warn(`Language ${newLanguage} is not supported.`);
      return;
    }

    if (location && navigate && isLocalizablePath(location.pathname)) {
      const newPath = addLocaleToPath(location.pathname, newLanguage);
      navigate(newPath);
    }
    
    setLanguage(newLanguage);
  };

  const translate = (key, paramsOrCategory = null, category = 'default') => {
    try {
      let translatedText;
      let params = null;

      if (typeof paramsOrCategory === 'string') {
        category = paramsOrCategory;
      } else {
        params = paramsOrCategory;
      }
      
      if (category === 'capabilities') {
        const [section, itemKey] = key.split('.');
        translatedText = currentTranslations?.capabilities?.[section]?.[itemKey] || key;
      } else {
        translatedText = currentTranslations?.[key] || key;
      }

      return params ? interpolateString(translatedText, params) : translatedText;
    } catch (error) {
      console.error('Translation error:', error);
      return key;
    }
  };

  const value = {
    language,
    changeLanguage,
    t: translate,
    supportedLanguages: Object.keys(translations),
    currentLanguage: language,
    isLocalizablePath,
    getLocaleFromPath,
    addLocaleToPath
  };

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};

export default LanguageContext;