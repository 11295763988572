// File: frontend/src/pages/InvestorPitch/components/slides/GrowthSlide/GrowthIndicator.js

import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from './GrowthIndicator.module.css';

const YEAR_PERCENTAGES = {
  '1': 1.5,
  '2': 2.3,
  '3': 3.1
};

const GrowthIndicator = ({ year, value, color }) => {
  const { t } = useLanguage();
  
  // Extract year number from the year string (assuming format "Year X" or "X")
  const yearNumber = year.replace(/\D/g, '');
  const percentage = YEAR_PERCENTAGES[yearNumber] || 1.5; // Default to 1.5% if year not found
  
  return (
    <motion.div 
      className={styles.container}
      whileHover={{ scale: 1.05 }}
      transition={{ type: 'spring', stiffness: 300, damping: 30 }}
    >
      <div className={styles.header}>
        <div className={styles.year}>
          {t('investor_pitch.growth.year_label', { year })}
        </div>
        <div className={styles.metrics}>
          <div className={styles.target}>
            <div className={styles.value} style={{ color }}>
              {value}
            </div>
            <div className={styles.label}>
              {t('investor_pitch.growth.target_label')}
            </div>
          </div>
          {/* <div className={styles.revenue}>
            <div className={styles.percentage} style={{ color }}>
              {t('investor_pitch.growth.revenue_percentage', { percentage })}%
            </div>
            <div className={styles.projectedValue} style={{ color }}>
              {calculateProjectedValue(value, percentage)}
            </div>
            <div className={styles.label}>
              {t('investor_pitch.growth.revenue_label')}
            </div>
          </div> */}
        </div>
      </div>
    </motion.div>
  );
};

// Helper function to calculate projected value and convert to billions
const calculateProjectedValue = (targetValue, percentage) => {
  // Convert target value to numeric (billions)
  let numericValue;
  if (targetValue.includes('T')) {
    // Convert trillion to billion
    numericValue = parseFloat(targetValue.replace(/[^0-9.]/g, '')) * 1000;
  } else {
    numericValue = parseFloat(targetValue.replace(/[^0-9.]/g, ''));
  }
  
  // Calculate projected value (as percentage of target)
  const projectedValue = (numericValue * (percentage / 100));
  
  // Format the value
  if (projectedValue >= 1000) {
    return `$${(projectedValue / 1000).toFixed(1)}T`;
  } else {
    return `$${projectedValue.toFixed(1)}B`;
  }
};

GrowthIndicator.propTypes = {
  year: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default GrowthIndicator;