// File: frontend/src/pages/AI1/components/ChatContainer/ChatContainer.js

import React, { useRef, useEffect, useState } from 'react';
import styles from './ChatContainer.module.css';
import MessageBubble from '../MessageBubble/MessageBubble';

const ChatContainer = ({ messages, isLoading }) => {
  const containerRef = useRef(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  
  useEffect(() => {
    // Mark initial load as complete after first render
    setIsInitialLoad(false);
  }, []);

  useEffect(() => {
    if (!messages.length) return;
    
    const lastMessage = messages[messages.length - 1];
    const isUserMessage = lastMessage.role === 'user';
    
    if (isUserMessage) {
      // Immediately scroll up to show just the bottom portion of the message
      const container = containerRef.current;
      container.style.scrollBehavior = 'smooth';
      // Scroll up more aggressively - only show about 10% of viewport height from bottom
      container.scrollTop = container.scrollHeight - (container.clientHeight * 0.1);
    } else {
      // For AI responses, smooth scroll to bottom
      containerRef.current?.scrollTo({
        top: containerRef.current.scrollHeight,
        behavior: 'smooth'
      });
    }
  }, [messages]);

  return (
    <div className={`${styles.container} chatContainer`} ref={containerRef}>
      <div className={styles.messagesWrapper}>
        {messages.map((message, index) => (
          <div
            key={index}
            className={`
              ${styles.messageWrapper}
              ${message.role === 'user' && index === messages.length - 1 ? styles.fastSlideUp : ''}
            `}
          >
            <MessageBubble
              message={message}
              isUser={message.role === 'user'}
              isTyping={message.isTyping}
              isHistorical={isInitialLoad}
            />
          </div>
        ))}
        {isLoading && (
          <div className={styles.loadingIndicator}>
            <span className={styles.dot}></span>
            <span className={styles.dot}></span>
            <span className={styles.dot}></span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatContainer;