// File: frontend/src/components/form/auth/SignUpForm.js

import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLanguage } from '../../../contexts/LanguageContext';
import { useSignUp } from '../../../contexts/SignUpContext';
import { useAuth, AUTH_FLOW_STATES } from '../../../contexts/AuthContextV2';
import Input from '../Input/Input';
import PhoneInput from '../PhoneInput/PhoneInput';
import FormGroup from '../FormGroup/FormGroup';
import Dropdown from '../Dropdown/Dropdown';
import { ENUMS } from '../../../config/constants';
import styles from './SignUpForm.module.css';

const SignUpForm = () => {
  const { t } = useLanguage();
  const { 
    formData, 
    updateFormData, 
    errors,
    validateFormData 
  } = useSignUp();

  const { flowState } = useAuth();

  // useEffect(() => {
  //   validateFormData(formData, flowState);
  // }, [validateFormData, formData, flowState]);

  const handleChange = useCallback((field, value) => {
    updateFormData({ [field]: value });
  }, [updateFormData]);

  const handlePhoneChange = useCallback((phoneData) => {
    updateFormData({
      country_phone_code: phoneData.country_phone_code,
      area_code: phoneData.area_code,
      phone_number: phoneData.phone_number
    });
  }, [updateFormData]);

  const renderInitialStep = () => (
    <FormGroup>
      <Input
        id="email_address"
        labelKey="auth.signUp.companyEmailLabel"
        placeholderKey="auth.signUp.companyEmailPlaceholder"
        required
        value={formData.email_address}
        onChange={(value) => handleChange('email_address', value)}
        error={errors.email_address}
      />
      <Input
        id="first_name"
        labelKey="auth.signUp.firstNameLabel"
        placeholderKey="auth.signUp.firstNamePlaceholder"
        required
        value={formData.first_name}
        onChange={(value) => handleChange('first_name', value)}
        error={errors.first_name}
      />
      <Input
        id="last_name"
        labelKey="auth.signUp.lastNameLabel"
        placeholderKey="auth.signUp.lastNamePlaceholder"
        required
        value={formData.last_name}
        onChange={(value) => handleChange('last_name', value)}
        error={errors.last_name}
      />
      <PhoneInput
        id="phone"
        labelKey="auth.signUp.phoneLabel"
        required
        hintKey="phone_verification_hint"
        value={{
          country_phone_code: formData.country_phone_code,
          area_code: formData.area_code || '',
          phone_number: formData.phone_number || ''
        }}
        onChange={handlePhoneChange}
        errors={{
          country_phone_code: errors.country_phone_code,
          area_code: errors.area_code,
          phone_number: errors.phone_number
        }}
      />
    </FormGroup>
  );

  const renderCompanyStep = () => (
    <FormGroup>
      <Input
        id="organization_name"
        labelKey="auth.signUp.organizationNameLabel"
        placeholderKey="auth.signUp.organizationNamePlaceholder"
        required
        value={formData.organization_name}
        onChange={(value) => handleChange('organization_name', value)}
        error={errors.organization_name}
        maxLength={100}
      />
      <Dropdown
        id="organization_size_id"
        labelKey="auth.signUp.employeesLabel"
        placeholderKey="auth.signUp.employeesPlaceholder"
        required
        options={Object.entries(ENUMS.ORGANIZATION_SIZE).map(([label], index) => ({
          value: ENUMS.ORGANIZATION_SIZE[label].toString(),
          labelKey: `auth.signUp.organizationSizeOptions.${index}.label`,
          label: t(`auth.signUp.organizationSizeOptions.${index}.label`)
        }))}
        onChange={(value) => handleChange('organization_size_id', value)}
        value={formData.organization_size_id}
        error={errors.organization_size_id}
      />
      <Dropdown
        id="dataset_region_id"
        labelKey="auth.signUp.datasetRegionLabel"
        placeholderKey="auth.signUp.datasetRegionPlaceholder"
        hintKey="auth.signUp.datasetRegionHint"
        required
        options={Object.entries(ENUMS.DATASET_REGION).map(([label], index) => ({
          value: ENUMS.DATASET_REGION[label].toString(),
          labelKey: `auth.signUp.datasetRegionOptions.${index}.label`,
          label: t(`auth.signUp.datasetRegionOptions.${index}.label`)
        }))}
        onChange={(value) => handleChange('dataset_region_id', value)}
        value={formData.dataset_region_id}
        error={errors.dataset_region_id}
      />
    </FormGroup>
  );

  const renderCurrentStep = () => {
    switch (flowState) {
      case AUTH_FLOW_STATES.SIGNUP.INITIAL:
        return renderInitialStep();
      case AUTH_FLOW_STATES.SIGNUP.COMPLETE_SIGNUP:
        return renderCompanyStep();
      default:
        return renderInitialStep();
    }
  };

  return (
    <div className={styles.signUpForm}>
      <div className={styles.formContent}>
        <form 
          className={styles.formFields}
          onSubmit={(e) => e.preventDefault()}
        >
          {renderCurrentStep()}
        </form>
      </div>
    </div>
  );
};

SignUpForm.propTypes = {
  className: PropTypes.string
};

export default React.memo(SignUpForm);