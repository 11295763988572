// File: frontend/src/pages/UMS/UserPortfolio/components/PortfolioOverview/Users.js

import React, { useEffect, useState } from 'react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import styles from './PortfolioOverview.module.css';

// Helper function to convert hex to rgba
const hexToRGBA = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const Users = () => {
  const { t } = useLanguage();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Simulating API call to fetch data
    setLoading(true);
    
    // Replace with actual API call
    setTimeout(() => {
      try {
        // Mock data - replace with actual data from your API
        const mockData = [
          {
            chartAxisDetails: {
              x1_axis_name: "Jan",
              y1_axis_value: 120,
              y1_axis_name: "Active Users",
              y1_axis_hex: "#4CAF50",
              y1_axis_transparency: 0.8,
              y2_axis_value: 80,
              y2_axis_name: "Inactive Users",
              y2_axis_hex: "#FFC107",
              y2_axis_transparency: 0.8,
              y3_axis_value: 20,
              y3_axis_name: "New Users",
              y3_axis_hex: "#2196F3",
              y3_axis_transparency: 0.8
            }
          },
          {
            chartAxisDetails: {
              x1_axis_name: "Feb",
              y1_axis_value: 150,
              y2_axis_value: 70,
              y3_axis_value: 30
            }
          },
          {
            chartAxisDetails: {
              x1_axis_name: "Mar",
              y1_axis_value: 180,
              y2_axis_value: 60,
              y3_axis_value: 40
            }
          },
          {
            chartAxisDetails: {
              x1_axis_name: "Apr",
              y1_axis_value: 220,
              y2_axis_value: 50,
              y3_axis_value: 45
            }
          },
          {
            chartAxisDetails: {
              x1_axis_name: "May",
              y1_axis_value: 250,
              y2_axis_value: 45,
              y3_axis_value: 50
            }
          },
          {
            chartAxisDetails: {
              x1_axis_name: "Jun",
              y1_axis_value: 280,
              y2_axis_value: 40,
              y3_axis_value: 55
            }
          }
        ];
        
        setData(mockData);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    }, 1000);
  }, []);

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <div className={styles.spinner}></div>
        <p>{t('loading_chart_data')}</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.errorContainer}>
        <i className="fas fa-exclamation-triangle"></i>
        <p>{t('error_loading_chart')}: {error}</p>
      </div>
    );
  }

  if (!data || data.length === 0) {
    return (
      <div className={styles.noDataContainer}>
        <p>{t('no_chart_data_available')}</p>
      </div>
    );
  }

  const firstEntryDetails = data[0].chartAxisDetails;

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={data}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="chartAxisDetails.x1_axis_name" />
        <YAxis label={{ value: t('number_of_users'), angle: -90, position: 'insideLeft' }} />
        <Tooltip />
        <Legend />
        <Bar 
          dataKey="chartAxisDetails.y1_axis_value" 
          stackId="a" 
          fill={hexToRGBA(firstEntryDetails.y1_axis_hex, firstEntryDetails.y1_axis_transparency)} 
          name={t(firstEntryDetails.y1_axis_name)}
        />
        <Bar 
          dataKey="chartAxisDetails.y2_axis_value" 
          stackId="a" 
          fill={hexToRGBA(firstEntryDetails.y2_axis_hex, firstEntryDetails.y2_axis_transparency)} 
          name={t(firstEntryDetails.y2_axis_name)}
        />
        <Bar 
          dataKey="chartAxisDetails.y3_axis_value" 
          stackId="a" 
          fill={hexToRGBA(firstEntryDetails.y3_axis_hex, firstEntryDetails.y3_axis_transparency)} 
          name={t(firstEntryDetails.y3_axis_name)}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default Users;