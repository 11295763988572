// File: frontend/src/pages/InvestorPitch/components/slides/InvestmentSlide/InvestmentSlide.js

import React from 'react';
import { motion } from 'framer-motion';
import * as LucideIcons from 'lucide-react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import FundingCard from './FundingCard';
import ProjectionCard from './ProjectionCard';
import { ANIMATION_VARIANTS } from '../../../constants/animations';
import styles from './InvestmentSlide.module.css';

const highlights = [
  'market_expansion',
  'product_lines',
  'partnerships'
];

const InvestmentSlide = () => {
  const { t } = useLanguage();

  const fundingAllocation = [
    {
      title: t('investor_pitch.investment.funding.rd.title'),
      amount: 80,
      icon: "Brain",
      details: [
        t('investor_pitch.investment.funding.rd.details.team', { amount: '$10M' }),
        t('investor_pitch.investment.funding.rd.details.sales', { amount: '$15M' }),
        t('investor_pitch.investment.funding.rd.details.benefits', { amount: '$5M' }),
        t('investor_pitch.investment.funding.rd.details.recruitment')
      ],
      gradient: "linear-gradient(135deg, var(--color-blue-310), var(--color-blue-510))"
    },
    {
      title: t('investor_pitch.investment.funding.infrastructure.title'),
      amount: 70,
      icon: "Server",
      details: [
        t('investor_pitch.investment.funding.infrastructure.details.hardware'),
        t('investor_pitch.investment.funding.infrastructure.details.cloud'),
        t('investor_pitch.investment.funding.infrastructure.details.datacenters'),
        t('investor_pitch.investment.funding.infrastructure.details.security')
      ],
      gradient: "linear-gradient(135deg, var(--color-green-310), var(--color-green-510))"
    },
    {
      title: t('investor_pitch.investment.funding.expansion.title'),
      amount: 50,
      icon: "Globe",
      details: [
        t('investor_pitch.investment.funding.expansion.details.sales', { countries: 10 }),
        t('investor_pitch.investment.funding.expansion.details.market'),
        t('investor_pitch.investment.funding.expansion.details.regulatory'),
        t('investor_pitch.investment.funding.expansion.details.partnerships')
      ],
      gradient: "linear-gradient(135deg, var(--color-orange-310), var(--color-orange-510))"
    }
  ];

  const projections = [
    {
      year: 1,
      gwp: "10B",
      revenuePercentage: 1.5,
      revenue: "150M",
      highlights: [
        'market_expansion',
        'product_lines',
        'partnerships'
      ],
      color: "var(--color-blue-310)"
    },
    {
      year: 2,
      gwp: "100B",
      revenuePercentage: 2.3,
      revenue: "2.3B",
      highlights: [
        'technology',
        'global_presence',
        'regulatory'
      ],
      color: "var(--color-green-310)"
    },
    {
      year: 3,
      gwp: "1T",
      revenuePercentage: 3.1,
      revenue: "31B",
      highlights: [
        'partnerships',
        'market_expansion',
        'technology'
      ],
      color: "var(--color-orange-310)"
    }
  ];

  return (
    <motion.div
      className={styles.container}
      variants={ANIMATION_VARIANTS.stagger.container}
      initial="hidden"
      animate="visible"
    >
      <motion.div 
        className={styles.header}
        variants={ANIMATION_VARIANTS.fadeIn}
      >
        <h2 className={styles.title}>
          {t('investor_pitch.investment.header.title', { amount: '$200 M' })}
        </h2>
        <p className={styles.subtitle}>
          {t('investor_pitch.investment.header.subtitle')}
        </p>
      </motion.div>

      <div className={styles.content}>
        <motion.section 
          className={styles.fundingSection}
          variants={ANIMATION_VARIANTS.stagger.container}
        >
          <h3 className={styles.sectionTitle}>
            {t('investor_pitch.investment.sections.allocation')}
          </h3>
          <div className={styles.fundingGrid}>
            {fundingAllocation.map((fund, index) => (
              <motion.div
                key={fund.title}
                variants={ANIMATION_VARIANTS.stagger.item}
                custom={index}
              >
                <FundingCard {...fund} />
              </motion.div>
            ))}
          </div>
        </motion.section>

        <motion.section 
          className={styles.projectionsSection}
          variants={ANIMATION_VARIANTS.stagger.container}
        >
          <h3 className={styles.sectionTitle}>
            {t('investor_pitch.investment.sections.projection')}
          </h3>
          <div className={styles.projectionsGrid}>
            {projections.map((projection, index) => (
              <motion.div
                key={projection.year}
                variants={ANIMATION_VARIANTS.stagger.item}
                custom={index}
              >
                <ProjectionCard
                  year={projection.year}
                  gwp={projection.gwp}
                  revenuePercentage={projection.revenuePercentage}
                  revenue={projection.revenue}
                  highlights={projection.highlights}
                  color={projection.color}              
                />
              </motion.div>
            ))}
          </div>
        </motion.section>
      </div>
    </motion.div>
  );
};

export default InvestmentSlide;