// /charts/v2/types/LineChart.js
import React from 'react';
import {
    LineChart as RechartsLineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ReferenceLine,
    ResponsiveContainer
} from 'recharts';
import ChartWrapper from '../core/ChartWrapper';
import ChartContainer from '../core/ChartContainer';
import colorSystem from '../../../../styles/colors/colorSystem';
import styles from './LineChart.module.css';

const CustomTooltip = ({ active, payload, label, format }) => {
    if (!active || !payload || !payload.length) return null;

    const formatValue = (value, format) => {
        switch (format) {
            case 'percentage':
                return `${value.toFixed(1)}%`;
            case 'number':
                return value.toLocaleString(undefined, { 
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2 
                });
            default:
                return value;
        }
    };

    return (
        <div className={styles.tooltip}>
            <p className={styles.tooltipLabel}>{label}</p>
            {payload.map((entry, index) => (
                <div key={index} className={styles.tooltipRow}>
                    <span className={styles.tooltipMetric} style={{ color: entry.color }}>
                        {entry.name}
                    </span>
                    <span className={styles.tooltipValue}>
                        {formatValue(entry.value, format)}
                    </span>
                </div>
            ))}
        </div>
    );
};

const LineChart = ({
    data,
    title,
    subtitle,
    format = 'number',
    height = 400,
    referenceLines = [],
    yAxis = {
        min: undefined,
        max: undefined
    },
    insights
}) => {
    const chartData = data.labels.map((label, index) => ({
        name: label,
        ...data.datasets.reduce((acc, dataset) => {
            acc[dataset.label] = dataset.data[index];
            return acc;
        }, {})
    }));

    const getStrokeColor = (index) => {
        const colors = colorSystem.sevenPiece.cool;
        return colors[index % colors.length];
    };

    return (
        <ChartWrapper title={title} subtitle={subtitle}>
            <div className={styles.lineChartContainer}>
                <ChartContainer height={height}>
                    <ResponsiveContainer width="100%" height="100%">
                        <RechartsLineChart
                            data={chartData}
                            margin={{ top: 20, right: 90, left: 10, bottom: 20 }}
                        >
                            <CartesianGrid 
                                strokeDasharray="3 3" 
                                vertical={false}
                                stroke="var(--color-border)"
                                strokeOpacity={0.5}
                            />
                            <XAxis
                                dataKey="name"
                                axisLine={false}
                                tickLine={false}
                                tick={{ 
                                    fill: 'var(--text-secondary)',
                                    fontSize: 12,
                                    fontFamily: 'var(--font-primary)'
                                }}
                                dy={8}
                            />
                            <YAxis
                                axisLine={false}
                                tickLine={false}
                                tick={{ 
                                    fill: 'var(--text-secondary)',
                                    fontSize: 12,
                                    fontFamily: 'var(--font-primary)'
                                }}
                                tickFormatter={(value) => 
                                    format === 'percentage' 
                                        ? `${value}%` 
                                        : value.toLocaleString()
                                }
                                domain={[
                                    yAxis.min ?? 'auto',
                                    yAxis.max ?? 'auto'
                                ]}
                                dx={-8}
                            />
                            <Tooltip 
                                content={<CustomTooltip format={format} />}
                                cursor={{ 
                                    stroke: 'var(--color-border)',
                                    strokeWidth: 1,
                                    strokeDasharray: '3 3'
                                }}
                            />
                            <Legend
                                verticalAlign="bottom"
                                height={36}
                                wrapperStyle={{
                                    paddingTop: 'var(--spacing-md)',
                                    fontSize: 'var(--font-size-sm)',
                                    fontFamily: 'var(--font-primary)'
                                }}
                            />
                            {referenceLines.map((line, index) => (
                                <ReferenceLine
                                    key={index}
                                    y={line.value}
                                    stroke={line.color}
                                    strokeDasharray="3 3"
                                    strokeWidth={1.5}
                                    label={{
                                        value: line.label,
                                        position: 'right',
                                        fill: line.color,
                                        fontSize: 12,
                                        fontFamily: 'var(--font-primary)',
                                        fontWeight: 500
                                    }}
                                />
                            ))}
                            {data.datasets.map((dataset, index) => (
                                <Line
                                    key={index}
                                    type="monotone"
                                    dataKey={dataset.label}
                                    stroke={dataset.borderColor || getStrokeColor(index)}
                                    strokeWidth={2}
                                    dot={{
                                        r: 4,
                                        strokeWidth: 2,
                                        fill: 'var(--color-white)'
                                    }}
                                    activeDot={{
                                        r: 6,
                                        strokeWidth: 2,
                                        fill: 'var(--color-white)'
                                    }}
                                    strokeDasharray={dataset.borderDash}
                                    fill={dataset.fill ? dataset.backgroundColor : undefined}
                                />
                            ))}
                        </RechartsLineChart>
                    </ResponsiveContainer>
                </ChartContainer>

                {insights && (
                    <div className={styles.insightsContainer}>
                        {insights.map((insight, index) => (
                            <div key={index} className={styles.insightCard}>
                                <h4 className={styles.insightTitle}>{insight.title}</h4>
                                <ul className={styles.insightList}>
                                    {insight.items.map((item, itemIndex) => (
                                        <li key={itemIndex} className={styles.insightItem}>
                                            {item}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </ChartWrapper>
    );
};

export default LineChart;