// src/components/SourceCode/AICodeAssistant.js
import React, { useState } from 'react';
import axios from 'axios';

const AICodeAssistant = () => {
  const [query, setQuery] = useState('');
  const [result, setResult] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post('/api/source-code/ai-assist', { query });
      setResult(response.data.result);
    } catch (error) {
      console.error('Error with AI code assistance:', error);
      setResult('An error occurred while processing your request.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Ask about code or request generation..."
        />
        <button type="submit" disabled={isLoading}>
          {isLoading ? 'Processing...' : 'Submit'}
        </button>
      </form>
      <pre>{result}</pre>
    </div>
  );
};

export default AICodeAssistant;