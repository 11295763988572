// File: frontend/src/pages/InvestorPitch/InvestorSeed.js

import React from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import PitchDeck from './PitchDeck';
import { getInvestorSeedSlides } from './configs/InvestorSeedConfig';

const InvestorSeed = () => {
  const { t } = useLanguage();
  const slides = getInvestorSeedSlides(t);
  
  return <PitchDeck slides={slides} deckId="investor-seed" />;
};

export default InvestorSeed;