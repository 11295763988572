// File: frontend/src/pages/TripInsight/components/LatestReports/index.js (updated)

import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import ReportGenerator from '../ReportGenerator'; // Import the new component
import styles from './LatestReports.module.css';

const LatestReports = () => {
  const { t } = useLanguage();
  const [loading, setLoading] = useState(true);
  const [reports, setReports] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(6);
  const [showReportGenerator, setShowReportGenerator] = useState(false); // New state for modal

  useEffect(() => {
    const fetchReports = async () => {
      setLoading(true);
      
      try {
        // This would be replaced with an actual API call
        // const response = await reportsService.getLatestReports();
        
        // Mock data for now
        await new Promise(resolve => setTimeout(resolve, 500));
        
        const mockReports = [
          {
            id: 1,
            name: 'Trip Summary Report - Q2 2023',
            date: 'July 5, 2023',
            time: '09:15 AM',
            status: 'ready',
            type: 'summary',
            downloadUrl: '/reports/trip-summary-q2-2023.csv'
          },
          {
            id: 2,
            name: 'Driver Behavior Analysis - June 2023',
            date: 'July 3, 2023',
            time: '2:30 PM',
            status: 'ready',
            type: 'behavior',
            downloadUrl: '/reports/driver-behavior-june-2023.csv'
          },
          {
            id: 3,
            name: 'Trip Scoring Metrics - Q2 2023',
            date: 'July 1, 2023',
            time: '11:45 AM',
            status: 'in_progress',
            type: 'scoring',
            downloadUrl: null
          },
          {
            id: 4,
            name: 'Route Analysis Report - June 2023',
            date: 'June 30, 2023',
            time: '4:20 PM',
            status: 'ready',
            type: 'route',
            downloadUrl: '/reports/route-analysis-june-2023.csv'
          },
          {
            id: 5,
            name: 'Speed Compliance Report - Q2 2023',
            date: 'June 28, 2023',
            time: '10:05 AM',
            status: 'in_progress',
            type: 'speed',
            downloadUrl: null
          }
        ];
        
        setReports(mockReports);
      } catch (error) {
        console.error('Error fetching reports:', error);
      } finally {
        setLoading(false);
      }
    };
    
    fetchReports();
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleGenerateReport = () => {
    setShowReportGenerator(true);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2 className={styles.title}>{t('latest_reports')}</h2>
        <div className={styles.headerActions}>
          <div className={styles.searchContainer}>
            <input
              type="text"
              className={styles.searchInput}
              placeholder={t('search_reports')}
            />
          </div>
          <button 
            className={styles.generateButton}
            onClick={handleGenerateReport}
          >
            {t('generate_report')}
          </button>
        </div>
      </div>
      
      {loading ? (
        <div className={styles.loading}>
          <div className={styles.loadingSpinner}></div>
          <p>{t('loading_reports')}</p>
        </div>
      ) : (
        <>
          <div className={styles.tableContainer}>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>{t('report_name')}</th>
                  <th>{t('type')}</th>
                  <th>{t('date_and_time')}</th>
                  <th>{t('status')}</th>
                  <th>{t('actions')}</th>
                </tr>
              </thead>
              <tbody>
                {reports.map((report) => (
                  <tr key={report.id}>
                    <td className={styles.reportName}>{report.name}</td>
                    <td>
                      <span className={`${styles.reportType} ${styles[report.type]}`}>
                        {t(report.type)}
                      </span>
                    </td>
                    <td>
                      <div>{report.date}</div>
                      <div className={styles.reportTime}>{report.time}</div>
                    </td>
                    <td>
                      <span className={`${styles.statusBadge} ${styles[report.status]}`}>
                        {report.status === 'in_progress' ? t('in_progress') : t('ready')}
                      </span>
                    </td>
                    <td>
                      {report.status === 'ready' ? (
                        <a href={report.downloadUrl} className={styles.downloadLink}>
                          {t('download')}
                        </a>
                      ) : (
                        <span className={styles.pendingMessage}>
                          {t('processing')}
                        </span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          
          <div className={styles.pagination}>
            <button 
              className={styles.paginationArrow}
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
              &lt;
            </button>
            
            {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
              <button
                key={page}
                className={`${styles.pageNumber} ${currentPage === page ? styles.activePage : ''}`}
                onClick={() => handlePageChange(page)}
              >
                {page}
              </button>
            ))}
            
            <button 
              className={styles.paginationArrow}
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              &gt;
            </button>
          </div>
        </>
      )}
      
      <div className={styles.reportInfo}>
        <h3 className={styles.infoTitle}>{t('about_reports')}</h3>
        <p className={styles.infoText}>
          {t('reports_explanation')}
        </p>
        <div className={styles.reportTypes}>
          <div className={styles.reportTypeItem}>
            <span className={`${styles.reportTypeIcon} ${styles.summary}`}></span>
            <div className={styles.reportTypeContent}>
              <div className={styles.reportTypeTitle}>{t('summary')}</div>
              <p className={styles.reportTypeDescription}>{t('summary_report_description')}</p>
            </div>
          </div>
          <div className={styles.reportTypeItem}>
            <span className={`${styles.reportTypeIcon} ${styles.behavior}`}></span>
            <div className={styles.reportTypeContent}>
              <div className={styles.reportTypeTitle}>{t('behavior')}</div>
              <p className={styles.reportTypeDescription}>{t('behavior_report_description')}</p>
            </div>
          </div>
          <div className={styles.reportTypeItem}>
            <span className={`${styles.reportTypeIcon} ${styles.scoring}`}></span>
            <div className={styles.reportTypeContent}>
              <div className={styles.reportTypeTitle}>{t('scoring')}</div>
              <p className={styles.reportTypeDescription}>{t('scoring_report_description')}</p>
            </div>
          </div>
          <div className={styles.reportTypeItem}>
            <span className={`${styles.reportTypeIcon} ${styles.route}`}></span>
            <div className={styles.reportTypeContent}>
              <div className={styles.reportTypeTitle}>{t('route')}</div>
              <p className={styles.reportTypeDescription}>{t('route_report_description')}</p>
            </div>
          </div>
          <div className={styles.reportTypeItem}>
            <span className={`${styles.reportTypeIcon} ${styles.speed}`}></span>
            <div className={styles.reportTypeContent}>
              <div className={styles.reportTypeTitle}>{t('speed')}</div>
              <p className={styles.reportTypeDescription}>{t('speed_report_description')}</p>
            </div>
          </div>
        </div>
      </div>
      
      {/* Add the ReportGenerator modal */}
      <ReportGenerator 
        isOpen={showReportGenerator} 
        onClose={() => setShowReportGenerator(false)} 
      />
    </div>
  );
};

export default LatestReports;