// File: frontend/src/pages/EmbeddedInsurance/components/ApiExplorer/index.js

import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import ApiEndpoint from './ApiEndpoint';
import { APP_ENV } from '../../../../config/constants';
import styles from './styles/ApiExplorer.module.css';

const getApiUrl = (env) => {
  let subdomain = '';
  
  switch (env) {
    case 'local':
      subdomain = 'dev';
      break;
    case 'prod':
      subdomain = '';
      break;
    case 'staging':
    case 'dev':
      subdomain = env;
      break;
    default:
      throw new Error(`Invalid environment: ${env}`);
  }
  
  return `https://api${subdomain ? `.${subdomain}` : ''}.allegory.app/v1/docs/swagger`;
};

const ApiExplorer = () => {
  const { t } = useLanguage();
  const [swagger, setSwagger] = useState(null);
  const [activeTag, setActiveTag] = useState(null);
  const [activePath, setActivePath] = useState(null);
  const [activeMethod, setActiveMethod] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSwagger = async () => {
      try {
        setLoading(true);
        const response = await fetch(getApiUrl(APP_ENV));
        const data = await response.json();
        setSwagger(data);
        
        // Find the "Quote" tag or default to first tag
        const quoteTag = data.tags.find(tag => tag.name.toLowerCase().includes('quote'));
        const defaultTag = quoteTag || data.tags[0];
        
        if (defaultTag) {
          setActiveTag(defaultTag.name);
          
          // Find first endpoint for this tag (preferably /quote/initiate)
          const endpointsForTag = [];
          
          Object.entries(data.paths).forEach(([path, methods]) => {
            Object.entries(methods).forEach(([method, details]) => {
              if (details.tags && details.tags.includes(defaultTag.name)) {
                endpointsForTag.push({ path, method, details });
              }
            });
          });
          
          // Try to find /quote/initiate or default to first endpoint
          const initiateEndpoint = endpointsForTag.find(ep => 
            ep.path.toLowerCase().includes('/quote/initiate') && ep.method.toLowerCase() === 'post'
          );
          
          const defaultEndpoint = initiateEndpoint || endpointsForTag[0];
          
          if (defaultEndpoint) {
            setActivePath(defaultEndpoint.path);
            setActiveMethod(defaultEndpoint.method);
          }
        }
      } catch (error) {
        console.error('Error fetching API documentation:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSwagger();
  }, []);

  const handleTagSelect = (tagName) => {
    setActiveTag(tagName);
    
    // Find first endpoint for this tag
    if (swagger) {
      const endpointsForTag = [];
      
      Object.entries(swagger.paths).forEach(([path, methods]) => {
        Object.entries(methods).forEach(([method, details]) => {
          if (details.tags && details.tags.includes(tagName)) {
            endpointsForTag.push({ path, method, details });
          }
        });
      });
      
      if (endpointsForTag.length > 0) {
        setActivePath(endpointsForTag[0].path);
        setActiveMethod(endpointsForTag[0].method);
      }
    }
  };

  const handleEndpointSelect = (path, method) => {
    setActivePath(path);
    setActiveMethod(method);
  };

  const getActiveEndpoint = () => {
    if (!swagger || !activePath || !activeMethod) return null;
    
    return {
      path: activePath,
      method: activeMethod,
      ...swagger.paths[activePath][activeMethod]
    };
  };

  // Get endpoints for active tag
  const getEndpointsForActiveTag = () => {
    if (!swagger || !activeTag) return [];
    
    const endpoints = [];
    
    Object.entries(swagger.paths).forEach(([path, methods]) => {
      Object.entries(methods).forEach(([method, details]) => {
        if (details.tags && details.tags.includes(activeTag)) {
          endpoints.push({
            path,
            method,
            summary: details.summary || path,
            operationId: details.operationId
          });
        }
      });
    });
    
    return endpoints;
  };

  const getMethodColor = (method) => {
    const colors = {
      get: styles.methodGet,
      post: styles.methodPost,
      put: styles.methodPut,
      delete: styles.methodDelete,
      patch: styles.methodPatch,
    };
    return colors[method.toLowerCase()] || styles.methodDefault;
  };

  return (
    <div className={styles.apiExplorer}>
      {loading ? (
        <div className={styles.loading}>
          <div className={styles.spinner}></div>
          <p>{t('loading_api_documentation') || 'Loading API documentation...'}</p>
        </div>
      ) : swagger ? (
        <>
          {/* Horizontal Tag Navigation */}
          <div className={styles.tagNavigation}>
            <div className={styles.tagScroller}>
              {swagger.tags.map((tag) => (
                <button
                  key={tag.name}
                  className={`${styles.tagButton} ${activeTag === tag.name ? styles.activeTag : ''}`}
                  onClick={() => handleTagSelect(tag.name)}
                >
                  {tag.name}
                </button>
              ))}
            </div>
          </div>
          
          {/* Horizontal Endpoint Navigation */}
          <div className={styles.endpointNavigation}>
            <div className={styles.endpointScroller}>
              {getEndpointsForActiveTag().map((endpoint) => (
                <button
                  key={`${endpoint.path}-${endpoint.method}`}
                  className={`${styles.endpointButton} ${
                    activePath === endpoint.path && activeMethod === endpoint.method 
                      ? styles.activeEndpoint 
                      : ''
                  }`}
                  onClick={() => handleEndpointSelect(endpoint.path, endpoint.method)}
                >
                  <span className={`${styles.methodBadge} ${getMethodColor(endpoint.method)}`}>
                    {endpoint.method.toUpperCase()}
                  </span>
                  <span className={styles.endpointPath}>{endpoint.path}</span>
                </button>
              ))}
            </div>
          </div>
          
          {/* Endpoint Details */}
          <div className={styles.endpointDetails}>
            {getActiveEndpoint() ? (
              <ApiEndpoint 
                endpoint={getActiveEndpoint()} 
                components={swagger.components}
              />
            ) : (
              <div className={styles.noEndpoint}>
                <i className="fas fa-code"></i>
                <p>{t('select_endpoint') || 'Select an endpoint from above'}</p>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className={styles.error}>
          <i className="fas fa-exclamation-triangle"></i>
          <p>{t('error_loading_api') || 'Error loading API documentation'}</p>
        </div>
      )}
    </div>
  );
};

export default ApiExplorer;