// File: frontend/src/components/Card/Card.js

import React from 'react';
import './Card.module.css';

const Card = ({ title, actionIcon, children }) => {
  return (
    <div className="card">
      <div className="card__header">
        <h2 className="card__title">{title}</h2>
        {actionIcon && (
          <button className="card__action-button">
            <img src={actionIcon} alt="Action" />
          </button>
        )}
      </div>
      <div className="card__content">
        {children}
      </div>
    </div>
  );
};

export default Card;