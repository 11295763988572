// File: frontend/src/pages/Auth/SignInSuccess.js

import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useLanguage } from '../../contexts/LanguageContext';
import AuthLayout from '../../components/layout/AuthLayout/AuthLayout';
import StatusScreen from '../../components/Auth/StatusScreen';
import styles from './SignInSuccess.module.css';

const SignInSuccess = () => {
  const { t } = useLanguage();
  const navigate = useNavigate();
  const location = useLocation();
  const [countdown, setCountdown] = useState(3);
  
  const { 
    user_id, 
    timestamp = new Date().toISOString(),
    deviceName = navigator.userAgent
  } = location.state || {};

  // Only check for location state
  useEffect(() => {
    // If no state, redirect to sign in
    if (!location.state?.user_id) {
      console.log('SignInSuccess: Missing state, redirecting to sign-in');
      navigate('/sign-in', { replace: true });
      return;
    }

    const countdownInterval = setInterval(() => {
      setCountdown(prev => Math.max(0, prev - 1));
    }, 1000);

    const redirectTimer = setTimeout(() => {
      console.log('SignInSuccess: Countdown complete, redirecting to dashboard');
      navigate('/dashboard', { 
        replace: true,
        state: { fromSignInSuccess: true }  // Add this flag
      });
    }, 3000);

    return () => {
      clearInterval(countdownInterval);
      clearTimeout(redirectTimer);
    };
  }, [navigate, location.state]);

  const formatTimestamp = (timestamp) => {
    return new Date(timestamp).toLocaleString(undefined, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    });
  };

  const additionalInfo = (
    <div className={styles.additionalInfo}>
      <div className={styles.infoGrid}>
        {user_id && (
          <div className={styles.infoItem}>
            <span className={styles.infoLabel}>
              {t('auth.signInSuccess.userIdLabel')}
            </span>
            <span className={styles.infoValue}>{user_id}</span>
          </div>
        )}
        
        <div className={styles.infoItem}>
          <span className={styles.infoLabel}>
            {t('auth.signInSuccess.timestampLabel')}
          </span>
          <span className={styles.infoValue}>
            {formatTimestamp(timestamp)}
          </span>
        </div>
        
        <div className={styles.infoItem}>
          <span className={styles.infoLabel}>
            {t('auth.signInSuccess.deviceLabel')}
          </span>
          <span className={styles.infoValue}>{deviceName}</span>
        </div>
      </div>

      <div className={styles.redirectInfo}>
        <div className={styles.countdown}>
          <div 
            className={styles.countdownBar} 
            style={{ width: `${(countdown / 3) * 100}%` }} 
          />
        </div>
        <p className={styles.redirectText}>
          {t('auth.signInSuccess.redirecting', { seconds: countdown })}
        </p>
      </div>
    </div>
  );

  return (
    <AuthLayout
      title={t('auth.signInSuccess.pageTitle')}
      showHomeButton={true}
    >
      <div className={styles.signInSuccessContainer}>
        <div className={styles.signInSuccessContent}>
          <StatusScreen
            icon="/icons/18/alerts/success.svg"
            title={t('auth.signInSuccess.title')}
            message={t('auth.signInSuccess.message')}
            additionalInfo={additionalInfo}
            type="success"
          />
        </div>
      </div>
    </AuthLayout>
  );
};

export default React.memo(SignInSuccess);