// File: frontend/src/pages/SuperAdmin/components/UserManagement/UserVerification/index.js

import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import SuperAdminTable from '../../common/SuperAdminTable';
import SuperAdminPagination from '../../common/SuperAdminPagination';
import styles from './UserVerification.module.css';

const UserVerification = ({ onBack, onViewUser }) => {
  const { t } = useLanguage();
  const [pendingUsers, setPendingUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    const fetchPendingUsers = async () => {
      setLoading(true);
      setError(null);
      
      try {
        // This would be replaced with an actual API call
        // const response = await userService.getPendingVerifications({
        //   page: currentPage
        // });
        
        // Mock data for now
        await new Promise(resolve => setTimeout(resolve, 800));
        
        const mockPendingUsers = [
          {
            user_id: 'user-34567',
            enterprise_user_id: 'euser-34567',
            enterprise_id: 'ent-12345',
            email: 'bob.johnson@acme.com',
            name: 'Bob Johnson',
            user_role: 'user',
            user_verification_status: 'verified',
            super_admin_verification_status: 'pending',
            organization_name: 'Acme Corporation',
            created_at: '2023-02-05T14:20:00Z'
          },
          {
            user_id: 'user-56789',
            enterprise_user_id: 'euser-56789',
            enterprise_id: 'ent-23456',
            email: 'charlie.brown@global.com',
            name: 'Charlie Brown',
            user_role: 'user',
            user_verification_status: 'verified',
            super_admin_verification_status: 'pending',
            organization_name: 'Global Industries',
            created_at: '2023-02-15T16:30:00Z'
          },
          {
            user_id: 'user-67890',
            enterprise_user_id: 'euser-67890',
            enterprise_id: 'ent-34567',
            email: 'david.miller@tech.com',
            name: 'David Miller',
            user_role: 'manager',
            user_verification_status: 'verified',
            super_admin_verification_status: 'pending',
            organization_name: 'Tech Solutions',
            created_at: '2023-03-10T09:45:00Z'
          }
        ];
        
        setPendingUsers(mockPendingUsers);
        setTotalPages(Math.ceil(mockPendingUsers.length / 10)); // Assuming 10 items per page
      } catch (err) {
        console.error('Error fetching pending verifications:', err);
        setError(t('failed_to_load_pending_verifications'));
      } finally {
        setLoading(false);
      }
    };
    
    fetchPendingUsers();
  }, [currentPage, t]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    }).format(date);
  };

  const handleSelectUser = (userId) => {
    if (selectedUsers.includes(userId)) {
      setSelectedUsers(selectedUsers.filter(id => id !== userId));
    } else {
      setSelectedUsers([...selectedUsers, userId]);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(pendingUsers.map(user => user.user_id));
    }
    setSelectAll(!selectAll);
  };

  const handleVerifySelected = async () => {
    if (selectedUsers.length === 0) return;
    
    setLoading(true);
    
    try {
      // This would be replaced with an actual API call
      // await userService.verifyUsers(selectedUsers);
      
      // Mock implementation for now
      await new Promise(resolve => setTimeout(resolve, 800));
      
      // Update the local state to remove verified users
      setPendingUsers(pendingUsers.filter(user => !selectedUsers.includes(user.user_id)));
      setSelectedUsers([]);
      setSelectAll(false);
    } catch (err) {
      console.error('Error verifying users:', err);
      setError(t('failed_to_verify_users'));
    } finally {
      setLoading(false);
    }
  };

  const handleRejectSelected = async () => {
    if (selectedUsers.length === 0) return;
    
    setLoading(true);
    
    try {
      // This would be replaced with an actual API call
      // await userService.rejectUsers(selectedUsers);
      
      // Mock implementation for now
      await new Promise(resolve => setTimeout(resolve, 800));
      
      // Update the local state to remove rejected users
      setPendingUsers(pendingUsers.filter(user => !selectedUsers.includes(user.user_id)));
      setSelectedUsers([]);
      setSelectAll(false);
    } catch (err) {
      console.error('Error rejecting users:', err);
      setError(t('failed_to_reject_users'));
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      header: (
        <div className={styles.checkboxHeader}>
          <input
            type="checkbox"
            checked={selectAll}
            onChange={handleSelectAll}
            className={styles.checkbox}
          />
        </div>
      ),
      accessor: 'select',
      cell: (row) => (
        <div className={styles.checkboxCell}>
          <input
            type="checkbox"
            checked={selectedUsers.includes(row.user_id)}
            onChange={() => handleSelectUser(row.user_id)}
            className={styles.checkbox}
          />
        </div>
      )
    },
    {
      header: t('user_id'),
      accessor: 'user_id',
      cell: (row) => <span className={styles.idCell}>{row.user_id}</span>
    },
    {
      header: t('name'),
      accessor: 'name',
      cell: (row) => <span className={styles.nameCell}>{row.name}</span>
    },
    {
      header: t('email'),
      accessor: 'email',
      cell: (row) => <span>{row.email}</span>
    },
    {
      header: t('organization'),
      accessor: 'organization_name',
      cell: (row) => <span>{row.organization_name}</span>
    },
    {
      header: t('role'),
      accessor: 'user_role',
      cell: (row) => <span className={styles.roleCell}>{row.user_role}</span>
    },
    {
      header: t('created_at'),
      accessor: 'created_at',
      cell: (row) => <span>{formatDate(row.created_at)}</span>
    },
    {
      header: t('actions'),
      accessor: 'actions',
      cell: (row) => (
        <div className={styles.actions}>
          <button 
            className={styles.viewButton}
            onClick={() => onViewUser(row.user_id)}
          >
            <i className="fas fa-eye"></i>
            <span>{t('view')}</span>
          </button>
          <button 
            className={styles.verifyButton}
            onClick={() => {
              handleSelectUser(row.user_id);
              handleVerifySelected();
            }}
          >
            <i className="fas fa-check"></i>
            <span>{t('verify')}</span>
          </button>
          <button 
            className={styles.rejectButton}
            onClick={() => {
              handleSelectUser(row.user_id);
              handleRejectSelected();
            }}
          >
            <i className="fas fa-times"></i>
            <span>{t('reject')}</span>
          </button>
        </div>
      )
    }
  ];

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <button className={styles.backButton} onClick={onBack}>
          <i className="fas fa-arrow-left"></i>
          <span>{t('back')}</span>
        </button>
        
        <div className={styles.headerTitle}>
          <h2 className={styles.title}>{t('pending_verifications')}</h2>
          <div className={styles.subtitle}>
            {t('pending_verifications_count', { count: pendingUsers.length })}
          </div>
        </div>
        
        <div className={styles.bulkActions}>
          <button 
            className={styles.bulkVerifyButton}
            disabled={selectedUsers.length === 0 || loading}
            onClick={handleVerifySelected}
          >
            <i className="fas fa-check"></i>
            <span>{t('verify_selected')}</span>
            <span className={styles.selectedCount}>{selectedUsers.length}</span>
          </button>
          <button 
            className={styles.bulkRejectButton}
            disabled={selectedUsers.length === 0 || loading}
            onClick={handleRejectSelected}
          >
            <i className="fas fa-times"></i>
            <span>{t('reject_selected')}</span>
          </button>
        </div>
      </div>
      
      {error && <div className={styles.error}>{error}</div>}
      
      {pendingUsers.length > 0 ? (
        <>
          <SuperAdminTable
            columns={columns}
            data={pendingUsers}
            loading={loading}
            emptyMessage={t('no_pending_verifications')}
          />
          
          <div className={styles.pagination}>
            <SuperAdminPagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        </>
      ) : (
        <div className={styles.emptyState}>
          <div className={styles.emptyIcon}>
            <i className="fas fa-check-circle"></i>
          </div>
          <h3 className={styles.emptyTitle}>{t('no_pending_verifications')}</h3>
          <p className={styles.emptyDescription}>
            {t('all_users_verified')}
          </p>
          <button className={styles.backToUsersButton} onClick={onBack}>
            {t('back_to_users')}
          </button>
        </div>
      )}
    </div>
  );
};

export default UserVerification;