// File: frontend/src/components/Sidebar/Sidebar.js

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useLanguage } from '../../contexts/LanguageContext';
import { useRoleBasedAccess } from '../../contexts/RoleBasedAccessContext';
import SidebarTop from './SidebarTop';
import SidebarNavigation from './SidebarNavigation';
import SidebarBot from './SidebarBot';
import styles from './Sidebar.module.css';

const Sidebar = ({ isExpanded, setIsExpanded, isMobileMenuOpen, setIsMobileMenuOpen }) => {
  const { t } = useLanguage();
  const location = useLocation();
  const { hasRole } = useRoleBasedAccess();
  const [activeCategory, setActiveCategory] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const [openCategories, setOpenCategories] = useState(['allegory_intelligence']);

  // Define role requirements for each category and item
  const getRoleRequirements = () => ({
    safe_superintelligence: {
      roleRequired: 'user',
      items: {
        allegory_intelligence: 'user',
        plato: 'super_admin',
        socrates: 'super_admin',
        cave: 'super_admin',
        source_code: 'super_admin',
      }
    },
    internet_of_things: {
      roleRequired: 'user',
      items: {
        driving_score: 'super_admin',
        trips: 'super_admin',
        mile_monitor: 'super_admin',
        route_safe: 'super_admin',
        auto_insight: 'user',
        property_insight_title: 'user',
      }
    },
    insurance: {
      roleRequired: 'admin',
      items: {
        ums: 'admin',
        qms: 'admin',
        pms: 'admin',
        ups: 'admin',
        cms: 'admin',
        lms: 'admin',
        dms: 'admin',
        tms: 'admin',
        rms: 'admin',
        mms: 'admin',
        embedded_insurance: 'user',
      }
    },
    super_admin_panel: {
      roleRequired: 'super_admin'
    }
  });

  // Filter categories based on user permissions
  // Filter categories based on user permissions
  const filterCategories = (categories) => {
    const roleRequirements = getRoleRequirements();
    
    return categories.map(category => {
      // Skip filtering for home category
      if (category.id === 'home') return category;

      const categoryRoles = roleRequirements[category.id];
      if (!categoryRoles || !hasRole(categoryRoles.roleRequired)) {
        return null;
      }

      // If category has items, filter them based on permissions
      if (category.items) {
        const filteredItems = category.items.filter(item => {
          const requiredRole = categoryRoles.items?.[item.name];
          return requiredRole && hasRole(requiredRole);
        });

        // If no items are accessible, hide the category
        if (filteredItems.length === 0) return null;

        return {
          ...category,
          items: filteredItems
        };
      }

      return category;
    }).filter(Boolean); // Remove null categories
  }; 
  
  const [categories, setCategories] = useState([
    {
      id: 'home',
      name: 'homepage',
      path: '/dashboard',
      icon: '/icons/24/homepage/default-menu.svg',
      activeIcon: '/icons/24/homepage/active-menu.svg',
      isOpen: false,
      isDraggable: false,
    },
    {
      id: 'safe_superintelligence',
      name: 'safe_superintelligence',
      isOpen: false,
      isDraggable: true,
      icon: '/icons/24/safe-superintelligence/default-menu.svg',
      activeIcon: '/icons/24/safe-superintelligence/active-menu.svg',
      items: [
        { name: 'allegory_intelligence', path: '/ai1', icon: '/icons/24/allegory-intelligence/default-menu.svg', activeIcon: '/icons/24/allegory-intelligence/active-menu.svg' },
        { name: 'plato', path: '/plato', icon: '/icons/24/plato/default-menu.svg', activeIcon: '/icons/24/plato/active-menu.svg' },
        { name: 'socrates', path: '/socrates', icon: '/icons/24/socrates/default-menu.svg', activeIcon: '/icons/24/socrates/active-menu.svg' },
        { name: 'cave', path: '/cave', icon: '/icons/24/cave/default-menu.svg', activeIcon: '/icons/24/cave/active-menu.svg' },
        { name: 'source_code', path: '/source-code', icon: '/icons/24/source-code/default-menu.svg', activeIcon: '/icons/24/source-code/active-menu.svg' },
      ],
    },
    {
      id: 'internet_of_things',
      name: 'internet_of_things',
      isOpen: false,
      isDraggable: true,
      icon: '/icons/24/internet-of-things/default-menu.svg',
      activeIcon: '/icons/24/internet-of-things/active-menu.svg',
      items: [
        { name: 'driving_score', path: '/driving-score', icon: '/icons/24/driving-score/default-menu.svg', activeIcon: '/icons/24/driving-score/active-menu.svg' },
        { name: 'trips', path: '/trips', icon: '/icons/24/trips/default-menu.svg', activeIcon: '/icons/24/trips/active-menu.svg' },
        { name: 'mile_monitor', path: '/mile-monitor', icon: '/icons/24/mile-monitor/default-menu.svg', activeIcon: '/icons/24/mile-monitor/active-menu.svg' },
        { name: 'route_safe', path: '/route-safe', icon: '/icons/24/route-safe/default-menu.svg', activeIcon: '/icons/24/route-safe/active-menu.svg' },
        { name: 'auto_insight', path: '/auto-insight', icon: '/icons/24/auto-insight/default-menu.svg', activeIcon: '/icons/24/auto-insight/active-menu.svg' },
        { name: 'property_insight_title', path: '/property-insight', icon: '/icons/24/property-insight/default-menu.svg', activeIcon: '/icons/24/property-insight/active-menu.svg' },
      ],
    },
    {
      id: 'insurance',
      name: 'insurance',
      isOpen: false,
      isDraggable: true,
      icon: '/icons/24/insurance/default-menu.svg',
      activeIcon: '/icons/24/insurance/active-menu.svg',
      items: [
        { name: 'ums', path: '/ums', icon: '/icons/24/ums/default-menu.svg', activeIcon: '/icons/24/ums/active-menu.svg' },
        { name: 'qms', path: '/qms', icon: '/icons/24/qms/default-menu.svg', activeIcon: '/icons/24/qms/active-menu.svg' },
        { name: 'pms', path: '/pms', icon: '/icons/24/pms/default-menu.svg', activeIcon: '/icons/24/pms/active-menu.svg' },
        { name: 'cms', path: '/cms', icon: '/icons/24/cms/default-menu.svg', activeIcon: '/icons/24/cms/active-menu.svg' },
        { name: 'ups', path: '/ups', icon: '/icons/24/ups/default-menu.svg', activeIcon: '/icons/24/ups/active-menu.svg' },
        { name: 'lms', path: '/lms', icon: '/icons/24/lms/default-menu.svg', activeIcon: '/icons/24/lms/active-menu.svg' },
        { name: 'dms', path: '/dms', icon: '/icons/24/dms/default-menu.svg', activeIcon: '/icons/24/dms/active-menu.svg' },
        { name: 'tms', path: '/tms', icon: '/icons/24/tms/default-menu.svg', activeIcon: '/icons/24/tms/active-menu.svg' },
        { name: 'rms', path: '/rms', icon: '/icons/24/rms/default-menu.svg', activeIcon: '/icons/24/rms/active-menu.svg' },
        { name: 'mms', path: '/mms', icon: '/icons/24/mms/default-menu.svg', activeIcon: '/icons/24/mms/active-menu.svg' },
        { name: 'embedded_insurance', path: '/embedded-insurance', icon: '/icons/24/embedded-insurance/default-menu.svg', activeIcon: '/icons/24/embedded-insurance/active-menu.svg' },
      ],
    },
    {
      id: 'super_admin_panel',
      name: 'super_admin_panel',
      path: '/super-admin-panel',
      icon: '/icons/24/super-admin-panel/default-menu.svg',
      activeIcon: '/icons/24/super-admin-panel/active-menu.svg',
      isOpen: false,
      isDraggable: false,
    },
  ]);

  // Get filtered categories based on permissions
  const visibleCategories = React.useMemo(() => filterCategories(categories), [categories, hasRole]);

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.classList.add('sidebar-open');
    } else {
      document.body.classList.remove('sidebar-open');
      // Add a small delay to ensure smooth transition
      setTimeout(() => {
        // Double-check that we're still not mobile-open before resetting
        if (!isMobileMenuOpen) {
          document.body.style.overflow = '';
          document.body.style.position = '';
          document.body.style.width = '';
          document.body.style.height = '';
        }
      }, 300); // Match your transition duration
    }
  
    return () => {
      document.body.classList.remove('sidebar-open');
      document.body.style.overflow = '';
      document.body.style.position = '';
      document.body.style.width = '';
      document.body.style.height = '';
    };
  }, [isMobileMenuOpen]);

  // Add click handler for the overlay
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      setIsMobileMenuOpen(false);
    }
  };

  // Handle initial and route-based active states
  useEffect(() => {
    const currentPath = location.pathname;
    let foundActiveItem = false;

    visibleCategories.forEach(category => {
      if (category.path === currentPath) {
        setActiveCategory(category.id);
        setActiveItem(null);
        foundActiveItem = true;
      } else if (category.items) {
        const activeItem = category.items.find(item => item.path === currentPath);
        if (activeItem) {
          setActiveCategory(category.id);
          setActiveItem(activeItem.name);
          if (!openCategories.includes(category.id)) {
            setOpenCategories(prev => [...prev, category.id]);
          }
          foundActiveItem = true;
        }
      }
    });

    if (!foundActiveItem) {
      if (currentPath === '/dashboard') {
        setActiveCategory('home');
        setActiveItem(null);
      } else {
        setActiveCategory(null);
        setActiveItem(null);
      }
    }
  }, [location.pathname, visibleCategories]);

  // Handle category toggle
  const handleCategoryClick = (categoryId) => {
    // Toggle category open state
    setOpenCategories(prev => {
      if (prev.includes(categoryId)) {
        return prev.filter(id => id !== categoryId);
      }
      return [...prev, categoryId];
    });

    // Set active states
    setActiveCategory(categoryId);
    
    // Set first item as active when opening a category
    const category = categories.find(cat => cat.id === categoryId);
    if (category?.items?.length > 0 && !openCategories.includes(categoryId)) {
      setActiveItem(category.items[0].name);
    }
  };

  // Drag and drop functionality
  const handleDragStart = (e, categoryId) => {
    const category = categories.find(cat => cat.id === categoryId);
    if (!category.isDraggable) {
      e.preventDefault();
      return;
    }
    e.dataTransfer.setData('text/plain', categoryId);
    e.target.style.opacity = '0.5';
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
  };

  const handleDrop = (e, targetCategoryId) => {
    e.preventDefault();
    const draggedCategoryId = e.dataTransfer.getData('text/plain');
    
    if (draggedCategoryId === targetCategoryId) return;

    const newCategories = [...categories];
    const draggedIndex = newCategories.findIndex(cat => cat.id === draggedCategoryId);
    const targetIndex = newCategories.findIndex(cat => cat.id === targetCategoryId);
    
    const [removed] = newCategories.splice(draggedIndex, 1);
    newCategories.splice(targetIndex, 0, removed);
    
    setCategories(newCategories);
  };

  const handleDragEnd = (e) => {
    e.target.style.opacity = '1';
  };

  return (
    <nav 
      className={`${styles.sidebar} ${isExpanded ? styles.expanded : ''} ${isMobileMenuOpen ? styles.mobileOpen : ''}`}
      onClick={handleOverlayClick}
    >
      <div className={styles.sidebarContent}>
        <SidebarTop 
          isExpanded={isExpanded} 
          setIsExpanded={setIsExpanded}
          setIsMobileMenuOpen={setIsMobileMenuOpen}
        />
        <div className={styles.categoriesContainer}>
          {visibleCategories.map((category) => (
            <div
              key={category.id}
              className={styles.categoryWrapper}
              draggable={category.isDraggable && isExpanded}
              onDragStart={(e) => handleDragStart(e, category.id)}
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, category.id)}
              onDragEnd={handleDragEnd}
            >
              <SidebarNavigation
                category={category}
                isExpanded={isExpanded}
                isActive={activeCategory === category.id}
                activeItem={activeItem}
                isOpen={openCategories.includes(category.id)}
                onClick={() => handleCategoryClick(category.id)}
              />
            </div>
          ))}
        </div>
        <SidebarBot isExpanded={isExpanded} />
      </div>
    </nav>
  );
};

export default Sidebar;

Sidebar.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  setIsExpanded: PropTypes.func.isRequired,
  isMobileMenuOpen: PropTypes.bool.isRequired,
  setIsMobileMenuOpen: PropTypes.func.isRequired,
};