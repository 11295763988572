// File: frontend/src/pages/SuperAdmin/components/AuditLogs/UsersAuditLog/index.js

import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import SuperAdminTable from '../../common/SuperAdminTable';
import SuperAdminSearch from '../../common/SuperAdminSearch';
import SuperAdminPagination from '../../common/SuperAdminPagination';
import styles from './UsersAuditLog.module.css';

const UsersAuditLog = () => {
  const { t } = useLanguage();
  const [auditLogs, setAuditLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterActionType, setFilterActionType] = useState('all');
  const [selectedLog, setSelectedLog] = useState(null);
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    const fetchAuditLogs = async () => {
      setLoading(true);
      setError(null);
      
      try {
        // This would be replaced with an actual API call
        // const response = await auditService.getUsersAuditLogs({
        //   page: currentPage,
        //   search: searchTerm,
        //   actionType: filterActionType !== 'all' ? filterActionType : undefined
        // });
        
        // Mock data for now
        await new Promise(resolve => setTimeout(resolve, 800));
        
        const mockAuditLogs = [
          {
            audit_log_id: 'audit-67890',
            enterprise_user_id: 'euser-12345',
            user_id: 'user-12345',
            user_name: 'John Smith',
            enterprise_id: 'ent-12345',
            enterprise_name: 'Acme Corporation',
            action_type: 'INSERT',
            action_timestamp: '2023-06-15T10:30:00Z',
            action_by: 'admin-user-1',
            action_by_name: 'Admin User',
            old_values: null,
            new_values: {
              user_role: 'admin',
              user_verification_status: 'pending',
              organization_name: 'Acme Corporation'
            }
          },
          {
            audit_log_id: 'audit-78901',
            enterprise_user_id: 'euser-12345',
            user_id: 'user-12345',
            user_name: 'John Smith',
            enterprise_id: 'ent-12345',
            enterprise_name: 'Acme Corporation',
            action_type: 'UPDATE',
            action_timestamp: '2023-06-16T11:45:00Z',
            action_by: 'admin-user-2',
            action_by_name: 'Super Admin',
            old_values: {
              user_verification_status: 'pending'
            },
            new_values: {
              user_verification_status: 'verified'
            }
          },
          {
            audit_log_id: 'audit-89012',
            enterprise_user_id: 'euser-23456',
            user_id: 'user-23456',
            user_name: 'Jane Doe',
            enterprise_id: 'ent-12345',
            enterprise_name: 'Acme Corporation',
            action_type: 'INSERT',
            action_timestamp: '2023-06-18T09:15:00Z',
            action_by: 'admin-user-1',
            action_by_name: 'Admin User',
            old_values: null,
            new_values: {
              user_role: 'manager',
              user_verification_status: 'pending',
              organization_name: 'Acme Corporation'
            }
          },
          {
            audit_log_id: 'audit-90123',
            enterprise_user_id: 'euser-34567',
            user_id: 'user-34567',
            user_name: 'Bob Johnson',
            enterprise_id: 'ent-23456',
            enterprise_name: 'Global Industries',
            action_type: 'UPDATE',
            action_timestamp: '2023-06-20T14:30:00Z',
            action_by: 'admin-user-2',
            action_by_name: 'Super Admin',
            old_values: {
              user_role: 'user'
            },
            new_values: {
              user_role: 'manager'
            }
          },
          {
            audit_log_id: 'audit-01234',
            enterprise_user_id: 'euser-45678',
            user_id: 'user-45678',
            user_name: 'Alice Williams',
            enterprise_id: 'ent-34567',
            enterprise_name: 'Tech Solutions',
            action_type: 'DELETE',
            action_timestamp: '2023-06-25T16:45:00Z',
            action_by: 'admin-user-2',
            action_by_name: 'Super Admin',
            old_values: {
              user_role: 'admin',
              user_verification_status: 'verified',
              organization_name: 'Tech Solutions'
            },
            new_values: null
          }
        ];
        
        // Filter based on search term and action type
        let filteredLogs = mockAuditLogs;
        
        if (searchTerm) {
          const searchLower = searchTerm.toLowerCase();
          filteredLogs = filteredLogs.filter(log => 
            log.user_name.toLowerCase().includes(searchLower) ||
            log.user_id.toLowerCase().includes(searchLower) ||
            log.enterprise_name.toLowerCase().includes(searchLower) ||
            log.action_by_name.toLowerCase().includes(searchLower)
          );
        }
        
        if (filterActionType !== 'all') {
          filteredLogs = filteredLogs.filter(log => 
            log.action_type === filterActionType
          );
        }
        
        setAuditLogs(filteredLogs);
        setTotalPages(Math.ceil(filteredLogs.length / 10)); // Assuming 10 items per page
      } catch (err) {
        console.error('Error fetching audit logs:', err);
        setError(t('failed_to_load_audit_logs'));
      } finally {
        setLoading(false);
      }
    };
    
    fetchAuditLogs();
  }, [currentPage, searchTerm, filterActionType, t]);

  const handleSearch = (term) => {
    setSearchTerm(term);
    setCurrentPage(1);
  };

  const handleActionTypeFilterChange = (e) => {
    setFilterActionType(e.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }).format(date);
  };

  const getActionTypeBadgeClass = (actionType) => {
    switch (actionType) {
      case 'INSERT':
        return styles.typeInsert;
      case 'UPDATE':
        return styles.typeUpdate;
      case 'DELETE':
        return styles.typeDelete;
      default:
        return '';
    }
  };

  const handleViewDetails = (log) => {
    setSelectedLog(log);
    setShowDetails(true);
  };

  const handleCloseDetails = () => {
    setShowDetails(false);
    setSelectedLog(null);
  };

  const renderChanges = (oldValues, newValues) => {
    if (!oldValues && !newValues) return null;
    
    // For INSERT operations
    if (!oldValues && newValues) {
      return (
        <div className={styles.changesList}>
          {Object.entries(newValues).map(([key, value]) => (
            <div key={key} className={styles.changeItem}>
              <div className={styles.changeField}>{key}</div>
              <div className={styles.changeValues}>
                <div className={styles.changeNewValue}>
                  <span className={styles.changeLabel}>{t('new')}:</span>
                  <span className={styles.changeValue}>{String(value)}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      );
    }
    
    // For DELETE operations
    if (oldValues && !newValues) {
      return (
        <div className={styles.changesList}>
          {Object.entries(oldValues).map(([key, value]) => (
            <div key={key} className={styles.changeItem}>
              <div className={styles.changeField}>{key}</div>
              <div className={styles.changeValues}>
                <div className={styles.changeOldValue}>
                  <span className={styles.changeLabel}>{t('old')}:</span>
                  <span className={styles.changeValue}>{String(value)}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      );
    }
    
    // For UPDATE operations
    const allKeys = [...new Set([...Object.keys(oldValues || {}), ...Object.keys(newValues || {})])];
    
    return (
      <div className={styles.changesList}>
        {allKeys.map(key => {
          const oldValue = oldValues?.[key];
          const newValue = newValues?.[key];
          const hasChanged = oldValue !== newValue;
          
          if (!hasChanged) return null;
          
          return (
            <div key={key} className={styles.changeItem}>
              <div className={styles.changeField}>{key}</div>
              <div className={styles.changeValues}>
                <div className={styles.changeOldValue}>
                  <span className={styles.changeLabel}>{t('old')}:</span>
                  <span className={styles.changeValue}>{oldValue !== undefined ? String(oldValue) : '-'}</span>
                </div>
                <div className={styles.changeNewValue}>
                  <span className={styles.changeLabel}>{t('new')}:</span>
                  <span className={styles.changeValue}>{newValue !== undefined ? String(newValue) : '-'}</span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const columns = [
    {
      header: t('user'),
      accessor: 'user_name',
      cell: (row) => <span className={styles.userName}>{row.user_name}</span>
    },
    {
      header: t('user_id'),
      accessor: 'user_id',
      cell: (row) => <span className={styles.idCell}>{row.user_id}</span>
    },
    {
      header: t('enterprise'),
      accessor: 'enterprise_name',
      cell: (row) => <span>{row.enterprise_name}</span>
    },
    {
      header: t('action_type'),
      accessor: 'action_type',
      cell: (row) => (
        <span className={`${styles.actionTypeBadge} ${getActionTypeBadgeClass(row.action_type)}`}>
          {row.action_type}
        </span>
      )
    },
    {
      header: t('action_by'),
      accessor: 'action_by_name',
      cell: (row) => <span>{row.action_by_name}</span>
    },
    {
      header: t('timestamp'),
      accessor: 'action_timestamp',
      cell: (row) => <span>{formatDate(row.action_timestamp)}</span>
    },
    {
      header: t('actions'),
      accessor: 'actions',
      cell: (row) => (
        <div className={styles.actions}>
          <button 
            className={styles.viewButton}
            onClick={() => handleViewDetails(row)}
          >
            <i className="fas fa-eye"></i>
            <span>{t('view_details')}</span>
          </button>
        </div>
      )
    }
  ];

  return (
    <div className={styles.container}>
      <div className={styles.toolbar}>
        <div className={styles.filters}>
          <SuperAdminSearch 
            placeholder={t('search_audit_logs')}
            onSearch={handleSearch}
          />
          
          <div className={styles.filterGroup}>
            <label htmlFor="actionTypeFilter" className={styles.filterLabel}>
              {t('action_type')}:
            </label>
            <select
              id="actionTypeFilter"
              className={styles.filterSelect}
              value={filterActionType}
              onChange={handleActionTypeFilterChange}
            >
              <option value="all">{t('all_types')}</option>
              <option value="INSERT">{t('insert')}</option>
              <option value="UPDATE">{t('update')}</option>
              <option value="DELETE">{t('delete')}</option>
            </select>
          </div>
        </div>
      </div>
      
      {error && <div className={styles.error}>{error}</div>}
      
      <SuperAdminTable
        columns={columns}
        data={auditLogs}
        loading={loading}
        emptyMessage={t('no_audit_logs_found')}
      />
      
      <div className={styles.pagination}>
        <SuperAdminPagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
      
      {showDetails && selectedLog && (
        <div className={styles.detailsOverlay}>
          <div className={styles.detailsModal}>
            <div className={styles.detailsHeader}>
              <h3 className={styles.detailsTitle}>{t('audit_log_details')}</h3>
              <button className={styles.closeButton} onClick={handleCloseDetails}>
                <i className="fas fa-times"></i>
              </button>
            </div>
            
            <div className={styles.detailsContent}>
              <div className={styles.detailsSection}>
                <h4 className={styles.detailsSectionTitle}>{t('general_information')}</h4>
                <div className={styles.detailsGrid}>
                  <div className={styles.detailsItem}>
                    <div className={styles.detailsLabel}>{t('audit_log_id')}</div>
                    <div className={styles.detailsValue}>{selectedLog.audit_log_id}</div>
                  </div>
                  <div className={styles.detailsItem}>
                    <div className={styles.detailsLabel}>{t('user')}</div>
                    <div className={styles.detailsValue}>{selectedLog.user_name}</div>
                  </div>
                  <div className={styles.detailsItem}>
                    <div className={styles.detailsLabel}>{t('user_id')}</div>
                    <div className={styles.detailsValue}>{selectedLog.user_id}</div>
                  </div>
                  <div className={styles.detailsItem}>
                    <div className={styles.detailsLabel}>{t('enterprise_user_id')}</div>
                    <div className={styles.detailsValue}>{selectedLog.enterprise_user_id}</div>
                  </div>
                  <div className={styles.detailsItem}>
                    <div className={styles.detailsLabel}>{t('enterprise')}</div>
                    <div className={styles.detailsValue}>{selectedLog.enterprise_name}</div>
                  </div>
                  <div className={styles.detailsItem}>
                    <div className={styles.detailsLabel}>{t('enterprise_id')}</div>
                    <div className={styles.detailsValue}>{selectedLog.enterprise_id}</div>
                  </div>
                  <div className={styles.detailsItem}>
                    <div className={styles.detailsLabel}>{t('action_type')}</div>
                    <div className={styles.detailsValue}>
                      <span className={`${styles.actionTypeBadge} ${getActionTypeBadgeClass(selectedLog.action_type)}`}>
                        {selectedLog.action_type}
                      </span>
                    </div>
                  </div>
                  <div className={styles.detailsItem}>
                    <div className={styles.detailsLabel}>{t('action_by')}</div>
                    <div className={styles.detailsValue}>{selectedLog.action_by_name}</div>
                  </div>
                  <div className={styles.detailsItem}>
                    <div className={styles.detailsLabel}>{t('action_by_id')}</div>
                    <div className={styles.detailsValue}>{selectedLog.action_by}</div>
                  </div>
                  <div className={styles.detailsItem}>
                    <div className={styles.detailsLabel}>{t('timestamp')}</div>
                    <div className={styles.detailsValue}>{formatDate(selectedLog.action_timestamp)}</div>
                  </div>
                </div>
              </div>
              
              <div className={styles.detailsSection}>
                <h4 className={styles.detailsSectionTitle}>{t('changes')}</h4>
                {renderChanges(selectedLog.old_values, selectedLog.new_values)}
              </div>
            </div>
            
            <div className={styles.detailsFooter}>
              <button className={styles.closeDetailsButton} onClick={handleCloseDetails}>
                {t('close')}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UsersAuditLog;