// File: frontend/src/pages/QMS/QuoteDetail/components/QuoteHeader/index.js

import React from 'react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from './QuoteHeader.module.css';

const QuoteHeader = ({ quote, onBack }) => {
  const { t } = useLanguage();

  const getStatusClass = (status) => {
    switch (status) {
      case 'completed': return styles.statusCompleted;
      case 'collecting_data': return styles.statusCollecting;
      case 'abandoned': case 'abandoned_by_system': return styles.statusAbandoned;
      case 'locked': return styles.statusLocked;
      default: return '';
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <h1 className={styles.title}>{t('quote_details')}</h1>
        
        <div className={styles.meta}>
          <div className={styles.metaItem}>
            <span className={styles.metaLabel}>{t('qnr')}:</span>
            <span className={styles.metaValue}>{quote.qnr}</span>
          </div>
          
          <div className={styles.metaItem}>
            <span className={styles.metaLabel}>{t('quote_id')}:</span>
            <span className={styles.metaValue}>{quote.quote_id_masked || quote.quote_id}</span>
          </div>
          
          <div className={styles.metaItem}>
            <span className={styles.metaLabel}>{t('status')}:</span>
            <span className={`${styles.metaValue} ${getStatusClass(quote.meta?.quote_status)}`}>
              {t(quote.meta?.quote_status)}
            </span>
          </div>
        </div>
      </div>
      
      <div className={styles.actions}>
        <button className={styles.backButton} onClick={onBack}>
          {t('back_to_list')}
        </button>
        
        <button className={styles.downloadButton}>
          {t('download_quote_pdf')}
        </button>
      </div>
    </div>
  );
};

export default QuoteHeader;