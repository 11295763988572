// File: frontend/src/utils/formatters.js

export const formatCurrency = (value) => {
  if (!value && value !== 0) return 'N/A';
  
  // Convert to billions or millions for readability
  if (value >= 1000000000) {
    return `$${(value / 1000000000).toFixed(1)}B`;
  } else if (value >= 1000000) {
    return `$${(value / 1000000).toFixed(1)}M`;
  } else {
    return `$${value.toLocaleString()}`;
  }
};