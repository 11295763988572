// File: frontend/src/components/Breadcrumb/Breadcrumb.js

import React from 'react';
import PropTypes from 'prop-types';
import { useLanguage } from '../../contexts/LanguageContext';
import styles from './Breadcrumb.module.css';

// Utility functions for text formatting
const formatBreadcrumbText = (text) => {
  // Handle UUID - convert to lowercase
  if (text.match(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/)) {
    return text.toLowerCase();
  }

  // Handle AI related text - convert to uppercase
  if (text.match(/^ai\d+$/i)) {
    return text.toUpperCase();
  }

  // Handle specific single words that need capitalization
  const capitalizedWords = ['plato', 'socrates', 'cave', 'trips', 'trip', 'chat', 'vehicle', 'property', 'portfolio'];
  if (capitalizedWords.includes(text.toLowerCase())) {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  }

  // Handle hyphenated words - convert to title case without hyphens
  if (text.includes('-')) {
    return text
      .split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

  return text;
};

const BreadcrumbStep = ({ text, isActive, isLast, onClick }) => {
  const { t } = useLanguage();
  
  const getDisplayText = (text) => {
    // Check if text contains system abbreviations (LMS, UMS, QMS, etc.)
    const systemAbbreviations = ['UMS', 'QMS', 'PMS', 'UPS','CMS', 'LMS', 'DMS', 'TMS', 'RMS', 'MMS'];
    for (const abbr of systemAbbreviations) {
      if (text.toLowerCase().includes(abbr.toLowerCase())) {
        return t(`${abbr.toLowerCase()}`, text);
      }
    }
    
    return formatBreadcrumbText(text);
  };

  return (
    <div className={styles['breadcrumb-step']}>
      <button 
        className={`${styles['breadcrumb-text']} ${isActive ? styles['breadcrumb-text-active'] : ''}`}
        onClick={onClick}
        disabled={!onClick}
        role={onClick ? "button" : undefined}
        tabIndex={onClick ? 0 : undefined}
        aria-current={isActive ? 'page' : undefined}
      >
        {getDisplayText(text)}
      </button>
      {!isLast && (
        <svg 
          className={styles['breadcrumb-separator']}
          viewBox="0 0 24 24" 
          fill="none"
          stroke="currentColor" 
          strokeWidth="2" 
          strokeLinecap="round" 
          strokeLinejoin="round"
          aria-hidden="true"
        >
          <polyline points="9 18 15 12 9 6" />
        </svg>
      )}
    </div>
  );
};

const Breadcrumb = ({ steps, activeStep, onStepClick, className = '' }) => {
  return (
    <nav 
      className={`${styles.breadcrumb} ${className}`} 
      aria-label="Breadcrumb"
    >
      <ol className={styles['breadcrumb-list']}>
        {steps.map((step, index) => (
          <li key={index} className={styles['breadcrumb-item']}>
            <BreadcrumbStep 
              text={step}
              isActive={index === activeStep}
              isLast={index === steps.length - 1}
              onClick={onStepClick ? () => onStepClick(index, step) : undefined}
            />
          </li>
        ))}
      </ol>
    </nav>
  );
};

Breadcrumb.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeStep: PropTypes.number.isRequired,
  onStepClick: PropTypes.func,
  className: PropTypes.string
};

export default Breadcrumb;

