// File: frontend/src/pages/SuperAdmin/components/DatasetManagement/DatasetList/index.js

import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import SuperAdminTable from '../../common/SuperAdminTable';
import SuperAdminSearch from '../../common/SuperAdminSearch';
import SuperAdminPagination from '../../common/SuperAdminPagination';
import styles from './DatasetList.module.css';

const DatasetList = ({ 
  onViewDetail, 
  onCreateNew, 
  onBack, 
  isCreateView = false,
  onSuccess = null
}) => {
  const { t } = useLanguage();
  const [datasets, setDatasets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('all');
  const [filterType, setFilterType] = useState('all');
  
  // Form state for create
  const [formData, setFormData] = useState({
    dataset_name: '',
    dataset_description: '',
    dataset_type: 'structured',
    dataset_status: 'active',
    enterprise_id: '',
    storage_location: '',
    region: '',
    format: 'csv',
    schema: '',
    retention_policy: '30 days'
  });
  
  // For create view
  const [enterprises, setEnterprises] = useState([]);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    const fetchDatasets = async () => {
      setLoading(true);
      setError(null);
      
      try {
        // This would be replaced with an actual API call
        // const response = await datasetService.listDatasets({
        //   page: currentPage,
        //   search: searchTerm,
        //   status: filterStatus !== 'all' ? filterStatus : undefined,
        //   type: filterType !== 'all' ? filterType : undefined
        // });
        
        // Mock data for now
        await new Promise(resolve => setTimeout(resolve, 800));
        
        const mockDatasets = [
          {
            dataset_id: 'ds-12345',
            dataset_name: 'Customer Analytics Data',
            dataset_description: 'Customer behavior and analytics data for reporting',
            dataset_type: 'structured',
            dataset_status: 'active',
            enterprise_id: 'ent-12345',
            enterprise_name: 'Acme Corporation',
            storage_location: 's3://acme-analytics/customer-data',
            region: 'us-west-2',
            created_at: '2023-01-15T10:30:00Z',
            updated_at: '2023-06-20T14:45:00Z',
            size_in_gb: 25.4,
            record_count: 1250000
          },
          {
            dataset_id: 'ds-23456',
            dataset_name: 'Product Catalog',
            dataset_description: 'Complete product catalog with metadata',
            dataset_type: 'structured',
            dataset_status: 'active',
            enterprise_id: 'ent-12345',
            enterprise_name: 'Acme Corporation',
            storage_location: 's3://acme-products/catalog',
            region: 'us-west-2',
            created_at: '2023-02-10T09:15:00Z',
            updated_at: '2023-06-15T11:30:00Z',
            size_in_gb: 3.2,
            record_count: 45000
          },
          {
            dataset_id: 'ds-34567',
            dataset_name: 'Transaction Logs',
            dataset_description: 'Historical transaction logs for auditing',
            dataset_type: 'semi-structured',
            dataset_status: 'archived',
            enterprise_id: 'ent-23456',
            enterprise_name: 'Global Industries',
            storage_location: 's3://global-data/transactions',
            region: 'us-east-1',
            created_at: '2023-01-05T08:45:00Z',
            updated_at: '2023-05-10T16:20:00Z',
            size_in_gb: 120.7,
            record_count: 8500000
          },
          {
            dataset_id: 'ds-45678',
            dataset_name: 'User Feedback',
            dataset_description: 'Customer feedback and survey responses',
            dataset_type: 'unstructured',
            dataset_status: 'active',
            enterprise_id: 'ent-34567',
            enterprise_name: 'Tech Solutions',
            storage_location: 's3://tech-feedback/responses',
            region: 'eu-central-1',
            created_at: '2023-03-20T13:10:00Z',
            updated_at: '2023-06-25T09:45:00Z',
            size_in_gb: 8.9,
            record_count: 75000
          },
          {
            dataset_id: 'ds-56789',
            dataset_name: 'Marketing Campaign Data',
            dataset_description: 'Marketing campaign performance metrics',
            dataset_type: 'structured',
            dataset_status: 'inactive',
            enterprise_id: 'ent-45678',
            enterprise_name: 'Innovative Systems',
            storage_location: 's3://innovative-marketing/campaigns',
            region: 'ap-southeast-1',
            created_at: '2023-04-05T11:30:00Z',
            updated_at: '2023-06-01T15:45:00Z',
            size_in_gb: 5.6,
            record_count: 320000
          }
        ];
        
        // Filter based on search term, status, and type
        let filteredDatasets = mockDatasets;
        
        if (searchTerm) {
          const searchLower = searchTerm.toLowerCase();
          filteredDatasets = filteredDatasets.filter(dataset => 
            dataset.dataset_name.toLowerCase().includes(searchLower) ||
            dataset.dataset_description.toLowerCase().includes(searchLower) ||
            dataset.enterprise_name.toLowerCase().includes(searchLower)
          );
        }
        
        if (filterStatus !== 'all') {
          filteredDatasets = filteredDatasets.filter(dataset => 
            dataset.dataset_status === filterStatus
          );
        }
        
        if (filterType !== 'all') {
          filteredDatasets = filteredDatasets.filter(dataset => 
            dataset.dataset_type === filterType
          );
        }
        
        setDatasets(filteredDatasets);
        setTotalPages(Math.ceil(filteredDatasets.length / 10)); // Assuming 10 items per page
      } catch (err) {
        console.error('Error fetching datasets:', err);
        setError(t('failed_to_load_datasets'));
      } finally {
        setLoading(false);
      }
    };
    
    const fetchEnterprises = async () => {
      try {
        // This would be replaced with an actual API call
        // const response = await enterpriseService.listEnterprises();
        
        // Mock data for now
        await new Promise(resolve => setTimeout(resolve, 500));
        
        const mockEnterprises = [
          {
            enterprise_id: 'ent-12345',
            enterprise_name: 'Acme Corporation',
            enterprise_status: 'active'
          },
          {
            enterprise_id: 'ent-23456',
            enterprise_name: 'Global Industries',
            enterprise_status: 'active'
          },
          {
            enterprise_id: 'ent-34567',
            enterprise_name: 'Tech Solutions',
            enterprise_status: 'active'
          },
          {
            enterprise_id: 'ent-45678',
            enterprise_name: 'Innovative Systems',
            enterprise_status: 'active'
          }
        ];
        
        setEnterprises(mockEnterprises);
      } catch (err) {
        console.error('Error fetching enterprises:', err);
        setError(t('failed_to_load_enterprises'));
      }
    };
    
    if (!isCreateView) {
      fetchDatasets();
    } else {
      fetchEnterprises();
      setLoading(false);
    }
  }, [currentPage, searchTerm, filterStatus, filterType, isCreateView, t]);

  const handleSearch = (term) => {
    setSearchTerm(term);
    setCurrentPage(1);
  };

  const handleStatusFilterChange = (e) => {
    setFilterStatus(e.target.value);
    setCurrentPage(1);
  };

  const handleTypeFilterChange = (e) => {
    setFilterType(e.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    }).format(date);
  };

  const formatSize = (sizeInGB) => {
    if (sizeInGB < 1) {
      return `${Math.round(sizeInGB * 1024)} MB`;
    }
    return `${sizeInGB.toFixed(1)} GB`;
  };

  const formatNumber = (num) => {
    return new Intl.NumberFormat().format(num);
  };

  const getStatusBadgeClass = (status) => {
    switch (status) {
      case 'active':
        return styles.statusActive;
      case 'inactive':
        return styles.statusInactive;
      case 'archived':
        return styles.statusArchived;
      default:
        return '';
    }
  };

  const getTypeBadgeClass = (type) => {
    switch (type) {
      case 'structured':
        return styles.typeStructured;
      case 'semi-structured':
        return styles.typeSemiStructured;
      case 'unstructured':
        return styles.typeUnstructured;
      default:
        return '';
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSaving(true);
    setError(null);
    
    try {
      // This would be replaced with an actual API call
      // const response = await datasetService.createDataset(formData);
      
      // Mock response for now
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      const mockResponse = {
        success: true,
        dataset_id: `ds-${Math.random().toString(36).substring(2, 10)}`
      };
      
      // Call the success callback with the new dataset ID
      if (onSuccess) {
        onSuccess(mockResponse.dataset_id);
      }
    } catch (err) {
      console.error('Error creating dataset:', err);
      setError(t('failed_to_create_dataset'));
    } finally {
      setSaving(false);
    }
  };

  const columns = [
    {
      header: t('dataset_name'),
      accessor: 'dataset_name',
      cell: (row) => <span className={styles.nameCell}>{row.dataset_name}</span>
    },
    {
      header: t('enterprise'),
      accessor: 'enterprise_name',
      cell: (row) => <span>{row.enterprise_name}</span>
    },
    {
      header: t('type'),
      accessor: 'dataset_type',
      cell: (row) => (
        <span className={`${styles.typeBadge} ${getTypeBadgeClass(row.dataset_type)}`}>
          {row.dataset_type}
        </span>
      )
    },
    {
      header: t('status'),
      accessor: 'dataset_status',
      cell: (row) => (
        <span className={`${styles.statusBadge} ${getStatusBadgeClass(row.dataset_status)}`}>
          {row.dataset_status}
        </span>
      )
    },
    {
      header: t('size'),
      accessor: 'size_in_gb',
      cell: (row) => <span>{formatSize(row.size_in_gb)}</span>
    },
    {
      header: t('records'),
      accessor: 'record_count',
      cell: (row) => <span>{formatNumber(row.record_count)}</span>
    },
    {
      header: t('updated_at'),
      accessor: 'updated_at',
      cell: (row) => <span>{formatDate(row.updated_at)}</span>
    },
    {
      header: t('actions'),
      accessor: 'actions',
      cell: (row) => (
        <div className={styles.actions}>
          <button 
            className={styles.actionButton}
            onClick={() => onViewDetail(row.dataset_id)}
          >
            <i className="fas fa-eye"></i>
            <span>{t('view')}</span>
          </button>
        </div>
      )
    }
  ];

  // Render create dataset form
  if (isCreateView) {
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <button className={styles.backButton} onClick={onBack} disabled={saving}>
            <i className="fas fa-arrow-left"></i>
            <span>{t('back')}</span>
          </button>
        </div>
        
        {error && (
          <div className={styles.error}>
            <i className="fas fa-exclamation-circle"></i>
            <span>{error}</span>
          </div>
        )}
        
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.formSection}>
            <h3 className={styles.sectionTitle}>{t('dataset_information')}</h3>
            <div className={styles.formGrid}>
              <div className={styles.formGroup}>
                <label htmlFor="dataset_name" className={styles.label}>
                  {t('dataset_name')} <span className={styles.required}>*</span>
                </label>
                <input
                  type="text"
                  id="dataset_name"
                  name="dataset_name"
                  className={styles.input}
                  value={formData.dataset_name}
                  onChange={handleChange}
                  required
                  disabled={saving}
                />
              </div>
              
              <div className={styles.formGroup}>
                <label htmlFor="enterprise_id" className={styles.label}>
                  {t('enterprise')} <span className={styles.required}>*</span>
                </label>
                <select
                  id="enterprise_id"
                  name="enterprise_id"
                  className={styles.select}
                  value={formData.enterprise_id}
                  onChange={handleChange}
                  required
                  disabled={saving}
                >
                  <option value="">{t('select_enterprise')}</option>
                  {enterprises.map((enterprise) => (
                    <option key={enterprise.enterprise_id} value={enterprise.enterprise_id}>
                      {enterprise.enterprise_name}
                    </option>
                  ))}
                </select>
              </div>
              
              <div className={styles.formGroup}>
                <label htmlFor="dataset_type" className={styles.label}>
                  {t('dataset_type')} <span className={styles.required}>*</span>
                </label>
                <select
                  id="dataset_type"
                  name="dataset_type"
                  className={styles.select}
                  value={formData.dataset_type}
                  onChange={handleChange}
                  required
                  disabled={saving}
                >
                  <option value="structured">{t('structured')}</option>
                  <option value="semi-structured">{t('semi_structured')}</option>
                  <option value="unstructured">{t('unstructured')}</option>
                </select>
              </div>
              
              <div className={styles.formGroup}>
                <label htmlFor="dataset_status" className={styles.label}>
                  {t('status')} <span className={styles.required}>*</span>
                </label>
                <select
                  id="dataset_status"
                  name="dataset_status"
                  className={styles.select}
                  value={formData.dataset_status}
                  onChange={handleChange}
                  required
                  disabled={saving}
                >
                  <option value="active">{t('active')}</option>
                  <option value="inactive">{t('inactive')}</option>
                  <option value="archived">{t('archived')}</option>
                </select>
              </div>
              
              <div className={styles.formGroupFull}>
                <label htmlFor="dataset_description" className={styles.label}>
                  {t('description')} <span className={styles.required}>*</span>
                </label>
                <textarea
                  id="dataset_description"
                  name="dataset_description"
                  className={styles.textarea}
                  value={formData.dataset_description}
                  onChange={handleChange}
                  rows={3}
                  required
                  disabled={saving}
                ></textarea>
              </div>
            </div>
          </div>
          
          <div className={styles.formSection}>
            <h3 className={styles.sectionTitle}>{t('storage_information')}</h3>
            <div className={styles.formGrid}>
              <div className={styles.formGroup}>
                <label htmlFor="storage_location" className={styles.label}>
                  {t('storage_location')} <span className={styles.required}>*</span>
                </label>
                <input
                  type="text"
                  id="storage_location"
                  name="storage_location"
                  className={styles.input}
                  value={formData.storage_location}
                  onChange={handleChange}
                  placeholder="s3://bucket-name/path"
                  required
                  disabled={saving}
                />
              </div>
              
              <div className={styles.formGroup}>
                <label htmlFor="region" className={styles.label}>
                  {t('region')} <span className={styles.required}>*</span>
                </label>
                <select
                  id="region"
                  name="region"
                  className={styles.select}
                  value={formData.region}
                  onChange={handleChange}
                  required
                  disabled={saving}
                >
                  <option value="">{t('select_region')}</option>
                  <option value="us-east-1">US East (N. Virginia)</option>
                  <option value="us-east-2">US East (Ohio)</option>
                  <option value="us-west-1">US West (N. California)</option>
                  <option value="us-west-2">US West (Oregon)</option>
                  <option value="eu-central-1">EU (Frankfurt)</option>
                  <option value="eu-west-1">EU (Ireland)</option>
                  <option value="ap-southeast-1">Asia Pacific (Singapore)</option>
                  <option value="ap-southeast-2">Asia Pacific (Sydney)</option>
                </select>
              </div>
              
              <div className={styles.formGroup}>
                <label htmlFor="format" className={styles.label}>
                  {t('format')} <span className={styles.required}>*</span>
                </label>
                <select
                  id="format"
                  name="format"
                  className={styles.select}
                  value={formData.format}
                  onChange={handleChange}
                  required
                  disabled={saving}
                >
                  <option value="csv">CSV</option>
                  <option value="json">JSON</option>
                  <option value="parquet">Parquet</option>
                  <option value="avro">Avro</option>
                  <option value="xml">XML</option>
                  <option value="text">Text</option>
                  <option value="binary">Binary</option>
                </select>
              </div>
              
              <div className={styles.formGroup}>
                <label htmlFor="retention_policy" className={styles.label}>
                  {t('retention_policy')} <span className={styles.required}>*</span>
                </label>
                <select
                  id="retention_policy"
                  name="retention_policy"
                  className={styles.select}
                  value={formData.retention_policy}
                  onChange={handleChange}
                  required
                  disabled={saving}
                >
                  <option value="7 days">7 days</option>
                  <option value="30 days">30 days</option>
                  <option value="90 days">90 days</option>
                  <option value="180 days">180 days</option>
                  <option value="1 year">1 year</option>
                  <option value="3 years">3 years</option>
                  <option value="5 years">5 years</option>
                  <option value="indefinite">Indefinite</option>
                </select>
              </div>
              
              <div className={styles.formGroupFull}>
                <label htmlFor="schema" className={styles.label}>
                  {t('schema')}
                </label>
                <textarea
                  id="schema"
                  name="schema"
                  className={styles.textarea}
                  value={formData.schema}
                  onChange={handleChange}
                  rows={5}
                  placeholder={`{
  "fields": [
    {"name": "id", "type": "string"},
    {"name": "timestamp", "type": "datetime"},
    {"name": "value", "type": "float"}
  ]
}`}
                  disabled={saving}
                ></textarea>
                <div className={styles.helpText}>
                  {t('schema_help_text')}
                </div>
              </div>
            </div>
          </div>
          
          <div className={styles.formActions}>
            <button 
              type="button" 
              className={styles.cancelButton} 
              onClick={onBack}
              disabled={saving}
            >
              {t('cancel')}
            </button>
            <button 
              type="submit" 
              className={styles.submitButton}
              disabled={saving}
            >
              {saving ? (
                <>
                  <span className={styles.spinner}></span>
                  <span>{t('creating')}</span>
                </>
              ) : (
                t('create_dataset')
              )}
            </button>
          </div>
        </form>
      </div>
    );
  }

  // Render dataset list view
  return (
    <div className={styles.container}>
      <div className={styles.toolbar}>
        <div className={styles.filters}>
          <SuperAdminSearch 
            placeholder={t('search_datasets')}
            onSearch={handleSearch}
          />
          
          <div className={styles.filterGroup}>
            <label htmlFor="statusFilter" className={styles.filterLabel}>
              {t('status')}:
            </label>
            <select
              id="statusFilter"
              className={styles.filterSelect}
              value={filterStatus}
              onChange={handleStatusFilterChange}
            >
              <option value="all">{t('all_statuses')}</option>
              <option value="active">{t('active')}</option>
              <option value="inactive">{t('inactive')}</option>
              <option value="archived">{t('archived')}</option>
            </select>
          </div>
          
          <div className={styles.filterGroup}>
            <label htmlFor="typeFilter" className={styles.filterLabel}>
              {t('type')}:
            </label>
            <select
              id="typeFilter"
              className={styles.filterSelect}
              value={filterType}
              onChange={handleTypeFilterChange}
            >
              <option value="all">{t('all_types')}</option>
              <option value="structured">{t('structured')}</option>
              <option value="semi-structured">{t('semi_structured')}</option>
              <option value="unstructured">{t('unstructured')}</option>
            </select>
          </div>
        </div>
        
        <button className={styles.createButton} onClick={onCreateNew}>
          <i className="fas fa-plus"></i>
          <span>{t('create_dataset')}</span>
        </button>
      </div>
      
      {error && <div className={styles.error}>{error}</div>}
      
      <SuperAdminTable
        columns={columns}
        data={datasets}
        loading={loading}
        emptyMessage={t('no_datasets_found')}
      />
      
      <div className={styles.pagination}>
        <SuperAdminPagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default DatasetList;