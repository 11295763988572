// File: frontend/src/components/PixelatedImage/PixelatedImage.js

import React, { useRef, useEffect, useState, useCallback, useMemo } from 'react';
import styles from './PixelatedImage.module.css';

const PixelatedImage = ({ src, pixelSize = 4, animationDuration = 3000, onAnimationComplete }) => {
  const canvasRef = useRef(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [error, setError] = useState(null);

  const hexToRgb = useCallback((hex) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [
      parseInt(result[1], 16),
      parseInt(result[2], 16),
      parseInt(result[3], 16)
    ] : null;
  }, []);

  const brandColors = useMemo(() => [
    '#EFF4FF', '#DBE6FE', '#BED3FF', '#92B7FE', '#5E91FC',
    '#3667F8', '#2348ED', '#1B35DA', '#1C2CB1', '#1D2B8B', '#161D55',
  ], []);

  const brandColorsRgb = useMemo(() => 
    brandColors.map(color => hexToRgb(color)),
    [brandColors, hexToRgb]
  );

  const mapColorToBrand = useCallback((r, g, b) => {
    let minDistance = Infinity;
    let closestColorIndex = 0;

    for (let i = 0; i < brandColorsRgb.length; i++) {
      const [br, bg, bb] = brandColorsRgb[i];
      const distance = (r - br) ** 2 + (g - bg) ** 2 + (b - bb) ** 2;
      if (distance < minDistance) {
        minDistance = distance;
        closestColorIndex = i;
      }
    }

    return brandColors[closestColorIndex];
  }, [brandColors, brandColorsRgb]);

  const animatePixels = useCallback((ctx, width, height, pixelSize, duration) => {
    const imageData = ctx.getImageData(0, 0, width, height);
    const pixels = imageData.data;
    const tempCanvas = document.createElement('canvas');
    tempCanvas.width = width;
    tempCanvas.height = height;
    const tempCtx = tempCanvas.getContext('2d', { willReadFrequently: true });

    const startTime = performance.now();
    const animate = (currentTime) => {
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / duration, 1);

      tempCtx.clearRect(0, 0, width, height);

      for (let y = 0; y < height; y += pixelSize) {
        for (let x = 0; x < width; x += pixelSize) {
          if (x / width < progress) {
            const i = (y * width + x) * 4;
            tempCtx.fillStyle = `rgba(${pixels[i]}, ${pixels[i + 1]}, ${pixels[i + 2]}, ${progress})`;
            tempCtx.fillRect(x, y, pixelSize, pixelSize);
          }
        }
      }

      ctx.clearRect(0, 0, width, height);
      ctx.drawImage(tempCanvas, 0, 0);

      if (progress < 1) {
        requestAnimationFrame(animate);
      } else {
        onAnimationComplete();
      }
    };

    requestAnimationFrame(animate);
  }, []);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImageLoaded(true);
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d', { alpha: false, willReadFrequently: true });
      
      const aspectRatio = img.width / img.height;
      const windowAspectRatio = window.innerWidth / window.innerHeight;
      
      let width, height;
      if (aspectRatio > windowAspectRatio) {
        height = window.innerHeight;
        width = height * aspectRatio;
      } else {
        width = window.innerWidth;
        height = width / aspectRatio;
      }

      canvas.width = width;
      canvas.height = height;

      ctx.drawImage(img, 0, 0, width, height);

      const imageData = ctx.getImageData(0, 0, width, height);
      const pixels = imageData.data;

      const pixelatedData = new ImageData(width, height);
      const pixelatedPixels = pixelatedData.data;

      for (let y = 0; y < height; y += pixelSize) {
        for (let x = 0; x < width; x += pixelSize) {
          const i = (y * width + x) * 4;
          const color = mapColorToBrand(pixels[i], pixels[i + 1], pixels[i + 2]);
          const [r, g, b] = hexToRgb(color);

          for (let py = 0; py < pixelSize && y + py < height; py++) {
            for (let px = 0; px < pixelSize && x + px < width; px++) {
              const pixelIndex = ((y + py) * width + (x + px)) * 4;
              pixelatedPixels[pixelIndex] = r;
              pixelatedPixels[pixelIndex + 1] = g;
              pixelatedPixels[pixelIndex + 2] = b;
              pixelatedPixels[pixelIndex + 3] = 255;
            }
          }
        }
      }

      ctx.putImageData(pixelatedData, 0, 0);
      animatePixels(ctx, width, height, pixelSize, animationDuration);
    };
    img.onerror = (e) => {
      console.error('Error loading image:', e);
      setError('Failed to load image');
    };
    img.src = src;
  }, [src, pixelSize, animationDuration, mapColorToBrand, animatePixels, hexToRgb]);

  return (
    <div className={styles.container}>
      {!imageLoaded && !error && <div className={styles.loader}>Loading...</div>}
      {error && <div className={styles.error}>{error}</div>}
      <canvas ref={canvasRef} className={styles.canvas} />
    </div>
  );
};

export default PixelatedImage;