// src/components/charts/Geography/GeographyProvinces.js
import React, { useEffect, useState } from 'react';
import MapboxWrapper from '../../Map/MapboxWrapper';

const GeographyProvinces = ({ width, height }) => {
  const [geoData, setGeoData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch('https://allegory-dashboard-maps-geojson-data.s3.ca-central-1.amazonaws.com/ofa9a9/driving-score/all_time/e18ce91a-247f-4a9e-a237-c23c73b38e36.json')
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        console.log("GeoJSON data loaded successfully:", data);
        setGeoData(data);
      })
      .catch(error => {
        console.error("Error loading the geojson data:", error);
        setError(error.message);
      });
  }, []);

  if (error) {
    return <div>Error loading map data: {error}</div>;
  }

  if (!geoData) {
    return <div>Data is loading. This may take a few seconds...</div>;
  }

  const data = [{
    type: "choroplethmapbox",
    geojson: geoData,
    locations: geoData.features.map(feature => feature.id),
    z: geoData.features.map(feature => feature.properties.percentage),
    colorscale: [
      [0.0, '#FBB1BD'], [0.1, '#FF99AC'], [0.2, '#FF99AC'],
      [0.3, '#FF85A1'], [0.4, '#FF85A1'], [0.5, '#FF7096'],
      [0.6, '#FF7096'], [0.7, '#FF477E'], [0.8, '#FF477E'],
      [0.9, '#FF0A54'], [1.0, '#FF0A54']
    ],
    zmin: 0,
    zmax: 100,
    colorbar: {
      title: "Percentage",
      thickness: 20
    }
  }];

  const layout = {
    mapbox: {
      center: { lon: -80, lat: 44 },
      zoom: 3
    },
    width: width || 1146,  // Provide a default value if width is undefined
    height: height || 684, // Provide a default value if height is undefined
    margin: { t: 0, b: 50 }
  };

  console.log("Rendering map with dimensions:", { width, height });

  return (
    <MapboxWrapper
      data={data}
      layout={layout}
      style={{ width: "100%", height: "100%" }}
    />
  );
};

export default GeographyProvinces;