// File: frontend/src/pages/TripDetail/index.js

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useLanguage } from '../../contexts/LanguageContext';
import TripHeader from './components/TripHeader';
import TripOverview from './components/TripOverview';
import TripRoute from './components/TripRoute';
import TripSpeed from './components/TripSpeed';
import TripScoring from './components/TripScoring';
import TripEvents from './components/TripEvents';
import TripEngagement from './components/TripEngagement';
import TripLocation from './components/TripLocation';
import TripVehicle from './components/TripVehicle';
import TripErrors from './components/TripErrors';
import styles from './TripDetail.module.css';

const TripDetail = () => {
  const { tripId } = useParams();
  const navigate = useNavigate();
  const { t } = useLanguage();
  const [activeTab, setActiveTab] = useState('overview');
  const [trip, setTrip] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTripDetails = async () => {
      setLoading(true);
      setError(null);
      
      try {
        // This would be replaced with an actual API call
        // const response = await tripService.getTripDetails(tripId);
        
        // Mock data for now
        await new Promise(resolve => setTimeout(resolve, 800));
        
        const mockTripData = {
          trip_id: tripId,
          user_id: "user-12345",
          device_id: "device-12345",
          vehicle_id: "vehicle-12345",
          trip_status: "completed",
          trip_start_timestamp_epoch: 1685608200,
          trip_start_timestamp: "2023-06-01T08:30:00Z",
          trip_start_timestamp_local: "2023-06-01T04:30:00-04:00",
          trip_finished_timestamp_epoch: 1685610900,
          trip_finished_timestamp: "2023-06-01T09:15:00Z",
          trip_finished_timestamp_local: "2023-06-01T05:15:00-04:00",
          trip_started_place_id: "place-12345",
          trip_started_location: {
            latitude: 37.7749,
            longitude: -122.4194
          },
          trip_finished_place_id: "place-12346",
          trip_finished_location: {
            latitude: 37.7833,
            longitude: -122.4167
          },
          distance_travelled_odometer: 26.2,
          distance_travelled_calculation: 25.4,
          missing_distance: 0.8,
          trip_duration_seconds: 2700,
          missing_seconds: 0,
          not_connected_seconds: 0,
          extreme_accel_events: 2,
          average_speed: 35.2,
          maximum_speed: 65.8,
          acceleration_score: 82,
          braking_score: 88,
          speeding_score: 95,
          cornering_score: 90,
          fatigue_driving_score: 100,
          distracted_driving_score: 95,
          contextual_score: 92,
          overall_score: 90,
          is_night_driving: false,
          is_peak_driving: true,
          is_weekend_driving: false,
          is_driver: true,
          trip_type_id: 1,
          trip_mode_transportation_id: 1,
          idling_time_seconds: 120,
          created_at: "2023-06-01T09:20:00Z",
          updated_at: "2023-06-01T09:25:00Z",
          
          filtered_locations: {
            trip_id: tripId,
            user_id: "user-12345",
            filtered_latitudes: [37.7749, 37.7751, 37.7755, 37.7762, 37.7770, 37.7780, 37.7790, 37.7800, 37.7810, 37.7820, 37.7833],
            filtered_longitudes: [-122.4194, -122.4192, -122.4190, -122.4187, -122.4183, -122.4180, -122.4177, -122.4173, -122.4170, -122.4168, -122.4167],
            filtered_place_ids: ["place-12345", null, null, null, null, null, null, null, null, null, "place-12346"],
            original_indices: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50],
            updated_at: "2023-06-01T09:25:00Z"
          },
          
          filtered_locations_aggs: {
            trip_id: tripId,
            user_id: "user-12345",
            trip_start_coordinates: {
              latitude: 37.7749,
              longitude: -122.4194
            },
            trip_midpoint_coordinates: {
              latitude: 37.7790,
              longitude: -122.4177
            },
            trip_finished_coordinates: {
              latitude: 37.7833,
              longitude: -122.4167
            },
            trip_start_address: {
              street: "Market St",
              city: "San Francisco",
              state: "CA",
              country: "USA",
              postal_code: "94103"
            },
            trip_finished_address: {
              street: "Powell St",
              city: "San Francisco",
              state: "CA",
              country: "USA",
              postal_code: "94108"
            },
            updated_at: "2023-06-01T09:25:00Z"
          },
          
          events: {
            trip_id: tripId,
            user_id: "user-12345",
            acceleration_events: [
              {
                timestamp: "2023-06-01T08:35:00Z",
                latitude: 37.7751,
                longitude: -122.4192,
                value: 3.2,
                severity: "medium"
              },
              {
                timestamp: "2023-06-01T08:50:00Z",
                latitude: 37.7780,
                longitude: -122.4180,
                value: 4.5,
                severity: "high"
              }
            ],
            braking_events: [
              {
                timestamp: "2023-06-01T08:40:00Z",
                latitude: 37.7762,
                longitude: -122.4187,
                value: -3.8,
                severity: "medium"
              }
            ],
            cornering_events: [
              {
                timestamp: "2023-06-01T09:00:00Z",
                latitude: 37.7810,
                longitude: -122.4170,
                value: 2.9,
                severity: "low"
              }
            ],
            speeding_events: [],
            fatigue_driving_events: [],
            night_driving_events: [],
            distracted_driving_events: [
              {
                timestamp: "2023-06-01T08:45:00Z",
                latitude: 37.7770,
                longitude: -122.4183,
                duration: 15,
                type: "phone_usage"
              }
            ],
            peak_driving_events: [
              {
                timestamp: "2023-06-01T08:30:00Z",
                latitude: 37.7749,
                longitude: -122.4194,
                duration: 2700,
                type: "morning_rush"
              }
            ],
            distracted_driving_duration: 15,
            updated_at: "2023-06-01T09:25:00Z"
          },
          
          speeds: {
            trip_id: tripId,
            user_id: "user-12345",
            speed_json: {
              timestamps: [
                "2023-06-01T08:30:00Z", "2023-06-01T08:35:00Z", "2023-06-01T08:40:00Z", 
                "2023-06-01T08:45:00Z", "2023-06-01T08:50:00Z", "2023-06-01T08:55:00Z", 
                "2023-06-01T09:00:00Z", "2023-06-01T09:05:00Z", "2023-06-01T09:10:00Z", 
                "2023-06-01T09:15:00Z"
              ],
              speeds: [0, 25, 40, 35, 45, 50, 40, 30, 20, 0],
              speed_limits: [35, 35, 45, 45, 45, 55, 45, 35, 25, 25]
            },
            updated_at: "2023-06-01T09:25:00Z"
          },
          
          engagement: {
            trip_id: tripId,
            user_id: "user-12345",
            engagement_scores: {
              v1: {
                engagement_score: 85,
                engagement_factors: {
                  trip_completion: 100,
                  feedback_provided: 0,
                  app_interaction: 75
                }
              },
              v2: {
                engagement_score: 88,
                engagement_factors: {
                  trip_completion: 100,
                  feedback_provided: 0,
                  app_interaction: 75,
                  social_sharing: 50
                }
              }
            },
            updated_at: "2023-06-01T09:25:00Z"
          },
          
          deciles: {
            trip_id: tripId,
            user_id: "user-12345",
            overall_score: 90,
            acceleration_score: 82,
            braking_score: 88,
            speeding_score: 95,
            cornering_score: 90,
            fatigue_driving_score: 100,
            distracted_driving_score: 95,
            contextual_score: 92,
            score_deciles: {
              v1: {
                overall_score_decile: 8,
                acceleration_score_decile: 7,
                braking_score_decile: 8,
                speeding_score_decile: 9,
                cornering_score_decile: 8
              },
              v2: {
                overall_score_decile: 8,
                acceleration_score_decile: 7,
                braking_score_decile: 8,
                speeding_score_decile: 9,
                cornering_score_decile: 8,
                fatigue_driving_score_decile: 10,
                distracted_driving_score_decile: 9,
                contextual_score_decile: 9
              }
            },
            updated_at: "2023-06-01T09:25:00Z"
          }
        };
        
        setTrip(mockTripData);
      } catch (err) {
        console.error('Error fetching trip details:', err);
        setError(t('failed_to_load_trip_details'));
      } finally {
        setLoading(false);
      }
    };

    if (tripId) {
      fetchTripDetails();
    }
  }, [tripId, t]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleBack = () => {
    navigate('/trips');
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        {t('loading_trip_details')}
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.error}>
        <h2>{t('error_loading_trip')}</h2>
        <p>{error}</p>
        <button className={styles.backButton} onClick={handleBack}>
          {t('back_to_trip_insight')}
        </button>
      </div>
    );
  }

  if (!trip) {
    return (
      <div className={styles.notFound}>
        <h2>{t('trip_not_found')}</h2>
        <p>{t('trip_not_found_message')}</p>
        <button className={styles.backButton} onClick={handleBack}>
          {t('back_to_trip_insight')}
        </button>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <TripHeader trip={trip} onBack={handleBack} />
      
      <div className={styles.content}>
        <div className={styles.tabs}>
          <button 
            className={`${styles.tab} ${activeTab === 'overview' ? styles.activeTab : ''}`}
            onClick={() => handleTabChange('overview')}
          >
            {t('overview')}
          </button>
          <button 
            className={`${styles.tab} ${activeTab === 'location' ? styles.activeTab : ''}`}
            onClick={() => handleTabChange('location')}
          >
            {t('location')}
          </button>
          <button 
            className={`${styles.tab} ${activeTab === 'route' ? styles.activeTab : ''}`}
            onClick={() => handleTabChange('route')}
          >
            {t('route')}
          </button>
          <button 
            className={`${styles.tab} ${activeTab === 'speed' ? styles.activeTab : ''}`}
            onClick={() => handleTabChange('speed')}
          >
            {t('speed')}
          </button>
          <button 
            className={`${styles.tab} ${activeTab === 'scoring' ? styles.activeTab : ''}`}
            onClick={() => handleTabChange('scoring')}
          >
            {t('scoring')}
          </button>
          <button 
            className={`${styles.tab} ${activeTab === 'events' ? styles.activeTab : ''}`}
            onClick={() => handleTabChange('events')}
          >
            {t('events')}
          </button>
          <button 
            className={`${styles.tab} ${activeTab === 'vehicle' ? styles.activeTab : ''}`}
            onClick={() => handleTabChange('vehicle')}
          >
            {t('vehicle')}
          </button>
          <button 
            className={`${styles.tab} ${activeTab === 'engagement' ? styles.activeTab : ''}`}
            onClick={() => handleTabChange('engagement')}
          >
            {t('engagement')}
          </button>
          <button 
            className={`${styles.tab} ${activeTab === 'errors' ? styles.activeTab : ''}`}
            onClick={() => handleTabChange('errors')}
          >
            {t('errors')}
          </button>
        </div>
        
        <div className={styles.tabContent}>
          {activeTab === 'overview' && <TripOverview trip={trip} />}
          {activeTab === 'location' && <TripLocation trip={trip} />}
          {activeTab === 'route' && <TripRoute trip={trip} />}
          {activeTab === 'speed' && <TripSpeed trip={trip} />}
          {activeTab === 'scoring' && <TripScoring trip={trip} />}
          {activeTab === 'events' && <TripEvents trip={trip} />}
          {activeTab === 'vehicle' && <TripVehicle trip={trip} />}
          {activeTab === 'engagement' && <TripEngagement trip={trip} />}
          {activeTab === 'errors' && <TripErrors trip={trip} />}
        </div>
      </div>
    </div>
  );
};

export default TripDetail;