// File: frontend/src/pages/TripInsight/components/TripSearchResults/index.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../../../contexts/LanguageContext';
import styles from './TripSearchResults.module.css';

const TripSearchResults = ({ 
  results, 
  loading, 
  onPageChange, 
  currentPage, 
  totalPages, 
  totalResults 
}) => {
  const { t } = useLanguage();
  const navigate = useNavigate();

  // Helper function to format date
  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  // Helper function to format duration
  const formatDuration = (seconds) => {
    if (!seconds) return 'N/A';
    
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    
    if (hours > 0) {
      return `${hours}h ${minutes}m`;
    }
    return `${minutes}m`;
  };

  const handleViewTrip = (tripId) => {
    navigate(`/trips/trip/${tripId}`);
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        {t('searching_trips')}
      </div>
    );
  }

  if (!results || results.length === 0) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.resultsHeader}>
        <h2 className={styles.title}>{t('search_results')}</h2>
        {totalResults > 0 && (
          <div className={styles.resultCount}>
            {t('showing_results', { 
              count: results.length, 
              total: totalResults || results.length 
            })}
          </div>
        )}
      </div>
      
      <div className={styles.resultsList}>
        {results.map((trip) => (
          <div key={trip.trip_id} className={styles.resultCard}>
            <div className={styles.resultInfo}>
              <div className={styles.resultRow}>
                <div className={styles.resultField}>
                  <span className={styles.fieldLabel}>{t('trip_id')}:</span>
                  <span className={styles.fieldValue}>{trip.trip_id}</span>
                </div>
                <div className={styles.resultField}>
                  <span className={styles.fieldLabel}>{t('user_id')}:</span>
                  <span className={styles.fieldValue}>{trip.user_id || 'N/A'}</span>
                </div>
              </div>
              
              <div className={styles.resultRow}>
                <div className={styles.resultField}>
                  <span className={styles.fieldLabel}>{t('start_time')}:</span>
                  <span className={styles.fieldValue}>{formatDate(trip.trip_start_timestamp)}</span>
                </div>
                <div className={styles.resultField}>
                  <span className={styles.fieldLabel}>{t('end_time')}:</span>
                  <span className={styles.fieldValue}>{formatDate(trip.trip_finished_timestamp)}</span>
                </div>
              </div>
              
              <div className={styles.resultRow}>
                <div className={styles.resultField}>
                  <span className={styles.fieldLabel}>{t('distance')}:</span>
                  <span className={styles.fieldValue}>{trip.distance_travelled_calculation} km</span>
                </div>
                <div className={styles.resultField}>
                  <span className={styles.fieldLabel}>{t('duration')}:</span>
                  <span className={styles.fieldValue}>{formatDuration(trip.trip_duration_seconds)}</span>
                </div>
              </div>
              
              <div className={styles.resultRow}>
                <div className={styles.resultField}>
                  <span className={styles.fieldLabel}>{t('average_speed')}:</span>
                  <span className={styles.fieldValue}>{trip.average_speed} km/h</span>
                </div>
                <div className={styles.resultField}>
                  <span className={styles.fieldLabel}>{t('overall_score')}:</span>
                  <span className={styles.fieldValue}>{trip.overall_score}</span>
                </div>
              </div>
            </div>
            
            <div className={styles.resultActions}>
              <button 
                className={styles.viewButton}
                onClick={() => handleViewTrip(trip.trip_id)}
              >
                {t('view_trip')}
              </button>
            </div>
          </div>
        ))}
      </div>
      
      {totalPages > 1 && (
        <div className={styles.pagination}>
          <button 
            className={styles.paginationButton}
            disabled={currentPage <= 1}
            onClick={() => onPageChange(currentPage - 1)}
          >
            {t('previous')}
          </button>
          
          <span className={styles.pageInfo}>
            {t('page_of_total', { page: currentPage, total: totalPages })}
          </span>
          
          <button 
            className={styles.paginationButton}
            disabled={currentPage >= totalPages}
            onClick={() => onPageChange(currentPage + 1)}
          >
            {t('next')}
          </button>
        </div>
      )}
    </div>
  );
};

export default TripSearchResults;