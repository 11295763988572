// /charts/v2/types/ColumnChart.js
import React from 'react';
import {
    BarChart as RechartsBarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from 'recharts';
import ChartWrapper from '../core/ChartWrapper';
import ChartContainer from '../core/ChartContainer';
import { formatValue } from '../utils/formatters';
import styles from './ColumnChart.module.css';

const CustomTooltip = ({ active, payload, label, format }) => {
    if (!active || !payload || !payload.length) return null;

    return (
        <div className={styles.tooltip}>
            <p className={styles.tooltipLabel}>{label}</p>
            {payload.map((entry, index) => (
                <div key={index} className={styles.tooltipRow}>
                    <span className={styles.tooltipMetric} style={{ color: entry.color }}>
                        {entry.name}
                    </span>
                    <span className={styles.tooltipValue}>
                        {format === 'currency' 
                            ? `$${entry.value.toLocaleString()}M`
                            : entry.value.toLocaleString()}
                    </span>
                </div>
            ))}
        </div>
    );
};

const ColumnChart = ({
    data,
    title,
    subtitle,
    stacked = false,
    format = 'currency',
    height = 400,
    showTable = false,
}) => {
    // Transform data for the chart
    const chartData = data.labels.map((label, index) => ({
        name: label,
        ...data.datasets.reduce((acc, dataset) => {
            acc[dataset.label] = dataset.data[index] / 1000000; // Convert to millions
            return acc;
        }, {})
    }));

    return (
        <ChartWrapper title={title} subtitle={subtitle}>
            <div className={styles.chartContainer}>
                <ChartContainer height={height}>
                    <ResponsiveContainer width="100%" height="100%">
                        <RechartsBarChart
                            data={chartData}
                            margin={{ top: 20, right: 30, left: 10, bottom: 20 }}
                            barSize={40}
                            barGap={stacked ? 0 : 4}
                            barCategoryGap={stacked ? 40 : 32}
                        >
                            <CartesianGrid 
                                strokeDasharray="3 3" 
                                vertical={false}
                                stroke="var(--color-border)"
                                strokeOpacity={0.5}
                            />
                            <XAxis
                                dataKey="name"
                                axisLine={false}
                                tickLine={false}
                                tick={{
                                    fill: 'var(--text-secondary)',
                                    fontSize: 12,
                                    fontFamily: 'var(--font-primary)'
                                }}
                                dy={8}
                            />
                            <YAxis
                                axisLine={false}
                                tickLine={false}
                                tick={{
                                    fill: 'var(--text-secondary)',
                                    fontSize: 12,
                                    fontFamily: 'var(--font-primary)'
                                }}
                                tickFormatter={(value) => {
                                    if (value >= 1000) {
                                        return `${(value/1000).toLocaleString()}B`;
                                    }
                                    return `${value.toLocaleString()}M`;
                                }}
                                dx={-8}
                            />
                            <Tooltip
                                content={<CustomTooltip format={format} />}
                                cursor={{ 
                                    fill: 'var(--color-border)',
                                    opacity: 0.1
                                }}
                            />
                            <Legend
                                verticalAlign="bottom"
                                height={36}
                                wrapperStyle={{
                                    paddingTop: 'var(--spacing-md)',
                                    fontSize: 'var(--font-size-sm)',
                                    fontFamily: 'var(--font-primary)'
                                }}
                            />
                            {data.datasets.map((dataset, index) => (
                                <Bar
                                    key={dataset.label}
                                    dataKey={dataset.label}
                                    stackId={stacked ? "stack" : undefined}
                                    fill={dataset.backgroundColor}
                                    radius={[4, 4, 0, 0]}
                                    maxBarSize={60}
                                />
                            ))}
                        </RechartsBarChart>
                    </ResponsiveContainer>
                </ChartContainer>

                {showTable && (
                    <div className={styles.tableContainer}>
                        <table className={styles.dataTable}>
                            <thead>
                                <tr>
                                    <th>Category</th>
                                    {data.labels.map(label => (
                                        <th key={label}>{label}</th>
                                    ))}
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.datasets.map((dataset) => (
                                    <tr key={dataset.label}>
                                        <td style={{ 
                                            borderLeft: `3px solid ${dataset.backgroundColor}` 
                                        }}>
                                            {dataset.label}
                                        </td>
                                        {dataset.data.map((value, j) => (
                                            <td key={j}>{formatValue(value, format)}</td>
                                        ))}
                                        <td className={styles.rowTotal}>
                                            {formatValue(
                                                dataset.data.reduce((a, b) => a + b, 0),
                                                format
                                            )}
                                        </td>
                                    </tr>
                                ))}
                                <tr className={styles.totalRow}>
                                    <td>Total</td>
                                    {data.labels.map((_, index) => (
                                        <td key={index} className={styles.grandTotal}>
                                            {formatValue(
                                                data.datasets.reduce(
                                                    (sum, dataset) => sum + dataset.data[index],
                                                    0
                                                ),
                                                format
                                            )}
                                        </td>
                                    ))}
                                    <td className={styles.grandTotal}>
                                        {formatValue(
                                            data.datasets.reduce(
                                                (sum, dataset) => sum + dataset.data.reduce((a, b) => a + b, 0),
                                                0
                                            ),
                                            format
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </ChartWrapper>
    );
};

export default ColumnChart;