// File: frontend/src/pages/InvestorPitch/configs/CustomerBrokersConfig.js

import React from 'react';
import * as Slides from '../slides';

export const getCustomerBrokersSlides = (t) => [
  {
    id: 'intro',
    title: t('investor_pitch.slides.intro.title'),
    content: <Slides.IntroSlide />
  },
  {
    id: 'technology',
    title: t('investor_pitch.slides.technology.title'),
    content: <Slides.TechnologySlide />
  },
  {
    id: 'market-impact',
    title: t('investor_pitch.slides.market_impact.title'),
    content: <Slides.MarketImpactSlide />
  },
  {
    id: 'partnerships',
    title: t('investor_pitch.slides.partnerships.title'),
    content: <Slides.PartnershipsSlide />
  },
  {
    id: 'demo',
    title: t('investor_pitch.slides.demo.title'),
    content: <Slides.DemoSlide />
  },
  {
    id: 'closing',
    title: t('investor_pitch.slides.closing.title'),
    content: <Slides.ClosingSlide />
  }
];