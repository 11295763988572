// File: frontend/src/pages/InvestorPitch/components/slides/MarketPosition/competitors.data.js

export const COMPETITOR_DATA = {
  CARRIERS: {
    companies: [
      {
        id: 'lemonade',
        name: 'LEMONADE',
        // website: 'https://www.lemonade.com',
        founded: 2015,
        latest_valuation_date: '2025-03-31',
        latest_valuation: 2360000000, // $1.9 billion at IPO
        strengths: [
          'Utilizes artificial intelligence and chatbots to streamline claims processing, enhancing customer experience.',
          'Certified B-Corporation, emphasizing social responsibility.'
        ],
        weaknesses: [
          'Reported net losses, indicating financial challenges.',
          'Limited product offerings compared to traditional insurers.'
        ]
      },
      {
        id: 'root',
        name: 'ROOT',
        // website: 'https://www.root.com',
        founded: 2015,
        latest_valuation_date: '2025-03-31',
        latest_valuation: 2080000000, // $7.24 billion at IPO
        strengths: [
          'Leverages telematics to offer personalized auto insurance rates based on driving behavior.',
          'Strong technological infrastructure supporting user-friendly mobile app.'
        ],
        weaknesses: [
          'High customer acquisition costs impacting profitability.',
          'Exposure to regulatory changes affecting telematics-based insurance models.'
        ]
      },
      {
        id: 'next',
        name: 'NEXT',
        // website: 'https://www.nextinsurance.com',
        founded: 2016,
        latest_valuation_date: '2025-03-20',
        latest_valuation: 2600000000, // $4 billion
        strengths: [
          'Focuses on small business insurance with tailored policies for various industries.',
          'Utilizes AI to simplify and expedite the insurance purchasing process.',
          'The company has raised nearly $1.2 billion in its lifetime, according to Crunchbase.',
          'Germany’s Munich Re signed a definitive agreement to acquire Next Insurance for $2.6 billion on March 20, 2025.',
        ],
        weaknesses: [
          'Limited brand recognition compared to established insurers.',
          'Challenges in scaling operations across diverse small business sectors.'
        ]
      },
      {
        id: 'hippo',
        name: 'HIPPO',
        // website: 'https://www.hippo.com',
        founded: 2015,
        latest_valuation_date: '2025-03-31',
        latest_valuation: 641088000, // $5 billion at IPO
        strengths: [
          'Integrates smart home technology to proactively mitigate risks and prevent claims.',
          'Offers a streamlined, user-friendly online platform for policy management.',
          'Valued $5 billion at IPO on August 3, 2021.',
        ],
        weaknesses: [
          'Significant net losses reported, indicating financial instability.',
          'Stock price volatility post-IPO, reflecting market uncertainty.'
        ]
      },
      {
        id: 'kin',
        name: 'KIN',
        // website: 'https://www.kin.com',
        founded: 2016,
        latest_valuation_date: '2024-02-06',
        latest_valuation: 1000000000, // $1 billion
        strengths: [
          'Data-driven platform offering home insurance in high-risk areas.',
          'Achieved unicorn status with a $1 billion valuation.'
        ],
        weaknesses: [
          'Limited geographic coverage compared to larger insurers.',
          'Potential exposure to catastrophic events in high-risk regions.'
        ]
      },
      {
        id: 'belairdirect',
        name: 'BELAIRDIRECT',
        // website: 'https://www.belairdirect.com',
        founded: 1955,
        latest_valuation_date: '2025-03-31',
        latest_valuation: 10000000000, // $10 billion (assumed one-fifth of Intact Financial Corporation's valuation)
        strengths: [
          'Established brand with a long history in the Canadian insurance market.',
          'Comprehensive product offerings including auto and home insurance.'
        ],
        weaknesses: [
          'Market concentration primarily in Canada, limiting international reach.',
          'Potential challenges in adapting to rapidly evolving insurtech innovations.'
        ]
      }
    ]
  },
  INFRASTRUCTURE: {
    companies: [
      {
        id: 'guidewire',
        name: 'GUIDEWIRE',
        // website: 'https://www.guidewire.com',
        founded: 2001,
        latest_valuation_date: '2025-03-31',
        latest_valuation: 8000000000, // $8 billion
        strengths: [
          'Leading provider of software solutions for property and casualty insurers.',
          'Robust suite of products supporting underwriting, policy administration, and claims management.'
        ],
        weaknesses: [
          'High implementation costs may deter smaller insurers.',
          'Dependence on the P&C insurance market exposes it to industry-specific risks.'
        ]
      },
      {
        id: 'duck_creek',
        name: 'DUCKCREEK',
        // website: 'https://www.duckcreek.com',
        founded: 2000,
        latest_valuation_date: '2025-03-31',
        latest_valuation: 5000000000, // $5 billion
        strengths: [
          'Offers comprehensive SaaS solutions for the insurance industry.',
          'Strong focus on innovation and cloud-based technologies.'
        ],
        weaknesses: [
          'Faces competition from larger, more established tech firms entering the insurance space.',
          'Potential challenges in maintaining rapid growth trajectory.'
        ]
      },
      {
        id: 'britecore',
        name: 'BRITECORE',
        // website: 'https://www.britecore.com',
        founded: 2009,
        latest_valuation_date: '2025-03-31',
        latest_valuation: 1000000000, // $1 billion (assumed)
        strengths: [
          'Provides modern core systems for property and casualty insurers.',
          'Emphasizes flexibility and configurability in its platform.'
        ],
        weaknesses: [
          'Smaller market share compared to industry giants.',
          'Limited resources for large-scale marketing and expansion.'
        ]
      },
      {
        id: 'applied',
        name: 'APPLIED',
        // website: 'https://www.appliedsystems.com',
        founded: 1983,
        latest_valuation_date: '2025-03-31',
        latest_valuation: 4000000000, // $4 billion (assumed)
        strengths: [
          'Long-standing provider of cloud-based software for insurance agencies and brokers.',
          'Extensive network and established relationships within the insurance industry.'
        ],
        weaknesses: [
          'May face challenges in modernizing legacy systems.',
          'Competition from newer, more agile insurtech startups.'
        ]
      },
      {
        id: 'socotra',
        name: 'SOCOTRA',
        // website: 'https://www.socotra.com',
        founded: 2014,
        latest_valuation_date: '2025-03-31',
        latest_valuation: 1000000000, // $1 billion (assumed)
        strengths: [
          'Offers a flexible, cloud-native core platform for insurers.',
          'Focus on rapid deployment and scalability.'
        ],
        weaknesses: [
          'Relatively new entrant with limited track record.',
          'Smaller client base compared to legacy providers.'
        ]
      },
      {
        id: 'majesco',
        name: 'MAJESCO',
        // website: 'https://www.majesco.com',
        founded: 1982,
        latest_valuation_date: '2020-09-21',
        latest_valuation: 594000000, // acquired by Thoma Bravo in 2020 for $594M
        strengths: [
          'Deep domain expertise in insurance software solutions.',
          'Strong analytics and digital transformation tools.'
        ],
        weaknesses: [
          'Under private equity ownership, growth visibility may be less transparent.',
          'Product modernization challenges compared to cloud-native competitors.'
        ]
      }
    ]
  },
  AGGREGATORS: {
    companies: [
      {
        id: 'policy_genius',
        name: 'POLICYGENIUS',
        // website: 'https://www.policygenius.com',
        founded: 2014,
        latest_valuation_date: '2022-03-17',
        latest_valuation: 500000000, // $500 million
        strengths: [
          'User-friendly platform comparing life, home, auto, and disability insurance.',
          'Strong content marketing and consumer education strategy.'
        ],
        weaknesses: [
          'Highly competitive aggregator landscape.',
          'Dependent on affiliate commissions and partner availability.'
        ]
      },
      {
        id: 'hippo_agg',
        name: 'HIPPO',
        // website: 'https://www.hippo.com',
        founded: 2015,
        latest_valuation_date: '2021-08-03',
        latest_valuation: 5000000000, // $5B as public company; aggregator value closer to $3B
        strengths: [
          'Combines direct-to-consumer model with marketplace offerings.',
          'Backed by strong reinsurers like Munich Re.'
        ],
        weaknesses: [
          'Struggled with losses and restructuring post-SPAC merger.',
          'Mixed investor sentiment due to inconsistent profitability.'
        ]
      },
      {
        id: 'jerry',
        name: 'JERRY',
        // website: 'https://www.getjerry.com',
        founded: 2017,
        latest_valuation_date: '2021-08-24',
        latest_valuation: 450000000, // $450 million
        strengths: [
          'Mobile-first experience for auto insurance shopping and switching.',
          'Built-in telematics for deeper insights and better matching.'
        ],
        weaknesses: [
          'Limited brand awareness outside digital-native audiences.',
          'Focus mostly on auto, limiting cross-sell potential.'
        ]
      },
      {
        id: 'insurify',
        name: 'INSURIFY',
        // website: 'https://www.insurify.com',
        founded: 2013,
        latest_valuation_date: '2022-01-26',
        latest_valuation: 100000000, // $100 million
        strengths: [
          'AI-powered comparison engine across multiple lines.',
          'Expanding network of insurer partners.'
        ],
        weaknesses: [
          'Valuation decreased in recent years amid market shifts.',
          'Suffers from low brand differentiation vs. competitors.'
        ]
      },
      {
        id: 'everquote',
        name: 'EVERQUOTE',
        // website: 'https://www.everquote.com',
        founded: 2011,
        latest_valuation_date: '2024-12-31',
        latest_valuation: 600000000, // ~$600M market cap
        strengths: [
          'Publicly traded with transparent performance metrics.',
          'Strong footprint in auto insurance lead generation.'
        ],
        weaknesses: [
          'Revenue heavily reliant on traffic volume and paid ads.',
          'Profitability challenges and tight margins.'
        ]
      },
      {
        id: 'policybazaar',
        name: 'POLICYBAZAAR',
        // website: 'https://www.policybazaar.com',
        founded: 2008,
        latest_valuation_date: '2021-11-15',
        latest_valuation: 2800000000, // $2.8 billion post-IPO
        strengths: [
          'Dominant position in the Indian insurance comparison market.',
          'Diversified into lending and health marketplaces.'
        ],
        weaknesses: [
          'Exposed to regulatory shifts in Indian financial markets.',
          'International expansion still in early stages.'
        ]
      }
    ]
  }
};
