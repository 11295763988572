// File: frontend/src/pages/UMS/components/UserSearchResults/index.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../../../contexts/LanguageContext';
import styles from './UserSearchResults.module.css';

// Helper function to format date in YYYY-MM-DD hh:mm AM/PM format
const formatDateTime = (dateString) => {
  if (!dateString) return 'N/A';
  
  const date = new Date(dateString);
  
  // Check if date is valid
  if (isNaN(date.getTime())) return 'Invalid Date';
  
  // Format date as YYYY-MM-DD
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  
  // Format time as hh:mm AM/PM
  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  
  // Convert hours to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const formattedHours = String(hours).padStart(2, '0');
  
  return `${year}-${month}-${day} ${formattedHours}:${minutes} ${ampm}`;
};

const UserSearchResults = ({ 
  results, 
  loading, 
  onPageChange, 
  currentPage, 
  totalPages, 
  totalResults 
}) => {
  const { t } = useLanguage();
  const navigate = useNavigate();

  const handleViewUser = (userId) => {
    navigate(`/ums/${userId}`);
  };

  const getAccountStatusClass = (status) => {
    switch (status) {
      case 495: return styles.active;
      case 494: return styles.pending;
      case 496: return styles.suspended;
      case 497: return styles.deactivated;
      case 498: return styles.locked;
      default: return styles.unknown;
    }
  };

  const getAccountStatusText = (status) => {
    switch (status) {
      case 495: return t('active');
      case 494: return t('pending_activation');
      case 496: return t('suspended');
      case 497: return t('deactivated');
      case 498: return t('locked');
      default: return t('unknown');
    }
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        {t('searching_users')}
      </div>
    );
  }

  if (!results || results.length === 0) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.resultsHeader}>
        <h2 className={styles.title}>{t('search_results')}</h2>
        {totalResults > 0 && (
          <div className={styles.resultCount}>
            {t('showing_results', { 
              count: results.length, 
              total: totalResults || results.length 
            })}
          </div>
        )}
      </div>
      
      <div className={styles.resultsList}>
        {results.map((user) => (
          <div key={user.user_id} className={styles.resultCard}>
            <div className={styles.resultInfo}>
              <div className={styles.resultRow}>
                <div className={styles.resultField}>
                  <span className={styles.fieldLabel}>{t('user_id')}:</span>
                  <span className={styles.fieldValue}>{user.user_id}</span>
                </div>
                <div className={styles.resultField}>
                  <span className={styles.fieldLabel}>{t('account_status')}:</span>
                  <span className={`${styles.fieldValue} ${getAccountStatusClass(user.account_status)}`}>
                    {getAccountStatusText(user.account_status)}
                  </span>
                </div>
              </div>
              
              <div className={styles.resultRow}>
                <div className={styles.resultField}>
                  <span className={styles.fieldLabel}>{t('name')}:</span>
                  <span className={styles.fieldValue}>
                    {user.first_name || 'N/A'} {user.last_name || ''}
                  </span>
                </div>
                <div className={styles.resultField}>
                  <span className={styles.fieldLabel}>{t('email')}:</span>
                  <span className={styles.fieldValue}>{user.email_address || 'N/A'}</span>
                </div>
              </div>
              
              <div className={styles.resultRow}>
                <div className={styles.resultField}>
                  <span className={styles.fieldLabel}>{t('phone')}:</span>
                  <span className={styles.fieldValue}>{user.mobile_phone_number || 'N/A'}</span>
                </div>
                <div className={styles.resultField}>
                  <span className={styles.fieldLabel}>{t('user_type')}:</span>
                  <span className={styles.fieldValue}>
                    {user.user_type === 1 ? t('standard_user') : 
                     user.user_type === 2 ? t('premium_user') : 
                     user.user_type === 3 ? t('admin_user') : 
                     user.user_type === 4 ? t('system_user') : t('unknown')}
                  </span>
                </div>
              </div>
              
              <div className={styles.resultRow}>
                <div className={styles.resultField}>
                  <span className={styles.fieldLabel}>{t('created_at')}:</span>
                  <span className={styles.fieldValue}>
                    {formatDateTime(user.created_at)}
                  </span>
                </div>
                <div className={styles.resultField}>
                  <span className={styles.fieldLabel}>{t('last_updated')}:</span>
                  <span className={styles.fieldValue}>
                    {formatDateTime(user.updated_at)}
                  </span>
                </div>
              </div>
            </div>
            
            <div className={styles.resultActions}>
              <button 
                className={styles.viewButton}
                onClick={() => handleViewUser(user.user_id)}
              >
                {t('view_user')}
              </button>
            </div>
          </div>
        ))}
      </div>
      
      {totalPages > 1 && (
        <div className={styles.pagination}>
          <button 
            className={styles.paginationButton}
            disabled={currentPage <= 1}
            onClick={() => onPageChange(currentPage - 1)}
          >
            {t('previous')}
          </button>
          
          <span className={styles.pageInfo}>
            {t('page_of_total', { page: currentPage, total: totalPages })}
          </span>
          
          <button 
            className={styles.paginationButton}
            disabled={currentPage >= totalPages}
            onClick={() => onPageChange(currentPage + 1)}
          >
            {t('next')}
          </button>
        </div>
      )}
    </div>
  );
};

export default UserSearchResults;