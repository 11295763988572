// File: frontend/src/pages/SuperAdmin/components/DatasetManagement/index.js

import React, { useState } from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import DatasetList from './DatasetList';
import DatasetDetail from './DatasetDetail';
import styles from './DatasetManagement.module.css';

const DatasetManagement = ({ updateBreadcrumbs }) => {
  const { t } = useLanguage();
  const [view, setView] = useState('list');
  const [selectedDatasetId, setSelectedDatasetId] = useState(null);

  // Handle view changes
  const handleViewChange = (newView, datasetId = null) => {
    setView(newView);
    setSelectedDatasetId(datasetId);
    
    // Update breadcrumbs based on view
    const baseBreadcrumbs = [
      { label: 'Dashboard', path: '/super-admin' },
      { label: 'Dataset Management', path: '/super-admin/datasets' }
    ];
    
    switch (newView) {
      case 'detail':
        updateBreadcrumbs([
          ...baseBreadcrumbs,
          { label: 'Dataset Details', path: `/super-admin/datasets/${datasetId}` }
        ]);
        break;
      case 'create':
        updateBreadcrumbs([
          ...baseBreadcrumbs,
          { label: 'Create Dataset', path: '/super-admin/datasets/create' }
        ]);
        break;
      case 'edit':
        updateBreadcrumbs([
          ...baseBreadcrumbs,
          { 
            label: 'Dataset Details', 
            path: `/super-admin/datasets/${datasetId}` 
          },
          { 
            label: 'Edit Dataset', 
            path: `/super-admin/datasets/${datasetId}/edit` 
          }
        ]);
        break;
      default:
        updateBreadcrumbs(baseBreadcrumbs);
    }
  };

  // Render the appropriate view
  const renderView = () => {
    switch (view) {
      case 'detail':
        return (
          <DatasetDetail 
            datasetId={selectedDatasetId} 
            onBack={() => handleViewChange('list')}
            onEdit={() => handleViewChange('edit', selectedDatasetId)}
          />
        );
      case 'create':
        return (
          <DatasetList 
            isCreateView={true}
            onBack={() => handleViewChange('list')}
            onSuccess={(datasetId) => handleViewChange('detail', datasetId)}
          />
        );
      case 'edit':
        return (
          <DatasetDetail 
            datasetId={selectedDatasetId} 
            isEditMode={true}
            onBack={() => handleViewChange('detail', selectedDatasetId)}
            onSuccess={() => handleViewChange('detail', selectedDatasetId)}
          />
        );
      default:
        return (
          <DatasetList 
            onViewDetail={(datasetId) => handleViewChange('detail', datasetId)}
            onCreateNew={() => handleViewChange('create')}
          />
        );
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>
        {view === 'list' && t('dataset_management')}
        {view === 'detail' && t('dataset_details')}
        {view === 'create' && t('create_dataset')}
        {view === 'edit' && t('edit_dataset')}
      </h1>
      
      {renderView()}
    </div>
  );
};

export default DatasetManagement;