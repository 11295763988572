import React, { useRef, useEffect } from 'react';
import ReactFlow from 'react-flow-renderer';

const ReactFlowWrapper = (props) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const preventDefault = (e) => {
        e.preventDefault();
      };

      container.addEventListener('touchstart', preventDefault, { passive: false });
      container.addEventListener('touchmove', preventDefault, { passive: false });
      container.addEventListener('wheel', preventDefault, { passive: false });

      return () => {
        container.removeEventListener('touchstart', preventDefault);
        container.removeEventListener('touchmove', preventDefault);
        container.removeEventListener('wheel', preventDefault);
      };
    }
  }, []);

  return (
    <div ref={containerRef} style={{ width: '100%', height: '100%' }}>
      <ReactFlow {...props} />
    </div>
  );
};

export default ReactFlowWrapper;