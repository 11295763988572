// File: frontend/src/pages/PropertyDetail/components/PropertyRisk/index.js

import React from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import styles from './PropertyRisk.module.css';

// Helper function to format date
const formatDate = (dateString) => {
  if (!dateString) return 'N/A';
  
  const date = new Date(dateString);
  
  // Check if date is valid
  if (isNaN(date.getTime())) return 'Invalid Date';
  
  return date.toLocaleDateString();
};

// Helper function to format percentage
const formatPercentage = (value) => {
  if (value === null || value === undefined) return 'N/A';
  return `${(value * 100).toFixed(2)}%`;
};

const PropertyRisk = ({ property }) => {
  const { t } = useLanguage();

  // Function to get risk level class based on score
  const getRiskLevelClass = (score) => {
    if (score <= 2) return styles.riskLow;
    if (score <= 4) return styles.riskMediumLow;
    if (score <= 6) return styles.riskMedium;
    if (score <= 8) return styles.riskMediumHigh;
    return styles.riskHigh;
  };

  // Function to get risk level text based on score
  const getRiskLevelText = (score) => {
    if (score <= 2) return t('risk_low');
    if (score <= 4) return t('risk_medium_low');
    if (score <= 6) return t('risk_medium');
    if (score <= 8) return t('risk_medium_high');
    return t('risk_high');
  };

  return (
    <div className={styles.container}>
      <div className={styles.grid}>
        <div className={styles.card}>
          <h3 className={styles.cardTitle}>{t('risk_assessment')}</h3>
          <div className={styles.cardContent}>
            {property.riskAndInsurance ? (
              <div className={styles.riskAssessment}>
                <div className={styles.riskScore}>
                  <div className={`${styles.scoreValue} ${getRiskLevelClass(property.riskAndInsurance.score)}`}>
                    {property.riskAndInsurance.score.toFixed(1)}
                  </div>
                  <div className={styles.scoreLabel}>
                    {getRiskLevelText(property.riskAndInsurance.score)}
                  </div>
                </div>
                
                <div className={styles.riskDetails}>
                  <div className={styles.riskDetail}>
                    <span className={styles.riskDetailLabel}>{t('peril')}:</span>
                    <span className={styles.riskDetailValue}>{property.riskAndInsurance.peril}</span>
                  </div>
                  <div className={styles.riskDetail}>
                    <span className={styles.riskDetailLabel}>{t('provider')}:</span>
                    <span className={styles.riskDetailValue}>{property.riskAndInsurance.provider}</span>
                  </div>
                  <div className={styles.riskDetail}>
                    <span className={styles.riskDetailLabel}>{t('score_date')}:</span>
                    <span className={styles.riskDetailValue}>{formatDate(property.riskAndInsurance.score_date)}</span>
                  </div>
                  <div className={styles.riskDetail}>
                    <span className={styles.riskDetailLabel}>{t('overall_probability')}:</span>
                    <span className={styles.riskDetailValue}>{formatPercentage(property.riskAndInsurance.overall_probability)}</span>
                  </div>
                  <div className={styles.riskDetail}>
                    <span className={styles.riskDetailLabel}>{t('building_damage_probability')}:</span>
                    <span className={styles.riskDetailValue}>{formatPercentage(property.riskAndInsurance.building_damage_probability)}</span>
                  </div>
                  <div className={styles.riskDetail}>
                    <span className={styles.riskDetailLabel}>{t('content_damage_probability')}:</span>
                    <span className={styles.riskDetailValue}>{formatPercentage(property.riskAndInsurance.content_damage_probability)}</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.noData}>{t('no_risk_assessment_available')}</div>
            )}
          </div>
        </div>
        
        <div className={styles.card}>
          <h3 className={styles.cardTitle}>{t('zone_information')}</h3>
          <div className={styles.cardContent}>
            {property.riskAndInsurance ? (
              <div className={styles.zoneInfo}>
                <div className={styles.zoneGrid}>
                  <div className={styles.zoneItem}>
                    <div className={styles.zoneLabel}>{t('zone_definition')}:</div>
                    <div className={styles.zoneValue}>{property.riskAndInsurance.zone_definition}</div>
                  </div>
                  <div className={styles.zoneItem}>
                    <div className={styles.zoneLabel}>{t('zone_provider')}:</div>
                    <div className={styles.zoneValue}>{property.riskAndInsurance.zone_provider}</div>
                  </div>
                  <div className={styles.zoneItem}>
                    <div className={styles.zoneLabel}>{t('zone_type')}:</div>
                    <div className={styles.zoneValue}>{property.riskAndInsurance.zone_type}</div>
                  </div>
                  <div className={styles.zoneItem}>
                    <div className={styles.zoneLabel}>{t('decile')}:</div>
                    <div className={styles.zoneValue}>{property.riskAndInsurance.decile}</div>
                  </div>
                </div>
                
                {property.territory && (
                  <div className={styles.additionalZones}>
                    <h4 className={styles.additionalZonesTitle}>{t('additional_zone_information')}</h4>
                    <div className={styles.zoneGrid}>
                      <div className={styles.zoneItem}>
                        <div className={styles.zoneLabel}>{t('flood_zone')}:</div>
                        <div className={styles.zoneValue}>{property.territory.flood_zone}</div>
                      </div>
                      <div className={styles.zoneItem}>
                        <div className={styles.zoneLabel}>{t('wildfire_zone')}:</div>
                        <div className={styles.zoneValue}>{property.territory.wildfire_zone}</div>
                      </div>
                      <div className={styles.zoneItem}>
                        <div className={styles.zoneLabel}>{t('earthquake_zone')}:</div>
                        <div className={styles.zoneValue}>{property.territory.earthquake_zone}</div>
                      </div>
                      <div className={styles.zoneItem}>
                        <div className={styles.zoneLabel}>{t('theft_risk_zone')}:</div>
                        <div className={styles.zoneValue}>{property.territory.theft_risk_zone}</div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className={styles.noData}>{t('no_zone_information_available')}</div>
            )}
          </div>
        </div>
      </div>
      
      <div className={styles.card}>
        <h3 className={styles.cardTitle}>{t('risk_factors')}</h3>
        <div className={styles.cardContent}>
          {property.riskAssessment ? (
            <div className={styles.riskFactorsGrid}>
              <div className={styles.riskFactorItem}>
                <div className={styles.riskFactorLabel}>{t('protection_class')}:</div>
                <div className={styles.riskFactorValue}>{property.riskAssessment.protection_class}</div>
              </div>
              <div className={styles.riskFactorItem}>
                <div className={styles.riskFactorLabel}>{t('distance_to_fire_hydrant')}:</div>
                <div className={styles.riskFactorValue}>{property.riskAssessment.distance_to_fire_hydrant} m</div>
              </div>
              <div className={styles.riskFactorItem}>
                <div className={styles.riskFactorLabel}>{t('distance_to_fire_station')}:</div>
                <div className={styles.riskFactorValue}>{property.riskAssessment.distance_to_fire_station} km</div>
              </div>
              <div className={styles.riskFactorItem}>
                <div className={styles.riskFactorLabel}>{t('flood_zone')}:</div>
                <div className={styles.riskFactorValue}>{property.riskAssessment.flood_zone}</div>
              </div>
              <div className={styles.riskFactorItem}>
                <div className={styles.riskFactorLabel}>{t('earthquake_zone')}:</div>
                <div className={styles.riskFactorValue}>{property.riskAssessment.earthquake_zone}</div>
              </div>
              <div className={styles.riskFactorItem}>
                <div className={styles.riskFactorLabel}>{t('alarm_type')}:</div>
                <div className={styles.riskFactorValue}>{property.riskAssessment.alarm_type}</div>
              </div>
              <div className={styles.riskFactorItem}>
                <div className={styles.riskFactorLabel}>{t('building_age')}:</div>
                <div className={styles.riskFactorValue}>{property.riskAssessment.building_age} {t('years')}</div>
              </div>
              
              {property.riskAssessment.special_risk_factors && property.riskAssessment.special_risk_factors.length > 0 && (
                <div className={styles.riskFactorItem}>
                  <div className={styles.riskFactorLabel}>{t('special_risk_factors')}:</div>
                  <div className={styles.riskFactorValue}>
                    {property.riskAssessment.special_risk_factors.join(', ')}
                  </div>
                </div>
              )}
              
              <div className={styles.riskFactorItem}>
                <div className={styles.riskFactorLabel}>{t('obc_compliance')}:</div>
                <div className={styles.riskFactorValue}>
                  {property.riskAssessment.obc_compliance ? t('yes') : t('no')}
                </div>
              </div>
              <div className={styles.riskFactorItem}>
                <div className={styles.riskFactorLabel}>{t('ccq_compliance')}:</div>
                <div className={styles.riskFactorValue}>
                  {property.riskAssessment.ccq_compliance ? t('yes') : t('no')}
                </div>
              </div>
              <div className={styles.riskFactorItem}>
                <div className={styles.riskFactorLabel}>{t('heritage_building')}:</div>
                <div className={styles.riskFactorValue}>
                  {property.riskAssessment.heritage_building ? t('yes') : t('no')}
                </div>
              </div>
              <div className={styles.riskFactorItem}>
                <div className={styles.riskFactorLabel}>{t('hurricane_protection')}:</div>
                <div className={styles.riskFactorValue}>
                  {property.riskAssessment.hurricane_protection ? t('yes') : t('no')}
                </div>
              </div>
              <div className={styles.riskFactorItem}>
                <div className={styles.riskFactorLabel}>{t('coastal_construction')}:</div>
                <div className={styles.riskFactorValue}>
                  {property.riskAssessment.coastal_construction ? t('yes') : t('no')}
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.noData}>{t('no_risk_factors_available')}</div>
          )}
        </div>
      </div>
      
      <div className={styles.riskMap}>
        <h3 className={styles.riskMapTitle}>{t('risk_map')}</h3>
        <div className={styles.riskMapContent}>
          <div className={styles.riskMapPlaceholder}>
            {t('risk_map_not_available')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyRisk;