// File: frontend/src/components/form/PhoneInput/PhoneInput.js

import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useLanguage } from '../../../contexts/LanguageContext';
import FormField from '../FormField/FormField';
import Dropdown from '../Dropdown/Dropdown';
import styles from './PhoneInput.module.css';

const PhoneInput = ({
  id,
  labelKey,
  required,
  value = {},
  onChange,
  hintKey,
  errorKey,
  disabled = false,
}) => {
  const { t } = useLanguage();

  // Initialize with API-matching structure
  const phoneValue = useMemo(() => ({
    country_phone_code: '+1',
    area_code: '',
    phone_number: '',
    ...value
  }), [value]);

  const countryCodes = [
    { value: '+1', labelKey: 'countryCode.ca', flag: '🇨🇦' },
    { value: '+1 ', labelKey: 'countryCode.us', flag: '🇺🇸' },
    { value: '+31', labelKey: 'countryCode.nl', flag: '🇳🇱' },
    { value: '+33', labelKey: 'countryCode.fr', flag: '🇫🇷' },
    { value: '+34', labelKey: 'countryCode.es', flag: '🇪🇸' },
    { value: '+44', labelKey: 'countryCode.uk', flag: '🇬🇧' },
    { value: '+49', labelKey: 'countryCode.de', flag: '🇩🇪' },
    { value: '+52', labelKey: 'countryCode.mx', flag: '🇲🇽' },
    { value: '+90', labelKey: 'countryCode.tr', flag: '🇹🇷' },
    { value: '+353', labelKey: 'countryCode.ie', flag: '🇮🇪' },
  ];

  const handleChange = useCallback((field, newValue) => {
    onChange({
      ...phoneValue,
      [field]: newValue,
    });
  }, [onChange, phoneValue]);

  const handleAreaCodeChange = useCallback((e) => {
    const newValue = e.target.value.replace(/\D/g, '').slice(0, 3);
    handleChange('area_code', newValue);
  }, [handleChange]);

  const handlePhoneNumberChange = useCallback((e) => {
    const digits = e.target.value.replace(/\D/g, '').slice(0, 7);
    handleChange('phone_number', digits);
  }, [handleChange]);

  const renderCountryOption = (option) => (
    <>
      <span className={styles.countryFlag}>{option.flag}</span>
      <span className={styles.country_phone_code}>{option.value}</span>
    </>
  );

  return (
    <FormField
      labelKey={labelKey}
      required={required}
      hintKey={hintKey}
      errorKey={errorKey}
      id={id}
    >
      <div className={styles.phoneInputContainer}>
        <div className={styles.countryCodeWrapper}>
          <Dropdown
            id={`${id}-country-code`}
            options={countryCodes}
            value={phoneValue.country_phone_code}
            onChange={(newValue) => handleChange('country_phone_code', newValue)}
            disabled={disabled}
            renderOption={renderCountryOption}
            variant="standalone"
          />
        </div>
        <div className={styles.numberWrapper}>
          <input
            id={`${id}-area-code`}
            type="tel"
            placeholder="570"
            maxLength={3}
            value={phoneValue.area_code}
            onChange={handleAreaCodeChange}
            disabled={disabled}
            className={styles.area_code}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            data-form-type="other"
          />
          <input
            id={`${id}-phone-number`}
            type="tel"
            placeholder="2013135"
            maxLength={7}
            value={phoneValue.phone_number}
            onChange={handlePhoneNumberChange}
            disabled={disabled}
            className={styles.phone_number}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            data-form-type="other"
          />
        </div>
      </div>
    </FormField>
  );
};

PhoneInput.propTypes = {
  id: PropTypes.string.isRequired,
  labelKey: PropTypes.string.isRequired,
  required: PropTypes.bool,
  value: PropTypes.shape({
    country_phone_code: PropTypes.string,
    area_code: PropTypes.string,
    phone_number: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  hintKey: PropTypes.string,
  errorKey: PropTypes.string,
  disabled: PropTypes.bool,
};

export default React.memo(PhoneInput);