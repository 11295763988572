// src/components/TripDetails/TripDetailsContainer.js
import React from 'react';
import UserInfo from './UserInfo';
import TripDriverInfo from './TripDriverInfo';
import TripSpeedGraph from './TripSpeedGraph';
import UserFeedback from './UserFeedback';
import TripScoreOverview from './TripScoreOverview';
import TripOverview from './TripOverview';
import TripEventsMaps from './TripEventsMaps';
import styles from './TripDetailsContainer.module.css';
import tripData from '../../data/trip_details_single_trip_result.json';
import tripEventsData from '../../data/trip_details_single_trip_events_result.json';

const TripDetailsContainer = () => {
  const singleTripResult = tripData[0].single_trip_result;
  const singleTripEventsResult = tripEventsData[0].single_trip_events_result;

  return (
    <div className={styles.container}>
      <div className={styles.columns1}>
        <div className={styles.firstColumn}>
          <UserInfo userData={singleTripResult.userDetailsList[0]} />
          <TripDriverInfo tripData={singleTripResult} />
          <TripSpeedGraph tripData={singleTripResult} />
          <UserFeedback feedbackData={singleTripResult.userTripFeedbackList[0]} />
        </div>
        <div className={styles.firstColumn}>
          <TripScoreOverview scoreData={singleTripResult.tripDrivingScoreOverviewList[0]} />
          <TripOverview tripData={singleTripResult.tripOverviewList[0]} />
        </div>
      </div>
      <div className={styles.columns2}>
        <TripEventsMaps tripEventsData={singleTripEventsResult} />
      </div>
    </div>
  );
};

export default TripDetailsContainer;