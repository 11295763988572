// File: frontend/src/pages/UMS/UserDetail/components/UserPermissions/index.js

import React, { useState } from 'react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from './UserPermissions.module.css';

const UserPermissions = ({ user }) => {
  const { t } = useLanguage();
  const [isEditing, setIsEditing] = useState(false);
  const [permissions, setPermissions] = useState(user.permissions || {});

  const handleToggleEdit = () => {
    setIsEditing(!isEditing);
  };

  const handleTogglePermission = (key) => {
    if (!isEditing) return;
    
    setPermissions({
      ...permissions,
      [key]: !permissions[key]
    });
  };

  const handleSavePermissions = () => {
    // Here you would call an API to save the updated permissions
    console.log('Saving permissions:', permissions);
    setIsEditing(false);
  };

  const handleCancelEdit = () => {
    // Reset permissions to original values
    setPermissions(user.permissions || {});
    setIsEditing(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2 className={styles.title}>{t('user_permissions')}</h2>
        {!isEditing ? (
          <button 
            className={styles.editButton}
            onClick={handleToggleEdit}
          >
            {t('edit_permissions')}
          </button>
        ) : (
          <div className={styles.editActions}>
            <button 
              className={styles.cancelButton}
              onClick={handleCancelEdit}
            >
              {t('cancel')}
            </button>
            <button 
              className={styles.saveButton}
              onClick={handleSavePermissions}
            >
              {t('save_changes')}
            </button>
          </div>
        )}
      </div>

      <div className={styles.permissionsGrid}>
        <div className={styles.permissionCard}>
          <h3 className={styles.cardTitle}>{t('verification_status')}</h3>
          <div className={styles.permissionList}>
            <div className={styles.permissionItem}>
              <div className={styles.permissionLabel}>{t('is_phone_number_verified')}</div>
              <div 
                className={`${styles.permissionToggle} ${permissions.is_phone_number_verified ? styles.enabled : styles.disabled}`}
                onClick={() => handleTogglePermission('is_phone_number_verified')}
              >
                {permissions.is_phone_number_verified ? t('yes') : t('no')}
              </div>
            </div>
            <div className={styles.permissionItem}>
              <div className={styles.permissionLabel}>{t('is_email_address_verified')}</div>
              <div 
                className={`${styles.permissionToggle} ${permissions.is_email_address_verified ? styles.enabled : styles.disabled}`}
                onClick={() => handleTogglePermission('is_email_address_verified')}
              >
                {permissions.is_email_address_verified ? t('yes') : t('no')}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.permissionCard}>
          <h3 className={styles.cardTitle}>{t('marketing_preferences')}</h3>
          <div className={styles.permissionList}>
            <div className={styles.permissionItem}>
              <div className={styles.permissionLabel}>{t('is_marketing_content_active')}</div>
              <div 
                className={`${styles.permissionToggle} ${permissions.is_marketing_content_active ? styles.enabled : styles.disabled}`}
                onClick={() => handleTogglePermission('is_marketing_content_active')}
              >
                {permissions.is_marketing_content_active ? t('yes') : t('no')}
              </div>
            </div>
            <div className={styles.permissionItem}>
              <div className={styles.permissionLabel}>{t('is_notification_allowed')}</div>
              <div 
                className={`${styles.permissionToggle} ${permissions.is_notification_allowed ? styles.enabled : styles.disabled}`}
                onClick={() => handleTogglePermission('is_notification_allowed')}
              >
                {permissions.is_notification_allowed ? t('yes') : t('no')}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.permissionCard}>
          <h3 className={styles.cardTitle}>{t('location_permissions')}</h3>
          <div className={styles.permissionList}>
            <div className={styles.permissionItem}>
              <div className={styles.permissionLabel}>{t('is_location_allowed')}</div>
              <div 
                className={`${styles.permissionToggle} ${permissions.is_location_allowed ? styles.enabled : styles.disabled}`}
                onClick={() => handleTogglePermission('is_location_allowed')}
              >
                {permissions.is_location_allowed ? t('yes') : t('no')}
              </div>
            </div>
            <div className={styles.permissionItem}>
              <div className={styles.permissionLabel}>{t('is_precise_location_allowed')}</div>
              <div 
                className={`${styles.permissionToggle} ${permissions.is_precise_location_allowed ? styles.enabled : styles.disabled}`}
                onClick={() => handleTogglePermission('is_precise_location_allowed')}
              >
                {permissions.is_precise_location_allowed ? t('yes') : t('no')}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.permissionCard}>
          <h3 className={styles.cardTitle}>{t('motion_and_trip_detection')}</h3>
          <div className={styles.permissionList}>
            <div className={styles.permissionItem}>
              <div className={styles.permissionLabel}>{t('is_motion_allowed')}</div>
              <div 
                className={`${styles.permissionToggle} ${permissions.is_motion_allowed ? styles.enabled : styles.disabled}`}
                onClick={() => handleTogglePermission('is_motion_allowed')}
              >
                {permissions.is_motion_allowed ? t('yes') : t('no')}
              </div>
            </div>
            <div className={styles.permissionItem}>
              <div className={styles.permissionLabel}>{t('is_trip_detection_allowed')}</div>
              <div 
                className={`${styles.permissionToggle} ${permissions.is_trip_detection_allowed ? styles.enabled : styles.disabled}`}
                onClick={() => handleTogglePermission('is_trip_detection_allowed')}
              >
                {permissions.is_trip_detection_allowed ? t('yes') : t('no')}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.permissionCard}>
          <h3 className={styles.cardTitle}>{t('data_and_integrations')}</h3>
          <div className={styles.permissionList}>
            <div className={styles.permissionItem}>
              <div className={styles.permissionLabel}>{t('is_data_sharing_allowed')}</div>
              <div 
                className={`${styles.permissionToggle} ${permissions.is_data_sharing_allowed ? styles.enabled : styles.disabled}`}
                onClick={() => handleTogglePermission('is_data_sharing_allowed')}
              >
                {permissions.is_data_sharing_allowed ? t('yes') : t('no')}
              </div>
            </div>
            <div className={styles.permissionItem}>
              <div className={styles.permissionLabel}>{t('is_third_party_integration_allowed')}</div>
              <div 
                className={`${styles.permissionToggle} ${permissions.is_third_party_integration_allowed ? styles.enabled : styles.disabled}`}
                onClick={() => handleTogglePermission('is_third_party_integration_allowed')}
              >
                {permissions.is_third_party_integration_allowed ? t('yes') : t('no')}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.permissionCard}>
          <h3 className={styles.cardTitle}>{t('authentication')}</h3>
          <div className={styles.permissionList}>
            <div className={styles.permissionItem}>
              <div className={styles.permissionLabel}>{t('is_biometric_auth_allowed')}</div>
              <div 
                className={`${styles.permissionToggle} ${permissions.is_biometric_auth_allowed ? styles.enabled : styles.disabled}`}
                onClick={() => handleTogglePermission('is_biometric_auth_allowed')}
              >
                {permissions.is_biometric_auth_allowed ? t('yes') : t('no')}
              </div>
            </div>
          </div>
        </div>
      </div>

      {isEditing && (
        <div className={styles.editingNote}>
          {t('click_on_permission_to_toggle')}
        </div>
      )}

      <div className={styles.permissionHistory}>
        <h3 className={styles.historyTitle}>{t('permission_change_history')}</h3>
        {user.permissions_history && user.permissions_history.length > 0 ? (
          <table className={styles.historyTable}>
            <thead>
              <tr>
                <th>{t('changed_field')}</th>
                <th>{t('old_value')}</th>
                <th>{t('new_value')}</th>
                <th>{t('changed_at')}</th>
                <th>{t('changed_by')}</th>
              </tr>
            </thead>
            <tbody>
              {user.permissions_history.map((history, index) => (
                <tr key={history.history_id || index}>
                  <td>{history.changed_field}</td>
                  <td>{history.old_value === true ? t('yes') : history.old_value === false ? t('no') : history.old_value || '-'}</td>
                  <td>{history.new_value === true ? t('yes') : history.new_value === false ? t('no') : history.new_value || '-'}</td>
                  <td>{new Date(history.changed_at).toLocaleString()}</td>
                  <td>{history.changed_by || '-'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className={styles.noHistory}>
            {t('no_permission_history')}
          </div>
        )}
      </div>
    </div>
  );
};

export default UserPermissions;