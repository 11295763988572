// Combine TextInput, Input, and InputForm into a single Input component.
// Use the FormField wrapper for consistent layout and error landling.
// Support different input types

// File: frontend/src/components/form/Input/Input.js

import React from 'react';
import PropTypes from 'prop-types';
import { useLanguage } from '../../../contexts/LanguageContext';
import FormField from '../FormField/FormField';
import styles from './Input.module.css';

const Input = ({
  labelKey,
  required,
  optional,
  icon,
  hintKey,
  errorKey,
  id,
  type = 'text',
  placeholderKey,
  value,
  onChange,
  disabled = false,
  maxLength,
  multiline = false,
}) => {
  const { t } = useLanguage();

  const handleChange = (e) => {
    if (onChange) {
      onChange(e.target.value);
    }
  };

  const inputClasses = [
    styles.input,
    disabled && styles.disabled,
    errorKey && styles.error,
    icon && styles.withIcon,
  ].filter(Boolean).join(' ');

  const inputProps = {
    id,
    type,
    placeholder: t(placeholderKey),
    value,
    onChange: handleChange,
    disabled,
    maxLength,
    className: inputClasses,
  };

  return (
    <FormField
      labelKey={labelKey}
      required={required}
      optional={optional}
      icon={icon}
      hintKey={hintKey}
      errorKey={errorKey}
      id={id}
    >
      {icon && <img className={styles.icon} src={icon} alt="" aria-hidden="true" />}
      {multiline ? (
        <textarea {...inputProps} />
      ) : (
        <input {...inputProps} />
      )}
      {maxLength && (
        <div className={styles.characterCount}>
          {value.length}/{maxLength}
        </div>
      )}
    </FormField>
  );
};

Input.propTypes = {
  labelKey: PropTypes.string.isRequired,
  required: PropTypes.bool,
  optional: PropTypes.bool,
  icon: PropTypes.string,
  hintKey: PropTypes.string,
  errorKey: PropTypes.string,
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholderKey: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  multiline: PropTypes.bool,
};

export default Input;