// File: frontend/src/pages/InvestorPitch/components/navigation/SlideNavigation.js

import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import styles from './SlideNavigation.module.css';

const SlideNavigation = ({ 
  currentSlide, 
  totalSlides, 
  onNext, 
  onPrevious 
}) => {
  return (
    <div className={styles.navigation}>
      <motion.button
        className={`${styles.navButton} ${styles.prevButton}`}
        onClick={onPrevious}
        disabled={currentSlide === 0}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        aria-label="Previous slide"
      >
        <ChevronLeft className={styles.navIcon} />
      </motion.button>

      <div className={styles.slideCounter}>
        {currentSlide + 1} / {totalSlides}
      </div>

      <motion.button
        className={`${styles.navButton} ${styles.nextButton}`}
        onClick={onNext}
        disabled={currentSlide === totalSlides - 1}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        aria-label="Next slide"
      >
        <ChevronRight className={styles.navIcon} />
      </motion.button>
    </div>
  );
};

SlideNavigation.propTypes = {
  currentSlide: PropTypes.number.isRequired,
  totalSlides: PropTypes.number.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired
};

export default SlideNavigation;