// File: frontend/src/pages/TripInsight/TripDetail/components/TripOverview/index.js

import React from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import styles from './TripOverview.module.css';

const TripOverview = ({ trip }) => {
  const { t } = useLanguage();

  // Helper function to format date
  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  // Helper function to format duration
  const formatDuration = (seconds) => {
    if (!seconds) return 'N/A';
    
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    
    if (hours > 0) {
      return `${hours}h ${minutes}m ${remainingSeconds}s`;
    }
    return `${minutes}m ${remainingSeconds}s`;
  };

  return (
    <div className={styles.container}>
      <div className={styles.grid}>
        <div className={styles.card}>
          <h3 className={styles.cardTitle}>{t('trip_information')}</h3>
          <div className={styles.cardContent}>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('trip_id')}:</div>
              <div className={styles.infoValue}>{trip.trip_id}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('user_id')}:</div>
              <div className={styles.infoValue}>{trip.user_id}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('device_id')}:</div>
              <div className={styles.infoValue}>{trip.device_id}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('vehicle_id')}:</div>
              <div className={styles.infoValue}>{trip.vehicle_id}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('trip_status')}:</div>
              <div className={styles.infoValue}>{t(trip.trip_status)}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('is_driver')}:</div>
              <div className={styles.infoValue}>{trip.is_driver ? t('yes') : t('no')}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('created_at')}:</div>
              <div className={styles.infoValue}>{formatDate(trip.created_at)}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('updated_at')}:</div>
              <div className={styles.infoValue}>{formatDate(trip.updated_at)}</div>
            </div>
          </div>
        </div>
        
        <div className={styles.card}>
          <h3 className={styles.cardTitle}>{t('trip_timing')}</h3>
          <div className={styles.cardContent}>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('start_time')}:</div>
              <div className={styles.infoValue}>{formatDate(trip.trip_start_timestamp)}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('start_time_local')}:</div>
              <div className={styles.infoValue}>{formatDate(trip.trip_start_timestamp_local)}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('end_time')}:</div>
              <div className={styles.infoValue}>{formatDate(trip.trip_finished_timestamp)}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('end_time_local')}:</div>
              <div className={styles.infoValue}>{formatDate(trip.trip_finished_timestamp_local)}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('duration')}:</div>
              <div className={styles.infoValue}>{formatDuration(trip.trip_duration_seconds)}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('missing_seconds')}:</div>
              <div className={styles.infoValue}>{trip.missing_seconds || 0}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('not_connected_seconds')}:</div>
              <div className={styles.infoValue}>{trip.not_connected_seconds || 0}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('idling_time')}:</div>
              <div className={styles.infoValue}>{formatDuration(trip.idling_time_seconds)}</div>
            </div>
          </div>
        </div>
      </div>
      
      <div className={styles.grid}>
        <div className={styles.card}>
          <h3 className={styles.cardTitle}>{t('trip_locations')}</h3>
          <div className={styles.cardContent}>
            <div className={styles.locationSection}>
              <h4 className={styles.locationTitle}>{t('start_location')}</h4>
              <div className={styles.locationDetails}>
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('place_id')}:</div>
                  <div className={styles.infoValue}>{trip.trip_started_place_id}</div>
                </div>
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('coordinates')}:</div>
                  <div className={styles.infoValue}>
                    {trip.trip_started_location ? 
                      `${trip.trip_started_location.latitude}, ${trip.trip_started_location.longitude}` : 
                      'N/A'}
                  </div>
                </div>
                {trip.filtered_locations_aggs?.trip_start_address && (
                  <>
                    <div className={styles.infoGroup}>
                      <div className={styles.infoLabel}>{t('street')}:</div>
                      <div className={styles.infoValue}>{trip.filtered_locations_aggs.trip_start_address.street}</div>
                    </div>
                    <div className={styles.infoGroup}>
                      <div className={styles.infoLabel}>{t('city')}:</div>
                      <div className={styles.infoValue}>{trip.filtered_locations_aggs.trip_start_address.city}</div>
                    </div>
                    <div className={styles.infoGroup}>
                      <div className={styles.infoLabel}>{t('state')}:</div>
                      <div className={styles.infoValue}>{trip.filtered_locations_aggs.trip_start_address.state}</div>
                    </div>
                    <div className={styles.infoGroup}>
                      <div className={styles.infoLabel}>{t('postal_code')}:</div>
                      <div className={styles.infoValue}>{trip.filtered_locations_aggs.trip_start_address.postal_code}</div>
                    </div>
                  </>
                )}
              </div>
            </div>
            
            <div className={styles.locationSection}>
              <h4 className={styles.locationTitle}>{t('end_location')}</h4>
              <div className={styles.locationDetails}>
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('place_id')}:</div>
                  <div className={styles.infoValue}>{trip.trip_finished_place_id}</div>
                </div>
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('coordinates')}:</div>
                  <div className={styles.infoValue}>
                    {trip.trip_finished_location ? 
                      `${trip.trip_finished_location.latitude}, ${trip.trip_finished_location.longitude}` : 
                      'N/A'}
                  </div>
                </div>
                {trip.filtered_locations_aggs?.trip_finished_address && (
                  <>
                    <div className={styles.infoGroup}>
                      <div className={styles.infoLabel}>{t('street')}:</div>
                      <div className={styles.infoValue}>{trip.filtered_locations_aggs.trip_finished_address.street}</div>
                    </div>
                    <div className={styles.infoGroup}>
                      <div className={styles.infoLabel}>{t('city')}:</div>
                      <div className={styles.infoValue}>{trip.filtered_locations_aggs.trip_finished_address.city}</div>
                    </div>
                    <div className={styles.infoGroup}>
                      <div className={styles.infoLabel}>{t('state')}:</div>
                      <div className={styles.infoValue}>{trip.filtered_locations_aggs.trip_finished_address.state}</div>
                    </div>
                    <div className={styles.infoGroup}>
                      <div className={styles.infoLabel}>{t('postal_code')}:</div>
                      <div className={styles.infoValue}>{trip.filtered_locations_aggs.trip_finished_address.postal_code}</div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        
        <div className={styles.card}>
          <h3 className={styles.cardTitle}>{t('trip_metrics')}</h3>
          <div className={styles.cardContent}>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('distance_odometer')}:</div>
              <div className={styles.infoValue}>{trip.distance_travelled_odometer} km</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('distance_calculated')}:</div>
              <div className={styles.infoValue}>{trip.distance_travelled_calculation} km</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('missing_distance')}:</div>
              <div className={styles.infoValue}>{trip.missing_distance} km</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('average_speed')}:</div>
              <div className={styles.infoValue}>{trip.average_speed} km/h</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('maximum_speed')}:</div>
              <div className={styles.infoValue}>{trip.maximum_speed} km/h</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('extreme_acceleration_events')}:</div>
              <div className={styles.infoValue}>{trip.extreme_accel_events}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('night_driving')}:</div>
              <div className={styles.infoValue}>{trip.is_night_driving ? t('yes') : t('no')}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('peak_driving')}:</div>
              <div className={styles.infoValue}>{trip.is_peak_driving ? t('yes') : t('no')}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('weekend_driving')}:</div>
              <div className={styles.infoValue}>{trip.is_weekend_driving ? t('yes') : t('no')}</div>
            </div>
          </div>
        </div>
      </div>
      
      <div className={styles.card}>
        <h3 className={styles.cardTitle}>{t('trip_scores')}</h3>
        <div className={styles.cardContent}>
          <div className={styles.scoresGrid}>
            <div className={styles.scoreCard}>
              <div className={styles.scoreValue}>{trip.overall_score}</div>
              <div className={styles.scoreLabel}>{t('overall_score')}</div>
              {trip.deciles?.score_deciles?.v2?.overall_score_decile && (
                <div className={styles.scoreDecile}>
                  {t('decile')}: {trip.deciles.score_deciles.v2.overall_score_decile}/10
                </div>
              )}
            </div>
            
            <div className={styles.scoreCard}>
              <div className={styles.scoreValue}>{trip.acceleration_score}</div>
              <div className={styles.scoreLabel}>{t('acceleration_score')}</div>
              {trip.deciles?.score_deciles?.v2?.acceleration_score_decile && (
                <div className={styles.scoreDecile}>
                  {t('decile')}: {trip.deciles.score_deciles.v2.acceleration_score_decile}/10
                </div>
              )}
            </div>
            
            <div className={styles.scoreCard}>
              <div className={styles.scoreValue}>{trip.braking_score}</div>
              <div className={styles.scoreLabel}>{t('braking_score')}</div>
              {trip.deciles?.score_deciles?.v2?.braking_score_decile && (
                <div className={styles.scoreDecile}>
                  {t('decile')}: {trip.deciles.score_deciles.v2.braking_score_decile}/10
                </div>
              )}
            </div>
            
            <div className={styles.scoreCard}>
              <div className={styles.scoreValue}>{trip.speeding_score}</div>
              <div className={styles.scoreLabel}>{t('speeding_score')}</div>
              {trip.deciles?.score_deciles?.v2?.speeding_score_decile && (
                <div className={styles.scoreDecile}>
                  {t('decile')}: {trip.deciles.score_deciles.v2.speeding_score_decile}/10
                </div>
              )}
            </div>
            
            <div className={styles.scoreCard}>
              <div className={styles.scoreValue}>{trip.cornering_score}</div>
              <div className={styles.scoreLabel}>{t('cornering_score')}</div>
              {trip.deciles?.score_deciles?.v2?.cornering_score_decile && (
                <div className={styles.scoreDecile}>
                  {t('decile')}: {trip.deciles.score_deciles.v2.cornering_score_decile}/10
                </div>
              )}
            </div>
            
            <div className={styles.scoreCard}>
              <div className={styles.scoreValue}>{trip.contextual_score}</div>
              <div className={styles.scoreLabel}>{t('contextual_score')}</div>
              {trip.deciles?.score_deciles?.v2?.contextual_score_decile && (
                <div className={styles.scoreDecile}>
                  {t('decile')}: {trip.deciles.score_deciles.v2.contextual_score_decile}/10
                </div>
              )}
            </div>
            
            <div className={styles.scoreCard}>
              <div className={styles.scoreValue}>{trip.fatigue_driving_score}</div>
              <div className={styles.scoreLabel}>{t('fatigue_driving_score')}</div>
              {trip.deciles?.score_deciles?.v2?.fatigue_driving_score_decile && (
                <div className={styles.scoreDecile}>
                  {t('decile')}: {trip.deciles.score_deciles.v2.fatigue_driving_score_decile}/10
                </div>
              )}
            </div>
            
            <div className={styles.scoreCard}>
              <div className={styles.scoreValue}>{trip.distracted_driving_score}</div>
              <div className={styles.scoreLabel}>{t('distracted_driving_score')}</div>
              {trip.deciles?.score_deciles?.v2?.distracted_driving_score_decile && (
                <div className={styles.scoreDecile}>
                  {t('decile')}: {trip.deciles.score_deciles.v2.distracted_driving_score_decile}/10
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TripOverview;