// File: frontend/src/pages/InvestorPitch/components/slides/ValuationModelSlide/ValuationModelSlide.js

import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import { ANIMATION_VARIANTS } from '../../../constants/animations';
import styles from './ValuationModelSlide.module.css';

const ValuationModelSlide = () => {
  const { t } = useLanguage();
  const [activeTab, setActiveTab] = useState('technology');
  const [scenarioView, setScenarioView] = useState('conservative');

  const tabs = [
    { id: 'technology', label: t('investor_pitch.valuation_model.tabs.technology', 'Technology Valuation') },
    { id: 'insurance', label: t('investor_pitch.valuation_model.tabs.insurance', 'Insurance Economics') },
    { id: 'combined', label: t('investor_pitch.valuation_model.tabs.combined', 'Combined Valuation') },
    { id: 'terms', label: t('investor_pitch.valuation_model.tabs.terms', 'Series A Terms') }
  ];

  const scenarios = [
    { id: 'conservative', label: t('investor_pitch.valuation_model.scenarios.conservative', 'Conservative') },
    { id: 'moderate', label: t('investor_pitch.valuation_model.scenarios.moderate', 'Moderate') },
    { id: 'aggressive', label: t('investor_pitch.valuation_model.scenarios.aggressive', 'Aggressive') }
  ];

  // Technology valuation data across scenarios
  const technologyData = {
    conservative: {
      codebase: [
        { component: 'Frontend', loc: 171566, devCost: 10601818, qualityScore: 98.13, qualityMultiplier: 119.63 },
        { component: 'Backend', loc: 44386, devCost: 2077500, qualityScore: 141.38, qualityMultiplier: 128.28 },
        { component: 'Infrastructure', loc: 47334, devCost: 7095000, qualityScore: 72.02, qualityMultiplier: 114.40 },
        { component: 'Database', loc: 28529, devCost: 1295455, qualityScore: 69.40, qualityMultiplier: 113.88 }
      ],
      summary: {
        totalLOC: 291815,
        totalDevCost: 21069773,
        qualityAdjustedValue: 24939758,
        qualityMultiplier: 120.23
      },
      saasMetrics: {
        arrPerClient: 120000,
        clientCount: 25,
        arrTotal: 3000000,
        valuationMultiple: 8,
        saasValuation: 24000000,
        qualityAdjustedSaasValue: 28856172
      },
      totalTechValue: 53795930
    },
    moderate: {
      codebase: [
        { component: 'Frontend', loc: 171566, devCost: 13252273, qualityScore: 98.13, qualityMultiplier: 119.63 },
        { component: 'Backend', loc: 44386, devCost: 2770000, qualityScore: 141.38, qualityMultiplier: 128.28 },
        { component: 'Infrastructure', loc: 47334, devCost: 9223500, qualityScore: 72.02, qualityMultiplier: 114.40 },
        { component: 'Database', loc: 28529, devCost: 1727273, qualityScore: 69.40, qualityMultiplier: 113.88 }
      ],
      summary: {
        totalLOC: 291815,
        totalDevCost: 26973046,
        qualityAdjustedValue: 31925564,
        qualityMultiplier: 120.23
      },
      saasMetrics: {
        arrPerClient: 660000,
        clientCount: 50,
        arrTotal: 33000000,
        valuationMultiple: 10,
        saasValuation: 330000000,
        qualityAdjustedSaasValue: 396772362
      },
      totalTechValue: 428697925
    },
    aggressive: {
      codebase: [
        { component: 'Frontend', loc: 171566, devCost: 15902727, qualityScore: 98.13, qualityMultiplier: 119.63 },
        { component: 'Backend', loc: 44386, devCost: 3462500, qualityScore: 141.38, qualityMultiplier: 128.28 },
        { component: 'Infrastructure', loc: 47334, devCost: 11352000, qualityScore: 72.02, qualityMultiplier: 114.40 },
        { component: 'Database', loc: 28529, devCost: 2159091, qualityScore: 69.40, qualityMultiplier: 113.88 }
      ],
      summary: {
        totalLOC: 291815,
        totalDevCost: 32876318,
        qualityAdjustedValue: 38911369,
        qualityMultiplier: 120.23
      },
      saasMetrics: {
        arrPerClient: 1200000,
        clientCount: 100,
        arrTotal: 120000000,
        valuationMultiple: 15,
        saasValuation: 1800000000,
        qualityAdjustedSaasValue: 2164212881
      },
      totalTechValue: 2203124250
    }
  };

  // Insurance economics data across scenarios
  const insuranceData = {
    conservative: {
      premiumVolume: 1000000000,
      lossRatio: 83,
      expenseRatio: 8,
      combinedRatio: 91,
      underwritingProfit: 90000000,
      investmentIncome: 100000000,
      totalInsuranceIncome: 190000000,
      fiveYearValue: 950000000
    },
    moderate: {
      premiumVolume: 1200000000,
      lossRatio: 60,
      expenseRatio: 8,
      combinedRatio: 68,
      underwritingProfit: 384000000,
      investmentIncome: 120000000,
      totalInsuranceIncome: 504000000,
      fiveYearValue: 2520000000
    },
    aggressive: {
      premiumVolume: 3200000000,
      lossRatio: 50,
      expenseRatio: 8,
      combinedRatio: 58,
      underwritingProfit: 1344000000,
      investmentIncome: 320000000,
      totalInsuranceIncome: 1664000000,
      fiveYearValue: 8320000000
    }
  };

  // Combined valuation data across scenarios
  const combinedData = {
    conservative: {
      technologyValue: 53795930,
      underwritingValue: 950000000,
      totalEnterpriseValue: 1003795930,
      seriesAInvestment: 250000000,
      preMoneyValuation: 1003795930,
      postMoneyValuation: 1253795930,
      equityPercentage: 19.94
    },
    moderate: {
      technologyValue: 428697925,
      underwritingValue: 2520000000,
      totalEnterpriseValue: 2948697925,
      seriesAInvestment: 250000000,
      preMoneyValuation: 1003795930,
      postMoneyValuation: 1253795930,
      equityPercentage: 19.94
    },
    aggressive: {
      technologyValue: 2203124250,
      underwritingValue: 8320000000,
      totalEnterpriseValue: 10523124250,
      seriesAInvestment: 250000000,
      preMoneyValuation: 1003795930,
      postMoneyValuation: 1253795930,
      equityPercentage: 19.94
    }
  };

  // Series A terms data
  const termsData = {
    investment: 250000000,
    preMoneyValuation: 1003795930,
    postMoneyValuation: 1253795930,
    liquidationPreference: '1x non-participating',
    antiDilution: 'Broad-based weighted average',
    boardComposition: '7-Member Board + Independent Audit & Compliance',
    proRataRights: 'Yes',
    informationRights: 'Quarterly financial statements, 24/7/365 access to Allegory Dashboard',
    dividends: 'Non-cumulative, as declared',
    exitScenarios: [
      { valuation: 500000000, seriesA: 250000000, common: 250000000 },
      { valuation: 2000000000, seriesA: 398664000, common: 1601336000 },
      { valuation: 10000000000, seriesA: 1993320000, common: 8006680000 }
    ],
    fundDistribution: [
      { category: 'Reserves, Risk Capital & License Acquisition', amount: 100000000, percentage: 40 },
      { category: 'Computing Power for AI-2', amount: 50000000, percentage: 20 },
      { category: 'Marketing & Advertising', amount: 50000000, percentage: 20 },
      { category: 'Salaries & Benefits', amount: 40000000, percentage: 16 },
      { category: 'Legal, Licensing & Similar Fees', amount: 10000000, percentage: 4 }
    ]
  };

  const formatCurrency = (value) => {
    if (value >= 1000000000) {
      return `$${(value / 1000000000).toFixed(1)} B`;
    } else if (value >= 1000000) {
      return `$${(value / 1000000).toFixed(1)} M`;
    } else {
      return `$${value.toLocaleString()}`;
    }
  };

  const formatPercentage = (value) => {
    return `${value}%`;
  };

  const renderScenarioSelector = () => (
    <div className={styles.scenarioSelector}>
      <span className={styles.scenarioLabel}>
        {t('investor_pitch.valuation_model.scenario_label', 'Financial Scenario:')}
      </span>
      <div className={styles.scenarioButtons}>
        {scenarios.map(scenario => (
          <button
            key={scenario.id}
            className={`${styles.scenarioButton} ${scenarioView === scenario.id ? styles.activeScenario : ''}`}
            onClick={() => setScenarioView(scenario.id)}
          >
            {scenario.label}
          </button>
        ))}
      </div>
    </div>
  );

  const renderTechnologyTab = () => {
    const data = technologyData[scenarioView];
    
    return (
      <div className={styles.tabContent}>
        {renderScenarioSelector()}
        
        <div className={styles.tableContainer}>
          <h3 className={styles.tableTitle}>
            {t('investor_pitch.valuation_model.technology.table_title', 'Software Development Valuation')}
          </h3>
          <table className={styles.valuationTable}>
            <thead>
              <tr>
                <th>{t('investor_pitch.valuation_model.technology.component', 'Component')}</th>
                <th>{t('investor_pitch.valuation_model.technology.lines_of_code', 'Lines of Code')}</th>
                <th>{t('investor_pitch.valuation_model.technology.development_cost', 'Development Cost')}</th>
                <th>{t('investor_pitch.valuation_model.technology.quality_multiplier', 'Quality Multiplier')}</th>
              </tr>
            </thead>
            <tbody>
              {data.codebase.map((item, index) => (
                <tr key={index}>
                  <td>{item.component}</td>
                  <td>{item.loc.toLocaleString()}</td>
                  <td>{formatCurrency(item.devCost)}</td>
                  <td>{item.qualityMultiplier.toFixed(2)}%</td>
                </tr>
              ))}
              <tr className={styles.totalRow}>
                <td>{t('investor_pitch.valuation_model.technology.total', 'Total')}</td>
                <td>{data.summary.totalLOC.toLocaleString()}</td>
                <td>{formatCurrency(data.summary.totalDevCost)}</td>
                <td>{data.summary.qualityMultiplier.toFixed(2)}%</td>
              </tr>
            </tbody>
          </table>
          
          <div className={styles.keyMetrics}>
            <div className={styles.metricCard}>
              <span className={styles.metricLabel}>
                {t('investor_pitch.valuation_model.technology.quality_adjusted_value', 'Quality-Adjusted Development Value')}
              </span>
              <span className={styles.metricValue}>{formatCurrency(data.summary.qualityAdjustedValue)}</span>
            </div>
            <div className={styles.metricCard}>
              <span className={styles.metricLabel}>
                {t('investor_pitch.valuation_model.technology.saas_valuation', 'SaaS Valuation')} ({data.saasMetrics.valuationMultiple}x ARR)
              </span>
              <span className={styles.metricValue}>{formatCurrency(data.saasMetrics.saasValuation)}</span>
            </div>
            <div className={styles.metricCard}>
              <span className={styles.metricLabel}>
                {t('investor_pitch.valuation_model.technology.quality_adjusted_saas', 'Quality-Adjusted SaaS Value')}
              </span>
              <span className={styles.metricValue}>{formatCurrency(data.saasMetrics.qualityAdjustedSaasValue)}</span>
            </div>
            <div className={styles.metricCard}>
              <span className={styles.metricLabel}>
                {t('investor_pitch.valuation_model.technology.total_tech_value', 'Total Technology Value')}
              </span>
              <span className={`${styles.metricValue} ${styles.highlightMetric}`}>{formatCurrency(data.totalTechValue)}</span>
            </div>
          </div>
          
          <div className={styles.valuationNotes}>
            <h4>{t('investor_pitch.valuation_model.technology.insights_title', 'Key Valuation Insights:')}</h4>
            <ul>
              <li>
                {t('investor_pitch.valuation_model.technology.insight_1', 'Our codebase represents')}{' '}
                <strong>{formatCurrency(data.summary.qualityAdjustedValue)}</strong>{' '}
                {t('investor_pitch.valuation_model.technology.insight_1_end', 'in development value')}
              </li>
              <li>
                {t('investor_pitch.valuation_model.technology.insight_2', 'SaaS business model alone justifies')}{' '}
                <strong>{formatCurrency(data.saasMetrics.qualityAdjustedSaasValue)}</strong>{' '}
                {t('investor_pitch.valuation_model.technology.insight_2_end', 'in value')}
              </li>
              <li>
                {t('investor_pitch.valuation_model.technology.insight_3', 'Pure software value exceeds')}{' '}
                <strong>{formatCurrency(25000000)}</strong>{' '}
                {t('investor_pitch.valuation_model.technology.insight_3_end', 'by a significant margin')}
              </li>
              <li>
                {t('investor_pitch.valuation_model.technology.insight_4', 'Technology stack built with enterprise-grade quality')}{' '}
                <strong>{data.summary.qualityMultiplier.toFixed(2)}%</strong>{' '}
                {t('investor_pitch.valuation_model.technology.insight_4_end', 'quality multiplier)')}
              </li>
              <li>
                {t('investor_pitch.valuation_model.technology.insight_5', 'Quality multiplier reflects our code quality, maintainability, and technical debt metrics compared to industry standards')}
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };

  const renderInsuranceTab = () => {
    const data = insuranceData[scenarioView];
    
    return (
      <div className={styles.tabContent}>
        {renderScenarioSelector()}
        
        <div className={styles.tableContainer}>
          <h3 className={styles.tableTitle}>
            {t('investor_pitch.valuation_model.insurance.table_title', 'Insurance Economics Model')}
          </h3>
          
          <div className={styles.insuranceMetrics}>
            <div className={styles.metricGroup}>
              <h4 className={styles.metricGroupTitle}>
                {t('investor_pitch.valuation_model.insurance.premium_ratios', 'Premium & Ratios')}
              </h4>
              <div className={styles.metricCard}>
                <span className={styles.metricLabel}>
                  {t('investor_pitch.valuation_model.insurance.premium_volume', 'Annual Premium Volume')}
                </span>
                <span className={styles.metricValue}>{formatCurrency(data.premiumVolume)}</span>
              </div>
              <div className={styles.metricCard}>
                <span className={styles.metricLabel}>
                  {t('investor_pitch.valuation_model.insurance.loss_ratio', 'Loss Ratio')}
                </span>
                <span className={styles.metricValue}>{formatPercentage(data.lossRatio)}</span>
              </div>
              <div className={styles.metricCard}>
                <span className={styles.metricLabel}>
                  {t('investor_pitch.valuation_model.insurance.expense_ratio', 'Expense Ratio')}
                </span>
                <span className={styles.metricValue}>{formatPercentage(data.expenseRatio)}</span>
              </div>
              <div className={styles.metricCard}>
                <span className={styles.metricLabel}>
                  {t('investor_pitch.valuation_model.insurance.combined_ratio', 'Combined Ratio')}
                </span>
                <span className={`${styles.metricValue} ${styles.highlightMetric}`}>{formatPercentage(data.combinedRatio)}</span>
              </div>
            </div>
            
            <div className={styles.metricGroup}>
              <h4 className={styles.metricGroupTitle}>
                {t('investor_pitch.valuation_model.insurance.profitability', 'Profitability')}
              </h4>
              <div className={styles.metricCard}>
                <span className={styles.metricLabel}>
                  {t('investor_pitch.valuation_model.insurance.underwriting_profit', 'Underwriting Profit')}
                </span>
                <span className={styles.metricValue}>{formatCurrency(data.underwritingProfit)}</span>
              </div>
              <div className={styles.metricCard}>
                <span className={styles.metricLabel}>
                  {t('investor_pitch.valuation_model.insurance.investment_income', 'Investment Income')}
                </span>
                <span className={styles.metricValue}>{formatCurrency(data.investmentIncome)}</span>
              </div>
              <div className={styles.metricCard}>
                <span className={styles.metricLabel}>
                  {t('investor_pitch.valuation_model.insurance.total_income', 'Total Insurance Income')}
                </span>
                <span className={`${styles.metricValue} ${styles.highlightMetric}`}>{formatCurrency(data.totalInsuranceIncome)}</span>
              </div>
              <div className={styles.metricCard}>
                <span className={styles.metricLabel}>
                  {t('investor_pitch.valuation_model.insurance.five_year_value', '5-Year Underwriting Value')}
                </span>
                <span className={`${styles.metricValue} ${styles.highlightMetric}`}>{formatCurrency(data.fiveYearValue)}</span>
              </div>
            </div>
          </div>
          
          <div className={styles.valuationNotes}>
            <h4>{t('investor_pitch.valuation_model.insurance.advantages_title', 'Insurance Model Advantages:')}</h4>
            <ul>
              <li>
                {t('investor_pitch.valuation_model.insurance.advantage_1', 'Industry-leading')}{' '}
                <strong>{data.combinedRatio}%</strong>{' '}
                {t('investor_pitch.valuation_model.insurance.advantage_1_end', 'combined ratio vs. industry average of 95-100%')}
              </li>
              <li>
                {t('investor_pitch.valuation_model.insurance.advantage_2', 'Exceptional')}{' '}
                <strong>{data.expenseRatio}%</strong>{' '}
                {t('investor_pitch.valuation_model.insurance.advantage_2_end', 'expense ratio due to AI-driven operations (vs. industry 25-30%)')}
              </li>
              <li>
                {t('investor_pitch.valuation_model.insurance.advantage_3', 'Competitive')}{' '}
                <strong>{data.lossRatio}%</strong>{' '}
                {t('investor_pitch.valuation_model.insurance.advantage_3_end', 'loss ratio through superior underwriting technology')}
              </li>
              <li>
                {t('investor_pitch.valuation_model.insurance.advantage_4', 'Annual income of')}{' '}
                <strong>{formatCurrency(data.totalInsuranceIncome)}</strong>{' '}
                {t('investor_pitch.valuation_model.insurance.advantage_4_end', 'at scale represents significant value creation')}
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };

  const renderCombinedTab = () => {
    const data = combinedData[scenarioView];
    
    return (
      <div className={styles.tabContent}>
        {renderScenarioSelector()}
        
        <div className={styles.tableContainer}>
          <h3 className={styles.tableTitle}>
            {t('investor_pitch.valuation_model.combined.table_title', 'Combined Enterprise Valuation')}
          </h3>
          
          <div className={styles.valuationBreakdown}>
            <div className={styles.valuationComponent}>
              <h4 className={styles.componentTitle}>
                {t('investor_pitch.valuation_model.combined.tech_value_title', 'Technology Value')}
              </h4>
              <div className={styles.componentValue}>{formatCurrency(data.technologyValue)}</div>
              <div className={styles.componentDescription}>
                {t('investor_pitch.valuation_model.combined.tech_value_desc', 'Quality-adjusted software value based on development costs and SaaS potential')}
              </div>
            </div>
            
            <div className={styles.valuationPlus}>+</div>
            
            <div className={styles.valuationComponent}>
              <h4 className={styles.componentTitle}>
                {t('investor_pitch.valuation_model.combined.underwriting_title', '5-Year Underwriting Value')}
              </h4>
              <div className={styles.componentValue}>{formatCurrency(data.underwritingValue)}</div>
              <div className={styles.componentDescription}>
                {t('investor_pitch.valuation_model.combined.underwriting_desc', 'Projected 5-year value of insurance operations at scale')}
              </div>
            </div>
            
            <div className={styles.valuationEquals}>=</div>
            
            <div className={styles.valuationComponent}>
              <h4 className={styles.componentTitle}>
                {t('investor_pitch.valuation_model.combined.enterprise_title', 'Total Enterprise Value')}
              </h4>
              <div className={`${styles.componentValue} ${styles.totalValue}`}>{formatCurrency(data.totalEnterpriseValue)}</div>
              <div className={styles.componentDescription}>
                {t('investor_pitch.valuation_model.combined.enterprise_desc', 'Combined value of technology and insurance operations')}
              </div>
            </div>
          </div>
          
          <div className={styles.seriesADetails}>
            <h4 className={styles.seriesATitle}>
              {t('investor_pitch.valuation_model.combined.series_a_title', 'Series A Investment Structure')}
            </h4>
            
            <div className={styles.seriesAMetrics}>
              <div className={styles.metricCard}>
                <span className={styles.metricLabel}>
                  {t('investor_pitch.valuation_model.combined.series_a_investment', 'Series A Investment')}
                </span>
                <span className={styles.metricValue}>{formatCurrency(data.seriesAInvestment)}</span>
              </div>
              <div className={styles.metricCard}>
                <span className={styles.metricLabel}>
                  {t('investor_pitch.valuation_model.combined.pre_money', 'Pre-Money Valuation')}
                </span>
                <span className={styles.metricValue}>{formatCurrency(data.preMoneyValuation)}</span>
              </div>
              <div className={styles.metricCard}>
                <span className={styles.metricLabel}>
                  {t('investor_pitch.valuation_model.combined.post_money', 'Post-Money Valuation')}
                </span>
                <span className={`${styles.metricValue} ${styles.highlightMetric}`}>{formatCurrency(data.postMoneyValuation)}</span>
              </div>
              <div className={styles.metricCard}>
                <span className={styles.metricLabel}>
                  {t('investor_pitch.valuation_model.combined.investor_equity', 'Investor Equity')}
                </span>
                <span className={styles.metricValue}>{formatPercentage(data.equityPercentage)}</span>
              </div>
            </div>
            
            <div className={styles.valuationNotes}>
              <h4>{t('investor_pitch.valuation_model.combined.rationale_title', 'Valuation Rationale:')}</h4>
              <ul>
                <li>
                  {t('investor_pitch.valuation_model.combined.rationale_1', 'Conservative scenario selected due to novelty of our approach')}
                </li>
                <li>
                  {scenarioView === 'conservative' 
                    ? (
                      <>
                        {t('investor_pitch.valuation_model.combined.rationale_2_conservative', 'Our pre-money valuation precisely matches our')}{' '}
                        <strong>{t('investor_pitch.valuation_model.combined.rationale_2_conservative_value', 'conservative enterprise value')}</strong>{' '}
                        {t('investor_pitch.valuation_model.combined.rationale_2_conservative_end', 'of')} {formatCurrency(data.totalEnterpriseValue)}
                      </>
                    ) : (
                      <>
                        {t('investor_pitch.valuation_model.combined.rationale_2_discount', 'Our pre-money valuation represents a')}{' '}
                        <strong>{((1 - (1003795930 / data.totalEnterpriseValue)) * 100).toFixed(0)}%</strong>{' '}
                        {t('investor_pitch.valuation_model.combined.rationale_2_discount_end', 'discount to calculated enterprise value')}
                      </>
                    )
                  }
                </li>
                <li>
                  {t('investor_pitch.valuation_model.combined.rationale_3', 'Provides significant upside potential for Series A investors')}
                </li>
                <li>
                  {t('investor_pitch.valuation_model.combined.rationale_4', 'Structured to enable rapid scaling while maintaining strong investor returns')}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderTermsTab = () => {
    return (
      <div className={styles.tabContent}>
        <div className={styles.tableContainer}>
          <h3 className={styles.tableTitle}>
            {t('investor_pitch.valuation_model.terms.table_title', 'Series A Terms & Exit Scenarios')}
          </h3>
          
          <div className={styles.termsSection}>
            <div className={styles.termsDetails}>
              <h4 className={styles.termsTitle}>
                {t('investor_pitch.valuation_model.terms.proposed_terms', 'Proposed Series A Terms')}
              </h4>
              <table className={styles.termsTable}>
                <tbody>
                  <tr>
                    <td>{t('investor_pitch.valuation_model.terms.investment', 'Investment')}</td>
                    <td>{formatCurrency(termsData.investment)}</td>
                  </tr>
                  <tr>
                    <td>{t('investor_pitch.valuation_model.terms.pre_money', 'Pre-Money Valuation')}</td>
                    <td>{formatCurrency(termsData.preMoneyValuation)}</td>
                  </tr>
                  <tr>
                    <td>{t('investor_pitch.valuation_model.terms.post_money', 'Post-Money Valuation')}</td>
                    <td>{formatCurrency(termsData.postMoneyValuation)}</td>
                  </tr>
                  <tr>
                    <td>{t('investor_pitch.valuation_model.terms.liquidation', 'Liquidation Preference')}</td>
                    <td>{termsData.liquidationPreference}</td>
                  </tr>
                  <tr>
                    <td>{t('investor_pitch.valuation_model.terms.anti_dilution', 'Anti-Dilution')}</td>
                    <td>{termsData.antiDilution}</td>
                  </tr>
                  <tr>
                    <td>{t('investor_pitch.valuation_model.terms.board', 'Board Composition')}</td>
                    <td>{termsData.boardComposition}</td>
                  </tr>
                  <tr>
                    <td>{t('investor_pitch.valuation_model.terms.pro_rata', 'Pro-Rata Rights')}</td>
                    <td>{termsData.proRataRights}</td>
                  </tr>
                  <tr>
                    <td>{t('investor_pitch.valuation_model.terms.information', 'Information Rights')}</td>
                    <td>{termsData.informationRights}</td>
                  </tr>
                  <tr>
                    <td>{t('investor_pitch.valuation_model.terms.dividends', 'Dividends')}</td>
                    <td>{termsData.dividends}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            
            <div className={styles.exitScenarios}>
              <h4 className={styles.exitTitle}>
                {t('investor_pitch.valuation_model.terms.exit_title', 'Exit Scenario Analysis')}
              </h4>
              
              {termsData.exitScenarios.map((scenario, index) => (
                <div key={index} className={styles.exitScenario}>
                  <h5 className={styles.exitValuation}>
                    {formatCurrency(scenario.valuation)} {t('investor_pitch.valuation_model.terms.exit', 'Exit')}
                  </h5>
                  <div className={styles.exitBreakdown}>
                    <div className={styles.exitBar}>
                      <div 
                        className={styles.seriesAPortion} 
                        style={{ width: `${(scenario.seriesA / scenario.valuation) * 100}%` }}
                      >
                        <span className={styles.exitLabel}>
                          {t('investor_pitch.valuation_model.terms.series_a', 'Series A')}
                        </span>
                      </div>
                      <div 
                        className={styles.commonPortion}
                        style={{ width: `${(scenario.common / scenario.valuation) * 100}%` }}
                      >
                        <span className={styles.exitLabel}>
                          {t('investor_pitch.valuation_model.terms.common', 'Common')}
                        </span>
                      </div>
                    </div>
                    <div className={styles.exitValues}>
                      <div className={styles.exitValue}>
                        <span>{t('investor_pitch.valuation_model.terms.series_a', 'Series A')}: {formatCurrency(scenario.seriesA)}</span>
                        <span>{((scenario.seriesA / scenario.valuation) * 100).toFixed(1)}%</span>
                      </div>
                      <div className={styles.exitValue}>
                        <span>{t('investor_pitch.valuation_model.terms.common', 'Common')}: {formatCurrency(scenario.common)}</span>
                        <span>{((scenario.common / scenario.valuation) * 100).toFixed(1)}%</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          
          <div className={styles.fundDistribution}>
            <h4 className={styles.distributionTitle}>
              {t('investor_pitch.valuation_model.terms.fund_distribution', 'Use of Funds')}
            </h4>
            <div className={styles.distributionChart}>
              {termsData.fundDistribution.map((item, index) => (
                <div key={index} className={styles.distributionItem}>
                  <div 
                    className={styles.distributionBar} 
                    style={{ width: `${item.percentage}%` }}
                  >
                    <span className={styles.distributionLabel}>
                      {item.percentage}%
                    </span>
                  </div>
                  <div className={styles.distributionDetails}>
                    <span className={styles.distributionCategory}>{item.category}</span>
                    <span className={styles.distributionAmount}>{formatCurrency(item.amount)}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
          
          <div className={styles.valuationNotes}>
            <h4>{t('investor_pitch.valuation_model.terms.return_title', 'Return Potential:')}</h4>
            <ul>
              <li>
                {t('investor_pitch.valuation_model.terms.return_1', 'At $10B exit:')}{' '}
                <strong>{t('investor_pitch.valuation_model.terms.return_1_value', '8x return')}</strong>{' '}
                {t('investor_pitch.valuation_model.terms.return_1_end', 'on Series A investment')}
              </li>
              <li>
                {t('investor_pitch.valuation_model.terms.return_2', 'At $30B target valuation:')}{' '}
                <strong>{t('investor_pitch.valuation_model.terms.return_2_value', '24x return')}</strong>{' '}
                {t('investor_pitch.valuation_model.terms.return_2_end', 'on Series A investment')}
              </li>
              <li>
                {t('investor_pitch.valuation_model.terms.return_3', 'Industry-standard terms with investor-friendly governance provisions')}
              </li>
              <li>
                {t('investor_pitch.valuation_model.terms.return_4', 'Clear path to liquidity through IPO or strategic acquisition')}
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'technology':
        return renderTechnologyTab();
      case 'insurance':
        return renderInsuranceTab();
      case 'combined':
        return renderCombinedTab();
      case 'terms':
        return renderTermsTab();
      default:
        return renderTechnologyTab();
    }
  };

  return (
    <motion.div
      className={styles.container}
      variants={ANIMATION_VARIANTS.stagger.container}
      initial="hidden"
      animate="visible"
    >
      <motion.div 
        className={styles.header}
        variants={ANIMATION_VARIANTS.fadeIn}
      >
        <h2 className={styles.title}>
          {t('investor_pitch.valuation_model.title', 'Valuation Model & Series A Structure')}
        </h2>
        <p className={styles.subtitle}>
          {t('investor_pitch.valuation_model.subtitle', 'Comprehensive analysis supporting our $1B pre-money valuation')}
        </p>
      </motion.div>

      <motion.div 
        className={styles.content}
        variants={ANIMATION_VARIANTS.fadeIn}
      >
        <div className={styles.tabsContainer}>
          <div className={styles.tabs}>
            {tabs.map(tab => (
              <button
                key={tab.id}
                className={`${styles.tabButton} ${activeTab === tab.id ? styles.activeTab : ''}`}
                onClick={() => setActiveTab(tab.id)}
              >
                {tab.label}
              </button>
            ))}
          </div>
          
          <div className={styles.tabContentContainer}>
            {renderTabContent()}
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default ValuationModelSlide;