// File: frontend/src/pages/SuperAdmin/components/AgreementManagement/AgreementList/index.js

import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import SuperAdminTable from '../../common/SuperAdminTable';
import SuperAdminSearch from '../../common/SuperAdminSearch';
import SuperAdminPagination from '../../common/SuperAdminPagination';
import styles from './AgreementList.module.css';

const AgreementList = ({ onViewDetail, onCreateNew }) => {
  const { t } = useLanguage();
  const [agreements, setAgreements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterType, setFilterType] = useState('all');
  const [filterStatus, setFilterStatus] = useState('all');

  useEffect(() => {
    const fetchAgreements = async () => {
      setLoading(true);
      setError(null);
      
      try {
        // This would be replaced with an actual API call
        // const response = await agreementService.listAgreements({
        //   page: currentPage,
        //   search: searchTerm,
        //   type: filterType !== 'all' ? filterType : undefined,
        //   status: filterStatus !== 'all' ? filterStatus : undefined
        // });
        
        // Mock data for now
        await new Promise(resolve => setTimeout(resolve, 800));
        
        const mockAgreements = [
          {
            agreement_id: 'agree-12345',
            enterprise_id: 'ent-12345',
            enterprise_name: 'Acme Corporation',
            agreement_start_timestamp: '2023-01-15T00:00:00Z',
            agreement_end_timestamp: '2024-01-14T23:59:59Z',
            agreement_type: 'production',
            agreement_status: 'active',
            agreement_notes: {
              notes: 'Standard agreement for production use',
              terms: 'Net 30 payment terms'
            },
            document_links: {
              contract: 'https://example.com/contracts/acme-contract.pdf',
              terms: 'https://example.com/terms/acme-terms.pdf'
            },
            created_at: '2023-01-10T15:30:00Z',
            updated_at: '2023-01-10T15:30:00Z'
          },
          {
            agreement_id: 'agree-23456',
            enterprise_id: 'ent-23456',
            enterprise_name: 'Global Industries',
            agreement_start_timestamp: '2023-02-01T00:00:00Z',
            agreement_end_timestamp: '2024-01-31T23:59:59Z',
            agreement_type: 'production',
            agreement_status: 'active',
            agreement_notes: {
              notes: 'Enterprise-wide agreement',
              terms: 'Net 45 payment terms'
            },
            document_links: {
              contract: 'https://example.com/contracts/global-contract.pdf',
              terms: 'https://example.com/terms/global-terms.pdf'
            },
            created_at: '2023-01-25T11:45:00Z',
            updated_at: '2023-01-25T11:45:00Z'
          },
          {
            agreement_id: 'agree-34567',
            enterprise_id: 'ent-34567',
            enterprise_name: 'Tech Solutions',
            agreement_start_timestamp: '2023-03-15T00:00:00Z',
            agreement_end_timestamp: '2023-09-14T23:59:59Z',
            agreement_type: 'trial',
            agreement_status: 'active',
            agreement_notes: {
              notes: '6-month trial agreement',
              terms: 'No payment required during trial'
            },
            document_links: {
              contract: 'https://example.com/contracts/tech-contract.pdf',
              terms: 'https://example.com/terms/tech-terms.pdf'
            },
            created_at: '2023-03-10T09:20:00Z',
            updated_at: '2023-03-10T09:20:00Z'
          },
          {
            agreement_id: 'agree-45678',
            enterprise_id: 'ent-45678',
            enterprise_name: 'Innovative Systems',
            agreement_start_timestamp: '2023-04-01T00:00:00Z',
            agreement_end_timestamp: '2023-06-30T23:59:59Z',
            agreement_type: 'pilot',
            agreement_status: 'active',
            agreement_notes: {
              notes: '3-month pilot program',
              terms: 'Reduced rate during pilot'
            },
            document_links: {
              contract: 'https://example.com/contracts/innovative-contract.pdf',
              terms: 'https://example.com/terms/innovative-terms.pdf'
            },
            created_at: '2023-03-25T14:10:00Z',
            updated_at: '2023-03-25T14:10:00Z'
          },
          {
            agreement_id: 'agree-56789',
            enterprise_id: 'ent-56789',
            enterprise_name: 'Digital Solutions',
            agreement_start_timestamp: '2022-05-01T00:00:00Z',
            agreement_end_timestamp: '2023-04-30T23:59:59Z',
            agreement_type: 'production',
            agreement_status: 'expired',
            agreement_notes: {
              notes: 'Annual agreement - expired',
              terms: 'Net 30 payment terms'
            },
            document_links: {
              contract: 'https://example.com/contracts/digital-contract.pdf',
              terms: 'https://example.com/terms/digital-terms.pdf'
            },
            created_at: '2022-04-15T10:30:00Z',
            updated_at: '2023-05-01T00:00:01Z'
          }
        ];
        
        // Filter based on search term, type, and status
        let filteredAgreements = mockAgreements;
        
        if (searchTerm) {
          const searchLower = searchTerm.toLowerCase();
          filteredAgreements = filteredAgreements.filter(agreement => 
            agreement.enterprise_name.toLowerCase().includes(searchLower) ||
            agreement.agreement_id.toLowerCase().includes(searchLower)
          );
        }
        
        if (filterType !== 'all') {
          filteredAgreements = filteredAgreements.filter(agreement => 
            agreement.agreement_type === filterType
          );
        }
        
        if (filterStatus !== 'all') {
          filteredAgreements = filteredAgreements.filter(agreement => 
            agreement.agreement_status === filterStatus
          );
        }
        
        setAgreements(filteredAgreements);
        setTotalPages(Math.ceil(filteredAgreements.length / 10)); // Assuming 10 items per page
      } catch (err) {
        console.error('Error fetching agreements:', err);
        setError(t('failed_to_load_agreements'));
      } finally {
        setLoading(false);
      }
    };
    
    fetchAgreements();
  }, [currentPage, searchTerm, filterType, filterStatus, t]);

  const handleSearch = (term) => {
    setSearchTerm(term);
    setCurrentPage(1);
  };

  const handleTypeFilterChange = (e) => {
    setFilterType(e.target.value);
    setCurrentPage(1);
  };

  const handleStatusFilterChange = (e) => {
    setFilterStatus(e.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    }).format(date);
  };

  const getStatusBadgeClass = (status) => {
    switch (status) {
      case 'active':
        return styles.statusActive;
      case 'expired':
        return styles.statusExpired;
      case 'terminated':
        return styles.statusTerminated;
      case 'pending':
        return styles.statusPending;
      default:
        return '';
    }
  };

  const getAgreementTypeClass = (type) => {
    switch (type) {
      case 'production':
        return styles.typeProduction;
      case 'trial':
        return styles.typeTrial;
      case 'pilot':
        return styles.typePilot;
      default:
        return '';
    }
  };

  const columns = [
    {
      header: t('agreement_id'),
      accessor: 'agreement_id',
      cell: (row) => <span className={styles.idCell}>{row.agreement_id}</span>
    },
    {
      header: t('enterprise'),
      accessor: 'enterprise_name',
      cell: (row) => <span className={styles.nameCell}>{row.enterprise_name}</span>
    },
    {
      header: t('type'),
      accessor: 'agreement_type',
      cell: (row) => (
        <span className={`${styles.typeBadge} ${getAgreementTypeClass(row.agreement_type)}`}>
          {row.agreement_type}
        </span>
      )
    },
    {
      header: t('status'),
      accessor: 'agreement_status',
      cell: (row) => (
        <span className={`${styles.statusBadge} ${getStatusBadgeClass(row.agreement_status)}`}>
          {row.agreement_status}
        </span>
      )
    },
    {
      header: t('start_date'),
      accessor: 'agreement_start_timestamp',
      cell: (row) => <span>{formatDate(row.agreement_start_timestamp)}</span>
    },
    {
      header: t('end_date'),
      accessor: 'agreement_end_timestamp',
      cell: (row) => <span>{formatDate(row.agreement_end_timestamp)}</span>
    },
    {
      header: t('created_at'),
      accessor: 'created_at',
      cell: (row) => <span>{formatDate(row.created_at)}</span>
    },
    {
      header: t('actions'),
      accessor: 'actions',
      cell: (row) => (
        <div className={styles.actions}>
          <button 
            className={styles.actionButton}
            onClick={() => onViewDetail(row.agreement_id)}
          >
            <i className="fas fa-eye"></i>
            <span>{t('view')}</span>
          </button>
        </div>
      )
    }
  ];

  return (
    <div className={styles.container}>
      <div className={styles.toolbar}>
        <div className={styles.filters}>
          <SuperAdminSearch 
            placeholder={t('search_agreements')}
            onSearch={handleSearch}
          />
          
          <div className={styles.filterGroup}>
            <label htmlFor="typeFilter" className={styles.filterLabel}>
              {t('type')}:
            </label>
            <select
              id="typeFilter"
              className={styles.filterSelect}
              value={filterType}
              onChange={handleTypeFilterChange}
            >
              <option value="all">{t('all_types')}</option>
              <option value="production">{t('production')}</option>
              <option value="trial">{t('trial')}</option>
              <option value="pilot">{t('pilot')}</option>
            </select>
          </div>
          
          <div className={styles.filterGroup}>
            <label htmlFor="statusFilter" className={styles.filterLabel}>
              {t('status')}:
            </label>
            <select
              id="statusFilter"
              className={styles.filterSelect}
              value={filterStatus}
              onChange={handleStatusFilterChange}
            >
              <option value="all">{t('all_statuses')}</option>
              <option value="active">{t('active')}</option>
              <option value="expired">{t('expired')}</option>
              <option value="terminated">{t('terminated')}</option>
              <option value="pending">{t('pending')}</option>
            </select>
          </div>
        </div>
        
        <button className={styles.createButton} onClick={onCreateNew}>
          <i className="fas fa-plus"></i>
          <span>{t('create_agreement')}</span>
        </button>
      </div>
      
      {error && <div className={styles.error}>{error}</div>}
      
      <SuperAdminTable
        columns={columns}
        data={agreements}
        loading={loading}
        emptyMessage={t('no_agreements_found')}
      />
      
      <div className={styles.pagination}>
        <SuperAdminPagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default AgreementList;