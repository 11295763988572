// File: frontend/src/pages/InvestorPitch/components/common/ProgressBar.js

import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import styles from './ProgressBar.module.css';

const ProgressBar = ({ value, max, gradient }) => {
  const percentage = (value / max) * 100;

  return (
    <div className={styles.container}>
      <motion.div 
        className={styles.progress}
        style={{ 
          background: gradient,
          width: `${percentage}%` 
        }}
        initial={{ width: 0 }}
        animate={{ width: `${percentage}%` }}
        transition={{ duration: 0.8, ease: "easeOut" }}
      />
    </div>
  );
};

ProgressBar.propTypes = {
  value: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  gradient: PropTypes.string.isRequired
};

export default ProgressBar;