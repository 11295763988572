// File: frontend/src/components/UploadSection/UploadAreaFile.js

import React from 'react';
import Button from '../Button/Button';
import styles from './UploadAreaFile.module.css';

const UploadAreaFile = ({ file, onFileChange, onFileRemove, acceptedFileTypes }) => {
  const handleChange = () => {
    document.getElementById('fileInput').click();
  };

  return (
    <div className={styles.uploadAreaFile}>
      <div className={styles.fileInfo}>
        <span className={styles.fileName}>{file.name}</span>
        <span className={styles.fileSize}>{(file.size / 1024).toFixed(0)} KB</span>
      </div>
      <div className={styles.actions}>
        <Button
          label="Change"
          icon="/icons/18/misc/refresh.svg"
          onClick={handleChange}
          variant="refresh"
        />
        <Button
          icon="/icons/18/misc/delete.svg"
          onClick={onFileRemove}
          variant="delete"
          aria-label="Remove file"
        />
      </div>
      <input
        id="fileInput"
        type="file"
        accept={acceptedFileTypes}
        style={{ display: 'none' }}
        onChange={(e) => onFileChange(e.target.files[0])}
      />
    </div>
  );
};

export default UploadAreaFile;