// File: frontend/src/hooks/useSystemStatus.js

import { useState, useEffect, useCallback } from 'react';
import { API_ENDPOINTS } from '../config/constants';
import apiService from '../services/api';

export const useSystemStatus = () => {
  const [currentStatus, setCurrentStatus] = useState(null);
  const [uptimeHistory, setUptimeHistory] = useState([]);
  const [metrics, setMetrics] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const processServiceMetrics = (services) => {
    if (!services) return {};

    return Object.entries(services).map(([name, data]) => ({
      name,
      displayName: name.charAt(0).toUpperCase() + name.slice(1),
      status: data.status,
      latency: data.latency,
      details: data.details || null,
      additionalInfo: {
        ...data.quotas && { quotas: data.quotas },
        ...data.topicCount !== undefined && { topicCount: data.topicCount },
        ...data.webAclName && { webAclName: data.webAclName }
      }
    }));
  };

  const processLambdaMetrics = (lambdaData) => {
    if (!lambdaData?.functions) return [];

    return Object.values(lambdaData.functions).map(func => ({
      name: func.name,
      displayName: func.displayName,
      description: func.description,
      groupName: func.groupName,
      status: func.status,
      metrics: {
        errorRate: func.metrics.errorRate,
        averageDuration: func.metrics.averageDuration,
        totalInvocations: func.metrics.totalInvocations,
        totalErrors: func.metrics.totalErrors
      }
    }));
  };

  const processAwsMetrics = (awsMetrics) => {
    if (!awsMetrics) return null;

    return {
        apiGateway: awsMetrics.apiGateway ? {
            status: awsMetrics.apiGateway.status,
            metrics: awsMetrics.apiGateway.metrics
        } : null,
        lambda: awsMetrics.lambda ? {
            status: awsMetrics.lambda.status,
            // Ensure functions is always an array
            functions: Array.isArray(awsMetrics.lambda.functions) 
                ? awsMetrics.lambda.functions 
                : Object.values(awsMetrics.lambda.functions || {}),
            summary: {
                totalFunctions: awsMetrics.lambda.metrics?.totalFunctions || 0,
                errorCount: awsMetrics.lambda.metrics?.errorCount || 0
            }
        } : null,
        waf: awsMetrics.waf ? {
            status: awsMetrics.waf.status,
            metrics: awsMetrics.waf.metrics
        } : null
    };
};

  const processHistory = (history) => {
      if (!history) return [];
  
      return history.map(check => {
          // Process AWS metrics for historical data
          const historicalAwsMetrics = check.awsMetrics ? processAwsMetrics(check.awsMetrics) : null;
  
          return {
              id: check.id,
              timestamp: new Date(check.timestamp * 1000),
              status: check.status,
              executionTime: check.executionTime,
              services: processServiceMetrics(check.services),
              metrics: check.aggregatedMetrics || null,
              awsMetrics: historicalAwsMetrics // Add AWS metrics to history items
          };
      });
  };

  const calculateUptimePercentage = (history) => {
    if (!history?.length) return 0;
    const weights = { OK: 1, DEGRADED: 0.5, ERROR: 0 };
    const total = history.reduce((sum, check) => sum + (weights[check.status] || 0), 0);
    return (total / history.length) * 100;
  };

  const calculateAverageLatency = (services) => {
    if (!services?.length) return 0;
    const latencies = services
      .map(service => service.latency)
      .filter(latency => typeof latency === 'number');
    return latencies.length ? 
      latencies.reduce((sum, latency) => sum + latency, 0) / latencies.length : 0;
  };

  const calculateServiceHealth = (services) => {
    if (!services?.length) return { ok: 0, degraded: 0, error: 0 };
    
    return services.reduce((acc, service) => {
      const status = service.status.toLowerCase();
      if (status === 'ok') acc.ok++;
      else if (status === 'degraded') acc.degraded++;
      else if (status === 'error') acc.error++;
      return acc;
    }, { ok: 0, degraded: 0, error: 0 });
  };  

  const fetchStatus = useCallback(async () => {
    // console.log('Starting fetchStatus');
    try {
      setIsLoading(true);
      // console.log('Fetching from API:', `${API_ENDPOINTS.SYSTEM.HEALTH_CHECKS}??detailed=true&history_limit=30&aws_metrics=true`);
      
      const response = await apiService.get(
        `${API_ENDPOINTS.SYSTEM.HEALTH_CHECKS}?detailed=true&history_limit=30&aws_metrics=true`
      );
      // console.log('API Response:', response);
  
      const processedServices = processServiceMetrics(response.services);
      // console.log('Processed Services:', processedServices);

      // Check if awsMetrics exists in the response
      if (!response.awsMetrics) {
        console.warn('No AWS metrics found in response');
      }
  
      const processedAwsMetrics = processAwsMetrics(response.awsMetrics);
      // console.log('Processed AWS Metrics:', processedAwsMetrics);
  
      const processedHistory = processHistory(response.history);
      // console.log('Processed History:', processedHistory);
  
      const currentMetrics = {
        uptime: calculateUptimePercentage(processedHistory),
        averageLatency: calculateAverageLatency(processedServices),
        serviceHealth: calculateServiceHealth(processedServices),
        aws: processedAwsMetrics
      };
      // console.log('Current Metrics:', currentMetrics);
  
      setCurrentStatus({
        status: response.status,
        timestamp: new Date(response.timestamp),
        environment: response.environment,
        region: response.region,
        checkId: response.checkId,
        executionTime: response.executionTime,
        services: processedServices
      });
      // console.log('Current Status Set');
  
      setUptimeHistory(processedHistory);
      setMetrics(currentMetrics);
      setError(null);
    } catch (err) {
      console.error('Error in fetchStatus:', err);
      setError(err);
    } finally {
      setIsLoading(false);
      // console.log('fetchStatus completed');
    }
  }, []);

  const refresh = () => {
    fetchStatus();
  };

  useEffect(() => {
    fetchStatus();
    const interval = setInterval(fetchStatus, 600000); // Refresh every 10 minutes
    return () => clearInterval(interval);
  }, [fetchStatus]);

  return {
    currentStatus,
    uptimeHistory,
    metrics,
    isLoading,
    error,
    refresh
  };
};
