// File: frontend/src/pages/Insurance/Insurance.js

import React from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import styles from './Insurance.module.css';

const Insurance = () => {
  const { t } = useLanguage();

  return (
    <div >
    </div>
  );
};

export default Insurance;