// File: frontend/src/pages/QMS/QuoteDetail/components/QuoteOverview/index.js

import React from 'react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from './QuoteOverview.module.css';

// Helper function to format date in YYYY-MM-DD hh:mm AM/PM format
const formatDateTime = (dateString) => {
  if (!dateString) return 'N/A';
  
  const date = new Date(dateString);
  
  // Check if date is valid
  if (isNaN(date.getTime())) return 'Invalid Date';
  
  // Format date as YYYY-MM-DD
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  
  // Format time as hh:mm AM/PM
  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  
  // Convert hours to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const formattedHours = String(hours).padStart(2, '0');
  
  return `${year}-${month}-${day} ${formattedHours}:${minutes} ${ampm}`;
};

const QuoteOverview = ({ quote }) => {
  const { t } = useLanguage();

  return (
    <div className={styles.container}>
      <div className={styles.grid}>
        <div className={styles.card}>
          <h3 className={styles.cardTitle}>{t('quote_information')}</h3>
          <div className={styles.cardContent}>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('quote_id')}:</div>
              <div className={styles.infoValue}>{quote.quote_id}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('qnr')}:</div>
              <div className={styles.infoValue}>{quote.qnr}</div>
            </div>
            {quote.qnr_link && (
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('qnr_link')}:</div>
                <div className={styles.infoValue}>
                  <a href={quote.qnr_link} target="_blank" rel="noopener noreferrer">
                    {quote.qnr_link}
                  </a>
                </div>
              </div>
            )}
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('status')}:</div>
              <div className={styles.infoValue}>
                <span className={styles[quote.meta?.quote_status]}>
                  {t(quote.meta?.quote_status)}
                </span>
              </div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('source')}:</div>
              <div className={styles.infoValue}>{quote.meta?.source || 'N/A'}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('product_types')}:</div>
              <div className={styles.infoValue}>
                {quote.meta?.product_types?.map((type, index) => (
                  <span key={type} className={styles.tag}>
                    {t(type)}
                    {index < quote.meta.product_types.length - 1 ? ', ' : ''}
                  </span>
                )) || 'N/A'}
              </div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('created_at')}:</div>
              <div className={styles.infoValue}>{formatDateTime(quote.timestamps?.created_at)}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('updated_at')}:</div>
              <div className={styles.infoValue}>{formatDateTime(quote.timestamps?.updated_at)}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('expires_at')}:</div>
              <div className={styles.infoValue}>{formatDateTime(quote.timestamps?.expires_at)}</div>
            </div>
          </div>
        </div>
        
        <div className={styles.card}>
          <h3 className={styles.cardTitle}>{t('contact_information')}</h3>
          <div className={styles.cardContent}>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('name')}:</div>
              <div className={styles.infoValue}>
                {quote.contact?.first_name} {quote.contact?.last_name}
              </div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('email')}:</div>
              <div className={styles.infoValue}>{quote.contact?.email_address}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('phone')}:</div>
              <div className={styles.infoValue}>{quote.contact?.mobile_phone_number}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('date_of_birth')}:</div>
              <div className={styles.infoValue}>{quote.contact?.date_of_birth || 'N/A'}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('language')}:</div>
              <div className={styles.infoValue}>{quote.contact?.primary_language || 'N/A'}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('country')}:</div>
              <div className={styles.infoValue}>{quote.contact?.country_of_residence || 'N/A'}</div>
            </div>
          </div>
        </div>
      </div>
      
      <div className={styles.card}>
        <h3 className={styles.cardTitle}>{t('premium_information')}</h3>
        <div className={styles.cardContent}>
          {quote.premium?.policy_premium ? (
            <div className={styles.premiumGrid}>
              <div className={styles.premiumCard}>
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('base_premium')}:</div>
                  <div className={styles.infoValue}>
                    ${quote.premium.policy_premium.base_premium?.toFixed(2) || 'N/A'}
                  </div>
                </div>
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('discounted_premium')}:</div>
                  <div className={styles.infoValue}>
                    ${quote.premium.policy_premium.discounted_premium?.toFixed(2) || 'N/A'}
                  </div>
                </div>
              </div>
              
              <div className={styles.premiumCard}>
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('fees')}:</div>
                  <div className={styles.infoValue}>
                    ${quote.premium.policy_premium.fees_amount?.toFixed(2) || 'N/A'}
                  </div>
                </div>
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('taxes')}:</div>
                  <div className={styles.infoValue}>
                    ${quote.premium.policy_premium.taxes_amount?.toFixed(2) || 'N/A'}
                  </div>
                </div>
              </div>
              
              <div className={styles.premiumCard}>
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('final_premium')}:</div>
                  <div className={`${styles.infoValue} ${styles.finalPremium}`}>
                    ${quote.premium.policy_premium.final_premium?.toFixed(2) || 'N/A'}
                  </div>
                </div>
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('premium_status')}:</div>
                  <div className={styles.infoValue}>
                    <span className={styles.tag}>
                      {quote.premium.policy_premium.premium_status || 'N/A'}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.noData}>{t('no_premium_information')}</div>
          )}
        </div>
      </div>
      
      <div className={styles.card}>
        <h3 className={styles.cardTitle}>{t('verification_status')}</h3>
        <div className={styles.cardContent}>
          <div className={styles.verificationGrid}>
            <div className={styles.verificationItem}>
              <div className={styles.verificationLabel}>{t('email_verified')}:</div>
              <div className={`${styles.verificationStatus} ${quote.verification?.email_verified ? styles.verified : styles.notVerified}`}>
                {quote.verification?.email_verified ? t('yes') : t('no')}
              </div>
            </div>
            
            <div className={styles.verificationItem}>
              <div className={styles.verificationLabel}>{t('phone_verified')}:</div>
              <div className={`${styles.verificationStatus} ${quote.verification?.phone_verified ? styles.verified : styles.notVerified}`}>
                {quote.verification?.phone_verified ? t('yes') : t('no')}
              </div>
            </div>
            
            {quote.verification?.email_verified && (
              <div className={styles.verificationItem}>
                <div className={styles.verificationLabel}>{t('email_verified_at')}:</div>
                <div className={styles.verificationValue}>
                  {formatDateTime(quote.verification?.email_verified_at)}
                </div>
              </div>
            )}
            
            {quote.verification?.phone_verified && (
              <div className={styles.verificationItem}>
                <div className={styles.verificationLabel}>{t('phone_verified_at')}:</div>
                <div className={styles.verificationValue}>
                  {formatDateTime(quote.verification?.phone_verified_at)}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuoteOverview;