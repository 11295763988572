// File: frontend/src/pages/UMS/UserDetail/components/UserActions/index.js

import React, { useState } from 'react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from './UserActions.module.css';

const UserActions = ({ user }) => {
  const { t } = useLanguage();
  const [dateRange, setDateRange] = useState('last_seven_days');
  const [actionType, setActionType] = useState('');
  const [loading, setLoading] = useState(false);
  const [actions, setActions] = useState([]);

  // Simulated actions data
  const mockActions = [
    {
      action_id: 'act-12345',
      user_id: user.user_id,
      action_timestamp: new Date().toISOString(),
      data_source: 'mobile_app',
      device_type: 'smartphone',
      connection_type: 'wifi',
      application: 'allegory_app',
      system_name: 'iOS',
      ip_address: '192.168.1.1',
      user_agent: 'Mozilla/5.0 (iPhone; CPU iPhone OS 14_7_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/14.1.2 Mobile/15E148 Safari/604.1',
      device_id: 'iPhone12,1',
      feature_id: 'login',
      action_type: 'authentication'
    },
    {
      action_id: 'act-12346',
      user_id: user.user_id,
      action_timestamp: new Date(Date.now() - 3600000).toISOString(),
      data_source: 'web_app',
      device_type: 'desktop',
      connection_type: 'ethernet',
      application: 'allegory_web',
      system_name: 'Windows',
      ip_address: '192.168.1.2',
      user_agent: 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.124 Safari/537.36',
      device_id: 'desktop-win10',
      feature_id: 'profile_update',
      action_type: 'profile'
    },
    {
      action_id: 'act-12347',
      user_id: user.user_id,
      action_timestamp: new Date(Date.now() - 7200000).toISOString(),
      data_source: 'mobile_app',
      device_type: 'smartphone',
      connection_type: 'cellular',
      application: 'allegory_app',
      system_name: 'Android',
      ip_address: '192.168.1.3',
      user_agent: 'Mozilla/5.0 (Linux; Android 11; SM-G991B) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.120 Mobile Safari/537.36',
      device_id: 'SM-G991B',
      feature_id: 'permission_update',
      action_type: 'permissions'
    }
  ];

  const handleSearch = () => {
    setLoading(true);
    
    // Simulate API call
    setTimeout(() => {
      setActions(mockActions.filter(action => {
        if (actionType && action.action_type !== actionType) {
          return false;
        }
        return true;
      }));
      setLoading(false);
    }, 500);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2 className={styles.title}>{t('user_actions')}</h2>
      </div>

      <div className={styles.filters}>
        <div className={styles.filterGroup}>
          <label htmlFor="dateRange" className={styles.label}>{t('date_range')}</label>
          <select 
            id="dateRange" 
            className={styles.select}
            value={dateRange}
            onChange={(e) => setDateRange(e.target.value)}
          >
            <option value="today">{t('today')}</option>
            <option value="yesterday">{t('yesterday')}</option>
            <option value="last_seven_days">{t('last_seven_days')}</option>
            <option value="last_thirty_days">{t('last_thirty_days')}</option>
            <option value="this_month">{t('this_month')}</option>
            <option value="last_month">{t('last_month')}</option>
            <option value="all_time">{t('all_time')}</option>
          </select>
        </div>
        
        <div className={styles.filterGroup}>
          <label htmlFor="actionType" className={styles.label}>{t('action_type')}</label>
          <select 
            id="actionType" 
            className={styles.select}
            value={actionType}
            onChange={(e) => setActionType(e.target.value)}
          >
            <option value="">{t('all_actions')}</option>
            <option value="authentication">{t('authentication')}</option>
            <option value="profile">{t('profile_updates')}</option>
            <option value="permissions">{t('permission_changes')}</option>
            <option value="address">{t('address_changes')}</option>
            <option value="connections">{t('connection_activities')}</option>
          </select>
        </div>
        
        <button 
          className={styles.searchButton}
          onClick={handleSearch}
          disabled={loading}
        >
          {loading ? t('searching') : t('search')}
        </button>
      </div>

      <div className={styles.actionsTable}>
        {loading ? (
          <div className={styles.loading}>{t('loading_actions')}</div>
        ) : actions.length > 0 ? (
          <table className={styles.table}>
            <thead>
              <tr>
                <th>{t('timestamp')}</th>
                <th>{t('action_type')}</th>
                <th>{t('feature')}</th>
                <th>{t('source')}</th>
                <th>{t('device')}</th>
                <th>{t('details')}</th>
              </tr>
            </thead>
            <tbody>
              {actions.map((action) => (
                <tr key={action.action_id}>
                  <td>{new Date(action.action_timestamp).toLocaleString()}</td>
                  <td>{t(action.action_type)}</td>
                  <td>{t(action.feature_id)}</td>
                  <td>{action.data_source}</td>
                  <td>
                    {action.device_type} ({action.system_name})
                  </td>
                  <td>
                    <button className={styles.detailsButton}>
                      {t('view_details')}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className={styles.noActions}>
            <p>{t('no_actions_found')}</p>
            <p>{t('try_different_filters')}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserActions;