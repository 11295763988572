// File: frontend/src/pages/AutoInsight/VehicleDetail/components/VehicleMaintenance/index.js

import React from 'react';
import { useLanguage } from '../../../../../../contexts/LanguageContext';
import styles from './VehicleMaintenance.module.css';

// Helper function to format date
const formatDate = (dateString) => {
  if (!dateString) return 'N/A';
  
  const date = new Date(dateString);
  
  // Check if date is valid
  if (isNaN(date.getTime())) return 'Invalid Date';
  
  return date.toLocaleDateString();
};

// Helper function to format currency
const formatCurrency = (amount, currency = 'USD') => {
  if (amount === null || amount === undefined) return 'N/A';
  
  return new Intl.NumberFormat('en-US', { 
    style: 'currency', 
    currency: currency 
  }).format(amount);
};

const VehicleMaintenance = ({ vehicle }) => {
  const { t } = useLanguage();

  // Sort maintenance records by date (newest first)
  const sortedMaintenanceRecords = vehicle.maintenance 
    ? [...vehicle.maintenance].sort((a, b) => {
        return new Date(b.maintenance_date) - new Date(a.maintenance_date);
      })
    : [];

  // Calculate total maintenance cost
  const totalCost = sortedMaintenanceRecords.reduce((total, record) => {
    return total + (record.cost || 0);
  }, 0);

  // Get upcoming maintenance
  const upcomingMaintenance = sortedMaintenanceRecords.filter(record => {
    if (!record.next_maintenance_date) return false;
    
    const nextDate = new Date(record.next_maintenance_date);
    const today = new Date();
    
    return nextDate > today;
  }).sort((a, b) => {
    return new Date(a.next_maintenance_date) - new Date(b.next_maintenance_date);
  });

  return (
    <div className={styles.container}>
      <div className={styles.grid}>
        <div className={styles.card}>
          <h3 className={styles.cardTitle}>{t('maintenance_summary')}</h3>
          <div className={styles.cardContent}>
            <div className={styles.summaryGrid}>
              <div className={styles.summaryItem}>
                <div className={styles.summaryLabel}>{t('total_records')}:</div>
                <div className={styles.summaryValue}>{sortedMaintenanceRecords.length}</div>
              </div>
              
              <div className={styles.summaryItem}>
                <div className={styles.summaryLabel}>{t('total_cost')}:</div>
                <div className={styles.summaryValue}>
                  {formatCurrency(totalCost, sortedMaintenanceRecords[0]?.cost_currency || 'USD')}
                </div>
              </div>
              
              <div className={styles.summaryItem}>
                <div className={styles.summaryLabel}>{t('last_maintenance')}:</div>
                <div className={styles.summaryValue}>
                  {sortedMaintenanceRecords.length > 0 
                    ? formatDate(sortedMaintenanceRecords[0].maintenance_date)
                    : 'N/A'}
                </div>
              </div>
              
              <div className={styles.summaryItem}>
                <div className={styles.summaryLabel}>{t('next_maintenance')}:</div>
                <div className={styles.summaryValue}>
                  {upcomingMaintenance.length > 0 
                    ? formatDate(upcomingMaintenance[0].next_maintenance_date)
                    : 'N/A'}
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className={styles.card}>
          <h3 className={styles.cardTitle}>{t('upcoming_maintenance')}</h3>
          <div className={styles.cardContent}>
            {upcomingMaintenance.length > 0 ? (
              <div className={styles.upcomingList}>
                {upcomingMaintenance.map((record) => (
                  <div key={record.maintenance_id} className={styles.upcomingItem}>
                    <div className={styles.upcomingDate}>
                      {formatDate(record.next_maintenance_date)}
                    </div>
                    <div className={styles.upcomingDetails}>
                      <div className={styles.upcomingType}>{record.maintenance_type}</div>
                      <div className={styles.upcomingDescription}>{record.description}</div>
                      <div className={styles.upcomingProvider}>{record.service_provider}</div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className={styles.noData}>{t('no_upcoming_maintenance')}</div>
            )}
          </div>
        </div>
      </div>
      
      <div className={styles.card}>
        <h3 className={styles.cardTitle}>{t('maintenance_history')}</h3>
        <div className={styles.cardContent}>
          {sortedMaintenanceRecords.length > 0 ? (
            <div className={styles.tableContainer}>
              <table className={styles.table}>
                <thead>
                  <tr>
                    <th>{t('date')}</th>
                    <th>{t('type')}</th>
                    <th>{t('description')}</th>
                    <th>{t('mileage')}</th>
                    <th>{t('cost')}</th>
                    <th>{t('service_provider')}</th>
                    <th>{t('next_maintenance')}</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedMaintenanceRecords.map((record) => (
                    <tr key={record.maintenance_id}>
                      <td>{formatDate(record.maintenance_date)}</td>
                      <td>{record.maintenance_type}</td>
                      <td>{record.description}</td>
                      <td>{record.mileage}</td>
                      <td>{formatCurrency(record.cost, record.cost_currency)}</td>
                      <td>{record.service_provider}</td>
                      <td>{formatDate(record.next_maintenance_date)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className={styles.noData}>{t('no_maintenance_records')}</div>
          )}
        </div>
      </div>
      
      <div className={styles.addMaintenanceContainer}>
        <button className={styles.addMaintenanceButton}>
          {t('add_maintenance_record')}
        </button>
      </div>
    </div>
  );
};

export default VehicleMaintenance;