// File: frontend/src/components/form/auth/OTPVerificationSignUp.js

import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLanguage } from '../../contexts/LanguageContext';
import { useAuth, AUTH_FLOW_STATES } from '../../contexts/AuthContextV2';
import { useSignUp } from '../../contexts/SignUpContext';
import { VALIDATION } from '../../config/constants';
import styles from './OTPVerificationSignUp.module.css';

const OTPVerificationSignUp = ({ onVerify }) => {
  const { t } = useLanguage();
  const { flowState, initiateSignUp, loading, error, updateFlowState } = useAuth();
  const { formData } = useSignUp();
  
  const [otp, setOtp] = useState(Array(VALIDATION.OTP.LENGTH).fill(''));
  const [resendAttempts, setResendAttempts] = useState(0);
  const [cooldownTime, setCooldownTime] = useState(0);
  const inputRefs = useRef([]);
  const containerRef = useRef(null);
  const hiddenInputRef = useRef(null);

  // Verify we're in the correct step
  useEffect(() => {
    if (flowState !== AUTH_FLOW_STATES.SIGNUP.MOBILE_VERIFICATION) {
      updateFlowState(AUTH_FLOW_STATES.SIGNUP.MOBILE_VERIFICATION);
    }
  }, [flowState, updateFlowState]);

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  useEffect(() => {
    let timer;
    if (cooldownTime > 0) {
      timer = setTimeout(() => setCooldownTime(time => time - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [cooldownTime]);

  const handleFullOTPInput = (value) => {
    if (value && value.length === VALIDATION.OTP.LENGTH) {
      const newOtp = value.split('');
      setOtp(newOtp);
      if (newOtp.every(digit => digit !== '')) {
        onVerify(newOtp.join(''));
      }
    }
  };

  // Handle clipboard paste
  useEffect(() => {
    const handlePaste = async (e) => {
      e.preventDefault();
      
      let pastedData;
      
      try {
        pastedData = await navigator.clipboard.readText();
      } catch {
        pastedData = e.clipboardData?.getData('text');
      }

      if (!pastedData) return;

      const numbers = pastedData.replace(/\D/g, '').split('').slice(0, VALIDATION.OTP.LENGTH);
      
      if (numbers.length > 0) {
        const newOtp = Array(VALIDATION.OTP.LENGTH).fill('');
        numbers.forEach((num, index) => {
          if (index < VALIDATION.OTP.LENGTH) {
            newOtp[index] = num;
          }
        });
        
        setOtp(newOtp);

        const nextEmptyIndex = newOtp.findIndex(digit => digit === '');
        const focusIndex = nextEmptyIndex === -1 ? VALIDATION.OTP.LENGTH - 1 : nextEmptyIndex;
        inputRefs.current[focusIndex]?.focus();

        if (newOtp.every(digit => digit !== '')) {
          onVerify(newOtp.join(''));
        }
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener('paste', handlePaste);
    }

    return () => {
      if (container) {
        container.removeEventListener('paste', handlePaste);
      }
    };
  }, [onVerify]);

  // Add this new effect to handle autofill across browsers
  useEffect(() => {
    const handleInput = (e) => {
      const input = e.target;
      const value = input.value;
      
      // Check if this is an autofill event
      if (value.length > 1) {
        const digits = value.replace(/\D/g, '').split('').slice(0, VALIDATION.OTP.LENGTH);
        const newOtp = Array(VALIDATION.OTP.LENGTH).fill('');
        
        digits.forEach((digit, i) => {
          if (i < VALIDATION.OTP.LENGTH) {
            newOtp[i] = digit;
          }
        });

        setOtp(newOtp);
        
        // Trigger verification if we have all digits
        if (newOtp.every(digit => digit !== '')) {
          onVerify(newOtp.join(''));
        }
      }
    };

    // Add input event listeners to all OTP inputs
    inputRefs.current.forEach(input => {
      if (input) {
        input.addEventListener('input', handleInput);
      }
    });

    return () => {
      inputRefs.current.forEach(input => {
        if (input) {
          input.removeEventListener('input', handleInput);
        }
      });
    };
  }, [onVerify]);

  const handleVerification = async (otpValue) => {
    try {
      await onVerify(otpValue);
      // After successful verification, move to complete signup
      updateFlowState(AUTH_FLOW_STATES.SIGNUP.COMPLETE_SIGNUP);
    } catch (err) {
      console.error('Verification error:', err);
    }
  };

  const handleChange = (e, index) => {
    const { value } = e.target;
    
    if (value.length > 1) {
      const digits = value.replace(/\D/g, '').split('').slice(0, VALIDATION.OTP.LENGTH);
      const newOtp = Array(VALIDATION.OTP.LENGTH).fill('');
      
      digits.forEach((digit, i) => {
        if (i < VALIDATION.OTP.LENGTH) {
          newOtp[i] = digit;
        }
      });
      
      setOtp(newOtp);
      
      const nextEmptyIndex = newOtp.findIndex(digit => digit === '');
      const focusIndex = nextEmptyIndex === -1 ? VALIDATION.OTP.LENGTH - 1 : nextEmptyIndex;
      inputRefs.current[focusIndex]?.focus();

      if (newOtp.every(digit => digit !== '')) {
        handleVerification(newOtp.join(''));
      }
      return;
    }

    if (!/^\d*$/.test(value)) return;

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value !== '' && index < VALIDATION.OTP.LENGTH - 1) {
      inputRefs.current[index + 1].focus();
    }

    if (newOtp.every(digit => digit !== '')) {
      handleVerification(newOtp.join(''));
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace') {
      if (index > 0 && otp[index] === '') {
        inputRefs.current[index - 1].focus();
      } else if (otp[index] !== '') {
        const newOtp = [...otp];
        newOtp[index] = '';
        setOtp(newOtp);
      }
    } else if (e.key === 'ArrowLeft' && index > 0) {
      inputRefs.current[index - 1].focus();
    } else if (e.key === 'ArrowRight' && index < VALIDATION.OTP.LENGTH - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleResendCode = async () => {
    if (cooldownTime > 0 || loading) return;

    try {
      const mobileNumber = flowState?.data?.mobile_phone_number;
      if (!mobileNumber) {
        throw new Error('Mobile number not found in session');
      }

      setResendAttempts(attempts => attempts + 1);
      
      if (resendAttempts === 0) {
        setCooldownTime(60);
      } else if (resendAttempts === 1) {
        setCooldownTime(600);
      } else {
        setCooldownTime(86400);
      }

      await initiateSignUp({
        mobile_phone_number: mobileNumber,
        email_address: formData.email_address,
        first_name: formData.first_name,
        last_name: formData.last_name
      });

      setOtp(Array(VALIDATION.OTP.LENGTH).fill(''));
      inputRefs.current[0]?.focus();
    } catch (error) {
      console.error('Error resending code:', error);
    }
  };

  const getResendButtonText = () => {
    if (cooldownTime > 0) {
      const minutes = Math.floor(cooldownTime / 60);
      const seconds = cooldownTime % 60;
      return t('auth.otpVerification.resendCodeIn', {
        time: `${minutes}:${seconds.toString().padStart(2, '0')}`
      });
    }
    return t('auth.otpVerification.resendCode');
  };

  const getMaskedContact = () => {
    const contact = flowState?.data?.mobile_phone_number;
    if (!contact) return '';
    
    const start = contact.slice(0, 4);
    const end = contact.slice(-4);
    return `${start}****${end}`;
  };

  return (
    <div className={styles.otpVerification}>
      <div className={styles.contentWrapper}>
        <img 
          className={styles.otpIcon} 
          src="/icons/misc/auth-otp-icon-group-sign-up.svg" 
          alt={t('auth.otpVerification.iconAlt')} 
        />
        
        <div className={styles.textContent}>
          <h2 className={styles.title}>
            {t('auth.otpVerification.title.phone')}
          </h2>
          <p className={styles.description}>
            {t('auth.otpVerification.description.phone', { 
              contact: getMaskedContact() 
            })}
          </p>
        </div>

        {/* Hidden input for full OTP autofill */}
        <input
          ref={hiddenInputRef}
          type="text"
          pattern="\d*"
          inputMode="numeric"
          maxLength={VALIDATION.OTP.LENGTH}
          autoComplete="one-time-code"
          style={{ 
            position: 'absolute', 
            opacity: 0, 
            pointerEvents: 'none',
            // Add these properties to help with Chrome autofill
            width: '1px',
            height: '1px',
            left: '-1px',
            top: '-1px'
          }}
          onChange={(e) => handleFullOTPInput(e.target.value)}
        />

        <div 
          ref={containerRef}
          className={`${styles.otpInputContainer} ${error ? styles.error : ''}`}
          tabIndex="-1"
        >
          {otp.map((digit, index) => (
            <input
              key={index}
              ref={el => inputRefs.current[index] = el}
              className={`${styles.otpInput} ${error ? styles.error : ''}`}
              type="text"
              inputMode="numeric"
              pattern="\d*"
              maxLength={VALIDATION.OTP.LENGTH} // Allow full length for Chrome autofill
              value={digit}
              onChange={(e) => handleChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              disabled={loading || resendAttempts >= 3}
              aria-label={`Digit ${index + 1}`}
              autoComplete={index === 0 ? "one-time-code" : "off"}
              data-index={index}
              // Add these attributes to help with Chrome autofill
              name={index === 0 ? "otp" : `otp-${index}`}
              id={`otp-input-${index}`}
            />
          ))}
        </div>

        {error && (
          <p className={styles.errorMessage}>
            {t(`auth.errors.${error.code}`, { 
              defaultValue: error.message 
            })}
          </p>
        )}

        <button 
          className={styles.resendButton} 
          onClick={handleResendCode}
          disabled={cooldownTime > 0 || resendAttempts >= 3 || loading}
        >
          {getResendButtonText()}
        </button>
      </div>
    </div>
  );
};

OTPVerificationSignUp.propTypes = {
  onVerify: PropTypes.func.isRequired
};

export default OTPVerificationSignUp;