// File: frontend/src/pages/Account/Account.js

import React from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import PageTitle from '../../components/layout/DashboardLayout/PageTitle';
import styles from './Account.module.css';

const Account = () => {
  const { t } = useLanguage();

  return (
    <div className={styles.AllegoryIntelligencePage}>
      <PageTitle 
        title={t('allegory_intelligence')}
      />
      <div className={styles.pageContent}>
      </div>
    </div>
  );
};

export default Account;