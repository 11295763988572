// File: frontend/src/components/MileMonitor/EventSelector.js

import React from 'react';

const EventSelector = ({ selectedEvent, onEventChange }) => {
  const events = ['acceleration', 'braking', 'cornering', 'distracted driving'];

  return (
    <div className="event-selector">
      {events.map(event => (
        <button
          key={event}
          className={selectedEvent === event ? 'active' : ''}
          onClick={() => onEventChange(event)}
        >
          {event.charAt(0).toUpperCase() + event.slice(1)}
        </button>
      ))}
    </div>
  );
};

export default EventSelector;