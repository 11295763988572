// File: frontend/src/components/DrivingScore/TopDrivers.js

import React from 'react';
import CustomTable from '../Tables/CustomTable';
import styles from './TopDrivers.module.css';

const TopDrivers = () => {
  const columns = [
    { title: 'First Name', dataIndex: 'firstName' },
    { title: 'Last Name', dataIndex: 'lastName' },
    {
      title: 'Risk Category',
      dataIndex: 'riskCategory',
      render: (value) => (
        <div className={styles.riskLevelGroup}>
          <img className={styles.riskLevelDotStatus} alt="" src={`/Risk Level / Dot Status ${value}.svg`} />
          <div className={styles.riskCategoryText}>{value}</div>
        </div>
      ),
    },
    { title: 'Task Completion', dataIndex: 'taskCompletion' },
    {
      title: 'Allegory Premium',
      dataIndex: 'allegoryPremium',
      render: (value) => (
        <div className={styles.badgeStatus}>
          <div className={styles.badgeText}>{value}</div>
        </div>
      ),
    },
    {
      title: 'Country',
      dataIndex: 'country',
      render: (value) => (
        <div className={styles.countryBadge}>
          <div className={styles.countryText}>{value}</div>
        </div>
      ),
    },
    { title: 'User Since', dataIndex: 'userSince' },
  ];

  const data = [
    {
      firstName: 'Alan',
      lastName: 'Bails',
      riskCategory: 'Very Low Risk',
      taskCompletion: '10/29',
      allegoryPremium: 'Active',
      country: 'EN 🇬🇧',
      userSince: '1 Year 3 Months',
    },
    {
      firstName: 'Alan',
      lastName: 'Bails',
      riskCategory: 'Very Low Risk',
      taskCompletion: '10/29',
      allegoryPremium: 'Active',
      country: 'EN 🇬🇧',
      userSince: '1 Year 3 Months',
    },
    {
      firstName: 'Alan',
      lastName: 'Bails',
      riskCategory: 'Very Low Risk',
      taskCompletion: '10/29',
      allegoryPremium: 'Active',
      country: 'EN 🇬🇧',
      userSince: '1 Year 3 Months',
    },
    {
      firstName: 'Alan',
      lastName: 'Bails',
      riskCategory: 'Very Low Risk',
      taskCompletion: '10/29',
      allegoryPremium: 'Active',
      country: 'EN 🇬🇧',
      userSince: '1 Year 3 Months',
    },
    {
      firstName: 'Alan',
      lastName: 'Bails',
      riskCategory: 'Very Low Risk',
      taskCompletion: '10/29',
      allegoryPremium: 'Active',
      country: 'EN 🇬🇧',
      userSince: '1 Year 3 Months',
    },
    {
      firstName: 'Alan',
      lastName: 'Bails',
      riskCategory: 'Very Low Risk',
      taskCompletion: '10/29',
      allegoryPremium: 'Active',
      country: 'EN 🇬🇧',
      userSince: '1 Year 3 Months',
    },
    {
      firstName: 'Alan',
      lastName: 'Bails',
      riskCategory: 'Very Low Risk',
      taskCompletion: '10/29',
      allegoryPremium: 'Active',
      country: 'EN 🇬🇧',
      userSince: '1 Year 3 Months',
    },
    {
      firstName: 'Alan',
      lastName: 'Bails',
      riskCategory: 'Very Low Risk',
      taskCompletion: '10/29',
      allegoryPremium: 'Active',
      country: 'EN 🇬🇧',
      userSince: '1 Year 3 Months',
    },
    {
      firstName: 'Alan',
      lastName: 'Bails',
      riskCategory: 'Very Low Risk',
      taskCompletion: '10/29',
      allegoryPremium: 'Active',
      country: 'EN 🇬🇧',
      userSince: '1 Year 3 Months',
    },
    {
      firstName: 'Alan',
      lastName: 'Bails',
      riskCategory: 'Very Low Risk',
      taskCompletion: '10/29',
      allegoryPremium: 'Active',
      country: 'EN 🇬🇧',
      userSince: '1 Year 3 Months',
    },
    {
      firstName: 'Alan',
      lastName: 'Bails',
      riskCategory: 'Very Low Risk',
      taskCompletion: '10/29',
      allegoryPremium: 'Active',
      country: 'EN 🇬🇧',
      userSince: '1 Year 3 Months',
    },
    {
      firstName: 'Alan',
      lastName: 'Bails',
      riskCategory: 'Very Low Risk',
      taskCompletion: '10/29',
      allegoryPremium: 'Active',
      country: 'EN 🇬🇧',
      userSince: '1 Year 3 Months',
    },
    {
      firstName: 'Alan',
      lastName: 'Bails',
      riskCategory: 'Very Low Risk',
      taskCompletion: '10/29',
      allegoryPremium: 'Active',
      country: 'EN 🇬🇧',
      userSince: '1 Year 3 Months',
    },
    {
      firstName: 'Alan',
      lastName: 'Bails',
      riskCategory: 'Very Low Risk',
      taskCompletion: '10/29',
      allegoryPremium: 'Active',
      country: 'EN 🇬🇧',
      userSince: '1 Year 3 Months',
    },
    {
      firstName: 'Alan',
      lastName: 'Bails',
      riskCategory: 'Very Low Risk',
      taskCompletion: '10/29',
      allegoryPremium: 'Active',
      country: 'EN 🇬🇧',
      userSince: '1 Year 3 Months',
    },
    {
      firstName: 'Alan',
      lastName: 'Bails',
      riskCategory: 'Very Low Risk',
      taskCompletion: '10/29',
      allegoryPremium: 'Active',
      country: 'EN 🇬🇧',
      userSince: '1 Year 3 Months',
    },
    // Add more data here...
  ];

  return (
    <CustomTable
      columns={columns}
      data={data}
      itemsPerPage={5}
      title="Top Drivers"
    />
  );
};

export default TopDrivers;