// File: frontend/src/pages/InvestorPitch/components/slides/LeadershipSlide/TrackRecordCard.js

import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import * as LucideIcons from 'lucide-react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from './TrackRecordCard.module.css';

const TrackRecordCard = ({ title, icon, details, color }) => {
  const { t } = useLanguage();
  const Icon = LucideIcons[icon];

  return (
    <motion.div 
      className={styles.container}
      whileHover={{ scale: 1.01 }}
      transition={{ type: 'spring', stiffness: 300, damping: 30 }}
    >
      <div 
        className={styles.iconWrapper}
        style={{ backgroundColor: `${color}15`, color }}
      >
        <Icon 
          className={styles.icon} 
          aria-label={t('investor_pitch.leadership.track_record.icon.aria_label', { title }, `${title} icon`)}
        />
      </div>
      <div className={styles.content}>
        <h4 className={styles.title}>{title}</h4>
        <ul className={styles.details}>
          {details.map((detail, index) => (
            <li key={index} className={styles.detailItem}>{detail}</li>
          ))}
        </ul>
      </div>
    </motion.div>
  );
};

TrackRecordCard.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  details: PropTypes.arrayOf(PropTypes.string).isRequired,
  color: PropTypes.string.isRequired,
};

export default TrackRecordCard;