import React from 'react';
import { motion } from 'framer-motion';
import { useLanguage } from '../../../../../../contexts/LanguageContext';
import StatCard from '../../../cards/StatCard';
import { ANIMATION_VARIANTS } from '../../../../constants/animations';
import styles from './InsuranceFutureSlide.module.css';

const InsuranceFutureSlide = () => {
  const { t } = useLanguage();

  const stats = [
    {
      value: "$862M",
      title: "Operational Expenses Eliminated",
      color: "var(--color-green-310)"
    },
    {
      value: "85%",
      title: "Process Automation Achieved",
      color: "var(--color-blue-310)"
    },
    {
      value: "24/7/365",
      title: "Autonomous Orchestration by AI1",
      color: "var(--color-orange-310)"
    }
  ];

  return (
    <motion.div
      className={styles.container}
      variants={ANIMATION_VARIANTS.stagger.container}
      initial="hidden"
      animate="visible"
    >
      <motion.div 
        className={styles.header}
        variants={ANIMATION_VARIANTS.fadeIn}
      >
        <h1 className={styles.title}>Reimagining Insurance</h1>
        <p className={styles.subtitle}>
          Building an Autonomous Future Through Advanced AI Integration
        </p>
      </motion.div>

      <motion.div 
        className={styles.statsGrid}
        variants={ANIMATION_VARIANTS.stagger.container}
      >
        {stats.map((stat, index) => (
          <motion.div
            key={stat.title}
            className={styles.statsCard}
            variants={ANIMATION_VARIANTS.stagger.item}
            custom={index}
          >
            <StatCard {...stat} />
          </motion.div>
        ))}
      </motion.div>

      <motion.div
        className={styles.description}
        variants={ANIMATION_VARIANTS.fadeIn}
      >
        Our AI-driven transformation is revolutionizing the insurance industry,
        delivering <span className={styles.highlight}>unprecedented operational efficiency</span> and 
        customer value through autonomous systems and advanced analytics.
      </motion.div>
    </motion.div>
  );
};

export default InsuranceFutureSlide;