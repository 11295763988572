// File: frontend/src/pages/Compliance/Compliance.js

import React from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import styles from './Compliance.module.css';

const Compliance = () => {
  const { t } = useLanguage();

  return (
    <div >
    </div>
  );
};

export default Compliance;