// File: frontend/src/pages/UMS/index.js

import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import PageTitle from '../../components/layout/DashboardLayout/PageTitle';
import PageTitleControls from '../../components/layout/DashboardLayout/PageTitleControls';
import UserSearchForm from './components/UserSearchForm';
import UserStatistics from './components/UserStatistics';
import UserSearchResults from './components/UserSearchResults';
import UserDetail from './UserDetail';
import UserPortfolio from './UserPortfolio';
import userService from '../../services/user';
import styles from './UMS.module.css';

const UMS = () => {
  const { t } = useLanguage();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const [lastSearchParams, setLastSearchParams] = useState(null);
  const [activeView, setActiveView] = useState('search');
  const [dateRange, setDateRange] = useState('all_time');

  // Check if we're on a detail page to hide the navigation
  const isDetailPage = location.pathname.split('/').length > 3;
  const isPortfolioPage = location.pathname.includes('/portfolio');

  useEffect(() => {
    // Set active view based on current path
    if (isPortfolioPage) {
      setActiveView('portfolio');
    } else if (!isDetailPage) {
      setActiveView('search');
    }
  }, [location, isDetailPage, isPortfolioPage]);

  const handleSearch = async (searchParams) => {
    setLoading(true);
    setError(null);
    
    try {
      // Add pagination parameters
      const params = {
        ...searchParams,
        page: 1,
        limit: 10
      };
      
      setLastSearchParams(params);
      setCurrentPage(1);
      
      // Convert the search parameters to the format expected by the API
      const queryParams = {};
      
      // Map the field to the appropriate query parameter
      if (searchParams.field && searchParams.query) {
        switch (searchParams.field) {
          case 'user_id':
            queryParams.user_id = searchParams.query;
            break;
          case 'first_name':
            queryParams.first_name = searchParams.query;
            break;
          case 'last_name':
            queryParams.last_name = searchParams.query;
            break;
          case 'email_address':
            queryParams.email_address = searchParams.query;
            break;
          case 'mobile_phone_number':
            queryParams.mobile_phone_number = searchParams.query;
            break;
          default:
            queryParams[searchParams.field] = searchParams.query;
        }
      }
      
      // Add date range if provided
      if (searchParams.date_range) {
        queryParams.date_range = searchParams.date_range;
      }
      
      // Add user type if provided
      if (searchParams.user_type) {
        queryParams.user_type = searchParams.user_type;
      }
      
      // Add account status if provided
      if (searchParams.account_status) {
        queryParams.account_status = searchParams.account_status;
      }
      
      // Add pagination
      queryParams.page = params.page;
      queryParams.limit = params.limit;
      
      // Call the API
      const response = await userService.searchUser(queryParams);
      
      if (response && response.users) {
        setSearchResults(response.users);
        setTotalResults(response.total || response.users.length);
        setTotalPages(Math.ceil((response.total || response.users.length) / 10));
      } else {
        setSearchResults([]);
        setTotalResults(0);
        setTotalPages(1);
        setError(t('no_users_found'));
      }
    } catch (err) {
      console.error('Error searching users:', err);
      setError(t('failed_to_search_users'));
      setSearchResults([]);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = async (page) => {
    if (page === currentPage || !lastSearchParams) return;
    
    setLoading(true);
    setError(null);
    
    try {
      const params = {
        ...lastSearchParams,
        page,
        limit: 10
      };
      
      // Convert the search parameters to the format expected by the API
      const queryParams = {};
      
      // Map the field to the appropriate query parameter
      if (lastSearchParams.field && lastSearchParams.query) {
        switch (lastSearchParams.field) {
          case 'user_id':
            queryParams.user_id = lastSearchParams.query;
            break;
          case 'first_name':
            queryParams.first_name = lastSearchParams.query;
            break;
          case 'last_name':
            queryParams.last_name = lastSearchParams.query;
            break;
          case 'email_address':
            queryParams.email_address = lastSearchParams.query;
            break;
          case 'mobile_phone_number':
            queryParams.mobile_phone_number = lastSearchParams.query;
            break;
          default:
            queryParams[lastSearchParams.field] = lastSearchParams.query;
        }
      }
      
      // Add date range if provided
      if (lastSearchParams.date_range) {
        queryParams.date_range = lastSearchParams.date_range;
      }
      
      // Add user type if provided
      if (lastSearchParams.user_type) {
        queryParams.user_type = lastSearchParams.user_type;
      }
      
      // Add account status if provided
      if (lastSearchParams.account_status) {
        queryParams.account_status = lastSearchParams.account_status;
      }
      
      // Add pagination
      queryParams.page = page;
      queryParams.limit = 10;
      
      // Call the API
      const response = await userService.searchUser(queryParams);
      
      if (response && response.users) {
        setSearchResults(response.users);
        setCurrentPage(page);
      } else {
        setError(t('error_loading_page'));
      }
    } catch (err) {
      console.error('Error changing page:', err);
      setError(t('error_loading_page'));
    } finally {
      setLoading(false);
    }
  };

  const handleViewChange = (e) => {
    const view = e.target.value;
    setActiveView(view);
    if (view === 'search') {
      navigate('/ums');
    } else if (view === 'portfolio') {
      navigate('/ums/portfolio');
    }
  };

  const handleDateRangeChange = (e) => {
    setDateRange(e.target.value);
    // Here you would typically fetch new data based on the date range
  };

  const handleRefresh = () => {
    setRefreshing(true);
    
    // Simulate refresh
    setTimeout(() => {
      if (activeView === 'search' && lastSearchParams) {
        handleSearch(lastSearchParams);
      }
      setRefreshing(false);
    }, 1000);
  };

  const handleExport = () => {
    // Handle export functionality
    console.log('Exporting data...');
  };

  const dateRangeOptions = [
    { value: 'all_time', label: t('all_time') },
    { value: 'today', label: t('today') },
    { value: 'yesterday', label: t('yesterday') },
    { value: 'this_week', label: t('this_week') },
    { value: 'last_week', label: t('last_week') },
    { value: 'last_seven_days', label: t('last_seven_days') },
    { value: 'last_thirty_days', label: t('last_thirty_days') },
    { value: 'this_month', label: t('this_month') },
    { value: 'last_month', label: t('last_month') },
    { value: 'this_year', label: t('this_year') },
    { value: 'last_year', label: t('last_year') }
  ];

  const viewOptions = [
    { value: 'search', label: t('user_search') },
    { value: 'portfolio', label: t('user_portfolio') }
  ];

  return (
    <div className={styles.container}>
      {!isDetailPage && (
        <PageTitle title={t('user_management')}>
          <PageTitleControls>
            <PageTitleControls.ControlGroup label={t('date_range')}>
              <PageTitleControls.Select
                options={dateRangeOptions}
                value={dateRange}
                onChange={handleDateRangeChange}
              />
            </PageTitleControls.ControlGroup>
            
            <PageTitleControls.ControlGroup label={t('export')}>
              <PageTitleControls.ExportButton onClick={handleExport} />
            </PageTitleControls.ControlGroup>
            
            <PageTitleControls.ControlGroup label={t('view')}>
              <PageTitleControls.Select
                options={viewOptions}
                value={activeView}
                onChange={handleViewChange}
              />
            </PageTitleControls.ControlGroup>
            
            <PageTitleControls.RefreshButton 
              onClick={handleRefresh}
              loading={refreshing}
            />
          </PageTitleControls>
        </PageTitle>
      )}
      
      <Routes>
        <Route path="/" element={
          <>
            <UserStatistics />
            <UserSearchForm onSearch={handleSearch} loading={loading} />
            {error && <div className={styles.error}>{error}</div>}
            <UserSearchResults 
              results={searchResults} 
              loading={loading}
              onPageChange={handlePageChange}
              currentPage={currentPage}
              totalPages={totalPages}
              totalResults={totalResults}
            />
          </>
        } />
        <Route path="/portfolio/*" element={<UserPortfolio dateRange={dateRange} />} />
        <Route path="/:userId" element={<UserDetail />} />
      </Routes>
    </div>
  );
};

export default UMS;