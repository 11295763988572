// File: frontend/src/components/Dashboard/AllegoryIntelligenceCard.js

import React, { Suspense, lazy } from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import { useNavigate } from 'react-router-dom';
import styles from './AllegoryIntelligenceCard.module.css';

const StaticParticleAnimation = lazy(() => import('./StaticParticleAnimation'));

const AllegoryIntelligenceCard = ({ isActive = false }) => {
  const { t } = useLanguage();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/ai1');
  };

  return (
    <div className={styles.card}>
      <div className={styles.contentWrapper}>
        <div className={styles.textContent}>
          <h2 className={styles.title}>
            <span className={styles.highlight}>
              {t('homepage_allegory_intelligence_promo_title')}
            </span>
          </h2>
          <p className={styles.description}>
            {t('homepage_allegory_intelligence_promo_description')}
          </p>
          {isActive ? (
            <button className={styles.button} onClick={handleClick}>
              {t('try_it_now')}
            </button>
          ) : (
            <>
              <button className={`${styles.button} ${styles.inactive}`} disabled>
                {t('try_it_now')}
              </button>
              <p className={styles.comingSoon}>{t('coming_soon')}</p>
              <a href="/news" className={styles.learnMore}>
                {t('learn_more')}
              </a>
            </>
          )}
        </div>
        <div className={styles.mediaContent}>
          <div className={styles.animationContainer}>
            <Suspense
              fallback={
                <div className={styles.animationPlaceholder}>
                  <div className={styles.loadingIndicator}>
                    {t('loading_animation')}
                  </div>
                </div>
              }
            >
              <StaticParticleAnimation />
            </Suspense>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllegoryIntelligenceCard;