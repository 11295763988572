// File: frontend/src/pages/SuperAdmin/components/EnterpriseManagement/EnterpriseEdit/index.js

import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from './EnterpriseEdit.module.css';

const EnterpriseEdit = ({ enterpriseId, onBack, onSuccess }) => {
  const { t } = useLanguage();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    enterprise_name: '',
    enterprise_name_fr: '',
    enterprise_parent_name: '',
    enterprise_parent_name_fr: '',
    country_code: '',
    province_state_code: '',
    dataset_location: '',
    dataset_region: '',
    enterprise_status: '',
    jurisdiction_of_existence: '',
    timezone_offset: 0,
    contact_name: '',
    contact_email: '',
    contact_phone: '',
    address_street: '',
    address_city: '',
    address_state: '',
    address_zip: '',
    address_country: ''
  });
  
  useEffect(() => {
    const fetchEnterpriseDetails = async () => {
      setLoading(true);
      setError(null);
      
      try {
        // This would be replaced with an actual API call
        // const response = await enterpriseService.getEnterpriseDetails(enterpriseId);
        
        // Mock data for now
        await new Promise(resolve => setTimeout(resolve, 800));
        
        const mockEnterprise = {
          enterprise_id: enterpriseId,
          enterprise_numerical_id: 1001,
          enterprise_parent_name: "Global Holdings Inc",
          enterprise_parent_name_fr: "Global Holdings Inc",
          enterprise_name: "Acme Corporation",
          enterprise_name_fr: "Corporation Acme",
          country_code: "US",
          province_state_code: "CA",
          dataset_location: "US-WEST",
          dataset_region: "us-west-2",
          aws_public_bucket_url: "https://s3.amazonaws.com/acme-public/",
          aws_private_bucket_url: "https://s3.amazonaws.com/acme-private/",
          enterprise_hash: "abc123",
          enterprise_status: "active",
          jurisdiction_of_existence: "Delaware, USA",
          contact_details: {
            primary_contact: {
              name: "John Smith",
              email: "john.smith@acme.com",
              phone: "+14155552671"
            },
            address: {
              street: "123 Tech Blvd",
              city: "San Francisco",
              state: "California",
              zip: "94105",
              country: "USA"
            }
          },
          timezone_offset: -420, // Pacific Time
          created_at: "2023-01-15T10:30:00Z",
          updated_at: "2023-06-20T14:45:00Z",
          created_by: "admin-user-1",
          updated_by: "admin-user-2"
        };
        
        // Set form data from the fetched enterprise
        setFormData({
          enterprise_name: mockEnterprise.enterprise_name,
          enterprise_name_fr: mockEnterprise.enterprise_name_fr,
          enterprise_parent_name: mockEnterprise.enterprise_parent_name,
          enterprise_parent_name_fr: mockEnterprise.enterprise_parent_name_fr,
          country_code: mockEnterprise.country_code,
          province_state_code: mockEnterprise.province_state_code,
          dataset_location: mockEnterprise.dataset_location,
          dataset_region: mockEnterprise.dataset_region,
          enterprise_status: mockEnterprise.enterprise_status,
          jurisdiction_of_existence: mockEnterprise.jurisdiction_of_existence,
          timezone_offset: mockEnterprise.timezone_offset,
          contact_name: mockEnterprise.contact_details?.primary_contact?.name || '',
          contact_email: mockEnterprise.contact_details?.primary_contact?.email || '',
          contact_phone: mockEnterprise.contact_details?.primary_contact?.phone || '',
          address_street: mockEnterprise.contact_details?.address?.street || '',
          address_city: mockEnterprise.contact_details?.address?.city || '',
          address_state: mockEnterprise.contact_details?.address?.state || '',
          address_zip: mockEnterprise.contact_details?.address?.zip || '',
          address_country: mockEnterprise.contact_details?.address?.country || ''
        });
      } catch (err) {
        console.error('Error fetching enterprise details:', err);
        setError(t('failed_to_load_enterprise_details'));
      } finally {
        setLoading(false);
      }
    };
    
    if (enterpriseId) {
      fetchEnterpriseDetails();
    }
  }, [enterpriseId, t]);
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSaving(true);
    setError(null);
    
    try {
      // This would be replaced with an actual API call
      // const response = await enterpriseService.updateEnterprise(enterpriseId, {
      //   enterprise_name: formData.enterprise_name,
      //   enterprise_name_fr: formData.enterprise_name_fr,
      //   enterprise_parent_name: formData.enterprise_parent_name,
      //   enterprise_parent_name_fr: formData.enterprise_parent_name_fr,
      //   country_code: formData.country_code,
      //   province_state_code: formData.province_state_code,
      //   dataset_location: formData.dataset_location,
      //   dataset_region: formData.dataset_region,
      //   enterprise_status: formData.enterprise_status,
      //   jurisdiction_of_existence: formData.jurisdiction_of_existence,
      //   timezone_offset: formData.timezone_offset,
      //   contact_details: {
      //     primary_contact: {
      //       name: formData.contact_name,
      //       email: formData.contact_email,
      //       phone: formData.contact_phone
      //     },
      //     address: {
      //       street: formData.address_street,
      //       city: formData.address_city,
      //       state: formData.address_state,
      //       zip: formData.address_zip,
      //       country: formData.address_country
      //     }
      //   }
      // });
      
      // Mock response for now
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      const mockResponse = {
        success: true
      };
      
      // Call the success callback
      onSuccess();
    } catch (err) {
      console.error('Error updating enterprise:', err);
      setError(t('failed_to_update_enterprise'));
    } finally {
      setSaving(false);
    }
  };
  
  if (loading) {
    return (
      <div className={styles.loading}>
        <div className={styles.spinner}></div>
        <p>{t('loading_enterprise_details')}</p>
      </div>
    );
  }
  
  if (error && !formData.enterprise_name) {
    return (
      <div className={styles.error}>
        <p>{error}</p>
        <button className={styles.backButton} onClick={onBack}>
          {t('back')}
        </button>
      </div>
    );
  }
  
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <button className={styles.backButton} onClick={onBack} disabled={saving}>
          <i className="fas fa-arrow-left"></i>
          <span>{t('back')}</span>
        </button>
      </div>
      
      {error && (
        <div className={styles.errorMessage}>
          <i className="fas fa-exclamation-circle"></i>
          <span>{error}</span>
        </div>
      )}
      
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.formSection}>
          <h3 className={styles.sectionTitle}>{t('basic_information')}</h3>
          <div className={styles.formGrid}>
            <div className={styles.formGroup}>
              <label htmlFor="enterprise_name" className={styles.label}>
                {t('enterprise_name')} <span className={styles.required}>*</span>
              </label>
              <input
                type="text"
                id="enterprise_name"
                name="enterprise_name"
                className={styles.input}
                value={formData.enterprise_name}
                onChange={handleChange}
                required
                disabled={saving}
              />
            </div>
            
            <div className={styles.formGroup}>
              <label htmlFor="enterprise_name_fr" className={styles.label}>
                {t('enterprise_name_fr')}
              </label>
              <input
                type="text"
                id="enterprise_name_fr"
                name="enterprise_name_fr"
                className={styles.input}
                value={formData.enterprise_name_fr}
                onChange={handleChange}
                disabled={saving}
              />
            </div>
            
            <div className={styles.formGroup}>
              <label htmlFor="enterprise_parent_name" className={styles.label}>
                {t('parent_company')}
              </label>
              <input
                type="text"
                id="enterprise_parent_name"
                name="enterprise_parent_name"
                className={styles.input}
                value={formData.enterprise_parent_name}
                onChange={handleChange}
                disabled={saving}
              />
            </div>
            
            <div className={styles.formGroup}>
              <label htmlFor="enterprise_parent_name_fr" className={styles.label}>
                {t('parent_company_fr')}
              </label>
              <input
                type="text"
                id="enterprise_parent_name_fr"
                name="enterprise_parent_name_fr"
                className={styles.input}
                value={formData.enterprise_parent_name_fr}
                onChange={handleChange}
                disabled={saving}
              />
            </div>
            
            <div className={styles.formGroup}>
              <label htmlFor="enterprise_status" className={styles.label}>
                {t('status')} <span className={styles.required}>*</span>
              </label>
              <select
                id="enterprise_status"
                name="enterprise_status"
                className={styles.select}
                value={formData.enterprise_status}
                onChange={handleChange}
                required
                disabled={saving}
              >
                <option value="active">{t('active')}</option>
                <option value="inactive">{t('inactive')}</option>
                <option value="pending">{t('pending')}</option>
              </select>
            </div>
            
            <div className={styles.formGroup}>
              <label htmlFor="jurisdiction_of_existence" className={styles.label}>
                {t('jurisdiction')}
              </label>
              <input
                type="text"
                id="jurisdiction_of_existence"
                name="jurisdiction_of_existence"
                className={styles.input}
                value={formData.jurisdiction_of_existence}
                onChange={handleChange}
                disabled={saving}
              />
            </div>
          </div>
        </div>
        
        <div className={styles.formSection}>
          <h3 className={styles.sectionTitle}>{t('location_information')}</h3>
          <div className={styles.formGrid}>
            <div className={styles.formGroup}>
              <label htmlFor="country_code" className={styles.label}>
                {t('country')} <span className={styles.required}>*</span>
              </label>
              <select
                id="country_code"
                name="country_code"
                className={styles.select}
                value={formData.country_code}
                onChange={handleChange}
                required
                disabled={saving}
              >
                <option value="US">{t('united_states')}</option>
                <option value="CA">{t('canada')}</option>
                <option value="UK">{t('united_kingdom')}</option>
                <option value="AU">{t('australia')}</option>
                <option value="DE">{t('germany')}</option>
                <option value="FR">{t('france')}</option>
              </select>
            </div>
            
            <div className={styles.formGroup}>
              <label htmlFor="province_state_code" className={styles.label}>
                {t('province_state')}
              </label>
              <input
                type="text"
                id="province_state_code"
                name="province_state_code"
                className={styles.input}
                value={formData.province_state_code}
                onChange={handleChange}
                disabled={saving}
              />
            </div>
            
            <div className={styles.formGroup}>
              <label htmlFor="dataset_location" className={styles.label}>
                {t('dataset_location')} <span className={styles.required}>*</span>
              </label>
              <select
                id="dataset_location"
                name="dataset_location"
                className={styles.select}
                value={formData.dataset_location}
                onChange={handleChange}
                required
                disabled={saving}
              >
                <option value="US-WEST">{t('us_west')}</option>
                <option value="US-EAST">{t('us_east')}</option>
                <option value="EU-CENTRAL">{t('eu_central')}</option>
                <option value="AP-SOUTHEAST">{t('ap_southeast')}</option>
              </select>
            </div>
            
            <div className={styles.formGroup}>
              <label htmlFor="dataset_region" className={styles.label}>
                {t('dataset_region')} <span className={styles.required}>*</span>
              </label>
              <select
                id="dataset_region"
                name="dataset_region"
                className={styles.select}
                value={formData.dataset_region}
                onChange={handleChange}
                required
                disabled={saving}
              >
                <option value="us-west-2">{t('us_west_2')}</option>
                <option value="us-east-1">{t('us_east_1')}</option>
                <option value="eu-central-1">{t('eu_central_1')}</option>
                <option value="ap-southeast-1">{t('ap_southeast_1')}</option>
              </select>
            </div>
            
            <div className={styles.formGroup}>
              <label htmlFor="timezone_offset" className={styles.label}>
                {t('timezone')} <span className={styles.required}>*</span>
              </label>
              <select
                id="timezone_offset"
                name="timezone_offset"
                className={styles.select}
                value={formData.timezone_offset}
                onChange={handleChange}
                required
                disabled={saving}
              >
                <option value="-480">{t('pacific_time')} (UTC-8)</option>
                <option value="-420">{t('mountain_time')} (UTC-7)</option>
                <option value="-360">{t('central_time')} (UTC-6)</option>
                <option value="-300">{t('eastern_time')} (UTC-5)</option>
                <option value="0">{t('utc')} (UTC+0)</option>
                <option value="60">{t('central_european_time')} (UTC+1)</option>
                <option value="120">{t('eastern_european_time')} (UTC+2)</option>
                <option value="330">{t('india_standard_time')} (UTC+5:30)</option>
                <option value="480">{t('china_standard_time')} (UTC+8)</option>
                <option value="540">{t('japan_standard_time')} (UTC+9)</option>
                <option value="600">{t('australian_eastern_standard_time')} (UTC+10)</option>
              </select>
            </div>
          </div>
        </div>
        
        <div className={styles.formSection}>
          <h3 className={styles.sectionTitle}>{t('contact_information')}</h3>
          <div className={styles.formGrid}>
            <div className={styles.formGroup}>
              <label htmlFor="contact_name" className={styles.label}>
                {t('contact_name')} <span className={styles.required}>*</span>
              </label>
              <input
                type="text"
                id="contact_name"
                name="contact_name"
                className={styles.input}
                value={formData.contact_name}
                onChange={handleChange}
                required
                disabled={saving}
              />
            </div>
            
            <div className={styles.formGroup}>
              <label htmlFor="contact_email" className={styles.label}>
                {t('contact_email')} <span className={styles.required}>*</span>
              </label>
              <input
                type="email"
                id="contact_email"
                name="contact_email"
                className={styles.input}
                value={formData.contact_email}
                onChange={handleChange}
                required
                disabled={saving}
              />
            </div>
            
            <div className={styles.formGroup}>
              <label htmlFor="contact_phone" className={styles.label}>
                {t('contact_phone')}
              </label>
              <input
                type="tel"
                id="contact_phone"
                name="contact_phone"
                className={styles.input}
                value={formData.contact_phone}
                onChange={handleChange}
                disabled={saving}
              />
            </div>
            
            <div className={styles.formGroup}>
              <label htmlFor="address_street" className={styles.label}>
                {t('street_address')}
              </label>
              <input
                type="text"
                id="address_street"
                name="address_street"
                className={styles.input}
                value={formData.address_street}
                onChange={handleChange}
                disabled={saving}
              />
            </div>
            
            <div className={styles.formGroup}>
              <label htmlFor="address_city" className={styles.label}>
                {t('city')}
              </label>
              <input
                type="text"
                id="address_city"
                name="address_city"
                className={styles.input}
                value={formData.address_city}
                onChange={handleChange}
                disabled={saving}
              />
            </div>
            
            <div className={styles.formGroup}>
              <label htmlFor="address_state" className={styles.label}>
                {t('state_province')}
              </label>
              <input
                type="text"
                id="address_state"
                name="address_state"
                className={styles.input}
                value={formData.address_state}
                onChange={handleChange}
                disabled={saving}
              />
            </div>
            
            <div className={styles.formGroup}>
              <label htmlFor="address_zip" className={styles.label}>
                {t('zip_postal_code')}
              </label>
              <input
                type="text"
                id="address_zip"
                name="address_zip"
                className={styles.input}
                value={formData.address_zip}
                onChange={handleChange}
                disabled={saving}
              />
            </div>
            
            <div className={styles.formGroup}>
              <label htmlFor="address_country" className={styles.label}>
                {t('country')}
              </label>
              <input
                type="text"
                id="address_country"
                name="address_country"
                className={styles.input}
                value={formData.address_country}
                onChange={handleChange}
                disabled={saving}
              />
            </div>
          </div>
        </div>
        
        <div className={styles.formActions}>
          <button 
            type="button" 
            className={styles.cancelButton} 
            onClick={onBack}
            disabled={saving}
          >
            {t('cancel')}
          </button>
          <button 
            type="submit" 
            className={styles.submitButton}
            disabled={saving}
          >
            {saving ? (
              <>
                <span className={styles.spinner}></span>
                <span>{t('saving')}</span>
              </>
            ) : (
              t('save_changes')
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EnterpriseEdit;