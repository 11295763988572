// File: frontend/src/pages/Status/utils/metrics.js

export const calculateUptimePercentage = (history) => {
    if (!history?.length) return 0;
  
    const totalChecks = history.length;
    const successfulChecks = history.filter(check => check.status === 'OK').length;
    const degradedChecks = history.filter(check => check.status === 'DEGRADED').length;

    // Count degraded checks as 50% uptime
    return ((successfulChecks + (degradedChecks * 0.5)) / totalChecks) * 100;
};
  
export const calculateAverageResponseTime = (history) => {
  if (!history?.length) return 0;

  const totalTime = history.reduce((sum, check) => {
    return sum + (check.executionTime || 0);
  }, 0);

  return Math.round(totalTime / history.length);
};

export const countIncidents = (history) => {
  if (!history?.length) return 0;

  let incidents = 0;
  let wasDown = false;

  history.forEach(check => {
    if (check.status === 'ERROR' && !wasDown) {
      incidents++;
      wasDown = true;
    } else if (check.status === 'OK') {
      wasDown = false;
    }
  });

  return incidents;
};

export const calculateSLAMetrics = (history) => {
  const TARGET_SLA = 99.9; // 99.9% uptime target
  const uptime = calculateUptimePercentage(history);

  return {
    target: TARGET_SLA,
    current: Number(uptime.toFixed(2)),
    isMeeting: uptime >= TARGET_SLA
  };
};

export const calculateServiceMetrics = (services) => {
  if (!services) return {};

  return Object.entries(services).reduce((metrics, [serviceName, data]) => {
    metrics[serviceName] = {
      status: data.status,
      latency: data.latency || 0,
      availability: data.status === 'OK' ? 100 : 
                   data.status === 'DEGRADED' ? 50 : 0,
      details: data.details || {}
    };
    return metrics;
  }, {});
};

export const getStatusSeverity = (status) => {
  const severityMap = {
    'OK': 0,
    'DEGRADED': 1,
    'ERROR': 2
  };
  return severityMap[status] || 3;
};

export const aggregateServiceStatus = (services) => {
  if (!services) return 'UNKNOWN';

  const statuses = Object.values(services).map(service => service.status);
  
  if (statuses.some(status => status === 'ERROR')) {
    return 'ERROR';
  }
  if (statuses.some(status => status === 'DEGRADED')) {
    return 'DEGRADED';
  }
  if (statuses.every(status => status === 'OK')) {
    return 'OK';
  }
  
  return 'UNKNOWN';
};

export const formatDateTime = (date) => {
    if (!date) return 'N/A';
    
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short'
    };
  
    return new Date(date).toLocaleString(undefined, options);
};