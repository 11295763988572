// File: frontend/src/pages/ArticleDetail/ArticleDetail.js

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Cell, BarChart, Bar, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ComposedChart, ReferenceLine, Area } from 'recharts';
import { useLanguage } from '../../contexts/LanguageContext';
import ColumnChart from '../../components/charts/v2/types/ColumnChart';
import ComparisonColumnChart from '../../components/charts/v2/types/ComparisonColumnChart';
import MetricsChart from '../../components/charts/v2/types/MetricsChart';
import PieChart from '../../components/charts/v2/types/PieChart';
import StackedBarChart from '../../components/charts/v2/types/StackedBarChart';
import LineChart from '../../components/charts/v2/types/LineChart';
import ReactMarkdown from 'react-markdown';
import defaultNewsArticles from '../../data/newsArticles';
import styles from './ArticleDetail.module.css';

// Helper functions
const getBarHeight = (value, metric) => {
  // Scale the bars appropriately based on metric type
  switch (metric.format) {
      case 'currency':
          return (value / 1e9) * 200; // Scale for billions
      case 'percentage':
          return value * 3; // Scale for percentages
      default:
          return value;
  }
};

const getBarWidth = (value, metric) => {
  switch (metric.format) {
      case 'currency':
          return (value / 3.2e9) * 100; // Scale based on max GWP
      case 'percentage':
          return (value / 40) * 100; // Scale based on max percentage
      default:
          return value;
  }
};

const formatValue = (value, format) => {
  switch (format) {
      case 'currency':
          return formatCurrency(value);
      case 'percentage':
          return `${value}%`;
      case 'number':
          return value.toLocaleString(); // Adds thousand separators
      default:
          return value.toLocaleString();
  }
};

const formatCurrency = (value) => {
  if (value >= 1000000) {
      const millions = value / 1000000;
      return `$${millions < 10 ? millions.toFixed(1) : Math.round(millions)}M`;
  } else if (value >= 1000) {
      const thousands = value / 1000;
      return `$${thousands < 10 ? thousands.toFixed(1) : Math.round(thousands)}k`;
  }
  return `$${Math.round(value)}`;
};

// Distribution calculation function
const mixtureModel = (x, params, aiReduction = 0) => {
  if (x <= 0) return 0;
  const scale = 1 - aiReduction;

  try {
      const peaks = params.peaks.map(peak => (
          peak.proportion * 
          (1/(x * peak.spread * Math.sqrt(2*Math.PI))) * 
          Math.exp(-Math.pow(Math.log(x) - Math.log(peak.amount), 2)/
          (2 * Math.pow(peak.spread, 2)))
      ));

      const tailComponent = x > params.tail.threshold ? 
          params.tail.proportion * (1/params.tail.spread) * 
          Math.pow(1 + (params.tail.shape*(x-params.tail.threshold))/
          params.tail.spread, -(1/params.tail.shape + 1)) : 0;

      const result = (peaks.reduce((a, b) => a + b, 0) + tailComponent) * scale;
      return isNaN(result) ? 0 : result;
  } catch (error) {
      console.error('Error in mixtureModel:', error);
      return 0;
  }
};

// Scaling factor calculation
const calculateScalingFactor = (params) => {
  let totalArea = 0;
  let x = 100;

  while (x <= params.maxValue) {
      let dx;
      if (x < params.granularity.veryFine.threshold) {
          dx = params.granularity.veryFine.step;
      } else if (x < params.granularity.fine.threshold) {
          dx = params.granularity.fine.step;
      } else if (x < params.granularity.medium.threshold) {
          dx = params.granularity.medium.step;
      } else {
          dx = params.granularity.coarse.step;
      }

      const y = mixtureModel(x, params, 0);
      if (!isNaN(y)) {
          totalArea += y * x * dx;
      }
      x += dx;
  }

  return totalArea > 0 ? params.totalExpenseAmount / totalArea : 1;
};

// Data generation function
const generateDistributionData = (params) => {
  const scalingFactor = calculateScalingFactor(params);
  const data = [];
  let x = params.granularity.veryFine.step;
  
  while (x <= params.maxValue) {
      const baselineFreq = mixtureModel(x, params, 0) * scalingFactor;
      
      const aiImpacts = params.peaks.map((peak, index) => ({
          [`aiImpact${index + 1}`]: 
              x <= peak.amount ? 
              baselineFreq * peak.impact.reduction : 0
      }));

      const tailImpact = {
          aiImpact4: x > params.peaks[2].amount ? 
              baselineFreq * params.tail.impact.reduction : 0
      };

      if (!isNaN(baselineFreq)) {
          data.push({
              x,
              baseline: baselineFreq,
              ...Object.assign({}, ...aiImpacts),
              ...tailImpact
          });
      }
      
      let step;
      if (x < params.granularity.veryFine.threshold) {
          step = params.granularity.veryFine.step;
      } else if (x < params.granularity.fine.threshold) {
          step = params.granularity.fine.step;
      } else if (x < params.granularity.medium.threshold) {
          step = params.granularity.medium.step;
      } else {
          step = params.granularity.coarse.step;
      }
      x += step;
  }
  
  return data;
};

// Custom tooltip component
const CustomDistributionTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
      return (
          <div className={styles.tooltip}>
              <p className={styles.tooltipLabel}>Cost: {formatCurrency(label)}</p>
              {payload.map((entry) => (
                  <p 
                      key={entry.name} 
                      className={styles.tooltipRow}
                      style={{ color: entry.color }}
                  >
                      {entry.name}: {Number(entry.value).toLocaleString(undefined, {
                          maximumFractionDigits: 2
                      })}
                  </p>
              ))}
          </div>
      );
  }
  return null;
};

const calculateRunningTotal = (data) => {
  let total = 0;
  return data.map(item => {
      if (item.type === 'total') {
          total = item.value;
          return { ...item, running: total };
      } else if (item.type === 'reduction') {
          total += item.value;
          return { ...item, running: total };
      } else {
          return { ...item, value: total, running: total };
      }
  });
};

const CustomWaterfallTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
          <div className={styles.tooltip}>
              <p className={styles.tooltipLabel}>{label.replace('\n', ' ')}</p>
              <p className={styles.tooltipValue}>
                  {data.type === 'reduction' 
                      ? `Reduction: ${formatCurrency(Math.abs(data.value))}`
                      : `Total: ${formatCurrency(Math.abs(data.running))}`
                  }
              </p>
              {data.type === 'reduction' && (
                  <p className={styles.tooltipPercentage}>
                      {`(${(Math.abs(data.value) / data.originalTotal * 100).toFixed(1)}% of original)`}
                  </p>
              )}
          </div>
      );
  }
  return null;
};

const ChartComponent = ({ chart }) => {
  const TableView = ({ data }) => {
    // Calculate column totals
    const columnTotals = data.datasets[0].data.map((_, colIndex) => 
        data.datasets.reduce((sum, dataset) => sum + dataset.data[colIndex], 0)
    );

    return (
        <div className={styles.tableContainer}>
            <table className={styles.dataTable}>
                <thead>
                    <tr>
                        <th>Category</th>
                        {data.labels.map(label => (
                            <th key={label}>{label}</th>
                        ))}
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {data.datasets.map((dataset, i) => (
                        <tr key={dataset.label}>
                            <td>{dataset.label}</td>
                            {dataset.data.map((value, j) => (
                                <td key={j}>${value}M</td>
                            ))}
                            <td className={styles.rowTotal}>
                                ${dataset.data.reduce((a, b) => a + b, 0)}M
                            </td>
                        </tr>
                    ))}
                    <tr className={styles.totalRow}>
                        <td>Total</td>
                        {columnTotals.map((total, i) => (
                            <td key={i}>${total}M</td>
                        ))}
                        <td className={styles.grandTotal}>
                            ${columnTotals.reduce((a, b) => a + b, 0)}M
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
      );
    };
    switch (chart.type) {
      case 'bar-table':
        return (
          <ColumnChart
            title={chart.title}
            subtitle={chart.subtitle}
            data={chart.data}
            orientation={chart.orientation || 'vertical'}
            stacked={chart.stacked}
            format={chart.data.format || 'currency'}
            height={chart.data.height || 400}
            showTable={chart.data.showTable !== false}
          />
        );
      case 'bar-comparison':
        return (
          <ComparisonColumnChart
            title={chart.title}
            subtitle={chart.subtitle}
            data={chart.data}
          />
        );
      case 'pie':
        return (
          <PieChart
            title={chart.title}
            subtitle={chart.subtitle}
            data={chart.data}
            options={chart.options}
          />
        );
      case 'stacked-bar':
        return (
            <StackedBarChart
                title={chart.title}
                subtitle={chart.subtitle}
                data={chart.data}
                orientation={chart.orientation || 'horizontal'}
            />
        );
      case 'line':
        return (
            <LineChart
                title={chart.title}
                subtitle={chart.subtitle}
                data={chart.data}
                format={chart.format}
                height={chart.height || 400}
                referenceLines={chart.referenceLines}
                yAxis={chart.yAxis}
                insights={chart.insights}
            />
        );
          case 'bar': {
            if (chart.data.insights) {
              return (
                  <MetricsChart
                      title={chart.title}
                      subtitle={chart.subtitle}
                      data={chart.data}
                  />
              );
            };

            const formatCurrency = (value) => {
                if (value >= 1000000) {
                    return `$${(value / 1000000).toFixed(1)}M`;
                } else if (value >= 1000) {
                    return `$${(value / 1000).toFixed(1)}K`;
                }
                return `$${value}`;
            };
        
            const MetricBar = ({ label, value, maxValue, prefix = "" }) => (
                <div className={styles.metricContainer}>
                    <div className={styles.metricHeader}>
                        <div className={styles.metricLabel}>{label}</div>
                        <div className={styles.metricValue}>
                            {prefix ? formatCurrency(value) : value.toLocaleString()}
                        </div>
                    </div>
                    <div className={styles.barContainer}>
                        <div 
                            className={styles.bar}
                            style={{
                                width: `${(value / maxValue) * 100}%`,
                                backgroundColor: 'var(--color-blue-310)'
                            }}
                        />
                    </div>
                </div>
            );
        
            const calculateSavings = (categories) => {
                if (categories.length !== 2) return null;
                const traditional = categories[0].value;
                const enhanced = categories[1].value;
                const savings = traditional - enhanced;
                const percentage = ((savings / traditional) * 100).toFixed(1);
                return { amount: savings, percentage };
            };
        
            const savings = chart.data.metrics[0].categories.length === 2 
                ? calculateSavings(chart.data.metrics[0].categories) 
                : null;
        
            return (
                <div className={styles.barChartContainer}>
                    {chart.data.metrics.map((metric, index) => (
                        <div key={index} className={styles.metricGroup}>
                            {metric.categories.map((category, catIndex) => (
                                <MetricBar
                                    key={catIndex}
                                    label={category.name}
                                    value={category.value}
                                    maxValue={2075000} // Maximum value from traditional development
                                    prefix={category.prefix}
                                />
                            ))}
                        </div>
                    ))}
                    {savings && (
                        <div className={styles.savingsContainer}>
                            <div className={styles.savingsAmount}>
                                Total Savings: {formatCurrency(savings.amount)}
                            </div>
                            <div className={styles.savingsPercentage}>
                                {savings.percentage}% Cost Reduction
                            </div>
                        </div>
                    )}
                </div>
            );
        };
      case 'bar': {
        const GroupedBarChart = () => (
            <ResponsiveContainer width="100%" height={400}>
                <BarChart
                    data={chart.data.metrics}
                    layout="vertical"
                    margin={{ top: 20, right: 30, left: 150, bottom: 20 }}
                >
                    <CartesianGrid strokeDasharray="3 3" horizontal={false} />
                    <XAxis type="number" domain={[0, 100]} unit="%" />
                    <YAxis 
                        type="category" 
                        dataKey="label" 
                        width={140}
                        tick={{ fontSize: 12 }}
                    />
                    <Tooltip
                        content={({ active, payload, label }) => {
                            if (active && payload && payload.length) {
                                return (
                                    <div className={styles.customTooltip}>
                                        <p className={styles.tooltipLabel}>{label}</p>
                                        {payload.map((entry) => (
                                            <p 
                                                key={entry.name}
                                                className={styles.tooltipValue}
                                                style={{ color: entry.color }}
                                            >
                                                {entry.name}: {entry.value}%
                                            </p>
                                        ))}
                                    </div>
                                );
                            }
                            return null;
                        }}
                    />
                    <Legend />
                    <Bar 
                        dataKey="categories[0].value" 
                        name="Before AI" 
                        fill="var(--color-gray-400)"
                        barSize={20}
                    />
                    <Bar 
                        dataKey="categories[1].value" 
                        name="With AI" 
                        fill="var(--color-blue-310)"
                        barSize={20}
                    />
                </BarChart>
            </ResponsiveContainer>
        );
    
        return (
            <div className={styles.focusShiftContainer}>
                <GroupedBarChart />
                <div className={styles.insightBox}>
                    <div className={styles.insightTitle}>Key Shifts</div>
                    <div className={styles.insightContent}>
                        <div className={styles.insightItem}>
                            <span className={styles.insightLabel}>Strategic Focus</span>
                            <span className={styles.insightValue}>+125%</span>
                        </div>
                        <div className={styles.insightItem}>
                            <span className={styles.insightLabel}>Team Collaboration</span>
                            <span className={styles.insightValue}>+150%</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    case 'bar': {
      return (
          <div className={styles.benefitsContainer}>
              <div className={styles.metricsSection}>
                  {chart.data.metrics.map((metric, index) => (
                      <div key={index} className={styles.metricGroup}>
                          <div className={styles.metricHeader}>
                              <div className={styles.metricLabel}>{metric.label}</div>
                              <div 
                                  className={styles.metricValue}
                                  style={{ color: metric.color }}
                              >
                                  {metric.categories[0].value.toLocaleString()}
                                  {metric.categories[0].suffix}
                              </div>
                          </div>
                          <div className={styles.barContainer}>
                              <div 
                                  className={styles.bar}
                                  style={{
                                      width: '100%',
                                      backgroundColor: metric.color
                                  }}
                              />
                          </div>
                      </div>
                  ))}
              </div>
              
              <div className={styles.insightsGrid}>
                  {chart.data.insights.map((insight, index) => (
                      <div key={index} className={styles.insightCard}>
                          <div className={styles.insightTitle}>
                              {insight.title}
                          </div>
                          <ul className={styles.insightList}>
                              {insight.items.map((item, itemIndex) => (
                                  <li key={itemIndex} className={styles.insightItem}>
                                      {item}
                                  </li>
                              ))}
                          </ul>
                      </div>
                  ))}
              </div>
          </div>
      );
  };
      case 'bar':
          return (
            <ResponsiveContainer width="100%" height={400}>
              <BarChart 
                data={chart.data.labels.map((label, index) => ({
                  name: label,
                  ...chart.data.datasets.reduce((acc, dataset) => {
                    acc[dataset.label] = dataset.data[index];
                    return acc;
                  }, {})
                }))}
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis label={{ value: 'Million USD', angle: -90, position: 'insideLeft' }} />
                <Tooltip />
                <Legend />
                {chart.data.datasets.map((dataset, index) => (
                  <Bar 
                    key={dataset.label}
                    dataKey={dataset.label}
                    stackId={chart.stacked ? "a" : undefined}
                    fill={dataset.backgroundColor}
                  />
                ))}
              </BarChart>
            </ResponsiveContainer>
          );
      // case 'line':
      //   return (
      //     <ResponsiveContainer width="100%" height={400}>
      //       <LineChart data={chart.data.labels.map((label, index) => ({
      //         name: label,
      //         ...chart.data.datasets.reduce((acc, dataset) => {
      //           acc[dataset.label] = dataset.data[index];
      //           return acc;
      //         }, {})
      //       }))}>
      //         <CartesianGrid strokeDasharray="3 3" />
      //         <XAxis dataKey="name" />
      //         <YAxis />
      //         <Tooltip />
      //         <Legend />
      //         {chart.data.datasets.map((dataset, index) => (
      //           <Line key={index} type="monotone" dataKey={dataset.label} stroke={dataset.borderColor} />
      //         ))}
      //       </LineChart>
      //     </ResponsiveContainer>
      //   );
      case 'distribution':
          return (
              <div className={styles.distributionChartContainer}>
                  <ResponsiveContainer width="100%" height={450}>
                      <ComposedChart
                          data={generateDistributionData(chart.params)}
                          margin={{ top: 30, right: 30, left: 30, bottom: 30 }}
                      >
                          <CartesianGrid 
                              strokeDasharray="3 3" 
                              vertical={false}
                          />
                          {chart.params.peaks.map(peak => (
                              <ReferenceLine
                                  key={peak.amount}
                                  x={peak.amount}
                                  stroke="var(--color-border)"
                                  strokeDasharray="3 3"
                                  label={{
                                      value: formatCurrency(peak.amount),
                                      position: 'top'
                                  }}
                              />
                          ))}
                          <XAxis
                              dataKey="x"
                              scale="log"
                              domain={['auto', 'auto']}
                              tickFormatter={formatCurrency}
                              label={{
                                  value: 'Expense Amount in US Dollars',
                                  position: 'bottom',
                                  offset: 20
                              }}
                          />
                          <YAxis
                              label={{
                                  value: 'Modelled Frequency',
                                  angle: -90,
                                  position: 'insideLeft'
                              }}
                          />
                          <Tooltip content={<CustomDistributionTooltip />} />
                          <Legend 
                              verticalAlign="bottom"
                              iconType="circle"
                              iconSize={10}
                              wrapperStyle={{
                                  paddingTop: '20px',
                                  margin: '-50px 30px',
                                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                  borderRadius: '8px',
                                  // padding: '12px'
                              }}
                              formatter={(value) => (
                                  <span className="text-white font-medium text-sm pl-2">
                                      {value}
                                  </span>
                              )}
                          />
                          <Line
                              type="monotone"
                              dataKey="baseline"
                              stroke="var(--color-red-310)"
                              name="OPEX Distribution"
                              dot={false}
                              strokeWidth={2}
                          />
                          {chart.params.peaks.map((peak, index) => (
                              <Area
                                  key={index}
                                  type="monotone"
                                  dataKey={`aiImpact${index + 1}`}
                                  fill={peak.impact.color}
                                  stroke="none"
                                  fillOpacity={0.4}
                                  name={`Impact ${index + 1}`}
                              />
                          ))}
                          <Area
                              type="monotone"
                              dataKey="aiImpact4"
                              fill={chart.params.tail.impact.color}
                              stroke="none"
                              fillOpacity={0.4}
                              name="Strategic Impact"
                          />
                      </ComposedChart>
                  </ResponsiveContainer>
              </div>
          );
          case 'waterfall':
            const processedData = calculateRunningTotal(chart.data);
            return (
                <div className={styles.waterfallChartContainer}>
                    <ResponsiveContainer width="100%" height={470}>
                        <ComposedChart
                            data={processedData}
                            margin={{ top: 20, right: 30, left: 40, bottom: 60 }}
                        >
                            <CartesianGrid 
                                strokeDasharray="3 3" 
                                vertical={false}
                            />
                            <XAxis 
                                dataKey="name"
                                angle={0}
                                interval={0}
                                tickLine={false}
                            />
                            <YAxis 
                                tickFormatter={(value) => formatCurrency(Math.abs(value))}
                                label={{ 
                                    value: 'Cost Amount', 
                                    angle: -90, 
                                    position: 'insideLeft'
                                }}
                                tickLine={false}
                            />
                            <Tooltip content={<CustomWaterfallTooltip />} />
                            <Bar 
                                dataKey="value" 
                                radius={[4, 4, 0, 0]}
                            >
                                {processedData.map((entry, index) => (
                                    <Cell 
                                        key={`cell-${index}`}
                                        fill={entry.color}
                                    />
                                ))}
                            </Bar>
                            <Line
                                type="step"
                                dataKey="running"
                                stroke="var(--color-gray-300)"
                                strokeWidth={2}
                                dot={{
                                    fill: 'var(--color-gray-300)',
                                    r: 4
                                }}
                                activeDot={{
                                    r: 6,
                                    fill: 'var(--color-primary)'
                                }}
                            />
                        </ComposedChart>
                    </ResponsiveContainer>
                </div>
              );
              // case 'bar-comparison': {
              //   return (
              //       <div className="chart-container">
              //           {/* <h3 className="chart-title">{chart.title}</h3> */}
              //           <h4 className="chart-subtitle">{chart.subtitle}</h4>
              //           <div className="comparison-chart">
              //               {chart.data.metrics.map((metric) => (
              //                   <div key={metric.name} className="metric-group">
              //                       <div className="metric-label">{metric.name}</div>
              //                       <div className="metric-bars">
              //                           {chart.data.categories.map((category, index) => (
              //                               <div key={category} className="bar-container">
              //                                   <div className="category-label">{category}</div>
              //                                   <div 
              //                                       className="horizontal-bar"
              //                                       style={{
              //                                           width: `${getBarWidth(metric.values[index], metric)}%`,
              //                                           backgroundColor: metric.color
              //                                       }}
              //                                   >
              //                                       <span className="bar-value">
              //                                           {formatValue(metric.values[index], metric.format)}
              //                                       </span>
              //                                   </div>
              //                               </div>
              //                           ))}
              //                       </div>
              //                   </div>
              //               ))}
              //               {chart.data.annotations?.savings && (
              //                   <div className="savings-annotation">
              //                       <div className="savings-amount">
              //                           Total Savings: {formatCurrency(chart.data.annotations.savings.amount)}
              //                       </div>
              //                       <div className="savings-percentage">
              //                           {chart.data.annotations.savings.percentage}% Reduction in Expenses
              //                       </div>
              //                   </div>
              //               )}
              //           </div>
              //       </div>
              //   );
              // }
      default:
        return null;
    }
  };

  const ArticleDetail = () => {
    const { t, language } = useLanguage();
    const { slug } = useParams();
    const [article, setArticle] = useState(null);
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      const fetchArticle = async () => {
        setLoading(true);
        try {
          // Try to import the language-specific news articles
          const languageCode = language.replace('-', '_').toLowerCase();
          const newsArticlesModule = await import(`../../data/newsArticles_${languageCode}.js`);
          const languageSpecificArticles = newsArticlesModule.default;
          
          // Find the article in the language-specific articles
          let foundArticle = languageSpecificArticles.find(a => a.slug === slug);
          
          // If not found, fall back to the default articles
          if (!foundArticle) {
            foundArticle = defaultNewsArticles.find(a => a.slug === slug);
          }
          
          setArticle(foundArticle);
        } catch (error) {
          console.error('Error loading news articles:', error);
          // Fall back to the default articles if there's an error
          const foundArticle = defaultNewsArticles.find(a => a.slug === slug);
          setArticle(foundArticle);
        } finally {
          setLoading(false);
        }
      };
  
      fetchArticle();
    }, [slug, language]);
  
    if (loading) {
      return <div className={styles.loading}>{t('loading')}</div>;
    }
  
    if (!article) {
      return <div className={styles.notFound}>{t('article_not_found')}</div>;
    }
  
    const shareUrl = window.location.href;
    const shareTitle = article.blog_title;
  
    const shareLinks = {
      email: `mailto:?subject=${encodeURIComponent(shareTitle)}&body=${encodeURIComponent(shareUrl)}`,
      twitter: `https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(shareTitle)}`,
      linkedin: `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(shareUrl)}&title=${encodeURIComponent(shareTitle)}`,
      reddit: `https://reddit.com/submit?url=${encodeURIComponent(shareUrl)}&title=${encodeURIComponent(shareTitle)}`,
      facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`,
      whatsapp: `https://wa.me/?text=${encodeURIComponent(shareTitle + ' ' + shareUrl)}`,
      slack: `https://slack.com/openid/share?url=${encodeURIComponent(shareUrl)}`
    };
  
    const parseInlineStyles = (text) => {
      // Process bold and italic styling
      return text
        .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
        .replace(/\*(.*?)\*/g, '<em>$1</em>');
    };
    
    const parseContent = (content) => {
      const lines = content.split('\n');
      
      return lines.map((line, index) => {
        // Check for charts first
        const chartMatch = line.match(/\[CHART_(\d+):.+?\]/);
        if (chartMatch) {
          const chartId = `CHART_${chartMatch[1]}`;
          const chart = article.charts?.find(c => c.id === chartId);
          return chart ? (
            <div key={`chart-${index}`} className={styles.chartContainer}>
              {/* <h3 className={styles.chartTitle}>{chart.title}</h3> */}
              <ChartComponent chart={chart} />
            </div>
          ) : null;
        }
    
        // Handle Headers (h1 to h6)
        const headerMatches = [
          { regex: /^# (.*)/, tag: 'h1' },
          { regex: /^## (.*)/, tag: 'h2' },
          { regex: /^### (.*)/, tag: 'h3' },
          { regex: /^#### (.*)/, tag: 'h4' },
          { regex: /^##### (.*)/, tag: 'h5' },
          { regex: /^###### (.*)/, tag: 'h6' }
        ];
    
        for (const header of headerMatches) {
          const match = line.match(header.regex);
          if (match) {
            const processedText = parseInlineStyles(match[1]);
            return React.createElement(
              header.tag,
              {
                key: index,
                className: styles[header.tag],
                dangerouslySetInnerHTML: { __html: processedText }
              }
            );
          }
        }
    
        // Lists
        const listMatch = line.match(/^- (.*)/);
        if (listMatch) {
          const processedText = parseInlineStyles(listMatch[1]);
          return (
            <li 
              key={index} 
              className={styles.listItem}
              dangerouslySetInnerHTML={{ __html: processedText }}
            />
          );
        }
    
        // Numbered Lists
        const numberedListMatch = line.match(/^\d+\. (.*)/);
        if (numberedListMatch) {
          const processedText = parseInlineStyles(numberedListMatch[1]);
          return (
            <li 
              key={index} 
              className={styles.numberedListItem}
              dangerouslySetInnerHTML={{ __html: processedText }}
            />
          );
        }
    
        // If line is empty, return a spacer
        if (!line.trim()) {
          return <div key={index} className={styles.spacer}></div>;
        }
    
        // Regular paragraph with processed inline styles
        const processedText = parseInlineStyles(line);
        return line.trim() ? (
          <p 
            key={index} 
            className={styles.paragraph}
            dangerouslySetInnerHTML={{ __html: processedText }}
          />
        ) : null;
      });
    };
    
    const renderContent = () => {
      const elements = parseContent(article.blog_content);
      
      // Group list items into proper ul/ol elements
      const processedElements = [];
      let currentList = [];
      let isNumberedList = false;
    
      elements.forEach((element, index) => {
        if (element?.type === 'li') {
          currentList.push(element);
          isNumberedList = element.props.className === styles.numberedListItem;
        } else {
          if (currentList.length > 0) {
            processedElements.push(
              isNumberedList ? 
                <ol key={`list-${index}`} className={styles.orderedList}>{currentList}</ol> :
                <ul key={`list-${index}`} className={styles.unorderedList}>{currentList}</ul>
            );
            currentList = [];
          }
          processedElements.push(element);
        }
      });
    
      // Handle any remaining list items
      if (currentList.length > 0) {
        processedElements.push(
          isNumberedList ? 
            <ol key="list-final" className={styles.orderedList}>{currentList}</ol> :
            <ul key="list-final" className={styles.unorderedList}>{currentList}</ul>
        );
      }
    
      return <div className={styles.articleContent}>{processedElements}</div>;
    };
  
    return (
      <article className={styles.articleContainer}>
        <Helmet>
          <title>{`${article.blog_title} | ${t('allegory_news')}`}</title>
          <meta name="description" content={article.blog_excerpt} />
          <meta name="keywords" content={article.blog_keywords.join(', ')} />
        </Helmet>
        <header className={styles.articleHeader}>
          <h1 className={styles.articleTitle}>{article.blog_title}</h1>
          <div className={styles.articleMeta}>
            <span>{t('published')}: {article.published_at}</span>
            <span>{article.blog_time_to_read} {t('min_read')}</span>
          </div>
        </header>
        <div className={styles.imageContainer}>
          <img src={`/images/${article.image}`} alt={article.blog_title} className={styles.articleImage} />
        </div>
        <div className={styles.articleContent}>
          {renderContent()}
        </div>
        <footer className={styles.articleFooter}>
          <h3 className={styles.shareTitle}>{t('share_article')}</h3>
          <div className={styles.shareButtons}>
          <a href={shareLinks.email} className={styles.shareButton} aria-label={t('share_email')}>
            <img src="/icons/18/misc/email.svg" alt="Email" />
          </a>
          <a href={shareLinks.twitter} target="_blank" rel="noopener noreferrer" className={styles.shareButton} aria-label={t('share_twitter')}>
            <img src="/icons/18/socials/x/x-logo.svg" alt="Twitter" />
          </a>
          <a href={shareLinks.linkedin} target="_blank" rel="noopener noreferrer" className={styles.shareButton} aria-label={t('share_linkedin')}>
            <img src="/icons/18/socials/linkedin/linkedin-logo.svg" alt="LinkedIn" />
          </a>
          <a href={shareLinks.reddit} target="_blank" rel="noopener noreferrer" className={styles.shareButton} aria-label={t('share_reddit')}>
            <img src="/icons/18/socials/reddit/reddit-logo.svg" alt="Reddit" />
          </a>
          <a href={shareLinks.facebook} target="_blank" rel="noopener noreferrer" className={styles.shareButton} aria-label={t('share_facebook')}>
            <img src="/icons/18/socials/meta/facebook-logo.svg" alt="Facebook" />
          </a>
          <a href={shareLinks.whatsapp} target="_blank" rel="noopener noreferrer" className={styles.shareButton} aria-label={t('share_whatsapp')}>
            <img src="/icons/18/socials/meta/whatsapp-logo.svg" alt="WhatsApp" />
          </a>
          <a href={shareLinks.slack} target="_blank" rel="noopener noreferrer" className={styles.shareButton} aria-label={t('share_slack')}>
            <img src="/icons/18/socials/slack/slack-logo.svg" alt="Slack" />
          </a>
        </div>
      </footer>
    </article>
  );
};

export default ArticleDetail;