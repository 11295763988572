// File: frontend/src/components/layout/DashboardLayout/PageTitle.js

import React from 'react';
import { useLanguage } from '../../../contexts/LanguageContext';
import styles from './PageTitle.module.css';

const PageTitle = ({ title, children }) => {
  const { t } = useLanguage();

  return (
    <div className={styles.pageTitleContainer}>
      <div className={styles.titleWrapper}>
        <h1 className={styles.title}>{title}</h1>
      </div>
      {children && <div className={styles.rightSection}>{children}</div>}
    </div>
  );
};

export default PageTitle;