// File: frontend/src/pages/Quote/components/ChatInterface/components/QuoteSummary.js

import React from 'react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from '../ChatInterface.module.css';

const QuoteSummary = ({ localQuoteData, quoteStage, onQuoteComplete, onClose }) => {
  const { t } = useLanguage();

  return (
    <div className={styles.summaryPanel}>
      <div className={styles.summaryPanelHeader}>
        <h3>{t('Quote Summary')}</h3>
        <button className={styles.closeButton} onClick={onClose}>×</button>
      </div>
      <div className={styles.summaryContent}>
        {localQuoteData && localQuoteData.insuranceType ? (
          <>
            <div className={styles.summaryItem}>
              <span className={styles.summaryLabel}>{t('Insurance Type:')}</span>
              <span className={styles.summaryValue}>
                {localQuoteData.insuranceType.charAt(0).toUpperCase() + localQuoteData.insuranceType.slice(1)}
              </span>
            </div>
            
            {localQuoteData.currentPremium > 0 && (
              <>
                <div className={styles.summaryItem}>
                  <span className={styles.summaryLabel}>{t('Current Premium:')}</span>
                  <span className={styles.summaryValue}>${localQuoteData.currentPremium}/month</span>
                </div>
                
                <div className={styles.summaryItem}>
                  <span className={styles.summaryLabel}>{t('Allegory Premium:')}</span>
                  <span className={styles.summaryValue}>
                    ${localQuoteData.currentPremium - localQuoteData.estimatedSavings}/month
                  </span>
                </div>
                
                <div className={styles.summaryItem}>
                  <span className={styles.summaryLabel}>{t('Your Savings:')}</span>
                  <span className={`${styles.summaryValue} ${styles.highlight}`}>
                    ${localQuoteData.estimatedSavings}/month
                  </span>
                </div>
              </>
            )}
            
            {quoteStage === 'completion' && (
              <button 
                className={styles.checkoutButton} 
                onClick={() => onQuoteComplete(localQuoteData)}
              >
                {t('Complete Purchase')}
              </button>
            )}
          </>
        ) : (
          <p className={styles.emptyState}>{t('Please continue chatting to build your quote.')}</p>
        )}
      </div>
    </div>
  );
};

export default QuoteSummary;