// File: frontend/src/pages/Quote/components/SavingsCalculator/SavingsCalculator.js

import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../../../contexts/LanguageContext';
import { quoteService } from '../../../../services/quote'; // Import the quote service
import { v4 as uuidv4 } from 'uuid'; // Import UUID for generating session IDs
import Dropdown from '../../../../components/form/Dropdown/Dropdown';
import { SUPPORTED_LOCALES } from '../../../../config/i18n.config';
import { getInsuranceTypes } from './InsuranceTypes';
import safeStorage from '../../../../utils/storage';
import styles from './SavingsCalculator.module.css';

// Get today's date in YYYY-MM-DD format in local timezone
const getTodayDateString = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const SavingsCalculator = ({ onGetQuote, initialInsuranceType }) => {
  const { t, language } = useLanguage();
  const navigate = useNavigate();
  const [premium, setPremium] = useState(180);
  const [premiumType, setPremiumType] = useState('monthly');
  const [insuranceType, setInsuranceType] = useState(initialInsuranceType || 'auto');
  const [currentPolicyExpiriesAt, setCurrentPolicyExpiriesAt] = useState(getTodayDateString());
  const [calculatedValues, setCalculatedValues] = useState(null);
  const [isAnimatingBars, setIsAnimatingBars] = useState(false);
  const [showAdditionalSavings, setShowAdditionalSavings] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const [isTracking, setIsTracking] = useState(false);
  const [trackingDebounce, setTrackingDebounce] = useState(null);
  const [hasUserInteracted, setHasUserInteracted] = useState(false);
  
  const canvasRef = useRef(null);

  const INSURANCE_TYPES = useMemo(() => getInsuranceTypes(t), [t]);

  // Initialize or retrieve session ID on component mount
  useEffect(() => {
    // Try to get session ID from storage
    const savedSessionId = safeStorage.getItem('savings_session_id');
    if (savedSessionId) {
      console.log('Retrieved saved session ID:', savedSessionId);
      setSessionId(savedSessionId);
      
      // Don't automatically redirect based on session ID
      // This was causing the redirect loop
    } else {
      // Generate a new session ID if none exists
      const newSessionId = uuidv4();
      setSessionId(newSessionId);
      safeStorage.setItem('savings_session_id', newSessionId);
      console.log('Generated new session ID:', newSessionId);
    }
    
    // Set insurance type from prop if provided
    if (initialInsuranceType && initialInsuranceType !== insuranceType) {
      setInsuranceType(initialInsuranceType);
    }
  }, [initialInsuranceType]);

  // Track savings session when meaningful changes occur
  // Use a debounce to avoid too many API calls
  useEffect(() => {
    // Only track if user has interacted and we have calculated values
    if (!calculatedValues || !sessionId || !hasUserInteracted) return;
    
    // Clear any existing debounce timer
    if (trackingDebounce) {
      clearTimeout(trackingDebounce);
    }
    
    // Set a new debounce timer (1 second delay)
    const timer = setTimeout(() => {
      if (!isTracking) {
        trackSavingsSession();
      }
    }, 1000);
    
    setTrackingDebounce(timer);
    
    // Cleanup on unmount
    return () => {
      if (trackingDebounce) {
        clearTimeout(trackingDebounce);
      }
    };
  }, [
    calculatedValues, 
    sessionId, 
    premium, 
    premiumType, 
    insuranceType, 
    showAdditionalSavings, 
    currentPolicyExpiriesAt,
    hasUserInteracted
  ]);

  // Function to track savings session
  const trackSavingsSession = async () => {
    if (!calculatedValues || !sessionId || !hasUserInteracted) return;
    
    setIsTracking(true);
    console.log('Tracking savings session...');
    
    try {
      // Get currency from locale
      const currencyCode = SUPPORTED_LOCALES[language]?.currency || 'CAD';
      
      // Prepare data for tracking
      const trackingData = {
        session_id: sessionId,
        product_type: insuranceType,
        payment_frequency: premiumType,
        current_premium: annualPremium,
        current_premium_currency: currencyCode,
        current_policy_expiries_at: currentPolicyExpiriesAt || getTodayDateString(), // Use today if not set
        is_additional_savings_selected: showAdditionalSavings,
        base_savings: calculatedValues.savings.baseSavings,
        additional_savings: showAdditionalSavings ? calculatedValues.additionalSavings.amount : 0,
        total_savings: showAdditionalSavings 
          ? calculatedValues.savings.potentialTotalSavings 
          : calculatedValues.savings.baseSavings,
        total_savings_percentage: showAdditionalSavings 
          ? calculatedValues.savings.potentialTotalSavingsPercentage 
          : calculatedValues.savings.baseSavingsPercentage,
        landing_page: window.location.href,
        referrer: document.referrer || null
      };
      
      console.log('Sending tracking data:', trackingData);
      
      // Call the API
      const response = await quoteService.trackSavingsSession(trackingData);
      
      // Store session ID for future updates
      if (response && response.session_id) {
        setSessionId(response.session_id);
        safeStorage.setItem('savings_session_id', response.session_id);
        console.log('Updated session ID:', response.session_id);
        
        // Store the last product type used
        safeStorage.setItem('last_product_type', insuranceType);
        
        // If we have a quote_id, store it as well
        if (response.quote_id && !response.quote_id.startsWith('no-quote')) {
          safeStorage.setItem('quote_id', response.quote_id);
          console.log('Saved quote_id:', response.quote_id);
        }
      }
      
      console.log('Savings session tracked successfully:', response);
    } catch (error) {
      console.error('Error tracking savings session:', error);
    } finally {
      setIsTracking(false);
    }
  };

  // Function to get all products for a session
  const getAllProductsForSession = async (sessionId) => {
    try {
      const response = await quoteService.getSavingsSessionProducts(sessionId);
      return response.products || [];
    } catch (error) {
      console.error('Error fetching session products:', error);
      return [];
    }
  };

  // Function to get a specific product for a session
  const getProductForSession = async (sessionId, productType) => {
    try {
      const response = await quoteService.getSavingsSessionProduct(sessionId, productType);
      return response;
    } catch (error) {
      console.error('Error fetching session product:', error);
      return null;
    }
  };

  // Update handleGetQuote to use safe storage
  const handleGetQuote = () => {
    // Store the selected insurance type in session storage
    try {
      sessionStorage.setItem('selectedInsuranceType', insuranceType);
    } catch (error) {
      console.warn('sessionStorage access failed:', error);
    }
    
    // Check for existing quote ID first
    let quoteId = safeStorage.getItem('quote_id');
    
    // Only generate a new ID if one doesn't exist or it's a placeholder
    if (!quoteId || quoteId.startsWith('no-quote')) {
      quoteId = uuidv4();
      safeStorage.setItem('quote_id', quoteId);
      console.log('Generated new quote ID for actual quote:', quoteId);
    } else {
      console.log('Using existing quote ID:', quoteId);
    }
    
    // Call the parent's onGetQuote function with the insurance type if it exists
    if (typeof onGetQuote === 'function') {
      onGetQuote(insuranceType, quoteId); // Pass the quoteId to the parent handler
      return; // Exit early if parent handler exists
    }
    
    // Otherwise handle navigation directly
    if (window.location.pathname.includes('/quote')) {
      // Use window.location for hash changes to avoid React Router issues
      window.location.href = `/en-ca/quote/${quoteId}#chatSection?type=${insuranceType}`;
    } else {
      // Navigate to the quote page with the quote ID, chat section hash and insurance type parameter
      navigate(`/en-ca/quote/${quoteId}#chatSection?type=${insuranceType}`);
    }
    
    // Track this event with analytics if available
    if (window.analytics) {
      window.analytics.track('Start Quote', {
        page: 'Calculator',
        insuranceType: insuranceType,
        sessionId: sessionId,
        quoteId: quoteId
      });
    }
  };
  
  // Transform the insurance types object into the format expected by Dropdown
  const insuranceTypeOptions = Object.entries(INSURANCE_TYPES).map(([key, data]) => ({
    value: key,
    labelKey: `calculator.insurance_type.${key}`,
    displayName: data.name // Fallback if translation is not available
  }));
  
  // Allegory's fixed expense percentage
  const ALLEGORY_EXPENSE_PERCENTAGE = 8;
  
  // Premium type options
  const premiumTypeOptions = [
    { value: 'monthly', labelKey: 'calculator.premium_type.monthly' },
    { value: 'bi_monthly', labelKey: 'calculator.premium_type.bi_monthly' },
    { value: 'quarterly', labelKey: 'calculator.premium_type.quarterly' },
    { value: 'semi_annual', labelKey: 'calculator.premium_type.semi_annual' },
    { value: 'annual', labelKey: 'calculator.premium_type.annual' },
    { value: 'pay_in_full', labelKey: 'calculator.premium_type.pay_in_full' },
    { value: 'installment', labelKey: 'calculator.premium_type.installment' },
    { value: 'pay_as_you_go', labelKey: 'calculator.premium_type.pay_as_you_go' }
  ];

  const paymentMethodOptions = [
    { value: 'bank_transfer', labelKey: 'calculator.payment_method.bank_transfer' },
    { value: 'eft', labelKey: 'calculator.payment_method.eft' },
    { value: 'credit_card', labelKey: 'calculator.payment_method.credit_card' },
    { value: 'bank_draft', labelKey: 'calculator.payment_method.bank_draft' },
    { value: 'check', labelKey: 'calculator.payment_method.check' },
    { value: 'cash', labelKey: 'calculator.payment_method.cash' },
    { value: 'mobile_payment', labelKey: 'calculator.payment_method.mobile_payment' },
    { value: 'automatic', labelKey: 'calculator.payment_method.automatic' }
  ];
  
  // Get currency symbol from language context
  const getCurrencySymbol = () => {
    // Import from the config
    const { SUPPORTED_LOCALES } = require('../../../../config/i18n.config');
    return SUPPORTED_LOCALES[language]?.currencySymbol || '$';
  };
  
  // Convert premium to annual amount
  const annualPremium = (() => {
    switch (premiumType) {
      case 'monthly':
        return premium * 12;
      case 'bi_monthly':
        return premium * 6;
      case 'quarterly':
        return premium * 4;
      case 'semi_annual':
        return premium * 2;
      case 'annual':
      case 'pay_in_full':
        return premium;
      case 'installment':
        // Assuming a standard 10-payment installment plan
        return premium * 10 / 10; 
      case 'pay_as_you_go':
        // Assuming monthly average × 12
        return premium * 12;
      default:
        return premium;
    }
  })();
  
  // Calculate values based on current inputs
  useEffect(() => {
    // Default to 'auto' if insuranceType is not valid
    const validInsuranceType = INSURANCE_TYPES[insuranceType] ? insuranceType : 'auto';
    const insuranceData = INSURANCE_TYPES[validInsuranceType];
    
    const traditional = {
      expenses: annualPremium * (insuranceData.traditionalExpensePercentage / 100),
      costOfRisk: annualPremium * (insuranceData.costOfRiskPercentage / 100),
      total: annualPremium
    };
    
    const allegory = {
      expenses: (annualPremium * (insuranceData.costOfRiskPercentage / 100)) * (ALLEGORY_EXPENSE_PERCENTAGE / 100),
      costOfRisk: annualPremium * (insuranceData.costOfRiskPercentage / 100),
      total: (annualPremium * (insuranceData.costOfRiskPercentage / 100)) * (ALLEGORY_EXPENSE_PERCENTAGE / 100) + 
              annualPremium * (insuranceData.costOfRiskPercentage / 100)
    };
    
    const additionalSavings = {
      amount: allegory.costOfRisk * (insuranceData.additionalSavingsPercentage / 100),
      percentage: insuranceData.additionalSavingsPercentage,
      description: insuranceData.additionalSavingsDescription,
      type: insuranceData.additionalSavingsType
    };
    
    const savings = {
      baseSavings: traditional.total - allegory.total,
      baseSavingsPercentage: ((traditional.total - allegory.total) / traditional.total) * 100,
      potentialTotalSavings: traditional.total - (allegory.total - additionalSavings.amount),
      potentialTotalSavingsPercentage: ((traditional.total - (allegory.total - additionalSavings.amount)) / traditional.total) * 100
    };
    
    setCalculatedValues({
      traditional,
      allegory,
      additionalSavings,
      savings
    });
    
    // Trigger bar animation when values change
    setIsAnimatingBars(true);
    setTimeout(() => setIsAnimatingBars(false), 1000);
    
  }, [annualPremium, insuranceType]);
  
  // Draw comparison chart on canvas
  useEffect(() => {
    if (!calculatedValues || !canvasRef.current) return;
    
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    
    // Function to render the chart with current dimensions
    const renderChart = () => {
      const dpr = window.devicePixelRatio || 1;
      
      // Set canvas dimensions with proper DPI handling
      const rect = canvas.getBoundingClientRect();
      canvas.width = rect.width * dpr;
      canvas.height = rect.height * dpr;
      ctx.scale(dpr, dpr);
      
      // Clear canvas
      ctx.clearRect(0, 0, canvas.width / dpr, canvas.height / dpr);
      
      // Get canvas dimensions
      const canvasWidth = canvas.width / dpr;
      const canvasHeight = canvas.height / dpr;
      const isMobile = canvasWidth < 500;
      
      // Responsive spacing and sizes
      const fontSize = Math.max(10, Math.min(14, canvasWidth / 40));
      const titleFontSize = Math.max(12, Math.min(16, canvasWidth / 35));
      const calloutFontSize = Math.max(14, Math.min(18, canvasWidth / 30));
      const legendFontSize = Math.max(10, Math.min(13, canvasWidth / 45));
      const legendSize = Math.max(10, Math.min(15, canvasWidth / 40));
      
      // Chart dimensions - more responsive
      const yAxisSpace = isMobile ? 60 : 70;
      const bottomSpace = isMobile ? 100 : 140;
      const chartWidth = canvasWidth - yAxisSpace - 30;
      const chartHeight = canvasHeight - bottomSpace;
      
      // Calculate bar dimensions
      const barCount = 3;
      const barSpacing = isMobile ? 20 : 40;
      const barWidth = (chartWidth - ((barCount - 1) * barSpacing)) / barCount;
      
      const startX = yAxisSpace;
      const startY = 40;
      
      const maxValue = Math.max(
        calculatedValues.traditional.total,
        calculatedValues.allegory.total,
        calculatedValues.allegory.total - calculatedValues.additionalSavings.amount
      );
      
      const valueToHeight = (value) => (value / maxValue) * chartHeight;
      
      // Function to draw a stacked bar
      const drawStackedBar = (x, segments, title, animationProgress = 1) => {
        let currentY = startY + chartHeight;
        
        // Draw bar segments from bottom to top
        segments.forEach((segment) => {
          const height = valueToHeight(segment.value) * animationProgress;
          
          ctx.fillStyle = segment.color;
          ctx.fillRect(x, currentY - height, barWidth, height);
          
          // Only add text label if there's room and segment is significant
          if (height > 25 && segment.value > (maxValue * 0.05)) {
            ctx.fillStyle = segment.textColor || 'white';
            ctx.font = `bold ${fontSize}px var(--font-primary)`;
            ctx.textAlign = 'center';
            
            // Format the value text based on size
            let valueText = formatCurrency(Math.round(segment.value));
            if (isMobile && height < 40 && segment.value >= 10000) {
                valueText = `${getCurrencySymbol()}${Math.round(segment.value / 1000).toLocaleString()}k`;
            } 
            
            ctx.fillText(
              valueText,
              x + barWidth / 2,
              currentY - height / 2
            );
          }
          
          currentY -= height;
        });
        
        // Draw bar title - more responsive
        const titleY = startY + chartHeight + (isMobile ? 20 : 30);
        
        ctx.fillStyle = '#333';
        ctx.font = `bold ${fontSize}px var(--font-primary)`;
        ctx.textAlign = 'center';
        
        // Truncate or wrap title if needed
        if (isMobile && title.length > 15) {
          const shortenedTitle = title.substring(0, 12) + '...';
          ctx.fillText(shortenedTitle, x + barWidth / 2, titleY);
        } else {
          ctx.fillText(title, x + barWidth / 2, titleY);
        }
        
        // Draw total at the bottom
        const total = segments.reduce((sum, segment) => sum + segment.value, 0);
        ctx.fillStyle = '#222';
        ctx.font = `bold ${fontSize}px var(--font-primary)`;
        ctx.textAlign = 'center';
        
        // For the bar total
        let totalText = formatCurrency(Math.round(total));
        // If space is limited on mobile, conditionally shorten very large values
        if (isMobile && barWidth < 50 && total >= 100000) {
          totalText = `${getCurrencySymbol()}${Math.round(total / 1000).toLocaleString()}k`;
        }
        
        ctx.fillText(totalText, x + barWidth / 2, titleY + (isMobile ? 18 : 20));
      };
      
      // Animation progress
      const animationProgress = isAnimatingBars ? 0.3 + 0.7 * (Date.now() % 1000) / 1000 : 1;
      
      // Draw Traditional Insurance Bar
      drawStackedBar(
        startX,
        [
          {
            value: calculatedValues.traditional.expenses,
            label: t('calculator.expenses_traditional', { percentage: 33 }),
            color: '#FF6B6B',
            textColor: 'white'
          },
          {
            value: calculatedValues.traditional.costOfRisk,
            label: t('calculator.cost_of_risk'),
            color: '#8AA2FF',
            textColor: 'white'
          },
          {
            value: calculatedValues.traditional.total - calculatedValues.traditional.expenses - calculatedValues.traditional.costOfRisk,
            label: t('calculator.profit'),
            color: '#A3A3A3',
            textColor: 'white'
          }
        ],
        t('calculator.traditional'),
        animationProgress
      );
      
      // Draw Allegory Bar
      drawStackedBar(
        startX + barWidth + barSpacing,
        [
          {
            value: calculatedValues.allegory.expenses,
            label: t('calculator.expenses_allegory', { percentage: 8 }),
            color: '#38C793',
            textColor: 'white'
          },
          {
            value: calculatedValues.allegory.costOfRisk,
            label: t('calculator.cost_of_risk'),
            color: '#8AA2FF',
            textColor: 'white'
          },
          {
            value: 0, // No profit segment
            label: '',
            color: 'transparent'
          }
        ],
        t('calculator.allegory'),
        animationProgress
      );
      
      // Draw Allegory with Additional Savings Bar
      const additionalBarSegments = [
        {
          value: calculatedValues.allegory.expenses,
          label: t('calculator.expenses_allegory', { percentage: 8 }),
          color: '#38C793',
          textColor: 'white'
        },
        {
          value: calculatedValues.allegory.costOfRisk - calculatedValues.additionalSavings.amount,
          label: t('calculator.cost_of_risk_with_technology', { 
            technology: calculatedValues.additionalSavings.type 
          }),
          color: '#8AA2FF',
          textColor: 'white'
        }
      ];
      
      // Add the additional savings segment with stripes
      if (showAdditionalSavings) {
        additionalBarSegments.push({
          value: calculatedValues.additionalSavings.amount,
          label: t('calculator.additional_savings'),
          color: '#FFD166',
          textColor: 'black'
        });
      }
      
      drawStackedBar(
        startX + (barWidth + barSpacing) * 2,
        additionalBarSegments,
        isMobile ? t('calculator.allegory_tech_short') : t('calculator.allegory_with_technology', { 
          technology: calculatedValues.additionalSavings.type 
        }),
        animationProgress
      );
      
      // Draw Y axis
      ctx.beginPath();
      ctx.moveTo(startX - 10, startY);
      ctx.lineTo(startX - 10, startY + chartHeight);
      ctx.strokeStyle = '#ddd';
      ctx.stroke();
      
      // Draw Y axis ticks and labels
      const numTicks = isMobile ? 3 : 5;
      for (let i = 0; i <= numTicks; i++) {
        const y = startY + chartHeight - (i * chartHeight / numTicks);
        const value = (i * maxValue / numTicks);
        
        ctx.beginPath();
        ctx.moveTo(startX - 10, y);
        ctx.lineTo(startX - 5, y);
        ctx.strokeStyle = '#aaa';
        ctx.stroke();
        
        ctx.fillStyle = '#555';
        ctx.font = `${fontSize - 2}px var(--font-primary)`;
        ctx.textAlign = 'right';
        
        // Format the y-axis labels with commas
        let yLabel = formatCurrency(Math.round(value));
        // If space is limited and value is large, use abbreviated format
        if (value >= 10000 && startX < 80) {
          yLabel = `${getCurrencySymbol()}${Math.round(value / 1000).toLocaleString()}k`;
        }
        
        ctx.fillText(yLabel, startX - 15, y + 4);
      }
      
      // Draw X axis
      ctx.beginPath();
      ctx.moveTo(startX - 10, startY + chartHeight);
      ctx.lineTo(startX + chartWidth, startY + chartHeight);
      ctx.strokeStyle = '#ddd';
      ctx.stroke();
      
      // Draw savings callout
      const centerX = startX + (chartWidth / 2);
      const calloutY = startY - (isMobile ? 15 : 25);
      
      ctx.fillStyle = '#38C793';
      ctx.font = `bold ${calloutFontSize}px var(--font-primary)`;
      ctx.textAlign = 'center';
      
      let savingsText;
      if (showAdditionalSavings) {
        savingsText = isMobile 
          ? t('calculator.save_up_to_short', {
              percentage: Math.round(calculatedValues.savings.potentialTotalSavingsPercentage)
            })
          : t('calculator.save_up_to', {
              percentage: Math.round(calculatedValues.savings.potentialTotalSavingsPercentage),
              amount: formatCurrency(calculatedValues.savings.potentialTotalSavings)
            });
      } else {
        savingsText = isMobile
          ? t('calculator.save_percent_short', {
              percentage: Math.round(calculatedValues.savings.baseSavingsPercentage)
            })
          : t('calculator.save_percent', {
              percentage: Math.round(calculatedValues.savings.baseSavingsPercentage),
              amount: formatCurrency(calculatedValues.savings.baseSavings)
            });
      }
      
      ctx.fillText(savingsText, centerX, calloutY);
      
      // Draw legend at the bottom - responsive positioning
      let legendY;
      let legendItemWidth;
      
      if (isMobile) {
        // On mobile, stack legend items in two rows if needed
        legendY = startY + chartHeight + (barWidth < 60 ? 70 : 80);
        legendItemWidth = Math.min(canvasWidth / 2 - 20, 120);
        
        const drawMobileLegend = () => {
          // First row legends
          drawLegendItem(startX, '#FF6B6B', t('calculator.legend.expenses_traditional_short'));
          drawLegendItem(startX + legendItemWidth, '#38C793', t('calculator.legend.expenses_allegory_short'));
          
          // Second row legends
          const secondRowY = legendY + 25;
          drawLegendItem(startX, '#8AA2FF', t('calculator.legend.cost_of_risk_short'));
          
          if (showAdditionalSavings) {
            drawLegendItem(startX + legendItemWidth, '#FFD166', t('calculator.legend.additional_savings_short'));
          }
        };
        
        drawMobileLegend();
      } else {
        // On desktop, show all legends in a single row
        legendY = startY + chartHeight + 80;
        legendItemWidth = 120;
        const legendStartX = canvasWidth / 2 - ((showAdditionalSavings ? 4 : 3) * legendItemWidth / 2);
        
        drawLegendItem(legendStartX, '#FF6B6B', t('calculator.legend.expenses_traditional'));
        drawLegendItem(legendStartX + legendItemWidth, '#38C793', t('calculator.legend.expenses_allegory'));
        drawLegendItem(legendStartX + legendItemWidth * 2, '#8AA2FF', t('calculator.legend.cost_of_risk'));
        
        if (showAdditionalSavings) {
          drawLegendItem(legendStartX + legendItemWidth * 3, '#FFD166', t('calculator.legend.additional_savings'));
        }
      }
      
      // Function to draw legend item
      function drawLegendItem(x, color, text) {
        const boxSize = isMobile ? 12 : 15;
        
        ctx.fillStyle = color;
        ctx.fillRect(x, legendY, boxSize, boxSize);
        
        ctx.fillStyle = '#444';
        ctx.font = `${legendFontSize}px var(--font-primary)`;
        ctx.textAlign = 'left';
        ctx.fillText(text, x + boxSize + 10, legendY + boxSize - 2);
      }
    };
    
    // Initial render
    renderChart();
    
    // Add resize listener for responsiveness
    const handleResize = () => {
      renderChart();
    };
    
    window.addEventListener('resize', handleResize);
    
    // Clean up
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [calculatedValues, isAnimatingBars, showAdditionalSavings, t]);
  
  // Format currency function
  const formatCurrency = (value) => {
    return new Intl.NumberFormat(language, {
      style: 'currency',
      currency: SUPPORTED_LOCALES[language]?.currency || 'CAD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(Math.round(value));
  };
  
  // Your existing handleSubmit function should be updated to call trackSavingsSession
  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Your existing calculation logic...
    
    // After setting calculatedValues, the useEffect will trigger tracking
    setCalculatedValues({
      // Your calculated values
    });
  };
  
  // Handle premium input change
  const handlePremiumChange = (e) => {
    // Get the raw input value
    const inputValue = e.target.value;
    
    // Remove all non-numeric characters except decimal point
    const numericString = inputValue.replace(/[^\d.]/g, '');
    
    // Handle empty input case
    if (numericString === '' || numericString === '.') {
      setPremium(0);
      return;
    }
    
    // Parse as float and update state if it's a valid number
    const numericValue = parseFloat(numericString);
    if (!isNaN(numericValue)) {
      setPremium(numericValue);
    }

    setHasUserInteracted(true);
  };

  const handleInsuranceTypeChange = (value) => {
    setInsuranceType(value);
    setHasUserInteracted(true);
  };
  
  const handlePremiumTypeChange = (value) => {
    setPremiumType(value);
    setHasUserInteracted(true);
  };
  
  const handlePolicyExpiryChange = (e) => {
    setCurrentPolicyExpiriesAt(e.target.value);
    setHasUserInteracted(true);
  };
  
  const handleAdditionalSavingsChange = () => {
    setShowAdditionalSavings(!showAdditionalSavings);
    setHasUserInteracted(true);
  };
  
  // Format the display value with commas for thousands
  const formattedPremium = premium === 0 ? '' : premium.toLocaleString();

  // Custom dropdown wrapper for premium type
  const PremiumTypeDropdown = ({ value, onChange }) => {
    return (
      <div className={styles.premiumTypeDropdown}>
        <div className={styles.customDropdownWrapper}>
          <Dropdown
            id="premiumType"
            options={premiumTypeOptions}
            value={value}
            onChange={onChange}
            variant="standalone"
            direction="up"
          />
        </div>
      </div>
    );
  };
  
  return (
    <div className={styles.calculatorContainer}>
      <div className={styles.calculatorHeader}>
        <h2>{t('calculator.title')}</h2>
        <p>{t('calculator.description')}</p>
      </div>
      
      <div className={styles.calculatorContent}>
        <form className={styles.calculatorForm} onSubmit={handleSubmit}>
          <div className={styles.formGroup}>
            <label htmlFor="insuranceType">{t('calculator.insurance_type_label')}</label>
            <div className={styles.dropdownWrapper}>
              <Dropdown
                id="insuranceType"
                labelKey="calculator.insurance_type_label"
                options={insuranceTypeOptions}
                value={insuranceType}
                onChange={handleInsuranceTypeChange}
                variant="standalone"
                renderOption={(option) => option.displayName || t(option.labelKey)}
                renderSelected={(value) => {
                  const option = insuranceTypeOptions.find(opt => opt.value === value);
                  return option?.displayName || t(option?.labelKey || '');
                }}
              />
            </div>
          </div>
          
          <div className={styles.formGroup}>
            <label htmlFor="premium">{t('calculator.current_premium_label')}</label>
            <div className={styles.premiumInputGroup}>
              <span className={styles.currencySymbol}>{getCurrencySymbol()}</span>
              <input
                id="premium"
                type="text"
                inputMode="numeric"
                value={formattedPremium}
                onChange={handlePremiumChange}
                className={styles.premiumInput}
                placeholder="0"
              />
              <PremiumTypeDropdown
                  value={premiumType}
                  onChange={handlePremiumTypeChange}
              />
            </div>
          </div>

          <div className={styles.formGroup}>
            <label htmlFor="currentPolicyExpiriesAt">{t('calculator.policy_expiry_date_label')}</label>
            <input
              id="currentPolicyExpiriesAt"
              type="date"
              className={styles.dateInput}
              value={currentPolicyExpiriesAt}
              onChange={handlePolicyExpiryChange}
              min={new Date().toISOString().split('T')[0]} // Set minimum date to today
            />
          </div>
          
          <div className={styles.formGroup}>
            <label className={styles.checkboxContainer}>
              <input
                type="checkbox"
                checked={showAdditionalSavings}
                onChange={handleAdditionalSavingsChange}
              />
              <span className={styles.checkboxText}>
                {t('calculator.show_additional_savings', { 
                  technology: calculatedValues?.additionalSavings.type 
                })}
              </span>
            </label>
          </div>
        </form>
        
        <div className={styles.chartContainer}>
          <canvas 
            ref={canvasRef} 
            className={styles.chart}
            height={400}
          />
        </div>
      </div>
      
      {calculatedValues && (
        <div className={styles.savingsHighlight}>
          <div className={styles.savingsSummary}>
            <div className={styles.savingAmount}>
              {showAdditionalSavings 
                ? formatCurrency(calculatedValues.savings.potentialTotalSavings)
                : formatCurrency(calculatedValues.savings.baseSavings)}
            </div>
            <div className={styles.savingPeriod}>{t('calculator.per_year')}</div>
          </div>
          <div className={styles.savingsDetails}>
            <p className={styles.savingsTitle}>
              {t('calculator.your_estimated_savings')}
            </p>
            <div className={styles.savingsBreakdown}>
              <div className={styles.savingsItem}>
                <span>{t('calculator.base_savings')}:</span>
                <strong>{formatCurrency(calculatedValues.savings.baseSavings)}/{t('calculator.year')}</strong>
              </div>
              {showAdditionalSavings && (
                <div className={styles.savingsItem}>
                  <span>{t('calculator.additional_savings_with', { 
                    technology: calculatedValues.additionalSavings.type 
                  })}:</span>
                  <strong>{formatCurrency(calculatedValues.additionalSavings.amount)}/{t('calculator.year')}</strong>
                </div>
              )}
              <div className={styles.savingsItem}>
                <span>{t('calculator.total_savings')}:</span>
                <strong>
                  {showAdditionalSavings 
                    ? formatCurrency(calculatedValues.savings.potentialTotalSavings)
                    : formatCurrency(calculatedValues.savings.baseSavings)}/{t('calculator.year')}
                </strong>
                <span className={styles.savingsPercentage}>
                  ({showAdditionalSavings 
                    ? Math.round(calculatedValues.savings.potentialTotalSavingsPercentage)
                    : Math.round(calculatedValues.savings.baseSavingsPercentage)}%)
                </span>
              </div>
            </div>
          </div>
          <div className={styles.ctaContainer}>
          <button 
              // className={`${styles.ctaButton} ${styles.disabled}`}
              className={`${styles.ctaButton}`}
              onClick={handleGetQuote}
              // disabled
            >
              {INSURANCE_TYPES[insuranceType]?.cta || t('calculator.get_your_quote')} <span className={styles.arrow}>→</span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SavingsCalculator;