// File: frontend/src/pages/InvestorPitch/components/slides/IntroSlide/IntroSlide.js

import React from 'react';
import { motion } from 'framer-motion';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import StatCard from '../../cards/StatCard';
import { ANIMATION_VARIANTS } from '../../../constants/animations';
import styles from './IntroSlide.module.css';

const IntroSlide = () => {
  const { t } = useLanguage();

  const stats = [
    {
      value: t('investor_pitch.intro.stats.gwp.value', { amount: '3.2' }),
      title: t('investor_pitch.intro.stats.gwp.title'),
      color: "var(--color-blue-310)"
    },
    {
      value: t('investor_pitch.intro.stats.languages.value', { count: '10' }),
      title: t('investor_pitch.intro.stats.languages.title'),
      color: "var(--color-green-310)"
    },
    {
      value: t('investor_pitch.intro.stats.market.value', { amount: '7.0' }),
      title: t('investor_pitch.intro.stats.market.title'),
      color: "var(--color-orange-310)"
    }
  ];

  return (
    <motion.div
      className={styles.container}
      variants={ANIMATION_VARIANTS.stagger.container}
      initial="hidden"
      animate="visible"
    >
      <motion.div 
        className={styles.header}
        variants={ANIMATION_VARIANTS.fadeIn}
      >
        <h1 className={styles.title}>{t('investor_pitch.intro.title')}</h1>
        <p className={styles.subtitle}>
          {t('investor_pitch.intro.subtitle')}
        </p>
      </motion.div>

      <motion.div 
        className={styles.statsGrid}
        variants={ANIMATION_VARIANTS.stagger.container}
      >
        {stats.map((stat, index) => (
          <motion.div
            key={stat.title}
            variants={ANIMATION_VARIANTS.stagger.item}
            custom={index}
          >
            <StatCard {...stat} />
          </motion.div>
        ))}
      </motion.div>
    </motion.div>
  );
};

export default IntroSlide;