// File: src/components/routing/LocalizedRoutes.js

import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { SUPPORTED_LOCALES, DEFAULT_LOCALE } from '../../config/i18n.config';
import MainLayout from '../layout/MainLayout/MainLayout';

// Import your page components
import Home from '../../pages/Home/Home';
import About from '../../pages/About/About';
import AIAgents from '../../pages/AIAgents/AIAgents';
import AllegoryApp from '../../pages/AllegoryApp/AllegoryApp';
import Blog from '../../pages/Blog/Blog';
import Capabilities from '../../pages/Capabilities/Capabilities';
import Compliance from '../../pages/Compliance/Compliance';
import Contact from '../../pages/Contact/Contact';
import Insurance from '../../pages/Insurance/Insurance';
import Join from '../../pages/Join/Join';
import News from '../../pages/News/News';
import ArticleDetail from '../../pages/ArticleDetail/ArticleDetail';
import PrivacyChoices from '../../pages/PrivacyChoices/PrivacyChoices';
import PrivacyPolicy from '../../pages/PrivacyPolicy/PrivacyPolicy';
import Support from '../../pages/Support/Support';
import SupportedCountries from '../../pages/SupportedCountries/SupportedCountries';
import TermsOfService from '../../pages/TermsOfService/TermsOfService';
import Quote from '../../pages/Quote/Quote';
// import UsagePolicy from '../../pages/UsagePolicy/UsagePolicy';

const LocalizedRoutes = () => {
  return [
    // Root redirect
    <Route 
      key="root"
      index 
      element={<Navigate to={`/${DEFAULT_LOCALE}`} replace />} 
    />,

    // Localized routes for each locale
    ...Object.keys(SUPPORTED_LOCALES).map(locale => (
      <Route key={locale} path={locale}>
        {/* Home page (index) */}
        <Route
          index
          element={
            <MainLayout>
              <Home />
            </MainLayout>
          }
        />

        {/* Quote */}
        <Route
          path="quote"
          element={
            <MainLayout>
              <Quote />
            </MainLayout>
          }
        />

        {/* Quote with ID - Make sure this is properly configured */}
        <Route
          path="quote/:quoteId"
          element={
            <MainLayout>
              <Quote />
            </MainLayout>
          }
        />

        {/* About */}
        <Route
          path="about"
          element={
            <MainLayout>
              <About />
            </MainLayout>
          }
        />

        {/* AI Agents */}
        <Route
          path="ai-agents"
          element={
            <MainLayout>
              <AIAgents />
            </MainLayout>
          }
        />

        {/* Allegory App */}
        <Route
          path="allegory-app"
          element={
            <MainLayout>
              <AllegoryApp />
            </MainLayout>
          }
        />

        {/* Blog */}
        <Route
          path="blog"
          element={
            <MainLayout>
              <Blog />
            </MainLayout>
          }
        />

        {/* Capabilities */}
        <Route
          path="capabilities"
          element={
            <MainLayout>
              <Capabilities />
            </MainLayout>
          }
        />

        {/* Compliance */}
        <Route
          path="compliance"
          element={
            <MainLayout>
              <Compliance />
            </MainLayout>
          }
        />

        {/* Contact */}
        <Route
          path="contact"
          element={
            <MainLayout>
              <Contact />
            </MainLayout>
          }
        />

        {/* Insurance */}
        <Route
          path="insurance"
          element={
            <MainLayout>
              <Insurance />
            </MainLayout>
          }
        />

        {/* Join */}
        <Route
          path="join"
          element={
            <MainLayout>
              <Join />
            </MainLayout>
          }
        />

        {/* News (with nested routes) */}
        <Route path="news">
          <Route
            index
            element={
              <MainLayout>
                <News />
              </MainLayout>
            }
          />
          <Route
            path=":slug"
            element={
              <MainLayout>
                <ArticleDetail />
              </MainLayout>
            }
          />
        </Route>

        {/* Privacy routes */}
        <Route
          path="privacy-choices"
          element={
            <MainLayout>
              <PrivacyChoices />
            </MainLayout>
          }
        />

        <Route
          path="privacy-policy"
          element={
            <MainLayout>
              <PrivacyPolicy />
            </MainLayout>
          }
        />

        {/* Support */}
        <Route
          path="support"
          element={
            <MainLayout>
              <Support />
            </MainLayout>
          }
        />

        {/* Supported Countries */}
        <Route
          path="supported-countries"
          element={
            <MainLayout>
              <SupportedCountries />
            </MainLayout>
          }
        />

        {/* Terms of Service */}
        <Route
          path="terms-of-service"
          element={
            <MainLayout>
              <TermsOfService />
            </MainLayout>
          }
        />

        {/* Usage Policy */}
        {/* <Route
          path="usage-policy"
          element={
            <MainLayout>
              <UsagePolicy />
            </MainLayout>
          }
        /> */}
      </Route>
    ))
  ];
};

export default LocalizedRoutes;