// File: frontend/src/components/routing/RouteWrappers.js

import ProtectedRoute from './ProtectedRoute';
import DashboardLayout from '../layout/DashboardLayout/DashboardLayout';

export const ProtectedDashboardRoute = ({ children, requiredRole }) => (
  <ProtectedRoute requiredRole={requiredRole}>
    <DashboardLayout>{children}</DashboardLayout>
  </ProtectedRoute>
);

export const AdminRoute = ({ children }) => (
  <ProtectedDashboardRoute requiredRole="admin">
    {children}
  </ProtectedDashboardRoute>
);

export const SuperAdminRoute = ({ children }) => (
  <ProtectedDashboardRoute requiredRole="super_admin">
    {children}
  </ProtectedDashboardRoute>
);