// File: frontend/src/components/DrivingScore/DrivingScoreTable.js

import React from 'react';
import Card from '../Card/Card';
import styles from './DrivingScoreTable.module.css';

const DrivingScoreTable = () => {
  const tableData = [
    { decile: 1, users: 130, risk: 'Very Low Risk' },
    { decile: 2, users: 100, risk: 'Very Low Risk' },
    { decile: 3, users: 60, risk: 'Low Risk' },
    { decile: 4, users: 50, risk: 'Low Risk' },
    { decile: 5, users: 30, risk: 'Medium Risk' },
    { decile: 6, users: 20, risk: 'Medium Risk' },
    { decile: 7, users: 10, risk: 'High Risk' },
    { decile: 8, users: 5, risk: 'High Risk' },
    { decile: 9, users: 2, risk: 'Very High Risk' },
    { decile: 10, users: 0, risk: 'Very High Risk' },
  ];

  return (
    <Card
      title="Driving Score Table"
      subtitle="Compare risk patterns and accident probabilities across different driving score deciles."
    >
      <p className={styles.tableDescription}>
        Users in the same decile have similar risk patterns and accident probabilities.
      </p>
      <div className={styles.frameParent}>
        <div className={styles.frameGroup}>
          <div className={styles.frameWrapper}>
            <div className={styles.decileWrapper}>
              <div className={styles.decile}>Decile</div>
            </div>
          </div>
          <div className={styles.frameWrapper}>
            <div className={styles.decileWrapper}>
              <div className={styles.decile}>#User</div>
            </div>
          </div>
        </div>
        <div className={styles.frameDiv}>
          <div className={styles.frameParent1}>
            <div className={styles.decileWrapper}>
              <div className={styles.decile}>Top 10%</div>
            </div>
            <div className={styles.decileWrapper}>
              <div className={styles.decile}>Bottom 10%</div>
            </div>
          </div>
          <div className={styles.frameParent2}>
            {tableData.map((row, index) => (
              <div key={index} className={styles.frameParent3}>
                <div className={styles.frameParent4}>
                  <div className={styles.instanceParent}>
                    <div className={styles.scoreWrapper}>
                      <div className={styles.decile}>{row.decile}</div>
                    </div>
                    <div className={styles.tableItem}>
                      <div className={styles.veryLowRisk}>{row.users}</div>
                    </div>
                  </div>
                </div>
                <div className={styles.widgetTableItem}>
                  <div className={styles.riskLevelGroup}>
                    <img className={styles.riskLevelDotStatus} alt="" src={`/icons/risk-level-${row.risk.toLowerCase().replace(' ', '-')}.svg`} />
                    <div className={styles.veryLowRisk}>{row.risk}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default DrivingScoreTable;