// File: frontend/src/pages/Status/components/AwsMetrics.js

import React from 'react';
import styles from './AwsMetrics.module.css';

const AwsMetrics = ({ metrics, isLoading }) => {
  if (isLoading) {
    return <div className={styles.loading}>Loading AWS metrics...</div>;
  }

  // Add more specific checks
  if (!metrics?.aws) {
    return (
      <div className={styles.container}>
        <h2 className={styles.title}>AWS Services</h2>
        <div className={styles.noData}>
          AWS metrics are currently unavailable
        </div>
      </div>
    );
  }

  const formatNumber = (num, decimals = 2) => {
    if (num === undefined || num === null) return 'N/A';
    return Number(num).toFixed(decimals);
  };

  const formatPercentage = (value) => {
    if (value === undefined || value === null) return 'N/A';
    return `${formatNumber(value)}%`;
  };

  const getStatusClass = (status) => {
    switch (status?.toUpperCase()) {
      case 'OK':
        return styles.statusOk;
      case 'DEGRADED':
        return styles.statusDegraded;
      case 'ERROR':
        return styles.statusError;
      default:
        return styles.statusUnknown;
    }
  };

  const renderLambdaFunctions = () => {
    const functions = metrics.aws?.lambda?.functions;
    if (!functions?.length) {
        return (
            <div className={styles.noData}>
                No Lambda function metrics available
            </div>
        );
    }
  
    return (
      <div className={styles.lambdaFunctions}>
        <h3>Lambda Functions ({functions.length})</h3>
        <div className={styles.functionsList}>
          {functions.map((func) => (
            <div key={func.name} className={styles.functionCard}>
              <div className={styles.functionHeader}>
                <div className={styles.functionName}>
                  <span className={styles.displayName}>{func.displayName}</span>
                  <span className={`${styles.status} ${getStatusClass(func.status)}`}>
                    {func.status}
                  </span>
                </div>
                <div className={styles.functionGroup}>{func.groupName}</div>
              </div>
              <div className={styles.functionMetrics}>
                <div className={styles.metric}>
                  <span>Error Rate</span>
                  <span className={func.metrics.errorRate > 5 ? styles.error : ''}>
                    {formatPercentage(func.metrics.errorRate)}
                  </span>
                </div>
                <div className={styles.metric}>
                  <span>Avg Duration</span>
                  <span>{formatNumber(func.metrics.averageDuration)}ms</span>
                </div>
                {/* <div className={styles.metric}>
                  <span>Invocations</span>
                  <span>{func.metrics.totalInvocations}</span>
                </div> */}
                <div className={styles.metric}>
                  <span>Errors</span>
                  <span>{func.metrics.totalErrors}</span>
                </div>
              </div>
              {func.description && (
                <div className={styles.functionDescription}>
                  {func.description}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderApiGateway = () => {
    if (!metrics.aws.apiGateway) return null;

    return (
      <div className={styles.serviceMetrics}>
        <h3>API Gateway</h3>
        <div className={styles.metricsGrid}>
          <div className={styles.metricCard}>
            <span className={styles.metricLabel}>Status</span>
            <span className={`${styles.status} ${getStatusClass(metrics.aws.apiGateway.status)}`}>
              {metrics.aws.apiGateway.status}
            </span>
          </div>
          <div className={styles.metricCard}>
            <span className={styles.metricLabel}>Error Rate</span>
            <span className={styles.metricValue}>
              {formatPercentage(metrics.aws.apiGateway.metrics?.errorRate)}
            </span>
          </div>
          <div className={styles.metricCard}>
            <span className={styles.metricLabel}>Average Latency</span>
            <span className={styles.metricValue}>
              {formatNumber(metrics.aws.apiGateway.metrics?.averageLatency)}ms
            </span>
          </div>
          <div className={styles.metricCard}>
            <span className={styles.metricLabel}>Total Requests</span>
            <span className={styles.metricValue}>
              {metrics.aws.apiGateway.metrics?.totalRequests || 0}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderWaf = () => {
    if (!metrics.aws.waf) return null;

    return (
      <div className={styles.serviceMetrics}>
        <h3>WAF</h3>
        <div className={styles.metricsGrid}>
          <div className={styles.metricCard}>
            <span className={styles.metricLabel}>Status</span>
            <span className={`${styles.status} ${getStatusClass(metrics.aws.waf.status)}`}>
              {metrics.aws.waf.status}
            </span>
          </div>
          <div className={styles.metricCard}>
            <span className={styles.metricLabel}>Block Rate</span>
            <span className={styles.metricValue}>
              {formatPercentage(metrics.aws.waf.metrics?.blockRate)}
            </span>
          </div>
          <div className={styles.metricCard}>
            <span className={styles.metricLabel}>Total Requests</span>
            <span className={styles.metricValue}>
              {metrics.aws.waf.metrics?.totalRequests || 0}
            </span>
          </div>
          <div className={styles.metricCard}>
            <span className={styles.metricLabel}>Blocked Requests</span>
            <span className={styles.metricValue}>
              {metrics.aws.waf.metrics?.blockedRequests || 0}
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>AWS Services</h2>
      {renderLambdaFunctions()}
      {renderApiGateway()}
      {renderWaf()}
    </div>
  );
};

export default AwsMetrics;