// data/hiringData.js
import { 
    DollarSign, Award, Gift, 
    BookOpen, Shield, Coins, 
    Database, Code, Brain,
    Zap, Calculator, TrendingUp,
    Users
  } from 'lucide-react';
  
  export const locations = [
    {
      city: 'Toronto',
      type: 'On-site',
      coordinates: { lat: 43.6532, lng: -79.3832 },
      teamSize: '15+ members',
      timeZone: 'EST (UTC-5)',
      gradient: 'blue'
    },
    {
      city: 'New York',
      type: 'Hybrid & On-site',
      coordinates: { lat: 40.7128, lng: -74.0060 },
      teamSize: '25+ members',
      timeZone: 'EST (UTC-5)',
      gradient: 'green'
    },
    {
      city: 'San Francisco',
      type: 'Hybrid & On-site',
      coordinates: { lat: 37.7749, lng: -122.4194 },
      teamSize: '10+ members',
      timeZone: 'PST (UTC-8)',
      gradient: 'orange'
    }
  ];
  
  export const positions = [
    {
      title: 'Actuarial Science',
      icon: <BookOpen size={20} />,
      description: 'US/Canada licensed; non-life, life, pension',
      requirements: ['FSA/FCAS certification', '5+ years experience', 'Pricing expertise'],
      gradient: 'blue',
      category: 'Risk'
    },
    {
      title: 'Risk Management',
      icon: <Shield size={20} />,
      description: 'Portfolio management > $0.5B',
      requirements: ['CFA/FRM', '7+ years experience', 'Portfolio optimization'],
      gradient: 'green',
      category: 'Risk'
    },
    {
      title: 'Asset Management',
      icon: <Coins size={20} />,
      description: 'CAT bonds, longevity-indexed vehicles',
      requirements: ['CFA Level III', '5+ years experience', 'Alternative investments'],
      gradient: 'orange',
      category: 'Finance'
    },
    {
      title: 'Data Science',
      icon: <Database size={20} />,
      description: 'Deep understanding of probability theory',
      requirements: ['PhD preferred', 'ML expertise', 'Statistical modeling'],
      gradient: 'blue',
      category: 'Tech'
    },
    {
      title: 'Software Engineering',
      icon: <Code size={20} />,
      description: 'Full-stack development expertise',
      requirements: ['Backend', 'Frontend', 'Infrastructure'],
      gradient: 'green',
      category: 'Tech'
    },
    {
      title: 'AI/ML Research',
      icon: <Brain size={20} />,
      description: 'Advanced machine learning research',
      requirements: ['PhD in AI/ML', 'Research publications', 'Model development'],
      gradient: 'orange',
      category: 'Research'
    },
    {
      title: 'Physics',
      icon: <Zap size={20} />,
      description: 'Observation and hypothesis validation',
      requirements: ['PhD in Physics', 'Research experience', 'Modeling expertise'],
      gradient: 'blue',
      category: 'Research'
    },
    {
      title: 'Mathematics',
      icon: <Calculator size={20} />,
      description: 'Logic, reasoning, and real-world models',
      requirements: ['PhD in Mathematics', 'Probability theory', 'Statistical analysis'],
      gradient: 'green',
      category: 'Research'
    },
    {
      title: 'Economics',
      icon: <TrendingUp size={20} />,
      description: 'Macro/micro expertise, game theory',
      requirements: ['PhD in Economics', 'Risk modeling', 'Game theory'],
      gradient: 'orange',
      category: 'Research'
    },
    {
      title: 'Revenue Leadership',
      icon: <Users size={20} />,
      description: 'Scale to $1B+ revenue in three years',
      requirements: ['15+ years experience', 'Previous exits', 'Team building'],
      gradient: 'blue',
      category: 'Leadership'
    }
  ];
  
  export const compensationItems = [
    {
      title: 'Base Salary',
      icon: <DollarSign size={24} />,
      range: '$250,000 - $600,000',
      details: 'Annual compensation',
      highlights: [
        'Competitive market rates',
        'Regular performance reviews',
        'Merit-based increases'
      ]
    },
    {
      title: 'Equity',
      icon: <Award size={24} />,
      range: '0.1% - 1.5%',
      details: 'One-year cliff, four-year vesting',
      highlights: [
        'Stock options',
        'Performance bonuses',
        'Long-term incentives'
      ]
    },
    {
      title: 'Benefits',
      icon: <Gift size={24} />,
      range: 'Comprehensive Package',
      details: 'Collaborative environment with top-tier professionals',
      highlights: [
        'Health & wellness',
        'Learning & development',
        'Flexible work arrangements'
      ]
    }
  ];
  
  // Animation variants for Framer Motion
  export const animationVariants = {
    fadeIn: {
      initial: { opacity: 0, y: 20 },
      animate: { opacity: 1, y: 0 },
      exit: { opacity: 0, y: -20 }
    },
    slideIn: {
      initial: { opacity: 0, x: -20 },
      animate: { opacity: 1, x: 0 },
      exit: { opacity: 0, x: 20 }
    },
    scale: {
      initial: { opacity: 0, scale: 0.8 },
      animate: { opacity: 1, scale: 1 },
      exit: { opacity: 0, scale: 0.8 }
    }
  };
  
  // Transition timings
  export const transitionTimings = {
    sections: 5000, // 5 seconds per section
    elements: {
      stagger: 0.1,
      duration: 0.5
    }
  };