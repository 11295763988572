// File: frontend/src/pages/Auth/SignInFailure.js

import React, { useState, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useLanguage } from '../../contexts/LanguageContext';
import AuthLayout from '../../components/layout/AuthLayout/AuthLayout';
import StatusScreen from '../../components/Auth/StatusScreen';
import styles from './SignInFailure.module.css';

const SignInFailure = () => {
  const { t } = useLanguage();
  const navigate = useNavigate();
  const location = useLocation();
  const [isRetrying, setIsRetrying] = useState(false);

  // Get error details from location state
  const { 
    errorCode = 'unknown',
    errorMessage = t('auth.signInFailure.defaultError'),
    timestamp = new Date().toISOString()
  } = location.state || {};

  const handleRetry = useCallback(async () => {
    setIsRetrying(true);
    // Add a small delay for better UX
    await new Promise(resolve => setTimeout(resolve, 500));
    navigate('/sign-in', { replace: true });
  }, [navigate]);

  const handleContact = useCallback(() => {
    window.open('/contact', '_blank');
  }, []);

  const formatTimestamp = (timestamp) => {
    return new Date(timestamp).toLocaleString(undefined, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    });
  };

  const additionalInfo = (
    <div className={styles.errorDetails}>
      <div className={styles.errorInfo}>
        <div className={styles.errorItem}>
          <span className={styles.errorLabel}>
            {t('auth.signInFailure.errorCodeLabel')}
          </span>
          <span className={styles.errorValue}>
            {errorCode}
          </span>
        </div>
        
        <div className={styles.errorItem}>
          <span className={styles.errorLabel}>
            {t('auth.signInFailure.timestampLabel')}
          </span>
          <span className={styles.errorValue}>
            {formatTimestamp(timestamp)}
          </span>
        </div>

        {errorMessage && (
          <div className={styles.errorMessage}>
            {errorMessage}
          </div>
        )}
      </div>

      <div className={styles.actionButtons}>
        <button 
          className={styles.retryButton}
          onClick={handleRetry}
          disabled={isRetrying}
        >
          {isRetrying ? (
            <>
              <div className={styles.spinner} />
              {t('auth.signInFailure.retrying')}
            </>
          ) : (
            t('auth.signInFailure.retry')
          )}
        </button>
        
        <button 
          className={styles.contactButton}
          onClick={handleContact}
        >
          {t('auth.signInFailure.contactSupport')}
        </button>
      </div>
    </div>
  );

  return (
    <AuthLayout
      title={t('error_title')}
      showHomeButton={true}
    >
      <div className={styles.signInFailureContainer}>
        <div className={styles.signInFailureContent}>
          <StatusScreen
            icon="/icons/18/alerts/error.svg"
            title={t('auth.signInFailure.title')}
            message={t('auth.signInFailure.message')}
            additionalInfo={additionalInfo}
            type="error"
          />
        </div>
      </div>
    </AuthLayout>
  );
};

export default React.memo(SignInFailure);