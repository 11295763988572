// File: frontend/src/pages/SuperAdmin/components/common/SuperAdminCard/index.js

import React from 'react';
import styles from './SuperAdminCard.module.css';

const SuperAdminCard = ({ title, value, icon, loading, color = 'primary', onClick }) => {
  return (
    <div 
      className={`${styles.card} ${styles[color]} ${onClick ? styles.clickable : ''}`}
      onClick={onClick}
    >
      <div className={styles.iconContainer}>
        <i className={`fas ${icon}`}></i>
      </div>
      <div className={styles.content}>
        <h3 className={styles.title}>{title}</h3>
        {loading ? (
          <div className={styles.loading}>
            <div className={styles.loadingBar}></div>
          </div>
        ) : (
          <div className={styles.value}>{value}</div>
        )}
      </div>
    </div>
  );
};

export default SuperAdminCard;