// File: frontend/src/pages/AutoInsight/VehicleDetail/index.js

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useLanguage } from '../../contexts/LanguageContext';
import VehicleHeader from '../AutoInsight/components/VehicleDetail/components/VehicleHeader';
import VehicleOverview from '../AutoInsight/components/VehicleDetail/components/VehicleOverview';
import VehicleImages from '../AutoInsight/components/VehicleDetail/components/VehicleImages';
import VehicleSafety from '../AutoInsight/components/VehicleDetail/components/VehicleSafety';
import VehicleUsage from '../AutoInsight/components/VehicleDetail/components/VehicleUsage';
import VehicleMaintenance from '../AutoInsight/components/VehicleDetail/components/VehicleMaintenance';
import VehicleTelematics from '../AutoInsight/components/VehicleDetail/components/VehicleTelematics';
import VehicleValuation from '../AutoInsight/components/VehicleDetail/components/VehicleValuation';
import VehicleOwnership from '../AutoInsight/components/VehicleDetail/components/VehicleOwnership';
import VehicleRecalls from '../AutoInsight/components/VehicleDetail/components/VehicleRecalls';
import VehicleClaims from '../AutoInsight/components/VehicleDetail/components/VehicleClaims';
import styles from '../AutoInsight/components/VehicleDetail/VehicleDetail.module.css';

const VehicleDetail = () => {
  const { vehicleId } = useParams();
  const navigate = useNavigate();
  const { t } = useLanguage();
  const [activeTab, setActiveTab] = useState('overview');
  const [vehicle, setVehicle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchVehicleDetails = async () => {
      setLoading(true);
      setError(null);
      
      try {
        // This would be replaced with an actual API call
        // const response = await vehicleService.getVehicleDetails(vehicleId);
        
        // Mock data for now
        await new Promise(resolve => setTimeout(resolve, 800));
        
        const mockVehicleData = {
          vehicle_id: vehicleId,
          vin: "1HGCM82633A123456",
          license_plate: "ABC123",
          make_model_year: "TOYOTA#CAMRY#2022",
          owner_id: "owner-12345",
          user_id: "user-12345",
          is_primary_vehicle: true,
          serial_number: "SN12345678",
          rspru_number: "RSPRU123456",
          vehicle_added_timestamp: "2023-01-15T10:30:00Z",
          created_at: "2023-01-15T10:30:00Z",
          updated_at: "2023-06-20T14:45:00Z",
          
          details: {
            vehicle_id: vehicleId,
            vicc_code: "VICC12345",
            vicc_code_ext: "VICC12345-EXT",
            vehicle_year: 2022,
            vehicle_make: "TOYOTA",
            vehicle_model: "CAMRY",
            vehicle_series_1: "XSE",
            vehicle_series_2: "",
            vehicle_trim_1: "Premium",
            vehicle_color: "White",
            vehicle_body_type: "Sedan",
            vehicle_type: "Car",
            vehicle_style: "4-door",
            vehicle_branded: false,
            vehicle_number_of_doors: 4,
            ibc_vehicle_use: "Personal",
            vehicle_engine_size: 2.5,
            vehicle_engine_unit: "L",
            vehicle_primary_fuel_type: "Gasoline",
            vehicle_drive_type: "FWD",
            vehicle_transmission_style: "Automatic",
            vehicle_gross_weight_rating_min: "Class 1",
            vehicle_gross_weight_rating_max: "Class 1",
            vehicle_curb_weight: 3400,
            vehicle_wheelbase: 112,
            vehicle_track_width: 62.2,
            vehicle_plant_country: "USA",
            vehicle_plant_city: "Georgetown",
            vehicle_plant_company: "Toyota Motor Manufacturing",
            vehicle_horsepower: 203,
            vehicle_engine_configuration: "Inline",
            vehicle_engine_cylinders: 4,
            vehicle_engine_model: "A25A-FKS",
            vehicle_valve_train_design: "DOHC",
            vehicle_fuel_delivery_type: "Direct Injection",
            vehicle_cooling_type: "Liquid",
            vehicle_turbo: false,
            vehicle_top_speed: 135,
            updated_at: "2023-06-20T14:45:00Z"
          },
          
          interior: {
            vehicle_id: vehicleId,
            interior_id: "int-12345",
            interior_color: "Black",
            interior_material: "Leather/Leatherette",
            interior_condition: "Excellent",
            interior_features: ["Heated Seats", "Power Adjustable Seats", "Infotainment System"],
            updated_at: "2023-01-15T10:30:00Z"
          },
          
          safety_features: {
            vehicle_id: vehicleId,
            vehicle_safety_id: "safety-12345",
            vehicle_winter_tires: true,
            vehicle_winter_tire_type: "Snow",
            vehicle_winter_tire_name: "Bridgestone Blizzak",
            vehicle_winter_tire_installed_date: "2022-11-15",
            vehicle_winter_tire_installed_by: "Tire Shop Inc.",
            vehicle_anti_theft_device: "Immobilizer",
            vehicle_airbag_type: "Full-System",
            vehicle_abs_brakes: true,
            vehicle_seat_belt_type: "3-Point",
            vehicle_antilock_braking_system_abs: "standard",
            vehicle_electronic_stability_control_esc: "standard",
            vehicle_traction_control: "standard",
            vehicle_tire_pressure_monitoring_system_type_tpms: "standard",
            vehicle_keyless_ignition: "standard",
            vehicle_event_data_recorder_edr: "standard",
            vehicle_daytime_running_light_drl: "standard",
            vehicle_auto_reverse_system: true,
            vehicle_pedestrian_alerting_sound: false,
            vehicle_headlamp_light_source: "LED",
            vehicle_semiautomatic_headlamp_switching: true,
            vehicle_automation_level_min: 1,
            vehicle_automation_level_max: 2,
            updated_at: "2023-06-20T14:45:00Z"
          },
          
          adas_features: {
            vehicle_id: vehicleId,
            vehicle_adas_id: "adas-12345",
            adaptive_cruise_control_acc: "standard",
            crash_imminent_braking_cib: "standard",
            blind_spot_warning_bsw: "standard",
            forward_collision_warning_fcw: "standard",
            lane_departure_warning_ldw: "standard",
            lane_keeping_assistance_lka: "standard",
            backup_camera: "standard",
            parking_assist: "standard",
            dynamic_brake_support_dbs: "standard",
            pedestrian_automatic_emergency_braking_paeb: "standard",
            automatic_crash_notification_acn: "standard",
            adaptive_driving_beam_adb: "not-available",
            rear_cross_traffic_alert: "standard",
            rear_automatic_emergency_braking: "standard",
            blind_spot_intervention_bsi: "not-available",
            lane_centering_assistance: "standard",
            updated_at: "2023-06-20T14:45:00Z"
          },
          
          modifications: {
            vehicle_id: vehicleId,
            vehicle_modification_id: "mod-12345",
            vehicle_modification_added_timestamp: "2023-03-10T09:15:00Z",
            vehicle_modified_customized: true,
            vehicle_modification_description: "Aftermarket exhaust system and tinted windows",
            updated_at: "2023-03-10T09:15:00Z"
          },
          
          usage: {
            vehicle_id: vehicleId,
            vehicle_usage_id: "usage-12345",
            vehicle_usage_entry_timestamp: "2023-06-01T08:30:00Z",
            vehicle_primary_use_type: "Personal",
            vehicle_secondary_use_type: "Commuting",
            vehicle_use_measurement_unit: "km",
            vehicle_personal_use_daily_distance: 35,
            vehicle_business_use_daily_distance: 0,
            vehicle_commercial_use_daily_distance: 0,
            vehicle_farm_use_daily_distance: 0,
            vehicle_personal_use_monthly_distance: 1050,
            vehicle_business_use_monthly_distance: 0,
            vehicle_commercial_use_monthly_distance: 0,
            vehicle_farm_use_monthly_distance: 0,
            vehicle_personal_use_annual_distance: 12600,
            vehicle_business_use_annual_distance: 0,
            vehicle_commercial_use_annual_distance: 0,
            vehicle_farm_use_annual_distance: 0,
            vehicle_personal_use_annual_distance_percentage: 100,
            vehicle_business_use_annual_distance_percentage: 0,
            vehicle_commercial_use_annual_distance_percentage: 0,
            vehicle_farm_use_annual_distance_percentage: 0,
            updated_at: "2023-06-01T08:30:00Z"
          },
          
          odometer_readings: [
            {
              vehicle_id: vehicleId,
              vehicle_odometer_id: "odo-12345-1",
              vehicle_odometer_timestamp: "2023-01-15T10:30:00Z",
              vehicle_odometer_source: "User Input",
              vehicle_odometer_source_confidence_level: 80,
              vehicle_odometer_measurement_unit: "km",
              vehicle_odometer_value: 45000,
              updated_at: "2023-01-15T10:30:00Z"
            },
            {
              vehicle_id: vehicleId,
              vehicle_odometer_id: "odo-12345-2",
              vehicle_odometer_timestamp: "2023-03-20T14:15:00Z",
              vehicle_odometer_source: "Service Record",
              vehicle_odometer_source_confidence_level: 95,
              vehicle_odometer_measurement_unit: "km",
              vehicle_odometer_value: 49500,
              updated_at: "2023-03-20T14:15:00Z"
            },
            {
              vehicle_id: vehicleId,
              vehicle_odometer_id: "odo-12345-3",
              vehicle_odometer_timestamp: "2023-06-01T08:30:00Z",
              vehicle_odometer_source: "User Input",
              vehicle_odometer_source_confidence_level: 80,
              vehicle_odometer_measurement_unit: "km",
              vehicle_odometer_value: 53545,
              updated_at: "2023-06-01T08:30:00Z"
            }
          ],
          
          maintenance: [
            {
              vehicle_id: vehicleId,
              maintenance_id: "maint-12345-1",
              maintenance_date: "2023-03-20",
              maintenance_type: "Oil Change",
              description: "Regular oil change and filter replacement",
              mileage: 49500,
              cost: 89.99,
              cost_currency: "USD",
              service_provider: "Toyota Service Center",
              next_maintenance_date: "2023-09-20",
              updated_at: "2023-03-20T14:15:00Z"
            },
            {
              vehicle_id: vehicleId,
              maintenance_id: "maint-12345-2",
              maintenance_date: "2023-06-15",
              maintenance_type: "Tire Rotation",
              description: "Tire rotation and pressure check",
              mileage: 53000,
              cost: 49.99,
              cost_currency: "USD",
              service_provider: "Toyota Service Center",
              next_maintenance_date: "2023-12-15",
              updated_at: "2023-06-15T11:30:00Z"
            }
          ],
          
          telematics: [
            {
              telematics_id: "telem-12345-1",
              vehicle_id: vehicleId,
              device_id: "device-12345",
              trip_id: "trip-12345-1",
              user_id: "user-12345",
              chunk_number: 1,
              timestamp: "2023-06-01T08:30:00Z",
              latitude: 37.7749,
              longitude: -122.4194,
              speed: 45.5,
              acceleration: 0.2,
              deceleration: 0,
              heading: 90,
              altitude: 15,
              distance_traveled: 5.2,
              fuel_consumption: 7.8,
              battery_level: null,
              engine_rpm: 2100,
              engine_temperature: 90,
              tire_pressure: {
                front_left: 32,
                front_right: 32,
                rear_left: 32,
                rear_right: 32
              },
              updated_at: "2023-06-01T08:30:00Z"
            }
          ],
          
          market_intel: {
            vehicle_id: vehicleId,
            vehicle_market_intel_id: "intel-12345",
            vehicle_market_intel_timestamp: "2023-06-20T14:45:00Z",
            vehicle_market_intel: {
              market_value: 28500,
              depreciation_rate: 12,
              market_demand: "High",
              popularity_index: 85
            },
            updated_at: "2023-06-20T14:45:00Z"
          },
          
          valuation: [
            {
              vehicle_id: vehicleId,
              vehicle_price_id: "price-12345-1",
              price_timestamp: "2023-01-15T10:30:00Z",
              price_type: "MSRP",
              price: 32000,
              price_currency: "USD",
              price_source: "Manufacturer",
              updated_at: "2023-01-15T10:30:00Z"
            },
            {
              vehicle_id: vehicleId,
              vehicle_price_id: "price-12345-2",
              price_timestamp: "2023-06-20T14:45:00Z",
              price_type: "Current Market Value",
              price: 28500,
              price_currency: "USD",
              price_source: "KBB",
              updated_at: "2023-06-20T14:45:00Z"
            }
          ],
          
          purchase: {
            vehicle_id: vehicleId,
            vehicle_purchase_id: "purch-12345",
            purchase_type: "New",
            purchase_price: 32000,
            purchase_date: "2023-01-10",
            seller_name: "Toyota Dealership",
            seller_type: "Dealer",
            financing_type: "Loan",
            financing_term: 60,
            down_payment: 5000,
            updated_at: "2023-01-15T10:30:00Z"
          },
          
          ownership: {
            vehicle_id: vehicleId,
            vehicle_registered_owner_id: "owner-12345",
            vehicle_owner_entry_timestamp: "2023-01-15T10:30:00Z",
            vehicle_registered_owners: [
              {
                owner_id: "owner-12345",
                owner_name: "John Doe",
                relationship: "primary"
              }
            ],
            vehicle_lienholders: [
              {
                lienholder_id: "lien-12345",
                lienholder_name: "Toyota Financial Services",
                lien_amount: 27000,
                lien_start_date: "2023-01-10"
              }
            ],
            vehicle_listed_drivers: [
              {
                driver_id: "driver-12345-1",
                driver_name: "John Doe",
                relationship: "primary"
              },
              {
                driver_id: "driver-12345-2",
                driver_name: "Jane Doe",
                relationship: "spouse"
              }
            ],
            updated_at: "2023-01-15T10:30:00Z"
          },
          
          images: [
            {
              vehicle_id: vehicleId,
              vehicle_image_id: "img-12345-1",
              vehicle_image_timestamp: "2023-01-15T10:30:00Z",
              vehicle_image_source: "user",
              vehicle_image_url: "/images/toyota_camry_sample_1.jpg",
              vehicle_image_ai_assessment: {
                condition: "Excellent",
                damage_detected: false,
                confidence: 0.95,
                detailed_report: `## Vehicle Details
        - **Year**: 2020-2022
        - **Make**: Toyota
        - **Model**: Camry
        - **Trim**: XSE
        - **Color**: White
        - **Mileage**: 53,545 km
        
        ## Damage Assessment Summary
        Based on the provided images, no visible damage was detected on this Toyota Camry. The vehicle appears to be in good condition with no signs of exterior or interior damage in the visible areas.
        
        ### Exterior Condition
        - **Front End**: No visible damage to the front bumper, hood, or front fenders
        - **Doors & Side Panels**: No visible damage
        - **Wheels & Tires**: Appear to be in good condition
        - **Glass & Lights**: No visible damage or cracks
        
        ### Interior Condition
        - **Dashboard & Instruments**: In good condition, no visible damage
        - **Seats**: Black leather/leatherette upholstery in good condition
        - **Door Panels**: No visible damage
        - **Rear Seating Area**: Clean and undamaged
        
        ## Repair Recommendations
        No repairs needed based on the visible areas of the vehicle.
        
        ## Safety Considerations
        No safety concerns identified from the provided images.
        
        ## Limitations & Additional Recommendations
        Please note that this assessment is limited by the partial views provided in the images. The following areas were not fully visible and should be inspected:
        - Rear of the vehicle (trunk and rear bumper)
        - Complete roof view
        - Rear quarter panels
        - Undercarriage
        
        A complete walk-around inspection is recommended to confirm the absence of damage on all sides of the vehicle.`
              },
              updated_at: "2023-01-15T10:30:00Z"
            },
            {
              vehicle_id: vehicleId,
              vehicle_image_id: "img-12345-2",
              vehicle_image_timestamp: "2023-01-15T10:35:00Z",
              vehicle_image_source: "user",
              vehicle_image_url: "/images/toyota_camry_sample_2.jpg",
              vehicle_image_ai_assessment: {
                condition: "Excellent",
                damage_detected: false,
                confidence: 0.92,
                detailed_report: `## Vehicle Details
        - **Year**: 2020-2022
        - **Make**: Toyota
        - **Model**: Camry
        - **Trim**: XSE
        - **Color**: White
        - **Mileage**: 53,545 km
        
        ## Damage Assessment Summary
        Based on the provided images, no visible damage was detected on this Toyota Camry. The vehicle appears to be in good condition with no signs of exterior or interior damage in the visible areas.
        
        ### Exterior Condition
        - **Front End**: No visible damage to the front bumper, hood, or front fenders
        - **Doors & Side Panels**: No visible damage
        - **Wheels & Tires**: Appear to be in good condition
        - **Glass & Lights**: No visible damage or cracks
        
        ### Interior Condition
        - **Dashboard & Instruments**: In good condition, no visible damage
        - **Seats**: Black leather/leatherette upholstery in good condition
        - **Door Panels**: No visible damage
        - **Rear Seating Area**: Clean and undamaged
        
        ## Repair Recommendations
        No repairs needed based on the visible areas of the vehicle.`
              },
              updated_at: "2023-01-15T10:35:00Z"
            },
            {
              vehicle_id: vehicleId,
              vehicle_image_id: "img-12345-3",
              vehicle_image_timestamp: "2023-01-15T10:40:00Z",
              vehicle_image_source: "user",
              vehicle_image_url: "/images/toyota_camry_sample_3.jpg",
              vehicle_image_ai_assessment: {
                condition: "Good",
                damage_detected: false,
                confidence: 0.89,
                detailed_report: `## Vehicle Details
        - **Year**: 2020-2022
        - **Make**: Toyota
        - **Model**: Camry
        - **Trim**: XSE
        - **Color**: White
        - **Mileage**: 53,545 km
        
        ## Damage Assessment Summary
        Based on the provided images, no visible damage was detected on this Toyota Camry. The vehicle appears to be in good condition with no signs of exterior or interior damage in the visible areas.
        
        ## Safety Considerations
        No safety concerns identified from the provided images.
        
        ## Limitations & Additional Recommendations
        Please note that this assessment is limited by the partial views provided in the images. The following areas were not fully visible and should be inspected:
        - Rear of the vehicle (trunk and rear bumper)
        - Complete roof view
        - Rear quarter panels
        - Undercarriage
        
        A complete walk-around inspection is recommended to confirm the absence of damage on all sides of the vehicle.`
              },
              updated_at: "2023-01-15T10:40:00Z"
            }
          ],
          
          risk: {
            vehicle_id: vehicleId,
            vehicle_risk_group_id: "risk-12345",
            vehicle_risk_group_state: "CA",
            vehicle_risk_group_basis: "Make/Model/Year",
            vehicle_risk_group_base_date: "2023-01-01",
            vehicle_risk_group_for_accident_benefit: "A",
            vehicle_risk_group_for_collision: "B",
            vehicle_risk_group_for_comprehensive: "C",
            vehicle_risk_group_for_liability: "B",
            vehicle_risk_group_for_dcpd: "B",
            updated_at: "2023-01-15T10:30:00Z"
          },
          
          claims: [
            {
              claim_id: "claim-12345-1",
              vehicle_id: vehicleId,
              claim_date: "2023-05-10",
              claim_status: "Closed",
              claim_type: "Comprehensive",
              claim_description: "Windshield damage from road debris",
              claim_amount: 750,
              claim_currency: "USD",
              updated_at: "2023-05-15T09:20:00Z"
            }
          ],
          
          policies: [
            {
              vehicle_id: vehicleId,
              policy_id: "policy-12345",
              policy_start_date: "2023-01-15",
              policy_end_date: "2024-01-15",
              policy_status: "Active",
              updated_at: "2023-01-15T10:30:00Z"
            }
          ],
          
          feedback: {
            vehicle_id: vehicleId,
            feedback_timestamp: "2023-06-01T08:30:00Z",
            user_id: "user-12345",
            vehicle_user_feedback: true,
            vehicle_user_notes: "Great vehicle, very reliable and fuel efficient.",
            updated_at: "2023-06-01T08:30:00Z"
          },
          
          recalls: [
            {
              recall_id: "recall-12345",
              vehicle_id: vehicleId,
              nhtsa_campaign_number: "23V123",
              manufacturer_recall_number: "TYT2023-01",
              recall_date: "2023-04-15",
              recall_description: "Potential issue with brake actuator",
              recall_consequence: "May lead to increased stopping distance",
              recall_remedy: "Replace brake actuator assembly",
              recall_notes: "Affects certain 2022 models manufactured between Jan-Mar 2022",
              recall_component: "Brakes",
              recall_status: "REMEDY_AVAILABLE",
              remedy_date: "2023-05-01",
              affected_units: 5000,
              is_remedied_for_vehicle: true,
              remedy_date_for_vehicle: "2023-05-20",
              updated_at: "2023-05-20T13:45:00Z"
            }
          ]
        };
        
        setVehicle(mockVehicleData);
      } catch (err) {
        console.error('Error fetching vehicle details:', err);
        setError(t('failed_to_load_vehicle_details'));
      } finally {
        setLoading(false);
      }
    };

    if (vehicleId) {
      fetchVehicleDetails();
    }
  }, [vehicleId, t]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleBack = () => {
    navigate('/auto-insight');
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <div className={styles.loadingSpinner}></div>
        <p>{t('loading_vehicle_details')}</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.error}>
        <h2>{t('error_loading_vehicle')}</h2>
        <p>{error}</p>
        <button className={styles.backButton} onClick={handleBack}>
          {t('back_to_auto_insight')}
        </button>
      </div>
    );
  }

  if (!vehicle) {
    return (
      <div className={styles.notFound}>
        <h2>{t('vehicle_not_found')}</h2>
        <p>{t('vehicle_not_found_message')}</p>
        <button className={styles.backButton} onClick={handleBack}>
          {t('back_to_auto_insight')}
        </button>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <VehicleHeader vehicle={vehicle} onBack={handleBack} />
      
      <div className={styles.content}>
        <div className={styles.tabs}>
          <button 
            className={`${styles.tab} ${activeTab === 'overview' ? styles.activeTab : ''}`}
            onClick={() => handleTabChange('overview')}
          >
            {t('overview')}
          </button>
          <button 
            className={`${styles.tab} ${activeTab === 'images' ? styles.activeTab : ''}`}
            onClick={() => handleTabChange('images')}
          >
            {t('images')}
          </button>
          <button 
            className={`${styles.tab} ${activeTab === 'safety' ? styles.activeTab : ''}`}
            onClick={() => handleTabChange('safety')}
          >
            {t('safety')}
          </button>
          <button 
            className={`${styles.tab} ${activeTab === 'usage' ? styles.activeTab : ''}`}
            onClick={() => handleTabChange('usage')}
          >
            {t('usage')}
          </button>
          <button 
            className={`${styles.tab} ${activeTab === 'maintenance' ? styles.activeTab : ''}`}
            onClick={() => handleTabChange('maintenance')}
          >
            {t('maintenance')}
          </button>
          <button 
            className={`${styles.tab} ${activeTab === 'telematics' ? styles.activeTab : ''}`}
            onClick={() => handleTabChange('telematics')}
          >
            {t('telematics')}
          </button>
          <button 
            className={`${styles.tab} ${activeTab === 'valuation' ? styles.activeTab : ''}`}
            onClick={() => handleTabChange('valuation')}
          >
            {t('valuation')}
          </button>
          <button 
            className={`${styles.tab} ${activeTab === 'ownership' ? styles.activeTab : ''}`}
            onClick={() => handleTabChange('ownership')}
          >
            {t('ownership')}
          </button>
          <button 
            className={`${styles.tab} ${activeTab === 'recalls' ? styles.activeTab : ''}`}
            onClick={() => handleTabChange('recalls')}
          >
            {t('recalls')}
          </button>
          <button 
            className={`${styles.tab} ${activeTab === 'claims' ? styles.activeTab : ''}`}
            onClick={() => handleTabChange('claims')}
          >
            {t('claims')}
          </button>
        </div>
        
        <div className={styles.tabContent}>
          {activeTab === 'overview' && <VehicleOverview vehicle={vehicle} />}
          {activeTab === 'images' && <VehicleImages vehicle={vehicle} />}
          {activeTab === 'safety' && <VehicleSafety vehicle={vehicle} />}
          {activeTab === 'usage' && <VehicleUsage vehicle={vehicle} />}
          {activeTab === 'maintenance' && <VehicleMaintenance vehicle={vehicle} />}
          {activeTab === 'telematics' && <VehicleTelematics vehicle={vehicle} />}
          {activeTab === 'valuation' && <VehicleValuation vehicle={vehicle} />}
          {activeTab === 'ownership' && <VehicleOwnership vehicle={vehicle} />}
          {activeTab === 'recalls' && <VehicleRecalls vehicle={vehicle} />}
          {activeTab === 'claims' && <VehicleClaims vehicle={vehicle} />}
        </div>
      </div>
    </div>
  );
};

export default VehicleDetail;