// File: frontend/src/pages/EmbeddedInsurance/components/ChatbotIntegration/index.js

import React, { useState } from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import CodeBlock from '../common/CodeBlock';
import { User } from 'lucide-react'; 
import styles from './styles/ChatbotIntegration.module.css';

const ChatbotIntegration = ({
  selectedProduct,
  selectedCoverage,
  selectedJurisdiction
}) => {
  const { t } = useLanguage();
  const [activeTab, setActiveTab] = useState('overview');
  const [chatbotSettings, setChatbotSettings] = useState({
    theme: 'light',
    position: 'right',
    initialMessage: 'Hi there! How can I help you with your insurance needs today?',
    agentName: 'Insurance Assistant',
    // Just store "user-icon" as identifier for the embed code
    agentAvatar: 'user-icon'
  });

  const handleSettingChange = (setting, value) => {
    setChatbotSettings(prev => ({
      ...prev,
      [setting]: value
    }));
  };

  const getEmbedCode = () => {
    const productParam = selectedProduct ? `&product=${selectedProduct}` : '';
    const coverageParam = selectedCoverage ? `&coverage=${selectedCoverage}` : '';
    const jurisdictionParam = selectedJurisdiction ? `&jurisdiction=${selectedJurisdiction}` : '';
    
    return `<script>
  (function(w, d, s, o) {
    w.AllegoryChat = o;
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(o.id)) return;
    js = d.createElement(s); js.id = o.id;
    js.src = 'https://cdn.allegory.app/chat/v1/widget.js';
    js.async = 1;
    fjs.parentNode.insertBefore(js, fjs);
  }(window, document, 'script', {
    id: 'allegory-chat',
    apiKey: 'YOUR_API_KEY',
    theme: '${chatbotSettings.theme}',
    position: '${chatbotSettings.position}',
    initialMessage: '${chatbotSettings.initialMessage}',
    agentName: '${chatbotSettings.agentName}',
    agentAvatar: '${chatbotSettings.agentAvatar}'${productParam}${coverageParam}${jurisdictionParam}
  }));
</script>`;
  };

  const getApiCode = () => {
    return `// Initialize the chat session
const response = await fetch('https://api.allegory.app/v1/chat/session', {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer YOUR_API_KEY'
  },
  body: JSON.stringify({
    product: '${selectedProduct || 'device_protection'}',
    coverage: '${selectedCoverage || 'standard'}',
    jurisdiction: '${selectedJurisdiction || 'us-ca'}'
  })
});

const { session_id } = await response.json();

// Send a message to the AI agent
const messageResponse = await fetch('https://api.allegory.app/v1/chat/message', {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer YOUR_API_KEY'
  },
  body: JSON.stringify({
    session_id: session_id,
    message: "I'd like to get a quote for my new iPhone"
  })
});

const { reply } = await messageResponse.json();
console.log(reply); // AI agent's response`;
  };

  // Render the avatar wherever needed in the component
  const renderAgentAvatar = () => {
    return <User size={24} color="#FFF" />;
  };

  return (
    <div className={styles.chatbotIntegration}>
      <div className={styles.tabs}>
        <button
          className={`${styles.tab} ${activeTab === 'overview' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('overview')}
        >
          <i className="fas fa-info-circle"></i>
          <span>{t('overview') || 'Overview'}</span>
        </button>
        <button
          className={`${styles.tab} ${activeTab === 'embed' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('embed')}
        >
          <i className="fas fa-code"></i>
          <span>{t('embed_code') || 'Embed Code'}</span>
        </button>
        <button
          className={`${styles.tab} ${activeTab === 'api' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('api')}
        >
          <i className="fas fa-plug"></i>
          <span>{t('api_integration') || 'API Integration'}</span>
        </button>
        <button
          className={`${styles.tab} ${activeTab === 'customize' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('customize')}
        >
          <i className="fas fa-paint-brush"></i>
          <span>{t('customize') || 'Customize'}</span>
        </button>
      </div>

      <div className={styles.content}>
        {activeTab === 'overview' && (
          <div className={styles.overview}>
            {/* Overview header content */}
            <div className={styles.overviewHeader}>
              <h2 className={styles.overviewTitle}>{t('chatbot_integration_overview') || 'Chatbot Integration Overview'}</h2>
              <p className={styles.overviewDescription}>
                {t('chatbot_integration_description') || 'Integrate our AI-powered insurance chatbot into your website or application to provide instant quotes and policy management for your customers.'}
              </p>
            </div>

            {/* Feature grid */}
            <div className={styles.featureGrid}>
              {/* Feature cards */}
              <div className={styles.featureCard}>
                <div className={styles.featureIcon}>
                  <i className="fas fa-robot"></i>
                </div>
                <h3 className={styles.featureTitle}>{t('ai_powered_agents') || 'AI-Powered Agents'}</h3>
                <p className={styles.featureDescription}>
                  {t('ai_powered_agents_description') || 'Our chatbots use advanced AI to understand customer needs and provide personalized insurance recommendations.'}
                </p>
              </div>
              
              {/* Other feature cards */}
              <div className={styles.featureCard}>
                <div className={styles.featureIcon}>
                  <i className="fas fa-code"></i>
                </div>
                <h3 className={styles.featureTitle}>{t('easy_integration') || 'Easy Integration'}</h3>
                <p className={styles.featureDescription}>
                  {t('easy_integration_description') || 'Add our chatbot to your website with just a few lines of code. No complex setup required.'}
                </p>
              </div>
              
              <div className={styles.featureCard}>
                <div className={styles.featureIcon}>
                  <i className="fas fa-paint-brush"></i>
                </div>
                <h3 className={styles.featureTitle}>{t('fully_customizable') || 'Fully Customizable'}</h3>
                <p className={styles.featureDescription}>
                  {t('fully_customizable_description') || 'Customize the appearance and behavior of the chatbot to match your brand and specific insurance offerings.'}
                </p>
              </div>
              
              <div className={styles.featureCard}>
                <div className={styles.featureIcon}>
                  <i className="fas fa-chart-line"></i>
                </div>
                <h3 className={styles.featureTitle}>{t('analytics_insights') || 'Analytics & Insights'}</h3>
                <p className={styles.featureDescription}>
                  {t('analytics_insights_description') || 'Gain valuable insights into customer interactions and optimize your insurance offerings based on real data.'}
                </p>
              </div>
            </div>

            {/* Demo section */}
            <div className={styles.demoSection}>
              <h3 className={styles.demoTitle}>{t('see_it_in_action') || 'See It In Action'}</h3>
              <div className={styles.demoContainer}>
                <div className={styles.demoChat}>
                  <div className={styles.demoChatHeader}>
                    <div className={styles.agentAvatar}>
                      {renderAgentAvatar()}
                    </div>
                    <div className={styles.agentInfo}>
                      <div className={styles.agentName}>{chatbotSettings.agentName}</div>
                      <div className={styles.agentStatus}>{t('online') || 'Online'}</div>
                    </div>
                    <button className={styles.closeButton}>
                      <i className="fas fa-times"></i>
                    </button>
                  </div>
                  
                  {/* Chat messages */}
                  <div className={styles.demoChatMessages}>
                    <div className={styles.agentMessage}>
                      <div className={styles.messageContent}>
                        {chatbotSettings.initialMessage}
                      </div>
                      <div className={styles.messageTime}>12:01 PM</div>
                    </div>
                    
                    <div className={styles.userMessage}>
                      <div className={styles.messageContent}>
                        I need insurance for my new iPhone.
                      </div>
                      <div className={styles.messageTime}>12:02 PM</div>
                    </div>
                    
                    <div className={styles.agentMessage}>
                      <div className={styles.messageContent}>
                        Great! I can help you with device protection insurance for your iPhone. Could you tell me which model it is and when you purchased it?
                      </div>
                      <div className={styles.messageTime}>12:02 PM</div>
                    </div>
                    
                    <div className={styles.userMessage}>
                      <div className={styles.messageContent}>
                        It's an iPhone 13 Pro, I bought it last week.
                      </div>
                      <div className={styles.messageTime}>12:03 PM</div>
                    </div>
                    
                    <div className={styles.agentMessage}>
                      <div className={styles.messageContent}>
                        Thanks for that information! Based on your iPhone 13 Pro, I recommend our Standard Protection plan at $7.99/month. This includes accidental damage, theft protection, and next-day replacement. Would you like to see the full coverage details?
                      </div>
                      <div className={styles.messageTime}>12:03 PM</div>
                    </div>
                  </div>
                  
                  {/* Chat input */}
                  <div className={styles.demoChatInput}>
                    <input 
                      type="text" 
                      placeholder={t('type_your_message') || 'Type your message...'}
                      className={styles.chatInput}
                    />
                    <button className={styles.sendButton}>
                      <i className="fas fa-paper-plane"></i>
                    </button>
                  </div>
                </div>
                
                {/* Demo info */}
                <div className={styles.demoInfo}>
                  {/* Demo info content */}
                  <h4 className={styles.demoInfoTitle}>{t('how_it_works') || 'How It Works'}</h4>
                  <ol className={styles.demoSteps}>
                    <li className={styles.demoStep}>
                      <div className={styles.stepNumber}>1</div>
                      <div className={styles.stepContent}>
                        <h5 className={styles.stepTitle}>{t('customer_inquiry') || 'Customer Inquiry'}</h5>
                        <p className={styles.stepDescription}>
                          {t('customer_inquiry_description') || 'Customer initiates a conversation with the chatbot about their insurance needs.'}
                        </p>
                      </div>
                    </li>
                    <li className={styles.demoStep}>
                      <div className={styles.stepNumber}>2</div>
                      <div className={styles.stepContent}>
                        <h5 className={styles.stepTitle}>{t('ai_analysis') || 'AI Analysis'}</h5>
                        <p className={styles.stepDescription}>
                          {t('ai_analysis_description') || 'The AI analyzes the customer\'s needs and gathers relevant information through natural conversation.'}
                        </p>
                      </div>
                    </li>
                    <li className={styles.demoStep}>
                      <div className={styles.stepNumber}>3</div>
                      <div className={styles.stepContent}>
                        <h5 className={styles.stepTitle}>{t('personalized_recommendation') || 'Personalized Recommendation'}</h5>
                        <p className={styles.stepDescription}>
                          {t('personalized_recommendation_description') || 'Based on the information gathered, the AI recommends the most suitable insurance product.'}
                        </p>
                      </div>
                    </li>
                    <li className={styles.demoStep}>
                      <div className={styles.stepNumber}>4</div>
                      <div className={styles.stepContent}>
                        <h5 className={styles.stepTitle}>{t('seamless_purchase') || 'Seamless Purchase'}</h5>
                        <p className={styles.stepDescription}>
                          {t('seamless_purchase_description') || 'The customer can complete the purchase directly through the chat interface with minimal friction.'}
                        </p>
                      </div>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        )}

        {activeTab === 'embed' && (
          <div className={styles.embed}>
            {/* Embed code section */}
            <div className={styles.embedHeader}>
              <h2 className={styles.embedTitle}>{t('embed_chatbot') || 'Embed Chatbot'}</h2>
              <p className={styles.embedDescription}>
                {t('embed_chatbot_description') || 'Add our insurance chatbot to your website with this simple embed code. Just copy and paste it into your HTML.'}
              </p>
            </div>

            <div className={styles.embedCode}>
              <h3 className={styles.embedCodeTitle}>{t('copy_paste_code') || 'Copy & Paste This Code'}</h3>
              <CodeBlock 
                code={getEmbedCode()} 
                language="html"
                title={t('embed_code') || 'Embed Code'}
              />
            </div>

            <div className={styles.embedInstructions}>
              <h3 className={styles.embedInstructionsTitle}>{t('installation_instructions') || 'Installation Instructions'}</h3>
              <ol className={styles.instructionsList}>
                <li className={styles.instructionItem}>
                  <h4 className={styles.instructionTitle}>{t('get_api_key') || 'Get Your API Key'}</h4>
                  <p className={styles.instructionDescription}>
                    {t('get_api_key_description') || 'Sign in to your Allegory dashboard and get your API key from the Settings page.'}
                  </p>
                </li>
                <li className={styles.instructionItem}>
                  <h4 className={styles.instructionTitle}>{t('copy_code') || 'Copy the Code'}</h4>
                  <p className={styles.instructionDescription}>
                    {t('copy_code_description') || 'Copy the embed code above and replace YOUR_API_KEY with your actual API key.'}
                  </p>
                </li>
                <li className={styles.instructionItem}>
                  <h4 className={styles.instructionTitle}>{t('paste_before_body') || 'Paste Before </body>'}</h4>
                  <p className={styles.instructionDescription}>
                    {t('paste_before_body_description') || 'Paste the code into your website HTML, just before the closing </body> tag.'}
                  </p>
                </li>
                <li className={styles.instructionItem}>
                  <h4 className={styles.instructionTitle}>{t('test_integration') || 'Test the Integration'}</h4>
                  <p className={styles.instructionDescription}>
                    {t('test_integration_description') || 'Visit your website and check that the chatbot appears and functions correctly.'}
                  </p>
                </li>
              </ol>
            </div>
          </div>
        )}

        {activeTab === 'api' && (
          <div className={styles.api}>
            {/* API integration section */}
            <div className={styles.apiHeader}>
              <h2 className={styles.apiTitle}>{t('api_integration') || 'API Integration'}</h2>
              <p className={styles.apiDescription}>
                {t('api_integration_description') || 'For more control, integrate our chatbot using our API. This allows you to build custom interfaces and experiences.'}
              </p>
            </div>

            <div className={styles.apiCode}>
              <h3 className={styles.apiCodeTitle}>{t('sample_code') || 'Sample Code'}</h3>
              <CodeBlock 
                code={getApiCode()} 
                language="javascript"
                title={t('javascript_example') || 'JavaScript Example'}
              />
            </div>

            <div className={styles.apiEndpoints}>
              <h3 className={styles.apiEndpointsTitle}>{t('available_endpoints') || 'Available Endpoints'}</h3>
              
              <div className={styles.endpoint}>
                <div className={styles.endpointHeader}>
                  <div className={styles.endpointMethod}>POST</div>
                  <div className={styles.endpointPath}>/v1/chat/session</div>
                </div>
                <p className={styles.endpointDescription}>
                  {t('create_chat_session_description') || 'Create a new chat session with the AI agent.'}
                </p>
              </div>
              
              <div className={styles.endpoint}>
                <div className={styles.endpointHeader}>
                  <div className={styles.endpointMethod}>POST</div>
                  <div className={styles.endpointPath}>/v1/chat/message</div>
                </div>
                <p className={styles.endpointDescription}>
                  {t('send_message_description') || 'Send a message to the AI agent and receive a response.'}
                </p>
              </div>
              
              <div className={styles.endpoint}>
                <div className={styles.endpointHeader}>
                  <div className={styles.endpointMethod}>GET</div>
                  <div className={styles.endpointPath}>/v1/chat/session/{'{session_id}'}</div>
                </div>
                <p className={styles.endpointDescription}>
                  {t('get_session_description') || 'Get details about a specific chat session.'}
                </p>
              </div>
              
              <div className={styles.endpoint}>
                <div className={styles.endpointHeader}>
                  <div className={styles.endpointMethod}>GET</div>
                  <div className={styles.endpointPath}>/v1/chat/session/{'{session_id}'}/messages</div>
                </div>
                <p className={styles.endpointDescription}>
                  {t('get_messages_description') || 'Get all messages in a chat session.'}
                </p>
              </div>
            </div>
          </div>
        )}

        {activeTab === 'customize' && (
          <div className={styles.customize}>
            {/* Customize section */}
            <div className={styles.customizeHeader}>
              <h2 className={styles.customizeTitle}>{t('customize_chatbot') || 'Customize Chatbot'}</h2>
              <p className={styles.customizeDescription}>
                {t('customize_chatbot_description') || 'Customize the appearance and behavior of the chatbot to match your brand and specific needs.'}
              </p>
            </div>

            <div className={styles.customizeForm}>
              <div className={styles.formRow}>
                <div className={styles.formGroup}>
                  <label className={styles.formLabel}>{t('theme') || 'Theme'}</label>
                  <select
                    className={styles.formSelect}
                    value={chatbotSettings.theme}
                    onChange={(e) => handleSettingChange('theme', e.target.value)}
                  >
                    <option value="light">{t('light') || 'Light'}</option>
                    <option value="dark">{t('dark') || 'Dark'}</option>
                    <option value="blue">{t('blue') || 'Blue'}</option>
                    <option value="green">{t('green') || 'Green'}</option>
                  </select>
                </div>
                
                <div className={styles.formGroup}>
                  <label className={styles.formLabel}>{t('position') || 'Position'}</label>
                  <select
                    className={styles.formSelect}
                    value={chatbotSettings.position}
                    onChange={(e) => handleSettingChange('position', e.target.value)}
                  >
                    <option value="right">{t('bottom_right') || 'Bottom Right'}</option>
                    <option value="left">{t('bottom_left') || 'Bottom Left'}</option>
                    <option value="center">{t('bottom_center') || 'Bottom Center'}</option>
                  </select>
                </div>
              </div>
              
              <div className={styles.formGroup}>
                <label className={styles.formLabel}>{t('initial_message') || 'Initial Message'}</label>
                <input
                  type="text"
                  className={styles.formInput}
                  value={chatbotSettings.initialMessage}
                  onChange={(e) => handleSettingChange('initialMessage', e.target.value)}
                />
              </div>
              
              <div className={styles.formRow}>
                <div className={styles.formGroup}>
                  <label className={styles.formLabel}>{t('agent_name') || 'Agent Name'}</label>
                  <input
                    type="text"
                    className={styles.formInput}
                    value={chatbotSettings.agentName}
                    onChange={(e) => handleSettingChange('agentName', e.target.value)}
                  />
                </div>
                
                {/* Note: We're still keeping this for the widget code, but using icons in the UI */}
                <div className={styles.formGroup}>
                  <label className={styles.formLabel}>{t('agent_avatar') || 'Agent Avatar Type'}</label>
                  <select
                    className={styles.formSelect}
                    value={chatbotSettings.agentAvatar}
                    onChange={(e) => handleSettingChange('agentAvatar', e.target.value)}
                  >
                    <option value="user-icon">{t('user_icon') || 'User Icon'}</option>
                    <option value="robot-icon">{t('robot_icon') || 'Robot Icon'}</option>
                    <option value="support-icon">{t('support_icon') || 'Support Icon'}</option>
                  </select>
                </div>
              </div>
            </div>

            <div className={styles.previewSection}>
              <h3 className={styles.previewTitle}>{t('live_preview') || 'Live Preview'}</h3>
              <div className={styles.chatbotPreview}>
                <div className={`${styles.previewChat} ${styles[chatbotSettings.theme]}`}>
                  <div className={styles.previewChatHeader}>
                    <div className={styles.previewAgentAvatar}>
                      {renderAgentAvatar()}
                    </div>
                    <div className={styles.previewAgentInfo}>
                      <div className={styles.previewAgentName}>{chatbotSettings.agentName}</div>
                      <div className={styles.previewAgentStatus}>{t('online') || 'Online'}</div>
                    </div>
                    <button className={styles.previewCloseButton}>
                      <i className="fas fa-times"></i>
                    </button>
                  </div>
                  
                  <div className={styles.previewChatMessages}>
                    <div className={styles.previewAgentMessage}>
                      <div className={styles.previewMessageContent}>
                        {chatbotSettings.initialMessage}
                      </div>
                      <div className={styles.previewMessageTime}>Just now</div>
                    </div>
                  </div>
                  
                  <div className={styles.previewChatInput}>
                    <input 
                      type="text" 
                      placeholder={t('type_your_message') || 'Type your message...'}
                      className={styles.previewChatInputField}
                    />
                    <button className={styles.previewSendButton}>
                      <i className="fas fa-paper-plane"></i>
                    </button>
                  </div>
                </div>
                
                <div className={styles.previewButton} style={{ [chatbotSettings.position]: '20px' }}>
                  <div className={`${styles.chatButton} ${styles[chatbotSettings.theme]}`}>
                    <i className="fas fa-comment"></i>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.updatedCode}>
              <h3 className={styles.updatedCodeTitle}>{t('updated_embed_code') || 'Updated Embed Code'}</h3>
              <CodeBlock 
                code={getEmbedCode()} 
                language="html"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatbotIntegration;