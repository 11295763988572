// File: frontend/src/pages/TripDetail/components/TripScoring/index.js

import React, { useEffect, useRef, useState } from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import styles from './TripScoring.module.css';

const TripScoring = ({ trip }) => {
  const { t } = useLanguage();
  const chartRef = useRef(null);
  const [chartLoaded, setChartLoaded] = useState(false);
  const [chartError, setChartError] = useState(null);
  const [activeVersion, setActiveVersion] = useState('v2');

  useEffect(() => {
    // This would be replaced with actual chart initialization code
    const initializeChart = async () => {
      try {
        // Simulate chart loading
        await new Promise(resolve => setTimeout(resolve, 800));
        
        // Check if we have scoring data
        if (!trip.deciles || !trip.deciles.score_deciles) {
          setChartError(t('no_scoring_data_available'));
          return;
        }
        
        // In a real implementation, this is where you would:
        // 1. Initialize the chart library
        // 2. Create a radar chart with scoring factors
        // 3. Configure axes, legends, etc.
        
        setChartLoaded(true);
      } catch (error) {
        console.error('Error initializing chart:', error);
        setChartError(t('failed_to_load_scoring_chart'));
      }
    };

    if (trip && chartRef.current) {
      initializeChart();
    }
  }, [trip, t, activeVersion]);

  // Get available versions
  const getAvailableVersions = () => {
    if (!trip.deciles || !trip.deciles.score_deciles) {
      return [];
    }
    
    return Object.keys(trip.deciles.score_deciles);
  };

  const availableVersions = getAvailableVersions();

  // Get decile data for the active version
  const getDecileData = () => {
    if (!trip.deciles || !trip.deciles.score_deciles || !trip.deciles.score_deciles[activeVersion]) {
      return null;
    }
    
    return trip.deciles.score_deciles[activeVersion];
  };

  const decileData = getDecileData();

  // Get score data
  const getScoreData = () => {
    if (!trip) return [];
    
    const scores = [
      { name: 'overall_score', value: trip.overall_score, decile: decileData?.overall_score_decile },
      { name: 'acceleration_score', value: trip.acceleration_score, decile: decileData?.acceleration_score_decile },
      { name: 'braking_score', value: trip.braking_score, decile: decileData?.braking_score_decile },
      { name: 'speeding_score', value: trip.speeding_score, decile: decileData?.speeding_score_decile },
      { name: 'cornering_score', value: trip.cornering_score, decile: decileData?.cornering_score_decile }
    ];
    
    // Add v2 specific scores
    if (activeVersion === 'v2') {
      scores.push(
        { name: 'fatigue_driving_score', value: trip.fatigue_driving_score, decile: decileData?.fatigue_driving_score_decile },
        { name: 'distracted_driving_score', value: trip.distracted_driving_score, decile: decileData?.distracted_driving_score_decile },
        { name: 'contextual_score', value: trip.contextual_score, decile: decileData?.contextual_score_decile }
      );
    }
    
    return scores.filter(score => score.value !== null && score.value !== undefined);
  };

  const scoreData = getScoreData();

  // Helper function to get score color
  const getScoreColor = (score) => {
    if (score >= 90) return 'excellent';
    if (score >= 80) return 'good';
    if (score >= 70) return 'average';
    if (score >= 60) return 'fair';
    return 'poor';
  };

  return (
    <div className={styles.container}>
      {availableVersions.length > 1 && (
        <div className={styles.versionSelector}>
          <div className={styles.versionLabel}>{t('scoring_version')}:</div>
          <div className={styles.versionButtons}>
            {availableVersions.map(version => (
              <button
                key={version}
                className={`${styles.versionButton} ${activeVersion === version ? styles.activeVersion : ''}`}
                onClick={() => setActiveVersion(version)}
              >
                {version.toUpperCase()}
              </button>
            ))}
          </div>
        </div>
      )}
      
      <div className={styles.scoringContent}>
        <div className={styles.scoreCards}>
          {scoreData.map(score => (
            <div key={score.name} className={styles.scoreCard}>
              <div className={`${styles.scoreValue} ${styles[getScoreColor(score.value)]}`}>
                {score.value}
              </div>
              <div className={styles.scoreLabel}>{t(score.name)}</div>
              {score.decile && (
                <div className={styles.scoreDecile}>
                  {t('decile')}: {score.decile}/10
                </div>
              )}
            </div>
          ))}
        </div>
        
        <div className={styles.chartContainer} ref={chartRef}>
          {!chartLoaded && !chartError && (
            <div className={styles.loading}>
              <div className={styles.loadingSpinner}></div>
              <p>{t('loading_scoring_chart')}</p>
            </div>
          )}
          
          {chartError && (
            <div className={styles.chartError}>
              <p>{chartError}</p>
            </div>
          )}
          
          {chartLoaded && (
            <div className={styles.chartPlaceholder}>
              {/* This would be replaced by the actual chart */}
              <div className={styles.mockChart}>
                <div className={styles.mockChartText}>
                  {t('scoring_chart_would_be_displayed_here')}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      
      <div className={styles.scoringInfo}>
        <h3 className={styles.infoTitle}>{t('about_driving_scores')}</h3>
        <p className={styles.infoText}>
          {t('driving_scores_explanation')}
        </p>
        <div className={styles.infoFactors}>
          <div className={styles.infoFactor}>
            <div className={styles.infoFactorTitle}>{t('acceleration_score')}</div>
            <p className={styles.infoFactorText}>{t('acceleration_score_explanation')}</p>
          </div>
          <div className={styles.infoFactor}>
            <div className={styles.infoFactorTitle}>{t('braking_score')}</div>
            <p className={styles.infoFactorText}>{t('braking_score_explanation')}</p>
          </div>
          <div className={styles.infoFactor}>
            <div className={styles.infoFactorTitle}>{t('speeding_score')}</div>
            <p className={styles.infoFactorText}>{t('speeding_score_explanation')}</p>
          </div>
          <div className={styles.infoFactor}>
            <div className={styles.infoFactorTitle}>{t('cornering_score')}</div>
            <p className={styles.infoFactorText}>{t('cornering_score_explanation')}</p>
          </div>
          {activeVersion === 'v2' && (
            <>
              <div className={styles.infoFactor}>
                <div className={styles.infoFactorTitle}>{t('fatigue_driving_score')}</div>
                <p className={styles.infoFactorText}>{t('fatigue_driving_score_explanation')}</p>
              </div>
              <div className={styles.infoFactor}>
                <div className={styles.infoFactorTitle}>{t('distracted_driving_score')}</div>
                <p className={styles.infoFactorText}>{t('distracted_driving_score_explanation')}</p>
              </div>
              <div className={styles.infoFactor}>
                <div className={styles.infoFactorTitle}>{t('contextual_score')}</div>
                <p className={styles.infoFactorText}>{t('contextual_score_explanation')}</p>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TripScoring;