// File: frontend/src/pages/TripDetail/components/TripEvents/index.js

import React, { useState } from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import styles from './TripEvents.module.css';

const TripEvents = ({ trip }) => {
  const { t } = useLanguage();
  const [activeEventType, setActiveEventType] = useState('all');

  // Helper function to format date
  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  // Extract events from trip data
  const extractEvents = () => {
    if (!trip.events) return [];
    
    const allEvents = [];
    
    // Process acceleration events
    if (trip.events.acceleration_events) {
      trip.events.acceleration_events.forEach(event => {
        allEvents.push({
          ...event,
          type: 'acceleration',
          typeLabel: t('acceleration'),
          valueLabel: `${event.value} m/s²`,
          severityClass: getSeverityClass(event.severity)
        });
      });
    }
    
    // Process braking events
    if (trip.events.braking_events) {
      trip.events.braking_events.forEach(event => {
        allEvents.push({
          ...event,
          type: 'braking',
          typeLabel: t('braking'),
          valueLabel: `${event.value} m/s²`,
          severityClass: getSeverityClass(event.severity)
        });
      });
    }
    
    // Process cornering events
    if (trip.events.cornering_events) {
      trip.events.cornering_events.forEach(event => {
        allEvents.push({
          ...event,
          type: 'cornering',
          typeLabel: t('cornering'),
          valueLabel: `${event.value} m/s²`,
          severityClass: getSeverityClass(event.severity)
        });
      });
    }
    
    // Process speeding events
    if (trip.events.speeding_events) {
      trip.events.speeding_events.forEach(event => {
        allEvents.push({
          ...event,
          type: 'speeding',
          typeLabel: t('speeding'),
          valueLabel: event.value ? `${event.value} km/h` : '',
          severityClass: getSeverityClass(event.severity)
        });
      });
    }
    
    // Process distracted driving events
    if (trip.events.distracted_driving_events) {
      trip.events.distracted_driving_events.forEach(event => {
        allEvents.push({
          ...event,
          type: 'distracted',
          typeLabel: t('distracted_driving'),
          valueLabel: event.duration ? `${event.duration}s` : '',
          severityClass: 'medium'
        });
      });
    }
    
    // Process fatigue driving events
    if (trip.events.fatigue_driving_events) {
      trip.events.fatigue_driving_events.forEach(event => {
        allEvents.push({
          ...event,
          type: 'fatigue',
          typeLabel: t('fatigue_driving'),
          valueLabel: event.duration ? `${event.duration}s` : '',
          severityClass: 'high'
        });
      });
    }
    
    // Process night driving events
    if (trip.events.night_driving_events) {
      trip.events.night_driving_events.forEach(event => {
        allEvents.push({
          ...event,
          type: 'night',
          typeLabel: t('night_driving'),
          valueLabel: event.duration ? `${event.duration}s` : '',
          severityClass: 'low'
        });
      });
    }
    
    // Process peak driving events
    if (trip.events.peak_driving_events) {
      trip.events.peak_driving_events.forEach(event => {
        allEvents.push({
          ...event,
          type: 'peak',
          typeLabel: t('peak_driving'),
          valueLabel: event.duration ? `${event.duration}s` : '',
          severityClass: 'low'
        });
      });
    }
    
    // Sort events by timestamp
    return allEvents.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
  };

  // Helper function to get severity class
  const getSeverityClass = (severity) => {
    if (!severity) return 'medium';
    
    switch (severity.toLowerCase()) {
      case 'low':
        return 'low';
      case 'medium':
        return 'medium';
      case 'high':
        return 'high';
      default:
        return 'medium';
    }
  };

  const allEvents = extractEvents();
  
  // Filter events based on active type
  const filteredEvents = activeEventType === 'all' 
    ? allEvents 
    : allEvents.filter(event => event.type === activeEventType);

  // Count events by type
  const eventCounts = {
    all: allEvents.length,
    acceleration: allEvents.filter(e => e.type === 'acceleration').length,
    braking: allEvents.filter(e => e.type === 'braking').length,
    cornering: allEvents.filter(e => e.type === 'cornering').length,
    speeding: allEvents.filter(e => e.type === 'speeding').length,
    distracted: allEvents.filter(e => e.type === 'distracted').length,
    fatigue: allEvents.filter(e => e.type === 'fatigue').length,
    night: allEvents.filter(e => e.type === 'night').length,
    peak: allEvents.filter(e => e.type === 'peak').length
  };

  return (
    <div className={styles.container}>
      <div className={styles.eventFilters}>
        <button 
          className={`${styles.filterButton} ${activeEventType === 'all' ? styles.activeFilter : ''}`}
          onClick={() => setActiveEventType('all')}
        >
          {t('all')} ({eventCounts.all})
        </button>
        {eventCounts.acceleration > 0 && (
          <button 
            className={`${styles.filterButton} ${activeEventType === 'acceleration' ? styles.activeFilter : ''}`}
            onClick={() => setActiveEventType('acceleration')}
          >
            {t('acceleration')} ({eventCounts.acceleration})
          </button>
        )}
        {eventCounts.braking > 0 && (
          <button 
            className={`${styles.filterButton} ${activeEventType === 'braking' ? styles.activeFilter : ''}`}
            onClick={() => setActiveEventType('braking')}
          >
            {t('braking')} ({eventCounts.braking})
          </button>
        )}
        {eventCounts.cornering > 0 && (
          <button 
            className={`${styles.filterButton} ${activeEventType === 'cornering' ? styles.activeFilter : ''}`}
            onClick={() => setActiveEventType('cornering')}
          >
            {t('cornering')} ({eventCounts.cornering})
          </button>
        )}
        {eventCounts.speeding > 0 && (
          <button 
            className={`${styles.filterButton} ${activeEventType === 'speeding' ? styles.activeFilter : ''}`}
            onClick={() => setActiveEventType('speeding')}
          >
            {t('speeding')} ({eventCounts.speeding})
          </button>
        )}
        {eventCounts.distracted > 0 && (
          <button 
            className={`${styles.filterButton} ${activeEventType === 'distracted' ? styles.activeFilter : ''}`}
            onClick={() => setActiveEventType('distracted')}
          >
            {t('distracted')} ({eventCounts.distracted})
          </button>
        )}
        {eventCounts.fatigue > 0 && (
          <button 
            className={`${styles.filterButton} ${activeEventType === 'fatigue' ? styles.activeFilter : ''}`}
            onClick={() => setActiveEventType('fatigue')}
          >
            {t('fatigue')} ({eventCounts.fatigue})
          </button>
        )}
        {eventCounts.night > 0 && (
          <button 
            className={`${styles.filterButton} ${activeEventType === 'night' ? styles.activeFilter : ''}`}
            onClick={() => setActiveEventType('night')}
          >
            {t('night')} ({eventCounts.night})
          </button>
        )}
        {eventCounts.peak > 0 && (
          <button 
            className={`${styles.filterButton} ${activeEventType === 'peak' ? styles.activeFilter : ''}`}
            onClick={() => setActiveEventType('peak')}
          >
            {t('peak')} ({eventCounts.peak})
          </button>
        )}
      </div>
      
      <div className={styles.eventSummary}>
        <div className={styles.summaryCard}>
          <div className={styles.summaryTitle}>{t('total_events')}</div>
          <div className={styles.summaryValue}>{allEvents.length}</div>
        </div>
        
        <div className={styles.summaryCard}>
          <div className={styles.summaryTitle}>{t('extreme_events')}</div>
          <div className={styles.summaryValue}>
            {allEvents.filter(e => e.severityClass === 'high').length}
          </div>
        </div>
        
        <div className={styles.summaryCard}>
          <div className={styles.summaryTitle}>{t('distracted_driving_duration')}</div>
          <div className={styles.summaryValue}>
            {trip.events?.distracted_driving_duration || 0}s
          </div>
        </div>
      </div>
      
      {filteredEvents.length > 0 ? (
        <div className={styles.eventsContainer}>
          <div className={styles.eventsGrid}>
            {filteredEvents.map((event, index) => (
              <div key={index} className={styles.eventCard}>
                <div className={styles.eventHeader}>
                  <div className={styles.eventType}>{event.typeLabel}</div>
                  <div className={`${styles.eventSeverity} ${styles[event.severityClass]}`}>
                    {event.severity ? t(event.severity.toLowerCase()) : ''}
                  </div>
                </div>
                
                <div className={styles.eventDetails}>
                  <div className={styles.eventDetail}>
                    <span className={styles.eventDetailLabel}>{t('time')}:</span>
                    <span className={styles.eventDetailValue}>{formatDate(event.timestamp)}</span>
                  </div>
                  
                  {event.valueLabel && (
                    <div className={styles.eventDetail}>
                      <span className={styles.eventDetailLabel}>{t('value')}:</span>
                      <span className={styles.eventDetailValue}>{event.valueLabel}</span>
                    </div>
                  )}
                  
                  {event.type && (
                    <div className={styles.eventDetail}>
                      <span className={styles.eventDetailLabel}>{t('type')}:</span>
                      <span className={styles.eventDetailValue}>
                        {event.type === 'distracted' && event.type ? t(event.type) : ''}
                      </span>
                    </div>
                  )}
                  
                  <div className={styles.eventDetail}>
                    <span className={styles.eventDetailLabel}>{t('location')}:</span>
                    <span className={styles.eventDetailValue}>
                      {event.latitude?.toFixed(6)}, {event.longitude?.toFixed(6)}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className={styles.noEvents}>
          {activeEventType === 'all' 
            ? t('no_events_recorded_during_trip') 
            : t('no_events_of_selected_type')}
        </div>
      )}
    </div>
  );
};

export default TripEvents;