// File: frontend/src/pages/InvestorPitch/components/slides/RevenueProjection/RevenueProjection.js

import React from 'react';
import { motion } from 'framer-motion';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import { ANIMATION_VARIANTS } from '../../../constants/animations';
import styles from './RevenueProjection.module.css';

const RevenueProjection = () => {
  const { t } = useLanguage();

  const cycles = [
    { id: 'first',  years: '0-2', value: 3.2,   displayValue: '$3.2 B' },
    { id: 'second', years: '2-4', value: 32,    displayValue: '$32 B'  },
    { id: 'third',  years: '4-6', value: 160,   displayValue: '$160 B' },
    { id: 'fourth', years: '6-8', value: 480,   displayValue: '$480 B' },
    { id: 'fifth',  years: '8-10',value: 1000,  displayValue: '$1,000 B'   }
  ];

  // For simplicity, we'll just do linear scaling from minValue→maxValue to 40px→300px
  const minValue = 3.2;
  const maxValue = 1000;
  const minHeightPx = 40;
  const maxHeightPx = 300;

  const getBarHeight = (val) => {
    let ratio = (val - minValue) / (maxValue - minValue);
    ratio = Math.max(0, Math.min(1, ratio));
    return minHeightPx + ratio * (maxHeightPx - minHeightPx);
  };

  return (
    <motion.div
      className={styles.container}
      variants={ANIMATION_VARIANTS.stagger.container}
      initial="hidden"
      animate="visible"
    >
      {/* Title & subtitle */}
      <motion.div
        className={styles.header}
        variants={ANIMATION_VARIANTS.fadeIn}
      >
        <h2 className={styles.title}>
          {t('investor_pitch.revenue_projection.title')}
        </h2>
        <p className={styles.subtitle}>
          {t('investor_pitch.revenue_projection.subtitle')}
        </p>
      </motion.div>

      {/* The bar chart */}
      <div className={styles.chartWrapper}>
        <div className={styles.chartContainer}>
          {cycles.map((cycle, index) => {
            const barHeight = getBarHeight(cycle.value);

            return (
              <motion.div
                key={cycle.id}
                className={styles.cycleColumn}
                variants={ANIMATION_VARIANTS.stagger.item}
                custom={index}
              >
                <div className={styles.barContainer}>
                  <motion.div
                    className={styles.bar}
                    initial={{ height: 0 }}
                    animate={{ height: barHeight }}
                    transition={{ duration: 0.8, delay: index * 0.2 }}
                  >
                    <span className={styles.barValue}>
                      {cycle.displayValue}
                    </span>
                  </motion.div>
                </div>
                <div className={styles.cycleLabel}>
                  <span className={styles.cycleName}>
                    {`${
                      cycle.id.charAt(0).toUpperCase() + cycle.id.slice(1)
                    } Cycle`}
                  </span>
                  <span className={styles.cycleYears}>
                    {`Year ${cycle.years}`}
                  </span>
                </div>
              </motion.div>
            );
          })}
        </div>
      </div>

      {/* EXPLANATION SECTION BELOW THE CHART */}
      <div className={styles.explanationContainer}>
        <h3 className={styles.explanationTitle}>What is an Underwriting Cycle?</h3>
        <p className={styles.explanationText}>
          For insurance policies, the premium you write in a given year is
          typically <strong>earned</strong> over the following 12 months. For
          example, a policy written on December 31 stays in effect until
          the next December 31, so the revenue is recognized over that
          year‐long period.
        </p>

        <h4 className={styles.explanationSubtitle}>Why Does This Matter?</h4>
        <p className={styles.explanationText}>
          While you might "book" premium upfront, you only earn it
          gradually. This has an impact on how revenue shows up in your
          financial statements—<strong>you base your metrics on the earned portion </strong>
          in any given fiscal year. It's a key distinction for investors:
          <em> insurance revenue is recognized over the policy's coverage period.</em>
        </p>
      </div>
    </motion.div>
  );
};

export default RevenueProjection;