// File: frontend/src/pages/SuperAdmin/components/AuditLogs/RbacAuditLog/index.js

import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import SuperAdminTable from '../../common/SuperAdminTable';
import SuperAdminSearch from '../../common/SuperAdminSearch';
import SuperAdminPagination from '../../common/SuperAdminPagination';
import styles from './RbacAuditLog.module.css';

const RbacAuditLog = () => {
  const { t } = useLanguage();
  const [auditLogs, setAuditLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterActionType, setFilterActionType] = useState('all');
  const [selectedLog, setSelectedLog] = useState(null);
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    const fetchAuditLogs = async () => {
      setLoading(true);
      setError(null);
      
      try {
        // This would be replaced with an actual API call
        // const response = await auditService.getRbacAuditLogs({
        //   page: currentPage,
        //   search: searchTerm,
        //   actionType: filterActionType !== 'all' ? filterActionType : undefined
        // });
        
        // Mock data for now
        await new Promise(resolve => setTimeout(resolve, 800));
        
        const mockAuditLogs = [
          {
            audit_log_id: 'audit-67676',
            rbac_id: 'rbac-12345',
            enterprise_id: 'ent-12345',
            enterprise_name: 'Acme Corporation',
            user_role: 'admin',
            action_type: 'INSERT',
            action_timestamp: '2023-06-05T10:30:00Z',
            action_by: 'admin-user-1',
            action_by_name: 'John Smith',
            old_values: null,
            new_values: {
              user_role: 'admin',
              disallowed_features: {
                "feature-1": true,
                "feature-2": false
              }
            }
          },
          {
            audit_log_id: 'audit-78787',
            rbac_id: 'rbac-12345',
            enterprise_id: 'ent-12345',
            enterprise_name: 'Acme Corporation',
            user_role: 'admin',
            action_type: 'UPDATE',
            action_timestamp: '2023-06-10T14:45:00Z',
            action_by: 'admin-user-2',
            action_by_name: 'Jane Doe',
            old_values: {
              disallowed_features: {
                "feature-1": true,
                "feature-2": false
              }
            },
            new_values: {
              disallowed_features: {
                "feature-1": false,
                "feature-2": false,
                "feature-3": true
              }
            }
          },
          {
            audit_log_id: 'audit-89898',
            rbac_id: 'rbac-23456',
            enterprise_id: 'ent-23456',
            enterprise_name: 'Global Industries',
            user_role: 'manager',
            action_type: 'INSERT',
            action_timestamp: '2023-06-15T11:20:00Z',
            action_by: 'admin-user-1',
            action_by_name: 'John Smith',
            old_values: null,
            new_values: {
              user_role: 'manager',
              disallowed_features: {
                "feature-3": true,
                "feature-4": true
              }
            }
          },
          {
            audit_log_id: 'audit-90909',
            rbac_id: 'rbac-34567',
            enterprise_id: 'ent-34567',
            enterprise_name: 'Tech Solutions',
            user_role: 'user',
            action_type: 'UPDATE',
            action_timestamp: '2023-06-20T09:15:00Z',
            action_by: 'admin-user-3',
            action_by_name: 'Bob Johnson',
            old_values: {
              disallowed_features: {
                "feature-1": true,
                "feature-5": true
              }
            },
            new_values: {
              disallowed_features: {
                "feature-1": true,
                "feature-5": false
              }
            }
          },
          {
            audit_log_id: 'audit-01010',
            rbac_id: 'rbac-45678',
            enterprise_id: 'ent-45678',
            enterprise_name: 'Innovative Systems',
            user_role: 'admin',
            action_type: 'DELETE',
            action_timestamp: '2023-06-25T16:30:00Z',
            action_by: 'admin-user-2',
            action_by_name: 'Jane Doe',
            old_values: {
              user_role: 'admin',
              disallowed_features: {
                "feature-2": true,
                "feature-3": true
              }
            },
            new_values: null
          }
        ];
        
        // Filter based on search term and action type
        let filteredLogs = mockAuditLogs;
        
        if (searchTerm) {
          const searchLower = searchTerm.toLowerCase();
          filteredLogs = filteredLogs.filter(log => 
            log.enterprise_name.toLowerCase().includes(searchLower) ||
            log.rbac_id.toLowerCase().includes(searchLower) ||
            log.user_role.toLowerCase().includes(searchLower) ||
            log.action_by_name.toLowerCase().includes(searchLower)
          );
        }
        
        if (filterActionType !== 'all') {
          filteredLogs = filteredLogs.filter(log => 
            log.action_type === filterActionType
          );
        }
        
        setAuditLogs(filteredLogs);
        setTotalPages(Math.ceil(filteredLogs.length / 10)); // Assuming 10 items per page
      } catch (err) {
        console.error('Error fetching audit logs:', err);
        setError(t('failed_to_load_audit_logs'));
      } finally {
        setLoading(false);
      }
    };
    
    fetchAuditLogs();
  }, [currentPage, searchTerm, filterActionType, t]);

  const handleSearch = (term) => {
    setSearchTerm(term);
    setCurrentPage(1);
  };

  const handleActionTypeFilterChange = (e) => {
    setFilterActionType(e.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }).format(date);
  };

  const getActionTypeBadgeClass = (actionType) => {
    switch (actionType) {
      case 'INSERT':
        return styles.typeInsert;
      case 'UPDATE':
        return styles.typeUpdate;
      case 'DELETE':
        return styles.typeDelete;
      default:
        return '';
    }
  };

  const handleViewDetails = (log) => {
    setSelectedLog(log);
    setShowDetails(true);
  };

  const handleCloseDetails = () => {
    setShowDetails(false);
    setSelectedLog(null);
  };

  const renderChanges = (oldValues, newValues) => {
    if (!oldValues && !newValues) return null;
    
    // For INSERT operations
    if (!oldValues && newValues) {
      return (
        <div className={styles.changesList}>
          <div className={styles.changeItem}>
            <div className={styles.changeField}>user_role</div>
            <div className={styles.changeValues}>
              <div className={styles.changeNewValue}>
                <span className={styles.changeLabel}>{t('new')}:</span>
                <span className={styles.changeValue}>{newValues.user_role}</span>
              </div>
            </div>
          </div>
          
          <div className={styles.changeItem}>
            <div className={styles.changeField}>disallowed_features</div>
            <div className={styles.changeValues}>
              <div className={styles.changeNewValue}>
                <span className={styles.changeLabel}>{t('new')}:</span>
                <div className={styles.featuresList}>
                  {Object.entries(newValues.disallowed_features).map(([feature, isDisallowed]) => (
                    <div key={feature} className={styles.featureItem}>
                      <span className={styles.featureName}>{feature}</span>
                      <span className={`${styles.featureStatus} ${isDisallowed ? styles.disallowed : styles.allowed}`}>
                        {isDisallowed ? t('disallowed') : t('allowed')}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    
    // For DELETE operations
    if (oldValues && !newValues) {
      return (
        <div className={styles.changesList}>
          <div className={styles.changeItem}>
            <div className={styles.changeField}>user_role</div>
            <div className={styles.changeValues}>
              <div className={styles.changeOldValue}>
                <span className={styles.changeLabel}>{t('old')}:</span>
                <span className={styles.changeValue}>{oldValues.user_role}</span>
              </div>
            </div>
          </div>
          
          <div className={styles.changeItem}>
            <div className={styles.changeField}>disallowed_features</div>
            <div className={styles.changeValues}>
              <div className={styles.changeOldValue}>
                <span className={styles.changeLabel}>{t('old')}:</span>
                <div className={styles.featuresList}>
                  {Object.entries(oldValues.disallowed_features).map(([feature, isDisallowed]) => (
                    <div key={feature} className={styles.featureItem}>
                      <span className={styles.featureName}>{feature}</span>
                      <span className={`${styles.featureStatus} ${isDisallowed ? styles.disallowed : styles.allowed}`}>
                        {isDisallowed ? t('disallowed') : t('allowed')}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    
    // For UPDATE operations - handle user_role changes
    const userRoleChanged = oldValues.user_role !== newValues.user_role;
    
    // For UPDATE operations - handle disallowed_features changes
    const oldFeatures = oldValues.disallowed_features || {};
    const newFeatures = newValues.disallowed_features || {};
    
    // Get all unique feature keys
    const allFeatures = [...new Set([...Object.keys(oldFeatures), ...Object.keys(newFeatures)])];
    
    // Check which features have changed
    const changedFeatures = allFeatures.filter(feature => 
      oldFeatures[feature] !== newFeatures[feature]
    );
    
    return (
      <div className={styles.changesList}>
        {userRoleChanged && (
          <div className={styles.changeItem}>
            <div className={styles.changeField}>user_role</div>
            <div className={styles.changeValues}>
              <div className={styles.changeOldValue}>
                <span className={styles.changeLabel}>{t('old')}:</span>
                <span className={styles.changeValue}>{oldValues.user_role || '-'}</span>
              </div>
              <div className={styles.changeNewValue}>
                <span className={styles.changeLabel}>{t('new')}:</span>
                <span className={styles.changeValue}>{newValues.user_role || '-'}</span>
              </div>
            </div>
          </div>
        )}
        
        {changedFeatures.length > 0 && (
          <div className={styles.changeItem}>
            <div className={styles.changeField}>disallowed_features</div>
            <div className={styles.changeValues}>
              <div className={styles.featureChanges}>
                {changedFeatures.map(feature => (
                  <div key={feature} className={styles.featureChange}>
                    <div className={styles.featureName}>{feature}</div>
                    <div className={styles.featureChangeValues}>
                      <div className={styles.featureOldValue}>
                        <span className={styles.changeLabel}>{t('old')}:</span>
                        <span className={`${styles.featureStatus} ${oldFeatures[feature] ? styles.disallowed : styles.allowed}`}>
                          {oldFeatures[feature] !== undefined 
                            ? (oldFeatures[feature] ? t('disallowed') : t('allowed')) 
                            : '-'}
                        </span>
                      </div>
                      <div className={styles.featureNewValue}>
                        <span className={styles.changeLabel}>{t('new')}:</span>
                        <span className={`${styles.featureStatus} ${newFeatures[feature] ? styles.disallowed : styles.allowed}`}>
                          {newFeatures[feature] !== undefined 
                            ? (newFeatures[feature] ? t('disallowed') : t('allowed')) 
                            : '-'}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const columns = [
    {
      header: t('rbac_id'),
      accessor: 'rbac_id',
      cell: (row) => <span className={styles.idCell}>{row.rbac_id}</span>
    },
    {
      header: t('enterprise'),
      accessor: 'enterprise_name',
      cell: (row) => <span className={styles.enterpriseName}>{row.enterprise_name}</span>
    },
    {
      header: t('user_role'),
      accessor: 'user_role',
      cell: (row) => <span className={styles.roleCell}>{row.user_role}</span>
    },
    {
      header: t('action_type'),
      accessor: 'action_type',
      cell: (row) => (
        <span className={`${styles.actionTypeBadge} ${getActionTypeBadgeClass(row.action_type)}`}>
          {row.action_type}
        </span>
      )
    },
    {
      header: t('action_by'),
      accessor: 'action_by_name',
      cell: (row) => <span>{row.action_by_name}</span>
    },
    {
      header: t('timestamp'),
      accessor: 'action_timestamp',
      cell: (row) => <span>{formatDate(row.action_timestamp)}</span>
    },
    {
      header: t('actions'),
      accessor: 'actions',
      cell: (row) => (
        <div className={styles.actions}>
          <button 
            className={styles.viewButton}
            onClick={() => handleViewDetails(row)}
          >
            <i className="fas fa-eye"></i>
            <span>{t('view_details')}</span>
          </button>
        </div>
      )
    }
  ];

  return (
    <div className={styles.container}>
      <div className={styles.toolbar}>
        <div className={styles.filters}>
          <SuperAdminSearch 
            placeholder={t('search_audit_logs')}
            onSearch={handleSearch}
          />
          
          <div className={styles.filterGroup}>
            <label htmlFor="actionTypeFilter" className={styles.filterLabel}>
              {t('action_type')}:
            </label>
            <select
              id="actionTypeFilter"
              className={styles.filterSelect}
              value={filterActionType}
              onChange={handleActionTypeFilterChange}
            >
              <option value="all">{t('all_types')}</option>
              <option value="INSERT">{t('insert')}</option>
              <option value="UPDATE">{t('update')}</option>
              <option value="DELETE">{t('delete')}</option>
            </select>
          </div>
        </div>
      </div>
      
      {error && <div className={styles.error}>{error}</div>}
      
      <SuperAdminTable
        columns={columns}
        data={auditLogs}
        loading={loading}
        emptyMessage={t('no_audit_logs_found')}
      />
      
      <div className={styles.pagination}>
        <SuperAdminPagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
      
      {showDetails && selectedLog && (
        <div className={styles.detailsOverlay}>
          <div className={styles.detailsModal}>
            <div className={styles.detailsHeader}>
              <h3 className={styles.detailsTitle}>{t('audit_log_details')}</h3>
              <button className={styles.closeButton} onClick={handleCloseDetails}>
                <i className="fas fa-times"></i>
              </button>
            </div>
            
            <div className={styles.detailsContent}>
              <div className={styles.detailsSection}>
                <h4 className={styles.detailsSectionTitle}>{t('general_information')}</h4>
                <div className={styles.detailsGrid}>
                  <div className={styles.detailsItem}>
                    <div className={styles.detailsLabel}>{t('audit_log_id')}</div>
                    <div className={styles.detailsValue}>{selectedLog.audit_log_id}</div>
                  </div>
                  <div className={styles.detailsItem}>
                    <div className={styles.detailsLabel}>{t('rbac_id')}</div>
                    <div className={styles.detailsValue}>{selectedLog.rbac_id}</div>
                  </div>
                  <div className={styles.detailsItem}>
                    <div className={styles.detailsLabel}>{t('enterprise')}</div>
                    <div className={styles.detailsValue}>{selectedLog.enterprise_name}</div>
                  </div>
                  <div className={styles.detailsItem}>
                    <div className={styles.detailsLabel}>{t('enterprise_id')}</div>
                    <div className={styles.detailsValue}>{selectedLog.enterprise_id}</div>
                  </div>
                  <div className={styles.detailsItem}>
                    <div className={styles.detailsLabel}>{t('user_role')}</div>
                    <div className={styles.detailsValue}>{selectedLog.user_role}</div>
                  </div>
                  <div className={styles.detailsItem}>
                    <div className={styles.detailsLabel}>{t('action_type')}</div>
                    <div className={styles.detailsValue}>
                      <span className={`${styles.actionTypeBadge} ${getActionTypeBadgeClass(selectedLog.action_type)}`}>
                        {selectedLog.action_type}
                      </span>
                    </div>
                  </div>
                  <div className={styles.detailsItem}>
                    <div className={styles.detailsLabel}>{t('action_by')}</div>
                    <div className={styles.detailsValue}>{selectedLog.action_by_name}</div>
                  </div>
                  <div className={styles.detailsItem}>
                    <div className={styles.detailsLabel}>{t('action_by_id')}</div>
                    <div className={styles.detailsValue}>{selectedLog.action_by}</div>
                  </div>
                  <div className={styles.detailsItem}>
                    <div className={styles.detailsLabel}>{t('timestamp')}</div>
                    <div className={styles.detailsValue}>{formatDate(selectedLog.action_timestamp)}</div>
                  </div>
                </div>
              </div>
              
              <div className={styles.detailsSection}>
                <h4 className={styles.detailsSectionTitle}>{t('changes')}</h4>
                {renderChanges(selectedLog.old_values, selectedLog.new_values)}
              </div>
            </div>
            
            <div className={styles.detailsFooter}>
              <button className={styles.closeDetailsButton} onClick={handleCloseDetails}>
                {t('close')}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RbacAuditLog;