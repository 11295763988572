// File: frontend/src/components/Avatar/Avatar.js

import React from 'react';
import PropTypes from 'prop-types';
import './Avatar.module.css';

const AvatarUser = ({ size, privacy, imgSrc }) => {
  const sizeClass = `avatar-${size}`;
  const privacyClass = privacy ? 'avatar-privacy' : '';

  return (
    <div className={`avatar ${sizeClass} ${privacyClass}`}>
      <img className="avatar-img" alt="User avatar" src={imgSrc} />
      {privacy && (
        <img 
          className="privacy-icon" 
          alt="Privacy" 
          src="/icons/18/misc/privacy.svg" 
        />
      )}
    </div>
  );
};

AvatarUser.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']).isRequired,
  privacy: PropTypes.bool,
  imgSrc: PropTypes.string.isRequired,
};

AvatarUser.defaultProps = {
  privacy: false,
};

export default AvatarUser;