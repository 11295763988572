// File: frontend/src/pages/TripDetail/components/TripSpeed/index.js

import React, { useEffect, useRef, useState } from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import styles from './TripSpeed.module.css';

const TripSpeed = ({ trip }) => {
  const { t } = useLanguage();
  const chartRef = useRef(null);
  const [chartLoaded, setChartLoaded] = useState(false);
  const [chartError, setChartError] = useState(null);

  useEffect(() => {
    // This would be replaced with actual chart initialization code
    // For example, using Chart.js, D3.js, or another charting library
    const initializeChart = async () => {
      try {
        // Simulate chart loading
        await new Promise(resolve => setTimeout(resolve, 800));
        
        // Check if we have speed data
        if (!trip.speeds || !trip.speeds.speed_json || !trip.speeds.speed_json.speeds) {
          setChartError(t('no_speed_data_available'));
          return;
        }
        
        // In a real implementation, this is where you would:
        // 1. Initialize the chart library
        // 2. Create a line chart with speed data
        // 3. Add speed limit data if available
        // 4. Configure axes, legends, etc.
        
        setChartLoaded(true);
      } catch (error) {
        console.error('Error initializing chart:', error);
        setChartError(t('failed_to_load_speed_chart'));
      }
    };

    if (trip && chartRef.current) {
      initializeChart();
    }
  }, [trip, t]);

  // Extract speed data for display
  const speedData = trip.speeds?.speed_json ? {
    timestamps: trip.speeds.speed_json.timestamps || [],
    speeds: trip.speeds.speed_json.speeds || [],
    speedLimits: trip.speeds.speed_json.speed_limits || []
  } : { timestamps: [], speeds: [], speedLimits: [] };

  // Calculate speed statistics
  const calculateSpeedStats = () => {
    if (!speedData.speeds || speedData.speeds.length === 0) {
      return {
        avgSpeed: trip.average_speed || 0,
        maxSpeed: trip.maximum_speed || 0,
        minSpeed: 0,
        speedingDuration: 0,
        speedingPercentage: 0,
        speedingInstances: 0
      };
    }

    const speeds = speedData.speeds;
    const speedLimits = speedData.speedLimits;
    
    const avgSpeed = trip.average_speed || 
      (speeds.reduce((sum, speed) => sum + speed, 0) / speeds.length);
    
    const maxSpeed = trip.maximum_speed || 
      Math.max(...speeds);
    
    const minSpeed = Math.min(...speeds.filter(speed => speed > 0));
    
    // Calculate speeding statistics if speed limits are available
    let speedingDuration = 0;
    let speedingInstances = 0;
    let lastWasSpeeding = false;
    
    if (speedLimits && speedLimits.length === speeds.length) {
      for (let i = 0; i < speeds.length; i++) {
        if (speeds[i] > speedLimits[i]) {
          speedingDuration++;
          
          if (!lastWasSpeeding) {
            speedingInstances++;
            lastWasSpeeding = true;
          }
        } else {
          lastWasSpeeding = false;
        }
      }
    }
    
    const speedingPercentage = speeds.length > 0 ? 
      (speedingDuration / speeds.length) * 100 : 0;
    
    return {
      avgSpeed,
      maxSpeed,
      minSpeed,
      speedingDuration,
      speedingPercentage,
      speedingInstances
    };
  };

  const speedStats = calculateSpeedStats();

  return (
    <div className={styles.container}>
      <div className={styles.statsGrid}>
        <div className={styles.statCard}>
          <div className={styles.statIcon}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <circle cx="12" cy="12" r="10"></circle>
              <polyline points="12 6 12 12 16 14"></polyline>
            </svg>
          </div>
          <div className={styles.statContent}>
            <div className={styles.statValue}>{speedStats.avgSpeed.toFixed(1)} km/h</div>
            <div className={styles.statLabel}>{t('average_speed')}</div>
          </div>
        </div>
        
        <div className={styles.statCard}>
          <div className={styles.statIcon}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M13 2L3 14h9l-1 8 10-12h-9l1-8z"></path>
            </svg>
          </div>
          <div className={styles.statContent}>
            <div className={styles.statValue}>{speedStats.maxSpeed.toFixed(1)} km/h</div>
            <div className={styles.statLabel}>{t('maximum_speed')}</div>
          </div>
        </div>
        
        <div className={styles.statCard}>
          <div className={styles.statIcon}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <circle cx="12" cy="12" r="10"></circle>
              <line x1="8" y1="12" x2="16" y2="12"></line>
            </svg>
          </div>
          <div className={styles.statContent}>
            <div className={styles.statValue}>{speedStats.minSpeed.toFixed(1)} km/h</div>
            <div className={styles.statLabel}>{t('minimum_speed')}</div>
          </div>
        </div>
        
        <div className={styles.statCard}>
          <div className={styles.statIcon}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
            </svg>
          </div>
          <div className={styles.statContent}>
            <div className={styles.statValue}>{speedStats.speedingPercentage.toFixed(1)}%</div>
            <div className={styles.statLabel}>{t('speeding_percentage')}</div>
          </div>
        </div>
      </div>
      
      <div className={styles.chartContainer} ref={chartRef}>
        {!chartLoaded && !chartError && (
          <div className={styles.loading}>
            <div className={styles.loadingSpinner}></div>
            <p>{t('loading_speed_chart')}</p>
          </div>
        )}
        
        {chartError && (
          <div className={styles.chartError}>
            <p>{chartError}</p>
          </div>
        )}
        
        {chartLoaded && (
          <div className={styles.chartPlaceholder}>
            {/* This would be replaced by the actual chart */}
            <div className={styles.mockChart}>
              <div className={styles.mockSpeedLine}></div>
              <div className={styles.mockSpeedLimitLine}></div>
              <div className={styles.mockChartText}>
                {t('speed_chart_would_be_displayed_here')}
              </div>
              <div className={styles.mockLegend}>
                <div className={styles.mockLegendItem}>
                  <div className={styles.mockLegendColor} style={{ backgroundColor: 'var(--color-primary)' }}></div>
                  <div className={styles.mockLegendLabel}>{t('speed')}</div>
                </div>
                <div className={styles.mockLegendItem}>
                  <div className={styles.mockLegendColor} style={{ backgroundColor: 'var(--color-error)' }}></div>
                  <div className={styles.mockLegendLabel}>{t('speed_limit')}</div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      
      {speedData.timestamps.length > 0 && (
        <div className={styles.speedDataContainer}>
          <h3 className={styles.speedDataTitle}>{t('speed_data')}</h3>
          <div className={styles.tableContainer}>
            <table className={styles.speedDataTable}>
              <thead>
                <tr>
                  <th>{t('timestamp')}</th>
                  <th>{t('speed')} (km/h)</th>
                  {speedData.speedLimits.length > 0 && (
                    <th>{t('speed_limit')} (km/h)</th>
                  )}
                  {speedData.speedLimits.length > 0 && (
                    <th>{t('speeding')}</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {speedData.timestamps.map((timestamp, index) => {
                  const isSpeeding = speedData.speedLimits.length > 0 && 
                    speedData.speeds[index] > speedData.speedLimits[index];
                  
                  return (
                    <tr key={index} className={isSpeeding ? styles.speedingRow : ''}>
                      <td>{new Date(timestamp).toLocaleString()}</td>
                      <td>{speedData.speeds[index]}</td>
                      {speedData.speedLimits.length > 0 && (
                        <td>{speedData.speedLimits[index]}</td>
                      )}
                      {speedData.speedLimits.length > 0 && (
                        <td>{isSpeeding ? t('yes') : t('no')}</td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default TripSpeed;