// File: frontend/src/contexts/MeasurementUnitContext.js

import React, { createContext, useContext, useState, useEffect } from 'react';
import { useUser } from './UserContext';
import { ENUMS } from '../config/constants';

const MeasurementUnitContext = createContext(null);

export const MeasurementUnitProvider = ({ children }) => {
  const { user, updateUser } = useUser();
  const [measurementUnit, setMeasurementUnit] = useState(() => {
    return user?.preferred_measurement_unit_key || 'metric';
  });

  // Update local state when user data changes
  useEffect(() => {
    if (user?.preferred_measurement_unit_key) {
      setMeasurementUnit(user.preferred_measurement_unit_key);
    }
  }, [user?.preferred_measurement_unit_key]);

  const changeMeasurementUnit = async (newUnit) => {
    setMeasurementUnit(newUnit);
    const unitId = ENUMS.PREFERRED_MEASUREMENT_UNIT[newUnit];
    if (unitId) {
      await updateUser({ preferred_measurement_unit_id: unitId });
    }
  };

  return (
    <MeasurementUnitContext.Provider value={{
      measurementUnit,
      changeMeasurementUnit
    }}>
      {children}
    </MeasurementUnitContext.Provider>
  );
};

export const useMeasurementUnit = () => {
  const context = useContext(MeasurementUnitContext);
  if (!context) {
    throw new Error('useMeasurementUnit must be used within a MeasurementUnitProvider');
  }
  return context;
};