// src/components/TripDetails/UserInfo.js
import React from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import { usePrivacy } from '../../contexts/PrivacyContext';
import AvatarUser from '../Avatar/Avatar';
import styles from './UserInfo.module.css';

const UserInfo = ({ userData }) => {
  const { t } = useLanguage();
  const { privacyProtection } = usePrivacy();

  const maskData = (data, type) => {
    if (!privacyProtection) return data;
    switch (type) {
      case 'name':
        return `${data.charAt(0)}**${data.charAt(data.length - 1)}`;
      case 'email':
        const [localPart, domain] = data.split('@');
        return `${localPart.slice(0, 2)}**${domain}`;
      case 'customerId':
        return `${data.slice(0, 2)}**${data.slice(-2)}`;
      default:
        return data;
    }
  };

  return (
    <div className={styles.card}>
      <div className={styles.button}>
        <AvatarUser
          size="large"
          privacyProtection={privacyProtection}
          profilePic={userData.profilePic}
        />
        <div className={styles.paragraphContainer}>
          <div className={styles.userName}>
            {maskData(`${userData.firstName} ${userData.lastName}`, 'name')}
          </div>
          <div className={styles.userEmail}>
            {maskData(userData.emailAddress, 'email')}
          </div>
        </div>
      </div>
      <div className={styles.cardStandartContent}>
        <div className={styles.cardStandartContentA}>
          <div className={styles.cardStandartContentA1}>
            <div className={styles.text}>{t('customer_id')}</div>
            <div className={styles.text}>:</div>
          </div>
          <div className={styles.cardStandartContentA2}>
            <div className={styles.text}>
              {maskData(userData.customerID, 'customerId')}
            </div>
            <img className={styles.icons18CopyPaste} alt="" src="/icons/18/misc/copy-paste.svg" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInfo;