// File: frontend/src/components/LoadingSpinner/LoadingSpinner.js

// ****************************************************************
// Usage Examples:
// 
// Basic spinner:
// <LoadingSpinner />
//
// Inside a button:
// <button disabled={isLoading}>
//   {isLoading ? <LoadingSpinner size="small" color="white" /> : 'Submit'}
// </button>
//
// Custom sizes:
// <LoadingSpinner size="small" />
// <LoadingSpinner size="medium" />  {/* default */}
// <LoadingSpinner size="large" />
//
// Custom colors:
// <LoadingSpinner color="primary" />  {/* default */}
// <LoadingSpinner color="secondary" />
// <LoadingSpinner color="white" />
// ****************************************************************

import React from 'react';
import PropTypes from 'prop-types';
import { useLanguage } from '../../contexts/LanguageContext';
import styles from './LoadingSpinner.module.css';

const LoadingSpinner = ({ 
  size = 'medium', 
  color = 'primary',
  className = ''
}) => {
  const { t } = useLanguage();

  const spinnerClasses = `
    ${styles.spinner} 
    ${styles[size]} 
    ${styles[color]}
    ${className}
  `.trim();

  return (
    <div className={spinnerClasses} role="status">
      <div className={styles.spinnerInner}>
        <span className={styles.visuallyHidden}>{t('loading')}</span>
      </div>
    </div>
  );
};

LoadingSpinner.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  color: PropTypes.oneOf(['primary', 'secondary', 'white']),
  className: PropTypes.string
};

export default LoadingSpinner;