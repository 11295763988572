// File: frontend/src/services/quote.js

import { apiService } from './api';
import { API_ENDPOINTS } from '../config/constants';
import { v4 as uuidv4 } from 'uuid';
import safeStorage from '../utils/storage';

export const quoteService = {
  // Initiate a new quote session
  initiateQuote: async (data) => {
    console.group('Initiating Quote');
    console.log('Request data:', data);
    
    try {
      // Prepare request data
      const requestData = {
        product_type: data.product_type,
        source: data.source || 'web',
        primary_language: data.primary_language || 'en-ca',
        referrer: data.referrer,
        landing_page: data.landing_page
      };
      
      // If a quote_id is provided, include it in the request
      if (data.quote_id) {
        requestData.quote_id = data.quote_id;
        console.log('Using provided quote_id:', data.quote_id);
      }
      
      // If chat_id is provided, include it in the request
      if (data.chat_id) {
        requestData.chat_id = data.chat_id;
        console.log('Using provided chat_id:', data.chat_id);
      }
      
      const response = await apiService.post(API_ENDPOINTS.QUOTE.INITIATE, requestData);
      
      console.log('Quote initiated successfully:', response);
      
      // Store the quote_id and chat_id in localStorage for future reference
      if (response && response.quote_id) {
        safeStorage.setItem('quote_id', response.quote_id);
        console.log('Saved quote_id to localStorage:', response.quote_id);
      }
      
      if (response && response.chat_id) {
        safeStorage.setItem('chat_id', response.chat_id);
        console.log('Saved chat_id to localStorage:', response.chat_id);
      }
      
      console.groupEnd();
      return response;
    } catch (error) {
      console.error('Error initiating quote:', error);
      
      // Enhanced error handling - add specific properties to the error object
      // so the UI can handle different error types appropriately
      if (error.response) {
        // Add the status code to the error object for easier checking
        error.statusCode = error.response.status;
        
        // Special handling for 409 Conflict (quote already exists)
        if (error.response.status === 409) {
          error.isQuoteExistsError = true;
          // Extract the quote_id from the error response if available
          if (error.response.data && error.response.data.quote_id) {
            error.quoteId = error.response.data.quote_id;
          }
          console.log('Quote already exists (409 Conflict)');
        }
      }
      
      console.groupEnd();
      throw error;
    }
  },

  // Get quote details
  getQuote: async (quoteId) => {
    console.group('Getting Quote Details');
    console.log('Quote ID:', quoteId);
    
    try {
      if (!quoteId) {
        throw new Error('Quote ID is required to get quote details');
      }
      
      const response = await apiService.get(API_ENDPOINTS.QUOTE.GET_QUOTE(quoteId));
      
      console.log('Quote details retrieved successfully:', response);
      
      // Store the quote_id and chat_id in localStorage for future reference
      if (response) {
        if (response.quote_id) {
          safeStorage.setItem('quote_id', response.quote_id);
        }
        
        if (response.chat_id) {
          safeStorage.setItem('chat_id', response.chat_id);
        }
      }
      
      console.groupEnd();
      return response;
    } catch (error) {
      console.error('Error getting quote details:', error);
      
      // Enhanced error handling for getQuote
      if (error.response) {
        // Add the status code to the error object for easier checking
        error.statusCode = error.response.status;
        
        // Special handling for 404 Not Found
        if (error.response.status === 404) {
          error.isQuoteNotFoundError = true;
          console.log('Quote not found (404 Not Found)');
        }
      }
      
      console.groupEnd();
      throw error;
    }
  },

  // Search quotes
  searchQuote: async (queryParams) => {
    console.group('Searching Quotes');
    console.log('Search Parameters:', queryParams);
    
    try {
      const response = await apiService.get(API_ENDPOINTS.QUOTE.SEARCH_QUOTE, {
        params: queryParams
      });
      
      console.log('Quote search results:', response);
      console.groupEnd();
      return response;
    } catch (error) {
      console.error('Error searching quotes:', error);
      console.groupEnd();
      throw error;
    }
  },

  // Update a quote
  updateQuote: async (quoteId, data) => {
    console.group('Updating Quote');
    console.log('Quote ID:', quoteId, 'Data:', data);
    
    try {
      if (!quoteId) {
        throw new Error('Quote ID is required to update');
      }
      
      const response = await apiService.post(API_ENDPOINTS.QUOTE.UPDATE, {
        quote_id: quoteId,
        ...data
      });
      
      console.log('Quote updated successfully:', response);
      console.groupEnd();
      return response;
    } catch (error) {
      console.error('Error updating quote:', error);
      console.groupEnd();
      throw error;
    }
  },
  
  // Send a message to the quote chat
  sendMessage: async (chatId, message) => {
    console.group('Sending Message');
    console.log('Chat ID:', chatId);
    console.log('Message:', message);
    
    try {
      if (!chatId) {
        throw new Error('Chat ID is required to send a message');
      }
      
      const response = await apiService.post(API_ENDPOINTS.QUOTE.SEND_MESSAGE, {
        chat_id: chatId,
        message
      });
      
      console.log('Message sent successfully:', response);
      
      // If the response includes an updated chat_id, store it
      if (response && response.chat_id) {
        safeStorage.setItem('chat_id', response.chat_id);
      }
      
      console.groupEnd();
      return response;
    } catch (error) {
      console.error('Error sending message:', error);
      
      // Enhanced error handling for sendMessage
      if (error.response) {
        // Add the status code to the error object for easier checking
        error.statusCode = error.response.status;
      }
      
      console.groupEnd();
      throw error;
    }
  },
  
  // Get messages for a quote
  getMessages: async (quoteId) => {
    console.group('Getting Messages');
    console.log('Quote ID:', quoteId);
    
    try {
      if (!quoteId) {
        throw new Error('Quote ID is required to get messages');
      }
      
      const response = await apiService.get(API_ENDPOINTS.QUOTE.GET_MESSAGES(quoteId));
      
      console.log('Messages retrieved successfully:', response);
      
      // If the response includes a chat_id, store it
      if (response && response.chat_id) {
        safeStorage.setItem('chat_id', response.chat_id);
      }
      
      console.groupEnd();
      return response;
    } catch (error) {
      console.error('Error getting messages:', error);
      
      // Enhanced error handling for getMessages
      if (error.response) {
        // Add the status code to the error object for easier checking
        error.statusCode = error.response.status;
      }
      
      console.groupEnd();
      throw error;
    }
  },
  
  // Track savings calculator session
  trackSavingsSession: async (data) => {
    console.group('Tracking Savings Session');
    console.log('Request data:', data);
    
    try {
      // Don't include quote_id in the request if it starts with "no-quote"
      const requestData = {
        ...data
      };
      
      // If there's a stored quote_id that doesn't start with "no-quote", include it
      const storedQuoteId = safeStorage.getItem('quote_id');
      if (storedQuoteId && !storedQuoteId.startsWith('no-quote')) {
        requestData.quote_id = storedQuoteId;
        console.log('Including stored quote_id in request:', storedQuoteId);
      }
      
      const response = await apiService.post(API_ENDPOINTS.QUOTE.TRACK_SAVINGS, requestData);
      console.log('Response:', response);
      
      // Store session ID for future updates
      if (response && response.session_id) {
        safeStorage.setItem('savings_session_id', response.session_id);
        console.log('Saved session_id to localStorage:', response.session_id);
        
        // If we have a quote_id that doesn't start with "no-quote", store it
        if (response.quote_id && !response.quote_id.startsWith('no-quote')) {
          safeStorage.setItem('quote_id', response.quote_id);
          console.log('Saved quote_id to localStorage:', response.quote_id);
        }
      }
      
      console.log('Savings session tracked successfully');
      console.groupEnd();
      return response;
    } catch (error) {
      console.error('Error tracking savings session:', error);
      console.groupEnd();
      throw error;
    }
  },

  // Get all products for a savings session
  getSavingsSessionProducts: async (sessionId) => {
    console.group('Getting all products for savings session');
    console.log('Session ID:', sessionId);
    
    try {
      if (!sessionId) {
        throw new Error('Session ID is required to get products');
      }
      
      const response = await apiService.get(`${API_ENDPOINTS.QUOTE.GET_SAVINGS_PRODUCTS}/${sessionId}`);
      console.log('Response:', response);
      console.groupEnd();
      return response;
    } catch (error) {
      console.error('Error getting savings session products:', error);
      console.groupEnd();
      throw error;
    }
  },

  // Get a specific product for a savings session
  getSavingsSessionProduct: async (sessionId, productType) => {
    console.group('Getting product for savings session');
    console.log('Session ID:', sessionId, 'Product Type:', productType);
    
    try {
      if (!sessionId || !productType) {
        throw new Error('Session ID and Product Type are required');
      }
      
      const response = await apiService.get(`${API_ENDPOINTS.QUOTE.GET_SAVINGS_PRODUCT}/${sessionId}/${productType}`);
      console.log('Response:', response);
      console.groupEnd();
      return response;
    } catch (error) {
      console.error('Error getting savings session product:', error);
      console.groupEnd();
      throw error;
    }
  },
  
  // Generate a QNR (Quote Number Reference)
  generateQnr: async (quoteId) => {
    console.group('Generating QNR');
    console.log('Quote ID:', quoteId);
    
    try {
      if (!quoteId) {
        throw new Error('Quote ID is required to generate QNR');
      }
      
      const response = await apiService.post(API_ENDPOINTS.QUOTE.GENERATE_QNR, {
        quote_id: quoteId
      });
      
      console.log('QNR generated successfully:', response);
      console.groupEnd();
      return response;
    } catch (error) {
      console.error('Error generating QNR:', error);
      console.groupEnd();
      throw error;
    }
  },
  
  // Verify a QNR
  verifyQnr: async (qnr) => {
    console.group('Verifying QNR');
    console.log('QNR:', qnr);
    
    try {
      if (!qnr) {
        throw new Error('QNR is required for verification');
      }
      
      const response = await apiService.post(API_ENDPOINTS.QUOTE.VERIFY_QNR, {
        qnr
      });
      
      console.log('QNR verified successfully:', response);
      console.groupEnd();
      return response;
    } catch (error) {
      console.error('Error verifying QNR:', error);
      console.groupEnd();
      throw error;
    }
  },
  
  // Mark a quote as abandoned
  abandonedQuote: async (quoteId, reason) => {
    console.group('Marking Quote as Abandoned');
    console.log('Quote ID:', quoteId, 'Reason:', reason);
    
    try {
      if (!quoteId) {
        throw new Error('Quote ID is required to mark as abandoned');
      }
      
      const response = await apiService.post(API_ENDPOINTS.QUOTE.ABANDONED, {
        quote_id: quoteId,
        reason: reason || 'user_abandoned'
      });
      
      console.log('Quote marked as abandoned successfully:', response);
      console.groupEnd();
      return response;
    } catch (error) {
      console.error('Error marking quote as abandoned:', error);
      console.groupEnd();
      throw error;
    }
  },
  
  // Explicitly abandon a quote
  abandonQuote: async (quoteId, reason) => {
    console.group('Abandoning Quote');
    console.log('Quote ID:', quoteId, 'Reason:', reason);
    
    try {
      if (!quoteId) {
        throw new Error('Quote ID is required to abandon');
      }
      
      const response = await apiService.post(API_ENDPOINTS.QUOTE.ABANDON, {
        quote_id: quoteId,
        reason: reason || 'user_abandoned'
      });
      
      console.log('Quote abandoned successfully:', response);
      console.groupEnd();
      return response;
    } catch (error) {
      console.error('Error abandoning quote:', error);
      console.groupEnd();
      throw error;
    }
  },
  
  // Complete a quote
  completeQuote: async (quoteId, data) => {
    console.group('Completing Quote');
    console.log('Quote ID:', quoteId, 'Data:', data);
    
    try {
      if (!quoteId) {
        throw new Error('Quote ID is required to complete');
      }
      
      const response = await apiService.post(API_ENDPOINTS.QUOTE.COMPLETE, {
        quote_id: quoteId,
        ...data
      });
      
      console.log('Quote completed successfully:', response);
      console.groupEnd();
      return response;
    } catch (error) {
      console.error('Error completing quote:', error);
      console.groupEnd();
      throw error;
    }
  },
  
  // Create savings for a quote
  createSavings: async (quoteId, savingsData) => {
    console.group('Creating Savings for Quote');
    console.log('Quote ID:', quoteId, 'Savings Data:', savingsData);
    
    try {
      if (!quoteId) {
        throw new Error('Quote ID is required to create savings');
      }
      
      const response = await apiService.post(API_ENDPOINTS.QUOTE.CREATE_SAVINGS, {
        quote_id: quoteId,
        ...savingsData
      });
      
      console.log('Savings created successfully:', response);
      console.groupEnd();
      return response;
    } catch (error) {
      console.error('Error creating savings:', error);
      console.groupEnd();
      throw error;
    }
  },
  
  // Lock a quote
  lockQuote: async (quoteId, reason) => {
    console.group('Locking Quote');
    console.log('Quote ID:', quoteId, 'Reason:', reason);
    
    try {
      if (!quoteId) {
        throw new Error('Quote ID is required to lock');
      }
      
      const response = await apiService.post(API_ENDPOINTS.QUOTE.LOCK, {
        quote_id: quoteId,
        reason: reason || 'user_requested'
      });
      
      console.log('Quote locked successfully:', response);
      console.groupEnd();
      return response;
    } catch (error) {
      console.error('Error locking quote:', error);
      console.groupEnd();
      throw error;
    }
  },
  
  // Verify email for a quote
  verifyEmail: async (quoteId, email) => {
    console.group('Verifying Email for Quote');
    console.log('Quote ID:', quoteId, 'Email:', email);
    
    try {
      if (!quoteId || !email) {
        throw new Error('Quote ID and email are required for verification');
      }
      
      const response = await apiService.post(API_ENDPOINTS.QUOTE.VERIFY_EMAIL, {
        quote_id: quoteId,
        email
      });
      
      console.log('Email verified successfully:', response);
      console.groupEnd();
      return response;
    } catch (error) {
      console.error('Error verifying email:', error);
      console.groupEnd();
      throw error;
    }
  },
  
  // Verify phone for a quote
  verifyPhone: async (quoteId, phone) => {
    console.group('Verifying Phone for Quote');
    console.log('Quote ID:', quoteId, 'Phone:', phone);
    
    try {
      if (!quoteId || !phone) {
        throw new Error('Quote ID and phone are required for verification');
      }
      
      const response = await apiService.post(API_ENDPOINTS.QUOTE.VERIFY_PHONE, {
        quote_id: quoteId,
        phone
      });
      
      console.log('Phone verified successfully:', response);
      console.groupEnd();
      return response;
    } catch (error) {
      console.error('Error verifying phone:', error);
      console.groupEnd();
      throw error;
    }
  },
  
  // Update savings for a quote
  updateSavings: async (quoteId, savingsData) => {
    console.group('Updating Savings for Quote');
    console.log('Quote ID:', quoteId, 'Savings Data:', savingsData);
    
    try {
      if (!quoteId) {
        throw new Error('Quote ID is required to update savings');
      }
      
      const response = await apiService.post(API_ENDPOINTS.QUOTE.UPDATE_SAVINGS, {
        quote_id: quoteId,
        ...savingsData
      });
      
      console.log('Savings updated successfully:', response);
      console.groupEnd();
      return response;
    } catch (error) {
      console.error('Error updating savings:', error);
      console.groupEnd();
      throw error;
    }
  },
  
  // Helper methods
  
  // Format currency for display
  formatCurrency: (amount, currency = 'CAD') => {
    if (amount === undefined || amount === null) return '';
    
    return new Intl.NumberFormat('en-CA', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(amount);
  },
  
  // Format percentage for display
  formatPercentage: (value) => {
    if (value === undefined || value === null) return '';
    
    return `${value}%`;
  },
  
  // Calculate savings based on current premium and percentage
  calculateSavings: (currentPremium, savingsPercentage) => {
    if (!currentPremium || !savingsPercentage) return 0;
    
    return Math.round(currentPremium * (savingsPercentage / 100));
  },
  
  // Get payment frequency options
  getPaymentFrequencyOptions: () => {
    return [
      { value: 'monthly', label: 'Monthly' },
      { value: 'quarterly', label: 'Quarterly' },
      { value: 'semi_annual', label: 'Semi-Annual' },
      { value: 'annual', label: 'Annual' }
    ];
  },
  
  // Get product type options
  getProductTypeOptions: () => {
    return [
      { value: 'auto', label: 'Auto Insurance' },
      { value: 'home', label: 'Home Insurance' },
      { value: 'tenant', label: 'Tenant Insurance' }
    ];
  },
  
  // Create a new savings session with default values
  createNewSavingsSession: (productType = 'auto') => {
    return {
      session_id: uuidv4(),
      product_type: productType,
      payment_frequency: 'monthly',
      current_premium: null,
      current_premium_currency: 'CAD',
      is_additional_savings_selected: false,
      base_savings: 0,
      additional_savings: 0,
      total_savings: 0,
      total_savings_percentage: 0,
      landing_page: window.location.href,
      referrer: document.referrer || null
    };
  }
};

export default quoteService;