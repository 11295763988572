// File: frontend/src/pages/AutoInsight/VehicleDetail/components/VehicleImages/index.js

import React, { useState } from 'react';
import { useLanguage } from '../../../../../../contexts/LanguageContext';
import styles from './VehicleImages.module.css';

const VehicleImages = ({ vehicle }) => {
  const { t } = useLanguage();
  const [activeImage, setActiveImage] = useState(vehicle.images && vehicle.images.length > 0 ? 0 : null);
  const [showDetailedReport, setShowDetailedReport] = useState(true);

  if (!vehicle.images || vehicle.images.length === 0) {
    return (
      <div className={styles.noImages}>
        <p>{t('no_vehicle_images_available')}</p>
        <button className={styles.uploadButton}>
          {t('upload_images')}
        </button>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.mainImageContainer}>
        {activeImage !== null && (
          <img 
            src={vehicle.images[activeImage].vehicle_image_url} 
            alt={`${vehicle.details?.vehicle_year} ${vehicle.details?.vehicle_make} ${vehicle.details?.vehicle_model}`}
            className={styles.mainImage}
          />
        )}
      </div>
      
      <div className={styles.thumbnailsContainer}>
        {vehicle.images.map((image, index) => (
          <div 
            key={image.vehicle_image_id} 
            className={`${styles.thumbnail} ${activeImage === index ? styles.activeThumbnail : ''}`}
            onClick={() => setActiveImage(index)}
          >
            <img 
              src={image.vehicle_image_url} 
              alt={`Thumbnail ${index + 1}`}
              className={styles.thumbnailImage}
            />
          </div>
        ))}
        
        <button className={styles.uploadButton}>
          {t('add_more_images')}
        </button>
      </div>
      
      {activeImage !== null && vehicle.images[activeImage].vehicle_image_ai_assessment && (
        <div className={styles.imageDetails}>
          <h3 className={styles.imageDetailsTitle}>{t('image_assessment')}</h3>
          
          <div className={styles.imageDetailsContent}>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('condition')}:</div>
              <div className={styles.infoValue}>
                {vehicle.images[activeImage].vehicle_image_ai_assessment.condition || 'N/A'}
              </div>
            </div>
            
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('damage_detected')}:</div>
              <div className={styles.infoValue}>
                {vehicle.images[activeImage].vehicle_image_ai_assessment.damage_detected ? t('yes') : t('no')}
              </div>
            </div>
            
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('confidence')}:</div>
              <div className={styles.infoValue}>
                {vehicle.images[activeImage].vehicle_image_ai_assessment.confidence 
                  ? `${(vehicle.images[activeImage].vehicle_image_ai_assessment.confidence * 100).toFixed(0)}%` 
                  : 'N/A'}
              </div>
            </div>
            
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('source')}:</div>
              <div className={styles.infoValue}>
                {vehicle.images[activeImage].vehicle_image_source || 'N/A'}
              </div>
            </div>
            
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('date_taken')}:</div>
              <div className={styles.infoValue}>
                {new Date(vehicle.images[activeImage].vehicle_image_timestamp).toLocaleDateString()}
              </div>
            </div>
          </div>
          
          {vehicle.images[activeImage].vehicle_image_ai_assessment.detailed_report && (
            <div className={styles.reportContainer}>
              <button 
                className={styles.toggleReportButton}
                onClick={() => setShowDetailedReport(!showDetailedReport)}
              >
                {showDetailedReport ? t('hide_detailed_report') : t('show_detailed_report')}
              </button>
              
              {showDetailedReport && (
                <div className={styles.detailedReport}>
                  <h2 className={styles.reportTitle}>Vehicle Inspection Report</h2>
                  <pre className={styles.reportContent}>
                    {vehicle.images[activeImage].vehicle_image_ai_assessment.detailed_report}
                  </pre>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default VehicleImages;