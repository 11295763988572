// src/components/charts/Geography/GeographyPostalCodes.js
import React, { useEffect, useState } from 'react';
import MapboxWrapper from '../../Map/MapboxWrapper';

const GeographyPostalCodes = ({ width, height }) => {
  const [geoData, setGeoData] = useState(null);

  useEffect(() => {
    fetch('https://allegory-dashboard-maps-geojson-data.s3.ca-central-1.amazonaws.com/ofa9a9/driving-score/all_time/003ebc0a-d724-4eb7-932b-29ba0baa03f8.json')
      .then(response => response.json())
      .then(data => setGeoData(data))
      .catch(error => console.log("Error loading the geojson data: ", error));
  }, []);

  if (!geoData) {
    return <div>Data is loading. This may take a few seconds...</div>;
  }

  const data = [{
    type: "choroplethmapbox",
    geojson: geoData,
    locations: geoData.features.map(feature => feature.id),
    z: geoData.features.map(feature => feature.properties.percentage),
    colorscale: [
      [0.0, '#FBB1BD'], [0.1, '#FF99AC'], [0.2, '#FF99AC'],
      [0.3, '#FF85A1'], [0.4, '#FF85A1'], [0.5, '#FF7096'],
      [0.6, '#FF7096'], [0.7, '#FF477E'], [0.8, '#FF477E'],
      [0.9, '#FF0A54'], [1.0, '#FF0A54']
    ],
    zmin: 0,
    zmax: 100,
    colorbar: {
      title: "Percentage",
      thickness: 20
    }
  }];

  const layout = {
    mapbox: {
      center: { lon: -80, lat: 44 },
      zoom: 3
    },
    width: width,
    height: height,
    margin: { t: 0, b: 50 }
  };

  return (
    <MapboxWrapper
      data={data}
      layout={layout}
      style={{ width: "100%", height: "100%" }}
    />
  );
};

export default GeographyPostalCodes;