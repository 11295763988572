// File: frontend/src/pages/SuperAdmin/components/EnterpriseManagement/EnterpriseList/index.js

import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import SuperAdminTable from '../../common/SuperAdminTable';
import SuperAdminSearch from '../../common/SuperAdminSearch';
import SuperAdminPagination from '../../common/SuperAdminPagination';
import styles from './EnterpriseList.module.css';

const EnterpriseList = ({ onViewDetail, onCreateNew }) => {
  const { t } = useLanguage();
  const [enterprises, setEnterprises] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('all');

  useEffect(() => {
    const fetchEnterprises = async () => {
      setLoading(true);
      setError(null);
      
      try {
        // This would be replaced with an actual API call
        // const response = await enterpriseService.listEnterprises({
        //   page: currentPage,
        //   search: searchTerm,
        //   status: filterStatus !== 'all' ? filterStatus : undefined
        // });
        
        // Mock data for now
        await new Promise(resolve => setTimeout(resolve, 800));
        
        const mockEnterprises = [
          {
            enterprise_id: 'ent-12345',
            enterprise_name: 'Acme Corporation',
            enterprise_status: 'active',
            country_code: 'US',
            created_at: '2023-01-15T10:30:00Z',
            users_count: 45
          },
          {
            enterprise_id: 'ent-23456',
            enterprise_name: 'Global Industries',
            enterprise_status: 'active',
            country_code: 'CA',
            created_at: '2023-02-20T14:45:00Z',
            users_count: 32
          },
          {
            enterprise_id: 'ent-34567',
            enterprise_name: 'Tech Solutions Inc',
            enterprise_status: 'inactive',
            country_code: 'UK',
            created_at: '2023-03-10T09:15:00Z',
            users_count: 18
          },
          {
            enterprise_id: 'ent-45678',
            enterprise_name: 'Innovative Systems',
            enterprise_status: 'active',
            country_code: 'AU',
            created_at: '2023-04-05T16:20:00Z',
            users_count: 27
          },
          {
            enterprise_id: 'ent-56789',
            enterprise_name: 'Digital Solutions',
            enterprise_status: 'pending',
            country_code: 'DE',
            created_at: '2023-05-12T11:10:00Z',
            users_count: 8
          }
        ];
        
        // Filter based on search term and status
        let filteredEnterprises = mockEnterprises;
        
        if (searchTerm) {
          const searchLower = searchTerm.toLowerCase();
          filteredEnterprises = filteredEnterprises.filter(ent => 
            ent.enterprise_name.toLowerCase().includes(searchLower) ||
            ent.enterprise_id.toLowerCase().includes(searchLower)
          );
        }
        
        if (filterStatus !== 'all') {
          filteredEnterprises = filteredEnterprises.filter(ent => 
            ent.enterprise_status === filterStatus
          );
        }
        
        setEnterprises(filteredEnterprises);
        setTotalPages(Math.ceil(filteredEnterprises.length / 10)); // Assuming 10 items per page
      } catch (err) {
        console.error('Error fetching enterprises:', err);
        setError(t('failed_to_load_enterprises'));
      } finally {
        setLoading(false);
      }
    };
    
    fetchEnterprises();
  }, [currentPage, searchTerm, filterStatus, t]);

  const handleSearch = (term) => {
    setSearchTerm(term);
    setCurrentPage(1);
  };

  const handleFilterChange = (e) => {
    setFilterStatus(e.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    }).format(date);
  };

  const getStatusBadgeClass = (status) => {
    switch (status) {
      case 'active':
        return styles.statusActive;
      case 'inactive':
        return styles.statusInactive;
      case 'pending':
        return styles.statusPending;
      default:
        return '';
    }
  };

  const columns = [
    {
      header: t('enterprise_id'),
      accessor: 'enterprise_id',
      cell: (row) => <span className={styles.idCell}>{row.enterprise_id}</span>
    },
    {
      header: t('enterprise_name'),
      accessor: 'enterprise_name',
      cell: (row) => <span className={styles.nameCell}>{row.enterprise_name}</span>
    },
    {
      header: t('status'),
      accessor: 'enterprise_status',
      cell: (row) => (
        <span className={`${styles.statusBadge} ${getStatusBadgeClass(row.enterprise_status)}`}>
          {row.enterprise_status}
        </span>
      )
    },
    {
      header: t('country'),
      accessor: 'country_code',
      cell: (row) => <span>{row.country_code}</span>
    },
    {
      header: t('created_date'),
      accessor: 'created_at',
      cell: (row) => <span>{formatDate(row.created_at)}</span>
    },
    {
      header: t('users'),
      accessor: 'users_count',
      cell: (row) => <span>{row.users_count}</span>
    },
    {
      header: t('actions'),
      accessor: 'actions',
      cell: (row) => (
        <div className={styles.actions}>
          <button 
            className={styles.actionButton}
            onClick={() => onViewDetail(row.enterprise_id)}
          >
            <i className="fas fa-eye"></i>
            <span>{t('view')}</span>
          </button>
        </div>
      )
    }
  ];

  return (
    <div className={styles.container}>
      <div className={styles.toolbar}>
        <div className={styles.filters}>
          <SuperAdminSearch 
            placeholder={t('search_enterprises')}
            onSearch={handleSearch}
          />
          
          <div className={styles.statusFilter}>
            <label htmlFor="statusFilter" className={styles.filterLabel}>
              {t('status')}:
            </label>
            <select
              id="statusFilter"
              className={styles.filterSelect}
              value={filterStatus}
              onChange={handleFilterChange}
            >
              <option value="all">{t('all_statuses')}</option>
              <option value="active">{t('active')}</option>
              <option value="inactive">{t('inactive')}</option>
              <option value="pending">{t('pending')}</option>
            </select>
          </div>
        </div>
        
        <button className={styles.createButton} onClick={onCreateNew}>
          <i className="fas fa-plus"></i>
          <span>{t('create_enterprise')}</span>
        </button>
      </div>
      
      {error && <div className={styles.error}>{error}</div>}
      
      <SuperAdminTable
        columns={columns}
        data={enterprises}
        loading={loading}
        emptyMessage={t('no_enterprises_found')}
      />
      
      <div className={styles.pagination}>
        <SuperAdminPagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default EnterpriseList;