// File: frontend/src/pages/Support/Support.js

import React from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import styles from './Support.module.css';

const Support = () => {
  const { t } = useLanguage();

  return (
    <div >
    </div>
  );
};

export default Support;