// File: frontend/src/pages/SuperAdmin/components/EnterpriseManagement/EnterpriseDetail/index.js

import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from './EnterpriseDetail.module.css';

const EnterpriseDetail = ({ enterpriseId, onBack, onEdit }) => {
  const { t } = useLanguage();
  const [enterprise, setEnterprise] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('overview');

  useEffect(() => {
    const fetchEnterpriseDetails = async () => {
      setLoading(true);
      setError(null);
      
      try {
        // This would be replaced with an actual API call
        // const response = await enterpriseService.getEnterpriseDetails(enterpriseId);
        
        // Mock data for now
        await new Promise(resolve => setTimeout(resolve, 800));
        
        const mockEnterprise = {
          enterprise_id: enterpriseId,
          enterprise_numerical_id: 1001,
          enterprise_parent_name: "Global Holdings Inc",
          enterprise_parent_name_fr: "Global Holdings Inc",
          enterprise_name: "Acme Corporation",
          enterprise_name_fr: "Corporation Acme",
          country_code: "US",
          province_state_code: "CA",
          dataset_location: "US-WEST",
          dataset_region: "us-west-2",
          aws_public_bucket_url: "https://s3.amazonaws.com/acme-public/",
          aws_private_bucket_url: "https://s3.amazonaws.com/acme-private/",
          enterprise_hash: "abc123",
          enterprise_status: "active",
          jurisdiction_of_existence: "Delaware, USA",
          contact_details: {
            primary_contact: {
              name: "John Smith",
              email: "john.smith@acme.com",
              phone: "+14155552671"
            },
            address: {
              street: "123 Tech Blvd",
              city: "San Francisco",
              state: "California",
              zip: "94105",
              country: "USA"
            }
          },
          timezone_offset: -420, // Pacific Time
          created_at: "2023-01-15T10:30:00Z",
          updated_at: "2023-06-20T14:45:00Z",
          created_by: "admin-user-1",
          updated_by: "admin-user-2",
          
          // Additional related data
          users: [
            {
              enterprise_user_id: "euser-12345",
              user_id: "user-12345",
              user_role: "admin",
              user_verification_status: "verified",
              organization_name: "Acme Corporation",
              created_at: "2023-01-16T09:30:00Z"
            },
            {
              enterprise_user_id: "euser-23456",
              user_id: "user-23456",
              user_role: "manager",
              user_verification_status: "verified",
              organization_name: "Acme Corporation",
              created_at: "2023-01-17T11:45:00Z"
            },
            {
              enterprise_user_id: "euser-34567",
              user_id: "user-34567",
              user_role: "user",
              user_verification_status: "pending",
              organization_name: "Acme Corporation",
              created_at: "2023-02-05T14:20:00Z"
            }
          ],
          
          products: [
            {
              product_id: "prod-12345",
              product_name: "Analytics Dashboard",
              product_status: "active",
              product_category: "analytics",
              created_at: "2023-01-20T10:00:00Z"
            },
            {
              product_id: "prod-23456",
              product_name: "Risk Assessment Tool",
              product_status: "active",
              product_category: "risk",
              created_at: "2023-02-15T09:30:00Z"
            }
          ],
          
          agreements: [
            {
              agreement_id: "agree-12345",
              agreement_type: "production",
              agreement_status: "active",
              agreement_start_timestamp: "2023-01-15T00:00:00Z",
              agreement_end_timestamp: "2024-01-14T23:59:59Z",
              created_at: "2023-01-10T15:30:00Z"
            }
          ],
          
          subscriptions: [
            {
              subscription_id: "subs-12345",
              subscription_status: "active",
              subscription_tier: "enterprise",
              billing_frequency: "monthly",
              subscription_start_date: "2023-01-15",
              subscription_end_date: "2024-01-14",
              created_at: "2023-01-10T15:35:00Z"
            }
          ]
        };
        
        setEnterprise(mockEnterprise);
      } catch (err) {
        console.error('Error fetching enterprise details:', err);
        setError(t('failed_to_load_enterprise_details'));
      } finally {
        setLoading(false);
      }
    };
    
    if (enterpriseId) {
      fetchEnterpriseDetails();
    }
  }, [enterpriseId, t]);

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }).format(date);
  };

  const formatTimezoneOffset = (offsetMinutes) => {
    if (offsetMinutes === null || offsetMinutes === undefined) return 'N/A';
    
    const hours = Math.abs(Math.floor(offsetMinutes / 60));
    const minutes = Math.abs(offsetMinutes % 60);
    const sign = offsetMinutes < 0 ? '-' : '+';
    
    return `UTC${sign}${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  };

  const getStatusBadgeClass = (status) => {
    switch (status) {
      case 'active':
        return styles.statusActive;
      case 'inactive':
        return styles.statusInactive;
      case 'pending':
        return styles.statusPending;
      default:
        return '';
    }
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <div className={styles.spinner}></div>
        <p>{t('loading_enterprise_details')}</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.error}>
        <p>{error}</p>
        <button className={styles.backButton} onClick={onBack}>
          {t('back_to_enterprises')}
        </button>
      </div>
    );
  }

  if (!enterprise) {
    return (
      <div className={styles.notFound}>
        <p>{t('enterprise_not_found')}</p>
        <button className={styles.backButton} onClick={onBack}>
          {t('back_to_enterprises')}
        </button>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <h2 className={styles.enterpriseName}>{enterprise.enterprise_name}</h2>
          <div className={styles.enterpriseId}>ID: {enterprise.enterprise_id}</div>
          <div className={styles.statusContainer}>
            <span className={`${styles.statusBadge} ${getStatusBadgeClass(enterprise.enterprise_status)}`}>
              {enterprise.enterprise_status}
            </span>
          </div>
        </div>
        <div className={styles.headerActions}>
          <button className={styles.backButton} onClick={onBack}>
            <i className="fas fa-arrow-left"></i>
            <span>{t('back')}</span>
          </button>
          <button className={styles.editButton} onClick={onEdit}>
            <i className="fas fa-edit"></i>
            <span>{t('edit')}</span>
          </button>
        </div>
      </div>
      
      <div className={styles.tabs}>
        <button 
          className={`${styles.tab} ${activeTab === 'overview' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('overview')}
        >
          {t('overview')}
        </button>
        <button 
          className={`${styles.tab} ${activeTab === 'users' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('users')}
        >
          {t('users')}
        </button>
        <button 
          className={`${styles.tab} ${activeTab === 'products' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('products')}
        >
          {t('products')}
        </button>
        <button 
          className={`${styles.tab} ${activeTab === 'agreements' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('agreements')}
        >
          {t('agreements')}
        </button>
        <button 
          className={`${styles.tab} ${activeTab === 'subscriptions' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('subscriptions')}
        >
          {t('subscriptions')}
        </button>
      </div>
      
      <div className={styles.tabContent}>
        {activeTab === 'overview' && (
          <div className={styles.overviewTab}>
            <div className={styles.section}>
              <h3 className={styles.sectionTitle}>{t('basic_information')}</h3>
              <div className={styles.infoGrid}>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('enterprise_id')}</div>
                  <div className={styles.infoValue}>{enterprise.enterprise_id}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('numerical_id')}</div>
                  <div className={styles.infoValue}>{enterprise.enterprise_numerical_id}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('enterprise_name')}</div>
                  <div className={styles.infoValue}>{enterprise.enterprise_name}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('enterprise_name_fr')}</div>
                  <div className={styles.infoValue}>{enterprise.enterprise_name_fr}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('parent_company')}</div>
                  <div className={styles.infoValue}>{enterprise.enterprise_parent_name}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('parent_company_fr')}</div>
                  <div className={styles.infoValue}>{enterprise.enterprise_parent_name_fr}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('status')}</div>
                  <div className={styles.infoValue}>
                    <span className={`${styles.statusBadge} ${getStatusBadgeClass(enterprise.enterprise_status)}`}>
                      {enterprise.enterprise_status}
                    </span>
                  </div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('hash')}</div>
                  <div className={styles.infoValue}>{enterprise.enterprise_hash}</div>
                </div>
              </div>
            </div>
            
            <div className={styles.section}>
              <h3 className={styles.sectionTitle}>{t('location_information')}</h3>
              <div className={styles.infoGrid}>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('country')}</div>
                  <div className={styles.infoValue}>{enterprise.country_code}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('province_state')}</div>
                  <div className={styles.infoValue}>{enterprise.province_state_code}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('jurisdiction')}</div>
                  <div className={styles.infoValue}>{enterprise.jurisdiction_of_existence}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('timezone')}</div>
                  <div className={styles.infoValue}>{formatTimezoneOffset(enterprise.timezone_offset)}</div>
                </div>
              </div>
            </div>
            
            <div className={styles.section}>
              <h3 className={styles.sectionTitle}>{t('contact_information')}</h3>
              <div className={styles.infoGrid}>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('contact_name')}</div>
                  <div className={styles.infoValue}>{enterprise.contact_details?.primary_contact?.name}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('contact_email')}</div>
                  <div className={styles.infoValue}>{enterprise.contact_details?.primary_contact?.email}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('contact_phone')}</div>
                  <div className={styles.infoValue}>{enterprise.contact_details?.primary_contact?.phone}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('address')}</div>
                  <div className={styles.infoValue}>
                    {enterprise.contact_details?.address?.street}, {enterprise.contact_details?.address?.city}, {enterprise.contact_details?.address?.state} {enterprise.contact_details?.address?.zip}, {enterprise.contact_details?.address?.country}
                  </div>
                </div>
              </div>
            </div>
            
            <div className={styles.section}>
              <h3 className={styles.sectionTitle}>{t('data_storage')}</h3>
              <div className={styles.infoGrid}>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('dataset_location')}</div>
                  <div className={styles.infoValue}>{enterprise.dataset_location}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('dataset_region')}</div>
                  <div className={styles.infoValue}>{enterprise.dataset_region}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('public_bucket')}</div>
                  <div className={styles.infoValue}>{enterprise.aws_public_bucket_url}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('private_bucket')}</div>
                  <div className={styles.infoValue}>{enterprise.aws_private_bucket_url}</div>
                </div>
              </div>
            </div>
            
            <div className={styles.section}>
              <h3 className={styles.sectionTitle}>{t('audit_information')}</h3>
              <div className={styles.infoGrid}>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('created_at')}</div>
                  <div className={styles.infoValue}>{formatDate(enterprise.created_at)}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('created_by')}</div>
                  <div className={styles.infoValue}>{enterprise.created_by}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('updated_at')}</div>
                  <div className={styles.infoValue}>{formatDate(enterprise.updated_at)}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('updated_by')}</div>
                  <div className={styles.infoValue}>{enterprise.updated_by}</div>
                </div>
              </div>
            </div>
          </div>
        )}
        
        {activeTab === 'users' && (
          <div className={styles.usersTab}>
            <div className={styles.tabHeader}>
              <h3 className={styles.tabTitle}>{t('enterprise_users')}</h3>
              <button className={styles.addButton}>
                <i className="fas fa-plus"></i>
                <span>{t('add_user')}</span>
              </button>
            </div>
            
            {enterprise.users && enterprise.users.length > 0 ? (
              <div className={styles.usersList}>
                <table className={styles.usersTable}>
                  <thead>
                    <tr>
                      <th>{t('user_id')}</th>
                      <th>{t('role')}</th>
                      <th>{t('verification_status')}</th>
                      <th>{t('created_at')}</th>
                      <th>{t('actions')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {enterprise.users.map((user) => (
                      <tr key={user.enterprise_user_id}>
                        <td>{user.user_id}</td>
                        <td>{user.user_role}</td>
                        <td>
                          <span className={`${styles.verificationBadge} ${styles[user.user_verification_status]}`}>
                            {user.user_verification_status}
                          </span>
                        </td>
                        <td>{formatDate(user.created_at)}</td>
                        <td>
                          <div className={styles.tableActions}>
                            <button className={styles.actionButton}>
                              <i className="fas fa-eye"></i>
                            </button>
                            <button className={styles.actionButton}>
                              <i className="fas fa-edit"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className={styles.emptyState}>
                <p>{t('no_users_found')}</p>
              </div>
            )}
          </div>
        )}
        
        {activeTab === 'products' && (
          <div className={styles.productsTab}>
            <div className={styles.tabHeader}>
              <h3 className={styles.tabTitle}>{t('enterprise_products')}</h3>
              <button className={styles.addButton}>
                <i className="fas fa-plus"></i>
                <span>{t('add_product')}</span>
              </button>
            </div>
            
            {enterprise.products && enterprise.products.length > 0 ? (
              <div className={styles.productsList}>
                <table className={styles.productsTable}>
                  <thead>
                    <tr>
                      <th>{t('product_id')}</th>
                      <th>{t('product_name')}</th>
                      <th>{t('category')}</th>
                      <th>{t('status')}</th>
                      <th>{t('created_at')}</th>
                      <th>{t('actions')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {enterprise.products.map((product) => (
                      <tr key={product.product_id}>
                        <td>{product.product_id}</td>
                        <td>{product.product_name}</td>
                        <td>{product.product_category}</td>
                        <td>
                          <span className={`${styles.statusBadge} ${getStatusBadgeClass(product.product_status)}`}>
                            {product.product_status}
                          </span>
                        </td>
                        <td>{formatDate(product.created_at)}</td>
                        <td>
                          <div className={styles.tableActions}>
                            <button className={styles.actionButton}>
                              <i className="fas fa-eye"></i>
                            </button>
                            <button className={styles.actionButton}>
                              <i className="fas fa-edit"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className={styles.emptyState}>
                <p>{t('no_products_found')}</p>
              </div>
            )}
          </div>
        )}
        
        {activeTab === 'agreements' && (
          <div className={styles.agreementsTab}>
            <div className={styles.tabHeader}>
              <h3 className={styles.tabTitle}>{t('enterprise_agreements')}</h3>
              <button className={styles.addButton}>
                <i className="fas fa-plus"></i>
                <span>{t('add_agreement')}</span>
              </button>
            </div>
            
            {enterprise.agreements && enterprise.agreements.length > 0 ? (
              <div className={styles.agreementsList}>
                <table className={styles.agreementsTable}>
                  <thead>
                    <tr>
                      <th>{t('agreement_id')}</th>
                      <th>{t('type')}</th>
                      <th>{t('status')}</th>
                      <th>{t('start_date')}</th>
                      <th>{t('end_date')}</th>
                      <th>{t('actions')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {enterprise.agreements.map((agreement) => (
                      <tr key={agreement.agreement_id}>
                        <td>{agreement.agreement_id}</td>
                        <td>{agreement.agreement_type}</td>
                        <td>
                          <span className={`${styles.statusBadge} ${getStatusBadgeClass(agreement.agreement_status)}`}>
                            {agreement.agreement_status}
                          </span>
                        </td>
                        <td>{formatDate(agreement.agreement_start_timestamp)}</td>
                        <td>{formatDate(agreement.agreement_end_timestamp)}</td>
                        <td>
                          <div className={styles.tableActions}>
                            <button className={styles.actionButton}>
                              <i className="fas fa-eye"></i>
                            </button>
                            <button className={styles.actionButton}>
                              <i className="fas fa-edit"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className={styles.emptyState}>
                <p>{t('no_agreements_found')}</p>
              </div>
            )}
          </div>
        )}
        
        {activeTab === 'subscriptions' && (
          <div className={styles.subscriptionsTab}>
            <div className={styles.tabHeader}>
              <h3 className={styles.tabTitle}>{t('enterprise_subscriptions')}</h3>
              <button className={styles.addButton}>
                <i className="fas fa-plus"></i>
                <span>{t('add_subscription')}</span>
              </button>
            </div>
            
            {enterprise.subscriptions && enterprise.subscriptions.length > 0 ? (
              <div className={styles.subscriptionsList}>
                <table className={styles.subscriptionsTable}>
                  <thead>
                    <tr>
                      <th>{t('subscription_id')}</th>
                      <th>{t('tier')}</th>
                      <th>{t('status')}</th>
                      <th>{t('billing_frequency')}</th>
                      <th>{t('start_date')}</th>
                      <th>{t('end_date')}</th>
                      <th>{t('actions')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {enterprise.subscriptions.map((subscription) => (
                      <tr key={subscription.subscription_id}>
                        <td>{subscription.subscription_id}</td>
                        <td>{subscription.subscription_tier}</td>
                        <td>
                          <span className={`${styles.statusBadge} ${getStatusBadgeClass(subscription.subscription_status)}`}>
                            {subscription.subscription_status}
                          </span>
                        </td>
                        <td>{subscription.billing_frequency}</td>
                        <td>{subscription.subscription_start_date}</td>
                        <td>{subscription.subscription_end_date}</td>
                        <td>
                          <div className={styles.tableActions}>
                            <button className={styles.actionButton}>
                              <i className="fas fa-eye"></i>
                            </button>
                            <button className={styles.actionButton}>
                              <i className="fas fa-edit"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className={styles.emptyState}>
                <p>{t('no_subscriptions_found')}</p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default EnterpriseDetail;