// File: frontend/src/pages/InvestorPitch/components/slides/TechnicalArchitectureSlide/TechnicalArchitectureSlide.js

import React from 'react';
import { motion } from 'framer-motion';
import * as LucideIcons from 'lucide-react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import SystemMetric from './SystemMetric';
import DeploymentOption from './DeploymentOption';
import { ANIMATION_VARIANTS } from '../../../constants/animations';
import styles from './TechnicalArchitectureSlide.module.css';

const TechnicalArchitectureSlide = () => {
  const { t } = useLanguage();

  const systemMetrics = [
    {
      icon: 'Code2',
      title: t('investor_pitch.technical.metrics.code.title'),
      value: "1,300,000+",
      unit: t('investor_pitch.technical.metrics.code.unit'),
      color: "var(--color-blue-310)",
      details: {
        title: t('investor_pitch.technical.metrics.code.hover.title'),
        description: t('investor_pitch.technical.metrics.code.hover.description'),
        projects: [
          t('investor_pitch.technical.metrics.code.hover.projects.frontend'),
          t('investor_pitch.technical.metrics.code.hover.projects.backend'),
          t('investor_pitch.technical.metrics.code.hover.projects.infrastructure'),
          t('investor_pitch.technical.metrics.code.hover.projects.cicd'),
          t('investor_pitch.technical.metrics.code.hover.projects.containerization')
        ]
      }
    },
    {
      icon: 'Network',
      title: t('investor_pitch.technical.metrics.architecture.title'),
      value: t('investor_pitch.technical.metrics.architecture.value'),
      color: "var(--color-green-310)",
      details: {
        title: t('investor_pitch.technical.metrics.architecture.hover.title'),
        description: t('investor_pitch.technical.metrics.architecture.hover.description'),
        regions: {
          americas: [
            "🇨🇦 Canada (Central)",
            "🇺🇸 US East (N. Virginia)",
            "🇺🇸 US East (Ohio)",
            "🇺🇸 US West (N. California)",
            "🇺🇸 US West (Oregon)",
            "🇧🇷 South America (São Paulo)"
          ],
          europe: [
            "🇬🇧 Europe (London)",
            "🇮🇪 Europe (Ireland)",
            "🇫🇷 Europe (Paris)",
            "🇩🇪 Europe (Frankfurt)",
            "🇮🇹 Europe (Milan)",
            "🇸🇪 Europe (Stockholm)"
          ],
          asiaPacific: [
            "🇯🇵 Asia Pacific (Tokyo)",
            "🇯🇵 Asia Pacific (Osaka)",
            "🇰🇷 Asia Pacific (Seoul)",
            "🇭🇰 Asia Pacific (Hong Kong)",
            "🇸🇬 Asia Pacific (Singapore)",
            "🇮🇳 Asia Pacific (Mumbai)",
            "🇮🇩 Asia Pacific (Jakarta)",
            "🇦🇺 Asia Pacific (Sydney)"
          ],
          middleEastAfrica: [
            "🇦🇪 Middle East (UAE)",
            "🇧🇭 Middle East (Bahrain)",
            "🇿🇦 Africa (Cape Town)"
          ]
        }
      }
    },
    {
      icon: 'Binary',
      title: t('investor_pitch.technical.metrics.knowledge.title'),
      value: "95/5",
      color: "var(--color-orange-310)",
      details: {
        title: t('investor_pitch.technical.metrics.knowledge.hover.title'),
        description: t('investor_pitch.technical.metrics.knowledge.hover.description'),
        stats: {
          days: {
            value: "20",
            label: t('investor_pitch.technical.metrics.stats.days')
          },
          hours: {
            value: "181.54",
            label: t('investor_pitch.technical.metrics.stats.hours')
          },
          iterations: {
            value: "438",
            label: t('investor_pitch.technical.metrics.stats.iterations')
          },
          inputTokens: {
            value: "25,260,000",
            label: t('investor_pitch.technical.metrics.stats.inputTokens')
          },
          codeTokens: {
            value: "1,200,000",
            label: t('investor_pitch.technical.metrics.stats.codeTokens')
          },
          capacity: {
            value: "$1B",
            label: t('investor_pitch.technical.metrics.stats.capacity')
          }
        }
      }
    }
  ];

  const systemMetricsWithDetails = [
    {
      icon: 'Code2',
      title: t('investor_pitch.technical.metrics.code.title'),
      value: "350,000",
      unit: t('investor_pitch.technical.metrics.code.unit'),
      color: "var(--color-blue-310)",
      details: {
        title: t('investor_pitch.technical.metrics.code.hover.title'),
        description: t('investor_pitch.technical.metrics.code.hover.description'),
        projects: [
          t('investor_pitch.technical.metrics.code.hover.projects.frontend'),
          t('investor_pitch.technical.metrics.code.hover.projects.backend'),
          t('investor_pitch.technical.metrics.code.hover.projects.infrastructure'),
          t('investor_pitch.technical.metrics.code.hover.projects.cicd'),
          t('investor_pitch.technical.metrics.code.hover.projects.containerization')
        ]
      }
    },
    {
      icon: 'Network',
      title: t('investor_pitch.technical.metrics.architecture.title'),
      value: t('investor_pitch.technical.metrics.architecture.value'),
      color: "var(--color-green-310)",
      details: {
        title: t('investor_pitch.technical.metrics.architecture.hover.title'),
        description: t('investor_pitch.technical.metrics.architecture.hover.description'),
        regions: {
          americas: [
            "🇨🇦 Canada (Central)",
            "🇺🇸 US East (N. Virginia)",
            "🇺🇸 US East (Ohio)",
            "🇺🇸 US West (N. California)",
            "🇺🇸 US West (Oregon)",
            "🇧🇷 South America (São Paulo)"
          ],
          europe: [
            "🇬🇧 Europe (London)",
            "🇮🇪 Europe (Ireland)",
            "🇫🇷 Europe (Paris)",
            "🇩🇪 Europe (Frankfurt)",
            "🇮🇹 Europe (Milan)",
            "🇸🇪 Europe (Stockholm)"
          ],
          asiaPacific: [
            "🇯🇵 Asia Pacific (Tokyo)",
            "🇯🇵 Asia Pacific (Osaka)",
            "🇰🇷 Asia Pacific (Seoul)",
            "🇭🇰 Asia Pacific (Hong Kong)",
            "🇸🇬 Asia Pacific (Singapore)",
            "🇮🇳 Asia Pacific (Mumbai)",
            "🇮🇩 Asia Pacific (Jakarta)",
            "🇦🇺 Asia Pacific (Sydney)"
          ],
          middleEastAfrica: [
            "🇦🇪 Middle East (UAE)",
            "🇧🇭 Middle East (Bahrain)",
            "🇿🇦 Africa (Cape Town)"
          ]
        }
      }
    },
    {
      icon: 'Binary',
      title: t('investor_pitch.technical.metrics.knowledge.title'),
      value: "95/5",
      color: "var(--color-orange-310)",
      details: {
        title: t('investor_pitch.technical.metrics.knowledge.hover.title'),
        description: t('investor_pitch.technical.metrics.knowledge.hover.description'),
        stats: {
          days: 20,
          hours: 181.54,
          iterations: 438,
          inputTokens: "25,260,000",
          codeTokens: "1,200,000",
          capacity: "$1B GWP"
        }
      }
    }
  ];

  const deploymentOptions = [
    {
      title: t('investor_pitch.technical.deployment.onpremise.title'),
      description: t('investor_pitch.technical.deployment.onpremise.description'),
      icon: 'Server',
      gradient: "linear-gradient(135deg, var(--color-blue-110), var(--color-blue-310))"
    },
    {
      title: t('investor_pitch.technical.deployment.cloud.title'),
      description: t('investor_pitch.technical.deployment.cloud.description'),
      icon: 'Cloud',
      gradient: "linear-gradient(135deg, var(--color-green-110), var(--color-green-310))"
    },
    {
      title: t('investor_pitch.technical.deployment.hybrid.title'),
      description: t('investor_pitch.technical.deployment.hybrid.description'),
      icon: 'Network',
      gradient: "linear-gradient(135deg, var(--color-orange-110), var(--color-orange-310))"
    }
  ];

  return (
    <motion.div
      className={styles.container}
      variants={ANIMATION_VARIANTS.stagger.container}
      initial="hidden"
      animate="visible"
    >
      <motion.div 
        className={styles.header}
        variants={ANIMATION_VARIANTS.fadeIn}
      >
        <h2 className={styles.title}>{t('investor_pitch.technical.title')}</h2>
        <p className={styles.subtitle}>{t('investor_pitch.technical.subtitle')}</p>
      </motion.div>

      <div className={styles.content}>
        <motion.section 
          className={styles.systemOverview}
          variants={ANIMATION_VARIANTS.stagger.container}
        >
          <h3 className={styles.sectionTitle}>
            {t('investor_pitch.technical.sections.overview')}
          </h3>
          <div className={styles.metricsGrid}>
            {systemMetrics.map((metric, index) => (
              <motion.div
                key={metric.title}
                variants={ANIMATION_VARIANTS.stagger.item}
                custom={index}
              >
                <SystemMetric {...metric} />
              </motion.div>
            ))}
          </div>
        </motion.section>

        <motion.section 
          className={styles.deploymentSection}
          variants={ANIMATION_VARIANTS.stagger.container}
        >
          <h3 className={styles.sectionTitle}>
            {t('investor_pitch.technical.sections.deployment')}
          </h3>
          <div className={styles.deploymentGrid}>
            {deploymentOptions.map((option, index) => (
              <motion.div
                key={option.title}
                variants={ANIMATION_VARIANTS.stagger.item}
                custom={index}
              >
                <DeploymentOption {...option} />
              </motion.div>
            ))}
          </div>
        </motion.section>
      </div>
    </motion.div>
  );
};

export default TechnicalArchitectureSlide;