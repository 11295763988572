// File: frontend/src/pages/Status/Status.js

import React from 'react';
import { useSystemStatus } from '../../hooks/useSystemStatus';
import StatusOverview from './components/StatusOverview';
import MetricsDisplay from './components/MetricsDisplay';
import UptimeChart from './components/UptimeChart';
import ServicesList from './components/ServicesList';
import AwsMetrics from './components/AwsMetrics';
import styles from './Status.module.css';

const Status = () => {
  const { 
    currentStatus, 
    uptimeHistory, 
    metrics, 
    isLoading, 
    error, 
    refresh 
  } = useSystemStatus();

  if (error) {
    return (
      <div className={styles.error}>
        <div className={styles.errorContent}>
          <i className="pi pi-exclamation-triangle" />
          <h2>Error Loading System Status</h2>
          <p>{error.message}</p>
          <button 
            className={styles.retryButton}
            onClick={refresh}
          >
            <i className="pi pi-refresh" />
            Retry
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {/* Header Section */}
        <div className={styles.header}>
          <h1>System Status</h1>
          <button 
            className={styles.refreshButton}
            onClick={refresh}
            disabled={isLoading}
          >
            <i className={`pi pi-refresh ${isLoading ? styles.spinning : ''}`} />
            Refresh
          </button>
        </div>

        {/* Main Content */}
        <div className={styles.grid}>
          {/* Overview Section */}
          <div className={styles.section}>
            <StatusOverview 
              currentStatus={currentStatus}
              isLoading={isLoading}
              onRefresh={refresh}
            />
          </div>

          {/* Metrics Section */}
          <div className={styles.section}>
            <MetricsDisplay 
              metrics={metrics}
              isLoading={isLoading}
            />
          </div>

          {/* Performance Chart Section */}
          <div className={styles.section}>
            <UptimeChart 
              history={uptimeHistory}
              isLoading={isLoading}
            />
          </div>

          {/* Services List Section */}
          <div className={styles.section}>
            <ServicesList 
              services={currentStatus?.services}
              isLoading={isLoading}
            />
          </div>

          {/* AWS Metrics Section */}
          <div className={styles.section}>
            <AwsMetrics 
              metrics={metrics}
              isLoading={isLoading}
            />
          </div>
        </div>

        {/* Footer */}
        <div className={styles.footer}>
          <div className={styles.footerContent}>
            Last updated: {currentStatus?.timestamp ? 
              new Date(currentStatus.timestamp).toLocaleString() : 
              'Never'
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Status;