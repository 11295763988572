// File: frontend/src/components/DrivingScore/AxisSelector.js

import React from 'react';
import styles from './AxisSelector.module.css';

const AxisSelector = ({ label, icon, value, onChange, options }) => {
  return (
    <div className={styles.dropdownForm}>
      <div className={styles.label}>
        <div className={styles.label1}>{label}</div>
        <img className={styles.icons18Scale} alt="" src={icon} />
      </div>
      <div className={styles.dropdownBase}>
        <select 
          className={styles.select}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <img className={styles.inputDropdownChevron} alt="" src="/icons/18/misc/chevron-down.svg" />
      </div>
    </div>
  );
};

export default AxisSelector;