// File: frontend/src/pages/InvestorPitch/components/slides/HiringSlide/HiringSlide.js

import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import { ANIMATION_VARIANTS } from '../../../constants/animations';
import { MapPin, Calendar, Users, DollarSign, Award, Gift, Briefcase, Code, Database, ChevronRight, ChevronLeft } from 'lucide-react';
import styles from './HiringSlide.module.css';

const HiringSlide = () => {
  const { t } = useLanguage();
  const [activeSection, setActiveSection] = useState(0);
  const totalSections = 4;

  // Auto-rotate sections
  useEffect(() => {
    const timer = setInterval(() => {
      setActiveSection((prev) => (prev + 1) % totalSections);
    }, 8000); // 8 seconds per section

    return () => clearInterval(timer);
  }, []);

  const handleNext = () => {
    setActiveSection((prev) => (prev + 1) % totalSections);
  };

  const handlePrev = () => {
    setActiveSection((prev) => (prev - 1 + totalSections) % totalSections);
  };

  const locations = [
    {
      city: t('investor_pitch.hiring.locations.toronto.city', 'Toronto'),
      country: t('investor_pitch.hiring.locations.toronto.country', 'Canada'),
      timeline: t('investor_pitch.hiring.locations.toronto.timeline', 'Q1 2024'),
      headcount: 15,
      roles: [
        t('investor_pitch.hiring.locations.toronto.roles.role1', 'AI Engineers'),
        t('investor_pitch.hiring.locations.toronto.roles.role2', 'Product Managers'),
        t('investor_pitch.hiring.locations.toronto.roles.role3', 'Data Scientists'),
        t('investor_pitch.hiring.locations.toronto.roles.role4', 'Full-Stack Developers')
      ],
      gradient: "linear-gradient(135deg, var(--color-blue-310), var(--color-blue-510))"
    },
    {
      city: t('investor_pitch.hiring.locations.newyork.city', 'New York'),
      country: t('investor_pitch.hiring.locations.newyork.country', 'USA'),
      timeline: t('investor_pitch.hiring.locations.newyork.timeline', 'Q2 2024'),
      headcount: 25,
      roles: [
        t('investor_pitch.hiring.locations.newyork.roles.role1', 'Insurance Specialists'),
        t('investor_pitch.hiring.locations.newyork.roles.role2', 'Sales Executives'),
        t('investor_pitch.hiring.locations.newyork.roles.role3', 'Marketing Strategists'),
        t('investor_pitch.hiring.locations.newyork.roles.role4', 'Business Analysts')
      ],
      gradient: "linear-gradient(135deg, var(--color-green-310), var(--color-green-510))"
    },
    {
      city: t('investor_pitch.hiring.locations.london.city', 'London'),
      country: t('investor_pitch.hiring.locations.london.country', 'UK'),
      timeline: t('investor_pitch.hiring.locations.london.timeline', 'Q3 2024'),
      headcount: 10,
      roles: [
        t('investor_pitch.hiring.locations.london.roles.role1', 'Compliance Officers'),
        t('investor_pitch.hiring.locations.london.roles.role2', 'Risk Analysts'),
        t('investor_pitch.hiring.locations.london.roles.role3', 'UX/UI Designers'),
        t('investor_pitch.hiring.locations.london.roles.role4', 'DevOps Engineers')
      ],
      gradient: "linear-gradient(135deg, var(--color-orange-310), var(--color-orange-510))"
    }
  ];

  const positions = [
    {
      title: t('investor_pitch.hiring.positions.ai_engineer', 'AI Engineer'),
      icon: <Code size={20} />,
      gradient: "blue"
    },
    {
      title: t('investor_pitch.hiring.positions.product_manager', 'Product Manager'),
      icon: <Briefcase size={20} />,
      gradient: "green"
    },
    {
      title: t('investor_pitch.hiring.positions.data_scientist', 'Data Scientist'),
      icon: <Database size={20} />,
      gradient: "orange"
    },
    {
      title: t('investor_pitch.hiring.positions.full_stack', 'Full-Stack Developer'),
      icon: <Code size={20} />,
      gradient: "blue"
    },
    {
      title: t('investor_pitch.hiring.positions.insurance_specialist', 'Insurance Specialist'),
      icon: <Briefcase size={20} />,
      gradient: "green"
    },
    {
      title: t('investor_pitch.hiring.positions.sales_executive', 'Sales Executive'),
      icon: <Briefcase size={20} />,
      gradient: "orange"
    },
    {
      title: t('investor_pitch.hiring.positions.marketing', 'Marketing Strategist'),
      icon: <Briefcase size={20} />,
      gradient: "blue"
    },
    {
      title: t('investor_pitch.hiring.positions.business_analyst', 'Business Analyst'),
      icon: <Database size={20} />,
      gradient: "green"
    },
    {
      title: t('investor_pitch.hiring.positions.compliance', 'Compliance Officer'),
      icon: <Briefcase size={20} />,
      gradient: "orange"
    }
  ];

  const timeline = [
    {
      period: t('investor_pitch.hiring.timeline.phase1.period', 'Q1-Q2 2024'),
      phase: t('investor_pitch.hiring.timeline.phase1.name', 'Initial Team Building'),
      hires: 10,
      progress: 30,
      color: "var(--color-blue-310)"
    },
    {
      period: t('investor_pitch.hiring.timeline.phase2.period', 'Q3-Q4 2024'),
      phase: t('investor_pitch.hiring.timeline.phase2.name', 'Core Team Expansion'),
      hires: 25,
      progress: 50,
      color: "var(--color-green-310)"
    },
    {
      period: t('investor_pitch.hiring.timeline.phase3.period', 'Q1-Q2 2025'),
      phase: t('investor_pitch.hiring.timeline.phase3.name', 'Global Scaling'),
      hires: 50,
      progress: 85,
      color: "var(--color-orange-310)"
    }
  ];

  const compensation = [
    {
      title: t('investor_pitch.hiring.compensation.base.title', 'Base Salary'),
      range: t('investor_pitch.hiring.compensation.base.range', '$120K - $250K'),
      details: t('investor_pitch.hiring.compensation.base.details', 'Competitive base salary based on experience and location'),
      icon: <DollarSign size={24} />,
      color: "var(--color-blue-310)"
    },
    {
      title: t('investor_pitch.hiring.compensation.equity.title', 'Equity'),
      range: t('investor_pitch.hiring.compensation.equity.range', '0.1% - 1.5%'),
      details: t('investor_pitch.hiring.compensation.equity.details', 'Meaningful equity with 4-year vesting and 1-year cliff'),
      icon: <Award size={24} />,
      color: "var(--color-green-310)"
    },
    {
      title: t('investor_pitch.hiring.compensation.benefits.title', 'Benefits'),
      range: t('investor_pitch.hiring.compensation.benefits.range', 'Comprehensive'),
      details: t('investor_pitch.hiring.compensation.benefits.details', 'Health, dental, vision, 401k/RRSP matching, unlimited PTO'),
      icon: <Gift size={24} />,
      color: "var(--color-orange-310)"
    }
  ];

  // Animation variants
  const pageTransitionVariants = {
    initial: {
      opacity: 0,
      y: 20,
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut"
      }
    },
    exit: {
      opacity: 0,
      y: -20,
      transition: {
        duration: 0.3
      }
    }
  };

  const staggerContainer = {
    animate: {
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.2,
      },
    },
  };

  const staggerItem = {
    initial: { opacity: 0, y: 20 },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut",
      },
    },
  };

  return (
    <motion.div
      className={styles.container}
      variants={ANIMATION_VARIANTS.stagger.container}
      initial="hidden"
      animate="visible"
    >
      <motion.div 
        className={styles.header}
        variants={ANIMATION_VARIANTS.fadeIn}
      >
        <h2 className={styles.title}>
          {t('investor_pitch.hiring.title', 'Join Our Mission')}
        </h2>
        <p className={styles.subtitle}>
          {t('investor_pitch.hiring.subtitle', 'We\'re building the future of insurance with world-class talent')}
        </p>
      </motion.div>

      <div className={styles.content}>
        <div className={styles.navigationControls}>
          <button 
            className={styles.navButton} 
            onClick={handlePrev}
            aria-label="Previous section"
          >
            <ChevronLeft size={24} />
          </button>
          <div className={styles.indicators}>
            {Array.from({ length: totalSections }).map((_, index) => (
              <button
                key={index}
                className={`${styles.indicator} ${activeSection === index ? styles.activeIndicator : ''}`}
                onClick={() => setActiveSection(index)}
                aria-label={`Go to section ${index + 1}`}
              />
            ))}
          </div>
          <button 
            className={styles.navButton} 
            onClick={handleNext}
            aria-label="Next section"
          >
            <ChevronRight size={24} />
          </button>
        </div>

        <AnimatePresence mode="wait">
          {activeSection === 0 && (
            <motion.div
              key="missionSection"
              className={styles.missionSection}
              variants={pageTransitionVariants}
              initial="initial"
              animate="animate"
              exit="exit"
            >
              <motion.div 
                className={styles.missionContent}
                variants={staggerContainer}
                initial="initial"
                animate="animate"
              >
                <motion.h3 
                  className={styles.missionTitle}
                  variants={staggerItem}
                >
                  <span className={styles.gradientText}>Redefine Risk Management</span>
                  <br />Through Safe Super Intelligence
                </motion.h3>
                
                <motion.div
                  className={styles.buttonWrapper}
                  variants={staggerItem}
                >
                  <motion.a
                    href="https://allegory.app/join"
                    className={styles.ctaButton}
                    whileHover={{
                      translateY: -5,
                      boxShadow: "0 10px 25px rgba(35, 72, 237, 0.3)"
                    }}
                    whileTap={{
                      translateY: 0,
                      boxShadow: "0 5px 15px rgba(35, 72, 237, 0.2)"
                    }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Apply Now: allegory.app/join
                  </motion.a>
                </motion.div>
              </motion.div>
            </motion.div>
          )}

          {activeSection === 1 && (
            <motion.div
              key="locationsSection"
              className={styles.locationsSection}
              variants={pageTransitionVariants}
              initial="initial"
              animate="animate"
              exit="exit"
            >
              <motion.div 
                className={styles.locationsGrid}
                variants={staggerContainer}
                initial="initial"
                animate="animate"
              >
                {locations.map((location, index) => (
                  <motion.div
                    key={location.city}
                    className={styles.locationCard}
                    variants={staggerItem}
                    custom={index}
                  >
                    <div className={styles.locationHeader}>
                      <div 
                        className={styles.locationIconWrapper}
                        style={{ background: location.gradient }}
                      >
                        <MapPin className={styles.locationIcon} />
                      </div>
                      <div className={styles.locationInfo}>
                        <h3 className={styles.locationCity}>{location.city}</h3>
                        <p className={styles.locationCountry}>{location.country}</p>
                      </div>
                    </div>

                    <div className={styles.locationTimeline}>
                      <Calendar className={styles.timelineIcon} />
                      <span>{location.timeline}</span>
                    </div>

                    <div className={styles.locationHeadcount}>
                      <div className={styles.headcountHeader}>
                        <Users className={styles.headcountIcon} />
                        <span>{`Target: ${location.headcount} employees`}</span>
                      </div>
                      <div className={styles.progressBarContainer}>
                        <div 
                          className={styles.progressBar}
                          style={{ 
                            width: '50%',
                            background: location.gradient
                          }}
                        />
                      </div>
                    </div>

                    <div className={styles.locationRoles}>
                      {location.roles.map((role, roleIndex) => (
                        <div key={roleIndex} className={styles.locationRole}>
                          {role}
                        </div>
                      ))}
                    </div>
                  </motion.div>
                ))}
              </motion.div>
            </motion.div>
          )}

          {activeSection === 2 && (
            <motion.div
              key="positionsSection"
              className={styles.positionsSection}
              variants={pageTransitionVariants}
              initial="initial"
              animate="animate"
              exit="exit"
            >
              <motion.h3 
                className={styles.sectionTitle}
                variants={staggerItem}
              >
                <motion.span
                  className={styles.counter}
                  animate={{
                    scale: [1, 1.2, 1],
                    transition: { duration: 0.5 }
                  }}
                >
                  54
                </motion.span>
                {' '}Open Positions
              </motion.h3>
              
              <motion.div
                className={styles.positionsGrid}
                variants={staggerContainer}
                initial="initial"
                animate="animate"
              >
                {positions.map((position, index) => (
                  <motion.div
                    key={position.title}
                    className={`${styles.positionCard} ${styles[position.gradient]}`}
                    variants={staggerItem}
                    custom={index}
                    whileHover={{
                      scale: 1.05,
                      boxShadow: "0 10px 25px rgba(0, 0, 0, 0.2)"
                    }}
                  >
                    <span className={styles.positionIcon}>
                      {position.icon}
                    </span>
                    <span className={styles.positionTitle}>
                      {position.title}
                    </span>
                  </motion.div>
                ))}
              </motion.div>
              
              <motion.div 
                className={styles.timelineContainer}
                variants={staggerItem}
              >
                <h3 className={styles.timelineTitle}>Hiring Timeline</h3>
                <div className={styles.timeline}>
                  {timeline.map((phase, index) => (
                    <div key={phase.period} className={styles.timelinePhase}>
                      <div className={styles.phaseHeader}>
                        <div className={styles.phasePeriod}>{phase.period}</div>
                        <div className={styles.phaseHires}>{`${phase.hires} hires`}</div>
                      </div>
                      <div className={styles.phaseContent}>
                        <div className={styles.phaseName}>{phase.phase}</div>
                        <div className={styles.progressBarContainer}>
                          <div 
                            className={styles.progressBar}
                            style={{ 
                              width: `${phase.progress}%`,
                              background: phase.color
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </motion.div>
            </motion.div>
          )}

          {activeSection === 3 && (
            <motion.div
              key="compensationSection"
              className={styles.compensationSection}
              variants={pageTransitionVariants}
              initial="initial"
              animate="animate"
              exit="exit"
            >
              <motion.h3 
                className={styles.sectionTitle}
                variants={staggerItem}
              >
                Compensation & Benefits
              </motion.h3>
              
              <motion.div
                className={styles.compensationGrid}
                variants={staggerContainer}
                initial="initial"
                animate="animate"
              >
                {compensation.map((item, index) => (
                  <motion.div
                    key={item.title}
                    className={styles.compensationCard}
                    variants={staggerItem}
                    custom={index}
                    whileHover={{
                      y: -10,
                      boxShadow: "0 15px 30px rgba(0, 0, 0, 0.2)"
                    }}
                  >
                    <div 
                      className={styles.compensationIconWrapper}
                      style={{ color: item.color }}
                    >
                      {item.icon}
                    </div>
                    <h4 className={styles.compensationTitle}>{item.title}</h4>
                    <div className={styles.compensationRange} style={{ color: item.color }}>
                      {item.range}
                    </div>
                    <p className={styles.compensationDetails}>
                      {item.details}
                    </p>
                  </motion.div>
                ))}
              </motion.div>
              
              <motion.div 
                className={styles.buttonWrapper}
                variants={staggerItem}
              >
                <motion.a
                  href="https://allegory.app/join"
                  className={styles.ctaButton}
                  whileHover={{
                    translateY: -5,
                    boxShadow: "0 10px 25px rgba(35, 72, 237, 0.3)"
                  }}
                  whileTap={{
                    translateY: 0,
                    boxShadow: "0 5px 15px rgba(35, 72, 237, 0.2)"
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Join Our Team
                </motion.a>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </motion.div>
  );
};

export default HiringSlide;