// src/components/charts/TripEvents/AttentiveDrivingMap.js
import React from 'react';
import MapboxWrapper from '../../Map/MapboxWrapper';
import { MAPBOX_ACCESS_TOKEN } from '../../../config/constants';
import { parseRouteData } from '../../../utils/mapHelpers';

const AttentiveDrivingMap = ({ width, height, tripEventsData }) => {
  const routeCoordinates = parseRouteData(tripEventsData);

  if (routeCoordinates.length === 0) {
    return <div>No valid route coordinates found</div>;
  }

  const data = [
    {
      type: 'scattermapbox',
      lat: routeCoordinates.map(coord => coord.lat),
      lon: routeCoordinates.map(coord => coord.lng),
      mode: 'lines',
      line: {
        width: 2,
        color: 'rgb(44,180,131)',
      },
      name: 'Trip Route',
    },
    {
      type: 'scattermapbox',
      lat: [routeCoordinates[0].lat],
      lon: [routeCoordinates[0].lng],
      mode: 'markers',
      marker: {
        size: 14,
        color: 'rgb(54, 103, 248)',
      },
      name: 'Start',
    },
    {
      type: 'scattermapbox',
      lat: [routeCoordinates[routeCoordinates.length - 1].lat],
      lon: [routeCoordinates[routeCoordinates.length - 1].lng],
      mode: 'markers',
      marker: {
        size: 14,
        color: 'rgb(248,199,54)',
      },
      name: 'End',
    },
  ];

  const layout = {
    autosize: true,
    hovermode: 'closest',
    mapbox: {
      bearing: 0,
      center: {
        lat: routeCoordinates[0].lat,
        lon: routeCoordinates[0].lng,
      },
      pitch: 0,
      zoom: 10,
    },
    showlegend: false,
    margin: { l: 0, r: 0, t: 0, b: 0 },
  };

  return (
    <MapboxWrapper
      data={data}
      layout={layout}
      style={{ width, height }}
    />
  );
};

export default AttentiveDrivingMap;