// File: frontend/src/components/UploadSection/UploadArea.js

import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useLanguage } from '../../contexts/LanguageContext';
import styles from './UploadArea.module.css';

const UploadArea = ({ onFileChange, acceptedFileTypes }) => {
  const { t } = useLanguage();

  // Create a proper accept object for react-dropzone
  const getAcceptObject = (fileTypes) => {
    if (fileTypes === '.pdf') {
      return {
        'application/pdf': ['.pdf']
      };
    }
    if (fileTypes === '.csv') {
      return {
        'text/csv': ['.csv']
      };
    }
    // Add more file types as needed
    return {};
  };

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      onFileChange(acceptedFiles[0]);
    }
  }, [onFileChange]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: getAcceptObject(acceptedFileTypes),
    multiple: false,
    maxSize: 5 * 1024 * 1024 // 5MB
  });

  return (
    <div
      {...getRootProps()}
      className={`${styles.uploadArea} ${isDragActive ? styles.active : ''}`}
    >
      <input {...getInputProps()} />
      <img className={styles.icon} alt="" src="/icons/vectors/upload_icon.svg" />
      <div className={styles.content}>
        <p className={styles.instruction}>
          {t('uploadArea.dragAndDrop')} <button type="button" className={styles.chooseFile}>{t('uploadArea.chooseFile')}</button> 
        </p>
        <p className={styles.supportedFile}>
          {t('uploadArea.supportedFile', { fileTypes: acceptedFileTypes })}
        </p>
      </div>
    </div>
  );
};

export default UploadArea;