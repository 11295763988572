// File: frontend/src/components/DrivingScore/DrivingScoreMaps.js

import React, { useState, useEffect, useRef } from 'react';
import styles from './DrivingScoreMaps.module.css';
import GeographyProvinces from '../charts/Geography/GeographyProvinces';
import GeographyPostalCodes from '../charts/Geography/GeographyPostalCodes';
import GeographyCoordinates from '../charts/Geography/GeographyCoordinates';

const DrivingScoreMaps = () => {
  const [activeMap, setActiveMap] = useState('provinces');
  const [mapDimensions, setMapDimensions] = useState({ width: 1146, height: 684 });
  const mapContainerRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (mapContainerRef.current) {
        const { width, height } = mapContainerRef.current.getBoundingClientRect();
        setMapDimensions({ width, height });
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const renderMap = () => {
    const props = {
      width: mapDimensions.width,
      height: mapDimensions.height,
    };

    switch (activeMap) {
      case 'provinces':
        return <GeographyProvinces {...props} />;
      case 'postalCodes':
        return <GeographyPostalCodes {...props} />;
      case 'coordinates':
        return <GeographyCoordinates {...props} />;
      default:
        return null;
    }
  };

  return (
    <div className={styles.card}>
      <div className={styles.cardStandartTitleRow}>
        <div className={styles.textParent}>
          <div className={styles.text}>Driving Score Maps</div>
          <div className={styles.text1}>Focus on the evaluation of driving behaviors through mapped scores, categorized by province, postal code, and precise coordinates. This analysis assists in identifying areas with higher risks and implementing focused interventions to improve driving safety.</div>
        </div>
        <div className={styles.button}>
          <img className={styles.icons18MoreHorizontal} alt="" src="Icons/18/More-Horizontal.svg" />
        </div>
      </div>
      <div className={styles.cardStandartContent}>
        <div className={styles.cardMappingContainer}>
          <div className={styles.cardsTableSystem}>
            <div 
              className={`${styles.cardsTableSystemItem} ${activeMap === 'provinces' ? styles.active : ''}`}
              onClick={() => setActiveMap('provinces')}
            >
              <div className={styles.cardsTableSystemIcon}>
                <img className={styles.icons18MapPinned} alt="" src="Icons/18/Map-Pinned.svg" />
              </div>
              <div className={styles.texts}>
                <div className={styles.attentiveDriving}>Show by State or Province</div>
                <div className={styles.attentiveDrivingIs}>Explore driving scores by province to identify trends and areas for improvement.</div>
              </div>
            </div>
            <div 
              className={`${styles.cardsTableSystemItem1} ${activeMap === 'postalCodes' ? styles.active : ''}`}
              onClick={() => setActiveMap('postalCodes')}
            >
              <div className={styles.cardsTableSystemIcon}>
                <img className={styles.icons18MapPinned} alt="" src="Icons/18/Map-Postal.svg" />
              </div>
              <div className={styles.texts}>
                <div className={styles.attentiveDriving1}>Show by Postal Code</div>
                <div className={styles.attentiveDrivingIs1}>Analyze driving scores by postal code for detailed behaviour and score patterns.</div>
              </div>
            </div>
            <div 
              className={`${styles.cardsTableSystemItem1} ${activeMap === 'coordinates' ? styles.active : ''}`}
              onClick={() => setActiveMap('coordinates')}
            >
              <div className={styles.cardsTableSystemIcon}>
                <img className={styles.icons18MapPinned} alt="" src="Icons/18/Map-Coordinates.svg" />
              </div>
              <div className={styles.texts}>
                <div className={styles.attentiveDriving1}>Show by Coordinates</div>
                <div className={styles.attentiveDrivingIs1}>Pinpoint driving events by coordinates to understand context and enhance safety.</div>
              </div>
            </div>
          </div>
          <div className={styles.cardMappingContainerC} ref={mapContainerRef}>
            <div className={styles.mapContainer}>
              {renderMap()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DrivingScoreMaps;