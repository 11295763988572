// File: frontend/src/pages/TermsOfService/TermsOfService.js

import React from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import styles from './TermsOfService.module.css';

const TermsOfService = () => {
  const { t } = useLanguage();

  const introduction = {
    content: 'conditions_of_use.introduction'
  };

  const servicesDescription = {
    title: 'conditions_of_use.services_description',
    content: 'conditions_of_use.services_description_content'
  };

  const accountCreation = {
    title: 'conditions_of_use.account_creation_and_use',
    content: 'conditions_of_use.account_creation_and_use_content'
  };

  const dataCollection = {
    title: 'conditions_of_use.data_collection_and_use',
    content: 'conditions_of_use.data_collection_and_use_content'
  };

  const userResponsibilities = {
    title: 'conditions_of_use.user_responsibilities',
    content: 'conditions_of_use.user_responsibilities_content'
  };

  const intellectualProperty = {
    title: 'conditions_of_use.intellectual_property_rights',
    content: 'conditions_of_use.intellectual_property_rights_content'
  };

  const userContent = {
    title: 'conditions_of_use.user_content',
    content: 'conditions_of_use.user_content_content'
  };

  const premiumServices = {
    title: 'conditions_of_use.premium_services_and_payments',
    content: 'conditions_of_use.premium_services_and_payments_content'
  };

  const disclaimerOfWarranties = {
    title: 'conditions_of_use.disclaimer_of_warranties',
    content: 'conditions_of_use.disclaimer_of_warranties_content'
  };

  const limitationOfLiability = {
    title: 'conditions_of_use.limitation_of_liability',
    content: 'conditions_of_use.limitation_of_liability_content'
  };

  const indemnification = {
    title: 'conditions_of_use.indemnification',
    content: 'conditions_of_use.indemnification_content'
  };

  const modifications = {
    title: 'conditions_of_use.modifications_to_services_and_agreement',
    content: 'conditions_of_use.modifications_to_services_and_agreement_content'
  };

  const governingLaw = {
    title: 'conditions_of_use.governing_law_and_jurisdiction',
    content: 'conditions_of_use.governing_law_and_jurisdiction_content'
  };

  const termination = {
    title: 'conditions_of_use.termination',
    content: 'conditions_of_use.termination_content'
  };

  const aiDisclosure = {
    title: 'conditions_of_use.ai_and_data_analytics_disclosure',
    content: 'conditions_of_use.ai_and_data_analytics_disclosure_content'
  };

  const mobileApp = {
    title: 'conditions_of_use.mobile_application_use',
    content: 'conditions_of_use.mobile_application_use_content'
  };

  const contactInformation = {
    title: 'conditions_of_use.contact_information',
    content: 'conditions_of_use.contact_information_content'
  };

  const acknowledgment = {
    content: 'conditions_of_use.agreement_acknowledgment'
  };

  return (
    <div className={styles.conditionsOfUseContainer}>
      <h1 className={styles.title}>{t('conditions_of_use_title')}</h1>
      <p className={styles.lastUpdated}>{t('conditions_of_use_last_updated')}</p>
      <p className={styles.effectiveDate}>{t('conditions_of_use_effective_date')}</p>

      <section className={styles.section}>
        <p>{t(introduction.content)}</p>
      </section>

      <section className={styles.section}>
        <h2>{t(servicesDescription.title)}</h2>
        <p>{t(servicesDescription.content)}</p>
      </section>

      <section className={styles.section}>
        <h2>{t(accountCreation.title)}</h2>
        <p>{t(accountCreation.content)}</p>
      </section>

      <section className={styles.section}>
        <h2>{t(dataCollection.title)}</h2>
        <p>{t(dataCollection.content)}</p>
      </section>

      <section className={styles.section}>
        <h2>{t(userResponsibilities.title)}</h2>
        <p>{t(userResponsibilities.content)}</p>
      </section>

      <section className={styles.section}>
        <h2>{t(intellectualProperty.title)}</h2>
        <p>{t(intellectualProperty.content)}</p>
      </section>

      <section className={styles.section}>
        <h2>{t(userContent.title)}</h2>
        <p>{t(userContent.content)}</p>
      </section>

      <section className={styles.section}>
        <h2>{t(premiumServices.title)}</h2>
        <p>{t(premiumServices.content)}</p>
      </section>

      <section className={styles.section}>
        <h2>{t(disclaimerOfWarranties.title)}</h2>
        <p>{t(disclaimerOfWarranties.content)}</p>
      </section>

      <section className={styles.section}>
        <h2>{t(limitationOfLiability.title)}</h2>
        <p>{t(limitationOfLiability.content)}</p>
      </section>

      <section className={styles.section}>
        <h2>{t(indemnification.title)}</h2>
        <p>{t(indemnification.content)}</p>
      </section>

      <section className={styles.section}>
        <h2>{t(modifications.title)}</h2>
        <p>{t(modifications.content)}</p>
      </section>

      <section className={styles.section}>
        <h2>{t(governingLaw.title)}</h2>
        <p>{t(governingLaw.content)}</p>
      </section>

      <section className={styles.section}>
        <h2>{t(termination.title)}</h2>
        <p>{t(termination.content)}</p>
      </section>

      <section className={styles.section}>
        <h2>{t(aiDisclosure.title)}</h2>
        <p>{t(aiDisclosure.content)}</p>
      </section>

      <section className={styles.section}>
        <h2>{t(mobileApp.title)}</h2>
        <p>{t(mobileApp.content)}</p>
      </section>

      <section className={styles.section}>
        <h2>{t(contactInformation.title)}</h2>
        <p>{t(contactInformation.content)}</p>
      </section>

      <section className={styles.section}>
        <p>{t(acknowledgment.content)}</p>
      </section>
    </div>
  );
};

export default TermsOfService;