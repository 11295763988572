// File: frontend/src/pages/InvestorPitch/components/slides/MarketPosition/MarketPosition.js

import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import { ANIMATION_VARIANTS } from '../../../constants/animations';
import styles from './MarketPosition.module.css';
import { COMPETITOR_DATA } from './competitors.data';
import { formatCurrency } from '../../../../../utils/formatters';

const CompetitorModal = ({ competitor, onClose }) => {
  if (!competitor) return null;
  
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long' });
  };

  return (
    <motion.div 
      className={styles.modalOverlay}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onClick={onClose}
    >
      <motion.div 
        className={styles.modalContent}
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 50, opacity: 0 }}
        onClick={e => e.stopPropagation()}
      >
        <button className={styles.closeButton} onClick={onClose}>×</button>
        
        <div className={styles.modalHeader}>
          <h3 className={styles.modalTitle}>{competitor.name}</h3>
        </div>
        
        <div className={styles.modalBody}>
          <div className={styles.modalInfo}>
            <div className={styles.infoItem}>
              <span className={styles.infoLabel}>Founded:</span>
              <span className={styles.infoValue}>{competitor.founded}</span>
            </div>
            
            <div className={styles.infoItem}>
              <span className={styles.infoLabel}>Latest Valuation:</span>
              <span className={styles.infoValue}>
                {formatCurrency(competitor.latest_valuation)}
                <span className={styles.valuationDate}>
                  ({formatDate(competitor.latest_valuation_date)})
                </span>
              </span>
            </div>
          </div>
          
          <div className={styles.strengthsWeaknesses}>
            <div className={styles.section}>
              <h4 className={styles.sectionTitle}>Strengths</h4>
              <ul className={styles.bulletList}>
                {competitor.strengths.map((strength, index) => (
                  <li key={index} className={styles.bulletItem}>{strength}</li>
                ))}
              </ul>
            </div>
            
            <div className={styles.section}>
              <h4 className={styles.sectionTitle}>Weaknesses</h4>
              <ul className={styles.bulletList}>
                {competitor.weaknesses.map((weakness, index) => (
                  <li key={index} className={styles.bulletItem}>{weakness}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

const MarketPosition = () => {
  const { t } = useLanguage();
  const [selectedCompetitor, setSelectedCompetitor] = useState(null);

  const capabilities = [
    { id: 'products' },
    { id: 'ai_agents' },
    { id: 'nonstop' },
    { id: 'model' }
  ];

  const handleCompanyClick = (company, e) => {
    e.preventDefault();
    setSelectedCompetitor(company);
  };

  const closeModal = () => {
    setSelectedCompetitor(null);
  };

  return (
    <motion.div
      className={styles.container}
      variants={ANIMATION_VARIANTS.stagger.container}
      initial="hidden"
      animate="visible"
    >
      <motion.div 
        className={styles.header}
        variants={ANIMATION_VARIANTS.fadeIn}
      >
        <h2 className={styles.title}>
          {t('investor_pitch.market_position.title')}
        </h2>
        <p className={styles.subtitle}>
          {t('investor_pitch.market_position.subtitle')}
        </p>
      </motion.div>

      <div className={styles.content}>
        <motion.div 
          className={styles.allegorySection}
          variants={ANIMATION_VARIANTS.fadeIn}
        >
          <div className={styles.allegoryContainer}>
            <div className={styles.logoWrapper}>
              <img 
                src="/logos/allegory-logo-blue.svg" 
                alt="Allegory" 
                className={styles.logo}
              />
            </div>
            
            <div className={styles.capabilities}>
              {capabilities.map((capability) => (
                <motion.div
                  key={capability.id}
                  className={styles.capabilityCard}
                  variants={ANIMATION_VARIANTS.stagger.item}
                >
                  <span className={styles.capabilityText}>
                    {t(`investor_pitch.market_position.capabilities.${capability.id}`, capability.text)}
                  </span>
                </motion.div>
              ))}
            </div>
          </div>
        </motion.div>

        <div className={styles.comparisonContainer}>
          <motion.div 
            className={styles.arrow}
            variants={ANIMATION_VARIANTS.fadeIn}
          >
            &gt;
          </motion.div>
        </div>

        <motion.div 
          className={styles.competitorsSection}
          variants={ANIMATION_VARIANTS.fadeIn}
        >
          {Object.entries(COMPETITOR_DATA).map(([category, { companies }]) => (
            <motion.div
              key={category}
              className={styles.competitorCategory}
              variants={ANIMATION_VARIANTS.stagger.item}
            >
              <h3 className={styles.categoryTitle}>
                {t(`investor_pitch.market_position.categories.${category.toLowerCase()}`, category)}
              </h3>
              <div className={styles.companies}>
                {companies.map((company) => (
                  <motion.div
                    key={company.id}
                    className={styles.company}
                    onClick={(e) => handleCompanyClick(company, e)}
                    variants={ANIMATION_VARIANTS.stagger.item}
                    whileHover={{ scale: 1.05 }}
                    title={`Click to view details about ${company.name}`}
                  >
                    <span className={styles.companyName}>{company.name}</span>
                  </motion.div>
                ))}
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>

      <AnimatePresence>
        {selectedCompetitor && (
          <CompetitorModal 
            competitor={selectedCompetitor} 
            onClose={closeModal} 
          />
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default MarketPosition;