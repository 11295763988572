// File: frontend/src/pages/InvestorPitch/CustomerInsurers.js

import React from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import PitchDeck from './PitchDeck';
import { getCustomerInsurersSlides } from './configs/CustomerInsurersConfig';

const CustomerInsurers = () => {
  const { t } = useLanguage();
  const slides = getCustomerInsurersSlides(t);
  
  return <PitchDeck slides={slides} deckId="customer-insurers" />;
};

export default CustomerInsurers;