// File: frontend/src/components/Home/ProductDevSection.js

import React, { useRef, useEffect, useState, lazy, Suspense } from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import LandingButton from '../../components/Button/LandingButton';
import styles from './ProductDevSection.module.css';

const ProductDevelopmentAnimation = lazy(() => import('./ProductDevelopmentAnimation'));

const ProductDevSection = () => {
  const { t } = useLanguage();
  const [isVisible, setIsVisible] = useState(false);
  const [isIntersecting, setIsIntersecting] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        const visibleRatio = entry.intersectionRatio;
        
        setIsIntersecting(entry.isIntersecting);
        setIsVisible(visibleRatio >= 0.2);
      },
      { 
        threshold: [0, 0.2, 0.4, 0.6, 0.8, 1.0],
        rootMargin: '50px 0px'
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <section 
      ref={sectionRef} 
      className={styles.productDevSection}
      aria-label={t('product_dev_section_aria_label')}
    >
      <div className={styles.container}>
        <Suspense 
          fallback={
            <div className={styles.animationPlaceholder}>
              <div className={styles.loadingIndicator}>
                {t('loading_animation')}
              </div>
            </div>
          }
        >
          {isIntersecting && <ProductDevelopmentAnimation />}
        </Suspense>
        {/* <div className={styles.content}>
          <h2 className={styles.title}>{t('product_dev_title')}</h2>
          <p className={styles.description}>{t('product_dev_description')}</p>
          <LandingButton 
            href="/news" 
            className={styles.ctaButton}
            aria-label={t('discover_innovations')}
          >
            {t('discover_innovations')}
          </LandingButton>
        </div> */}
      </div>
    </section>
  );
};

export default ProductDevSection;