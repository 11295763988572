// File: frontend/src/pages/PropertyDetail/index.js

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useLanguage } from '../../contexts/LanguageContext';
import PropertyHeader from './components/PropertyHeader';
import PropertyOverview from './components/PropertyOverview';
import PropertyImages from './components/PropertyImages';
import PropertyLocation from './components/PropertyLocation';
import BuildingCharacteristics from './components/BuildingCharacteristics';
import BuildingSystems from './components/BuildingSystems';
import PropertyValuation from './components/PropertyValuation';
import PropertyRisk from './components/PropertyRisk';
import PropertyClaims from './components/PropertyClaims';
import PropertyOwnership from './components/PropertyOwnership';
import PropertyOccupancy from './components/PropertyOccupancy';
import PropertySafety from './components/PropertySafety';
import ExternalFeatures from './components/ExternalFeatures';
import styles from './PropertyDetail.module.css';

const PropertyDetail = () => {
  const { propertyId } = useParams();
  const navigate = useNavigate();
  const { t } = useLanguage();
  const [activeTab, setActiveTab] = useState('overview');
  const [property, setProperty] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPropertyDetails = async () => {
      setLoading(true);
      setError(null);
      
      try {
        // This would be replaced with an actual API call
        // const response = await propertyService.getPropertyDetails(propertyId);
        
        // Mock data for now
        await new Promise(resolve => setTimeout(resolve, 800));
        
        // Create a mock property using the schema structure
        const mockPropertyData = {
          property_id: propertyId,
          address_id: `addr-${propertyId.substring(0, 8)}`,
          property_type: "Residential",
          zoning_classification: "R1",
          is_income_property: false,
          land_area: 5000,
          area_measure: "sq ft",
          geographical_exposure: "Urban",
          exposure_characteristics: "Standard",
          fire_hall_distance: 2.5,
          fire_hall_distance_unit: "km",
          fire_hydrant_distance: 150,
          fire_hydrant_distance_unit: "m",
          fire_hall_location_id: null,
          fire_hall_name: "Station 12",
          protection_class: "Class 1",
          protection_class_basis: "Municipal Rating",
          additional_info: JSON.stringify({}),
          created_at: "2023-01-15T10:30:00Z",
          updated_at: "2023-06-20T14:45:00Z",
          
          // Master address
          masterAddress: {
            address_id: `addr-${propertyId.substring(0, 8)}`,
            address_line_1: "123 Main Street",
            address_line_2: null,
            address_line_3: null,
            street_number: "123",
            street_name: "Main Street",
            city: "Toronto",
            province_state_type: "Province",
            province_state_code: "ON",
            province_state_name: "Ontario",
            country_code: "CA",
            postal_code: "M5V 2N4",
            location: null,
            altitude: 100,
            is_on_land: true,
            is_on_water: false,
            is_po_box: false,
            google_place_id: "ChIJpTvG15DL1IkRd8S0KlBVNTI",
            google_geocode_results: JSON.stringify({}),
            azure_place_id: null,
            azure_geocode_results: null,
            is_verified: true,
            verification_date: "2023-01-15T10:30:00Z",
            data_source: "User Input",
            last_updated_source: "Google Maps API",
            last_validated_date: "2023-06-20T14:45:00Z",
            additional_info: JSON.stringify({}),
            created_at: "2023-01-15T10:30:00Z",
            updated_at: "2023-06-20T14:45:00Z"
          },
          
          // Building information
          building: {
            building_id: `bldg-${propertyId.substring(0, 8)}`,
            property_id: propertyId,
            building_type: "Single Family",
            main_type: "Detached",
            specific_type: "Two-Story",
            height_style: "Standard",
            dwelling_type: "Single Family",
            architectural_style: "Colonial",
            layout_style: "Traditional",
            year_built: 2005,
            year_rebuilt: null,
            last_renovation_date: "2018-05-10",
            number_of_floors: 2,
            number_of_stories: 2,
            total_area: 2500,
            area_measure: "sq ft",
            primary_use: "Residential",
            construction_type: "Wood Frame",
            is_log_construction: false,
            foundation_type: "Concrete",
            roof_type: "Asphalt Shingle",
            exterior_wall_type: "Vinyl Siding",
            exterior_material: "Vinyl",
            basement_type: "Full",
            additional_info: JSON.stringify({}),
            created_at: "2023-01-15T10:30:00Z",
            updated_at: "2023-06-20T14:45:00Z"
          },
          
          // Unit information
          unit: {
            unit_id: `unit-${propertyId.substring(0, 8)}`,
            building_id: `bldg-${propertyId.substring(0, 8)}`,
            unit_number: null,
            unit_type: "Main Residence",
            floor: "All",
            living_area: 2500,
            area_measure: "sq ft",
            number_of_bedrooms: 4,
            number_of_full_bathrooms: 2,
            number_of_half_bathrooms: 1,
            number_of_kitchens: 1,
            number_of_dens: 1,
            has_basement_apartment: false,
            kitchen_finishes: "Granite",
            additional_info: JSON.stringify({}),
            created_at: "2023-01-15T10:30:00Z",
            updated_at: "2023-06-20T14:45:00Z"
          },
          
          // Building systems
          buildingSystems: {
            system_id: `sys-${propertyId.substring(0, 8)}`,
            building_id: `bldg-${propertyId.substring(0, 8)}`,
            heating_type: "Forced Air",
            cooling_type: "Central Air",
            system_type: "HVAC",
            primary_heating_system: "Gas Furnace",
            primary_heating_fuel_type: "Natural Gas",
            primary_heating_installation_date: "2015-03-15",
            primary_heating_approval_date: "2015-03-15",
            primary_heating_oil_tank: false,
            primary_heating_oil_tank_installation_date: null,
            cooling_system: "Central Air Conditioning",
            secondary_heat: "Electric Baseboard",
            secondary_heat_approved: true,
            secondary_heat_date: "2015-03-15",
            secondary_heat_oil_tank: false,
            secondary_heat_oil_tank_date: null,
            additional_info: JSON.stringify({}),
            created_at: "2023-01-15T10:30:00Z",
            updated_at: "2023-06-20T14:45:00Z"
          },
          
          // Safety features
          safetyFeatures: {
            safety_feature_id: `safe-${propertyId.substring(0, 8)}`,
            property_id: propertyId,
            building_id: `bldg-${propertyId.substring(0, 8)}`,
            fire_alarm_type: "Smoke Detectors",
            burglar_alarm_type: "Motion Sensors",
            fire_detection_system: true,
            security_system: true,
            water_leak_detection_system: false,
            sump_pump_type: "Standard",
            sump_pump_aux_power: true,
            back_flow_valve: true,
            has_sump_pump_pit: true,
            is_sump_pump_alarmed: true,
            building_protection: "Standard",
            loss_control_measures: JSON.stringify({}),
            loss_prevention_device: JSON.stringify({}),
            monitored_alarm_systems: JSON.stringify({}),
            created_at: "2023-01-15T10:30:00Z",
            updated_at: "2023-06-20T14:45:00Z"
          },
          
          // Risk and insurance
          riskAndInsurance: {
            risk_insurance_id: `risk-${propertyId.substring(0, 8)}`,
            property_id: propertyId,
            risk_factor_type: "Standard",
            risk_data: JSON.stringify({}),
            insurance_info_type: "Homeowners",
            insurance_info_data: JSON.stringify({}),
            peril: "Flood",
            provider: "Risk Assessment Inc.",
            score_date: "2023-06-01",
            score: 3.5,
            overall_probability: 0.02,
            building_damage_probability: 0.015,
            content_damage_probability: 0.01,
            decile: 3,
            zone_definition: "Low Risk",
            zone_provider: "FEMA",
            zone_type: "X",
            created_at: "2023-01-15T10:30:00Z",
            updated_at: "2023-06-20T14:45:00Z"
          },
          
          // Replacement cost
          replacementCost: {
            replacement_cost_id: `cost-${propertyId.substring(0, 8)}`,
            property_id: propertyId,
            replacement_cost_provider: "Allegory AI-1",
            replacement_cost_date: "2023-06-01",
            replacement_cost_amount: 450000,
            replacement_cost_amount_currency: "USD",
            created_at: "2023-01-15T10:30:00Z",
            updated_at: "2023-06-20T14:45:00Z"
          },
          
          // Occupancy and business
          occupancyAndBusiness: {
            occupancy_business_id: `occb-${propertyId.substring(0, 8)}`,
            property_id: propertyId,
            is_owner_occupied: true,
            occupancy_type: "Primary Residence",
            rental_situation: "Not Rented",
            ownership_type: "Individual",
            number_of_rented_units: 0,
            number_of_rented_rooms: 0,
            is_multi_unit: false,
            has_in_law_apartment: false,
            business_info_type: null,
            number_of_units: 1,
            business_info_data: JSON.stringify({}),
            created_at: "2023-01-15T10:30:00Z",
            updated_at: "2023-06-20T14:45:00Z"
          },
          
          // Mortgage
          mortgage: {
            mortgage_id: `mort-${propertyId.substring(0, 8)}`,
            property_id: propertyId,
            lender_name: "First National Bank",
            mortgage_amount: 350000,
            mortgage_amount_currency: "USD",
            mortgage_start_date: "2020-01-15",
            mortgage_term: 360,
            interest_rate: 3.25,
            mortgage_type: "Fixed",
            is_primary_mortgage: true,
            created_at: "2023-01-15T10:30:00Z",
            updated_at: "2023-06-20T14:45:00Z"
          },
          
          // External features
          externalFeatures: {
            external_feature_id: `feat-${propertyId.substring(0, 8)}`,
            property_id: propertyId,
            garage_type: "Attached",
            garage_size: "2-Car",
            attached_garages: true,
            parking_spaces: 2,
            swimming_pool: true,
            pool_type: "In-ground",
            pool_age: 5,
            created_at: "2023-01-15T10:30:00Z",
            updated_at: "2023-06-20T14:45:00Z"
          },
          
          // Claims
          claims: [
            {
              claim_id: `clm-${propertyId.substring(0, 8)}-1`,
              property_id: propertyId,
              claim_date: "2022-05-10",
              claim_status: "Closed",
              claim_type: "Water Damage",
              claim_description: "Basement flooding due to heavy rainfall",
              claim_amount: 15000,
              claim_currency: "USD",
              water_damage: true,
              fire_damage: false,
              theft: false,
              liability: false,
              weather_event_type: "Heavy Rain",
              coastal_impact: false,
              updated_at: "2022-06-15T09:20:00Z"
            },
            {
              claim_id: `clm-${propertyId.substring(0, 8)}-2`,
              property_id: propertyId,
              claim_date: "2020-07-22",
              claim_status: "Closed",
              claim_type: "Wind Damage",
              claim_description: "Roof damage from windstorm",
              claim_amount: 8500,
              claim_currency: "USD",
              water_damage: false,
              fire_damage: false,
              theft: false,
              liability: false,
              weather_event_type: "Windstorm",
              coastal_impact: false,
              updated_at: "2020-08-30T14:45:00Z"
            }
          ],
          
          // Images and videos
          images: [
            {
              property_id: propertyId,
              property_image_id: `img-${propertyId.substring(0, 8)}-1`,
              property_image_timestamp: "2023-01-15T10:30:00Z",
              property_image_source: "user",
              property_image_url: "/images/property_insight_sv_1.png",
              property_image_type: "image",
              property_image_ai_assessment: {
                condition: "Excellent",
                damage_detected: false,
                confidence: 0.95
              },
              updated_at: "2023-01-15T10:30:00Z"
            },
            {
              property_id: propertyId,
              property_image_id: `img-${propertyId.substring(0, 8)}-2`,
              property_image_timestamp: "2023-01-15T10:35:00Z",
              property_image_source: "user",
              property_image_url: "/images/property_insight_sv_2.png",
              property_image_type: "image",
              property_image_ai_assessment: {
                condition: "Excellent",
                damage_detected: false,
                confidence: 0.92
              },
              updated_at: "2023-01-15T10:35:00Z"
            },
            {
              property_id: propertyId,
              property_image_id: `img-${propertyId.substring(0, 8)}-3`,
              property_image_timestamp: "2023-01-15T10:40:00Z",
              property_image_source: "user",
              property_image_url: "/images/property_insight_sv_3.png",
              property_image_type: "image",
              property_image_ai_assessment: {
                condition: "Good",
                damage_detected: false,
                confidence: 0.90
              },
              updated_at: "2023-01-15T10:40:00Z"
            },
            {
              property_id: propertyId,
              property_image_id: `img-${propertyId.substring(0, 8)}-4`,
              property_image_timestamp: "2023-01-15T10:45:00Z",
              property_image_source: "user",
              property_image_url: "/images/property_insight_sv_4.png",
              property_image_type: "image",
              property_image_ai_assessment: {
                condition: "Good",
                damage_detected: false,
                confidence: 0.91
              },
              updated_at: "2023-01-15T10:45:00Z"
            },
            {
              property_id: propertyId,
              property_image_id: `vid-${propertyId.substring(0, 8)}-1`,
              property_image_timestamp: "2023-01-15T11:00:00Z",
              property_image_source: "drone",
              property_image_url: "/videos/property_insight_oblique.mov",
              property_image_type: "video",
              property_image_ai_assessment: {
                condition: "Excellent",
                damage_detected: false,
                confidence: 0.97
              },
              updated_at: "2023-01-15T11:00:00Z"
            },
            {
              property_id: propertyId,
              property_image_id: `vid-${propertyId.substring(0, 8)}-2`,
              property_image_timestamp: "2023-01-15T11:15:00Z",
              property_image_source: "drone",
              property_image_url: "/videos/property_insight_top_down.mov",
              property_image_type: "video",
              property_image_ai_assessment: {
                condition: "Excellent",
                damage_detected: false,
                confidence: 0.96
              },
              updated_at: "2023-01-15T11:15:00Z"
            }
          ],
          
          // Location
          location: {
            location_id: `loc-${propertyId.substring(0, 8)}`,
            location_type: "residential",
            is_primary: true,
            raw_address: "123 Main Street, Toronto, ON M5V 2N4",
            address_processing_status: "completed",
            allegory_address_confidence: 0.98,
            parsed_address: {
              street_number: "123",
              street_name: "Main Street",
              unit_number: null,
              city: "Toronto",
              province: "Ontario",
              state: null,
              postal_code: "M5V 2N4",
              zip_code: null,
              country: "Canada",
              country_code: "CA",
              formatted_address: "123 Main Street, Toronto, ON M5V 2N4",
            },
            geolocation: {
              latitude: 43.6532,
              longitude: -79.3832,
              accuracy: 8,
              place_id: "ChIJpTvG15DL1IkRd8S0KlBVNTI",
            },
            related_entity_id: propertyId,
            related_entity_type: "PROPERTY",
            created_at: "2023-01-15T10:30:00Z",
            updated_at: "2023-06-20T14:45:00Z",
          },
          
          // Territory
          territory: {
            territory_code: "TOR-001",
            urban_classification: "Urban",
            risk_factor: 0.8,
            theft_risk_zone: "Medium",
            montreal_island: false,
            hail_zone: "Low",
            resource_community: false,
            mountain_exposure: false,
            coastal_impact: false,
            flood_zone: "X",
            wildfire_zone: "Low",
            earthquake_zone: "Low",
            postal_code_risk_factor: 0.75,
            created_at: "2023-01-15T10:30:00Z",
            updated_at: "2023-06-20T14:45:00Z",
          },
          
          // Occupants
          occupants: [
            {
              occupant_id: `occ-${propertyId.substring(0, 8)}-1`,
              property_id: propertyId,
              first_name: "John",
              last_name: "Smith",
              date_of_birth: "1980-05-15",
              gender: "Male",
              marital_status: "Married",
              is_primary_resident: true,
              is_smoker: false,
              created_at: "2023-01-15T10:30:00Z",
              updated_at: "2023-06-20T14:45:00Z",
            },
            {
              occupant_id: `occ-${propertyId.substring(0, 8)}-2`,
              property_id: propertyId,
              first_name: "Jane",
              last_name: "Smith",
              date_of_birth: "1982-08-22",
              gender: "Female",
              marital_status: "Married",
              is_primary_resident: true,
              is_smoker: false,
              created_at: "2023-01-15T10:30:00Z",
              updated_at: "2023-06-20T14:45:00Z",
            }
          ],
          
          // Risk assessment
          riskAssessment: {
            risk_id: `ra-${propertyId.substring(0, 8)}`,
            property_id: propertyId,
            protection_class: 1,
            distance_to_fire_hydrant: 150,
            distance_to_fire_station: 2.5,
            flood_zone: "X",
            earthquake_zone: "Low",
            alarm_type: "Central",
            building_age: 18,
            special_risk_factors: ["None"],
            created_at: "2023-01-15T10:30:00Z",
            updated_at: "2023-06-20T14:45:00Z",
            obc_compliance: true,
            ccq_compliance: false,
            heritage_building: false,
            hurricane_protection: false,
            coastal_construction: false,
          }
        };
        
        setProperty(mockPropertyData);
      } catch (err) {
        console.error('Error fetching property details:', err);
        setError(t('failed_to_load_property_details'));
      } finally {
        setLoading(false);
      }
    };

    if (propertyId) {
      fetchPropertyDetails();
    }
  }, [propertyId, t]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleBack = () => {
    navigate('/property-insight');
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        {t('loading_property_details')}
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.error}>
        <h2>{t('error_loading_property')}</h2>
        <p>{error}</p>
        <button className={styles.backButton} onClick={handleBack}>
          {t('back_to_property_insight')}
        </button>
      </div>
    );
  }

  if (!property) {
    return (
      <div className={styles.notFound}>
        <h2>{t('property_not_found')}</h2>
        <p>{t('property_not_found_message')}</p>
        <button className={styles.backButton} onClick={handleBack}>
          {t('back_to_property_insight')}
        </button>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <PropertyHeader property={property} onBack={handleBack} />
      
      <div className={styles.content}>
        <div className={styles.tabs}>
          <button 
            className={`${styles.tab} ${activeTab === 'overview' ? styles.activeTab : ''}`}
            onClick={() => handleTabChange('overview')}
          >
            {t('overview')}
          </button>
          <button 
            className={`${styles.tab} ${activeTab === 'images' ? styles.activeTab : ''}`}
            onClick={() => handleTabChange('images')}
          >
            {t('images')}
          </button>
          <button 
            className={`${styles.tab} ${activeTab === 'location' ? styles.activeTab : ''}`}
            onClick={() => handleTabChange('location')}
          >
            {t('location')}
          </button>
          <button 
            className={`${styles.tab} ${activeTab === 'building' ? styles.activeTab : ''}`}
            onClick={() => handleTabChange('building')}
          >
            {t('building_characteristics')}
          </button>
          <button 
            className={`${styles.tab} ${activeTab === 'systems' ? styles.activeTab : ''}`}
            onClick={() => handleTabChange('systems')}
          >
            {t('building_systems')}
          </button>
          <button 
            className={`${styles.tab} ${activeTab === 'valuation' ? styles.activeTab : ''}`}
            onClick={() => handleTabChange('valuation')}
          >
            {t('valuation')}
          </button>
          <button 
            className={`${styles.tab} ${activeTab === 'risk' ? styles.activeTab : ''}`}
            onClick={() => handleTabChange('risk')}
          >
            {t('risk')}
          </button>
          <button 
            className={`${styles.tab} ${activeTab === 'claims' ? styles.activeTab : ''}`}
            onClick={() => handleTabChange('claims')}
          >
            {t('claims')}
          </button>
          <button 
            className={`${styles.tab} ${activeTab === 'ownership' ? styles.activeTab : ''}`}
            onClick={() => handleTabChange('ownership')}
          >
            {t('ownership')}
          </button>
          <button 
            className={`${styles.tab} ${activeTab === 'occupancy' ? styles.activeTab : ''}`}
            onClick={() => handleTabChange('occupancy')}
          >
            {t('occupancy')}
          </button>
          <button 
            className={`${styles.tab} ${activeTab === 'safety' ? styles.activeTab : ''}`}
            onClick={() => handleTabChange('safety')}
          >
            {t('safety')}
          </button>
          <button 
            className={`${styles.tab} ${activeTab === 'external' ? styles.activeTab : ''}`}
            onClick={() => handleTabChange('external')}
          >
            {t('external_features')}
          </button>
        </div>
        
        <div className={styles.tabContent}>
          {activeTab === 'overview' && <PropertyOverview property={property} />}
          {activeTab === 'images' && <PropertyImages property={property} />}
          {activeTab === 'location' && <PropertyLocation property={property} />}
          {activeTab === 'building' && <BuildingCharacteristics property={property} />}
          {activeTab === 'systems' && <BuildingSystems property={property} />}
          {activeTab === 'valuation' && <PropertyValuation property={property} />}
          {activeTab === 'risk' && <PropertyRisk property={property} />}
          {activeTab === 'claims' && <PropertyClaims property={property} />}
          {activeTab === 'ownership' && <PropertyOwnership property={property} />}
          {activeTab === 'occupancy' && <PropertyOccupancy property={property} />}
          {activeTab === 'safety' && <PropertySafety property={property} />}
          {activeTab === 'external' && <ExternalFeatures property={property} />}
        </div>
      </div>
    </div>
  );
};

export default PropertyDetail;