// /charts/v2/core/ChartContainer.js
import React from 'react';
import { ResponsiveContainer } from 'recharts';
import styles from './ChartContainer.module.css';

const ChartContainer = ({ height = 400, children }) => {
  return (
    <div className={styles.containerWrapper}>
      <ResponsiveContainer width="100%" height={height}>
        {children}
      </ResponsiveContainer>
    </div>
  );
};

export default ChartContainer;