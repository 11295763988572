// File: frontend/src/pages/InvestorPitch/components/slides/EngineeringMetrics/EngineeringMetrics.js

import React from 'react';
import { motion } from 'framer-motion';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import { ANIMATION_VARIANTS } from '../../../constants/animations';
import styles from './EngineeringMetrics.module.css';

const EngineeringMetrics = () => {
  const { t } = useLanguage();

  const components = [
    {
      id: 'frontend',
      color: 'var(--color-blue-310)',
      metrics: {
        files: 1239,
        loc: 171566,
        commentRatio: 15.52,
        complexity: 7.77,
        fileSize: 138.47,
        codeSmells: 844,
        techStack: 'React, JavaScript, HTML, CSS',
        cost: 12682582.13
      }
    },
    {
      id: 'backend',
      color: 'var(--color-green-310)',
      metrics: {
        files: 436,
        loc: 44386,
        commentRatio: 27.36,
        complexity: 10.36,
        fileSize: 101.8,
        codeSmells: 192,
        techStack: 'NodeJS, Python, Serverless with API Gateway',
        cost: 2664932.43
      }
    },
    {
      id: 'infrastructure',
      color: 'var(--color-orange-310)',
      metrics: {
        files: 189,
        loc: 47334,
        commentRatio: 9.25,
        complexity: 6.5,
        fileSize: 250.44,
        codeSmells: 124,
        techStack: 'AWS Multi-Tenant & Region, Infrastructure-as-Code by Terraform',
        cost: 8116972.10
      }
    },
    {
      id: 'database',
      color: 'var(--color-yellow-310)',
      metrics: {
        files: 223,
        loc: 28529,
        commentRatio: 6.48,
        complexity: 5.12,
        fileSize: 127.93,
        codeSmells: 148,
        techStack: 'PL/pgSQL, DynamoDB, S3',
        cost: 1475271.83
      }
    }
  ];

  const formatNumber = (number) => {
    return new Intl.NumberFormat('en-US').format(number);
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(amount);
  };

  return (
    <motion.div
      className={styles.container}
      variants={ANIMATION_VARIANTS.stagger.container}
      initial="hidden"
      animate="visible"
    >
      <motion.div 
        className={styles.header}
        variants={ANIMATION_VARIANTS.fadeIn}
      >
        <h2 className={styles.title}>
          {t('investor_pitch.engineering_metrics.title', 'Engineering Metrics')}
        </h2>
        <p className={styles.subtitle}>
          {t('investor_pitch.engineering_metrics.subtitle', 'Code quality and complexity analysis')}
        </p>
      </motion.div>

      <motion.div 
        className={styles.metricsGrid}
        variants={ANIMATION_VARIANTS.stagger.container}
      >
        {components.map((component, index) => (
          <motion.div
            key={component.id}
            className={styles.componentCard}
            variants={ANIMATION_VARIANTS.stagger.item}
            custom={index}
            style={{
              '--component-color': component.color
            }}
          >
            <div className={styles.componentHeader}>
              <h3 className={styles.componentName}>
                {t(`investor_pitch.engineering_metrics.components.${component.id}.name`, component.id)}
              </h3>
              <span className={styles.fileCount}>
                {t('investor_pitch.engineering_metrics.metrics.files', {
                  count: formatNumber(component.metrics.files)
                }, `${formatNumber(component.metrics.files)} files`)}
              </span>
            </div>

            <div className={styles.metricsContainer}>
              <div className={styles.metric}>
                <span className={styles.metricLabel}>
                  {t('investor_pitch.engineering_metrics.metrics.loc.label', 'Lines of Code')}
                </span>
                <span className={styles.metricValue}>
                  {formatNumber(component.metrics.loc)}
                </span>
              </div>
              
              <div className={styles.metric}>
                <span className={styles.metricLabel}>
                  {t('investor_pitch.engineering_metrics.metrics.comment_ratio.label', 'Comment Ratio')}
                </span>
                <span className={styles.metricValue}>
                  {component.metrics.commentRatio}%
                </span>
              </div>

              <div className={styles.metric}>
                <span className={styles.metricLabel}>
                  {t('investor_pitch.engineering_metrics.metrics.complexity.label', 'Complexity')}
                </span>
                <span className={styles.metricValue}>
                  {component.metrics.complexity}
                </span>
              </div>

              <div className={styles.metric}>
                <span className={styles.metricLabel}>
                  {t('investor_pitch.engineering_metrics.metrics.code_smells.label', 'Code Smells')}
                </span>
                <span className={styles.metricValue}>
                  {formatNumber(component.metrics.codeSmells)}
                </span>
              </div>
            </div>

            <div className={styles.techStackContainer}>
              <span className={styles.techStackLabel}>
                {t('investor_pitch.engineering_metrics.metrics.tech_stack.label', 'Tech Stack')}
              </span>
              <span className={styles.techStackValue}>
                {component.metrics.techStack}
              </span>
            </div>

            <div className={styles.costIndicator}>
              <span className={styles.costLabel}>
                {t('investor_pitch.engineering_metrics.cost.label', 'Development Cost')}
              </span>
              <span className={styles.costValue}>
                {formatCurrency(component.metrics.cost)}
              </span>
            </div>
          </motion.div>
        ))}
      </motion.div>
    </motion.div>
  );
};

export default EngineeringMetrics;