// File: frontend/src/components/form/Dropdown/Dropdown.js

import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useLanguage } from '../../../contexts/LanguageContext';
import FormField from '../FormField/FormField';
import styles from './Dropdown.module.css';

const Dropdown = ({
  id,
  labelKey,
  options,
  placeholderKey = 'dropdown_placeholder',
  value,
  onChange,
  disabled = false,
  required = false,
  hintKey,
  errorKey,
  variant = 'default',
  renderOption,
  renderSelected,
  direction = 'down', // Add this prop with default value
  multiple = false,
}) => {
  const { t } = useLanguage();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Memoize the selected option
  const selectedOption = useMemo(() => {
    if (multiple) {
      return value ? options.filter(option => value.includes(option.value)) : [];
    }
    return options.find(option => option.value === value) || null;
  }, [options, value, multiple]);

  // Handle click outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  // Memoized handlers
  const handleToggle = useCallback(() => {
    if (!disabled) {
      setIsOpen(prev => !prev);
    }
  }, [disabled]);

  const handleSelect = useCallback((option) => {
    if (multiple) {
      // Ensure value is always an array
      const currentValues = Array.isArray(value) ? value : [];
      const index = currentValues.indexOf(option.value);
      
      let newValues;
      if (index === -1) {
        // Add new value
        newValues = [...currentValues, option.value];
      } else {
        // Remove existing value
        newValues = currentValues.filter(v => v !== option.value);
      }
      
      onChange(newValues);
    } else {
      setIsOpen(false);
      onChange(option.value);
    }
  }, [multiple, value, onChange]);

  // Keyboard navigation
  const handleKeyDown = useCallback((e) => {
    switch (e.key) {
      case 'Enter':
      case ' ':
        e.preventDefault();
        handleToggle();
        break;
      case 'Escape':
        setIsOpen(false);
        break;
      case 'ArrowDown':
        if (!isOpen) {
          e.preventDefault();
          setIsOpen(true);
        }
        break;
      default:
        break;
    }
  }, [handleToggle, isOpen]);

  // Render option content
  const renderOptionContent = useCallback((option) => (
    renderOption ? renderOption(option) : t(option.labelKey)
  ), [renderOption, t]);

  const renderSelectedContent = () => {
    if (multiple) {
      if (!value || value.length === 0) {
        return t(placeholderKey);
      }
      
      return renderSelected ? renderSelected(value) : value.join(', ');
    }
    
    return selectedOption ? renderOptionContent(selectedOption) : t(placeholderKey);
  };

  const dropdownContent = (
    <div 
      className={`
        ${styles.dropdown} 
        ${styles[variant]} 
        ${styles[direction]}
        ${isOpen ? styles.open : ''} 
        ${disabled ? styles.disabled : ''}
        ${multiple ? styles.multiple : ''}
      `}
      ref={dropdownRef}
    >
      <div 
        className={styles.selected}
        onClick={handleToggle}
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex={disabled ? -1 : 0}
        aria-haspopup="listbox"
        aria-expanded={isOpen}
        aria-disabled={disabled}
        id={`${id}-button`}
      >
        {renderSelectedContent()}
        <img 
          className={`${styles.chevron} ${isOpen ? styles.open : ''}`}
          src="/icons/18/misc/chevron-down.svg" 
          alt=""
          aria-hidden="true"
        />
      </div>

      {isOpen && (
        <ul 
          className={styles.options} 
          role="listbox"
          aria-labelledby={`${id}-button`}
        >
          {options.map((option) => (
            <li
              key={option.value}
              className={`
                ${styles.option} 
                ${multiple 
                  ? value?.includes(option.value) ? styles.selected : ''
                  : selectedOption?.value === option.value ? styles.selected : ''
                }
              `}
              onClick={() => handleSelect(option)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  e.preventDefault();
                  handleSelect(option);
                }
              }}
              role="option"
              tabIndex={0}
              aria-selected={multiple 
                ? value?.includes(option.value)
                : selectedOption?.value === option.value
              }
            >
              {renderOptionContent(option)}
            </li>
          ))}
        </ul>
      )}
    </div>
  );

  // Return standalone dropdown or wrapped in FormField
  return variant === 'standalone' || variant === 'language-picker' 
    ? dropdownContent
    : (
      <FormField
        labelKey={labelKey}
        required={required}
        hintKey={hintKey}
        errorKey={errorKey}
        id={id}
      >
        {dropdownContent}
      </FormField>
    );
};

Dropdown.propTypes = {
  id: PropTypes.string.isRequired,
  labelKey: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      labelKey: PropTypes.string.isRequired,
    })
  ).isRequired,
  placeholderKey: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]), // Allow both single string and array of strings
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  hintKey: PropTypes.string,
  errorKey: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'standalone', 'language-picker']),
  renderOption: PropTypes.func,
  renderSelected: PropTypes.func,
  direction: PropTypes.oneOf(['up', 'down']),
  multiple: PropTypes.bool, // Add this prop
};

export default React.memo(Dropdown);