// File: frontend/src/pages/AutoInsight/VehicleDetail/components/VehicleSafety/index.js

import React from 'react';
import { useLanguage } from '../../../../../../contexts/LanguageContext';
import styles from './VehicleSafety.module.css';

const VehicleSafety = ({ vehicle }) => {
  const { t } = useLanguage();

  // Helper function to format safety feature status
  const formatFeatureStatus = (status) => {
    if (!status) return 'N/A';
    
    if (typeof status === 'boolean') {
      return status ? t('yes') : t('no');
    }
    
    if (status === 'standard') {
      return t('standard');
    } else if (status === 'optional') {
      return t('optional');
    } else if (status === 'not-available') {
      return t('not_available');
    }
    
    return status;
  };

  return (
    <div className={styles.container}>
      <div className={styles.grid}>
        <div className={styles.card}>
          <h3 className={styles.cardTitle}>{t('safety_features')}</h3>
          <div className={styles.cardContent}>
            {vehicle.safety_features ? (
              <div className={styles.featuresGrid}>
                <div className={styles.featureItem}>
                  <div className={styles.featureLabel}>{t('winter_tires')}:</div>
                  <div className={styles.featureValue}>
                    {formatFeatureStatus(vehicle.safety_features.vehicle_winter_tires)}
                  </div>
                </div>
                
                {vehicle.safety_features.vehicle_winter_tires && (
                  <>
                    <div className={styles.featureItem}>
                      <div className={styles.featureLabel}>{t('winter_tire_type')}:</div>
                      <div className={styles.featureValue}>
                        {vehicle.safety_features.vehicle_winter_tire_type || 'N/A'}
                      </div>
                    </div>
                    <div className={styles.featureItem}>
                      <div className={styles.featureLabel}>{t('winter_tire_name')}:</div>
                      <div className={styles.featureValue}>
                        {vehicle.safety_features.vehicle_winter_tire_name || 'N/A'}
                      </div>
                    </div>
                    <div className={styles.featureItem}>
                      <div className={styles.featureLabel}>{t('winter_tire_installed_date')}:</div>
                      <div className={styles.featureValue}>
                        {vehicle.safety_features.vehicle_winter_tire_installed_date 
                          ? new Date(vehicle.safety_features.vehicle_winter_tire_installed_date).toLocaleDateString() 
                          : 'N/A'}
                      </div>
                    </div>
                    <div className={styles.featureItem}>
                      <div className={styles.featureLabel}>{t('winter_tire_installed_by')}:</div>
                      <div className={styles.featureValue}>
                        {vehicle.safety_features.vehicle_winter_tire_installed_by || 'N/A'}
                      </div>
                    </div>
                  </>
                )}
                
                <div className={styles.featureItem}>
                  <div className={styles.featureLabel}>{t('anti_theft_device')}:</div>
                  <div className={styles.featureValue}>
                    {vehicle.safety_features.vehicle_anti_theft_device || 'N/A'}
                  </div>
                </div>
                
                <div className={styles.featureItem}>
                  <div className={styles.featureLabel}>{t('airbag_type')}:</div>
                  <div className={styles.featureValue}>
                    {vehicle.safety_features.vehicle_airbag_type || 'N/A'}
                  </div>
                </div>
                
                <div className={styles.featureItem}>
                  <div className={styles.featureLabel}>{t('abs_brakes')}:</div>
                  <div className={styles.featureValue}>
                    {formatFeatureStatus(vehicle.safety_features.vehicle_abs_brakes)}
                  </div>
                </div>
                
                <div className={styles.featureItem}>
                  <div className={styles.featureLabel}>{t('seat_belt_type')}:</div>
                  <div className={styles.featureValue}>
                    {vehicle.safety_features.vehicle_seat_belt_type || 'N/A'}
                  </div>
                </div>
                
                <div className={styles.featureItem}>
                  <div className={styles.featureLabel}>{t('antilock_braking_system')}:</div>
                  <div className={styles.featureValue}>
                    {formatFeatureStatus(vehicle.safety_features.vehicle_antilock_braking_system_abs)}
                  </div>
                </div>
                
                <div className={styles.featureItem}>
                  <div className={styles.featureLabel}>{t('electronic_stability_control')}:</div>
                  <div className={styles.featureValue}>
                    {formatFeatureStatus(vehicle.safety_features.vehicle_electronic_stability_control_esc)}
                  </div>
                </div>
                
                <div className={styles.featureItem}>
                  <div className={styles.featureLabel}>{t('traction_control')}:</div>
                  <div className={styles.featureValue}>
                    {formatFeatureStatus(vehicle.safety_features.vehicle_traction_control)}
                  </div>
                </div>
                
                <div className={styles.featureItem}>
                  <div className={styles.featureLabel}>{t('tire_pressure_monitoring')}:</div>
                  <div className={styles.featureValue}>
                    {formatFeatureStatus(vehicle.safety_features.vehicle_tire_pressure_monitoring_system_type_tpms)}
                  </div>
                </div>
                
                <div className={styles.featureItem}>
                  <div className={styles.featureLabel}>{t('keyless_ignition')}:</div>
                  <div className={styles.featureValue}>
                    {formatFeatureStatus(vehicle.safety_features.vehicle_keyless_ignition)}
                  </div>
                </div>
                
                <div className={styles.featureItem}>
                  <div className={styles.featureLabel}>{t('event_data_recorder')}:</div>
                  <div className={styles.featureValue}>
                    {formatFeatureStatus(vehicle.safety_features.vehicle_event_data_recorder_edr)}
                  </div>
                </div>
                
                <div className={styles.featureItem}>
                  <div className={styles.featureLabel}>{t('daytime_running_lights')}:</div>
                  <div className={styles.featureValue}>
                    {formatFeatureStatus(vehicle.safety_features.vehicle_daytime_running_light_drl)}
                  </div>
                </div>
                
                <div className={styles.featureItem}>
                  <div className={styles.featureLabel}>{t('auto_reverse_system')}:</div>
                  <div className={styles.featureValue}>
                    {formatFeatureStatus(vehicle.safety_features.vehicle_auto_reverse_system)}
                  </div>
                </div>
                
                <div className={styles.featureItem}>
                  <div className={styles.featureLabel}>{t('pedestrian_alerting_sound')}:</div>
                  <div className={styles.featureValue}>
                    {formatFeatureStatus(vehicle.safety_features.vehicle_pedestrian_alerting_sound)}
                  </div>
                </div>
                
                <div className={styles.featureItem}>
                  <div className={styles.featureLabel}>{t('headlamp_light_source')}:</div>
                  <div className={styles.featureValue}>
                    {vehicle.safety_features.vehicle_headlamp_light_source || 'N/A'}
                  </div>
                </div>
                
                <div className={styles.featureItem}>
                  <div className={styles.featureLabel}>{t('semiautomatic_headlamp_switching')}:</div>
                  <div className={styles.featureValue}>
                    {formatFeatureStatus(vehicle.safety_features.vehicle_semiautomatic_headlamp_switching)}
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.noData}>{t('no_safety_features_available')}</div>
            )}
          </div>
        </div>
        
        <div className={styles.card}>
          <h3 className={styles.cardTitle}>{t('automation_level')}</h3>
          <div className={styles.cardContent}>
            {vehicle.safety_features ? (
              <div className={styles.automationContainer}>
                <div className={styles.automationLevel}>
                  <div className={styles.automationLabel}>{t('automation_level_range')}:</div>
                  <div className={styles.automationValue}>
                    {vehicle.safety_features.vehicle_automation_level_min !== null && 
                     vehicle.safety_features.vehicle_automation_level_max !== null
                      ? `${vehicle.safety_features.vehicle_automation_level_min} - ${vehicle.safety_features.vehicle_automation_level_max}`
                      : 'N/A'}
                  </div>
                </div>
                
                <div className={styles.automationDescription}>
                  <h4 className={styles.automationTitle}>{t('sae_automation_levels')}</h4>
                  <ul className={styles.automationList}>
                    <li className={styles.automationListItem}>
                      <span className={styles.automationListLevel}>0 - </span>
                      <span className={styles.automationListText}>{t('no_automation')}</span>
                    </li>
                    <li className={styles.automationListItem}>
                      <span className={styles.automationListLevel}>1 - </span>
                      <span className={styles.automationListText}>{t('driver_assistance')}</span>
                    </li>
                    <li className={styles.automationListItem}>
                      <span className={styles.automationListLevel}>2 - </span>
                      <span className={styles.automationListText}>{t('partial_automation')}</span>
                    </li>
                    <li className={styles.automationListItem}>
                      <span className={styles.automationListLevel}>3 - </span>
                      <span className={styles.automationListText}>{t('conditional_automation')}</span>
                    </li>
                    <li className={styles.automationListItem}>
                      <span className={styles.automationListLevel}>4 - </span>
                      <span className={styles.automationListText}>{t('high_automation')}</span>
                    </li>
                    <li className={styles.automationListItem}>
                      <span className={styles.automationListLevel}>5 - </span>
                      <span className={styles.automationListText}>{t('full_automation')}</span>
                    </li>
                  </ul>
                </div>
              </div>
            ) : (
              <div className={styles.noData}>{t('no_automation_information_available')}</div>
            )}
          </div>
        </div>
      </div>
      
      <div className={styles.card}>
        <h3 className={styles.cardTitle}>{t('advanced_driver_assistance_systems')}</h3>
        <div className={styles.cardContent}>
          {vehicle.adas_features ? (
            <div className={styles.adasGrid}>
              <div className={styles.featureItem}>
                <div className={styles.featureLabel}>{t('adaptive_cruise_control')}:</div>
                <div className={styles.featureValue}>
                  {formatFeatureStatus(vehicle.adas_features.adaptive_cruise_control_acc)}
                </div>
              </div>
              
              <div className={styles.featureItem}>
                <div className={styles.featureLabel}>{t('crash_imminent_braking')}:</div>
                <div className={styles.featureValue}>
                  {formatFeatureStatus(vehicle.adas_features.crash_imminent_braking_cib)}
                </div>
              </div>
              
              <div className={styles.featureItem}>
                <div className={styles.featureLabel}>{t('blind_spot_warning')}:</div>
                <div className={styles.featureValue}>
                  {formatFeatureStatus(vehicle.adas_features.blind_spot_warning_bsw)}
                </div>
              </div>
              
              <div className={styles.featureItem}>
                <div className={styles.featureLabel}>{t('forward_collision_warning')}:</div>
                <div className={styles.featureValue}>
                  {formatFeatureStatus(vehicle.adas_features.forward_collision_warning_fcw)}
                </div>
              </div>
              
              <div className={styles.featureItem}>
                <div className={styles.featureLabel}>{t('lane_departure_warning')}:</div>
                <div className={styles.featureValue}>
                  {formatFeatureStatus(vehicle.adas_features.lane_departure_warning_ldw)}
                </div>
              </div>
              
              <div className={styles.featureItem}>
                <div className={styles.featureLabel}>{t('lane_keeping_assistance')}:</div>
                <div className={styles.featureValue}>
                  {formatFeatureStatus(vehicle.adas_features.lane_keeping_assistance_lka)}
                </div>
              </div>
              
              <div className={styles.featureItem}>
                <div className={styles.featureLabel}>{t('backup_camera')}:</div>
                <div className={styles.featureValue}>
                  {formatFeatureStatus(vehicle.adas_features.backup_camera)}
                </div>
              </div>
              
              <div className={styles.featureItem}>
                <div className={styles.featureLabel}>{t('parking_assist')}:</div>
                <div className={styles.featureValue}>
                  {formatFeatureStatus(vehicle.adas_features.parking_assist)}
                </div>
              </div>
              
              <div className={styles.featureItem}>
                <div className={styles.featureLabel}>{t('dynamic_brake_support')}:</div>
                <div className={styles.featureValue}>
                  {formatFeatureStatus(vehicle.adas_features.dynamic_brake_support_dbs)}
                </div>
              </div>
              
              <div className={styles.featureItem}>
                <div className={styles.featureLabel}>{t('pedestrian_automatic_emergency_braking')}:</div>
                <div className={styles.featureValue}>
                  {formatFeatureStatus(vehicle.adas_features.pedestrian_automatic_emergency_braking_paeb)}
                </div>
              </div>
              
              <div className={styles.featureItem}>
                <div className={styles.featureLabel}>{t('automatic_crash_notification')}:</div>
                <div className={styles.featureValue}>
                  {formatFeatureStatus(vehicle.adas_features.automatic_crash_notification_acn)}
                </div>
              </div>
              
              <div className={styles.featureItem}>
                <div className={styles.featureLabel}>{t('adaptive_driving_beam')}:</div>
                <div className={styles.featureValue}>
                  {formatFeatureStatus(vehicle.adas_features.adaptive_driving_beam_adb)}
                </div>
              </div>
              
              <div className={styles.featureItem}>
                <div className={styles.featureLabel}>{t('rear_cross_traffic_alert')}:</div>
                <div className={styles.featureValue}>
                  {formatFeatureStatus(vehicle.adas_features.rear_cross_traffic_alert)}
                </div>
              </div>
              
              <div className={styles.featureItem}>
                <div className={styles.featureLabel}>{t('rear_automatic_emergency_braking')}:</div>
                <div className={styles.featureValue}>
                  {formatFeatureStatus(vehicle.adas_features.rear_automatic_emergency_braking)}
                </div>
              </div>
              
              <div className={styles.featureItem}>
                <div className={styles.featureLabel}>{t('blind_spot_intervention')}:</div>
                <div className={styles.featureValue}>
                  {formatFeatureStatus(vehicle.adas_features.blind_spot_intervention_bsi)}
                </div>
              </div>
              
              <div className={styles.featureItem}>
                <div className={styles.featureLabel}>{t('lane_centering_assistance')}:</div>
                <div className={styles.featureValue}>
                  {formatFeatureStatus(vehicle.adas_features.lane_centering_assistance)}
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.noData}>{t('no_adas_features_available')}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VehicleSafety;