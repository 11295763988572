// File: frontend/src/pages/SuperAdmin/components/DatasetManagement/DatasetDetail/index.js

import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from './DatasetDetail.module.css';

const DatasetDetail = ({ datasetId, onBack, onEdit, isEditMode = false, onSuccess = null }) => {
  const { t } = useLanguage();
  const [dataset, setDataset] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('overview');
  
  // Form state for edit mode
  const [formData, setFormData] = useState({
    dataset_name: '',
    dataset_description: '',
    dataset_type: '',
    dataset_status: '',
    storage_location: '',
    region: '',
    format: '',
    schema: '',
    retention_policy: ''
  });
  
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    const fetchDatasetDetails = async () => {
      setLoading(true);
      setError(null);
      
      try {
        // This would be replaced with an actual API call
        // const response = await datasetService.getDatasetDetails(datasetId);
        
        // Mock data for now
        await new Promise(resolve => setTimeout(resolve, 800));
        
        const mockDataset = {
          dataset_id: datasetId,
          dataset_name: 'Customer Analytics Data',
          dataset_description: 'Customer behavior and analytics data for reporting and business intelligence. Contains user interactions, purchase history, and demographic information.',
          dataset_type: 'structured',
          dataset_status: 'active',
          enterprise_id: 'ent-12345',
          enterprise_name: 'Acme Corporation',
          storage_location: 's3://acme-analytics/customer-data',
          region: 'us-west-2',
          format: 'parquet',
          schema: `{
  "fields": [
    {"name": "customer_id", "type": "string"},
    {"name": "timestamp", "type": "datetime"},
    {"name": "action", "type": "string"},
    {"name": "product_id", "type": "string"},
    {"name": "value", "type": "float"}
  ]
}`,
          retention_policy: '1 year',
          created_at: '2023-01-15T10:30:00Z',
          updated_at: '2023-06-20T14:45:00Z',
          created_by: 'admin-user-1',
          updated_by: 'admin-user-2',
          size_in_gb: 25.4,
          record_count: 1250000,
          last_updated_timestamp: '2023-06-20T14:45:00Z',
          
          // Additional metadata
          metadata: {
            owner: 'Data Science Team',
            sensitivity_level: 'medium',
            data_classification: 'internal',
            compliance_requirements: ['GDPR', 'CCPA'],
            data_source: 'Web Application',
            update_frequency: 'daily',
            last_validation_date: '2023-06-15',
            quality_score: 92
          },
          
          // Access information
          access: {
            access_control: 'role-based',
            authorized_roles: ['data_scientist', 'analyst', 'admin'],
            encryption: 'AES-256',
            requires_approval: false
          },
          
          // Usage statistics
          usage_stats: {
            total_queries: 5280,
            unique_users: 42,
            average_query_time_ms: 320,
            peak_usage_time: '13:00-15:00',
            most_frequent_users: ['user-45678', 'user-56789', 'user-67890'],
            popular_joins: ['products', 'transactions']
          }
        };
        
        setDataset(mockDataset);
        
        // Initialize form data for editing
        if (isEditMode) {
          setFormData({
            dataset_name: mockDataset.dataset_name,
            dataset_description: mockDataset.dataset_description,
            dataset_type: mockDataset.dataset_type,
            dataset_status: mockDataset.dataset_status,
            storage_location: mockDataset.storage_location,
            region: mockDataset.region,
            format: mockDataset.format,
            schema: mockDataset.schema,
            retention_policy: mockDataset.retention_policy
          });
        }
      } catch (err) {
        console.error('Error fetching dataset details:', err);
        setError(t('failed_to_load_dataset_details'));
      } finally {
        setLoading(false);
      }
    };
    
    if (datasetId) {
      fetchDatasetDetails();
    }
  }, [datasetId, isEditMode, t]);

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }).format(date);
  };

  const formatSize = (sizeInGB) => {
    if (sizeInGB < 1) {
      return `${Math.round(sizeInGB * 1024)} MB`;
    }
    return `${sizeInGB.toFixed(1)} GB`;
  };

  const formatNumber = (num) => {
    return new Intl.NumberFormat().format(num);
  };

  const getStatusBadgeClass = (status) => {
    switch (status) {
      case 'active':
        return styles.statusActive;
      case 'inactive':
        return styles.statusInactive;
      case 'archived':
        return styles.statusArchived;
      default:
        return '';
    }
  };

  const getTypeBadgeClass = (type) => {
    switch (type) {
      case 'structured':
        return styles.typeStructured;
      case 'semi-structured':
        return styles.typeSemiStructured;
      case 'unstructured':
        return styles.typeUnstructured;
      default:
        return '';
    }
  };

  const getSensitivityClass = (level) => {
    switch (level) {
      case 'high':
        return styles.sensitivityHigh;
      case 'medium':
        return styles.sensitivityMedium;
      case 'low':
        return styles.sensitivityLow;
      default:
        return '';
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSaving(true);
    setError(null);
    
    try {
      // This would be replaced with an actual API call
      // await datasetService.updateDataset(datasetId, formData);
      
      // Mock update for now
      await new Promise(resolve => setTimeout(resolve, 800));
      
      // Call the success callback
      if (onSuccess) {
        onSuccess();
      }
    } catch (err) {
      console.error('Error updating dataset:', err);
      setError(t('failed_to_update_dataset'));
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <div className={styles.spinner}></div>
        <p>{t('loading_dataset_details')}</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.error}>
        <p>{error}</p>
        <button className={styles.backButton} onClick={onBack}>
          {t('back_to_datasets')}
        </button>
      </div>
    );
  }

  if (!dataset) {
    return (
      <div className={styles.notFound}>
        <p>{t('dataset_not_found')}</p>
        <button className={styles.backButton} onClick={onBack}>
          {t('back_to_datasets')}
        </button>
      </div>
    );
  }

  // Render edit form
  if (isEditMode) {
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <button className={styles.backButton} onClick={onBack} disabled={saving}>
            <i className="fas fa-arrow-left"></i>
            <span>{t('back')}</span>
          </button>
        </div>
        
        {error && (
          <div className={styles.errorMessage}>
            <i className="fas fa-exclamation-circle"></i>
            <span>{error}</span>
          </div>
        )}
        
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.formSection}>
            <h3 className={styles.sectionTitle}>{t('dataset_information')}</h3>
            <div className={styles.formGrid}>
              <div className={styles.formGroup}>
                <label htmlFor="dataset_name" className={styles.label}>
                  {t('dataset_name')} <span className={styles.required}>*</span>
                </label>
                <input
                  type="text"
                  id="dataset_name"
                  name="dataset_name"
                  className={styles.input}
                  value={formData.dataset_name}
                  onChange={handleChange}
                  required
                  disabled={saving}
                />
              </div>
              
              <div className={styles.formGroup}>
                <label htmlFor="dataset_type" className={styles.label}>
                  {t('dataset_type')} <span className={styles.required}>*</span>
                </label>
                <select
                  id="dataset_type"
                  name="dataset_type"
                  className={styles.select}
                  value={formData.dataset_type}
                  onChange={handleChange}
                  required
                  disabled={saving}
                >
                  <option value="structured">{t('structured')}</option>
                  <option value="semi-structured">{t('semi_structured')}</option>
                  <option value="unstructured">{t('unstructured')}</option>
                </select>
              </div>
              
              <div className={styles.formGroup}>
                <label htmlFor="dataset_status" className={styles.label}>
                  {t('status')} <span className={styles.required}>*</span>
                </label>
                <select
                  id="dataset_status"
                  name="dataset_status"
                  className={styles.select}
                  value={formData.dataset_status}
                  onChange={handleChange}
                  required
                  disabled={saving}
                >
                  <option value="active">{t('active')}</option>
                  <option value="inactive">{t('inactive')}</option>
                  <option value="archived">{t('archived')}</option>
                </select>
              </div>
              
              <div className={styles.formGroup}>
                <label htmlFor="retention_policy" className={styles.label}>
                  {t('retention_policy')} <span className={styles.required}>*</span>
                </label>
                <select
                  id="retention_policy"
                  name="retention_policy"
                  className={styles.select}
                  value={formData.retention_policy}
                  onChange={handleChange}
                  required
                  disabled={saving}
                >
                  <option value="7 days">7 days</option>
                  <option value="30 days">30 days</option>
                  <option value="90 days">90 days</option>
                  <option value="180 days">180 days</option>
                  <option value="1 year">1 year</option>
                  <option value="3 years">3 years</option>
                  <option value="5 years">5 years</option>
                  <option value="indefinite">Indefinite</option>
                </select>
              </div>
              
              <div className={styles.formGroupFull}>
                <label htmlFor="dataset_description" className={styles.label}>
                  {t('description')} <span className={styles.required}>*</span>
                </label>
                <textarea
                  id="dataset_description"
                  name="dataset_description"
                  className={styles.textarea}
                  value={formData.dataset_description}
                  onChange={handleChange}
                  rows={3}
                  required
                  disabled={saving}
                ></textarea>
              </div>
            </div>
          </div>
          
          <div className={styles.formSection}>
            <h3 className={styles.sectionTitle}>{t('storage_information')}</h3>
            <div className={styles.formGrid}>
              <div className={styles.formGroup}>
                <label htmlFor="storage_location" className={styles.label}>
                  {t('storage_location')} <span className={styles.required}>*</span>
                </label>
                <input
                  type="text"
                  id="storage_location"
                  name="storage_location"
                  className={styles.input}
                  value={formData.storage_location}
                  onChange={handleChange}
                  required
                  disabled={saving}
                />
              </div>
              
              <div className={styles.formGroup}>
                <label htmlFor="region" className={styles.label}>
                  {t('region')} <span className={styles.required}>*</span>
                </label>
                <select
                  id="region"
                  name="region"
                  className={styles.select}
                  value={formData.region}
                  onChange={handleChange}
                  required
                  disabled={saving}
                >
                  <option value="us-east-1">US East (N. Virginia)</option>
                  <option value="us-east-2">US East (Ohio)</option>
                  <option value="us-west-1">US West (N. California)</option>
                  <option value="us-west-2">US West (Oregon)</option>
                  <option value="eu-central-1">EU (Frankfurt)</option>
                  <option value="eu-west-1">EU (Ireland)</option>
                  <option value="ap-southeast-1">Asia Pacific (Singapore)</option>
                  <option value="ap-southeast-2">Asia Pacific (Sydney)</option>
                </select>
              </div>
              
              <div className={styles.formGroup}>
                <label htmlFor="format" className={styles.label}>
                  {t('format')} <span className={styles.required}>*</span>
                </label>
                <select
                  id="format"
                  name="format"
                  className={styles.select}
                  value={formData.format}
                  onChange={handleChange}
                  required
                  disabled={saving}
                >
                  <option value="csv">CSV</option>
                  <option value="json">JSON</option>
                  <option value="parquet">Parquet</option>
                  <option value="avro">Avro</option>
                  <option value="xml">XML</option>
                  <option value="text">Text</option>
                  <option value="binary">Binary</option>
                </select>
              </div>
              
              <div className={styles.formGroupFull}>
                <label htmlFor="schema" className={styles.label}>
                  {t('schema')}
                </label>
                <textarea
                  id="schema"
                  name="schema"
                  className={styles.textarea}
                  value={formData.schema}
                  onChange={handleChange}
                  rows={5}
                  disabled={saving}
                ></textarea>
              </div>
            </div>
          </div>
          
          <div className={styles.formActions}>
            <button 
              type="button" 
              className={styles.cancelButton} 
              onClick={onBack}
              disabled={saving}
            >
              {t('cancel')}
            </button>
            <button 
              type="submit" 
              className={styles.submitButton}
              disabled={saving}
            >
              {saving ? (
                <>
                  <span className={styles.spinner}></span>
                  <span>{t('saving')}</span>
                </>
              ) : (
                t('save_changes')
              )}
            </button>
          </div>
        </form>
      </div>
    );
  }

  // Render detail view
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <h2 className={styles.datasetTitle}>{dataset.dataset_name}</h2>
          <div className={styles.datasetId}>ID: {dataset.dataset_id}</div>
          <div className={styles.statusContainer}>
            <span className={`${styles.statusBadge} ${getStatusBadgeClass(dataset.dataset_status)}`}>
              {dataset.dataset_status}
            </span>
            <span className={`${styles.typeBadge} ${getTypeBadgeClass(dataset.dataset_type)}`}>
              {dataset.dataset_type}
            </span>
          </div>
        </div>
        <div className={styles.headerActions}>
          <button className={styles.backButton} onClick={onBack}>
            <i className="fas fa-arrow-left"></i>
            <span>{t('back')}</span>
          </button>
          <button className={styles.editButton} onClick={onEdit}>
            <i className="fas fa-edit"></i>
            <span>{t('edit')}</span>
          </button>
        </div>
      </div>
      
      <div className={styles.tabs}>
        <button 
          className={`${styles.tab} ${activeTab === 'overview' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('overview')}
        >
          {t('overview')}
        </button>
        <button 
          className={`${styles.tab} ${activeTab === 'schema' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('schema')}
        >
          {t('schema')}
        </button>
        <button 
          className={`${styles.tab} ${activeTab === 'metadata' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('metadata')}
        >
          {t('metadata')}
        </button>
        <button 
          className={`${styles.tab} ${activeTab === 'access' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('access')}
        >
          {t('access')}
        </button>
        <button 
          className={`${styles.tab} ${activeTab === 'usage' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('usage')}
        >
          {t('usage')}
        </button>
      </div>
      
      <div className={styles.tabContent}>
        {activeTab === 'overview' && (
          <div className={styles.overviewTab}>
            <div className={styles.section}>
              <h3 className={styles.sectionTitle}>{t('dataset_information')}</h3>
              <div className={styles.infoGrid}>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('dataset_id')}</div>
                  <div className={styles.infoValue}>{dataset.dataset_id}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('dataset_name')}</div>
                  <div className={styles.infoValue}>{dataset.dataset_name}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('dataset_type')}</div>
                  <div className={styles.infoValue}>
                    <span className={`${styles.typeBadge} ${getTypeBadgeClass(dataset.dataset_type)}`}>
                      {dataset.dataset_type}
                    </span>
                  </div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('status')}</div>
                  <div className={styles.infoValue}>
                    <span className={`${styles.statusBadge} ${getStatusBadgeClass(dataset.dataset_status)}`}>
                      {dataset.dataset_status}
                    </span>
                  </div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('enterprise')}</div>
                  <div className={styles.infoValue}>{dataset.enterprise_name}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('enterprise_id')}</div>
                  <div className={styles.infoValue}>{dataset.enterprise_id}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('size')}</div>
                  <div className={styles.infoValue}>{formatSize(dataset.size_in_gb)}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('records')}</div>
                  <div className={styles.infoValue}>{formatNumber(dataset.record_count)}</div>
                </div>
              </div>
            </div>
            
            <div className={styles.section}>
              <h3 className={styles.sectionTitle}>{t('description')}</h3>
              <p className={styles.description}>{dataset.dataset_description}</p>
            </div>
            
            <div className={styles.section}>
              <h3 className={styles.sectionTitle}>{t('storage_information')}</h3>
              <div className={styles.infoGrid}>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('storage_location')}</div>
                  <div className={styles.infoValue}>{dataset.storage_location}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('region')}</div>
                  <div className={styles.infoValue}>{dataset.region}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('format')}</div>
                  <div className={styles.infoValue}>{dataset.format.toUpperCase()}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('retention_policy')}</div>
                  <div className={styles.infoValue}>{dataset.retention_policy}</div>
                </div>
              </div>
            </div>
            
            <div className={styles.section}>
              <h3 className={styles.sectionTitle}>{t('audit_information')}</h3>
              <div className={styles.infoGrid}>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('created_at')}</div>
                  <div className={styles.infoValue}>{formatDate(dataset.created_at)}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('created_by')}</div>
                  <div className={styles.infoValue}>{dataset.created_by}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('updated_at')}</div>
                  <div className={styles.infoValue}>{formatDate(dataset.updated_at)}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('updated_by')}</div>
                  <div className={styles.infoValue}>{dataset.updated_by}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('last_data_update')}</div>
                  <div className={styles.infoValue}>{formatDate(dataset.last_updated_timestamp)}</div>
                </div>
              </div>
            </div>
          </div>
        )}
        
        {activeTab === 'schema' && (
          <div className={styles.schemaTab}>
            <div className={styles.section}>
              <h3 className={styles.sectionTitle}>{t('dataset_schema')}</h3>
              
              {dataset.schema ? (
                <div className={styles.schemaContainer}>
                  <pre className={styles.schemaCode}>
                    {dataset.schema}
                  </pre>
                  
                  {dataset.dataset_type === 'structured' && (
                    <div className={styles.schemaTable}>
                      <h4 className={styles.schemaTableTitle}>{t('fields')}</h4>
                      <table>
                        <thead>
                          <tr>
                            <th>{t('field_name')}</th>
                            <th>{t('data_type')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {JSON.parse(dataset.schema).fields.map((field, index) => (
                            <tr key={index}>
                              <td>{field.name}</td>
                              <td>{field.type}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              ) : (
                <div className={styles.emptyState}>
                  <p>{t('no_schema_defined')}</p>
                </div>
              )}
            </div>
          </div>
        )}
        
        {activeTab === 'metadata' && (
          <div className={styles.metadataTab}>
            <div className={styles.section}>
              <h3 className={styles.sectionTitle}>{t('dataset_metadata')}</h3>
              
              {dataset.metadata ? (
                <div className={styles.metadataGrid}>
                  <div className={styles.infoItem}>
                    <div className={styles.infoLabel}>{t('owner')}</div>
                    <div className={styles.infoValue}>{dataset.metadata.owner}</div>
                  </div>
                  <div className={styles.infoItem}>
                    <div className={styles.infoLabel}>{t('sensitivity_level')}</div>
                    <div className={styles.infoValue}>
                      <span className={`${styles.sensitivityBadge} ${getSensitivityClass(dataset.metadata.sensitivity_level)}`}>
                        {dataset.metadata.sensitivity_level}
                      </span>
                    </div>
                  </div>
                  <div className={styles.infoItem}>
                    <div className={styles.infoLabel}>{t('data_classification')}</div>
                    <div className={styles.infoValue}>{dataset.metadata.data_classification}</div>
                  </div>
                  <div className={styles.infoItem}>
                    <div className={styles.infoLabel}>{t('data_source')}</div>
                    <div className={styles.infoValue}>{dataset.metadata.data_source}</div>
                  </div>
                  <div className={styles.infoItem}>
                    <div className={styles.infoLabel}>{t('update_frequency')}</div>
                    <div className={styles.infoValue}>{dataset.metadata.update_frequency}</div>
                  </div>
                  <div className={styles.infoItem}>
                    <div className={styles.infoLabel}>{t('last_validation_date')}</div>
                    <div className={styles.infoValue}>{dataset.metadata.last_validation_date}</div>
                  </div>
                  <div className={styles.infoItem}>
                    <div className={styles.infoLabel}>{t('quality_score')}</div>
                    <div className={styles.infoValue}>
                      <div className={styles.qualityScore}>
                        <div 
                          className={styles.qualityBar} 
                          style={{ width: `${dataset.metadata.quality_score}%` }}
                        ></div>
                        <span>{dataset.metadata.quality_score}%</span>
                      </div>
                    </div>
                  </div>
                  <div className={styles.infoItem}>
                    <div className={styles.infoLabel}>{t('compliance_requirements')}</div>
                    <div className={styles.infoValue}>
                      <div className={styles.tagsList}>
                        {dataset.metadata.compliance_requirements.map((req, index) => (
                          <span key={index} className={styles.complianceTag}>{req}</span>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className={styles.emptyState}>
                  <p>{t('no_metadata_available')}</p>
                </div>
              )}
            </div>
          </div>
        )}
        
        {activeTab === 'access' && (
          <div className={styles.accessTab}>
            <div className={styles.section}>
              <h3 className={styles.sectionTitle}>{t('access_information')}</h3>
              
              {dataset.access ? (
                <div className={styles.accessInfo}>
                  <div className={styles.infoGrid}>
                    <div className={styles.infoItem}>
                      <div className={styles.infoLabel}>{t('access_control')}</div>
                      <div className={styles.infoValue}>{dataset.access.access_control}</div>
                    </div>
                    <div className={styles.infoItem}>
                      <div className={styles.infoLabel}>{t('encryption')}</div>
                      <div className={styles.infoValue}>{dataset.access.encryption}</div>
                    </div>
                    <div className={styles.infoItem}>
                      <div className={styles.infoLabel}>{t('requires_approval')}</div>
                      <div className={styles.infoValue}>
                        {dataset.access.requires_approval ? t('yes') : t('no')}
                      </div>
                    </div>
                  </div>
                  
                  <div className={styles.authorizedRoles}>
                    <h4 className={styles.subSectionTitle}>{t('authorized_roles')}</h4>
                    <div className={styles.rolesList}>
                      {dataset.access.authorized_roles.map((role, index) => (
                        <span key={index} className={styles.roleTag}>{role}</span>
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                <div className={styles.emptyState}>
                  <p>{t('no_access_information_available')}</p>
                </div>
              )}
            </div>
          </div>
        )}
        
        {activeTab === 'usage' && (
          <div className={styles.usageTab}>
            <div className={styles.section}>
              <h3 className={styles.sectionTitle}>{t('usage_statistics')}</h3>
              
              {dataset.usage_stats ? (
                <div className={styles.usageStats}>
                  <div className={styles.statsGrid}>
                    <div className={styles.statCard}>
                      <div className={styles.statValue}>{formatNumber(dataset.usage_stats.total_queries)}</div>
                      <div className={styles.statLabel}>{t('total_queries')}</div>
                    </div>
                    <div className={styles.statCard}>
                      <div className={styles.statValue}>{dataset.usage_stats.unique_users}</div>
                      <div className={styles.statLabel}>{t('unique_users')}</div>
                    </div>
                    <div className={styles.statCard}>
                      <div className={styles.statValue}>{dataset.usage_stats.average_query_time_ms} ms</div>
                      <div className={styles.statLabel}>{t('avg_query_time')}</div>
                    </div>
                    <div className={styles.statCard}>
                      <div className={styles.statValue}>{dataset.usage_stats.peak_usage_time}</div>
                      <div className={styles.statLabel}>{t('peak_usage_time')}</div>
                    </div>
                  </div>
                  
                  <div className={styles.usageDetails}>
                    <div className={styles.usageDetail}>
                      <h4 className={styles.subSectionTitle}>{t('most_frequent_users')}</h4>
                      <ul className={styles.usersList}>
                        {dataset.usage_stats.most_frequent_users.map((user, index) => (
                          <li key={index} className={styles.userItem}>{user}</li>
                        ))}
                      </ul>
                    </div>
                    
                    <div className={styles.usageDetail}>
                      <h4 className={styles.subSectionTitle}>{t('popular_joins')}</h4>
                      <div className={styles.joinsList}>
                        {dataset.usage_stats.popular_joins.map((join, index) => (
                          <span key={index} className={styles.joinTag}>{join}</span>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className={styles.emptyState}>
                  <p>{t('no_usage_statistics_available')}</p>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DatasetDetail;