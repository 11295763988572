// src/components/TripDetails/TripScoreOverview.js
import React from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import styles from './TripScoreOverview.module.css';

const TripScoreOverview = ({ scoreData }) => {
  const { t } = useLanguage();

  const renderScoreItem = (label, score) => (
    <div className={styles.cardScoredContentARo1}>
      <div className={styles.div}>{t[label]}</div>
      <div className={styles.badgeStatus}>
        <div className={styles.div1}>{score?.toFixed(2) || 'N/A'}</div>
      </div>
    </div>
  );

  return (
    <div className={styles.card}>
      <div className={styles.cardStandartTitleRow}>
        <div className={styles.textParent}>
          <div className={styles.text}>{t('trip_driving_score_overview')}</div>
          <div className={styles.text1}>{t('trip_driving_score_description')}</div>
        </div>
        <div className={styles.button}>
          <img className={styles.icons18MoreHorizontal} alt="" src="/icons/18/More-Horizontal.svg" />
        </div>
      </div>
      <div className={styles.cardScoredContainer}>
        <div className={styles.cardStandartContentA}>
          <div className={styles.cardStandartContentA1}>
            <div className={styles.div}>{t('attentiveness')}</div>
            <div className={styles.div}>:</div>
          </div>
          <div className={styles.cardStandartContentA2}>
            <div className={styles.div}>{scoreData?.isDistracted ? t('distracted') : t('no_distraction')}</div>
          </div>
        </div>
        <div className={styles.cardScoredContainerCo}>
          <div className={styles.cardScoredContentARo}>
            {renderScoreItem('overall_score', scoreData?.overallScore)}
            {renderScoreItem('acceleration_score', scoreData?.accelerationScore)}
          </div>
          <div className={styles.cardScoredContentARo}>
            {renderScoreItem('speeding_score', scoreData?.speedingScore)}
            {renderScoreItem('cornering_score', scoreData?.corneringScore)}
          </div>
          <div className={styles.cardScoredContentARo}>
            {renderScoreItem('braking_score', scoreData?.brakingScore)}
            {renderScoreItem('fatigue_driving_score', scoreData?.fatigueDrivingScore)}
          </div>
          <div className={styles.cardScoredContentARo}>
            {renderScoreItem('distracted_driving_score', scoreData?.distractedDrivingScore)}
            {renderScoreItem('contextual_score', scoreData?.contextualScore)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TripScoreOverview;