// File: frontend/src/pages/SuperAdmin/components/UserManagement/UserDetail/index.js

import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from './UserDetail.module.css';

const UserDetail = ({ userId, onBack }) => {
  const { t } = useLanguage();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('overview');

  useEffect(() => {
    const fetchUserDetails = async () => {
      setLoading(true);
      setError(null);
      
      try {
        // This would be replaced with an actual API call
        // const response = await userService.getUserDetails(userId);
        
        // Mock data for now
        await new Promise(resolve => setTimeout(resolve, 800));
        
        const mockUser = {
          user_id: userId,
          enterprise_user_id: `euser-${userId.substring(5)}`,
          enterprise_id: 'ent-12345',
          email: 'john.smith@acme.com',
          name: 'John Smith',
          first_name: 'John',
          last_name: 'Smith',
          user_role: 'admin',
          user_role_timestamp: '2023-01-16T09:30:00Z',
          user_verification_status: 'verified',
          user_verification_timestamp: '2023-01-16T10:15:00Z',
          super_admin_verification_status: 'verified',
          super_admin_verification_timestamp: '2023-01-16T11:00:00Z',
          organization_name: 'Acme Corporation',
          organization_size: 'medium',
          dataset_location: 'US-WEST',
          dataset_region: 'us-west-2',
          phone_number: '+14155552671',
          profile_picture_url: 'https://randomuser.me/api/portraits/men/1.jpg',
          created_at: '2023-01-16T09:30:00Z',
          updated_at: '2023-01-16T11:00:00Z',
          created_by: 'system',
          updated_by: 'admin-user-1',
          
          // Additional related data
          enterprise: {
            enterprise_id: 'ent-12345',
            enterprise_name: 'Acme Corporation',
            enterprise_status: 'active',
            country_code: 'US',
            province_state_code: 'CA'
          },
          
          permissions: [
            {
              resource: 'users',
              actions: ['view', 'create', 'update', 'delete']
            },
            {
              resource: 'enterprises',
              actions: ['view', 'update']
            },
            {
              resource: 'products',
              actions: ['view', 'create', 'update', 'delete']
            },
            {
              resource: 'agreements',
              actions: ['view', 'create', 'update']
            }
          ],
          
          activity_log: [
            {
              activity_id: 'act-12345',
              activity_type: 'login',
              timestamp: '2023-07-15T09:30:00Z',
              ip_address: '192.168.1.1',
              user_agent: 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.124 Safari/537.36'
            },
            {
              activity_id: 'act-23456',
              activity_type: 'product_created',
              timestamp: '2023-07-14T14:45:00Z',
              ip_address: '192.168.1.1',
              user_agent: 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.124 Safari/537.36',
              details: {
                product_id: 'prod-12345',
                product_name: 'Analytics Dashboard'
              }
            },
            {
              activity_id: 'act-34567',
              activity_type: 'user_updated',
              timestamp: '2023-07-13T11:20:00Z',
              ip_address: '192.168.1.1',
              user_agent: 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.124 Safari/537.36',
              details: {
                user_id: 'user-23456',
                fields_updated: ['user_role']
              }
            }
          ]
        };
        
        setUser(mockUser);
      } catch (err) {
        console.error('Error fetching user details:', err);
        setError(t('failed_to_load_user_details'));
      } finally {
        setLoading(false);
      }
    };
    
    if (userId) {
      fetchUserDetails();
    }
  }, [userId, t]);

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }).format(date);
  };

  const getVerificationBadgeClass = (status) => {
    switch (status) {
      case 'verified':
        return styles.statusVerified;
      case 'pending':
        return styles.statusPending;
      case 'rejected':
        return styles.statusRejected;
      default:
        return '';
    }
  };

  const handleVerifyUser = async () => {
    // This would be replaced with an actual API call
    // await userService.verifyUser(userId);
    
    // For now, just update the local state
    setUser(prev => ({
      ...prev,
      super_admin_verification_status: 'verified',
      super_admin_verification_timestamp: new Date().toISOString()
    }));
  };

  const handleRejectUser = async () => {
    // This would be replaced with an actual API call
    // await userService.rejectUser(userId);
    
    // For now, just update the local state
    setUser(prev => ({
      ...prev,
      super_admin_verification_status: 'rejected',
      super_admin_verification_timestamp: new Date().toISOString()
    }));
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <div className={styles.spinner}></div>
        <p>{t('loading_user_details')}</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.error}>
        <p>{error}</p>
        <button className={styles.backButton} onClick={onBack}>
          {t('back_to_users')}
        </button>
      </div>
    );
  }

  if (!user) {
    return (
      <div className={styles.notFound}>
        <p>{t('user_not_found')}</p>
        <button className={styles.backButton} onClick={onBack}>
          {t('back_to_users')}
        </button>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <div className={styles.userAvatar}>
            <img 
              src={user.profile_picture_url || '/images/default-avatar.png'} 
              alt={user.name} 
              className={styles.avatarImage}
            />
          </div>
          <div className={styles.userInfo}>
            <h2 className={styles.userName}>{user.name}</h2>
            <div className={styles.userEmail}>{user.email}</div>
            <div className={styles.userMeta}>
              <span className={styles.userRole}>{user.user_role}</span>
              <span className={styles.separator}>•</span>
              <span className={styles.userOrg}>{user.organization_name}</span>
            </div>
            <div className={styles.verificationStatus}>
              <span className={`${styles.verificationBadge} ${getVerificationBadgeClass(user.super_admin_verification_status)}`}>
                {user.super_admin_verification_status}
              </span>
              {user.super_admin_verification_status === 'pending' && (
                <div className={styles.verificationActions}>
                  <button 
                    className={styles.verifyButton}
                    onClick={handleVerifyUser}
                  >
                    {t('verify')}
                  </button>
                  <button 
                    className={styles.rejectButton}
                    onClick={handleRejectUser}
                  >
                    {t('reject')}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.headerActions}>
          <button className={styles.backButton} onClick={onBack}>
            <i className="fas fa-arrow-left"></i>
            <span>{t('back')}</span>
          </button>
        </div>
      </div>
      
      <div className={styles.tabs}>
        <button 
          className={`${styles.tab} ${activeTab === 'overview' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('overview')}
        >
          {t('overview')}
        </button>
        <button 
          className={`${styles.tab} ${activeTab === 'permissions' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('permissions')}
        >
          {t('permissions')}
        </button>
        <button 
          className={`${styles.tab} ${activeTab === 'activity' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('activity')}
        >
          {t('activity_log')}
        </button>
      </div>
      
      <div className={styles.tabContent}>
        {activeTab === 'overview' && (
          <div className={styles.overviewTab}>
            <div className={styles.section}>
              <h3 className={styles.sectionTitle}>{t('basic_information')}</h3>
              <div className={styles.infoGrid}>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('user_id')}</div>
                  <div className={styles.infoValue}>{user.user_id}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('enterprise_user_id')}</div>
                  <div className={styles.infoValue}>{user.enterprise_user_id}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('first_name')}</div>
                  <div className={styles.infoValue}>{user.first_name}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('last_name')}</div>
                  <div className={styles.infoValue}>{user.last_name}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('email')}</div>
                  <div className={styles.infoValue}>{user.email}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('phone_number')}</div>
                  <div className={styles.infoValue}>{user.phone_number || 'N/A'}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('user_role')}</div>
                  <div className={styles.infoValue}>{user.user_role}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('role_assigned_at')}</div>
                  <div className={styles.infoValue}>{formatDate(user.user_role_timestamp)}</div>
                </div>
              </div>
            </div>
            
            <div className={styles.section}>
              <h3 className={styles.sectionTitle}>{t('organization_information')}</h3>
              <div className={styles.infoGrid}>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('enterprise_id')}</div>
                  <div className={styles.infoValue}>{user.enterprise_id}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('organization_name')}</div>
                  <div className={styles.infoValue}>{user.organization_name}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('organization_size')}</div>
                  <div className={styles.infoValue}>{user.organization_size}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('dataset_location')}</div>
                  <div className={styles.infoValue}>{user.dataset_location}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('dataset_region')}</div>
                  <div className={styles.infoValue}>{user.dataset_region}</div>
                </div>
              </div>
            </div>
            
            <div className={styles.section}>
              <h3 className={styles.sectionTitle}>{t('verification_information')}</h3>
              <div className={styles.infoGrid}>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('user_verification_status')}</div>
                  <div className={styles.infoValue}>
                    <span className={`${styles.verificationBadge} ${getVerificationBadgeClass(user.user_verification_status)}`}>
                      {user.user_verification_status}
                    </span>
                  </div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('user_verification_timestamp')}</div>
                  <div className={styles.infoValue}>{formatDate(user.user_verification_timestamp)}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('super_admin_verification_status')}</div>
                  <div className={styles.infoValue}>
                    <span className={`${styles.verificationBadge} ${getVerificationBadgeClass(user.super_admin_verification_status)}`}>
                      {user.super_admin_verification_status}
                    </span>
                  </div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('super_admin_verification_timestamp')}</div>
                  <div className={styles.infoValue}>{formatDate(user.super_admin_verification_timestamp)}</div>
                </div>
              </div>
            </div>
            
            <div className={styles.section}>
              <h3 className={styles.sectionTitle}>{t('audit_information')}</h3>
              <div className={styles.infoGrid}>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('created_at')}</div>
                  <div className={styles.infoValue}>{formatDate(user.created_at)}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('created_by')}</div>
                  <div className={styles.infoValue}>{user.created_by}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('updated_at')}</div>
                  <div className={styles.infoValue}>{formatDate(user.updated_at)}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('updated_by')}</div>
                  <div className={styles.infoValue}>{user.updated_by}</div>
                </div>
              </div>
            </div>
          </div>
        )}
        
        {activeTab === 'permissions' && (
          <div className={styles.permissionsTab}>
            <div className={styles.section}>
              <h3 className={styles.sectionTitle}>{t('user_permissions')}</h3>
              
              {user.permissions && user.permissions.length > 0 ? (
                <div className={styles.permissionsList}>
                  {user.permissions.map((permission, index) => (
                    <div key={index} className={styles.permissionItem}>
                      <div className={styles.permissionResource}>
                        <i className={`fas fa-${getResourceIcon(permission.resource)}`}></i>
                        <span>{permission.resource}</span>
                      </div>
                      <div className={styles.permissionActions}>
                        {permission.actions.map(action => (
                          <span key={action} className={styles.permissionAction}>
                            {action}
                          </span>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className={styles.emptyState}>
                  <p>{t('no_permissions_found')}</p>
                </div>
              )}
              
              <div className={styles.permissionsActions}>
                <button className={styles.editPermissionsButton}>
                  <i className="fas fa-edit"></i>
                  <span>{t('edit_permissions')}</span>
                </button>
              </div>
            </div>
          </div>
        )}
        
        {activeTab === 'activity' && (
          <div className={styles.activityTab}>
            <div className={styles.section}>
              <h3 className={styles.sectionTitle}>{t('activity_log')}</h3>
              
              {user.activity_log && user.activity_log.length > 0 ? (
                <div className={styles.activityList}>
                  {user.activity_log.map((activity) => (
                    <div key={activity.activity_id} className={styles.activityItem}>
                      <div className={styles.activityIcon}>
                        <i className={`fas fa-${getActivityIcon(activity.activity_type)}`}></i>
                      </div>
                      <div className={styles.activityContent}>
                        <div className={styles.activityHeader}>
                          <div className={styles.activityType}>{formatActivityType(activity.activity_type)}</div>
                          <div className={styles.activityTime}>{formatDate(activity.timestamp)}</div>
                        </div>
                        <div className={styles.activityDetails}>
                          <div className={styles.activityDetail}>
                            <span className={styles.activityDetailLabel}>{t('ip_address')}:</span>
                            <span className={styles.activityDetailValue}>{activity.ip_address}</span>
                          </div>
                          {activity.details && (
                            <div className={styles.activityDetail}>
                              <span className={styles.activityDetailLabel}>{t('details')}:</span>
                              <span className={styles.activityDetailValue}>
                                {formatActivityDetails(activity.details)}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className={styles.emptyState}>
                  <p>{t('no_activity_found')}</p>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

// Helper functions for icons and formatting
const getResourceIcon = (resource) => {
  switch (resource) {
    case 'users':
      return 'users';
    case 'enterprises':
      return 'building';
    case 'products':
      return 'box';
    case 'agreements':
      return 'file-contract';
    default:
      return 'cog';
  }
};

const getActivityIcon = (activityType) => {
  switch (activityType) {
    case 'login':
      return 'sign-in-alt';
    case 'logout':
      return 'sign-out-alt';
    case 'product_created':
      return 'plus-circle';
    case 'user_updated':
      return 'user-edit';
    default:
      return 'history';
  }
};

const formatActivityType = (activityType) => {
  return activityType
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const formatActivityDetails = (details) => {
  if (!details) return '';
  
  return Object.entries(details)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return `${key}: ${value.join(', ')}`;
      }
      return `${key}: ${value}`;
    })
    .join(', ');
};

export default UserDetail;