// File: frontend/src/components/NewsCard/NewsCard.js

import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './NewsCard.module.css';

const NewsCard = ({ article, featured }) => {
  return (
    <div className={`${styles.newsCard} ${featured ? styles.featured : ''}`}>
      <img src={`/images/${article.image}`} alt={article.blog_title} className={styles.newsImage} />
      <div className={styles.newsContent}>
        <h3 className={styles.newsTitle}>{article.blog_title}</h3>
        <p className={styles.newsExcerpt}>{article.blog_excerpt}</p>
        <div className={styles.newsMetadata}>
          <span>{article.published_at}</span>
          <span>{article.blog_time_to_read} min read</span>
        </div>
        <Link to={`/news/${article.slug}`} className={styles.readMore}>Read More</Link>
      </div>
    </div>
  );
};

NewsCard.propTypes = {
  article: PropTypes.shape({
    image: PropTypes.string.isRequired,
    blog_title: PropTypes.string.isRequired,
    blog_excerpt: PropTypes.string.isRequired,
    published_at: PropTypes.string.isRequired,
    blog_time_to_read: PropTypes.number.isRequired,
    slug: PropTypes.string.isRequired,
  }).isRequired,
  featured: PropTypes.bool,
};

export default NewsCard;