// File: frontend/src/pages/DrivingScore/DrivingScore.js

import React from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import DrivingScoreContainer from '../../components/DrivingScore/DrivingScoreContainer';
import PageTitle from '../../components/layout/DashboardLayout/PageTitle';
import styles from './DrivingScore.module.css';

const DrivingScore = () => {
  const { t } = useLanguage();

  return (
    <div className={styles.drivingScorePage}>
      <PageTitle 
        title={t('driving_score')}
        filters={[
          { 
            type: 'sortingModel', 
            label: t('sorting_model'), 
            defaultValue: 'v2_1'
          },
          { 
            type: 'dateRange', 
            label: t('date_range'), 
            defaultValue: 'lastSevenDays'
          }
        ]}
      />
      <div className={styles.pageContent}>
        <DrivingScoreContainer />
      </div>
    </div>
  );
};

export default DrivingScore;