// File: frontend/src/pages/InvestorPitch/components/slides/CompetitiveAdvantageSlide/ComparisonMatrix.js

import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from './ComparisonMatrix.module.css';

const ComparisonMatrix = ({ categories }) => {
  const { t } = useLanguage();

  return (
    <motion.div 
      className={styles.container}
      whileHover={{ scale: 1.02 }}
      transition={{ type: 'spring', stiffness: 300, damping: 30 }}
    >
      <table className={styles.matrix}>
        <thead>
          <tr>
            <th className={styles.headerCell}>{t('investor_pitch.comparison.header.feature')}</th>
            <th className={styles.headerCell}>{t('investor_pitch.comparison.header.allegory')}</th>
            <th className={styles.headerCell}>{t('investor_pitch.comparison.header.others')}</th>
          </tr>
        </thead>
        <tbody>
          {categories.map((category, categoryIndex) => (
            <React.Fragment key={category.name}>
              <tr className={styles.categoryRow}>
                <td colSpan={3} className={styles.categoryCell}>{category.name}</td>
              </tr>
              {category.features.map((feature, featureIndex) => (
                <tr 
                  key={`${categoryIndex}-${featureIndex}`}
                  className={styles.featureRow}
                >
                  <td className={styles.featureCell}>{feature.name}</td>
                  <td className={`${styles.valueCell} ${styles.ourValue}`}>{feature.us}</td>
                  <td className={`${styles.valueCell} ${styles.othersValue}`}>{feature.others}</td>
                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </motion.div>
  );
};

ComparisonMatrix.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      features: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          us: PropTypes.string.isRequired,
          others: PropTypes.string.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
};

export default ComparisonMatrix;