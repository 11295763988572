// File: frontend/src/utils/errorHandler.js

import { ERROR_CODES } from '../config/errorMessages';
import { APIError } from '../services/api';

export const getErrorMessage = (error, t) => {
  // Log the error for debugging
  console.log('getErrorMessage received:', error);
  
  if (!(error instanceof APIError)) {
    console.warn('Non-APIError received:', error);
    return t(ERROR_CODES.SERVER_ERROR.DEFAULT);
  }

  const { code, details } = error;

  // Handle standardized error codes from the backend
  if (code) {
    // Handle specific cases where we need additional context/parameters
    switch (code) {
      case 'RATE_LIMIT.DEFAULT':
        const retryAfter = error.getRetryAfter();
        return t(ERROR_CODES.RATE_LIMIT.DEFAULT, { seconds: retryAfter });

      case 'AUTH.OTP.INVALID':
        if (details?.reason === 'EXPIRED') {
          return t(ERROR_CODES.AUTH.OTP.EXPIRED);
        }
        if (details?.reason === 'MAX_ATTEMPTS_REACHED') {
          return t(ERROR_CODES.AUTH.OTP.MAX_ATTEMPTS);
        }
        return t(ERROR_CODES.AUTH.OTP.INVALID);

      default:
        // Split the code and traverse ERROR_CODES
        const codePath = code.split('.');
        let errorCode = ERROR_CODES;
        
        for (const part of codePath) {
          errorCode = errorCode[part];
          if (!errorCode) {
            console.warn(`Unknown error code path: ${code}`);
            return t(ERROR_CODES.SERVER_ERROR.DEFAULT);
          }
        }
        
        return t(errorCode);
    }
  }

  // Handle validation errors array if present
  if (details?.errors?.length > 0) {
    return details.errors.map(err => {
      // Map specific validation error codes
      if (err.code) {
        return t(err.code);
      }
      
      // Legacy validation error handling if needed
      return t(ERROR_CODES.VALIDATION.DEFAULT);
    }).join('. ');
  }

  // Final fallback
  return t(ERROR_CODES.SERVER_ERROR.DEFAULT);
};

// Helper function to check if code exists in ERROR_CODES
const isValidErrorCode = (code) => {
  if (!code) return false;
  
  const codePath = code.split('.');
  let current = ERROR_CODES;
  
  for (const part of codePath) {
    current = current[part];
    if (!current) return false;
  }
  
  return typeof current === 'string';
};