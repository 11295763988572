// File: frontend/src/pages/PropertyDetail/components/PropertySafety/index.js

import React from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import styles from './PropertySafety.module.css';

const PropertySafety = ({ property }) => {
  const { t } = useLanguage();

  // Helper function to format feature status
  const formatFeatureStatus = (status) => {
    if (status === null || status === undefined) return 'N/A';
    
    if (typeof status === 'boolean') {
      return status ? t('yes') : t('no');
    }
    
    return status;
  };

  return (
    <div className={styles.container}>
      <div className={styles.grid}>
        <div className={styles.card}>
          <h3 className={styles.cardTitle}>{t('alarm_systems')}</h3>
          <div className={styles.cardContent}>
            {property.safetyFeatures ? (
              <div className={styles.featuresGrid}>
                <div className={styles.featureItem}>
                  <div className={styles.featureLabel}>{t('fire_alarm_type')}:</div>
                  <div className={styles.featureValue}>{property.safetyFeatures.fire_alarm_type}</div>
                </div>
                <div className={styles.featureItem}>
                  <div className={styles.featureLabel}>{t('burglar_alarm_type')}:</div>
                  <div className={styles.featureValue}>{property.safetyFeatures.burglar_alarm_type}</div>
                </div>
                <div className={styles.featureItem}>
                  <div className={styles.featureLabel}>{t('fire_detection_system')}:</div>
                  <div className={styles.featureValue}>
                    {formatFeatureStatus(property.safetyFeatures.fire_detection_system)}
                  </div>
                </div>
                <div className={styles.featureItem}>
                  <div className={styles.featureLabel}>{t('security_system')}:</div>
                  <div className={styles.featureValue}>
                    {formatFeatureStatus(property.safetyFeatures.security_system)}
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.noData}>{t('no_alarm_system_information_available')}</div>
            )}
          </div>
        </div>
        
        <div className={styles.card}>
          <h3 className={styles.cardTitle}>{t('water_protection')}</h3>
          <div className={styles.cardContent}>
            {property.safetyFeatures ? (
              <div className={styles.featuresGrid}>
                <div className={styles.featureItem}>
                  <div className={styles.featureLabel}>{t('water_leak_detection_system')}:</div>
                  <div className={styles.featureValue}>
                    {formatFeatureStatus(property.safetyFeatures.water_leak_detection_system)}
                  </div>
                </div>
                <div className={styles.featureItem}>
                  <div className={styles.featureLabel}>{t('sump_pump_type')}:</div>
                  <div className={styles.featureValue}>{property.safetyFeatures.sump_pump_type}</div>
                </div>
                <div className={styles.featureItem}>
                  <div className={styles.featureLabel}>{t('sump_pump_aux_power')}:</div>
                  <div className={styles.featureValue}>
                    {formatFeatureStatus(property.safetyFeatures.sump_pump_aux_power)}
                  </div>
                </div>
                <div className={styles.featureItem}>
                  <div className={styles.featureLabel}>{t('back_flow_valve')}:</div>
                  <div className={styles.featureValue}>
                    {formatFeatureStatus(property.safetyFeatures.back_flow_valve)}
                  </div>
                </div>
                <div className={styles.featureItem}>
                  <div className={styles.featureLabel}>{t('has_sump_pump_pit')}:</div>
                  <div className={styles.featureValue}>
                    {formatFeatureStatus(property.safetyFeatures.has_sump_pump_pit)}
                  </div>
                </div>
                <div className={styles.featureItem}>
                  <div className={styles.featureLabel}>{t('is_sump_pump_alarmed')}:</div>
                  <div className={styles.featureValue}>
                    {formatFeatureStatus(property.safetyFeatures.is_sump_pump_alarmed)}
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.noData}>{t('no_water_protection_information_available')}</div>
            )}
          </div>
        </div>
      </div>
      
      <div className={styles.card}>
        <h3 className={styles.cardTitle}>{t('fire_protection')}</h3>
        <div className={styles.cardContent}>
          <div className={styles.fireProtectionGrid}>
            <div className={styles.featureItem}>
              <div className={styles.featureLabel}>{t('fire_hall_distance')}:</div>
              <div className={styles.featureValue}>
                {property.fire_hall_distance} {property.fire_hall_distance_unit}
              </div>
            </div>
            <div className={styles.featureItem}>
              <div className={styles.featureLabel}>{t('fire_hydrant_distance')}:</div>
              <div className={styles.featureValue}>
                {property.fire_hydrant_distance} {property.fire_hydrant_distance_unit}
              </div>
            </div>
            <div className={styles.featureItem}>
              <div className={styles.featureLabel}>{t('fire_hall_name')}:</div>
              <div className={styles.featureValue}>{property.fire_hall_name}</div>
            </div>
            <div className={styles.featureItem}>
              <div className={styles.featureLabel}>{t('protection_class')}:</div>
              <div className={styles.featureValue}>{property.protection_class}</div>
            </div>
            <div className={styles.featureItem}>
              <div className={styles.featureLabel}>{t('protection_class_basis')}:</div>
              <div className={styles.featureValue}>{property.protection_class_basis}</div>
            </div>
          </div>
        </div>
      </div>
      
      {property.riskAssessment && (
        <div className={styles.card}>
          <h3 className={styles.cardTitle}>{t('additional_safety_features')}</h3>
          <div className={styles.cardContent}>
            <div className={styles.additionalFeaturesGrid}>
              <div className={styles.featureItem}>
                <div className={styles.featureLabel}>{t('alarm_type')}:</div>
                <div className={styles.featureValue}>{property.riskAssessment.alarm_type}</div>
              </div>
              <div className={styles.featureItem}>
                <div className={styles.featureLabel}>{t('obc_compliance')}:</div>
                <div className={styles.featureValue}>
                  {formatFeatureStatus(property.riskAssessment.obc_compliance)}
                </div>
              </div>
              <div className={styles.featureItem}>
                <div className={styles.featureLabel}>{t('ccq_compliance')}:</div>
                <div className={styles.featureValue}>
                  {formatFeatureStatus(property.riskAssessment.ccq_compliance)}
                </div>
              </div>
              <div className={styles.featureItem}>
                <div className={styles.featureLabel}>{t('hurricane_protection')}:</div>
                <div className={styles.featureValue}>
                  {formatFeatureStatus(property.riskAssessment.hurricane_protection)}
                </div>
              </div>
              <div className={styles.featureItem}>
                <div className={styles.featureLabel}>{t('coastal_construction')}:</div>
                <div className={styles.featureValue}>
                  {formatFeatureStatus(property.riskAssessment.coastal_construction)}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      
      <div className={styles.safetyRecommendations}>
        <h3 className={styles.recommendationsTitle}>{t('safety_recommendations')}</h3>
        <div className={styles.recommendationsList}>
          {property.safetyFeatures && !property.safetyFeatures.fire_detection_system && (
            <div className={styles.recommendationItem}>
              <div className={styles.recommendationIcon}>⚠️</div>
              <div className={styles.recommendationContent}>
                <div className={styles.recommendationTitle}>{t('install_fire_detection_system')}</div>
                <div className={styles.recommendationDescription}>
                  {t('fire_detection_system_recommendation')}
                </div>
              </div>
            </div>
          )}
          
          {property.safetyFeatures && !property.safetyFeatures.water_leak_detection_system && (
            <div className={styles.recommendationItem}>
              <div className={styles.recommendationIcon}>⚠️</div>
              <div className={styles.recommendationContent}>
                <div className={styles.recommendationTitle}>{t('install_water_leak_detection')}</div>
                <div className={styles.recommendationDescription}>
                  {t('water_leak_detection_recommendation')}
                </div>
              </div>
            </div>
          )}
          
          {property.safetyFeatures && !property.safetyFeatures.security_system && (
            <div className={styles.recommendationItem}>
              <div className={styles.recommendationIcon}>⚠️</div>
              <div className={styles.recommendationContent}>
                <div className={styles.recommendationTitle}>{t('install_security_system')}</div>
                <div className={styles.recommendationDescription}>
                  {t('security_system_recommendation')}
                </div>
              </div>
            </div>
          )}
          
          {property.safetyFeatures && !property.safetyFeatures.back_flow_valve && (
            <div className={styles.recommendationItem}>
              <div className={styles.recommendationIcon}>⚠️</div>
              <div className={styles.recommendationContent}>
                <div className={styles.recommendationTitle}>{t('install_back_flow_valve')}</div>
                <div className={styles.recommendationDescription}>
                  {t('back_flow_valve_recommendation')}
                </div>
              </div>
            </div>
          )}
          
          {(!property.safetyFeatures || 
            (property.safetyFeatures.fire_detection_system && 
             property.safetyFeatures.water_leak_detection_system && 
             property.safetyFeatures.security_system && 
             property.safetyFeatures.back_flow_valve)) && (
            <div className={styles.noRecommendations}>
              {t('no_safety_recommendations')}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PropertySafety;