// File: frontend/src/components/form/auth/OTPVerificationSignInV2.js

import React from 'react';
import PropTypes from 'prop-types';
import { useLanguage } from '../../../contexts/LanguageContext';
import { useAuth } from '../../../contexts/AuthContextV2';
import OTPVerificationBase from './OTPVerificationBase';

const OTPVerificationSignInV2 = ({ 
  onVerify, 
  authMethod, 
  contactInfo, 
  onResend 
}) => {
  const { t } = useLanguage();
  const { loading, error } = useAuth();

  const getTitle = () => t(`auth.otpVerification.title.${authMethod}`);
  
  const getDescription = () => t(`auth.otpVerification.description.${authMethod}`, { 
    contactInfo 
  });

  return (
    <OTPVerificationBase
      length={7} // SignIn uses 7 digits
      onVerify={onVerify}
      onResend={onResend}
      loading={loading}
      error={error}
      iconSrc="/icons/misc/auth-otp-icon-group-sign-in.svg"
      title={getTitle()}
      description={getDescription()}
      cooldownInitialTimes={{
        first: 60,    // 1 minute
        second: 600,  // 10 minutes
        third: 86400  // 24 hours
      }}
    />
  );
};

OTPVerificationSignInV2.propTypes = {
  onVerify: PropTypes.func.isRequired,
  onResend: PropTypes.func.isRequired,
  authMethod: PropTypes.oneOf(['email', 'phone']).isRequired,
  contactInfo: PropTypes.string.isRequired,
};

export default OTPVerificationSignInV2;