// File: frontend/src/services/user.js

// Mock user data
const mockUsers = [
  {
    user_id: "usr-12345",
    user_type: 1,
    account_status: 495, // active
    first_name: "John",
    last_name: "Doe",
    email_address: "john.doe@example.com",
    is_email_verified: true,
    mobile_phone_number: "555-123-4567",
    is_phone_verified: true,
    date_of_birth: "1985-06-15",
    gender: "Male",
    country_of_residence: "Canada",
    primary_language: "English",
    created_at: "2022-03-15T10:30:00Z",
    updated_at: "2023-01-20T14:45:00Z",
    
    // Additional details for user detail view
    middle_name: null,
    prefix: "Mr.",
    suffix: null,
    email_address_secondary: null,
    country_phone_code: "1",
    area_code: "555",
    home_phone_number_full: null,
    work_phone_number_full: null,
    work_phone_number_extension: null,
    fax_phone_number_full: null,
    marital_status: "Single",
    occupation: "Software Developer",
    employment_status: "Full-time",
    is_retired: false,
    primary_mode_transportation: "Car",
    preferred_measurement_unit: "metric",
    user_loyalty_status: "Gold",
    is_allegory_tester: false,
    is_allegory_featured_driver: true,
    is_allegory_premium_user: true,
    is_allegory_premium_family_share: false,
    privacy_protection: true,
    training_mode: false,
    created_by: "system",
    updated_by: "admin",
    
    // Addresses
    addresses: [
      {
        user_address_id: "addr-1",
        address_type: 1, // home
        is_primary: true,
        is_billing: true,
        is_shipping: true,
        address_id: {
          street_number: "123",
          street_name: "Main St",
          unit_number: "Apt 4B",
          city: "Toronto",
          province: "Ontario",
          postal_code: "M5V 2N4",
          country: "Canada"
        }
      },
      {
        user_address_id: "addr-2",
        address_type: 2, // work
        is_primary: false,
        is_billing: false,
        is_shipping: false,
        address_id: {
          street_number: "456",
          street_name: "Business Ave",
          unit_number: "Suite 200",
          city: "Toronto",
          province: "Ontario",
          postal_code: "M5J 2T3",
          country: "Canada"
        }
      }
    ],
    
    // Permissions
    permissions: {
      is_phone_number_verified: true,
      is_email_address_verified: true,
      is_marketing_content_active: true,
      is_notification_allowed: true,
      is_location_allowed: true,
      is_precise_location_allowed: true,
      is_motion_allowed: true,
      is_trip_detection_allowed: true,
      is_data_sharing_allowed: false,
      is_third_party_integration_allowed: false,
      is_biometric_auth_allowed: true
    },
    
    // Permissions history
    permissions_history: [
      {
        history_id: "hist-1",
        changed_field: "is_data_sharing_allowed",
        old_value: true,
        new_value: false,
        changed_at: "2023-01-15T09:30:00Z",
        changed_by: "user"
      },
      {
        history_id: "hist-2",
        changed_field: "is_third_party_integration_allowed",
        old_value: true,
        new_value: false,
        changed_at: "2023-01-15T09:31:00Z",
        changed_by: "user"
      }
    ],
    
    // Connections
    connections: [
      {
        connection_id: "conn-1",
        connected_user_id: "usr-67890",
        connected_user_name: "Jane Smith",
        user_connection_type: 2, // friend
        user_connection_status: 2, // active
        connection_date: "2022-05-20T14:30:00Z",
        last_interaction_date: "2023-02-10T11:15:00Z"
      },
      {
        connection_id: "conn-2",
        connected_user_id: "usr-54321",
        connected_user_name: "Bob Johnson",
        user_connection_type: 1, // family
        user_connection_status: 2, // active
        connection_date: "2022-06-15T10:45:00Z",
        last_interaction_date: "2023-02-05T16:20:00Z"
      },
      {
        connection_id: "conn-3",
        connected_user_name: "Alice Williams",
        user_connection_type: 3, // colleague
        user_connection_status: 1, // pending
        invite_link: "https://example.com/invite/abc123",
        invite_expiry: "2023-03-15T23:59:59Z"
      }
    ]
  },
  {
    user_id: "usr-67890",
    user_type: 2,
    account_status: 495, // active
    first_name: "Jane",
    last_name: "Smith",
    email_address: "jane.smith@example.com",
    is_email_verified: true,
    mobile_phone_number: "555-987-6543",
    is_phone_verified: true,
    date_of_birth: "1990-08-22",
    gender: "Female",
    country_of_residence: "Canada",
    primary_language: "English",
    created_at: "2022-04-10T15:20:00Z",
    updated_at: "2023-01-25T11:30:00Z"
  },
  // Add more mock users as needed
];

const userService = {
  // Search users based on query parameters
  searchUser: async (params) => {
    // Simulate API delay
    await new Promise(resolve => setTimeout(resolve, 800));
    
    let filteredUsers = [...mockUsers];
    
    // Apply filters based on params
    if (params.field && params.query) {
      const query = params.query.toLowerCase();
      
      switch (params.field) {
        case 'user_id':
          filteredUsers = filteredUsers.filter(user => 
            user.user_id.toLowerCase().includes(query)
          );
          break;
        case 'first_name':
          filteredUsers = filteredUsers.filter(user => 
            user.first_name.toLowerCase().includes(query)
          );
          break;
        case 'last_name':
          filteredUsers = filteredUsers.filter(user => 
            user.last_name.toLowerCase().includes(query)
          );
          break;
        case 'email_address':
          filteredUsers = filteredUsers.filter(user => 
            user.email_address.toLowerCase().includes(query)
          );
          break;
        case 'mobile_phone_number':
          filteredUsers = filteredUsers.filter(user => 
            user.mobile_phone_number.includes(query)
          );
          break;
        default:
          break;
      }
    }
    
    // Apply account status filter
    if (params.account_status) {
      filteredUsers = filteredUsers.filter(user => 
        user.account_status === parseInt(params.account_status)
      );
    }
    
    // Apply user type filter
    if (params.user_type) {
      filteredUsers = filteredUsers.filter(user => 
        user.user_type === parseInt(params.user_type)
      );
    }
    
    // Apply date range filter (simplified)
    if (params.date_range) {
      // This is a simplified implementation
      // In a real app, you would parse the date range and filter accordingly
      console.log(`Filtering by date range: ${params.date_range}`);
    }
    
    // Apply pagination
    const page = params.page || 1;
    const limit = params.limit || 10;
    const startIndex = (page - 1) * limit;
    const endIndex = startIndex + limit;
    const paginatedUsers = filteredUsers.slice(startIndex, endIndex);
    
    return {
      users: paginatedUsers,
      total: filteredUsers.length,
      page: page,
      limit: limit,
      pages: Math.ceil(filteredUsers.length / limit)
    };
  },
  
  // Get user by ID
  getUser: async (userId) => {
    // Simulate API delay
    await new Promise(resolve => setTimeout(resolve, 500));
    
    const user = mockUsers.find(user => user.user_id === userId);
    
    if (!user) {
      throw new Error('User not found');
    }
    
    return user;
  },
  
  // Create a new user
  createUser: async (userData) => {
    // Simulate API delay
    await new Promise(resolve => setTimeout(resolve, 1000));
    
    // Generate a new user ID
    const userId = `usr-${Math.floor(Math.random() * 100000)}`;
    
    // Create a new user object
    const newUser = {
      user_id: userId,
      ...userData,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString()
    };
    
    // In a real app, you would send this to your API
    console.log('Creating new user:', newUser);
    
    return newUser;
  },
  
  // Update an existing user
  updateUser: async (userId, userData) => {
    // Simulate API delay
    await new Promise(resolve => setTimeout(resolve, 800));
    
    const userIndex = mockUsers.findIndex(user => user.user_id === userId);
    
    if (userIndex === -1) {
      throw new Error('User not found');
    }
    
    // Update the user
    const updatedUser = {
      ...mockUsers[userIndex],
      ...userData,
      updated_at: new Date().toISOString()
    };
    
    // In a real app, you would send this to your API
    console.log('Updating user:', updatedUser);
    
    return updatedUser;
  },
  
  // Delete a user
  deleteUser: async (userId) => {
    // Simulate API delay
    await new Promise(resolve => setTimeout(resolve, 600));
    
    const userIndex = mockUsers.findIndex(user => user.user_id === userId);
    
    if (userIndex === -1) {
      throw new Error('User not found');
    }
    
    // In a real app, you would send this to your API
    console.log('Deleting user:', userId);
    
    return { success: true };
  }
};

export default userService;