// File: frontend/src/pages/AutoInsight/components/VehicleStatistics/index.js

import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import styles from './VehicleStatistics.module.css';

const VehicleStatistics = () => {
  const { t } = useLanguage();
  const [stats, setStats] = useState({
    totalVehicles: 0,
    activeVehicles: 0,
    primaryVehicles: 0,
    recentlyAddedVehicles: 0
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch statistics data
    const fetchStats = async () => {
      try {
        // This would be replaced with an actual API call
        await new Promise(resolve => setTimeout(resolve, 500));
        
        setStats({
          totalVehicles: 1250,
          activeVehicles: 950,
          primaryVehicles: 450,
          recentlyAddedVehicles: 75
        });
      } catch (error) {
        console.error('Error fetching statistics:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, []);

  return (
    <div className={styles.container}>
      {loading ? (
        <div className={styles.loading}>{t('loading_statistics')}</div>
      ) : (
        <div className={styles.statsGrid}>
          <div className={`${styles.statCard} ${styles.total}`}>
            <div className={styles.statValue}>{stats.totalVehicles}</div>
            <div className={styles.statLabel}>{t('total_vehicles')}</div>
          </div>
          
          <div className={`${styles.statCard} ${styles.active}`}>
            <div className={styles.statValue}>{stats.activeVehicles}</div>
            <div className={styles.statLabel}>{t('active_vehicles')}</div>
          </div>
          
          <div className={`${styles.statCard} ${styles.primary}`}>
            <div className={styles.statValue}>{stats.primaryVehicles}</div>
            <div className={styles.statLabel}>{t('primary_vehicles')}</div>
          </div>
          
          <div className={`${styles.statCard} ${styles.recent}`}>
            <div className={styles.statValue}>{stats.recentlyAddedVehicles}</div>
            <div className={styles.statLabel}>{t('recently_added')}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VehicleStatistics;