// File: frontend/src/pages/InvestorPitch/components/slides/PartnershipsSlide/IntegrationTimeline.js

import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import * as LucideIcons from 'lucide-react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from './IntegrationTimeline.module.css';

const IntegrationTimeline = ({ highlights }) => {
  const { t } = useLanguage();

  return (
    <motion.div 
      className={styles.container}
      whileHover={{ scale: 1.02 }}
      transition={{ type: 'spring', stiffness: 300, damping: 30 }}
    >
      {highlights.map((highlight, index) => {
        const Icon = LucideIcons[highlight.icon];
        return (
          <div 
            key={highlight.title} 
            className={styles.highlight}
            aria-label={t('investor_pitch.partnerships.integration.highlight.aria_label', { 
              title: highlight.title 
            })}
          >
            <div 
              className={styles.iconWrapper}
              style={{ color: highlight.color }}
            >
              <Icon 
                className={styles.icon}
                aria-hidden="true"
              />
            </div>
            <div className={styles.content}>
              <h4 className={styles.title}>{highlight.title}</h4>
              <p className={styles.description}>{highlight.description}</p>
            </div>
          </div>
        );
      })}
    </motion.div>
  );
};

IntegrationTimeline.propTypes = {
  highlights: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default IntegrationTimeline;