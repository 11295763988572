// File: frontend/src/pages/InvestorPitch/components/slides/MarketImpactSlide/MarketImpactSlide.js

import React from 'react';
import { motion } from 'framer-motion';
import * as LucideIcons from 'lucide-react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import MarketCard from './MarketCard';
import ImpactMetric from './ImpactMetric';
import { ANIMATION_VARIANTS } from '../../../constants/animations';
import styles from './MarketImpactSlide.module.css';

const MarketImpactSlide = () => {
  const { t } = useLanguage();

  const markets = [
    {
      country: "🇨🇦 " + t('investor_pitch.market_impact.markets.canada.name'),
      language: t('investor_pitch.market_impact.markets.canada.languages'),
      readiness: 100,
      highlights: [
        t('investor_pitch.market_impact.markets.canada.highlights.regulatory'),
        t('investor_pitch.market_impact.markets.canada.highlights.partnerships'),
        t('investor_pitch.market_impact.markets.canada.highlights.bilingual')
      ],
      // gradient: "linear-gradient(135deg, var(--color-blue-110), var(--color-blue-310))",
      gradient: "linear-gradient(135deg, var(--color-green-110), var(--color-green-410))"
    },
    {
      country: "🇺🇸 " + t('investor_pitch.market_impact.markets.us.name'),
      language: t('investor_pitch.market_impact.markets.us.languages'),
      readiness: 75,
      highlights: [
        t('investor_pitch.market_impact.markets.us.highlights.rollout'),
        t('investor_pitch.market_impact.markets.us.highlights.focus'),
        t('investor_pitch.market_impact.markets.us.highlights.partnerships')
      ],
      gradient: "linear-gradient(135deg, var(--color-green-110), var(--color-green-310))"
    },
    {
      country: "🇬🇧 " + t('investor_pitch.market_impact.markets.uk.name'),
      language: t('investor_pitch.market_impact.markets.uk.languages'),
      readiness: 60,
      highlights: [
        t('investor_pitch.market_impact.markets.uk.highlights.compliance'),
        t('investor_pitch.market_impact.markets.uk.highlights.lloyds'),
        t('investor_pitch.market_impact.markets.uk.highlights.ecosystem')
      ],
      gradient: "linear-gradient(135deg, var(--color-orange-110), var(--color-orange-310))"
    },
    {
      country: "🇪🇺 " + t('investor_pitch.market_impact.markets.eu.name'),
      language: t('investor_pitch.market_impact.markets.eu.languages'),
      readiness: 45,
      highlights: [
        t('investor_pitch.market_impact.markets.eu.highlights.passport'),
        t('investor_pitch.market_impact.markets.eu.highlights.compliance'),
        t('investor_pitch.market_impact.markets.eu.highlights.network')
      ],
      gradient: "linear-gradient(135deg, var(--color-orange-110), var(--color-orange-310))"
    }
  ];

  const impactMetrics = [
    {
      title: t('investor_pitch.market_impact.metrics.coverage.title'),
      value: "65%",
      icon: "PieChart",
      description: t('investor_pitch.market_impact.metrics.coverage.description'),
      color: "var(--color-blue-310)"
    },
    {
      title: t('investor_pitch.market_impact.metrics.cost.title'),
      value: "25%",
      icon: "TrendingDown",
      description: t('investor_pitch.market_impact.metrics.cost.description'),
      color: "var(--color-green-310)"
    }
  ];

  return (
    <motion.div
      className={styles.container}
      variants={ANIMATION_VARIANTS.stagger.container}
      initial="hidden"
      animate="visible"
    >
      <motion.div 
        className={styles.header}
        variants={ANIMATION_VARIANTS.fadeIn}
      >
        <h2 className={styles.title}>{t('investor_pitch.market_impact.title')}</h2>
        <p className={styles.subtitle}>{t('investor_pitch.market_impact.subtitle')}</p>
      </motion.div>

      <div className={styles.content}>
        <motion.section 
          className={styles.marketsGrid}
          variants={ANIMATION_VARIANTS.stagger.container}
        >
          {markets.map((market, index) => (
            <motion.div
              key={market.country}
              variants={ANIMATION_VARIANTS.stagger.item}
              custom={index}
            >
              <MarketCard {...market} />
            </motion.div>
          ))}
        </motion.section>

        <motion.section 
          className={styles.impactMetrics}
          variants={ANIMATION_VARIANTS.stagger.container}
        >
          {impactMetrics.map((metric, index) => (
            <motion.div
              key={metric.title}
              variants={ANIMATION_VARIANTS.stagger.item}
              custom={index}
            >
              <ImpactMetric {...metric} />
            </motion.div>
          ))}
        </motion.section>
      </div>
    </motion.div>
  );
};

export default MarketImpactSlide;