// File: frontend/src/pages/InvestorPitch/components/slides/ObsessedWithDataSlide/ObsessedWithDataSlide.js

import React, { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import { ANIMATION_VARIANTS } from '../../../constants/animations';
import styles from './ObsessedWithDataSlide.module.css';
import { FiPlayCircle, FiX, FiLoader } from 'react-icons/fi';

const videoItems = [
  {
    id: 'ai1',
    title: 'Allegory Intelligence AI-1 - 3:34',
    src: 'https://allegory-public.s3.ca-central-1.amazonaws.com/pitch/pitch-products-ai1.mp4',
    poster: 'https://allegory-public.s3.ca-central-1.amazonaws.com/pitch/pitch-products-ai1-poster.jpg'
  },
  {
    id: 'sales',
    title: 'AI-1 Powered Insurance Sales - 1:07',
    src: 'https://allegory-public.s3.ca-central-1.amazonaws.com/pitch/pitch-sales-desktop.mp4',
    poster: 'https://allegory-public.s3.ca-central-1.amazonaws.com/pitch/pitch-sales-desktop-poster.jpg'
  },
  {
    id: 'qms',
    title: 'Quote Management System - 2:03',
    src: 'https://allegory-public.s3.ca-central-1.amazonaws.com/pitch/pitch-products-qms.mp4',
    poster: 'https://allegory-public.s3.ca-central-1.amazonaws.com/pitch/pitch-products-qms-poster.jpg'
  },
  {
    id: 'embedded',
    title: 'Embedded Insurance - 3:04',
    src: 'https://allegory-public.s3.ca-central-1.amazonaws.com/pitch/pitch-products-embedded-insurance.mp4',
    poster: 'https://allegory-public.s3.ca-central-1.amazonaws.com/pitch/pitch-products-embedded-insurance-poster.jpg'
  },
  {
    id: 'auto',
    title: 'Auto Insight - 0:57',
    src: 'https://allegory-public.s3.ca-central-1.amazonaws.com/pitch/pitch-products-auto-insight.mp4',
    poster: 'https://allegory-public.s3.ca-central-1.amazonaws.com/pitch/pitch-products-auto-insight-poster.jpg'
  },
  {
    id: 'property',
    title: 'Property Insight - 2:15',
    src: 'https://allegory-public.s3.ca-central-1.amazonaws.com/pitch/pitch-products-property-insight.mp4',
    poster: 'https://allegory-public.s3.ca-central-1.amazonaws.com/pitch/pitch-products-property-insight-poster.jpg'
  },
];

const ObsessedWithDataSlide = () => {
  const { t } = useLanguage();
  const [activeVideo, setActiveVideo] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [modalVideoError, setModalVideoError] = useState(false);
  const videoRef = useRef(null);
  const containerRef = useRef(null);

  // Detect mobile devices
  useEffect(() => {
    const checkMobile = () => {
      const mobile = window.innerWidth <= 768;
      setIsMobile(mobile);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const handlePlay = (video) => {
    setActiveVideo(video);
    setIsModalLoading(true);
    setModalVideoError(false);
  };

  const handleClose = () => {
    setActiveVideo(null);
    setIsModalLoading(false);
    setModalVideoError(false);
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
  };

  const handleModalVideoLoaded = () => {
    setIsModalLoading(false);
  };

  const handleModalVideoError = () => {
    setModalVideoError(true);
    setIsModalLoading(false);
  };

  // Get video source based on device
  const getVideoSource = (originalSrc) => {
    if (isMobile) {
      // Extract the base filename and add -mobile suffix
      const parts = originalSrc.split('.');
      const extension = parts.pop();
      const basePath = parts.join('.');
      return `${basePath}-mobile.${extension}`;
    }
    return originalSrc;
  };

  // Handle escape key to close modal
  useEffect(() => {
    const handleEscKey = (e) => {
      if (e.key === 'Escape' && activeVideo) {
        handleClose();
      }
    };

    window.addEventListener('keydown', handleEscKey);
    return () => window.removeEventListener('keydown', handleEscKey);
  }, [activeVideo]);

  // Lock body scroll when modal is open
  useEffect(() => {
    if (activeVideo) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    
    return () => {
      document.body.style.overflow = '';
    };
  }, [activeVideo]);

  return (
    <motion.div
      className={styles.container}
      variants={ANIMATION_VARIANTS.stagger.container}
      initial="hidden"
      animate="visible"
      ref={containerRef}
    >
      <motion.div 
        className={styles.header}
        variants={ANIMATION_VARIANTS.fadeIn}
      >
        <h2 className={styles.title}>
          {t('investor_pitch.obsessed_with_data.title', 'Obsessed With Data')}
        </h2>
        <p className={styles.subtitle}>
          {t('investor_pitch.obsessed_with_data.subtitle', '4,000+ Data Points, All Managed by AI-1')}
        </p>
      </motion.div>

      <motion.div 
        className={styles.grid}
        variants={ANIMATION_VARIANTS.stagger.container}
      >
        {videoItems.map((item, index) => (
          <motion.div
            key={item.id}
            className={styles.card}
            variants={ANIMATION_VARIANTS.stagger.item}
            custom={index}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={() => handlePlay(item)}
          >
            <div className={styles.cardVideoContainer}>
              <img 
                src={item.poster} 
                alt={item.title} 
                className={styles.cardPoster}
              />
              <div className={styles.playIconWrapper}>
                <FiPlayCircle className={styles.playIcon} />
              </div>
            </div>
            <div className={styles.cardTitle}>{item.title}</div>
          </motion.div>
        ))}
      </motion.div>

      {/* Video Modal */}
      <AnimatePresence>
        {activeVideo && (
          <motion.div
            className={styles.modalOverlay}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={handleClose}
          >
            <motion.div
              className={styles.modalContent}
              initial={{ scale: 0.95 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.95 }}
              onClick={(e) => e.stopPropagation()}
            >
              {isModalLoading && (
                <div className={styles.modalLoading}>
                  <div className={styles.loadingSpinner} />
                </div>
              )}
              
              {modalVideoError ? (
                <div className={styles.modalError}>
                  <p>Video could not be loaded</p>
                  <button 
                    className={styles.retryButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      setModalVideoError(false);
                      setIsModalLoading(true);
                      if (videoRef.current) {
                        videoRef.current.load();
                      }
                    }}
                  >
                    Retry
                  </button>
                </div>
              ) : (
                <video
                  ref={videoRef}
                  className={styles.modalVideo}
                  src={getVideoSource(activeVideo.src)}
                  poster={activeVideo.poster}
                  controls
                  autoPlay
                  onLoadedData={handleModalVideoLoaded}
                  onError={handleModalVideoError}
                />
              )}
              
              <button
                className={styles.closeButton}
                onClick={handleClose}
                aria-label="Close video"
              >
                <FiX />
              </button>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default ObsessedWithDataSlide;