// File: frontend/src/services/superAdmin/pitchService.js

import { apiService } from '../api';
import { API_ENDPOINTS } from '../../config/constants';

export const pitchService = {
  // Get access list for a specific deck
  getAccessList: async (deckId) => {
    console.group('Getting Pitch Access List');
    console.log('Deck ID:', deckId);
    
    try {
      const response = await apiService.get(`${API_ENDPOINTS.ENTERPRISE.PITCH.GET_ACCESS_LIST}`, {
        params: { deckId }
      });
      
      console.log('Access list retrieved successfully:', response);
      console.groupEnd();
      return response;
    } catch (error) {
      console.error('Error getting access list:', error);
      console.groupEnd();
      throw error;
    }
  },

  // Verify if a user has access to a pitch deck
  verifyAccess: async (email, deckId) => {
    console.group('Verifying Pitch Access');
    console.log('Email:', email, 'Deck ID:', deckId);
    
    try {
      const response = await apiService.post(API_ENDPOINTS.ENTERPRISE.PITCH.VERIFY_ACCESS, {
        email,
        deckId
      });
      
      console.log('Access verification successful:', response);
      console.groupEnd();
      return response;
    } catch (error) {
      console.error('Error verifying access:', error);
      console.groupEnd();
      throw error;
    }
  },

  // Add access record (without granting access yet)
  addAccess: async (email, deckId) => {
    console.group('Adding Pitch Access Record');
    console.log('Email:', email, 'Deck ID:', deckId);
    
    try {
      const response = await apiService.post(API_ENDPOINTS.ENTERPRISE.PITCH.ADD_ACCESS, {
        email,
        deckId
      });
      
      console.log('Access record added successfully:', response);
      console.groupEnd();
      return response;
    } catch (error) {
      console.error('Error adding access record:', error);
      console.groupEnd();
      throw error;
    }
  },

  // Grant access to a pitch deck
  grantAccess: async (email, deckId, durationDays) => {
    console.group('Granting Pitch Access');
    console.log('Email:', email, 'Deck ID:', deckId, 'Duration:', durationDays, 'days');
    
    try {
      const response = await apiService.post(API_ENDPOINTS.ENTERPRISE.PITCH.GRANT_ACCESS, {
        email,
        deckId,
        durationDays: parseInt(durationDays, 10)
      });
      
      console.log('Access granted successfully:', response);
      console.groupEnd();
      return response;
    } catch (error) {
      console.error('Error granting access:', error);
      console.groupEnd();
      throw error;
    }
  },

  // Revoke access to a pitch deck
  revokeAccess: async (accessId) => {
    console.group('Revoking Pitch Access');
    console.log('Access ID:', accessId);
    
    try {
      const response = await apiService.post(API_ENDPOINTS.ENTERPRISE.PITCH.REVOKE_ACCESS, {
        accessId
      });
      
      console.log('Access revoked successfully:', response);
      console.groupEnd();
      return response;
    } catch (error) {
      console.error('Error revoking access:', error);
      console.groupEnd();
      throw error;
    }
  },

  // Extend access to a pitch deck
  extendAccess: async (accessId, durationDays) => {
    console.group('Extending Pitch Access');
    console.log('Access ID:', accessId, 'Duration:', durationDays, 'days');
    
    try {
      const response = await apiService.post(API_ENDPOINTS.ENTERPRISE.PITCH.EXTEND_ACCESS, {
        accessId,
        durationDays: parseInt(durationDays, 10)
      });
      
      console.log('Access extended successfully:', response);
      console.groupEnd();
      return response;
    } catch (error) {
      console.error('Error extending access:', error);
      console.groupEnd();
      throw error;
    }
  },

  // Update access count for a pitch deck
  updateAccessCount: async (accessId) => {
    console.group('Updating Pitch Access Count');
    console.log('Access ID:', accessId);
    
    try {
      const response = await apiService.post(API_ENDPOINTS.ENTERPRISE.PITCH.UPDATE_ACCESS_COUNT, {
        accessId
      });
      
      console.log('Access count updated successfully:', response);
      console.groupEnd();
      return response;
    } catch (error) {
      console.error('Error updating access count:', error);
      console.groupEnd();
      throw error;
    }
  },

  // Helper functions
  formatDate: (dateString) => {
    if (!dateString) return 'Never';
    
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }).format(date);
  },

  isExpired: (expiryDate) => {
    return new Date(expiryDate) < new Date();
  }
};

export default pitchService;