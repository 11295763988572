// File: frontend/src/pages/InvestorPitch/components/slides/ProductEvolutionSlide/CapabilityMatrix.js

import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { Check, Minus } from 'lucide-react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from './CapabilityMatrix.module.css';

const CapabilityMatrix = ({ categories }) => {
  const { t } = useLanguage();
  const versions = ['AI1', 'AI2', 'AI3', 'AI4'];

  return (
    <div className={styles.container}>
      <div className={styles.tableWrapper}>
        <table className={styles.matrix}>
          <thead>
            <tr>
              <th className={styles.headerCell}>
                {t('investor_pitch.product_evolution.matrix.headers.capability', 'Capability')}
              </th>
              {versions.map(version => (
                <th key={version} className={styles.headerCell}>{version}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {categories.map((category, categoryIndex) => (
              <React.Fragment key={category.name}>
                <tr className={styles.categoryRow}>
                  <td 
                    colSpan={versions.length + 1} 
                    className={styles.categoryCell}
                  >
                    {category.name}
                  </td>
                </tr>
                {category.features.map((feature, featureIndex) => (
                  <tr 
                    key={`${categoryIndex}-${featureIndex}`}
                    className={styles.featureRow}
                    aria-label={t('investor_pitch.product_evolution.matrix.feature.aria_label', { 
                      feature: feature.name 
                    }, `Feature: ${feature.name}`)}
                  >
                    <td className={styles.featureCell}>{feature.name}</td>
                    {versions.map((version, versionIndex) => {
                      const versionKey = `ai${versionIndex + 1}`;
                      return (
                        <td 
                          key={versionKey} 
                          className={styles.valueCell}
                        >
                          {feature[versionKey] ? (
                            <Check 
                              className={styles.checkIcon} 
                              aria-label={t('investor_pitch.product_evolution.matrix.available', 'Available')}
                            />
                          ) : (
                            <Minus 
                              className={styles.minusIcon} 
                              aria-label={t('investor_pitch.product_evolution.matrix.unavailable', 'Not available')}
                            />
                          )}
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

CapabilityMatrix.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      features: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          ai1: PropTypes.bool.isRequired,
          ai2: PropTypes.bool.isRequired,
          ai3: PropTypes.bool.isRequired,
          ai4: PropTypes.bool.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
};

export default CapabilityMatrix;