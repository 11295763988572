// File: frontend/src/hooks/useAutoLogout.js

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContextV2';
import { AUTH_CONFIG } from '../config/constants';

export const useAutoLogout = () => {
  const { signOut, isAuthenticated } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // Early return if auto logout is disabled
      if (!AUTH_CONFIG.ENABLE_AUTO_LOGOUT) {
        return;
      }

      if (!isAuthenticated) return;

      let lastActivity = Date.now();
      let checkTimer;

      const updateActivity = () => {
          lastActivity = Date.now();
      };

      const checkInactivity = () => {
          const inactiveTime = Date.now() - lastActivity;
          if (inactiveTime >= AUTH_CONFIG.INACTIVITY_TIMEOUT) {
              console.log('Auto logout triggered after', 
                  Math.round(inactiveTime/1000/60), 'minutes of inactivity');
              signOut();
              navigate('/sign-out-inactivity');
          }
      };

      // Setup activity listeners
      const events = ['mousedown', 'keypress', 'mousemove', 'scroll', 'touchstart'];
      events.forEach(event => document.addEventListener(event, updateActivity));
      
      // Setup periodic check
      checkTimer = setInterval(checkInactivity, AUTH_CONFIG.ACTIVITY_CHECK_INTERVAL);

      return () => {
          events.forEach(event => 
              document.removeEventListener(event, updateActivity));
          clearInterval(checkTimer);
      };
  }, [signOut, navigate, isAuthenticated]);
};