// File: frontend/src/pages/InvestorPitch/components/slides/GlobalHiringSlide/GlobalHiringSlide.js

import React from 'react';
import { motion } from 'framer-motion';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import LocationCard from './LocationCard';
import HiringTimelineCard from './HiringTimelineCard';
import CompensationCard from './CompensationCard';
import { ANIMATION_VARIANTS } from '../../../constants/animations';
import styles from './GlobalHiringSlide.module.css';

const GlobalHiringSlide = () => {
  const { t } = useLanguage();

  const locations = [
    {
      city: t('investor_pitch.hiring.locations.toronto.city'),
      country: t('investor_pitch.hiring.locations.toronto.country'),
      timeline: t('investor_pitch.hiring.locations.toronto.timeline'),
      headcount: 15,
      roles: [
        t('investor_pitch.hiring.locations.toronto.roles.role5'),
        t('investor_pitch.hiring.locations.toronto.roles.role1'),
        t('investor_pitch.hiring.locations.toronto.roles.role3'),
        t('investor_pitch.hiring.locations.toronto.roles.role4')
      ],
      gradient: "linear-gradient(135deg, var(--color-blue-110), var(--color-blue-310))"
    },
    {
      city: t('investor_pitch.hiring.locations.newyork.city'),
      country: t('investor_pitch.hiring.locations.newyork.country'),
      timeline: t('investor_pitch.hiring.locations.newyork.timeline'),
      headcount: 25,
      roles: [
        t('investor_pitch.hiring.locations.newyork.roles.role2'),
        t('investor_pitch.hiring.locations.newyork.roles.role4'),
        t('investor_pitch.hiring.locations.newyork.roles.role1'),
        t('investor_pitch.hiring.locations.newyork.roles.role3'),
        t('investor_pitch.hiring.locations.newyork.roles.role5'),
        t('investor_pitch.hiring.locations.newyork.roles.role8'),
      ],
      gradient: "linear-gradient(135deg, var(--color-green-110), var(--color-green-310))"
    },
    {
      city: t('investor_pitch.hiring.locations.london.city'),
      country: t('investor_pitch.hiring.locations.london.country'),
      timeline: t('investor_pitch.hiring.locations.london.timeline'),
      headcount: 10,
      roles: [
        t('investor_pitch.hiring.locations.london.roles.role1'),
        t('investor_pitch.hiring.locations.london.roles.role2'),
        t('investor_pitch.hiring.locations.london.roles.role3'),
        t('investor_pitch.hiring.locations.london.roles.role4')
      ],
      gradient: "linear-gradient(135deg, var(--color-orange-110), var(--color-orange-310))"
    }
  ];

  const timeline = [
    {
      period: t('investor_pitch.hiring.timeline.phase1.period'),
      phase: t('investor_pitch.hiring.timeline.phase1.name'),
      hires: 10,
      progress: 30,
      color: "var(--color-blue-310)"
    },
    {
      period: t('investor_pitch.hiring.timeline.phase2.period'),
      phase: t('investor_pitch.hiring.timeline.phase2.name'),
      hires: 25,
      progress: 50,
      color: "var(--color-green-310)"
    },
    {
      period: t('investor_pitch.hiring.timeline.phase3.period'),
      phase: t('investor_pitch.hiring.timeline.phase3.name'),
      hires: 50,
      progress: 85,
      color: "var(--color-orange-310)"
    }
  ];

  const compensation = {
    base: {
      range: t('investor_pitch.hiring.compensation.base.range'),
      details: t('investor_pitch.hiring.compensation.base.details')
    },
    equity: {
      range: t('investor_pitch.hiring.compensation.equity.range'),
      details: t('investor_pitch.hiring.compensation.equity.details')
    },
    benefits: {
      range: t('investor_pitch.hiring.compensation.benefits.range'),
      details: t('investor_pitch.hiring.compensation.benefits.details')
    }
  };

  return (
    <motion.div
      className={styles.container}
      variants={ANIMATION_VARIANTS.stagger.container}
      initial="hidden"
      animate="visible"
    >
      <motion.div 
        className={styles.header}
        variants={ANIMATION_VARIANTS.fadeIn}
      >
        <h2 className={styles.title}>{t('investor_pitch.hiring.title')}</h2>
        <p className={styles.subtitle}>{t('investor_pitch.hiring.subtitle')}</p>
      </motion.div>

      <div className={styles.content}>
        <motion.section 
          className={styles.locationsGrid}
          variants={ANIMATION_VARIANTS.stagger.container}
        >
          {locations.map((location, index) => (
            <motion.div
              key={location.city}
              variants={ANIMATION_VARIANTS.stagger.item}
              custom={index}
            >
              <LocationCard {...location} />
            </motion.div>
          ))}
        </motion.section>

        <div className={styles.infoSection}>
          <motion.div 
            className={styles.timeline}
            variants={ANIMATION_VARIANTS.stagger.container}
          >
            <h3 className={styles.sectionTitle}>{t('investor_pitch.hiring.timeline.title')}</h3>
            <HiringTimelineCard phases={timeline} />
          </motion.div>

          <motion.div 
            className={styles.compensation}
            variants={ANIMATION_VARIANTS.stagger.container}
          >
            <h3 className={styles.sectionTitle}>{t('investor_pitch.hiring.compensation.title')}</h3>
            <CompensationCard {...compensation} />
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
};

export default GlobalHiringSlide;