// File: frontend/src/pages/SuperAdmin/components/common/SuperAdminBreadcrumbs/index.js

import React from 'react';
import { Link } from 'react-router-dom';
import styles from './SuperAdminBreadcrumbs.module.css';

const SuperAdminBreadcrumbs = ({ breadcrumbs }) => {
  return (
    <div className={styles.breadcrumbs}>
      {breadcrumbs.map((crumb, index) => (
        <React.Fragment key={index}>
          {index > 0 && <span className={styles.separator}>/</span>}
          {index === breadcrumbs.length - 1 ? (
            <span className={styles.currentCrumb}>{crumb.label}</span>
          ) : (
            <Link to={crumb.path} className={styles.crumbLink}>
              {crumb.label}
            </Link>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default SuperAdminBreadcrumbs;