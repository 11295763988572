// File: frontend/src/pages/InvestorPitch/components/slides/MarketImpactSlide/MarketCard.js

import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import ProgressBar from '../../common/ProgressBar';
import styles from './MarketCard.module.css';

const MarketCard = ({ country, language, readiness, highlights, gradient }) => {
  const { t } = useLanguage();

  return (
    <motion.div 
      className={styles.container}
      whileHover={{ scale: 1.02 }}
      transition={{ type: 'spring', stiffness: 300, damping: 30 }}
    >
      <div className={styles.header}>
        <div className={styles.country}>{country}</div>
        <div className={styles.language}>{language}</div>
      </div>

      <div className={styles.readiness}>
        <div className={styles.readinessHeader}>
          <span>{t('investor_pitch.market_impact.card.readiness_label')}</span>
          <span className={styles.readinessValue}>
            {t('investor_pitch.market_impact.card.readiness_value', { value: readiness })}
          </span>
        </div>
        <ProgressBar 
          value={readiness} 
          max={100} 
          gradient={gradient}
          aria-label={t('investor_pitch.market_impact.card.progress_aria', { 
            country: country.split(' ')[1],
            value: readiness 
          })}
        />
      </div>

      <ul className={styles.highlights}>
        {highlights.map((highlight, index) => (
          <li key={index}>{highlight}</li>
        ))}
      </ul>
    </motion.div>
  );
};

MarketCard.propTypes = {
  country: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  readiness: PropTypes.number.isRequired,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  gradient: PropTypes.string.isRequired,
};

export default MarketCard;