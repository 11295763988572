// File: frontend/src/pages/EmbeddedInsurance/components/ProductBuilder/PricingRules.js

import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import CodeBlock from '../common/CodeBlock';
import styles from './styles/PricingRules.module.css';

const PricingRules = ({
  selectedProduct,
  selectedCoverage,
  selectedJurisdiction,
  pricingRules,
  onChange
}) => {
  const { t } = useLanguage();
  const [activeRule, setActiveRule] = useState(null);
  const [editingRule, setEditingRule] = useState(null);
  const [showAddForm, setShowAddForm] = useState(false);

  // Sample pricing rule templates
  const ruleTemplates = [
    {
      id: 'base_premium',
      name: 'Base Premium',
      description: 'Set the base premium for this coverage',
      template: {
        type: 'base_premium',
        amount: 10.00,
        currency: 'USD',
        frequency: 'monthly'
      }
    },
    {
      id: 'age_factor',
      name: 'Age Factor',
      description: 'Adjust premium based on customer age',
      template: {
        type: 'age_factor',
        ranges: [
          { min_age: 18, max_age: 25, factor: 1.5 },
          { min_age: 26, max_age: 35, factor: 1.2 },
          { min_age: 36, max_age: 50, factor: 1.0 },
          { min_age: 51, max_age: 65, factor: 1.3 },
          { min_age: 66, max_age: 99, factor: 1.8 }
        ]
      }
    },
    {
      id: 'device_value',
      name: 'Device Value',
      description: 'Adjust premium based on device value',
      template: {
        type: 'device_value',
        ranges: [
          { min_value: 0, max_value: 500, factor: 0.8 },
          { min_value: 501, max_value: 1000, factor: 1.0 },
          { min_value: 1001, max_value: 1500, factor: 1.2 },
          { min_value: 1501, max_value: 2000, factor: 1.5 },
          { min_value: 2001, max_value: null, factor: 2.0 }
        ]
      }
    }
  ];

  // Initialize with a default rule if none exist
  useEffect(() => {
    if (pricingRules.length === 0) {
      const defaultRule = {
        ...ruleTemplates[0].template,
        id: `rule_${Date.now()}`
      };
      onChange([defaultRule]);
      setActiveRule(defaultRule.id);
    } else if (!activeRule && pricingRules.length > 0) {
      setActiveRule(pricingRules[0].id);
    }
  }, [pricingRules, activeRule, onChange]);

  const handleAddRule = (templateId) => {
    const template = ruleTemplates.find(t => t.id === templateId);
    if (template) {
      const newRule = {
        ...template.template,
        id: `rule_${Date.now()}`
      };
      const updatedRules = [...pricingRules, newRule];
      onChange(updatedRules);
      setActiveRule(newRule.id);
      setShowAddForm(false);
    }
  };

  const handleEditRule = (ruleId) => {
    const rule = pricingRules.find(r => r.id === ruleId);
    if (rule) {
      setEditingRule({ ...rule });
    }
  };

  const handleSaveRule = () => {
    if (editingRule) {
      const updatedRules = pricingRules.map(rule => 
        rule.id === editingRule.id ? editingRule : rule
      );
      onChange(updatedRules);
      setEditingRule(null);
    }
  };

  const handleDeleteRule = (ruleId) => {
    const updatedRules = pricingRules.filter(rule => rule.id !== ruleId);
    onChange(updatedRules);
    if (activeRule === ruleId && updatedRules.length > 0) {
      setActiveRule(updatedRules[0].id);
    } else if (updatedRules.length === 0) {
      setActiveRule(null);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditingRule(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleRangeChange = (index, field, value) => {
    const updatedRanges = [...editingRule.ranges];
    updatedRanges[index] = {
      ...updatedRanges[index],
      [field]: value
    };
    
    setEditingRule(prev => ({
      ...prev,
      ranges: updatedRanges
    }));
  };

  const getActiveRuleDetails = () => {
    return pricingRules.find(rule => rule.id === activeRule);
  };

  const renderRuleEditor = () => {
    if (!editingRule) return null;

    switch (editingRule.type) {
      case 'base_premium':
        return (
          <div className={styles.ruleEditor}>
            <div className={styles.formGroup}>
              <label className={styles.formLabel}>{t('amount') || 'Amount'}</label>
              <input
                type="number"
                name="amount"
                className={styles.formInput}
                value={editingRule.amount}
                onChange={handleInputChange}
                step="0.01"
                min="0"
              />
            </div>
            <div className={styles.formRow}>
              <div className={styles.formGroup}>
                <label className={styles.formLabel}>{t('currency') || 'Currency'}</label>
                <select
                  name="currency"
                  className={styles.formSelect}
                  value={editingRule.currency}
                  onChange={handleInputChange}
                >
                  <option value="USD">USD</option>
                  <option value="CAD">CAD</option>
                  <option value="EUR">EUR</option>
                  <option value="GBP">GBP</option>
                </select>
              </div>
              <div className={styles.formGroup}>
                <label className={styles.formLabel}>{t('frequency') || 'Frequency'}</label>
                <select
                  name="frequency"
                  className={styles.formSelect}
                  value={editingRule.frequency}
                  onChange={handleInputChange}
                >
                  <option value="monthly">{t('monthly') || 'Monthly'}</option>
                  <option value="quarterly">{t('quarterly') || 'Quarterly'}</option>
                  <option value="annually">{t('annually') || 'Annually'}</option>
                  <option value="one_time">{t('one_time') || 'One-time'}</option>
                </select>
              </div>
            </div>
          </div>
        );
      
      case 'age_factor':
      case 'device_value':
        const isAge = editingRule.type === 'age_factor';
        const minLabel = isAge ? (t('min_age') || 'Min Age') : (t('min_value') || 'Min Value');
        const maxLabel = isAge ? (t('max_age') || 'Max Age') : (t('max_value') || 'Max Value');
        
        return (
          <div className={styles.ruleEditor}>
            <div className={styles.rangesTable}>
              <div className={styles.rangesHeader}>
                <div className={styles.rangeCell}>{minLabel}</div>
                <div className={styles.rangeCell}>{maxLabel}</div>
                <div className={styles.rangeCell}>{t('factor') || 'Factor'}</div>
              </div>
              {editingRule.ranges.map((range, index) => (
                <div key={index} className={styles.rangeRow}>
                  <div className={styles.rangeCell}>
                    <input
                      type="number"
                      className={styles.rangeInput}
                      value={range.min_age || range.min_value}
                      onChange={(e) => handleRangeChange(
                        index, 
                        isAge ? 'min_age' : 'min_value', 
                        parseFloat(e.target.value)
                      )}
                      min="0"
                    />
                  </div>
                  <div className={styles.rangeCell}>
                    <input
                      type="number"
                      className={styles.rangeInput}
                      value={range.max_age || range.max_value || ''}
                      onChange={(e) => handleRangeChange(
                        index, 
                        isAge ? 'max_age' : 'max_value', 
                        e.target.value ? parseFloat(e.target.value) : null
                      )}
                      min="0"
                      placeholder="No limit"
                    />
                  </div>
                  <div className={styles.rangeCell}>
                    <input
                      type="number"
                      className={styles.rangeInput}
                      value={range.factor}
                      onChange={(e) => handleRangeChange(index, 'factor', parseFloat(e.target.value))}
                      step="0.1"
                      min="0"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      
      default:
        return (
          <div className={styles.ruleEditor}>
            <p>{t('unsupported_rule_type') || 'Unsupported rule type'}</p>
          </div>
        );
    }
  };

  return (
    <div className={styles.pricingRules}>
      <div className={styles.sectionHeader}>
        <h2 className={styles.sectionTitle}>{t('pricing_rules') || 'Pricing Rules'}</h2>
        <p className={styles.sectionDescription}>
          {t('pricing_rules_description') || 'Configure how premiums are calculated for this insurance product.'}
        </p>
      </div>

      <div className={styles.rulesContainer}>
        <div className={styles.rulesList}>
          <div className={styles.rulesHeader}>
            <h3 className={styles.rulesTitle}>{t('active_rules') || 'Active Rules'}</h3>
            <button 
              className={styles.addRuleButton}
              onClick={() => setShowAddForm(true)}
            >
              <i className="fas fa-plus"></i>
              <span>{t('add_rule') || 'Add Rule'}</span>
            </button>
          </div>

          {showAddForm && (
            <div className={styles.addRuleForm}>
              <h4 className={styles.addRuleTitle}>{t('select_rule_template') || 'Select Rule Template'}</h4>
              <div className={styles.ruleTemplates}>
                {ruleTemplates.map(template => (
                  <div 
                    key={template.id} 
                    className={styles.ruleTemplate}
                    onClick={() => handleAddRule(template.id)}
                  >
                    <h5 className={styles.templateName}>{template.name}</h5>
                    <p className={styles.templateDescription}>{template.description}</p>
                  </div>
                ))}
              </div>
              <button 
                className={styles.cancelButton}
                onClick={() => setShowAddForm(false)}
              >
                {t('cancel') || 'Cancel'}
              </button>
            </div>
          )}

          <div className={styles.rulesListItems}>
            {pricingRules.map(rule => (
              <div 
                key={rule.id} 
                className={`${styles.ruleItem} ${activeRule === rule.id ? styles.activeRule : ''}`}
                onClick={() => setActiveRule(rule.id)}
              >
                <div className={styles.ruleInfo}>
                  <div className={styles.ruleType}>
                    {rule.type === 'base_premium' && (t('base_premium') || 'Base Premium')}
                    {rule.type === 'age_factor' && (t('age_factor') || 'Age Factor')}
                    {rule.type === 'device_value' && (t('device_value') || 'Device Value')}
                  </div>
                  <div className={styles.rulePreview}>
                    {rule.type === 'base_premium' && `${rule.amount} ${rule.currency} ${rule.frequency}`}
                    {rule.type === 'age_factor' && `${rule.ranges.length} ${t('age_ranges') || 'age ranges'}`}
                    {rule.type === 'device_value' && `${rule.ranges.length} ${t('value_ranges') || 'value ranges'}`}
                  </div>
                </div>
                <div className={styles.ruleActions}>
                  <button 
                    className={styles.editButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEditRule(rule.id);
                    }}
                  >
                    <i className="fas fa-edit"></i>
                  </button>
                  <button 
                    className={styles.deleteButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteRule(rule.id);
                    }}
                  >
                    <i className="fas fa-trash-alt"></i>
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className={styles.ruleDetails}>
          {editingRule ? (
            <div className={styles.editRuleForm}>
              <h3 className={styles.editRuleTitle}>
                {editingRule.type === 'base_premium' && (t('edit_base_premium') || 'Edit Base Premium')}
                {editingRule.type === 'age_factor' && (t('edit_age_factor') || 'Edit Age Factor')}
                {editingRule.type === 'device_value' && (t('edit_device_value') || 'Edit Device Value')}
              </h3>
              
              {renderRuleEditor()}
              
              <div className={styles.editActions}>
                <button 
                  className={styles.cancelButton}
                  onClick={() => setEditingRule(null)}
                >
                  {t('cancel') || 'Cancel'}
                </button>
                <button 
                  className={styles.saveButton}
                  onClick={handleSaveRule}
                >
                  {t('save_changes') || 'Save Changes'}
                </button>
              </div>
            </div>
          ) : activeRule ? (
            <div className={styles.viewRuleDetails}>
              <h3 className={styles.viewRuleTitle}>
                {getActiveRuleDetails().type === 'base_premium' && (t('base_premium') || 'Base Premium')}
                {getActiveRuleDetails().type === 'age_factor' && (t('age_factor') || 'Age Factor')}
                {getActiveRuleDetails().type === 'device_value' && (t('device_value') || 'Device Value')}
              </h3>
              
              <div className={styles.rulePreview}>
                <CodeBlock 
                  code={JSON.stringify(getActiveRuleDetails(), null, 2)}
                  language="json"
                />
              </div>
              
              <button 
                className={styles.editButton}
                onClick={() => handleEditRule(activeRule)}
              >
                <i className="fas fa-edit"></i>
                <span>{t('edit_rule') || 'Edit Rule'}</span>
              </button>
            </div>
          ) : (
            <div className={styles.noRuleSelected}>
              <i className="fas fa-arrow-left"></i>
              <p>{t('select_rule_or_add_new') || 'Select a rule from the list or add a new one'}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PricingRules;