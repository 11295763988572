// File: frontend/src/pages/InvestorPitch/hooks/useSlideNavigation.js

import { useState, useCallback, useMemo, useEffect } from 'react';
import { SLIDE_DIRECTION } from '../constants/animations';

const useSlideNavigation = (totalSlides, initialSlide = 0) => {
  const [currentSlide, setCurrentSlide] = useState(initialSlide);
  const [direction, setDirection] = useState(null);
  const [isAnimating, setIsAnimating] = useState(false);

  // Calculate whether navigation is possible
  const canNavigate = useMemo(() => ({
    next: currentSlide < totalSlides - 1,
    previous: currentSlide > 0
  }), [currentSlide, totalSlides]);

  // Scroll to top whenever slide changes
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, [currentSlide]);

  // Handle next slide
  const goToNextSlide = useCallback(() => {
    if (canNavigate.next && !isAnimating) {
      setIsAnimating(true);
      setDirection(SLIDE_DIRECTION.NEXT);
      setCurrentSlide(prev => prev + 1);
      
      // Reset animation flag after transition
      setTimeout(() => setIsAnimating(false), 500);
    }
  }, [canNavigate.next, isAnimating]);

  // Handle previous slide
  const goToPreviousSlide = useCallback(() => {
    if (canNavigate.previous && !isAnimating) {
      setIsAnimating(true);
      setDirection(SLIDE_DIRECTION.PREVIOUS);
      setCurrentSlide(prev => prev - 1);
      
      // Reset animation flag after transition
      setTimeout(() => setIsAnimating(false), 500);
    }
  }, [canNavigate.previous, isAnimating]);

  // Handle direct navigation to specific slide
  const goToSlide = useCallback((slideIndex) => {
    if (slideIndex >= 0 && 
        slideIndex < totalSlides && 
        slideIndex !== currentSlide && 
        !isAnimating) {
      setIsAnimating(true);
      setDirection(slideIndex > currentSlide ? SLIDE_DIRECTION.NEXT : SLIDE_DIRECTION.PREVIOUS);
      setCurrentSlide(slideIndex);
      
      // Reset animation flag after transition
      setTimeout(() => setIsAnimating(false), 500);
    }
  }, [currentSlide, totalSlides, isAnimating]);

  // Handle keyboard navigation
  const handleKeyboardNavigation = useCallback((event) => {
    switch (event.key) {
      case 'ArrowRight':
        goToNextSlide();
        break;
      case 'ArrowLeft':
        goToPreviousSlide();
        break;
      default:
        break;
    }
  }, [goToNextSlide, goToPreviousSlide]);

  // Calculate progress
  const progress = useMemo(() => ({
    current: currentSlide + 1,
    total: totalSlides,
    percentage: ((currentSlide + 1) / totalSlides) * 100
  }), [currentSlide, totalSlides]);

  // Slide transition variants for Framer Motion
  const slideVariants = useMemo(() => ({
    enter: (direction) => ({
      x: direction === SLIDE_DIRECTION.NEXT ? '100%' : '-100%',
      opacity: 0
    }),
    center: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: 'easeOut'
      }
    },
    exit: (direction) => ({
      x: direction === SLIDE_DIRECTION.NEXT ? '-100%' : '100%',
      opacity: 0,
      transition: {
        duration: 0.5,
        ease: 'easeIn'
      }
    })
  }), []);

  // Auto-advance functionality
  const startAutoAdvance = useCallback((interval = 5000) => {
    const timer = setInterval(() => {
      if (canNavigate.next) {
        goToNextSlide();
      }
    }, interval);

    return () => clearInterval(timer);
  }, [canNavigate.next, goToNextSlide]);

  return {
    currentSlide,
    direction,
    isAnimating,
    progress,
    slideVariants,
    canNavigate,
    goToNextSlide,
    goToPreviousSlide,
    goToSlide,
    handleKeyboardNavigation,
    startAutoAdvance
  };
};

export default useSlideNavigation;