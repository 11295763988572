// File: frontend/src/pages/InvestorPitch/components/cards/StatCard.js

import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import styles from './StatCard.module.css';

const StatCard = ({ title, value, color }) => {
  return (
    <motion.div 
      className={styles.card}
      whileHover={{ scale: 1.05 }}
      transition={{ type: 'spring', stiffness: 300, damping: 30 }}
    >
      <div className={styles.value} style={{ color }}>
        {value}
      </div>
      <div className={styles.title}>
        {title}
      </div>
    </motion.div>
  );
};

StatCard.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  color: PropTypes.string,
};

export default StatCard;