// File: frontend/src/pages/SuperAdmin/components/AuditLogs/index.js

import React, { useState } from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import AccountsAuditLog from './AccountsAuditLog';
import UsersAuditLog from './UsersAuditLog';
import AgreementsAuditLog from './AgreementsAuditLog';
import RbacAuditLog from './RbacAuditLog';
import styles from './AuditLogs.module.css';

const AuditLogs = ({ updateBreadcrumbs }) => {
  const { t } = useLanguage();
  const [activeTab, setActiveTab] = useState('accounts');

  // Update breadcrumbs when component mounts
  React.useEffect(() => {
    updateBreadcrumbs([
      { label: 'Dashboard', path: '/super-admin' },
      { label: 'Audit Logs', path: '/super-admin/audit-logs' }
    ]);
  }, [updateBreadcrumbs]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'accounts':
        return <AccountsAuditLog />;
      case 'users':
        return <UsersAuditLog />;
      case 'agreements':
        return <AgreementsAuditLog />;
      case 'rbac':
        return <RbacAuditLog />;
      default:
        return <AccountsAuditLog />;
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{t('audit_logs')}</h1>
      
      <div className={styles.description}>
        {t('audit_logs_description')}
      </div>
      
      <div className={styles.tabs}>
        <button 
          className={`${styles.tab} ${activeTab === 'accounts' ? styles.activeTab : ''}`}
          onClick={() => handleTabChange('accounts')}
        >
          <i className="fas fa-building"></i>
          <span>{t('enterprise_accounts')}</span>
        </button>
        <button 
          className={`${styles.tab} ${activeTab === 'users' ? styles.activeTab : ''}`}
          onClick={() => handleTabChange('users')}
        >
          <i className="fas fa-users"></i>
          <span>{t('enterprise_users')}</span>
        </button>
        <button 
          className={`${styles.tab} ${activeTab === 'agreements' ? styles.activeTab : ''}`}
          onClick={() => handleTabChange('agreements')}
        >
          <i className="fas fa-file-contract"></i>
          <span>{t('agreements')}</span>
        </button>
        <button 
          className={`${styles.tab} ${activeTab === 'rbac' ? styles.activeTab : ''}`}
          onClick={() => handleTabChange('rbac')}
        >
          <i className="fas fa-shield-alt"></i>
          <span>{t('rbac_settings')}</span>
        </button>
      </div>
      
      <div className={styles.tabContent}>
        {renderTabContent()}
      </div>
    </div>
  );
};

export default AuditLogs;