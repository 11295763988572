// File: frontend/src/pages/Quote/components/SavingsCalculator/SavingsCalculatorSection.js

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useLanguage } from '../../../../contexts/LanguageContext';
import SavingsCalculator from './SavingsCalculator';
import styles from './SavingsCalculatorSection.module.css';

const SavingsCalculatorSection = ({ onGetQuote }) => {
  const { t } = useLanguage();
  const navigate = useNavigate();
  const location = useLocation();
  const sectionRef = useRef(null);
  const observerRef = useRef(null);
  const [selectedInsuranceType, setSelectedInsuranceType] = useState('auto');
  const [isVisible, setIsVisible] = useState(false);
  
  // Check for saved insurance type on mount
  useEffect(() => {
    try {
      const savedType = sessionStorage.getItem('selectedInsuranceType');
      if (savedType) {
        setSelectedInsuranceType(savedType);
      }
    } catch (error) {
      console.error('Error retrieving saved insurance type:', error);
    }
  }, []);
  
  // Memoize the get quote handler to prevent unnecessary re-renders
  const handleGetQuote = useCallback((insuranceType) => {
    // Update the selected insurance type
    setSelectedInsuranceType(insuranceType);
    
    // Store the selected insurance type in session storage
    try {
      sessionStorage.setItem('selectedInsuranceType', insuranceType);
    } catch (error) {
      console.error('Error storing insurance type:', error);
    }
    
    // Call the parent handler if provided
    if (typeof onGetQuote === 'function') {
      onGetQuote(insuranceType);
      return;
    }
    
    // If already on the Quote page, just update the hash
    if (location.pathname.includes('/quote')) {
      window.location.hash = `chatSection?type=${insuranceType}`;
      
      // Dispatch a custom event to notify other components
      try {
        const event = new CustomEvent('insuranceTypeSelected', { 
          detail: { insuranceType } 
        });
        window.dispatchEvent(event);
      } catch (error) {
        console.error('Error dispatching custom event:', error);
      }
    } else {
      // Navigate to the quote page with the chat section hash and insurance type parameter
      navigate(`/quote#chatSection?type=${insuranceType}`);
    }
    
    // Analytics tracking if available
    if (window.analytics) {
      window.analytics.track('Start Quote', {
        page: 'Calculator Section',
        insuranceType: insuranceType,
        currentUrl: location.pathname
      });
    }
  }, [location.pathname, navigate, onGetQuote]);
  
  // Add intersection observer for animation on scroll - with better cleanup
  useEffect(() => {
    // Setup observer with proper options
    const options = { 
      threshold: 0.1,
      rootMargin: '20px 0px' // A little margin to trigger earlier
    };
    
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (entry.target === sectionRef.current) {
            setIsVisible(true);
          } else {
            // For other observed elements, add visible class directly
            entry.target.classList.add(styles.visible);
          }
        }
      });
    };
    
    observerRef.current = new IntersectionObserver(handleIntersection, options);

    // Observe the section itself
    if (sectionRef.current) {
      observerRef.current.observe(sectionRef.current);
    }

    // Observe explanation items for staggered animation with a small delay
    setTimeout(() => {
      const explanationItems = document.querySelectorAll(`.${styles.explanationItem}`);
      explanationItems.forEach((item, index) => {
        // Add staggered delay based on index
        item.style.transitionDelay = `${index * 0.1}s`;
        if (observerRef.current) {
          observerRef.current.observe(item);
        }
      });
    }, 100);

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
        observerRef.current = null;
      }
    };
  }, []);
  
  // Apply the visible class to the section when it becomes visible
  useEffect(() => {
    if (isVisible && sectionRef.current) {
      sectionRef.current.classList.add(styles.visible);
    }
  }, [isVisible]);
  
  return (
    <section 
      className={styles.calculatorSection} 
      id="calculatorSection"
      ref={sectionRef}
      aria-label={t('calculator_section.title')}
    >
      <div className={styles.sectionContent}>
        <div className={styles.sectionHeader}>
          <h2>{t('calculator_section.title')}</h2>
          <p>
            {t('calculator_section.description')}
          </p>
        </div>
        
        <div className={styles.calculatorWrapper}>
          <SavingsCalculator 
            onGetQuote={handleGetQuote} 
            initialInsuranceType={selectedInsuranceType}
          />
        </div>
        
        <div className={styles.explanationText}>
          <h3>{t('calculator_section.how_title')}</h3>
          <div className={styles.explanationItems}>
            <div className={styles.explanationItem}>
              <div className={styles.explanationIcon} aria-hidden="true">🔍</div>
              <div className={styles.explanationContent}>
                <h4>{t('calculator_section.lower_expenses_title')}</h4>
                <p>{t('calculator_section.lower_expenses_description')}</p>
              </div>
            </div>
            
            <div className={styles.explanationItem}>
              <div className={styles.explanationIcon} aria-hidden="true">💡</div>
              <div className={styles.explanationContent}>
                <h4>{t('calculator_section.smart_technology_title')}</h4>
                <p>{t('calculator_section.smart_technology_description')}</p>
              </div>
            </div>
            
            <div className={styles.explanationItem}>
              <div className={styles.explanationIcon} aria-hidden="true">⚡</div>
              <div className={styles.explanationContent}>
                <h4>{t('calculator_section.efficient_claims_title')}</h4>
                <p>{t('calculator_section.efficient_claims_description')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SavingsCalculatorSection;