// File: frontend/src/components/MileMonitor/StatisticsCard.js
import React from 'react';
import styles from './StatisticsCard.module.css';

const StatisticsCard = () => {
    return (
        <div className={styles.cardStandartContent}>
            <div className={styles.cardBentoParent}>
                <div className={styles.cardBento}>
                    <div className={styles.cardBentoIconArea}>
                        <img className={styles.icons18Crash} alt="" src="/icons/18/misc/Crash.svg" />
                    </div>
                    <div className={styles.mParent}>
                        <div className={styles.m}>0</div>
                        <div className={styles.kilometersOfDriving}>accidents reported or identified through AI analysis</div>
                    </div>
                </div>
                <div className={styles.frameWrapper}>
                    <div className={styles.cardBentoIconAreaParent}>
                        <div className={styles.cardBentoIconArea}>
                            <img className={styles.icons18Crash} alt="" src="/icons/18/misc/Hours.svg" />
                        </div>
                        <div className={styles.mGroup}>
                            <div className={styles.m}>7,000</div>
                            <div className={styles.kilometersOfDriving}>hours of driving data analyzed</div>
                        </div>
                    </div>
                </div>
                <div className={styles.cardBentoIconAreaGroup}>
                    <div className={styles.cardBentoIconArea}>
                        <img className={styles.icons18Crash} alt="" src="/icons/18/misc/Distracted.svg" />
                    </div>
                    <div className={styles.mParent}>
                        <div className={styles.m}>400+</div>
                        <div className={styles.kilometersOfDriving}>hours of distracted driving captured using AI</div>
                    </div>
                </div>
                <div className={styles.cardBentoIconAreaContainer}>
                    <div className={styles.cardBentoIconArea}>
                        <img className={styles.icons18Crash} alt="" src="/icons/18/misc/Kilometers.svg" />
                    </div>
                    <div className={styles.mParent}>
                        <div className={styles.m}>3M+</div>
                        <div className={styles.kilometersOfDriving}>kilometers of driving data collected</div>
                    </div>
                </div>
            </div>
            <div className={styles.card3CardInsideContent}>
                <img className={styles.image148Icon} alt="" src="/images/image148.png" />
            </div>
        </div>
    );
};

export default StatisticsCard;