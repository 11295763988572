// File: frontend/src/pages/EmbeddedInsurance/data/products.js

// Mock products data
const products = [
  {
    id: 'device_protection',
    name: 'Device Protection',
    description: 'Insurance for smartphones, tablets, and other electronic devices',
    icon: 'mobile-alt'
  },
  {
    id: 'travel_insurance',
    name: 'Travel Insurance',
    description: 'Coverage for trip cancellations, medical emergencies, and lost luggage',
    icon: 'plane'
  },
  {
    id: 'home_warranty',
    name: 'Home Warranty',
    description: 'Protection for home appliances and systems',
    icon: 'home'
  },
  {
    id: 'pet_insurance',
    name: 'Pet Insurance',
    description: 'Medical coverage for pets including accidents and illnesses',
    icon: 'paw'
  },
  // Personal Lines
  {
    id: 'auto',
    name: 'Auto Insurance',
    description: 'Coverage for personal vehicles with potential savings through telematics programs',
    icon: 'car'
  },
  {
    id: 'home_insurance',
    name: 'Home Insurance',
    description: 'Protection for your home with additional savings when using smart home devices',
    icon: 'home'
  },
  {
    id: 'tenant',
    name: 'Tenant Insurance',
    description: 'Coverage for renters with savings available through policy bundling',
    icon: 'building'
  },
  {
    id: 'umbrella',
    name: 'Umbrella Insurance',
    description: 'Extra liability coverage beyond standard policy limits with bundling discounts',
    icon: 'umbrella'
  },
  {
    id: 'dwelling',
    name: 'Dwelling Insurance',
    description: 'Coverage for residential properties with savings for fire safety features',
    icon: 'house'
  },
  {
    id: 'life',
    name: 'Life Insurance',
    description: 'Financial protection for your family with potential savings using health tracking devices',
    icon: 'heartbeat'
  },
  // Commercial Lines
  {
    id: 'commercial_auto',
    name: 'Commercial Auto',
    description: 'Vehicle coverage for businesses with savings available through fleet telematics',
    icon: 'truck'
  },
  {
    id: 'workers_comp',
    name: 'Workers Compensation',
    description: 'Coverage for employee injuries with discounts for workplace safety programs',
    icon: 'hard-hat'
  },
  {
    id: 'commercial_property',
    name: 'Commercial Property',
    description: 'Protection for business buildings with savings through risk management protocols',
    icon: 'building'
  },
  {
    id: 'general_liability',
    name: 'General Liability',
    description: 'Business liability coverage with discounts for professional risk assessment',
    icon: 'shield-alt'
  },
  {
    id: 'business_owners',
    name: 'Business Owners Policy',
    description: 'Combined coverage for small businesses with savings for enhanced security systems',
    icon: 'store'
  },
  // Specialty Lines
  {
    id: 'cyber',
    name: 'Cyber Insurance',
    description: 'Protection against digital threats with savings for advanced security protocols',
    icon: 'shield-virus'
  },
  {
    id: 'product_liability',
    name: 'Product Liability',
    description: 'Coverage for product-related claims with savings for enhanced quality control',
    icon: 'box'
  },
  {
    id: 'directors_officers',
    name: 'Directors & Officers',
    description: 'Protection for company leadership with discounts for improved corporate governance',
    icon: 'user-tie'
  },
  {
    id: 'flood',
    name: 'Flood Insurance',
    description: 'Coverage for flood damage with savings for implementing mitigation measures',
    icon: 'water'
  },
  {
    id: 'earthquake',
    name: 'Earthquake Insurance',
    description: 'Protection against earthquake damage with discounts for structural improvements',
    icon: 'mountain'
  }
];

export default products;