// File: frontend/src/pages/TripDetail/components/TripRoute/index.js

import React, { useEffect, useRef, useState } from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import styles from './TripRoute.module.css';

const TripRoute = ({ trip }) => {
  const { t } = useLanguage();
  const mapRef = useRef(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [mapError, setMapError] = useState(null);

  useEffect(() => {
    // This would be replaced with actual map initialization code
    // For example, using Google Maps, Mapbox, or Leaflet
    const initializeMap = async () => {
      try {
        // Simulate map loading
        await new Promise(resolve => setTimeout(resolve, 1000));
        
        // Check if we have location data
        if (!trip.filtered_locations || 
            !trip.filtered_locations.filtered_latitudes || 
            !trip.filtered_locations.filtered_longitudes ||
            trip.filtered_locations.filtered_latitudes.length === 0) {
          setMapError(t('no_route_data_available'));
          return;
        }
        
        // In a real implementation, this is where you would:
        // 1. Initialize the map library
        // 2. Create a map centered on the start location
        // 3. Draw the route using the filtered locations
        // 4. Add markers for start and end points
        // 5. Add markers for events if available
        
        setMapLoaded(true);
      } catch (error) {
        console.error('Error initializing map:', error);
        setMapError(t('failed_to_load_map'));
      }
    };

    if (trip && mapRef.current) {
      initializeMap();
    }
  }, [trip, t]);

  // Extract route data for display
  const routePoints = trip.filtered_locations ? 
    trip.filtered_locations.filtered_latitudes.map((lat, index) => ({
      latitude: lat,
      longitude: trip.filtered_locations.filtered_longitudes[index],
      placeId: trip.filtered_locations.filtered_place_ids?.[index] || null,
      originalIndex: trip.filtered_locations.original_indices?.[index] || index
    })) : [];

  // Get start and end addresses
  const startAddress = trip.filtered_locations_aggs?.trip_start_address;
  const endAddress = trip.filtered_locations_aggs?.trip_finished_address;

  const formatAddress = (address) => {
    if (!address) return 'N/A';
    
    const parts = [];
    if (address.street) parts.push(address.street);
    if (address.city) parts.push(address.city);
    if (address.state) parts.push(address.state);
    if (address.postal_code) parts.push(address.postal_code);
    if (address.country) parts.push(address.country);
    
    return parts.join(', ');
  };

  return (
    <div className={styles.container}>
      <div className={styles.routeInfo}>
        <div className={styles.locationCard}>
          <div className={styles.locationIcon}>
            <div className={styles.startIcon}></div>
          </div>
          <div className={styles.locationDetails}>
            <div className={styles.locationLabel}>{t('start_location')}</div>
            <div className={styles.locationAddress}>{formatAddress(startAddress)}</div>
            <div className={styles.locationCoordinates}>
              {trip.trip_started_location ? 
                `${trip.trip_started_location.latitude.toFixed(6)}, ${trip.trip_started_location.longitude.toFixed(6)}` : 
                'N/A'}
            </div>
          </div>
        </div>
        
        <div className={styles.routeStats}>
          <div className={styles.routeStat}>
            <div className={styles.routeStatLabel}>{t('distance')}</div>
            <div className={styles.routeStatValue}>{trip.distance_travelled_calculation} km</div>
          </div>
          <div className={styles.routeStat}>
            <div className={styles.routeStatLabel}>{t('points')}</div>
            <div className={styles.routeStatValue}>{routePoints.length}</div>
          </div>
        </div>
        
        <div className={styles.locationCard}>
          <div className={styles.locationIcon}>
            <div className={styles.endIcon}></div>
          </div>
          <div className={styles.locationDetails}>
            <div className={styles.locationLabel}>{t('end_location')}</div>
            <div className={styles.locationAddress}>{formatAddress(endAddress)}</div>
            <div className={styles.locationCoordinates}>
              {trip.trip_finished_location ? 
                `${trip.trip_finished_location.latitude.toFixed(6)}, ${trip.trip_finished_location.longitude.toFixed(6)}` : 
                'N/A'}
            </div>
          </div>
        </div>
      </div>
      
      <div className={styles.mapContainer} ref={mapRef}>
        {!mapLoaded && !mapError && (
          <div className={styles.loading}>
            <div className={styles.loadingSpinner}></div>
            <p>{t('loading_map')}</p>
          </div>
        )}
        
        {mapError && (
          <div className={styles.mapError}>
            <p>{mapError}</p>
          </div>
        )}
        
        {mapLoaded && (
          <div className={styles.mapPlaceholder}>
            {/* This would be replaced by the actual map */}
            <div className={styles.mockMap}>
              <div className={styles.mockRoute}>
                <div className={styles.mockStartPoint}></div>
                <div className={styles.mockEndPoint}></div>
              </div>
              <div className={styles.mockMapText}>
                {t('map_would_be_displayed_here')}
              </div>
            </div>
          </div>
        )}
      </div>
      
      {routePoints.length > 0 && (
        <div className={styles.routePointsContainer}>
          <h3 className={styles.routePointsTitle}>{t('route_points')}</h3>
          <div className={styles.tableContainer}>
            <table className={styles.routePointsTable}>
              <thead>
                <tr>
                  <th>{t('index')}</th>
                  <th>{t('latitude')}</th>
                  <th>{t('longitude')}</th>
                  <th>{t('place_id')}</th>
                </tr>
              </thead>
              <tbody>
                {routePoints.map((point, index) => (
                  <tr key={index} className={index === 0 || index === routePoints.length - 1 ? styles.highlightedRow : ''}>
                    <td>{point.originalIndex}</td>
                    <td>{point.latitude.toFixed(6)}</td>
                    <td>{point.longitude.toFixed(6)}</td>
                    <td>{point.placeId || '-'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default TripRoute;