// File: frontend/src/components/Sidebar/SidebarNavigation.js

import React from 'react';
import { Link } from 'react-router-dom';
import { useLanguage } from '../../contexts/LanguageContext';
import SidebarNavItem from './SidebarNavItem';
import styles from './SidebarNavigation.module.css';

const SidebarNavigation = ({ 
  category, 
  isExpanded, 
  isActive, 
  activeItem,
  isOpen,
  onClick 
}) => {
  const { t } = useLanguage();

  const handleClick = (e) => {
    if (!category.path) {
      e.preventDefault();
    }
    onClick();
  };

  const CategoryContent = () => (
    <>
      <div className={styles.iconWrapper}>
        <img
          src={isActive ? category.activeIcon : category.icon}
          alt={t(category.name)}
          className={styles.categoryIcon}
        />
      </div>
      {isExpanded && (
        <>
          <span className={styles.categoryName}>{t(category.name)}</span>
          {category.items && (
            <img
              src={`/icons/18/misc/chevron-${isOpen ? 'down' : 'right'}.svg`}
              alt=""
              className={styles.chevronIcon}
            />
          )}
        </>
      )}
    </>
  );

  return (
    <div className={`${styles.categoryWrapper} ${isActive ? styles.active : ''}`}>
      {category.path ? (
        <Link 
          to={category.path} 
          className={styles.categoryHeader}
          onClick={handleClick}
        >
          <CategoryContent />
        </Link>
      ) : (
        <div 
          className={styles.categoryHeader}
          onClick={handleClick}
        >
          {isExpanded && category.isDraggable && (
            <div className={styles.dragHandle}>
              ⋮⋮
            </div>
          )}
          <CategoryContent />
        </div>
      )}
      {category.items && isOpen && isExpanded && (
        <ul className={styles.navList}>
          {category.items.map((item) => (
            <SidebarNavItem
              key={item.path}
              item={item}
              isActive={activeItem === item.name}
              isExpanded={isExpanded}
            />
          ))}
        </ul>
      )}
    </div>
  );
};

export default SidebarNavigation;