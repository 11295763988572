// File: frontend/src/pages/SuperAdmin/components/RbacManagement/RoleList/index.js

import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import SuperAdminTable from '../../common/SuperAdminTable';
import SuperAdminSearch from '../../common/SuperAdminSearch';
import SuperAdminPagination from '../../common/SuperAdminPagination';
import styles from './RoleList.module.css';

const RoleList = ({ 
  onViewDetail, 
  onViewPermissions, 
  onCreateRole, 
  onBack, 
  roleId = null, 
  isDetailView = false,
  isCreateView = false,
  onSuccess = null
}) => {
  const { t } = useLanguage();
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  
  // Form state for create/edit
  const [formData, setFormData] = useState({
    role_name: '',
    role_description: '',
    is_system_role: false,
    permissions: {}
  });

  useEffect(() => {
    const fetchRoles = async () => {
      setLoading(true);
      setError(null);
      
      try {
        // This would be replaced with an actual API call
        // const response = await rbacService.listRoles({
        //   page: currentPage,
        //   search: searchTerm
        // });
        
        // Mock data for now
        await new Promise(resolve => setTimeout(resolve, 800));
        
        const mockRoles = [
          {
            role_id: 'role-12345',
            role_name: 'Super Admin',
            role_description: 'Full system access with all permissions',
            is_system_role: true,
            created_at: '2023-01-01T10:00:00Z',
            updated_at: '2023-01-01T10:00:00Z',
            permissions: {
              users: ['view', 'create', 'update', 'delete'],
              enterprises: ['view', 'create', 'update', 'delete'],
              products: ['view', 'create', 'update', 'delete'],
              agreements: ['view', 'create', 'update', 'delete'],
              subscriptions: ['view', 'create', 'update', 'delete'],
              rbac: ['view', 'create', 'update', 'delete'],
              reports: ['view', 'create', 'update', 'delete'],
              settings: ['view', 'update']
            },
            users_count: 5
          },
          {
            role_id: 'role-23456',
            role_name: 'Admin',
            role_description: 'Administrative access with limited system settings',
            is_system_role: true,
            created_at: '2023-01-01T10:00:00Z',
            updated_at: '2023-01-01T10:00:00Z',
            permissions: {
              users: ['view', 'create', 'update'],
              enterprises: ['view', 'update'],
              products: ['view', 'create', 'update'],
              agreements: ['view', 'create', 'update'],
              subscriptions: ['view', 'create', 'update'],
              rbac: ['view'],
              reports: ['view', 'create'],
              settings: ['view']
            },
            users_count: 12
          },
          {
            role_id: 'role-34567',
            role_name: 'Manager',
            role_description: 'Management access for team oversight',
            is_system_role: true,
            created_at: '2023-01-01T10:00:00Z',
            updated_at: '2023-01-01T10:00:00Z',
            permissions: {
              users: ['view', 'create'],
              enterprises: ['view'],
              products: ['view'],
              agreements: ['view'],
              subscriptions: ['view'],
              rbac: [],
              reports: ['view', 'create'],
              settings: []
            },
            users_count: 28
          },
          {
            role_id: 'role-45678',
            role_name: 'User',
            role_description: 'Standard user access',
            is_system_role: true,
            created_at: '2023-01-01T10:00:00Z',
            updated_at: '2023-01-01T10:00:00Z',
            permissions: {
              users: ['view'],
              enterprises: ['view'],
              products: ['view'],
              agreements: ['view'],
              subscriptions: ['view'],
              rbac: [],
              reports: ['view'],
              settings: []
            },
            users_count: 156
          },
          {
            role_id: 'role-56789',
            role_name: 'Custom Role',
            role_description: 'Custom role with specific permissions',
            is_system_role: false,
            created_at: '2023-02-15T14:30:00Z',
            updated_at: '2023-02-15T14:30:00Z',
            permissions: {
              users: ['view'],
              enterprises: ['view'],
              products: ['view', 'create', 'update'],
              agreements: ['view'],
              subscriptions: ['view'],
              rbac: [],
              reports: ['view', 'create'],
              settings: []
            },
            users_count: 3
          }
        ];
        
        // Filter based on search term
        let filteredRoles = mockRoles;
        
        if (searchTerm) {
          const searchLower = searchTerm.toLowerCase();
          filteredRoles = filteredRoles.filter(role => 
            role.role_name.toLowerCase().includes(searchLower) ||
            role.role_description.toLowerCase().includes(searchLower)
          );
        }
        
        setRoles(filteredRoles);
        setTotalPages(Math.ceil(filteredRoles.length / 10)); // Assuming 10 items per page
        
        // If in detail view, find the selected role
        if (isDetailView && roleId) {
          const role = filteredRoles.find(r => r.role_id === roleId);
          if (role) {
            setSelectedRole(role);
          } else {
            setError(t('role_not_found'));
          }
        }
      } catch (err) {
        console.error('Error fetching roles:', err);
        setError(t('failed_to_load_roles'));
      } finally {
        setLoading(false);
      }
    };
    
    fetchRoles();
  }, [currentPage, searchTerm, roleId, isDetailView, t]);

  const handleSearch = (term) => {
    setSearchTerm(term);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    }).format(date);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    if (name.startsWith('permission_')) {
      const [_, resource, action] = name.split('_');
      
      setFormData(prev => {
        const updatedPermissions = { ...prev.permissions };
        
        if (!updatedPermissions[resource]) {
          updatedPermissions[resource] = [];
        }
        
        if (checked) {
          // Add the action if it doesn't exist
          if (!updatedPermissions[resource].includes(action)) {
            updatedPermissions[resource] = [...updatedPermissions[resource], action];
          }
        } else {
          // Remove the action
          updatedPermissions[resource] = updatedPermissions[resource].filter(a => a !== action);
        }
        
        return {
          ...prev,
          permissions: updatedPermissions
        };
      });
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: type === 'checkbox' ? checked : value
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    try {
      // This would be replaced with an actual API call
      // const response = await rbacService.createRole(formData);
      
      // Mock response for now
      await new Promise(resolve => setTimeout(resolve, 800));
      
      const newRoleId = `role-${Math.random().toString(36).substring(2, 10)}`;
      
      // Call the success callback with the new role ID
      if (onSuccess) {
        onSuccess(newRoleId);
      }
    } catch (err) {
      console.error('Error creating role:', err);
      setError(t('failed_to_create_role'));
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      header: t('role_name'),
      accessor: 'role_name',
      cell: (row) => <span className={styles.nameCell}>{row.role_name}</span>
    },
    {
      header: t('description'),
      accessor: 'role_description',
      cell: (row) => <span className={styles.descriptionCell}>{row.role_description}</span>
    },
    {
      header: t('type'),
      accessor: 'is_system_role',
      cell: (row) => (
        <span className={`${styles.typeBadge} ${row.is_system_role ? styles.systemRole : styles.customRole}`}>
          {row.is_system_role ? t('system_role') : t('custom_role')}
        </span>
      )
    },
    {
      header: t('users'),
      accessor: 'users_count',
      cell: (row) => <span>{row.users_count}</span>
    },
    {
      header: t('created_at'),
      accessor: 'created_at',
      cell: (row) => <span>{formatDate(row.created_at)}</span>
    },
    {
      header: t('actions'),
      accessor: 'actions',
      cell: (row) => (
        <div className={styles.actions}>
          <button 
            className={styles.actionButton}
            onClick={() => onViewDetail(row.role_id)}
          >
            <i className="fas fa-eye"></i>
            <span>{t('view')}</span>
          </button>
          {!row.is_system_role && (
            <button className={styles.actionButton}>
              <i className="fas fa-edit"></i>
              <span>{t('edit')}</span>
            </button>
          )}
        </div>
      )
    }
  ];

  // Render role detail view
  if (isDetailView && selectedRole) {
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <button className={styles.backButton} onClick={onBack}>
            <i className="fas fa-arrow-left"></i>
            <span>{t('back')}</span>
          </button>
          
          {!selectedRole.is_system_role && (
            <button className={styles.editButton}>
              <i className="fas fa-edit"></i>
              <span>{t('edit')}</span>
            </button>
          )}
        </div>
        
        <div className={styles.roleDetail}>
          <div className={styles.roleHeader}>
            <h2 className={styles.roleName}>{selectedRole.role_name}</h2>
            <span className={`${styles.typeBadge} ${selectedRole.is_system_role ? styles.systemRole : styles.customRole}`}>
              {selectedRole.is_system_role ? t('system_role') : t('custom_role')}
            </span>
          </div>
          
          <div className={styles.roleDescription}>
            {selectedRole.role_description}
          </div>
          
          <div className={styles.section}>
            <h3 className={styles.sectionTitle}>{t('permissions')}</h3>
            <div className={styles.permissionsGrid}>
              {Object.entries(selectedRole.permissions).map(([resource, actions]) => (
                <div key={resource} className={styles.permissionCard}>
                  <div className={styles.resourceName}>
                    <i className={`fas fa-${getResourceIcon(resource)}`}></i>
                    <span>{t(resource)}</span>
                  </div>
                  <div className={styles.actionsList}>
                    {actions.length > 0 ? (
                      actions.map(action => (
                        <span key={action} className={styles.actionBadge}>
                          {t(action)}
                        </span>
                      ))
                    ) : (
                      <span className={styles.noActions}>{t('no_permissions')}</span>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
          
          <div className={styles.section}>
            <h3 className={styles.sectionTitle}>{t('role_information')}</h3>
            <div className={styles.infoGrid}>
              <div className={styles.infoItem}>
                <div className={styles.infoLabel}>{t('role_id')}</div>
                <div className={styles.infoValue}>{selectedRole.role_id}</div>
              </div>
              <div className={styles.infoItem}>
                <div className={styles.infoLabel}>{t('created_at')}</div>
                <div className={styles.infoValue}>{formatDate(selectedRole.created_at)}</div>
              </div>
              <div className={styles.infoItem}>
                <div className={styles.infoLabel}>{t('updated_at')}</div>
                <div className={styles.infoValue}>{formatDate(selectedRole.updated_at)}</div>
              </div>
              <div className={styles.infoItem}>
                <div className={styles.infoLabel}>{t('users_with_role')}</div>
                <div className={styles.infoValue}>{selectedRole.users_count}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Render create role view
  if (isCreateView) {
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <button className={styles.backButton} onClick={onBack} disabled={loading}>
            <i className="fas fa-arrow-left"></i>
            <span>{t('back')}</span>
          </button>
        </div>
        
        {error && (
          <div className={styles.error}>
            <i className="fas fa-exclamation-circle"></i>
            <span>{error}</span>
          </div>
        )}
        
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.formSection}>
            <h3 className={styles.sectionTitle}>{t('role_details')}</h3>
            <div className={styles.formGrid}>
              <div className={styles.formGroup}>
                <label htmlFor="role_name" className={styles.label}>
                  {t('role_name')} <span className={styles.required}>*</span>
                </label>
                <input
                  type="text"
                  id="role_name"
                  name="role_name"
                  className={styles.input}
                  value={formData.role_name}
                  onChange={handleChange}
                  required
                  disabled={loading}
                />
              </div>
              
              <div className={styles.formGroupFull}>
                <label htmlFor="role_description" className={styles.label}>
                  {t('description')} <span className={styles.required}>*</span>
                </label>
                <textarea
                  id="role_description"
                  name="role_description"
                  className={styles.textarea}
                  value={formData.role_description}
                  onChange={handleChange}
                  rows={3}
                  required
                  disabled={loading}
                ></textarea>
              </div>
            </div>
          </div>
          
          <div className={styles.formSection}>
            <h3 className={styles.sectionTitle}>{t('permissions')}</h3>
            <p className={styles.permissionsDescription}>
              {t('select_permissions_description')}
            </p>
            
            <div className={styles.permissionsTable}>
              <table>
                <thead>
                  <tr>
                    <th>{t('resource')}</th>
                    <th>{t('view')}</th>
                    <th>{t('create')}</th>
                    <th>{t('update')}</th>
                    <th>{t('delete')}</th>
                  </tr>
                </thead>
                <tbody>
                  {['users', 'enterprises', 'products', 'agreements', 'subscriptions', 'rbac', 'reports', 'settings'].map(resource => (
                    <tr key={resource}>
                      <td className={styles.resourceCell}>
                        <i className={`fas fa-${getResourceIcon(resource)}`}></i>
                        <span>{t(resource)}</span>
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          id={`permission_${resource}_view`}
                          name={`permission_${resource}_view`}
                          checked={formData.permissions[resource]?.includes('view') || false}
                          onChange={handleChange}
                          disabled={loading}
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          id={`permission_${resource}_create`}
                          name={`permission_${resource}_create`}
                          checked={formData.permissions[resource]?.includes('create') || false}
                          onChange={handleChange}
                          disabled={loading || resource === 'settings'}
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          id={`permission_${resource}_update`}
                          name={`permission_${resource}_update`}
                          checked={formData.permissions[resource]?.includes('update') || false}
                          onChange={handleChange}
                          disabled={loading}
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          id={`permission_${resource}_delete`}
                          name={`permission_${resource}_delete`}
                          checked={formData.permissions[resource]?.includes('delete') || false}
                          onChange={handleChange}
                          disabled={loading || resource === 'settings'}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          
          <div className={styles.formActions}>
            <button 
              type="button" 
              className={styles.cancelButton} 
              onClick={onBack}
              disabled={loading}
            >
              {t('cancel')}
            </button>
            <button 
              type="submit" 
              className={styles.submitButton}
              disabled={loading}
            >
              {loading ? (
                <>
                  <span className={styles.spinner}></span>
                  <span>{t('creating')}</span>
                </>
              ) : (
                t('create_role')
              )}
            </button>
          </div>
        </form>
      </div>
    );
  }

  // Render role list view
  return (
    <div className={styles.container}>
      <div className={styles.toolbar}>
        <div className={styles.filters}>
          <SuperAdminSearch 
            placeholder={t('search_roles')}
            onSearch={handleSearch}
          />
        </div>
        
        <div className={styles.actionButtons}>
          <button className={styles.permissionsButton} onClick={onViewPermissions}>
            <i className="fas fa-shield-alt"></i>
            <span>{t('permission_settings')}</span>
          </button>
          <button className={styles.createButton} onClick={onCreateRole}>
            <i className="fas fa-plus"></i>
            <span>{t('create_role')}</span>
          </button>
        </div>
      </div>
      
      {error && <div className={styles.error}>{error}</div>}
      
      <SuperAdminTable
        columns={columns}
        data={roles}
        loading={loading}
        emptyMessage={t('no_roles_found')}
      />
      
      <div className={styles.pagination}>
        <SuperAdminPagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

// Helper function to get icon for resource
const getResourceIcon = (resource) => {
  switch (resource) {
    case 'users':
      return 'users';
    case 'enterprises':
      return 'building';
    case 'products':
      return 'box';
    case 'agreements':
      return 'file-contract';
    case 'subscriptions':
      return 'credit-card';
    case 'rbac':
      return 'shield-alt';
    case 'reports':
      return 'chart-bar';
    case 'settings':
      return 'cog';
    default:
      return 'circle';
  }
};

export default RoleList;