// File: frontend/src/pages/SuperAdmin/components/common/SuperAdminHeader/index.js

import React from 'react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from './SuperAdminHeader.module.css';

const SuperAdminHeader = () => {
  const { t } = useLanguage();
  
  return (
    <header className={styles.header}>
      <div className={styles.logoContainer}>
        <img src="/logo.svg" alt="Logo" className={styles.logo} />
        <h1 className={styles.title}>{t('super_admin_portal')}</h1>
      </div>
      
      <div className={styles.userActions}>
        <div className={styles.searchContainer}>
          <input 
            type="text" 
            className={styles.searchInput} 
            placeholder={t('search_super_admin')} 
          />
          <button className={styles.searchButton}>
            <i className="fas fa-search"></i>
          </button>
        </div>
        
        <div className={styles.notifications}>
          <button className={styles.notificationButton}>
            <i className="fas fa-bell"></i>
            <span className={styles.notificationBadge}>3</span>
          </button>
        </div>
        
        <div className={styles.userProfile}>
          <img 
            src="/images/default-avatar.png" 
            alt="User Avatar" 
            className={styles.userAvatar} 
          />
          <span className={styles.userName}>Admin User</span>
          <button className={styles.userMenuButton}>
            <i className="fas fa-chevron-down"></i>
          </button>
        </div>
      </div>
    </header>
  );
};

export default SuperAdminHeader;