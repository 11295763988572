// File: frontend/src/components/JobApplicationForm/JobApplicationForm.js

import React, { useState } from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import { useJobApplication } from '../../hooks/useJobApplication';
import Input from '../form/Input/Input';
import PhoneInput from '../form/PhoneInput/PhoneInput';
import FormGroup from '../form/FormGroup/FormGroup';
import UploadSection from '../UploadSection/UploadSection';
import Checkbox from '../form/Checkbox/Checkbox';
import Button from '../Button/Button';
import Alert from '../Alert/Alert';
import PositionSelect from './PositionSelect';
import CountrySelectResidence from './CountrySelectResidence';
import CountrySelectWork from './CountrySelectWork';
import { COUNTRIES } from './jobApplicationConstants';
import styles from './JobApplicationForm.module.css';

const JobApplicationForm = () => {
  const { t } = useLanguage();
  const { submit, isLoading, error, success, clearError } = useJobApplication();

  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: { country_phone_code: '+1', area_code: '', phone_number: '' },
    positions: [],
    country_of_residence: null,
    preferred_work_location: null,
    resume: null,
    cover_letter: null,
    consent: false,
  });

  const [formErrors, setFormErrors] = useState({});

  // Add this function to check if all required fields are filled
  const isFormValid = () => {
    return !!(
      formData.first_name &&
      formData.last_name &&
      formData.email &&
      formData.phone.country_phone_code &&
      formData.phone.area_code &&
      formData.phone.phone_number &&
      formData.positions.length > 0 &&
      formData.positions.length <= 3 &&
      formData.country_of_residence &&
      formData.preferred_work_location && 
      formData.resume &&
      formData.cover_letter &&
      formData.consent
    );
  };

  // Add this function to validate email format
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Add this function to validate phone number
  const isValidPhone = (phone) => {
    return phone.country_phone_code &&
           phone.area_code &&
           phone.phone_number &&
           phone.area_code.length >= 2 &&
           phone.phone_number.length >= 7;
  };

  // Update the validateForm function to include more specific validations
  const validateForm = () => {
    const errors = {};
    if (!formData.first_name) errors.first_name = 'required';
    if (!formData.last_name) errors.last_name = 'required';
    if (!formData.email) {
      errors.email = 'required';
    } else if (!isValidEmail(formData.email)) {
      errors.email = 'invalid';
    }
    if (!isValidPhone(formData.phone)) {
      errors.phone = 'invalid';
    }
    if (!formData.positions || formData.positions.length === 0) {
      errors.positions = 'required';
    } else if (formData.positions.length > 3) {
      errors.positions = 'maxThreePositions';
    }
    if (!formData.country_of_residence) errors.country_of_residence = 'required';
    if (!formData.preferred_work_location) errors.preferred_work_location = 'required';
    if (!formData.resume) errors.resume = 'required';
    if (!formData.cover_letter) errors.cover_letter = 'required';
    if (!formData.consent) errors.consent = 'required';
    
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleChange = (field, value) => {
    setFormData(prev => ({ ...prev, [field]: value }));
    if (formErrors[field]) {
      setFormErrors(prev => ({ ...prev, [field]: null }));
    }
  };

  const handlePhoneChange = (value) => {
    handleChange('phone', value);
  };

  const handleFileChange = (field, file) => {
    handleChange(field, file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      // console.log('Form data before submission:', formData);

      const payload = {
        ...formData,
        country_of_residence: formData.country_of_residence?.value || formData.country_of_residence,
        preferred_work_location: formData.preferred_work_location?.value || formData.preferred_work_location,
        positions: formData.positions
      };

      // console.log('Payload after transformation:', payload);

      await submit(payload);
      // Reset form on success
      setFormData({
        first_name: '',
        last_name: '',
        email: '',
        phone: { country_phone_code: '+1', area_code: '', phone_number: '' },
        positions: [],
        country_of_residence: null,
        preferred_work_location: null,
        resume: null,
        cover_letter: null,
        consent: false,
      });
    } catch (err) {
      // Error handling is done in the hook
    }
  };

  return (
    <form className={styles.jobApplicationForm} onSubmit={handleSubmit}>

      {!success && (
        <>
          <FormGroup>
            <Input
              id="first_name"
              labelKey="jobApplication.firstNameLabel"
              placeholderKey="auth.signUp.firstNamePlaceholder"
              required
              value={formData.first_name}
              onChange={(value) => handleChange('first_name', value)}
              error={!!formErrors.first_name}
              errorKey={formErrors.first_name && 'validation.required'}
            />
            <Input
              id="last_name"
              labelKey="jobApplication.lastNameLabel"
              placeholderKey="auth.signUp.lastNamePlaceholder"
              required
              value={formData.last_name}
              onChange={(value) => handleChange('last_name', value)}
              error={!!formErrors.last_name}
              errorKey={formErrors.last_name && 'validation.required'}
            />
            <Input
              id="email"
              type="email"
              labelKey="jobApplication.emailLabel"
              placeholderKey="auth.signUp.companyEmailPlaceholder"
              required
              value={formData.email}
              onChange={(value) => handleChange('email', value)}
              error={!!formErrors.email}
              errorKey={formErrors.email && 'validation.required'}
            />
            <PhoneInput
              id="phone"
              labelKey="jobApplication.phoneLabel"
              required
              value={formData.phone}
              onChange={handlePhoneChange}
              error={!!formErrors.phone}
              errorKey={formErrors.phone && 'validation.required'}
            />
          </FormGroup>

          <PositionSelect
            value={formData.positions} // Pass the array directly
            onChange={(newPositions) => handleChange('positions', newPositions)}
            error={!!formErrors.positions}
            errorKey={formErrors.positions && `validation.positions.${formErrors.positions}`}
          />

          <FormGroup>
          <CountrySelectResidence
            id="residence-country"
            labelKey="jobApplication.residence.label"
            placeholderKey="jobApplication.residence.placeholder"
            hintKey="jobApplication.residence.hint"
            options={COUNTRIES.RESIDENCE}
            value={formData.country_of_residence}
            onChange={(value) => handleChange('country_of_residence', value)}
            error={!!formErrors.country_of_residence}
            errorKey={formErrors.country_of_residence && 'validation.residence.required'}
          />

          <CountrySelectWork
            id="work-locations"
            labelKey="jobApplication.workLocations.label"
            placeholderKey="jobApplication.workLocations.placeholder"
            hintKey="jobApplication.workLocations.hint"
            options={COUNTRIES.WORK_LOCATIONS}
            value={formData.preferred_work_location}
            onChange={(value) => handleChange('preferred_work_location', value)}
            error={!!formErrors.preferred_work_location}
            errorKey={formErrors.preferred_work_location && 'validation.workLocations.required'}
          />
          </FormGroup>

          <div className={styles.uploadSections}>
            <UploadSection
              labelKey="jobApplication.resumeLabel"
              acceptedFileTypes=".pdf"
              onFileChange={(file) => handleFileChange('resume', file)}
              error={!!formErrors.resume}
              errorKey={formErrors.resume && 'validation.required'}
            />
            <UploadSection
              labelKey="jobApplication.coverLetterLabel"
              acceptedFileTypes=".pdf"
              onFileChange={(file) => handleFileChange('cover_letter', file)}
              error={!!formErrors.cover_letter}
              errorKey={formErrors.cover_letter && 'validation.required'}
            />
          </div>

          <Checkbox
            id="consent"
            labelKey="jobApplication.consentLabel"
            required
            checked={formData.consent}
            onChange={(value) => handleChange('consent', value)}
            error={!!formErrors.consent}
            errorKey={formErrors.consent && 'validation.consent.required'}
          />

          <Button 
            type="submit" 
            variant="primary" 
            size="medium" 
            isLoading={isLoading}
            disabled={!isFormValid()}
            showArrow
          >
            {t('jobApplication.submitButton')}
          </Button>
        </>
      )}

      {error && (
        <Alert 
          type="error" 
          titleKey="jobApplication.error.title"
          messageKey={`jobApplication.errors.${error}`}
          onClose={clearError}
        />
      )}

      {success && (
        <Alert 
          type="success" 
          titleKey="jobApplication.success.title"
          messageKey="jobApplication.success.message"
        />
      )}
    </form>
  );
};

export default JobApplicationForm;