// File: frontend/src/pages/AutoInsight/VehicleDetail/components/VehicleOwnership/index.js

import React from 'react';
import { useLanguage } from '../../../../../../contexts/LanguageContext';
import styles from './VehicleOwnership.module.css';

// Helper function to format date
const formatDate = (dateString) => {
  if (!dateString) return 'N/A';
  
  const date = new Date(dateString);
  
  // Check if date is valid
  if (isNaN(date.getTime())) return 'Invalid Date';
  
  return date.toLocaleDateString();
};

const VehicleOwnership = ({ vehicle }) => {
  const { t } = useLanguage();

  // Parse JSON strings if needed
  const registeredOwners = vehicle.ownership?.vehicle_registered_owners 
    ? (typeof vehicle.ownership.vehicle_registered_owners === 'string' 
        ? JSON.parse(vehicle.ownership.vehicle_registered_owners) 
        : vehicle.ownership.vehicle_registered_owners)
    : [];
  
  const lienholders = vehicle.ownership?.vehicle_lienholders 
    ? (typeof vehicle.ownership.vehicle_lienholders === 'string' 
        ? JSON.parse(vehicle.ownership.vehicle_lienholders) 
        : vehicle.ownership.vehicle_lienholders)
    : [];
  
  const listedDrivers = vehicle.ownership?.vehicle_listed_drivers 
    ? (typeof vehicle.ownership.vehicle_listed_drivers === 'string' 
        ? JSON.parse(vehicle.ownership.vehicle_listed_drivers) 
        : vehicle.ownership.vehicle_listed_drivers)
    : [];

  return (
    <div className={styles.container}>
      <div className={styles.grid}>
        <div className={styles.card}>
          <h3 className={styles.cardTitle}>{t('registered_owners')}</h3>
          <div className={styles.cardContent}>
            {registeredOwners.length > 0 ? (
              <div className={styles.ownersList}>
                {registeredOwners.map((owner, index) => (
                  <div key={owner.owner_id || index} className={styles.ownerItem}>
                    <div className={styles.ownerHeader}>
                      <div className={styles.ownerName}>{owner.owner_name}</div>
                      {owner.relationship === 'primary' && (
                        <div className={styles.primaryBadge}>{t('primary')}</div>
                      )}
                    </div>
                    <div className={styles.ownerDetails}>
                      <div className={styles.ownerDetail}>
                        <span className={styles.ownerDetailLabel}>{t('owner_id')}:</span>
                        <span className={styles.ownerDetailValue}>{owner.owner_id}</span>
                      </div>
                      <div className={styles.ownerDetail}>
                        <span className={styles.ownerDetailLabel}>{t('relationship')}:</span>
                        <span className={styles.ownerDetailValue}>{owner.relationship}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className={styles.noData}>{t('no_registered_owners')}</div>
            )}
          </div>
        </div>
        
        <div className={styles.card}>
          <h3 className={styles.cardTitle}>{t('lienholders')}</h3>
          <div className={styles.cardContent}>
            {lienholders.length > 0 ? (
              <div className={styles.lienholdersList}>
                {lienholders.map((lienholder, index) => (
                  <div key={lienholder.lienholder_id || index} className={styles.lienholderItem}>
                    <div className={styles.lienholderName}>{lienholder.lienholder_name}</div>
                    <div className={styles.lienholderDetails}>
                      <div className={styles.lienholderDetail}>
                        <span className={styles.lienholderDetailLabel}>{t('lienholder_id')}:</span>
                        <span className={styles.lienholderDetailValue}>{lienholder.lienholder_id}</span>
                      </div>
                      <div className={styles.lienholderDetail}>
                        <span className={styles.lienholderDetailLabel}>{t('lien_amount')}:</span>
                        <span className={styles.lienholderDetailValue}>
                          ${lienholder.lien_amount?.toFixed(2) || 'N/A'}
                        </span>
                      </div>
                      <div className={styles.lienholderDetail}>
                        <span className={styles.lienholderDetailLabel}>{t('lien_start_date')}:</span>
                        <span className={styles.lienholderDetailValue}>
                          {formatDate(lienholder.lien_start_date)}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className={styles.noData}>{t('no_lienholders')}</div>
            )}
          </div>
        </div>
      </div>
      
      <div className={styles.card}>
        <h3 className={styles.cardTitle}>{t('listed_drivers')}</h3>
        <div className={styles.cardContent}>
          {listedDrivers.length > 0 ? (
            <div className={styles.driversGrid}>
              {listedDrivers.map((driver, index) => (
                <div key={driver.driver_id || index} className={styles.driverItem}>
                  <div className={styles.driverHeader}>
                    <div className={styles.driverName}>{driver.driver_name}</div>
                    {driver.relationship === 'primary' && (
                      <div className={styles.primaryBadge}>{t('primary')}</div>
                    )}
                  </div>
                  <div className={styles.driverDetails}>
                    <div className={styles.driverDetail}>
                      <span className={styles.driverDetailLabel}>{t('driver_id')}:</span>
                      <span className={styles.driverDetailValue}>{driver.driver_id}</span>
                    </div>
                    <div className={styles.driverDetail}>
                      <span className={styles.driverDetailLabel}>{t('relationship')}:</span>
                      <span className={styles.driverDetailValue}>{driver.relationship}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className={styles.noData}>{t('no_listed_drivers')}</div>
          )}
        </div>
      </div>
      
      <div className={styles.ownershipInfo}>
        <div className={styles.infoGroup}>
          <div className={styles.infoLabel}>{t('registered_owner_id')}:</div>
          <div className={styles.infoValue}>{vehicle.ownership?.vehicle_registered_owner_id || 'N/A'}</div>
        </div>
        <div className={styles.infoGroup}>
          <div className={styles.infoLabel}>{t('ownership_entry_date')}:</div>
          <div className={styles.infoValue}>{formatDate(vehicle.ownership?.vehicle_owner_entry_timestamp)}</div>
        </div>
        <div className={styles.infoGroup}>
          <div className={styles.infoLabel}>{t('last_updated')}:</div>
          <div className={styles.infoValue}>{formatDate(vehicle.ownership?.updated_at)}</div>
        </div>
      </div>
    </div>
  );
};

export default VehicleOwnership;