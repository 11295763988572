// File: frontend/src/services/userService.js
import { enumService } from './enumService';

const USER_KEY = 'user_data';

export const userService = {
  setUser: (userData) => {
    if (!userData) {
      localStorage.removeItem(USER_KEY);
      return;
    }
    
    // Transform and store user data with resolved enums
    const transformedUser = enumService.resolveUserEnums(userData);
    localStorage.setItem(USER_KEY, JSON.stringify(transformedUser));
  },

  getUser: () => {
    const userData = localStorage.getItem(USER_KEY);
    return userData ? JSON.parse(userData) : null;
  },

  clearUser: () => {
    localStorage.removeItem(USER_KEY);
  },

  updateUser: (updates) => {
    const currentUser = userService.getUser();
    if (!currentUser) return;
    
    // If updating any enum fields, resolve them
    const transformedUpdates = enumService.resolveUserEnums(updates);
    const updatedUser = { ...currentUser, ...transformedUpdates };
    userService.setUser(updatedUser);
    return updatedUser;
  }
};