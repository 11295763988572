import React from 'react';
import { motion } from 'framer-motion';
import { 
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
  Cell, LabelList 
} from 'recharts';
import { ANIMATION_VARIANTS } from '../../../../constants/animations';
import styles from './ExpenseAnalysisSlide.module.css';

const ExpenseAnalysisSlide = () => {
  const data = [
    {
      name: "Auto",
      Operational: 283,
      Acquisition: 690,
      IT: 101,
      totalSavings: 911,
      aiImpact: {
        Operational: 99.9,
        Acquisition: 80,
        IT: 75
      }
    },
    {
      name: "Home",
      Operational: 115,
      Acquisition: 288,
      IT: 43,
      totalSavings: 377,
      aiImpact: {
        Operational: 99.9,
        Acquisition: 80,
        IT: 75
      }
    },
    {
      name: "Commercial",
      Operational: 53,
      Acquisition: 120,
      IT: 19,
      totalSavings: 163,
      aiImpact: {
        Operational: 99.9,
        Acquisition: 80,
        IT: 75
      }
    }
  ];

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const total = payload.reduce((sum, p) => sum + (p.value || 0), 0);
      return (
        <div className={styles.tooltip}>
          <p className={styles.tooltipTitle}>{label}</p>
          {payload.map((p) => (
            <div key={p.name} className={styles.tooltipRow}>
              <span>{p.name}:</span>
              <span>${p.value}M</span>
              <span>{((p.value / total) * 100).toFixed(1)}%</span>
            </div>
          ))}
          <div className={styles.tooltipTotal}>
            <span>Total:</span>
            <span>${total}M</span>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <motion.div
      className={styles.container}
      variants={ANIMATION_VARIANTS.stagger.container}
      initial="hidden"
      animate="visible"
    >
      <motion.div 
        className={styles.header}
        variants={ANIMATION_VARIANTS.fadeIn}
      >
        <h1 className={styles.title}>Insurance Operational Expense Distribution</h1>
        <p className={styles.subtitle}>
          Detailed expense analysis of a $3.2B portfolio
        </p>
      </motion.div>

      <motion.div 
        className={styles.chartContainer}
        variants={ANIMATION_VARIANTS.fadeIn}
      >
        <ResponsiveContainer width="100%" height={400}>
          <BarChart
            data={data}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis label={{ value: 'Million USD', angle: -90, position: 'insideLeft' }} />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <Bar dataKey="Operational" stackId="a" fill="var(--color-blue-310)" />
            <Bar dataKey="Acquisition" stackId="a" fill="var(--color-green-310)" />
            <Bar dataKey="IT" stackId="a" fill="var(--color-orange-310)" />
          </BarChart>
        </ResponsiveContainer>
      </motion.div>

      <motion.div 
        className={styles.summary}
        variants={ANIMATION_VARIANTS.fadeIn}
      >
        <div className={styles.summaryCard}>
          <h3>Total AI-Driven Savings</h3>
          <span className={styles.savingsAmount}>$862M</span>
          <p>Through intelligent automation and optimization</p>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default ExpenseAnalysisSlide;