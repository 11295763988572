// File: src/utils/aiSeoHelpers.js

export const generateStructuredData = (categories, t) => {
    const baseStructuredData = {
      '@context': 'https://schema.org',
      '@type': 'SoftwareApplication',
      name: 'Allegory AI Agents Platform',
      applicationCategory: 'Insurance Software',
      applicationSubCategory: 'Artificial Intelligence',
      operatingSystem: 'Web-based',
      description: t('ai_agents.hero.subtitle'),
      offers: {
        '@type': 'Offer',
        availability: 'https://schema.org/OnlineOnly',
        price: '0',
        priceCurrency: 'USD'
      }
    };
  
    const organizationData = {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      name: 'Allegory Insurance Technologies',
      url: 'https://www.allegory.app',
      logo: 'https://www.allegory.app/logo.png'
    };
  
    const categoryListData = {
      '@context': 'https://schema.org',
      '@type': 'ItemList',
      name: 'AI Insurance Agents',
      itemListElement: categories.map((category, index) => ({
        '@type': 'ListItem',
        position: index + 1,
        item: {
          '@type': 'SoftwareApplication',
          name: t(`ai_agents.categories.${category.id}.title`),
          description: t(`ai_agents.categories.${category.id}.description`),
          applicationCategory: 'Insurance Software',
          operatingSystem: 'Web-based'
        }
      }))
    };
  
    return [baseStructuredData, organizationData, categoryListData];
  };