// File: frontend/src/pages/PrivacyPolicy/PrivacyPolicy.js

import React from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import styles from './PrivacyPolicy.module.css';

const PrivacyPolicy = () => {
  const { t } = useLanguage();

  const introduction = {
    content: 'privacy_policy.introduction'
  };

  const informationWeCollect = {
    title: 'privacy_policy.information_we_collect',
    personalInformationProvided: {
      title: 'privacy_policy.personal_information_provided',
      content: 'privacy_policy.personal_information_provided_list'
    },
    informationCollectedAutomatically: {
      title: 'privacy_policy.information_collected_automatically',
      content: 'privacy_policy.information_collected_automatically_list'
    },
    informationFromThirdParties: {
      title: 'privacy_policy.information_from_third_parties',
      content: 'privacy_policy.information_from_third_parties_list'
    }
  };

  const howWeUseYourInformation = {
    title: 'privacy_policy.how_we_use_your_information',
    content: 'privacy_policy.how_we_use_your_information_list'
  };

  const aiAndDataAnalytics = {
    title: 'privacy_policy.ai_and_data_analytics',
    content: 'privacy_policy.ai_and_data_analytics_description'
  };

  const dataSharing = {
    title: 'privacy_policy.data_sharing_and_disclosure',
    content: 'privacy_policy.data_sharing_and_disclosure_description'
  };

  const dataSecurity = {
    title: 'privacy_policy.data_security',
    content: 'privacy_policy.data_security_description'
  };

  const yourRights = {
    title: 'privacy_policy.your_rights_and_choices',
    content: 'privacy_policy.your_rights_and_choices_description'
  };

  const childrenPrivacy = {
    title: 'privacy_policy.childrens_privacy',
    content: 'privacy_policy.childrens_privacy_description'
  };

  const policyChanges = {
    title: 'privacy_policy.changes_to_this_policy',
    content: 'privacy_policy.changes_to_this_policy_description'
  };

  const contactUs = {
    title: 'privacy_policy.contact_us',
    content: 'privacy_policy.contact_us_description'
  };

  const optOutMarketing = {
    title: 'privacy_policy.opt_out_of_marketing',
    content: 'privacy_policy.opt_out_of_marketing_description'
  };

  const disclaimer = {
    content: 'privacy_policy.disclaimer'
  };

  return (
    <div className={styles.privacyPolicyContainer}>
      <h1 className={styles.title}>{t('privacy_policy_title')}</h1>
      <p className={styles.lastUpdated}>{t('privacy_policy_last_updated')}</p>
      <p className={styles.effectiveDate}>{t('privacy_policy_effective_date')}</p>

      <section className={styles.section}>
        <p>{t(introduction.content)}</p>
      </section>

      <section className={styles.section}>
        <h2>{t(informationWeCollect.title)}</h2>
        <div>
          <h3>{t(informationWeCollect.personalInformationProvided.title)}</h3>
          <p>{t(informationWeCollect.personalInformationProvided.content)}</p>
        </div>
        <div>
          <h3>{t(informationWeCollect.informationCollectedAutomatically.title)}</h3>
          <p>{t(informationWeCollect.informationCollectedAutomatically.content)}</p>
        </div>
        <div>
          <h3>{t(informationWeCollect.informationFromThirdParties.title)}</h3>
          <p>{t(informationWeCollect.informationFromThirdParties.content)}</p>
        </div>
      </section>

      <section className={styles.section}>
        <h2>{t(howWeUseYourInformation.title)}</h2>
        <p>{t(howWeUseYourInformation.content)}</p>
      </section>

      <section className={styles.section}>
        <h2>{t(aiAndDataAnalytics.title)}</h2>
        <p>{t(aiAndDataAnalytics.content)}</p>
      </section>

      <section className={styles.section}>
        <h2>{t(dataSharing.title)}</h2>
        <p>{t(dataSharing.content)}</p>
      </section>

      <section className={styles.section}>
        <h2>{t(dataSecurity.title)}</h2>
        <p>{t(dataSecurity.content)}</p>
      </section>

      <section className={styles.section}>
        <h2>{t(yourRights.title)}</h2>
        <p>{t(yourRights.content)}</p>
      </section>

      <section className={styles.section}>
        <h2>{t(childrenPrivacy.title)}</h2>
        <p>{t(childrenPrivacy.content)}</p>
      </section>

      <section className={styles.section}>
        <h2>{t(policyChanges.title)}</h2>
        <p>{t(policyChanges.content)}</p>
      </section>

      <section className={styles.section}>
        <h2>{t(contactUs.title)}</h2>
        <p>{t(contactUs.content)}</p>
      </section>

      <section className={styles.section}>
        <h2>{t(optOutMarketing.title)}</h2>
        <p>{t(optOutMarketing.content)}</p>
      </section>

      <section className={styles.section}>
        <p>{t(disclaimer.content)}</p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;