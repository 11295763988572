// File: frontend/src/pages/InvestorPitch/components/slides/PartnershipsSlide/MarketPartnership.js

import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { Clock } from 'lucide-react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from './MarketPartnership.module.css';

const MarketPartnership = ({ market, status, details, timeline, gradient }) => {
  const { t } = useLanguage();

  return (
    <motion.div 
      className={styles.container}
      whileHover={{ scale: 1.02 }}
      transition={{ type: 'spring', stiffness: 300, damping: 30 }}
    >
      <div className={styles.header}>
        <h4 className={styles.market}>{market}</h4>
        <div 
          className={`${styles.status} ${styles[status.toLowerCase().replace('/', '-')]}`}
          aria-label={t('investor_pitch.partnerships.market.status.aria_label', { status })}
        >
          {status}
        </div>
      </div>

      <ul className={styles.details}>
        {details.map((detail, index) => (
          <li key={index}>{detail}</li>
        ))}
      </ul>

      <div className={styles.timeline}>
        <Clock 
          className={styles.timelineIcon}
          aria-hidden="true"
        />
        <span>{timeline}</span>
      </div>
    </motion.div>
  );
};

MarketPartnership.propTypes = {
  market: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  details: PropTypes.arrayOf(PropTypes.string).isRequired,
  timeline: PropTypes.string.isRequired,
  gradient: PropTypes.string.isRequired,
};

export default MarketPartnership;