// File: frontend/src/pages/InvestorPitch/components/slides/SecuritySlide/SecurityFramework.js

import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import * as LucideIcons from 'lucide-react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from './SecurityFramework.module.css';

const SecurityFramework = ({ features }) => {
  const { t } = useLanguage();

  return (
    <div className={styles.container}>
      <div className={styles.features}>
        {features.map((feature, index) => {
          const Icon = LucideIcons[feature.icon];
          return (
            <motion.div 
              key={feature.title} 
              className={styles.feature}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              aria-label={t('investor_pitch.security.framework.feature.aria_label', { 
                title: feature.title 
              }, `Security feature: ${feature.title}`)}
            >
              <div 
                className={styles.header}
                style={{ background: feature.gradient }}
              >
                <div className={styles.iconWrapper}>
                  <Icon className={styles.icon} aria-hidden="true" />
                </div>
                <h4 className={styles.title}>{feature.title}</h4>
                <p className={styles.description}>{feature.description}</p>
              </div>
              <div className={styles.metrics}>
                {feature.metrics.map((metric, mIndex) => (
                  <div 
                    key={mIndex} 
                    className={styles.metric}
                    aria-label={t('investor_pitch.security.framework.metric.aria_label', {
                      label: metric.label,
                      value: metric.value
                    }, `${metric.label}: ${metric.value}`)}
                  >
                    <span className={styles.metricLabel}>{metric.label}</span>
                    <span className={styles.metricValue}>{metric.value}</span>
                  </div>
                ))}
              </div>
            </motion.div>
          );
        })}
      </div>
    </div>
  );
};

SecurityFramework.propTypes = {
  features: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      metrics: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
        })
      ).isRequired,
      gradient: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default SecurityFramework;