// File: frontend/src/pages/PropertyInsight/components/PropertyStatistics/index.js

import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import styles from './PropertyStatistics.module.css';

const PropertyStatistics = () => {
  const { t } = useLanguage();
  const [stats, setStats] = useState({
    totalProperties: 0,
    residentialProperties: 0,
    commercialProperties: 0,
    recentlyAddedProperties: 0
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch statistics data
    const fetchStats = async () => {
      try {
        // This would be replaced with an actual API call
        await new Promise(resolve => setTimeout(resolve, 500));
        
        setStats({
          totalProperties: 165100000,
          residentialProperties: 158000000,
          commercialProperties: 7100000,
          recentlyAddedProperties: 1000000
        });
      } catch (error) {
        console.error('Error fetching statistics:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, []);

  // Format number with thousands separators
  const formatNumber = (num) => {
    return num.toLocaleString();
  };

  return (
    <div className={styles.container}>
      {loading ? (
        <div className={styles.loading}>{t('loading_statistics')}</div>
      ) : (
        <div className={styles.statsGrid}>
          <div className={`${styles.statCard} ${styles.total}`}>
            <div className={styles.statValue}>{formatNumber(stats.totalProperties)}</div>
            <div className={styles.statLabel}>{t('total_properties')}</div>
          </div>
          
          <div className={`${styles.statCard} ${styles.residential}`}>
            <div className={styles.statValue}>{formatNumber(stats.residentialProperties)}</div>
            <div className={styles.statLabel}>{t('residential_properties')}</div>
          </div>
          
          <div className={`${styles.statCard} ${styles.commercial}`}>
            <div className={styles.statValue}>{formatNumber(stats.commercialProperties)}</div>
            <div className={styles.statLabel}>{t('commercial_properties')}</div>
          </div>
          
          <div className={`${styles.statCard} ${styles.recent}`}>
            <div className={styles.statValue}>{formatNumber(stats.recentlyAddedProperties)}</div>
            <div className={styles.statLabel}>{t('recently_added')}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PropertyStatistics;