// File: frontend/src/pages/InvestorPitch/InvestorSeriesA.js

import React from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import PitchDeck from './PitchDeck';
import { getInvestorSeriesASlides } from './configs/InvestorSeriesAConfig';

const InvestorSeriesA = () => {
  const { t } = useLanguage();
  const slides = getInvestorSeriesASlides(t);
  
  return <PitchDeck slides={slides} deckId="investor-series-a" />;
};

export default InvestorSeriesA;