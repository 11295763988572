// File: frontend/src/pages/InvestorPitch/components/slides/ProductEvolutionSlide/FeatureTimeline.js

import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import * as LucideIcons from 'lucide-react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from './FeatureTimeline.module.css';

const FeatureTimeline = ({ features }) => {
  const { t } = useLanguage();

  return (
    <div className={styles.container}>
      {features.map((feature, index) => {
        const Icon = LucideIcons[feature.icon] || LucideIcons.Star;
        return (
          <motion.div 
            key={feature.title} 
            className={styles.feature}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.2 }}
            aria-label={t('investor_pitch.product_evolution.timeline.feature.aria_label', { 
              title: feature.title 
            }, `Feature: ${feature.title}`)}
          >
            <div className={styles.header}>
              <div 
                className={styles.iconWrapper}
                style={{ color: feature.color }}
              >
                <Icon className={styles.icon} aria-hidden="true" />
              </div>
              <h4 className={styles.title}>{feature.title}</h4>
            </div>
            
            <div className={styles.milestones}>
              {feature.milestones.map((milestone) => (
                <div 
                  key={milestone.version} 
                  className={styles.milestone}
                >
                  <div className={styles.version}>{milestone.version}</div>
                  <div className={styles.value}>{milestone.value}</div>
                </div>
              ))}
            </div>
          </motion.div>
        );
      })}
    </div>
  );
};

FeatureTimeline.propTypes = {
  features: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      milestones: PropTypes.arrayOf(
        PropTypes.shape({
          version: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
};

export default FeatureTimeline;