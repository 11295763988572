// File: frontend/src/pages/TripInsight/index.js (updated)

import React, { useState } from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import TripStatistics from './components/TripStatistics';
import TripSearchResults from './components/TripSearchResults';
import TripDistributionChart from './components/TripDistributionChart';
import DrivingBehaviorStatistics from './components/DrivingBehaviorStatistics';
import TripSearchPanel from './components/TripSearchPanel';
import LatestTrips from './components/LatestTrips';
import LatestReports from './components/LatestReports';
import TripMaps from './components/TripMaps';
import styles from './TripInsight.module.css';

const TripInsight = () => {
  const { t } = useLanguage();
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const [lastSearchParams, setLastSearchParams] = useState(null);
  const [hasSearched, setHasSearched] = useState(false);

  const handleSearch = async (searchParams) => {
    setLoading(true);
    setError(null);
    setHasSearched(true);
    
    try {
      // Add pagination parameters
      const params = {
        ...searchParams,
        page: 1,
        limit: 10
      };
      
      setLastSearchParams(params);
      setCurrentPage(1);
      
      // Call the API - this will be replaced with actual API call
      // const response = await tripService.searchTrips(params);
      
      // Mock response for now
      const mockResponse = {
        trips: [
          {
            trip_id: "trip-12345",
            user_id: "user-12345",
            vehicle_id: "vehicle-12345",
            trip_status: "completed",
            trip_start_timestamp: "2023-06-01T08:30:00Z",
            trip_finished_timestamp: "2023-06-01T09:15:00Z",
            distance_travelled_calculation: 25.4,
            trip_duration_seconds: 2700,
            average_speed: 35.2,
            maximum_speed: 65.8,
            overall_score: 85,
            is_night_driving: false,
            is_peak_driving: true,
            is_weekend_driving: false
          },
          {
            trip_id: "trip-12346",
            user_id: "user-12345",
            vehicle_id: "vehicle-12345",
            trip_status: "completed",
            trip_start_timestamp: "2023-06-02T17:30:00Z",
            trip_finished_timestamp: "2023-06-02T18:15:00Z",
            distance_travelled_calculation: 18.7,
            trip_duration_seconds: 2700,
            average_speed: 28.5,
            maximum_speed: 55.3,
            overall_score: 92,
            is_night_driving: false,
            is_peak_driving: true,
            is_weekend_driving: false
          }
        ],
        total: 2
      };
      
      if (mockResponse && mockResponse.trips) {
        setSearchResults(mockResponse.trips);
        setTotalResults(mockResponse.total || mockResponse.trips.length);
        setTotalPages(Math.ceil((mockResponse.total || mockResponse.trips.length) / 10));
      } else {
        setSearchResults([]);
        setTotalResults(0);
        setTotalPages(1);
        setError(t('no_trips_found'));
      }
    } catch (err) {
      console.error('Error searching trips:', err);
      setError(t('failed_to_search_trips'));
      setSearchResults([]);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = async (page) => {
    if (page === currentPage || !lastSearchParams) return;
    
    setLoading(true);
    setError(null);
    
    try {
      // Implementation similar to handleSearch but with updated page
      // This would be replaced with actual API call
      
      // Mock implementation for now
      setCurrentPage(page);
      setLoading(false);
    } catch (err) {
      console.error('Error changing page:', err);
      setError(t('error_loading_page'));
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.pageHeader}>
        <div className={styles.pageTitle}>
          <div className={styles.pageIconArea}>
            <img className={styles.pageIcon} alt="" src="/icons/trip-icon.svg" />
          </div>
          <h1 className={styles.title}>{t('trip_insight')}</h1>
        </div>
      </div>
      
      <div className={styles.searchPanels}>
        <TripSearchPanel onSearch={handleSearch} loading={loading} />
      </div>
      
      {error && <div className={styles.error}>{error}</div>}
      
      {hasSearched && (
        <TripSearchResults 
          results={searchResults} 
          loading={loading}
          onPageChange={handlePageChange}
          currentPage={currentPage}
          totalPages={totalPages}
          totalResults={totalResults}
        />
      )}
      
      <div className={styles.statsSection}>
        <TripStatistics />
        <DrivingBehaviorStatistics />
      </div>
      
      <div className={styles.chartSection}>
        <TripDistributionChart />
      </div>
      
      {/* Add the new TripMaps component */}
      <div className={styles.mapsSection}>
        <TripMaps />
      </div>
      
      <div className={styles.reportsSection}>
        <LatestTrips />
        <LatestReports />
      </div>
    </div>
  );
};

export default TripInsight;