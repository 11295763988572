// File: frontend/src/pages/PropertyInsight/components/PropertySearchResults/index.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../../../contexts/LanguageContext';
import styles from './PropertySearchResults.module.css';

const PropertySearchResults = ({ 
  results, 
  loading, 
  onPageChange, 
  currentPage, 
  totalPages, 
  totalResults 
}) => {
  const { t } = useLanguage();
  const navigate = useNavigate();

  const handleViewProperty = (propertyId) => {
    navigate(`/property-insight/property/${propertyId}`);
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        {t('searching_properties')}
      </div>
    );
  }

  if (!results || results.length === 0) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.resultsHeader}>
        <h2 className={styles.title}>{t('search_results')}</h2>
        {totalResults > 0 && (
          <div className={styles.resultCount}>
            {t('showing_results', { 
              count: results.length, 
              total: totalResults || results.length 
            })}
          </div>
        )}
      </div>
      
      <div className={styles.resultsList}>
        {results.map((property) => (
          <div key={property.property_id} className={styles.resultCard}>
            <div className={styles.resultInfo}>
              <div className={styles.resultRow}>
                <div className={styles.resultField}>
                  <span className={styles.fieldLabel}>{t('property_id')}:</span>
                  <span className={styles.fieldValue}>{property.property_id}</span>
                </div>
                <div className={styles.resultField}>
                  <span className={styles.fieldLabel}>{t('property_type')}:</span>
                  <span className={styles.fieldValue}>{property.property_type || 'N/A'}</span>
                </div>
              </div>
              
              <div className={styles.resultRow}>
                <div className={styles.resultField}>
                  <span className={styles.fieldLabel}>{t('address')}:</span>
                  <span className={styles.fieldValue}>
                    {property.masterAddress?.address_line_1}, {property.masterAddress?.city}, {property.masterAddress?.province_state_name}
                  </span>
                </div>
                <div className={styles.resultField}>
                  <span className={styles.fieldLabel}>{t('postal_code')}:</span>
                  <span className={styles.fieldValue}>{property.masterAddress?.postal_code || 'N/A'}</span>
                </div>
              </div>
              
              <div className={styles.resultRow}>
                <div className={styles.resultField}>
                  <span className={styles.fieldLabel}>{t('year_built')}:</span>
                  <span className={styles.fieldValue}>{property.building?.year_built || 'N/A'}</span>
                </div>
                <div className={styles.resultField}>
                  <span className={styles.fieldLabel}>{t('building_type')}:</span>
                  <span className={styles.fieldValue}>{property.building?.building_type || 'N/A'}</span>
                </div>
              </div>
              
              <div className={styles.resultRow}>
                <div className={styles.resultField}>
                  <span className={styles.fieldLabel}>{t('total_area')}:</span>
                  <span className={styles.fieldValue}>
                    {property.building?.total_area ? `${property.building.total_area} ${property.building.area_measure}` : 'N/A'}
                  </span>
                </div>
                <div className={styles.resultField}>
                  <span className={styles.fieldLabel}>{t('income_property')}:</span>
                  <span className={styles.fieldValue}>
                    {property.is_income_property ? t('yes') : t('no')}
                  </span>
                </div>
              </div>
            </div>
            
            <div className={styles.resultActions}>
              <button 
                className={styles.viewButton}
                onClick={() => handleViewProperty(property.property_id)}
              >
                {t('view_property')}
              </button>
            </div>
          </div>
        ))}
      </div>
      
      {totalPages > 1 && (
        <div className={styles.pagination}>
          <button 
            className={styles.paginationButton}
            disabled={currentPage <= 1}
            onClick={() => onPageChange(currentPage - 1)}
          >
            {t('previous')}
          </button>
          
          <span className={styles.pageInfo}>
            {t('page_of_total', { page: currentPage, total: totalPages })}
          </span>
          
          <button 
            className={styles.paginationButton}
            disabled={currentPage >= totalPages}
            onClick={() => onPageChange(currentPage + 1)}
          >
            {t('next')}
          </button>
        </div>
      )}
    </div>
  );
};

export default PropertySearchResults;