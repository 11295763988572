// File: frontend/src/components/AppStoreButtons/AppStoreButtons.js

import React from 'react';
import styles from './AppStoreButtons.module.css';

const AppStoreButtons = () => {
  return (
    <div className={styles.container}>
      <a 
        href="https://apps.apple.com/app/id1512832027" 
        target="_blank" 
        rel="noopener noreferrer"
        className={styles.button}
      >
        <img 
          src="/download_on_the_app_store/US/Download_on_App_Store/Black_lockup/SVG/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" 
          alt="Download on the App Store" 
          className={styles.badge}
        />
      </a>
      <a 
        href="https://play.google.com/store/apps/details?id=com.allegoryapp" 
        target="_blank" 
        rel="noopener noreferrer"
        className={styles.button}
      >
        <img 
          src="/get_it_on_google_play/Digital/GetItOnGooglePlay_Badge_Web_color_English.png" 
          alt="Get it on Google Play" 
          className={styles.badge}
        />
      </a>
    </div>
  );
};

export default AppStoreButtons;