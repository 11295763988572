// File: frontend/src/pages/QMS/QuoteSearch/index.js

import React, { useState } from 'react';
import { useLanguage } from '../../../contexts/LanguageContext';
import QuoteSearchForm from '../components/QuoteSearchForm';
import QuoteSearchResults from '../components/QuoteSearchResults';
import quoteService from '../../../services/quote';
import styles from './QuoteSearch.module.css';

const QuoteSearch = () => {
  const { t } = useLanguage();
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const [lastSearchParams, setLastSearchParams] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [infoMessage, setInfoMessage] = useState('');

  // Helper function to show messages
  const showMessage = (message, type) => {
    if (type === 'error') {
      setErrorMessage(message);
      setInfoMessage('');
    } else {
      setInfoMessage(message);
      setErrorMessage('');
    }
    
    // Clear messages after 5 seconds
    setTimeout(() => {
      setErrorMessage('');
      setInfoMessage('');
    }, 5000);
  };

  const handleSearch = async (searchParams) => {
    setLoading(true);
    setSearchResults(null);
    setErrorMessage('');
    setInfoMessage('');
    
    try {
      // Add pagination parameters
      const params = {
        ...searchParams,
        page: 1,
        limit: 10
      };
      
      setLastSearchParams(params);
      setCurrentPage(1);
      
      const response = await quoteService.searchQuote(params);
      
      if (response && response.quotes) {
        setSearchResults(response.quotes);
        setTotalResults(response.total || response.quotes.length);
        setTotalPages(Math.ceil((response.total || response.quotes.length) / 10));
        
        if (response.quotes.length === 0) {
          showMessage(t('no_quotes_found'), 'info');
        }
      } else {
        setSearchResults([]);
        setTotalResults(0);
        setTotalPages(1);
        showMessage(t('no_quotes_found'), 'info');
      }
    } catch (error) {
      console.error('Error searching quotes:', error);
      showMessage(t('error_searching_quotes'), 'error');
      setSearchResults([]);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = async (page) => {
    if (page === currentPage || !lastSearchParams) return;
    
    setLoading(true);
    setErrorMessage('');
    setInfoMessage('');
    
    try {
      const params = {
        ...lastSearchParams,
        page,
        limit: 10
      };
      
      const response = await quoteService.searchQuote(params);
      
      if (response && response.quotes) {
        setSearchResults(response.quotes);
        setCurrentPage(page);
      } else {
        showMessage(t('error_loading_page'), 'error');
      }
    } catch (error) {
      console.error('Error changing page:', error);
      showMessage(t('error_loading_page'), 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.pageTitle}>{t('quote_search')}</h1>
      
      {errorMessage && (
        <div className={styles.errorMessage}>
          {errorMessage}
        </div>
      )}
      
      {infoMessage && (
        <div className={styles.infoMessage}>
          {infoMessage}
        </div>
      )}
      
      <QuoteSearchForm onSearch={handleSearch} loading={loading} />
      
      {(loading || searchResults) && (
        <QuoteSearchResults 
          results={searchResults || []} 
          loading={loading}
          onPageChange={handlePageChange}
          currentPage={currentPage}
          totalPages={totalPages}
          totalResults={totalResults}
        />
      )}
    </div>
  );
};

export default QuoteSearch;