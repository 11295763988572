// File: frontend/src/pages/SuperAdmin/components/UserManagement/UserList/index.js

import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import SuperAdminTable from '../../common/SuperAdminTable';
import SuperAdminSearch from '../../common/SuperAdminSearch';
import SuperAdminPagination from '../../common/SuperAdminPagination';
import styles from './UserList.module.css';

const UserList = ({ onViewDetail, onViewVerification }) => {
  const { t } = useLanguage();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterRole, setFilterRole] = useState('all');
  const [filterVerification, setFilterVerification] = useState('all');

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      setError(null);
      
      try {
        // This would be replaced with an actual API call
        // const response = await userService.listUsers({
        //   page: currentPage,
        //   search: searchTerm,
        //   role: filterRole !== 'all' ? filterRole : undefined,
        //   verification: filterVerification !== 'all' ? filterVerification : undefined
        // });
        
        // Mock data for now
        await new Promise(resolve => setTimeout(resolve, 800));
        
        const mockUsers = [
          {
            user_id: 'user-12345',
            enterprise_user_id: 'euser-12345',
            enterprise_id: 'ent-12345',
            email: 'john.smith@acme.com',
            name: 'John Smith',
            user_role: 'admin',
            user_verification_status: 'verified',
            super_admin_verification_status: 'verified',
            organization_name: 'Acme Corporation',
            created_at: '2023-01-16T09:30:00Z'
          },
          {
            user_id: 'user-23456',
            enterprise_user_id: 'euser-23456',
            enterprise_id: 'ent-12345',
            email: 'jane.doe@acme.com',
            name: 'Jane Doe',
            user_role: 'manager',
            user_verification_status: 'verified',
            super_admin_verification_status: 'verified',
            organization_name: 'Acme Corporation',
            created_at: '2023-01-17T11:45:00Z'
          },
          {
            user_id: 'user-34567',
            enterprise_user_id: 'euser-34567',
            enterprise_id: 'ent-12345',
            email: 'bob.johnson@acme.com',
            name: 'Bob Johnson',
            user_role: 'user',
            user_verification_status: 'pending',
            super_admin_verification_status: 'pending',
            organization_name: 'Acme Corporation',
            created_at: '2023-02-05T14:20:00Z'
          },
          {
            user_id: 'user-45678',
            enterprise_user_id: 'euser-45678',
            enterprise_id: 'ent-23456',
            email: 'alice.williams@global.com',
            name: 'Alice Williams',
            user_role: 'admin',
            user_verification_status: 'verified',
            super_admin_verification_status: 'verified',
            organization_name: 'Global Industries',
            created_at: '2023-02-10T10:15:00Z'
          },
          {
            user_id: 'user-56789',
            enterprise_user_id: 'euser-56789',
            enterprise_id: 'ent-23456',
            email: 'charlie.brown@global.com',
            name: 'Charlie Brown',
            user_role: 'user',
            user_verification_status: 'verified',
            super_admin_verification_status: 'pending',
            organization_name: 'Global Industries',
            created_at: '2023-02-15T16:30:00Z'
          }
        ];
        
        // Filter based on search term, role, and verification status
        let filteredUsers = mockUsers;
        
        if (searchTerm) {
          const searchLower = searchTerm.toLowerCase();
          filteredUsers = filteredUsers.filter(user => 
            user.name.toLowerCase().includes(searchLower) ||
            user.email.toLowerCase().includes(searchLower) ||
            user.user_id.toLowerCase().includes(searchLower) ||
            user.organization_name.toLowerCase().includes(searchLower)
          );
        }
        
        if (filterRole !== 'all') {
          filteredUsers = filteredUsers.filter(user => 
            user.user_role === filterRole
          );
        }
        
        if (filterVerification !== 'all') {
          filteredUsers = filteredUsers.filter(user => 
            user.super_admin_verification_status === filterVerification
          );
        }
        
        setUsers(filteredUsers);
        setTotalPages(Math.ceil(filteredUsers.length / 10)); // Assuming 10 items per page
      } catch (err) {
        console.error('Error fetching users:', err);
        setError(t('failed_to_load_users'));
      } finally {
        setLoading(false);
      }
    };
    
    fetchUsers();
  }, [currentPage, searchTerm, filterRole, filterVerification, t]);

  const handleSearch = (term) => {
    setSearchTerm(term);
    setCurrentPage(1);
  };

  const handleRoleFilterChange = (e) => {
    setFilterRole(e.target.value);
    setCurrentPage(1);
  };

  const handleVerificationFilterChange = (e) => {
    setFilterVerification(e.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    }).format(date);
  };

  const getVerificationBadgeClass = (status) => {
    switch (status) {
      case 'verified':
        return styles.statusVerified;
      case 'pending':
        return styles.statusPending;
      case 'rejected':
        return styles.statusRejected;
      default:
        return '';
    }
  };

  const columns = [
    {
      header: t('user_id'),
      accessor: 'user_id',
      cell: (row) => <span className={styles.idCell}>{row.user_id}</span>
    },
    {
      header: t('name'),
      accessor: 'name',
      cell: (row) => <span className={styles.nameCell}>{row.name}</span>
    },
    {
      header: t('email'),
      accessor: 'email',
      cell: (row) => <span>{row.email}</span>
    },
    {
      header: t('organization'),
      accessor: 'organization_name',
      cell: (row) => <span>{row.organization_name}</span>
    },
    {
      header: t('role'),
      accessor: 'user_role',
      cell: (row) => <span className={styles.roleCell}>{row.user_role}</span>
    },
    {
      header: t('verification'),
      accessor: 'super_admin_verification_status',
      cell: (row) => (
        <span className={`${styles.verificationBadge} ${getVerificationBadgeClass(row.super_admin_verification_status)}`}>
          {row.super_admin_verification_status}
        </span>
      )
    },
    {
      header: t('created_at'),
      accessor: 'created_at',
      cell: (row) => <span>{formatDate(row.created_at)}</span>
    },
    {
      header: t('actions'),
      accessor: 'actions',
      cell: (row) => (
        <div className={styles.actions}>
          <button 
            className={styles.actionButton}
            onClick={() => onViewDetail(row.user_id)}
          >
            <i className="fas fa-eye"></i>
            <span>{t('view')}</span>
          </button>
        </div>
      )
    }
  ];

  return (
    <div className={styles.container}>
      <div className={styles.toolbar}>
        <div className={styles.filters}>
          <SuperAdminSearch 
            placeholder={t('search_users')}
            onSearch={handleSearch}
          />
          
          <div className={styles.filterGroup}>
            <label htmlFor="roleFilter" className={styles.filterLabel}>
              {t('role')}:
            </label>
            <select
              id="roleFilter"
              className={styles.filterSelect}
              value={filterRole}
              onChange={handleRoleFilterChange}
            >
              <option value="all">{t('all_roles')}</option>
              <option value="admin">{t('admin')}</option>
              <option value="manager">{t('manager')}</option>
              <option value="user">{t('user')}</option>
            </select>
          </div>
          
          <div className={styles.filterGroup}>
            <label htmlFor="verificationFilter" className={styles.filterLabel}>
              {t('verification')}:
            </label>
            <select
              id="verificationFilter"
              className={styles.filterSelect}
              value={filterVerification}
              onChange={handleVerificationFilterChange}
            >
              <option value="all">{t('all_statuses')}</option>
              <option value="verified">{t('verified')}</option>
              <option value="pending">{t('pending')}</option>
              <option value="rejected">{t('rejected')}</option>
            </select>
          </div>
        </div>
        
        <button className={styles.verificationButton} onClick={onViewVerification}>
          <i className="fas fa-user-check"></i>
          <span>{t('pending_verifications')}</span>
          <span className={styles.pendingCount}>
            {users.filter(user => user.super_admin_verification_status === 'pending').length}
          </span>
        </button>
      </div>
      
      {error && <div className={styles.error}>{error}</div>}
      
      <SuperAdminTable
        columns={columns}
        data={users}
        loading={loading}
        emptyMessage={t('no_users_found')}
      />
      
      <div className={styles.pagination}>
        <SuperAdminPagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default UserList;