// File: frontend/src/pages/Socrates/Socrates.js

import React from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import PageTitle from '../../components/layout/DashboardLayout/PageTitle';
import styles from './Socrates.module.css';

const Socrates = () => {
  const { t } = useLanguage();

  return (
    <div className={styles.SocratesPage}>
      <PageTitle title={t('socrates')}/>
      <div className={styles.pageContent}>
      </div>
    </div>
  );
};

export default Socrates;