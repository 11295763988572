// File: frontend/src/components/form/Label/Label.js

import React from 'react';
import PropTypes from 'prop-types';
import { useLanguage } from '../../../contexts/LanguageContext';
import styles from './Label.module.css';

const Label = ({ textKey, required, optional, icon, htmlFor }) => {
  const { t } = useLanguage();

  return (
    <label className={styles.label} htmlFor={htmlFor}>
      <span className={styles.text}>{t(textKey)}</span>
      {required && <span className={styles.required} aria-hidden="true">*</span>}
      {optional && <span className={styles.optional}>{t('label_optional')}</span>}
      {icon && <img className={styles.icon} src={icon} alt="" aria-hidden="true" />}
    </label>
  );
};

Label.propTypes = {
  textKey: PropTypes.string.isRequired,
  required: PropTypes.bool,
  optional: PropTypes.bool,
  icon: PropTypes.string,
  htmlFor: PropTypes.string,
};

export default Label;