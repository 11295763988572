// File: frontend/src/pages/SuperAdmin/components/CommunicationManagement/SmsTemplates/index.js

import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import SuperAdminTable from '../../common/SuperAdminTable';
import SuperAdminSearch from '../../common/SuperAdminSearch';
import SuperAdminPagination from '../../common/SuperAdminPagination';
import styles from './SmsTemplates.module.css';

const SmsTemplates = ({ templateId, isEditMode, isCreateMode, onViewChange }) => {
  const { t } = useLanguage();
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterCategory, setFilterCategory] = useState('all');
  
  // Form state for create/edit
  const [formData, setFormData] = useState({
    template_name: '',
    template_description: '',
    message_text: '',
    template_category: 'general',
    template_status: 'active',
    variables: []
  });

  useEffect(() => {
    const fetchTemplates = async () => {
      setLoading(true);
      setError(null);
      
      try {
        // This would be replaced with an actual API call
        // const response = await communicationService.listSmsTemplates({
        //   page: currentPage,
        //   search: searchTerm,
        //   category: filterCategory !== 'all' ? filterCategory : undefined
        // });
        
        // Mock data for now
        await new Promise(resolve => setTimeout(resolve, 800));
        
        const mockTemplates = [
          {
            template_id: 'sms-12345',
            template_name: 'Verification Code',
            template_description: 'SMS sent with verification code',
            message_text: 'Your verification code is {{code}}. It expires in {{expiry}} minutes.',
            variables: ['code', 'expiry'],
            template_category: 'authentication',
            template_status: 'active',
            created_at: '2023-01-15T10:00:00Z',
            updated_at: '2023-01-15T10:00:00Z'
          },
          {
            template_id: 'sms-23456',
            template_name: 'Appointment Reminder',
            template_description: 'SMS sent to remind users of upcoming appointments',
            message_text: 'Reminder: You have an appointment scheduled for {{date}} at {{time}}. Reply Y to confirm or N to reschedule.',
            variables: ['date', 'time'],
            template_category: 'reminder',
            template_status: 'active',
            created_at: '2023-01-20T14:30:00Z',
            updated_at: '2023-01-20T14:30:00Z'
          },
          {
            template_id: 'sms-34567',
            template_name: 'Payment Confirmation',
            template_description: 'SMS sent to confirm payment receipt',
            message_text: 'Your payment of {{amount}} has been received. Thank you for your business!',
            variables: ['amount'],
            template_category: 'billing',
            template_status: 'active',
            created_at: '2023-02-05T09:15:00Z',
            updated_at: '2023-02-05T09:15:00Z'
          },
          {
            template_id: 'sms-45678',
            template_name: 'Order Status Update',
            template_description: 'SMS sent to update customers on order status',
            message_text: 'Your order #{{order_number}} has been {{status}}. Track it at {{tracking_link}}',
            variables: ['order_number', 'status', 'tracking_link'],
            template_category: 'notification',
            template_status: 'active',
            created_at: '2023-03-10T11:45:00Z',
            updated_at: '2023-03-10T11:45:00Z'
          },
          {
            template_id: 'sms-56789',
            template_name: 'Account Deactivation',
            template_description: 'SMS sent when an account is deactivated',
            message_text: 'Your account has been deactivated. If you believe this is an error, please contact support.',
            variables: [],
            template_category: 'account',
            template_status: 'inactive',
            created_at: '2023-04-15T16:20:00Z',
            updated_at: '2023-04-15T16:20:00Z'
          }
        ];
        
        // Filter based on search term and category
        let filteredTemplates = mockTemplates;
        
        if (searchTerm) {
          const searchLower = searchTerm.toLowerCase();
          filteredTemplates = filteredTemplates.filter(template => 
            template.template_name.toLowerCase().includes(searchLower) ||
            template.template_description.toLowerCase().includes(searchLower) ||
            template.message_text.toLowerCase().includes(searchLower)
          );
        }
        
        if (filterCategory !== 'all') {
          filteredTemplates = filteredTemplates.filter(template => 
            template.template_category === filterCategory
          );
        }
        
        setTemplates(filteredTemplates);
        setTotalPages(Math.ceil(filteredTemplates.length / 10)); // Assuming 10 items per page
        
        // If we have a templateId, find the selected template
        if (templateId) {
          const template = filteredTemplates.find(t => t.template_id === templateId);
          if (template) {
            setSelectedTemplate(template);
            
            // If in edit mode, initialize form data
            if (isEditMode) {
              setFormData({
                template_name: template.template_name,
                template_description: template.template_description,
                message_text: template.message_text,
                template_category: template.template_category,
                template_status: template.template_status,
                variables: template.variables
              });
            }
          } else {
            setError(t('template_not_found'));
          }
        }
        
        // If in create mode, initialize empty form
        if (isCreateMode) {
          setFormData({
            template_name: '',
            template_description: '',
            message_text: '',
            template_category: 'general',
            template_status: 'active',
            variables: []
          });
        }
      } catch (err) {
        console.error('Error fetching SMS templates:', err);
        setError(t('failed_to_load_sms_templates'));
      } finally {
        setLoading(false);
      }
    };
    
    fetchTemplates();
  }, [templateId, isEditMode, isCreateMode, currentPage, searchTerm, filterCategory, t]);

  const handleSearch = (term) => {
    setSearchTerm(term);
    setCurrentPage(1);
  };

  const handleCategoryFilterChange = (e) => {
    setFilterCategory(e.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    }).format(date);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleVariableChange = (e) => {
    const variableText = e.target.value;
    const variables = variableText.split(',')
      .map(v => v.trim())
      .filter(v => v !== '');
    
    setFormData(prev => ({
      ...prev,
      variables
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    try {
      if (isEditMode) {
        // This would be replaced with an actual API call
        // await communicationService.updateSmsTemplate(templateId, formData);
        
        // Mock update for now
        await new Promise(resolve => setTimeout(resolve, 800));
        
        // Return to detail view
        onViewChange('sms', templateId);
      } else {
        // This would be replaced with an actual API call
        // const response = await communicationService.createSmsTemplate(formData);
        
        // Mock response for now
        await new Promise(resolve => setTimeout(resolve, 800));
        const newTemplateId = `sms-${Math.random().toString(36).substring(2, 10)}`;
        
        // Return to list view
        onViewChange('sms');
      }
    } catch (err) {
      console.error('Error saving SMS template:', err);
      setError(t('failed_to_save_sms_template'));
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    if (!window.confirm(t('confirm_delete_template'))) return;
    
    setLoading(true);
    
    try {
      // This would be replaced with an actual API call
      // await communicationService.deleteSmsTemplate(templateId);
      
      // Mock deletion for now
      await new Promise(resolve => setTimeout(resolve, 800));
      
      // Return to list view
      onViewChange('sms');
    } catch (err) {
      console.error('Error deleting SMS template:', err);
      setError(t('failed_to_delete_template'));
    } finally {
      setLoading(false);
    }
  };

  const getStatusBadgeClass = (status) => {
    switch (status) {
      case 'active':
        return styles.statusActive;
      case 'inactive':
        return styles.statusInactive;
      default:
        return '';
    }
  };

  const getCategoryBadgeClass = (category) => {
    switch (category) {
      case 'authentication':
        return styles.categoryAuthentication;
      case 'reminder':
        return styles.categoryReminder;
      case 'billing':
        return styles.categoryBilling;
      case 'notification':
        return styles.categoryNotification;
      case 'account':
        return styles.categoryAccount;
      case 'general':
      default:
        return styles.categoryGeneral;
    }
  };

  const columns = [
    {
      header: t('template_name'),
      accessor: 'template_name',
      cell: (row) => <span className={styles.nameCell}>{row.template_name}</span>
    },
    {
      header: t('message'),
      accessor: 'message_text',
      cell: (row) => <span className={styles.messageCell}>{row.message_text}</span>
    },
    {
      header: t('category'),
      accessor: 'template_category',
      cell: (row) => (
        <span className={`${styles.categoryBadge} ${getCategoryBadgeClass(row.template_category)}`}>
          {row.template_category}
        </span>
      )
    },
    {
      header: t('status'),
      accessor: 'template_status',
      cell: (row) => (
        <span className={`${styles.statusBadge} ${getStatusBadgeClass(row.template_status)}`}>
          {row.template_status}
        </span>
      )
    },
    {
      header: t('updated_at'),
      accessor: 'updated_at',
      cell: (row) => <span>{formatDate(row.updated_at)}</span>
    },
    {
      header: t('actions'),
      accessor: 'actions',
      cell: (row) => (
        <div className={styles.actions}>
          <button 
            className={styles.actionButton}
            onClick={() => onViewChange('sms', row.template_id)}
          >
            <i className="fas fa-eye"></i>
            <span>{t('view')}</span>
          </button>
          <button 
            className={styles.actionButton}
            onClick={() => onViewChange('sms', row.template_id, 'edit')}
          >
            <i className="fas fa-edit"></i>
            <span>{t('edit')}</span>
          </button>
        </div>
      )
    }
  ];

  // Render template detail view
  if (templateId && !isEditMode && !isCreateMode && selectedTemplate) {
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <button 
            className={styles.backButton} 
            onClick={() => onViewChange('sms')}
          >
            <i className="fas fa-arrow-left"></i>
            <span>{t('back')}</span>
          </button>
          
          <div className={styles.headerActions}>
            <button 
              className={styles.editButton}
              onClick={() => onViewChange('sms', templateId, 'edit')}
            >
              <i className="fas fa-edit"></i>
              <span>{t('edit')}</span>
            </button>
            <button 
              className={styles.deleteButton}
              onClick={handleDelete}
            >
              <i className="fas fa-trash-alt"></i>
              <span>{t('delete')}</span>
            </button>
          </div>
        </div>
        
        {error && (
          <div className={styles.error}>
            <i className="fas fa-exclamation-circle"></i>
            <span>{error}</span>
          </div>
        )}
        
        <div className={styles.templateDetail}>
          <div className={styles.templateHeader}>
            <h2 className={styles.templateName}>{selectedTemplate.template_name}</h2>
            <div className={styles.templateMeta}>
              <span className={`${styles.categoryBadge} ${getCategoryBadgeClass(selectedTemplate.template_category)}`}>
                {selectedTemplate.template_category}
              </span>
              <span className={`${styles.statusBadge} ${getStatusBadgeClass(selectedTemplate.template_status)}`}>
                {selectedTemplate.template_status}
              </span>
            </div>
          </div>
          
          <div className={styles.templateDescription}>
            {selectedTemplate.template_description}
          </div>
          
          <div className={styles.section}>
            <h3 className={styles.sectionTitle}>{t('message_text')}</h3>
            <div className={styles.messagePreview}>
              {selectedTemplate.message_text}
            </div>
            <div className={styles.messageInfo}>
              <div className={styles.characterCount}>
                {selectedTemplate.message_text.length} {t('characters')}
              </div>
              <div className={styles.segmentCount}>
                {Math.ceil(selectedTemplate.message_text.length / 160)} {t('segments')}
              </div>
            </div>
          </div>
          
          <div className={styles.section}>
            <h3 className={styles.sectionTitle}>{t('template_variables')}</h3>
            {selectedTemplate.variables && selectedTemplate.variables.length > 0 ? (
              <div className={styles.variablesList}>
                {selectedTemplate.variables.map((variable) => (
                  <span key={variable} className={styles.variableBadge}>
                    {`{{${variable}}}`}
                  </span>
                ))}
              </div>
            ) : (
              <div className={styles.noVariables}>
                {t('no_variables_defined')}
              </div>
            )}
          </div>
          
          <div className={styles.section}>
            <h3 className={styles.sectionTitle}>{t('template_information')}</h3>
            <div className={styles.infoGrid}>
              <div className={styles.infoItem}>
                <div className={styles.infoLabel}>{t('template_id')}</div>
                <div className={styles.infoValue}>{selectedTemplate.template_id}</div>
              </div>
              <div className={styles.infoItem}>
                <div className={styles.infoLabel}>{t('created_at')}</div>
                <div className={styles.infoValue}>{formatDate(selectedTemplate.created_at)}</div>
              </div>
              <div className={styles.infoItem}>
                <div className={styles.infoLabel}>{t('updated_at')}</div>
                <div className={styles.infoValue}>{formatDate(selectedTemplate.updated_at)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Render create/edit form
  if (isEditMode || isCreateMode) {
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <button 
            className={styles.backButton} 
            onClick={() => isEditMode ? onViewChange('sms', templateId) : onViewChange('sms')}
            disabled={loading}
          >
            <i className="fas fa-arrow-left"></i>
            <span>{t('back')}</span>
          </button>
        </div>
        
        {error && (
          <div className={styles.error}>
            <i className="fas fa-exclamation-circle"></i>
            <span>{error}</span>
          </div>
        )}
        
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.formSection}>
            <h3 className={styles.sectionTitle}>{t('template_details')}</h3>
            <div className={styles.formGrid}>
              <div className={styles.formGroup}>
                <label htmlFor="template_name" className={styles.label}>
                  {t('template_name')} <span className={styles.required}>*</span>
                </label>
                <input
                  type="text"
                  id="template_name"
                  name="template_name"
                  className={styles.input}
                  value={formData.template_name}
                  onChange={handleChange}
                  required
                  disabled={loading}
                />
              </div>
              
              <div className={styles.formGroup}>
                <label htmlFor="template_category" className={styles.label}>
                  {t('category')} <span className={styles.required}>*</span>
                </label>
                <select
                  id="template_category"
                  name="template_category"
                  className={styles.select}
                  value={formData.template_category}
                  onChange={handleChange}
                  required
                  disabled={loading}
                >
                  <option value="general">{t('general')}</option>
                  <option value="authentication">{t('authentication')}</option>
                  <option value="reminder">{t('reminder')}</option>
                  <option value="billing">{t('billing')}</option>
                  <option value="notification">{t('notification')}</option>
                  <option value="account">{t('account')}</option>
                </select>
              </div>
              
              <div className={styles.formGroup}>
                <label htmlFor="template_status" className={styles.label}>
                  {t('status')} <span className={styles.required}>*</span>
                </label>
                <select
                  id="template_status"
                  name="template_status"
                  className={styles.select}
                  value={formData.template_status}
                  onChange={handleChange}
                  required
                  disabled={loading}
                >
                  <option value="active">{t('active')}</option>
                  <option value="inactive">{t('inactive')}</option>
                </select>
              </div>
              
              <div className={styles.formGroupFull}>
                <label htmlFor="template_description" className={styles.label}>
                  {t('description')} <span className={styles.required}>*</span>
                </label>
                <textarea
                  id="template_description"
                  name="template_description"
                  className={styles.textarea}
                  value={formData.template_description}
                  onChange={handleChange}
                  rows={2}
                  required
                  disabled={loading}
                ></textarea>
              </div>
            </div>
          </div>
          
          <div className={styles.formSection}>
            <h3 className={styles.sectionTitle}>{t('message_content')}</h3>
            <div className={styles.formGrid}>
              <div className={styles.formGroupFull}>
                <label htmlFor="message_text" className={styles.label}>
                  {t('message_text')} <span className={styles.required}>*</span>
                </label>
                <textarea
                  id="message_text"
                  name="message_text"
                  className={styles.textarea}
                  value={formData.message_text}
                  onChange={handleChange}
                  rows={5}
                  required
                  maxLength={1600}
                  disabled={loading}
                ></textarea>
                <div className={styles.messageInfo}>
                  <div className={styles.characterCount}>
                    {formData.message_text.length} / 1600 {t('characters')}
                  </div>
                  <div className={styles.segmentCount}>
                    {Math.ceil(formData.message_text.length / 160)} {t('segments')}
                  </div>
                </div>
              </div>
              
              <div className={styles.formGroupFull}>
                <label htmlFor="variables" className={styles.label}>
                  {t('template_variables')}
                </label>
                <input
                  type="text"
                  id="variables"
                  className={styles.input}
                  value={formData.variables.join(', ')}
                  onChange={handleVariableChange}
                  placeholder="code, expiry, etc."
                  disabled={loading}
                />
                <div className={styles.helpText}>
                  {t('variables_help_text')}
                </div>
              </div>
            </div>
          </div>
          
          <div className={styles.formActions}>
            <button 
              type="button" 
              className={styles.cancelButton} 
              onClick={() => isEditMode ? onViewChange('sms', templateId) : onViewChange('sms')}
              disabled={loading}
            >
              {t('cancel')}
            </button>
            <button 
              type="submit" 
              className={styles.submitButton}
              disabled={loading}
            >
              {loading ? (
                <>
                  <span className={styles.spinner}></span>
                  <span>{isEditMode ? t('updating') : t('creating')}</span>
                </>
              ) : (
                isEditMode ? t('update_template') : t('create_template')
              )}
            </button>
          </div>
        </form>
      </div>
    );
  }

  // Render template list view
  return (
    <div className={styles.container}>
      <div className={styles.toolbar}>
        <div className={styles.filters}>
          <SuperAdminSearch 
            placeholder={t('search_sms_templates')}
            onSearch={handleSearch}
          />
          
          <div className={styles.filterGroup}>
            <label htmlFor="categoryFilter" className={styles.filterLabel}>
              {t('category')}:
            </label>
            <select
              id="categoryFilter"
              className={styles.filterSelect}
              value={filterCategory}
              onChange={handleCategoryFilterChange}
            >
              <option value="all">{t('all_categories')}</option>
              <option value="general">{t('general')}</option>
              <option value="authentication">{t('authentication')}</option>
              <option value="reminder">{t('reminder')}</option>
              <option value="billing">{t('billing')}</option>
              <option value="notification">{t('notification')}</option>
              <option value="account">{t('account')}</option>
            </select>
          </div>
        </div>
        
        <button 
          className={styles.createButton}
          onClick={() => onViewChange('sms', null, 'create')}
        >
          <i className="fas fa-plus"></i>
          <span>{t('create_template')}</span>
        </button>
      </div>
      
      {error && <div className={styles.error}>{error}</div>}
      
      <SuperAdminTable
        columns={columns}
        data={templates}
        loading={loading}
        emptyMessage={t('no_sms_templates_found')}
      />
      
      <div className={styles.pagination}>
        <SuperAdminPagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default SmsTemplates;