// File: frontend/src/pages/TripDetail/components/TripLocation/index.js

import React from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import styles from './TripLocation.module.css';

const TripLocation = ({ trip }) => {
  const { t } = useLanguage();

  // Helper function to format address
  const formatAddress = (address) => {
    if (!address) return 'N/A';
    
    const parts = [];
    if (address.street) parts.push(address.street);
    if (address.city) parts.push(address.city);
    if (address.state) parts.push(address.state);
    if (address.postal_code) parts.push(address.postal_code);
    if (address.country) parts.push(address.country);
    
    return parts.join(', ');
  };

  // Get start and end locations
  const startLocation = trip.trip_started_location || 
    (trip.filtered_locations_aggs?.trip_start_coordinates || null);
  
  const endLocation = trip.trip_finished_location || 
    (trip.filtered_locations_aggs?.trip_finished_coordinates || null);
  
  const startAddress = trip.filtered_locations_aggs?.trip_start_address || null;
  const endAddress = trip.filtered_locations_aggs?.trip_finished_address || null;

  return (
    <div className={styles.container}>
      <div className={styles.locationsGrid}>
        <div className={styles.locationCard}>
          <div className={styles.locationHeader}>
            <div className={styles.locationIcon}>
              <div className={styles.startIcon}></div>
            </div>
            <div className={styles.locationTitle}>{t('start_location')}</div>
          </div>
          
          <div className={styles.locationContent}>
            <div className={styles.addressSection}>
              <div className={styles.sectionTitle}>{t('address')}</div>
              <div className={styles.addressText}>
                {formatAddress(startAddress)}
              </div>
            </div>
            
            <div className={styles.coordinatesSection}>
              <div className={styles.sectionTitle}>{t('coordinates')}</div>
              <div className={styles.coordinatesText}>
                {startLocation ? 
                  `${startLocation.latitude.toFixed(6)}, ${startLocation.longitude.toFixed(6)}` : 
                  'N/A'}
              </div>
            </div>
            
            <div className={styles.placeIdSection}>
              <div className={styles.sectionTitle}>{t('place_id')}</div>
              <div className={styles.placeIdText}>
                {trip.trip_started_place_id || 'N/A'}
              </div>
            </div>
          </div>
        </div>
        
        <div className={styles.locationCard}>
          <div className={styles.locationHeader}>
            <div className={styles.locationIcon}>
              <div className={styles.endIcon}></div>
            </div>
            <div className={styles.locationTitle}>{t('end_location')}</div>
          </div>
          
          <div className={styles.locationContent}>
            <div className={styles.addressSection}>
              <div className={styles.sectionTitle}>{t('address')}</div>
              <div className={styles.addressText}>
                {formatAddress(endAddress)}
              </div>
            </div>
            
            <div className={styles.coordinatesSection}>
              <div className={styles.sectionTitle}>{t('coordinates')}</div>
              <div className={styles.coordinatesText}>
                {endLocation ? 
                  `${endLocation.latitude.toFixed(6)}, ${endLocation.longitude.toFixed(6)}` : 
                  'N/A'}
              </div>
            </div>
            
            <div className={styles.placeIdSection}>
              <div className={styles.sectionTitle}>{t('place_id')}</div>
              <div className={styles.placeIdText}>
                {trip.trip_finished_place_id || 'N/A'}
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className={styles.mapContainer}>
        <div className={styles.mapPlaceholder}>
          {/* This would be replaced by an actual map showing both locations */}
          <div className={styles.mockMap}>
            <div className={styles.mockRoute}>
              <div className={styles.mockStartPoint}></div>
              <div className={styles.mockEndPoint}></div>
            </div>
            <div className={styles.mockMapText}>
              {t('location_map_would_be_displayed_here')}
            </div>
          </div>
        </div>
      </div>
      
      {trip.filtered_locations_aggs?.trip_midpoint_coordinates && (
        <div className={styles.midpointCard}>
          <div className={styles.midpointHeader}>
            <div className={styles.midpointIcon}></div>
            <div className={styles.midpointTitle}>{t('trip_midpoint')}</div>
          </div>
          
          <div className={styles.midpointContent}>
            <div className={styles.midpointCoordinates}>
              {`${trip.filtered_locations_aggs.trip_midpoint_coordinates.latitude.toFixed(6)}, ${trip.filtered_locations_aggs.trip_midpoint_coordinates.longitude.toFixed(6)}`}
            </div>
          </div>
        </div>
      )}
      
      <div className={styles.distanceCard}>
        <div className={styles.distanceHeader}>
          <div className={styles.distanceTitle}>{t('distance_information')}</div>
        </div>
        
        <div className={styles.distanceContent}>
          <div className={styles.distanceItem}>
            <div className={styles.distanceLabel}>{t('distance_odometer')}:</div>
            <div className={styles.distanceValue}>{trip.distance_travelled_odometer} km</div>
          </div>
          
          <div className={styles.distanceItem}>
            <div className={styles.distanceLabel}>{t('distance_calculated')}:</div>
            <div className={styles.distanceValue}>{trip.distance_travelled_calculation} km</div>
          </div>
          
          <div className={styles.distanceItem}>
            <div className={styles.distanceLabel}>{t('missing_distance')}:</div>
            <div className={styles.distanceValue}>{trip.missing_distance} km</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TripLocation;