// File: frontend/src/components/Home/MigrationSection.js

import React, { useState, useRef, useEffect, lazy, Suspense } from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import LandingButton from '../Button/LandingButton';
import styles from './MigrationSection.module.css';

const MigrationAnimation = lazy(() => 
  import('./MigrationAnimation').then(module => {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(module);
      }, 100);
    });
  })
);

const MigrationSection = () => {
  const { t } = useLanguage();
  const [startMigration, setStartMigration] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isIntersecting, setIsIntersecting] = useState(false);
  const sectionRef = useRef(null);

  const handleMigrateClick = () => {
    setStartMigration(true);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        const visibleRatio = entry.intersectionRatio;
        
        setIsIntersecting(entry.isIntersecting);
        setIsVisible(visibleRatio >= 0.2);
      },
      { 
        threshold: [0, 0.2, 0.4, 0.6, 0.8, 1.0],
        rootMargin: '50px 0px'
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <section 
      ref={sectionRef} 
      className={styles.migrationSection}
      aria-label={t('migration_section_aria_label')}
    >
      <div className={styles.container}>
        <div className={styles.content}>
          <h2 className={styles.title}>{t('migration_title')}</h2>
          <p className={styles.description}>{t('migration_description')}</p>
          <LandingButton 
            onClick={handleMigrateClick} 
            disabled={startMigration} 
            className={styles.ctaButton}
            aria-label={startMigration ? t('migrating') : t('click_to_see_migration')}
          >
            {startMigration ? t('migrating') : t('click_to_see_migration')}
          </LandingButton>
        </div>
        <div className={styles.animationWrapper}>
          <Suspense 
            fallback={
              <div 
                className={styles.animationPlaceholder}
                aria-hidden="true"
              >
                <div className={styles.loadingIndicator}>
                  {t('loading_animation')}
                </div>
              </div>
            }
          >
            {isIntersecting && (
              <MigrationAnimation 
                startMigration={startMigration}
                isVisible={isVisible}
              />
            )}
          </Suspense>
        </div>
      </div>
    </section>
  );
};

export default MigrationSection;