// File: frontend/src/pages/InvestorPitch/components/slides/InvestmentOpportunitySlide/InvestmentOpportunitySlide.js

import React from 'react';
import { motion } from 'framer-motion';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import { ANIMATION_VARIANTS } from '../../../constants/animations';
import styles from './InvestmentOpportunitySlide.module.css';

const InvestmentOpportunitySlide = () => {
  const { t } = useLanguage();

  /**
   * Series A bullet points:
   * - If an item has both "label" and "value", we show them distinctly.
   * - If only "label" is given, we display it as a single line.
   */
  const seriesAItems = [
    { 
      label: t('investor_pitch.investment_opportunity.amount'), 
      value: '$250 M USD',
      highlight: true
    },
    { 
      label: t('investor_pitch.investment_opportunity.valuation'), 
      value: '$1,253 M USD',
      highlight: true
    },
    { 
      label: t('investor_pitch.investment_opportunity.return', 'Expected Return Timeline'), 
      value: 'Regulatory Approvals + 24 Months' 
    },
    { 
      label: t('investor_pitch.investment_opportunity.gwp', 'Gross Written Premium: $3.2B by Year 2'),
      singleLine: true
    },
    { 
      label: t('investor_pitch.investment_opportunity.opex', 'Operating Expenses: 15% of GWP'),
      singleLine: true
    },
    { 
      label: t('investor_pitch.investment_opportunity.loss_ratio', 'Target Loss Ratio: 60%'),
      singleLine: true
    },
    { 
      label: t('investor_pitch.investment_opportunity.profitability', 'Profitability: Year 3'),
      singleLine: true
    },
    { 
      label: t('investor_pitch.investment_opportunity.investment_income', 'Investment Income: 10% on Float'),
      singleLine: true
    },
    { 
      label: t('investor_pitch.investment_opportunity.target_mcat', 'Target M-CAT Ratio: 3.5x'),
      singleLine: true
    },
    { 
      label: t('investor_pitch.investment_opportunity.valuation_future', 'Year 5 Valuation: $25B+'),
      singleLine: true,
      highlight: true
    },
    { 
      label: t('investor_pitch.investment_opportunity.operating_countries', 'Operating in 10+ Countries by Year 3'),
      singleLine: true
    },
    { 
      label: t('investor_pitch.investment_opportunity.board_structure', '2 Board Seats for Series A Investors'),
      singleLine: true
    }
  ];

  /**
   * Funding Allocation in a 2x2 grid, each with label + amount
   */
  const allocations = [
    { 
      label: t('investor_pitch.investment_opportunity.reserves'), 
      amount: '$100 M',
      percentage: '40%'
    },
    { 
      label: t('investor_pitch.investment_opportunity.computing'), 
      amount: '$50 M',
      percentage: '20%'
    },
    { 
      label: t('investor_pitch.investment_opportunity.marketing'), 
      amount: '$50 M',
      percentage: '20%'
    },
    { 
      label: t('investor_pitch.investment_opportunity.salaries'), 
      amount: '$40 M',
      percentage: '16%'
    },
    { 
      label: t('investor_pitch.investment_opportunity.legal'), 
      amount: '$10 M',
      percentage: '4%'
    },
  ];

  return (
    <motion.div
      className={styles.container}
      variants={ANIMATION_VARIANTS.stagger.container}
      initial="hidden"
      animate="visible"
    >
      {/* Main Header */}
      <motion.div
        className={styles.header}
        variants={ANIMATION_VARIANTS.fadeIn}
      >
        <h2 className={styles.mainTitle}>
          {t(
            'investor_pitch.investment_opportunity.title',
            'Once in a Lifetime Investment Opportunity'
          )}
        </h2>
        <p className={styles.subtitle}>
          {t(
            'investor_pitch.investment_opportunity.subtitle',
            `We seek to work with high-impact, world-class investors to build the world's first $1 trillion insurance company. Our vision is clear, our path is set, and now we're making it a reality.`
          )}
        </p>
      </motion.div>

      {/* Two columns: Left for Series A, Right for Funding Allocation */}
      <motion.div
        className={styles.gridContainer}
        variants={ANIMATION_VARIANTS.stagger.container}
      >
        {/* Left Column: Series A */}
        <motion.div
          className={styles.seriesAColumn}
          variants={ANIMATION_VARIANTS.fadeIn}
        >
          <h3 className={styles.columnTitle}>
            {t('investor_pitch.investment_opportunity.series_a_equity_round', 'Series A Equity Round')}
          </h3>
          <div className={styles.seriesACard}>
            {seriesAItems.map((item, idx) => {
              // If it's a single line item
              if (item.singleLine) {
                return (
                  <div 
                    key={idx} 
                    className={`${styles.seriesAItem} ${styles.singleLine} ${item.highlight ? styles.highlight : ''}`}
                  >
                    {item.label}
                  </div>
                );
              } else {
                // If there's a separate value, we display label & value distinctly
                return (
                  <div 
                    key={idx} 
                    className={`${styles.seriesAItem} ${item.highlight ? styles.highlight : ''}`}
                  >
                    <div className={styles.seriesALabel}>{item.label}</div>
                    <div className={styles.seriesAValue}>{item.value}</div>
                  </div>
                );
              }
            })}
          </div>
        </motion.div>

        {/* Right Column: Funding Allocation in 2x2 */}
        <motion.div
          className={styles.allocationColumn}
          variants={ANIMATION_VARIANTS.fadeIn}
        >
          <h3 className={styles.columnTitle}>
            {t('investor_pitch.investment_opportunity.funding_allocation_title', 'Funding Allocation')}
          </h3>
          <div className={styles.allocationGrid}>
            {allocations.map((item, idx) => (
              <motion.div 
                key={idx} 
                className={styles.allocationCard}
                variants={ANIMATION_VARIANTS.stagger.item}
                custom={idx}
              >
                <div className={styles.allocationLabel}>{item.label}</div>
                <div className={styles.allocationAmount}>{item.amount}</div>
                <div className={styles.allocationPercentage}>{item.percentage}</div>
              </motion.div>
            ))}
          </div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default InvestmentOpportunitySlide;