// src/components/common/CustomTable.js
import React, { useState } from 'react';
import styles from './CustomTable.module.css';

const CustomTable = ({ columns, data, itemsPerPage = 5, title }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');

  // Filter data based on search term
  const filteredData = data.filter(item =>
    Object.values(item).some(value =>
      value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  // Calculate pagination
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = filteredData.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className={styles.tableContainer}>
      <div className={styles.cardStandartTitleRow}>
        <div className={styles.text}>{title}</div>
        <div className={styles.searchInputParent}>
          <div className={styles.searchInput}>
            <div className={styles.searchCustomersInsightsOrWrapper}>
              <input
                type="text"
                placeholder="Search customers, insights, or support articles"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className={styles.searchCustomersInsights}
              />
            </div>
            <div className={styles.searchBtn}>
              <button className={styles.button}>
                <img className={styles.icons18miscsearch} alt="" src="/icons/18/misc/search-right.svg" />
              </button>
            </div>
          </div>
          <button className={styles.button1}>
            <img className={styles.icons18miscshowMoreHorizo} alt="" src="/icons/18/misc/show-more-horizontal-three-dots.svg" />
          </button>
        </div>
      </div>
      <div className={styles.cardStandartContent}>
        <div className={styles.tableBasic}>
          <div className={styles.tableColumnTitleCellParent}>
            {columns.map((column, index) => (
              <div key={index} className={styles.tableColumnTitleCell}>
                <div className={styles.columnTitle}>{column.title}</div>
                <img className={styles.icons18miscsort} alt="" src="/icons/18/misc/sort.svg" />
              </div>
            ))}
          </div>
          {currentData.map((item, rowIndex) => (
            <div key={rowIndex} className={styles.tableRowCellParent}>
              {columns.map((column, cellIndex) => (
                <div key={cellIndex} className={styles.tableRowCell}>
                  {column.render ? column.render(item[column.dataIndex], item) : item[column.dataIndex]}
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className={styles.paginationGroup}>
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className={styles.pagination}
          >
            <img className={styles.icons18miscchevronLeft} alt="" src="/icons/18/misc/chevron-left.svg" />
          </button>
          <div className={styles.paginationParent}>
            {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
              <button
                key={page}
                onClick={() => handlePageChange(page)}
                className={`${styles.pagination} ${currentPage === page ? styles.pagination1 : styles.pagination2}`}
              >
                <div className={styles.div5}>{page}</div>
              </button>
            ))}
          </div>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className={styles.pagination}
          >
            <img className={styles.icons18miscchevronLeft} alt="" src="/icons/18/misc/chevron-right.svg" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomTable;