// /charts/v2/utils/formatters.js
export const formatCurrency = (value) => {
  if (value >= 1000000) {
    const millions = value / 1000000;
    return `$${millions < 10 ? millions.toFixed(1) : Math.round(millions)}M`;
  } else if (value >= 1000) {
    const thousands = value / 1000;
    return `$${thousands < 10 ? thousands.toFixed(1) : Math.round(thousands)}k`;
  }
  return `$${Math.round(value)}`;
};

export const formatValue = (value, format) => {
  switch (format) {
    case 'currency':
      return formatCurrency(value);
    case 'percentage':
      return `${value}%`;
    case 'number':
      return value.toLocaleString();
    default:
      return value.toLocaleString();
  }
};