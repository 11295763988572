import React, { createContext, useState, useContext, useMemo, useCallback } from 'react';
import { ENUMS, VALIDATION } from '../config/constants';
import { AUTH_FLOW_STATES } from './AuthContextV2';

const SignUpContext = createContext();

const initialFormData = {
  // Step 1: Initial User Data
  email_address: '',
  first_name: '',
  last_name: '',
  country_phone_code: '+1',
  area_code: '',
  phone_number: '',
  
  // Step 2: Verification (handled by AuthContext)
  // verification_code: '',
  
  // Step 3: Organization Details
  organization_name: '',
  organization_size_id: '',
  dataset_region_id: ''
};

export const SignUpProvider = ({ children }) => {
  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({});
  const [currentStep, setCurrentStep] = useState(AUTH_FLOW_STATES.SIGNUP.INITIAL);

  // Update form data while preserving previous data
  const updateFormData = useCallback((newData) => {
    setFormData(prevData => {
      const updated = { ...prevData };
      Object.entries(newData).forEach(([key, value]) => {
        if (key.includes('.')) {
          const [parent, child] = key.split('.');
          updated[parent] = { ...updated[parent], [child]: value };
        } else {
          updated[key] = value;
        }
      });
      return updated;
    });
    validateFormData(newData, currentStep);
  }, [currentStep]);

  // Update current step
  const updateStep = useCallback((newStep) => {
    setCurrentStep(newStep);
    // Revalidate form data for new step
    validateFormData(formData, newStep);
  }, [formData]);

  const validateFormData = useCallback((data = formData, step = currentStep) => {
    const newErrors = {};

    const validateStep = (stepData) => {
      switch (step) {
        case AUTH_FLOW_STATES.SIGNUP.INITIAL:
          // Initial step validation
          if (!stepData.email_address?.trim()) {
            newErrors.email_address = 'Email is required';
          } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(stepData.email_address)) {
            newErrors.email_address = 'Invalid email format';
          }

          if (!stepData.first_name?.trim()) newErrors.first_name = 'First name is required';
          if (!stepData.last_name?.trim()) newErrors.last_name = 'Last name is required';

          // Phone validation
          if (!stepData.country_phone_code) {
            newErrors.country_phone_code = 'Country code is required';
          } else if (!VALIDATION.PHONE.COUNTRY_CODE_REGEX.test(stepData.country_phone_code)) {
            newErrors.country_phone_code = 'Invalid country code';
          }

          if (!stepData.area_code) {
            newErrors.area_code = 'Area code is required';
          } else if (!VALIDATION.PHONE.AREA_CODE_REGEX.test(stepData.area_code)) {
            newErrors.area_code = 'Invalid area code';
          }

          if (!stepData.phone_number) {
            newErrors.phone_number = 'Phone number is required';
          } else if (!VALIDATION.PHONE.NUMBER_REGEX.test(stepData.phone_number)) {
            newErrors.phone_number = 'Invalid phone number';
          }
          break;

        case AUTH_FLOW_STATES.SIGNUP.COMPLETE_SIGNUP:
          // Organization validation
          if (!stepData.organization_name?.trim()) {
            newErrors.organization_name = 'Organization name is required';
          }

          if (!stepData.organization_size_id) {
            newErrors.organization_size_id = 'Number of employees is required';
          } else if (!Object.values(ENUMS.ORGANIZATION_SIZE).includes(Number(stepData.organization_size_id))) {
            newErrors.organization_size_id = 'Invalid employee count selection';
          }

          if (!stepData.dataset_region_id) {
            newErrors.dataset_region_id = 'Dataset region is required';
          } else if (!Object.values(ENUMS.DATASET_REGION).includes(Number(stepData.dataset_region_id))) {
            newErrors.dataset_region_id = 'Invalid dataset region';
          }
          break;

        default:
          break;
      }
    };

    validateStep(data);
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [currentStep]);

  const isValid = useMemo(() => {
    return validateFormData(formData, currentStep);
  }, [formData, currentStep, validateFormData]);

  // Get formatted data for specific step
  const getStepData = useCallback((step = currentStep) => {
    switch (step) {
      case AUTH_FLOW_STATES.SIGNUP.INITIAL:
        return {
          email_address: formData.email_address.toLowerCase(),
          first_name: formData.first_name.trim(),
          last_name: formData.last_name.trim(),
          mobile_phone_number: `${formData.country_phone_code}${formData.area_code}${formData.phone_number}`.replace(/\s+/g, ''),
          country_phone_code: formData.country_phone_code,
          area_code: formData.area_code,
          phone_number: formData.phone_number
        };
      
      case AUTH_FLOW_STATES.SIGNUP.COMPLETE_SIGNUP:
        return {
          organization_name: formData.organization_name.trim(),
          organization_size_id: parseInt(formData.organization_size_id),
          dataset_region_id: parseInt(formData.dataset_region_id)
        };
      
      default:
        return formData;
    }
  }, [formData, currentStep]);

  const resetForm = useCallback(() => {
    setFormData(initialFormData);
    setErrors({});
    setCurrentStep(AUTH_FLOW_STATES.SIGNUP.INITIAL);
  }, []);

  const contextValue = useMemo(() => ({
    formData,
    errors,
    isValid,
    currentStep,
    updateFormData,
    updateStep,
    getStepData,
    resetForm,
    validateFormData
  }), [
    formData,
    errors,
    isValid,
    currentStep,
    updateFormData,
    updateStep,
    getStepData,
    resetForm,
    validateFormData
  ]);

  return (
    <SignUpContext.Provider value={contextValue}>
      {children}
    </SignUpContext.Provider>
  );
};

export const useSignUp = () => {
  const context = useContext(SignUpContext);
  if (context === undefined) {
    throw new Error('useSignUp must be used within a SignUpProvider');
  }
  return context;
};

export default SignUpContext;