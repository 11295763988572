// File: frontend/src/pages/QMS/QuoteDetail/components/QuoteProducts/TenantDetails/index.js

import React, { useState } from 'react';
import { useLanguage } from '../../../../../../contexts/LanguageContext';
import styles from './TenantDetails.module.css';

const TenantDetails = ({ quote }) => {
  const { t } = useLanguage();
  const [activeTab, setActiveTab] = useState('rental');
  
  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    return new Date(dateString).toLocaleString();
  };

  if (!quote.tenant) {
    return (
      <div className={styles.noData}>
        <p>{t('no_tenant_insurance_details')}</p>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.tabs}>
        <button
          className={`${styles.tab} ${activeTab === 'rental' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('rental')}
        >
          {t('rental_property')}
        </button>
        <button
          className={`${styles.tab} ${activeTab === 'coverages' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('coverages')}
        >
          {t('coverages')}
        </button>
        <button
          className={`${styles.tab} ${activeTab === 'high-value' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('high-value')}
        >
          {t('high_value_items')}
        </button>
      </div>
      
      <div className={styles.tabContent}>
        {activeTab === 'rental' && (
          <div className={styles.rentalContent}>
            {quote.tenant.rental ? (
              <div className={styles.card}>
                <div className={styles.cardHeader}>
                  <h3 className={styles.cardTitle}>{t('rental_property_details')}</h3>
                </div>
                <div className={styles.cardContent}>
                  <div className={styles.infoGrid}>
                    <div className={styles.infoGroup}>
                      <div className={styles.infoLabel}>{t('property_type')}:</div>
                      <div className={styles.infoValue}>{quote.tenant.rental.property_type_id || 'N/A'}</div>
                    </div>
                    <div className={styles.infoGroup}>
                      <div className={styles.infoLabel}>{t('unit_number')}:</div>
                      <div className={styles.infoValue}>{quote.tenant.rental.unit_number || 'N/A'}</div>
                    </div>
                    <div className={styles.infoGroup}>
                      <div className={styles.infoLabel}>{t('building_type')}:</div>
                      <div className={styles.infoValue}>{quote.tenant.rental.building_type || 'N/A'}</div>
                    </div>
                    <div className={styles.infoGroup}>
                      <div className={styles.infoLabel}>{t('occupancy_type')}:</div>
                      <div className={styles.infoValue}>{quote.tenant.rental.occupancy_type_id || 'N/A'}</div>
                    </div>
                    <div className={styles.infoGroup}>
                      <div className={styles.infoLabel}>{t('square_footage')}:</div>
                      <div className={styles.infoValue}>{quote.tenant.rental.square_footage || 'N/A'}</div>
                    </div>
                    <div className={styles.infoGroup}>
                      <div className={styles.infoLabel}>{t('floor_number')}:</div>
                      <div className={styles.infoValue}>{quote.tenant.rental.floor_number || 'N/A'}</div>
                    </div>
                    <div className={styles.infoGroup}>
                      <div className={styles.infoLabel}>{t('lease_term')}:</div>
                      <div className={styles.infoValue}>{quote.tenant.rental.lease_term || 'N/A'}</div>
                    </div>
                    <div className={styles.infoGroup}>
                      <div className={styles.infoLabel}>{t('lease_effective_at')}:</div>
                      <div className={styles.infoValue}>{formatDate(quote.tenant.rental.lease_effective_at)}</div>
                    </div>
                    <div className={styles.infoGroup}>
                      <div className={styles.infoLabel}>{t('monthly_rent')}:</div>
                      <div className={styles.infoValue}>
                        {quote.tenant.rental.monthly_rent 
                          ? `$${quote.tenant.rental.monthly_rent.toFixed(2)}` 
                          : 'N/A'}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.noData}>
                <p>{t('no_rental_property_information')}</p>
              </div>
            )}
          </div>
        )}
        
        {activeTab === 'coverages' && (
          <div className={styles.coveragesContent}>
            {quote.tenant.coverages && quote.tenant.coverages.length > 0 ? (
              <div className={styles.tableContainer}>
                <table className={styles.table}>
                  <thead>
                    <tr>
                      <th>{t('coverage')}</th>
                      <th>{t('personal_property_limit')}</th>
                      <th>{t('liability_limit')}</th>
                      <th>{t('additional_living_expenses')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {quote.tenant.coverages.map((coverage, index) => (
                      <tr key={coverage.coverage_id || index}>
                        <td>
                          <div className={styles.coverageName}>{coverage.coverage_code}</div>
                          <div className={styles.coverageCode}>{coverage.coverage_type_id}</div>
                        </td>
                        <td>
                          {coverage.personal_property_limit 
                            ? `$${coverage.personal_property_limit.toFixed(2)}` 
                            : 'N/A'}
                        </td>
                        <td>
                          {coverage.liability_limit 
                            ? `$${coverage.liability_limit.toFixed(2)}` 
                            : 'N/A'}
                        </td>
                        <td>
                          {coverage.additional_living_expenses 
                            ? `$${coverage.additional_living_expenses.toFixed(2)}` 
                            : 'N/A'}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className={styles.noData}>
                <p>{t('no_coverage_information')}</p>
              </div>
            )}
          </div>
        )}
        
        {activeTab === 'high-value' && (
          <div className={styles.highValueContent}>
            {quote.tenant.high_value_items && quote.tenant.high_value_items.length > 0 ? (
              <div className={styles.tableContainer}>
                <table className={styles.table}>
                  <thead>
                    <tr>
                      <th>{t('item_type')}</th>
                      <th>{t('description')}</th>
                      <th>{t('value')}</th>
                      <th>{t('coverage_limit')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {quote.tenant.high_value_items.map((item, index) => (
                      <tr key={item.item_id || index}>
                        <td>{item.item_type || 'N/A'}</td>
                        <td>{item.item_description || 'N/A'}</td>
                        <td>
                          {item.item_value 
                            ? `$${item.item_value.toFixed(2)}` 
                            : 'N/A'}
                        </td>
                        <td>
                          {item.coverage_limit 
                            ? `$${item.coverage_limit.toFixed(2)}` 
                            : 'N/A'}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className={styles.noData}>
                <p>{t('no_high_value_items_information')}</p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TenantDetails;