// File: frontend/src/pages/UMS/UserDetail/components/UserConnections/index.js

import React, { useState } from 'react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from './UserConnections.module.css';

const UserConnections = ({ user }) => {
  const { t } = useLanguage();
  const [showAddConnectionForm, setShowAddConnectionForm] = useState(false);

  // Check if user has connections
  const hasConnections = user.connections && user.connections.length > 0;

  const getConnectionTypeText = (type) => {
    switch (type) {
      case 1: return t('family');
      case 2: return t('friend');
      case 3: return t('colleague');
      case 4: return t('spouse');
      case 5: return t('child');
      case 6: return t('parent');
      default: return t('other');
    }
  };

  const getConnectionStatusText = (status) => {
    switch (status) {
      case 1: return t('pending');
      case 2: return t('active');
      case 3: return t('declined');
      case 4: return t('blocked');
      default: return t('unknown');
    }
  };

  const getConnectionStatusClass = (status) => {
    switch (status) {
      case 1: return styles.pending;
      case 2: return styles.active;
      case 3: return styles.declined;
      case 4: return styles.blocked;
      default: return '';
    }
  };

  const handleAddConnection = () => {
    setShowAddConnectionForm(true);
  };

  const handleCancelAddConnection = () => {
    setShowAddConnectionForm(false);
  };

  const handleSubmitConnection = (e) => {
    e.preventDefault();
    // Handle connection submission logic here
    setShowAddConnectionForm(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2 className={styles.title}>{t('user_connections')}</h2>
        {!showAddConnectionForm && (
          <button 
            className={styles.addButton}
            onClick={handleAddConnection}
          >
            {t('add_connection')}
          </button>
        )}
      </div>

      {showAddConnectionForm && (
        <div className={styles.formCard}>
          <h3 className={styles.formTitle}>{t('add_new_connection')}</h3>
          <form onSubmit={handleSubmitConnection} className={styles.form}>
            <div className={styles.formRow}>
              <div className={styles.formGroup}>
                <label htmlFor="connectionType" className={styles.label}>{t('connection_type')}</label>
                <select id="connectionType" className={styles.select} required>
                  <option value="">{t('select_connection_type')}</option>
                  <option value="1">{t('family')}</option>
                  <option value="2">{t('friend')}</option>
                  <option value="3">{t('colleague')}</option>
                  <option value="4">{t('spouse')}</option>
                  <option value="5">{t('child')}</option>
                  <option value="6">{t('parent')}</option>
                </select>
              </div>
              <div className={styles.formGroup}>
                <label htmlFor="connectionMethod" className={styles.label}>{t('connection_method')}</label>
                <select id="connectionMethod" className={styles.select} required>
                  <option value="email">{t('email')}</option>
                  <option value="user_id">{t('user_id')}</option>
                  <option value="phone">{t('phone')}</option>
                </select>
              </div>
            </div>

            <div className={styles.formRow}>
              <div className={styles.formGroup}>
                <label htmlFor="connectionValue" className={styles.label}>{t('email_user_id_or_phone')}</label>
                <input type="text" id="connectionValue" className={styles.input} required />
              </div>
            </div>

            <div className={styles.formActions}>
              <button 
                type="button" 
                className={styles.cancelButton}
                onClick={handleCancelAddConnection}
              >
                {t('cancel')}
              </button>
              <button type="submit" className={styles.submitButton}>
                {t('send_invitation')}
              </button>
            </div>
          </form>
        </div>
      )}

      {hasConnections ? (
        <div className={styles.connectionsList}>
          <table className={styles.connectionsTable}>
            <thead>
              <tr>
                <th>{t('name')}</th>
                <th>{t('connection_type')}</th>
                <th>{t('status')}</th>
                <th>{t('connection_date')}</th>
                <th>{t('last_interaction')}</th>
                <th>{t('actions')}</th>
              </tr>
            </thead>
            <tbody>
              {user.connections.map((connection, index) => (
                <tr key={connection.connection_id || index}>
                  <td>
                    {connection.connected_user_name || t('pending_user')}
                  </td>
                  <td>
                    {getConnectionTypeText(connection.user_connection_type)}
                  </td>
                  <td>
                    <span className={getConnectionStatusClass(connection.user_connection_status)}>
                      {getConnectionStatusText(connection.user_connection_status)}
                    </span>
                  </td>
                  <td>
                    {connection.connection_date 
                      ? new Date(connection.connection_date).toLocaleDateString() 
                      : '-'}
                  </td>
                  <td>
                    {connection.last_interaction_date 
                      ? new Date(connection.last_interaction_date).toLocaleDateString() 
                      : '-'}
                  </td>
                  <td>
                    <div className={styles.actionButtons}>
                      {connection.user_connection_status === 1 && (
                        <>
                          <button className={styles.acceptButton}>{t('accept')}</button>
                          <button className={styles.declineButton}>{t('decline')}</button>
                        </>
                      )}
                      {connection.user_connection_status === 2 && (
                        <button className={styles.blockButton}>{t('block')}</button>
                      )}
                      {connection.user_connection_status === 3 && (
                        <button className={styles.resendButton}>{t('resend')}</button>
                      )}
                      {connection.user_connection_status === 4 && (
                        <button className={styles.unblockButton}>{t('unblock')}</button>
                      )}
                      <button className={styles.removeButton}>{t('remove')}</button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        !showAddConnectionForm && (
          <div className={styles.noConnections}>
            <p>{t('no_connections_found')}</p>
            <p>{t('add_connection_message')}</p>
          </div>
        )
      )}
    </div>
  );
};

export default UserConnections;