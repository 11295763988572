// File: frontend/src/utils/validation.js

export const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})$/;
  return re.test(String(email).toLowerCase());
};
  
export const validatePhone = (phone) => {
  const phoneRegex = /^\+?[1-9]\d{7,14}$/;
  return phone && phoneRegex.test(phone.replace(/\D/g, ''));
};

export const validateName = (name) => {
  return name && name.trim().length >= 2;
};

export const validateCompanyName = (name) => {
  return name && name.trim().length >= 2 && name.trim().length <= 100;
};

export const formatName = (name) => {
  if (!name) return name;
  
  // Split on various delimiters that might appear in names
  const nameParts = name.split(/(['\s-])/);
  
  return nameParts
    .map((part, index) => {
      // Skip empty parts and delimiters
      if (!part || part.match(/['\s-]/)) return part;

      // Special prefixes that should be in a specific case
      const specialPrefixes = {
        'mc': 'Mc',
        'mac': 'Mac',
        'o\'': 'O\'',
        'van': 'van',
        'von': 'von',
        'de': 'de',
        'la': 'la',
        'le': 'Le',
        'den': 'den',
        'der': 'der',
        'di': 'di',
        'du': 'du',
        'san': 'San',
        'st': 'St',
        'st.': 'St.'
      };

      // Convert part to lowercase for comparison
      const lowerPart = part.toLowerCase();

      // Check if this part is a special prefix
      if (specialPrefixes[lowerPart]) {
        return specialPrefixes[lowerPart];
      }

      // Handle Roman numerals (e.g., III, IV, VIII)
      const romanNumeralPattern = /^(i{1,3}|iv|v|vi{1,3}|ix|x|xi{1,3}|xiv|xv|xvi{1,3})$/i;
      if (romanNumeralPattern.test(lowerPart)) {
        return lowerPart.toUpperCase();
      }

      // General case: capitalize first letter, lowercase rest
      return part.charAt(0).toUpperCase() + part.slice(1).toLowerCase();
    })
    .join('');
};

// // Example usage:
// console.log(formatName('john')); // "John"
// console.log(formatName('SMITH')); // "Smith"
// console.log(formatName('von braun')); // "von Braun"
// console.log(formatName('macarthur')); // "MacArthur"
// console.log(formatName('o\'connor')); // "O'Connor"
// console.log(formatName('mary-jane')); // "Mary-Jane"
// console.log(formatName('jean-pierre')); // "Jean-Pierre"
// console.log(formatName('st. john')); // "St. John"
// console.log(formatName('william iii')); // "William III"