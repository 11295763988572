// File: frontend/src/pages/Join/Join.js 

import React from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import JobApplicationForm from '../../components/JobApplicationForm/JobApplicationForm';
import styles from './Join.module.css';

const Join = () => {
  const { t } = useLanguage();

  const positions = {
    actuarial_science: 'positions.actuarial_science',
    risk_management: 'positions.risk_management',
    risk_related_asset_management: 'positions.risk_related_asset_management',
    data_science: 'positions.data_science',
    software_engineering: 'positions.software_engineering',
    ai_ml_research: 'positions.ai_ml_research',
    physics: 'positions.physics',
    mathematics: 'positions.mathematics',
    economics: 'positions.economics',
    revenue_leadership: 'positions.revenue_leadership'
  };

  const applicationRequirements = {
    resume: 'applicationRequirements.resume',
    cover_letter: 'applicationRequirements.cover_letter'
  };

  const coverLetterRequirements = {
    reasoning_mastery: 'applicationRequirements.cover_letter.reasoning_mastery',
    professional_case_studies: 'applicationRequirements.cover_letter.professional_case_studies',
    unique_narrative: 'applicationRequirements.cover_letter.unique_narrative'
  };

  const offerDetails = {
    base_salary: 'offer.base_salary',
    locations: 'offer.locations',
    equity: 'offer.equity',
    collaborative_environment: 'offer.collaborative_environment',
    open_to_discussion: 'offer.open_to_discussion'
  };

  return (
    <div className={styles.joinContainer}>
      <h1 className={styles.title}>{t('join_allegory')}</h1>

      <section className={styles.introduction} aria-label="Introduction">
        <p className={styles.paragraph}>{t('join_intro_1')}</p>
        <p className={styles.paragraph}>{t('join_intro_2')}</p>
      </section>

      <section className={styles.positions} aria-labelledby="positions-title">
        <h2 id="positions-title" className={styles.sectionTitle}>{t('looking_for')}</h2>
        <ul className={styles.list}>
          {Object.entries(positions).map(([key, value]) => (
            <li key={key} className={styles.listItem}>{t(value)}</li>
          ))}
        </ul>
      </section>

      <section className={styles.application} aria-labelledby="application-title">
        <h2 id="application-title" className={styles.sectionTitle}>{t('application_requirements')}</h2>
        <ol className={`${styles.list} ${styles.orderedList}`}>
          {Object.entries(applicationRequirements).map(([key, value]) => (
            <li key={key} className={styles.listItem}>
              {t(value)}
              {key === 'cover_letter' && (
                <ul className={`${styles.list} ${styles.unorderedList}`}>
                  {Object.entries(coverLetterRequirements).map(([coverKey, coverValue]) => (
                    <li key={coverKey} className={styles.listItem}>{t(coverValue)}</li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ol>
      </section>

      <section className={styles.offer} aria-labelledby="offer-title">
        <h2 id="offer-title" className={styles.sectionTitle}>{t('our_offer')}</h2>
        <ul className={styles.list}>
          {Object.entries(offerDetails).map(([key, value]) => (
            <li key={key} className={styles.listItem}>{t(value)}</li>
          ))}
        </ul>
      </section>

      <section className={styles.closing} aria-label="Closing statement">
        <p className={styles.paragraph}>{t('join_closing')}</p>
      </section>
      <section className={styles.applicationForm} aria-labelledby="application-form-title">
        <h2 id="application-form-title" className={styles.sectionTitle}>{t('jobApplication.title')}</h2>
        <JobApplicationForm />
      </section>
    </div>
  );
};

export default Join;