// src/components/TripDetails/TripEventsMaps.js
import React, { useState } from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import styles from './TripEventsMaps.module.css';

import AttentiveDrivingMap from '../charts/TripEvents/AttentiveDrivingMap';
import AccelerationMap from '../charts/TripEvents/AccelerationMap';
import BrakingMap from '../charts/TripEvents/BrakingMap';
import CorneringMap from '../charts/TripEvents/CorneringMap';
import SpeedingMap from '../charts/TripEvents/SpeedingMap';

const TripEventsMaps = ({ tripEventsData }) => {
  const { t } = useLanguage();
  const [activeMap, setActiveMap] = useState('attentiveDriving');

  const renderMap = () => {
    const props = {
      width: '100%',
      height: 650,
      tripEventsData: tripEventsData,
    };

    switch (activeMap) {
      case 'attentiveDriving':
        return <AttentiveDrivingMap {...props} />;
      case 'acceleration':
        return <AccelerationMap {...props} />;
      case 'braking':
        return <BrakingMap {...props} />;
      case 'cornering':
        return <CorneringMap {...props} />;
      case 'speeding':
        return <SpeedingMap {...props} />;
      default:
        return null;
    }
  };

  const renderMapTab = (key, title, description) => (
    <div
      className={`${styles.cardsTableSystemItem} ${activeMap === key ? styles.active : ''}`}
      onClick={() => setActiveMap(key)}
    >
      <div className={styles.cardsTableSystemIcon}>
        <img className={styles.icons18MapPinned} alt="" src={`/icons/18/misc/${key}.svg`} />
      </div>
      <div className={styles.texts}>
        <div className={styles.attentiveDriving}>{title}</div>
        <div className={styles.attentiveDrivingIs}>{description}</div>
      </div>
    </div>
  );

  return (
    <div className={styles.card}>
      <div className={styles.cardStandartTitleRow}>
        <div className={styles.text}>{t('trip_map_and_event_details')}</div>
        <div className={styles.button}>
          <img className={styles.icons18MoreHorizontal} alt="" src="/icons/18/misc/show-more-horizontal-three-dots.svg" />
        </div>
      </div>
      <div className={styles.cardMappingContainer}>
        <div className={styles.cardsTableSystem}>
          {renderMapTab('attentiveDriving', t('attentive_driving'), t('attentive_driving_description'))}
          {renderMapTab('acceleration', t('acceleration'), t('acceleration_description'))}
          {renderMapTab('braking', t('braking'), t('braking_description'))}
          {renderMapTab('cornering', t('cornering'), t('cornering_description'))}
          {renderMapTab('speeding', t('speeding'), t('speeding_description'))}
        </div>
        <div className={styles.cardMappingContainerC}>
          <div className={styles.cardMappingContainerC1}>
            <div className={styles.cardMappingContainerC2}>
              {renderMap()}
              <div className={styles.zoomInOut}>
                <div className={styles.button}>
                  <img className={styles.icons18MoreHorizontal} alt="" src="/icons/18/misc/plus.svg" />
                </div>
                <div className={styles.button}>
                  <img className={styles.icons18MoreHorizontal} alt="" src="/icons/18/misc/minus.svg" />
                </div>
              </div>
              <div className={styles.frameParent}>
                <div className={styles.frameGroup}>
                  <div className={styles.frameContainer}>
                    <img className={styles.frameItem} alt="" src="/icons/map-legend/started.svg" />
                    <img className={styles.frameItem} alt="" src="/icons/map-legend/trip-route.svg" />
                    <img className={styles.frameItem} alt="" src="/icons/map-legend/low-severity.svg" />
                    <img className={styles.frameItem} alt="" src="/icons/map-legend/medium-severity.svg" />
                    <img className={styles.frameItem} alt="" src="/icons/map-legend/high-severity.svg" />
                    <img className={styles.frameItem} alt="" src="/icons/map-legend/arrived.svg" />
                  </div>
                  <div className={styles.startedParent}>
                    <div className={styles.started}>{t('started')}</div>
                    <div className={styles.started}>{t('trip_route')}</div>
                    <div className={styles.started}>{t('low_severity')}</div>
                    <div className={styles.started}>{t('medium_severity')}</div>
                    <div className={styles.started}>{t('high_severity')}</div>
                    <div className={styles.started}>{t('arrived')}</div>
                  </div>
                </div>
                <div className={styles.legendParent}>
                  <div className={styles.legend}>{t('legend')}</div>
                  <img className={styles.frameChild4} alt="" src="/icons/18/misc/chevron-down.svg" />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.frameDiv}>
            <div className={styles.icons18UserParent}>
              <img className={styles.icons18MoreHorizontal} alt="" src="/icons/18/misc/user-gray.svg" />
              <div className={styles.thisContentBeProvidedByParent}>
                <div className={styles.thisContentBe}>{t('data_collection_info')}</div>
                <div className={styles.allegoryApp}>{t('allegory_app')}</div>
              </div>
            </div>
            <img className={styles.horizontalContainerIcon} alt="" src="/icons/horizontal-container.svg" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TripEventsMaps;