// File: frontend/src/pages/QMS/components/QuoteStatistics/index.js

import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import styles from './QuoteStatistics.module.css';

const QuoteStatistics = () => {
  const { t } = useLanguage();
  const [stats, setStats] = useState({
    totalQuotes: 0,
    activeQuotes: 0,
    completedQuotes: 0,
    abandonedQuotes: 0
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch statistics data
    const fetchStats = async () => {
      try {
        // This would be replaced with an actual API call
        await new Promise(resolve => setTimeout(resolve, 500));
        
        setStats({
          totalQuotes: 1250,
          activeQuotes: 450,
          completedQuotes: 650,
          abandonedQuotes: 150
        });
      } catch (error) {
        console.error('Error fetching statistics:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, []);

  return (
    <div className={styles.container}>
      {loading ? (
        <div className={styles.loading}>{t('loading_statistics')}</div>
      ) : (
        <div className={styles.statsGrid}>
          <div className={`${styles.statCard} ${styles.total}`}>
            <div className={styles.statValue}>{stats.totalQuotes}</div>
            <div className={styles.statLabel}>{t('total_quotes')}</div>
          </div>
          
          <div className={`${styles.statCard} ${styles.active}`}>
            <div className={styles.statValue}>{stats.activeQuotes}</div>
            <div className={styles.statLabel}>{t('active_quotes')}</div>
          </div>
          
          <div className={`${styles.statCard} ${styles.completed}`}>
            <div className={styles.statValue}>{stats.completedQuotes}</div>
            <div className={styles.statLabel}>{t('completed_quotes')}</div>
          </div>
          
          <div className={`${styles.statCard} ${styles.abandoned}`}>
            <div className={styles.statValue}>{stats.abandonedQuotes}</div>
            <div className={styles.statLabel}>{t('abandoned_quotes')}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuoteStatistics;