// File: frontend/src/pages/InvestorPitch/components/slides/TechnologySlide/TechnologySlide.js

import React from 'react';
import { motion } from 'framer-motion';
import * as LucideIcons from 'lucide-react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import TechCard from './TechCard';
import { ANIMATION_VARIANTS } from '../../../constants/animations';
import styles from './TechnologySlide.module.css';

const TechnologySlide = () => {
  const { t } = useLanguage();

  const techStack = [
    {
      title: t('investor_pitch.technology.stack.plato.title'),
      description: t('investor_pitch.technology.stack.plato.description', { 
        parameters: '1.8M' 
      }),
      icon: LucideIcons.Brain,
      gradient: "linear-gradient(135deg, var(--color-blue-310), var(--color-blue-510))"
    },
    {
      title: t('investor_pitch.technology.stack.socrates.title'),
      description: t('investor_pitch.technology.stack.socrates.description'),
      icon: LucideIcons.Users,
      gradient: "linear-gradient(135deg, var(--color-green-310), var(--color-green-510))"
    },
    {
      title: t('investor_pitch.technology.stack.cave.title'),
      description: t('investor_pitch.technology.stack.cave.description'),
      icon: LucideIcons.Lock,
      gradient: "linear-gradient(135deg, var(--color-orange-310), var(--color-orange-510))"
    },
    {
      title: t('investor_pitch.technology.stack.source.title'),
      description: t('investor_pitch.technology.stack.source.description'),
      icon: LucideIcons.Cpu,
      gradient: "linear-gradient(135deg, var(--color-purple-200), var(--color-blue-510))"
    }
  ];

  return (
    <motion.div
      className={styles.container}
      variants={ANIMATION_VARIANTS.stagger.container}
      initial="hidden"
      animate="visible"
    >
      <motion.div 
        className={styles.header}
        variants={ANIMATION_VARIANTS.fadeIn}
      >
        <h2 className={styles.title}>{t('investor_pitch.technology.title')}</h2>
        <p className={styles.subtitle}>{t('investor_pitch.technology.subtitle')}</p>
      </motion.div>

      <motion.div 
        className={styles.techGrid}
        variants={ANIMATION_VARIANTS.stagger.container}
      >
        {techStack.map((tech, index) => (
          <motion.div
            key={tech.title}
            variants={ANIMATION_VARIANTS.stagger.item}
            custom={index}
          >
            <TechCard {...tech} />
          </motion.div>
        ))}
      </motion.div>
    </motion.div>
  );
};

export default TechnologySlide;