// File: frontend/src/pages/PropertyDetail/components/ExternalFeatures/index.js

import React from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import styles from './ExternalFeatures.module.css';

const ExternalFeatures = ({ property }) => {
  const { t } = useLanguage();

  return (
    <div className={styles.container}>
      <div className={styles.grid}>
        <div className={styles.card}>
          <h3 className={styles.cardTitle}>{t('garage_information')}</h3>
          <div className={styles.cardContent}>
            {property.externalFeatures ? (
              <div className={styles.featuresInfo}>
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('garage_type')}:</div>
                  <div className={styles.infoValue}>{property.externalFeatures.garage_type}</div>
                </div>
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('garage_size')}:</div>
                  <div className={styles.infoValue}>{property.externalFeatures.garage_size}</div>
                </div>
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('attached_garages')}:</div>
                  <div className={styles.infoValue}>
                    {property.externalFeatures.attached_garages ? t('yes') : t('no')}
                  </div>
                </div>
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('parking_spaces')}:</div>
                  <div className={styles.infoValue}>{property.externalFeatures.parking_spaces}</div>
                </div>
              </div>
            ) : (
              <div className={styles.noData}>{t('no_garage_information_available')}</div>
            )}
          </div>
        </div>
        
        <div className={styles.card}>
          <h3 className={styles.cardTitle}>{t('swimming_pool')}</h3>
          <div className={styles.cardContent}>
            {property.externalFeatures ? (
              <div className={styles.featuresInfo}>
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('has_swimming_pool')}:</div>
                  <div className={styles.infoValue}>
                    {property.externalFeatures.swimming_pool ? t('yes') : t('no')}
                  </div>
                </div>
                
                {property.externalFeatures.swimming_pool && (
                  <>
                    <div className={styles.infoGroup}>
                      <div className={styles.infoLabel}>{t('pool_type')}:</div>
                      <div className={styles.infoValue}>{property.externalFeatures.pool_type}</div>
                    </div>
                    <div className={styles.infoGroup}>
                      <div className={styles.infoLabel}>{t('pool_age')}:</div>
                      <div className={styles.infoValue}>{property.externalFeatures.pool_age} {t('years')}</div>
                    </div>
                  </>
                )}
              </div>
            ) : (
              <div className={styles.noData}>{t('no_swimming_pool_information_available')}</div>
            )}
          </div>
        </div>
      </div>
      
      <div className={styles.card}>
        <h3 className={styles.cardTitle}>{t('land_information')}</h3>
        <div className={styles.cardContent}>
          <div className={styles.landInfo}>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('land_area')}:</div>
              <div className={styles.infoValue}>
                {property.land_area} {property.area_measure}
              </div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('geographical_exposure')}:</div>
              <div className={styles.infoValue}>{property.geographical_exposure}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('exposure_characteristics')}:</div>
              <div className={styles.infoValue}>{property.exposure_characteristics}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('zoning_classification')}:</div>
              <div className={styles.infoValue}>{property.zoning_classification}</div>
            </div>
          </div>
        </div>
      </div>
      
      <div className={styles.externalFeaturesVisual}>
        <h3 className={styles.visualTitle}>{t('property_layout')}</h3>
        <div className={styles.visualContent}>
          <div className={styles.layoutPlaceholder}>
            {t('property_layout_not_available')}
          </div>
        </div>
      </div>
      
      <div className={styles.additionalFeatures}>
        <h3 className={styles.additionalFeaturesTitle}>{t('additional_external_features')}</h3>
        <div className={styles.additionalFeaturesContent}>
          <div className={styles.featuresList}>
            {property.externalFeatures && property.externalFeatures.swimming_pool && (
              <div className={styles.featureTag}>{t('swimming_pool')}</div>
            )}
            {property.externalFeatures && property.externalFeatures.attached_garages && (
              <div className={styles.featureTag}>{t('attached_garage')}</div>
            )}
            {property.externalFeatures && property.externalFeatures.garage_type !== 'None' && (
              <div className={styles.featureTag}>{property.externalFeatures.garage_type} {t('garage')}</div>
            )}
            
            {/* Mock additional features for demonstration */}
            <div className={styles.featureTag}>{t('patio')}</div>
            <div className={styles.featureTag}>{t('deck')}</div>
            <div className={styles.featureTag}>{t('fence')}</div>
            <div className={styles.featureTag}>{t('sprinkler_system')}</div>
            <div className={styles.featureTag}>{t('outdoor_lighting')}</div>
            
            {(!property.externalFeatures || 
              (!property.externalFeatures.swimming_pool && 
               !property.externalFeatures.attached_garages && 
               property.externalFeatures.garage_type === 'None')) && (
              <div className={styles.noAdditionalFeatures}>
                {t('no_additional_external_features')}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExternalFeatures;