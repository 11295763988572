// File: frontend/src/pages/AI1/components/TypingAnimation/TypingAnimation.js

import React from 'react';
import styles from './TypingAnimation.module.css';

const TypingAnimation = () => {
  return (
    <div className={styles.typingContainer}>
      <div className={styles.dot}></div>
      <div className={styles.dot}></div>
      <div className={styles.dot}></div>
    </div>
  );
};

export default TypingAnimation;