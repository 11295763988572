// File: frontend/src/pages/InvestorPitch/components/slides/GrowthSlide/GrowthSlide.js

import React from 'react';
import { motion } from 'framer-motion';
import { ArrowRight } from 'lucide-react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import GrowthIndicator from './GrowthIndicator';
import MetricCard from './MetricCard';
import { ANIMATION_VARIANTS } from '../../../constants/animations';
import styles from './GrowthSlide.module.css';

const GrowthSlide = () => {
  const { t } = useLanguage();

  const growthStages = [
    {
      year: t('investor_pitch.growth.stages.year_1'),
      value: "$10B",
      color: "var(--color-blue-440)"
    },
    {
      year: t('investor_pitch.growth.stages.year_2'),
      value: "$100B",
      color: "var(--color-blue-640)"
    },
    {
      year: t('investor_pitch.growth.stages.year_3'),
      value: "$1T",
      color: "var(--color-blue-840)"
    }
  ];

  const metrics = [
    {
      title: t('investor_pitch.growth.metrics.speed.title'),
      description: t('investor_pitch.growth.metrics.speed.description', {
        days: 20,
        capacity: '$1B'
      }),
      impact: t('investor_pitch.growth.metrics.speed.impact'),
      icon: "Rocket"
    },
    {
      title: t('investor_pitch.growth.metrics.economics.title'),
      description: t('investor_pitch.growth.metrics.economics.description', {
        reduction: 25,
        efficiency: 60
      }),
      impact: t('investor_pitch.growth.metrics.economics.impact'),
      icon: "Rotate3D"
    },
    {
      title: t('investor_pitch.growth.metrics.scale.title'),
      description: t('investor_pitch.growth.metrics.scale.description', {
        capacity: '$1B'
      }),
      impact: t('investor_pitch.growth.metrics.scale.impact'),
      icon: "LineChart"
    },
    {
      title: t('investor_pitch.growth.metrics.roi.title'),
      description: t('investor_pitch.growth.metrics.roi.market', {
        percentage: 15
      }),
      impact: t('investor_pitch.growth.metrics.roi.valuation', {
        value: '$300B'
      }),
      icon: "TrendingUp"
    }
  ];

  return (
    <motion.div
      className={styles.container}
      variants={ANIMATION_VARIANTS.stagger.container}
      initial="hidden"
      animate="visible"
    >
      <motion.div 
        className={styles.header}
        variants={ANIMATION_VARIANTS.fadeIn}
      >
        <h2 className={styles.title}>{t('investor_pitch.growth.title')}</h2>
        <p className={styles.subtitle}>{t('investor_pitch.growth.subtitle')}</p>
      </motion.div>

      <motion.div 
        className={styles.growthTrajectory}
        variants={ANIMATION_VARIANTS.stagger.container}
      >
        {growthStages.map((stage, index) => (
          <React.Fragment key={stage.year}>
            <motion.div
              variants={ANIMATION_VARIANTS.stagger.item}
              custom={index}
            >
              <GrowthIndicator {...stage} />
            </motion.div>
            {index < growthStages.length - 1 && (
              <motion.div
                className={styles.arrowWrapper}
                variants={ANIMATION_VARIANTS.fadeIn}
              >
                <ArrowRight className={styles.arrow} />
              </motion.div>
            )}
          </React.Fragment>
        ))}
      </motion.div>

      <motion.div 
        className={styles.metrics}
        variants={ANIMATION_VARIANTS.stagger.container}
      >
        {metrics.map((metric, index) => (
          <motion.div
            key={metric.title}
            variants={ANIMATION_VARIANTS.stagger.item}
            custom={index}
          >
            <MetricCard {...metric} />
          </motion.div>
        ))}
      </motion.div>
    </motion.div>
  );
};

export default GrowthSlide;