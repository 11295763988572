// File: frontend/src/pages/InvestorPitch/components/slides/DemoSlide/VideoPlayer.js

import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from './VideoPlayer.module.css';

const VideoPlayer = ({ url, title }) => {
  const videoRef = useRef(null);
  const { t } = useLanguage();

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.focus({ preventScroll: true });
    }
  }, []);

  return (
    <div className={styles.container}>
      <video
        ref={videoRef}
        className={styles.video}
        controls
        autoPlay
        playsInline
        title={title}
      >
        <source src={url} type="video/mp4" />
        {t('investor_pitch.demo.video.fallback')}
      </video>
    </div>
  );
};

VideoPlayer.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default VideoPlayer;