// File: frontend/src/pages/AIAgents/AIAgents.js

import React, { useEffect, useMemo } from 'react'; // Add useMemo to imports
import { motion } from 'framer-motion';
import { useLanguage } from '../../contexts/LanguageContext';
import { useSEO } from '../../contexts/SEOContext';
import { generateAlternateUrls, getCanonicalUrl } from '../../utils/seoHelpers';
import { generateStructuredData } from '../../utils/aiSeoHelpers';
import { Helmet } from 'react-helmet-async';
import LandingButton from '../../components/Button/LandingButton';
import AgentCard from './components/AgentCard';
import CategorySection from './components/CategorySection';
import styles from './AIAgents.module.css';

const AIAgents = () => {
    const { t, language, supportedLanguages } = useLanguage();
    const { updateSEO } = useSEO();
  
    // Move categories definition outside of the component or memoize it
    const categories = useMemo(() => [ 
    {
      id: 'core',
      title: t('ai_agents.categories.core.title'),
      description: t('ai_agents.categories.core.description'),
      agents: [
          {
              id: 'ai1_general',
              icon: 'Cpu',
              name: t('ai_agents.agents.ai1_general.name'),
              title: t('ai_agents.agents.ai1_general.title'),
              description: t('ai_agents.agents.ai1_general.description'),
              capabilities: [
                  t('ai_agents.agents.ai1_general.capabilities.assistance'),
                  t('ai_agents.agents.ai1_general.capabilities.integration'),
                  t('ai_agents.agents.ai1_general.capabilities.analysis'),
                  t('ai_agents.agents.ai1_general.capabilities.support')
                ],
                gradient: "linear-gradient(135deg, var(--color-blue-310), var(--color-blue-510))"
            },
            {
                id: 'ai1_purpose',
                icon: 'Zap',
                name: t('ai_agents.agents.ai1_purpose.name'),
                title: t('ai_agents.agents.ai1_purpose.title'),
                description: t('ai_agents.agents.ai1_purpose.description'),
                capabilities: [
                    t('ai_agents.agents.ai1_purpose.capabilities.schema'),
                    t('ai_agents.agents.ai1_purpose.capabilities.api'),
                    t('ai_agents.agents.ai1_purpose.capabilities.deployment'),
                    t('ai_agents.agents.ai1_purpose.capabilities.automation')
                ],
                gradient: "linear-gradient(135deg, var(--color-orange-310), var(--color-orange-510))"
            },
            {
              id: 'ato',
              icon: 'Brain',
              name: t('ai_agents.agents.ato.name'),
              title: t('ai_agents.agents.ato.title'),
              description: t('ai_agents.agents.ato.description'),
              capabilities: [
                t('ai_agents.agents.ato.capabilities.development'),
                t('ai_agents.agents.ato.capabilities.architecture'),
                t('ai_agents.agents.ato.capabilities.security'),
                t('ai_agents.agents.ato.capabilities.compliance')
              ],
              gradient: "linear-gradient(135deg, var(--color-green-310), var(--color-blue-510))"
            },
        ]
    },
    {
      id: 'specialized',
      title: t('ai_agents.categories.specialized.title'),
      description: t('ai_agents.categories.specialized.description'),
      agents: [
        {
          id: 'actuary',
          icon: 'Brain',
          name: t('ai_agents.agents.actuary.name'),
          title: t('ai_agents.agents.actuary.title'),
          description: t('ai_agents.agents.actuary.description'),
          capabilities: [
            t('ai_agents.agents.actuary.capabilities.data'),
            t('ai_agents.agents.actuary.capabilities.pricing'),
            t('ai_agents.agents.actuary.capabilities.payment'),
            t('ai_agents.agents.actuary.capabilities.filing')
          ],
          gradient: "linear-gradient(135deg, var(--color-blue-310), var(--color-blue-510))"
        },
        {
          id: 'privacy_officer',
          icon: 'Shield',
          name: t('ai_agents.agents.privacy_officer.name'),
          title: t('ai_agents.agents.privacy_officer.title'),
          description: t('ai_agents.agents.privacy_officer.description'),
          capabilities: [
            t('ai_agents.agents.privacy_officer.capabilities.compliance'),
            t('ai_agents.agents.privacy_officer.capabilities.audit'),
            t('ai_agents.agents.privacy_officer.capabilities.protect'),
            t('ai_agents.agents.privacy_officer.capabilities.report')
          ],
          gradient: "linear-gradient(135deg, var(--color-blue-310), var(--color-blue-510))"
        },
        {
          id: 'data_analyst',
          icon: 'LineChart',
          name: t('ai_agents.agents.data_analyst.name'),
          title: t('ai_agents.agents.data_analyst.title'),
          description: t('ai_agents.agents.data_analyst.description'),
          capabilities: [
            t('ai_agents.agents.data_analyst.capabilities.analyze'),
            t('ai_agents.agents.data_analyst.capabilities.visualize'),
            t('ai_agents.agents.data_analyst.capabilities.predict'),
            t('ai_agents.agents.data_analyst.capabilities.report')
          ],
          gradient: "linear-gradient(135deg, var(--color-blue-310), var(--color-blue-510))"
        },
        {
          id: 'insurance_translator',
          icon: 'Languages',
          name: t('ai_agents.agents.insurance_translator.name'),
          title: t('ai_agents.agents.insurance_translator.title'),
          description: t('ai_agents.agents.insurance_translator.description'),
          capabilities: [
            t('ai_agents.agents.insurance_translator.capabilities.translate'),
            t('ai_agents.agents.insurance_translator.capabilities.localize'),
            t('ai_agents.agents.insurance_translator.capabilities.validate'),
            t('ai_agents.agents.insurance_translator.capabilities.maintain')
          ],
          gradient: "linear-gradient(135deg, var(--color-blue-310), var(--color-blue-510))"
        },
      ]
    },
    {
      id: 'data',
      title: t('ai_agents.categories.data.title'),
      description: t('ai_agents.categories.data.description'),
      agents: [
        {
          id: 'code_classifier',
          icon: 'Code2',
          name: t('ai_agents.agents.code_classifier.name'),
          title: t('ai_agents.agents.code_classifier.title'),
          description: t('ai_agents.agents.code_classifier.description'),
          capabilities: [
            t('ai_agents.agents.code_classifier.capabilities.explanation'),
            t('ai_agents.agents.code_classifier.capabilities.documentation'),
            t('ai_agents.agents.code_classifier.capabilities.analysis'),
            t('ai_agents.agents.code_classifier.capabilities.optimization')
          ],
          gradient: "linear-gradient(135deg, var(--color-blue-310), var(--color-blue-510))"
        },
        {
          id: 'csv_converter',
          icon: 'FileJson',
          name: t('ai_agents.agents.csv_converter.name'),
          title: t('ai_agents.agents.csv_converter.title'),
          description: t('ai_agents.agents.csv_converter.description'),
          capabilities: [
            t('ai_agents.agents.csv_converter.capabilities.format'),
            t('ai_agents.agents.csv_converter.capabilities.transform'),
            t('ai_agents.agents.csv_converter.capabilities.validate'),
            t('ai_agents.agents.csv_converter.capabilities.export')
          ],
          gradient: "linear-gradient(135deg, var(--color-green-310), var(--color-green-510))"
        },
        {
          id: 'csv_generator',
          icon: 'FileSpreadsheet',
          name: t('ai_agents.agents.csv_generator.name'),
          title: t('ai_agents.agents.csv_generator.title'),
          description: t('ai_agents.agents.csv_generator.description'),
          capabilities: [
            t('ai_agents.agents.csv_generator.capabilities.create'),
            t('ai_agents.agents.csv_generator.capabilities.customize'),
            t('ai_agents.agents.csv_generator.capabilities.populate'),
            t('ai_agents.agents.csv_generator.capabilities.format')
          ],
          gradient: "linear-gradient(135deg, var(--color-orange-310), var(--color-orange-510))"
        },
        {
          id: 'data_organizer',
          icon: 'TableProperties',
          name: t('ai_agents.agents.data_organizer.name'),
          title: t('ai_agents.agents.data_organizer.title'),
          description: t('ai_agents.agents.data_organizer.description'),
          capabilities: [
            t('ai_agents.agents.data_organizer.capabilities.structure'),
            t('ai_agents.agents.data_organizer.capabilities.clean'),
            t('ai_agents.agents.data_organizer.capabilities.format'),
            t('ai_agents.agents.data_organizer.capabilities.export')
          ],
          gradient: "linear-gradient(135deg, var(--color-yellow-310), var(--color-brown-510))"
        }
      ]
    },
    {
      id: 'productivity',
      title: t('ai_agents.categories.productivity.title'),
      description: t('ai_agents.categories.productivity.description'),
      agents: [
        {
          id: 'email_extractor',
          icon: 'Mail',
          name: t('ai_agents.agents.email_extractor.name'),
          title: t('ai_agents.agents.email_extractor.title'),
          description: t('ai_agents.agents.email_extractor.description'),
          capabilities: [
            t('ai_agents.agents.email_extractor.capabilities.extract'),
            t('ai_agents.agents.email_extractor.capabilities.validate'),
            t('ai_agents.agents.email_extractor.capabilities.format'),
            t('ai_agents.agents.email_extractor.capabilities.export')
          ],
          gradient: "linear-gradient(135deg, var(--color-orange-310), var(--color-orange-510))"
        },
        {
          id: 'excel_expert',
          icon: 'Table',
          name: t('ai_agents.agents.excel_expert.name'),
          title: t('ai_agents.agents.excel_expert.title'),
          description: t('ai_agents.agents.excel_expert.description'),
          capabilities: [
            t('ai_agents.agents.excel_expert.capabilities.formulas'),
            t('ai_agents.agents.excel_expert.capabilities.automation'),
            t('ai_agents.agents.excel_expert.capabilities.analysis'),
            t('ai_agents.agents.excel_expert.capabilities.optimization')
          ],
          gradient: "linear-gradient(135deg, var(--color-blue-310), var(--color-blue-510))"
        },
        {
          id: 'tree_diagram',
          icon: 'GitBranch',
          name: t('ai_agents.agents.tree_diagram.name'),
          title: t('ai_agents.agents.tree_diagram.title'),
          description: t('ai_agents.agents.tree_diagram.description'),
          capabilities: [
            t('ai_agents.agents.tree_diagram.capabilities.create'),
            t('ai_agents.agents.tree_diagram.capabilities.customize'),
            t('ai_agents.agents.tree_diagram.capabilities.export'),
            t('ai_agents.agents.tree_diagram.capabilities.share')
          ],
          gradient: "linear-gradient(135deg, var(--color-green-310), var(--color-green-510))"
        }
      ]
    },
    {
      id: 'communication',
      title: t('ai_agents.categories.communication.title'),
      description: t('ai_agents.categories.communication.description'),
      agents: [
        {
          id: 'presentation_creator',
          icon: 'Presentation',
          name: t('ai_agents.agents.presentation_creator.name'),
          title: t('ai_agents.agents.presentation_creator.title'),
          description: t('ai_agents.agents.presentation_creator.description'),
          capabilities: [
            t('ai_agents.agents.presentation_creator.capabilities.create'),
            t('ai_agents.agents.presentation_creator.capabilities.design'),
            t('ai_agents.agents.presentation_creator.capabilities.export'),
            t('ai_agents.agents.presentation_creator.capabilities.collaborate')
          ],
          gradient: "linear-gradient(135deg, var(--color-orange-310), var(--color-orange-510))"
        },
        {
          id: 'social_media',
          icon: 'Share2',
          name: t('ai_agents.agents.social_media.name'),
          title: t('ai_agents.agents.social_media.title'),
          description: t('ai_agents.agents.social_media.description'),
          capabilities: [
            t('ai_agents.agents.social_media.capabilities.compose'),
            t('ai_agents.agents.social_media.capabilities.schedule'),
            t('ai_agents.agents.social_media.capabilities.analyze'),
            t('ai_agents.agents.social_media.capabilities.optimize')
          ],
          gradient: "linear-gradient(135deg, var(--color-orange-310), var(--color-orange-510))"
        }
      ]
    },
    {
      id: 'custom',
      title: t('ai_agents.categories.custom.title'),
      description: t('ai_agents.categories.custom.description'),
      agents: [
        {
          id: 'custom_builder',
          icon: 'Wand2',
          name: t('ai_agents.agents.custom_builder.name'),
          title: t('ai_agents.agents.custom_builder.title'),
          description: t('ai_agents.agents.custom_builder.description'),
          capabilities: [
            t('ai_agents.agents.custom_builder.capabilities.steps.1'),
            t('ai_agents.agents.custom_builder.capabilities.steps.2'),
            t('ai_agents.agents.custom_builder.capabilities.steps.3'),
            t('ai_agents.agents.custom_builder.capabilities.steps.4')
          ],
          gradient: "linear-gradient(135deg, var(--color-orange-310), var(--color-orange-510))"
        }
      ]
    }
  ], [t]); // Only depend on translation function

  useEffect(() => {
    const structuredData = generateStructuredData(categories, t);
    const alternateUrls = generateAlternateUrls('ai-agents');
    const canonicalUrl = getCanonicalUrl('ai-agents', language);

    updateSEO({
      title: `${t('ai_agents.hero.title')} | Allegory`,
      description: t('ai_agents.hero.subtitle'),
      keywords: 'AI insurance agents, automated insurance, digital insurance platform',
      canonicalUrl,
      alternateUrls,
      ogImage: 'https://www.allegory.app/images/ai-agents-og.jpg',
      structuredData
    });
  }, [categories, language, t, updateSEO]);

  return (
    <>
      <Helmet>
        <html lang={language} />
        {supportedLanguages.map(lang => (
          <link 
            key={lang}
            rel="alternate" 
            hreflang={lang} 
            href={`https://www.allegory.app/${lang}/ai-agents`}
          />
        ))}
        <link 
          rel="alternate" 
          hreflang="x-default" 
          href="https://www.allegory.app/en-ca/ai-agents" 
        />
        <meta property="og:locale" content={language} />
      </Helmet>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <motion.div 
            className={styles.hero}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <h1 className={styles.title}>{t('ai_agents.hero.title')}</h1>
            <p className={styles.subtitle}>{t('ai_agents.hero.subtitle')}</p>
            <LandingButton 
              href="/sign-up" 
              className={styles.ctaButton}
              // aria-label={t('ai_agents.hero.cta')}
              aria-label={t('partner.cta.primary')}
            >
              {/* {t('ai_agents.hero.cta')} */}
              {t('partner.cta.primary')}
            </LandingButton>
          </motion.div>

          <div className={styles.content}>
            {categories.map((category, index) => (
              <CategorySection
                key={category.id}
                {...category}
                index={index}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(AIAgents); // Memoize the component