// File: frontend/src/components/form/auth/OTPVerificationBase.js

import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLanguage } from '../../../contexts/LanguageContext';
import Alert from '../../Alert/Alert';
import styles from './OTPVerificationBase.module.css';

const OTPVerificationBase = ({
  length,
  onVerify,
  onResend,
  loading,
  error,
  iconSrc,
  title,
  description,
  cooldownInitialTimes = {
    first: 60,
    second: 600,
    third: 86400
  }
}) => {
  const { t } = useLanguage();
  const [otp, setOtp] = useState(Array(length).fill(''));
  const [resendAttempts, setResendAttempts] = useState(0);
  const [cooldownTime, setCooldownTime] = useState(0);
  const [resendError, setResendError] = useState(null);
  const inputRefs = useRef([]);
  const containerRef = useRef(null);
  const hiddenInputRef = useRef(null);

  // Reset OTP when error occurs
  useEffect(() => {
    if (error?.code === 'HTTP_400') {
      setOtp(Array(length).fill(''));
      inputRefs.current[0]?.focus();
    }
  }, [error, length]);

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  useEffect(() => {
    let timer;
    if (cooldownTime > 0) {
      timer = setTimeout(() => setCooldownTime(time => time - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [cooldownTime]);

  const handleFullOTPInput = (value) => {
    if (value && value.length === length) {
      const newOtp = value.split('');
      setOtp(newOtp);
      if (newOtp.every(digit => digit !== '')) {
        onVerify(newOtp.join(''));
      }
    }
  };

  useEffect(() => {
    const handlePaste = async (e) => {
      e.preventDefault();
      
      let pastedData;
      try {
        pastedData = await navigator.clipboard.readText();
      } catch {
        pastedData = e.clipboardData?.getData('text');
      }

      if (!pastedData) return;

      const numbers = pastedData.replace(/\D/g, '').split('').slice(0, length);
      
      if (numbers.length > 0) {
        const newOtp = Array(length).fill('');
        numbers.forEach((num, index) => {
          if (index < length) {
            newOtp[index] = num;
          }
        });
        
        setOtp(newOtp);

        const nextEmptyIndex = newOtp.findIndex(digit => digit === '');
        const focusIndex = nextEmptyIndex === -1 ? length - 1 : nextEmptyIndex;
        inputRefs.current[focusIndex]?.focus();

        if (newOtp.every(digit => digit !== '')) {
          onVerify(newOtp.join(''));
        }
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener('paste', handlePaste);
    }

    return () => {
      if (container) {
        container.removeEventListener('paste', handlePaste);
      }
    };
  }, [length, onVerify]);

  const handleChange = async (e, index) => {
    const { value } = e.target;
    
    if (value.length > 1) {
      const digits = value.replace(/\D/g, '').split('').slice(0, length);
      const newOtp = Array(length).fill('');
      
      digits.forEach((digit, i) => {
        if (i < length) {
          newOtp[i] = digit;
        }
      });
      
      setOtp(newOtp);
      
      const nextEmptyIndex = newOtp.findIndex(digit => digit === '');
      const focusIndex = nextEmptyIndex === -1 ? length - 1 : nextEmptyIndex;
      inputRefs.current[focusIndex]?.focus();

      if (newOtp.every(digit => digit !== '')) {
        try {
          await onVerify(newOtp.join(''));
        } catch (err) {
          if (err.code === 'HTTP_400') {
            setOtp(Array(length).fill(''));
            inputRefs.current[0]?.focus();
          }
        }
      }
      return;
    }

    if (!/^\d*$/.test(value)) return;

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value !== '' && index < length - 1) {
      inputRefs.current[index + 1].focus();
    }

    if (newOtp.every(digit => digit !== '')) {
      try {
        await onVerify(newOtp.join(''));
      } catch (err) {
        if (err.code === 'HTTP_400') {
          setOtp(Array(length).fill(''));
          inputRefs.current[0]?.focus();
        }
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace') {
      if (index > 0 && otp[index] === '') {
        inputRefs.current[index - 1].focus();
      } else if (otp[index] !== '') {
        const newOtp = [...otp];
        newOtp[index] = '';
        setOtp(newOtp);
      }
    } else if (e.key === 'ArrowLeft' && index > 0) {
      inputRefs.current[index - 1].focus();
    } else if (e.key === 'ArrowRight' && index < length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleResendCode = async () => {
    if (cooldownTime > 0 || loading) return;

    try {
      // Set resend attempts and cooldown before the API call
      // to prevent multiple clicks
      const nextAttempt = resendAttempts + 1;
      
      // Determine cooldown time based on attempt count
      if (nextAttempt === 1) {
        setCooldownTime(cooldownInitialTimes.first);
      } else if (nextAttempt === 2) {
        setCooldownTime(cooldownInitialTimes.second);
      } else {
        setCooldownTime(cooldownInitialTimes.third);
      }

      await onResend();
      setResendAttempts(nextAttempt);
      setOtp(Array(length).fill(''));
      inputRefs.current[0]?.focus();
    } catch (error) {
      // If we get the active OTP error, keep the cooldown
      if (error.code === 'HTTP_500' && error.message.includes('active OTP')) {
        // Keep the cooldown timer running
        console.error('Error resending code:', error);
      } else {
        // For other errors, reset the cooldown
        setCooldownTime(0);
        console.error('Error resending code:', error);
      }
    }
  };

  const getResendButtonText = () => {
    if (cooldownTime > 0) {
      if (cooldownTime >= 86400) {
        return t('auth.otpVerification.resendCodeInDays', {
          days: Math.ceil(cooldownTime / 86400)
        });
      }
      if (cooldownTime >= 3600) {
        return t('auth.otpVerification.resendCodeInHours', {
          hours: Math.ceil(cooldownTime / 3600)
        });
      }
      const minutes = Math.floor(cooldownTime / 60);
      const seconds = cooldownTime % 60;
      return t('auth.otpVerification.resendCodeIn', {
        time: `${minutes}:${seconds.toString().padStart(2, '0')}`
      });
    }
    return t('auth.otpVerification.resendCode');
  };

  return (
    <div className={styles.otpVerification}>
      <div className={styles.contentWrapper}>
        <img 
          className={styles.otpIcon} 
          src={iconSrc}
          alt={t('auth.otpVerification.iconAlt')} 
        />
        
        <div className={styles.textContent}>
          <h2 className={styles.title}>{title}</h2>
          <p className={styles.description}>{description}</p>
        </div>

        {/* Hidden input for auto-fill */}
        <input
          ref={hiddenInputRef}
          type="text"
          pattern="\d*"
          inputMode="numeric"
          maxLength={length}
          autoComplete="one-time-code"
          style={{ 
            position: 'absolute', 
            opacity: 0, 
            pointerEvents: 'none',
            width: '1px',
            height: '1px',
            left: '-1px',
            top: '-1px'
          }}
          onChange={(e) => handleFullOTPInput(e.target.value)}
        />

        <div 
          ref={containerRef}
          className={`${styles.otpInputContainer} ${error || resendError ? styles.error : ''}`}
          tabIndex="-1"
        >
          {otp.map((digit, index) => (
            <input
              key={index}
              ref={el => inputRefs.current[index] = el}
              className={`${styles.otpInput} ${error || resendError ? styles.error : ''}`}
              type="text"
              inputMode="numeric"
              pattern="\d*"
              maxLength={length}
              value={digit}
              onChange={(e) => handleChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              disabled={loading || resendAttempts >= 3}
              aria-label={`Digit ${index + 1}`}
              autoComplete={index === 0 ? "one-time-code" : "off"}
              data-index={index}
              name={index === 0 ? "otp" : `otp-${index}`}
              id={`otp-input-${index}`}
            />
          ))}
        </div>

        {error && (
          <p className={styles.errorMessage}>
              {error.code === 'HTTP_500' && error.message.includes('active OTP')
              ? t('auth.errors.resendCooldown', { 
                  defaultValue: error.message 
                  })
              : t(`auth.errors.${error.code}`, { 
                  defaultValue: error.message 
                  })
              }
          </p>
          )}

        <button 
          className={styles.resendButton} 
          onClick={handleResendCode}
          disabled={cooldownTime > 0 || resendAttempts >= 3 || loading}
        >
          {getResendButtonText()}
        </button>
      </div>
    </div>
  );
};

OTPVerificationBase.propTypes = {
  length: PropTypes.number.isRequired,
  onVerify: PropTypes.func.isRequired,
  onResend: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.shape({
    code: PropTypes.string,
    message: PropTypes.string
  }),
  iconSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  cooldownInitialTimes: PropTypes.shape({
    first: PropTypes.number,
    second: PropTypes.number,
    third: PropTypes.number
  })
};

export default OTPVerificationBase;