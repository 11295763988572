// File: frontend/src/pages/PropertyInsight/components/InsuranceStatistics/index.js

import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import styles from './InsuranceStatistics.module.css';

const InsuranceStatistics = () => {
  const { t } = useLanguage();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [stats, setStats] = useState({
    averageSumInsured: 0,
    totalSumInsured: 0,
    totalProperties: 0,
    totalClaims: 0,
    totalClaimAmount: 0,
    averageClaimAmount: 0
  });

  useEffect(() => {
    const fetchInsuranceStats = async () => {
      setLoading(true);
      setError(null);
      
      try {
        // This would be replaced with an actual API call
        // const response = await propertyService.getInsuranceStatistics();
        
        // Mock data for now
        await new Promise(resolve => setTimeout(resolve, 600));
        
        const mockStats = {
          averageSumInsured: 450000,
          totalSumInsured: 832500000,
          totalProperties: 1850,
          totalClaims: 320,
          totalClaimAmount: 4800000,
          averageClaimAmount: 15000
        };
        
        setStats(mockStats);
      } catch (err) {
        console.error('Error fetching insurance statistics:', err);
        setError(t('failed_to_load_insurance_statistics'));
      } finally {
        setLoading(false);
      }
    };
    
    fetchInsuranceStats();
  }, [t]);

  // Helper function to format currency
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', { 
      style: 'currency', 
      currency: 'USD',
      maximumFractionDigits: 0
    }).format(amount);
  };

  // Helper function to format large numbers
  const formatNumber = (number) => {
    return new Intl.NumberFormat('en-US').format(number);
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <div className={styles.loadingSpinner}></div>
        <p>{t('loading_insurance_statistics')}</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.error}>
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{t('insurance_statistics')}</h3>
      
      <div className={styles.statsGrid}>
        <div className={styles.statCard}>
          <div className={styles.statIcon}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M12 2L2 7l10 5 10-5-10-5z"></path>
              <path d="M2 17l10 5 10-5"></path>
              <path d="M2 12l10 5 10-5"></path>
            </svg>
          </div>
          <div className={styles.statContent}>
            <div className={styles.statValue}>{formatCurrency(stats.averageSumInsured)}</div>
            <div className={styles.statLabel}>{t('average_sum_insured')}</div>
          </div>
        </div>
        
        <div className={styles.statCard}>
          <div className={styles.statIcon}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect>
              <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
            </svg>
          </div>
          <div className={styles.statContent}>
            <div className={styles.statValue}>{formatCurrency(stats.totalSumInsured)}</div>
            <div className={styles.statLabel}>{t('total_sum_insured')}</div>
          </div>
        </div>
        
        <div className={styles.statCard}>
          <div className={styles.statIcon}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
              <rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
            </svg>
          </div>
          <div className={styles.statContent}>
            <div className={styles.statValue}>{formatNumber(stats.totalClaims)}</div>
            <div className={styles.statLabel}>{t('total_claims')}</div>
          </div>
        </div>
        
        <div className={styles.statCard}>
          <div className={styles.statIcon}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <circle cx="12" cy="12" r="10"></circle>
              <path d="M12 6v6l4 2"></path>
            </svg>
          </div>
          <div className={styles.statContent}>
            <div className={styles.statValue}>{formatCurrency(stats.totalClaimAmount)}</div>
            <div className={styles.statLabel}>{t('total_claim_amount')}</div>
          </div>
        </div>
      </div>
      
      <div className={styles.additionalStats}>
        <div className={styles.additionalStat}>
          <span className={styles.additionalStatLabel}>{t('average_claim_amount')}:</span>
          <span className={styles.additionalStatValue}>{formatCurrency(stats.averageClaimAmount)}</span>
        </div>
        <div className={styles.additionalStat}>
          <span className={styles.additionalStatLabel}>{t('total_properties')}:</span>
          <span className={styles.additionalStatValue}>{formatNumber(stats.totalProperties)}</span>
        </div>
      </div>
    </div>
  );
};

export default InsuranceStatistics;