// File: frontend/src/pages/SuperAdmin/components/ReportManagement/ReportList/index.js

import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import SuperAdminTable from '../../common/SuperAdminTable';
import SuperAdminSearch from '../../common/SuperAdminSearch';
import SuperAdminPagination from '../../common/SuperAdminPagination';
import styles from './ReportList.module.css';

const ReportList = ({ 
  onViewDetail, 
  onCreateNew, 
  onBack, 
  isCreateView = false,
  onSuccess = null
}) => {
  const { t } = useLanguage();
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('all');
  const [filterType, setFilterType] = useState('all');
  
  // Form state for create
  const [formData, setFormData] = useState({
    report_name: '',
    report_description: '',
    report_type: 'analytics',
    report_format: 'pdf',
    enterprise_id: '',
    scheduled: false,
    schedule_frequency: 'weekly',
    report_status: 'draft'
  });
  
  // For create view
  const [enterprises, setEnterprises] = useState([]);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    const fetchReports = async () => {
      setLoading(true);
      setError(null);
      
      try {
        // This would be replaced with an actual API call
        // const response = await reportService.listReports({
        //   page: currentPage,
        //   search: searchTerm,
        //   status: filterStatus !== 'all' ? filterStatus : undefined,
        //   type: filterType !== 'all' ? filterType : undefined
        // });
        
        // Mock data for now
        await new Promise(resolve => setTimeout(resolve, 800));
        
        const mockReports = [
          {
            report_id: 'report-12345',
            report_name: 'Monthly User Activity',
            report_description: 'Monthly analysis of user engagement and activity metrics',
            report_type: 'analytics',
            report_format: 'pdf',
            report_status: 'completed',
            enterprise_id: 'ent-12345',
            enterprise_name: 'Acme Corporation',
            scheduled: true,
            schedule_frequency: 'monthly',
            last_run_date: '2023-06-01T10:30:00Z',
            next_run_date: '2023-07-01T10:30:00Z',
            created_at: '2023-01-15T10:30:00Z',
            updated_at: '2023-06-01T14:45:00Z'
          },
          {
            report_id: 'report-23456',
            report_name: 'Quarterly Financial Summary',
            report_description: 'Quarterly financial performance and metrics',
            report_type: 'financial',
            report_format: 'excel',
            report_status: 'scheduled',
            enterprise_id: 'ent-12345',
            enterprise_name: 'Acme Corporation',
            scheduled: true,
            schedule_frequency: 'quarterly',
            last_run_date: '2023-04-01T09:15:00Z',
            next_run_date: '2023-07-01T09:15:00Z',
            created_at: '2023-01-10T09:15:00Z',
            updated_at: '2023-04-01T11:30:00Z'
          },
          {
            report_id: 'report-34567',
            report_name: 'Security Audit Log',
            report_description: 'Security audit logs and potential issues',
            report_type: 'security',
            report_format: 'pdf',
            report_status: 'failed',
            enterprise_id: 'ent-23456',
            enterprise_name: 'Global Industries',
            scheduled: true,
            schedule_frequency: 'weekly',
            last_run_date: '2023-06-25T08:45:00Z',
            next_run_date: '2023-07-02T08:45:00Z',
            created_at: '2023-02-05T08:45:00Z',
            updated_at: '2023-06-25T16:20:00Z'
          },
          {
            report_id: 'report-45678',
            report_name: 'Customer Satisfaction Survey',
            report_description: 'Analysis of customer satisfaction survey results',
            report_type: 'survey',
            report_format: 'pdf',
            report_status: 'draft',
            enterprise_id: 'ent-34567',
            enterprise_name: 'Tech Solutions',
            scheduled: false,
            schedule_frequency: null,
            last_run_date: null,
            next_run_date: null,
            created_at: '2023-06-20T13:10:00Z',
            updated_at: '2023-06-20T13:10:00Z'
          },
          {
            report_id: 'report-56789',
            report_name: 'Marketing Campaign Performance',
            report_description: 'Performance metrics for marketing campaigns',
            report_type: 'marketing',
            report_format: 'dashboard',
            report_status: 'completed',
            enterprise_id: 'ent-45678',
            enterprise_name: 'Innovative Systems',
            scheduled: true,
            schedule_frequency: 'weekly',
            last_run_date: '2023-06-26T11:30:00Z',
            next_run_date: '2023-07-03T11:30:00Z',
            created_at: '2023-03-15T11:30:00Z',
            updated_at: '2023-06-26T15:45:00Z'
          }
        ];
        
        // Filter based on search term, status, and type
        let filteredReports = mockReports;
        
        if (searchTerm) {
          const searchLower = searchTerm.toLowerCase();
          filteredReports = filteredReports.filter(report => 
            report.report_name.toLowerCase().includes(searchLower) ||
            report.report_description.toLowerCase().includes(searchLower) ||
            report.enterprise_name.toLowerCase().includes(searchLower)
          );
        }
        
        if (filterStatus !== 'all') {
          filteredReports = filteredReports.filter(report => 
            report.report_status === filterStatus
          );
        }
        
        if (filterType !== 'all') {
          filteredReports = filteredReports.filter(report => 
            report.report_type === filterType
          );
        }
        
        setReports(filteredReports);
        setTotalPages(Math.ceil(filteredReports.length / 10)); // Assuming 10 items per page
      } catch (err) {
        console.error('Error fetching reports:', err);
        setError(t('failed_to_load_reports'));
      } finally {
        setLoading(false);
      }
    };
    
    const fetchEnterprises = async () => {
      try {
        // This would be replaced with an actual API call
        // const response = await enterpriseService.listEnterprises();
        
        // Mock data for now
        await new Promise(resolve => setTimeout(resolve, 500));
        
        const mockEnterprises = [
          {
            enterprise_id: 'ent-12345',
            enterprise_name: 'Acme Corporation',
            enterprise_status: 'active'
          },
          {
            enterprise_id: 'ent-23456',
            enterprise_name: 'Global Industries',
            enterprise_status: 'active'
          },
          {
            enterprise_id: 'ent-34567',
            enterprise_name: 'Tech Solutions',
            enterprise_status: 'active'
          },
          {
            enterprise_id: 'ent-45678',
            enterprise_name: 'Innovative Systems',
            enterprise_status: 'active'
          }
        ];
        
        setEnterprises(mockEnterprises);
      } catch (err) {
        console.error('Error fetching enterprises:', err);
        setError(t('failed_to_load_enterprises'));
      }
    };
    
    if (!isCreateView) {
      fetchReports();
    } else {
      fetchEnterprises();
      setLoading(false);
    }
  }, [currentPage, searchTerm, filterStatus, filterType, isCreateView, t]);

  const handleSearch = (term) => {
    setSearchTerm(term);
    setCurrentPage(1);
  };

  const handleStatusFilterChange = (e) => {
    setFilterStatus(e.target.value);
    setCurrentPage(1);
  };

  const handleTypeFilterChange = (e) => {
    setFilterType(e.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    }).format(date);
  };

  const getStatusBadgeClass = (status) => {
    switch (status) {
      case 'completed':
        return styles.statusCompleted;
      case 'scheduled':
        return styles.statusScheduled;
      case 'running':
        return styles.statusRunning;
      case 'failed':
        return styles.statusFailed;
      case 'draft':
        return styles.statusDraft;
      default:
        return '';
    }
  };

  const getTypeBadgeClass = (type) => {
    switch (type) {
      case 'analytics':
        return styles.typeAnalytics;
      case 'financial':
        return styles.typeFinancial;
      case 'security':
        return styles.typeSecurity;
      case 'survey':
        return styles.typeSurvey;
      case 'marketing':
        return styles.typeMarketing;
      default:
        return '';
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSaving(true);
    setError(null);
    
    try {
      // This would be replaced with an actual API call
      // const response = await reportService.createReport(formData);
      
      // Mock response for now
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      const mockResponse = {
        success: true,
        report_id: `report-${Math.random().toString(36).substring(2, 10)}`
      };
      
      // Call the success callback with the new report ID
      if (onSuccess) {
        onSuccess(mockResponse.report_id);
      }
    } catch (err) {
      console.error('Error creating report:', err);
      setError(t('failed_to_create_report'));
    } finally {
      setSaving(false);
    }
  };

  const columns = [
    {
      header: t('report_name'),
      accessor: 'report_name',
      cell: (row) => <span className={styles.nameCell}>{row.report_name}</span>
    },
    {
      header: t('enterprise'),
      accessor: 'enterprise_name',
      cell: (row) => <span>{row.enterprise_name}</span>
    },
    {
      header: t('type'),
      accessor: 'report_type',
      cell: (row) => (
        <span className={`${styles.typeBadge} ${getTypeBadgeClass(row.report_type)}`}>
          {row.report_type}
        </span>
      )
    },
    {
      header: t('status'),
      accessor: 'report_status',
      cell: (row) => (
        <span className={`${styles.statusBadge} ${getStatusBadgeClass(row.report_status)}`}>
          {row.report_status}
        </span>
      )
    },
    {
      header: t('scheduled'),
      accessor: 'scheduled',
      cell: (row) => <span>{row.scheduled ? t('yes') : t('no')}</span>
    },
    {
      header: t('last_run'),
      accessor: 'last_run_date',
      cell: (row) => <span>{formatDate(row.last_run_date)}</span>
    },
    {
      header: t('next_run'),
      accessor: 'next_run_date',
      cell: (row) => <span>{formatDate(row.next_run_date)}</span>
    },
    {
      header: t('actions'),
      accessor: 'actions',
      cell: (row) => (
        <div className={styles.actions}>
          <button 
            className={styles.actionButton}
            onClick={() => onViewDetail(row.report_id)}
          >
            <i className="fas fa-eye"></i>
            <span>{t('view')}</span>
          </button>
        </div>
      )
    }
  ];

  // Render create report form
  if (isCreateView) {
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <button className={styles.backButton} onClick={onBack} disabled={saving}>
            <i className="fas fa-arrow-left"></i>
            <span>{t('back')}</span>
          </button>
        </div>
        
        {error && (
          <div className={styles.error}>
            <i className="fas fa-exclamation-circle"></i>
            <span>{error}</span>
          </div>
        )}
        
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.formSection}>
            <h3 className={styles.sectionTitle}>{t('report_information')}</h3>
            <div className={styles.formGrid}>
              <div className={styles.formGroup}>
                <label htmlFor="report_name" className={styles.label}>
                  {t('report_name')} <span className={styles.required}>*</span>
                </label>
                <input
                  type="text"
                  id="report_name"
                  name="report_name"
                  className={styles.input}
                  value={formData.report_name}
                  onChange={handleChange}
                  required
                  disabled={saving}
                />
              </div>
              
              <div className={styles.formGroup}>
                <label htmlFor="enterprise_id" className={styles.label}>
                  {t('enterprise')} <span className={styles.required}>*</span>
                </label>
                <select
                  id="enterprise_id"
                  name="enterprise_id"
                  className={styles.select}
                  value={formData.enterprise_id}
                  onChange={handleChange}
                  required
                  disabled={saving}
                >
                  <option value="">{t('select_enterprise')}</option>
                  {enterprises.map((enterprise) => (
                    <option key={enterprise.enterprise_id} value={enterprise.enterprise_id}>
                      {enterprise.enterprise_name}
                    </option>
                  ))}
                </select>
              </div>
              
              <div className={styles.formGroup}>
                <label htmlFor="report_type" className={styles.label}>
                  {t('report_type')} <span className={styles.required}>*</span>
                </label>
                <select
                  id="report_type"
                  name="report_type"
                  className={styles.select}
                  value={formData.report_type}
                  onChange={handleChange}
                  required
                  disabled={saving}
                >
                  <option value="analytics">{t('analytics')}</option>
                  <option value="financial">{t('financial')}</option>
                  <option value="security">{t('security')}</option>
                  <option value="survey">{t('survey')}</option>
                  <option value="marketing">{t('marketing')}</option>
                </select>
              </div>
              
              <div className={styles.formGroup}>
                <label htmlFor="report_format" className={styles.label}>
                  {t('report_format')} <span className={styles.required}>*</span>
                </label>
                <select
                  id="report_format"
                  name="report_format"
                  className={styles.select}
                  value={formData.report_format}
                  onChange={handleChange}
                  required
                  disabled={saving}
                >
                  <option value="pdf">PDF</option>
                  <option value="excel">Excel</option>
                  <option value="csv">CSV</option>
                  <option value="dashboard">Dashboard</option>
                </select>
              </div>
              
              <div className={styles.formGroup}>
                <label htmlFor="report_status" className={styles.label}>
                  {t('status')} <span className={styles.required}>*</span>
                </label>
                <select
                  id="report_status"
                  name="report_status"
                  className={styles.select}
                  value={formData.report_status}
                  onChange={handleChange}
                  required
                  disabled={saving}
                >
                  <option value="draft">{t('draft')}</option>
                  <option value="scheduled">{t('scheduled')}</option>
                </select>
              </div>
              
              <div className={styles.formGroupFull}>
                <label htmlFor="report_description" className={styles.label}>
                  {t('description')} <span className={styles.required}>*</span>
                </label>
                <textarea
                  id="report_description"
                  name="report_description"
                  className={styles.textarea}
                  value={formData.report_description}
                  onChange={handleChange}
                  rows={3}
                  required
                  disabled={saving}
                ></textarea>
              </div>
            </div>
          </div>
          
          <div className={styles.formSection}>
            <h3 className={styles.sectionTitle}>{t('scheduling_information')}</h3>
            <div className={styles.formGrid}>
              <div className={styles.formGroup}>
                <div className={styles.checkboxGroup}>
                  <input
                    type="checkbox"
                    id="scheduled"
                    name="scheduled"
                    className={styles.checkbox}
                    checked={formData.scheduled}
                    onChange={handleChange}
                    disabled={saving}
                  />
                  <label htmlFor="scheduled" className={styles.checkboxLabel}>
                    {t('schedule_this_report')}
                  </label>
                </div>
              </div>
              
              {formData.scheduled && (
                <div className={styles.formGroup}>
                  <label htmlFor="schedule_frequency" className={styles.label}>
                    {t('frequency')} <span className={styles.required}>*</span>
                  </label>
                  <select
                    id="schedule_frequency"
                    name="schedule_frequency"
                    className={styles.select}
                    value={formData.schedule_frequency}
                    onChange={handleChange}
                    required={formData.scheduled}
                    disabled={saving}
                  >
                    <option value="daily">{t('daily')}</option>
                    <option value="weekly">{t('weekly')}</option>
                    <option value="monthly">{t('monthly')}</option>
                    <option value="quarterly">{t('quarterly')}</option>
                  </select>
                </div>
              )}
            </div>
          </div>
          
          <div className={styles.formActions}>
            <button 
              type="button" 
              className={styles.cancelButton} 
              onClick={onBack}
              disabled={saving}
            >
              {t('cancel')}
            </button>
            <button 
              type="submit" 
              className={styles.submitButton}
              disabled={saving}
            >
              {saving ? (
                <>
                  <span className={styles.spinner}></span>
                  <span>{t('creating')}</span>
                </>
              ) : (
                t('create_report')
              )}
            </button>
          </div>
        </form>
      </div>
    );
  }

  // Render report list view
  return (
    <div className={styles.container}>
      <div className={styles.toolbar}>
        <div className={styles.filters}>
          <SuperAdminSearch 
            placeholder={t('search_reports')}
            onSearch={handleSearch}
          />
          
          <div className={styles.filterGroup}>
            <label htmlFor="statusFilter" className={styles.filterLabel}>
              {t('status')}:
            </label>
            <select
              id="statusFilter"
              className={styles.filterSelect}
              value={filterStatus}
              onChange={handleStatusFilterChange}
            >
              <option value="all">{t('all_statuses')}</option>
              <option value="completed">{t('completed')}</option>
              <option value="scheduled">{t('scheduled')}</option>
              <option value="running">{t('running')}</option>
              <option value="failed">{t('failed')}</option>
              <option value="draft">{t('draft')}</option>
            </select>
          </div>
          
          <div className={styles.filterGroup}>
            <label htmlFor="typeFilter" className={styles.filterLabel}>
              {t('type')}:
            </label>
            <select
              id="typeFilter"
              className={styles.filterSelect}
              value={filterType}
              onChange={handleTypeFilterChange}
            >
              <option value="all">{t('all_types')}</option>
              <option value="analytics">{t('analytics')}</option>
              <option value="financial">{t('financial')}</option>
              <option value="security">{t('security')}</option>
              <option value="survey">{t('survey')}</option>
              <option value="marketing">{t('marketing')}</option>
            </select>
          </div>
        </div>
        
        <button className={styles.createButton} onClick={onCreateNew}>
          <i className="fas fa-plus"></i>
          <span>{t('create_report')}</span>
        </button>
      </div>
      
      {error && <div className={styles.error}>{error}</div>}
      
      <SuperAdminTable
        columns={columns}
        data={reports}
        loading={loading}
        emptyMessage={t('no_reports_found')}
      />
      
      <div className={styles.pagination}>
        <SuperAdminPagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default ReportList;