// File: frontend/src/pages/PropertyDetail/components/PropertyOverview/index.js

import React from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import styles from './PropertyOverview.module.css';

// Helper function to format date
const formatDate = (dateString) => {
  if (!dateString) return 'N/A';
  
  const date = new Date(dateString);
  
  // Check if date is valid
  if (isNaN(date.getTime())) return 'Invalid Date';
  
  return date.toLocaleDateString();
};

const PropertyOverview = ({ property }) => {
  const { t } = useLanguage();

  return (
    <div className={styles.container}>
      <div className={styles.grid}>
        <div className={styles.card}>
          <h3 className={styles.cardTitle}>{t('property_information')}</h3>
          <div className={styles.cardContent}>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('property_id')}:</div>
              <div className={styles.infoValue}>{property.property_id}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('property_type')}:</div>
              <div className={styles.infoValue}>{property.property_type}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('zoning_classification')}:</div>
              <div className={styles.infoValue}>{property.zoning_classification}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('income_property')}:</div>
              <div className={styles.infoValue}>
                {property.is_income_property ? t('yes') : t('no')}
              </div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('land_area')}:</div>
              <div className={styles.infoValue}>
                {property.land_area} {property.area_measure}
              </div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('geographical_exposure')}:</div>
              <div className={styles.infoValue}>{property.geographical_exposure}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('added_on')}:</div>
              <div className={styles.infoValue}>{formatDate(property.created_at)}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('last_updated')}:</div>
              <div className={styles.infoValue}>{formatDate(property.updated_at)}</div>
            </div>
          </div>
        </div>
        
        <div className={styles.card}>
          <h3 className={styles.cardTitle}>{t('address_details')}</h3>
          <div className={styles.cardContent}>
            {property.masterAddress ? (
              <>
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('address_line_1')}:</div>
                  <div className={styles.infoValue}>{property.masterAddress.address_line_1}</div>
                </div>
                {property.masterAddress.address_line_2 && (
                  <div className={styles.infoGroup}>
                    <div className={styles.infoLabel}>{t('address_line_2')}:</div>
                    <div className={styles.infoValue}>{property.masterAddress.address_line_2}</div>
                  </div>
                )}
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('city')}:</div>
                  <div className={styles.infoValue}>{property.masterAddress.city}</div>
                </div>
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('province_state')}:</div>
                  <div className={styles.infoValue}>{property.masterAddress.province_state_name}</div>
                </div>
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('postal_code')}:</div>
                  <div className={styles.infoValue}>{property.masterAddress.postal_code}</div>
                </div>
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('country')}:</div>
                  <div className={styles.infoValue}>{property.masterAddress.country_code}</div>
                </div>
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('address_verified')}:</div>
                  <div className={styles.infoValue}>
                    {property.masterAddress.is_verified ? t('yes') : t('no')}
                  </div>
                </div>
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('verification_date')}:</div>
                  <div className={styles.infoValue}>
                    {formatDate(property.masterAddress.verification_date)}
                  </div>
                </div>
              </>
            ) : (
              <div className={styles.noData}>{t('no_address_details_available')}</div>
            )}
          </div>
        </div>
      </div>
      
      <div className={styles.card}>
        <h3 className={styles.cardTitle}>{t('building_overview')}</h3>
        <div className={styles.cardContent}>
          {property.building ? (
            <div className={styles.buildingGrid}>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('building_type')}:</div>
                <div className={styles.infoValue}>{property.building.building_type}</div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('year_built')}:</div>
                <div className={styles.infoValue}>{property.building.year_built}</div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('last_renovation')}:</div>
                <div className={styles.infoValue}>{formatDate(property.building.last_renovation_date)}</div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('number_of_floors')}:</div>
                <div className={styles.infoValue}>{property.building.number_of_floors}</div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('total_area')}:</div>
                <div className={styles.infoValue}>
                  {property.building.total_area} {property.building.area_measure}
                </div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('construction_type')}:</div>
                <div className={styles.infoValue}>{property.building.construction_type}</div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('foundation_type')}:</div>
                <div className={styles.infoValue}>{property.building.foundation_type}</div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('roof_type')}:</div>
                <div className={styles.infoValue}>{property.building.roof_type}</div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('exterior_wall_type')}:</div>
                <div className={styles.infoValue}>{property.building.exterior_wall_type}</div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('basement_type')}:</div>
                <div className={styles.infoValue}>{property.building.basement_type}</div>
              </div>
            </div>
          ) : (
            <div className={styles.noData}>{t('no_building_information_available')}</div>
          )}
        </div>
      </div>
      
      <div className={styles.card}>
        <h3 className={styles.cardTitle}>{t('fire_protection')}</h3>
        <div className={styles.cardContent}>
          <div className={styles.fireProtectionGrid}>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('protection_class')}:</div>
              <div className={styles.infoValue}>{property.protection_class}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('protection_class_basis')}:</div>
              <div className={styles.infoValue}>{property.protection_class_basis}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('fire_hall_distance')}:</div>
              <div className={styles.infoValue}>
                {property.fire_hall_distance} {property.fire_hall_distance_unit}
              </div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('fire_hydrant_distance')}:</div>
              <div className={styles.infoValue}>
                {property.fire_hydrant_distance} {property.fire_hydrant_distance_unit}
              </div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('fire_hall_name')}:</div>
              <div className={styles.infoValue}>{property.fire_hall_name}</div>
            </div>
          </div>
        </div>
      </div>
      
      {property.unit && (
        <div className={styles.card}>
          <h3 className={styles.cardTitle}>{t('unit_information')}</h3>
          <div className={styles.cardContent}>
            <div className={styles.unitGrid}>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('unit_type')}:</div>
                <div className={styles.infoValue}>{property.unit.unit_type}</div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('living_area')}:</div>
                <div className={styles.infoValue}>
                  {property.unit.living_area} {property.unit.area_measure}
                </div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('bedrooms')}:</div>
                <div className={styles.infoValue}>{property.unit.number_of_bedrooms}</div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('full_bathrooms')}:</div>
                <div className={styles.infoValue}>{property.unit.number_of_full_bathrooms}</div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('half_bathrooms')}:</div>
                <div className={styles.infoValue}>{property.unit.number_of_half_bathrooms}</div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('kitchens')}:</div>
                <div className={styles.infoValue}>{property.unit.number_of_kitchens}</div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('dens')}:</div>
                <div className={styles.infoValue}>{property.unit.number_of_dens}</div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('basement_apartment')}:</div>
                <div className={styles.infoValue}>
                  {property.unit.has_basement_apartment ? t('yes') : t('no')}
                </div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('kitchen_finishes')}:</div>
                <div className={styles.infoValue}>{property.unit.kitchen_finishes}</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PropertyOverview;