// File: frontend/src/pages/SuperAdmin/components/common/SuperAdminSearch/index.js

import React, { useState } from 'react';
import styles from './SuperAdminSearch.module.css';

const SuperAdminSearch = ({ 
  placeholder = 'Search...', 
  onSearch, 
  initialValue = '',
  debounceTime = 300
}) => {
  const [searchTerm, setSearchTerm] = useState(initialValue);
  
  const handleChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    
    // Debounce search to avoid too many requests
    if (onSearch) {
      clearTimeout(window.searchTimeout);
      window.searchTimeout = setTimeout(() => {
        onSearch(value);
      }, debounceTime);
    }
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
    if (onSearch) {
      clearTimeout(window.searchTimeout);
      onSearch(searchTerm);
    }
  };
  
  const handleClear = () => {
    setSearchTerm('');
    if (onSearch) {
      onSearch('');
    }
  };
  
  return (
    <form className={styles.searchForm} onSubmit={handleSubmit}>
      <div className={styles.searchContainer}>
        <i className={`fas fa-search ${styles.searchIcon}`}></i>
        <input
          type="text"
          className={styles.searchInput}
          placeholder={placeholder}
          value={searchTerm}
          onChange={handleChange}
        />
        {searchTerm && (
          <button 
            type="button" 
            className={styles.clearButton}
            onClick={handleClear}
          >
            <i className="fas fa-times"></i>
          </button>
        )}
      </div>
    </form>
  );
};

export default SuperAdminSearch;