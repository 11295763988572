// File: src/contexts/SEOContext.js

import React, { createContext, useContext, useState, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { SUPPORTED_LOCALES, DEFAULT_LOCALE } from '../config/i18n.config';

const SEOContext = createContext();

const defaultSEOData = {
  title: 'Allegory - Intelligent Insurance Solutions',
  description: 'AI-powered insurance solutions for modern businesses. Revolutionizing risk assessment and management.',
  canonicalUrl: 'https://www.allegory.app',
  ogImage: 'https://www.allegory.app/og-image.jpg',
  ogType: 'website',
  twitterCard: 'summary_large_image',
  keywords: 'insurance technology, insurtech, AI insurance, digital insurance platform',
  author: 'Allegory Insurance Technologies',
  structuredData: [],
  noIndex: false,
  alternateUrls: {}
};

export const SEOProvider = ({ children }) => {
  const location = useLocation();
  const [seoData, setSeoData] = useState(defaultSEOData);

  const updateSEO = useCallback((newData) => {
    setSeoData(prevData => {
      // Only update if there are actual changes
      const hasChanges = Object.entries(newData).some(([key, value]) => {
        if (key === 'structuredData' || key === 'alternateUrls') {
          return JSON.stringify(prevData[key]) !== JSON.stringify(value);
        }
        return prevData[key] !== value;
      });

      return hasChanges ? { ...prevData, ...newData } : prevData;
    });
  }, []);

  const contextValue = useMemo(() => ({
    seoData,
    updateSEO,
    defaultSEOData
  }), [seoData, updateSEO]);

  const structuredDataScripts = useMemo(() => {
    if (!seoData.structuredData) return null;

    const dataArray = Array.isArray(seoData.structuredData) 
      ? seoData.structuredData 
      : [seoData.structuredData];

    return dataArray.map((data, index) => (
      <script
        key={`structured-data-${index}`}
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
      />
    ));
  }, [seoData.structuredData]);

  return (
    <SEOContext.Provider value={contextValue}>
      {children}
      {structuredDataScripts}
    </SEOContext.Provider>
  );
};

export const useSEO = () => {
  const context = useContext(SEOContext);
  if (!context) {
    throw new Error('useSEO must be used within a SEOProvider');
  }
  return context;
};