// File: frontend/src/pages/UMS/components/UserSearchForm/index.js

import React, { useState } from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import styles from './UserSearchForm.module.css';

const UserSearchForm = ({ onSearch, loading }) => {
  const { t } = useLanguage();
  const [searchField, setSearchField] = useState('user_id');
  const [searchQuery, setSearchQuery] = useState('');
  const [dateRange, setDateRange] = useState('');
  const [userType, setUserType] = useState('');
  const [accountStatus, setAccountStatus] = useState('');
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!searchQuery.trim() && !dateRange && !userType && !accountStatus) return;
    
    const searchParams = {};
    
    // Only add search field and query if they're provided
    if (searchQuery.trim()) {
      searchParams.field = searchField;
      searchParams.query = searchQuery;
    }
    
    // Add optional filters if they're provided
    if (dateRange) searchParams.date_range = dateRange;
    if (userType) searchParams.user_type = userType;
    if (accountStatus) searchParams.account_status = accountStatus;
    
    onSearch(searchParams);
  };

  const handleClearFilters = () => {
    setSearchQuery('');
    setDateRange('');
    setUserType('');
    setAccountStatus('');
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>{t('search_users')}</h2>
      
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.formGroup}>
          <label htmlFor="searchField" className={styles.label}>
            {t('search_by')}
          </label>
          <select
            id="searchField"
            className={styles.select}
            value={searchField}
            onChange={(e) => setSearchField(e.target.value)}
            disabled={loading}
          >
            <option value="user_id">{t('user_id')}</option>
            <option value="first_name">{t('first_name')}</option>
            <option value="last_name">{t('last_name')}</option>
            <option value="email_address">{t('email_address')}</option>
            <option value="mobile_phone_number">{t('mobile_phone_number')}</option>
          </select>
        </div>
        
        <div className={styles.formGroup}>
          <label htmlFor="searchQuery" className={styles.label}>
            {t('search_term')}
          </label>
          <input
            id="searchQuery"
            type="text"
            className={styles.input}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder={t('enter_search_term')}
            disabled={loading}
          />
        </div>
        
        <div className={styles.formActions}>
          <button 
            type="button" 
            className={styles.advancedButton}
            onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}
          >
            {showAdvancedFilters ? t('hide_advanced_filters') : t('show_advanced_filters')}
          </button>
          
          <button 
            type="submit" 
            className={styles.button}
            disabled={loading || (!searchQuery.trim() && !dateRange && !userType && !accountStatus)}
          >
            {loading ? t('searching') : t('search')}
          </button>
        </div>
        
        {showAdvancedFilters && (
          <div className={styles.advancedFilters}>
            <div className={styles.formGroup}>
              <label htmlFor="dateRange" className={styles.label}>
                {t('date_range')}
              </label>
              <select
                id="dateRange"
                className={styles.select}
                value={dateRange}
                onChange={(e) => setDateRange(e.target.value)}
                disabled={loading}
              >
                <option value="">{t('select_date_range')}</option>
                <option value="today">{t('today')}</option>
                <option value="yesterday">{t('yesterday')}</option>
                <option value="this_week">{t('this_week')}</option>
                <option value="last_week">{t('last_week')}</option>
                <option value="last_seven_days">{t('last_seven_days')}</option>
                <option value="last_fourteen_days">{t('last_fourteen_days')}</option>
                <option value="last_thirty_days">{t('last_thirty_days')}</option>
                <option value="last_sixty_days">{t('last_sixty_days')}</option>
                <option value="last_ninety_days">{t('last_ninety_days')}</option>
                <option value="quarter_to_date">{t('quarter_to_date')}</option>
                <option value="this_month">{t('this_month')}</option>
                <option value="last_month">{t('last_month')}</option>
                <option value="last_twelve_months">{t('last_twelve_months')}</option>
                <option value="this_year">{t('this_year')}</option>
                <option value="last_year">{t('last_year')}</option>
                <option value="all_time">{t('all_time')}</option>
              </select>
            </div>
            
            <div className={styles.formGroup}>
              <label htmlFor="userType" className={styles.label}>
                {t('user_type')}
              </label>
              <select
                id="userType"
                className={styles.select}
                value={userType}
                onChange={(e) => setUserType(e.target.value)}
                disabled={loading}
              >
                <option value="">{t('select_user_type')}</option>
                <option value="1">{t('standard_user')}</option>
                <option value="2">{t('premium_user')}</option>
                <option value="3">{t('admin_user')}</option>
                <option value="4">{t('system_user')}</option>
              </select>
            </div>
            
            <div className={styles.formGroup}>
              <label htmlFor="accountStatus" className={styles.label}>
                {t('account_status')}
              </label>
              <select
                id="accountStatus"
                className={styles.select}
                value={accountStatus}
                onChange={(e) => setAccountStatus(e.target.value)}
                disabled={loading}
              >
                <option value="">{t('select_account_status')}</option>
                <option value="494">{t('pending_activation')}</option>
                <option value="495">{t('active')}</option>
                <option value="496">{t('suspended')}</option>
                <option value="497">{t('deactivated')}</option>
                <option value="498">{t('locked')}</option>
              </select>
            </div>
            
            <button 
              type="button" 
              className={styles.clearButton}
              onClick={handleClearFilters}
              disabled={loading}
            >
              {t('clear_filters')}
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

export default UserSearchForm;