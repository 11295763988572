// File: frontend/src/pages/Auth/SignUp.js

import React, { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../contexts/LanguageContext';
import { useSignUp } from '../../contexts/SignUpContext';
import { useAuth } from '../../contexts/AuthContextV2';
import { SUPPORTED_LOCALES } from '../../config/i18n.config';
import { AUTH_FLOW_STATES } from '../../contexts/AuthContextV2';
import AuthLayout from '../../components/layout/AuthLayout/AuthLayout';
import SignUpForm from '../../components/form/auth/SignUpForm';
import OTPVerificationSignUp from '../../components/Auth/OTPVerificationSignUp';
import StepIndicator from '../../components/StepIndicator/StepIndicator';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import styles from './SignUp.module.css';

const SignUp = () => {
  const { t, language } = useLanguage();
  const navigate = useNavigate();
  const { 
    formData,
    isValid, 
    getStepData,
    updateStep,
    getFormattedData,
    resetForm,
    validateFormData
  } = useSignUp();
  
  const { 
    initiateSignUp, 
    verifySignUpMobile, 
    completeSignUp, 
    loading, 
    error: authError,
    clearError,
    flowState,
    flowData,
    updateFlowState
  } = useAuth();

  // Make sure flowState is initialized
  useEffect(() => {
    if (!flowState) {
      updateFlowState(AUTH_FLOW_STATES.SIGNUP.INITIAL);
    }
  }, [flowState, updateFlowState]);

  const steps = [
    { 
      number: 1, 
      label: t('auth.signUp.steps.accountInfo'),
      status: flowState === AUTH_FLOW_STATES.SIGNUP.INITIAL ? 'current' : 
             flowState === AUTH_FLOW_STATES.SIGNUP.MOBILE_VERIFICATION || 
             flowState === AUTH_FLOW_STATES.SIGNUP.COMPLETE_SIGNUP ? 'completed' : 'pending'
    },
    { 
      number: 2, 
      label: t('auth.signUp.steps.verification'),
      status: flowState === AUTH_FLOW_STATES.SIGNUP.MOBILE_VERIFICATION ? 'current' :
             flowState === AUTH_FLOW_STATES.SIGNUP.COMPLETE_SIGNUP ? 'completed' : 'pending'
    },
    { 
      number: 3, 
      label: t('auth.signUp.steps.companyInfo'),
      status: flowState === AUTH_FLOW_STATES.SIGNUP.COMPLETE_SIGNUP ? 'current' : 'pending'
    }
  ];

  // Handle cleanup only when navigating away
  useEffect(() => {
    const cleanup = () => {
      const currentPath = window.location.pathname;
      if (!currentPath.includes('/sign-up')) {
        clearError();
        resetForm();
      }
    };

    // Add navigation listener
    window.addEventListener('beforeunload', cleanup);

    return () => {
      window.removeEventListener('beforeunload', cleanup);
    };
  }, [clearError, resetForm]);

  const handleNextClick = async () => {
    try {
      // Validate current step
      if (!validateFormData(formData, flowState)) {
        return;
      }
  
      switch (flowState) {
        case AUTH_FLOW_STATES.SIGNUP.INITIAL:
          const initialData = getStepData(AUTH_FLOW_STATES.SIGNUP.INITIAL);
          await initiateSignUp({
            ...formData,  // Keep original form data
            ...initialData // Include formatted data
          });
          updateStep(AUTH_FLOW_STATES.SIGNUP.MOBILE_VERIFICATION);
          break;
  
        case AUTH_FLOW_STATES.SIGNUP.MOBILE_VERIFICATION:
          // Preserve all data when moving to complete signup
          await updateFlowState(AUTH_FLOW_STATES.SIGNUP.COMPLETE_SIGNUP, {
            ...flowData,
            ...formData
          });
          updateStep(AUTH_FLOW_STATES.SIGNUP.COMPLETE_SIGNUP);
          break;
  
        case AUTH_FLOW_STATES.SIGNUP.COMPLETE_SIGNUP:
          if (!flowData?.mobile_phone_number) {
            throw new Error('Missing verification data');
          }
  
          const companyData = {
            mobile_phone_number: flowData.mobile_phone_number,
            organization_name: formData.organization_name,
            organization_size_id: parseInt(formData.organization_size_id),
            primary_language_id: SUPPORTED_LOCALES[language].categoryValueId,
            dataset_region_id: parseInt(formData.dataset_region_id)
          };
          
          await completeSignUp(companyData);
          navigate('/email-verification', { 
            state: { 
              email: flowData.email_address 
            } 
          });
          break;
  
        default:
          break;
      }
    } catch (error) {
      console.error('Signup error:', error);
    }
  };

  const handleOTPVerification = useCallback(async (otp) => {
    try {
      await verifySignUpMobile(otp);
    } catch (error) {
      console.error('OTP verification error:', error);
    }
  }, [verifySignUpMobile]);

  const handleBackClick = useCallback(() => {
    if (flowState === AUTH_FLOW_STATES.SIGNUP.MOBILE_VERIFICATION || 
      flowState === AUTH_FLOW_STATES.SIGNUP.COMPLETE_SIGNUP) {
      updateFlowState(AUTH_FLOW_STATES.SIGNUP.INITIAL);
    } else {
      navigate('/');
    }
  }, [flowState, updateFlowState, navigate]);

  const getContactInfo = useCallback(() => {
    return flowData?.mobile_phone_number || formData.email_address;
  }, [flowData, formData.email_address]);

  const isNextButtonDisabled = useCallback(() => {
    // console.debug('Next button state:', {
    //   loading,
    //   flowState,
    //   isValid,
    //   formData
    // });

    if (loading) return true;
    if (flowState === AUTH_FLOW_STATES.SIGNUP.MOBILE_VERIFICATION) return false;
    
    if (flowState === AUTH_FLOW_STATES.SIGNUP.INITIAL) {
      return !formData.email_address || !formData.first_name || 
             !formData.last_name || !formData.phone_number || !isValid;
    }
    
    if (flowState === AUTH_FLOW_STATES.SIGNUP.COMPLETE_SIGNUP) {
      return !formData.organization_name || 
             !formData.organization_size_id || 
             !formData.dataset_region_id || !isValid;
    }

    return true;
  }, [loading, flowState, isValid, formData]);

  const renderCurrentStep = () => {
    switch (flowState) {
      case AUTH_FLOW_STATES.SIGNUP.INITIAL:
        return <SignUpForm />;
      
      case AUTH_FLOW_STATES.SIGNUP.MOBILE_VERIFICATION:
        return (
          <OTPVerificationSignUp
            onVerify={handleOTPVerification}
            contactInfo={getContactInfo()}
            resendDisabled={loading}
            error={authError}
          />
        );
      
      case AUTH_FLOW_STATES.SIGNUP.COMPLETE_SIGNUP:
        return <SignUpForm />;
      
      default:
        return <SignUpForm />;
    }
  };

  return (
    <>
      {authError && (
        <ErrorMessage 
          error={authError}
          variant="floating"
          onClose={clearError}
        />
      )}
      <AuthLayout
        title={t('auth.signUp.title')}
        showHomeButton={true}
        showBackButton={flowState !== AUTH_FLOW_STATES.SIGNUP.INITIAL}
        showNextButton={flowState !== AUTH_FLOW_STATES.SIGNUP.MOBILE_VERIFICATION}
        onBackClick={handleBackClick}
        onNextClick={handleNextClick}
        nextButtonText={flowState === AUTH_FLOW_STATES.SIGNUP.COMPLETE_SIGNUP 
          ? t('auth.signUp.create') 
          : t('auth.signUp.next')}
        alignNextButtonRight={flowState === AUTH_FLOW_STATES.SIGNUP.INITIAL}
        isNextButtonDisabled={isNextButtonDisabled()}
        isVerifying={loading}
      >
        <div className={styles.signUpPage}>
          <div className={styles.stepIndicatorWrapper}>
            <StepIndicator 
              steps={steps} 
              currentStep={
                flowState === AUTH_FLOW_STATES.SIGNUP.INITIAL ? 1 :
                flowState === AUTH_FLOW_STATES.SIGNUP.MOBILE_VERIFICATION ? 2 :
                flowState === AUTH_FLOW_STATES.SIGNUP.COMPLETE_SIGNUP ? 3 : 1
              }
            />
          </div>
          <div className={styles.formWrapper}>
            {renderCurrentStep()}
          </div>
        </div>
      </AuthLayout>
    </>
  );
};

export default SignUp;