// File: frontend/src/contexts/FeatureFlagContext.js

import React, { createContext, useState, useContext } from 'react';

const FeatureFlagContext = createContext();

export const FeatureFlagProvider = ({ children }) => {
  const [features, setFeatures] = useState({});

  const setFeature = (featureName, isEnabled) => {
    setFeatures(prev => ({ ...prev, [featureName]: isEnabled }));
  };

  const isFeatureEnabled = (featureName) => {
    return features[featureName] || false;
  };

  return (
    <FeatureFlagContext.Provider value={{ setFeature, isFeatureEnabled }}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

export const useFeatureFlag = () => {
  const context = useContext(FeatureFlagContext);
  if (context === undefined) {
    throw new Error('useFeatureFlag must be used within a FeatureFlagProvider');
  }
  return context;
};