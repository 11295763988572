// File: frontend/src/pages/AIAgents/components/AgentCard.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContextV2';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import * as LucideIcons from 'lucide-react';
import { useLanguage } from '../../../contexts/LanguageContext';
import styles from './AgentCard.module.css';
import LandingButton from '../../../components/Button/LandingButton';

const AgentCard = ({ id, name, title, description, capabilities, icon, gradient, index }) => {
  const { t } = useLanguage();
  const Icon = LucideIcons[icon];
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();

  const handleAgentAccess = () => {
    // Create agent path based on ID
    const agentPath = `/ai1/${id}`;
    
    if (isAuthenticated) {
      navigate(agentPath);
    } else {
      // Store intended destination
      localStorage.setItem('redirectAfterAuth', agentPath);
      navigate('/sign-in');
    }
  };

  return (
    <motion.div
      className={styles.container}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: index * 0.1 }}
      whileHover={{ scale: 1.02 }}
    >
      <div 
        className={styles.header}
        style={{ background: gradient }}
      >
        <div className={styles.iconWrapper}>
          <Icon 
            className={styles.icon} 
            aria-hidden="true"
          />
        </div>
        <h3 className={styles.name}>{name}</h3>
        <p className={styles.title}>{title}</p>
      </div>
      <div className={styles.content}>
        <p className={styles.description}>{description}</p>
        <div className={styles.capabilities}>
          <h4 className={styles.capabilitiesTitle}>
            {t('ai_agents.capabilities_title')}
          </h4>
          <ul className={styles.capabilitiesList}>
            {capabilities.map((capability, index) => (
              <motion.li 
                key={index} 
                className={styles.capability}
                initial={{ opacity: 0, x: -10 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                <div className={styles.bullet} />
                <span>{capability}</span>
              </motion.li>
            ))}
          </ul>
        </div>
        <LandingButton 
        //   href={`/ai1/${id}`}
          href={`/sign-in`}
          className={styles.agentCta}
          aria-label={`${t('access')} ${name}`}
        >
          {`${t('access')} ${name}`}
        </LandingButton>
      </div>
    </motion.div>
  );
};

AgentCard.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  capabilities: PropTypes.arrayOf(PropTypes.string).isRequired,
  icon: PropTypes.string.isRequired,
  gradient: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};

export default AgentCard;