// File: frontend/src/pages/AutoInsight/index.js (updated)

import React, { useState } from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import VehicleStatistics from './components/VehicleStatistics';
import VehicleSearchResults from './components/VehicleSearchResults';
import VehicleDistributionChart from './components/VehicleDistributionChart';
import InsuranceStatistics from './components/InsuranceStatistics';
import VehicleSearchPanel from './components/VehicleSearchPanel';
import LatestReports from './components/LatestReports';
import styles from './AutoInsight.module.css';

const AutoInsight = () => {
  const { t } = useLanguage();
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const [lastSearchParams, setLastSearchParams] = useState(null);
  const [hasSearched, setHasSearched] = useState(false);

  const handleSearch = async (vin) => {
    setLoading(true);
    setError(null);
    setHasSearched(true);
    
    try {
      // Add pagination parameters
      const params = {
        vin,
        page: 1,
        limit: 10
      };
      
      setLastSearchParams(params);
      setCurrentPage(1);
      
      // Call the API - this will be replaced with actual API call
      // const response = await vehicleService.searchVehicle({ vin });
      
      // Mock response for now
      const mockResponse = {
        vehicles: [
          {
            vehicle_id: "1HGCM82633A123456",
            vin: vin || "1HGCM82633A123456",
            license_plate: "ABC123",
            make_model_year: "TOYOTA#CAMRY#2022",
            owner_id: "owner-12345",
            is_primary_vehicle: true,
            details: {
              vehicle_year: 2022,
              vehicle_make: "TOYOTA",
              vehicle_model: "CAMRY",
              vehicle_color: "Blue",
              vehicle_body_type: "Sedan"
            }
          }
        ],
        total: 1
      };
      
      if (mockResponse && mockResponse.vehicles) {
        setSearchResults(mockResponse.vehicles);
        setTotalResults(mockResponse.total || mockResponse.vehicles.length);
        setTotalPages(Math.ceil((mockResponse.total || mockResponse.vehicles.length) / 10));
      } else {
        setSearchResults([]);
        setTotalResults(0);
        setTotalPages(1);
        setError(t('no_vehicles_found'));
      }
    } catch (err) {
      console.error('Error searching vehicles:', err);
      setError(t('failed_to_search_vehicles'));
      setSearchResults([]);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = async (page) => {
    if (page === currentPage || !lastSearchParams) return;
    
    setLoading(true);
    setError(null);
    
    try {
      // Implementation similar to handleSearch but with updated page
      // This would be replaced with actual API call
      
      // Mock implementation for now
      setCurrentPage(page);
      setLoading(false);
    } catch (err) {
      console.error('Error changing page:', err);
      setError(t('error_loading_page'));
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.pageHeader}>
        <div className={styles.pageTitle}>
          <div className={styles.pageIconArea}>
            <img className={styles.pageIcon} alt="" src="/icons/insight-icon.svg" />
          </div>
          <h1 className={styles.title}>{t('auto_insight')}</h1>
        </div>
      </div>
      
      <div className={styles.searchPanels}>
        <VehicleSearchPanel onSearch={handleSearch} loading={loading} />
      </div>
      
      {error && <div className={styles.error}>{error}</div>}
      
      {hasSearched && (
        <VehicleSearchResults 
          results={searchResults} 
          loading={loading}
          onPageChange={handlePageChange}
          currentPage={currentPage}
          totalPages={totalPages}
          totalResults={totalResults}
        />
      )}
      
      <div className={styles.statsSection}>
        <VehicleStatistics />
        <InsuranceStatistics />
      </div>
      
      <div className={styles.chartSection}>
        <VehicleDistributionChart />
      </div>
      
      <div className={styles.reportsSection}>
        <LatestReports />
      </div>
    </div>
  );
};

export default AutoInsight;