// File: frontend/src/pages/MileMonitor/MileMonitor.js

import React from 'react';
import styles from './MileMonitor.module.css';
import { useAuth } from '../../contexts/AuthContextV2';
import { useLanguage } from '../../contexts/LanguageContext';
import PageTitle from '../../components/layout/DashboardLayout/PageTitle';
import MileMonitorContainer from '../../components/MileMonitor/MileMonitorContainer';

const MileMonitor = () => {
  const { user } = useAuth();
  const { t } = useLanguage();

  return (
    <div className={styles.mileMonitorPage}>
      <PageTitle title={t('mile_monitor')} />
      <MileMonitorContainer />
    </div>
  );
};

export default MileMonitor;