// src/components/DrivingScore/DrivingScoreContainer.js
import React from 'react';
import DrivingScoreDistribution from './DrivingScoreDistribution';
import DrivingScoreTable from './DrivingScoreTable';
import UnderwritingMatrix from './UnderwritingMatrix';
import TopDrivers from './TopDrivers';
import DrivingScoreMaps from './DrivingScoreMaps';
import styles from './DrivingScoreContainer.module.css';

const DrivingScoreContainer = () => {
  return (
    <div className={styles.container}>
      <div className={styles.upperSection}>
        <div className={styles.leftColumn}>
          <DrivingScoreDistribution />
          <DrivingScoreTable />
        </div>
        <div className={styles.rightColumn}>
          <UnderwritingMatrix />
        </div>
      </div>
      <div className={styles.lowerSection}>
        <TopDrivers />
        <DrivingScoreMaps />
      </div>
    </div>
  );
};

export default DrivingScoreContainer;