// File: frontend/src/pages/UMS/UserPortfolio/components/PortfolioOverview/Trips.js

import React, { useEffect, useState } from 'react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import styles from './PortfolioOverview.module.css';

// Helper function to convert hex to rgba
const hexToRGBA = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const Trips = () => {
  const { t } = useLanguage();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Simulating API call to fetch data
    setLoading(true);
    
    // Replace with actual API call
    setTimeout(() => {
      try {
        // Mock data - replace with actual data from your API
        const mockData = [
          {
            chartAxisDetails: {
              x1_axis_name: "Jan",
              y4_axis_value: 500,
              y4_axis_name: "Completed Trips",
              y4_axis_hex: "#4CAF50",
              y4_axis_transparency: 0.8,
              y5_axis_value: 100,
              y5_axis_name: "Incomplete Trips",
              y5_axis_hex: "#F44336",
              y5_axis_transparency: 0.8
            }
          },
          {
            chartAxisDetails: {
              x1_axis_name: "Feb",
              y4_axis_value: 600,
              y5_axis_value: 90
            }
          },
          {
            chartAxisDetails: {
              x1_axis_name: "Mar",
              y4_axis_value: 700,
              y5_axis_value: 85
            }
          },
          {
            chartAxisDetails: {
              x1_axis_name: "Apr",
              y4_axis_value: 800,
              y5_axis_value: 80
            }
          },
          {
            chartAxisDetails: {
              x1_axis_name: "May",
              y4_axis_value: 900,
              y5_axis_value: 75
            }
          },
          {
            chartAxisDetails: {
              x1_axis_name: "Jun",
              y4_axis_value: 1000,
              y5_axis_value: 70
            }
          }
        ];
        
        setData(mockData);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    }, 1000);
  }, []);

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <div className={styles.spinner}></div>
        <p>{t('loading_chart_data')}</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.errorContainer}>
        <i className="fas fa-exclamation-triangle"></i>
        <p>{t('error_loading_chart')}: {error}</p>
      </div>
    );
  }

  if (!data || data.length === 0) {
    return (
      <div className={styles.noDataContainer}>
        <p>{t('no_chart_data_available')}</p>
      </div>
    );
  }

  const firstEntryDetails = data[0].chartAxisDetails;

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={data}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="chartAxisDetails.x1_axis_name" />
        <YAxis label={{ value: t('number_of_trips'), angle: -90, position: 'insideLeft' }} />
        <Tooltip />
        <Legend />
        <Bar 
          dataKey="chartAxisDetails.y4_axis_value" 
          stackId="a" 
          fill={hexToRGBA(firstEntryDetails.y4_axis_hex, firstEntryDetails.y4_axis_transparency)} 
          name={t(firstEntryDetails.y4_axis_name)}
        />
        <Bar 
          dataKey="chartAxisDetails.y5_axis_value" 
          stackId="a" 
          fill={hexToRGBA(firstEntryDetails.y5_axis_hex, firstEntryDetails.y5_axis_transparency)} 
          name={t(firstEntryDetails.y5_axis_name)}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default Trips;