// File: frontend/src/pages/PropertyDetail/components/BuildingCharacteristics/index.js

import React from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import styles from './BuildingCharacteristics.module.css';

// Helper function to format date
const formatDate = (dateString) => {
  if (!dateString) return 'N/A';
  
  const date = new Date(dateString);
  
  // Check if date is valid
  if (isNaN(date.getTime())) return 'Invalid Date';
  
  return date.toLocaleDateString();
};

const BuildingCharacteristics = ({ property }) => {
  const { t } = useLanguage();

  if (!property.building) {
    return (
      <div className={styles.noData}>
        {t('no_building_information_available')}
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.grid}>
        <div className={styles.card}>
          <h3 className={styles.cardTitle}>{t('general_information')}</h3>
          <div className={styles.cardContent}>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('building_type')}:</div>
              <div className={styles.infoValue}>{property.building.building_type}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('main_type')}:</div>
              <div className={styles.infoValue}>{property.building.main_type}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('specific_type')}:</div>
              <div className={styles.infoValue}>{property.building.specific_type}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('height_style')}:</div>
              <div className={styles.infoValue}>{property.building.height_style}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('dwelling_type')}:</div>
              <div className={styles.infoValue}>{property.building.dwelling_type}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('architectural_style')}:</div>
              <div className={styles.infoValue}>{property.building.architectural_style}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('layout_style')}:</div>
              <div className={styles.infoValue}>{property.building.layout_style}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('primary_use')}:</div>
              <div className={styles.infoValue}>{property.building.primary_use}</div>
            </div>
          </div>
        </div>
        
        <div className={styles.card}>
          <h3 className={styles.cardTitle}>{t('building_details')}</h3>
          <div className={styles.cardContent}>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('year_built')}:</div>
              <div className={styles.infoValue}>{property.building.year_built}</div>
            </div>
            {property.building.year_rebuilt && (
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('year_rebuilt')}:</div>
                <div className={styles.infoValue}>{property.building.year_rebuilt}</div>
              </div>
            )}
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('last_renovation')}:</div>
              <div className={styles.infoValue}>{formatDate(property.building.last_renovation_date)}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('number_of_floors')}:</div>
              <div className={styles.infoValue}>{property.building.number_of_floors}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('number_of_stories')}:</div>
              <div className={styles.infoValue}>{property.building.number_of_stories}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('total_area')}:</div>
              <div className={styles.infoValue}>
                {property.building.total_area} {property.building.area_measure}
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className={styles.card}>
        <h3 className={styles.cardTitle}>{t('construction_details')}</h3>
        <div className={styles.cardContent}>
          <div className={styles.constructionGrid}>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('construction_type')}:</div>
              <div className={styles.infoValue}>{property.building.construction_type}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('log_construction')}:</div>
              <div className={styles.infoValue}>
                {property.building.is_log_construction ? t('yes') : t('no')}
              </div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('foundation_type')}:</div>
              <div className={styles.infoValue}>{property.building.foundation_type}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('roof_type')}:</div>
              <div className={styles.infoValue}>{property.building.roof_type}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('exterior_wall_type')}:</div>
              <div className={styles.infoValue}>{property.building.exterior_wall_type}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('exterior_material')}:</div>
              <div className={styles.infoValue}>{property.building.exterior_material}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('basement_type')}:</div>
              <div className={styles.infoValue}>{property.building.basement_type}</div>
            </div>
          </div>
        </div>
      </div>
      
      {property.unit && (
        <div className={styles.card}>
          <h3 className={styles.cardTitle}>{t('unit_details')}</h3>
          <div className={styles.cardContent}>
            <div className={styles.unitGrid}>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('unit_type')}:</div>
                <div className={styles.infoValue}>{property.unit.unit_type}</div>
              </div>
              {property.unit.unit_number && (
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('unit_number')}:</div>
                  <div className={styles.infoValue}>{property.unit.unit_number}</div>
                </div>
              )}
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('floor')}:</div>
                <div className={styles.infoValue}>{property.unit.floor}</div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('living_area')}:</div>
                <div className={styles.infoValue}>
                  {property.unit.living_area} {property.unit.area_measure}
                </div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('bedrooms')}:</div>
                <div className={styles.infoValue}>{property.unit.number_of_bedrooms}</div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('full_bathrooms')}:</div>
                <div className={styles.infoValue}>{property.unit.number_of_full_bathrooms}</div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('half_bathrooms')}:</div>
                <div className={styles.infoValue}>{property.unit.number_of_half_bathrooms}</div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('kitchens')}:</div>
                <div className={styles.infoValue}>{property.unit.number_of_kitchens}</div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('dens')}:</div>
                <div className={styles.infoValue}>{property.unit.number_of_dens}</div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('basement_apartment')}:</div>
                <div className={styles.infoValue}>
                  {property.unit.has_basement_apartment ? t('yes') : t('no')}
                </div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('kitchen_finishes')}:</div>
                <div className={styles.infoValue}>{property.unit.kitchen_finishes}</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BuildingCharacteristics;