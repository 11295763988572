// Refactor to use the FormField wrapper.
// Enhance to support indeterminate state consistently.

// File: frontend/src/components/form/Checkbox/Checkbox.js

import React from 'react';
import PropTypes from 'prop-types';
import { useLanguage } from '../../../contexts/LanguageContext';
import styles from './Checkbox.module.css';

const Checkbox = ({
  id,
  labelKey,
  checked,
  onChange,
  disabled = false,
  indeterminate = false,
}) => {
  const { t } = useLanguage();

  const handleChange = (e) => {
    if (onChange) {
      onChange(e.target.checked);
    }
  };

  return (
    <div className={styles.checkboxWrapper}>
      <input
        type="checkbox"
        id={id}
        className={styles.checkboxInput}
        checked={checked}
        onChange={handleChange}
        disabled={disabled}
        ref={(input) => {
          if (input) {
            input.indeterminate = indeterminate;
          }
        }}
      />
      <label htmlFor={id} className={styles.checkboxLabel}>
        {t(labelKey)}
      </label>
    </div>
  );
};

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  labelKey: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  indeterminate: PropTypes.bool,
};

export default Checkbox;