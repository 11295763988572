// File: frontend/src/pages/PropertyDetail/components/PropertyLocation/index.js

import React from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import styles from './PropertyLocation.module.css';

const PropertyLocation = ({ property }) => {
  const { t } = useLanguage();
  
  // Format date helper
  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    
    const date = new Date(dateString);
    
    // Check if date is valid
    if (isNaN(date.getTime())) return 'Invalid Date';
    
    return date.toLocaleDateString();
  };

  return (
    <div className={styles.container}>
      <div className={styles.grid}>
        <div className={styles.card}>
          <h3 className={styles.cardTitle}>{t('location_details')}</h3>
          <div className={styles.cardContent}>
            {property.location ? (
              <>
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('location_type')}:</div>
                  <div className={styles.infoValue}>{property.location.location_type}</div>
                </div>
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('raw_address')}:</div>
                  <div className={styles.infoValue}>{property.location.raw_address}</div>
                </div>
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('processing_status')}:</div>
                  <div className={styles.infoValue}>{property.location.address_processing_status}</div>
                </div>
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('address_confidence')}:</div>
                  <div className={styles.infoValue}>
                    {property.location.allegory_address_confidence 
                      ? `${(property.location.allegory_address_confidence * 100).toFixed(0)}%` 
                      : 'N/A'}
                  </div>
                </div>
              </>
            ) : (
              <div className={styles.noData}>{t('no_location_details_available')}</div>
            )}
          </div>
        </div>
        
        <div className={styles.card}>
          <h3 className={styles.cardTitle}>{t('geolocation')}</h3>
          <div className={styles.cardContent}>
            {property.location && property.location.geolocation ? (
              <>
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('latitude')}:</div>
                  <div className={styles.infoValue}>{property.location.geolocation.latitude}</div>
                </div>
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('longitude')}:</div>
                  <div className={styles.infoValue}>{property.location.geolocation.longitude}</div>
                </div>
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('accuracy')}:</div>
                  <div className={styles.infoValue}>
                    {property.location.geolocation.accuracy} {t('meters')}
                  </div>
                </div>
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('place_id')}:</div>
                  <div className={styles.infoValue}>{property.location.geolocation.place_id}</div>
                </div>
              </>
            ) : (
              <div className={styles.noData}>{t('no_geolocation_available')}</div>
            )}
          </div>
        </div>
      </div>
      
      <div className={styles.mapContainer}>
        {property.location && property.location.geolocation ? (
          <div className={styles.map}>
            <iframe
              title="Property Location"
              width="100%"
              height="100%"
              frameBorder="0"
              style={{ border: 0 }}
              src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_API_KEY}&q=${property.location.geolocation.latitude},${property.location.geolocation.longitude}`}
              allowFullScreen
            ></iframe>
          </div>
        ) : (
          <div className={styles.noMap}>
            {t('map_not_available')}
          </div>
        )}
      </div>
      
      <div className={styles.card}>
        <h3 className={styles.cardTitle}>{t('territory_information')}</h3>
        <div className={styles.cardContent}>
          {property.territory ? (
            <div className={styles.territoryGrid}>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('territory_code')}:</div>
                <div className={styles.infoValue}>{property.territory.territory_code}</div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('urban_classification')}:</div>
                <div className={styles.infoValue}>{property.territory.urban_classification}</div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('risk_factor')}:</div>
                <div className={styles.infoValue}>{property.territory.risk_factor}</div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('theft_risk_zone')}:</div>
                <div className={styles.infoValue}>{property.territory.theft_risk_zone}</div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('flood_zone')}:</div>
                <div className={styles.infoValue}>{property.territory.flood_zone}</div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('wildfire_zone')}:</div>
                <div className={styles.infoValue}>{property.territory.wildfire_zone}</div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('earthquake_zone')}:</div>
                <div className={styles.infoValue}>{property.territory.earthquake_zone}</div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('postal_code_risk_factor')}:</div>
                <div className={styles.infoValue}>{property.territory.postal_code_risk_factor}</div>
              </div>
            </div>
          ) : (
            <div className={styles.noData}>{t('no_territory_information_available')}</div>
          )}
        </div>
      </div>
      
      <div className={styles.card}>
        <h3 className={styles.cardTitle}>{t('fire_protection')}</h3>
        <div className={styles.cardContent}>
          <div className={styles.fireProtectionGrid}>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('fire_hall_distance')}:</div>
              <div className={styles.infoValue}>
                {property.fire_hall_distance} {property.fire_hall_distance_unit}
              </div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('fire_hydrant_distance')}:</div>
              <div className={styles.infoValue}>
                {property.fire_hydrant_distance} {property.fire_hydrant_distance_unit}
              </div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('fire_hall_name')}:</div>
              <div className={styles.infoValue}>{property.fire_hall_name}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('protection_class')}:</div>
              <div className={styles.infoValue}>{property.protection_class}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('protection_class_basis')}:</div>
              <div className={styles.infoValue}>{property.protection_class_basis}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyLocation;