// src/components/KnowledgeTransfer/InputContainer.js
import React, { useState } from 'react';
import { readFileAsText, parseJSON, parseCSV } from '../../utils/fileUtils';
import styles from './InputContainer.module.css';

const InputContainer = ({ onSubmit }) => {
  const [inputText, setInputText] = useState('');
  const [selectedModel, setSelectedModel] = useState('claude');
  const [file, setFile] = useState(null);

  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };

  const handleModelChange = (e) => {
    setSelectedModel(e.target.value);
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    setFile(file);
    
    if (file) {
      try {
        const content = await readFileAsText(file);
        if (file.name.endsWith('.json')) {
          setInputText(JSON.stringify(parseJSON(content), null, 2));
        } else if (file.name.endsWith('.csv')) {
          setInputText(parseCSV(content).join('\n')); // Simple CSV display
        } else {
          setInputText(content);
        }
      } catch (error) {
        console.error('Error reading file:', error);
        alert('Error reading file. Please try again.');
      }
    }
  };

  const handleSubmit = () => {
    onSubmit({ inputText, selectedModel, file });
  };

  return (
    <div className={styles.container}>
      <textarea
        value={inputText}
        onChange={handleInputChange}
        placeholder="Enter your knowledge here..."
        className={styles.inputTextarea}
      />
      <div className={styles.controls}>
        <div className={styles.fileInputWrapper}>
          <label htmlFor="file-upload" className={styles.fileInputLabel}>
            Upload File
          </label>
          <input
            id="file-upload"
            type="file"
            onChange={handleFileChange}
            accept=".json,.csv,image/*"
            className={styles.fileInput}
          />
        </div>
        <select value={selectedModel} onChange={handleModelChange} className={styles.modelSelect}>
          <option value="claude">Claude 3.5 Sonnet</option>
          <option value="gpt4">OpenAI GPT-4</option>
        </select>
        <button onClick={handleSubmit} className={styles.submitButton}>Submit</button>
      </div>
    </div>
  );
};

export default InputContainer;