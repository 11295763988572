// File: frontend/src/components/Dashboard/StatCard.js

import React from 'react';
import styles from './GlobalStatsCard.module.css';

const StatCard = ({ icon, value, label, color }) => (
  <div className={`${styles.statCard} ${styles[color]}`}>
    <div className={styles.statIconArea}>
      <img src={icon} alt="" className={styles.statIcon} />
    </div>
    <div className={styles.statContent}>
      <div className={styles.statValue}>{value}</div>
      <div className={styles.statLabel}>{label}</div>
    </div>
  </div>
);

export default StatCard;