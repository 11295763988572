// File: frontend/src/components/Alert/Alert.js

import React from 'react';
import PropTypes from 'prop-types';
import { useLanguage } from '../../contexts/LanguageContext';
import styles from './Alert.module.css';

const AlertIcon = ({ type }) => {
  const iconSrc = `/icons/18/alerts/${type}.svg`;
  return <img className={styles.alert_icon} alt="" src={iconSrc} aria-hidden="true" />;
};

const Alert = ({ type, titleKey, messageKey, onClose }) => {
  const { t } = useLanguage();

  return (
    <div className={`${styles.alert} ${styles[`alert_${type}`]}`} role="alert">
      <AlertIcon type={type} />
      <div className={styles.alert_content}>
        <div className={styles.alert_title}>{t(titleKey)}</div>
        <div className={styles.alert_message}>{t(messageKey)}</div>
      </div>
      {onClose && (
        <button 
          className={styles.alert_close} 
          onClick={onClose}
          aria-label={t('alert_close')}
        >
          <img className={styles.close_icon} alt="" src="/icons/18/misc/close.svg" aria-hidden="true" />
        </button>
      )}
    </div>
  );
};

Alert.propTypes = {
  type: PropTypes.oneOf(['error', 'warning', 'information', 'success']).isRequired,
  titleKey: PropTypes.string.isRequired,
  messageKey: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

export default Alert;