// File: frontend/src/pages/AI1/components/AgentSelector/defaultAgents.js

export const defaultAgents = [
    // General Purpose
    { id: 3436, key: 'ai_general_purpose', name: 'General Purpose', description: 'Versatile AI assistant for general tasks and inquiries', isPremium: false, isEnterprise: false, minRole: 'standard'},
    { id: 3662, key: 'ai_quote_agent', name: 'Get a Quote', description: 'Our most advanced insurance agent for quote & bind', isPremium: false, isEnterprise: false, minRole: 'standard'},
  
    // Technical & Development
    { id: 3456, key: 'ai_technology_officer', name: 'Technology Officer', description: 'Expert in technology strategy and technical leadership', isPremium: false, isEnterprise: true, minRole: 'standard'},
    { id: 3425, key: 'ai_codebase', name: 'Code Assistant', description: 'Specialized in code analysis and development support', isPremium: true, isEnterprise: false, minRole: 'standard'},
    { id: 3424, key: 'ai_code_classifier', name: 'Code Classifier', description: 'Analyzes and categorizes code patterns and structures', isPremium: true, isEnterprise: true, minRole: 'standard'},
    { id: 3424, key: 'ai_code_mentor', name: 'Code Mentor', description: 'An AI assistant designed to help insurance professionals learn coding and technology skills.', isPremium: false, isEnterprise: false, minRole: 'standard'},
    { id: 3454, key: 'ai_system_apis', name: 'API Expert', description: 'Specialized in API design, documentation, and integration', isPremium: false, isEnterprise: true, minRole: 'standard'},
    { id: 3455, key: 'ai_system_commands', name: 'System Commands', description: 'Expert in system command operations and automation', isPremium: false, isEnterprise: false, minRole: 'super_admin'},
  
    // Data & Analytics
    { id: 3428, key: 'ai_data_analytics', name: 'Data Analyst', description: 'Expert in data analysis and interpretation', isPremium: false, isEnterprise: true, minRole: 'standard'},
    { id: 3429, key: 'ai_data_organizer', name: 'Data Organizer', description: 'Helps structure and organize data efficiently', isPremium: false, isEnterprise: true, minRole: 'standard'},
    { id: 3426, key: 'ai_csv_converter', name: 'CSV Processor', description: 'Specializes in CSV file manipulation and conversion', isPremium: false, isEnterprise: true, minRole: 'standard'},
    { id: 3427, key: 'ai_csv_generator', name: 'CSV Generator', description: 'Creates and formats CSV files from various data sources', isPremium: false, isEnterprise: true, minRole: 'standard'},
    { id: 3434, key: 'ai_excel_formula_expert', name: 'Excel Expert', description: 'Advanced Excel formula and functionality specialist', isPremium: false, isEnterprise: false, minRole: 'standard'},
  
    // Business & Documentation
    { id: 3441, key: 'ai_meeting_scribe', name: 'Meeting Scribe', description: 'Captures and organizes meeting notes and action items', isPremium: false, isEnterprise: true, minRole: 'standard'},
    { id: 3444, key: 'ai_presentation', name: 'Presentation Helper', description: 'Assists in creating and structuring presentations', isPremium: true, isEnterprise: true, minRole: 'standard'},
    { id: 3442, key: 'ai_memo', name: 'Memo Writer', description: 'Helps draft and format professional memos', isPremium: false, isEnterprise: false, minRole: 'standard'},
    { id: 3433, key: 'ai_email_address_extractor', name: 'Email Extractor', description: 'Extracts and validates email addresses from various sources', isPremium: false, isEnterprise: false, minRole: 'standard'},
  
    // SQL & Database
    { id: 3452, key: 'ai_sql_refactoring', name: 'SQL Refactoring Expert', description: 'Optimizes and improves SQL query structure', isPremium: false, isEnterprise: true, minRole: 'standard'},
    { id: 3453, key: 'ai_sql_usecase_builder', name: 'SQL Use Case Builder', description: 'Develops SQL solutions for specific use cases', isPremium: false, isEnterprise: true, minRole: 'standard'},
    { id: 3451, key: 'ai_sql_parser', name: 'SQL Parser', description: 'Analyzes and interprets SQL query structures', isPremium: false, isEnterprise: true, minRole: 'standard'},
    { id: 3450, key: 'ai_sql_category_creator', name: 'SQL Category Creator', description: 'Creates and manages SQL category structures', isPremium: false, isEnterprise: true, minRole: 'standard'},
  
    // Insurance & Underwriting
    { id: 3437, key: 'ai_insurance_purpose', name: 'Insurance Specialist', description: 'Expert in insurance-related analysis and processing', isPremium: false, isEnterprise: true, minRole: 'standard'},
    { id: 3422, key: 'ai_actuary', name: 'Actuary', description: 'Specialized in actuarial analysis and risk assessment', isPremium: false, isEnterprise: true, minRole: 'standard'},
    { id: 3423, key: 'ai_auto_insights_underwriter', name: 'Auto Insights Underwriter', description: 'Analyzes automotive insurance risks and insights', isPremium: false, isEnterprise: true, minRole: 'standard'},
    { id: 3432, key: 'ai_driving_score_underwriter', name: 'Driving Score Underwriter', description: 'Evaluates driving scores for insurance purposes', isPremium: false, isEnterprise: true, minRole: 'standard'},
    { id: 3443, key: 'ai_mile_monitor_underwriter', name: 'Mile Monitor Underwriter', description: 'Tracks and analyzes mileage-based insurance data', isPremium: false, isEnterprise: true, minRole: 'standard'},
    { id: 3448, key: 'ai_property_insights_underwriter', name: 'Property Insights Underwriter', description: 'Analyzes property-related insurance risks', isPremium: false, isEnterprise: true, minRole: 'standard'},
    { id: 3459, key: 'ai_trips_underwriter', name: 'Trips Underwriter', description: 'Evaluates travel and trip-related insurance risks', isPremium: false, isEnterprise: true, minRole: 'standard'},
  
    // Design & UI
    { id: 3460, key: 'ai_ui_designer', name: 'UI Designer', description: 'Creates and optimizes user interface designs', isPremium: true, isEnterprise: true, minRole: 'standard'},
    { id: 3458, key: 'ai_tree_diagram_creator', name: 'Diagram Creator', description: 'Specializes in creating structured tree diagrams', isPremium: false, isEnterprise: false, minRole: 'standard'},
  
    // Language & Communication
    { id: 3457, key: 'ai_translator', name: 'Translator', description: 'Provides translation and language assistance', isPremium: false, isEnterprise: false, minRole: 'standard'},
    { id: 3449, key: 'ai_social_media', name: 'Social Media Expert', description: 'Assists with social media content and strategy', isPremium: true, isEnterprise: false, minRole: 'standard'},
  
    // Driving & Transportation
    { id: 3431, key: 'ai_driving_coach', name: 'Driving Coach', description: 'Provides guidance on safe driving practices', isPremium: true, isEnterprise: false, minRole: 'standard'},
    { id: 3430, key: 'ai_direction_decoder', name: 'Direction Decoder', description: 'Analyzes and optimizes navigation instructions', isPremium: true, isEnterprise: false, minRole: 'standard'},
  
    // AI & Intelligence
    { id: 3438, key: 'ai_intel_anthropic_sonnet', name: 'Anthropic Sonnet', description: 'Specialized in Anthropic AI capabilities', isPremium: true, isEnterprise: true, minRole: 'super_admin'},
    { id: 3439, key: 'ai_intel_openai_advanced_voice_model', name: 'Advanced Voice Model', description: 'Expert in advanced voice processing and analysis', isPremium: true, isEnterprise: true, minRole: 'super_admin'},
    { id: 3440, key: 'ai_intel_openai_canvas', name: 'Canvas Expert', description: 'Specializes in OpenAI Canvas capabilities', isPremium: true, isEnterprise: true, minRole: 'super_admin'},
  
    // Misc Specialized
    { id: 3445, key: 'ai_privacy', name: 'Privacy Expert', description: 'Focuses on privacy compliance and best practices', isPremium: false, isEnterprise: true, minRole: 'standard'},
    { id: 3435, key: 'ai_feedback_classifier', name: 'Feedback Analyzer', description: 'Analyzes and categorizes user feedback', isPremium: false, isEnterprise: true, minRole: 'standard'},
    { id: 3446, key: 'ai_product_naming', name: 'Product Naming', description: 'Assists in creating effective product names', isPremium: true, isEnterprise: true, minRole: 'standard'},
    { id: 3447, key: 'ai_prompting_techniques', name: 'Prompting Expert', description: 'Specializes in effective AI prompting strategies', isPremium: true, isEnterprise: true, minRole: 'admin'},
  ];