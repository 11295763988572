// File: frontend/src/pages/Auth/VerifyEmail.js

import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLanguage } from '../../contexts/LanguageContext';
import { useAuth, AUTH_FLOW_STATES } from '../../contexts/AuthContextV2';
import AuthLayout from '../../components/layout/AuthLayout/AuthLayout';
import StatusScreen from '../../components/Auth/StatusScreen';
import styles from './VerifyEmail.module.css';

const VerifyEmail = () => {
  const { t } = useLanguage();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { verifyEmail, loading, updateFlowState } = useAuth();
  const [verificationAttempted, setVerificationAttempted] = useState(false);
  const [verificationProgress, setVerificationProgress] = useState(0);
  

  useEffect(() => {
    const verifyToken = async () => {
        const token = searchParams.get('token');
        
        if (!token || verificationAttempted) {
            navigate('/verification-failure', { 
                state: { errorCode: 'missing_token' },
                replace: true 
            });
            return;
        }

        try {
            // Simulate progress steps
            setVerificationProgress(25);
            await new Promise(resolve => setTimeout(resolve, 500));
            setVerificationProgress(50);
            
            const response = await verifyEmail(token);
            setVerificationProgress(75);
            
            // Set verification attempted immediately after first try
            setVerificationAttempted(true);

            await new Promise(resolve => setTimeout(resolve, 500));
            setVerificationProgress(100);

            // Add small delay before redirect for better UX
            await new Promise(resolve => setTimeout(resolve, 500));
            
            if (response.isFullyVerified) {
                // Update flow state before navigation
                updateFlowState(AUTH_FLOW_STATES.SIGNUP.ACCOUNT_CREATION, {
                    email_verified: true,
                    verification_timestamp: new Date().toISOString()
                });

                navigate('/verification-success', { 
                    replace: true,
                    state: {
                        fromVerification: true,
                        isFullyVerified: true,
                        timestamp: new Date().toISOString()
                    }
                });
            } else {
                navigate('/account-creation', { 
                    replace: true,
                    state: { 
                        fromVerification: true,
                        verificationTimestamp: new Date().toISOString()
                    }
                });
            }
        } catch (error) {
            // If it's already verified, treat as success
            if (error.code === 'HTTP_400' && error.message === 'Email already verified') {
                navigate('/verification-success', { 
                    replace: true,
                    state: {
                        fromVerification: true,
                        isFullyVerified: true,
                        timestamp: new Date().toISOString()
                    }
                });
                return;
            }

            console.error('Email verification failed:', error);
            navigate('/verification-failure', { 
                state: { 
                    errorCode: error.code || 'verification_failed',
                    errorMessage: error.message
                },
                replace: true 
            });
        }
    };

    if (!verificationAttempted) {
        verifyToken();
    }
  }, [verifyEmail, navigate, searchParams, verificationAttempted]);

  const renderLoadingContent = () => (
    <div className={styles.loadingContent}>
      <div className={styles.progressContainer}>
        <div 
          className={styles.progressBar}
          style={{ width: `${verificationProgress}%` }}
        />
      </div>
      <div className={styles.progressSteps}>
        <div className={`${styles.step} ${verificationProgress >= 25 ? styles.completed : ''}`}>
          {t('auth.verifyEmail.steps.validating')}
        </div>
        <div className={`${styles.step} ${verificationProgress >= 50 ? styles.completed : ''}`}>
          {t('auth.verifyEmail.steps.verifying')}
        </div>
        <div className={`${styles.step} ${verificationProgress >= 75 ? styles.completed : ''}`}>
          {t('auth.verifyEmail.steps.updating')}
        </div>
        <div className={`${styles.step} ${verificationProgress >= 100 ? styles.completed : ''}`}>
          {t('auth.verifyEmail.steps.completing')}
        </div>
      </div>
    </div>
  );

  return (
    <AuthLayout
      title={t('auth.verifyEmail.title')}
      showHomeButton={true}
    >
      <div className={styles.verifyEmailContainer}>
        <div className={styles.verifyEmailContent}>
          <StatusScreen
            icon="/icons/18/alerts/information.svg"
            title={t('auth.verifyEmail.title')}
            message={t('auth.verifyEmail.verifying')}
            type="info"
            additionalInfo={loading && renderLoadingContent()}
          />
        </div>
      </div>
    </AuthLayout>
  );
};

export default React.memo(VerifyEmail);