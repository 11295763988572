// File: frontend/src/pages/EmbeddedInsurance/components/ProductBuilder/CoverageSelector.js

import React, { useState, useRef, useEffect } from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import { getProducts, getCoverages } from '../../data/apiEndpoints';
import styles from './styles/CoverageSelector.module.css';

// ProductWheel component with clean state management
const ProductWheel = React.memo(({ products, selectedProduct, onProductSelect }) => {
  const wheelRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  // Set activeIndex when selectedProduct changes (one-way data flow)
  useEffect(() => {
    const index = products.findIndex(p => p.id === selectedProduct);
    if (index !== -1) {
      setActiveIndex(index);
    }
  }, [selectedProduct, products]);

  // Handle UI scrolling when activeIndex changes
  useEffect(() => {
    if (wheelRef.current) {
      const itemWidth = 260; // Card width + gap
      const containerCenter = wheelRef.current.offsetWidth / 2;
      const scrollPosition = activeIndex * itemWidth - containerCenter + (itemWidth / 2);
      
      wheelRef.current.scrollTo({
        left: scrollPosition,
        behavior: 'smooth'
      });
    }
  }, [activeIndex]);

  // Clean selection handler that directly calls parent callback
  const handleSelect = (index) => {
    if (!isDragging && products[index] && products[index].id !== selectedProduct) {
      onProductSelect(products[index].id);
    }
  };

  const handlePrevious = () => {
    const newIndex = activeIndex <= 0 ? products.length - 1 : activeIndex - 1;
    setActiveIndex(newIndex);
    handleSelect(newIndex);
  };

  const handleNext = () => {
    const newIndex = activeIndex >= products.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(newIndex);
    handleSelect(newIndex);
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - wheelRef.current.offsetLeft);
    setScrollLeft(wheelRef.current.scrollLeft);
    e.preventDefault();
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    
    const x = e.pageX - wheelRef.current.offsetLeft;
    const dragDistance = (x - startX) * 0.6;
    wheelRef.current.scrollLeft = scrollLeft - dragDistance;
    
    e.preventDefault();
  };

  const handleDragEnd = () => {
    if (!isDragging) return;
    
    setIsDragging(false);
    
    // Find closest item to snap to
    if (wheelRef.current) {
      const itemWidth = 260;
      const scrollPosition = wheelRef.current.scrollLeft;
      const closestIndex = Math.round(scrollPosition / itemWidth);
      const boundedIndex = Math.max(0, Math.min(closestIndex, products.length - 1));
      
      setActiveIndex(boundedIndex);
      handleSelect(boundedIndex);
    }
  };

  const handleTouchStart = (e) => {
    setIsDragging(true);
    setStartX(e.touches[0].pageX - wheelRef.current.offsetLeft);
    setScrollLeft(wheelRef.current.scrollLeft);
  };

  const handleTouchMove = (e) => {
    if (!isDragging) return;
    
    const x = e.touches[0].pageX - wheelRef.current.offsetLeft;
    const dragDistance = (x - startX) * 0.6;
    wheelRef.current.scrollLeft = scrollLeft - dragDistance;
    e.preventDefault();
  };
  
  const handleCardClick = (index) => {
    if (isDragging) return;
    
    setActiveIndex(index);
    handleSelect(index);
  };

  const calculateCardStyle = (index) => {
    // Calculate relative position
    let relativePosition = index - activeIndex;
    
    // Adjust for circular effect
    if (relativePosition < -2) relativePosition += products.length;
    if (relativePosition > 2) relativePosition -= products.length;
    
    // Base transformations on relative position
    switch (relativePosition) {
      case -2: // Far left
        return {
          transform: 'translateX(-180%) scale(0.65) translateZ(-140px)',
          zIndex: 1,
          opacity: 0.5,
          pointerEvents: 'none'
        };
      case -1: // Left
        return {
          transform: 'translateX(-100%) scale(0.8) translateZ(-80px)',
          zIndex: 2,
          opacity: 0.7,
          pointerEvents: 'none'
        };
      case 0: // Center - active
        return {
          transform: 'translateX(0) scale(1.1) translateZ(0)',
          zIndex: 5,
          opacity: 1
        };
      case 1: // Right
        return {
          transform: 'translateX(100%) scale(0.8) translateZ(-80px)',
          zIndex: 2,
          opacity: 0.7,
          pointerEvents: 'none'
        };
      case 2: // Far right
        return {
          transform: 'translateX(180%) scale(0.65) translateZ(-140px)',
          zIndex: 1,
          opacity: 0.5,
          pointerEvents: 'none'
        };
      default:
        return {
          transform: 'scale(0.5) translateZ(-200px)',
          zIndex: 0,
          opacity: 0,
          pointerEvents: 'none'
        };
    }
  };

  return (
    <div className={styles.wheelContainer}>
      <button className={`${styles.wheelButton} ${styles.prevButton}`} onClick={handlePrevious}>
        <i className="fas fa-chevron-left"></i>
      </button>
      
      <div 
        className={styles.productWheel}
        ref={wheelRef}
        onMouseDown={handleMouseDown}
        onMouseUp={handleDragEnd}
        onMouseLeave={handleDragEnd}
        onMouseMove={handleMouseMove}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleDragEnd}
        onTouchMove={handleTouchMove}
      >
        <div className={styles.wheelTrack}>
          {products.map((product, index) => {
            const isVisible = Math.abs(index - activeIndex) <= 2 || 
                              Math.abs(index - activeIndex - products.length) <= 2 || 
                              Math.abs(index - activeIndex + products.length) <= 2;
            
            return (
              <div
                key={product.id}
                className={`${styles.productCard} ${index === activeIndex ? styles.activeCard : ''} ${!isVisible ? styles.hiddenCard : ''}`}
                onClick={() => handleCardClick(index)}
                style={calculateCardStyle(index)}
              >
                <div className={styles.productIcon}>
                  <i className={`fas fa-${product.icon}`}></i>
                </div>
                <h4 className={styles.productName}>{product.name}</h4>
                <p className={styles.productDescription}>{product.description}</p>
                
                <div className={styles.cardReflection}></div>
              </div>
            );
          })}
        </div>
      </div>
      
      <button className={`${styles.wheelButton} ${styles.nextButton}`} onClick={handleNext}>
        <i className="fas fa-chevron-right"></i>
      </button>
      
      <div className={styles.wheelPagination}>
        {products.map((product, index) => (
          <button
            key={index}
            className={`${styles.paginationDot} ${index === activeIndex ? styles.activeDot : ''}`}
            onClick={() => handleCardClick(index)}
          />
        ))}
      </div>
    </div>
  );
});

// Main CoverageSelector component
const CoverageSelector = ({
  selectedProduct,
  onProductSelect,
  selectedCoverage,
  onCoverageSelect
}) => {
  const { t } = useLanguage();
  const products = getProducts();
  const coverages = getCoverages(selectedProduct);

  // Clean coverage selection handler
  const handleCoverageSelect = (coverageId) => {
    if (coverageId !== selectedCoverage) {
      onCoverageSelect(coverageId);
    }
  };

  return (
    <div className={styles.coverageSelector}>
      <div className={styles.sectionHeader}>
        <h2 className={styles.sectionTitle}>{t('select_product_and_coverage') || 'Select Product and Coverage'}</h2>
        <p className={styles.sectionDescription}>
          {t('select_product_coverage_description') || 'Choose the insurance product and coverage level you want to offer.'}
        </p>
      </div>

      <div className={styles.productSelection}>
        <h3 className={styles.selectionTitle}>{t('select_product') || 'Select Product'}</h3>
        <ProductWheel 
          products={products} 
          selectedProduct={selectedProduct} 
          onProductSelect={onProductSelect} 
        />
      </div>

      {selectedProduct && (
        <div className={styles.coverageSelection}>
          <h3 className={styles.selectionTitle}>{t('select_coverage') || 'Select Coverage'}</h3>
          <div className={styles.coverageGrid}>
            {coverages.map((coverage) => (
              <div
                key={coverage.id}
                className={`${styles.coverageCard} ${selectedCoverage === coverage.id ? styles.selectedCard : ''}`}
                onClick={() => handleCoverageSelect(coverage.id)}
              >
                <div className={styles.coverageHeader}>
                  <h4 className={styles.coverageName}>{coverage.name}</h4>
                  {coverage.premium && (
                    <div className={styles.coveragePremium}>
                      {coverage.premium}
                    </div>
                  )}
                </div>
                <div className={styles.coverageDetails}>
                  <p className={styles.coverageDescription}>{coverage.description}</p>
                  <ul className={styles.coverageBenefits}>
                    {coverage.benefits.map((benefit, index) => (
                      <li key={index} className={styles.coverageBenefit}>
                        <i className="fas fa-check"></i>
                        <span>{benefit}</span>
                      </li>
                    ))}
                  </ul>
                </div>
                {selectedCoverage === coverage.id && (
                  <div className={styles.selectedIndicator}>
                    <i className="fas fa-check-circle"></i>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CoverageSelector;