// File: frontend/src/pages/PrivacyChoices/PrivacyChoices.js

import React from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import styles from './PrivacyChoices.module.css';

const PrivacyChoices = () => {
  const { t } = useLanguage();

  return (
    <div >
    </div>
  );
};

export default PrivacyChoices;