// File: frontend/src/pages/AI1/AI1.js

import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { apiService } from '../../services/api';
import { API_ENDPOINTS } from '../../config/constants';
import { useLanguage } from '../../contexts/LanguageContext';
import { useHeader } from '../../contexts/HeaderContext';
import { useUser } from '../../contexts/UserContext';
import styles from './AI1.module.css';

// Components
import ChatHeader from './components/ChatHeader/ChatHeader';
import ChatContainer from './components/ChatContainer/ChatContainer';
import ChatInput from './components/ChatInput/ChatInput';
import TemplateSelector from './components/TemplateSelector/TemplateSelector';
import AgentSelector from './components/AgentSelector/AgentSelector';
import TypingAnimation from './components/TypingAnimation/TypingAnimation';

const DEFAULT_TEMPLATE = {
  id: '2c806623-dc74-4f8b-961b-c7dd82529dd4',
  name: 'Technical Analysis'
};

const DEFAULT_AGENT = {
  id: 3436,
  key: 'ai_general_purpose',
  name: 'General Purpose',
  description: 'Versatile AI assistant for general tasks and inquiries'
};

// Helper functions for message handling
const createUserMessage = (message) => ({
  role: 'user',
  content: [{
    type: 'text',
    text: message
  }],
  timestamp: new Date().toISOString()
});

const createTypingIndicator = () => ({
  role: 'assistant',
  content: [{
    type: 'text',
    text: ''
  }],
  isTyping: true,
  timestamp: new Date().toISOString()
});

const createAssistantMessage = (response) => ({
  role: 'assistant',
  content: response.message,
  timestamp: new Date().toISOString(),
  usage: response.usage
});

const AI1 = ({ onMount, ...props }) => {
  const { t } = useLanguage();
  const { chat_id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { updateChatControls } = useHeader();
  const inputContainerRef = useRef(null);

  // Core states
  const [chatId, setChatId] = useState(chat_id);
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchingChat, setIsFetchingChat] = useState(false);
  const [isMessageSending, setIsMessageSending] = useState(false);
  const [isNavigating, setIsNavigating] = useState(false);

  // Get initial state from navigation if available
  const initialState = location.state || {};
  
  // UI states
  const [showTemplateSelector, setShowTemplateSelector] = useState(false);
  const [showAgentSelector, setShowAgentSelector] = useState(false);
  
  // Template and Agent states
  const [selectedTemplate, setSelectedTemplate] = useState(() => {
    return initialState.template || 
           JSON.parse(localStorage.getItem('selectedTemplate')) || 
           DEFAULT_TEMPLATE;
  });

  const [selectedAgent, setSelectedAgent] = useState(() => {
    return initialState.agent ||
           JSON.parse(localStorage.getItem('selectedAgent')) || 
           DEFAULT_AGENT;
  });

  useEffect(() => {
    const handleInitialMessage = async () => {
      if (initialState.initialMessage && !chatId) {
        await handleSendMessage(initialState.initialMessage);
        // Clear the navigation state after handling the message
        navigate(location.pathname, { replace: true, state: {} });
      }
    };

    handleInitialMessage();
  }, [initialState.initialMessage]);

  // Template selection handler
  const handleTemplateSelect = (template) => {
    console.log('Selected template:', template);
    setSelectedTemplate(template);
  };

  const handleNewChat = useCallback(() => {
    setChatId(null);
    setMessages([]);
    // Reset template and agent to defaults
    setSelectedTemplate(DEFAULT_TEMPLATE);
    setSelectedAgent(DEFAULT_AGENT);
    // Also update localStorage
    localStorage.setItem('selectedTemplate', JSON.stringify(DEFAULT_TEMPLATE));
    localStorage.setItem('selectedAgent', JSON.stringify(DEFAULT_AGENT));
    // Navigate to base AI1 route
    navigate('/ai1');
  }, [navigate]);

  // Share chat handler
  const handleShareChat = useCallback(async (type) => {
    if (!chatId) return;
    
    if (type === 'email') {
      try {
        await apiService.post(API_ENDPOINTS.AGENTS.CHAT.SHARE_EMAIL, {
          chat_id: chatId
        });
        // You might want to show a success notification here
      } catch (error) {
        console.error('Error sharing via email:', error);
        // Handle error appropriately
      }
    } else if (type === 'download') {
      try {
        // Fetch complete chat data if needed
        const chatData = {
          chat_id: chatId,
          messages,
          template: selectedTemplate,
          agent: selectedAgent,
          timestamp: new Date().toISOString()
        };
        
        const blob = new Blob([JSON.stringify(chatData, null, 2)], { 
          type: 'application/json' 
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `chat-${chatId}-${new Date().toISOString()}.json`;
        a.click();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Error downloading chat:', error);
        // Handle error appropriately
      }
    }
  }, [chatId, messages, selectedTemplate, selectedAgent]);

  // Memoize the controls object itself
  const chatControls = useMemo(() => ({
    chatId,
    onNewChat: handleNewChat,
    onShareChat: handleShareChat,
    showHistoryButton: true
  }), [chatId, handleNewChat, handleShareChat]);

  // Update header controls only when the memoized object changes
  useEffect(() => {
    updateChatControls(chatControls);
  }, [chatControls, updateChatControls]);

  // Chat initialization handler
  const initializeChat = async (message) => {
    try {
      const chatResponse = await apiService.post(API_ENDPOINTS.AGENTS.CHAT.INITIATE, {
        template_id: selectedTemplate.id,
        agent_name: selectedAgent.key,
        training_mode: false,
        source: 'ui'
      });

      const newChatId = chatResponse.chat_id;
      navigate(`/ai1/chat/${newChatId}`, { replace: true });
      setChatId(newChatId);
      
      return newChatId;
    } catch (error) {
      console.error('Error initializing chat:', error);
      throw error;
    }
  };

  // Message sending handler
  const sendMessage = async (chatId, message) => {
    try {
      const response = await apiService.post(API_ENDPOINTS.AGENTS.CHAT.MESSAGE, {
        chat_id: chatId,
        message
      });
      return response;
    } catch (error) {
      console.error('Error sending message:', error);
      throw error;
    }
  };

  // Main message handler
  const handleSendMessage = async (message) => {
    if (!message.trim()) return;

    setIsMessageSending(true); // Set at start of sending
    const userMessage = createUserMessage(message);
    const typingIndicator = createTypingIndicator();

    try {
      // Immediately update UI with user message and typing indicator
      setMessages(prev => [...prev, userMessage, typingIndicator]);

      if (!chatId) {
        // Initialize chat but don't wait for navigation
        const chatResponse = await apiService.post(API_ENDPOINTS.AGENTS.CHAT.INITIATE, {
          template_id: selectedTemplate.id,
          agent_name: selectedAgent.key,
          training_mode: false,
          source: 'ui'
        });

        const newChatId = chatResponse.chat_id;
        
        // Set navigating state before changing URL
        setIsNavigating(true);
        
        // Update URL without causing a reload
        navigate(`/ai1/chat/${newChatId}`, { 
          replace: true,
          state: { 
            messages: [...messages, userMessage],
            preserveState: true 
          }
        });
        
        setChatId(newChatId);

        // Send message with new chat ID
        const messageResponse = await sendMessage(newChatId, message);
        
        if (messageResponse) {
          // Update messages, replacing typing indicator
          setMessages(prev => [
            ...prev.filter(msg => !msg.isTyping),
            createAssistantMessage(messageResponse)
          ]);
        }
      } else {
        // Subsequent messages flow
        // setMessages(prev => [...prev, userMessage, typingIndicator]);
        
        const messageResponse = await sendMessage(chatId, message);
        
        if (messageResponse) {
          setMessages(prev => [
            ...prev.filter(msg => !msg.isTyping),
            createAssistantMessage(messageResponse)
          ]);
        }
      }
    } catch (error) {
      console.error('Error sending message:', error);
      // Remove typing indicator on error
      setMessages(prev => prev.filter(msg => !msg.isTyping));
    } finally {
      setIsMessageSending(false);
      setIsNavigating(false);
    }
  };

  // Fetch existing chat if chat_id exists
  useEffect(() => {
    if (chat_id && !isNavigating) {
      const fetchChat = async () => {
        try {
          setIsFetchingChat(true);
          const response = await apiService.get(`/agents/chat/${chat_id}`);
          
          if (response) {
            setChatId(chat_id);
            // Only update messages if we don't already have them
            if (!messages.length) {
              setMessages(response.messages || []);
            }
            setSelectedTemplate({
              id: response.template_id,
              name: response.template_name || 'Technical Analysis'
            });
            setSelectedAgent({
              id: response.agent_id,
              key: response.agent_name,
              name: response.agent_display_name || 'General Purpose',
              description: response.agent_description || 'Versatile AI assistant for general tasks and inquiries'
            });
          }
        } catch (error) {
          console.error('Error fetching chat:', error);
          navigate('/ai1');
        } finally {
          setIsFetchingChat(false);
        }
      };

      fetchChat();
    }
  }, [chat_id, isNavigating]);

  // Loading state handler
  if (isFetchingChat && !messages.length) {
    return (
      <div className={styles.loading}>
        <div className={styles.loadingSpinner} />
        {t('loading')}
      </div>
    );
  }
  

  return (
    <div className={styles.aiContainer}>
      <ChatContainer 
        messages={messages} 
        isLoading={isLoading}
      />
      
      <ChatInput
        ref={inputContainerRef}
        onSendMessage={handleSendMessage}
        selectedTemplate={selectedTemplate}
        onTemplateClick={() => setShowTemplateSelector(true)}
        selectedAgent={selectedAgent}
        onAgentClick={() => setShowAgentSelector(true)}
        disabled={isLoading}
        chatId={chatId}
        isMessageSending={isMessageSending}
        isTrainingMode={false}
      />

      {showTemplateSelector && (
        <TemplateSelector
          selectedTemplate={selectedTemplate}
          onSelect={handleTemplateSelect}
          onClose={() => setShowTemplateSelector(false)}
        />
      )}

      {showAgentSelector && (
        <AgentSelector
          selectedAgent={selectedAgent}
          onSelect={setSelectedAgent}
          onClose={() => setShowAgentSelector(false)}
        />
      )}
    </div>
  );
};

export default AI1;