// File: frontend/src/pages/InvestorPitch/components/slides/ProductEvolutionSlide/RoadmapCard.js

import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { Calendar, CheckCircle } from 'lucide-react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from './RoadmapCard.module.css';

const RoadmapCard = ({ version, date, focus, features, gradient }) => {
  const { t } = useLanguage();

  return (
    <motion.div 
      className={styles.container}
      whileHover={{ scale: 1.01 }}
      transition={{ type: 'spring', stiffness: 300, damping: 30 }}
    >
      <div 
        className={styles.header}
        style={{ background: gradient }}
      >
        <h4 className={styles.version}>{version}</h4>
        <div className={styles.date}>
          <Calendar 
            className={styles.icon} 
            aria-label={t('investor_pitch.product_evolution.roadmap.date.aria_label', 'Release date')}
          />
          <span>{date}</span>
        </div>
      </div>

      <div className={styles.content}>
        <div className={styles.focus}>{focus}</div>
        <ul className={styles.features}>
          {features.map((feature, index) => (
            <li key={index} className={styles.feature}>
              <CheckCircle className={styles.checkIcon} aria-hidden="true" />
              <span>{feature}</span>
            </li>
          ))}
        </ul>
      </div>
    </motion.div>
  );
};

RoadmapCard.propTypes = {
  version: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  focus: PropTypes.string.isRequired,
  features: PropTypes.arrayOf(PropTypes.string).isRequired,
  gradient: PropTypes.string.isRequired,
};

export default RoadmapCard;