// File: frontend/src/components/JobApplicationForm/CountrySelectResidence.js

import React, { useMemo } from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import Dropdown from '../form/Dropdown/Dropdown';
import styles from './CountrySelectResidence.module.css';

const CountrySelectResidence = ({ 
  id,
  labelKey,
  placeholderKey,
  hintKey,
  options,
  value,
  onChange,
  error,
  errorKey
}) => {
  const { t } = useLanguage();

  // Memoize options to prevent unnecessary recalculations
  const dropdownOptions = useMemo(() => 
    options.map(country => ({
      value: country.value,
      labelKey: `countries.${country.value.toLowerCase()}`,
      flag: country.flag
    })), [options]);

  // Custom render function for options
  const renderOption = (option) => (
    <div className={styles.countryOption}>
      <span className={styles.flag}>{option.flag}</span>
      <span className={styles.countryName}>{t(option.labelKey)}</span>
    </div>
  );

  // Custom render function for selected value
  const renderSelected = (selectedValue) => {
    if (!selectedValue) {
      return t(placeholderKey);
    }

    const selectedCountry = options.find(country => country.value === selectedValue);
    return (
      <div className={styles.selectedCountry}>
        <span className={styles.flag}>{selectedCountry.flag}</span>
        <span>{t(`countries.${selectedValue.toLowerCase()}`)}</span>
      </div>
    );
  };

  return (
    <div className={styles.selectCountryContainer}>
      <Dropdown
        id={id}
        labelKey={labelKey}
        placeholderKey={placeholderKey}
        options={dropdownOptions}
        value={value}
        onChange={onChange}
        required
        hintKey={hintKey}
        errorKey={errorKey}
        renderOption={renderOption}
        renderSelected={renderSelected}
        className={styles.countrySelect}
      />
    </div>
  );
};

export default CountrySelectResidence;