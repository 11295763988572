// File: frontend/src/pages/SuperAdmin/components/SubscriptionManagement/SubscriptionDetail/index.js

import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from './SubscriptionDetail.module.css';

const SubscriptionDetail = ({ subscriptionId, onBack }) => {
  const { t } = useLanguage();
  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('overview');

  useEffect(() => {
    const fetchSubscriptionDetails = async () => {
      setLoading(true);
      setError(null);
      
      try {
        // This would be replaced with an actual API call
        // const response = await subscriptionService.getSubscriptionDetails(subscriptionId);
        
        // Mock data for now
        await new Promise(resolve => setTimeout(resolve, 800));
        
        const mockSubscription = {
          subscription_id: subscriptionId,
          enterprise_id: 'ent-12345',
          organization_name: 'Acme Corporation',
          subscription_status: 'active',
          subscription_tier: 'enterprise',
          billing_frequency: 'monthly',
          organization_size: 'large',
          contact_name: 'John Smith',
          contact_phone: '+14155552671',
          subscription_start_date: '2023-01-15',
          subscription_end_date: '2024-01-14',
          last_billing_date: '2023-06-15',
          next_billing_date: '2023-07-15',
          created_at: '2023-01-10T15:35:00Z',
          updated_at: '2023-06-15T10:20:00Z',
          created_by: 'admin-user-1',
          updated_by: 'admin-user-2',
          
          // Additional related data
          enterprise: {
            enterprise_id: 'ent-12345',
            enterprise_name: 'Acme Corporation',
            enterprise_status: 'active',
            country_code: 'US',
            province_state_code: 'CA'
          },
          
          tier_details: {
            tier_id: 'tier-12345',
            tier_name: 'Enterprise',
            tier_description: 'Enterprise tier with all features included',
            monthly_price: 999.99,
            annual_price: 9999.99,
            features: {
              feature1: true,
              feature2: true,
              feature3: true,
              feature4: true
            }
          },
          
          products: [
            {
              product_id: 'prod-12345',
              product_name: 'Analytics Dashboard',
              product_status: 'active',
              quantity: 1
            },
            {
              product_id: 'prod-23456',
              product_name: 'Risk Assessment Tool',
              product_status: 'active',
              quantity: 2
            }
          ],
          
          billing_history: [
            {
              invoice_id: 'inv-12345',
              amount: 999.99,
              currency: 'USD',
              status: 'paid',
              billing_date: '2023-06-15',
              payment_date: '2023-06-15'
            },
            {
              invoice_id: 'inv-23456',
              amount: 999.99,
              currency: 'USD',
              status: 'paid',
              billing_date: '2023-05-15',
              payment_date: '2023-05-15'
            },
            {
              invoice_id: 'inv-34567',
              amount: 999.99,
              currency: 'USD',
              status: 'paid',
              billing_date: '2023-04-15',
              payment_date: '2023-04-15'
            }
          ]
        };
        
        setSubscription(mockSubscription);
      } catch (err) {
        console.error('Error fetching subscription details:', err);
        setError(t('failed_to_load_subscription_details'));
      } finally {
        setLoading(false);
      }
    };
    
    if (subscriptionId) {
      fetchSubscriptionDetails();
    }
  }, [subscriptionId, t]);

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }).format(date);
  };

  const formatCurrency = (amount, currency = 'USD') => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency
    }).format(amount);
  };

  const getStatusBadgeClass = (status) => {
    switch (status) {
      case 'active':
        return styles.statusActive;
      case 'expired':
        return styles.statusExpired;
      case 'pending':
        return styles.statusPending;
      case 'paid':
        return styles.statusPaid;
      case 'unpaid':
        return styles.statusUnpaid;
      default:
        return '';
    }
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <div className={styles.spinner}></div>
        <p>{t('loading_subscription_details')}</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.error}>
        <p>{error}</p>
        <button className={styles.backButton} onClick={onBack}>
          {t('back_to_subscriptions')}
        </button>
      </div>
    );
  }

  if (!subscription) {
    return (
      <div className={styles.notFound}>
        <p>{t('subscription_not_found')}</p>
        <button className={styles.backButton} onClick={onBack}>
          {t('back_to_subscriptions')}
        </button>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <h2 className={styles.subscriptionTitle}>
            {t('subscription_for')} {subscription.organization_name}
          </h2>
          <div className={styles.subscriptionId}>ID: {subscription.subscription_id}</div>
          <div className={styles.statusContainer}>
            <span className={`${styles.statusBadge} ${getStatusBadgeClass(subscription.subscription_status)}`}>
              {subscription.subscription_status}
            </span>
            <span className={styles.tierBadge}>{subscription.subscription_tier}</span>
          </div>
        </div>
        <div className={styles.headerActions}>
          <button className={styles.backButton} onClick={onBack}>
            <i className="fas fa-arrow-left"></i>
            <span>{t('back')}</span>
          </button>
          <button className={styles.editButton}>
            <i className="fas fa-edit"></i>
            <span>{t('edit')}</span>
          </button>
        </div>
      </div>
      
      <div className={styles.tabs}>
        <button 
          className={`${styles.tab} ${activeTab === 'overview' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('overview')}
        >
          {t('overview')}
        </button>
        <button 
          className={`${styles.tab} ${activeTab === 'products' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('products')}
        >
          {t('products')}
        </button>
        <button 
          className={`${styles.tab} ${activeTab === 'billing' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('billing')}
        >
          {t('billing_history')}
        </button>
      </div>
      
      <div className={styles.tabContent}>
        {activeTab === 'overview' && (
          <div className={styles.overviewTab}>
            <div className={styles.section}>
              <h3 className={styles.sectionTitle}>{t('subscription_information')}</h3>
              <div className={styles.infoGrid}>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('subscription_id')}</div>
                  <div className={styles.infoValue}>{subscription.subscription_id}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('status')}</div>
                  <div className={styles.infoValue}>
                    <span className={`${styles.statusBadge} ${getStatusBadgeClass(subscription.subscription_status)}`}>
                      {subscription.subscription_status}
                    </span>
                  </div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('tier')}</div>
                  <div className={styles.infoValue}>{subscription.subscription_tier}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('billing_frequency')}</div>
                  <div className={styles.infoValue}>{subscription.billing_frequency}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('start_date')}</div>
                  <div className={styles.infoValue}>{formatDate(subscription.subscription_start_date)}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('end_date')}</div>
                  <div className={styles.infoValue}>{formatDate(subscription.subscription_end_date)}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('last_billing_date')}</div>
                  <div className={styles.infoValue}>{formatDate(subscription.last_billing_date)}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('next_billing_date')}</div>
                  <div className={styles.infoValue}>{formatDate(subscription.next_billing_date)}</div>
                </div>
              </div>
            </div>
            
            <div className={styles.section}>
              <h3 className={styles.sectionTitle}>{t('organization_information')}</h3>
              <div className={styles.infoGrid}>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('enterprise_id')}</div>
                  <div className={styles.infoValue}>{subscription.enterprise_id}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('organization_name')}</div>
                  <div className={styles.infoValue}>{subscription.organization_name}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('organization_size')}</div>
                  <div className={styles.infoValue}>{subscription.organization_size}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('country')}</div>
                  <div className={styles.infoValue}>{subscription.enterprise.country_code}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('province_state')}</div>
                  <div className={styles.infoValue}>{subscription.enterprise.province_state_code}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('contact_name')}</div>
                  <div className={styles.infoValue}>{subscription.contact_name}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('contact_phone')}</div>
                  <div className={styles.infoValue}>{subscription.contact_phone}</div>
                </div>
              </div>
            </div>
            
            <div className={styles.section}>
              <h3 className={styles.sectionTitle}>{t('tier_details')}</h3>
              <div className={styles.tierCard}>
                <div className={styles.tierHeader}>
                  <div className={styles.tierName}>{subscription.tier_details.tier_name}</div>
                  <div className={styles.tierPrice}>
                    {formatCurrency(subscription.tier_details.monthly_price)}
                    <span className={styles.billingCycle}>/{t('month')}</span>
                  </div>
                </div>
                <div className={styles.tierDescription}>
                  {subscription.tier_details.tier_description}
                </div>
                <div className={styles.tierFeatures}>
                  <h4 className={styles.tierFeaturesTitle}>{t('included_features')}</h4>
                  <ul className={styles.featuresList}>
                    {Object.entries(subscription.tier_details.features).map(([feature, included]) => (
                      <li key={feature} className={`${styles.featureItem} ${included ? styles.featureIncluded : styles.featureExcluded}`}>
                        <i className={`fas ${included ? 'fa-check' : 'fa-times'}`}></i>
                        <span>{feature}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            
            <div className={styles.section}>
              <h3 className={styles.sectionTitle}>{t('audit_information')}</h3>
              <div className={styles.infoGrid}>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('created_at')}</div>
                  <div className={styles.infoValue}>{formatDate(subscription.created_at)}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('created_by')}</div>
                  <div className={styles.infoValue}>{subscription.created_by}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('updated_at')}</div>
                  <div className={styles.infoValue}>{formatDate(subscription.updated_at)}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('updated_by')}</div>
                  <div className={styles.infoValue}>{subscription.updated_by}</div>
                </div>
              </div>
            </div>
          </div>
        )}
        
        {activeTab === 'products' && (
          <div className={styles.productsTab}>
            <div className={styles.section}>
              <div className={styles.sectionHeader}>
                <h3 className={styles.sectionTitle}>{t('subscribed_products')}</h3>
                <button className={styles.addButton}>
                  <i className="fas fa-plus"></i>
                  <span>{t('add_product')}</span>
                </button>
              </div>
              
              {subscription.products && subscription.products.length > 0 ? (
                <div className={styles.productsList}>
                  <table className={styles.productsTable}>
                    <thead>
                      <tr>
                        <th>{t('product_id')}</th>
                        <th>{t('product_name')}</th>
                        <th>{t('status')}</th>
                        <th>{t('quantity')}</th>
                        <th>{t('actions')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {subscription.products.map((product) => (
                        <tr key={product.product_id}>
                          <td>{product.product_id}</td>
                          <td>{product.product_name}</td>
                          <td>
                            <span className={`${styles.statusBadge} ${getStatusBadgeClass(product.product_status)}`}>
                              {product.product_status}
                            </span>
                          </td>
                          <td>{product.quantity}</td>
                          <td>
                            <div className={styles.tableActions}>
                              <button className={styles.actionButton}>
                                <i className="fas fa-eye"></i>
                              </button>
                              <button className={styles.actionButton}>
                                <i className="fas fa-edit"></i>
                              </button>
                              <button className={styles.actionButton}>
                                <i className="fas fa-trash-alt"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className={styles.emptyState}>
                  <p>{t('no_products_found')}</p>
                </div>
              )}
            </div>
          </div>
        )}
        
        {activeTab === 'billing' && (
          <div className={styles.billingTab}>
            <div className={styles.section}>
              <h3 className={styles.sectionTitle}>{t('billing_history')}</h3>
              
              {subscription.billing_history && subscription.billing_history.length > 0 ? (
                <div className={styles.billingList}>
                  <table className={styles.billingTable}>
                    <thead>
                      <tr>
                        <th>{t('invoice_id')}</th>
                        <th>{t('amount')}</th>
                        <th>{t('status')}</th>
                        <th>{t('billing_date')}</th>
                        <th>{t('payment_date')}</th>
                        <th>{t('actions')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {subscription.billing_history.map((invoice) => (
                        <tr key={invoice.invoice_id}>
                          <td>{invoice.invoice_id}</td>
                          <td>{formatCurrency(invoice.amount, invoice.currency)}</td>
                          <td>
                            <span className={`${styles.statusBadge} ${getStatusBadgeClass(invoice.status)}`}>
                              {invoice.status}
                            </span>
                          </td>
                          <td>{formatDate(invoice.billing_date)}</td>
                          <td>{formatDate(invoice.payment_date)}</td>
                          <td>
                            <div className={styles.tableActions}>
                              <button className={styles.actionButton}>
                                <i className="fas fa-file-invoice"></i>
                                <span>{t('view_invoice')}</span>
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className={styles.emptyState}>
                  <p>{t('no_billing_history_found')}</p>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SubscriptionDetail;