// File: frontend/src/components/SearchBar/SearchBar.js

import React from 'react';
import PropTypes from 'prop-types';
import { useLanguage } from '../../contexts/LanguageContext';
import styles from './SearchBar.module.css';

const SearchBar = ({ value, onChange }) => {
  const { t } = useLanguage();

  return (
    <div className={styles.searchBar}>
      <input
        type="text"
        placeholder={t('search_placeholder')}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className={styles.searchInput}
      />
    </div>
  );
};

SearchBar.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SearchBar;