// File: frontend/src/components/LoadingSpinner/LoadingContainer.js

// ****************************************************************
// Usage Examples:
// 
// Full screen overlay:
// <LoadingContainer fullScreen spinnerSize="large" message="Loading..." />
//
// Container overlay (parent needs position: relative):
// <div style={{ position: 'relative' }}>
//   <LoadingContainer spinnerSize="medium" message="Loading data..." />
//   {/* Content */}
// </div>
//
// Transparent overlay for forms:
// <form style={{ position: 'relative' }}>
//   <LoadingContainer transparent spinnerSize="medium" />
//   {/* Form fields */}
// </form>
// ****************************************************************

import React from 'react';
import PropTypes from 'prop-types';
import LoadingSpinner from './LoadingSpinner';
import { useLanguage } from '../../contexts/LanguageContext';
import styles from './LoadingContainer.module.css';


const LoadingContainer = ({ 
  message = 'Loading...', 
  spinnerSize = 'medium',
  spinnerColor = 'primary',
  className = '',
  fullScreen = false,
  transparent = false
}) => {
  const { t } = useLanguage();
  
  return (
    <div 
      className={`
        ${styles.loadingContainer} 
        ${fullScreen ? styles.fullScreen : ''} 
        ${transparent ? styles.transparent : ''} 
        ${className}
      `.trim()}
    >
      <div className={styles.spinnerWrapper}>
        <LoadingSpinner size={spinnerSize} color={spinnerColor} />
        {message && <p className={styles.loadingMessage}>{message}</p>}
      </div>
    </div>
  );
};

LoadingContainer.propTypes = {
  message: PropTypes.string,
  spinnerSize: PropTypes.oneOf(['small', 'medium', 'large']),
  spinnerColor: PropTypes.oneOf(['primary', 'secondary', 'white']),
  className: PropTypes.string,
  fullScreen: PropTypes.bool,
  transparent: PropTypes.bool
};

export default LoadingContainer;