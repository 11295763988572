// File: frontend/src/pages/InvestorPitch/components/slides/LeadershipSlide/LeadershipSlide.js

import React from 'react';
import { motion } from 'framer-motion';
import * as LucideIcons from 'lucide-react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import LeaderProfile from './LeaderProfile';
import TrackRecordCard from './TrackRecordCard';
import PartnershipCard from './PartnershipCard';
import { ANIMATION_VARIANTS } from '../../../constants/animations';
import styles from './LeadershipSlide.module.css';

const LeadershipSlide = () => {
  const { t } = useLanguage();

  const leaderHighlights = [
    {
      icon: "Award",
      title: t('investor_pitch.leadership.highlights.actuary', 'Fellow of the Society of Actuaries'),
      color: "var(--color-blue-310)"
    },
    {
      icon: "GraduationCap",
      title: t('investor_pitch.leadership.highlights.education', 'MSc in Actuarial Science'),
      color: "var(--color-green-310)"
    },
    {
      icon: "Medal",
      title: t('investor_pitch.leadership.highlights.military', 'Military Officer'),
      color: "var(--color-red-310)"
    },
    {
      icon: "Building2",
      title: t('investor_pitch.leadership.highlights.founder', 'Serial Entrepreneur'),
      color: "var(--color-orange-310)"
    },
  ];

  const socialLinks = {
    linkedin: "https://www.linkedin.com/in/onur-gungor/",
    x: "https://x.com/_onurgungor"
  };

  const achievements = [
    t('investor_pitch.leadership.achievements.telematics', { customers: '6M+' }, 'Built telematics platform serving 6M+ customers'),
    t('investor_pitch.leadership.achievements.funding', { amount: '$50M+', team: '6', total: '40' }, 'Raised $50M+ with team of 6, grew to 40'),
    t('investor_pitch.leadership.achievements.expertise', 'Deep expertise in insurance technology and AI')
  ];

  const globalDetails = [
    t('investor_pitch.leadership.track_record.global.details.canada', 'Canada'),
    t('investor_pitch.leadership.track_record.global.details.us', 'United States'),
    t('investor_pitch.leadership.track_record.global.details.netherlands', 'Netherlands'),
    t('investor_pitch.leadership.track_record.global.details.germany', 'Germany'),
    t('investor_pitch.leadership.track_record.global.details.poland', 'Poland'),
    t('investor_pitch.leadership.track_record.global.details.turkey', 'Turkey')
  ];

  const trackRecord = [
    {
      title: t('investor_pitch.leadership.track_record.global.title', 'Global Experience'),
      icon: "Globe",
      details: globalDetails,
      color: "var(--color-blue-310)"
    },
    {
      title: t('investor_pitch.leadership.track_record.team.title', 'Team Building'),
      icon: "Users",
      details: [
        t('investor_pitch.leadership.track_record.team.retention', { years: '6+' }, 'Core team retention: 6+ years'),
        t('investor_pitch.leadership.track_record.team.size', { count: '40+' }, 'Built team of 40+ professionals'),
        t('investor_pitch.leadership.track_record.team.countries', { count: 4 }, 'Teams across 4 countries')
      ],
      color: "var(--color-green-310)"
    }
  ];

  const partnerships = [
    t('investor_pitch.leadership.partnerships.ia', 'iA Financial Group'),
    t('investor_pitch.leadership.partnerships.cooperators', 'The Co-operators'),
    t('investor_pitch.leadership.partnerships.achmea', 'Achmea'),
    t('investor_pitch.leadership.partnerships.fairfax', 'Fairfax Financial'),
    t('investor_pitch.leadership.partnerships.talanx', 'Talanx'),
    t('investor_pitch.leadership.partnerships.pc_insurance', 'PC Insurance'),
    t('investor_pitch.leadership.partnerships.northbridge', 'Northbridge'),
    t('investor_pitch.leadership.partnerships.prysm', 'Prysm Insurance')
  ];

  return (
    <motion.div
      className={styles.container}
      variants={ANIMATION_VARIANTS.stagger.container}
      initial="hidden"
      animate="visible"
    >
      <motion.div 
        className={styles.header}
        variants={ANIMATION_VARIANTS.fadeIn}
      >
        <h2 className={styles.title}>
          {t('investor_pitch.leadership.title', 'Leadership')}
        </h2>
        <p className={styles.subtitle}>
          {t('investor_pitch.leadership.subtitle', 'Experienced team with proven track record')}
        </p>
      </motion.div>

      <motion.div 
        className={styles.content}
        variants={ANIMATION_VARIANTS.stagger.container}
      >
        <motion.div 
          className={styles.leaderSection}
          variants={ANIMATION_VARIANTS.fadeIn}
        >
          <LeaderProfile
            name={t('investor_pitch.leadership.profile.name', 'Onur Gungor')}
            role={t('investor_pitch.leadership.profile.role', 'Founder & CEO')}
            highlights={leaderHighlights}
            achievements={achievements}
            socialLinks={socialLinks}
            photoUrl="/images/onurgungor2-v1.jpg"
          />
        </motion.div>

        <div className={styles.infoSection}>
          <motion.div 
            className={styles.trackRecordGrid}
            variants={ANIMATION_VARIANTS.stagger.container}
          >
            {trackRecord.map((record, index) => (
              <motion.div
                key={record.title}
                variants={ANIMATION_VARIANTS.stagger.item}
                custom={index}
              >
                <TrackRecordCard {...record} />
              </motion.div>
            ))}
          </motion.div>

          <motion.div 
            className={styles.partnershipsSection}
            variants={ANIMATION_VARIANTS.fadeIn}
          >
            <PartnershipCard partnerships={partnerships} />
          </motion.div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default LeadershipSlide;