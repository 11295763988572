// File: frontend/src/components/form/TextArea/TextArea.js

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLanguage } from '../../../contexts/LanguageContext';
import FormField from '../FormField/FormField';
import styles from './TextArea.module.css';

const TextArea = ({
  id,
  labelKey,
  placeholderKey,
  value,
  onChange,
  disabled = false,
  required = false,
  maxLength,
  hintKey,
  errorKey,
  rows = 3,
}) => {
  const { t } = useLanguage();
  const [isFocused, setIsFocused] = useState(false);

  const handleChange = (e) => {
    if (onChange) {
      onChange(e.target.value);
    }
  };

  const textareaClasses = [
    styles.textarea,
    disabled && styles.disabled,
    errorKey && styles.error,
    isFocused && styles.focused,
    value && styles.filled
  ].filter(Boolean).join(' ');

  return (
    <FormField
      labelKey={labelKey}
      required={required}
      hintKey={hintKey}
      errorKey={errorKey}
      id={id}
    >
      <div className={styles.textareaWrapper}>
        <textarea
          id={id}
          className={textareaClasses}
          placeholder={t(placeholderKey)}
          value={value}
          onChange={handleChange}
          disabled={disabled}
          maxLength={maxLength}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          rows={rows}
          aria-invalid={!!errorKey}
        />
        {maxLength && (
          <div className={styles.characterCount} aria-live="polite">
            <span className={styles.currentCount}>{value.length}</span>
            <span>/</span>
            <span className={styles.maxCount}>{maxLength}</span>
          </div>
        )}
      </div>
    </FormField>
  );
};

TextArea.propTypes = {
  id: PropTypes.string.isRequired,
  labelKey: PropTypes.string.isRequired,
  placeholderKey: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  maxLength: PropTypes.number,
  hintKey: PropTypes.string,
  errorKey: PropTypes.string,
  rows: PropTypes.number,
};

export default TextArea;