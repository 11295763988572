// File: frontend/src/pages/Auth/SignIn.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../contexts/LanguageContext';
import { useAuth, AUTH_FLOW_STATES } from '../../contexts/AuthContextV2';
import AuthLayout from '../../components/layout/AuthLayout/AuthLayout';
import SignInForm from '../../components/form/auth/SignInForm';
import OTPVerificationSignInV2 from '../../components/form/auth/OTPVerificationSignInV2';
import ProductPromotion from './ProductPromotion';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import styles from './SignIn.module.css';
import { tokenService } from '../../services/tokenService';

const SignIn = () => {
  const { t } = useLanguage();
  const navigate = useNavigate();
  const { 
    initiateSignIn, 
    verifySignIn,
    loading, 
    error,
    clearError,
    flowState,
    flowData,
    updateFlowState
  } = useAuth();

  const [authMethod, setAuthMethod] = useState(null);

  // Initialize auth step
  useEffect(() => {
    // Initialize auth step if needed
    if (!flowState) {
        updateFlowState(AUTH_FLOW_STATES.SIGNIN.INITIAL);
        return;
    }

    // Handle auth state changes
    if (flowState === AUTH_FLOW_STATES.SIGNIN.OTP_VERIFICATION && 
        flowData?.identifier) {
        const method = flowData.identifier.includes('@') ? 'email' : 'phone';
        setAuthMethod(method);
    }
  }, [flowState, flowData, updateFlowState]); // Removed authMethod

  const handleSubmit = async (formData) => {
    try {
        console.debug('SignIn handleSubmit:', {
            formData,
            beforeFlowState: flowState
        });
    
        tokenService.clearTokens();
        const response = await initiateSignIn(formData);
        
        console.debug('SignIn after initiateSignIn:', {
            afterFlowState: flowState,
            afterFlowData: flowData,
            response // Add this to see what we're getting back
        });

        // Add this check for the redirect
        if (response?.redirectTo === 'account-creation') {
            navigate('/account-creation', { 
                state: { 
                    identifier: typeof formData === 'string' 
                        ? formData.toLowerCase() 
                        : formData.identifier.toLowerCase(),
                    identifierType: formData.includes('@') ? 'email' : 'phone'
                } 
            });
            return;
        }

    } catch (err) {
        console.error('Error initiating sign-in:', err);
    }
  };

  const handleOTPVerify = async (otpString) => {
    try {
      if (!flowData?.identifier) {
        throw new Error('No identifier found');
      }
  
      console.log('Before verifySignIn:', { identifier: flowData.identifier, otp: otpString });
      const result = await verifySignIn(flowData.identifier, otpString);
      console.log('After verifySignIn result:', result);
      
      // Check for success instead of redirectTo
      if (result.success) {
        console.log('Sign in successful, navigating to success page');
        navigate('/sign-in-success', {
          state: {
            user_id: result.user.user_id,
            timestamp: new Date().toISOString(),
            deviceName: navigator.userAgent
          }
        });
      }
    } catch (err) {
      console.error('Error verifying OTP:', err);
    }
  };

  const handleResendOTP = async () => {
    try {
      if (!flowData?.identifier) {
        throw new Error('No identifier found');
      }
      await initiateSignIn(flowData.identifier);
    } catch (err) {
      console.error('Error resending OTP:', err);
    }
  };

  const handleBack = () => {
    clearError();
    updateFlowState(AUTH_FLOW_STATES.SIGNIN.INITIAL);
  };

  const showOTP = flowState === AUTH_FLOW_STATES.SIGNIN.OTP_VERIFICATION &&
                 authMethod !== null; // Only show OTP when authMethod is set
  return (
    <AuthLayout
      title={t('auth.signIn.title')}
      showHomeButton={true}
      showBackButton={showOTP}
      showNextButton={false}
      onBackClick={showOTP ? handleBack : () => navigate('/')}
      isVerifying={loading}
    >
      {error && (
        <ErrorMessage
          error={error}
          onClose={clearError}
          variant="floating"
        />
      )}
      
      <div className={styles.signInPage}>
        {showOTP ? (
          <OTPVerificationSignInV2
            onVerify={handleOTPVerify}
            onResend={handleResendOTP}
            authMethod={authMethod}
            contactInfo={flowData?.identifier}
            onBack={handleBack}
          />
        ) : (
          <>
            <div className={styles.formSection}>
              <SignInForm 
                onSubmit={handleSubmit}
                isLoading={loading}
              />
            </div>
            <div className={styles.promotionSection}>
              <ProductPromotion />
            </div>
          </>
        )}
      </div>
    </AuthLayout>
  );
};

export default SignIn;