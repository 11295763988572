// File: frontend/src/pages/InvestorPitch/components/slides/ClosingSlideInv/ClosingSlideInv.js

import React from 'react';
import { motion } from 'framer-motion';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import { ANIMATION_VARIANTS } from '../../../constants/animations';
import LandingButton from '../../../../../components/Button/LandingButton';
import styles from './ClosingSlideInv.module.css';

const ClosingSlideInv = () => {
  const { t } = useLanguage();

  const investmentDetails = [
    {
      label: t('investor_pitch.closing_slide.post_money', 'Post-money valuation:'),
      value: '$1,253 M USD',
      highlight: true
    },
    {
      label: t('investor_pitch.closing_slide.returns', '5-year return potential:'),
      value: '100x',
      highlight: true
    },
    {
      label: t('investor_pitch.closing_slide.ai1', 'AI-1:'),
      value: t('investor_pitch.closing_slide.ai1_description', '40 Context-Aware AI Agents')
    },
    {
      label: t('investor_pitch.closing_slide.model', 'Underwriting model:'),
      value: t('investor_pitch.closing_slide.model_description', 'Operational')
    },
    {
      label: t('investor_pitch.closing_slide.modules', 'Built-in Insurance Modules:'),
      value: '28'
    },
    {
      label: t('investor_pitch.closing_slide.regulatory', 'Regulatory readiness:'),
      value: '🇨🇦 🇺🇸 🇲🇽 🇬🇧 🇪🇺'
    },
    {
      label: t('investor_pitch.closing_slide.languages', 'Languages:'),
      value: '🇨🇦 🇺🇸 🇬🇧 🇩🇪 🇪🇸 🇲🇽 🇫🇷 🇳🇱 🇹🇷'
    }
  ];

  return (
    <motion.div
      className={styles.container}
      variants={ANIMATION_VARIANTS.stagger.container}
      initial="hidden"
      animate="visible"
    >
      <motion.div className={styles.titleContainer} variants={ANIMATION_VARIANTS.fadeIn}>
        <h2 className={styles.title}>
          {t('investor_pitch.closing_slide.title', 'Let\'s Build the World\'s First Trillion-Dollar Insurance Company')}
        </h2>
      </motion.div>

      <motion.div 
        className={styles.contentContainer}
        variants={ANIMATION_VARIANTS.stagger.container}
      >
        {/* LEFT CONTAINER */}
        <motion.div 
          className={styles.leftBox}
          variants={ANIMATION_VARIANTS.fadeIn}
        >
          <div className={styles.raisingContainer}>
            <p className={styles.label}>
              {t('investor_pitch.closing_slide.label_now_raising', 'Now Raising')}
            </p>
            <p className={styles.amount}>
              {t('investor_pitch.closing_slide.amount', '$200 M Series A')}
            </p>
          </div>
          
          <div className={styles.messageContainer}>
            <p className={styles.prompt}>
              {t('investor_pitch.closing_slide.prompt', 'Ready to shape the future of insurance with us?')}
            </p>
            <p className={styles.supercycle}>
              {t('investor_pitch.closing_slide.supercycle', 'Secure your allocation in the next insurance supercycle')}
            </p>
          </div>
          
          <motion.div 
            className={styles.ctaContainer}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            <LandingButton
              href="https://calendly.com/meet-with-allegory/30min"
              className={styles.ctaButton}
              aria-label={t('schedule_a_meeting', 'Schedule a Meeting')}
            >
              {t('schedule_a_meeting', 'Schedule a Meeting')}
            </LandingButton>
          </motion.div>
        </motion.div>

        {/* RIGHT CONTAINER */}
        <motion.div 
          className={styles.rightBox}
          variants={ANIMATION_VARIANTS.stagger.container}
        >
          <ul className={styles.detailsList}>
            {investmentDetails.map((detail, index) => (
              <motion.li 
                key={index}
                className={detail.highlight ? styles.highlightItem : ''}
                variants={ANIMATION_VARIANTS.stagger.item}
                custom={index}
              >
                <span className={styles.detailLabel}>
                  {detail.label}
                </span>{' '}
                <span className={styles.detailValue}>
                  {detail.value}
                </span>
              </motion.li>
            ))}
          </ul>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default ClosingSlideInv;