// File: frontend/src/pages/EmbeddedInsurance/components/ApiExplorer/ApiResponses.js

import React, { useState } from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import CodeBlock from '../common/CodeBlock';
import styles from './styles/ApiResponses.module.css';

const ApiResponses = ({ responses, components }) => {
  const { t } = useLanguage();
  const [activeResponse, setActiveResponse] = useState(
    Object.keys(responses)[0] // Select first response code by default
  );

  const getStatusCodeColor = (code) => {
    const statusCode = parseInt(code);
    if (statusCode >= 200 && statusCode < 300) return styles.success;
    if (statusCode >= 300 && statusCode < 400) return styles.redirect;
    if (statusCode >= 400 && statusCode < 500) return styles.clientError;
    if (statusCode >= 500) return styles.serverError;
    return styles.default;
  };

  // Get example response for a status code
  const getResponseExample = (statusCode) => {
    const response = responses[statusCode];
    if (!response || !response.content) return null;
    
    const contentType = Object.keys(response.content)[0];
    if (!contentType) return null;
    
    return response.content[contentType].example || null;
  };

  return (
    <div className={styles.section}>
      <h3 className={styles.sectionTitle}>{t('responses') || 'Responses'}</h3>
      
      <div className={styles.responseTabs}>
        {Object.entries(responses).map(([code, response]) => (
          <button
            key={code}
            className={`${styles.responseTab} ${activeResponse === code ? styles.activeTab : ''} ${getStatusCodeColor(code)}`}
            onClick={() => setActiveResponse(code)}
          >
            <span className={styles.statusCode}>{code}</span>
            <span className={styles.statusText}>{response.description}</span>
          </button>
        ))}
      </div>

      <div className={styles.responseContent}>
        {responses[activeResponse].description && (
          <p className={styles.responseDescription}>
            {responses[activeResponse].description}
          </p>
        )}

        {getResponseExample(activeResponse) && (
          <div className={styles.responseExample}>
            <div className={styles.responseExampleHeader}>
              <h4 className={styles.responseExampleTitle}>{t('example_response') || 'Example Response'}</h4>
              {responses[activeResponse].content && Object.keys(responses[activeResponse].content).map(contentType => (
                <code key={contentType} className={styles.contentType}>
                  {contentType}
                </code>
              ))}
            </div>
            
            <div className={styles.responseExampleBody}>
              <CodeBlock 
                code={JSON.stringify(getResponseExample(activeResponse), null, 2)} 
                language="json"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ApiResponses;