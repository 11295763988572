// src/components/TripDetails/TripOverview.js
import React from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import { usePrivacy } from '../../contexts/PrivacyContext';
import styles from './TripOverview.module.css';

const TripOverview = ({ tripData }) => {
  const { t } = useLanguage();
  const { privacyProtection } = usePrivacy();

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      weekday: 'short',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
  };

  const maskTripId = (tripId) => {
    if (!privacyProtection) return tripId;
    return tripId.slice(0, 2) + '**' + tripId.slice(-2);
  };

  const renderOverviewItem = (label, value, isCopyable = false, isMultiValue = false) => (
    <div className={styles.cardStandartContentA}>
      <div className={styles.cardStandartContentA1}>
        <div className={styles.div}>{t[label]}</div>
        <div className={styles.div}>:</div>
      </div>
      {isMultiValue ? (
        <div className={styles.cardStandartContentAParent}>
          {value.map((item, index) => (
            <div key={index} className={styles.cardStandartContentA5}>
              <div className={styles.div}>{item}</div>
            </div>
          ))}
        </div>
      ) : (
        <div className={styles.cardStandartContentAWrapper}>
          <div className={styles.cardStandartContentA2}>
            <div className={styles.text2}>{value}</div>
            {isCopyable && (
              <img className={styles.icons18CopyPaste} alt="" src="/icons/18/Copy & Paste.png" />
            )}
          </div>
        </div>
      )}
    </div>
  );

  const [startDate, startTime] = formatDate(tripData.tripStartTimestampLocal).split(', ');
  const [endDate, endTime] = formatDate(tripData.tripFinishedTimestampLocal).split(', ');

  return (
    <div className={styles.card}>
      <div className={styles.cardStandartTitleRow}>
        <div className={styles.text}>{t('trip_overview')}</div>
        <div className={styles.button}>
          <img className={styles.icons18MoreHorizontal} alt="" src="/icons/18/More-Horizontal.svg" />
        </div>
      </div>
      <div className={styles.cardPicturedContent}>
        <div className={styles.img}>
          <img className={styles.imgChild} alt="" src={tripData.tripImageUrl640x374} />
        </div>
        <div className={styles.cardStandartContent}>
          {renderOverviewItem('trip_id', maskTripId(tripData.tripID), true)}
          {renderOverviewItem('start_date_time', [startDate, startTime], false, true)}
          {renderOverviewItem('end_date_time', [endDate, endTime], false, true)}
          {renderOverviewItem('start_address', tripData.tripStartedLocation)}
          {renderOverviewItem('end_address', tripData.tripFinishedLocation)}
          {renderOverviewItem('distance', `${(tripData.distanceTravelledOdometer / 1000).toFixed(2)} km`)}
          {renderOverviewItem('time', `${Math.floor(tripData.tripDurationSeconds / 3600)} hours ${Math.floor((tripData.tripDurationSeconds % 3600) / 60)} minutes`)}
          {renderOverviewItem('idle_time', `${Math.floor(tripData.idlingTimeSeconds / 60)} mins.`)}
          {renderOverviewItem('avg_speed', `${tripData.averageSpeed.toFixed(2)} km/h`)}
          {renderOverviewItem('max_speed', `${tripData.maximumSpeed.toFixed(2)} km/h`)}
          {renderOverviewItem('night_driving', tripData.isNightDriving ? t('yes') : t('no'))}
          {renderOverviewItem('peak_hour_driving', tripData.isPeakDriving ? t('yes') : t('no'))}
          {renderOverviewItem('weekend_driving', tripData.isWeekendDriving ? t('yes') : t('no'))}
        </div>
      </div>
    </div>
  );
};

export default TripOverview;