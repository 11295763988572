// File: frontend/src/components/form/auth/SignInForm.js

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useLanguage } from '../../../contexts/LanguageContext';
import { VALIDATION } from '../../../config/constants';
import Input from '../Input/Input';
import PhoneInput from '../PhoneInput/PhoneInput';
import Checkbox from '../Checkbox/Checkbox';
import FormGroup from '../FormGroup/FormGroup';
import Divider from '../../Divider/Divider';
import Button from '../../Button/Button';
import styles from './SignInForm.module.css';

const SignInForm = ({ onSubmit, isLoading }) => {
  const { t } = useLanguage();
  const [formData, setFormData] = useState({
    email_address: '',
    country_phone_code: '+1',
    area_code: '',
    phone_number: '',
    rememberMe: false
  });

  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false);

  const validateForm = () => {
    const newErrors = {};
    
    // Check if either email or phone is provided
    const hasEmail = formData.email_address?.trim() !== '';
    const hasCompletePhone = formData.phone_number?.trim() !== '' && 
                            formData.country_phone_code?.trim() !== '' && 
                            formData.area_code?.trim() !== '';

    if (!hasEmail && !hasCompletePhone) {
      newErrors.general = t('auth.signIn.errors.provideEmailOrPhone');
    }
  
    // Email validation if provided
    if (hasEmail) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.email_address)) {
        newErrors.email_address = t('auth.signIn.errors.invalidEmail');
      }
    }
  
    // Phone validation if provided
    if (hasCompletePhone) {
      if (!VALIDATION.PHONE.COUNTRY_CODE_REGEX.test(formData.country_phone_code)) {
        newErrors.phone = t('auth.signIn.errors.invalidCountryCode');
      }
      if (!VALIDATION.PHONE.AREA_CODE_REGEX.test(formData.area_code)) {
        newErrors.phone = t('auth.signIn.errors.invalidAreaCode');
      }
      if (!VALIDATION.PHONE.NUMBER_REGEX.test(formData.phone_number)) {
        newErrors.phone = t('auth.signIn.errors.invalidPhoneNumber');
      }
    }
  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    const isFormValid = validateForm();
    setIsValid(isFormValid);
  }, [formData]);

  const handleChange = (field, value) => {
    setFormData(prevData => ({
      ...prevData,
      [field]: value
    }));
  };

  const handlePhoneChange = (phoneData) => {
    setFormData(prevData => {
      const newData = {
        ...prevData,
        country_phone_code: phoneData.country_phone_code,
        area_code: phoneData.area_code,
        phone_number: phoneData.phone_number
      };
      return newData;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    let identifier;
    
    if (formData.email_address?.trim()) {
      identifier = formData.email_address.toLowerCase();
    } else if (formData.phone_number?.trim()) {
      identifier = `${formData.country_phone_code}${formData.area_code}${formData.phone_number}`.replace(/\s+/g, '');
    }
  
    if (!identifier) {
      setErrors({ general: t('auth.signIn.errors.provideEmailOrPhone') });
      return;
    }
  
    onSubmit(identifier);
  };

  return (
    <div className={styles.signInForm}>
      <div className={styles.formContent}>
        <form onSubmit={handleSubmit} className={styles.formFields}>
          <FormGroup>
            <Input
              id="email_address"
              labelKey="auth.signIn.emailLabel"
              placeholderKey="auth.signIn.emailPlaceholder"
              required={false}
              value={formData.email_address}
              onChange={(value) => handleChange('email_address', value)}
              error={errors.email_address}
            />
            <Divider childrenKey="auth.signIn.or" />
            <PhoneInput
              id="phone"
              labelKey="auth.signIn.phoneLabel"
              required={false}
              value={{
                country_phone_code: formData.country_phone_code,
                area_code: formData.area_code,
                phone_number: formData.phone_number
              }}
              onChange={handlePhoneChange}
              error={errors.phone}
            />
            <div className={styles.rememberMeWrapper}>
              <Checkbox
                id="rememberMe"
                labelKey="auth.signIn.rememberMe"
                checked={formData.rememberMe}
                onChange={(checked) => handleChange('rememberMe', checked)}
              />
            </div>
          </FormGroup>
          <Button
            type="submit"
            variant="primary"
            size="medium"
            icon="/icons/18/misc/Chevron-Right-White.svg"
            iconPosition="right"
            disabled={!isValid}
            isLoading={isLoading}
          >
            {t('auth.signIn.submitButton')}
          </Button>
          <p className={styles.termsText}>
            {t('auth.signIn.termsText')}
          </p>
          <Divider childrenKey="auth.signIn.newToAllegory" />
          <Button
            href="/sign-up"
            variant="social"
            size="small"
          >
            {t('auth.signIn.createAccount')}
          </Button>
        </form>
      </div>
    </div>
  );
};

SignInForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

export default SignInForm;