// File: frontend/src/components/Home/InsuranceManagementSection.js

import React, { useRef, useEffect, useState, lazy, Suspense } from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import LandingButton from '../Button/LandingButton';
import styles from './InsuranceManagementSection.module.css';

const StructuredParticleAnimation = lazy(() => 
  import('./StructuredParticleAnimation').then(module => {
    // Add a small delay to ensure smooth loading
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(module);
      }, 100);
    });
  })
);

const InsuranceManagementSection = () => {
  const { t } = useLanguage();
  const [isVisible, setIsVisible] = useState(false);
  const [isIntersecting, setIsIntersecting] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        const visibleRatio = entry.intersectionRatio;
        
        // Set visibility state based on intersection ratio
        setIsIntersecting(entry.isIntersecting);
        setIsVisible(visibleRatio >= 0.2); // Match the animation threshold
      },
      { 
        threshold: [0, 0.2, 0.4, 0.6, 0.8, 1.0],
        rootMargin: '50px 0px'
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.disconnect();
      }
    };
  }, []);

  return (
    <section 
      ref={sectionRef} 
      className={styles.insuranceManagementSection}
      aria-label={t('hero_section_aria_label')}
    >
      <div className={styles.container}>
        <div className={styles.content}>
          <h2 className={styles.title}>{t('hero_title')}</h2>
          <p className={styles.description}>{t('hero_description')}</p>
          <LandingButton 
            href="/ai-agents" 
            className={styles.ctaButton}
            aria-label={t('explore_capabilities_agents')}
          >
            {t('explore_capabilities_agents')}
          </LandingButton>
        </div>
        <Suspense 
          fallback={
            <div 
              className={styles.animationPlaceholder}
              aria-hidden="true"
            >
              <div className={styles.loadingIndicator}>
                {t('loading_animation')}
              </div>
            </div>
          }
        >
          {isIntersecting && <StructuredParticleAnimation />}
        </Suspense>
      </div>
    </section>
  );
};

export default InsuranceManagementSection;