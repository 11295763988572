// File: frontend/src/pages/AutoInsight/VehicleDetail/components/VehicleOverview/index.js

import React from 'react';
import { useLanguage } from '../../../../../../contexts/LanguageContext';
import styles from './VehicleOverview.module.css';

// Helper function to format date
const formatDate = (dateString) => {
  if (!dateString) return 'N/A';
  
  const date = new Date(dateString);
  
  // Check if date is valid
  if (isNaN(date.getTime())) return 'Invalid Date';
  
  return date.toLocaleDateString();
};

const VehicleOverview = ({ vehicle }) => {
  const { t } = useLanguage();

  return (
    <div className={styles.container}>
      <div className={styles.grid}>
        <div className={styles.card}>
          <h3 className={styles.cardTitle}>{t('vehicle_information')}</h3>
          <div className={styles.cardContent}>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('vehicle_id')}:</div>
              <div className={styles.infoValue}>{vehicle.vehicle_id}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('vin')}:</div>
              <div className={styles.infoValue}>{vehicle.vin}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('license_plate')}:</div>
              <div className={styles.infoValue}>{vehicle.license_plate}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('serial_number')}:</div>
              <div className={styles.infoValue}>{vehicle.serial_number || 'N/A'}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('rspru_number')}:</div>
              <div className={styles.infoValue}>{vehicle.rspru_number || 'N/A'}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('primary_vehicle')}:</div>
              <div className={styles.infoValue}>
                {vehicle.is_primary_vehicle ? t('yes') : t('no')}
              </div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('added_on')}:</div>
              <div className={styles.infoValue}>{formatDate(vehicle.vehicle_added_timestamp)}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('last_updated')}:</div>
              <div className={styles.infoValue}>{formatDate(vehicle.updated_at)}</div>
            </div>
          </div>
        </div>
        
        <div className={styles.card}>
          <h3 className={styles.cardTitle}>{t('vehicle_details')}</h3>
          <div className={styles.cardContent}>
            {vehicle.details ? (
              <>
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('year')}:</div>
                  <div className={styles.infoValue}>{vehicle.details.vehicle_year}</div>
                </div>
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('make')}:</div>
                  <div className={styles.infoValue}>{vehicle.details.vehicle_make}</div>
                </div>
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('model')}:</div>
                  <div className={styles.infoValue}>{vehicle.details.vehicle_model}</div>
                </div>
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('series')}:</div>
                  <div className={styles.infoValue}>
                    {vehicle.details.vehicle_series_1 || 'N/A'}
                    {vehicle.details.vehicle_series_2 && ` / ${vehicle.details.vehicle_series_2}`}
                    {vehicle.details.vehicle_series_3 && ` / ${vehicle.details.vehicle_series_3}`}
                  </div>
                </div>
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('trim')}:</div>
                  <div className={styles.infoValue}>
                    {vehicle.details.vehicle_trim_1 || 'N/A'}
                    {vehicle.details.vehicle_trim_2 && ` / ${vehicle.details.vehicle_trim_2}`}
                    {vehicle.details.vehicle_trim_3 && ` / ${vehicle.details.vehicle_trim_3}`}
                  </div>
                </div>
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('color')}:</div>
                  <div className={styles.infoValue}>{vehicle.details.vehicle_color || 'N/A'}</div>
                </div>
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('body_type')}:</div>
                  <div className={styles.infoValue}>{vehicle.details.vehicle_body_type || 'N/A'}</div>
                </div>
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('vehicle_type')}:</div>
                  <div className={styles.infoValue}>{vehicle.details.vehicle_type || 'N/A'}</div>
                </div>
              </>
            ) : (
              <div className={styles.noData}>{t('no_vehicle_details_available')}</div>
            )}
          </div>
        </div>
      </div>
      
      <div className={styles.card}>
        <h3 className={styles.cardTitle}>{t('engine_specifications')}</h3>
        <div className={styles.cardContent}>
          {vehicle.details ? (
            <div className={styles.engineGrid}>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('engine_size')}:</div>
                <div className={styles.infoValue}>
                  {vehicle.details.vehicle_engine_size} {vehicle.details.vehicle_engine_unit}
                </div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('fuel_type')}:</div>
                <div className={styles.infoValue}>{vehicle.details.vehicle_primary_fuel_type || 'N/A'}</div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('secondary_fuel_type')}:</div>
                <div className={styles.infoValue}>{vehicle.details.vehicle_secondary_fuel_type || 'N/A'}</div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('drive_type')}:</div>
                <div className={styles.infoValue}>{vehicle.details.vehicle_drive_type || 'N/A'}</div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('transmission')}:</div>
                <div className={styles.infoValue}>{vehicle.details.vehicle_transmission_style || 'N/A'}</div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('horsepower')}:</div>
                <div className={styles.infoValue}>{vehicle.details.vehicle_horsepower || 'N/A'}</div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('engine_configuration')}:</div>
                <div className={styles.infoValue}>{vehicle.details.vehicle_engine_configuration || 'N/A'}</div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('cylinders')}:</div>
                <div className={styles.infoValue}>{vehicle.details.vehicle_engine_cylinders || 'N/A'}</div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('engine_model')}:</div>
                <div className={styles.infoValue}>{vehicle.details.vehicle_engine_model || 'N/A'}</div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('valve_train')}:</div>
                <div className={styles.infoValue}>{vehicle.details.vehicle_valve_train_design || 'N/A'}</div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('fuel_delivery')}:</div>
                <div className={styles.infoValue}>{vehicle.details.vehicle_fuel_delivery_type || 'N/A'}</div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('cooling_type')}:</div>
                <div className={styles.infoValue}>{vehicle.details.vehicle_cooling_type || 'N/A'}</div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('turbo')}:</div>
                <div className={styles.infoValue}>{vehicle.details.vehicle_turbo ? t('yes') : t('no')}</div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('top_speed')}:</div>
                <div className={styles.infoValue}>
                  {vehicle.details.vehicle_top_speed ? `${vehicle.details.vehicle_top_speed} mph` : 'N/A'}
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.noData}>{t('no_engine_specifications_available')}</div>
          )}
        </div>
      </div>
      
      <div className={styles.card}>
        <h3 className={styles.cardTitle}>{t('dimensions_and_weight')}</h3>
        <div className={styles.cardContent}>
          {vehicle.details ? (
            <div className={styles.dimensionsGrid}>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('gross_weight_rating')}:</div>
                <div className={styles.infoValue}>
                  {vehicle.details.vehicle_gross_weight_rating_min || 'N/A'} - {vehicle.details.vehicle_gross_weight_rating_max || 'N/A'}
                </div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('curb_weight')}:</div>
                <div className={styles.infoValue}>
                  {vehicle.details.vehicle_curb_weight ? `${vehicle.details.vehicle_curb_weight} lbs` : 'N/A'}
                </div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('wheelbase')}:</div>
                <div className={styles.infoValue}>
                  {vehicle.details.vehicle_wheelbase ? `${vehicle.details.vehicle_wheelbase} in` : 'N/A'}
                </div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('track_width')}:</div>
                <div className={styles.infoValue}>
                  {vehicle.details.vehicle_track_width ? `${vehicle.details.vehicle_track_width} in` : 'N/A'}
                </div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('number_of_doors')}:</div>
                <div className={styles.infoValue}>{vehicle.details.vehicle_number_of_doors || 'N/A'}</div>
              </div>
            </div>
          ) : (
            <div className={styles.noData}>{t('no_dimensions_available')}</div>
          )}
        </div>
      </div>
      
      <div className={styles.card}>
        <h3 className={styles.cardTitle}>{t('manufacturing_information')}</h3>
        <div className={styles.cardContent}>
          {vehicle.details ? (
            <div className={styles.manufacturingGrid}>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('plant_country')}:</div>
                <div className={styles.infoValue}>{vehicle.details.vehicle_plant_country || 'N/A'}</div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('plant_city')}:</div>
                <div className={styles.infoValue}>{vehicle.details.vehicle_plant_city || 'N/A'}</div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('plant_company')}:</div>
                <div className={styles.infoValue}>{vehicle.details.vehicle_plant_company || 'N/A'}</div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('vicc_code')}:</div>
                <div className={styles.infoValue}>{vehicle.details.vicc_code || 'N/A'}</div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('vicc_code_ext')}:</div>
                <div className={styles.infoValue}>{vehicle.details.vicc_code_ext || 'N/A'}</div>
              </div>
            </div>
          ) : (
            <div className={styles.noData}>{t('no_manufacturing_information_available')}</div>
          )}
        </div>
      </div>
      
      {vehicle.modifications && vehicle.modifications.vehicle_modified_customized && (
        <div className={styles.card}>
          <h3 className={styles.cardTitle}>{t('modifications')}</h3>
          <div className={styles.cardContent}>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('modified')}:</div>
              <div className={styles.infoValue}>{t('yes')}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('modification_date')}:</div>
              <div className={styles.infoValue}>{formatDate(vehicle.modifications.vehicle_modification_added_timestamp)}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('description')}:</div>
              <div className={styles.infoValue}>{vehicle.modifications.vehicle_modification_description || 'N/A'}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VehicleOverview;