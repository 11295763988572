// File: frontend/src/styles/colors/colorSystem.js

export const colorSystem = {
    fivePiece: {
      warm: [
        'var(--color-yellow-100)',
        'var(--color-yellow-200)',
        'var(--color-orange-300)',
        'var(--color-orange-400)',
        'var(--color-red-500)'
      ],
      cool: [
        'var(--color-blue-100)',
        'var(--color-green-200)',
        'var(--color-green-300)',
        'var(--color-yellow-400)',
        'var(--color-red-600)'
      ]
    },
    scoringAndCustomer: {
      yellow: [
        'var(--color-yellow-110)',
        'var(--color-yellow-210)',
        'var(--color-yellow-310)',
        'var(--color-brown-410)',
        'var(--color-brown-510)'
      ],
      red: [
        'var(--color-red-110)',
        'var(--color-red-210)',
        'var(--color-red-310)',
        'var(--color-red-410)',
        'var(--color-red-510)'
      ],
      green: [
        'var(--color-green-110)',
        'var(--color-green-210)',
        'var(--color-green-310)',
        'var(--color-green-410)',
        'var(--color-green-510)'
      ],
      orange: [
        'var(--color-orange-110)',
        'var(--color-orange-210)',
        'var(--color-orange-310)',
        'var(--color-orange-410)',
        'var(--color-orange-510)'
      ],
      blue: [
        'var(--color-blue-110)',
        'var(--color-blue-210)',
        'var(--color-blue-310)',
        'var(--color-blue-410)',
        'var(--color-blue-510)'
      ]
    },
    sevenPiece: {
      cool: [
        'var(--color-green-120)',
        'var(--color-green-220)',
        'var(--color-green-320)',
        'var(--color-green-420)',
        'var(--color-green-520)',
        'var(--color-blue-620)',
        'var(--color-blue-720)'
      ],
      warm: [
        'var(--color-green-130)',
        'var(--color-green-230)',
        'var(--color-green-330)',
        'var(--color-yellow-430)',
        'var(--color-yellow-530)',
        'var(--color-yellow-630)',
        'var(--color-yellow-730)'
      ]
    },
    ninePiece: {
      cool: [
        'var(--color-white)',
        'var(--color-pink-100)',
        'var(--color-purple-200)',
        'var(--color-blue-300)',
        'var(--color-cyan-400)',
        'var(--color-green-500)',
        'var(--color-yellow-600)',
        'var(--color-orange-700)',
        'var(--color-red-800)'
      ],
      warm: [
        'var(--color-pink-110)',
        'var(--color-pink-210)',
        'var(--color-pink-310)',
        'var(--color-pink-410)',
        'var(--color-pink-510)',
        'var(--color-pink-610)',
        'var(--color-pink-710)',
        'var(--color-pink-810)',
        'var(--color-red-910)'
      ]
    },
    elevenPiece: {
      heatmap: [
        'var(--color-blue-140)',
        'var(--color-blue-240)',
        'var(--color-blue-340)',
        'var(--color-blue-440)',
        'var(--color-blue-540)',
        'var(--color-blue-640)',
        'var(--color-blue-740)',
        'var(--color-blue-840)',
        'var(--color-blue-940)',
        'var(--color-blue-1040)',
        'var(--color-blue-1140)'
      ]
    }
  };
  
  export default colorSystem;