// src/components/KnowledgeTransfer/ParameterSelector.js
import React from 'react';
import styles from './ParameterSelector.module.css';
import platoConfig from '../../config/platoConfig.json';

const ParameterSelector = ({ parameters, onParameterChange }) => {
  if (!parameters) {
    return <div>Loading parameters...</div>;
  }

  const handleSliderChange = (paramName, value) => {
    onParameterChange(paramName, parseFloat(value));
  };

  const handleSelectChange = (paramName, value) => {
    onParameterChange(paramName, value);
  };

  const handleToneChange = (tone, value) => {
    const newTones = { ...parameters.tone, [tone]: parseFloat(value) };
    onParameterChange('tone', newTones);
  };

  return (
    <div className={styles.parameterSelector}>
      <h3>Parameters</h3>
      
      {Object.entries(platoConfig.parameters).map(([key, config]) => {
        switch (config.type) {
          case 'integer':
          case 'number':
            return (
              <div key={key} className={styles.parameter}>
                <label htmlFor={key}>{key.replace('_', ' ')}:</label>
                <input
                  type="range"
                  id={key}
                  min={config.min}
                  max={config.max}
                  step={config.step}
                  value={parameters[key] || config.default}
                  onChange={(e) => handleSliderChange(key, e.target.value)}
                />
                <span>{parameters[key] || config.default}</span>
              </div>
            );
          case 'string':
            return (
              <div key={key} className={styles.parameter}>
                <label htmlFor={key}>{key.replace('_', ' ')}:</label>
                <select
                  id={key}
                  value={parameters[key] || config.default}
                  onChange={(e) => handleSelectChange(key, e.target.value)}
                >
                  {config.options.map(option => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
              </div>
            );
          case 'boolean':
            return (
              <div key={key} className={styles.parameter}>
                <label htmlFor={key}>{key.replace('_', ' ')}:</label>
                <input
                  type="checkbox"
                  id={key}
                  checked={parameters[key] || config.default}
                  onChange={(e) => onParameterChange(key, e.target.checked)}
                />
              </div>
            );
          case 'object': // For tone
            return (
              <div key={key} className={styles.parameter}>
                <label>{key.replace('_', ' ')}:</label>
                {config.options.map(tone => (
                  <div key={tone} className={styles.toneOption}>
                    <label htmlFor={`tone_${tone}`}>{tone}</label>
                    <input
                      type="range"
                      id={`tone_${tone}`}
                      min={config.values[0]}
                      max={config.values[config.values.length - 1]}
                      step={config.values[1] - config.values[0]}
                      value={parameters.tone[tone] || 0}
                      onChange={(e) => handleToneChange(tone, e.target.value)}
                    />
                    <span>{parameters.tone[tone] || 0}</span>
                  </div>
                ))}
              </div>
            );
          default:
            return null;
        }
      })}
    </div>
  );
};

export default ParameterSelector;