// File: frontend/src/pages/EmbeddedInsurance/components/ProductBuilder/JurisdictionSelector.js

import React, { useState } from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import { getJurisdictions } from '../../data/apiEndpoints';
import styles from './styles/JurisdictionSelector.module.css';

const JurisdictionSelector = ({
  selectedJurisdiction,
  onJurisdictionSelect
}) => {
  const { t } = useLanguage();
  const jurisdictions = getJurisdictions();
  const [searchTerm, setSearchTerm] = useState('');
  const [activeView, setActiveView] = useState('grid'); // 'grid' or 'map'

  const filteredJurisdictions = searchTerm
    ? jurisdictions.filter(j => 
        j.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        j.code.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : jurisdictions;

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const toggleView = (view) => {
    setActiveView(view);
  };

  return (
    <div className={styles.jurisdictionSelector}>
      <div className={styles.sectionHeader}>
        <h2 className={styles.sectionTitle}>{t('select_jurisdiction') || 'Select Jurisdiction'}</h2>
        <p className={styles.sectionDescription}>
          {t('select_jurisdiction_description') || 'Choose the jurisdiction where this insurance product will be offered.'}
        </p>
      </div>

      <div className={styles.jurisdictionControls}>
        <div className={styles.searchContainer}>
          <i className="fas fa-search"></i>
          <input
            type="text"
            className={styles.searchInput}
            placeholder={t('search_jurisdictions') || 'Search jurisdictions...'}
            value={searchTerm}
            onChange={handleSearch}
          />
          {searchTerm && (
            <button 
              className={styles.clearSearch}
              onClick={() => setSearchTerm('')}
            >
              <i className="fas fa-times"></i>
            </button>
          )}
        </div>

        <div className={styles.viewToggle}>
          <button
            className={`${styles.viewButton} ${activeView === 'grid' ? styles.activeView : ''}`}
            onClick={() => toggleView('grid')}
          >
            <i className="fas fa-th-large"></i>
          </button>
          <button
            className={`${styles.viewButton} ${activeView === 'map' ? styles.activeView : ''}`}
            onClick={() => toggleView('map')}
          >
            <i className="fas fa-map"></i>
          </button>
        </div>
      </div>

      {activeView === 'grid' ? (
        <div className={styles.jurisdictionGrid}>
          {filteredJurisdictions.map((jurisdiction) => (
            <div
              key={jurisdiction.id}
              className={`${styles.jurisdictionCard} ${selectedJurisdiction === jurisdiction.id ? styles.selectedCard : ''}`}
              onClick={() => onJurisdictionSelect(jurisdiction.id)}
            >
              <div className={styles.jurisdictionFlag}>
                {jurisdiction.flag || jurisdiction.code}
              </div>
              <div className={styles.jurisdictionInfo}>
                <h4 className={styles.jurisdictionName}>{jurisdiction.name}</h4>
                <div className={styles.jurisdictionCode}>{jurisdiction.code}</div>
              </div>
              {selectedJurisdiction === jurisdiction.id && (
                <div className={styles.selectedIndicator}>
                  <i className="fas fa-check-circle"></i>
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <div className={styles.jurisdictionMap}>
          {/* In a real implementation, this would be a map component */}
          <div className={styles.mapPlaceholder}>
            <i className="fas fa-map-marked-alt"></i>
            <p>{t('interactive_map_coming_soon') || 'Interactive map coming soon'}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default JurisdictionSelector;