// File: frontend/src/pages/InvestorPitch/components/slides/LeadershipSlide/LeaderProfile.js

import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import * as LucideIcons from 'lucide-react';
import styles from './LeaderProfile.module.css';

const LeaderProfile = ({ name, role, highlights, achievements, socialLinks, photoUrl }) => {
  const { t } = useLanguage();

  return (
    <motion.div 
      className={styles.container}
      whileHover={{ scale: 1.01 }}
      transition={{ type: 'spring', stiffness: 300, damping: 30 }}
    >
      <div className={styles.profile}>
        <div className={styles.avatar}>
          {photoUrl ? (
            <img 
              src={photoUrl} 
              alt={name}
              className={styles.avatarPhoto}
              aria-label={t('investor_pitch.leadership.profile.avatar.aria_label', 'Profile photo')}
            />
          ) : (
            <LucideIcons.User 
              className={styles.avatarIcon} 
              aria-label={t('investor_pitch.leadership.profile.avatar.aria_label', 'Profile icon')}
            />
          )}
        </div>
        <div className={styles.info}>
          <div className={styles.nameSection}>
            <div className={styles.nameRole}>
              <h3 className={styles.name}>{name}</h3>
              <p className={styles.role}>{role}</p>
            </div>
            <div className={styles.socialLinks}>
              <a 
                href={socialLinks.linkedin}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.socialLink}
                aria-label={t('investor_pitch.leadership.social.linkedin.aria_label', 'LinkedIn profile')}
              >
                <img 
                  src="/icons/18/socials/linkedin/linkedin-logo.svg" 
                  alt="LinkedIn"
                  className={styles.socialIcon}
                />
              </a>
              <a 
                href={socialLinks.x}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.socialLink}
                aria-label={t('investor_pitch.leadership.social.x.aria_label', 'X profile')}
              >
                <img 
                  src="/icons/18/socials/x/x-logo.svg" 
                  alt="X"
                  className={styles.socialIcon}
                />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.highlights}>
        {highlights.map((highlight, index) => {
          const Icon = LucideIcons[highlight.icon];
          return (
            <div 
              key={index} 
              className={styles.highlight}
            >
              <div 
                className={styles.highlightIcon}
                style={{ color: highlight.color }}
              >
                <Icon size={24} />
              </div>
              <span className={styles.highlightText}>{highlight.title}</span>
            </div>
          );
        })}
      </div>

      <div className={styles.achievements}>
        {achievements.map((achievement, index) => (
          <div key={index} className={styles.achievement}>
            <div className={styles.bullet} />
            <span className={styles.achievementText}>{achievement}</span>
          </div>
        ))}
      </div>
    </motion.div>
  );
};

LeaderProfile.propTypes = {
  name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  highlights: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
  })).isRequired,
  achievements: PropTypes.arrayOf(PropTypes.string).isRequired,
  socialLinks: PropTypes.shape({
    linkedin: PropTypes.string.isRequired,
    x: PropTypes.string.isRequired,
  }).isRequired,
  photoUrl: PropTypes.string,
};

export default LeaderProfile;