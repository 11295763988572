// File: frontend/src/contexts/HeaderContext.js

import React, { createContext, useContext, useCallback, useState } from 'react';

const HeaderContext = createContext(null);

export const HeaderProvider = ({ children }) => {
  const [chatControls, setChatControls] = useState({
    chatId: null,
    onNewChat: null,
    onShareChat: null,
    showHistoryButton: true
  });

  const updateChatControls = useCallback((newControls) => {
    setChatControls(prev => {
      // Only update if values actually changed
      if (prev.chatId === newControls.chatId &&
          prev.onNewChat === newControls.onNewChat &&
          prev.onShareChat === newControls.onShareChat &&
          prev.showHistoryButton === newControls.showHistoryButton) {
        return prev;
      }
      return {
        ...newControls,
        showHistoryButton: newControls.showHistoryButton ?? true // Default to false if not provided
      };
    });
  }, []);

  const value = {
    chatControls,
    updateChatControls
  };

  return (
    <HeaderContext.Provider value={value}>
      {children}
    </HeaderContext.Provider>
  );
};

export const useHeader = () => {
  const context = useContext(HeaderContext);
  if (!context) {
    throw new Error('useHeader must be used within a HeaderProvider');
  }
  return context;
};