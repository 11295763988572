// File: frontend/src/utils/fileUtils.js

export const readFileAsText = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => resolve(event.target.result);
      reader.onerror = (error) => reject(error);
      reader.readAsText(file);
    });
  };
  
  export const parseJSON = (text) => {
    try {
      return JSON.parse(text);
    } catch (error) {
      console.error('Error parsing JSON:', error);
      throw error;
    }
  };
  
  export const parseCSV = (text) => {
    // Simple CSV parsing, you might want to use a library for more complex CSVs
    return text.split('\n').map(line => line.split(','));
  };