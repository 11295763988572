// File: frontend/src/pages/TripDetail/components/TripErrors/index.js

import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import styles from './TripErrors.module.css';

const TripErrors = ({ trip }) => {
  const { t } = useLanguage();
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTripErrors = async () => {
      if (!trip.trip_id) {
        setLoading(false);
        return;
      }

      setLoading(true);
      setError(null);
      
      try {
        // This would be replaced with an actual API call
        // const response = await tripService.getTripErrors(trip.trip_id);
        
        // Mock data for now
        await new Promise(resolve => setTimeout(resolve, 800));
        
        // Check if we're simulating errors
        if (trip.trip_id.includes('error')) {
          const mockErrors = [
            {
              error_id: 1,
              trip_id: trip.trip_id,
              user_id: trip.user_id,
              log_group_name: "/aws/lambda/trip-processor",
              log_stream_name: "2023/06/01/[$LATEST]abcdef123456",
              request_id: "a1b2c3d4-e5f6-7890-abcd-ef1234567890",
              error_type: "PROCESSING_ERROR",
              error_detail: "Failed to process trip data due to missing location information",
              updated_at: "2023-06-01T09:20:00Z"
            },
            {
              error_id: 2,
              trip_id: trip.trip_id,
              user_id: trip.user_id,
              log_group_name: "/aws/lambda/trip-scorer",
              log_stream_name: "2023/06/01/[$LATEST]fedcba654321",
              request_id: "z9y8x7w6-v5u4-3210-zyxw-vu9876543210",
              error_type: "SCORING_ERROR",
              error_detail: "Unable to calculate speeding score due to missing speed limit data",
              updated_at: "2023-06-01T09:22:00Z"
            }
          ];
          setErrors(mockErrors);
        } else {
          // No errors for this trip
          setErrors([]);
        }
      } catch (err) {
        console.error('Error fetching trip errors:', err);
        setError(t('failed_to_load_trip_errors'));
      } finally {
        setLoading(false);
      }
    };

    fetchTripErrors();
  }, [trip.trip_id, trip.user_id, t]);

  // Helper function to format date
  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <div className={styles.loadingSpinner}></div>
        <p>{t('loading_trip_errors')}</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.error}>
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {errors.length > 0 ? (
        <>
          <div className={styles.errorSummary}>
            <div className={styles.summaryCard}>
              <div className={styles.summaryTitle}>{t('total_errors')}</div>
              <div className={styles.summaryValue}>{errors.length}</div>
            </div>
            
            <div className={styles.summaryCard}>
              <div className={styles.summaryTitle}>{t('processing_errors')}</div>
              <div className={styles.summaryValue}>
                {errors.filter(e => e.error_type === 'PROCESSING_ERROR').length}
              </div>
            </div>
            
            <div className={styles.summaryCard}>
              <div className={styles.summaryTitle}>{t('scoring_errors')}</div>
              <div className={styles.summaryValue}>
                {errors.filter(e => e.error_type === 'SCORING_ERROR').length}
              </div>
            </div>
          </div>
          
          <div className={styles.errorsContainer}>
            <h3 className={styles.errorsTitle}>{t('error_details')}</h3>
            
            <div className={styles.errorsList}>
              {errors.map((errorItem) => (
                <div key={errorItem.error_id} className={styles.errorCard}>
                  <div className={styles.errorHeader}>
                    <div className={styles.errorType}>{errorItem.error_type}</div>
                    <div className={styles.errorDate}>{formatDate(errorItem.updated_at)}</div>
                  </div>
                  
                  <div className={styles.errorDetail}>
                    {errorItem.error_detail}
                  </div>
                  
                  <div className={styles.errorMeta}>
                    <div className={styles.metaItem}>
                      <span className={styles.metaLabel}>{t('error_id')}:</span>
                      <span className={styles.metaValue}>{errorItem.error_id}</span>
                    </div>
                    
                    <div className={styles.metaItem}>
                      <span className={styles.metaLabel}>{t('request_id')}:</span>
                      <span className={styles.metaValue}>{errorItem.request_id}</span>
                    </div>
                    
                    <div className={styles.metaItem}>
                      <span className={styles.metaLabel}>{t('log_group')}:</span>
                      <span className={styles.metaValue}>{errorItem.log_group_name}</span>
                    </div>
                    
                    <div className={styles.metaItem}>
                      <span className={styles.metaLabel}>{t('log_stream')}:</span>
                      <span className={styles.metaValue}>{errorItem.log_stream_name}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        <div className={styles.noErrors}>
          <div className={styles.noErrorsIcon}>✓</div>
          <h3 className={styles.noErrorsTitle}>{t('no_errors_found')}</h3>
          <p className={styles.noErrorsDescription}>
            {t('trip_processed_successfully')}
          </p>
        </div>
      )}
    </div>
  );
};

export default TripErrors;