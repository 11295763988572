// File: frontend/src/components/Auth/StatusScreen.js
import React from 'react';
import PropTypes from 'prop-types';
import { useLanguage } from '../../contexts/LanguageContext';
import styles from './StatusScreen.module.css';

const StatusScreen = ({ 
  icon, 
  title, 
  message, 
  additionalInfo,
  buttonText,
  onButtonClick,
  type = 'success'
}) => {
  const { t } = useLanguage();

  return (
    <div className={`${styles.statusScreen} ${styles[type]}`}>
      <img className={styles.statusIcon} src={icon} alt="" />
      <h2 className={styles.statusTitle}>{t(title)}</h2>
      <p className={styles.statusMessage}>{t(message)}</p>
      {additionalInfo && (
        <div className={styles.statusAdditionalInfo}>
          {additionalInfo}
        </div>
      )}
      {buttonText && (
        <button className={styles.statusButton} onClick={onButtonClick}>
          {t(buttonText)}
        </button>
      )}
    </div>
  );
};

StatusScreen.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  additionalInfo: PropTypes.node,
  buttonText: PropTypes.string,
  onButtonClick: PropTypes.func,
  type: PropTypes.oneOf(['success', 'error', 'warning'])
};

export default StatusScreen;