// File: frontend/src/pages/EmbeddedInsurance/components/ApiExplorer/ApiParameters.js

import React from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import styles from './styles/ApiParameters.module.css';

const ApiParameters = ({ parameters }) => {
  const { t } = useLanguage();
  
  // Group parameters by their location (path, query, header, etc.)
  const groupedParameters = parameters.reduce((acc, param) => {
    const group = param.in || 'other';
    if (!acc[group]) acc[group] = [];
    acc[group].push(param);
    return acc;
  }, {});

  return (
    <div className={styles.section}>
      <h3 className={styles.sectionTitle}>{t('parameters') || 'Parameters'}</h3>
      
      {Object.entries(groupedParameters).map(([group, params]) => (
        <div key={group} className={styles.parameterGroup}>
          <h4 className={styles.groupTitle}>
            {t(`${group}_parameters`) || `${group.charAt(0).toUpperCase() + group.slice(1)} Parameters`}
          </h4>
          
          <div className={styles.parameters}>
            {params.map((param, index) => (
              <div key={index} className={styles.parameter}>
                <div className={styles.parameterHeader}>
                  <div className={styles.parameterName}>
                    <code>{param.name}</code>
                    {param.required && <span className={styles.requiredBadge}>{t('required') || 'Required'}</span>}
                  </div>
                  <div className={styles.parameterType}>
                    {param.schema?.type || param.type || 'any'}
                  </div>
                </div>
                
                {param.description && (
                  <p className={styles.parameterDescription}>{param.description}</p>
                )}
                
                {param.schema?.enum && (
                  <div className={styles.enumValues}>
                    <span className={styles.enumLabel}>{t('possible_values') || 'Possible values'}:</span>
                    <div className={styles.enumList}>
                      {param.schema.enum.map((value, i) => (
                        <code key={i} className={styles.enumValue}>{value}</code>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ApiParameters;