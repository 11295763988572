// File: frontend/src/pages/Quote/components/ChatInterface/components/MarketComparison.js

import React from 'react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from '../ChatInterface.module.css';

const MarketComparison = ({ localQuoteData, onClose }) => {
  const { t } = useLanguage();
  
  if (!localQuoteData.currentPremium) return null;
  
  const marketLow = Math.round(localQuoteData.currentPremium * 0.9);
  const marketHigh = Math.round(localQuoteData.currentPremium * 1.2);
  const allegoryPremium = localQuoteData.currentPremium - localQuoteData.estimatedSavings;
  
  return (
    <div className={styles.marketPanel}>
      <div className={styles.marketPanelHeader}>
        <h3>{t('Market Comparison')}</h3>
        <button className={styles.closeButton} onClick={onClose}>×</button>
      </div>
      <div className={styles.marketContent}>
        <div className={styles.marketRange}>
          <div className={styles.marketTitle}>{t('People like you pay:')}</div>
          <div className={styles.rangeBar}>
            <div className={styles.rangeIndicator}>
              <span className={styles.rangeLow}>${marketLow}</span>
              <span className={styles.rangeHigh}>${marketHigh}</span>
            </div>
            <div className={styles.rangeBarInner}>
              <div 
                className={styles.allegoryMarker} 
                style={{ left: `${((allegoryPremium - marketLow) / (marketHigh - marketLow)) * 100}%` }}
              >
                <div className={styles.markerLabel}>Allegory</div>
                <div className={styles.markerAmount}>${allegoryPremium}</div>
              </div>
            </div>
          </div>
          <p className={styles.marketNote}>
            {t('Based on similar customers in your area with comparable coverage.')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default MarketComparison;