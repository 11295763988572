// File: frontend/src/pages/Dashboard/Dashboard.js

import React from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import WelcomeCard from '../../components/Dashboard/WelcomeCard';
import AllegoryIntelligenceCard from '../../components/Dashboard/AllegoryIntelligenceCard';
import ProductSuiteCard from '../../components/Dashboard/ProductSuiteCard';
import GlobalStatsCard from '../../components/Dashboard/GlobalStatsCard';
import PageTitle from '../../components/layout/DashboardLayout/PageTitle';
import AllegoryIntelligenceInitiator from '../../components/Dashboard/AllegoryIntelligenceInitiator';
import styles from './Dashboard.module.css';

const Dashboard = () => {
  const { t } = useLanguage();

  return (
    <div className={styles.dashboard}>
      <PageTitle title={t('homepage')} />
      <div className={styles.pageContent}>
        <WelcomeCard />
        {/* <AllegoryIntelligenceCard isActive={true} /> */}
        {/* <AllegoryIntelligenceInitiator /> */}
        <GlobalStatsCard />
        {/* <ProductSuiteCard /> */}
        <div className={styles.cardContainer}>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;