// File: frontend/src/pages/UMS/UserPortfolio/components/PortfolioOverview/UwDecisions.js

import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from './UwDecisions.module.css';

const UwDecisions = () => {
  const { t } = useLanguage();
  const [matrixData, setMatrixData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hoverInfo, setHoverInfo] = useState(null);

  useEffect(() => {
    // Simulating API call to fetch data
    setLoading(true);
    
    // Replace with actual API call
    setTimeout(() => {
      try {
        // Mock data - replace with actual data from your API
        const mockData = [];
        
        // Generate some sample data for the matrix
        for (let x = 1; x <= 10; x++) {
          for (let y = 1; y <= 10; y++) {
            // Calculate a percentage based on position
            const percentage = Math.round((x + y) / 20 * 100);
            
            // Determine color based on percentage
            let color;
            if (percentage < 30) color = '#FBB1BD';
            else if (percentage < 50) color = '#FF99AC';
            else if (percentage < 70) color = '#FF85A1';
            else if (percentage < 90) color = '#FF477E';
            else color = '#FF0A54';
            
            mockData.push({
              value_attributes: {
                x_values: x,
                y_values: y,
                colourHexCode: color,
                percentage
              }
            });
          }
        }
        
        setMatrixData(mockData);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    }, 1000);
  }, []);

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <div className={styles.spinner}></div>
        <p>{t('loading_matrix_data')}</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.errorContainer}>
        <i className="fas fa-exclamation-triangle"></i>
        <p>{t('error_loading_matrix')}: {error}</p>
      </div>
    );
  }

  if (!matrixData || matrixData.length === 0) {
    return (
      <div className={styles.noDataContainer}>
        <p>{t('no_matrix_data_available')}</p>
      </div>
    );
  }

  // Create a 10x10 grid
  const grid = Array(10).fill(null).map(() => Array(10).fill(null));
  
  // Fill the grid with data
  matrixData.forEach(item => {
    const { x_values, y_values, colourHexCode, percentage } = item.value_attributes;
    if (x_values > 0 && y_values > 0) {
      grid[10 - y_values][x_values - 1] = { color: colourHexCode, x_values, y_values, percentage };
    }
  });

  const handleMouseEnter = (cell) => {
    if (cell) {
      setHoverInfo(cell);
    }
  };

  const handleMouseLeave = () => {
    setHoverInfo(null);
  };

  return (
    <div className={styles.container}>
      <div className={styles.matrixContainer}>
        <div className={styles.yAxisLabel}>{t('risk_score')}</div>
        <table className={styles.matrix}>
          <thead>
            <tr>
              <th></th>
              {Array.from({ length: 10 }, (_, i) => (
                <th key={i}>{i + 1}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {grid.map((row, rowIndex) => (
              <tr key={rowIndex}>
                <th>{10 - rowIndex}</th>
                {row.map((cell, colIndex) => (
                  <td
                    key={colIndex}
                    style={{
                      backgroundColor: cell ? cell.color : '#fff',
                    }}
                    onMouseEnter={() => handleMouseEnter(cell)}
                    onMouseLeave={handleMouseLeave}
                  />
                ))}
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <th></th>
              {Array.from({ length: 10 }, (_, i) => (
                <th key={i}>{i + 1}</th>
              ))}
            </tr>
          </tfoot>
        </table>
        <div className={styles.xAxisLabel}>{t('driving_score')}</div>
      </div>
      
      {hoverInfo && (
        <div className={styles.tooltip}>
          <div className={styles.tooltipHeader}>
            <span className={styles.tooltipTitle}>{t('cell_details')}</span>
            <button className={styles.tooltipClose} onClick={() => setHoverInfo(null)}>×</button>
          </div>
          <div className={styles.tooltipContent}>
            <div className={styles.tooltipRow}>
              <span className={styles.tooltipLabel}>{t('x_axis_value')}:</span>
              <span className={styles.tooltipValue}>{hoverInfo.x_values}</span>
            </div>
            <div className={styles.tooltipRow}>
              <span className={styles.tooltipLabel}>{t('y_axis_value')}:</span>
              <span className={styles.tooltipValue}>{hoverInfo.y_values}</span>
            </div>
            <div className={styles.tooltipRow}>
              <span className={styles.tooltipLabel}>{t('percentage')}:</span>
              <span className={styles.tooltipValue}>{hoverInfo.percentage}%</span>
            </div>
            <button className={styles.campaignButton} disabled>
              {t('create_campaign')}
            </button>
          </div>
        </div>
      )}
      
      <div className={styles.legend}>
        <div className={styles.legendTitle}>{t('percentage_legend')}</div>
        <div className={styles.legendItems}>
          <div className={styles.legendItem}>
            <div className={styles.legendColor} style={{ backgroundColor: '#FBB1BD' }}></div>
            <div className={styles.legendLabel}>0-30%</div>
          </div>
          <div className={styles.legendItem}>
            <div className={styles.legendColor} style={{ backgroundColor: '#FF99AC' }}></div>
            <div className={styles.legendLabel}>30-50%</div>
          </div>
          <div className={styles.legendItem}>
            <div className={styles.legendColor} style={{ backgroundColor: '#FF85A1' }}></div>
            <div className={styles.legendLabel}>50-70%</div>
          </div>
          <div className={styles.legendItem}>
            <div className={styles.legendColor} style={{ backgroundColor: '#FF477E' }}></div>
            <div className={styles.legendLabel}>70-90%</div>
          </div>
          <div className={styles.legendItem}>
            <div className={styles.legendColor} style={{ backgroundColor: '#FF0A54' }}></div>
            <div className={styles.legendLabel}>90-100%</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UwDecisions;