// File: frontend/src/components/Home/HeroSection.js

import React, { useRef, useEffect, useState, lazy, Suspense } from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import LandingButton from '../../components/Button/LandingButton';
import styles from './HeroSection.module.css';

const ParticleAnimation = lazy(() => import('./ParticleAnimation'));

// File: frontend/src/components/Home/HeroSection.js

const HeroSection = () => {
  const { t } = useLanguage();
  const [isVisible, setIsVisible] = useState(false);
  const [isIntersecting, setIsIntersecting] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
      const observer = new IntersectionObserver(
          (entries) => {
              const entry = entries[0];
              const visibleRatio = entry.intersectionRatio;
              
              setIsIntersecting(entry.isIntersecting);
              setIsVisible(visibleRatio >= 0.2);
          },
          { 
              threshold: [0, 0.2, 0.4, 0.6, 0.8, 1.0],
              rootMargin: '50px 0px'
          }
      );

      if (sectionRef.current) {
          observer.observe(sectionRef.current);
      }

      return () => observer.disconnect();
  }, []);

  return (
      <section 
          ref={sectionRef} 
          className={styles.heroSection}
          aria-label={t('hero_section_aria_label')}
      >
          <div className={styles.animationContainer}>
              <Suspense 
                  fallback={
                      <div 
                          className={styles.animationPlaceholder}
                          aria-hidden="true"
                      >
                          <div className={styles.loadingIndicator}>
                              {t('loading_animation')}
                          </div>
                      </div>
                  }
              >
                  {isIntersecting && <ParticleAnimation />}
              </Suspense>
          </div>
          {/* <div className={styles.content}>
              <h1 className={styles.title}>{t('insurance_management_title')}</h1>
              <p className={styles.description}>
                  {t('insurance_management_description')}
              </p>
              <LandingButton 
                  href="/sign-up" 
                  className={styles.ctaButton}
                  aria-label={t('get_started')}
              >
                  {t('get_started')}
              </LandingButton>
          </div> */}
      </section>
  );
};

export default HeroSection;