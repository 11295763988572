// File: frontend/src/pages/AnimationGeneration/ChartExample.js

import React, { useState, useCallback } from 'react';
import { LineChart, Bar, Cell, Line, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ComposedChart, ReferenceLine } from 'recharts';
import styles from './InsuranceModel.module.css';

const formatCurrency = (value) => {
  if (value >= 1000000) {
      const millions = value / 1000000;
      return `$${millions < 10 ? millions.toFixed(0) : Math.round(millions)}M`;
  } else if (value >= 1000) {
      const thousands = value / 1000;
      return `$${thousands < 10 ? thousands.toFixed(1) : Math.round(thousands)}k`;
  }
  return `$${Math.round(value)}`;
};

// Market calculations
const US = {
    gwp: 3.2e9,
    portfolio_mix: {
        auto: 0.45,
        home: 0.40,
        commercial: 0.15
    },
    expense_ratios: {
        operational: {auto: 0.08, home: 0.09, commercial: 0.11},
        acquisition: {auto: 0.20, home: 0.22, commercial: 0.25},
        IT: {auto: 0.03, home: 0.03, commercial: 0.04}
    }
};

const calculateWeightedAverage = (ratios) => {
    return Object.keys(US.portfolio_mix).reduce((acc, line) => {
        return acc + (ratios[line] * US.portfolio_mix[line]);
    }, 0);
};

const weightedOperational = calculateWeightedAverage(US.expense_ratios.operational);
const weightedAcquisition = calculateWeightedAverage(US.expense_ratios.acquisition);
const weightedIT = calculateWeightedAverage(US.expense_ratios.IT);
const totalExpenseRatio = weightedOperational + weightedAcquisition + weightedIT;
const totalExpenseAmount = US.gwp * totalExpenseRatio;

// Distribution Parameters
const DISTRIBUTION_PARAMS = {
    // Market Configuration
    TOTAL_EXPENSE_AMOUNT: totalExpenseAmount,
    TOLERANCE_PERCENTAGE: 0.005,

    // First Peak (Around $5K)
    PEAK_1_AMOUNT: 2500,
    PEAK_1_PROPORTION: 0.30,
    PEAK_1_SPREAD: 0.4,

    // Second Peak (Around $15K)
    PEAK_2_AMOUNT: 10000,
    PEAK_2_PROPORTION: 0.30,
    PEAK_2_SPREAD: 0.45,

    // Third Peak (Around $100K)
    PEAK_3_AMOUNT: 30000,
    PEAK_3_PROPORTION: 0.20,
    PEAK_3_SPREAD: 0.5,

    // Heavy Tail Component
    TAIL_THRESHOLD: 100000,
    TAIL_PROPORTION: 0.20,
    TAIL_SPREAD: 500000,
    TAIL_SHAPE: 0.3,

    // AI Impact per segment
    AI_IMPACTS: {
      SEGMENT_1: { // Up to PEAK_1_AMOUNT
          REDUCTION: 0.999,
          COLOR: 'var(--color-blue-210)' // Lightest shade
      },
      SEGMENT_2: { // PEAK_1_AMOUNT to PEAK_2_AMOUNT
          REDUCTION: 0.80,
          COLOR: 'var(--color-blue-310)'
      },
      SEGMENT_3: { // PEAK_2_AMOUNT to PEAK_3_AMOUNT
          REDUCTION: 0.75,
          COLOR: 'var(--color-blue-410)'
      },
      SEGMENT_4: { // Above PEAK_3_AMOUNT
          REDUCTION: 0.50,
          COLOR: 'var(--color-blue-510)' // Darkest shade
      }
    },

    // Visualization Parameters
    MAX_X_VALUE: 1000000,
    GRANULARITY_LEVELS: {
        VERY_FINE: { threshold: 10000, step: 500 },    // For the first peak around 5K
        FINE: { threshold: 50000, step: 1000 },        // For the second peak around 15K
        MEDIUM: { threshold: 100000, step: 2500 },     // For the third peak around 100K
        COARSE: { threshold: 25000000, step: 50000 }   // For the tail
    },

    // Initial scaling factor
    FREQUENCY_SCALE: 1
};

const ModelMethodology = () => {
  return (
      <div className={styles['methodology-container']}>
          <h3 className={styles['methodology-title']}>Model Methodology & Assumptions</h3>
          
          <div className={styles['methodology-section']}>
              <h4>Distribution Characteristics</h4>
              <ul className={styles['methodology-list']}>
                  <li>
                      <strong>Trimodal Distribution:</strong> The model employs a trimodal distribution to reflect the natural clustering of operational costs across different complexity levels:
                      <ul>
                          <li>Simple Operations (Peak 1): {formatCurrency(DISTRIBUTION_PARAMS.PEAK_1_AMOUNT)} - Routine tasks, automated workflows</li>
                          <li>Medium Complexity (Peak 2): {formatCurrency(DISTRIBUTION_PARAMS.PEAK_2_AMOUNT)} - Semi-complex operations</li>
                          <li>High Complexity (Peak 3): {formatCurrency(DISTRIBUTION_PARAMS.PEAK_3_AMOUNT)} - Strategic initiatives</li>
                      </ul>
                  </li>
                  <li>
                      <strong>Heavy Tail Component:</strong> Accounts for high-cost, strategic projects with threshold at {formatCurrency(DISTRIBUTION_PARAMS.TAIL_THRESHOLD)}
                  </li>
              </ul>
          </div>

          <div className={styles['methodology-section']}>
              <h4>Workflow-Based Cost Analysis</h4>
              <p>Rather than traditional cost-center analysis, this model deconstructs operational expenses into individual workflows:</p>
              <div className={styles['workflow-examples']}>
                  <div className={styles['workflow-category']}>
                      <h5>Customer Service Operations</h5>
                      <ul>
                          <li>Quote Generation & Binding</li>
                          <li>Policy Servicing</li>
                          <li>Claims Processing</li>
                          <li>Call Center Operations</li>
                      </ul>
                  </div>
                  <div className={styles['workflow-category']}>
                      <h5>Technical Operations</h5>
                      <ul>
                          <li>Product Development</li>
                          <li>Underwriting Rules Updates</li>
                          <li>System Maintenance</li>
                          <li>IT Project Implementation</li>
                      </ul>
                  </div>
                  <div className={styles['workflow-category']}>
                      <h5>Strategic Initiatives</h5>
                      <ul>
                          <li>Digital Transformation</li>
                          <li>Process Automation</li>
                          <li>Market Expansion Projects</li>
                          <li>Core System Updates</li>
                      </ul>
                  </div>
              </div>
          </div>

          <div className={styles['methodology-section']}>
              <h4>Key Model Parameters (Before model fit)</h4>
              <div className={styles['parameters-grid']}>
                  <div className={styles['parameter-card']}>
                      <h5>Distribution Parameters</h5>
                      <table className={styles['parameter-table']}>
                          <tbody>
                              <tr>
                                  <td>Kurtosis:</td>
                                  <td>10.49 (leptokurtic - heavy tails)</td>
                              </tr>
                              <tr>
                                  <td>Skewness:</td>
                                  <td>2.17 (right-skewed)</td>
                              </tr>
                              <tr>
                                  <td>Peak Ratios:</td>
                                  <td>{DISTRIBUTION_PARAMS.PEAK_1_PROPORTION} : {DISTRIBUTION_PARAMS.PEAK_2_PROPORTION} : {DISTRIBUTION_PARAMS.PEAK_3_PROPORTION}</td>
                              </tr>
                          </tbody>
                      </table>
                  </div>
                  <div className={styles['parameter-card']}>
                      <h5>Market Calibration</h5>
                      <table className={styles['parameter-table']}>
                          <tbody>
                              <tr>
                                  <td>Total GWP:</td>
                                  <td>{formatCurrency(US.gwp)}</td>
                              </tr>
                              <tr>
                                  <td>Expense Ratio:</td>
                                  <td>{(totalExpenseRatio * 100).toFixed(1)}%</td>
                              </tr>
                              <tr>
                                  <td>Portfolio Mix:</td>
                                  <td>{Object.entries(US.portfolio_mix).map(([key, value]) => 
                                      `${key}: ${(value * 100).toFixed(0)}%`).join(', ')}</td>
                              </tr>
                          </tbody>
                      </table>
                  </div>
              </div>
          </div>

          <div className={styles['methodology-section']}>
              <h4>Novel Approach: Workflow-Centric Analysis</h4>
              <p className={styles['methodology-highlight']}>
                  This model revolutionizes traditional expense analysis by focusing on operational workflows rather than 
                  static cost allocations. Each expense is mapped to its corresponding operational workflow, allowing for:
              </p>
              <ul className={styles['methodology-benefits']}>
                  <li>Precise identification of automation opportunities</li>
                  <li>Accurate assessment of AI impact potential</li>
                  <li>Granular understanding of operational complexity</li>
                  <li>Strategic prioritization of transformation initiatives</li>
              </ul>
          </div>
      </div>
  );
};

const WaterfallChart = () => {
  // Calculate running total for the final value
  const calculateRunningTotal = (data) => {
      let total = 0;
      return data.map(item => {
          if (item.type === 'total') {
              total = item.value;
              return { ...item, running: total };
          } else if (item.type === 'reduction') {
              total += item.value;
              return { ...item, running: total };
          } else {
              return { ...item, value: total, running: total };
          }
      });
  };

  const initialData = [
      { 
          name: 'Current\nExpenses', 
          value: totalExpenseAmount, 
          type: 'total',
          color: 'var(--color-red-310)'
      },
      { 
          name: 'Simple\nTasks', 
          value: -totalExpenseAmount * 0.30 * DISTRIBUTION_PARAMS.AI_IMPACTS.SEGMENT_1.REDUCTION, 
          type: 'reduction',
          color: 'var(--color-blue-210)'
      },
      { 
          name: 'Medium\nTasks', 
          value: -totalExpenseAmount * 0.30 * DISTRIBUTION_PARAMS.AI_IMPACTS.SEGMENT_2.REDUCTION, 
          type: 'reduction',
          color: 'var(--color-blue-310)'
      },
      { 
          name: 'Complex\nTasks', 
          value: -totalExpenseAmount * 0.25 * DISTRIBUTION_PARAMS.AI_IMPACTS.SEGMENT_3.REDUCTION, 
          type: 'reduction',
          color: 'var(--color-blue-410)'
      },
      { 
          name: 'Strategic\nInitiatives', 
          value: -totalExpenseAmount * 0.15 * DISTRIBUTION_PARAMS.AI_IMPACTS.SEGMENT_4.REDUCTION, 
          type: 'reduction',
          color: 'var(--color-blue-510)'
      },
      { 
          name: 'Future\nExpenses', 
          type: 'final',
          color: 'var(--color-success)'
      }
  ];

  const data = calculateRunningTotal(initialData);

  return (
      <div className={styles['waterfall-container']}>
          <h3 className={styles['waterfall-title']}>Cost Reduction Waterfall</h3>
          <div className={styles['waterfall-chart']}>
              <ResponsiveContainer width="100%" height={400}>
                  <ComposedChart
                      data={data}
                      margin={{ top: 20, right: 30, left: 40, bottom: 60 }}
                  >
                      <CartesianGrid 
                          strokeDasharray="3 3" 
                          vertical={false}
                      />
                      <XAxis 
                          dataKey="name"
                          angle={0}
                          interval={0}
                          tick={{
                              fill: 'var(--text-secondary)',
                              fontSize: 'var(--font-size-sm)',
                              fontFamily: 'var(--font-primary)'
                          }}
                          tickLine={false}
                          axisLine={{ stroke: 'var(--color-border)' }}
                      />
                      <YAxis 
                          tickFormatter={(value) => `$${Math.abs(value / 1000000).toFixed(0)}M`}
                          label={{ 
                              value: 'Cost Amount', 
                              angle: -90, 
                              position: 'insideLeft',
                              style: {
                                  fill: 'var(--text-secondary)',
                                  fontSize: 'var(--font-size-sm)',
                                  fontFamily: 'var(--font-primary)'
                              }
                          }}
                          tick={{
                              fill: 'var(--text-secondary)',
                              fontSize: 'var(--font-size-sm)',
                              fontFamily: 'var(--font-primary)'
                          }}
                          tickLine={false}
                          axisLine={{ stroke: 'var(--color-border)' }}
                      />
                      <Tooltip 
                          content={({ active, payload, label }) => {
                              if (active && payload && payload.length) {
                                  const data = payload[0].payload;
                                  return (
                                      <div className={styles['waterfall-tooltip']}>
                                          <p className={styles['tooltip-label']}>{label.replace('\n', ' ')}</p>
                                          <p className={styles['tooltip-value']}>
                                              {data.type === 'reduction' 
                                                  ? `Reduction: ${formatCurrency(Math.abs(data.value))}`
                                                  : `Total: ${formatCurrency(Math.abs(data.running))}`
                                              }
                                          </p>
                                          {data.type === 'reduction' && (
                                              <p className={styles['tooltip-percentage']}>
                                                  {`(${(Math.abs(data.value) / totalExpenseAmount * 100).toFixed(1)}% of original)`}
                                              </p>
                                          )}
                                      </div>
                                  );
                              }
                              return null;
                          }}
                      />
                      <Bar 
                          dataKey="value" 
                          radius={[4, 4, 0, 0]}
                      >
                          {
                              data.map((entry, index) => (
                                  <Cell 
                                      key={`cell-${index}`}
                                      fill={entry.color}
                                  />
                              ))
                          }
                      </Bar>
                      <Line
                          type="step"
                          dataKey="running"
                          stroke="var(--color-gray-600)"
                          strokeWidth={2}
                          dot={{
                              fill: 'var(--color-gray-600)',
                              r: 4
                          }}
                          activeDot={{
                              r: 6,
                              fill: 'var(--color-primary)'
                          }}
                      />
                  </ComposedChart>
              </ResponsiveContainer>
          </div>
      </div>
  );
};

export const InsuranceModel = () => {
    // Mixture model function with safeguards
    const mixtureModel = useCallback((x, params, aiReduction = 0) => {
        if (x <= 0) return 0;
        const scale = 1 - aiReduction;

        try {
            // First peak (around $5K)
            const peak1 = DISTRIBUTION_PARAMS.PEAK_1_PROPORTION * 
                        (1/(x * DISTRIBUTION_PARAMS.PEAK_1_SPREAD * Math.sqrt(2*Math.PI))) * 
                        Math.exp(-Math.pow(Math.log(x) - Math.log(DISTRIBUTION_PARAMS.PEAK_1_AMOUNT), 2)/
                        (2 * Math.pow(DISTRIBUTION_PARAMS.PEAK_1_SPREAD, 2)));

            // Second peak (around $15K)
            const peak2 = DISTRIBUTION_PARAMS.PEAK_2_PROPORTION * 
                        (1/(x * DISTRIBUTION_PARAMS.PEAK_2_SPREAD * Math.sqrt(2*Math.PI))) * 
                        Math.exp(-Math.pow(Math.log(x) - Math.log(DISTRIBUTION_PARAMS.PEAK_2_AMOUNT), 2)/
                        (2 * Math.pow(DISTRIBUTION_PARAMS.PEAK_2_SPREAD, 2)));

            // Third peak (around $100K)
            const peak3 = DISTRIBUTION_PARAMS.PEAK_3_PROPORTION * 
                        (1/(x * DISTRIBUTION_PARAMS.PEAK_3_SPREAD * Math.sqrt(2*Math.PI))) * 
                        Math.exp(-Math.pow(Math.log(x) - Math.log(DISTRIBUTION_PARAMS.PEAK_3_AMOUNT), 2)/
                        (2 * Math.pow(DISTRIBUTION_PARAMS.PEAK_3_SPREAD, 2)));

            // Heavy tail component
            const tailComponent = x > DISTRIBUTION_PARAMS.TAIL_THRESHOLD ? 
                                DISTRIBUTION_PARAMS.TAIL_PROPORTION * (1/DISTRIBUTION_PARAMS.TAIL_SPREAD) * 
                                Math.pow(1 + (DISTRIBUTION_PARAMS.TAIL_SHAPE*(x-DISTRIBUTION_PARAMS.TAIL_THRESHOLD))/
                                DISTRIBUTION_PARAMS.TAIL_SPREAD, -(1/DISTRIBUTION_PARAMS.TAIL_SHAPE + 1)) : 0;

            const result = (peak1 + peak2 + peak3 + tailComponent) * scale;
            return isNaN(result) ? 0 : result;
        } catch (error) {
            console.error('Error in mixtureModel:', error);
            return 0;
        }
    }, []);

    // Calculate scaling factor with safeguards using new granularity levels
    const calculateScalingFactor = useCallback(() => {
        let totalArea = 0;
        let x = 100; // Start from a small positive number to avoid log(0)

        while (x <= DISTRIBUTION_PARAMS.MAX_X_VALUE) {
            let dx;
            if (x < DISTRIBUTION_PARAMS.GRANULARITY_LEVELS.VERY_FINE.threshold) {
                dx = DISTRIBUTION_PARAMS.GRANULARITY_LEVELS.VERY_FINE.step;
            } else if (x < DISTRIBUTION_PARAMS.GRANULARITY_LEVELS.FINE.threshold) {
                dx = DISTRIBUTION_PARAMS.GRANULARITY_LEVELS.FINE.step;
            } else if (x < DISTRIBUTION_PARAMS.GRANULARITY_LEVELS.MEDIUM.threshold) {
                dx = DISTRIBUTION_PARAMS.GRANULARITY_LEVELS.MEDIUM.step;
            } else {
                dx = DISTRIBUTION_PARAMS.GRANULARITY_LEVELS.COARSE.step;
            }

            const y = mixtureModel(x, null, 0);
            if (!isNaN(y)) {
                totalArea += y * x * dx;
            }
            x += dx;
        }

        const scalingFactor = totalArea > 0 ? DISTRIBUTION_PARAMS.TOTAL_EXPENSE_AMOUNT / totalArea : 1;
        console.log('Scaling factor calculated:', scalingFactor);
        return scalingFactor;
    }, [mixtureModel]);

    // Set scaling factor once
    const [scalingFactor] = useState(calculateScalingFactor());

    // Generate data points
    const generateData = useCallback(() => {
      const data = [];
      let x = DISTRIBUTION_PARAMS.GRANULARITY_LEVELS.VERY_FINE.step;
      
      while (x <= DISTRIBUTION_PARAMS.MAX_X_VALUE) {
          const baselineFreq = mixtureModel(x, null, 0) * scalingFactor;
          
          // Calculate AI impacts for each segment
          const segment1Impact = baselineFreq * (DISTRIBUTION_PARAMS.AI_IMPACTS.SEGMENT_1.REDUCTION);
          const segment2Impact = baselineFreq * (DISTRIBUTION_PARAMS.AI_IMPACTS.SEGMENT_2.REDUCTION);
          const segment3Impact = baselineFreq * (DISTRIBUTION_PARAMS.AI_IMPACTS.SEGMENT_3.REDUCTION);
          const segment4Impact = baselineFreq * (DISTRIBUTION_PARAMS.AI_IMPACTS.SEGMENT_4.REDUCTION);
  
          if (!isNaN(baselineFreq)) {
              data.push({
                  x,
                  baseline: baselineFreq,
                  // For each segment, we only show the impact within its range
                  aiImpact1: x <= DISTRIBUTION_PARAMS.PEAK_1_AMOUNT ? segment1Impact : 0,
                  aiImpact2: (x > DISTRIBUTION_PARAMS.PEAK_1_AMOUNT && x <= DISTRIBUTION_PARAMS.PEAK_2_AMOUNT) ? segment2Impact : 0,
                  aiImpact3: (x > DISTRIBUTION_PARAMS.PEAK_2_AMOUNT && x <= DISTRIBUTION_PARAMS.PEAK_3_AMOUNT) ? segment3Impact : 0,
                  aiImpact4: x > DISTRIBUTION_PARAMS.PEAK_3_AMOUNT ? segment4Impact : 0,
                  // Store the total AI impact for tooltip
                  totalAiImpact: x <= DISTRIBUTION_PARAMS.PEAK_1_AMOUNT ? segment1Impact :
                                x <= DISTRIBUTION_PARAMS.PEAK_2_AMOUNT ? segment2Impact :
                                x <= DISTRIBUTION_PARAMS.PEAK_3_AMOUNT ? segment3Impact :
                                segment4Impact
              });
          }
          
          // Determine step size based on current x value
          let step;
          if (x < DISTRIBUTION_PARAMS.GRANULARITY_LEVELS.VERY_FINE.threshold) {
              step = DISTRIBUTION_PARAMS.GRANULARITY_LEVELS.VERY_FINE.step;
          } else if (x < DISTRIBUTION_PARAMS.GRANULARITY_LEVELS.FINE.threshold) {
              step = DISTRIBUTION_PARAMS.GRANULARITY_LEVELS.FINE.step;
          } else if (x < DISTRIBUTION_PARAMS.GRANULARITY_LEVELS.MEDIUM.threshold) {
              step = DISTRIBUTION_PARAMS.GRANULARITY_LEVELS.MEDIUM.step;
          } else {
              step = DISTRIBUTION_PARAMS.GRANULARITY_LEVELS.COARSE.step;
          }
          x += step;
      }
      
      return data;
    }, [mixtureModel, scalingFactor]);

    const [selectedLOB, setSelectedLOB] = useState('total'); // 'total', 'auto', 'home', 'commercial'

    // Helper function to calculate expenses for specific LOB
    const calculateLOBExpenses = (lob) => {
        if (lob === 'total') {
            return {
                gwp: US.gwp,
                operational: weightedOperational * US.gwp,
                acquisition: weightedAcquisition * US.gwp,
                IT: weightedIT * US.gwp,
                total: totalExpenseAmount
            };
        }

        const lobGWP = US.gwp * US.portfolio_mix[lob];
        return {
            gwp: lobGWP,
            operational: US.expense_ratios.operational[lob] * lobGWP,
            acquisition: US.expense_ratios.acquisition[lob] * lobGWP,
            IT: US.expense_ratios.IT[lob] * lobGWP,
            total: (US.expense_ratios.operational[lob] + 
                    US.expense_ratios.acquisition[lob] + 
                    US.expense_ratios.IT[lob]) * lobGWP
        };
    };

    // Add the calculation helpers inside InsuranceModel
    const calculateDistributionStats = useCallback(() => {
        // Helper function to get weighted average for a specific range
        const getWeightedAverage = (start, end, steps = 1000) => {
            let sum = 0;
            let weightSum = 0;
            const step = (end - start) / steps;
            
            for (let x = start; x <= end; x += step) {
                const y = mixtureModel(x, null, 0);
                sum += x * y;
                weightSum += y;
            }
            
            return sum / weightSum;
        };

        const calculateMean = () => {
            return getWeightedAverage(0, DISTRIBUTION_PARAMS.MAX_X_VALUE);
        };

        const calculateMedian = () => {
            return (DISTRIBUTION_PARAMS.PEAK_1_AMOUNT * DISTRIBUTION_PARAMS.PEAK_1_PROPORTION +
                    DISTRIBUTION_PARAMS.PEAK_2_AMOUNT * DISTRIBUTION_PARAMS.PEAK_2_PROPORTION +
                    DISTRIBUTION_PARAMS.PEAK_3_AMOUNT * DISTRIBUTION_PARAMS.PEAK_3_PROPORTION) /
                    (DISTRIBUTION_PARAMS.PEAK_1_PROPORTION + DISTRIBUTION_PARAMS.PEAK_2_PROPORTION + DISTRIBUTION_PARAMS.PEAK_3_PROPORTION);
        };

        const calculateStandardDev = () => {
            const mean = calculateMean();
            let variance = 0;
            let weightSum = 0;
            const steps = 1000;
            const step = DISTRIBUTION_PARAMS.MAX_X_VALUE / steps;
            
            for (let x = step; x <= DISTRIBUTION_PARAMS.MAX_X_VALUE; x += step) {
                const y = mixtureModel(x, null, 0);
                variance += Math.pow(x - mean, 2) * y;
                weightSum += y;
            }
            
            return Math.sqrt(variance / weightSum);
        };

        const calculatePeakedness = () => {
            const maxHeight1 = mixtureModel(DISTRIBUTION_PARAMS.PEAK_1_AMOUNT, null, 0);
            const maxHeight2 = mixtureModel(DISTRIBUTION_PARAMS.PEAK_2_AMOUNT, null, 0);
            const maxHeight3 = mixtureModel(DISTRIBUTION_PARAMS.PEAK_3_AMOUNT, null, 0);
            return Math.max(maxHeight1, maxHeight2, maxHeight3);
        };

        return {
            mean: calculateMean(),
            median: calculateMedian(),
            standardDev: calculateStandardDev(),
            peakedness: calculatePeakedness(),
            // Placeholder values for these statistics
            chiSquare: 0.95,
            ksTest: 0.92,
            logLikelihood: -1250.34
        };
    }, [mixtureModel]);

    // Calculate stats once
    const [distributionStats] = useState(() => calculateDistributionStats());

    // Define StatisticalParameters component inside InsuranceModel
    const StatisticalParameters = () => {
        return (
            <div className={styles['stats-parameters']}>
                <h3 className={styles['stats-parameters__title']}>Statistical Model Parameters</h3>
                <div className={styles['stats-parameters__grid']}>
                    <div className={styles['stats-card']}>
                        <h4>Basic Statistics</h4>
                        <table>
                            <tbody>
                                <tr>
                                    <td>Mean:</td>
                                    <td>{formatCurrency(distributionStats.mean)}</td>
                                </tr>
                                <tr>
                                    <td>Median:</td>
                                    <td>{formatCurrency(distributionStats.median)}</td>
                                </tr>
                                <tr>
                                    <td>Modes:</td>
                                    <td>
                                        {formatCurrency(DISTRIBUTION_PARAMS.PEAK_1_AMOUNT)}, {' '}
                                        {formatCurrency(DISTRIBUTION_PARAMS.PEAK_2_AMOUNT)}, {' '}
                                        {formatCurrency(DISTRIBUTION_PARAMS.PEAK_3_AMOUNT)}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    
                    <div className={styles['stats-card']}>
                        <h4>Distribution Shape</h4>
                        <table>
                            <tbody>
                                <tr>
                                    <td>Standard Deviation:</td>
                                    <td>{formatCurrency(distributionStats.standardDev)}</td>
                                </tr>
                                <tr>
                                    <td>Peakedness:</td>
                                    <td>{distributionStats.peakedness.toFixed(4)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className={styles['stats-card']}>
                        <h4>Mixture Components</h4>
                        <table>
                            <tbody>
                                <tr>
                                    <td>Component Weights:</td>
                                    <td>
                                        {(DISTRIBUTION_PARAMS.PEAK_1_PROPORTION * 100).toFixed(1)}% : {' '}
                                        {(DISTRIBUTION_PARAMS.PEAK_2_PROPORTION * 100).toFixed(1)}% : {' '}
                                        {(DISTRIBUTION_PARAMS.PEAK_3_PROPORTION * 100).toFixed(1)}%
                                    </td>
                                </tr>
                                <tr>
                                    <td>Tail Weight:</td>
                                    <td>{(DISTRIBUTION_PARAMS.TAIL_PROPORTION * 100).toFixed(1)}%</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    };

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div style={{ background: '#fff', padding: '10px', border: '1px solid #e5e7eb' }}>
                    <p style={{ margin: '0 0 5px', fontWeight: 500 }}>Cost: ${Number(label).toLocaleString()}</p>
                    {payload.map((entry, index) => (
                        <p key={index} style={{ color: entry.color, margin: '3px 0' }}>
                            {entry.name}: {Number(entry.value).toLocaleString(undefined, {
                                maximumFractionDigits: 2
                            })}
                            {entry.dataKey === 'reductionPercentage' ? '%' : ''}
                        </p>
                    ))}
                </div>
            );
        }
        return null;
    };

  return (
    <div className={styles['insurance-model']}>
      <div className={styles['insurance-model__header']}>
        <h2 className={styles['insurance-model__title']}>
            Insurance Operational Expense Distribution
        </h2>
        <div className={styles['insurance-model__controls']}>
          <select 
            value={selectedLOB} 
            onChange={(e) => setSelectedLOB(e.target.value)}
            className={styles['insurance-model__select']}
          >
            <option value="total">Total Portfolio</option>
            <option value="auto">Auto</option>
            <option value="home">Home</option>
            <option value="commercial">Commercial</option>
          </select>
        </div>
      </div>
      {/* Expense Summary Table */}
      <div className={styles['insurance-model__summary']}>
        <table className={styles['summary-table']}>
            <thead>
                <tr>
                    <th>Category</th>
                    <th>Amount</th>
                    <th>Ratio</th>
                    <th>AI Impact</th>
                    <th>Savings</th>
                </tr>
            </thead>
            <tbody>
                {(() => {
                    const expenses = calculateLOBExpenses(selectedLOB);
                    const rows = [
                        {
                            category: 'Operational',
                            amount: expenses.operational,
                            ratio: (expenses.operational / expenses.gwp * 100).toFixed(1) + '%',
                            aiImpact: DISTRIBUTION_PARAMS.AI_IMPACTS.SEGMENT_1.REDUCTION * 100 + '%',
                            savings: formatCurrency(expenses.operational * DISTRIBUTION_PARAMS.AI_IMPACTS.SEGMENT_1.REDUCTION)
                        },
                        {
                            category: 'Acquisition',
                            amount: expenses.acquisition,
                            ratio: (expenses.acquisition / expenses.gwp * 100).toFixed(1) + '%',
                            aiImpact: DISTRIBUTION_PARAMS.AI_IMPACTS.SEGMENT_2.REDUCTION * 100 + '%',
                            savings: formatCurrency(expenses.acquisition * DISTRIBUTION_PARAMS.AI_IMPACTS.SEGMENT_2.REDUCTION)
                        },
                        {
                            category: 'IT',
                            amount: expenses.IT,
                            ratio: (expenses.IT / expenses.gwp * 100).toFixed(1) + '%',
                            aiImpact: DISTRIBUTION_PARAMS.AI_IMPACTS.SEGMENT_3.REDUCTION * 100 + '%',
                            savings: formatCurrency(expenses.IT * DISTRIBUTION_PARAMS.AI_IMPACTS.SEGMENT_3.REDUCTION)
                        }
                    ];
                    return rows.map((row, index) => (
                        <tr key={index}>
                            <td>{row.category}</td>
                            <td>{formatCurrency(row.amount)}</td>
                            <td>{row.ratio}</td>
                            <td>{row.aiImpact}</td>
                            <td>{row.savings}</td>
                        </tr>
                    ));
                })()}
            </tbody>
            <tfoot>
                <tr>
                    <td>Total</td>
                    <td>{formatCurrency(calculateLOBExpenses(selectedLOB).total)}</td>
                    <td>
                        {(calculateLOBExpenses(selectedLOB).total / 
                        calculateLOBExpenses(selectedLOB).gwp * 100).toFixed(1)}%
                    </td>
                    <td>-</td>
                    <td>{formatCurrency(calculateLOBExpenses(selectedLOB).total * 0.8)}</td>
                </tr>
            </tfoot>
        </table>
      </div>          
      <div className={styles['insurance-model__chart']}>
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
              data={generateData()}
              margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
          >
              <CartesianGrid strokeDasharray="3 3" />                
              <ReferenceLine
                  x={DISTRIBUTION_PARAMS.PEAK_1_AMOUNT}
                  yAxisId="left"
                  stroke="var(--color-gray-400)"
                  strokeDasharray="3 3"
                  label={{
                      value: `${formatCurrency(DISTRIBUTION_PARAMS.PEAK_1_AMOUNT)}`,
                      position: 'top',
                      fill: 'var(--text-secondary)',
                      fontSize: 'var(--font-size-xs)'
                  }}
              />
              <ReferenceLine
                  x={DISTRIBUTION_PARAMS.PEAK_2_AMOUNT}
                  yAxisId="left"
                  stroke="var(--color-gray-400)"
                  strokeDasharray="3 3"
                  label={{
                      value: `${formatCurrency(DISTRIBUTION_PARAMS.PEAK_2_AMOUNT)}`,
                      position: 'top',
                      fill: 'var(--text-secondary)',
                      fontSize: 'var(--font-size-xs)'
                  }}
              />
              <ReferenceLine
                  x={DISTRIBUTION_PARAMS.PEAK_3_AMOUNT}
                  yAxisId="left"
                  stroke="var(--color-gray-400)"
                  strokeDasharray="3 3"
                  label={{
                      value: `${formatCurrency(DISTRIBUTION_PARAMS.PEAK_3_AMOUNT)}`,
                      position: 'top',
                      fill: 'var(--text-secondary)',
                      fontSize: 'var(--font-size-xs)'
                  }}
              />
              <XAxis 
                  dataKey="x"
                  scale="log"
                  domain={['auto', 'auto']}
                  tickFormatter={formatCurrency}
                  label={{ 
                      value: 'Cost Amount', 
                      position: 'bottom', 
                      offset: -10 
                  }}
              />
              <YAxis
                  yAxisId="left"
                  label={{ 
                      value: 'Frequency', 
                      angle: -90, 
                      position: 'insideLeft', 
                      offset: -10 
                  }}
              />
              <YAxis
                  yAxisId="right"
                  orientation="right"
                  label={{ 
                      value: 'AI Impact', 
                      angle: 90, 
                      position: 'insideRight', 
                      offset: -10 
                  }}
              />
              <Tooltip content={<CustomTooltip />} />
              <Legend />                
              <Line 
                  yAxisId="left"
                  type="monotone" 
                  dataKey="baseline" 
                  stroke="var(--color-red-310)" 
                  name="OPEX Distribution"
                  dot={false}
                  strokeWidth={2}
              />
              <Area
                  yAxisId="right"
                  type="monotone"
                  dataKey="aiImpact1"
                  fill={DISTRIBUTION_PARAMS.AI_IMPACTS.SEGMENT_1.COLOR}
                  stroke="none"
                  fillOpacity={0.3}
                  name="AI Impact (Segment 1)"
              />
              <Area
                  yAxisId="right"
                  type="monotone"
                  dataKey="aiImpact2"
                  fill={DISTRIBUTION_PARAMS.AI_IMPACTS.SEGMENT_2.COLOR}
                  stroke="none"
                  fillOpacity={0.3}
                  name="AI Impact (Segment 2)"
              />
              <Area
                  yAxisId="right"
                  type="monotone"
                  dataKey="aiImpact3"
                  fill={DISTRIBUTION_PARAMS.AI_IMPACTS.SEGMENT_3.COLOR}
                  stroke="none"
                  fillOpacity={0.3}
                  name="AI Impact (Segment 3)"
              />
              <Area
                  yAxisId="right"
                  type="monotone"
                  dataKey="aiImpact4"
                  fill={DISTRIBUTION_PARAMS.AI_IMPACTS.SEGMENT_4.COLOR}
                  stroke="none"
                  fillOpacity={0.3}
                  name="AI Impact (Segment 4)"
              />
          </ComposedChart>
        </ResponsiveContainer>
      </div>

      {/* Add ModelMethodology component here */}
      <ModelMethodology />

      {/* Add Waterfall Chart */}
      <WaterfallChart />

      {/* Add Statistical Parameters */}
      <StatisticalParameters />

      <div className={styles['insurance-model__stats']}>
          <div className={styles['stats-card']}>
              <h3 className={styles['stats-card__title']}>Market Statistics</h3>
              <p>Total GWP: ${US.gwp.toLocaleString()}</p>
              <p>Total Expense Ratio: {(totalExpenseRatio * 100).toFixed(1)}%</p>
              <p>Total Expenses: ${totalExpenseAmount.toLocaleString()}</p>
              <p>Scaling Factor: {scalingFactor.toExponential(2)}</p>
          </div>              
          <div className={styles['stats-card']}>
              <h3 className={styles['stats-card__title']}>Distribution Peaks</h3>
              <p>First Peak: ${DISTRIBUTION_PARAMS.PEAK_1_AMOUNT.toLocaleString()}</p>
              <p>Second Peak: ${DISTRIBUTION_PARAMS.PEAK_2_AMOUNT.toLocaleString()}</p>
              <p>Third Peak: ${DISTRIBUTION_PARAMS.PEAK_3_AMOUNT.toLocaleString()}</p>
              <p>AI Impact: {(DISTRIBUTION_PARAMS.AI_COST_REDUCTION * 100).toFixed(1)}%</p>
          </div>
      </div>
    </div>
  );
};

export default InsuranceModel;