// File: frontend/src/pages/Quote/Quote.js

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { useLanguage } from '../../contexts/LanguageContext';
import { quoteService } from '../../services/quote';
import { v4 as uuidv4 } from 'uuid';
import safeStorage from '../../utils/storage';
import Hero from './components/Hero/Hero';
import ChatInterface from './components/ChatInterface/ChatInterface';
import SavingsCalculatorSection from './components/SavingsCalculator/SavingsCalculatorSection';
import LoadingContainer from '../../components/LoadingSpinner/LoadingContainer';
import styles from './Quote.module.css';

const Quote = () => {
  // ----------- State Management -----------
  const { t, language } = useLanguage();
  const [showChat, setShowChat] = useState(false);
  const [quoteData, setQuoteData] = useState(null);
  const [mounted, setMounted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [lockReason, setLockReason] = useState('');
  const [isInitializing, setIsInitializing] = useState(false);
  
  // ----------- Hooks -----------
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const isInitialMount = useRef(true);
  const observerRef = useRef(null);
  const scrollYRef = useRef(0);

  // ----------- Data Fetching Functions -----------
  
  // Function to fetch quote data - memoized with useCallback
  const fetchQuoteData = useCallback(async (quoteId) => {
    if (!quoteId) return;
    
    setIsLoading(true);
    setError(null);
    setLockReason('Loading your quote data...');
    
    try {
      const data = await quoteService.getQuote(quoteId);
      setQuoteData(data);
      
      // Automatically open chat when quote data is loaded
      setShowChat(true);
      
      // Store in session storage
      try {
        sessionStorage.setItem('quoteData', JSON.stringify(data));
        
        // Store quote ID and chat_id in localStorage
        safeStorage.setItem('quote_id', quoteId);
        if (data.chat_id) {
          safeStorage.setItem('chat_id', data.chat_id);
        }
      } catch (storageError) {
        console.warn('Storage access failed:', storageError);
      }
      
      console.log('Quote data fetched successfully');
    } catch (error) {
      console.error('Error fetching quote data:', error);
      
      // Check if it's a 404 (quote not found)
      if (error.response && error.response.status === 404) {
        console.log('Quote not found. Creating a new quote...');
        setLockReason('Creating a new quote...');
        
        // Generate a new quote ID since the requested one doesn't exist
        const newQuoteId = uuidv4();
        safeStorage.setItem('quote_id', newQuoteId);
        
        // Get the selected insurance type from session storage
        const selectedInsuranceType = sessionStorage.getItem('selectedInsuranceType');
        const apiProductType = 'auto'; // Default for API compatibility
        
        // Initiate a new quote with the new ID
        try {
          const response = await quoteService.initiateQuote({
            quote_id: newQuoteId,
            product_type: apiProductType, // Use default for API
            source: 'web',
            primary_language: language || 'en-ca',
            referrer: document.referrer,
            landing_page: window.location.href
          });
          
          setQuoteData(prev => ({
            ...(prev || {}),
            ...response,
            quote_id: newQuoteId,
            ...(selectedInsuranceType ? { insuranceType: selectedInsuranceType } : {})
          }));
          
          setShowChat(true);
          
          // Update URL without page reload
          navigate(`/quote/${newQuoteId}`, { replace: true });
          
          console.log('New quote initiated successfully');
        } catch (initError) {
          console.error('Error creating new quote:', initError);
          setError('Failed to create a new quote. Please try again.');
          setLockReason('');
        }
      } else {
        setError('Failed to load quote data. Please try again.');
        setLockReason('');
      }
    } finally {
      setIsLoading(false);
    }
  }, [language, navigate]);

  // ----------- Event Handlers -----------
  
  // Handle starting a new quote - memoized with useCallback
  const handleStartQuote = useCallback(async (insuranceType, providedQuoteId = null) => {
    // Set initializing state to show loading UI
    setIsInitializing(true);
    
    // Store the selected insurance type in session storage only if it's provided
    if (insuranceType) {
      try {
        sessionStorage.setItem('selectedInsuranceType', insuranceType);
      } catch (error) {
        console.warn('sessionStorage access failed:', error);
      }
    }
    
    // Check for existing quote ID first
    let quoteId = providedQuoteId || safeStorage.getItem('quote_id');
    
    // Only generate a new ID if one doesn't exist or it's a placeholder
    if (!quoteId || quoteId.startsWith('no-quote')) {
      quoteId = uuidv4();
      safeStorage.setItem('quote_id', quoteId);
      console.log('Generated new quote ID for actual quote:', quoteId);
    } else {
      console.log('Using existing quote ID:', quoteId);
    }
    
    try {
      // First, try to fetch the existing quote data
      try {
        console.log('Attempting to fetch existing quote data for ID:', quoteId);
        const existingQuoteData = await quoteService.getQuote(quoteId);
        
        // If we successfully get the quote data, update state and show chat
        setQuoteData(prev => ({
          ...(prev || {}),
          ...existingQuoteData,
          quote_id: quoteId,
          // Only update insuranceType if provided
          ...(insuranceType ? { insuranceType } : {})
        }));
        
        console.log('Successfully fetched existing quote data');
        
        // Show chat and update URL
        setShowChat(true);
        navigate(`/quote/${quoteId}`);
        setIsInitializing(false);
        return; // Exit early since we've handled the existing quote
      } catch (fetchError) {
        // If the quote doesn't exist (404) or there's another error fetching it,
        // continue with creating a new quote
        console.log('Could not fetch existing quote, will create new one:', fetchError);
      }
      
      // If we get here, we need to create a new quote
      console.log('Initiating new quote with ID:', quoteId);
      
      // IMPORTANT: Initiate the quote in the backend
      const response = await quoteService.initiateQuote({
        quote_id: quoteId,
        product_type: 'auto', // Always use default for API
        source: 'web',
        primary_language: language || 'en-ca',
        referrer: document.referrer,
        landing_page: window.location.href
      });
      
      // Update quote data with the response, including chat_id
      setQuoteData(prev => ({
        ...(prev || {}),
        ...response,
        quote_id: quoteId,
        // Don't set insuranceType in the state if not provided
        ...(insuranceType ? { insuranceType } : {})
      }));
      
      console.log('Quote initiated successfully');
      
      // Show the chat and update the URL
      setShowChat(true);
      navigate(`/quote/${quoteId}`);
    } catch (error) {
      console.error('Error initiating quote:', error);
      
      // Check if this is a 409 conflict (quote already exists)
      if (error.response && error.response.status === 409) {
        console.log('Quote already exists, fetching existing data');
        setLockReason('Loading existing quote...');
        
        try {
          // Try to fetch the existing quote data
          const data = await quoteService.getQuote(quoteId);
          setQuoteData(data);
          
          // Show the chat and update URL
          setShowChat(true);
          navigate(`/quote/${quoteId}`);
          
          console.log('Existing quote data fetched successfully');
        } catch (fetchError) {
          console.error('Error fetching existing quote:', fetchError);
          setError('Failed to load existing quote. Please try again.');
          setLockReason('');
        }
      } else {
        setError('Failed to start quote. Please try again.');
        setLockReason('');
      }
    } finally {
      setIsInitializing(false);
      setIsLoading(false);
    }
    
    // Track this event with analytics if available
    if (window.analytics) {
      window.analytics.track('Start Quote', {
        page: 'Quote Page',
        component: 'Hero CTA',
        insuranceType: insuranceType || 'auto',
        quoteId: quoteId
      });
    }
  }, [language, navigate]);
  
  // Handle closing the chat - memoized with useCallback
  const handleCloseChat = useCallback(() => {
    setShowChat(false);
    
    // Remove quote ID from URL when closing chat
    // FIXED: Use navigate to avoid browser history issues
    navigate('/quote', { replace: true });
    
    // Clear any error messages
    setError(null);
    setLockReason('');
    
    // Track this event with analytics if available
    if (window.analytics) {
      window.analytics.track('Close Chat', {
        page: 'Quote Page',
        hasQuoteData: !!quoteData
      });
    }
    
    // Restore scroll position after small delay to avoid jank
    setTimeout(() => {
      window.scrollTo({
        top: scrollYRef.current,
        behavior: 'auto'
      });
    }, 10);
  }, [navigate, quoteData]);

  // Handle quote completion
  const handleQuoteComplete = useCallback((data) => {
    setQuoteData(data);
    
    // Close the chat interface after completing the quote
    setShowChat(false);
    
    // Redirect to next step or show success modal
    console.log('Quote completed with data');
    
    // Track this event with analytics if available
    if (window.analytics) {
      window.analytics.track('Quote Completed', {
        insuranceType: data.insuranceType,
        currentPremium: data.currentPremium,
        estimatedSavings: data.estimatedSavings
      });
    }
  }, []);

  // ----------- Effects -----------

  // Effect for fetching quote data when quoteId in URL changes
  useEffect(() => {
    const quoteId = params.quoteId;
    
    if (quoteId) {
      // If we have a quote ID in the URL, fetch the quote data
      fetchQuoteData(quoteId);
    } else {
      // Clear any loading state when no quote ID is present
      setIsLoading(false);
      setError(null);
      setLockReason('');
    }
  }, [params.quoteId, fetchQuoteData]);

  // Effect for loading saved quote data on initial mount
  useEffect(() => {
    if (isInitialMount.current) {
      const savedQuoteData = sessionStorage.getItem('quoteData');
      if (savedQuoteData) {
        try {
          setQuoteData(JSON.parse(savedQuoteData));
        } catch (error) {
          console.error('Error parsing saved quote data:', error);
        }
      }
      isInitialMount.current = false;
    }
  }, []);

  // Effect for saving quote data to session storage
  useEffect(() => {
    if (quoteData) {
      try {
        sessionStorage.setItem('quoteData', JSON.stringify(quoteData));
      } catch (error) {
        console.warn('Session storage error:', error);
      }
    }
  }, [quoteData]);

  // Effect for handling initial mount and hash in URL
  useEffect(() => {
    setMounted(true);
    
    // Handle initial hash only once on mount
    if (location.hash.includes('#chatSection')) {
      setShowChat(true);
      
      const urlParams = new URLSearchParams(location.hash.split('?')[1] || '');
      const typeParam = urlParams.get('type');
      
      if (typeParam) {
        setQuoteData(prevData => ({
          ...(prevData || {}),
          insuranceType: typeParam
        }));
        
        try {
          const savedData = sessionStorage.getItem('quoteData');
          const parsedData = savedData ? JSON.parse(savedData) : {};
          sessionStorage.setItem('quoteData', JSON.stringify({
            ...parsedData,
            insuranceType: typeParam
          }));
        } catch (error) {
          console.error('Error updating session storage:', error);
        }
      }
    }
    
    // Clean up function
    return () => {
      // Clean up any resources or event listeners here
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [location.hash]);

  // Optimized scroll performance effect using IntersectionObserver
  useEffect(() => {
    if (!mounted) return;
    
    // Create a timeout to delay setting up the observer
    // This allows critical content to render first
    const observerTimer = setTimeout(() => {
      // Disconnect previous observer if it exists
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
      
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            // Only add the visible class when the element comes into view
            if (entry.isIntersecting) {
              // Add animation class
              entry.target.classList.add(styles.visible);
              
              // Unobserve element after it becomes visible
              // to reduce unnecessary processing
              observer.unobserve(entry.target);
            }
          });
        },
        { 
          threshold: 0.1, // Trigger when at least 10% of the element is visible
          rootMargin: '20px 0px' // Add a small margin to trigger earlier
        }
      );
      
      // Only observe elements that aren't already visible
      const sections = document.querySelectorAll(
        `.${styles.featuresSection}:not(.${styles.visible}), ` +
        `.${styles.testimonialsSection}:not(.${styles.visible})`
      );
      
      sections.forEach((section) => {
        observer.observe(section);
      });
      
      // Store observer reference for cleanup
      observerRef.current = observer;
    }, 100); // Short delay to prioritize critical rendering
    
    return () => {
      clearTimeout(observerTimer);
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [mounted]);

  // Optimized scroll event handling
  useEffect(() => {
    if (!mounted) return;
    
    let rafId;
    let lastScrollY = window.scrollY;
    let ticking = false;
    
    const handleScroll = () => {
      // Store current scroll position for restoration when needed
      scrollYRef.current = window.scrollY;
      
      // Only process scroll events when not already processing one
      if (!ticking) {
        rafId = window.requestAnimationFrame(() => {
          // Only do work if we've scrolled significantly
          if (Math.abs(window.scrollY - lastScrollY) > 5) {
            lastScrollY = window.scrollY;
            // Any scroll-dependent UI updates would go here
            // But keep them minimal
          }
          ticking = false;
        });
        ticking = true;
      }
    };
    
    // Add event listener with passive option for better performance
    window.addEventListener('scroll', handleScroll, { passive: true });
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (rafId) {
        window.cancelAnimationFrame(rafId);
      }
    };
  }, [mounted]);

  // Improved body overflow handling when chat opens/closes
  useEffect(() => {
    if (!mounted) return;
    
    const htmlEl = document.documentElement;
    const bodyEl = document.body;
    
    if (showChat) {
      // Save current scroll position before locking scroll
      scrollYRef.current = window.scrollY;
      
      // Store original styles for restoration
      const originalOverflow = bodyEl.style.overflow;
      const originalPosition = bodyEl.style.position;
      const originalTop = bodyEl.style.top;
      const originalScrollBehavior = htmlEl.style.scrollBehavior;
      
      // Prevent jarring scroll behavior during this transition
      htmlEl.style.scrollBehavior = 'auto';
      
      // Set body position fixed to lock scroll at current position
      bodyEl.style.overflow = 'hidden';
      bodyEl.style.position = 'fixed';
      bodyEl.style.top = `-${scrollYRef.current}px`;
      bodyEl.style.width = '100%';
      
      // Add a class for additional styling
      bodyEl.classList.add('chat-open');
      
      // Cleanup function to restore original state
      return () => {
        // Remove the chat-open class
        bodyEl.classList.remove('chat-open');
        
        // Restore original styles
        bodyEl.style.overflow = originalOverflow;
        bodyEl.style.position = originalPosition;
        bodyEl.style.top = originalTop;
        bodyEl.style.width = '';
        htmlEl.style.scrollBehavior = originalScrollBehavior;
        
        // Restore scroll position after releasing fixed position
        window.scrollTo(0, scrollYRef.current);
      };
    }
  }, [showChat, mounted]);

  // Effect for component unmount cleanup
  useEffect(() => {
    return () => {
      // Final cleanup when component unmounts
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
      
      // Ensure body styles are properly reset
      const bodyEl = document.body;
      bodyEl.style.overflow = '';
      bodyEl.style.position = '';
      bodyEl.style.top = '';
      bodyEl.style.width = '';
      bodyEl.classList.remove('chat-open');
      
      document.documentElement.style.scrollBehavior = '';
    };
  }, []);

  // ----------- Render Functions -----------
  
  // Function to render feature cards
  const renderFeatureCards = useCallback(() => {
    const features = [
      {
        icon: '💰',
        titleKey: 'quote_page.lower_premiums_title',
        descriptionKey: 'quote_page.lower_premiums_description'
      },
      {
        icon: '⚡',
        titleKey: 'quote_page.instant_quotes_title',
        descriptionKey: 'quote_page.instant_quotes_description'
      },
      {
        icon: '📱',
        titleKey: 'quote_page.smart_savings_title',
        descriptionKey: 'quote_page.smart_savings_description'
      }
    ];

    return features.map((feature, index) => (
      <div className={styles.featureCard} key={index}>
        <div className={styles.featureIcon}>{feature.icon}</div>
        <h3>{t(feature.titleKey)}</h3>
        <p>{t(feature.descriptionKey)}</p>
      </div>
    ));
  }, [t]);

  // Function to render testimonials
  const renderTestimonials = useCallback(() => {
    const testimonials = [
      {
        quoteKey: 'quote_page.testimonial1_quote',
        authorKey: 'quote_page.testimonial1_author'
      },
      {
        quoteKey: 'quote_page.testimonial2_quote',
        authorKey: 'quote_page.testimonial2_author'
      },
      {
        quoteKey: 'quote_page.testimonial3_quote',
        authorKey: 'quote_page.testimonial3_author'
      }
    ];

    return testimonials.map((testimonial, index) => (
      <div className={styles.testimonial} key={index}>
        <div className={styles.quote}>{t(testimonial.quoteKey)}</div>
        <div className={styles.author}>{t(testimonial.authorKey)}</div>
      </div>
    ));
  }, [t]);

  // ----------- Component Return -----------
  return (
    <>
      <Helmet>
        <title>{t('quote_page.title')}</title>
        <meta name="description" content={t('quote_page.meta_description')} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0" />
      </Helmet>
      
      <div className={styles.quotePage}>
        {isInitializing && (
          <LoadingContainer 
            fullScreen 
            spinnerSize="large" 
            message={t('Starting your quote...')} 
          />
        )}
        
        <Hero onStartQuote={handleStartQuote} />
        
        <SavingsCalculatorSection onGetQuote={handleStartQuote} />
        
        <section 
          className={styles.featuresSection} 
          aria-label={t('quote_page.features_title')}
        >
          <div className={styles.sectionHeader}>
            <h2>{t('quote_page.features_title')}</h2>
            <p>{t('quote_page.features_description')}</p>
          </div>
          <div className={styles.featureCards}>
            {renderFeatureCards()}
          </div>
        </section>
        
        {/* Testimonials section - commented out in original code */}
        {/* <section 
          className={styles.testimonialsSection}
          aria-label={t('quote_page.testimonials_title')}
        >
          <div className={styles.sectionHeader}>
            <h2>{t('quote_page.testimonials_title')}</h2>
            <p>{t('quote_page.testimonials_description')}</p>
          </div>
          
          <div className={styles.testimonials}>
            {renderTestimonials()}
          </div>
        </section> */}
        
        {/* Fullscreen Chat Interface */}
        {showChat && (
          <ChatInterface 
            isOpen={true}
            onClose={handleCloseChat}
            onQuoteComplete={handleQuoteComplete}
            quoteId={params.quoteId || safeStorage.getItem('quote_id')}
            chatId={quoteData?.chat_id || safeStorage.getItem('chat_id')}
            quoteData={quoteData}
            isLoading={isLoading}
            isLocked={isLoading}
            lockReason={lockReason}
          />
        )}
      </div>
    </>
  );
};

export default Quote;