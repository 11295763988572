import React from 'react';
import { motion } from 'framer-motion';
import { ANIMATION_VARIANTS } from '../../../../constants/animations';
import styles from './MethodologySlide.module.css';

const MethodologySlide = () => {
  const benefits = [
    {
      title: "Precise Identification",
      description: "of automation opportunities across operational workflows",
      color: "var(--color-blue-310)",
      icon: "🎯"
    },
    {
      title: "Accurate Assessment",
      description: "of AI impact potential on each workflow component",
      color: "var(--color-green-310)",
      icon: "📊"
    },
    {
      title: "Granular Understanding",
      description: "of operational complexity and cost drivers",
      color: "var(--color-orange-310)",
      icon: "🔍"
    },
    {
      title: "Strategic Prioritization",
      description: "of transformation initiatives based on impact",
      color: "var(--color-primary)",
      icon: "⭐"
    }
  ];

  return (
    <motion.div
      className={styles.container}
      variants={ANIMATION_VARIANTS.stagger.container}
      initial="hidden"
      animate="visible"
    >
      <motion.div 
        className={styles.header}
        variants={ANIMATION_VARIANTS.fadeIn}
      >
        <h1 className={styles.title}>Our Novel Expense Modelling Methodology</h1>
        <p className={styles.subtitle}>A Workflow-Centric Approach to Insurance Operations to Achieve Complete Autonomy</p>
      </motion.div>

      <motion.div 
        className={styles.content}
        variants={ANIMATION_VARIANTS.stagger.container}
      >
        {/* <motion.div 
          className={styles.methodologyBox}
          variants={ANIMATION_VARIANTS.fadeIn}
          whileHover={{ scale: 1.02 }}
        >
          <div className={styles.boxContent}>
            <h2 className={styles.boxTitle}>Novel Approach</h2>
            <p>Revolutionizing traditional expense analysis by focusing on operational workflows rather than static cost allocations</p>
          </div>
          <div className={styles.workflowIcon}>🔄</div>
        </motion.div> */}

        <motion.div 
          className={styles.benefitsGrid}
          variants={ANIMATION_VARIANTS.stagger.container}
        >
          {benefits.map((benefit, index) => (
            <motion.div
              key={benefit.title}
              className={styles.benefitCard}
              variants={ANIMATION_VARIANTS.stagger.item}
              custom={index}
              whileHover={{ scale: 1.02 }}
            >
              <span className={styles.benefitIcon} style={{ color: benefit.color }}>
                {benefit.icon}
              </span>
              <div>
                <h3 className={styles.benefitTitle} style={{ color: benefit.color }}>
                  {benefit.title}
                </h3>
                <p className={styles.benefitDescription}>{benefit.description}</p>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default MethodologySlide;