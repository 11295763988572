// File: frontend/src/components/routing/AuthRoute.js
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContextV2';
import LoadingContainer from '../LoadingSpinner/LoadingContainer';

const AuthRoute = ({ children }) => {
  const { isAuthenticated, loading, user } = useAuth();
  const location = useLocation();

  // I will review this function
  // useEffect(() => {
  //   // Clear any sensitive data from localStorage/sessionStorage
  //   if (!isAuthenticated) {
  //     sessionStorage.removeItem('tempAuthData');
  //   }
  // }, [isAuthenticated]);

  if (loading) {
    return <LoadingContainer transparent spinnerSize="large" message="Loading..." />;
  }

  // Special case for sign-in-success
  if (location.pathname === '/sign-in-success') {
    return children;
  }

  if (isAuthenticated && user) {
    return <Navigate to={location.state?.from || "/ai1"} replace />;
  }

  return children;
};

export default AuthRoute;

