// File: frontend/src/pages/AutoInsight/components/VehicleDistributionChart/index.js

import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import styles from './VehicleDistributionChart.module.css';

const VehicleDistributionChart = () => {
  const { t } = useLanguage();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [distributionData, setDistributionData] = useState([]);
  const [displayMode, setDisplayMode] = useState('absolute'); // 'absolute' or 'percentage'

  useEffect(() => {
    const fetchDistributionData = async () => {
      setLoading(true);
      setError(null);
      
      try {
        // This would be replaced with an actual API call
        // const response = await vehicleService.getVehicleDistribution();
        
        // Mock data for now
        await new Promise(resolve => setTimeout(resolve, 800));
        
        // Generate mock data for the last 10 years
        const currentYear = new Date().getFullYear();
        const mockData = [];
        let totalVehicles = 0;
        
        for (let i = 0; i < 10; i++) {
          const year = currentYear - i;
          const count = Math.floor(Math.random() * 200) + 50; // Random count between 50 and 250
          mockData.push({ year, count });
          totalVehicles += count;
        }
        
        // Sort by year ascending
        mockData.sort((a, b) => a.year - b.year);
        
        // Add percentage to each data point
        mockData.forEach(item => {
          item.percentage = (item.count / totalVehicles) * 100;
        });
        
        setDistributionData(mockData);
      } catch (err) {
        console.error('Error fetching vehicle distribution data:', err);
        setError(t('failed_to_load_distribution_data'));
      } finally {
        setLoading(false);
      }
    };
    
    fetchDistributionData();
  }, [t]);

  // Find the maximum value for scaling the bars
  const maxValue = displayMode === 'absolute'
    ? Math.max(...distributionData.map(item => item.count))
    : Math.max(...distributionData.map(item => item.percentage));

  const toggleDisplayMode = () => {
    setDisplayMode(displayMode === 'absolute' ? 'percentage' : 'absolute');
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <div className={styles.loadingSpinner}></div>
        <p>{t('loading_distribution_data')}</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.error}>
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3 className={styles.title}>{t('vehicle_distribution_by_year')}</h3>
        <div className={styles.displayToggle}>
          <button 
            className={`${styles.toggleButton} ${displayMode === 'absolute' ? styles.activeToggle : ''}`}
            onClick={() => setDisplayMode('absolute')}
          >
            {t('absolute')}
          </button>
          <button 
            className={`${styles.toggleButton} ${displayMode === 'percentage' ? styles.activeToggle : ''}`}
            onClick={() => setDisplayMode('percentage')}
          >
            {t('percentage')}
          </button>
        </div>
      </div>
      
      <div className={styles.chartContainer}>
        <div className={styles.chart}>
          {distributionData.map((item) => {
            const value = displayMode === 'absolute' ? item.count : item.percentage;
            const height = (value / maxValue) * 100;
            
            return (
              <div key={item.year} className={styles.barContainer}>
                <div className={styles.barLabel}>
                  {displayMode === 'absolute' 
                    ? item.count 
                    : `${item.percentage.toFixed(1)}%`}
                </div>
                <div 
                  className={styles.bar} 
                  style={{ height: `${height}%` }}
                  title={`${item.year}: ${displayMode === 'absolute' 
                    ? `${item.count} ${t('vehicles')}` 
                    : `${item.percentage.toFixed(1)}%`}`}
                ></div>
                <div className={styles.yearLabel}>{item.year}</div>
              </div>
            );
          })}
        </div>
        
        <div className={styles.yAxis}>
          <div className={styles.yAxisLabel}>
            {displayMode === 'absolute' ? t('number_of_vehicles') : t('percentage')}
          </div>
        </div>
      </div>
      
      <div className={styles.totalVehicles}>
        {t('total_vehicles')}: {distributionData.reduce((sum, item) => sum + item.count, 0)}
      </div>
    </div>
  );
};

export default VehicleDistributionChart;