import React from 'react';
import { motion } from 'framer-motion';
import { useLanguage } from '../../../../../../contexts/LanguageContext';
import { ANIMATION_VARIANTS } from '../../../../constants/animations';
import styles from './VisionSlide.module.css';

const VisionSlide = () => {
  const { t } = useLanguage();

  const visionGoals = [
    {
      id: 1,
      title: "Reducing coverage gap with affordable insurance",
      color: "var(--color-blue-310)"
    },
    {
      id: 2,
      title: "Transparent and accountable Super Intelligence",
      color: "var(--color-green-310)"
    },
    {
      id: 3,
      title: "Shifting focus to prevention of emerging risks",
      color: "var(--color-orange-310)"
    }
  ];

  return (
    <motion.div
      className={styles.container}
      variants={ANIMATION_VARIANTS.stagger.container}
      initial="hidden"
      animate="visible"
    >
      <motion.div 
        className={styles.header}
        variants={ANIMATION_VARIANTS.fadeIn}
      >
        <h1 className={styles.title}>The Vision</h1>
      </motion.div>

      <motion.div 
        className={styles.goalsContainer}
        variants={ANIMATION_VARIANTS.stagger.container}
      >
        {visionGoals.map((goal, index) => (
          <motion.div
            key={goal.id}
            className={styles.goalItem}
            variants={ANIMATION_VARIANTS.stagger.item}
            custom={index}
            whileHover={{ scale: 1.02 }}
          >
            <div 
              className={styles.goalIcon}
              style={{ color: goal.color }}
            >
              {index === 0 ? '🎯' : index === 1 ? '🔍' : '🛡️'}
            </div>
            <h2 className={styles.goalTitle}>{goal.title}</h2>
            <div 
              className={styles.goalBackground}
              style={{ backgroundColor: goal.color }}
            />
          </motion.div>
        ))}
      </motion.div>

      <motion.div
        className={styles.footer}
        variants={ANIMATION_VARIANTS.fadeIn}
      >
        <p className={styles.footerText}>
          These aren't just aspirational goals – we've achieved them through a ground-up reimagining of insurance operations.
        </p>
      </motion.div>
    </motion.div>
  );
};

export default VisionSlide;