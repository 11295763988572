// File: frontend/src/hooks/useContact.js

import { useState } from 'react';
import { API_ENDPOINTS } from '../config/constants';
import apiService from '../services/api';
import { useLanguage } from '../contexts/LanguageContext';
import { SUPPORTED_LOCALES } from '../config/i18n.config';

export const CONTACT_ERROR_CODES = {
  INVALID_EMAIL: 'invalid_email',
  MISSING_REQUIRED_FIELDS: 'missing_required_fields',
  MESSAGE_TOO_LONG: 'message_too_long',
  SUBMISSION_FAILED: 'submission_failed'
};

export const useContact = () => {
  const { t, language } = useLanguage();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      throw new Error('INVALID_EMAIL');
    }
    return true;
  };

  const submit = async (formData) => {
    setIsLoading(true);
    setError(null);
    setSuccess(false);

    try {
      // Validate fields
      if (!formData.firstName || !formData.lastName || !formData.email || !formData.message) {
        throw new Error('MISSING_REQUIRED_FIELDS');
      }

      validateEmail(formData.email);

      if (formData.message.length > 500) {
        throw new Error('MESSAGE_TOO_LONG');
      }

      const payload = {
        firstName: formData.firstName.trim(),
        lastName: formData.lastName.trim(),
        email: formData.email.trim(),
        message: formData.message.trim(),
        primary_language_id: SUPPORTED_LOCALES[language].categoryValueId
      };

      await apiService.post(
        API_ENDPOINTS.ENGAGEMENT.CONTACT_SUBMIT,
        payload
      );

      setSuccess(true);
      return true;
    } catch (err) {
      let errorCode;

      switch (err.message) {
        case 'INVALID_EMAIL':
          errorCode = CONTACT_ERROR_CODES.INVALID_EMAIL;
          break;
        case 'MISSING_REQUIRED_FIELDS':
          errorCode = CONTACT_ERROR_CODES.MISSING_REQUIRED_FIELDS;
          break;
        case 'MESSAGE_TOO_LONG':
          errorCode = CONTACT_ERROR_CODES.MESSAGE_TOO_LONG;
          break;
        default:
          errorCode = CONTACT_ERROR_CODES.SUBMISSION_FAILED;
      }

      setError(errorCode);
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    submit,
    isLoading,
    error,
    success,
    clearError: () => setError(null),
    clearSuccess: () => setSuccess(false)
  };
};