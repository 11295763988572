// File: frontend/src/pages/SuperAdmin/components/ReportManagement/ReportDetail/index.js

import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from './ReportDetail.module.css';

const ReportDetail = ({ reportId, onBack, onEdit, isEditMode = false, onSuccess = null }) => {
  const { t } = useLanguage();
  const [report, setReport] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('overview');
  
  // Form state for edit mode
  const [formData, setFormData] = useState({
    report_name: '',
    report_description: '',
    report_type: '',
    report_format: '',
    report_status: '',
    scheduled: false,
    schedule_frequency: ''
  });
  
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    const fetchReportDetails = async () => {
      setLoading(true);
      setError(null);
      
      try {
        // This would be replaced with an actual API call
        // const response = await reportService.getReportDetails(reportId);
        
        // Mock data for now
        await new Promise(resolve => setTimeout(resolve, 800));
        
        const mockReport = {
          report_id: reportId,
          report_name: 'Monthly User Activity',
          report_description: 'Monthly analysis of user engagement and activity metrics. Includes active users, session duration, feature usage, and conversion rates.',
          report_type: 'analytics',
          report_format: 'pdf',
          report_url: 'https://example.com/reports/monthly-user-activity.pdf',
          report_status: 'completed',
          enterprise_id: 'ent-12345',
          enterprise_name: 'Acme Corporation',
          scheduled: true,
          schedule_frequency: 'monthly',
          last_run_date: '2023-06-01T10:30:00Z',
          next_run_date: '2023-07-01T10:30:00Z',
          created_at: '2023-01-15T10:30:00Z',
          updated_at: '2023-06-01T14:45:00Z',
          created_by: 'admin-user-1',
          updated_by: 'admin-user-2',
          
          // Additional metadata
          report_data: {
            metrics: ['active_users', 'session_duration', 'feature_usage', 'conversion_rate'],
            timeframe: 'last_30_days',
            filters: {
              user_segments: ['new_users', 'returning_users'],
              regions: ['north_america', 'europe', 'asia']
            }
          },
          
          // Report parameters
          parameters: {
            start_date: '2023-05-01',
            end_date: '2023-05-31',
            include_charts: true,
            include_raw_data: false,
            chart_types: ['line', 'bar', 'pie']
          },
          
          // Execution history
          execution_history: [
            {
              execution_id: 'exec-12345',
              status: 'completed',
              start_time: '2023-06-01T10:30:00Z',
              end_time: '2023-06-01T10:32:15Z',
              duration_seconds: 135,
              error: null,
              triggered_by: 'schedule',
              download_url: 'https://example.com/reports/monthly-user-activity-202306.pdf'
            },
            {
              execution_id: 'exec-12344',
              status: 'completed',
              start_time: '2023-05-01T10:30:00Z',
              end_time: '2023-05-01T10:31:45Z',
              duration_seconds: 105,
              error: null,
              triggered_by: 'schedule',
              download_url: 'https://example.com/reports/monthly-user-activity-202305.pdf'
            },
            {
              execution_id: 'exec-12343',
              status: 'failed',
              start_time: '2023-04-01T10:30:00Z',
              end_time: '2023-04-01T10:30:30Z',
              duration_seconds: 30,
              error: 'Data source connection timeout',
              triggered_by: 'schedule',
              download_url: null
            }
          ],
          
          // Access information
          access: {
            access_control: 'role-based',
            authorized_roles: ['admin', 'analyst', 'manager'],
            shared_with: [
              {
                user_id: 'user-23456',
                name: 'Jane Doe',
                email: 'jane.doe@acme.com',
                shared_at: '2023-01-20T14:30:00Z',
                shared_by: 'admin-user-1'
              },
              {
                user_id: 'user-34567',
                name: 'Bob Johnson',
                email: 'bob.johnson@acme.com',
                shared_at: '2023-02-05T09:15:00Z',
                shared_by: 'admin-user-1'
              }
            ]
          }
        };
        
        setReport(mockReport);
        
        // Initialize form data for editing
        if (isEditMode) {
          setFormData({
            report_name: mockReport.report_name,
            report_description: mockReport.report_description,
            report_type: mockReport.report_type,
            report_format: mockReport.report_format,
            report_status: mockReport.report_status,
            scheduled: mockReport.scheduled,
            schedule_frequency: mockReport.schedule_frequency
          });
        }
      } catch (err) {
        console.error('Error fetching report details:', err);
        setError(t('failed_to_load_report_details'));
      } finally {
        setLoading(false);
      }
    };
    
    if (reportId) {
      fetchReportDetails();
    }
  }, [reportId, isEditMode, t]);

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }).format(date);
  };

  const formatDuration = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}m ${remainingSeconds}s`;
  };

  const getStatusBadgeClass = (status) => {
    switch (status) {
      case 'completed':
        return styles.statusCompleted;
      case 'scheduled':
        return styles.statusScheduled;
      case 'running':
        return styles.statusRunning;
      case 'failed':
        return styles.statusFailed;
      case 'draft':
        return styles.statusDraft;
      default:
        return '';
    }
  };

  const getTypeBadgeClass = (type) => {
    switch (type) {
      case 'analytics':
        return styles.typeAnalytics;
      case 'financial':
        return styles.typeFinancial;
      case 'security':
        return styles.typeSecurity;
      case 'survey':
        return styles.typeSurvey;
      case 'marketing':
        return styles.typeMarketing;
      default:
        return '';
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSaving(true);
    setError(null);
    
    try {
      // This would be replaced with an actual API call
      // await reportService.updateReport(reportId, formData);
      
      // Mock update for now
      await new Promise(resolve => setTimeout(resolve, 800));
      
      // Call the success callback
      if (onSuccess) {
        onSuccess();
      }
    } catch (err) {
      console.error('Error updating report:', err);
      setError(t('failed_to_update_report'));
    } finally {
      setSaving(false);
    }
  };

  const handleRunReport = async () => {
    try {
      // This would be replaced with an actual API call
      // await reportService.runReport(reportId);
      
      // Mock update for now
      await new Promise(resolve => setTimeout(resolve, 800));
      
      // Update the local state to show the report is running
      setReport(prev => ({
        ...prev,
        report_status: 'running'
      }));
    } catch (err) {
      console.error('Error running report:', err);
      setError(t('failed_to_run_report'));
    }
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <div className={styles.spinner}></div>
        <p>{t('loading_report_details')}</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.error}>
        <p>{error}</p>
        <button className={styles.backButton} onClick={onBack}>
          {t('back_to_reports')}
        </button>
      </div>
    );
  }

  if (!report) {
    return (
      <div className={styles.notFound}>
        <p>{t('report_not_found')}</p>
        <button className={styles.backButton} onClick={onBack}>
          {t('back_to_reports')}
        </button>
      </div>
    );
  }

  // Render edit form
  if (isEditMode) {
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <button className={styles.backButton} onClick={onBack} disabled={saving}>
            <i className="fas fa-arrow-left"></i>
            <span>{t('back')}</span>
          </button>
        </div>
        
        {error && (
          <div className={styles.errorMessage}>
            <i className="fas fa-exclamation-circle"></i>
            <span>{error}</span>
          </div>
        )}
        
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.formSection}>
            <h3 className={styles.sectionTitle}>{t('report_information')}</h3>
            <div className={styles.formGrid}>
              <div className={styles.formGroup}>
                <label htmlFor="report_name" className={styles.label}>
                  {t('report_name')} <span className={styles.required}>*</span>
                </label>
                <input
                  type="text"
                  id="report_name"
                  name="report_name"
                  className={styles.input}
                  value={formData.report_name}
                  onChange={handleChange}
                  required
                  disabled={saving}
                />
              </div>
              
              <div className={styles.formGroup}>
                <label htmlFor="report_type" className={styles.label}>
                  {t('report_type')} <span className={styles.required}>*</span>
                </label>
                <select
                  id="report_type"
                  name="report_type"
                  className={styles.select}
                  value={formData.report_type}
                  onChange={handleChange}
                  required
                  disabled={saving}
                >
                  <option value="analytics">{t('analytics')}</option>
                  <option value="financial">{t('financial')}</option>
                  <option value="security">{t('security')}</option>
                  <option value="survey">{t('survey')}</option>
                  <option value="marketing">{t('marketing')}</option>
                </select>
              </div>
              
              <div className={styles.formGroup}>
                <label htmlFor="report_format" className={styles.label}>
                  {t('report_format')} <span className={styles.required}>*</span>
                </label>
                <select
                  id="report_format"
                  name="report_format"
                  className={styles.select}
                  value={formData.report_format}
                  onChange={handleChange}
                  required
                  disabled={saving}
                >
                  <option value="pdf">PDF</option>
                  <option value="excel">Excel</option>
                  <option value="csv">CSV</option>
                  <option value="dashboard">Dashboard</option>
                </select>
              </div>
              
              <div className={styles.formGroup}>
                <label htmlFor="report_status" className={styles.label}>
                  {t('status')} <span className={styles.required}>*</span>
                </label>
                <select
                  id="report_status"
                  name="report_status"
                  className={styles.select}
                  value={formData.report_status}
                  onChange={handleChange}
                  required
                  disabled={saving}
                >
                  <option value="draft">{t('draft')}</option>
                  <option value="scheduled">{t('scheduled')}</option>
                  <option value="completed">{t('completed')}</option>
                </select>
              </div>
              
              <div className={styles.formGroupFull}>
                <label htmlFor="report_description" className={styles.label}>
                  {t('description')} <span className={styles.required}>*</span>
                </label>
                <textarea
                  id="report_description"
                  name="report_description"
                  className={styles.textarea}
                  value={formData.report_description}
                  onChange={handleChange}
                  rows={3}
                  required
                  disabled={saving}
                ></textarea>
              </div>
            </div>
          </div>
          
          <div className={styles.formSection}>
            <h3 className={styles.sectionTitle}>{t('scheduling_information')}</h3>
            <div className={styles.formGrid}>
              <div className={styles.formGroup}>
                <div className={styles.checkboxGroup}>
                  <input
                    type="checkbox"
                    id="scheduled"
                    name="scheduled"
                    className={styles.checkbox}
                    checked={formData.scheduled}
                    onChange={handleChange}
                    disabled={saving}
                  />
                  <label htmlFor="scheduled" className={styles.checkboxLabel}>
                    {t('schedule_this_report')}
                  </label>
                </div>
              </div>
              
              {formData.scheduled && (
                <div className={styles.formGroup}>
                  <label htmlFor="schedule_frequency" className={styles.label}>
                    {t('frequency')} <span className={styles.required}>*</span>
                  </label>
                  <select
                    id="schedule_frequency"
                    name="schedule_frequency"
                    className={styles.select}
                    value={formData.schedule_frequency}
                    onChange={handleChange}
                    required={formData.scheduled}
                    disabled={saving}
                  >
                    <option value="daily">{t('daily')}</option>
                    <option value="weekly">{t('weekly')}</option>
                    <option value="monthly">{t('monthly')}</option>
                    <option value="quarterly">{t('quarterly')}</option>
                  </select>
                </div>
              )}
            </div>
          </div>
          
          <div className={styles.formActions}>
            <button 
              type="button" 
              className={styles.cancelButton} 
              onClick={onBack}
              disabled={saving}
            >
              {t('cancel')}
            </button>
            <button 
              type="submit" 
              className={styles.submitButton}
              disabled={saving}
            >
              {saving ? (
                <>
                  <span className={styles.spinner}></span>
                  <span>{t('saving')}</span>
                </>
              ) : (
                t('save_changes')
              )}
            </button>
          </div>
        </form>
      </div>
    );
  }

  // Render detail view
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <h2 className={styles.reportTitle}>{report.report_name}</h2>
          <div className={styles.reportId}>ID: {report.report_id}</div>
          <div className={styles.statusContainer}>
            <span className={`${styles.statusBadge} ${getStatusBadgeClass(report.report_status)}`}>
              {report.report_status}
            </span>
            <span className={`${styles.typeBadge} ${getTypeBadgeClass(report.report_type)}`}>
              {report.report_type}
            </span>
          </div>
        </div>
        <div className={styles.headerActions}>
          <button className={styles.backButton} onClick={onBack}>
            <i className="fas fa-arrow-left"></i>
            <span>{t('back')}</span>
          </button>
          <button 
            className={styles.runButton} 
            onClick={handleRunReport}
            disabled={report.report_status === 'running'}
          >
            <i className="fas fa-play"></i>
            <span>{t('run_report')}</span>
          </button>
          <button className={styles.editButton} onClick={onEdit}>
            <i className="fas fa-edit"></i>
            <span>{t('edit')}</span>
          </button>
        </div>
      </div>
      
      <div className={styles.tabs}>
        <button 
          className={`${styles.tab} ${activeTab === 'overview' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('overview')}
        >
          {t('overview')}
        </button>
        <button 
          className={`${styles.tab} ${activeTab === 'parameters' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('parameters')}
        >
          {t('parameters')}
        </button>
        <button 
          className={`${styles.tab} ${activeTab === 'history' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('history')}
        >
          {t('execution_history')}
        </button>
        <button 
          className={`${styles.tab} ${activeTab === 'access' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('access')}
        >
          {t('access')}
        </button>
      </div>
      
      <div className={styles.tabContent}>
        {activeTab === 'overview' && (
          <div className={styles.overviewTab}>
            <div className={styles.section}>
              <h3 className={styles.sectionTitle}>{t('report_information')}</h3>
              <div className={styles.infoGrid}>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('report_id')}</div>
                  <div className={styles.infoValue}>{report.report_id}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('report_name')}</div>
                  <div className={styles.infoValue}>{report.report_name}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('report_type')}</div>
                  <div className={styles.infoValue}>
                    <span className={`${styles.typeBadge} ${getTypeBadgeClass(report.report_type)}`}>
                      {report.report_type}
                    </span>
                  </div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('status')}</div>
                  <div className={styles.infoValue}>
                    <span className={`${styles.statusBadge} ${getStatusBadgeClass(report.report_status)}`}>
                      {report.report_status}
                    </span>
                  </div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('enterprise')}</div>
                  <div className={styles.infoValue}>{report.enterprise_name}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('enterprise_id')}</div>
                  <div className={styles.infoValue}>{report.enterprise_id}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('format')}</div>
                  <div className={styles.infoValue}>{report.report_format.toUpperCase()}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('scheduled')}</div>
                  <div className={styles.infoValue}>{report.scheduled ? t('yes') : t('no')}</div>
                </div>
                {report.scheduled && (
                  <>
                    <div className={styles.infoItem}>
                      <div className={styles.infoLabel}>{t('frequency')}</div>
                      <div className={styles.infoValue}>{report.schedule_frequency}</div>
                    </div>
                    <div className={styles.infoItem}>
                      <div className={styles.infoLabel}>{t('last_run')}</div>
                      <div className={styles.infoValue}>{formatDate(report.last_run_date)}</div>
                    </div>
                    <div className={styles.infoItem}>
                      <div className={styles.infoLabel}>{t('next_run')}</div>
                      <div className={styles.infoValue}>{formatDate(report.next_run_date)}</div>
                    </div>
                  </>
                )}
              </div>
            </div>
            
            <div className={styles.section}>
              <h3 className={styles.sectionTitle}>{t('description')}</h3>
              <p className={styles.description}>{report.report_description}</p>
            </div>
            
            <div className={styles.section}>
              <h3 className={styles.sectionTitle}>{t('report_data')}</h3>
              <div className={styles.infoGrid}>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('metrics')}</div>
                  <div className={styles.infoValue}>
                    <div className={styles.tagsList}>
                      {report.report_data.metrics.map((metric, index) => (
                        <span key={index} className={styles.metricTag}>
                          {metric.replace('_', ' ')}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('timeframe')}</div>
                  <div className={styles.infoValue}>{report.report_data.timeframe.replace('_', ' ')}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('user_segments')}</div>
                  <div className={styles.infoValue}>
                    <div className={styles.tagsList}>
                      {report.report_data.filters.user_segments.map((segment, index) => (
                        <span key={index} className={styles.segmentTag}>
                          {segment.replace('_', ' ')}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('regions')}</div>
                  <div className={styles.infoValue}>
                    <div className={styles.tagsList}>
                      {report.report_data.filters.regions.map((region, index) => (
                        <span key={index} className={styles.regionTag}>
                          {region.replace('_', ' ')}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div className={styles.section}>
              <h3 className={styles.sectionTitle}>{t('audit_information')}</h3>
              <div className={styles.infoGrid}>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('created_at')}</div>
                  <div className={styles.infoValue}>{formatDate(report.created_at)}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('created_by')}</div>
                  <div className={styles.infoValue}>{report.created_by}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('updated_at')}</div>
                  <div className={styles.infoValue}>{formatDate(report.updated_at)}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('updated_by')}</div>
                  <div className={styles.infoValue}>{report.updated_by}</div>
                </div>
              </div>
            </div>
          </div>
        )}
        
        {activeTab === 'parameters' && (
          <div className={styles.parametersTab}>
            <div className={styles.section}>
              <h3 className={styles.sectionTitle}>{t('report_parameters')}</h3>
              
              {report.parameters ? (
                <div className={styles.parametersGrid}>
                  <div className={styles.infoItem}>
                    <div className={styles.infoLabel}>{t('start_date')}</div>
                    <div className={styles.infoValue}>{report.parameters.start_date}</div>
                  </div>
                  <div className={styles.infoItem}>
                    <div className={styles.infoLabel}>{t('end_date')}</div>
                    <div className={styles.infoValue}>{report.parameters.end_date}</div>
                  </div>
                  <div className={styles.infoItem}>
                    <div className={styles.infoLabel}>{t('include_charts')}</div>
                    <div className={styles.infoValue}>{report.parameters.include_charts ? t('yes') : t('no')}</div>
                  </div>
                  <div className={styles.infoItem}>
                    <div className={styles.infoLabel}>{t('include_raw_data')}</div>
                    <div className={styles.infoValue}>{report.parameters.include_raw_data ? t('yes') : t('no')}</div>
                  </div>
                  <div className={styles.infoItem}>
                    <div className={styles.infoLabel}>{t('chart_types')}</div>
                    <div className={styles.infoValue}>
                      <div className={styles.tagsList}>
                        {report.parameters.chart_types.map((chartType, index) => (
                          <span key={index} className={styles.chartTypeTag}>
                            {chartType}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className={styles.emptyState}>
                  <p>{t('no_parameters_defined')}</p>
                </div>
              )}
            </div>
          </div>
        )}
        
        {activeTab === 'history' && (
          <div className={styles.historyTab}>
            <div className={styles.section}>
              <h3 className={styles.sectionTitle}>{t('execution_history')}</h3>
              
              {report.execution_history && report.execution_history.length > 0 ? (
                <div className={styles.executionList}>
                  {report.execution_history.map((execution) => (
                    <div key={execution.execution_id} className={styles.executionItem}>
                      <div className={styles.executionHeader}>
                        <div className={styles.executionStatus}>
                          <span className={`${styles.statusBadge} ${getStatusBadgeClass(execution.status)}`}>
                            {execution.status}
                          </span>
                        </div>
                        <div className={styles.executionTime}>
                          {formatDate(execution.start_time)}
                        </div>
                      </div>
                      <div className={styles.executionDetails}>
                        <div className={styles.executionDetail}>
                          <span className={styles.executionDetailLabel}>{t('execution_id')}:</span>
                          <span className={styles.executionDetailValue}>{execution.execution_id}</span>
                        </div>
                        <div className={styles.executionDetail}>
                          <span className={styles.executionDetailLabel}>{t('duration')}:</span>
                          <span className={styles.executionDetailValue}>{formatDuration(execution.duration_seconds)}</span>
                        </div>
                        <div className={styles.executionDetail}>
                          <span className={styles.executionDetailLabel}>{t('triggered_by')}:</span>
                          <span className={styles.executionDetailValue}>{execution.triggered_by}</span>
                        </div>
                        {execution.error && (
                          <div className={styles.executionDetail}>
                            <span className={styles.executionDetailLabel}>{t('error')}:</span>
                            <span className={`${styles.executionDetailValue} ${styles.errorText}`}>{execution.error}</span>
                          </div>
                        )}
                      </div>
                      {execution.download_url && (
                        <div className={styles.executionActions}>
                          <a 
                            href={execution.download_url} 
                            target="_blank" 
                            rel="noopener noreferrer"
                            className={styles.downloadButton}
                          >
                            <i className="fas fa-download"></i>
                            <span>{t('download')}</span>
                          </a>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <div className={styles.emptyState}>
                  <p>{t('no_execution_history')}</p>
                </div>
              )}
            </div>
          </div>
        )}
        
        {activeTab === 'access' && (
          <div className={styles.accessTab}>
            <div className={styles.section}>
              <h3 className={styles.sectionTitle}>{t('access_information')}</h3>
              
              {report.access ? (
                <div className={styles.accessInfo}>
                  <div className={styles.infoGrid}>
                    <div className={styles.infoItem}>
                      <div className={styles.infoLabel}>{t('access_control')}</div>
                      <div className={styles.infoValue}>{report.access.access_control}</div>
                    </div>
                  </div>
                  
                  <div className={styles.authorizedRoles}>
                    <h4 className={styles.subSectionTitle}>{t('authorized_roles')}</h4>
                    <div className={styles.rolesList}>
                      {report.access.authorized_roles.map((role, index) => (
                        <span key={index} className={styles.roleTag}>{role}</span>
                      ))}
                    </div>
                  </div>
                  
                  <div className={styles.sharedWith}>
                    <h4 className={styles.subSectionTitle}>{t('shared_with')}</h4>
                    {report.access.shared_with.length > 0 ? (
                      <div className={styles.sharedUsersList}>
                        {report.access.shared_with.map((user) => (
                          <div key={user.user_id} className={styles.sharedUser}>
                            <div className={styles.sharedUserInfo}>
                              <div className={styles.sharedUserName}>{user.name}</div>
                              <div className={styles.sharedUserEmail}>{user.email}</div>
                            </div>
                            <div className={styles.sharedUserMeta}>
                              <div className={styles.sharedUserDetail}>
                                <span className={styles.sharedUserDetailLabel}>{t('shared_at')}:</span>
                                <span className={styles.sharedUserDetailValue}>{formatDate(user.shared_at)}</span>
                              </div>
                              <div className={styles.sharedUserDetail}>
                                <span className={styles.sharedUserDetailLabel}>{t('shared_by')}:</span>
                                <span className={styles.sharedUserDetailValue}>{user.shared_by}</span>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className={styles.emptyState}>
                        <p>{t('not_shared_with_anyone')}</p>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className={styles.emptyState}>
                  <p>{t('no_access_information_available')}</p>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReportDetail;