// File: frontend/src/pages/InvestorPitch/configs/CustomerInsurersConfig.js

import React from 'react';
import * as Slides from '../slides';

export const getCustomerInsurersSlides = (t) => [
  {
    id: 'intro',
    title: t('investor_pitch.slides.intro.title'),
    content: <Slides.IntroSlide />
  },
  {
    id: 'insurance-future',
    title: t('investor_pitch.slides.future.title'),
    content: <Slides.InsuranceFutureSlide />
  },
  {
    id: 'vision',
    title: t('investor_pitch.slides.vision.title'),
    content: <Slides.VisionSlide />
  },
  {
    id: 'expense-analysis',
    title: t('investor_pitch.slides.expense_analysis.title'),
    content: <Slides.ExpenseAnalysisSlide />
  },
  {
    id: 'methodology',
    title: t('investor_pitch.slides.methodology.title'),
    content: <Slides.MethodologySlide />
  },
  {
    id: 'distribution-model',
    title: t('investor_pitch.slides.distribution_model.title'),
    content: <Slides.DistributionModelSlide />
  },
  {
    id: 'waterfall',
    title: t('investor_pitch.slides.waterfall.title'),
    content: <Slides.WaterfallSlide />
  },
  {
    id: 'technology',
    title: t('investor_pitch.slides.technology.title'),
    content: <Slides.TechnologySlide />
  },
  {
    id: 'security',
    title: t('investor_pitch.slides.security.title'),
    content: <Slides.SecuritySlide />
  },
  {
    id: 'closing',
    title: t('investor_pitch.slides.closing.title'),
    content: <Slides.ClosingSlide />
  }
];