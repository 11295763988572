// File: frontend/src/pages/TripInsight/components/TripSearchPanel/index.js

import React, { useState } from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import styles from './TripSearchPanel.module.css';

const TripSearchPanel = ({ onSearch, loading }) => {
  const { t } = useLanguage();
  const [tripId, setTripId] = useState('');
  const [userId, setUserId] = useState('');
  const [searchType, setSearchType] = useState('trip_id');

  const handleSubmit = (e) => {
    e.preventDefault();
    
    const searchParams = {};
    
    if (searchType === 'trip_id' && tripId.trim()) {
      searchParams.trip_id = tripId.trim();
    } else if (searchType === 'user_id' && userId.trim()) {
      searchParams.user_id = userId.trim();
    }
    
    if (Object.keys(searchParams).length > 0) {
      onSearch(searchParams);
    }
  };

  const handleSearchTypeChange = (type) => {
    setSearchType(type);
  };

  const handleStartUploading = () => {
    // This would open a file upload dialog or navigate to a bulk upload page
    console.log('Start uploading CSV file');
  };

  return (
    <div className={styles.searchPanelsContainer}>
      <div className={styles.searchPanel}>
        <div className={styles.panelContent}>
          <div className={styles.panelHeader}>
            <div className={styles.iconContainer}>
              <img src="/icons/trip-icon.svg" alt="" className={styles.panelIcon} />
            </div>
            <div className={styles.headerContent}>
              <h3 className={styles.panelTitle}>{t('get_insights_for_a_single_trip')}</h3>
              <p className={styles.panelDescription}>{t('search_by_trip_id_or_user_id_for_detailed_insights')}</p>
            </div>
          </div>
          
          <div className={styles.searchTypeToggle}>
            <button 
              className={`${styles.toggleButton} ${searchType === 'trip_id' ? styles.activeToggle : ''}`}
              onClick={() => handleSearchTypeChange('trip_id')}
              type="button"
            >
              {t('search_by_trip_id')}
            </button>
            <button 
              className={`${styles.toggleButton} ${searchType === 'user_id' ? styles.activeToggle : ''}`}
              onClick={() => handleSearchTypeChange('user_id')}
              type="button"
            >
              {t('search_by_user_id')}
            </button>
          </div>
          
          <form onSubmit={handleSubmit} className={styles.searchForm}>
            <div className={styles.searchInputContainer}>
              {searchType === 'trip_id' ? (
                <input
                  type="text"
                  className={styles.searchInput}
                  value={tripId}
                  onChange={(e) => setTripId(e.target.value)}
                  placeholder={t('enter_trip_id')}
                  disabled={loading}
                />
              ) : (
                <input
                  type="text"
                  className={styles.searchInput}
                  value={userId}
                  onChange={(e) => setUserId(e.target.value)}
                  placeholder={t('enter_user_id')}
                  disabled={loading}
                />
              )}
              <button 
                type="submit" 
                className={styles.searchButton}
                disabled={loading || (searchType === 'trip_id' ? !tripId.trim() : !userId.trim())}
              >
                {loading ? t('searching') : t('search')}
                <img src="/icons/search-icon.svg" alt="" className={styles.searchIcon} />
              </button>
            </div>
          </form>
        </div>
      </div>
      
      <div className={styles.searchPanel}>
        <div className={styles.panelContent}>
          <div className={styles.panelHeader}>
            <div className={styles.iconContainer}>
              <img src="/icons/trips-icon.svg" alt="" className={styles.panelIcon} />
            </div>
            <div className={styles.headerContent}>
              <h3 className={styles.panelTitle}>{t('get_insights_for_many_trips')}</h3>
              <p className={styles.panelDescription}>{t('upload_a_document_to_receive_comprehensive_trip_insights')}</p>
            </div>
          </div>
          
          <button 
            className={styles.uploadButton}
            onClick={handleStartUploading}
          >
            {t('start_uploading_with_a_csv_file')}
            <img src="/icons/chevron-right-icon.svg" alt="" className={styles.chevronIcon} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default TripSearchPanel;