// File: frontend/src/components/ErrorMessage/ErrorMessage.js

import React from 'react';
import PropTypes from 'prop-types';
import { useLanguage } from '../../contexts/LanguageContext';
import { getErrorMessage } from '../../utils/errorHandler';
import { APIError } from '../../services/api';  // Add this import
import styles from './ErrorMessage.module.css';

const ErrorMessage = ({ error, className, variant = 'inline', onClose }) => {
  const { t } = useLanguage();
  
  if (!error) return null;

  // Handle different error types
  let message;
  if (typeof error === 'string') {
    message = t(error); // Direct translation if it's a string key
  } else if (error instanceof APIError) {
    message = getErrorMessage(error, t);
  } else if (error.message) {
    // For regular Error objects or error-like objects with message
    message = t(error.message);
  } else {
    console.warn('Unexpected error type:', error);
    message = t('ERROR_CODES.SERVER_ERROR.DEFAULT');
  }

  const variantClass = variant === 'floating' ? styles.floating : '';
  
  return (
    <div className={`${styles.errorContainer} ${variantClass} ${className || ''}`}>
      <img src="/icons/18/alerts/error.svg" alt="" className={styles.errorIcon} aria-hidden="true" />
      <span role="alert">{message}</span>
      {onClose && (
        <button 
          className={styles.closeButton} 
          onClick={onClose}
          aria-label={t('alert_close')}
        >
          <img 
            src="/icons/18/misc/close.svg" 
            alt="" 
            className={styles.closeIcon} 
            aria-hidden="true" 
          />
        </button>
      )}
    </div>
  );
};

ErrorMessage.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(APIError),  // Update this to specifically check for APIError
    PropTypes.instanceOf(Error),
    PropTypes.shape({
      message: PropTypes.string,
      code: PropTypes.string,
      status: PropTypes.number,
      details: PropTypes.object
    })
  ]),
  className: PropTypes.string,
  variant: PropTypes.oneOf(['inline', 'floating']),
  onClose: PropTypes.func
};

export default ErrorMessage;