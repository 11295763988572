// File: frontend/src/pages/EmbeddedInsurance/components/common/Tabs.js

import React from 'react';
import styles from './styles/Tabs.module.css';

const Tabs = ({ tabs, activeTab, onChange }) => {
  return (
    <div className={styles.tabs}>
      {tabs.map((tab) => (
        <button
          key={tab.id}
          className={`${styles.tab} ${activeTab === tab.id ? styles.activeTab : ''}`}
          onClick={() => onChange(tab.id)}
        >
          <i className={`fas fa-${tab.icon}`}></i>
          <span>{tab.label}</span>
        </button>
      ))}
    </div>
  );
};

export default Tabs;