// File: frontend/src/pages/QMS/QuoteDetail/components/QuoteSystem/index.js

import React from 'react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from './QuoteSystem.module.css';

const QuoteSystem = ({ quote }) => {
  const { t } = useLanguage();

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <div className={styles.cardHeader}>
          <h3 className={styles.cardTitle}>{t('system_information')}</h3>
        </div>
        <div className={styles.cardContent}>
          <div className={styles.infoGroup}>
            <div className={styles.infoLabel}>{t('user_id')}:</div>
            <div className={styles.infoValue}>{quote.user_id || 'N/A'}</div>
          </div>
          
          {quote.s3_references && (
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('s3_references')}:</div>
              <div className={styles.infoValue}>
                <ul className={styles.referencesList}>
                  {quote.s3_references.prompt_path && (
                    <li>
                      <span className={styles.referenceLabel}>{t('prompt_path')}:</span>
                      <span className={styles.referenceValue}>{quote.s3_references.prompt_path}</span>
                    </li>
                  )}
                  {quote.s3_references.quote_template_path && (
                    <li>
                      <span className={styles.referenceLabel}>{t('quote_template_path')}:</span>
                      <span className={styles.referenceValue}>{quote.s3_references.quote_template_path}</span>
                    </li>
                  )}
                  {quote.s3_references.plato_reference && (
                    <li>
                      <span className={styles.referenceLabel}>{t('plato_reference')}:</span>
                      <span className={styles.referenceValue}>{quote.s3_references.plato_reference}</span>
                    </li>
                  )}
                  {quote.s3_references.quote_reference && (
                    <li>
                      <span className={styles.referenceLabel}>{t('quote_reference')}:</span>
                      <span className={styles.referenceValue}>{quote.s3_references.quote_reference}</span>
                    </li>
                  )}
                  {quote.s3_references.metadata_path && (
                    <li>
                      <span className={styles.referenceLabel}>{t('metadata_path')}:</span>
                      <span className={styles.referenceValue}>{quote.s3_references.metadata_path}</span>
                    </li>
                  )}
                  {quote.s3_references.messages_path && (
                    <li>
                      <span className={styles.referenceLabel}>{t('messages_path')}:</span>
                      <span className={styles.referenceValue}>{quote.s3_references.messages_path}</span>
                    </li>
                  )}
                  {quote.s3_references.quote_document_path && (
                    <li>
                      <span className={styles.referenceLabel}>{t('quote_document_path')}:</span>
                      <span className={styles.referenceValue}>{quote.s3_references.quote_document_path}</span>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          )}
          
          {quote.integration && (
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('integration')}:</div>
              <div className={styles.infoValue}>
                <div className={styles.integrationItem}>
                  <span className={styles.integrationLabel}>{t('external_reference_id')}:</span>
                  <span className={styles.integrationValue}>{quote.integration.external_reference_id || 'N/A'}</span>
                </div>
                <div className={styles.integrationItem}>
                  <span className={styles.integrationLabel}>{t('integration_status')}:</span>
                  <span className={styles.integrationValue}>{quote.integration.integration_status || 'N/A'}</span>
                </div>
                <div className={styles.integrationItem}>
                  <span className={styles.integrationLabel}>{t('callback_url')}:</span>
                  <span className={styles.integrationValue}>{quote.integration.callback_url || 'N/A'}</span>
                </div>
              </div>
            </div>
          )}
          
          {quote.flags && (
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('system_flags')}:</div>
              <div className={styles.infoValue}>
                <ul className={styles.flagsList}>
                  {Object.entries(quote.flags).map(([key, value]) => (
                    <li key={key} className={styles.flagItem}>
                      <span className={styles.flagLabel}>{t(key)}:</span>
                      <span className={`${styles.flagValue} ${value ? styles.flagTrue : styles.flagFalse}`}>
                        {value ? t('yes') : t('no')}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
      
      <div className={styles.card}>
        <div className={styles.cardHeader}>
          <h3 className={styles.cardTitle}>{t('underwriting_information')}</h3>
        </div>
        <div className={styles.cardContent}>
          {quote.underwriting ? (
            <>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('underwriting_company')}:</div>
                <div className={styles.infoValue}>{quote.underwriting.underwriting_company || 'N/A'}</div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('issuing_company')}:</div>
                <div className={styles.infoValue}>{quote.underwriting.issuing_company || 'N/A'}</div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('distributing_company')}:</div>
                <div className={styles.infoValue}>{quote.underwriting.distributing_company || 'N/A'}</div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('governing_law_jurisdiction')}:</div>
                <div className={styles.infoValue}>{quote.underwriting.governing_law_jurisdiction || 'N/A'}</div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('eligibility_status')}:</div>
                <div className={styles.infoValue}>
                  <span className={`${styles.eligibilityStatus} ${styles[quote.underwriting.eligibility_status]}`}>
                    {quote.underwriting.eligibility_status || 'N/A'}
                  </span>
                </div>
              </div>
              
              {quote.underwriting.decline_reasons && quote.underwriting.decline_reasons.length > 0 && (
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('decline_reasons')}:</div>
                  <div className={styles.infoValue}>
                    <ul className={styles.reasonsList}>
                      {quote.underwriting.decline_reasons.map((reason, index) => (
                        <li key={index} className={styles.reasonItem}>{reason}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
              
              {quote.underwriting.referral_reasons && quote.underwriting.referral_reasons.length > 0 && (
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('referral_reasons')}:</div>
                  <div className={styles.infoValue}>
                    <ul className={styles.reasonsList}>
                      {quote.underwriting.referral_reasons.map((reason, index) => (
                        <li key={index} className={styles.reasonItem}>{reason}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
              
              {quote.underwriting.hard_stops && quote.underwriting.hard_stops.length > 0 && (
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('hard_stops')}:</div>
                  <div className={styles.infoValue}>
                    <ul className={styles.reasonsList}>
                      {quote.underwriting.hard_stops.map((stop, index) => (
                        <li key={index} className={styles.reasonItem}>{stop}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className={styles.noData}>{t('no_underwriting_information')}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuoteSystem;