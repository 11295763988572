// File: frontend/src/components/form/ContactForm/ContactForm.js

import React, { useState } from 'react';
import { useLanguage } from '../../../contexts/LanguageContext';
import { useContact } from '../../../hooks/useContact';
import Input from '../Input/Input';
import TextArea from '../TextArea/TextArea';
import FormGroup from '../FormGroup/FormGroup';
import Button from '../../Button/Button';
import Alert from '../../Alert/Alert';
import styles from './ContactForm.module.css';

const ContactForm = () => {
  const { t } = useLanguage();
  const { submit, isLoading, error, success, clearError } = useContact();

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    message: '',
  });

  const [formErrors, setFormErrors] = useState({});

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.firstName) errors.firstName = 'required';
    if (!formData.lastName) errors.lastName = 'required';
    if (!formData.email) {
      errors.email = 'required';
    } else if (!isValidEmail(formData.email)) {
      errors.email = 'invalid';
    }
    if (!formData.message) {
      errors.message = 'required';
    } else if (formData.message.length > 500) {
      errors.message = 'tooLong';
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleChange = (field, value) => {
    setFormData(prev => ({ ...prev, [field]: value }));
    if (formErrors[field]) {
      setFormErrors(prev => ({ ...prev, [field]: null }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      await submit(formData);
      // Reset form on success
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        message: '',
      });
    } catch (err) {
      // Error handling is done in the hook
    }
  };

  return (
    <div className={styles.contactFormContainer}>
      {!success && (
        <form onSubmit={handleSubmit} className={styles.form}>
          <FormGroup>
            <Input
              id="firstName"
              labelKey="contact.firstNameLabel"
              placeholderKey="contact.firstNamePlaceholder"
              value={formData.firstName}
              onChange={(value) => handleChange('firstName', value)}
              required
              error={!!formErrors.firstName}
              errorKey={formErrors.firstName && 'validation.required'}
            />
            <Input
              id="lastName"
              labelKey="contact.lastNameLabel"
              placeholderKey="contact.lastNamePlaceholder"
              value={formData.lastName}
              onChange={(value) => handleChange('lastName', value)}
              required
              error={!!formErrors.lastName}
              errorKey={formErrors.lastName && 'validation.required'}
            />
            <Input
              id="email"
              type="email"
              labelKey="contact.emailLabel"
              placeholderKey="contact.emailPlaceholder"
              value={formData.email}
              onChange={(value) => handleChange('email', value)}
              required
              error={!!formErrors.email}
              errorKey={formErrors.email && `validation.email.${formErrors.email}`}
            />
            <TextArea
              id="message"
              labelKey="contact.messageLabel"
              placeholderKey="contact.messagePlaceholder"
              value={formData.message}
              onChange={(value) => handleChange('message', value)}
              maxLength={500}
              required
              error={!!formErrors.message}
              errorKey={formErrors.message && `validation.message.${formErrors.message}`}
            />
          </FormGroup>
          <Button 
            type="submit" 
            variant="primary" 
            size="medium"
            isLoading={isLoading}
            disabled={isLoading}
            showArrow
          >
            {t('contact.submitButton')}
          </Button>
        </form>
      )}

      {error && (
        <Alert 
          type="error" 
          titleKey="contact.error.title"
          messageKey={`contact.errors.${error}`}
          onClose={clearError}
        />
      )}

      {success && (
        <Alert 
          type="success" 
          titleKey="contact.success.title"
          messageKey="contact.success.message"
        />
      )}
    </div>
  );
};

export default ContactForm;