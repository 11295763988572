// File: frontend/src/pages/InvestorPitch/components/slides/MarketEntrySlide/MarketReadiness.js

import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import * as LucideIcons from 'lucide-react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import ProgressBar from '../../common/ProgressBar';
import styles from './MarketReadiness.module.css';

const MarketReadiness = ({ metrics, highlights }) => {
  const { t } = useLanguage();

  return (
    <motion.div 
      className={styles.container}
      whileHover={{ scale: 1.02 }}
      transition={{ type: 'spring', stiffness: 300, damping: 30 }}
    >
      <div className={styles.metrics}>
        {metrics.map((metric) => {
          const Icon = LucideIcons[metric.icon];
          return (
            <div 
              key={metric.title} 
              className={styles.metric}
              aria-label={t('investor_pitch.market_entry.readiness.metric.aria_label', { 
                title: metric.title,
                value: metric.value 
              })}
            >
              <div className={styles.metricHeader}>
                <div 
                  className={styles.iconWrapper}
                  style={{ color: metric.color }}
                >
                  <Icon className={styles.icon} />
                </div>
                <span className={styles.metricTitle}>{metric.title}</span>
              </div>
              <ProgressBar 
                value={metric.value} 
                max={100} 
                gradient={`linear-gradient(90deg, ${metric.color}, ${metric.color})`}
              />
              <div className={styles.value}>
                {t('investor_pitch.market_entry.readiness.metric.value', { value: metric.value })}
              </div>
            </div>
          );
        })}
      </div>

      <div className={styles.highlights}>
        <h4 className={styles.highlightsTitle}>
          {t('investor_pitch.market_entry.readiness.highlights.title')}
        </h4>
        <ul className={styles.highlightsList}>
          {highlights.map((highlight, index) => (
            <li key={index}>{highlight}</li>
          ))}
        </ul>
      </div>
    </motion.div>
  );
};

MarketReadiness.propTypes = {
  metrics: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      icon: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    })
  ).isRequired,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default MarketReadiness;