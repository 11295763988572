// File: frontend/src/pages/TripInsight/TripDetail/components/TripHeader/index.js

import React from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import styles from './TripHeader.module.css';

const TripHeader = ({ trip, onBack }) => {
  const { t } = useLanguage();

  // Helper function to format date
  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  // Helper function to format duration
  const formatDuration = (seconds) => {
    if (!seconds) return 'N/A';
    
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    
    if (hours > 0) {
      return `${hours}h ${minutes}m`;
    }
    return `${minutes}m`;
  };

  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <h1 className={styles.title}>
          {t('trip_details')}
        </h1>
        
        <div className={styles.meta}>
          <div className={styles.metaItem}>
            <span className={styles.metaLabel}>{t('trip_id')}:</span>
            <span className={styles.metaValue}>{trip.trip_id}</span>
          </div>
          
          <div className={styles.metaItem}>
            <span className={styles.metaLabel}>{t('start_time')}:</span>
            <span className={styles.metaValue}>{formatDate(trip.trip_start_timestamp)}</span>
          </div>
          
          <div className={styles.metaItem}>
            <span className={styles.metaLabel}>{t('duration')}:</span>
            <span className={styles.metaValue}>{formatDuration(trip.trip_duration_seconds)}</span>
          </div>
          
          <div className={styles.metaItem}>
            <span className={styles.metaLabel}>{t('distance')}:</span>
            <span className={styles.metaValue}>{trip.distance_travelled_calculation} km</span>
          </div>
          
          {trip.overall_score && (
            <div className={styles.scoreBadge}>
              {t('score')}: {trip.overall_score}
            </div>
          )}
        </div>
      </div>
      
      <div className={styles.actions}>
        <button className={styles.backButton} onClick={onBack}>
          {t('back_to_list')}
        </button>
        
        <button className={styles.exportButton}>
          {t('export_trip_data')}
        </button>
      </div>
    </div>
  );
};

export default TripHeader;