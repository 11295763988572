// File: frontend/src/pages/PMS/PMS.js

import React from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import styles from './PMS.module.css';

const PMS = () => {
  const { t } = useLanguage();

  return <div><h1 className={styles.title}>{t('pms')}</h1></div>;
};

export default PMS;


