// File: frontend/src/components/form/FormField/FormField.js

import React from 'react';
import PropTypes from 'prop-types';
import { useLanguage } from '../../../contexts/LanguageContext';
import Label from '../Label/Label';
import styles from './FormField.module.css';

const FormField = ({ 
  labelKey, 
  required, 
  optional, 
  icon, 
  hintKey, 
  errorKey, 
  children, 
  id 
}) => {
  const { t } = useLanguage();

  return (
    <div className={styles.formField}>
      <Label 
        textKey={labelKey} 
        required={required} 
        optional={optional} 
        icon={icon}
        htmlFor={id}
      />
      <div className={styles.inputWrapper}>
        {children}
      </div>
      {hintKey && !errorKey && (
        <div className={styles.hint}>
          <img className={styles.hintIcon} src="/icons/18/alerts/information.svg" alt="" aria-hidden="true" />
          <span>{t(hintKey)}</span>
        </div>
      )}
      {errorKey && (
        <div className={styles.error} role="alert">
          <img className={styles.errorIcon} src="/icons/18/alerts/error.svg" alt="" aria-hidden="true" />
          <span>{t(errorKey)}</span>
        </div>
      )}
    </div>
  );
};

FormField.propTypes = {
  id: PropTypes.string.isRequired,
  labelKey: PropTypes.string.isRequired,
  required: PropTypes.bool,
  optional: PropTypes.bool,
  icon: PropTypes.string,
  hintKey: PropTypes.string,
  errorKey: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default FormField;