// File: frontend/src/components/Dashboard/MapComponent.js

import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { MAPBOX_ACCESS_TOKEN } from '../../config/constants';
import mapData from '../../data/mapbox_data_for_dashboard.json';
import styles from './GlobalStatsCard.module.css';

mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;

const MapComponent = ({ data, category, onStyleLoad }) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [mapLoaded, setMapLoaded] = useState(false);

  // Initialize map
  useEffect(() => {
    if (!mapContainer.current) return;

    const initializeMap = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/dark-v11',
      center: [-37, 50],
      zoom: 1.4,
      dragRotate: false, // Disable map rotation
      touchZoomRotate: false, // Disable touch rotation
      scrollZoom: false, // Disable scroll zoom
      boxZoom: false, // Disable box zoom
      doubleClickZoom: false, // Disable double click zoom
      dragPan: true, // Keep the ability to pan
      keyboard: false, // Disable keyboard controls
      minZoom: 1.4, // Set minimum zoom level
      maxZoom: 1.4 // Set maximum zoom level (same as initial to lock it)
    });

    initializeMap.on('load', () => {
      // Remove default navigation controls
      // Note: We're not adding NavigationControl anymore

      // Add countries source
      initializeMap.addSource('countries', {
        type: 'vector',
        url: 'mapbox://mapbox.country-boundaries-v1'
      });

      // Add base country layer
      initializeMap.addLayer({
        id: 'country-fills',
        type: 'fill',
        source: 'countries',
        'source-layer': 'country_boundaries',
        paint: {
          'fill-color': '#2348ed',
          'fill-opacity': 0
        }
      });

      map.current = initializeMap;
      setMapLoaded(true);
      onStyleLoad(true);
    });

    return () => {
      initializeMap.remove();
      map.current = null;
      setMapLoaded(false);
      onStyleLoad(false);
    };
  }, []);

  // Update map data when category changes
  useEffect(() => {
    if (!map.current || !mapLoaded || !data) return;

    // Remove existing layers if they exist
    if (map.current.getLayer('locations')) {
      map.current.removeLayer('locations');
    }
    if (map.current.getSource('locations')) {
      map.current.removeSource('locations');
    }

    // Create GeoJSON for locations
    const geojson = {
      type: 'FeatureCollection',
      features: data.locations.map(location => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [location.longitude, location.latitude]
        },
        properties: {
          name: location.name,
          size: location.relative_size || 1
        }
      }))
    };

    // Add new source
    map.current.addSource('locations', {
      type: 'geojson',
      data: geojson
    });

    // Add appropriate layer based on type
    if (data.type === 'bubble') {
      map.current.addLayer({
        id: 'locations',
        type: 'circle',
        source: 'locations',
        paint: {
          'circle-radius': [
            'interpolate',
            ['linear'],
            ['get', 'size'],
            1, 5,
            50, 25
          ],
          'circle-color': '#2348ed',
          'circle-opacity': 0.6,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff'
        }
      });
    } else {
      // Highlight type
      const supportedCountries = mapData.supported_countries.countries;
      const countryNames = supportedCountries.map(country => country.name_en);
      const highlightedCountries = data.locations.map(location => location.name);

      map.current.setPaintProperty('country-fills', 'fill-opacity', [
        'case',
        ['in', ['get', 'name_en'], ['literal', highlightedCountries]],
        0.3,
        ['in', ['get', 'name_en'], ['literal', countryNames]],
        0.1,
        0
      ]);

      map.current.addLayer({
        id: 'locations',
        type: 'circle',
        source: 'locations',
        paint: {
          'circle-radius': 6,
          'circle-color': '#ffffff',
          'circle-opacity': 0.8,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#2348ed'
        }
      });
    }

    // Add popup functionality with improved styling
    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      className: styles.mapPopup, // Add custom class for styling
      offset: 15
    });

    map.current.on('mouseenter', 'locations', (e) => {
      map.current.getCanvas().style.cursor = 'pointer';
      
      const coordinates = e.features[0].geometry.coordinates.slice();
      const name = e.features[0].properties.name;
      
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }
      
      popup
        .setLngLat(coordinates)
        .setHTML(`<div class="${styles.popupContent}">${name}</div>`)
        .addTo(map.current);
    });

    map.current.on('mouseleave', 'locations', () => {
      map.current.getCanvas().style.cursor = '';
      popup.remove();
    });

  }, [data, category, mapLoaded]);

  return <div ref={mapContainer} className={styles.map} />;
};

export default MapComponent;