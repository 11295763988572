// File: frontend/src/pages/QMS/QuoteDetail/components/QuoteLocations/index.js

import React from 'react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from './QuoteLocations.module.css';

const QuoteLocations = ({ quote }) => {
  const { t } = useLanguage();

  if (!quote.locations || quote.locations.length === 0) {
    return (
      <div className={styles.noData}>
        <p>{t('no_location_information')}</p>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.locationsList}>
        {quote.locations.map((location, index) => (
          <div key={location.location_id || index} className={styles.locationCard}>
            <div className={styles.locationHeader}>
              <h3 className={styles.locationTitle}>
                {t(location.location_type)} {t('location')}
              </h3>
              {location.is_primary && (
                <span className={styles.primaryBadge}>{t('primary')}</span>
              )}
            </div>
            <div className={styles.locationContent}>
              <div className={styles.addressSection}>
                <h4 className={styles.sectionTitle}>{t('address')}</h4>
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('raw_address')}:</div>
                  <div className={styles.infoValue}>{location.raw_address || 'N/A'}</div>
                </div>
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('formatted_address')}:</div>
                  <div className={styles.infoValue}>{location.parsed_address?.formatted_address || 'N/A'}</div>
                </div>
              </div>
              
              <div className={styles.detailsSection}>
                <h4 className={styles.sectionTitle}>{t('details')}</h4>
                <div className={styles.detailsGrid}>
                  <div className={styles.infoGroup}>
                    <div className={styles.infoLabel}>{t('street')}:</div>
                    <div className={styles.infoValue}>
                      {location.parsed_address?.street_number} {location.parsed_address?.street_name}
                      {location.parsed_address?.unit_number && `, ${location.parsed_address.unit_number}`}
                    </div>
                  </div>
                  <div className={styles.infoGroup}>
                    <div className={styles.infoLabel}>{t('city')}:</div>
                    <div className={styles.infoValue}>{location.parsed_address?.city || 'N/A'}</div>
                  </div>
                  <div className={styles.infoGroup}>
                    <div className={styles.infoLabel}>{t('province_state')}:</div>
                    <div className={styles.infoValue}>
                      {location.parsed_address?.province || location.parsed_address?.state || 'N/A'}
                    </div>
                  </div>
                  <div className={styles.infoGroup}>
                    <div className={styles.infoLabel}>{t('postal_zip_code')}:</div>
                    <div className={styles.infoValue}>
                      {location.parsed_address?.postal_code || location.parsed_address?.zip_code || 'N/A'}
                    </div>
                  </div>
                  <div className={styles.infoGroup}>
                    <div className={styles.infoLabel}>{t('country')}:</div>
                    <div className={styles.infoValue}>{location.parsed_address?.country || 'N/A'}</div>
                  </div>
                  <div className={styles.infoGroup}>
                    <div className={styles.infoLabel}>{t('country_code')}:</div>
                    <div className={styles.infoValue}>{location.parsed_address?.country_code || 'N/A'}</div>
                  </div>
                </div>
              </div>
              
              {location.geolocation && (
                <div className={styles.geolocationSection}>
                  <h4 className={styles.sectionTitle}>{t('geolocation')}</h4>
                  <div className={styles.geolocationGrid}>
                    <div className={styles.infoGroup}>
                      <div className={styles.infoLabel}>{t('latitude')}:</div>
                      <div className={styles.infoValue}>{location.geolocation.latitude || 'N/A'}</div>
                    </div>
                    <div className={styles.infoGroup}>
                      <div className={styles.infoLabel}>{t('longitude')}:</div>
                      <div className={styles.infoValue}>{location.geolocation.longitude || 'N/A'}</div>
                    </div>
                    <div className={styles.infoGroup}>
                      <div className={styles.infoLabel}>{t('accuracy')}:</div>
                      <div className={styles.infoValue}>
                        {location.geolocation.accuracy 
                          ? `${location.geolocation.accuracy} ${t('meters')}` 
                          : 'N/A'}
                      </div>
                    </div>
                    <div className={styles.infoGroup}>
                      <div className={styles.infoLabel}>{t('place_id')}:</div>
                      <div className={styles.infoValue}>{location.geolocation.place_id || 'N/A'}</div>
                    </div>
                  </div>
                </div>
              )}
              
              <div className={styles.statusSection}>
                <h4 className={styles.sectionTitle}>{t('status')}</h4>
                <div className={styles.statusGrid}>
                  <div className={styles.infoGroup}>
                    <div className={styles.infoLabel}>{t('processing_status')}:</div>
                    <div className={styles.infoValue}>
                      <span className={styles[location.address_processing_status]}>
                        {t(location.address_processing_status || 'pending')}
                      </span>
                    </div>
                  </div>
                  <div className={styles.infoGroup}>
                    <div className={styles.infoLabel}>{t('confidence')}:</div>
                    <div className={styles.infoValue}>
                      {location.allegory_address_confidence 
                        ? `${(location.allegory_address_confidence * 100).toFixed(0)}%` 
                        : 'N/A'}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default QuoteLocations;