// File: frontend/src/pages/SuperAdmin/components/Dashboard/index.js

import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import SuperAdminCard from '../common/SuperAdminCard';
import styles from './Dashboard.module.css';

const Dashboard = () => {
  const { t } = useLanguage();
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState({
    totalEnterprises: 0,
    activeEnterprises: 0,
    totalUsers: 0,
    pendingVerifications: 0,
    activeSubscriptions: 0,
    expiringAgreements: 0,
    recentCommunications: 0,
    activeProducts: 0
  });

  useEffect(() => {
    const fetchDashboardData = async () => {
      setLoading(true);
      
      try {
        // This would be replaced with actual API calls
        // const response = await dashboardService.getStats();
        
        // Mock data for now
        await new Promise(resolve => setTimeout(resolve, 800));
        
        setStats({
          totalEnterprises: 42,
          activeEnterprises: 38,
          totalUsers: 1250,
          pendingVerifications: 15,
          activeSubscriptions: 40,
          expiringAgreements: 3,
          recentCommunications: 156,
          activeProducts: 12
        });
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      } finally {
        setLoading(false);
      }
    };
    
    fetchDashboardData();
  }, []);

  const recentActivities = [
    {
      id: 1,
      type: 'enterprise_created',
      entity: 'Acme Corporation',
      user: 'John Admin',
      timestamp: '2023-07-15T10:30:00Z'
    },
    {
      id: 2,
      type: 'user_verified',
      entity: 'jane.doe@example.com',
      user: 'Sarah Admin',
      timestamp: '2023-07-15T09:45:00Z'
    },
    {
      id: 3,
      type: 'agreement_updated',
      entity: 'Global Industries',
      user: 'John Admin',
      timestamp: '2023-07-14T16:20:00Z'
    },
    {
      id: 4,
      type: 'product_added',
      entity: 'Premium Analytics',
      user: 'Mike Admin',
      timestamp: '2023-07-14T14:10:00Z'
    },
    {
      id: 5,
      type: 'subscription_renewed',
      entity: 'Tech Solutions Inc',
      user: 'Sarah Admin',
      timestamp: '2023-07-14T11:05:00Z'
    }
  ];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }).format(date);
  };

  const getActivityIcon = (type) => {
    switch (type) {
      case 'enterprise_created':
        return 'fa-building';
      case 'user_verified':
        return 'fa-user-check';
      case 'agreement_updated':
        return 'fa-file-contract';
      case 'product_added':
        return 'fa-box';
      case 'subscription_renewed':
        return 'fa-sync';
      default:
        return 'fa-bell';
    }
  };

  const getActivityText = (activity) => {
    switch (activity.type) {
      case 'enterprise_created':
        return `${activity.user} created enterprise "${activity.entity}"`;
      case 'user_verified':
        return `${activity.user} verified user "${activity.entity}"`;
      case 'agreement_updated':
        return `${activity.user} updated agreement for "${activity.entity}"`;
      case 'product_added':
        return `${activity.user} added product "${activity.entity}"`;
      case 'subscription_renewed':
        return `${activity.user} renewed subscription for "${activity.entity}"`;
      default:
        return `${activity.user} performed action on "${activity.entity}"`;
    }
  };

  return (
    <div className={styles.dashboard}>
      <h1 className={styles.title}>{t('super_admin_dashboard')}</h1>
      
      <div className={styles.statsGrid}>
        <SuperAdminCard
          title={t('total_enterprises')}
          value={stats.totalEnterprises}
          icon="fa-building"
          loading={loading}
          color="primary"
        />
        <SuperAdminCard
          title={t('active_enterprises')}
          value={stats.activeEnterprises}
          icon="fa-check-circle"
          loading={loading}
          color="success"
        />
        <SuperAdminCard
          title={t('total_users')}
          value={stats.totalUsers}
          icon="fa-users"
          loading={loading}
          color="info"
        />
        <SuperAdminCard
          title={t('pending_verifications')}
          value={stats.pendingVerifications}
          icon="fa-user-clock"
          loading={loading}
          color="warning"
        />
        <SuperAdminCard
          title={t('active_subscriptions')}
          value={stats.activeSubscriptions}
          icon="fa-credit-card"
          loading={loading}
          color="primary"
        />
        <SuperAdminCard
          title={t('expiring_agreements')}
          value={stats.expiringAgreements}
          icon="fa-exclamation-triangle"
          loading={loading}
          color="danger"
        />
        <SuperAdminCard
          title={t('recent_communications')}
          value={stats.recentCommunications}
          icon="fa-envelope"
          loading={loading}
          color="info"
        />
        <SuperAdminCard
          title={t('active_products')}
          value={stats.activeProducts}
          icon="fa-box"
          loading={loading}
          color="success"
        />
      </div>
      
      <div className={styles.activitySection}>
        <h2 className={styles.sectionTitle}>{t('recent_activity')}</h2>
        <div className={styles.activityList}>
          {recentActivities.map((activity) => (
            <div key={activity.id} className={styles.activityItem}>
              <div className={styles.activityIcon}>
                <i className={`fas ${getActivityIcon(activity.type)}`}></i>
              </div>
              <div className={styles.activityContent}>
                <div className={styles.activityText}>
                  {getActivityText(activity)}
                </div>
                <div className={styles.activityTime}>
                  {formatDate(activity.timestamp)}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.viewAllContainer}>
          <button className={styles.viewAllButton}>
            {t('view_all_activity')}
          </button>
        </div>
      </div>
      
      <div className={styles.quickActions}>
        <h2 className={styles.sectionTitle}>{t('quick_actions')}</h2>
        <div className={styles.actionButtons}>
          <button className={styles.actionButton}>
            <i className="fas fa-plus-circle"></i>
            <span>{t('create_enterprise')}</span>
          </button>
          <button className={styles.actionButton}>
            <i className="fas fa-user-plus"></i>
            <span>{t('add_user')}</span>
          </button>
          <button className={styles.actionButton}>
            <i className="fas fa-box-open"></i>
            <span>{t('add_product')}</span>
          </button>
          <button className={styles.actionButton}>
            <i className="fas fa-file-alt"></i>
            <span>{t('create_agreement')}</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;