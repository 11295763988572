// src/components/KnowledgeTransfer/TemplateManager.js
import React from 'react';
import styles from './TemplateManager.module.css';

const TemplateManager = ({ templates, onTemplateSelect, onTemplateSave }) => {
  // Add a check to ensure templates is defined
  if (!templates) {
    return <div>Loading templates...</div>;
  }

  return (
    <div className={styles.templateManager}>
      <h3>Templates</h3>
      <select onChange={(e) => onTemplateSelect(e.target.value)}>
        <option value="">Select a template</option>
        {templates.map((template, index) => (
          <option key={index} value={index}>{template.name}</option>
        ))}
      </select>
      <button onClick={onTemplateSave}>Save Current as Template</button>
    </div>
  );
};

export default TemplateManager;