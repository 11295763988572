// File: frontend/src/pages/PropertyInsight/components/PropertySearchPanel/index.js

import React, { useState } from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import styles from './PropertySearchPanel.module.css';

const PropertySearchPanel = ({ onSearch, loading }) => {
  const { t } = useLanguage();
  const [address, setAddress] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (address.trim()) {
      onSearch({ field: 'address', query: address.trim() });
    }
  };

  const handleStartUploading = () => {
    // This would navigate to the bulk upload page
    window.location.href = '/property-bulk-upload';
  };

  return (
    <div className={styles.searchPanelsContainer}>
      <div className={styles.searchPanel}>
        <div className={styles.panelContent}>
          <div className={styles.panelHeader}>
            <div className={styles.iconContainer}>
              <img src="/icons/property-icon.svg" alt="" className={styles.panelIcon} />
            </div>
            <div className={styles.headerContent}>
              <h3 className={styles.panelTitle}>{t('get_insights_for_a_single_property')}</h3>
              <p className={styles.panelDescription}>{t('search_by_address_for_detailed_insights')}</p>
            </div>
          </div>
          
          <form onSubmit={handleSubmit} className={styles.searchForm}>
            <div className={styles.searchInputContainer}>
              <input
                type="text"
                className={styles.searchInput}
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                placeholder={t('enter_property_address')}
                disabled={loading}
              />
              <button 
                type="submit" 
                className={styles.searchButton}
                disabled={loading || !address.trim()}
              >
                {loading ? t('searching') : t('search')}
                <img src="/icons/search-icon.svg" alt="" className={styles.searchIcon} />
              </button>
            </div>
          </form>
        </div>
      </div>
      
      <div className={styles.searchPanel}>
        <div className={styles.panelContent}>
          <div className={styles.panelHeader}>
            <div className={styles.iconContainer}>
              <img src="/icons/properties-icon.svg" alt="" className={styles.panelIcon} />
            </div>
            <div className={styles.headerContent}>
              <h3 className={styles.panelTitle}>{t('get_insights_for_many_properties')}</h3>
              <p className={styles.panelDescription}>{t('upload_a_document_to_receive_comprehensive_insights_property')}</p>
            </div>
          </div>
          
          <button 
            className={styles.uploadButton}
            onClick={handleStartUploading}
          >
            {t('start_uploading_with_a_csv_file')}
            <img src="/icons/chevron-right-icon.svg" alt="" className={styles.chevronIcon} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default PropertySearchPanel;