// File: frontend/src/pages/DMS/DMS.js

import React from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import styles from './DMS.module.css';

const DMS = () => {
  const { t } = useLanguage();

  return <div><h1 className={styles.title}>{t('dms')}</h1></div>;
};

export default DMS;


