// File: frontend/src/hooks/useHeaderChatControls.js

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useHeaderChatControls = () => {
  const location = useLocation();

  // These will be passed down to the Header component
  const chatControls = {
    chatId: null,
    onNewChat: null,
    onShareChat: null,
    showHistoryButton: true // New control for history button visibility
  };

  const updateHeaderControls = (controls) => {
    chatControls.chatId = controls.chatId;
    chatControls.onNewChat = controls.onNewChat;
    chatControls.onShareChat = controls.onShareChat;
    chatControls.showHistoryButton = controls.showHistoryButton ?? true; // Default to false if not provided
  };

  // Reset controls when navigating away
  useEffect(() => {
    return () => {
      updateHeaderControls({
        chatId: null,
        onNewChat: null,
        onShareChat: null,
        showHistoryButton: true
      });
    };
  }, [location.pathname]);

  return { chatControls, updateHeaderControls };
};