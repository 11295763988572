// File: frontend/src/pages/PropertyDetail/components/BuildingSystems/index.js

import React from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import styles from './BuildingSystems.module.css';

// Helper function to format date
const formatDate = (dateString) => {
  if (!dateString) return 'N/A';
  
  const date = new Date(dateString);
  
  // Check if date is valid
  if (isNaN(date.getTime())) return 'Invalid Date';
  
  return date.toLocaleDateString();
};

const BuildingSystems = ({ property }) => {
  const { t } = useLanguage();

  if (!property.buildingSystems) {
    return (
      <div className={styles.noData}>
        {t('no_building_systems_information_available')}
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.grid}>
        <div className={styles.card}>
          <h3 className={styles.cardTitle}>{t('heating_system')}</h3>
          <div className={styles.cardContent}>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('heating_type')}:</div>
              <div className={styles.infoValue}>{property.buildingSystems.heating_type}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('primary_heating_system')}:</div>
              <div className={styles.infoValue}>{property.buildingSystems.primary_heating_system}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('primary_heating_fuel_type')}:</div>
              <div className={styles.infoValue}>{property.buildingSystems.primary_heating_fuel_type}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('installation_date')}:</div>
              <div className={styles.infoValue}>
                {formatDate(property.buildingSystems.primary_heating_installation_date)}
              </div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('approval_date')}:</div>
              <div className={styles.infoValue}>
                {formatDate(property.buildingSystems.primary_heating_approval_date)}
              </div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('oil_tank')}:</div>
              <div className={styles.infoValue}>
                {property.buildingSystems.primary_heating_oil_tank ? t('yes') : t('no')}
              </div>
            </div>
            {property.buildingSystems.primary_heating_oil_tank && (
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('oil_tank_installation_date')}:</div>
                <div className={styles.infoValue}>
                  {formatDate(property.buildingSystems.primary_heating_oil_tank_installation_date)}
                </div>
              </div>
            )}
          </div>
        </div>
        
        <div className={styles.card}>
          <h3 className={styles.cardTitle}>{t('cooling_system')}</h3>
          <div className={styles.cardContent}>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('cooling_type')}:</div>
              <div className={styles.infoValue}>{property.buildingSystems.cooling_type}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('cooling_system')}:</div>
              <div className={styles.infoValue}>{property.buildingSystems.cooling_system}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('system_type')}:</div>
              <div className={styles.infoValue}>{property.buildingSystems.system_type}</div>
            </div>
          </div>
        </div>
      </div>
      
      <div className={styles.card}>
        <h3 className={styles.cardTitle}>{t('secondary_heating')}</h3>
        <div className={styles.cardContent}>
          <div className={styles.secondaryGrid}>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('secondary_heat')}:</div>
              <div className={styles.infoValue}>{property.buildingSystems.secondary_heat}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('secondary_heat_approved')}:</div>
              <div className={styles.infoValue}>
                {property.buildingSystems.secondary_heat_approved ? t('yes') : t('no')}
              </div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('secondary_heat_date')}:</div>
              <div className={styles.infoValue}>
                {formatDate(property.buildingSystems.secondary_heat_date)}
              </div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('secondary_heat_oil_tank')}:</div>
              <div className={styles.infoValue}>
                {property.buildingSystems.secondary_heat_oil_tank ? t('yes') : t('no')}
              </div>
            </div>
            {property.buildingSystems.secondary_heat_oil_tank && (
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('secondary_heat_oil_tank_date')}:</div>
                <div className={styles.infoValue}>
                  {formatDate(property.buildingSystems.secondary_heat_oil_tank_date)}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      
      {property.safetyFeatures && (
        <div className={styles.card}>
          <h3 className={styles.cardTitle}>{t('water_systems')}</h3>
          <div className={styles.cardContent}>
            <div className={styles.waterGrid}>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('water_leak_detection_system')}:</div>
                <div className={styles.infoValue}>
                  {property.safetyFeatures.water_leak_detection_system ? t('yes') : t('no')}
                </div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('sump_pump_type')}:</div>
                <div className={styles.infoValue}>{property.safetyFeatures.sump_pump_type}</div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('sump_pump_aux_power')}:</div>
                <div className={styles.infoValue}>
                  {property.safetyFeatures.sump_pump_aux_power ? t('yes') : t('no')}
                </div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('back_flow_valve')}:</div>
                <div className={styles.infoValue}>
                  {property.safetyFeatures.back_flow_valve ? t('yes') : t('no')}
                </div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('has_sump_pump_pit')}:</div>
                <div className={styles.infoValue}>
                  {property.safetyFeatures.has_sump_pump_pit ? t('yes') : t('no')}
                </div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('is_sump_pump_alarmed')}:</div>
                <div className={styles.infoValue}>
                  {property.safetyFeatures.is_sump_pump_alarmed ? t('yes') : t('no')}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      
      <div className={styles.systemDiagram}>
        <h3 className={styles.diagramTitle}>{t('building_systems_diagram')}</h3>
        <div className={styles.diagramContent}>
          <div className={styles.diagramPlaceholder}>
            {t('building_systems_diagram_not_available')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuildingSystems;