// File: frontend/src/pages/TripInsight/components/TripMaps/index.js

import React, { useState, useEffect, useRef } from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import styles from './TripMaps.module.css';

const TripMaps = () => {
  const { t } = useLanguage();
  const [activeMap, setActiveMap] = useState('provinces');
  const [mapDimensions, setMapDimensions] = useState({ width: 0, height: 500 });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [mapData, setMapData] = useState(null);
  const mapContainerRef = useRef(null);

  // Filter states
  const [tripStatus, setTripStatus] = useState('all'); // 'all', 'ongoing', 'completed'
  const [timeRange, setTimeRange] = useState('month'); // 'week', 'month', 'year', 'all'
  const [showAccidents, setShowAccidents] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (mapContainerRef.current) {
        const { width } = mapContainerRef.current.getBoundingClientRect();
        setMapDimensions({ width, height: 500 });
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchMapData = async () => {
      setLoading(true);
      setError(null);
      
      try {
        // This would be replaced with actual API calls based on the active map type
        // const response = await mapService.getMapData(activeMap, tripStatus, timeRange, showAccidents);
        
        // Mock data loading delay
        await new Promise(resolve => setTimeout(resolve, 1000));
        
        // Mock data - in a real implementation, this would be GeoJSON data
        const mockData = {
          type: 'FeatureCollection',
          features: [
            // Features would be populated based on the active map type
          ]
        };
        
        setMapData(mockData);
      } catch (err) {
        console.error('Error fetching map data:', err);
        setError(t('failed_to_load_map_data'));
      } finally {
        setLoading(false);
      }
    };

    fetchMapData();
  }, [activeMap, tripStatus, timeRange, showAccidents, t]);

  const handleMapTypeChange = (mapType) => {
    setActiveMap(mapType);
  };

  const handleTripStatusChange = (status) => {
    setTripStatus(status);
  };

  const handleTimeRangeChange = (range) => {
    setTimeRange(range);
  };

  const toggleAccidents = () => {
    setShowAccidents(!showAccidents);
  };

  // This would be replaced with an actual map rendering component
  const renderMap = () => {
    if (loading) {
      return (
        <div className={styles.mapLoading}>
          <div className={styles.loadingSpinner}></div>
          <p>{t('loading_map_data')}</p>
        </div>
      );
    }

    if (error) {
      return (
        <div className={styles.mapError}>
          <p>{error}</p>
        </div>
      );
    }

    // In a real implementation, this would render a map using a library like Mapbox, Leaflet, or Google Maps
    return (
      <div className={styles.mockMap}>
        <div className={styles.mockMapContent}>
          <div className={styles.mockMapText}>
            {t('map_would_be_displayed_here')}
            <div className={styles.mockMapSubtext}>
              {t('showing')}: {t(activeMap)} | {t('status')}: {t(tripStatus)} | {t('time')}: {t(timeRange)}
              {showAccidents && ` | ${t('showing_accidents')}`}
            </div>
          </div>
          
          {activeMap === 'provinces' && (
            <div className={styles.mockProvinces}>
              <div className={styles.mockProvince} style={{ top: '30%', left: '20%' }}>
                <div className={styles.mockProvinceLabel}>Ontario</div>
                <div className={styles.mockProvinceCount}>1,245 trips</div>
              </div>
              <div className={styles.mockProvince} style={{ top: '40%', left: '40%' }}>
                <div className={styles.mockProvinceLabel}>Quebec</div>
                <div className={styles.mockProvinceCount}>987 trips</div>
              </div>
              <div className={styles.mockProvince} style={{ top: '60%', left: '30%' }}>
                <div className={styles.mockProvinceLabel}>New York</div>
                <div className={styles.mockProvinceCount}>1,876 trips</div>
              </div>
            </div>
          )}
          
          {activeMap === 'coordinates' && (
            <div className={styles.mockPoints}>
              <div className={styles.mockPoint} style={{ top: '25%', left: '30%' }}></div>
              <div className={styles.mockPoint} style={{ top: '35%', left: '40%' }}></div>
              <div className={styles.mockPoint} style={{ top: '45%', left: '25%' }}></div>
              <div className={styles.mockPoint} style={{ top: '55%', left: '35%' }}></div>
              <div className={styles.mockPoint} style={{ top: '65%', left: '45%' }}></div>
              <div className={styles.mockPoint} style={{ top: '30%', left: '50%' }}></div>
              <div className={styles.mockPoint} style={{ top: '40%', left: '60%' }}></div>
              <div className={styles.mockPoint} style={{ top: '50%', left: '55%' }}></div>
              <div className={styles.mockPoint} style={{ top: '60%', left: '65%' }}></div>
              <div className={styles.mockPoint} style={{ top: '70%', left: '70%' }}></div>
            </div>
          )}
          
          {activeMap === 'heatmap' && (
            <div className={styles.mockHeatmap}>
              <div className={styles.mockHeatArea} style={{ top: '30%', left: '30%', width: '100px', height: '100px', opacity: 0.8 }}></div>
              <div className={styles.mockHeatArea} style={{ top: '50%', left: '50%', width: '150px', height: '150px', opacity: 0.6 }}></div>
              <div className={styles.mockHeatArea} style={{ top: '40%', left: '60%', width: '80px', height: '80px', opacity: 0.9 }}></div>
            </div>
          )}
          
          {showAccidents && (
            <div className={styles.mockAccidents}>
              <div className={styles.mockAccident} style={{ top: '35%', left: '35%' }}></div>
              <div className={styles.mockAccident} style={{ top: '55%', left: '55%' }}></div>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2 className={styles.title}>{t('trip_maps')}</h2>
        <p className={styles.description}>
          {t('trip_maps_description')}
        </p>
      </div>
      
      <div className={styles.content}>
        <div className={styles.mapTypeSelector}>
          <div 
            className={`${styles.mapTypeOption} ${activeMap === 'provinces' ? styles.active : ''}`}
            onClick={() => handleMapTypeChange('provinces')}
          >
            <div className={styles.mapTypeIcon}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                <circle cx="12" cy="10" r="3"></circle>
              </svg>
            </div>
            <div className={styles.mapTypeContent}>
              <div className={styles.mapTypeTitle}>{t('show_by_province')}</div>
              <div className={styles.mapTypeDescription}>{t('province_map_description')}</div>
            </div>
          </div>
          
          <div 
            className={`${styles.mapTypeOption} ${activeMap === 'coordinates' ? styles.active : ''}`}
            onClick={() => handleMapTypeChange('coordinates')}
          >
            <div className={styles.mapTypeIcon}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <circle cx="12" cy="12" r="10"></circle>
                <line x1="2" y1="12" x2="22" y2="12"></line>
                <line x1="12" y1="2" x2="12" y2="22"></line>
              </svg>
            </div>
            <div className={styles.mapTypeContent}>
              <div className={styles.mapTypeTitle}>{t('show_by_coordinates')}</div>
              <div className={styles.mapTypeDescription}>{t('coordinates_map_description')}</div>
            </div>
          </div>
          
          <div 
            className={`${styles.mapTypeOption} ${activeMap === 'heatmap' ? styles.active : ''}`}
            onClick={() => handleMapTypeChange('heatmap')}
          >
            <div className={styles.mapTypeIcon}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                <circle cx="8.5" cy="8.5" r="1.5"></circle>
                <circle cx="15.5" cy="8.5" r="1.5"></circle>
                <circle cx="15.5" cy="15.5" r="1.5"></circle>
                <circle cx="8.5" cy="15.5" r="1.5"></circle>
              </svg>
            </div>
            <div className={styles.mapTypeContent}>
              <div className={styles.mapTypeTitle}>{t('show_heatmap')}</div>
              <div className={styles.mapTypeDescription}>{t('heatmap_description')}</div>
            </div>
          </div>
        </div>
        
        <div className={styles.mapFilters}>
          <div className={styles.filterGroup}>
            <div className={styles.filterLabel}>{t('trip_status')}:</div>
            <div className={styles.filterOptions}>
              <button 
                className={`${styles.filterOption} ${tripStatus === 'all' ? styles.activeFilter : ''}`}
                onClick={() => handleTripStatusChange('all')}
              >
                {t('all')}
              </button>
              <button 
                className={`${styles.filterOption} ${tripStatus === 'ongoing' ? styles.activeFilter : ''}`}
                onClick={() => handleTripStatusChange('ongoing')}
              >
                {t('ongoing')}
              </button>
              <button 
                className={`${styles.filterOption} ${tripStatus === 'completed' ? styles.activeFilter : ''}`}
                onClick={() => handleTripStatusChange('completed')}
              >
                {t('completed')}
              </button>
            </div>
          </div>
          
          <div className={styles.filterGroup}>
            <div className={styles.filterLabel}>{t('time_range')}:</div>
            <div className={styles.filterOptions}>
              <button 
                className={`${styles.filterOption} ${timeRange === 'week' ? styles.activeFilter : ''}`}
                onClick={() => handleTimeRangeChange('week')}
              >
                {t('week')}
              </button>
              <button 
                className={`${styles.filterOption} ${timeRange === 'month' ? styles.activeFilter : ''}`}
                onClick={() => handleTimeRangeChange('month')}
              >
                {t('month')}
              </button>
              <button 
                className={`${styles.filterOption} ${timeRange === 'year' ? styles.activeFilter : ''}`}
                onClick={() => handleTimeRangeChange('year')}
              >
                {t('year')}
              </button>
              <button 
                className={`${styles.filterOption} ${timeRange === 'all' ? styles.activeFilter : ''}`}
                onClick={() => handleTimeRangeChange('all')}
              >
                {t('all_time')}
              </button>
            </div>
          </div>
          
          <div className={styles.filterGroup}>
            <label className={styles.checkboxLabel}>
              <input 
                type="checkbox" 
                checked={showAccidents} 
                onChange={toggleAccidents}
                className={styles.checkbox}
              />
              {t('show_accidents')}
            </label>
          </div>
        </div>
        
        <div className={styles.mapContainer} ref={mapContainerRef}>
          {renderMap()}
        </div>
        
        <div className={styles.mapLegend}>
          <div className={styles.legendTitle}>{t('legend')}</div>
          <div className={styles.legendItems}>
            <div className={styles.legendItem}>
              <div className={`${styles.legendColor} ${styles.highDensity}`}></div>
              <div className={styles.legendLabel}>{t('high_trip_density')}</div>
            </div>
            <div className={styles.legendItem}>
              <div className={`${styles.legendColor} ${styles.mediumDensity}`}></div>
              <div className={styles.legendLabel}>{t('medium_trip_density')}</div>
            </div>
            <div className={styles.legendItem}>
              <div className={`${styles.legendColor} ${styles.lowDensity}`}></div>
              <div className={styles.legendLabel}>{t('low_trip_density')}</div>
            </div>
            {showAccidents && (
              <div className={styles.legendItem}>
                <div className={`${styles.legendColor} ${styles.accident}`}></div>
                <div className={styles.legendLabel}>{t('accident')}</div>
              </div>
            )}
          </div>
        </div>
      </div>
      
      <div className={styles.mapStats}>
        <div className={styles.statCard}>
          <div className={styles.statValue}>28,750</div>
          <div className={styles.statLabel}>{t('total_trips')}</div>
        </div>
        <div className={styles.statCard}>
          <div className={styles.statValue}>125</div>
          <div className={styles.statLabel}>{t('ongoing_trips')}</div>
        </div>
        <div className={styles.statCard}>
          <div className={styles.statValue}>28,625</div>
          <div className={styles.statLabel}>{t('completed_trips')}</div>
        </div>
        <div className={styles.statCard}>
          <div className={styles.statValue}>42</div>
          <div className={styles.statLabel}>{t('accidents')}</div>
        </div>
      </div>
    </div>
  );
};

export default TripMaps;