// File: frontend/src/pages/Trips/Trips.js

import React from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import PageTitle from '../../components/layout/DashboardLayout/PageTitle';
import TripDetailsContainer from '../../components/TripDetails/TripDetailsContainer';
import styles from './Trips.module.css';

const Trips = () => {
  const { t } = useLanguage();

  return (
      <div className={styles.content}>
        <PageTitle title={t('trip_details')} />
        <TripDetailsContainer />
      </div>
  );
};

export default Trips;