// File: frontend/src/pages/Status/components/ServicesList.js

import React from 'react';
import styles from './ServicesList.module.css';

const ServicesList = ({ services, isLoading }) => {
  if (isLoading) {
    return <div className={styles.loading}>Loading services...</div>;
  }

  if (!services?.length) {
    return <div className={styles.empty}>No services available</div>;
  }

  const getStatusClass = (status) => {
    switch (status?.toUpperCase()) {
      case 'OK':
        return styles.statusOk;
      case 'DEGRADED':
        return styles.statusDegraded;
      case 'ERROR':
        return styles.statusError;
      default:
        return styles.statusUnknown;
    }
  };

  const formatLatency = (latency) => {
    if (!latency && latency !== 0) return 'N/A';
    return `${Math.round(latency)}ms`;
  };

  const renderServiceDetails = (service) => {
    if (!service.details) return null;

    return (
      <div className={styles.details}>
        {Object.entries(service.details).map(([key, value]) => {
          if (key === 'latency') return null; // Skip latency as it's shown in the main row
          if (typeof value === 'object') {
            return (
              <div key={key} className={styles.detailItem}>
                <span className={styles.detailLabel}>{key}:</span>
                <span className={styles.detailValue}>
                  {value.status ? (
                    <span className={getStatusClass(value.status)}>
                      {value.status}
                    </span>
                  ) : (
                    JSON.stringify(value)
                  )}
                </span>
              </div>
            );
          }
          return (
            <div key={key} className={styles.detailItem}>
              <span className={styles.detailLabel}>{key}:</span>
              <span className={styles.detailValue}>{value}</span>
            </div>
          );
        })}
      </div>
    );
  };

  const renderAdditionalInfo = (service) => {
    if (!service.additionalInfo) return null;

    return (
      <div className={styles.additionalInfo}>
        {service.additionalInfo.quotas && (
          <div className={styles.quotas}>
            <div className={styles.quotaItem}>
              <span>Daily Quota:</span>
              <span>{service.additionalInfo.quotas.daily}</span>
            </div>
            <div className={styles.quotaItem}>
              <span>Rate:</span>
              <span>{service.additionalInfo.quotas.rate}/sec</span>
            </div>
            <div className={styles.quotaItem}>
              <span>Used:</span>
              <span>{service.additionalInfo.quotas.used}</span>
            </div>
          </div>
        )}
        {service.additionalInfo.topicCount !== undefined && (
          <div className={styles.infoItem}>
            <span>Topics:</span>
            <span>{service.additionalInfo.topicCount}</span>
          </div>
        )}
        {service.additionalInfo.webAclName && (
          <div className={styles.infoItem}>
            <span>Web ACL:</span>
            <span>{service.additionalInfo.webAclName}</span>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Service Status</h2>
      <div className={styles.servicesList}>
        {services.map((service) => (
          <div key={service.name} className={styles.serviceCard}>
            <div className={styles.serviceHeader}>
              <div className={styles.serviceName}>
                <span className={styles.displayName}>{service.displayName}</span>
                <span className={`${styles.status} ${getStatusClass(service.status)}`}>
                  {service.status}
                </span>
              </div>
              <div className={styles.latency}>
                <span className={styles.latencyLabel}>Latency:</span>
                <span className={styles.latencyValue}>
                  {formatLatency(service.latency)}
                </span>
              </div>
            </div>
            {renderServiceDetails(service)}
            {renderAdditionalInfo(service)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServicesList;