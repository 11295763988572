// File: frontend/src/pages/SuperAdmin/components/AgreementManagement/index.js

import React, { useState } from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import AgreementList from './AgreementList';
import AgreementDetail from './AgreementDetail';
import AgreementCreate from './AgreementCreate';
import styles from './AgreementManagement.module.css';

const AgreementManagement = ({ updateBreadcrumbs }) => {
  const { t } = useLanguage();
  const [view, setView] = useState('list');
  const [selectedAgreementId, setSelectedAgreementId] = useState(null);

  // Handle view changes
  const handleViewChange = (newView, agreementId = null) => {
    setView(newView);
    setSelectedAgreementId(agreementId);
    
    // Update breadcrumbs based on view
    const baseBreadcrumbs = [
      { label: 'Dashboard', path: '/super-admin' },
      { label: 'Agreement Management', path: '/super-admin/agreements' }
    ];
    
    switch (newView) {
      case 'detail':
        updateBreadcrumbs([
          ...baseBreadcrumbs,
          { label: 'Agreement Details', path: `/super-admin/agreements/${agreementId}` }
        ]);
        break;
      case 'create':
        updateBreadcrumbs([
          ...baseBreadcrumbs,
          { label: 'Create Agreement', path: '/super-admin/agreements/create' }
        ]);
        break;
      case 'edit':
        updateBreadcrumbs([
          ...baseBreadcrumbs,
          { 
            label: 'Agreement Details', 
            path: `/super-admin/agreements/${agreementId}` 
          },
          { 
            label: 'Edit Agreement', 
            path: `/super-admin/agreements/${agreementId}/edit` 
          }
        ]);
        break;
      default:
        updateBreadcrumbs(baseBreadcrumbs);
    }
  };

  // Render the appropriate view
  const renderView = () => {
    switch (view) {
      case 'detail':
        return (
          <AgreementDetail 
            agreementId={selectedAgreementId} 
            onBack={() => handleViewChange('list')}
            onEdit={() => handleViewChange('edit', selectedAgreementId)}
          />
        );
      case 'create':
        return (
          <AgreementCreate 
            onBack={() => handleViewChange('list')}
            onSuccess={(agreementId) => handleViewChange('detail', agreementId)}
          />
        );
      case 'edit':
        return (
          <AgreementDetail 
            agreementId={selectedAgreementId} 
            isEditing={true}
            onBack={() => handleViewChange('detail', selectedAgreementId)}
            onSave={() => handleViewChange('detail', selectedAgreementId)}
          />
        );
      default:
        return (
          <AgreementList 
            onViewDetail={(agreementId) => handleViewChange('detail', agreementId)}
            onCreateNew={() => handleViewChange('create')}
          />
        );
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>
        {view === 'list' && t('agreement_management')}
        {view === 'detail' && t('agreement_details')}
        {view === 'create' && t('create_agreement')}
        {view === 'edit' && t('edit_agreement')}
      </h1>
      
      {renderView()}
    </div>
  );
};

export default AgreementManagement;