// File: frontend/src/pages/TripInsight/components/TripDistributionChart/index.js

import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import styles from './TripDistributionChart.module.css';

const TripDistributionChart = () => {
  const { t } = useLanguage();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [distributionData, setDistributionData] = useState([]);
  const [displayMode, setDisplayMode] = useState('absolute'); // 'absolute' or 'percentage'
  const [timeFrame, setTimeFrame] = useState('daily'); // 'daily', 'weekly', 'monthly'

  useEffect(() => {
    const fetchDistributionData = async () => {
      setLoading(true);
      setError(null);
      
      try {
        // This would be replaced with an actual API call
        // const response = await tripService.getTripDistribution(timeFrame);
        
        // Mock data for now
        await new Promise(resolve => setTimeout(resolve, 800));
        
        // Generate mock data based on timeFrame
        const mockData = [];
        let totalTrips = 0;
        
        if (timeFrame === 'daily') {
          // Generate data for the last 7 days
          const today = new Date();
          for (let i = 6; i >= 0; i--) {
            const date = new Date(today);
            date.setDate(today.getDate() - i);
            const count = Math.floor(Math.random() * 50) + 20; // Random count between 20 and 70
            mockData.push({ 
              label: date.toLocaleDateString('en-US', { weekday: 'short' }),
              date: date.toISOString().split('T')[0],
              count 
            });
            totalTrips += count;
          }
        } else if (timeFrame === 'weekly') {
          // Generate data for the last 4 weeks
          for (let i = 4; i >= 1; i--) {
            const count = Math.floor(Math.random() * 200) + 100; // Random count between 100 and 300
            mockData.push({ 
              label: `Week ${i}`,
              date: `Week ${i}`,
              count 
            });
            totalTrips += count;
          }
        } else if (timeFrame === 'monthly') {
          // Generate data for the last 6 months
          const today = new Date();
          for (let i = 5; i >= 0; i--) {
            const date = new Date(today);
            date.setMonth(today.getMonth() - i);
            const count = Math.floor(Math.random() * 500) + 300; // Random count between 300 and 800
            mockData.push({ 
              label: date.toLocaleDateString('en-US', { month: 'short' }),
              date: `${date.getFullYear()}-${date.getMonth() + 1}`,
              count 
            });
            totalTrips += count;
          }
        }
        
        // Add percentage to each data point
        mockData.forEach(item => {
          item.percentage = (item.count / totalTrips) * 100;
        });
        
        setDistributionData(mockData);
      } catch (err) {
        console.error('Error fetching trip distribution data:', err);
        setError(t('failed_to_load_distribution_data'));
      } finally {
        setLoading(false);
      }
    };
    
    fetchDistributionData();
  }, [t, timeFrame]);

  // Find the maximum value for scaling the bars
  const maxValue = displayMode === 'absolute'
    ? Math.max(...distributionData.map(item => item.count))
    : Math.max(...distributionData.map(item => item.percentage));

  const handleTimeFrameChange = (newTimeFrame) => {
    setTimeFrame(newTimeFrame);
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <div className={styles.loadingSpinner}></div>
        <p>{t('loading_distribution_data')}</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.error}>
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3 className={styles.title}>{t('trip_distribution')}</h3>
        <div className={styles.controls}>
          <div className={styles.timeFrameToggle}>
            <button 
              className={`${styles.toggleButton} ${timeFrame === 'daily' ? styles.activeToggle : ''}`}
              onClick={() => handleTimeFrameChange('daily')}
            >
              {t('daily')}
            </button>
            <button 
              className={`${styles.toggleButton} ${timeFrame === 'weekly' ? styles.activeToggle : ''}`}
              onClick={() => handleTimeFrameChange('weekly')}
            >
              {t('weekly')}
            </button>
            <button 
              className={`${styles.toggleButton} ${timeFrame === 'monthly' ? styles.activeToggle : ''}`}
              onClick={() => handleTimeFrameChange('monthly')}
            >
              {t('monthly')}
            </button>
          </div>
          <div className={styles.displayToggle}>
            <button 
              className={`${styles.toggleButton} ${displayMode === 'absolute' ? styles.activeToggle : ''}`}
              onClick={() => setDisplayMode('absolute')}
            >
              {t('absolute')}
            </button>
            <button 
              className={`${styles.toggleButton} ${displayMode === 'percentage' ? styles.activeToggle : ''}`}
              onClick={() => setDisplayMode('percentage')}
            >
              {t('percentage')}
            </button>
          </div>
        </div>
      </div>
      
      <div className={styles.chartContainer}>
        <div className={styles.yAxis}>
          <div className={styles.yAxisLabel}>
            {displayMode === 'absolute' ? t('number_of_trips') : t('percentage')}
          </div>
        </div>
        <div className={styles.chart}>
          {distributionData.map((item) => {
            const value = displayMode === 'absolute' ? item.count : item.percentage;
            const height = (value / maxValue) * 100;
            
            return (
              <div key={item.date} className={styles.barContainer}>
                <div className={styles.barLabel}>
                  {displayMode === 'absolute' 
                    ? item.count 
                    : `${item.percentage.toFixed(1)}%`}
                </div>
                <div 
                  className={styles.bar} 
                  style={{ height: `${height}%` }}
                  title={`${item.label}: ${displayMode === 'absolute' 
                    ? `${item.count} ${t('trips')}` 
                    : `${item.percentage.toFixed(1)}%`}`}
                ></div>
                <div className={styles.dateLabel}>{item.label}</div>
              </div>
            );
          })}
        </div>
      </div>
      
      <div className={styles.totalTrips}>
        {t('total_trips')}: {distributionData.reduce((sum, item) => sum + item.count, 0)}
      </div>
    </div>
  );
};

export default TripDistributionChart;