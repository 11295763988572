// File: frontend/src/pages/SuperAdmin/components/CommunicationManagement/CommunicationLogs/index.js

import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import SuperAdminTable from '../../common/SuperAdminTable';
import SuperAdminSearch from '../../common/SuperAdminSearch';
import SuperAdminPagination from '../../common/SuperAdminPagination';
import styles from './CommunicationLogs.module.css';

const CommunicationLogs = ({ onViewChange }) => {
  const { t } = useLanguage();
  const [logs, setLogs] = useState([]);
  const [selectedLog, setSelectedLog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterType, setFilterType] = useState('all');
  const [filterStatus, setFilterStatus] = useState('all');
  const [showDetail, setShowDetail] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: '',
    endDate: ''
  });

  useEffect(() => {
    const fetchCommunicationLogs = async () => {
      setLoading(true);
      setError(null);
      
      try {
        // This would be replaced with an actual API call
        // const response = await communicationService.listCommunicationLogs({
        //   page: currentPage,
        //   search: searchTerm,
        //   type: filterType !== 'all' ? filterType : undefined,
        //   status: filterStatus !== 'all' ? filterStatus : undefined,
        //   startDate: dateRange.startDate,
        //   endDate: dateRange.endDate
        // });
        
        // Mock data for now
        await new Promise(resolve => setTimeout(resolve, 800));
        
        const mockLogs = [
          {
            log_id: 'comm-12345',
            enterprise_id: 'ent-12345',
            communication_type: 'email',
            template_id: 'email-12345',
            template_name: 'Welcome Email',
            recipient: 'john.doe@example.com',
            subject: 'Welcome to Acme Corporation!',
            content: 'Welcome, John! Thank you for joining Acme Corporation. We\'re excited to have you on board.',
            status: 'sent',
            sent_at: '2023-07-15T10:30:00Z',
            error_message: null,
            created_by: 'system',
            enterprise_name: 'Acme Corporation'
          },
          {
            log_id: 'comm-23456',
            enterprise_id: 'ent-12345',
            communication_type: 'sms',
            template_id: 'sms-12345',
            template_name: 'Verification Code',
            recipient: '+14155552671',
            subject: null,
            content: 'Your verification code is 123456. It expires in 10 minutes.',
            status: 'sent',
            sent_at: '2023-07-15T11:45:00Z',
            error_message: null,
            created_by: 'system',
            enterprise_name: 'Acme Corporation'
          },
          {
            log_id: 'comm-34567',
            enterprise_id: 'ent-23456',
            communication_type: 'email',
            template_id: 'email-23456',
            template_name: 'Password Reset',
            recipient: 'jane.smith@example.com',
            subject: 'Reset Your Global Industries Password',
            content: 'Hello Jane, Click the link below to reset your password: https://example.com/reset/token123',
            status: 'sent',
            sent_at: '2023-07-14T16:20:00Z',
            error_message: null,
            created_by: 'admin-user-1',
            enterprise_name: 'Global Industries'
          },
          {
            log_id: 'comm-45678',
            enterprise_id: 'ent-34567',
            communication_type: 'email',
            template_id: 'email-34567',
            template_name: 'Subscription Renewal',
            recipient: 'bob.johnson@example.com',
            subject: 'Your Tech Solutions Subscription is Renewing Soon',
            content: 'Hello Bob, Your subscription will renew on 2023-08-15. The amount of $99.99 will be charged to your payment method on file.',
            status: 'failed',
            sent_at: '2023-07-14T14:10:00Z',
            error_message: 'Invalid email address',
            created_by: 'system',
            enterprise_name: 'Tech Solutions'
          },
          {
            log_id: 'comm-56789',
            enterprise_id: 'ent-45678',
            communication_type: 'sms',
            template_id: 'sms-23456',
            template_name: 'Appointment Reminder',
            recipient: '+14155553456',
            subject: null,
            content: 'Reminder: You have an appointment scheduled for 2023-07-20 at 10:00 AM. Reply Y to confirm or N to reschedule.',
            status: 'sent',
            sent_at: '2023-07-13T09:15:00Z',
            error_message: null,
            created_by: 'admin-user-2',
            enterprise_name: 'Innovative Systems'
          }
        ];
        
        // Filter based on search term, type, status, and date range
        let filteredLogs = mockLogs;
        
        if (searchTerm) {
          const searchLower = searchTerm.toLowerCase();
          filteredLogs = filteredLogs.filter(log => 
            log.recipient.toLowerCase().includes(searchLower) ||
            (log.subject && log.subject.toLowerCase().includes(searchLower)) ||
            log.content.toLowerCase().includes(searchLower) ||
            log.enterprise_name.toLowerCase().includes(searchLower)
          );
        }
        
        if (filterType !== 'all') {
          filteredLogs = filteredLogs.filter(log => 
            log.communication_type === filterType
          );
        }
        
        if (filterStatus !== 'all') {
          filteredLogs = filteredLogs.filter(log => 
            log.status === filterStatus
          );
        }
        
        if (dateRange.startDate) {
          const startDate = new Date(dateRange.startDate);
          filteredLogs = filteredLogs.filter(log => 
            new Date(log.sent_at) >= startDate
          );
        }
        
        if (dateRange.endDate) {
          const endDate = new Date(dateRange.endDate);
          endDate.setHours(23, 59, 59, 999); // End of day
          filteredLogs = filteredLogs.filter(log => 
            new Date(log.sent_at) <= endDate
          );
        }
        setLogs(filteredLogs);
        setTotalPages(Math.ceil(filteredLogs.length / 10)); // Assuming 10 items per page
      } catch (err) {
        console.error('Error fetching communication logs:', err);
        setError(t('failed_to_load_communication_logs'));
      } finally {
        setLoading(false);
      }
    };
    
    fetchCommunicationLogs();
  }, [currentPage, searchTerm, filterType, filterStatus, dateRange, t]);

  const handleSearch = (term) => {
    setSearchTerm(term);
    setCurrentPage(1);
  };

  const handleTypeFilterChange = (e) => {
    setFilterType(e.target.value);
    setCurrentPage(1);
  };

  const handleStatusFilterChange = (e) => {
    setFilterStatus(e.target.value);
    setCurrentPage(1);
  };

  const handleDateRangeChange = (e) => {
    const { name, value } = e.target;
    setDateRange(prev => ({
      ...prev,
      [name]: value
    }));
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleViewDetail = (log) => {
    setSelectedLog(log);
    setShowDetail(true);
  };

  const handleCloseDetail = () => {
    setShowDetail(false);
    setSelectedLog(null);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    }).format(date);
  };

  const getStatusBadgeClass = (status) => {
    switch (status) {
      case 'sent':
        return styles.statusSent;
      case 'failed':
        return styles.statusFailed;
      case 'pending':
        return styles.statusPending;
      default:
        return '';
    }
  };

  const getTypeIconClass = (type) => {
    switch (type) {
      case 'email':
        return 'fa-envelope';
      case 'sms':
        return 'fa-sms';
      default:
        return 'fa-comment';
    }
  };

  const columns = [
    {
      header: t('type'),
      accessor: 'communication_type',
      cell: (row) => (
        <div className={styles.typeCell}>
          <i className={`fas ${getTypeIconClass(row.communication_type)}`}></i>
          <span>{row.communication_type}</span>
        </div>
      )
    },
    {
      header: t('recipient'),
      accessor: 'recipient',
      cell: (row) => <span className={styles.recipientCell}>{row.recipient}</span>
    },
    {
      header: t('template'),
      accessor: 'template_name',
      cell: (row) => <span className={styles.templateCell}>{row.template_name}</span>
    },
    {
      header: t('enterprise'),
      accessor: 'enterprise_name',
      cell: (row) => <span>{row.enterprise_name}</span>
    },
    {
      header: t('status'),
      accessor: 'status',
      cell: (row) => (
        <span className={`${styles.statusBadge} ${getStatusBadgeClass(row.status)}`}>
          {row.status}
        </span>
      )
    },
    {
      header: t('sent_at'),
      accessor: 'sent_at',
      cell: (row) => <span>{formatDate(row.sent_at)}</span>
    },
    {
      header: t('actions'),
      accessor: 'actions',
      cell: (row) => (
        <div className={styles.actions}>
          <button 
            className={styles.actionButton}
            onClick={() => handleViewDetail(row)}
          >
            <i className="fas fa-eye"></i>
            <span>{t('view')}</span>
          </button>
        </div>
      )
    }
  ];

  return (
    <div className={styles.container}>
      <div className={styles.toolbar}>
        <div className={styles.filters}>
          <SuperAdminSearch 
            placeholder={t('search_communication_logs')}
            onSearch={handleSearch}
          />
          
          <div className={styles.filterGroup}>
            <label htmlFor="typeFilter" className={styles.filterLabel}>
              {t('type')}:
            </label>
            <select
              id="typeFilter"
              className={styles.filterSelect}
              value={filterType}
              onChange={handleTypeFilterChange}
            >
              <option value="all">{t('all_types')}</option>
              <option value="email">{t('email')}</option>
              <option value="sms">{t('sms')}</option>
            </select>
          </div>
          
          <div className={styles.filterGroup}>
            <label htmlFor="statusFilter" className={styles.filterLabel}>
              {t('status')}:
            </label>
            <select
              id="statusFilter"
              className={styles.filterSelect}
              value={filterStatus}
              onChange={handleStatusFilterChange}
            >
              <option value="all">{t('all_statuses')}</option>
              <option value="sent">{t('sent')}</option>
              <option value="failed">{t('failed')}</option>
              <option value="pending">{t('pending')}</option>
            </select>
            </div>
          
          <div className={styles.dateRangeFilters}>
            <div className={styles.filterGroup}>
              <label htmlFor="startDate" className={styles.filterLabel}>
                {t('from')}:
              </label>
              <input
                type="date"
                id="startDate"
                name="startDate"
                className={styles.dateInput}
                value={dateRange.startDate}
                onChange={handleDateRangeChange}
              />
            </div>
            
            <div className={styles.filterGroup}>
              <label htmlFor="endDate" className={styles.filterLabel}>
                {t('to')}:
              </label>
              <input
                type="date"
                id="endDate"
                name="endDate"
                className={styles.dateInput}
                value={dateRange.endDate}
                onChange={handleDateRangeChange}
              />
            </div>
          </div>
        </div>
        
        <button 
          className={styles.exportButton}
          onClick={() => console.log('Export logs')}
        >
          <i className="fas fa-download"></i>
          <span>{t('export')}</span>
        </button>
      </div>
      
      {error && <div className={styles.error}>{error}</div>}
      
      <SuperAdminTable
        columns={columns}
        data={logs}
        loading={loading}
        emptyMessage={t('no_communication_logs_found')}
      />
      
      <div className={styles.pagination}>
        <SuperAdminPagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
      
      {showDetail && selectedLog && (
        <div className={styles.modalOverlay}>
          <div className={styles.modal}>
            <div className={styles.modalHeader}>
              <h3 className={styles.modalTitle}>
                {selectedLog.communication_type === 'email' ? t('email_details') : t('sms_details')}
              </h3>
              <button className={styles.closeButton} onClick={handleCloseDetail}>
                <i className="fas fa-times"></i>
              </button>
            </div>
            
            <div className={styles.modalContent}>
              <div className={styles.detailSection}>
                <div className={styles.detailHeader}>
                  <div className={styles.detailType}>
                    <i className={`fas ${getTypeIconClass(selectedLog.communication_type)}`}></i>
                    <span>{selectedLog.communication_type}</span>
                  </div>
                  <span className={`${styles.statusBadge} ${getStatusBadgeClass(selectedLog.status)}`}>
                    {selectedLog.status}
                  </span>
                </div>
                
                <div className={styles.detailGrid}>
                  <div className={styles.detailItem}>
                    <div className={styles.detailLabel}>{t('log_id')}</div>
                    <div className={styles.detailValue}>{selectedLog.log_id}</div>
                  </div>
                  
                  <div className={styles.detailItem}>
                    <div className={styles.detailLabel}>{t('sent_at')}</div>
                    <div className={styles.detailValue}>{formatDate(selectedLog.sent_at)}</div>
                  </div>
                  
                  <div className={styles.detailItem}>
                    <div className={styles.detailLabel}>{t('template')}</div>
                    <div className={styles.detailValue}>{selectedLog.template_name}</div>
                  </div>
                  
                  <div className={styles.detailItem}>
                    <div className={styles.detailLabel}>{t('template_id')}</div>
                    <div className={styles.detailValue}>{selectedLog.template_id}</div>
                  </div>
                  
                  <div className={styles.detailItem}>
                    <div className={styles.detailLabel}>{t('enterprise')}</div>
                    <div className={styles.detailValue}>{selectedLog.enterprise_name}</div>
                  </div>
                  
                  <div className={styles.detailItem}>
                    <div className={styles.detailLabel}>{t('enterprise_id')}</div>
                    <div className={styles.detailValue}>{selectedLog.enterprise_id}</div>
                  </div>
                  
                  <div className={styles.detailItem}>
                    <div className={styles.detailLabel}>{t('recipient')}</div>
                    <div className={styles.detailValue}>{selectedLog.recipient}</div>
                  </div>
                  
                  <div className={styles.detailItem}>
                    <div className={styles.detailLabel}>{t('created_by')}</div>
                    <div className={styles.detailValue}>{selectedLog.created_by}</div>
                  </div>
                </div>
                
                {selectedLog.communication_type === 'email' && (
                  <div className={styles.detailItem}>
                    <div className={styles.detailLabel}>{t('subject')}</div>
                    <div className={styles.detailValue}>{selectedLog.subject}</div>
                  </div>
                )}
                
                <div className={styles.detailItem}>
                  <div className={styles.detailLabel}>{t('content')}</div>
                  <div className={styles.contentBox}>
                    {selectedLog.communication_type === 'email' ? (
                      <div className={styles.emailContent}>
                        <div dangerouslySetInnerHTML={{ __html: selectedLog.content }} />
                      </div>
                    ) : (
                      <div className={styles.smsContent}>
                        {selectedLog.content}
                      </div>
                    )}
                  </div>
                </div>
                
                {selectedLog.status === 'failed' && selectedLog.error_message && (
                  <div className={styles.detailItem}>
                    <div className={styles.detailLabel}>{t('error_message')}</div>
                    <div className={styles.errorMessage}>{selectedLog.error_message}</div>
                  </div>
                )}
              </div>
            </div>
            
            <div className={styles.modalFooter}>
              <button className={styles.closeModalButton} onClick={handleCloseDetail}>
                {t('close')}
              </button>
              {selectedLog.communication_type === 'email' && (
                <button className={styles.resendButton}>
                  <i className="fas fa-paper-plane"></i>
                  <span>{t('resend')}</span>
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CommunicationLogs;