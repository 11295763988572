// File: frontend/src/components/MileMonitor/EventsChart.js

import React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { hexToRGBA } from '../../utils/chartHelpers';

const EventsChart = ({ data, chartType }) => {
  if (!data || !data.length) {
    return <div>No data available</div>;
  }

  const firstEntryDetails = data[0].chartAxisDetails;

  const getYAxisConfig = () => {
    switch (chartType) {
      case 'trips':
        return {
          left: { dataKey: 'y3_axis_value', label: 'Standardized Number of Harsh Events' },
          right: { dataKey: 'y7_axis_value', label: 'Average Number of Trips per User' }
        };
      case 'distance':
        return {
          left: { dataKey: 'y3_axis_value', label: 'Standardized Number of Harsh Events' },
          right: { dataKey: 'y8_axis_value', label: 'Average Distance Driven per User' }
        };
      case 'expDistance':
        return {
          left: { dataKey: 'y3_axis_value', label: 'Standardized Number of Harsh Events' },
          right: { dataKey: 'y9_axis_value', label: 'Deviation from Expected Distance Driven' }
        };
      default:
        return {};
    }
  };

  const yAxisConfig = getYAxisConfig();

  return (
    <ResponsiveContainer width={541} height={400}>
      <AreaChart
        data={data}
        margin={{top: 30, right: 15, left: 15, bottom: 0,}}
      >
        <CartesianGrid strokeDasharray="0 1"/>
        <XAxis 
          dataKey="chartAxisDetails.x1_axis_name" 
          height={60}
        />
        <YAxis 
          yAxisId="left" 
          domain={[firstEntryDetails.y3_axis_min_value, firstEntryDetails.y3_axis_max_value]} 
          orientation="left" 
          allowDataOverflow 
          allowDecimals={true} 
          axisLine={true} 
          scale="linear" 
          label={{ value: yAxisConfig.left.label, angle: -90, position: 'insideBottomLeft' }} 
        />
        <YAxis 
          yAxisId="right" 
          domain={[firstEntryDetails[`${yAxisConfig.right.dataKey}_min_value`], firstEntryDetails[`${yAxisConfig.right.dataKey}_max_value`]]}
          orientation="right" 
          allowDataOverflow
          allowDecimals={true} 
          axisLine={true} 
          scale="linear" 
          label={{ value: yAxisConfig.right.label, angle: 90, position: 'insideBottomRight' }}
        />      
        <Tooltip/>
        <Legend verticalAlign="bottom"/>
        <Area 
          yAxisId="left" 
          type="monotone" 
          dataKey={`chartAxisDetails.${yAxisConfig.left.dataKey}`}
          fill={hexToRGBA(firstEntryDetails.y3_axis_hex, firstEntryDetails.y3_axis_transparency)} 
          stroke={hexToRGBA(firstEntryDetails.y3_axis_hex, firstEntryDetails.y3_axis_transparency_stroke)} 
          name={firstEntryDetails.y3_axis_name} 
        />
        <Area 
          yAxisId="right" 
          type="monotone" 
          dataKey={`chartAxisDetails.${yAxisConfig.right.dataKey}`}
          fill={hexToRGBA(firstEntryDetails[`${yAxisConfig.right.dataKey.split('_')[0]}_axis_hex`], firstEntryDetails[`${yAxisConfig.right.dataKey.split('_')[0]}_axis_transparency`])} 
          stroke={hexToRGBA(firstEntryDetails[`${yAxisConfig.right.dataKey.split('_')[0]}_axis_hex`], firstEntryDetails[`${yAxisConfig.right.dataKey.split('_')[0]}_axis_transparency_stroke`])} 
          name={firstEntryDetails[`${yAxisConfig.right.dataKey.split('_')[0]}_axis_name`]} 
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default EventsChart;