// File: frontend/src/pages/RMS/RMS.js

import React from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import styles from './RMS.module.css';

const RMS = () => {
  const { t } = useLanguage();

  return <div><h1 className={styles.title}>{t('rms')}</h1></div>;
};

export default RMS;


