// File: frontend/src/components/form/LanguageSelector/LanguageSelector.js

import React from 'react';
import { useLanguage } from '../../../contexts/LanguageContext';
import Dropdown from '../Dropdown/Dropdown';
import styles from './LanguageSelector.module.css';

const LanguageSelector = () => {
  const { t, language, changeLanguage, supportedLanguages } = useLanguage();

  const languageOptions = supportedLanguages.map(lang => ({
    value: lang,
    labelKey: `language_${lang.replace('-', '_')}`,
  }));

  const handleLanguageChange = (newLanguage) => {
    changeLanguage(newLanguage);
  };

  const getLanguageDisplayName = (langCode) => {
    const languageNames = {
      'en-ca': '🇨🇦 English (Canada)',
      'de-de': '🇩🇪 Deutsch (Deutschland)',
      'en-gb': '🇬🇧 English (United Kingdom)',
      'en-us': '🇺🇸 English (USA)',
      'es-es': '🇪🇸 Español (España)',
      'es-mx': '🇲🇽 Español (México)',
      'fr-ca': '🇨🇦 Français (Canada)',
      'fr-fr': '🇫🇷 Français (France)',
      'nl-nl': '🇳🇱 Nederlands (Nederland)',
      'tr-tr': '🇹🇷 Türkçe (Türkiye)'
    };
    return languageNames[langCode] || langCode;
  };

  return (
    <div className={styles.languageSelector}>
      <Dropdown
        id="language-picker"
        options={languageOptions}
        value={language}
        onChange={handleLanguageChange}
        variant="language-picker"
        direction="up"
        renderOption={(option) => (
          <span>
            {getLanguageDisplayName(option.value)}
          </span>
        )}
        placeholderKey={`language_${language.replace('-', '_')}`}
      />
    </div>
  );
};

export default LanguageSelector;