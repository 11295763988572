// File: frontend/src/pages/Auth/AccountCreation.js

import React, { useEffect, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useLanguage } from '../../contexts/LanguageContext';
import { useAuth } from '../../contexts/AuthContextV2';
import AuthLayout from '../../components/layout/AuthLayout/AuthLayout';
import StatusScreen from '../../components/Auth/StatusScreen';
import styles from './AccountCreation.module.css';

const AccountCreation = () => {
  const { t } = useLanguage();
  const navigate = useNavigate();
  const location = useLocation();
  const { user, loading } = useAuth();

  // Get state data with better handling
  const { 
    message = t('auth.accountCreation.defaultMessage'),
    identifier,
    identifierType
  } = location.state || {};

  // Format the contact info based on type
  const contactInfo = useMemo(() => {
    if (!identifier) return null;
    
    if (identifierType === 'email') {
      return identifier.toLowerCase();
    } else if (identifierType === 'phone') {
      return identifier; // Phone is already formatted in E.164
    }
    return null;
  }, [identifier, identifierType]);

  // Security redirects
  useEffect(() => {
    if (user) {
      navigate('/dashboard', { replace: true });
    } else if (!contactInfo && !loading) {
      navigate('/sign-in', { replace: true });
    }
  }, [user, contactInfo, loading, navigate]);

  // Auto redirect after delay
  useEffect(() => {
    const redirectTimer = setTimeout(() => {
      if (user) {
        navigate('/ai1', { replace: true });
      }
    }, 5000);

    return () => clearTimeout(redirectTimer);
  }, [user, navigate]);

  if (loading) {
    return (
      <AuthLayout title={t('auth.signUp.title')} showHomeButton={true}>
        <div className={styles.loadingContainer}>
          <div className={styles.loadingSpinner} />
        </div>
      </AuthLayout>
    );
  }

  return (
    <AuthLayout title={t('auth.signUp.title')} showHomeButton={true}>
      <div className={styles.accountCreationContainer}>
        <div className={styles.accountCreationContent}>
          <StatusScreen
            icon="/icons/18/alerts/warning.svg"
            title={t('auth.accountCreation.title')}
            message={t('auth.accountCreation.message')}
            type="warning"
            additionalInfo={
              contactInfo && (
                <div className={styles.contactInfoContainer}>
                  <div className={styles.contactInfoContent}>
                    <span className={styles.contactLabel}>
                      {identifierType === 'email' 
                        ? t('auth.emailVerification.emailSentTo')
                        : t('auth.phoneVerification.phoneSentTo')
                      }
                    </span>
                    <span className={styles.contactValue}>
                      {contactInfo}
                    </span>
                  </div>
                </div>
              )
            }
          />
        </div>
      </div>
    </AuthLayout>
  );
};

export default React.memo(AccountCreation);