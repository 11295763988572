// File: frontend/src/pages/QMS/QuoteDetail/index.js

import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useLanguage } from '../../../contexts/LanguageContext';
import useQuoteDetail from '../../../hooks/useQuoteDetail';
import QuoteHeader from './components/QuoteHeader';
import QuoteOverview from './components/QuoteOverview';
import QuoteProducts from './components/QuoteProducts';
import QuoteLocations from './components/QuoteLocations';
import QuoteConversation from './components/QuoteConversation';
import QuoteSystem from './components/QuoteSystem';
import styles from './QuoteDetail.module.css';

const QuoteDetail = () => {
  const { quoteId } = useParams();
  const navigate = useNavigate();
  const { t } = useLanguage();
  const [activeTab, setActiveTab] = useState('overview');
  
  const { quote, loading, error } = useQuoteDetail(quoteId);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleBack = () => {
    navigate('/qms');
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        {t('loading_quote_details')}
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.error}>
        <h2>{t('error_loading_quote')}</h2>
        <p>{error}</p>
        <button className={styles.backButton} onClick={handleBack}>
          {t('back_to_quote_management')}
        </button>
      </div>
    );
  }

  if (!quote) {
    return (
      <div className={styles.notFound}>
        <h2>{t('quote_not_found')}</h2>
        <p>{t('quote_not_found_message')}</p>
        <button className={styles.backButton} onClick={handleBack}>
          {t('back_to_quote_management')}
        </button>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {/* Managed by main header */}
      {/* <div className={styles.breadcrumb}>
        <span className={styles.breadcrumbLink} onClick={handleBack}>
          {t('quote_management')}
        </span>
        <span className={styles.breadcrumbSeparator}>/</span>
        <span className={styles.breadcrumbCurrent}>{t('quote_details')}</span>
      </div> */}
      
      <QuoteHeader quote={quote} onBack={handleBack} />
      
      <div className={styles.tabs}>
        <button 
          className={`${styles.tab} ${activeTab === 'overview' ? styles.activeTab : ''}`}
          onClick={() => handleTabChange('overview')}
        >
          {t('overview')}
        </button>
        <button 
          className={`${styles.tab} ${activeTab === 'products' ? styles.activeTab : ''}`}
          onClick={() => handleTabChange('products')}
        >
          {t('products')}
        </button>
        <button 
          className={`${styles.tab} ${activeTab === 'locations' ? styles.activeTab : ''}`}
          onClick={() => handleTabChange('locations')}
        >
          {t('locations')}
        </button>
        <button 
          className={`${styles.tab} ${activeTab === 'conversation' ? styles.activeTab : ''}`}
          onClick={() => handleTabChange('conversation')}
        >
          {t('conversation')}
        </button>
        <button 
          className={`${styles.tab} ${activeTab === 'system' ? styles.activeTab : ''}`}
          onClick={() => handleTabChange('system')}
        >
          {t('system')}
        </button>
      </div>
      
      <div className={styles.tabContent}>
        {activeTab === 'overview' && <QuoteOverview quote={quote} />}
        {activeTab === 'products' && <QuoteProducts quote={quote} />}
        {activeTab === 'locations' && <QuoteLocations quote={quote} />}
        {activeTab === 'conversation' && <QuoteConversation quote={quote} />}
        {activeTab === 'system' && <QuoteSystem quote={quote} />}
      </div>
    </div>
  );
};

export default QuoteDetail;