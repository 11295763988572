// File: frontend/src/components/Footer/Footer.js

import React, { useMemo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useLanguage } from '../../contexts/LanguageContext';
import NewsletterForm from '../form/NewsletterForm/NewsletterForm';
import Dropdown from '../form/Dropdown/Dropdown';
import { APP_ENV } from '../../config/constants';
import styles from './Footer.module.css';

const Footer = () => {
  const { t, language, changeLanguage, supportedLanguages } = useLanguage();

  const handleNewsletterSubmit = (email) => {
    // Handle newsletter subscription
    console.log('Subscribed with email:', email);
  };

  // Memoize language options
  const languageOptions = useMemo(() => 
    supportedLanguages.map(lang => ({
      value: lang,
      labelKey: `language_${lang.replace('-', '_')}`,
    })), 
    [supportedLanguages]
  );

  // Memoize language change handler
  const handleLanguageChange = useCallback((newLanguage) => {
    changeLanguage(newLanguage);
  }, [changeLanguage]);

  const getLanguageDisplayName = (langCode) => {
    const languageNames = {
      'en-ca': '🇨🇦 English (Canada)',
      'de-de': '🇩🇪 Deutsch (Deutschland)',
      'en-gb': '🇬🇧 English (United Kingdom)',
      'en-us': '🇺🇸 English (USA)',
      'es-es': '🇪🇸 Español (España)',
      'es-mx': '🇲🇽 Español (México)',
      'fr-ca': '🇨🇦 Français (Canada)',
      'fr-fr': '🇫🇷 Français (France)',
      'nl-nl': '🇳🇱 Nederlands (Nederland)',
      'tr-tr': '🇹🇷 Türkçe (Türkiye)'
    };
    return languageNames[langCode] || langCode;
  };

  return (
    <footer className={styles.footer}>
      <div className={styles.footerContent}>
        <div className={styles.topSection}>
          <div className={styles.logoSection}>
            <Link to="/" className={styles.logoLink}>
              <img src="/logos/allegory-logo-white.svg" alt="Allegory" className={styles.logo} />
            </Link>
            <p>{t('allegory_technology_inc_copyright')}</p>
            <div className={styles.socialIcons}>
              <a href="https://www.facebook.com/Allegory-Insurance-115129876917282" target="_blank" rel="noopener noreferrer" aria-label="Facebook"><img src="/icons/18/socials/meta/facebook-logo.svg" alt="Facebook" /></a>
              <a href="https://x.com/allegory_app" target="_blank" rel="noopener noreferrer" aria-label="Twitter"><img src="/icons/18/socials/x/x-logo.svg" alt="X (Twitter)" /></a>
              <a href="https://www.linkedin.com/company/allegoryapp" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn"><img src="/icons/18/socials/linkedin/linkedin-logo.svg" alt="LinkedIn" /></a>
            </div>
          </div>

          <div className={styles.linksSection}>
            <div className={styles.linkColumn}>
              <h3>{t('company_title')}</h3>
              <ul>
                <li><Link to="/">{t('homepage')}</Link></li>
                <li><Link to="/about">{t('about_title')}</Link></li>
                <li><Link to="/join">{t('join_title')}</Link></li>
                <li><Link to="/contact">{t('contact_title')}</Link></li>
                <li><Link to="/supported-countries">{t('supported_countries_title')}</Link></li>
              </ul>
            </div>
            <div className={styles.linkColumn}>
              <h3>{t('product_title')}</h3>
              <ul>
                <li><Link to="/ai-agents">{t('ai_agents')}</Link></li>
                <li><Link to="/capabilities">{t('capabilities_title')}</Link></li>
                {/* <li><Link to="/insurance">{t('insurance')}</Link></li> */}
                <li><Link to="/allegory-app">{t('allegory_app')}</Link></li>
                <li><Link to="/news">{t('news_title')}</Link></li>
                {/* Once the blog is ready, update this */}
                <li><Link to="/news">{t('blog_title')}</Link></li>
              </ul>
            </div>
            <div className={styles.linkColumn}>
              <h3>{t('resources_title')}</h3>
              <ul>
                {/* <li><Link to="/api">{t('api_title')}</Link></li> */}
                <li><Link to="/embedded-insurance">{t('api_title')}</Link></li>
                <li><Link to="/status">{t('system_status_title')}</Link></li>
                <li>
                  <a 
                    href={`https://${APP_ENV === 'prod' ? '' : 
                          `${APP_ENV}`}.allegory.app/sitemap.xml`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('sitemap')}
                  </a>
                </li>
                {/* <li>
                  <a 
                  href={`https://api${APP_ENV === 'prod' ? '' : 
                  `.${APP_ENV}`}.allegory.app/v1/docs/ui`}
                  target="_blank"
                  rel="noopener noreferrer"
                  >
                  {t('api_title')}
                  </a>
                  </li> */}
                {/* <li><Link to="/compliance">{t('compliance_title')}</Link></li> */}
                {/* <li><Link to="/usage-policy">{t('usage_policy_title')}</Link></li> */}
                {/* Once the privacy choices is ready, update this */}
                <li><Link to="/privacy-policy">{t('privacy_choices_title')}</Link></li>
                <li><Link to="/contact">{t('support_title')}</Link></li>
              </ul>
            </div>
          </div>

          <div className={styles.subscribeSection}>
            <h3>{t('subscribe_news')}</h3>
            <p>{t('subscribe_news_desc')}</p>
            <NewsletterForm onSubmit={handleNewsletterSubmit} />
          </div>
        </div>
        
        {/* Legal Information Section */}
        <div className={styles.legalInfoSection}>
          <h3>{t('legal.disclaimer_heading')}</h3>
          <p>{t('legal.disclaimer_content')}</p>
          
          <div className={styles.legalGrid}>
            <div className={styles.legalColumn}>
              <h4>{t('legal.company_heading')}</h4>
              <p>{t('legal.company_content')}</p>
            </div>
            
            <div className={styles.legalColumn}>
              <h4>{t('legal.licensing_heading')}</h4>
              <p>{t('legal.licensing_content')}</p>
            </div>
          </div>
          
          <div className={styles.legalGrid}>
            <div className={styles.legalColumn}>
              <h4>{t('legal.data_protection_heading')}</h4>
              <p>{t('legal.data_protection_content')}</p>
            </div>
            
            <div className={styles.legalColumn}>
              <h4>{t('legal.quote_disclaimer_heading')}</h4>
              <p>{t('legal.quote_disclaimer_content')}</p>
            </div>
          </div>
          
          <p className={styles.legalContact}>{t('legal.contact_information')}</p>
        </div>
      </div>
      
      <div className={styles.bottomSection}>
        <p>{t('allegory_technology_inc')}</p>
        <div className={styles.languageSelector}>
          <Dropdown
            id="language-picker"
            options={languageOptions}
            value={language}
            onChange={handleLanguageChange}
            variant="language-picker"
            direction="up"
            renderOption={(option) => (
              <span>
                {getLanguageDisplayName(option.value)}
              </span>
            )}
            placeholderKey={`language_${language.replace('-', '_')}`}
          />
        </div>
        <div className={styles.legalLinks}>
          <Link to="/privacy-policy">{t('privacy_policy')}</Link>
          <span className={styles.divider}></span>
          <Link to="/terms-of-service">{t('terms_of_service')}</Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;