// File: frontend/src/contexts/PrivacyContext.js

import React, { createContext, useContext, useState, useEffect } from 'react';
import { useUser } from './UserContext';

const PrivacyContext = createContext(null);

export const PrivacyProvider = ({ children }) => {
  const { user, updateUser } = useUser();
  const [privacyProtection, setPrivacyProtection] = useState(() => {
    return user?.privacy_protection || false;
  });

  // Update local state when user data changes
  useEffect(() => {
    if (user?.privacy_protection !== undefined) {
      setPrivacyProtection(user.privacy_protection);
    }
  }, [user?.privacy_protection]);

  const togglePrivacyProtection = async (enabled) => {
    setPrivacyProtection(enabled);
    await updateUser({ privacy_protection: enabled });
  };

  return (
    <PrivacyContext.Provider value={{
      privacyProtection,
      togglePrivacyProtection
    }}>
      {children}
    </PrivacyContext.Provider>
  );
};

export const usePrivacy = () => {
  const context = useContext(PrivacyContext);
  if (!context) {
    throw new Error('usePrivacy must be used within a PrivacyProvider');
  }
  return context;
};