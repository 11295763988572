// File: frontend/src/pages/PropertyDetail/components/PropertyHeader/index.js

import React from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import styles from './PropertyHeader.module.css';

const PropertyHeader = ({ property, onBack }) => {
  const { t } = useLanguage();

  // Format the address for display
  const formatAddress = () => {
    if (!property.masterAddress) return 'No Address Available';
    
    const { address_line_1, city, province_state_name, postal_code } = property.masterAddress;
    return `${address_line_1}, ${city}, ${province_state_name} ${postal_code}`;
  };

  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <h1 className={styles.title}>
          {formatAddress()}
        </h1>
        
        <div className={styles.meta}>
          <div className={styles.metaItem}>
            <span className={styles.metaLabel}>{t('property_id')}:</span>
            <span className={styles.metaValue}>{property.property_id}</span>
          </div>
          
          <div className={styles.metaItem}>
            <span className={styles.metaLabel}>{t('property_type')}:</span>
            <span className={styles.metaValue}>{property.property_type}</span>
          </div>
          
          <div className={styles.metaItem}>
            <span className={styles.metaLabel}>{t('year_built')}:</span>
            <span className={styles.metaValue}>{property.building?.year_built || 'N/A'}</span>
          </div>
          
          {property.is_income_property && (
            <div className={styles.incomeBadge}>
              {t('income_property')}
            </div>
          )}
        </div>
      </div>
      
      <div className={styles.actions}>
        <button className={styles.backButton} onClick={onBack}>
          {t('back_to_list')}
        </button>
        
        <button className={styles.editButton}>
          {t('edit_property')}
        </button>
      </div>
    </div>
  );
};

export default PropertyHeader;