// File: frontend/src/pages/TripInsight/components/LatestTrips/index.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../../../contexts/LanguageContext';
import styles from './LatestTrips.module.css';

const LatestTrips = () => {
  const { t } = useLanguage();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [trips, setTrips] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(6);

  useEffect(() => {
    const fetchLatestTrips = async () => {
      setLoading(true);
      
      try {
        // This would be replaced with an actual API call
        // const response = await tripService.getLatestTrips();
        
        // Mock data for now
        await new Promise(resolve => setTimeout(resolve, 500));
        
        const mockTrips = [
          {
            trip_id: "trip-12345",
            user_id: "user-12345",
            vehicle_id: "vehicle-12345",
            trip_status: "completed",
            trip_start_timestamp: "2023-06-01T08:30:00Z",
            trip_finished_timestamp: "2023-06-01T09:15:00Z",
            distance_travelled_calculation: 25.4,
            trip_duration_seconds: 2700,
            average_speed: 35.2,
            maximum_speed: 65.8,
            overall_score: 85,
            is_night_driving: false,
            is_peak_driving: true,
            is_weekend_driving: false
          },
          {
            trip_id: "trip-12346",
            user_id: "user-12346",
            vehicle_id: "vehicle-12346",
            trip_status: "completed",
            trip_start_timestamp: "2023-06-02T17:30:00Z",
            trip_finished_timestamp: "2023-06-02T18:15:00Z",
            distance_travelled_calculation: 18.7,
            trip_duration_seconds: 2700,
            average_speed: 28.5,
            maximum_speed: 55.3,
            overall_score: 92,
            is_night_driving: false,
            is_peak_driving: true,
            is_weekend_driving: false
          },
          {
            trip_id: "trip-12347",
            user_id: "user-12347",
            vehicle_id: "vehicle-12347",
            trip_status: "completed",
            trip_start_timestamp: "2023-06-03T12:30:00Z",
            trip_finished_timestamp: "2023-06-03T13:00:00Z",
            distance_travelled_calculation: 12.3,
            trip_duration_seconds: 1800,
            average_speed: 25.1,
            maximum_speed: 45.7,
            overall_score: 78,
            is_night_driving: false,
            is_peak_driving: false,
            is_weekend_driving: true
          },
          {
            trip_id: "trip-12348",
            user_id: "user-12348",
            vehicle_id: "vehicle-12348",
            trip_status: "completed",
            trip_start_timestamp: "2023-06-04T22:30:00Z",
            trip_finished_timestamp: "2023-06-04T23:15:00Z",
            distance_travelled_calculation: 30.2,
            trip_duration_seconds: 2700,
            average_speed: 40.3,
            maximum_speed: 70.5,
            overall_score: 88,
            is_night_driving: true,
            is_peak_driving: false,
            is_weekend_driving: true
          },
          {
            trip_id: "trip-12349",
            user_id: "user-12349",
            vehicle_id: "vehicle-12349",
            trip_status: "in_progress",
            trip_start_timestamp: "2023-06-05T08:30:00Z",
            trip_finished_timestamp: null,
            distance_travelled_calculation: 5.7,
            trip_duration_seconds: 900,
            average_speed: 22.8,
            maximum_speed: 40.2,
            overall_score: null,
            is_night_driving: false,
            is_peak_driving: true,
            is_weekend_driving: false
          }
        ];
        
        setTrips(mockTrips);
      } catch (error) {
        console.error('Error fetching latest trips:', error);
      } finally {
        setLoading(false);
      }
    };
    
    fetchLatestTrips();
  }, []);

  // Helper function to format date
  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  // Helper function to format duration
  const formatDuration = (seconds) => {
    if (!seconds) return 'N/A';
    
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    
    if (hours > 0) {
      return `${hours}h ${minutes}m`;
    }
    return `${minutes}m`;
  };

  const handleViewTrip = (tripId) => {
    navigate(`/trips/trip/${tripId}`);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2 className={styles.title}>{t('latest_trips')}</h2>
        <div className={styles.searchContainer}>
          <input
            type="text"
            className={styles.searchInput}
            placeholder={t('search_trips')}
          />
        </div>
      </div>
      
      {loading ? (
        <div className={styles.loading}>
          <div className={styles.loadingSpinner}></div>
          <p>{t('loading_trips')}</p>
        </div>
      ) : (
        <>
          <div className={styles.tableContainer}>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>{t('trip_id')}</th>
                  <th>{t('user_id')}</th>
                  <th>{t('start_time')}</th>
                  <th>{t('distance')}</th>
                  <th>{t('duration')}</th>
                  <th>{t('score')}</th>
                  <th>{t('status')}</th>
                  <th>{t('actions')}</th>
                </tr>
              </thead>
              <tbody>
                {trips.map((trip) => (
                  <tr key={trip.trip_id}>
                    <td>{trip.trip_id}</td>
                    <td>{trip.user_id}</td>
                    <td>{formatDate(trip.trip_start_timestamp)}</td>
                    <td>{trip.distance_travelled_calculation} km</td>
                    <td>{formatDuration(trip.trip_duration_seconds)}</td>
                    <td>
                      {trip.overall_score !== null ? trip.overall_score : 'N/A'}
                    </td>
                    <td>
                      <span className={`${styles.statusBadge} ${styles[trip.trip_status]}`}>
                        {t(trip.trip_status)}
                      </span>
                    </td>
                    <td>
                      <button 
                        className={styles.viewButton}
                        onClick={() => handleViewTrip(trip.trip_id)}
                      >
                        {t('view')}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          
          <div className={styles.pagination}>
            <button 
              className={styles.paginationArrow}
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
              &lt;
            </button>
            
            {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
              <button
                key={page}
                className={`${styles.pageNumber} ${currentPage === page ? styles.activePage : ''}`}
                onClick={() => handlePageChange(page)}
              >
                {page}
              </button>
            ))}
            
            <button 
              className={styles.paginationArrow}
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              &gt;
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default LatestTrips;