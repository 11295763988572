// src/pages/InvestorPitch/configs/InvestorSeedConfig.js
import React from 'react';
import * as Slides from '../slides';

export const getInvestorSeedSlides = (t) => [
  {
    id: 'intro',
    title: t('investor_pitch.slides.intro.title'),
    content: <Slides.IntroSlide />
  },
  {
    id: 'technology',
    title: t('investor_pitch.slides.technology.title'),
    content: <Slides.TechnologySlide />
  },
  {
    id: 'growth',
    title: t('investor_pitch.slides.growth.title'),
    content: <Slides.GrowthSlide />
  },
  {
    id: 'architecture',
    title: t('investor_pitch.slides.architecture.title'),
    content: <Slides.TechnicalArchitectureSlide />
  },
  {
    id: 'leadership',
    title: t('investor_pitch.slides.leadership.title'),
    content: <Slides.LeadershipSlide />
  },
  {
    id: 'investment',
    title: t('investor_pitch.slides.investment.title'),
    content: <Slides.InvestmentSlide />
  },
  {
    id: 'closing',
    title: t('investor_pitch.slides.closing.title'),
    content: <Slides.ClosingSlide />
  }
];