// File: frontend/src/services/auth.js

import { apiService } from './api';
import { API_ENDPOINTS, APP_ENV } from '../config/constants';
import { tokenService } from './tokenService';

const devLog = (message, data = {}) => {
  if (APP_ENV === 'dev') {
    const timestamp = new Date().toLocaleString('en-CA', { 
      timeZone: 'America/Toronto',
      dateStyle: 'medium',
      timeStyle: 'long'
    });
    console.log(`[${timestamp}] Auth Service: ${message}`, data);
  }
};

export const authService = {
  //******************************
  //Signup Flow
  //******************************
  initiateSignup: async (userData) => {
    // console.log('Initiating signup with data:', userData);
    const payload = {
      email_address: userData.email_address,
      first_name: userData.first_name,
      last_name: userData.last_name,
      mobile_phone_number: userData.mobile_phone_number,
      country_phone_code: userData.country_phone_code,
      area_code: userData.area_code,
      phone_number: userData.phone_number
    };
    return apiService.post(API_ENDPOINTS.AUTH.SIGNUP.INITIATE, payload);
  },

  verifyMobile: async (verificationData) => {
    const payload = verificationData.otp 
      ? {
          mobile_phone_number: verificationData.mobile_phone_number,
          otp: verificationData.otp,
          signup_token: verificationData.signup_token
        }
      : {
          mobile_phone_number: verificationData.mobile_phone_number,
          signup_token: verificationData.signup_token
        };
    
    return apiService.post(API_ENDPOINTS.AUTH.SIGNUP.VERIFY_MOBILE, payload);
  },

  completeSignup: async (signupData) => {
    const payload = {
      mobile_phone_number: signupData.mobile_phone_number,
      organization_name: signupData.organization_name,
      organization_size_id: signupData.organization_size_id,
      primary_language_id: signupData.primary_language_id,
      dataset_region_id: signupData.dataset_region_id
    };
    return apiService.post(API_ENDPOINTS.AUTH.SIGNUP.COMPLETE, payload);
  },

  verifyEmail: async (token) => {
    return apiService.post(API_ENDPOINTS.AUTH.SIGNUP.VERIFY_EMAIL, { token });
  },

  resendVerificationEmail: async (email) => {
    return apiService.post(API_ENDPOINTS.AUTH.SIGNUP.RESEND_VERIFICATION, { 
      email: email.toLowerCase() 
    });
  },

  verifyUser: async (userData) => {
    const payload = {
      enterprise_user_id: userData.enterprise_user_id,
      enterprise_id: userData.enterprise_id,
      user_role_id: userData.user_role_id
    };
    return apiService.post(API_ENDPOINTS.AUTH.SIGNUP.VERIFY_USER, payload);
  },

  //******************************
  // Sign In Flow
  //******************************
  initiateSignin: async (identifier) => {
    const normalizedIdentifier = typeof identifier === 'string' 
      ? identifier.toLowerCase() 
      : identifier.identifier.toLowerCase();
      
    devLog('Initiating signin', { identifier: normalizedIdentifier });
    
    const payload = { identifier: normalizedIdentifier };
    return apiService.post(API_ENDPOINTS.AUTH.SIGNIN.INITIATE, payload);
  },

  verifySignin: async (identifier, otp) => {
    const normalizedIdentifier = typeof identifier === 'object' 
      ? identifier.identifier.toLowerCase() 
      : identifier.toLowerCase();

    devLog('Verifying signin', { identifier: normalizedIdentifier });
    
    const payload = {
      identifier: normalizedIdentifier,
      otp: typeof identifier === 'object' ? identifier.otp : otp
    };
    
    return apiService.post(API_ENDPOINTS.AUTH.SIGNIN.VERIFY, payload);
  },

  resendSigninOTP: async (identifier) => {
    const normalizedIdentifier = typeof identifier === 'string'
      ? identifier.toLowerCase()
      : identifier.identifier.toLowerCase();
      
    devLog('Resending signin OTP', { identifier: normalizedIdentifier });
    
    const payload = { identifier: normalizedIdentifier };
    return apiService.post(API_ENDPOINTS.AUTH.SIGNIN.INITIATE, payload);
  },

  //******************************
  //Token Management Flow
  //******************************
  refreshTokens: async (refreshToken, cognitoSub) => {
    devLog('Refreshing tokens', { 
      hasCognitoSub: !!cognitoSub,
      hasRefreshToken: !!refreshToken 
    });

    // If cognitoSub wasn't passed, try to get it from storage
    if (!cognitoSub) {
      cognitoSub = tokenService.getCognitoSub();
    }

    if (!cognitoSub || !refreshToken) {
      throw new Error('Missing refresh credentials');
    }

    const response = await apiService.post(
      API_ENDPOINTS.AUTH.REFRESH_TOKEN,
      {
        refresh_token: refreshToken,
        cognito_sub: cognitoSub
      }
    );

    devLog('Token refresh response received', {
      success: !!response.tokens
    });

    if (!response.tokens) {
      throw new Error('Invalid refresh response');
    }

    return response.tokens;
  },

  //******************************
  // Sign Out Flow
  //******************************
  signOut: async () => {
    devLog('Initiating sign out');
    
    // Get tokens for sign out
    const tokens = tokenService.getTokens();
    const cognitoSub = tokenService.getCognitoSub();
    
    if (!tokens?.access_token || !tokens?.refresh_token || !cognitoSub) {
      devLog('No active session to sign out');
      return;
    }

    try {
      return await apiService.post(
        API_ENDPOINTS.AUTH.SIGNOUT,
        {
          refresh_token: tokens.refresh_token,
          cognito_sub: cognitoSub
        },
        { 
          headers: { Authorization: `Bearer ${tokens.access_token}` }
        }
      );
    } catch (error) {
      console.error('Sign out API error:', error);
      // Continue with local cleanup even if API call fails
    } finally {
      // Always clear tokens locally
      tokenService.clearTokens();
    }
  },

  //******************************
  // Change Password Flow
  //******************************
  changePassword: async (passwordData) => {
    devLog('Initiating password change');
    
    const payload = {
      current_password: passwordData.current_password,
      new_password: passwordData.new_password
    };
    return apiService.post(API_ENDPOINTS.AUTH.SIGNIN.CHANGE_PASSWORD, payload);
  },
};