// File: frontend/src/hooks/useUserDetail.js

import { useState, useEffect } from 'react';
import userService from '../services/user';

const useUserDetail = (userId) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserDetail = async () => {
      if (!userId) {
        setLoading(false);
        return;
      }

      setLoading(true);
      setError(null);

      try {
        const userData = await userService.getUser(userId);
        setUser(userData);
      } catch (err) {
        console.error('Error fetching user details:', err);
        setError(err.message || 'Failed to load user details');
      } finally {
        setLoading(false);
      }
    };

    fetchUserDetail();
  }, [userId]);

  return { user, loading, error };
};

export default useUserDetail;