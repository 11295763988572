// File: frontend/src/components/Button/Button.js

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useLanguage } from '../../contexts/LanguageContext';
import styles from './Button.module.css';

const Button = ({
  children,
  variant = 'primary',
  size = 'medium',
  icon,
  iconPosition = 'left',
  disabled = false,
  isLoading = false, // Add isLoading prop
  onClick,
  href,
  className,
  showArrow,
  ariaLabel,
  ...props
}) => {
  const { t } = useLanguage();
  const ButtonTag = href ? Link : 'button';
  
  const buttonClasses = [
    styles.button,
    styles[`button-${variant}`],
    styles[`button-${size}`],
    isLoading && styles['button-loading'], // Add loading class
    className
  ].filter(Boolean).join(' ');

  const iconElement = icon && !isLoading && ( // Don't show icon when loading
    <img 
      className={`${styles['button-icon']} ${styles[`button-icon-${iconPosition}`]}`} 
      src={icon} 
      alt="" 
      aria-hidden="true"
    />
  );

  const loadingSpinner = isLoading && (
    <span className={styles['button-spinner']} aria-hidden="true" />
  );

  const arrowElement = showArrow && !isLoading && ( // Don't show arrow when loading
    <svg 
      className={styles['button-arrow']} 
      width="18" 
      height="18" 
      viewBox="0 0 18 18" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg" 
      aria-hidden="true"
    >
      <path 
        fillRule="evenodd" 
        clipRule="evenodd" 
        d="M14.6936 8.16201L9.62298 3.31326L10.7831 2.1001L18 9.00129L10.7831 15.9025L9.62298 14.6893L14.6936 9.84057H0V8.16201H14.6936Z" 
        fill="currentColor"
      />
    </svg>
  );

  return (
    <ButtonTag 
      className={buttonClasses}
      onClick={onClick}
      to={href}
      disabled={disabled || isLoading}
      aria-label={ariaLabel || (typeof children === 'string' ? children : t('button_aria_label', { content: 'Button' }))}
      aria-busy={isLoading}
      {...props}
    >
      {iconPosition === 'left' && iconElement}
      {loadingSpinner}
      <span className={styles['button-text']}>
        {isLoading ? t('common.loading') : children}
      </span>
      {iconPosition === 'right' && iconElement}
      {arrowElement}
    </ButtonTag>
  );
};

Button.propTypes = {
  children: PropTypes.node,
  // variant: PropTypes.oneOf(['primary', 'secondary', 'search', 'social']),
  variant: PropTypes.oneOf(['primary', 'secondary', 'search', 'social', 'delete', 'refresh']),
  size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large']),
  icon: PropTypes.string,
  iconPosition: PropTypes.oneOf(['left', 'right']),
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool, // Add isLoading to PropTypes
  onClick: PropTypes.func,
  href: PropTypes.string,
  className: PropTypes.string,
  showArrow: PropTypes.bool,
  ariaLabel: PropTypes.string,
};

export default Button;