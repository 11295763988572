// File: frontend/src/components/Home/IntroSection.js

import React, { useRef, useEffect, useState } from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import LandingButton from '../Button/LandingButton';
import styles from './IntroSection.module.css';

const IntroSection = () => {
  const { t } = useLanguage();
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.2 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <section 
      ref={sectionRef} 
      className={styles.introSection}
      aria-label={t('partner.section.aria_label')}
    >
      <div className={styles.container}>
        <div className={styles.content}>
          <span className={styles.partnerTag}>{t('partner.tag')}</span>
          <h2 className={styles.title}>{t('partner.title')}</h2>
          <p className={styles.description}>{t('partner.description')}</p>
          <div className={styles.statContainer}>
            <div className={styles.statItem}>
              <span className={styles.statNumber}>{t('partner.stats.conversion.number')}</span>
              <span className={styles.statLabel}>{t('partner.stats.conversion.label')}</span>
            </div>
            <div className={styles.statItem}>
              <span className={styles.statNumber}>{t('partner.stats.ai_agents.number')}</span>
              <span className={styles.statLabel}>{t('partner.stats.ai_agents.label')}</span>
            </div>
            <div className={styles.statItem}>
              <span className={styles.statNumber}>{t('partner.stats.channels.number')}</span>
              <span className={styles.statLabel}>{t('partner.stats.channels.label')}</span>
            </div>
          </div>
          <div className={styles.ctaContainer}>
            <LandingButton href="/sign-up" className={styles.primaryButton}>
              {t('partner.cta.primary')}
            </LandingButton>
            <LandingButton href="/ai-agents" className={styles.secondaryButton}>
              {t('partner.cta.secondary')}
            </LandingButton>
          </div>
        </div>
        <div className={styles.imageContainer}>
          <div className={`${styles.featureCard} ${styles.card1} ${isVisible ? styles.animate : ''}`}>
            <div className={styles.iconWrapper}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M12 2v4m0 12v4M4.93 4.93l2.83 2.83m8.48 8.48 2.83 2.83M2 12h4m12 0h4M4.93 19.07l2.83-2.83m8.48-8.48 2.83-2.83" />
              </svg>
            </div>
            <h3>{t('partner.features.ai.title')}</h3>
            <p>{t('partner.features.ai.description')}</p>
          </div>
          <div className={`${styles.featureCard} ${styles.card2} ${isVisible ? styles.animate : ''}`}>
            <div className={styles.iconWrapper}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
              </svg>
            </div>
            <h3>{t('partner.features.omnichannel.title')}</h3>
            <p>{t('partner.features.omnichannel.description')}</p>
          </div>
          <div className={`${styles.featureCard} ${styles.card3} ${isVisible ? styles.animate : ''}`}>
            <div className={styles.iconWrapper}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M12 2v20M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6" />
              </svg>
            </div>
            <h3>{t('partner.features.revenue.title')}</h3>
            <p>{t('partner.features.revenue.description')}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default IntroSection;