// File: frontend/src/pages/Auth/EmailVerification.js

import React, { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContextV2';
import { useLanguage } from '../../contexts/LanguageContext';
import { AUTH_FLOW_STATES } from '../../contexts/AuthContextV2';
import AuthLayout from '../../components/layout/AuthLayout/AuthLayout';
import EmailVerificationComponent from '../../components/Auth/EmailVerification';
import styles from './EmailVerification.module.css';

const EmailVerification = () => {
  const { t } = useLanguage();
  const navigate = useNavigate();
  const location = useLocation();
  
  const { 
    flowData,
    loading, 
    resendVerificationEmail,
    verifyEmail,
    flowState,
    updateFlowState,
    error: authError,
    clearError
  } = useAuth();

  const email = location.state?.email || flowData?.email_address;

  // Redirect if invalid state
  useEffect(() => {
    if (!email) {
      navigate('/sign-in', { replace: true });
      return;
    }

    // Set proper flow state if coming from signup
    if (!flowState) {
      updateFlowState(AUTH_FLOW_STATES.SIGNUP.EMAIL_VERIFICATION, {
        email_address: email
      });
    }
  }, [email, flowState, navigate, updateFlowState]);

  // Handle email resend
  const handleResendEmail = useCallback(async () => {
    try {
      await resendVerificationEmail(email);
      // Optionally show success message
    } catch (error) {
      console.error('Failed to resend verification email:', error);
    }
  }, [email, resendVerificationEmail]);

  // Handle verification token
  const handleVerification = useCallback(async (token) => {
    try {
      const response = await verifyEmail(token);
      
      if (response.verified) {
        if (response.isFullyVerified) {
          // Use provided redirectUrl if available
          const redirectPath = response.redirectUrl || '/account-creation';
          navigate(redirectPath, { replace: true });
        } else {
          // Handle partial verification state
          updateFlowState(AUTH_FLOW_STATES.SIGNUP.EMAIL_VERIFICATION, {
            ...flowData,
            email_verified: true
          });
        }
      }
    } catch (error) {
      console.error('Email verification failed:', error);
      navigate('/verification-failure', { replace: true });
    }
  }, [verifyEmail, navigate, updateFlowState, flowData]);

  // Check for verification token on mount
  useEffect(() => {
    const token = new URLSearchParams(window.location.search).get('token');
    if (token) {
      handleVerification(token);
    }
  }, [handleVerification]);

  if (!email) return null;

  return (
    <AuthLayout
      title={t('auth.signUp.title')}
      showHomeButton={true}
      showBackButton={false}
    >
      <div className={styles.emailVerificationContainer}>
        <EmailVerificationComponent
          email={email}
          onResendEmail={handleResendEmail}
          onVerify={handleVerification} // Add this prop
          loading={loading}
          error={authError}
          onErrorClose={clearError}
        />
      </div>
    </AuthLayout>
  );
};

export default React.memo(EmailVerification);