// File: frontend/src/contexts/AnalyticsContext.js

import React, { createContext, useContext } from 'react';
// import * as analytics from '../services/analytics';

const AnalyticsContext = createContext();

export const AnalyticsProvider = ({ children }) => {
  const trackEvent = (eventName, eventData) => {
    // analytics.trackEvent(eventName, eventData);
  };

  return (
    <AnalyticsContext.Provider value={{ trackEvent }}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalytics = () => {
  const context = useContext(AnalyticsContext);
  if (context === undefined) {
    throw new Error('useAnalytics must be used within an AnalyticsProvider');
  }
  return context;
};