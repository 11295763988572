// File: frontend/src/pages/UMS/UserPortfolio/components/PortfolioMaps/GeographyPostalCodes.js

import React, { useEffect, useState } from 'react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import MapboxWrapper from '../../../../../components/Map/MapboxWrapper';
import styles from './PortfolioMaps.module.css';

const GeographyPostalCodes = ({ width, height }) => {
  const { t } = useLanguage();
  const [geoData, setGeoData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    fetch('https://allegory-dashboard-maps-geojson-data.s3.ca-central-1.amazonaws.com/ofa9a9/driving-score/all_time/003ebc0a-d724-4eb7-932b-29ba0baa03f8.json')
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        setGeoData(data);
        setLoading(false);
      })
      .catch(error => {
        console.error("Error loading the geojson data:", error);
        setError(error.message);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <div className={styles.spinner}></div>
        <p>{t('loading_map_data')}</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.errorContainer}>
        <i className="fas fa-exclamation-triangle"></i>
        <p>{t('error_loading_map')}: {error}</p>
      </div>
    );
  }

  if (!geoData) {
    return (
      <div className={styles.noDataContainer}>
        <p>{t('no_map_data_available')}</p>
      </div>
    );
  }

  const data = [{
    type: "choroplethmapbox",
    geojson: geoData,
    locations: geoData.features.map(feature => feature.id),
    z: geoData.features.map(feature => feature.properties.percentage),
    colorscale: [
      [0.0, '#FBB1BD'], [0.1, '#FF99AC'], [0.2, '#FF99AC'],
      [0.3, '#FF85A1'], [0.4, '#FF85A1'], [0.5, '#FF7096'],
      [0.6, '#FF7096'], [0.7, '#FF477E'], [0.8, '#FF477E'],
      [0.9, '#FF0A54'], [1.0, '#FF0A54']
    ],
    zmin: 0,
    zmax: 100,
    colorbar: {
      title: t('percentage'),
      thickness: 20
    }
  }];

  const layout = {
    mapbox: {
      center: { lon: -80, lat: 44 },
      zoom: 3,
      style: "light"
    },
    width: width || 800,
    height: height || 500,
    margin: { t: 0, b: 0, l: 0, r: 0 },
    paper_bgcolor: 'rgba(0,0,0,0)',
    plot_bgcolor: 'rgba(0,0,0,0)'
  };

  return (
    <MapboxWrapper
      data={data}
      layout={layout}
      style={{ width: "100%", height: "100%" }}
    />
  );
};

export default GeographyPostalCodes;