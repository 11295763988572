// File: frontend/src/pages/InvestorPitch/components/slides/MarketingSlides/InsuranceFutureSlide/WaterfallSlide.js

import React from 'react';
import { motion } from 'framer-motion';
import { 
    ComposedChart, Bar, Line, XAxis, YAxis, CartesianGrid, 
    Tooltip, Legend, ResponsiveContainer, Cell 
} from 'recharts';
import { ANIMATION_VARIANTS } from '../../../../constants/animations';
import styles from './WaterfallSlide.module.css';

const WaterfallSlide = () => {
    const totalExpenseAmount = 1074e6; // This should match your model's total

    // Calculate running total for the final value
    const calculateRunningTotal = (data) => {
        let total = 0;
        return data.map(item => {
            if (item.type === 'total') {
                total = item.value;
                return { ...item, running: total };
            } else if (item.type === 'reduction') {
                total += item.value;
                return { ...item, running: total };
            } else {
                return { ...item, value: total, running: total };
            }
        });
    };

    const initialData = [
        { 
            name: 'Current\nExpenses', 
            value: totalExpenseAmount, 
            type: 'total',
            color: 'var(--color-red-310)'
        },
        { 
            name: 'Simple\nTasks', 
            value: -totalExpenseAmount * 0.30 * 0.999, 
            type: 'reduction',
            color: 'var(--color-blue-210)'
        },
        { 
            name: 'Medium\nTasks', 
            value: -totalExpenseAmount * 0.30 * 0.80, 
            type: 'reduction',
            color: 'var(--color-blue-310)'
        },
        { 
            name: 'Complex\nTasks', 
            value: -totalExpenseAmount * 0.25 * 0.75, 
            type: 'reduction',
            color: 'var(--color-blue-410)'
        },
        { 
            name: 'Strategic\nInitiatives', 
            value: -totalExpenseAmount * 0.15 * 0.50, 
            type: 'reduction',
            color: 'var(--color-blue-510)'
        },
        { 
            name: 'Future\nExpenses', 
            type: 'final',
            color: 'var(--color-success)'
        }
    ];

    const data = calculateRunningTotal(initialData);

    const formatCurrency = (value) => {
        const absValue = Math.abs(value);
        if (absValue >= 1e6) {
            return `$${(value / 1e6).toFixed(0)}M`;
        } else if (absValue >= 1e3) {
            return `$${(value / 1e3).toFixed(0)}K`;
        }
        return `$${value}`;
    };

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            const data = payload[0].payload;
            return (
                <div className={styles.tooltip}>
                    <p className={styles.tooltipLabel}>{label.replace('\n', ' ')}</p>
                    <p className={styles.tooltipValue}>
                        {data.type === 'reduction' 
                            ? `Reduction: ${formatCurrency(Math.abs(data.value))}`
                            : `Total: ${formatCurrency(Math.abs(data.running))}`
                        }
                    </p>
                    {data.type === 'reduction' && (
                        <p className={styles.tooltipPercentage}>
                            {`(${(Math.abs(data.value) / totalExpenseAmount * 100).toFixed(1)}% of original)`}
                        </p>
                    )}
                </div>
            );
        }
        return null;
    };

    return (
        <motion.div
            className={styles.container}
            variants={ANIMATION_VARIANTS.stagger.container}
            initial="hidden"
            animate="visible"
        >
            <motion.div 
                className={styles.header}
                variants={ANIMATION_VARIANTS.fadeIn}
            >
                <h1 className={styles.title}>An autonomous insurer could generate $3.2B with only $212M in expenses</h1>
                {/* <p className={styles.subtitle}>
                    From Current Operations to AI-Driven Future
                </p> */}
                {/* <p className={styles.highlight}>
                    Achieving 80% Cost Reduction Through Systematic AI Implementation
                </p> */}
            </motion.div>

            <motion.div 
                className={styles.chartWrapper}
                variants={ANIMATION_VARIANTS.fadeIn}
            >
                <ResponsiveContainer width="100%" height={470}>
                    <ComposedChart
                        data={data}
                        margin={{ top: 20, right: 30, left: 40, bottom: 60 }}
                    >
                        <CartesianGrid 
                            strokeDasharray="3 3" 
                            vertical={false}
                            stroke="rgba(255, 255, 255, 0.1)"
                        />
                        <XAxis 
                            dataKey="name"
                            angle={0}
                            interval={0}
                            tick={{
                                fill: 'var(--color-gray-300)',
                                fontSize: 12,
                                fontFamily: 'var(--font-primary)'
                            }}
                            tickLine={false}
                            axisLine={{ stroke: 'var(--color-gray-600)' }}
                        />
                        <YAxis 
                            tickFormatter={(value) => `$${Math.abs(value / 1000000).toFixed(0)}M`}
                            label={{ 
                                value: 'Cost Amount', 
                                angle: -90, 
                                position: 'insideLeft',
                                style: {
                                    fill: 'var(--color-gray-300)',
                                    fontSize: 12,
                                    fontFamily: 'var(--font-primary)'
                                }
                            }}
                            tick={{
                                fill: 'var(--color-gray-300)',
                                fontSize: 12,
                                fontFamily: 'var(--font-primary)'
                            }}
                            tickLine={false}
                            axisLine={{ stroke: 'var(--color-gray-600)' }}
                        />
                        <Tooltip content={<CustomTooltip />} />
                        <Bar 
                            dataKey="value" 
                            radius={[4, 4, 0, 0]}
                        >
                            {data.map((entry, index) => (
                                <Cell 
                                    key={`cell-${index}`}
                                    fill={entry.color}
                                />
                            ))}
                        </Bar>
                        <Line
                            type="step"
                            dataKey="running"
                            stroke="var(--color-gray-300)"
                            strokeWidth={2}
                            dot={{
                                fill: 'var(--color-gray-300)',
                                r: 4
                            }}
                            activeDot={{
                                r: 6,
                                fill: 'var(--color-primary)'
                            }}
                        />
                    </ComposedChart>
                </ResponsiveContainer>
            </motion.div>
        </motion.div>
    );
};

export default WaterfallSlide;