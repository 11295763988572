// File: frontend/src/components/routing/ProtectedRoute.js

import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContextV2';
import { useRoleBasedAccess } from '../../contexts/RoleBasedAccessContext';
import { tokenService } from '../../services/tokenService';
import LoadingContainer from '../LoadingSpinner/LoadingContainer';

const ProtectedRoute = ({ children, requiredRole }) => {
  const location = useLocation();
  const { isAuthenticated, loading, user } = useAuth();
  const { hasRole } = useRoleBasedAccess();
  console.log('ProtectedRoute render:', { 
    isAuthenticated, 
    hasUser: !!user, 
    tokens: tokenService.getTokens(),
    pathname: location.pathname,
    state: location.state ,
    timestamp: Date.now()
  });

  // Add this check
  const isPostSignIn = location.state?.fromSignInSuccess;
  
  if (loading) {
    return <LoadingContainer transparent spinnerSize="large" message="Loading..." />;
  }

  // Don't redirect immediately after sign-in-success
  if ((!isAuthenticated || !user) && !isPostSignIn) {
    return <Navigate to="/sign-in" state={{ from: location.pathname }} replace />;
  }

  if (requiredRole && !hasRole(requiredRole)) {
    console.warn(`Unauthorized access attempt to ${location.pathname}`);
    return <Navigate to="/ai1" replace />; // Change this to ai1
  }

  return children;
};

export default ProtectedRoute;