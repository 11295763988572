import React, { useState, useRef } from 'react';

const InteractiveBubbleSystem = () => {
  // Initial system data
  const initialNodes = [
    // Core Infrastructure
    {
      id: 1,
      name: "Core Infra",
      size: 100,
      color: "#EF4444", // red
      x: 600,
      y: 300
    },
    
    // Frontend Layer
    {
      id: 2,
      name: "Dashboard",
      size: 85,
      color: "#60A5FA", // blue
      x: 447,
      y: 194
    },
    {
      id: 3,
      name: "Agent Portal",
      size: 80,
      color: "#60A5FA",
      x: 569,
      y: 136
    },
    {
      id: 4,
      name: "Admin",
      size: 75,
      color: "#60A5FA",
      x: 573,
      y: 432
    },

    // API Layer
    {
      id: 5,
      name: "Policy API",
      size: 55,
      color: "#34D399", // green
      x: 909,
      y: 71
    },
    {
      id: 6,
      name: "Claims API",
      size: 55,
      color: "#34D399",
      x: 973,
      y: 174
    },
    {
      id: 7,
      name: "Auth API",
      size: 55,
      color: "#34D399",
      x: 950,
      y: 300
    },

    // Backend Services
    {
      id: 8,
      name: "Policy Service",
      size: 50,
      color: "#F97316", // orange
      x: 403,
      y: 925
    },
    {
      id: 9,
      name: "Claims Service",
      size: 50,
      color: "#F97316",
      x: 509,
      y: 923
    },
    {
      id: 10,
      name: "Notification Service",
      size: 45,
      color: "#F97316",
      x: 269,
      y: 910
    },

    // Database Layer
    {
      id: 11,
      name: "Policy DB",
      size: 53,
      color: "#A855F7", // purple
      x: 891,
      y: 462
    },
    {
      id: 12,
      name: "Claims DB",
      size: 53,
      color: "#A855F7",
      x: 776,
      y: 537
    },
    {
      id: 13,
      name: "Audit DB",
      size: 49,
      color: "#A855F7",
      x: 609,
      y: 532
    },

    // Sub-processes
    {
      id: 14,
      name: "Quote & Bind",
      size: 50,
      color: "#93C5FD", // lighter blue
      x: 315,
      y: 74
    },
    {
      id: 15,
      name: "Policy",
      size: 35,
      color: "#93C5FD",
      x: 385,
      y: 111
    },
    {
      id: 16,
      name: "FNOL",
      size: 48,
      color: "#93C5FD",
      x: 333,
      y: 199
    },

    // Backend Operations
    {
      id: 17,
      name: "Underwriting",
      size: 70,
      color: "#FDBA74", // lighter orange
      x: 447,
      y: 401
    },
    {
      id: 18,
      name: "Document Generation",
      size: 30,
      color: "#FDBA74",
      x: 656,
      y: 914
    },
    {
      id: 19,
      name: "Payments",
      size: 50,
      color: "#FDBA74",
      x: 325,
      y: 530
    },

    // Integration Points
    {
      id: 20,
      name: "Email",
      size: 40,
      color: "#F97316",
      x: 668,
      y: 149
    },
    {
      id: 21,
      name: "SMS",
      size: 35,
      color: "#F97316",
      x: 478,
      y: 296
    },
    {
      id: 22,
      name: "Ext. APIs",
      size: 50,
      color: "#34D399",
      x: 414,
      y: 490
    },

    // Core Management Systems
    {
      id: 23,
      name: "Users",
      size: 45,
      color: "#FB923C", // orange
      x: 454,
      y: 33
    },
    {
      id: 24,
      name: "Quotes",
      size: 45,
      color: "#FB923C",
      x: 231,
      y: 198
    },
    {
      id: 25,
      name: "PMS",
      size: 45,
      color: "#FB923C",
      x: 214,
      y: 314
    },
    {
      id: 26,
      name: "CMS",
      size: 45,
      color: "#FB923C",
      x: 352,
      y: 411
    },
    {
      id: 27,
      name: "Claims & Fraud",
      size: 55,
      color: "#FB923C",
      x: 245,
      y: 434
    },
    {
      id: 28,
      name: "Documents",
      size: 60,
      color: "#FB923C",
      x: 365,
      y: 291
    },
    {
      id: 29,
      name: "Treasury",
      size: 45,
      color: "#FB923C",
      x: 495,
      y: 532
    },
    {
      id: 30,
      name: "Reporting",
      size: 54,
      color: "#FB923C",
      x: 691,
      y: 421
    },
    {
      id: 31,
      name: "Marketing",
      size: 53,
      color: "#FB923C",
      x: 603,
      y: 32
    },

    // AI Components
    {
      id: 32,
      name: "AI-1",
      size: 50,
      color: "#6366F1", // indigo
      x: 771,
      y: 127
    },
    {
      id: 33,
      name: "Plato",
      size: 45,
      color: "#6366F1",
      x: 723,
      y: 47
    },
    {
      id: 34,
      name: "Socrates",
      size: 50,
      color: "#6366F1",
      x: 814,
      y: 58
    },
    {
      id: 35,
      name: "Cave",
      size: 45,
      color: "#6366F1",
      x: 852,
      y: 193
    },

    // IoT & Telematics
    {
      id: 36,
      name: "Telematics",
      size: 57,
      color: "#EC4899", // pink
      x: 814,
      y: 398
    },
    {
      id: 37,
      name: "IoT",
      size: 40,
      color: "#EC4899",
      x: 731,
      y: 341
    },
    {
      id: 38,
      name: "Sensors",
      size: 50,
      color: "#EC4899",
      x: 825,
      y: 281
    },

    // AI Agents
    {
      id: 39,
      name: "AI Agents",
      size: 60,
      color: "#6366F1",
      x: 725,
      y: 228
    }
  ];

  const [nodes, setNodes] = useState(initialNodes);
  const [connections, setConnections] = useState([]);
  const [draggingNode, setDraggingNode] = useState(null);
  const [selectedNode, setSelectedNode] = useState(null);
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const svgRef = useRef(null);

  // Handle starting to drag a node
  const handleMouseDown = (e, node) => {
    const svg = svgRef.current;
    const pt = svg.createSVGPoint();
    pt.x = e.clientX;
    pt.y = e.clientY;
    const svgP = pt.matrixTransform(svg.getScreenCTM().inverse());

    setDraggingNode(node.id);
    setOffset({
      x: svgP.x - node.x,
      y: svgP.y - node.y
    });
  };

  // Handle dragging
  const handleMouseMove = (e) => {
    if (!draggingNode) return;

    const svg = svgRef.current;
    const pt = svg.createSVGPoint();
    pt.x = e.clientX;
    pt.y = e.clientY;
    const svgP = pt.matrixTransform(svg.getScreenCTM().inverse());

    setNodes(nodes.map(node => {
      if (node.id === draggingNode) {
        return {
          ...node,
          x: svgP.x - offset.x,
          y: svgP.y - offset.y
        };
      }
      return node;
    }));
  };

  // Handle stopping drag
  const handleMouseUp = () => {
    setDraggingNode(null);
  };

  // Handle node selection for connections
  const handleNodeClick = (node) => {
    if (!selectedNode) {
      setSelectedNode(node);
    } else if (selectedNode.id !== node.id) {
      // Create new connection
      setConnections([
        ...connections,
        {
          source: selectedNode.id,
          target: node.id,
          color: selectedNode.color
        }
      ]);
      setSelectedNode(null);
    } else {
      setSelectedNode(null);
    }
  };

  // Remove connection on right click
  const handleConnectionRightClick = (e, connectionIndex) => {
    e.preventDefault();
    setConnections(connections.filter((_, i) => i !== connectionIndex));
  };

  return (
    <div className="w-full h-full bg-gray-900 p-8">
      <div className="mb-1">
        <h1 className="text-2xl font-bold text-white mb-2">3D Mind Map of Allegory Intelligence</h1>
        {/* <p className="text-gray-400">
          Drag bubbles to position them. Click two bubbles to connect them. 
          Right-click connections to remove them.
        </p> */}
      </div>
      
      <div className="relative w-full" style={{ height: '1200px' }}>
        <svg 
          ref={svgRef}
          width="100%" 
          height="100%" 
          viewBox="0 0 1200 1200"
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
        >
          {/* Background Grid */}
          <defs>
            <pattern id="grid" width="40" height="40" patternUnits="userSpaceOnUse">
              <path d="M 40 0 L 0 0 0 40" fill="none" stroke="#2D3748" strokeWidth="0.5"/>
            </pattern>
          </defs>
          <rect width="100%" height="100%" fill="url(#grid)" />

          {/* Connections */}
          {connections.map((connection, index) => {
            const source = nodes.find(n => n.id === connection.source);
            const target = nodes.find(n => n.id === connection.target);
            return (
              <path
                key={`${connection.source}-${connection.target}-${index}`}
                d={`M ${source.x} ${source.y} L ${target.x} ${target.y}`}
                stroke={connection.color}
                strokeWidth={1.5}
                strokeOpacity={0.5}
                onContextMenu={(e) => handleConnectionRightClick(e, index)}
                className="cursor-pointer"
              />
            );
          })}

          {/* Nodes */}
          {nodes.map(node => {
            const radius = (node.size / 100) * 60;
            const isSelected = selectedNode?.id === node.id;
            const fontSize = Math.max(12, (node.size / 100) * 16);

            return (
              <g
                key={node.id}
                onMouseDown={(e) => handleMouseDown(e, node)}
                onClick={() => handleNodeClick(node)}
                className="cursor-pointer"
              >
                {/* 3D effect using gradients */}
                <defs>
                  <radialGradient id={`gradient-${node.id}`} cx="30%" cy="30%" r="70%">
                    <stop offset="0%" style={{ stopColor: node.color, stopOpacity: 0.4 }} />
                    <stop offset="100%" style={{ stopColor: node.color, stopOpacity: 0.1 }} />
                  </radialGradient>
                  <filter id={`shadow-${node.id}`} x="-20%" y="-20%" width="140%" height="140%">
                    <feGaussianBlur in="SourceAlpha" stdDeviation="3" />
                    <feOffset dx="2" dy="2" result="offsetblur" />
                    <feComponentTransfer>
                      <feFuncA type="linear" slope="0.2" />
                    </feComponentTransfer>
                    <feMerge>
                      <feMergeNode />
                      <feMergeNode in="SourceGraphic" />
                    </feMerge>
                  </filter>
                </defs>
                <circle
                  cx={node.x}
                  cy={node.y}
                  r={radius}
                  fill={`url(#gradient-${node.id})`}
                  stroke={node.color}
                  strokeWidth={isSelected ? 3 : 0.1}
                  filter={`url(#shadow-${node.id})`}
                  className="transition-all duration-300"
                />
                {/* Text with line breaks */}
                {node.name.split(' ').reduce((rows, word, i, words) => {
                  if (i % 2 === 0) {
                    rows.push(words.slice(i, i + 2).join(' '));
                  }
                  return rows;
                }, []).map((line, i, lines) => (
                  <text
                    key={i}
                    x={node.x}
                    y={node.y + (i - lines.length/2 + 0.5) * fontSize}
                    textAnchor="middle"
                    fill="white"
                    fontSize={fontSize}
                    className="select-none pointer-events-none"
                  >
                    {line}
                  </text>
                ))}
                {/* Coordinates display */}
                {/* <text
                  x={node.x}
                  y={node.y + radius + 15}
                  textAnchor="middle"
                  fill="#4B5563"
                  fontSize="8"
                  className="select-none pointer-events-none"
                >
                  ({Math.round(node.x)}, {Math.round(node.y)})
                </text> */}
              </g>
            );
          })}
        </svg>
      </div>

      <div className="mt-4 flex gap-4">
        <div className="text-gray-400 text-sm flex items-center gap-2">
          <div className="w-3 h-3 rounded-full bg-blue-400"></div>
          <span>Drag bubbles to position</span>
        </div>
        <div className="text-gray-400 text-sm flex items-center gap-2">
          <div className="w-3 h-3 rounded-full bg-green-400"></div>
          <span>Click two bubbles to connect</span>
        </div>
        <div className="text-gray-400 text-sm flex items-center gap-2">
          <div className="w-3 h-3 rounded-full bg-red-400"></div>
          <span>Right-click to remove connections</span>
        </div>
      </div>
    </div>
  );
};

export default InteractiveBubbleSystem;