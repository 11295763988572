// File: frontend/src/pages/AutoInsight/VehicleDetail/components/VehicleTelematics/index.js

import React, { useState } from 'react';
import { useLanguage } from '../../../../../../contexts/LanguageContext';
import styles from './VehicleTelematics.module.css';

// Helper function to format date with time
const formatDateTime = (dateString) => {
  if (!dateString) return 'N/A';
  
  const date = new Date(dateString);
  
  // Check if date is valid
  if (isNaN(date.getTime())) return 'Invalid Date';
  
  return date.toLocaleString();
};

const VehicleTelematics = ({ vehicle }) => {
  const { t } = useLanguage();
  const [selectedTrip, setSelectedTrip] = useState(null);

  // Group telematics data by trip_id
  const tripData = {};
  if (vehicle.telematics && vehicle.telematics.length > 0) {
    vehicle.telematics.forEach(record => {
      if (!tripData[record.trip_id]) {
        tripData[record.trip_id] = [];
      }
      tripData[record.trip_id].push(record);
    });
  }

  // Sort trips by date (newest first)
  const sortedTrips = Object.keys(tripData).map(tripId => {
    const tripRecords = tripData[tripId];
    const latestRecord = tripRecords.reduce((latest, record) => {
      return new Date(record.timestamp) > new Date(latest.timestamp) ? record : latest;
    }, tripRecords[0]);
    
    return {
      tripId,
      timestamp: latestRecord.timestamp,
      records: tripRecords
    };
  }).sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

  // Calculate trip statistics
  const calculateTripStats = (records) => {
    let totalDistance = 0;
    let maxSpeed = 0;
    let avgSpeed = 0;
    let maxAcceleration = 0;
    let maxDeceleration = 0;
    let avgFuelConsumption = 0;
    let fuelConsumptionCount = 0;
    
    records.forEach(record => {
      totalDistance += record.distance_traveled || 0;
      maxSpeed = Math.max(maxSpeed, record.speed || 0);
      
      if (record.acceleration) {
        maxAcceleration = Math.max(maxAcceleration, record.acceleration);
      }
      
      if (record.deceleration) {
        maxDeceleration = Math.max(maxDeceleration, record.deceleration);
      }
      
      if (record.fuel_consumption) {
        avgFuelConsumption += record.fuel_consumption;
        fuelConsumptionCount++;
      }
    });
    
    avgSpeed = records.reduce((sum, record) => sum + (record.speed || 0), 0) / records.length;
    avgFuelConsumption = fuelConsumptionCount > 0 ? avgFuelConsumption / fuelConsumptionCount : 0;
    
    return {
      totalDistance: totalDistance.toFixed(2),
      maxSpeed: maxSpeed.toFixed(1),
      avgSpeed: avgSpeed.toFixed(1),
      maxAcceleration: maxAcceleration.toFixed(2),
      maxDeceleration: maxDeceleration.toFixed(2),
      avgFuelConsumption: avgFuelConsumption.toFixed(2)
    };
  };

  return (
    <div className={styles.container}>
      {Object.keys(tripData).length > 0 ? (
        <div className={styles.telematicsContent}>
          <div className={styles.tripsCard}>
            <h3 className={styles.cardTitle}>{t('trips')}</h3>
            <div className={styles.cardContent}>
              <div className={styles.tripsList}>
                {sortedTrips.map((trip) => (
                  <div 
                    key={trip.tripId} 
                    className={`${styles.tripItem} ${selectedTrip === trip.tripId ? styles.selectedTrip : ''}`}
                    onClick={() => setSelectedTrip(trip.tripId)}
                  >
                    <div className={styles.tripDate}>
                      {formatDateTime(trip.timestamp)}
                    </div>
                    <div className={styles.tripId}>
                      {t('trip_id')}: {trip.tripId}
                    </div>
                    <div className={styles.tripRecords}>
                      {t('data_points')}: {trip.records.length}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          
          {selectedTrip && (
            <div className={styles.tripDetailsCard}>
              <h3 className={styles.cardTitle}>{t('trip_details')}</h3>
              <div className={styles.cardContent}>
                <div className={styles.tripStats}>
                  {(() => {
                    const stats = calculateTripStats(tripData[selectedTrip]);
                    return (
                      <div className={styles.statsGrid}>
                        <div className={styles.statItem}>
                          <div className={styles.statLabel}>{t('total_distance')}:</div>
                          <div className={styles.statValue}>{stats.totalDistance} km</div>
                        </div>
                        
                        <div className={styles.statItem}>
                          <div className={styles.statLabel}>{t('max_speed')}:</div>
                          <div className={styles.statValue}>{stats.maxSpeed} km/h</div>
                        </div>
                        
                        <div className={styles.statItem}>
                          <div className={styles.statLabel}>{t('avg_speed')}:</div>
                          <div className={styles.statValue}>{stats.avgSpeed} km/h</div>
                        </div>
                        
                        <div className={styles.statItem}>
                          <div className={styles.statLabel}>{t('max_acceleration')}:</div>
                          <div className={styles.statValue}>{stats.maxAcceleration} m/s²</div>
                        </div>
                        
                        <div className={styles.statItem}>
                          <div className={styles.statLabel}>{t('max_deceleration')}:</div>
                          <div className={styles.statValue}>{stats.maxDeceleration} m/s²</div>
                        </div>
                        
                        <div className={styles.statItem}>
                          <div className={styles.statLabel}>{t('avg_fuel_consumption')}:</div>
                          <div className={styles.statValue}>{stats.avgFuelConsumption} L/100km</div>
                        </div>
                      </div>
                    );
                  })()}
                </div>
                
                <div className={styles.tripDataTable}>
                  <h4 className={styles.tableTitle}>{t('trip_data_points')}</h4>
                  <div className={styles.tableContainer}>
                    <table className={styles.table}>
                      <thead>
                        <tr>
                          <th>{t('timestamp')}</th>
                          <th>{t('location')}</th>
                          <th>{t('speed')}</th>
                          <th>{t('acceleration')}</th>
                          <th>{t('deceleration')}</th>
                          <th>{t('heading')}</th>
                          <th>{t('distance')}</th>
                          <th>{t('fuel')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tripData[selectedTrip].map((record, index) => (
                          <tr key={index}>
                            <td>{formatDateTime(record.timestamp)}</td>
                            <td>
                              {record.latitude}, {record.longitude}
                            </td>
                            <td>{record.speed} km/h</td>
                            <td>{record.acceleration || 'N/A'}</td>
                            <td>{record.deceleration || 'N/A'}</td>
                            <td>{record.heading}°</td>
                            <td>{record.distance_traveled} km</td>
                            <td>{record.fuel_consumption} L/100km</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className={styles.noData}>
          <p>{t('no_telematics_data_available')}</p>
          <div className={styles.noDataDescription}>
            {t('telematics_data_description')}
          </div>
        </div>
      )}
    </div>
  );
};

export default VehicleTelematics;