// File: frontend/src/pages/AutoInsight/VehicleDetail/components/VehicleValuation/index.js

import React from 'react';
import { useLanguage } from '../../../../../../contexts/LanguageContext';
import styles from './VehicleValuation.module.css';

// Helper function to format date
const formatDate = (dateString) => {
  if (!dateString) return 'N/A';
  
  const date = new Date(dateString);
  
  // Check if date is valid
  if (isNaN(date.getTime())) return 'Invalid Date';
  
  return date.toLocaleDateString();
};

// Helper function to format currency
const formatCurrency = (amount, currency = 'USD') => {
  if (amount === null || amount === undefined) return 'N/A';
  
  return new Intl.NumberFormat('en-US', { 
    style: 'currency', 
    currency: currency 
  }).format(amount);
};

const VehicleValuation = ({ vehicle }) => {
  const { t } = useLanguage();

  // Sort valuation records by date (newest first)
  const sortedValuations = vehicle.valuation 
    ? [...vehicle.valuation].sort((a, b) => {
        return new Date(b.price_timestamp) - new Date(a.price_timestamp);
      })
    : [];

  // Get the latest valuation
  const latestValuation = sortedValuations.length > 0 ? sortedValuations[0] : null;

  // Get market intelligence
  const marketIntel = vehicle.market_intel?.vehicle_market_intel || null;

  // Get purchase information
  const purchaseInfo = vehicle.purchase || null;

  return (
    <div className={styles.container}>
      <div className={styles.grid}>
        <div className={styles.card}>
          <h3 className={styles.cardTitle}>{t('current_valuation')}</h3>
          <div className={styles.cardContent}>
            {latestValuation ? (
              <div className={styles.valuationCurrent}>
                <div className={styles.valuationValue}>
                  {formatCurrency(latestValuation.price, latestValuation.price_currency)}
                </div>
                
                <div className={styles.valuationDetails}>
                  <div className={styles.valuationDetail}>
                    <span className={styles.valuationDetailLabel}>{t('valuation_type')}:</span>
                    <span className={styles.valuationDetailValue}>
                      {latestValuation.price_type}
                    </span>
                  </div>
                  
                  <div className={styles.valuationDetail}>
                    <span className={styles.valuationDetailLabel}>{t('source')}:</span>
                    <span className={styles.valuationDetailValue}>
                      {latestValuation.price_source}
                    </span>
                  </div>
                  
                  <div className={styles.valuationDetail}>
                    <span className={styles.valuationDetailLabel}>{t('as_of')}:</span>
                    <span className={styles.valuationDetailValue}>
                      {formatDate(latestValuation.price_timestamp)}
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.noData}>{t('no_valuation_available')}</div>
            )}
          </div>
        </div>
        
        <div className={styles.card}>
          <h3 className={styles.cardTitle}>{t('market_intelligence')}</h3>
          <div className={styles.cardContent}>
            {marketIntel ? (
              <div className={styles.marketIntelGrid}>
                <div className={styles.marketIntelItem}>
                  <div className={styles.marketIntelLabel}>{t('market_value')}:</div>
                  <div className={styles.marketIntelValue}>
                    {formatCurrency(marketIntel.market_value)}
                  </div>
                </div>
                
                <div className={styles.marketIntelItem}>
                  <div className={styles.marketIntelLabel}>{t('depreciation_rate')}:</div>
                  <div className={styles.marketIntelValue}>
                    {marketIntel.depreciation_rate}%
                  </div>
                </div>
                
                <div className={styles.marketIntelItem}>
                  <div className={styles.marketIntelLabel}>{t('market_demand')}:</div>
                  <div className={styles.marketIntelValue}>
                    {marketIntel.market_demand}
                  </div>
                </div>
                
                <div className={styles.marketIntelItem}>
                  <div className={styles.marketIntelLabel}>{t('popularity_index')}:</div>
                  <div className={styles.marketIntelValue}>
                    {marketIntel.popularity_index}/100
                  </div>
                </div>
                
                <div className={styles.marketIntelItem}>
                  <div className={styles.marketIntelLabel}>{t('last_updated')}:</div>
                  <div className={styles.marketIntelValue}>
                    {formatDate(vehicle.market_intel?.updated_at)}
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.noData}>{t('no_market_intelligence_available')}</div>
            )}
          </div>
        </div>
      </div>
      
      <div className={styles.card}>
        <h3 className={styles.cardTitle}>{t('purchase_information')}</h3>
        <div className={styles.cardContent}>
          {purchaseInfo ? (
            <div className={styles.purchaseGrid}>
              <div className={styles.purchaseItem}>
                <div className={styles.purchaseLabel}>{t('purchase_type')}:</div>
                <div className={styles.purchaseValue}>{purchaseInfo.purchase_type}</div>
              </div>
              
              <div className={styles.purchaseItem}>
                <div className={styles.purchaseLabel}>{t('purchase_price')}:</div>
                <div className={styles.purchaseValue}>
                  {formatCurrency(purchaseInfo.purchase_price)}
                </div>
              </div>
              
              <div className={styles.purchaseItem}>
                <div className={styles.purchaseLabel}>{t('purchase_date')}:</div>
                <div className={styles.purchaseValue}>
                  {formatDate(purchaseInfo.purchase_date)}
                </div>
              </div>
              
              <div className={styles.purchaseItem}>
                <div className={styles.purchaseLabel}>{t('seller_name')}:</div>
                <div className={styles.purchaseValue}>{purchaseInfo.seller_name}</div>
              </div>
              
              <div className={styles.purchaseItem}>
                <div className={styles.purchaseLabel}>{t('seller_type')}:</div>
                <div className={styles.purchaseValue}>{purchaseInfo.seller_type}</div>
              </div>
              
              <div className={styles.purchaseItem}>
                <div className={styles.purchaseLabel}>{t('financing_type')}:</div>
                <div className={styles.purchaseValue}>{purchaseInfo.financing_type}</div>
              </div>
              
              <div className={styles.purchaseItem}>
                <div className={styles.purchaseLabel}>{t('financing_term')}:</div>
                <div className={styles.purchaseValue}>
                  {purchaseInfo.financing_term} {t('months')}
                </div>
              </div>
              
              <div className={styles.purchaseItem}>
                <div className={styles.purchaseLabel}>{t('down_payment')}:</div>
                <div className={styles.purchaseValue}>
                  {formatCurrency(purchaseInfo.down_payment)}
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.noData}>{t('no_purchase_information_available')}</div>
          )}
        </div>
      </div>
      
      <div className={styles.card}>
        <h3 className={styles.cardTitle}>{t('valuation_history')}</h3>
        <div className={styles.cardContent}>
          {sortedValuations.length > 0 ? (
            <div className={styles.tableContainer}>
              <table className={styles.table}>
                <thead>
                  <tr>
                    <th>{t('date')}</th>
                    <th>{t('valuation_type')}</th>
                    <th>{t('value')}</th>
                    <th>{t('source')}</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedValuations.map((valuation) => (
                    <tr key={valuation.vehicle_price_id}>
                      <td>{formatDate(valuation.price_timestamp)}</td>
                      <td>{valuation.price_type}</td>
                      <td>{formatCurrency(valuation.price, valuation.price_currency)}</td>
                      <td>{valuation.price_source}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className={styles.noData}>{t('no_valuation_history_available')}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VehicleValuation;