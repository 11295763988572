// File: frontend/src/contexts/RoleBasedAccessContext.js

import React, { createContext, useContext, useEffect, useState } from 'react';
import { useUser } from './UserContext';
import { useNavigate } from 'react-router-dom';
import { ENUMS } from '../config/constants';

const RoleBasedAccessContext = createContext();

// Define role hierarchies and permissions
const ROLE_LEVELS = {
  SUPER_ADMIN: 4,
  ADMIN: 3,
  MANAGER: 2,
  STANDARD: 1,
  GUEST: 0
};

const getRoleLevel = (roleKey) => {
  // Convert role key to role level
  switch (roleKey) {
    case 'super_admin': return ROLE_LEVELS.SUPER_ADMIN;
    case 'admin': return ROLE_LEVELS.ADMIN;
    case 'manager': return ROLE_LEVELS.MANAGER;
    case 'standard': return ROLE_LEVELS.STANDARD;
    default: return ROLE_LEVELS.GUEST;
  }
};

export const RoleBasedAccessProvider = ({ children }) => {
  const { user, isLoading } = useUser();
  const navigate = useNavigate();
  const [currentRole, setCurrentRole] = useState(null);

  // Update role when user changes
  useEffect(() => {
    console.log('RoleBasedAccess: User state changed', {
      hasUser: !!user,
      roleKey: user?.role_key
    });

    if (user?.role_key) {
      setCurrentRole(user.role_key);
    } else {
      // Try to get role from localStorage as fallback
      const storedUser = JSON.parse(localStorage.getItem('user_data'));
      if (storedUser?.role_key) {
        setCurrentRole(storedUser.role_key);
      } else {
        setCurrentRole(null);
      }
    }
  }, [user]);

  const hasRole = (requiredRoleKey) => {
    // First check current role from state
    const roleToCheck = currentRole || user?.role_key;
    
    if (!roleToCheck) {
      console.log('RoleBasedAccess: No role found', {
        currentRole,
        userRole: user?.role_key
      });
      return false;
    }
    
    const userRoleLevel = getRoleLevel(roleToCheck);
    const requiredRoleLevel = getRoleLevel(requiredRoleKey);
    
    return userRoleLevel >= requiredRoleLevel;
  };

  const checkAccess = (requiredRoleKey, redirectPath = '/') => {
    // Don't check access while loading
    if (isLoading) return true;

    if (!hasRole(requiredRoleKey)) {
      console.log('RoleBasedAccess: Access denied', {
        required: requiredRoleKey,
        current: currentRole || user?.role_key
      });
      navigate(redirectPath);
      return false;
    }
    return true;
  };

  // Helper component for route protection
  const ProtectedRoute = ({ requiredRole, redirectPath = '/', children }) => {
    useEffect(() => {
      if (!isLoading) {
        checkAccess(requiredRole, redirectPath);
      }
    }, [requiredRole, redirectPath, isLoading]);

    if (isLoading) return null; // Or a loading spinner
    return hasRole(requiredRole) ? children : null;
  };

  return (
    <RoleBasedAccessContext.Provider 
      value={{ 
        hasRole,
        checkAccess,
        ProtectedRoute,
        userRole: currentRole || user?.role_key,
        roleLevel: currentRole ? 
          getRoleLevel(currentRole) : 
          user?.role_key ? 
            getRoleLevel(user.role_key) : 
            ROLE_LEVELS.GUEST,
        isLoading
      }}
    >
      {children}
    </RoleBasedAccessContext.Provider>
  );
};

export const useRoleBasedAccess = () => {
  const context = useContext(RoleBasedAccessContext);
  if (context === undefined) {
    throw new Error('useRoleBasedAccess must be used within a RoleBasedAccessProvider');
  }
  return context;
};