// File: frontend/src/pages/InvestorPitch/constants/animations.js

export const ANIMATION_VARIANTS = {
    slideContainer: {
      enter: {
        x: 0,
        opacity: 1,
        transition: {
          duration: 0.5,
          ease: 'easeOut'
        }
      },
      exit: {
        x: -50,
        opacity: 0,
        transition: {
          duration: 0.3,
          ease: 'easeIn'
        }
      }
    },
    fadeIn: {
      hidden: { opacity: 0, y: 20 },
      visible: {
        opacity: 1,
        y: 0,
        transition: {
          duration: 0.5,
          ease: 'easeOut'
        }
      }
    },
    scale: {
      hover: { scale: 1.05 },
      tap: { scale: 0.95 }
    },
    stagger: {
      container: {
        hidden: { opacity: 0 },
        visible: {
          opacity: 1,
          transition: {
            staggerChildren: 0.1
          }
        }
      },
      item: {
        hidden: { opacity: 0, y: 20 },
        visible: {
          opacity: 1,
          y: 0
        }
      }
    }
  };
  
  export const TRANSITION_SPRING = {
    type: 'spring',
    stiffness: 300,
    damping: 30
  };
  
  export const SLIDE_DIRECTION = {
    NEXT: 'next',
    PREVIOUS: 'prev'
  };