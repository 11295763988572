// File: frontend/src/pages/Auth/VerificationFailure.js

import React, { useEffect, useState } from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContextV2';
import { AUTH_FLOW_STATES } from '../../contexts/AuthContextV2'; // Add this import
import AuthLayout from '../../components/layout/AuthLayout/AuthLayout';
import StatusScreen from '../../components/Auth/StatusScreen';
import styles from './VerificationFailure.module.css';

const ERROR_MESSAGES = {
    'missing_token': 'auth.verificationFailure.errorTypes.missingToken',
    'invalid_token': 'auth.verificationFailure.errorTypes.invalidToken',
    'expired_token': 'auth.verificationFailure.errorTypes.expiredToken',
    'already_verified': 'auth.verificationFailure.errorTypes.alreadyVerified',
    'verification_failed': 'auth.verificationFailure.errorTypes.verificationFailed',
    'unknown': 'auth.verificationFailure.errorTypes.unknown'
};

const VerificationFailure = () => {
    const { t } = useLanguage();
    const navigate = useNavigate();
    const location = useLocation();
    const { updateFlowState, clearFlow } = useAuth(); // Update this
    const [isContacting, setIsContacting] = useState(false);

    const errorCode = location.state?.errorCode || 'unknown';
    const errorMessage = location.state?.errorMessage;
    const email = location.state?.email;

    useEffect(() => {
        // Clear the auth flow state but maintain any necessary information
        clearFlow();
        
        // If we have an email, update the flow state for potential retry
        if (email) {
            updateFlowState(AUTH_FLOW_STATES.SIGNUP.EMAIL_VERIFICATION, {
                email_address: email,
                verification_failed: true,
                error_code: errorCode,
                error_message: errorMessage
            });
        }
    }, [clearFlow, updateFlowState, email, errorCode, errorMessage]);

    const handleContactSupport = async () => {
        setIsContacting(true);
        try {
            // Navigate to contact page with error details
            navigate('/contact', { 
                state: { 
                    errorCode,
                    errorMessage,
                    email,
                    timestamp: new Date().toISOString(),
                    type: 'verification_failure'
                }
            });
        } catch (error) {
            console.error('Failed to contact support:', error);
            setIsContacting(false);
        }
    };

    const handleTryAgain = () => {
        if (email) {
            // If we have an email, we can request a new verification email
            updateFlowState(AUTH_FLOW_STATES.SIGNUP.EMAIL_VERIFICATION, {
                email_address: email
            });
            navigate('/email-verification', { 
                state: { 
                    email,
                    isRetry: true
                },
                replace: true 
            });
        } else {
            // If no email, go back to sign in
            navigate('/sign-in', { replace: true });
        }
    };

    const getErrorMessage = () => {
        // If we have a specific error message from the server, use it
        if (errorMessage) {
            return errorMessage;
        }
        // Otherwise, use our mapped messages
        return t(ERROR_MESSAGES[errorCode] || ERROR_MESSAGES.unknown);
    };

    const additionalInfo = (
        <div className={styles.failureInfo}>
            <div className={styles.errorDetails}>
                <div className={styles.errorItem}>
                    <span className={styles.errorLabel}>
                        {t('auth.verificationFailure.errorCode')}
                    </span>
                    <span className={styles.errorValue}>{errorCode}</span>
                </div>
                {email && (
                    <div className={styles.errorItem}>
                        <span className={styles.errorLabel}>
                            {t('auth.verificationFailure.email')}
                        </span>
                        <span className={styles.errorValue}>{email}</span>
                    </div>
                )}
                <div className={styles.errorItem}>
                    <span className={styles.errorLabel}>
                        {t('auth.verificationFailure.timestamp')}
                    </span>
                    <span className={styles.errorValue}>
                        {new Date().toLocaleString()}
                    </span>
                </div>
            </div>

            <div className={styles.actionButtons}>
                <button 
                    className={styles.contactButton}
                    onClick={handleContactSupport}
                    disabled={isContacting}
                >
                    {isContacting ? (
                        <>
                            <div className={styles.spinner} />
                            {t('auth.verificationFailure.contacting')}
                        </>
                    ) : (
                        t('auth.verificationFailure.contactSupport')
                    )}
                </button>
                
                <button 
                    className={styles.tryAgainButton}
                    onClick={handleTryAgain}
                >
                    {t('auth.verificationFailure.tryAgain')}
                </button>
            </div>
        </div>
    );

    return (
        <AuthLayout
            title={t('auth.signUp.title')}
            showHomeButton={true}
        >
            <div className={styles.verificationContainer}>
                <div className={styles.verificationContent}>
                    <StatusScreen
                        icon="/icons/18/alerts/error.svg"
                        title={t('auth.verificationFailure.title')}
                        message={getErrorMessage()}
                        type="error"
                        additionalInfo={additionalInfo}
                    />
                </div>
            </div>
        </AuthLayout>
    );
};

export default React.memo(VerificationFailure);