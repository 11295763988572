// File: frontend/src/components/Pagination/Pagination.js

import React from 'react';
import PropTypes from 'prop-types';
import { useLanguage } from '../../contexts/LanguageContext';
import styles from './Pagination.module.css';

const Pagination = ({ current, total, onChange, size = 'medium' }) => {
  const { t } = useLanguage();

  const renderPageNumbers = () => {
    const pages = [];
    for (let i = 1; i <= total; i++) {
      pages.push(
        <button
          key={i}
          className={`${styles.pageNumber} ${current === i ? styles.active : ''} ${styles[size]}`}
          onClick={() => onChange(i)}
          aria-label={t('pagination_page_label', { page: i })}
          aria-current={current === i ? 'page' : undefined}
        >
          {i}
        </button>
      );
    }
    return pages;
  };

  return (
    <nav className={styles.pagination} aria-label={t('pagination_nav_label')}>
      <button
        className={`${styles.navButton} ${styles[size]}`}
        onClick={() => onChange(current - 1)}
        disabled={current === 1}
        aria-label={t('pagination_previous_label')}
      >
        <span className={styles.srOnly}>{t('pagination_previous')}</span>
        <img src="/icons/18/misc/chevron-left.svg" alt="" aria-hidden="true" />
      </button>
      {renderPageNumbers()}
      <button
        className={`${styles.navButton} ${styles[size]}`}
        onClick={() => onChange(current + 1)}
        disabled={current === total}
        aria-label={t('pagination_next_label')}
      >
        <span className={styles.srOnly}>{t('pagination_next')}</span>
        <img src="/icons/18/misc/chevron-right.svg" alt="" aria-hidden="true" />
      </button>
    </nav>
  );
};

Pagination.propTypes = {
  current: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['medium', 'small', 'xsmall']),
};

export default Pagination;