// File: frontend/src/pages/EmbeddedInsurance/components/ProductBuilder/index.js

import React, { useState } from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import CoverageSelector from './CoverageSelector';
import JurisdictionSelector from './JurisdictionSelector';
import PricingRules from './PricingRules';
import UnderwritingRules from './UnderwritingRules';
import ProductPreview from './ProductPreview';
import styles from './styles/ProductBuilder.module.css';

const ProductBuilder = ({
  selectedProduct,
  onProductSelect,
  selectedCoverage,
  onCoverageSelect,
  selectedJurisdiction,
  onJurisdictionSelect
}) => {
  const { t } = useLanguage();
  const [activeStep, setActiveStep] = useState('coverage');
  const [pricingRules, setPricingRules] = useState([]);
  const [underwritingRules, setUnderwritingRules] = useState([]);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const steps = [
    { id: 'coverage', label: t('select_coverage') || 'Select Coverage', icon: 'shield-alt' },
    { id: 'jurisdiction', label: t('select_jurisdiction') || 'Select Jurisdiction', icon: 'map-marker-alt' },
    { id: 'pricing', label: t('pricing_rules') || 'Pricing Rules', icon: 'dollar-sign' },
    { id: 'underwriting', label: t('underwriting_rules') || 'Underwriting Rules', icon: 'clipboard-check' },
  ];

  const handleStepChange = (stepId) => {
    setActiveStep(stepId);
  };

  const handleNext = () => {
    const currentIndex = steps.findIndex(step => step.id === activeStep);
    if (currentIndex < steps.length - 1) {
      setActiveStep(steps[currentIndex + 1].id);
    } else {
      setIsPreviewOpen(true);
    }
  };

  const handleBack = () => {
    const currentIndex = steps.findIndex(step => step.id === activeStep);
    if (currentIndex > 0) {
      setActiveStep(steps[currentIndex - 1].id);
    }
  };

  const handlePricingRulesChange = (rules) => {
    setPricingRules(rules);
  };

  const handleUnderwritingRulesChange = (rules) => {
    setUnderwritingRules(rules);
  };

  const handlePreviewClose = () => {
    setIsPreviewOpen(false);
  };

  const isStepComplete = (stepId) => {
    switch (stepId) {
      case 'coverage':
        return !!selectedProduct && !!selectedCoverage;
      case 'jurisdiction':
        return !!selectedJurisdiction;
      case 'pricing':
        return pricingRules.length > 0;
      case 'underwriting':
        return underwritingRules.length > 0;
      default:
        return false;
    }
  };

  const canProceed = isStepComplete(activeStep);

  return (
    <div className={styles.productBuilder}>
      <div className={styles.stepIndicator}>
        {steps.map((step, index) => (
          <React.Fragment key={step.id}>
            {index > 0 && <div className={`${styles.stepConnector} ${isStepComplete(steps[index - 1].id) ? styles.completed : ''}`} />}
            <div 
              className={`${styles.step} ${activeStep === step.id ? styles.activeStep : ''} ${isStepComplete(step.id) ? styles.completedStep : ''}`}
              onClick={() => handleStepChange(step.id)}
            >
              <div className={styles.stepIcon}>
                <i className={`fas fa-${step.icon}`}></i>
              </div>
              <div className={styles.stepLabel}>{step.label}</div>
            </div>
          </React.Fragment>
        ))}
      </div>

      <div className={styles.stepContent}>
        {activeStep === 'coverage' && (
          <CoverageSelector
            selectedProduct={selectedProduct}
            onProductSelect={onProductSelect}
            selectedCoverage={selectedCoverage}
            onCoverageSelect={onCoverageSelect}
          />
        )}
        
        {activeStep === 'jurisdiction' && (
          <JurisdictionSelector
            selectedJurisdiction={selectedJurisdiction}
            onJurisdictionSelect={onJurisdictionSelect}
          />
        )}
        
        {activeStep === 'pricing' && (
          <PricingRules
            selectedProduct={selectedProduct}
            selectedCoverage={selectedCoverage}
            selectedJurisdiction={selectedJurisdiction}
            pricingRules={pricingRules}
            onChange={handlePricingRulesChange}
          />
        )}
        
        {activeStep === 'underwriting' && (
          <UnderwritingRules
            selectedProduct={selectedProduct}
            selectedCoverage={selectedCoverage}
            selectedJurisdiction={selectedJurisdiction}
            underwritingRules={underwritingRules}
            onChange={handleUnderwritingRulesChange}
          />
        )}
      </div>

      <div className={styles.stepActions}>
        <button
          className={styles.backButton}
          onClick={handleBack}
          disabled={activeStep === steps[0].id}
        >
          <i className="fas fa-arrow-left"></i>
          <span>{t('back') || 'Back'}</span>
        </button>
        
        <button
          className={styles.nextButton}
          onClick={handleNext}
          disabled={!canProceed}
        >
          {activeStep === steps[steps.length - 1].id ? (
            <>
              <span>{t('finish') || 'Finish'}</span>
              <i className="fas fa-check"></i>
            </>
          ) : (
            <>
              <span>{t('next') || 'Next'}</span>
              <i className="fas fa-arrow-right"></i>
            </>
          )}
        </button>
      </div>

      {isPreviewOpen && (
        <ProductPreview
          product={selectedProduct}
          coverage={selectedCoverage}
          jurisdiction={selectedJurisdiction}
          pricingRules={pricingRules}
          underwritingRules={underwritingRules}
          onClose={handlePreviewClose}
        />
      )}
    </div>
  );
};

export default ProductBuilder;