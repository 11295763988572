// File: frontend/src/pages/SuperAdmin/components/RbacManagement/PermissionSettings/index.js

import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from './PermissionSettings.module.css';

const PermissionSettings = ({ onBack }) => {
  const { t } = useLanguage();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [permissions, setPermissions] = useState({});
  const [activeTab, setActiveTab] = useState('resources');

  useEffect(() => {
    const fetchPermissionSettings = async () => {
      setLoading(true);
      setError(null);
      
      try {
        // This would be replaced with an actual API call
        // const response = await rbacService.getPermissionSettings();
        
        // Mock data for now
        await new Promise(resolve => setTimeout(resolve, 800));
        
        const mockPermissions = {
          resources: [
            {
              id: 'users',
              name: 'Users',
              description: 'User management and access control',
              actions: ['view', 'create', 'update', 'delete'],
              icon: 'users'
            },
            {
              id: 'enterprises',
              name: 'Enterprises',
              description: 'Enterprise account management',
              actions: ['view', 'create', 'update', 'delete'],
              icon: 'building'
            },
            {
              id: 'products',
              name: 'Products',
              description: 'Product catalog and management',
              actions: ['view', 'create', 'update', 'delete'],
              icon: 'box'
            },
            {
              id: 'agreements',
              name: 'Agreements',
              description: 'Legal agreements and contracts',
              actions: ['view', 'create', 'update', 'delete'],
              icon: 'file-contract'
            },
            {
              id: 'subscriptions',
              name: 'Subscriptions',
              description: 'Subscription plans and billing',
              actions: ['view', 'create', 'update', 'delete'],
              icon: 'credit-card'
            },
            {
              id: 'rbac',
              name: 'RBAC',
              description: 'Role-based access control settings',
              actions: ['view', 'create', 'update', 'delete'],
              icon: 'shield-alt'
            },
            {
              id: 'reports',
              name: 'Reports',
              description: 'Reporting and analytics',
              actions: ['view', 'create', 'update', 'delete'],
              icon: 'chart-bar'
            },
            {
              id: 'settings',
              name: 'Settings',
              description: 'System configuration and settings',
              actions: ['view', 'update'],
              icon: 'cog'
            }
          ],
          actions: [
            {
              id: 'view',
              name: 'View',
              description: 'Permission to view and read data'
            },
            {
              id: 'create',
              name: 'Create',
              description: 'Permission to create new records'
            },
            {
              id: 'update',
              name: 'Update',
              description: 'Permission to modify existing records'
            },
            {
              id: 'delete',
              name: 'Delete',
              description: 'Permission to delete records'
            }
          ]
        };
        
        setPermissions(mockPermissions);
      } catch (err) {
        console.error('Error fetching permission settings:', err);
        setError(t('failed_to_load_permission_settings'));
      } finally {
        setLoading(false);
      }
    };
    
    fetchPermissionSettings();
  }, [t]);

  const handleAddResource = () => {
    // Implementation for adding a new resource
    console.log('Add resource');
  };

  const handleAddAction = () => {
    // Implementation for adding a new action
    console.log('Add action');
  };

  const handleSaveChanges = async () => {
    setSaving(true);
    
    try {
      // This would be replaced with an actual API call
      // await rbacService.updatePermissionSettings(permissions);
      
      // Mock update for now
      await new Promise(resolve => setTimeout(resolve, 800));
      
      // Show success message or notification
    } catch (err) {
      console.error('Error saving permission settings:', err);
      setError(t('failed_to_save_permission_settings'));
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <div className={styles.spinner}></div>
        <p>{t('loading_permission_settings')}</p>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <button className={styles.backButton} onClick={onBack} disabled={saving}>
          <i className="fas fa-arrow-left"></i>
          <span>{t('back')}</span>
        </button>
        
        <button 
          className={styles.saveButton} 
          onClick={handleSaveChanges}
          disabled={saving}
        >
          {saving ? (
            <>
              <span className={styles.spinner}></span>
              <span>{t('saving')}</span>
            </>
          ) : (
            <>
              <i className="fas fa-save"></i>
              <span>{t('save_changes')}</span>
            </>
          )}
        </button>
      </div>
      
      {error && (
        <div className={styles.error}>
          <i className="fas fa-exclamation-circle"></i>
          <span>{error}</span>
        </div>
      )}
      
      <div className={styles.tabs}>
        <button 
          className={`${styles.tab} ${activeTab === 'resources' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('resources')}
        >
          {t('resources')}
        </button>
        <button 
          className={`${styles.tab} ${activeTab === 'actions' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('actions')}
        >
          {t('actions')}
        </button>
      </div>
      
      <div className={styles.tabContent}>
        {activeTab === 'resources' && (
          <div className={styles.resourcesTab}>
            <div className={styles.tabHeader}>
              <h3 className={styles.tabTitle}>{t('resource_permissions')}</h3>
              <button className={styles.addButton} onClick={handleAddResource}>
                <i className="fas fa-plus"></i>
                <span>{t('add_resource')}</span>
              </button>
            </div>
            
            <p className={styles.tabDescription}>
              {t('resources_description')}
            </p>
            
            <div className={styles.resourcesList}>
              {permissions.resources && permissions.resources.map((resource) => (
                <div key={resource.id} className={styles.resourceCard}>
                  <div className={styles.resourceHeader}>
                    <div className={styles.resourceIcon}>
                      <i className={`fas fa-${resource.icon}`}></i>
                    </div>
                    <div className={styles.resourceInfo}>
                      <h4 className={styles.resourceName}>{resource.name}</h4>
                      <div className={styles.resourceId}>{resource.id}</div>
                    </div>
                    <div className={styles.resourceActions}>
                      <button className={styles.editButton}>
                        <i className="fas fa-edit"></i>
                      </button>
                    </div>
                  </div>
                  
                  <div className={styles.resourceDescription}>
                    {resource.description}
                  </div>
                  
                  <div className={styles.resourcePermissions}>
                    <div className={styles.permissionsLabel}>{t('allowed_actions')}:</div>
                    <div className={styles.actionsList}>
                      {resource.actions.map((action) => (
                        <span key={action} className={styles.actionBadge}>
                          {action}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        
        {activeTab === 'actions' && (
          <div className={styles.actionsTab}>
            <div className={styles.tabHeader}>
              <h3 className={styles.tabTitle}>{t('action_permissions')}</h3>
              <button className={styles.addButton} onClick={handleAddAction}>
                <i className="fas fa-plus"></i>
                <span>{t('add_action')}</span>
              </button>
            </div>
            
            <p className={styles.tabDescription}>
              {t('actions_description')}
            </p>
            
            <div className={styles.actionsTable}>
              <table>
                <thead>
                  <tr>
                    <th>{t('action_id')}</th>
                    <th>{t('action_name')}</th>
                    <th>{t('description')}</th>
                    <th>{t('actions')}</th>
                  </tr>
                </thead>
                <tbody>
                  {permissions.actions && permissions.actions.map((action) => (
                    <tr key={action.id}>
                      <td>{action.id}</td>
                      <td>{action.name}</td>
                      <td>{action.description}</td>
                      <td>
                        <div className={styles.tableActions}>
                          <button className={styles.editButton}>
                            <i className="fas fa-edit"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PermissionSettings;