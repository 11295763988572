// src/components/TripDetails/TripSpeedGraph.js
import React, { useMemo } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { useLanguage } from '../../contexts/LanguageContext';
import styles from './TripSpeedGraph.module.css';

const TripSpeedGraph = ({ tripData }) => {
  const { t } = useLanguage();

  const chartData = useMemo(() => {
    if (!tripData || !tripData.tripSpeedList || !tripData.tripSpeedList[0]) {
      return [];
    }

    // Parse the JSON string, replacing NaN with null
    const speedJSON = JSON.parse(tripData.tripSpeedList[0].speedJSON.replace(/NaN/g, 'null'));

    return speedJSON.timestampEpoch.map((timestamp, index) => ({
      time: new Date(timestamp * 1000).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }),
      speed: speedJSON.speedKMH[index] === null ? NaN : speedJSON.speedKMH[index],
      isNaN: speedJSON.speedKMH[index] === null
    }));
  }, [tripData]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className={styles.customTooltip}>
          <p>{`Time: ${label}`}</p>
          <p>{`Speed: ${isNaN(payload[0].value) ? 'No data' : `${payload[0].value.toFixed(2)} km/h`}`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className={styles.card}>
      <div className={styles.cardStandartTitleRow}>
        <div className={styles.text}>{t('trip_speed_graph')}</div>
        <div className={styles.button}>
          <img className={styles.icons18MoreHorizontal} alt="" src="/icons/18/misc/show-more-horizontal-three-dots.svg" />
        </div>
      </div>
      <div className={styles.cardChartContent}>
        <div className={styles.paragraphContainer}>
          <div className={styles.chartTitle}>{t('trip_speed_over_time')}</div>
          <div className={styles.text1}>{t('speed_data_description')}</div>
        </div>
        <div className={styles.chart}>
          <ResponsiveContainer width="100%" height={300}>
            <AreaChart data={chartData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="time" />
              <YAxis />
              <Tooltip content={<CustomTooltip />} />
              <Area
                type="monotone"
                dataKey="speed"
                stroke="#8884d8"
                fill="#8884d8"
                fillOpacity={0.3}
                connectNulls={true}
                dot={(props) => {
                  if (props.payload.isNaN) {
                    return (
                      <circle
                        cx={props.cx}
                        cy={props.cy}
                        r={4}
                        fill="red"
                        stroke="none"
                      />
                    );
                  }
                  return null;
                }}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default TripSpeedGraph;