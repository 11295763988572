// /charts/v2/core/ChartWrapper.js
import React from 'react';
import styles from './ChartWrapper.module.css';

const ChartWrapper = ({ title, subtitle, children, className }) => {
  return (
    <div className={`${styles.chartContainer} ${className || ''}`}>
      {title && <h3 className={styles.chartTitle}>{title}</h3>}
      {subtitle && <h4 className={styles.chartSubtitle}>{subtitle}</h4>}
      {children}
    </div>
  );
};

export default ChartWrapper;