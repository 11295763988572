// File: frontend/src/pages/SuperAdmin/components/AgreementManagement/AgreementDetail/index.js

import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from './AgreementDetail.module.css';

const AgreementDetail = ({ agreementId, onBack, onEdit, isEditing = false, onSave }) => {
  const { t } = useLanguage();
  const [agreement, setAgreement] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('overview');
  const [formData, setFormData] = useState({});
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    const fetchAgreementDetails = async () => {
      setLoading(true);
      setError(null);
      
      try {
        // This would be replaced with an actual API call
        // const response = await agreementService.getAgreementDetails(agreementId);
        
        // Mock data for now
        await new Promise(resolve => setTimeout(resolve, 800));
        
        const mockAgreement = {
          agreement_id: agreementId,
          enterprise_id: 'ent-12345',
          enterprise_name: 'Acme Corporation',
          agreement_start_timestamp: '2023-01-15T00:00:00Z',
          agreement_end_timestamp: '2024-01-14T23:59:59Z',
          agreement_type: 'production',
          agreement_status: 'active',
          agreement_notes: {
            notes: 'Standard agreement for production use',
            terms: 'Net 30 payment terms'
          },
          document_links: {
            contract: 'https://example.com/contracts/acme-contract.pdf',
            terms: 'https://example.com/terms/acme-terms.pdf'
          },
          terms_and_conditions: 'This agreement is subject to the standard terms and conditions of service. The customer agrees to pay for all services rendered within 30 days of invoice date. Early termination fees may apply if agreement is cancelled before the end date.',
          renewal_terms: {
            auto_renew: true,
            renewal_period: '1 year',
            price_increase_cap: '5%',
            renewal_notification_days: 60
          },
          created_at: '2023-01-10T15:30:00Z',
          updated_at: '2023-01-10T15:30:00Z',
          created_by: 'admin-user-1',
          updated_by: 'admin-user-1',
          
          // Additional related data
          enterprise: {
            enterprise_id: 'ent-12345',
            enterprise_name: 'Acme Corporation',
            enterprise_status: 'active',
            country_code: 'US',
            province_state_code: 'CA'
          },
          
          subscriptions: [
            {
              subscription_id: 'subs-12345',
              subscription_status: 'active',
              subscription_tier: 'enterprise',
              billing_frequency: 'monthly',
              subscription_start_date: '2023-01-15',
              subscription_end_date: '2024-01-14'
            }
          ],
          
          products: [
            {
              product_id: 'prod-12345',
              product_name: 'Analytics Dashboard',
              product_status: 'active'
            },
            {
              product_id: 'prod-23456',
              product_name: 'Risk Assessment Tool',
              product_status: 'active'
            }
          ]
        };
        
        setAgreement(mockAgreement);
        
        // Initialize form data for editing
        if (isEditing) {
          setFormData({
            agreement_type: mockAgreement.agreement_type,
            agreement_status: mockAgreement.agreement_status,
            agreement_start_timestamp: mockAgreement.agreement_start_timestamp.split('T')[0],
            agreement_end_timestamp: mockAgreement.agreement_end_timestamp.split('T')[0],
            agreement_notes: mockAgreement.agreement_notes.notes,
            terms_and_conditions: mockAgreement.terms_and_conditions,
            auto_renew: mockAgreement.renewal_terms.auto_renew,
            renewal_period: mockAgreement.renewal_terms.renewal_period,
            price_increase_cap: mockAgreement.renewal_terms.price_increase_cap,
            renewal_notification_days: mockAgreement.renewal_terms.renewal_notification_days
          });
        }
      } catch (err) {
        console.error('Error fetching agreement details:', err);
        setError(t('failed_to_load_agreement_details'));
      } finally {
        setLoading(false);
      }
    };
    
    if (agreementId) {
      fetchAgreementDetails();
    }
  }, [agreementId, isEditing, t]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setSaving(true);
    
    try {
      // This would be replaced with an actual API call
      // await agreementService.updateAgreement(agreementId, {
      //   agreement_type: formData.agreement_type,
      //   agreement_status: formData.agreement_status,
      //   agreement_start_timestamp: `${formData.agreement_start_timestamp}T00:00:00Z`,
      //   agreement_end_timestamp: `${formData.agreement_end_timestamp}T23:59:59Z`,
      //   agreement_notes: {
      //     ...agreement.agreement_notes,
      //     notes: formData.agreement_notes
      //   },
      //   terms_and_conditions: formData.terms_and_conditions,
      //   renewal_terms: {
      //     auto_renew: formData.auto_renew,
      //     renewal_period: formData.renewal_period,
      //     price_increase_cap: formData.price_increase_cap,
      //     renewal_notification_days: parseInt(formData.renewal_notification_days)
      //   }
      // });
      
      // Mock update for now
      await new Promise(resolve => setTimeout(resolve, 800));
      
      // Call the save callback
      if (onSave) {
        onSave();
      }
    } catch (err) {
      console.error('Error updating agreement:', err);
      setError(t('failed_to_update_agreement'));
    } finally {
      setSaving(false);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }).format(date);
  };

  const getStatusBadgeClass = (status) => {
    switch (status) {
      case 'active':
        return styles.statusActive;
      case 'expired':
        return styles.statusExpired;
      case 'terminated':
        return styles.statusTerminated;
      case 'pending':
        return styles.statusPending;
      default:
        return '';
    }
  };

  const getAgreementTypeClass = (type) => {
    switch (type) {
      case 'production':
        return styles.typeProduction;
      case 'trial':
        return styles.typeTrial;
      case 'pilot':
        return styles.typePilot;
      default:
        return '';
    }
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <div className={styles.spinner}></div>
        <p>{t('loading_agreement_details')}</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.error}>
        <p>{error}</p>
        <button className={styles.backButton} onClick={onBack}>
          {t('back_to_agreements')}
        </button>
      </div>
    );
  }

  if (!agreement) {
    return (
      <div className={styles.notFound}>
        <p>{t('agreement_not_found')}</p>
        <button className={styles.backButton} onClick={onBack}>
          {t('back_to_agreements')}
        </button>
      </div>
    );
  }

  if (isEditing) {
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <button className={styles.backButton} onClick={onBack} disabled={saving}>
            <i className="fas fa-arrow-left"></i>
            <span>{t('back')}</span>
          </button>
          
          <div className={styles.headerContent}>
            <h2 className={styles.agreementTitle}>{t('edit_agreement')}</h2>
            <div className={styles.agreementId}>{agreement.agreement_id}</div>
          </div>
        </div>
        
        {error && (
          <div className={styles.errorMessage}>
            <i className="fas fa-exclamation-circle"></i>
            <span>{error}</span>
          </div>
        )}
        
        <form className={styles.form} onSubmit={handleSave}>
          <div className={styles.formSection}>
            <h3 className={styles.sectionTitle}>{t('agreement_details')}</h3>
            <div className={styles.formGrid}>
              <div className={styles.formGroup}>
                <label htmlFor="agreement_type" className={styles.label}>
                  {t('agreement_type')} <span className={styles.required}>*</span>
                </label>
                <select
                  id="agreement_type"
                  name="agreement_type"
                  className={styles.select}
                  value={formData.agreement_type}
                  onChange={handleChange}
                  required
                  disabled={saving}
                >
                  <option value="production">{t('production')}</option>
                  <option value="trial">{t('trial')}</option>
                  <option value="pilot">{t('pilot')}</option>
                </select>
              </div>
              
              <div className={styles.formGroup}>
                <label htmlFor="agreement_status" className={styles.label}>
                  {t('status')} <span className={styles.required}>*</span>
                </label>
                <select
                  id="agreement_status"
                  name="agreement_status"
                  className={styles.select}
                  value={formData.agreement_status}
                  onChange={handleChange}
                  required
                  disabled={saving}
                >
                  <option value="active">{t('active')}</option>
                  <option value="expired">{t('expired')}</option>
                  <option value="terminated">{t('terminated')}</option>
                  <option value="pending">{t('pending')}</option>
                </select>
              </div>
              
              <div className={styles.formGroup}>
                <label htmlFor="agreement_start_timestamp" className={styles.label}>
                  {t('start_date')} <span className={styles.required}>*</span>
                </label>
                <input
                  type="date"
                  id="agreement_start_timestamp"
                  name="agreement_start_timestamp"
                  className={styles.input}
                  value={formData.agreement_start_timestamp}
                  onChange={handleChange}
                  required
                  disabled={saving}
                />
              </div>
              
              <div className={styles.formGroup}>
                <label htmlFor="agreement_end_timestamp" className={styles.label}>
                  {t('end_date')} <span className={styles.required}>*</span>
                </label>
                <input
                  type="date"
                  id="agreement_end_timestamp"
                  name="agreement_end_timestamp"
                  className={styles.input}
                  value={formData.agreement_end_timestamp}
                  onChange={handleChange}
                  required
                  disabled={saving}
                />
              </div>
              
              <div className={styles.formGroupFull}>
                <label htmlFor="agreement_notes" className={styles.label}>
                  {t('agreement_notes')}
                </label>
                <textarea
                  id="agreement_notes"
                  name="agreement_notes"
                  className={styles.textarea}
                  value={formData.agreement_notes}
                  onChange={handleChange}
                  rows={3}
                  disabled={saving}
                ></textarea>
              </div>
            </div>
          </div>
          
          <div className={styles.formSection}>
            <h3 className={styles.sectionTitle}>{t('terms_and_conditions')}</h3>
            <div className={styles.formGroup}>
              <textarea
                id="terms_and_conditions"
                name="terms_and_conditions"
                className={styles.textarea}
                value={formData.terms_and_conditions}
                onChange={handleChange}
                rows={6}
                disabled={saving}
              ></textarea>
            </div>
          </div>
          
          <div className={styles.formSection}>
            <h3 className={styles.sectionTitle}>{t('renewal_terms')}</h3>
            <div className={styles.formGrid}>
              <div className={styles.formGroup}>
                <div className={styles.checkboxContainer}>
                  <input
                    type="checkbox"
                    id="auto_renew"
                    name="auto_renew"
                    className={styles.checkbox}
                    checked={formData.auto_renew}
                    onChange={handleChange}
                    disabled={saving}
                  />
                  <label htmlFor="auto_renew" className={styles.checkboxLabel}>
                    {t('auto_renew')}
                  </label>
                </div>
              </div>
              
              <div className={styles.formGroup}>
                <label htmlFor="renewal_period" className={styles.label}>
                  {t('renewal_period')}
                </label>
                <input
                  type="text"
                  id="renewal_period"
                  name="renewal_period"
                  className={styles.input}
                  value={formData.renewal_period}
                  onChange={handleChange}
                  disabled={saving}
                />
              </div>
              
              <div className={styles.formGroup}>
                <label htmlFor="price_increase_cap" className={styles.label}>
                  {t('price_increase_cap')}
                </label>
                <input
                  type="text"
                  id="price_increase_cap"
                  name="price_increase_cap"
                  className={styles.input}
                  value={formData.price_increase_cap}
                  onChange={handleChange}
                  disabled={saving}
                />
              </div>
              
              <div className={styles.formGroup}>
                <label htmlFor="renewal_notification_days" className={styles.label}>
                  {t('renewal_notification_days')}
                </label>
                <input
                  type="number"
                  id="renewal_notification_days"
                  name="renewal_notification_days"
                  className={styles.input}
                  value={formData.renewal_notification_days}
                  onChange={handleChange}
                  min="0"
                  disabled={saving}
                />
              </div>
            </div>
          </div>
          
          <div className={styles.formActions}>
            <button 
              type="button" 
              className={styles.cancelButton} 
              onClick={onBack}
              disabled={saving}
            >
              {t('cancel')}
            </button>
            <button 
              type="submit" 
              className={styles.submitButton}
              disabled={saving}
            >
              {saving ? (
                <>
                  <span className={styles.spinner}></span>
                  <span>{t('saving')}</span>
                </>
              ) : (
                t('save_changes')
              )}
            </button>
          </div>
        </form>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <h2 className={styles.agreementTitle}>{t('agreement_with')} {agreement.enterprise_name}</h2>
          <div className={styles.agreementId}>ID: {agreement.agreement_id}</div>
          <div className={styles.statusContainer}>
            <span className={`${styles.statusBadge} ${getStatusBadgeClass(agreement.agreement_status)}`}>
              {agreement.agreement_status}
            </span>
            <span className={`${styles.typeBadge} ${getAgreementTypeClass(agreement.agreement_type)}`}>
              {agreement.agreement_type}
            </span>
          </div>
        </div>
        <div className={styles.headerActions}>
          <button className={styles.backButton} onClick={onBack}>
            <i className="fas fa-arrow-left"></i>
            <span>{t('back')}</span>
          </button>
          <button className={styles.editButton} onClick={onEdit}>
            <i className="fas fa-edit"></i>
            <span>{t('edit')}</span>
          </button>
        </div>
      </div>
      
      <div className={styles.tabs}>
        <button 
          className={`${styles.tab} ${activeTab === 'overview' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('overview')}
        >
          {t('overview')}
        </button>
        <button 
          className={`${styles.tab} ${activeTab === 'terms' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('terms')}
        >
          {t('terms_and_conditions')}
        </button>
        <button 
          className={`${styles.tab} ${activeTab === 'products' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('products')}
        >
          {t('products')}
        </button>
        <button 
          className={`${styles.tab} ${activeTab === 'subscriptions' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('subscriptions')}
        >
          {t('subscriptions')}
        </button>
        <button 
          className={`${styles.tab} ${activeTab === 'documents' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('documents')}
        >
          {t('documents')}
        </button>
      </div>
      
      <div className={styles.tabContent}>
        {activeTab === 'overview' && (
          <div className={styles.overviewTab}>
            <div className={styles.section}>
              <h3 className={styles.sectionTitle}>{t('agreement_information')}</h3>
              <div className={styles.infoGrid}>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('agreement_id')}</div>
                  <div className={styles.infoValue}>{agreement.agreement_id}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('agreement_type')}</div>
                  <div className={styles.infoValue}>
                    <span className={`${styles.typeBadge} ${getAgreementTypeClass(agreement.agreement_type)}`}>
                      {agreement.agreement_type}
                    </span>
                  </div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('status')}</div>
                  <div className={styles.infoValue}>
                    <span className={`${styles.statusBadge} ${getStatusBadgeClass(agreement.agreement_status)}`}>
                      {agreement.agreement_status}
                    </span>
                  </div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('start_date')}</div>
                  <div className={styles.infoValue}>{formatDate(agreement.agreement_start_timestamp)}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('end_date')}</div>
                  <div className={styles.infoValue}>{formatDate(agreement.agreement_end_timestamp)}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('duration')}</div>
                  <div className={styles.infoValue}>
                    {calculateDuration(agreement.agreement_start_timestamp, agreement.agreement_end_timestamp)}
                  </div>
                </div>
              </div>
            </div>
            
            <div className={styles.section}>
              <h3 className={styles.sectionTitle}>{t('enterprise_information')}</h3>
              <div className={styles.infoGrid}>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('enterprise_id')}</div>
                  <div className={styles.infoValue}>{agreement.enterprise_id}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('enterprise_name')}</div>
                  <div className={styles.infoValue}>{agreement.enterprise_name}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('enterprise_status')}</div>
                  <div className={styles.infoValue}>
                    <span className={`${styles.statusBadge} ${getStatusBadgeClass(agreement.enterprise.enterprise_status)}`}>
                      {agreement.enterprise.enterprise_status}
                    </span>
                  </div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('country')}</div>
                  <div className={styles.infoValue}>{agreement.enterprise.country_code}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('province_state')}</div>
                  <div className={styles.infoValue}>{agreement.enterprise.province_state_code}</div>
                </div>
              </div>
            </div>
            
            <div className={styles.section}>
              <h3 className={styles.sectionTitle}>{t('agreement_notes')}</h3>
              <div className={styles.notesContainer}>
                <p className={styles.notes}>{agreement.agreement_notes.notes}</p>
                {agreement.agreement_notes.terms && (
                  <div className={styles.termsNote}>
                    <div className={styles.termsNoteLabel}>{t('payment_terms')}:</div>
                    <div className={styles.termsNoteValue}>{agreement.agreement_notes.terms}</div>
                  </div>
                )}
              </div>
            </div>
            
            <div className={styles.section}>
              <h3 className={styles.sectionTitle}>{t('renewal_information')}</h3>
              <div className={styles.renewalInfo}>
                <div className={styles.renewalItem}>
                  <div className={styles.renewalLabel}>{t('auto_renew')}:</div>
                  <div className={styles.renewalValue}>
                    {agreement.renewal_terms.auto_renew ? t('yes') : t('no')}
                  </div>
                </div>
                <div className={styles.renewalItem}>
                  <div className={styles.renewalLabel}>{t('renewal_period')}:</div>
                  <div className={styles.renewalValue}>{agreement.renewal_terms.renewal_period}</div>
                </div>
                <div className={styles.renewalItem}>
                  <div className={styles.renewalLabel}>{t('price_increase_cap')}:</div>
                  <div className={styles.renewalValue}>{agreement.renewal_terms.price_increase_cap}</div>
                </div>
                <div className={styles.renewalItem}>
                  <div className={styles.renewalLabel}>{t('renewal_notification_days')}:</div>
                  <div className={styles.renewalValue}>{agreement.renewal_terms.renewal_notification_days} {t('days')}</div>
                </div>
              </div>
            </div>
            
            <div className={styles.section}>
              <h3 className={styles.sectionTitle}>{t('audit_information')}</h3>
              <div className={styles.infoGrid}>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('created_at')}</div>
                  <div className={styles.infoValue}>{formatDate(agreement.created_at)}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('created_by')}</div>
                  <div className={styles.infoValue}>{agreement.created_by}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('updated_at')}</div>
                  <div className={styles.infoValue}>{formatDate(agreement.updated_at)}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('updated_by')}</div>
                  <div className={styles.infoValue}>{agreement.updated_by}</div>
                </div>
              </div>
            </div>
          </div>
        )}
        
        {activeTab === 'terms' && (
          <div className={styles.termsTab}>
            <div className={styles.section}>
              <h3 className={styles.sectionTitle}>{t('terms_and_conditions')}</h3>
              <div className={styles.termsContainer}>
                <p className={styles.termsText}>{agreement.terms_and_conditions}</p>
              </div>
            </div>
          </div>
        )}
        
        {activeTab === 'products' && (
          <div className={styles.productsTab}>
            <div className={styles.section}>
              <h3 className={styles.sectionTitle}>{t('included_products')}</h3>
              
              {agreement.products && agreement.products.length > 0 ? (
                <div className={styles.productsList}>
                  <table className={styles.productsTable}>
                    <thead>
                      <tr>
                        <th>{t('product_id')}</th>
                        <th>{t('product_name')}</th>
                        <th>{t('status')}</th>
                        <th>{t('actions')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {agreement.products.map((product) => (
                        <tr key={product.product_id}>
                          <td>{product.product_id}</td>
                          <td>{product.product_name}</td>
                          <td>
                            <span className={`${styles.statusBadge} ${getStatusBadgeClass(product.product_status)}`}>
                              {product.product_status}
                            </span>
                          </td>
                          <td>
                            <div className={styles.tableActions}>
                              <button className={styles.actionButton}>
                                <i className="fas fa-eye"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className={styles.emptyState}>
                  <p>{t('no_products_found')}</p>
                </div>
              )}
            </div>
          </div>
        )}
        
        {activeTab === 'subscriptions' && (
          <div className={styles.subscriptionsTab}>
            <div className={styles.section}>
              <h3 className={styles.sectionTitle}>{t('related_subscriptions')}</h3>
              
              {agreement.subscriptions && agreement.subscriptions.length > 0 ? (
                <div className={styles.subscriptionsList}>
                  <table className={styles.subscriptionsTable}>
                    <thead>
                      <tr>
                        <th>{t('subscription_id')}</th>
                        <th>{t('tier')}</th>
                        <th>{t('status')}</th>
                        <th>{t('billing_frequency')}</th>
                        <th>{t('start_date')}</th>
                        <th>{t('end_date')}</th>
                        <th>{t('actions')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {agreement.subscriptions.map((subscription) => (
                        <tr key={subscription.subscription_id}>
                          <td>{subscription.subscription_id}</td>
                          <td>{subscription.subscription_tier}</td>
                          <td>
                            <span className={`${styles.statusBadge} ${getStatusBadgeClass(subscription.subscription_status)}`}>
                              {subscription.subscription_status}
                            </span>
                          </td>
                          <td>{subscription.billing_frequency}</td>
                          <td>{formatDate(subscription.subscription_start_date)}</td>
                          <td>{formatDate(subscription.subscription_end_date)}</td>
                          <td>
                            <div className={styles.tableActions}>
                              <button className={styles.actionButton}>
                                <i className="fas fa-eye"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className={styles.emptyState}>
                  <p>{t('no_subscriptions_found')}</p>
                </div>
              )}
            </div>
          </div>
        )}
        
        {activeTab === 'documents' && (
          <div className={styles.documentsTab}>
            <div className={styles.section}>
              <h3 className={styles.sectionTitle}>{t('agreement_documents')}</h3>
              
              {agreement.document_links && Object.keys(agreement.document_links).length > 0 ? (
                <div className={styles.documentsList}>
                  {Object.entries(agreement.document_links).map(([key, url]) => (
                    <div key={key} className={styles.documentItem}>
                      <div className={styles.documentIcon}>
                        <i className="fas fa-file-pdf"></i>
                      </div>
                      <div className={styles.documentInfo}>
                        <div className={styles.documentName}>
                          {key.charAt(0).toUpperCase() + key.slice(1)}
                        </div>
                        <div className={styles.documentUrl}>{url}</div>
                      </div>
                      <div className={styles.documentActions}>
                        <a 
                          href={url} 
                          target="_blank" 
                          rel="noopener noreferrer"
                          className={styles.documentButton}
                        >
                          <i className="fas fa-download"></i>
                          <span>{t('download')}</span>
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className={styles.emptyState}>
                  <p>{t('no_documents_found')}</p>
                </div>
              )}
              
              <div className={styles.uploadContainer}>
                <button className={styles.uploadButton}>
                  <i className="fas fa-upload"></i>
                  <span>{t('upload_document')}</span>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

// Helper function to calculate duration between two dates
const calculateDuration = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  
  // Calculate the difference in milliseconds
  const diffTime = Math.abs(end - start);
  
  // Calculate days
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  
  // Calculate months (approximate)
  const diffMonths = Math.round(diffDays / 30);
  
  // Calculate years
  const diffYears = Math.floor(diffMonths / 12);
  
  if (diffYears > 0) {
    const remainingMonths = diffMonths % 12;
    if (remainingMonths > 0) {
      return `${diffYears} year${diffYears > 1 ? 's' : ''}, ${remainingMonths} month${remainingMonths > 1 ? 's' : ''}`;
    }
    return `${diffYears} year${diffYears > 1 ? 's' : ''}`;
  } else if (diffMonths > 0) {
    return `${diffMonths} month${diffMonths > 1 ? 's' : ''}`;
  } else {
    return `${diffDays} day${diffDays > 1 ? 's' : ''}`;
  }
};

export default AgreementDetail;