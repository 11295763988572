// File: frontend/src/pages/Quote/components/ChatInterface/components/LockMessage.js

import React from 'react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from '../ChatInterface.module.css';

const LockMessage = ({ lockReason }) => {
  const { t } = useLanguage();
  
  return (
    <div className={styles.lockMessage}>
      <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 2a6 6 0 0 0-6 6v3H4v10h16V11h-2V8a6 6 0 0 0-6-6zm0 2a4 4 0 0 1 4 4v3H8V8a4 4 0 0 1 4-4z" fill="currentColor"/>
      </svg>
      <span>{lockReason || t('This chat is locked')}</span>
    </div>
  );
};

export default LockMessage;