// File: frontend/src/pages/AI1/components/AIHeader/AIHeader.js

import React from 'react';
import { Sparkles } from 'lucide-react';
import styles from './AIHeader.module.css';

const AIHeader = () => {
  return (
    <div className={styles.header}>
      <div className={styles.titleWrapper}>
        <Sparkles className={styles.icon} />
        <h2 className={styles.title}>Allegory Intelligence</h2>
      </div>
      <p className={styles.subtitle}>
        Get instant insights and answers powered by AI
      </p>
    </div>
  );
};

export default AIHeader;