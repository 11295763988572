// File: frontend/src/pages/Quote/components/SavingsCalculator/InsuranceTypes.js

// Insurance type data with cost of risk percentages and additional savings
export const getInsuranceTypes = (t) => ({
    // Personal Lines
    auto: {
      name: t('calculator.insurance_type.auto'),
      cta: t('calculator.insurance_type.auto.cta'),
      costOfRiskPercentage: 69,
      traditionalExpensePercentage: 25,
      additionalSavingsType: 'telematics',
      additionalSavingsPercentage: 30,
      additionalSavingsDescription: 'with telematics program'
    },
    home: {
      name: t('calculator.insurance_type.home'),
      cta: t('calculator.insurance_type.home.cta'),
      costOfRiskPercentage: 65,
      traditionalExpensePercentage: 30,
      additionalSavingsType: 'smart home',
      additionalSavingsPercentage: 20,
      additionalSavingsDescription: 'with smart home devices'
    },
    tenant: {
      name: t('calculator.insurance_type.tenant'),
      cta: t('calculator.insurance_type.tenant.cta'),
      costOfRiskPercentage: 55,
      traditionalExpensePercentage: 40,
      additionalSavingsType: 'bundling',
      additionalSavingsPercentage: 15,
      additionalSavingsDescription: 'with policy bundling'
    },
    umbrella: {
      name: t('calculator.insurance_type.umbrella'),
      cta: t('calculator.insurance_type.umbrella.cta'),
      costOfRiskPercentage: 50,
      traditionalExpensePercentage: 35,
      additionalSavingsType: 'bundling',
      additionalSavingsPercentage: 10,
      additionalSavingsDescription: 'with policy bundling'
    },
    dwelling: {
      name: t('calculator.insurance_type.dwelling'),
      cta: t('calculator.insurance_type.dwelling.cta'),
      costOfRiskPercentage: 62,
      traditionalExpensePercentage: 33,
      additionalSavingsType: 'safety features',
      additionalSavingsPercentage: 12,
      additionalSavingsDescription: 'with fire safety features'
    },
    pet: {
      name: t('calculator.insurance_type.pet'),
      cta: t('calculator.insurance_type.pet.cta'),
      costOfRiskPercentage: 60,
      traditionalExpensePercentage: 35,
      additionalSavingsType: 'wearables',
      additionalSavingsPercentage: 15,
      additionalSavingsDescription: 'with pet health wearables'
    },
    travel: {
      name: t('calculator.insurance_type.travel'),
      cta: t('calculator.insurance_type.travel.cta'),
      costOfRiskPercentage: 55,
      traditionalExpensePercentage: 40,
      additionalSavingsType: 'digital health',
      additionalSavingsPercentage: 15,
      additionalSavingsDescription: 'with digital health monitoring'
    },
    life: {
      name: t('calculator.insurance_type.life'),
      cta: t('calculator.insurance_type.life.cta'),
      costOfRiskPercentage: 80,
      traditionalExpensePercentage: 18,
      additionalSavingsType: 'health tracking',
      additionalSavingsPercentage: 10,
      additionalSavingsDescription: 'with health tracking devices'
    },
    
    // Commercial Lines
    commercialAuto: {
      name: t('calculator.insurance_type.commercialAuto'),
      cta: t('calculator.insurance_type.commercialAuto.cta'),
      costOfRiskPercentage: 68,
      traditionalExpensePercentage: 27,
      additionalSavingsType: 'fleet telematics',
      additionalSavingsPercentage: 18,
      additionalSavingsDescription: 'with fleet telematics program'
    },
    workersComp: {
      name: t('calculator.insurance_type.workersComp'),
      cta: t('calculator.insurance_type.workersComp.cta'),
      costOfRiskPercentage: 72,
      traditionalExpensePercentage: 25,
      additionalSavingsType: 'safety programs',
      additionalSavingsPercentage: 15,
      additionalSavingsDescription: 'with workplace safety programs'
    },
    commercialProperty: {
      name: t('calculator.insurance_type.commercialProperty'),
      cta: t('calculator.insurance_type.commercialProperty.cta'),
      costOfRiskPercentage: 60,
      traditionalExpensePercentage: 32,
      additionalSavingsType: 'risk management',
      additionalSavingsPercentage: 14,
      additionalSavingsDescription: 'with risk management protocols'
    },
    generalLiability: {
      name: t('calculator.insurance_type.generalLiability'),
      cta: t('calculator.insurance_type.generalLiability.cta'),
      costOfRiskPercentage: 58,
      traditionalExpensePercentage: 30,
      additionalSavingsType: 'risk assessment',
      additionalSavingsPercentage: 12,
      additionalSavingsDescription: 'with professional risk assessment'
    },
    businessOwners: {
      name: t('calculator.insurance_type.businessOwners'),
      cta: t('calculator.insurance_type.businessOwners.cta'),
      costOfRiskPercentage: 55,
      traditionalExpensePercentage: 35,
      additionalSavingsType: 'security systems',
      additionalSavingsPercentage: 15,
      additionalSavingsDescription: 'with enhanced security systems'
    },
    professionalLiability: {
      name: t('calculator.insurance_type.professionalLiability'),
      cta: t('calculator.insurance_type.professionalLiability.cta'),
      costOfRiskPercentage: 50,
      traditionalExpensePercentage: 35,
      additionalSavingsType: 'training programs',
      additionalSavingsPercentage: 10,
      additionalSavingsDescription: 'with professional training programs'
    },
    commercialUmbrella: {
      name: t('calculator.insurance_type.commercialUmbrella'),
      cta: t('calculator.insurance_type.commercialUmbrella.cta'),
      costOfRiskPercentage: 45,
      traditionalExpensePercentage: 30,
      additionalSavingsType: 'risk management',
      additionalSavingsPercentage: 8,
      additionalSavingsDescription: 'with comprehensive risk management'
    },
    
    // Specialty Lines
    cyber: {
      name: t('calculator.insurance_type.cyber'),
      cta: t('calculator.insurance_type.cyber.cta'),
      costOfRiskPercentage: 57,
      traditionalExpensePercentage: 38,
      additionalSavingsType: 'security protocols',
      additionalSavingsPercentage: 20,
      additionalSavingsDescription: 'with advanced security protocols'
    },
    productLiability: {
      name: t('calculator.insurance_type.productLiability'),
      cta: t('calculator.insurance_type.productLiability.cta'),
      costOfRiskPercentage: 60,
      traditionalExpensePercentage: 32,
      additionalSavingsType: 'quality control',
      additionalSavingsPercentage: 15,
      additionalSavingsDescription: 'with enhanced quality control'
    },
    directorAndOfficers: {
      name: t('calculator.insurance_type.directorAndOfficers'),
      cta: t('calculator.insurance_type.directorAndOfficers.cta'),
      costOfRiskPercentage: 52,
      traditionalExpensePercentage: 35,
      additionalSavingsType: 'governance',
      additionalSavingsPercentage: 12,
      additionalSavingsDescription: 'with improved corporate governance'
    },
    employmentPractices: {
      name: t('calculator.insurance_type.employmentPractices'),
      cta: t('calculator.insurance_type.employmentPractices.cta'),
      costOfRiskPercentage: 55,
      traditionalExpensePercentage: 38,
      additionalSavingsType: 'HR programs',
      additionalSavingsPercentage: 18,
      additionalSavingsDescription: 'with enhanced HR programs'
    },
    marineInsurance: {
      name: t('calculator.insurance_type.marineInsurance'),
      cta: t('calculator.insurance_type.marineInsurance.cta'),
      costOfRiskPercentage: 65,
      traditionalExpensePercentage: 30,
      additionalSavingsType: 'tracking systems',
      additionalSavingsPercentage: 14,
      additionalSavingsDescription: 'with advanced tracking systems'
    },
    aviation: {
      name: t('calculator.insurance_type.aviation'),
      cta: t('calculator.insurance_type.aviation.cta'),
      costOfRiskPercentage: 68,
      traditionalExpensePercentage: 27,
      additionalSavingsType: 'safety technologies',
      additionalSavingsPercentage: 12,
      additionalSavingsDescription: 'with advanced safety technologies'
    },
    farmAndRanch: {
      name: t('calculator.insurance_type.farmAndRanch'),
      cta: t('calculator.insurance_type.farmAndRanch.cta'),
      costOfRiskPercentage: 63,
      traditionalExpensePercentage: 32,
      additionalSavingsType: 'weather monitoring',
      additionalSavingsPercentage: 15,
      additionalSavingsDescription: 'with weather monitoring systems'
    },
    farmersMultiPeril: {
      name: t('calculator.insurance_type.farmersMultiPeril'),
      cta: t('calculator.insurance_type.farmersMultiPeril.cta'),
      costOfRiskPercentage: 82,
      traditionalExpensePercentage: 18,
      additionalSavingsType: 'precision agriculture',
      additionalSavingsPercentage: 12,
      additionalSavingsDescription: 'with precision agriculture technology'
    },
    
    // Other Common P&C Lines
    flood: {
      name: t('calculator.insurance_type.flood'),
      cta: t('calculator.insurance_type.flood.cta'),
      costOfRiskPercentage: 75,
      traditionalExpensePercentage: 25,
      additionalSavingsType: 'mitigation measures',
      additionalSavingsPercentage: 15,
      additionalSavingsDescription: 'with flood mitigation measures'
    },
    earthquake: {
      name: t('calculator.insurance_type.earthquake'),
      cta: t('calculator.insurance_type.earthquake.cta'),
      costOfRiskPercentage: 72,
      traditionalExpensePercentage: 28,
      additionalSavingsType: 'structural improvements',
      additionalSavingsPercentage: 18,
      additionalSavingsDescription: 'with structural improvements'
    },
    title: {
      name: t('calculator.insurance_type.title'),
      cta: t('calculator.insurance_type.title.cta'),
      costOfRiskPercentage: 5,
      traditionalExpensePercentage: 85,
      additionalSavingsType: 'electronic processing',
      additionalSavingsPercentage: 10,
      additionalSavingsDescription: 'with electronic title processing'
    },
    surety: {
      name: t('calculator.insurance_type.surety'),
      cta: t('calculator.insurance_type.surety.cta'),
      costOfRiskPercentage: 25,
      traditionalExpensePercentage: 50,
      additionalSavingsType: 'financial health',
      additionalSavingsPercentage: 15,
      additionalSavingsDescription: 'with improved financial health metrics'
    },
});