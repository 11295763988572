// File: frontend/src/components/JobApplicationForm/PositionSelect.js

import React, { useMemo } from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import Dropdown from '../form/Dropdown/Dropdown';
import { POSITION_CATEGORIES } from './jobApplicationConstants';
import styles from './PositionSelect.module.css';

const PositionSelect = ({ value = [], onChange, error, errorKey }) => {
    const { t } = useLanguage();
  
    const options = useMemo(() => 
      Object.entries(POSITION_CATEGORIES).flatMap(([category, data]) => [
        {
          value: `header-${category}`,
          labelKey: data.labelKey,
          isHeader: true,
          disabled: true,
          className: styles.categoryHeader
        },
        ...data.positions.map(positionId => ({
          value: positionId.toString(),
          labelKey: `positions.list.${positionId}`,
          category: category
        }))
      ]), []);
  
    const handleChange = (newValues) => {
      // Filter out header values
      const filteredValues = Array.isArray(newValues) 
        ? newValues.filter(val => !val.startsWith('header-'))
        : [];
  
      // Only update if we're within the 3-item limit
      if (filteredValues.length <= 3) {
        onChange(filteredValues);
      }
    };

  // Custom render function for options
  const renderOption = (option) => {
    if (option.isHeader) {
      return <div className={styles.categoryHeader}>{t(option.labelKey)}</div>;
    }
    return (
      <div className={styles.optionContainer}>
        <span className={styles.optionLabel}>{t(option.labelKey)}</span>
        {value.includes(option.value) && (
          <img 
            src="/icons/18/misc/check.svg" 
            alt=""
            className={styles.checkIcon}
          />
        )}
      </div>
    );
  };

  // Custom render function for selected values
  const renderSelected = (selectedValues) => {
    if (!selectedValues?.length) {
      return t('jobApplication.positions.placeholder');
    }

    // Map the selected values to their labels
    return selectedValues
      .map(val => {
        const option = options.find(opt => opt.value === val);
        return option ? t(option.labelKey) : '';
      })
      .filter(Boolean)
      .join(', ');
  };

  return (
    <div className={styles.selectPositionContainer}>
      <Dropdown
        id="positions"
        labelKey="jobApplication.positions.label"
        placeholderKey="jobApplication.positions.placeholder"
        options={options}
        value={value}
        onChange={handleChange}
        required
        hintKey="jobApplication.positions.hint"
        errorKey={errorKey}
        multiple
        renderOption={renderOption}
        renderSelected={renderSelected}
        className={styles.positionSelect}
      />
    </div>
  );
};

export default PositionSelect;