// File: frontend/src/pages/SuperAdmin/components/PitchAccessManager/index.js

import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import pitchService from '../../../../services/superAdmin/pitchService';
import styles from './PitchAccessManager.module.css';

const PitchAccessManager = ({ updateBreadcrumbs }) => {
  const { t } = useLanguage();
  const [email, setEmail] = useState('');
  const [selectedDeck, setSelectedDeck] = useState('investor-seed');
  const [accessList, setAccessList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredAccessList, setFilteredAccessList] = useState([]);
  const [durationDays, setDurationDays] = useState(7);

  const decks = [
    { id: 'investor-seed', name: 'Investor Seed Pitch' },
    { id: 'investor-series-a', name: 'Investor Series A Pitch' },
    { id: 'customer-insurers', name: 'Customer Insurers Pitch' },
    { id: 'customer-brokers', name: 'Customer Brokers Pitch' }
  ];

  // Update breadcrumbs when component mounts
  useEffect(() => {
    updateBreadcrumbs([
      { label: 'Dashboard', path: '/super-admin' },
      { label: 'Pitch Access Manager', path: '/super-admin/pitch-access' }
    ]);
  }, [updateBreadcrumbs]);

  // Load access list
  useEffect(() => {
    const fetchAccessList = async () => {
      setIsLoading(true);
      setError(null);
      
      try {
        const response = await pitchService.getAccessList(selectedDeck);
        
        if (response.success) {
          setAccessList(response.records || []);
          setFilteredAccessList(response.records || []);
        } else {
          throw new Error(response.message || 'Failed to load access list');
        }
      } catch (err) {
        console.error('Error fetching access list:', err);
        setError('Failed to load access list. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchAccessList();
  }, [selectedDeck]);

  // Filter access list when search term changes
  useEffect(() => {
    if (accessList.length > 0) {
      if (searchTerm) {
        const searchLower = searchTerm.toLowerCase();
        const filtered = accessList.filter(access => 
          access.email.toLowerCase().includes(searchLower) ||
          access.access_status.toLowerCase().includes(searchLower)
        );
        setFilteredAccessList(filtered);
      } else {
        setFilteredAccessList(accessList);
      }
    }
  }, [searchTerm, accessList]);

  // Add access
  const handleAddAccess = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    setSuccess(null);
    
    try {
      // Validate email
      if (!email || !/\S+@\S+\.\S+/.test(email)) {
        throw new Error('Please enter a valid email address');
      }
      
      const response = await pitchService.grantAccess(email, selectedDeck, durationDays);
      
      if (response.success) {
        // Refresh the access list
        const listResponse = await pitchService.getAccessList(selectedDeck);
        
        if (listResponse.success) {
          setAccessList(listResponse.records || []);
          setFilteredAccessList(listResponse.records || []);
        }
        
        setSuccess(`Access granted to ${email} for ${durationDays} days`);
        setEmail('');
      } else {
        throw new Error(response.message || 'Failed to grant access');
      }
    } catch (err) {
      console.error('Error adding access:', err);
      setError(err.message || 'Failed to grant access. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  // Revoke access
  const handleRevokeAccess = async (accessId) => {
    if (!window.confirm('Are you sure you want to revoke access for this user?')) return;
    
    setIsLoading(true);
    setError(null);
    setSuccess(null);
    
    try {
      const response = await pitchService.revokeAccess(accessId);
      
      if (response.success) {
        // Update local state
        const updatedAccessList = accessList.map(access => 
          access.access_id === accessId 
            ? { ...access, access_status: 'revoked', revoked_at: new Date().toISOString() }
            : access
        );
        
        setAccessList(updatedAccessList);
        setFilteredAccessList(updatedAccessList.filter(access => 
          !searchTerm || 
          access.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
          access.access_status.toLowerCase().includes(searchTerm.toLowerCase())
        ));
        
        setSuccess(`Access revoked successfully`);
      } else {
        throw new Error(response.message || 'Failed to revoke access');
      }
    } catch (err) {
      console.error('Error revoking access:', err);
      setError(err.message || 'Failed to revoke access. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  // Extend access
  const handleExtendAccess = async (accessId) => {
    setIsLoading(true);
    setError(null);
    setSuccess(null);
    
    try {
      const response = await pitchService.extendAccess(accessId, durationDays);
      
      if (response.success) {
        // Refresh the access list
        const listResponse = await pitchService.getAccessList(selectedDeck);
        
        if (listResponse.success) {
          setAccessList(listResponse.records || []);
          setFilteredAccessList(listResponse.records || []);
        }
        
        setSuccess(`Access extended successfully for ${durationDays} days`);
      } else {
        throw new Error(response.message || 'Failed to extend access');
      }
    } catch (err) {
      console.error('Error extending access:', err);
      setError(err.message || 'Failed to extend access. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const formatDate = (dateString) => {
    return pitchService.formatDate(dateString);
  };

  const getStatusBadgeClass = (status) => {
    switch (status) {
      case 'active':
        return styles.statusActive;
      case 'expired':
        return styles.statusExpired;
      case 'revoked':
        return styles.statusRevoked;
      default:
        return '';
    }
  };

  const isExpired = (expiryDate) => {
    return pitchService.isExpired(expiryDate);
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Pitch Deck Access Manager</h1>
      
      <div className={styles.controls}>
        <div className={styles.deckSelector}>
          <label htmlFor="deckSelect" className={styles.label}>Select Pitch Deck:</label>
          <select
            id="deckSelect"
            className={styles.select}
            value={selectedDeck}
            onChange={(e) => setSelectedDeck(e.target.value)}
            disabled={isLoading}
          >
            {decks.map(deck => (
              <option key={deck.id} value={deck.id}>{deck.name}</option>
            ))}
          </select>
        </div>
        
        <div className={styles.searchContainer}>
          <input
            type="text"
            className={styles.searchInput}
            placeholder="Search by email or status..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <i className="fas fa-search"></i>
        </div>
      </div>
      
      {error && (
        <div className={styles.error}>
          <i className="fas fa-exclamation-circle"></i>
          <span>{error}</span>
        </div>
      )}
      
      {success && (
        <div className={styles.success}>
          <i className="fas fa-check-circle"></i>
          <span>{success}</span>
        </div>
      )}
      
      <div className={styles.addAccessSection}>
        <h2 className={styles.sectionTitle}>Grant New Access</h2>
        <form onSubmit={handleAddAccess} className={styles.addAccessForm}>
          <div className={styles.formGroup}>
            <label htmlFor="email" className={styles.label}>Email Address:</label>
            <input
              type="email"
              id="email"
              className={styles.input}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter email address"
              disabled={isLoading}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="durationDays" className={styles.label}>Duration (days):</label>
            <input
              type="number"
              id="durationDays"
              className={styles.input}
              value={durationDays}
              onChange={(e) => setDurationDays(e.target.value)}
              min="1"
              max="365"
              disabled={isLoading}
              required
            />
          </div>
          <button
            type="submit"
            className={styles.addButton}
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                <div className={styles.spinner}></div>
                <span>Processing...</span>
              </>
            ) : (
              <>
                <i className="fas fa-plus-circle"></i>
                <span>Grant Access</span>
              </>
            )}
          </button>
        </form>
      </div>
      
      <div className={styles.accessListSection}>
        <h2 className={styles.sectionTitle}>Access List</h2>
        
        {isLoading && filteredAccessList.length === 0 ? (
          <div className={styles.loading}>
            <div className={styles.spinner}></div>
            <p>Loading access list...</p>
          </div>
        ) : filteredAccessList.length > 0 ? (
          <div className={styles.tableContainer}>
            <table className={styles.accessTable}>
              <thead>
                <tr>
                  <th>Email</th>
                  <th>Status</th>
                  <th>Granted</th>
                  <th>Expires</th>
                  <th>Last Accessed</th>
                  <th>Views</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredAccessList.map(access => {
                  const expired = isExpired(access.expires_at);
                  const status = expired && access.access_status === 'active' ? 'expired' : access.access_status;
                  
                  return (
                    <tr key={access.access_id}>
                      <td>{access.email}</td>
                      <td>
                        <span className={`${styles.statusBadge} ${getStatusBadgeClass(status)}`}>
                          {status}
                        </span>
                      </td>
                      <td>{formatDate(access.granted_at)}</td>
                      <td>{formatDate(access.expires_at)}</td>
                      <td>{formatDate(access.last_accessed_at)}</td>
                      <td>{access.access_count}</td>
                      <td>
                        <div className={styles.actions}>
                          {status === 'active' && (
                            <button
                              className={styles.revokeButton}
                              onClick={() => handleRevokeAccess(access.access_id)}
                              disabled={isLoading}
                              title="Revoke Access"
                            >
                              <i className="fas fa-ban"></i>
                            </button>
                          )}
                          {(status === 'expired' || status === 'revoked') && (
                            <button
                              className={styles.extendButton}
                              onClick={() => handleExtendAccess(access.access_id)}
                              disabled={isLoading}
                              title="Extend Access"
                            >
                              <i className="fas fa-clock"></i>
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div className={styles.emptyState}>
            <i className="fas fa-users-slash"></i>
            <p>No access records found{searchTerm ? ' matching your search' : ''}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default PitchAccessManager;