// File: frontend/src/components/layout/MainLayout/MainLayout.js

import React, { useEffect, useState } from 'react';
import { useLanguage } from '../../../contexts/LanguageContext';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import styles from './MainLayout.module.css';

const MainLayout = ({ children }) => {
  const { language } = useLanguage();
  const [headerHeight, setHeaderHeight] = useState(0);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const updateHeaderHeight = () => {
      const header = document.querySelector('header');
      if (header) {
        setHeaderHeight(header.offsetHeight);
      }
    };

    updateHeaderHeight();
    window.addEventListener('resize', updateHeaderHeight);

    return () => {
      window.removeEventListener('resize', updateHeaderHeight);
    };
  }, []);

  const handleMobileMenuToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className={`${styles.mainLayout} ${isMobileMenuOpen ? styles.mobileMenuOpen : ''}`} lang={language}>
      <Header 
        isDashboard={false}
        onMobileMenuToggle={handleMobileMenuToggle}
        isMobileMenuOpen={isMobileMenuOpen}
      />
      <main 
        className={styles.mainContent}
        style={{ 
          paddingTop: `${headerHeight-18.5}px`,
          minHeight: `calc(100vh - ${headerHeight-27}px)`
        }}
      >
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default MainLayout;