// File: frontend/src/pages/SuperAdmin/components/SubscriptionManagement/SubscriptionTiers/index.js

import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from './SubscriptionTiers.module.css';

const SubscriptionTiers = ({ onBack }) => {
  const { t } = useLanguage();
  const [tiers, setTiers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAddForm, setShowAddForm] = useState(false);
  const [editingTierId, setEditingTierId] = useState(null);
  const [formData, setFormData] = useState({
    tier_name: '',
    tier_description: '',
    monthly_price: '',
    annual_price: '',
    is_active: true,
    features: {}
  });

  useEffect(() => {
    const fetchTiers = async () => {
      setLoading(true);
      setError(null);
      
      try {
        // This would be replaced with an actual API call
        // const response = await subscriptionService.getSubscriptionTiers();
        
        // Mock data for now
        await new Promise(resolve => setTimeout(resolve, 800));
        
        const mockTiers = [
          {
            tier_id: 'tier-12345',
            tier_name: 'Enterprise',
            tier_description: 'Enterprise tier with all features included',
            monthly_price: 999.99,
            annual_price: 9999.99,
            features: {
              'Advanced Analytics': true,
              'Custom Reports': true,
              'API Access': true,
              'Priority Support': true,
              'Unlimited Users': true
            },
            is_active: true,
            created_at: '2023-01-01T10:00:00Z',
            updated_at: '2023-01-01T10:00:00Z'
          },
          {
            tier_id: 'tier-23456',
            tier_name: 'Professional',
            tier_description: 'Professional tier for growing businesses',
            monthly_price: 499.99,
            annual_price: 4999.99,
            features: {
              'Advanced Analytics': true,
              'Custom Reports': true,
              'API Access': true,
              'Priority Support': false,
              'Unlimited Users': false
            },
            is_active: true,
            created_at: '2023-01-01T10:00:00Z',
            updated_at: '2023-01-01T10:00:00Z'
          },
          {
            tier_id: 'tier-34567',
            tier_name: 'Basic',
            tier_description: 'Basic tier for small businesses',
            monthly_price: 99.99,
            annual_price: 999.99,
            features: {
              'Advanced Analytics': false,
              'Custom Reports': false,
              'API Access': false,
              'Priority Support': false,
              'Unlimited Users': false
            },
            is_active: true,
            created_at: '2023-01-01T10:00:00Z',
            updated_at: '2023-01-01T10:00:00Z'
          }
        ];
        
        setTiers(mockTiers);
      } catch (err) {
        console.error('Error fetching subscription tiers:', err);
        setError(t('failed_to_load_subscription_tiers'));
      } finally {
        setLoading(false);
      }
    };
    
    fetchTiers();
  }, [t]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    if (name.startsWith('feature_')) {
      const featureName = name.replace('feature_', '');
      setFormData(prev => ({
        ...prev,
        features: {
          ...prev.features,
          [featureName]: checked
        }
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: type === 'checkbox' ? checked : value
      }));
    }
  };

  const handleAddTier = () => {
    // Initialize form with default values for a new tier
    setFormData({
      tier_name: '',
      tier_description: '',
      monthly_price: '',
      annual_price: '',
      is_active: true,
      features: {
        'Advanced Analytics': false,
        'Custom Reports': false,
        'API Access': false,
        'Priority Support': false,
        'Unlimited Users': false
      }
    });
    setShowAddForm(true);
    setEditingTierId(null);
  };

  const handleEditTier = (tier) => {
    // Initialize form with values from the tier being edited
    setFormData({
      tier_name: tier.tier_name,
      tier_description: tier.tier_description,
      monthly_price: tier.monthly_price.toString(),
      annual_price: tier.annual_price.toString(),
      is_active: tier.is_active,
      features: { ...tier.features }
    });
    setShowAddForm(true);
    setEditingTierId(tier.tier_id);
  };

  const handleCancelForm = () => {
    setShowAddForm(false);
    setEditingTierId(null);
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    try {
      const tierData = {
        tier_name: formData.tier_name,
        tier_description: formData.tier_description,
        monthly_price: parseFloat(formData.monthly_price),
        annual_price: parseFloat(formData.annual_price),
        is_active: formData.is_active,
        features: formData.features
      };
      
      if (editingTierId) {
        // This would be replaced with an actual API call
        // await subscriptionService.updateSubscriptionTier(editingTierId, tierData);
        
        // Mock update for now
        await new Promise(resolve => setTimeout(resolve, 500));
        
        // Update the tier in the local state
        setTiers(tiers.map(tier => 
          tier.tier_id === editingTierId
            ? { ...tier, ...tierData, updated_at: new Date().toISOString() }
            : tier
        ));
      } else {
        // This would be replaced with an actual API call
        // const response = await subscriptionService.createSubscriptionTier(tierData);
        
        // Mock response for now
        await new Promise(resolve => setTimeout(resolve, 500));
        
        const newTierId = `tier-${Math.random().toString(36).substring(2, 10)}`;
        const timestamp = new Date().toISOString();
        
        // Add the new tier to the local state
        setTiers([
          ...tiers,
          {
            tier_id: newTierId,
            ...tierData,
            created_at: timestamp,
            updated_at: timestamp
          }
        ]);
      }
      
      // Reset form and state
      setShowAddForm(false);
      setEditingTierId(null);
    } catch (err) {
      console.error('Error saving tier:', err);
      setError(t('failed_to_save_tier'));
    } finally {
      setLoading(false);
    }
  };

  const formatCurrency = (amount, currency = 'USD') => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency
    }).format(amount);
  };

  if (loading && tiers.length === 0) {
    return (
      <div className={styles.loading}>
        <div className={styles.spinner}></div>
        <p>{t('loading_subscription_tiers')}</p>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <button className={styles.backButton} onClick={onBack} disabled={loading}>
          <i className="fas fa-arrow-left"></i>
          <span>{t('back')}</span>
        </button>
        
        <button 
          className={styles.addButton} 
          onClick={handleAddTier}
          disabled={loading || showAddForm}
        >
          <i className="fas fa-plus"></i>
          <span>{t('add_tier')}</span>
        </button>
      </div>
      
      {error && (
        <div className={styles.error}>
          <i className="fas fa-exclamation-circle"></i>
          <span>{error}</span>
        </div>
      )}
      
      {showAddForm && (
        <div className={styles.formContainer}>
          <h3 className={styles.formTitle}>
            {editingTierId ? t('edit_tier') : t('add_new_tier')}
          </h3>
          
          <form className={styles.form} onSubmit={handleSubmitForm}>
            <div className={styles.formGrid}>
              <div className={styles.formGroup}>
                <label htmlFor="tier_name" className={styles.label}>
                  {t('tier_name')} <span className={styles.required}>*</span>
                </label>
                <input
                  type="text"
                  id="tier_name"
                  name="tier_name"
                  className={styles.input}
                  value={formData.tier_name}
                  onChange={handleChange}
                  required
                  disabled={loading}
                />
              </div>
              
              <div className={styles.formGroup}>
                <label htmlFor="monthly_price" className={styles.label}>
                  {t('monthly_price')} <span className={styles.required}>*</span>
                </label>
                <input
                  type="number"
                  id="monthly_price"
                  name="monthly_price"
                  className={styles.input}
                  value={formData.monthly_price}
                  onChange={handleChange}
                  step="0.01"
                  min="0"
                  required
                  disabled={loading}
                />
              </div>
              
              <div className={styles.formGroup}>
                <label htmlFor="annual_price" className={styles.label}>
                  {t('annual_price')} <span className={styles.required}>*</span>
                </label>
                <input
                  type="number"
                  id="annual_price"
                  name="annual_price"
                  className={styles.input}
                  value={formData.annual_price}
                  onChange={handleChange}
                  step="0.01"
                  min="0"
                  required
                  disabled={loading}
                />
              </div>
              
              <div className={styles.formGroup}>
                <div className={styles.checkboxContainer}>
                  <input
                    type="checkbox"
                    id="is_active"
                    name="is_active"
                    className={styles.checkbox}
                    checked={formData.is_active}
                    onChange={handleChange}
                    disabled={loading}
                  />
                  <label htmlFor="is_active" className={styles.checkboxLabel}>
                    {t('active_tier')}
                  </label>
                </div>
              </div>
              
              <div className={styles.formGroupFull}>
                <label htmlFor="tier_description" className={styles.label}>
                  {t('description')} <span className={styles.required}>*</span>
                </label>
                <textarea
                  id="tier_description"
                  name="tier_description"
                  className={styles.textarea}
                  value={formData.tier_description}
                  onChange={handleChange}
                  rows={3}
                  required
                  disabled={loading}
                ></textarea>
              </div>
              
              <div className={styles.formGroupFull}>
                <label className={styles.label}>
                  {t('included_features')}
                </label>
                <div className={styles.featuresGrid}>
                  {Object.keys(formData.features).map((feature) => (
                    <div key={feature} className={styles.featureCheckbox}>
                      <input
                        type="checkbox"
                        id={`feature_${feature}`}
                        name={`feature_${feature}`}
                        className={styles.checkbox}
                        checked={formData.features[feature]}
                        onChange={handleChange}
                        disabled={loading}
                      />
                      <label htmlFor={`feature_${feature}`} className={styles.checkboxLabel}>
                        {feature}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            
            <div className={styles.formActions}>
              <button 
                type="button" 
                className={styles.cancelButton} 
                onClick={handleCancelForm}
                disabled={loading}
              >
                {t('cancel')}
              </button>
              <button 
                type="submit" 
                className={styles.submitButton}
                disabled={loading}
              >
                {loading ? (
                  <>
                    <span className={styles.spinner}></span>
                    <span>{editingTierId ? t('updating') : t('adding')}</span>
                  </>
                ) : (
                  editingTierId ? t('update_tier') : t('add_tier')
                )}
              </button>
            </div>
          </form>
        </div>
      )}
      
      <div className={styles.tiersContainer}>
        <h3 className={styles.tiersTitle}>{t('subscription_tiers')}</h3>
        
        {tiers.length > 0 ? (
          <div className={styles.tiersGrid}>
            {tiers.map((tier) => (
              <div key={tier.tier_id} className={`${styles.tierCard} ${!tier.is_active ? styles.inactiveTier : ''}`}>
                <div className={styles.tierHeader}>
                  <div className={styles.tierName}>{tier.tier_name}</div>
                  <div className={styles.tierPrice}>
                    {formatCurrency(tier.monthly_price)}
                    <span className={styles.billingCycle}>/{t('month')}</span>
                  </div>
                </div>
                <div className={styles.tierDescription}>
                  {tier.tier_description}
                </div>
                <div className={styles.tierFeatures}>
                  <h4 className={styles.tierFeaturesTitle}>{t('included_features')}</h4>
                  <ul className={styles.featuresList}>
                    {Object.entries(tier.features).map(([feature, included]) => (
                      <li key={feature} className={`${styles.featureItem} ${included ? styles.featureIncluded : styles.featureExcluded}`}>
                        <i className={`fas ${included ? 'fa-check' : 'fa-times'}`}></i>
                        <span>{feature}</span>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className={styles.tierActions}>
                  <button 
                    className={styles.editButton}
                    onClick={() => handleEditTier(tier)}
                    disabled={loading || showAddForm}
                  >
                    <i className="fas fa-edit"></i>
                    <span>{t('edit')}</span>
                  </button>
                  {!tier.is_active ? (
                    <div className={styles.inactiveLabel}>{t('inactive')}</div>
                  ) : null}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className={styles.emptyState}>
            <p>{t('no_tiers_found')}</p>
            <button 
              className={styles.addFirstButton}
              onClick={handleAddTier}
              disabled={loading || showAddForm}
            >
              {t('add_first_tier')}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SubscriptionTiers;