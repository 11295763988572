// File: frontend/src/pages/AI1/components/MessageBubble/MessageBubble.js

import React, { useState, useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';
import styles from './MessageBubble.module.css';
import TypingAnimation from '../TypingAnimation/TypingAnimation';

// New helper function to preprocess markdown content
const preprocessMarkdown = (content) => {
  if (!content) return '';
  
  // Find all numbered list items and preserve their numbering
  return content.replace(/^(\d+)\.\s/gm, (match, number) => {
    return `${number}. `;
  });
};

const MessageBubble = ({ message, isUser, isTyping, isHistorical = false }) => {
  const [displayedContent, setDisplayedContent] = useState('');
  const contentRef = useRef(null);
  const isHistoricalMessage = useRef(true);
  
  // Set isHistoricalMessage to false for new messages
  useEffect(() => {
    // If isHistorical prop is provided, use that
    if (isHistorical !== undefined) {
      isHistoricalMessage.current = isHistorical;
    } else {
      // Fallback to time-based check
      const currentTime = new Date().getTime();
      const messageTime = new Date(message.timestamp).getTime();
      const timeDifference = currentTime - messageTime;
      isHistoricalMessage.current = timeDifference > 30000;
    }
  }, [message.timestamp, isHistorical]);

  // Helper function to extract text content from message
  const getMessageContent = (message) => {
    if (!message) return '';
    
    // Handle DynamoDB format
    if (message.content?.L) {
      const contentList = message.content.L;
      // Extract text from the first content object
      const firstContent = contentList[0]?.M;
      if (firstContent && firstContent.text?.S) {
        return firstContent.text.S;
      }
    }
    
    // Handle regular API format where content is an array
    if (Array.isArray(message.content)) {
      return message.content[0]?.text || '';
    }
    
    // Handle string content (for user messages or legacy format)
    if (typeof message.content === 'string') {
      return message.content;
    }
    
    // Handle object content (for newer API responses)
    if (typeof message.content === 'object' && message.content.text) {
      return message.content.text;
    }
    
    return '';
  };

  const currentContent = getMessageContent(message);

  useEffect(() => {
    if (isTyping) {
      return;
    }

    if (!isUser && currentContent !== contentRef.current) {
      contentRef.current = currentContent;
      
      if (isHistoricalMessage.current) {
        // For historical messages, display content immediately
        setDisplayedContent(currentContent);
      } else {
        // For new messages, animate the typing
        setDisplayedContent('');
        let index = 0;
        const animateText = () => {
          if (index < currentContent.length) {
            const nextChar = currentContent[index];
            setDisplayedContent(prev => prev + nextChar);
            index++;
            setTimeout(animateText, 8);
          }
        };
        animateText();
      }
    } else if (isUser) {
      setDisplayedContent(currentContent);
    }
  }, [currentContent, isUser, isTyping]);

  if (isTyping) {
    return (
      <div className={`${styles.bubbleWrapper} ${styles.assistantMessage}`}>
        <div className={styles.bubble}>
          <TypingAnimation />
        </div>
      </div>
    );
  }

  // Process the content to maintain list numbers
  const processedContent = preprocessMarkdown(isUser ? currentContent : displayedContent);

  const markdownComponents = {
    code({ node, inline, className, children, ...props }) {
      const match = /language-(\w+)/.exec(className || '');
      return !inline && match ? (
        <SyntaxHighlighter
          style={tomorrow}
          language={match[1]}
          PreTag="div"
          {...props}
        >
          {String(children).replace(/\n$/, '')}
        </SyntaxHighlighter>
      ) : (
        <code className={styles.inlineCode} {...props}>
          {children}
        </code>
      );
    },
    p: ({ children }) => <p className={styles.paragraph}>{children}</p>,
    ul: ({ children }) => <ul className={styles.list}>{children}</ul>,
    ol: ({ children, ordered, ...props }) => {
      // Use a custom class for our special ordered lists
      return <ol className={styles.orderedList} {...props}>{children}</ol>;
    },
    li: ({ children, ordered, index, ...props }) => {
      // Check if this is a numbered list item from our custom numbering
      const isNumbered = React.Children.toArray(children)[0]?.toString().match(/^(\d+)\.\s/);
      
      if (isNumbered) {
        // Extract the number
        const number = isNumbered[1];
        // Remove the number from the content since we'll use CSS to display it
        const content = React.Children.toArray(children)[0].toString().replace(/^\d+\.\s/, '');
        
        // Use custom data attribute to store the list number
        return (
          <li
            className={`${styles.listItem} ${styles.customNumbered}`}
            data-number={number}
            {...props}
          >
            {content}
            {React.Children.toArray(children).slice(1)}
          </li>
        );
      }
      
      return <li className={styles.listItem} {...props}>{children}</li>;
    },
    a: ({ children, href }) => (
      <a href={href} target="_blank" rel="noopener noreferrer" className={styles.link}>
        {children}
      </a>
    ),
  };

  return (
    <div className={`${styles.bubbleWrapper} ${isUser ? styles.userMessage : styles.assistantMessage}`}>
      <div className={styles.bubble}>
        <ReactMarkdown components={markdownComponents}>
          {processedContent}
        </ReactMarkdown>
      </div>
      {message.timestamp && (
        <div className={styles.timestamp}>
          {new Date(message.timestamp).toLocaleTimeString([], { 
            hour: '2-digit', 
            minute: '2-digit' 
          })}
        </div>
      )}
    </div>
  );
};

export default MessageBubble;