// File: frontend/src/pages/InvestorPitch/components/slides/PartnershipsSlide/PartnershipsSlide.js

import React from 'react';
import { motion } from 'framer-motion';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import FlagshipPartner from './FlagshipPartner';
import MarketPartnership from './MarketPartnership';
import IntegrationTimeline from './IntegrationTimeline';
import { ANIMATION_VARIANTS } from '../../../constants/animations';
import styles from './PartnershipsSlide.module.css';

const PartnershipsSlide = () => {
  const { t } = useLanguage();

  const flagshipPartner = {
    name: t('investor_pitch.partnerships.flagship.name'),
    type: t('investor_pitch.partnerships.flagship.type'),
    details: [
      t('investor_pitch.partnerships.flagship.details.contract'),
      t('investor_pitch.partnerships.flagship.details.integration'),
      t('investor_pitch.partnerships.flagship.details.success'),
      t('investor_pitch.partnerships.flagship.details.expansion')
    ],
    gradient: "linear-gradient(135deg, var(--color-blue-110), var(--color-blue-310))"
  };

  const marketPartnerships = [
    {
      market: `🇨🇦 ${t('investor_pitch.partnerships.markets.canada.name')}`,
      status: t('investor_pitch.partnerships.markets.canada.status'),
      details: [
        t('investor_pitch.partnerships.markets.canada.details.pipeline'),
        t('investor_pitch.partnerships.markets.canada.details.segments'),
        t('investor_pitch.partnerships.markets.canada.details.regulatory')
      ],
      timeline: t('investor_pitch.partnerships.markets.canada.timeline'),
      gradient: "linear-gradient(135deg, var(--color-green-110), var(--color-green-310))"
    },
    {
      market: `🇺🇸 ${t('investor_pitch.partnerships.markets.us.name')}`,
      status: t('investor_pitch.partnerships.markets.us.status'),
      details: [
        t('investor_pitch.partnerships.markets.us.details.strategy'),
        t('investor_pitch.partnerships.markets.us.details.focus'),
        t('investor_pitch.partnerships.markets.us.details.tech')
      ],
      timeline: t('investor_pitch.partnerships.markets.us.timeline'),
      gradient: "linear-gradient(135deg, var(--color-orange-110), var(--color-orange-310))"
    }
  ];

  const integrationHighlights = [
    {
      title: t('investor_pitch.partnerships.integration.quick.title'),
      icon: "Zap",
      description: t('investor_pitch.partnerships.integration.quick.description'),
      color: "var(--color-blue-310)"
    },
    {
      title: t('investor_pitch.partnerships.integration.risk.title'),
      icon: "Shield",
      description: t('investor_pitch.partnerships.integration.risk.description'),
      color: "var(--color-green-310)"
    },
    {
      title: t('investor_pitch.partnerships.integration.flexible.title'),
      icon: "Settings",
      description: t('investor_pitch.partnerships.integration.flexible.description'),
      color: "var(--color-orange-310)"
    }
  ];

  return (
    <motion.div
      className={styles.container}
      variants={ANIMATION_VARIANTS.stagger.container}
      initial="hidden"
      animate="visible"
    >
      <motion.div 
        className={styles.header}
        variants={ANIMATION_VARIANTS.fadeIn}
      >
        <h2 className={styles.title}>{t('investor_pitch.partnerships.title')}</h2>
        <p className={styles.subtitle}>{t('investor_pitch.partnerships.subtitle')}</p>
      </motion.div>

      <div className={styles.content}>
        <motion.div 
          className={styles.flagshipSection}
          variants={ANIMATION_VARIANTS.fadeIn}
        >
          <FlagshipPartner {...flagshipPartner} />
        </motion.div>

        <motion.div 
          className={styles.marketPartnerships}
          variants={ANIMATION_VARIANTS.stagger.container}
        >
          {marketPartnerships.map((partnership, index) => (
            <motion.div
              key={partnership.market}
              variants={ANIMATION_VARIANTS.stagger.item}
              custom={index}
            >
              <MarketPartnership {...partnership} />
            </motion.div>
          ))}
        </motion.div>

        <motion.div 
          className={styles.integrationSection}
          variants={ANIMATION_VARIANTS.fadeIn}
        >
          <h3 className={styles.sectionTitle}>
            {t('investor_pitch.partnerships.integration.title')}
          </h3>
          <IntegrationTimeline highlights={integrationHighlights} />
        </motion.div>
      </div>
    </motion.div>
  );
};

export default PartnershipsSlide;