// /charts/v2/types/ComparisonColumnChart.js
import React from 'react';
import {
    BarChart as RechartsBarChart,
    Bar,
    Cell,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer
} from 'recharts';
import ChartWrapper from '../core/ChartWrapper';
import ChartContainer from '../core/ChartContainer';
import { formatValue } from '../utils/formatters';
import styles from './ComparisonColumnChart.module.css';

const CustomTooltip = ({ active, payload, label, format }) => {
    if (!active || !payload || !payload.length) return null;

    return (
        <div className={styles.tooltip}>
            <p className={styles.tooltipLabel}>{label}</p>
            <div className={styles.tooltipValue}>
                {formatTooltip(payload[0].value, format)}
            </div>
        </div>
    );
};

const formatTooltip = (value, format) => {
    switch (format) {
        case 'number':
            return value.toLocaleString();
        case 'currency':
            return value >= 1e9 
                ? `$${(value / 1e9).toFixed(1)}B` 
                : `$${(value / 1e6).toFixed(1)}M`;
        case 'percentage':
            return `${value}%`;
        case 'lines':
            return `${value.toLocaleString()} lines`;
        default:
            return value;
    }
};



const SingleMetricChart = ({ data, metric, height = 300 }) => {
    const chartData = data.categories.map((category, index) => ({
        name: category,
        value: metric.values[index]
    }));

    const formatYAxis = (value) => {
        switch (metric.format) {
            case 'number':
                return value >= 1000 
                    ? `${(value / 1000).toFixed(0)}K` 
                    : value;
            case 'currency':
                return value >= 1e9 
                    ? `$${(value / 1e9).toFixed(1)}B` 
                    : `$${(value / 1e6).toFixed(1)}M`;
            case 'percentage':
                return `${value}%`;
            case 'lines':
                return value >= 1000 
                    ? `${(value / 1000).toFixed(0)}K` 
                    : value;
            default:
                return value;
        }
    };    

    return (
        <div className={styles.singleChartContainer}>
            <h4 className={styles.metricTitle}>{metric.name}</h4>
            <ChartContainer height={height}>
                <ResponsiveContainer width="100%" height="100%">
                    <RechartsBarChart
                        data={chartData}
                        margin={{ top: 20, right: 30, left: 10, bottom: 20 }}
                        barSize={40}
                        barGap={4}
                        barCategoryGap={32}
                    >
                        <CartesianGrid 
                            strokeDasharray="3 3" 
                            vertical={false}
                            stroke="var(--color-border)"
                            strokeOpacity={0.5}
                        />
                        <XAxis
                            dataKey="name"
                            axisLine={false}
                            tickLine={false}
                            tick={{
                                fill: 'var(--text-secondary)',
                                fontSize: 12,
                                fontFamily: 'var(--font-primary)'
                            }}
                            dy={8}
                        />
                        <YAxis
                            axisLine={false}
                            tickLine={false}
                            tick={{
                                fill: 'var(--text-secondary)',
                                fontSize: 12,
                                fontFamily: 'var(--font-primary)'
                            }}
                            tickFormatter={formatYAxis}
                            domain={[0, metric.maxValue || 'auto']}
                            dx={-8}
                        />
                        <Tooltip
                            content={<CustomTooltip format={metric.format} />}
                            cursor={{ 
                                fill: 'var(--color-border)',
                                opacity: 0.1
                            }}
                        />
                        <Bar
                            dataKey="value"
                            fill={metric.color}
                            radius={[4, 4, 0, 0]}
                            maxBarSize={60}
                        >
                            {metric.colors && chartData.map((entry, index) => (
                                <Cell 
                                    key={`cell-${index}`}
                                    fill={metric.colors[index] || metric.color}
                                    cursor="pointer"
                                />
                            ))}
                        </Bar>
                    </RechartsBarChart>
                </ResponsiveContainer>
            </ChartContainer>
        </div>
    );
};

const ComparisonColumnChart = ({
    data,
    title,
    subtitle,
}) => {
    return (
        <ChartWrapper title={title} subtitle={subtitle}>
            <div className={styles.chartsContainer}>
                {data.metrics.map((metric, index) => (
                    <SingleMetricChart
                        key={index}
                        data={data}
                        metric={metric}
                    />
                ))}
                
                {data.annotations?.savings && (
                    <div className={styles.savingsAnnotation}>
                        <div className={styles.savingsAmount}>
                            Total Cost Reduction: {formatValue(data.annotations.savings.amount, 'currency')}
                        </div>
                        <div className={styles.savingsPercentage}>
                            {data.annotations.savings.percentage}% Reduction in Expenses
                        </div>
                    </div>
                )}

                {data.annotations?.averages && (
                    <div className={styles.averagesAnnotation}>
                        {data.annotations.averages.label && (
                            <h4 className={styles.averagesTitle}>
                                {data.annotations.averages.label}
                            </h4>
                        )}
                        <div className={styles.averagesGrid}>
                            {data.annotations.averages.successRate !== undefined && (
                                <div className={styles.averageMetric}>
                                    <span className={styles.averageLabel}>Average Success Rate</span>
                                    <span className={styles.averageValue}>
                                        {data.annotations.averages.successRate}%
                                    </span>
                                </div>
                            )}
                            {data.annotations.averages.timeReduction !== undefined && (
                                <div className={styles.averageMetric}>
                                    <span className={styles.averageLabel}>Average Time Reduction</span>
                                    <span className={styles.averageValue}>
                                        {data.annotations.averages.timeReduction}%
                                    </span>
                                </div>
                            )}
                            {data.annotations.averages.complexity !== undefined && (
                                <div className={styles.averageMetric}>
                                    <span className={styles.averageLabel}>Average Complexity</span>
                                    <span className={styles.averageValue}>
                                        {data.annotations.averages.complexity}
                                    </span>
                                </div>
                            )}
                            {data.annotations.averages.commentRatio !== undefined && (
                                <div className={styles.averageMetric}>
                                    <span className={styles.averageLabel}>Overall Comment Ratio</span>
                                    <span className={styles.averageValue}>
                                        {data.annotations.averages.commentRatio}%
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {data.annotations?.changes && (
                    <div className={styles.changesAnnotation}>
                        <div className={styles.changesGrid}>
                            <div className={styles.changesColumn}>
                                <h4 className={styles.changesTitle}>Increased Focus</h4>
                                {data.annotations.changes.improvements.map((item, index) => (
                                    <div key={index} className={styles.changeItem}>
                                        <span className={styles.changeMetric}>{item.metric}</span>
                                        <span className={`${styles.changeValue} ${styles.positive}`}>
                                            {item.change}
                                        </span>
                                    </div>
                                ))}
                            </div>
                            <div className={styles.changesColumn}>
                                <h4 className={styles.changesTitle}>Reduced Time</h4>
                                {data.annotations.changes.reductions.map((item, index) => (
                                    <div key={index} className={styles.changeItem}>
                                        <span className={styles.changeMetric}>{item.metric}</span>
                                        <span className={`${styles.changeValue} ${styles.negative}`}>
                                            {item.change}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}

                {data.insights && (
                    <div className={styles.insightsContainer}>
                        {data.insights.map((insight, index) => (
                            <div key={index} className={styles.insightCard}>
                                <h4 className={styles.insightTitle}>{insight.title}</h4>
                                <ul className={styles.insightList}>
                                    {insight.items.map((item, itemIndex) => (
                                        <li key={itemIndex} className={styles.insightItem}>
                                            {item}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </ChartWrapper>
    );
};

export default ComparisonColumnChart;