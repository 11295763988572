// File: frontend/src/components/Settings/SettingsModal.js

import React, { useMemo, useCallback } from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import { usePrivacy } from '../../contexts/PrivacyContext';
import Dropdown from '../form/Dropdown/Dropdown';
import styles from './SettingsModal.module.css';

const SettingsModal = ({ isOpen, onClose }) => {
  const { t, language, changeLanguage, supportedLanguages } = useLanguage();
  const { privacyProtection, togglePrivacyProtection } = usePrivacy();
  const [measurementUnit, setMeasurementUnit] = React.useState('metric');

  // Memoize language options
  const languageOptions = useMemo(() => 
    supportedLanguages.map(lang => ({
      value: lang,
      labelKey: `language_${lang.replace('-', '_')}`,
    })), 
    [supportedLanguages]
  );

  // Memoize language change handler
  const handleLanguageChange = useCallback((newLanguage) => {
    changeLanguage(newLanguage);
  }, [changeLanguage]);

  

  const getLanguageDisplayName = (langCode) => {
      const languageNames = {
        'en-ca': '🇨🇦 English (Canada)',
        'de-de': '🇩🇪 Deutsch (Deutschland)',
        'en-gb': '🇬🇧 English (United Kingdom)',
        'en-us': '🇺🇸 English (USA)',
        'es-es': '🇪🇸 Español (España)',
        'es-mx': '🇲🇽 Español (México)',
        'fr-ca': '🇨🇦 Français (Canada)',
        'fr-fr': '🇫🇷 Français (France)',
        'nl-nl': '🇳🇱 Nederlands (Nederland)',
        'tr-tr': '🇹🇷 Türkçe (Türkiye)'
      };
      return languageNames[langCode] || langCode;
    };

  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
        <div className={styles.modalHeader}>
          <h2>{t('settings')}</h2>
          <button className={styles.closeButton} onClick={onClose}>
            <img src="/icons/18/misc/close.svg" alt="Close" />
          </button>
        </div>

        <div className={styles.modalBody}>
          <div className={styles.settingSection}>
            <h3>{t('language')}</h3>
            <Dropdown
              id="language-picker"
              options={languageOptions}
              value={language}
              onChange={handleLanguageChange}
              variant="language-picker"
              direction="down" // Specify down direction for settings modal
              renderOption={(option) => (
                  <span>{getLanguageDisplayName(option.value)}</span>
              )}
              placeholderKey={`language_${language.replace('-', '_')}`}
              />
          </div>

          <div className={styles.settingSection}>
            <h3>{t('measuring_unit')}</h3>
            <div className={styles.buttonGroup}>
              <button
                className={`${styles.button} ${measurementUnit === 'metric' ? styles.active : ''}`}
                onClick={() => setMeasurementUnit('metric')}
              >
                {t('metric')}
              </button>
              <button
                className={`${styles.button} ${measurementUnit === 'imperial' ? styles.active : ''}`}
                onClick={() => setMeasurementUnit('imperial')}
              >
                {t('imperial')}
              </button>
            </div>
          </div>

          <div className={styles.settingSection}>
            <h3>{t('privacy_protection')}</h3>
            <div className={styles.buttonGroup}>
              <button
                className={`${styles.button} ${privacyProtection ? styles.active : ''}`}
                onClick={() => togglePrivacyProtection(true)}
              >
                {t('on')}
              </button>
              <button
                className={`${styles.button} ${!privacyProtection ? styles.active : ''}`}
                onClick={() => togglePrivacyProtection(false)}
              >
                {t('off')}
              </button>
            </div>
            <p className={styles.description}>{t('pii_hidden_by_default')}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsModal;