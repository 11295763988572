// File: frontend/src/pages/InvestorPitch/components/slides/DemoSlide/DemoSlide.js

import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import * as LucideIcons from 'lucide-react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import VideoPlayer from './VideoPlayer';
import styles from './DemoSlide.module.css';

const DemoSlide = () => {
  const [activeVideo, setActiveVideo] = useState(null);
  const { t } = useLanguage();

  const demoVideos = [
    {
      id: 'core-platform',
      title: t('investor_pitch.demo.videos.core_platform.title'),
      description: t('investor_pitch.demo.videos.core_platform.description'),
      duration: "5:20",
      thumbnail: "/videos/thumbnails/core-platform.jpg",
      url: "/videos/core-platform.mp4",
      icon: "Layers",
      color: "var(--color-blue-310)"
    },
    {
      id: 'integration',
      title: t('investor_pitch.demo.videos.integration.title'),
      description: t('investor_pitch.demo.videos.integration.description'),
      duration: "3:45",
      thumbnail: "/videos/thumbnails/integration.jpg",
      url: "/videos/integration.mp4",
      icon: "GitBranch",
      color: "var(--color-green-310)"
    },
    {
      id: 'risk-assessment',
      title: t('investor_pitch.demo.videos.risk_assessment.title'),
      description: t('investor_pitch.demo.videos.risk_assessment.description'),
      duration: "4:15",
      thumbnail: "/videos/thumbnails/risk-assessment.jpg",
      url: "/videos/risk-assessment.mp4",
      icon: "Shield",
      color: "var(--color-orange-310)"
    }
  ];

  return (
    <motion.div
      className={styles.container}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div 
        className={styles.header}
        initial={{ y: -20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
      >
        <h2 className={styles.title}>{t('investor_pitch.demo.header.title')}</h2>
        <p className={styles.subtitle}>{t('investor_pitch.demo.header.subtitle')}</p>
      </motion.div>

      <div className={styles.content}>
        <div className={styles.videoGrid}>
          {demoVideos.map((video) => {
            const Icon = LucideIcons[video.icon];
            return (
              <motion.div
                key={video.id}
                className={styles.videoCard}
                whileHover={{ scale: 1.02 }}
                transition={{ type: 'spring', stiffness: 300, damping: 30 }}
                onClick={() => setActiveVideo(video)}
              >
                <div className={styles.thumbnailContainer}>
                  <div 
                    className={styles.thumbnail}
                    style={{ backgroundImage: `url(${video.thumbnail})` }}
                  >
                    <div className={styles.playButton}>
                      <LucideIcons.Play className={styles.playIcon} />
                    </div>
                    <div className={styles.duration}>{video.duration}</div>
                  </div>
                </div>

                <div className={styles.videoInfo}>
                  <div 
                    className={styles.iconWrapper}
                    style={{ color: video.color }}
                  >
                    <Icon className={styles.icon} />
                  </div>
                  <div className={styles.textContent}>
                    <h3 className={styles.videoTitle}>{video.title}</h3>
                    <p className={styles.videoDescription}>{video.description}</p>
                  </div>
                </div>
              </motion.div>
            );
          })}
        </div>
      </div>

      <AnimatePresence>
        {activeVideo && (
          <motion.div
            className={styles.videoOverlay}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div className={styles.videoModal}>
              <button 
                className={styles.closeButton}
                onClick={() => setActiveVideo(null)}
                aria-label={t('investor_pitch.demo.modal.close')}
              >
                <LucideIcons.X className={styles.closeIcon} />
              </button>
              <VideoPlayer 
                url={activeVideo.url}
                title={activeVideo.title}
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default DemoSlide;