// File: frontend/src/pages/QMS/QuoteDetail/components/QuoteProducts/HomeDetails/index.js

import React, { useState } from 'react';
import { useLanguage } from '../../../../../../contexts/LanguageContext';
import styles from './HomeDetails.module.css';

const HomeDetails = ({ quote }) => {
  const { t } = useLanguage();
  const [activeTab, setActiveTab] = useState('property');
  
  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    return new Date(dateString).toLocaleString();
  };

  if (!quote.home) {
    return (
      <div className={styles.noData}>
        <p>{t('no_home_insurance_details')}</p>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.tabs}>
        <button
          className={`${styles.tab} ${activeTab === 'property' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('property')}
        >
          {t('property')}
        </button>
        <button
          className={`${styles.tab} ${activeTab === 'coverages' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('coverages')}
        >
          {t('coverages')}
        </button>
        <button
          className={`${styles.tab} ${activeTab === 'claims' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('claims')}
        >
          {t('claims')}
        </button>
      </div>
      
      <div className={styles.tabContent}>
        {activeTab === 'property' && (
          <div className={styles.propertyContent}>
            {quote.home.property ? (
              <div className={styles.card}>
                <div className={styles.cardHeader}>
                  <h3 className={styles.cardTitle}>{t('property_details')}</h3>
                </div>
                <div className={styles.cardContent}>
                  <div className={styles.infoGrid}>
                    <div className={styles.infoGroup}>
                      <div className={styles.infoLabel}>{t('property_type')}:</div>
                      <div className={styles.infoValue}>{quote.home.property.property_type || 'N/A'}</div>
                    </div>
                    <div className={styles.infoGroup}>
                      <div className={styles.infoLabel}>{t('year_built')}:</div>
                      <div className={styles.infoValue}>{quote.home.property.year_built || 'N/A'}</div>
                    </div>
                    <div className={styles.infoGroup}>
                      <div className={styles.infoLabel}>{t('construction_type')}:</div>
                      <div className={styles.infoValue}>{quote.home.property.construction_type || 'N/A'}</div>
                    </div>
                    <div className={styles.infoGroup}>
                      <div className={styles.infoLabel}>{t('square_footage')}:</div>
                      <div className={styles.infoValue}>{quote.home.property.square_footage || 'N/A'}</div>
                    </div>
                    <div className={styles.infoGroup}>
                      <div className={styles.infoLabel}>{t('number_of_stories')}:</div>
                      <div className={styles.infoValue}>{quote.home.property.number_of_stories || 'N/A'}</div>
                    </div>
                    <div className={styles.infoGroup}>
                      <div className={styles.infoLabel}>{t('number_of_units')}:</div>
                      <div className={styles.infoValue}>{quote.home.property.number_of_units || 'N/A'}</div>
                    </div>
                    <div className={styles.infoGroup}>
                      <div className={styles.infoLabel}>{t('roof_type')}:</div>
                      <div className={styles.infoValue}>{quote.home.property.roof_type || 'N/A'}</div>
                    </div>
                    <div className={styles.infoGroup}>
                      <div className={styles.infoLabel}>{t('roof_age')}:</div>
                      <div className={styles.infoValue}>
                        {quote.home.property.roof_age ? `${quote.home.property.roof_age} ${t('years')}` : 'N/A'}
                      </div>
                    </div>
                    <div className={styles.infoGroup}>
                      <div className={styles.infoLabel}>{t('basement_type')}:</div>
                      <div className={styles.infoValue}>{quote.home.property.basement_type || 'N/A'}</div>
                    </div>
                    <div className={styles.infoGroup}>
                      <div className={styles.infoLabel}>{t('basement_finished')}:</div>
                      <div className={styles.infoValue}>
                        {quote.home.property.basement_finished === true ? t('yes') : 
                         quote.home.property.basement_finished === false ? t('no') : 'N/A'}
                      </div>
                    </div>
                    <div className={styles.infoGroup}>
                      <div className={styles.infoLabel}>{t('market_value')}:</div>
                      <div className={styles.infoValue}>
                        {quote.home.property.market_value 
                          ? `$${quote.home.property.market_value.toFixed(2)}` 
                          : 'N/A'}
                      </div>
                    </div>
                    <div className={styles.infoGroup}>
                      <div className={styles.infoLabel}>{t('replacement_cost')}:</div>
                      <div className={styles.infoValue}>
                        {quote.home.property.replacement_cost 
                          ? `$${quote.home.property.replacement_cost.toFixed(2)}` 
                          : 'N/A'}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.noData}>
                <p>{t('no_property_information')}</p>
              </div>
            )}
          </div>
        )}
        
        {activeTab === 'coverages' && (
          <div className={styles.coveragesContent}>
            {quote.home.coverages && quote.home.coverages.length > 0 ? (
              <div className={styles.tableContainer}>
                <table className={styles.table}>
                  <thead>
                    <tr>
                      <th>{t('coverage')}</th>
                      <th>{t('limit')}</th>
                      <th>{t('deductible')}</th>
                      <th>{t('premium')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {quote.home.coverages.map((coverage, index) => (
                      <tr key={coverage.coverage_id || index}>
                        <td>
                          <div className={styles.coverageName}>{coverage.coverage_name}</div>
                          <div className={styles.coverageCode}>{coverage.coverage_code}</div>
                        </td>
                        <td>{coverage.limit ? `$${coverage.limit.toFixed(2)}` : 'N/A'}</td>
                        <td>{coverage.deductible ? `$${coverage.deductible.toFixed(2)}` : 'N/A'}</td>
                        <td>{coverage.premium?.total ? `$${coverage.premium.total.toFixed(2)}` : 'N/A'}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className={styles.noData}>
                <p>{t('no_coverage_information')}</p>
              </div>
            )}
          </div>
        )}
        
        {activeTab === 'claims' && (
          <div className={styles.claimsContent}>
            {quote.home.claims && quote.home.claims.length > 0 ? (
              <div className={styles.tableContainer}>
                <table className={styles.table}>
                  <thead>
                    <tr>
                      <th>{t('date')}</th>
                      <th>{t('type')}</th>
                      <th>{t('amount')}</th>
                      <th>{t('description')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {quote.home.claims.map((claim, index) => (
                      <tr key={claim.claim_id || index}>
                        <td>{formatDate(claim.claim_date)}</td>
                        <td>{claim.claim_type || 'N/A'}</td>
                        <td>
                          {claim.claim_amount 
                            ? `$${claim.claim_amount.toFixed(2)}` 
                            : 'N/A'}
                        </td>
                        <td>
                          {(claim.water_damage && t('water_damage')) ||
                           (claim.fire_damage && t('fire_damage')) ||
                           (claim.theft && t('theft')) ||
                           (claim.liability && t('liability')) ||
                           'N/A'}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className={styles.noData}>
                <p>{t('no_claims_information')}</p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default HomeDetails;