// File: frontend/src/pages/News/News.js

import React, { useState, useEffect, useMemo } from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import { Helmet } from 'react-helmet-async';
import newsArticles from '../../data/newsArticles';
import NewsCard from '../../components/NewsCard/NewsCard';
import SearchBar from '../../components/SearchBar/SearchBar';
import Pagination from '../../components/Pagination/Pagination';
import styles from './News.module.css';

const News = () => {
  const { t, language, supportedLanguages } = useLanguage();
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const articlesPerPage = 6;

  const featuredArticle = newsArticles.find(article => article.featured);
  
  const filteredArticles = useMemo(() => {
    return newsArticles.filter(article => 
      article.blog_title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      article.blog_excerpt.toLowerCase().includes(searchTerm.toLowerCase()) ||
      article.blog_keywords.some(keyword => keyword.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  }, [searchTerm]);

  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = filteredArticles.slice(indexOfFirstArticle, indexOfLastArticle);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);

  const baseUrl = 'https://www.allegory.app';
  const currentPath = '/news';

  return (
    <div className={styles.newsContainer}>
      <Helmet>
        <html lang={language} />
        <title>{t('news_page_title')} | Allegory</title>
        <meta name="description" content={t('news_page_description')} />
        <meta name="keywords" content={t('news_page_keywords')} />
        
        {/* Canonical and alternate language links */}
        <link rel="canonical" href={`${baseUrl}/${language}${currentPath}`} />
        {supportedLanguages.map(lang => (
          <link 
            key={lang}
            rel="alternate" 
            hreflang={lang} 
            href={`${baseUrl}/${lang}${currentPath}`}
          />
        ))}
        <link 
          rel="alternate" 
          hreflang="x-default" 
          href={`${baseUrl}/en-ca${currentPath}`}
        />

        {/* Open Graph tags */}
        <meta property="og:title" content={t('news_page_title')} />
        <meta property="og:description" content={t('news_page_description')} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`${baseUrl}/${language}${currentPath}`} />
        <meta property="og:locale" content={language} />
        {supportedLanguages
          .filter(lang => lang !== language)
          .map(lang => (
            <meta 
              key={lang} 
              property="og:locale:alternate" 
              content={lang} 
            />
          ))}
      </Helmet>

      <h1 className={styles.title}>{t('news_title')}</h1>
      
      {featuredArticle && (
        <div className={styles.featuredNews}>
          <h2 className={styles.sectionTitle}>{t('featured_news')}</h2>
          <NewsCard article={featuredArticle} featured />
        </div>
      )}

      <SearchBar value={searchTerm} onChange={setSearchTerm} />

      <div className={styles.newsGrid}>
        {currentArticles.map(article => (
          <NewsCard key={article.slug} article={article} />
        ))}
      </div>

      <Pagination
        current={currentPage}
        total={Math.ceil(filteredArticles.length / articlesPerPage)}
        onChange={setCurrentPage}
      />

      <div className={styles.inquirySection}>
        <h2 className={styles.sectionTitle}>{t('inquiry_section_title')}</h2>
        <div className={styles.inquiryContent}>
          <div className={styles.inquiryEmails}>
            <p>{t('ai_inquiry')}: ai@allegory.app</p>
            <p>{t('support_inquiry')}: support@allegory.app</p>
            <p>{t('press_inquiry')}: press@allegory.app</p>
          </div>
          <div className={styles.socialLinks}>
            <a href="https://facebook.com/allegory" target="_blank" rel="noopener noreferrer">Facebook</a>
            <a href="https://twitter.com/allegory" target="_blank" rel="noopener noreferrer">Twitter</a>
            <a href="https://linkedin.com/company/allegory" target="_blank" rel="noopener noreferrer">LinkedIn</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default News;