// src/components/TripDetails/TripDriverInfo.js
import React from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import { usePrivacy } from '../../contexts/PrivacyContext';
import styles from './TripDriverInfo.module.css';

const TripDriverInfo = ({ tripData }) => {
  const { t } = useLanguage();
  const { privacyProtection } = usePrivacy();

  const {
    isDriver,
    tripEngagementList,
    tripModeTransportation,
    // Add more fields as needed
  } = tripData;

  const getUserEngagement = () => {
    if (tripEngagementList && tripEngagementList.length > 0) {
      return tripEngagementList[0].tripEngagementLevel_v1_0;
    }
    return 'N/A';
  };

  return (
    <div className={styles.card}>
      <div className={styles.cardStandartTitleRow}>
        <div className={styles.text}>{t('trip_driver_and_mode_of_transportation')}</div>
        <div className={styles.button}>
          <img className={styles.icons18MoreHorizontal} alt="" src="/icons/18/misc/show-more-horizontal-three-dots.svg" />
        </div>
      </div>
      <div className={styles.cardStandartContent}>
        <div className={styles.cardStandartContentA}>
          <div className={styles.cardStandartContentA1}>
            <div className={styles.div}>{t('user_trip_role')}</div>
            <div className={styles.div}>:</div>
          </div>
          <div className={styles.cardStandartContentA2}>
            <div className={styles.div}>{isDriver ? t('driver') : t('passenger')}</div>
          </div>
        </div>
        <div className={styles.cardStandartContentA}>
          <div className={styles.cardStandartContentA1}>
            <div className={styles.div}>{t('user_engagement')}</div>
            <div className={styles.div}>:</div>
          </div>
          <div className={styles.cardStandartContentA2}>
            <div className={styles.div}>{getUserEngagement()}</div>
          </div>
        </div>
        <div className={styles.cardStandartContentA}>
          <div className={styles.cardStandartContentA1}>
            <div className={styles.div}>{t('mode_of_transportation')}</div>
            <div className={styles.div}>:</div>
          </div>
          <div className={styles.cardStandartContentA2}>
            <div className={styles.div}>{t[tripModeTransportation] || tripModeTransportation}</div>
          </div>
        </div>
        <div className={styles.cardStandartContentA}>
          <div className={styles.cardStandartContentA1}>
            <div className={styles.div}>{t('trip_type')}</div>
            <div className={styles.div}>:</div>
          </div>
          <div className={styles.cardStandartContentA2}>
            <div className={styles.div}>{t('personal')}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TripDriverInfo;