// File: frontend/src/pages/InvestorPitch/components/slides/LeadershipSlide/PartnershipCard.js

import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { Handshake } from 'lucide-react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from './PartnershipCard.module.css';

const PartnershipCard = ({ partnerships }) => {
  const { t } = useLanguage();

  return (
    <motion.div 
      className={styles.container}
      whileHover={{ scale: 1.01 }}
      transition={{ type: 'spring', stiffness: 300, damping: 30 }}
    >
      <div className={styles.header}>
        <div className={styles.iconWrapper}>
          <Handshake 
            className={styles.icon} 
            aria-label={t('investor_pitch.leadership.partnerships.icon.aria_label', 'Partnerships icon')}
          />
        </div>
        <h4 className={styles.title}>
          {t('investor_pitch.leadership.partnerships.title', 'Insurance Partnerships')}
        </h4>
      </div>

      <div className={styles.partnerships}>
        {partnerships.map((partner, index) => (
          <motion.div 
            key={index}
            className={styles.partner}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.05 }}
          >
            <div className={styles.bullet} />
            <span className={styles.partnerName}>{partner}</span>
          </motion.div>
        ))}
      </div>
    </motion.div>
  );
};

PartnershipCard.propTypes = {
  partnerships: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default PartnershipCard;