// File: frontend/src/pages/QMS/QuoteDetail/components/QuoteProducts/AutoDetails/index.js

import React, { useState } from 'react';
import { useLanguage } from '../../../../../../contexts/LanguageContext';
import styles from './AutoDetails.module.css';

const AutoDetails = ({ quote }) => {
  const { t } = useLanguage();
  const [activeTab, setActiveTab] = useState('vehicles');
  
  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    return new Date(dateString).toLocaleString();
  };

  const formatCurrency = (amount, currency = 'CAD') => {
    if (amount === null || amount === undefined) return 'N/A';
    return new Intl.NumberFormat('en-CA', { 
      style: 'currency', 
      currency: currency 
    }).format(amount);
  };

  if (!quote.auto) {
    return (
      <div className={styles.noData}>
        <p>{t('no_auto_insurance_details')}</p>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.tabs}>
        <button
          className={`${styles.tab} ${activeTab === 'vehicles' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('vehicles')}
        >
          {t('vehicles')}
        </button>
        <button
          className={`${styles.tab} ${activeTab === 'drivers' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('drivers')}
        >
          {t('drivers')}
        </button>
        <button
          className={`${styles.tab} ${activeTab === 'coverages' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('coverages')}
        >
          {t('coverages')}
        </button>
        <button
          className={`${styles.tab} ${activeTab === 'premium' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('premium')}
        >
          {t('premium')}
        </button>
        <button
          className={`${styles.tab} ${activeTab === 'risk' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('risk')}
        >
          {t('risk')}
        </button>
        <button
          className={`${styles.tab} ${activeTab === 'claims' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('claims')}
        >
          {t('claims')}
        </button>
      </div>
      
      <div className={styles.tabContent}>
        {activeTab === 'vehicles' && (
          <div className={styles.vehiclesContent}>
            {quote.auto.vehicles && quote.auto.vehicles.length > 0 ? (
              quote.auto.vehicles.map((vehicle, index) => (
                <div key={vehicle.vehicle_id || index} className={styles.card}>
                  <div className={styles.cardHeader}>
                    <h3 className={styles.cardTitle}>
                      {vehicle.vehicle_year} {vehicle.vehicle_make} {vehicle.vehicle_model}
                    </h3>
                    {vehicle.is_primary_vehicle && (
                      <span className={styles.primaryBadge}>{t('primary')}</span>
                    )}
                  </div>
                  <div className={styles.cardContent}>
                    <div className={styles.sectionTitle}>{t('basic_information')}</div>
                    <div className={styles.infoGrid}>
                      <div className={styles.infoGroup}>
                        <div className={styles.infoLabel}>{t('vin')}:</div>
                        <div className={styles.infoValue}>{vehicle.vin || 'N/A'}</div>
                      </div>
                      <div className={styles.infoGroup}>
                        <div className={styles.infoLabel}>{t('license_plate')}:</div>
                        <div className={styles.infoValue}>{vehicle.license_plate || 'N/A'}</div>
                      </div>
                      <div className={styles.infoGroup}>
                        <div className={styles.infoLabel}>{t('vehicle_type')}:</div>
                        <div className={styles.infoValue}>{vehicle.vehicle_type || 'N/A'}</div>
                      </div>
                      <div className={styles.infoGroup}>
                        <div className={styles.infoLabel}>{t('vehicle_body_type')}:</div>
                        <div className={styles.infoValue}>{vehicle.vehicle_body_type || 'N/A'}</div>
                      </div>
                      <div className={styles.infoGroup}>
                        <div className={styles.infoLabel}>{t('vehicle_age')}:</div>
                        <div className={styles.infoValue}>{vehicle.vehicle_age || 'N/A'} {t('years')}</div>
                      </div>
                      <div className={styles.infoGroup}>
                        <div className={styles.infoLabel}>{t('number_of_doors')}:</div>
                        <div className={styles.infoValue}>{vehicle.vehicle_number_of_doors || 'N/A'}</div>
                      </div>
                    </div>

                    <div className={styles.sectionTitle}>{t('engine_information')}</div>
                    <div className={styles.infoGrid}>
                      {vehicle.engine && (
                        <>
                          <div className={styles.infoGroup}>
                            <div className={styles.infoLabel}>{t('engine_size')}:</div>
                            <div className={styles.infoValue}>
                              {vehicle.engine.engine_size ? `${vehicle.engine.engine_size} ${vehicle.engine.engine_unit || ''}` : 'N/A'}
                            </div>
                          </div>
                          <div className={styles.infoGroup}>
                            <div className={styles.infoLabel}>{t('fuel_type')}:</div>
                            <div className={styles.infoValue}>{vehicle.engine.primary_fuel_type || 'N/A'}</div>
                          </div>
                          <div className={styles.infoGroup}>
                            <div className={styles.infoLabel}>{t('drive_type')}:</div>
                            <div className={styles.infoValue}>{vehicle.engine.drive_type || 'N/A'}</div>
                          </div>
                          <div className={styles.infoGroup}>
                            <div className={styles.infoLabel}>{t('transmission')}:</div>
                            <div className={styles.infoValue}>{vehicle.engine.transmission_style || 'N/A'}</div>
                          </div>
                        </>
                      )}
                    </div>

                    <div className={styles.sectionTitle}>{t('usage_information')}</div>
                    <div className={styles.infoGrid}>
                      {vehicle.usage && (
                        <>
                          <div className={styles.infoGroup}>
                            <div className={styles.infoLabel}>{t('primary_use')}:</div>
                            <div className={styles.infoValue}>{vehicle.usage.primary_use_type || 'N/A'}</div>
                          </div>
                          <div className={styles.infoGroup}>
                            <div className={styles.infoLabel}>{t('annual_distance')}:</div>
                            <div className={styles.infoValue}>
                              {vehicle.usage.personal_use?.annual_distance 
                                ? `${vehicle.usage.personal_use.annual_distance} ${vehicle.usage.distance_unit || 'km'}`
                                : 'N/A'}
                            </div>
                          </div>
                        </>
                      )}
                    </div>

                    <div className={styles.sectionTitle}>{t('value_information')}</div>
                    <div className={styles.infoGrid}>
                      {vehicle.value_information && (
                        <>
                          <div className={styles.infoGroup}>
                            <div className={styles.infoLabel}>{t('current_value')}:</div>
                            <div className={styles.infoValue}>
                              {vehicle.value_information.vehicle_value 
                                ? formatCurrency(vehicle.value_information.vehicle_value)
                                : 'N/A'}
                            </div>
                          </div>
                          {vehicle.value_information.purchase_information && (
                            <>
                              <div className={styles.infoGroup}>
                                <div className={styles.infoLabel}>{t('purchase_price')}:</div>
                                <div className={styles.infoValue}>
                                  {vehicle.value_information.purchase_information.purchase_price 
                                    ? formatCurrency(vehicle.value_information.purchase_information.purchase_price)
                                    : 'N/A'}
                                </div>
                              </div>
                              <div className={styles.infoGroup}>
                                <div className={styles.infoLabel}>{t('purchase_date')}:</div>
                                <div className={styles.infoValue}>
                                  {vehicle.value_information.purchase_information.purchase_date 
                                    ? new Date(vehicle.value_information.purchase_information.purchase_date).toLocaleDateString()
                                    : 'N/A'}
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>

                    <div className={styles.sectionTitle}>{t('safety_features')}</div>
                    <div className={styles.infoGrid}>
                      {vehicle.safety_features && (
                        <>
                          <div className={styles.infoGroup}>
                            <div className={styles.infoLabel}>{t('winter_tires')}:</div>
                            <div className={styles.infoValue}>
                              {vehicle.safety_features.winter_tires ? t('yes') : t('no')}
                            </div>
                          </div>
                          <div className={styles.infoGroup}>
                            <div className={styles.infoLabel}>{t('abs_brakes')}:</div>
                            <div className={styles.infoValue}>
                              {vehicle.safety_features.antilock_braking_system_abs === "standard" || vehicle.safety_features.abs_brakes ? t('yes') : t('no')}
                            </div>
                          </div>
                          <div className={styles.infoGroup}>
                            <div className={styles.infoLabel}>{t('anti_theft_device')}:</div>
                            <div className={styles.infoValue}>{vehicle.safety_features.anti_theft_device || 'N/A'}</div>
                          </div>
                          <div className={styles.infoGroup}>
                            <div className={styles.infoLabel}>{t('airbag_type')}:</div>
                            <div className={styles.infoValue}>{vehicle.safety_features.airbag_type || 'N/A'}</div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className={styles.noData}>
                <p>{t('no_vehicle_information')}</p>
              </div>
            )}
          </div>
        )}
        
        {activeTab === 'drivers' && (
          <div className={styles.driversContent}>
            {quote.auto.drivers && quote.auto.drivers.length > 0 ? (
              quote.auto.drivers.map((driver, index) => (
                <div key={driver.driver_id || index} className={styles.card}>
                  <div className={styles.cardHeader}>
                    <h3 className={styles.cardTitle}>
                      {driver.demographics?.first_name || ''} {driver.demographics?.last_name || ''}
                    </h3>
                    {driver.driver_type === 'principal' && (
                      <span className={styles.primaryBadge}>{t('principal')}</span>
                    )}
                  </div>
                  <div className={styles.cardContent}>
                    <div className={styles.sectionTitle}>{t('basic_information')}</div>
                    <div className={styles.infoGrid}>
                      <div className={styles.infoGroup}>
                        <div className={styles.infoLabel}>{t('date_of_birth')}:</div>
                        <div className={styles.infoValue}>
                          {driver.demographics?.date_of_birth 
                            ? new Date(driver.demographics.date_of_birth).toLocaleDateString()
                            : 'N/A'}
                        </div>
                      </div>
                      <div className={styles.infoGroup}>
                        <div className={styles.infoLabel}>{t('gender')}:</div>
                        <div className={styles.infoValue}>{driver.demographics?.gender || 'N/A'}</div>
                      </div>
                      <div className={styles.infoGroup}>
                        <div className={styles.infoLabel}>{t('marital_status')}:</div>
                        <div className={styles.infoValue}>{driver.demographics?.marital_status || 'N/A'}</div>
                      </div>
                      <div className={styles.infoGroup}>
                        <div className={styles.infoLabel}>{t('occupation')}:</div>
                        <div className={styles.infoValue}>{driver.demographics?.occupation || 'N/A'}</div>
                      </div>
                    </div>

                    <div className={styles.sectionTitle}>{t('license_information')}</div>
                    <div className={styles.infoGrid}>
                      {driver.license && (
                        <>
                          <div className={styles.infoGroup}>
                            <div className={styles.infoLabel}>{t('license_class')}:</div>
                            <div className={styles.infoValue}>{driver.license.license_class || 'N/A'}</div>
                          </div>
                          <div className={styles.infoGroup}>
                            <div className={styles.infoLabel}>{t('years_licensed')}:</div>
                            <div className={styles.infoValue}>{driver.license.years_licensed || 'N/A'}</div>
                          </div>
                          <div className={styles.infoGroup}>
                            <div className={styles.infoLabel}>{t('province_state')}:</div>
                            <div className={styles.infoValue}>{driver.license.province_state_code || 'N/A'}</div>
                          </div>
                          <div className={styles.infoGroup}>
                            <div className={styles.infoLabel}>{t('license_expiry')}:</div>
                            <div className={styles.infoValue}>
                              {driver.license.license_expires_at 
                                ? new Date(driver.license.license_expires_at).toLocaleDateString()
                                : 'N/A'}
                            </div>
                          </div>
                        </>
                      )}
                    </div>

                    {driver.insurance_history && (
                      <>
                        <div className={styles.sectionTitle}>{t('insurance_history')}</div>
                        <div className={styles.infoGrid}>
                          <div className={styles.infoGroup}>
                            <div className={styles.infoLabel}>{t('years_insured')}:</div>
                            <div className={styles.infoValue}>{driver.insurance_history.years_insured || 'N/A'}</div>
                          </div>
                          <div className={styles.infoGroup}>
                            <div className={styles.infoLabel}>{t('years_claims_free')}:</div>
                            <div className={styles.infoValue}>{driver.insurance_history.years_claims_free || 'N/A'}</div>
                          </div>
                          <div className={styles.infoGroup}>
                            <div className={styles.infoLabel}>{t('continuous_insurance')}:</div>
                            <div className={styles.infoValue}>
                              {driver.insurance_history.continuous_insurance ? t('yes') : t('no')}
                            </div>
                          </div>
                          <div className={styles.infoGroup}>
                            <div className={styles.infoLabel}>{t('previous_insurer')}:</div>
                            <div className={styles.infoValue}>{driver.insurance_history.previous_insurer || 'N/A'}</div>
                          </div>
                        </div>
                      </>
                    )}

                    {driver.telematics && Object.keys(driver.telematics).length > 0 && (
                      <>
                        <div className={styles.sectionTitle}>{t('telematics_data')}</div>
                        <div className={styles.infoGrid}>
                          <div className={styles.infoGroup}>
                            <div className={styles.infoLabel}>{t('overall_score')}:</div>
                            <div className={styles.infoValue}>{driver.telematics.overall_score_agg || 'N/A'}</div>
                          </div>
                          <div className={styles.infoGroup}>
                            <div className={styles.infoLabel}>{t('total_trips')}:</div>
                            <div className={styles.infoValue}>{driver.telematics.total_trips_taken || 'N/A'}</div>
                          </div>
                          <div className={styles.infoGroup}>
                            <div className={styles.infoLabel}>{t('total_distance')}:</div>
                            <div className={styles.infoValue}>
                              {driver.telematics.tp_distance_sum_km 
                                ? `${driver.telematics.tp_distance_sum_km} km`
                                : 'N/A'}
                            </div>
                          </div>
                          <div className={styles.infoGroup}>
                            <div className={styles.infoLabel}>{t('acceleration_score')}:</div>
                            <div className={styles.infoValue}>{driver.telematics.acceleration_score_agg || 'N/A'}</div>
                          </div>
                          <div className={styles.infoGroup}>
                            <div className={styles.infoLabel}>{t('braking_score')}:</div>
                            <div className={styles.infoValue}>{driver.telematics.braking_score_agg || 'N/A'}</div>
                          </div>
                          <div className={styles.infoGroup}>
                            <div className={styles.infoLabel}>{t('speeding_score')}:</div>
                            <div className={styles.infoValue}>{driver.telematics.speeding_score_agg || 'N/A'}</div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div className={styles.noData}>
                <p>{t('no_driver_information')}</p>
              </div>
            )}
          </div>
        )}
        
        {activeTab === 'coverages' && (
          <div className={styles.coveragesContent}>
            {quote.auto.coverages && quote.auto.coverages.length > 0 ? (
              <div className={styles.tableContainer}>
                <table className={styles.table}>
                  <thead>
                    <tr>
                      <th>{t('coverage')}</th>
                      <th>{t('limit')}</th>
                      <th>{t('deductible')}</th>
                      <th>{t('premium')}</th>
                      <th>{t('type')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {quote.auto.coverages.map((coverage, index) => (
                      <tr key={coverage.coverage_id || index}>
                        <td>
                          <div className={styles.coverageName}>{coverage.coverage_name}</div>
                          <div className={styles.coverageCode}>{coverage.coverage_code}</div>
                          <div className={styles.coverageDescription}>{coverage.coverage_description}</div>
                        </td>
                        <td>
                          {coverage.limit 
                            ? formatCurrency(coverage.limit, coverage.limit_currency)
                            : 'N/A'}
                        </td>
                        <td>
                          {coverage.deductible 
                            ? formatCurrency(coverage.deductible, coverage.deductible_currency)
                            : 'N/A'}
                        </td>
                        <td>
                          {coverage.premium?.total 
                            ? formatCurrency(coverage.premium.total)
                            : 'N/A'}
                        </td>
                        <td>
                          <span className={styles.coverageCategory}>
                            {coverage.coverage_category}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className={styles.noData}>
                <p>{t('no_coverage_information')}</p>
              </div>
            )}
          </div>
        )}
        
        {activeTab === 'premium' && (
          <div className={styles.premiumContent}>
            {quote.premium?.policy_premium ? (
              <div className={styles.premiumDetails}>
                <div className={styles.card}>
                  <div className={styles.cardHeader}>
                    <h3 className={styles.cardTitle}>{t('premium_breakdown')}</h3>
                  </div>
                  <div className={styles.cardContent}>
                    <div className={styles.premiumGrid}>
                      <div className={styles.infoGroup}>
                        <div className={styles.infoLabel}>{t('base_premium')}:</div>
                        <div className={styles.infoValue}>
                          {formatCurrency(quote.premium.policy_premium.base_premium)}
                        </div>
                      </div>
                      <div className={styles.infoGroup}>
                        <div className={styles.infoLabel}>{t('discounted_premium')}:</div>
                        <div className={styles.infoValue}>
                          {formatCurrency(quote.premium.policy_premium.discounted_premium)}
                        </div>
                      </div>
                      <div className={styles.infoGroup}>
                        <div className={styles.infoLabel}>{t('fees')}:</div>
                        <div className={styles.infoValue}>
                          {formatCurrency(quote.premium.policy_premium.fees_amount)}
                        </div>
                      </div>
                      <div className={styles.infoGroup}>
                        <div className={styles.infoLabel}>{t('taxes')}:</div>
                        <div className={styles.infoValue}>
                          {formatCurrency(quote.premium.policy_premium.taxes_amount)}
                        </div>
                      </div>
                      <div className={styles.infoGroup}>
                        <div className={styles.infoLabel}>{t('final_premium')}:</div>
                        <div className={`${styles.infoValue} ${styles.finalPremium}`}>
                          {formatCurrency(quote.premium.policy_premium.final_premium)}
                        </div>
                      </div>
                      <div className={styles.infoGroup}>
                        <div className={styles.infoLabel}>{t('total_policy_cost')}:</div>
                        <div className={`${styles.infoValue} ${styles.totalCost}`}>
                          {formatCurrency(quote.premium.policy_premium.total_policy_cost)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {quote.premium.discounts && quote.premium.discounts.length > 0 && (
                  <div className={styles.card}>
                    <div className={styles.cardHeader}>
                      <h3 className={styles.cardTitle}>{t('discounts')}</h3>
                    </div>
                    <div className={styles.cardContent}>
                      <div className={styles.tableContainer}>
                        <table className={styles.table}>
                          <thead>
                            <tr>
                              <th>{t('discount_name')}</th>
                              <th>{t('discount_type')}</th>
                              <th>{t('discount_value')}</th>
                              <th>{t('applied')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {quote.premium.discounts.map((discount, index) => (
                              <tr key={discount.discount_id || index}>
                                <td>
                                  <div className={styles.discountName}>{discount.discount_name}</div>
                                  <div className={styles.discountCode}>{discount.discount_code}</div>
                                </td>
                                <td>{discount.discount_type}</td>
                                <td>
                                  {discount.discount_percentage 
                                    ? `${discount.discount_percentage}%` 
                                    : discount.discount_amount 
                                      ? formatCurrency(discount.discount_amount)
                                      : 'N/A'}
                                </td>
                                <td>
                                  <span className={discount.applied ? styles.applied : styles.notApplied}>
                                    {discount.applied ? t('yes') : t('no')}
                                  </span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}

                {quote.premium.payment_options && quote.premium.payment_options.length > 0 && (
                  <div className={styles.card}>
                    <div className={styles.cardHeader}>
                      <h3 className={styles.cardTitle}>{t('payment_options')}</h3>
                    </div>
                    <div className={styles.cardContent}>
                      <div className={styles.tableContainer}>
                        <table className={styles.table}>
                          <thead>
                            <tr>
                              <th>{t('payment_method')}</th>
                              <th>{t('frequency')}</th>
                              <th>{t('installments')}</th>
                              <th>{t('first_payment')}</th>
                              <th>{t('recurring_payment')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {quote.premium.payment_options.map((option, index) => (
                              <tr key={option.payment_option_id || index}>
                                <td>{option.payment_method}</td>
                                <td>{option.payment_frequency}</td>
                                <td>{option.number_of_installments}</td>
                                <td>{formatCurrency(option.first_installment_amount)}</td>
                                <td>{formatCurrency(option.recurring_installment_amount)}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}

                {quote.premium.policy_premium.competitive_intelligence && (
                  <div className={styles.card}>
                    <div className={styles.cardHeader}>
                      <h3 className={styles.cardTitle}>{t('competitive_intelligence')}</h3>
                    </div>
                    <div className={styles.cardContent}>
                      <div className={styles.infoGrid}>
                        <div className={styles.infoGroup}>
                          <div className={styles.infoLabel}>{t('competitive_position')}:</div>
                          <div className={styles.infoValue}>
                            {quote.premium.policy_premium.competitive_intelligence.competitive_position}
                          </div>
                        </div>
                        <div className={styles.infoGroup}>
                          <div className={styles.infoLabel}>{t('carriers_quoted')}:</div>
                          <div className={styles.infoValue}>
                            {quote.premium.policy_premium.competitive_intelligence.number_of_carriers_quoted}
                          </div>
                        </div>
                      </div>

                      {quote.premium.policy_premium.competitive_intelligence.premiums && 
                       quote.premium.policy_premium.competitive_intelligence.premiums.length > 0 && (
                        <div className={styles.competitiveChart}>
                          <h4 className={styles.chartTitle}>{t('competitive_premiums')}</h4>
                          <div className={styles.tableContainer}>
                            <table className={styles.table}>
                              <thead>
                                <tr>
                                  <th>{t('carrier')}</th>
                                  <th>{t('premium')}</th>
                                  <th>{t('difference')}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {quote.premium.policy_premium.competitive_intelligence.premiums.map((premium, index) => {
                                  const ourPremium = quote.premium.policy_premium.final_premium;
                                  const difference = premium.premium_amount - ourPremium;
                                  const percentDifference = (difference / ourPremium) * 100;
                                  
                                  return (
                                    <tr key={index} className={index === 0 ? styles.ourPremium : ''}>
                                      <td>
                                        {premium.carrier_name || `${t('carrier')} ${premium.carrier_number}`}
                                        {index === 0 && <span className={styles.ourPremiumLabel}> ({t('our_premium')})</span>}
                                      </td>
                                      <td>{formatCurrency(premium.premium_amount)}</td>
                                      <td>
                                        {index === 0 ? '-' : (
                                          <span className={difference > 0 ? styles.positiveDifference : styles.negativeDifference}>
                                            {formatCurrency(Math.abs(difference))} 
                                            ({difference > 0 ? '+' : '-'}{Math.abs(percentDifference).toFixed(1)}%)
                                          </span>
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className={styles.noData}>
                <p>{t('no_premium_information')}</p>
              </div>
            )}
          </div>
        )}
        
        {activeTab === 'risk' && (
          <div className={styles.riskContent}>
            {quote.auto.risk ? (
              <div className={styles.riskDetails}>
                {quote.auto.risk.risk && quote.auto.risk.risk.policy_risk && (
                  <div className={styles.card}>
                    <div className={styles.cardHeader}>
                      <h3 className={styles.cardTitle}>{t('policy_risk')}</h3>
                    </div>
                    <div className={styles.cardContent}>
                      <div className={styles.infoGrid}>
                        <div className={styles.infoGroup}>
                          <div className={styles.infoLabel}>{t('risk_score')}:</div>
                          <div className={styles.infoValue}>{quote.auto.risk.risk.policy_risk.risk_score || 'N/A'}</div>
                        </div>
                        <div className={styles.infoGroup}>
                          <div className={styles.infoLabel}>{t('risk_tier')}:</div>
                          <div className={styles.infoValue}>{quote.auto.risk.risk.policy_risk.risk_tier || 'N/A'}</div>
                        </div>
                        <div className={styles.infoGroup}>
                          <div className={styles.infoLabel}>{t('underwriting_tier')}:</div>
                          <div className={styles.infoValue}>{quote.auto.risk.risk.policy_risk.underwriting_tier || 'N/A'}</div>
                        </div>
                        <div className={styles.infoGroup}>
                          <div className={styles.infoLabel}>{t('credit_score')}:</div>
                          <div className={styles.infoValue}>{quote.auto.risk.risk.policy_risk.credit_score || 'N/A'}</div>
                        </div>
                        <div className={styles.infoGroup}>
                          <div className={styles.infoLabel}>{t('payment_risk_score')}:</div>
                          <div className={styles.infoValue}>{quote.auto.risk.risk.policy_risk.payment_risk_score || 'N/A'}</div>
                        </div>
                        <div className={styles.infoGroup}>
                          <div className={styles.infoLabel}>{t('claims_history_score')}:</div>
                          <div className={styles.infoValue}>{quote.auto.risk.risk.policy_risk.claims_history_score || 'N/A'}</div>
                        </div>
                        <div className={styles.infoGroup}>
                          <div className={styles.infoLabel}>{t('loyalty_factor')}:</div>
                          <div className={styles.infoValue}>{quote.auto.risk.risk.policy_risk.loyalty_factor || 'N/A'}</div>
                        </div>
                        <div className={styles.infoGroup}>
                          <div className={styles.infoLabel}>{t('multi_policy_discount')}:</div>
                          <div className={styles.infoValue}>
                            {quote.auto.risk.risk.policy_risk.multi_policy_discount ? t('yes') : t('no')}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {quote.auto.risk.risk && quote.auto.risk.risk.territory && (
                  <div className={styles.card}>
                    <div className={styles.cardHeader}>
                      <h3 className={styles.cardTitle}>{t('territory_risk')}</h3>
                    </div>
                    <div className={styles.cardContent}>
                      <div className={styles.infoGrid}>
                        <div className={styles.infoGroup}>
                          <div className={styles.infoLabel}>{t('territory_code')}:</div>
                          <div className={styles.infoValue}>{quote.auto.risk.risk.territory.territory_code || 'N/A'}</div>
                        </div>
                        <div className={styles.infoGroup}>
                          <div className={styles.infoLabel}>{t('risk_factor')}:</div>
                          <div className={styles.infoValue}>{quote.auto.risk.risk.territory.risk_factor || 'N/A'}</div>
                        </div>
                        <div className={styles.infoGroup}>
                          <div className={styles.infoLabel}>{t('urban_classification')}:</div>
                          <div className={styles.infoValue}>{quote.auto.risk.risk.territory.urban_classification || 'N/A'}</div>
                        </div>
                        <div className={styles.infoGroup}>
                          <div className={styles.infoLabel}>{t('theft_risk_zone')}:</div>
                          <div className={styles.infoValue}>{quote.auto.risk.risk.territory.theft_risk_zone || 'N/A'}</div>
                        </div>
                        <div className={styles.infoGroup}>
                          <div className={styles.infoLabel}>{t('flood_zone')}:</div>
                          <div className={styles.infoValue}>{quote.auto.risk.risk.territory.flood_zone || 'N/A'}</div>
                        </div>
                        <div className={styles.infoGroup}>
                          <div className={styles.infoLabel}>{t('wildfire_zone')}:</div>
                          <div className={styles.infoValue}>{quote.auto.risk.risk.territory.wildfire_zone || 'N/A'}</div>
                        </div>
                        <div className={styles.infoGroup}>
                          <div className={styles.infoLabel}>{t('earthquake_zone')}:</div>
                          <div className={styles.infoValue}>{quote.auto.risk.risk.territory.earthquake_zone || 'N/A'}</div>
                        </div>
                        <div className={styles.infoGroup}>
                          <div className={styles.infoLabel}>{t('postal_code_risk_factor')}:</div>
                          <div className={styles.infoValue}>{quote.auto.risk.risk.territory.postal_code_risk_factor || 'N/A'}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {quote.auto.risk.risk && quote.auto.risk.risk.vehicle_risks && quote.auto.risk.risk.vehicle_risks.length > 0 && (
                  <div className={styles.card}>
                    <div className={styles.cardHeader}>
                      <h3 className={styles.cardTitle}>{t('vehicle_risk_factors')}</h3>
                    </div>
                    <div className={styles.cardContent}>
                      {quote.auto.risk.risk.vehicle_risks.map((vehicleRisk, index) => {
                        // Find the corresponding vehicle
                        const vehicle = quote.auto.vehicles?.find(v => v.vehicle_id === vehicleRisk.vehicle_id);
                        const vehicleName = vehicle 
                          ? `${vehicle.vehicle_year} ${vehicle.vehicle_make} ${vehicle.vehicle_model}`
                          : `${t('vehicle')} ${index + 1}`;
                          
                        return (
                          <div key={vehicleRisk.vehicle_risk_id || index} className={styles.vehicleRiskSection}>
                            <h4 className={styles.vehicleRiskTitle}>{vehicleName}</h4>
                            <div className={styles.infoGrid}>
                              <div className={styles.infoGroup}>
                                <div className={styles.infoLabel}>{t('vehicle_age_factor')}:</div>
                                <div className={styles.infoValue}>{vehicleRisk.vehicle_age_factor || 'N/A'}</div>
                              </div>
                              <div className={styles.infoGroup}>
                                <div className={styles.infoLabel}>{t('vehicle_type_factor')}:</div>
                                <div className={styles.infoValue}>{vehicleRisk.vehicle_type_factor || 'N/A'}</div>
                              </div>
                              <div className={styles.infoGroup}>
                                <div className={styles.infoLabel}>{t('vehicle_use_factor')}:</div>
                                <div className={styles.infoValue}>{vehicleRisk.vehicle_use_factor || 'N/A'}</div>
                              </div>
                              <div className={styles.infoGroup}>
                                <div className={styles.infoLabel}>{t('vehicle_safety_factor')}:</div>
                                <div className={styles.infoValue}>{vehicleRisk.vehicle_safety_factor || 'N/A'}</div>
                              </div>
                              <div className={styles.infoGroup}>
                                <div className={styles.infoLabel}>{t('vehicle_theft_factor')}:</div>
                                <div className={styles.infoValue}>{vehicleRisk.vehicle_theft_factor || 'N/A'}</div>
                              </div>
                              <div className={styles.infoGroup}>
                                <div className={styles.infoLabel}>{t('collision_rating_group')}:</div>
                                <div className={styles.infoValue}>{vehicleRisk.collision_rating_group || 'N/A'}</div>
                              </div>
                              <div className={styles.infoGroup}>
                                <div className={styles.infoLabel}>{t('comprehensive_rating_group')}:</div>
                                <div className={styles.infoValue}>{vehicleRisk.comprehensive_rating_group || 'N/A'}</div>
                              </div>
                              <div className={styles.infoGroup}>
                                <div className={styles.infoLabel}>{t('liability_rating_group')}:</div>
                                <div className={styles.infoValue}>{vehicleRisk.liability_rating_group || 'N/A'}</div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}

                {quote.auto.risk.risk && quote.auto.risk.risk.driver_risks && quote.auto.risk.risk.driver_risks.length > 0 && (
                  <div className={styles.card}>
                    <div className={styles.cardHeader}>
                      <h3 className={styles.cardTitle}>{t('driver_risk_factors')}</h3>
                    </div>
                    <div className={styles.cardContent}>
                      {quote.auto.risk.risk.driver_risks.map((driverRisk, index) => {
                        // Find the corresponding driver
                        const driver = quote.auto.drivers?.find(d => d.driver_id === driverRisk.driver_id);
                        const driverName = driver && driver.demographics
                          ? `${driver.demographics.first_name} ${driver.demographics.last_name}`
                          : `${t('driver')} ${index + 1}`;
                          
                        return (
                          <div key={driverRisk.driver_risk_id || index} className={styles.driverRiskSection}>
                            <h4 className={styles.driverRiskTitle}>{driverName}</h4>
                            <div className={styles.infoGrid}>
                              <div className={styles.infoGroup}>
                                <div className={styles.infoLabel}>{t('driver_age_factor')}:</div>
                                <div className={styles.infoValue}>{driverRisk.driver_age_factor || 'N/A'}</div>
                              </div>
                              <div className={styles.infoGroup}>
                                <div className={styles.infoLabel}>{t('driver_experience_factor')}:</div>
                                <div className={styles.infoValue}>{driverRisk.driver_experience_factor || 'N/A'}</div>
                              </div>
                              <div className={styles.infoGroup}>
                                <div className={styles.infoLabel}>{t('driver_record_factor')}:</div>
                                <div className={styles.infoValue}>{driverRisk.driver_record_factor || 'N/A'}</div>
                              </div>
                              <div className={styles.infoGroup}>
                                <div className={styles.infoLabel}>{t('driver_training_factor')}:</div>
                                <div className={styles.infoValue}>{driverRisk.driver_training_factor || 'N/A'}</div>
                              </div>
                              <div className={styles.infoGroup}>
                                <div className={styles.infoLabel}>{t('driver_occupation_factor')}:</div>
                                <div className={styles.infoValue}>{driverRisk.driver_occupation_factor || 'N/A'}</div>
                              </div>
                              <div className={styles.infoGroup}>
                                <div className={styles.infoLabel}>{t('telematics_risk_factor')}:</div>
                                <div className={styles.infoValue}>{driverRisk.telematics_risk_factor || 'N/A'}</div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className={styles.noData}>
                <p>{t('no_risk_information')}</p>
              </div>
            )}
          </div>
        )}
        
        {activeTab === 'claims' && (
          <div className={styles.claimsContent}>
            {quote.auto.claims ? (
              <div className={styles.claimsDetails}>
                {quote.auto.claims.claim_records && quote.auto.claims.claim_records.length > 0 ? (
                  <div className={styles.card}>
                    <div className={styles.cardHeader}>
                      <h3 className={styles.cardTitle}>{t('claim_records')}</h3>
                    </div>
                    <div className={styles.cardContent}>
                      <div className={styles.tableContainer}>
                        <table className={styles.table}>
                          <thead>
                            <tr>
                              <th>{t('date')}</th>
                              <th>{t('type')}</th>
                              <th>{t('at_fault')}</th>
                              <th>{t('amount')}</th>
                              <th>{t('status')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {quote.auto.claims.claim_records.map((claim, index) => (
                              <tr key={claim.claim_id || index}>
                                <td>{formatDate(claim.claim_date)}</td>
                                <td>{claim.claim_type || 'N/A'}</td>
                                <td>
                                  <span className={claim.at_fault ? styles.atFault : styles.notAtFault}>
                                    {claim.at_fault ? t('yes') : t('no')}
                                  </span>
                                </td>
                                <td>
                                  {claim.claim_amounts?.total_incurred 
                                    ? formatCurrency(claim.claim_amounts.total_incurred)
                                    : 'N/A'}
                                </td>
                                <td>{claim.claim_status || 'N/A'}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={styles.card}>
                    <div className={styles.cardHeader}>
                      <h3 className={styles.cardTitle}>{t('claim_records')}</h3>
                    </div>
                    <div className={styles.cardContent}>
                      <div className={styles.noClaimsMessage}>{t('no_claims_found')}</div>
                    </div>
                  </div>
                )}

                {quote.auto.claims.claim_aggregates && (
                  <div className={styles.card}>
                    <div className={styles.cardHeader}>
                      <h3 className={styles.cardTitle}>{t('claim_aggregates')}</h3>
                    </div>
                    <div className={styles.cardContent}>
                      {quote.auto.claims.claim_aggregates.policy_aggregates && (
                        <div className={styles.aggregateSection}>
                          <h4 className={styles.aggregateTitle}>{t('policy_level_aggregates')}</h4>
                          <div className={styles.infoGrid}>
                            <div className={styles.infoGroup}>
                              <div className={styles.infoLabel}>{t('total_claims_3y')}:</div>
                              <div className={styles.infoValue}>
                                {quote.auto.claims.claim_aggregates.policy_aggregates.total_claims_3y}
                              </div>
                            </div>
                            <div className={styles.infoGroup}>
                              <div className={styles.infoLabel}>{t('total_claims_6y')}:</div>
                              <div className={styles.infoValue}>
                                {quote.auto.claims.claim_aggregates.policy_aggregates.total_claims_6y}
                              </div>
                            </div>
                            <div className={styles.infoGroup}>
                              <div className={styles.infoLabel}>{t('at_fault_claims_3y')}:</div>
                              <div className={styles.infoValue}>
                                {quote.auto.claims.claim_aggregates.policy_aggregates.at_fault_claims_3y}
                              </div>
                            </div>
                            <div className={styles.infoGroup}>
                              <div className={styles.infoLabel}>{t('at_fault_claims_6y')}:</div>
                              <div className={styles.infoValue}>
                                {quote.auto.claims.claim_aggregates.policy_aggregates.at_fault_claims_6y}
                              </div>
                            </div>
                            <div className={styles.infoGroup}>
                              <div className={styles.infoLabel}>{t('claims_free_years')}:</div>
                              <div className={styles.infoValue}>
                                {quote.auto.claims.claim_aggregates.policy_aggregates.claims_free_years}
                              </div>
                            </div>
                            <div className={styles.infoGroup}>
                              <div className={styles.infoLabel}>{t('eligible_for_claims_free_discount')}:</div>
                              <div className={styles.infoValue}>
                                {quote.auto.claims.claim_aggregates.policy_aggregates.eligible_for_claims_free_discount 
                                  ? t('yes') 
                                  : t('no')}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className={styles.noData}>
                <p>{t('no_claims_information')}</p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AutoDetails;