// File: frontend/src/pages/SupportedCountries/SupportedCountries.js

import React, { useState } from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import MapComponent from '../../components/Dashboard/MapComponent';
import mapData from '../../data/mapbox_data_for_dashboard.json';
import styles from './SupportedCountries.module.css';

const SupportedCountries = () => {
  const { t } = useLanguage();
  const [styleLoaded, setStyleLoaded] = useState(false);
  const [activeCategory, setActiveCategory] = useState('allegory_intelligence');

  const handleStyleLoad = (loaded) => {
    setStyleLoaded(loaded);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t('supported_countries_title')}</h1>
        <p className={styles.description}>{t('supported_countries_description')}</p>
      </div>

      <div className={styles.mapCard}>
        <div className={styles.mapHeader}>
          <div className={styles.categoryButtons}>
            {Object.entries(mapData.categories).map(([key, category]) => (
              <button
                key={key}
                className={`${styles.categoryButton} ${activeCategory === key ? styles.active : ''}`}
                onClick={() => setActiveCategory(key)}
              >
                {category.name}
              </button>
            ))}
          </div>
        </div>

        <div className={styles.mapSection}>
          {!styleLoaded && (
            <div className={styles.mapLoading}>
              <div className={styles.loadingSpinner}></div>
              Loading map...
            </div>
          )}
          <MapComponent 
            data={mapData.categories[activeCategory]}
            category={activeCategory}
            onStyleLoad={handleStyleLoad}
          />
        </div>
      </div>

      <div className={styles.statsSection}>
        <div className={styles.stat}>
          <h3>{mapData.supported_countries.countries.length}+</h3>
          <p>{t('countries_supported')}</p>
        </div>
        <div className={styles.stat}>
          <h3>24/7</h3>
          <p>{t('global_coverage')}</p>
        </div>
        <div className={styles.stat}>
          <h3>99.9%</h3>
          <p>{t('service_uptime')}</p>
        </div>
      </div>
    </div>
  );
};

export default SupportedCountries;