// src/components/common/MapboxWrapper.js
import React from 'react';
// eslint-disable-next-line import/no-unresolved
import Plot from 'react-plotly.js';
import useMapboxToken from '../../hooks/useMapboxToken';

const MapboxWrapper = ({ data, layout, style }) => {
  const mapboxAccessToken = useMapboxToken();

  const config = {
    mapboxAccessToken: mapboxAccessToken
  };

  return (
    <Plot
      data={data}
      layout={{
        ...layout,
        mapbox: {
          ...layout.mapbox,
          style: "dark",
        },
      }}
      config={config}
      style={style}
    />
  );
};

export default MapboxWrapper;