// File: frontend/src/pages/UMS/UserPortfolio/index.js

import React from 'react';
import { useLanguage } from '../../../contexts/LanguageContext';
import PortfolioMaps from './components/PortfolioMaps';
import PortfolioOverview from './components/PortfolioOverview';
import AIInsights from './components/AIInsights';
import styles from './UserPortfolio.module.css';

const UserPortfolio = ({ dateRange }) => {
  const { t } = useLanguage();

  return (
    <div className={styles.container}>
      <div className={styles.description}>
        <p>{t('user_portfolio_description')}</p>
      </div>
      
      <div className={styles.section}>
        <h2 className={styles.sectionTitle}>{t('portfolio_overview')}</h2>
        <PortfolioOverview dateRange={dateRange} />
      </div>
      
      <div className={styles.section}>
        <h2 className={styles.sectionTitle}>{t('geographic_distribution')}</h2>
        <PortfolioMaps dateRange={dateRange} />
      </div>
      
      <div className={styles.section}>
        <h2 className={styles.sectionTitle}>{t('ai_insights')}</h2>
        <AIInsights dateRange={dateRange} />
      </div>
    </div>
  );
};

export default UserPortfolio;