// In Hero.js
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import styles from './Hero.module.css';

const Hero = ({ onStartQuote }) => {
  const { t } = useLanguage();
  const canvasRef = useRef(null);
  const [isAnimated, setIsAnimated] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const hasAnimatedRef = useRef(false);
  const animationFrameRef = useRef(null);
  const isVisibleRef = useRef(true);
  
  // Check for mobile viewport on mount and resize
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 900);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    
    return () => window.removeEventListener('resize', checkMobile);
  }, []);
  
  // Function to draw the final state of the animation
  const drawFinalState = useCallback(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    
    const ctx = canvas.getContext('2d');
    const dpr = window.devicePixelRatio || 1;
    
    // Clear canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    
    // Get canvas dimensions
    const width = canvas.width / dpr;
    const height = canvas.height / dpr;
    
    // Calculate positions
    const centerY = height / 2;
    // Increase the radius by 15%
    const radius = Math.min(Math.min(width, height) / 4, 100) * 1.15;
    
    // Traditional insurance circle
    const traditionalX = width / 4;
    const traditionalY = centerY;
    
    // Allegory circle
    const allegoryX = (width / 4) * 3;
    const allegoryY = centerY;
    
    // Draw traditional base circle
    ctx.beginPath();
    ctx.arc(traditionalX, traditionalY, radius, 0, 2 * Math.PI);
    ctx.fillStyle = 'rgba(255, 255, 255, 0.1)';
    ctx.fill();
    
    // Draw allegory base circle
    ctx.beginPath();
    ctx.arc(allegoryX, allegoryY, radius, 0, 2 * Math.PI);
    ctx.fillStyle = 'rgba(255, 255, 255, 0.1)';
    ctx.fill();
    
    // Draw traditional expense slice (33%)
    ctx.beginPath();
    ctx.moveTo(traditionalX, traditionalY);
    ctx.arc(traditionalX, traditionalY, radius, -0.5 * Math.PI, -0.5 * Math.PI + (0.33 * 2 * Math.PI));
    ctx.closePath();
    ctx.fillStyle = '#FF6B6B';
    ctx.fill();
    
    // Draw allegory expense slice (8%)
    ctx.beginPath();
    ctx.moveTo(allegoryX, allegoryY);
    ctx.arc(allegoryX, allegoryY, radius, -0.5 * Math.PI, -0.5 * Math.PI + (0.0833 * 2 * Math.PI));
    ctx.closePath();
    ctx.fillStyle = '#38C793';
    ctx.fill();
    
    // Responsive font size
    // Responsive font size - increase by 15%
    const baseFontSize = Math.max(12, Math.min(16, width / 40)) * 1.15;
    const expenseFontSize = Math.max(10, Math.min(14, width / 45)) * 1.15;
    
    // Draw labels
    ctx.font = `600 ${baseFontSize}px var(--font-primary)`;
    ctx.fillStyle = 'rgba(255, 255, 255, 0.9)';
    ctx.textAlign = 'center';
    // Draw labels - increase spacing by 15%
    ctx.fillText(t('hero.traditional_insurance'), traditionalX, traditionalY + radius + 35); // Increased from 30
    ctx.fillText(t('hero.allegory'), allegoryX, allegoryY + radius + 35); // Increased from 30
    
    // Draw expense percentages
    ctx.font = `400 ${expenseFontSize}px var(--font-primary)`;
    ctx.fillStyle = 'rgba(255, 255, 255, 0.7)';
    // Draw expense percentages - increase spacing by 15%
    ctx.fillText(t('hero.expenses_percentage', { percentage: 33 }), 
                traditionalX, traditionalY + radius + 63); // Increased from 55
    ctx.fillText(t('hero.expenses_percentage', { percentage: 8 }), 
                allegoryX, allegoryY + radius + 63); // Increased from 55
    
    // Draw center dividing line
    ctx.beginPath();
    ctx.moveTo(width / 2, 30);
    ctx.lineTo(width / 2, height - 30);
    ctx.strokeStyle = 'rgba(255, 255, 255, 0.2)';
    ctx.lineWidth = 1;
    ctx.setLineDash([5, 3]);
    ctx.stroke();
    ctx.setLineDash([]);
    
    // Draw arrow
    const arrowOffsetX = Math.min(90, radius + 10) * 1.15;
    
    ctx.beginPath();
    ctx.moveTo(traditionalX + arrowOffsetX, traditionalY);
    ctx.lineTo(allegoryX - arrowOffsetX, traditionalY);
    ctx.strokeStyle = 'rgba(56, 199, 147, 1)';
    ctx.lineWidth = 2;
    ctx.stroke();
    
    // Draw arrow head
    const arrowHeadSize = Math.min(10, radius / 8);
    ctx.beginPath();
    ctx.moveTo(allegoryX - arrowOffsetX, traditionalY);
    ctx.lineTo(allegoryX - arrowOffsetX - arrowHeadSize, traditionalY - arrowHeadSize);
    ctx.lineTo(allegoryX - arrowOffsetX - arrowHeadSize, traditionalY + arrowHeadSize);
    ctx.closePath();
    ctx.fillStyle = 'rgba(56, 199, 147, 1)';
    ctx.fill();
  }, [t]);
  
  // Animation setup and logic for visualization
  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    
    // Skip if we've already animated to prevent double animations
    if (hasAnimatedRef.current) {
      // Just redraw the final state
      drawFinalState();
      return;
    }
    
    const ctx = canvas.getContext('2d');
    const dpr = window.devicePixelRatio || 1;
    
    // Set canvas dimensions with proper DPI handling
    const updateCanvasDimensions = () => {
      const rect = canvas.getBoundingClientRect();
      canvas.width = rect.width * dpr;
      canvas.height = rect.height * dpr;
      ctx.scale(dpr, dpr);
    };
    
    updateCanvasDimensions();
    
    // Responsive circle sizes based on canvas dimensions
    const getCircleRadius = () => {
      const minDimension = Math.min(canvas.width / dpr, canvas.height / dpr);
      const isDesktop = window.innerWidth > 1024;
      // Increase the radius by 15%
      return Math.min(minDimension / 4, isDesktop ? 100 : 80) * 1.15;
    };
    
    // Animation variables
    let startTime = null;
    const animationDuration = 1500; // ms
    
    // Calculate responsive positions and sizes
    const getCirclePositions = () => {
      const radius = getCircleRadius();
      const centerY = canvas.height / (2 * dpr);
      
      // Traditional insurance circle properties
      const traditional = {
        x: canvas.width / (4 * dpr),
        y: centerY,
        radius: radius,
        expenseAngle: 0,
        expenseColor: '#FF6B6B',
        baseColor: 'rgba(255, 255, 255, 0.1)'
      };
      
      // Allegory insurance circle properties
      const allegory = {
        x: (canvas.width / (4 * dpr)) * 3,
        y: centerY,
        radius: radius,
        expenseAngle: 0,
        expenseColor: '#38C793',
        baseColor: 'rgba(255, 255, 255, 0.1)'
      };
      
      return { traditional, allegory, centerY };
    };
    
    const positions = getCirclePositions();
    const { traditional, allegory, centerY } = positions;
    
    // Draw static parts immediately
    const drawBaseCircles = () => {
      // Clear canvas
      ctx.clearRect(0, 0, canvas.width / dpr, canvas.height / dpr);
      
      // Draw traditional base circle
      ctx.beginPath();
      ctx.arc(traditional.x, traditional.y, traditional.radius, 0, 2 * Math.PI);
      ctx.fillStyle = traditional.baseColor;
      ctx.fill();
      
      // Draw allegory base circle
      ctx.beginPath();
      ctx.arc(allegory.x, allegory.y, allegory.radius, 0, 2 * Math.PI);
      ctx.fillStyle = allegory.baseColor;
      ctx.fill();
      
      // Responsive font size based on canvas width and device - increase by 15%
      const isDesktop = window.innerWidth > 1024;
      const baseFontSize = Math.max(14, Math.min(isDesktop ? 18 : 16, canvas.width / (isDesktop ? 35 : 40) * dpr)) * 1.15;
      
      // Draw labels with larger text on desktop
      ctx.font = `600 ${baseFontSize}px var(--font-primary)`;
      ctx.fillStyle = 'rgba(255, 255, 255, 0.9)';
      ctx.textAlign = 'center';
      // Draw labels - increase spacing by 15%
      ctx.fillText(t('hero.traditional_insurance'), traditional.x, traditional.y + traditional.radius + 35); // Increased from 30
      ctx.fillText(t('hero.allegory'), allegory.x, allegory.y + allegory.radius + 35); // Increased from 30
    };
    
    // Draw animated parts
    const drawAnimatedParts = (progress) => {
      // Calculate current expense angles based on animation progress
      const traditionalExpenseAngle = progress * (0.33 * 2 * Math.PI);
      const allegoryExpenseAngle = progress * (0.0833 * 2 * Math.PI);
      
      // Draw traditional expense slice
      ctx.beginPath();
      ctx.moveTo(traditional.x, traditional.y);
      ctx.arc(traditional.x, traditional.y, traditional.radius, -0.5 * Math.PI, -0.5 * Math.PI + traditionalExpenseAngle);
      ctx.closePath();
      ctx.fillStyle = traditional.expenseColor;
      ctx.fill();
      
      // Draw allegory expense slice
      ctx.beginPath();
      ctx.moveTo(allegory.x, allegory.y);
      ctx.arc(allegory.x, allegory.y, allegory.radius, -0.5 * Math.PI, -0.5 * Math.PI + allegoryExpenseAngle);
      ctx.closePath();
      ctx.fillStyle = allegory.expenseColor;
      ctx.fill();
      
      // Responsive font size based on canvas width and device
      const isDesktop = window.innerWidth > 1024;
      const expenseFontSize = Math.max(12, Math.min(isDesktop ? 16 : 14, canvas.width / (isDesktop ? 40 : 45) * dpr)) * 1.15;
      
      // Draw expense percentages with larger text on desktop
      ctx.font = `400 ${expenseFontSize}px var(--font-primary)`;
      ctx.fillStyle = 'rgba(255, 255, 255, 0.7)';
      ctx.textAlign = 'center';
      // Draw expense percentages - increase spacing by 15%
      ctx.fillText(t('hero.expenses_percentage', { percentage: Math.round(progress * 33) }), 
                  traditional.x, traditional.y + traditional.radius + 63); // Increased from 55
      ctx.fillText(t('hero.expenses_percentage', { percentage: Math.round(progress * 8) }), 
                  allegory.x, allegory.y + allegory.radius + 63); // Increased from 55
      
      // Draw center dividing line
      if (progress > 0.3) {
        const lineOpacity = (progress - 0.3) / 0.7;
        ctx.beginPath();
        ctx.moveTo(canvas.width / (2 * dpr), 30 / dpr);
        ctx.lineTo(canvas.width / (2 * dpr), (canvas.height - 30) / dpr);
        ctx.strokeStyle = `rgba(255, 255, 255, ${lineOpacity * 0.2})`;
        ctx.lineWidth = 1;
        ctx.setLineDash([5, 3]);
        ctx.stroke();
        ctx.setLineDash([]);
      }
      
      // Draw savings callout
      if (progress > 0.6) {
        const savingsOpacity = (progress - 0.6) / 0.4;
        
        // Responsive arrow positioning
        const arrowOffsetX = Math.min(90, traditional.radius + 10) * 1.15;
        
        // Draw arrow
        ctx.beginPath();
        ctx.moveTo(traditional.x + arrowOffsetX, traditional.y);
        ctx.lineTo(allegory.x - arrowOffsetX, traditional.y);
        ctx.strokeStyle = `rgba(56, 199, 147, ${savingsOpacity})`;
        ctx.lineWidth = 2;
        ctx.stroke();
        
        // Draw arrow head - responsive size
        const arrowHeadSize = Math.min(10, traditional.radius / 8);
        ctx.beginPath();
        ctx.moveTo(allegory.x - arrowOffsetX, traditional.y);
        ctx.lineTo(allegory.x - arrowOffsetX - arrowHeadSize, traditional.y - arrowHeadSize);
        ctx.lineTo(allegory.x - arrowOffsetX - arrowHeadSize, traditional.y + arrowHeadSize);
        ctx.closePath();
        ctx.fillStyle = `rgba(56, 199, 147, ${savingsOpacity})`;
        ctx.fill();
      }
    };
    
    // Animation loop with better performance
    const animate = (timestamp) => {
      if (!isVisibleRef.current) {
        // If not visible, just draw the final state
        drawFinalState();
        hasAnimatedRef.current = true;
        setIsAnimated(true);
        return;
      }
      
      if (!startTime) startTime = timestamp;
      const elapsed = timestamp - startTime;
      const progress = Math.min(elapsed / animationDuration, 1);
      
      // Only redraw if the element is visible in viewport
      const rect = canvas.getBoundingClientRect();
      if (
        rect.bottom >= 0 &&
        rect.top <= window.innerHeight
      ) {
        drawBaseCircles();
        drawAnimatedParts(progress);
      }
      
      if (progress < 1) {
        animationFrameRef.current = requestAnimationFrame(animate);
      } else {
        setIsAnimated(true);
        hasAnimatedRef.current = true; // Mark as animated
      }
    };
    
    // Start animation only if not already animated
    if (!hasAnimatedRef.current) {
      drawBaseCircles(); // Draw base state immediately
      animationFrameRef.current = requestAnimationFrame(animate);
    }
    
    // Handle resize for responsive canvas
    const handleResize = () => {
      updateCanvasDimensions();
      
      // If animation is complete, redraw the final state
      if (isAnimated) {
        drawFinalState();
      }
    };
    
    window.addEventListener('resize', handleResize);
    
    // Handle visibility changes
    const handleVisibilityChange = () => {
      if (document.hidden) {
        isVisibleRef.current = false;
        cancelAnimationFrame(animationFrameRef.current);
      } else {
        isVisibleRef.current = true;
        if (!hasAnimatedRef.current) {
          animationFrameRef.current = requestAnimationFrame(animate);
        } else {
          drawFinalState();
        }
      }
    };
    
    document.addEventListener('visibilitychange', handleVisibilityChange);
    
    // Handle scroll events to ensure animation stays visible
    const handleScroll = () => {
      const rect = canvas.getBoundingClientRect();
      const isInViewport = rect.bottom >= 0 && rect.top <= window.innerHeight;
      
      if (isInViewport && !isVisibleRef.current) {
        isVisibleRef.current = true;
        if (!hasAnimatedRef.current) {
          animationFrameRef.current = requestAnimationFrame(animate);
        } else {
          drawFinalState();
        }
      } else if (!isInViewport && isVisibleRef.current) {
        isVisibleRef.current = false;
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
    
    window.addEventListener('scroll', handleScroll, { passive: true });
    
    // Cleanup
    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [t, isAnimated, drawFinalState]);

  return (
    <div className={styles.heroContainer}>
      <div className={styles.backgroundShapes} aria-hidden="true">
        <div className={styles.shape1}></div>
        <div className={styles.shape2}></div>
      </div>
      <div className={styles.heroContent}>
        <h1 className={styles.title}>
          {t('hero.title.line1')} <br />
          {t('hero.title.line2_part1')}<span className={styles.highlight}>{t('hero.title.line2_part2')}</span>
        </h1>
        <p className={styles.subtitle}>
          {t('hero.subtitle')}
        </p>
        <button 
          className={`${styles.ctaButton}`}
          aria-label={t('hero.cta_button')}
          onClick={() => onStartQuote()}
        >
          {t('hero.cta_button')} <span className={styles.arrow} aria-hidden="true">→</span>
        </button>
      </div>
      <div className={styles.visualSection}>
        <canvas 
          ref={canvasRef} 
          className={styles.comparisonCanvas}
          aria-hidden="true"
        />
        <div className={styles.savingsBadge} aria-hidden="true">
          <div className={styles.badgeContent}>
            <span className={styles.savingsText}>{t('hero.saving_percentage')}</span>
            <span className={styles.savingsLabel}>{t('hero.saving_all_caps')}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;