// File: frontend/src/components/DrivingScore/UnderwritingMatrix.js

import React, { useState } from 'react';
import Card from '../Card/Card';
import driving_score_uw_matrix_data from '../../data/driving_score_uw_matrix_data.json';
import styles from './UnderwritingMatrix.module.css';
import AxisSelector from './AxisSelector';
import { useLanguage } from '../../contexts/LanguageContext';
  

const axisOptions = [
    'acceleration',
    'braking',
    'speeding',
    'cornering',
    'fatigueDriving',
    'distractedDriving',
    'contextual',
    'overall'
  ];

const UnderwritingMatrix = () => {
  const { t } = useLanguage();

  const [xAxis, setXAxis] = useState('braking');
  const [yAxis, setYAxis] = useState('distractedDriving');

  const handleXAxisChange = (value) => {
    setXAxis(value);
  };

  const handleYAxisChange = (value) => {
    setYAxis(value);
  };

  const handleRefresh = () => {
    // Implement refresh logic here
    console.log('Refreshing with x-axis:', xAxis, 'and y-axis:', yAxis);
  };
  
  const grid = Array(10).fill(null).map(() => Array(10).fill(null));
  const [hoverInfo, setHoverInfo] = useState(null);

  driving_score_uw_matrix_data.forEach(item => {
    const { x_values, y_values, colourHexCode, percentage } = item.value_attributes;
    if (x_values > 0 && y_values > 0) {
      grid[10 - y_values][x_values - 1] = { color: colourHexCode, x_values, y_values, percentage };
    }
  });

  const handleMouseEnter = (cell) => {
    if (cell) {
      setHoverInfo(cell);
    }
  };

  const handleMouseLeave = () => {
    setHoverInfo(null);
  };

  return (
    <Card
      title={t('underwriting_matrix')}
      subtitle={t('underwriting_matrix_description')}
    >
      <div className={styles.controls}>
        <div className={styles.dropdownFormParent}>
          <AxisSelector
            label={t('select_x_axis')}
            icon="/icons/18/misc/scale-horizontal.svg"
            value={t(`${xAxis}_score_decile`)}
            onChange={handleXAxisChange}
            options={axisOptions.map(option => ({
              value: option,
              label: t(`${option}_score_decile`)
            }))}
          />
          <AxisSelector
            label={t('select_y_axis')}
            icon="/icons/18/misc/scale-vertical.svg"
            value={t(`${yAxis}_score_decile`)}
            onChange={handleYAxisChange}
            options={axisOptions.map(option => ({
              value: option,
              label: t(`${option}_score_decile`)
            }))}
          />
        </div>
        <div className={styles.buttonParent}>
          <button className={styles.button} onClick={handleRefresh}>
            <img className={styles.icons18Refresh} alt="" src="/icons/18/misc/refresh.svg" />
            <div className={styles.submit}>{t('refresh')}</div>
          </button>
        </div>
      </div>
      <div className={styles.tableContainer}>
        <table>
          <thead>
            <tr>
              <th></th>
              {Array.from({ length: 10 }, (_, i) => <th key={i}>{i + 1}</th>)}
            </tr>
          </thead>
          <tbody>
            {grid.map((row, rowIndex) => (
              <tr key={rowIndex}>
                <th>{10 - rowIndex}</th>
                {row.map((cell, colIndex) => (
                  <td
                    key={colIndex}
                    style={{
                      backgroundColor: cell ? cell.color : '#fff',
                    }}
                    onMouseEnter={() => handleMouseEnter(cell)}
                    onMouseLeave={handleMouseLeave}
                  />
                ))}
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <th></th>
              {Array.from({ length: 10 }, (_, i) => <th key={i}>{i + 1}</th>)}
            </tr>
          </tfoot>
        </table>
        {hoverInfo && (
          <div className={styles.tooltip}>
            <p>X Axis Decile Value: {hoverInfo.x_values}</p>
            <p>Y Axis Decile Value: {hoverInfo.y_values}</p>
            <p>Percentage of Trips: {hoverInfo.percentage}%</p>
            <a href="#" style={{ pointerEvents: 'none', opacity: 0.5 }}>Create a Campaign</a>
          </div>
        )}
      </div>
      <div className={styles.insightContainer}>
        <div className={styles.insightIcon}>
          <img src="/icons/18/alerts/lamp.svg" alt="Insight" />
          <span>{t('insight')}</span>
        </div>
        <p className={styles.insightText}>{t('underwriting_matrix_insight')}</p>
      </div>
      <div className={styles.downloadSection}>
        <h4>Download the suggested actions for underwriting and pricing</h4>
        {/* Add download buttons for each risk level */}
      </div>
    </Card>
  );
};

export default UnderwritingMatrix;