// File: frontend/src/components/Dashboard/GlobalStatsCard.js

import React, { useState } from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import MapComponent from './MapComponent';
import StatCard from './StatCard';
import mapData from '../../data/mapbox_data_for_dashboard.json';
import styles from './GlobalStatsCard.module.css';

const GlobalStatsCard = () => {
  const { t } = useLanguage();
  const [activeCategory, setActiveCategory] = useState('allegory_intelligence');
  const [styleLoaded, setStyleLoaded] = useState(false);

  const handleStyleLoad = (loaded) => {
    setStyleLoaded(loaded);
  };

  const stats = [
    {
      icon: '/icons/18/misc/distracted-driving.svg',
      value: '400+',
      label: t('distracted_driving_data'),
      color: 'cardGreen'
    },
    {
      icon: '/icons/18/misc/crash.svg',
      value: '0',
      label: t('accidents_reported_ai'),
      color: 'cardRed'
    },
    {
      icon: '/icons/18/misc/kilometers.svg',
      value: '3M+',
      label: t('driving_data_collected'),
      color: 'cardBlue'
    },
    {
      icon: '/icons/18/misc/hours.svg',
      value: '8,330',
      label: t('hours_driving_data_analyzed'),
      color: 'cardOrange'
    }
  ];

  return (
    <div className={styles.card}>
      <div className={styles.header}>
        <h2 className={styles.title}>{t('data_strength_title')}</h2>
        <div className={styles.categoryButtons}>
          {Object.entries(mapData.categories).map(([key, category]) => (
            <button
              key={key}
              className={`${styles.categoryButton} ${activeCategory === key ? styles.active : ''}`}
              onClick={() => setActiveCategory(key)}
            >
              {category.name}
            </button>
          ))}
        </div>
      </div>

      <div className={styles.mapContainer}>
        {!styleLoaded && (
          <div className={styles.mapLoading}>
            <div className={styles.loadingSpinner}></div>
            Loading map...
          </div>
        )}
        <MapComponent 
          data={mapData.categories[activeCategory]}
          category={activeCategory}
          onStyleLoad={handleStyleLoad}
        />
      </div>

      <div className={styles.statsGrid}>
        {stats.map((stat, index) => (
          <StatCard key={index} {...stat} />
        ))}
      </div>
    </div>
  );
};

export default GlobalStatsCard;