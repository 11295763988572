// File: frontend/src/pages/Status/components/MetricsDisplay.js

import React from 'react';
import styles from './MetricsDisplay.module.css';
import 'primeicons/primeicons.css';  // Add this import

const MetricsDisplay = ({ metrics, isLoading }) => {
  if (isLoading || !metrics) {
    return <div className={styles.loading}>Loading metrics...</div>;
  }

  const formatPercentage = (value) => {
    if (value === undefined || value === null) return 'N/A';
    return `${Math.round(value * 100) / 100}%`;
  };

  const formatNumber = (num) => {
    if (num === undefined || num === null) return 'N/A';
    return Math.round(num).toLocaleString();
  };

  const formatLatency = (ms) => {
    if (ms === undefined || ms === null) return 'N/A';
    return `${Math.round(ms)}ms`;
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>System Metrics</h2>
      <div className={styles.metricsGrid}>
        {/* Uptime Metric */}
        <div className={styles.metricCard}>
          <div className={styles.metricIcon}>
            <i className="pi pi-clock"></i>
          </div>
          <div className={styles.metricContent}>
            <h3>System Uptime</h3>
            <div className={styles.value}>{formatPercentage(metrics.uptime)}</div>
            <div className={styles.label}>Last 24 hours</div>
          </div>
        </div>

        {/* Response Time Metric */}
        <div className={styles.metricCard}>
          <div className={styles.metricIcon}>
            <i className="pi pi-bolt"></i>
          </div>
          <div className={styles.metricContent}>
            <h3>Average Latency</h3>
            <div className={styles.value}>
              {formatLatency(metrics.averageLatency)}
            </div>
            <div className={styles.label}>Response Time</div>
          </div>
        </div>

        {/* Service Health Metric */}
        <div className={styles.metricCard}>
          <div className={styles.metricIcon}>
            <i className="pi pi-check-circle"></i>
          </div>
          <div className={styles.metricContent}>
            <h3>Service Health</h3>
            <div className={styles.serviceHealth}>
              <div className={styles.healthItem}>
                <span className={styles.healthDot + ' ' + styles.healthy}></span>
                <span>{metrics.serviceHealth?.ok || 0} Operational</span>
              </div>
              <div className={styles.healthItem}>
                <span className={styles.healthDot + ' ' + styles.degraded}></span>
                <span>{metrics.serviceHealth?.degraded || 0} Degraded</span>
              </div>
              <div className={styles.healthItem}>
                <span className={styles.healthDot + ' ' + styles.error}></span>
                <span>{metrics.serviceHealth?.error || 0} Error</span>
              </div>
            </div>
          </div>
        </div>

        {/* AWS Summary Metric */}
        <div className={styles.metricCard}>
          <div className={styles.metricIcon}>
            <i className="pi pi-cloud"></i>
          </div>
          <div className={styles.metricContent}>
            <h3>AWS Services</h3>
            <div className={styles.awsSummary}>
              <div className={styles.awsItem}>
                <span>Functions:</span>
                <span>{metrics.aws?.lambda?.summary?.totalFunctions || 0}</span>
              </div>
              <div className={styles.awsItem}>
                <span>API Requests:</span>
                <span>
                  {formatNumber(metrics.aws?.apiGateway?.metrics?.totalRequests || 0)}
                </span>
              </div>
              <div className={styles.awsItem}>
                <span>WAF Blocks:</span>
                <span>
                  {formatPercentage(metrics.aws?.waf?.metrics?.blockRate || 0)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MetricsDisplay;