// File: frontend/src/pages/UMS/UserPortfolio/components/PortfolioMaps/GeographyCoordinates.js

import React, { useEffect, useState } from 'react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import MapboxWrapper from '../../../../../components/Map/MapboxWrapper';
import styles from './PortfolioMaps.module.css';

const GeographyCoordinates = ({ width, height }) => {
  const { t } = useLanguage();
  const [pointsData, setPointsData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    fetch('https://allegory-dashboard-maps-geojson-data.s3.ca-central-1.amazonaws.com/ofa9a9/driving-score/all_time/b968ca52-cebf-430e-a028-a74f871f3c18.json')
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        setPointsData(data.features);
        setLoading(false);
      })
      .catch(error => {
        console.error("Error loading the data:", error);
        setError(error.message);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <div className={styles.spinner}></div>
        <p>{t('loading_map_data')}</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.errorContainer}>
        <i className="fas fa-exclamation-triangle"></i>
        <p>{t('error_loading_map')}: {error}</p>
      </div>
    );
  }

  if (!pointsData) {
    return (
      <div className={styles.noDataContainer}>
        <p>{t('no_map_data_available')}</p>
      </div>
    );
  }

  const data = [{
    type: "scattermapbox",
    lat: pointsData.map(point => point.geometry.coordinates[1]),
    lon: pointsData.map(point => point.geometry.coordinates[0]),
    mode: 'markers',
    marker: {
      size: 9,
      color: pointsData.map(point => point.properties.percentage),
      colorscale: [
        [0.0, '#FBB1BD'], [0.1, '#FF99AC'], [0.2, '#FF99AC'],
        [0.3, '#FF85A1'], [0.4, '#FF85A1'], [0.5, '#FF7096'],
        [0.6, '#FF7096'], [0.7, '#FF477E'], [0.8, '#FF477E'],
        [0.9, '#FF0A54'], [1.0, '#FF0A54']
      ],
      cmin: 0,
      cmax: 100,
      colorbar: {
        title: t('percentage'),
        thickness: 20
      }
    },
    text: pointsData.map(point => `dc: ${point.properties.dc}, %: ${point.properties.percentage.toFixed(2)}`),
    hoverinfo: 'text'
  }];

  const layout = {
    mapbox: {
      center: { 
        lat: 43.9423260884896,
        lon: -79.39566431444186
      },
      zoom: 5,
      style: "light"
    },
    width: width || 800,
    height: height || 500,
    margin: { t: 0, b: 0, l: 0, r: 0 },
    paper_bgcolor: 'rgba(0,0,0,0)',
    plot_bgcolor: 'rgba(0,0,0,0)'
  };

  return (
    <MapboxWrapper
      data={data}
      layout={layout}
      style={{ width: "100%", height: "100%" }}
    />
  );
};

export default GeographyCoordinates;