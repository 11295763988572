// File: frontend/src/pages/Status/components/UptimeChart.js

import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine
} from 'recharts';
import styles from './UptimeChart.module.css';

const UptimeChart = ({ history, isLoading }) => {
  if (isLoading) {
    return <div className={styles.loading}>Loading uptime data...</div>;
  }

  if (!history?.length) {
    return <div className={styles.empty}>No uptime data available</div>;
  }

  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString([], { 
      hour: '2-digit', 
      minute: '2-digit' 
    });
  };

  const formatTooltipTime = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString([], {
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    });
  };

  const getStatusColor = (status) => {
    switch (status?.toUpperCase()) {
      case 'OK':
        return 'var(--color-success)';
      case 'DEGRADED':
        return 'var(--color-warning)';
      case 'ERROR':
        return 'var(--color-error)';
      default:
        return 'var(--text-tertiary)';
    }
  };

  const data = history
    .slice()
    .reverse()
    .map(check => ({
      timestamp: check.timestamp,
      status: check.status,
      executionTime: check.executionTime,
      metrics: check.metrics,
      color: getStatusColor(check.status)
    }));

  const CustomTooltip = ({ active, payload, label }) => {
      if (!active || !payload?.[0]) return null;
  
      const data = payload[0].payload;
      return (
          <div className={styles.tooltip}>
              <div className={styles.tooltipTime}>
                  {formatTooltipTime(data.timestamp)}
              </div>
              <div className={styles.tooltipContent}>
                  <div className={styles.tooltipRow}>
                      <span>Status:</span>
                      <span className={styles[data.status.toLowerCase()]}>
                          {data.status}
                      </span>
                  </div>
                  <div className={styles.tooltipRow}>
                      <span>Execution Time:</span>
                      <span>{data.executionTime}ms</span>
                  </div>
                  {data.metrics && (
                      <>
                          <div className={styles.tooltipRow}>
                              <span>Error Rate:</span>
                              <span>{(data.metrics.averageErrorRate || 0).toFixed(2)}%</span>
                          </div>
                          <div className={styles.tooltipRow}>
                              <span>Latency:</span>
                              <span>{(data.metrics.averageLatency || 0).toFixed(2)}ms</span>
                          </div>
                      </>
                  )}
                  {data.awsMetrics?.lambda && (
                      <>
                          <div className={styles.tooltipDivider} />
                          <div className={styles.tooltipRow}>
                              <span>Lambda Functions:</span>
                              <span>{data.awsMetrics.lambda.summary.totalFunctions}</span>
                          </div>
                          <div className={styles.tooltipRow}>
                              <span>Lambda Errors:</span>
                              <span>{data.awsMetrics.lambda.summary.errorCount}</span>
                          </div>
                      </>
                  )}
              </div>
          </div>
      );
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>System Performance</h2>
      <div className={styles.chart}>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" stroke="var(--color-border)" />
            <XAxis 
              dataKey="timestamp" 
              tickFormatter={formatTime}
              stroke="var(--text-secondary)"
            />
            <YAxis 
              stroke="var(--text-secondary)"
              domain={[0, dataMax => Math.max(dataMax, 2500)]}
              tickFormatter={value => `${value}ms`}
            />
            <Tooltip content={<CustomTooltip />} />
            <ReferenceLine 
              y={1500} 
              label="Warning" 
              stroke="var(--color-warning)" 
              strokeDasharray="3 3" 
            />
            <ReferenceLine 
              y={2500} 
              label="Critical" 
              stroke="var(--color-error)" 
              strokeDasharray="3 3" 
            />
            <Line
              type="monotone"
              dataKey="executionTime"
              stroke="var(--color-primary)"
              strokeWidth={2}
              dot={{ 
                fill: ({ color }) => color,
                stroke: ({ color }) => color,
                strokeWidth: 2,
                r: 4
              }}
              activeDot={{ 
                r: 6,
                strokeWidth: 2
              }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div className={styles.legend}>
        <div className={styles.legendItem}>
          <span className={`${styles.legendDot} ${styles.ok}`}></span>
          <span>Operational</span>
        </div>
        <div className={styles.legendItem}>
          <span className={`${styles.legendDot} ${styles.degraded}`}></span>
          <span>Degraded</span>
        </div>
        <div className={styles.legendItem}>
          <span className={`${styles.legendDot} ${styles.error}`}></span>
          <span>Error</span>
        </div>
      </div>
    </div>
  );
};

export default UptimeChart;