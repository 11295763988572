// File: frontend/src/components/Auth/EmailVerification.js

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useLanguage } from '../../contexts/LanguageContext';
import styles from './EmailVerification.module.css';

const EmailVerification = ({ email, onResendEmail, onVerify, loading, error, onErrorClose  }) => {
  const { t } = useLanguage();
  const [isResending, setIsResending] = useState(false);

  const emailClients = [
    { 
      name: 'Gmail', 
      icon: '/icons/18/socials/google/gmail-logo.svg', 
      url: 'https://mail.google.com/' 
    },
    { 
      name: 'Outlook', 
      icon: '/icons/18/socials/microsoft/outlook-logo.svg', 
      url: 'https://outlook.live.com/' 
    },
    { 
      name: 'Yahoo', 
      icon: '/icons/18/socials/yahoo/yahoo-logo.png', 
      url: 'https://mail.yahoo.com/' 
    },
    { 
      name: 'Apple Mail', 
      icon: '/icons/18/socials/apple/icloud-logo.svg', 
      url: 'https://www.icloud.com/mail' 
    }
  ];

  const handleResendEmail = async () => {
    setIsResending(true);
    await onResendEmail();
    setIsResending(false);
  };

  return (
    <div className={classNames(styles.emailVerification, {
      [styles.loading]: loading || isResending
    })}>
      <div className={styles.content}>
        <img 
          src="/icons/vectors/check-your-email.svg" 
          alt={t('auth.emailVerification.iconAlt')}
          className={styles.emailIcon}
        />
        
        <div className={styles.textContent}>
          <h2 className={styles.title}>
            {t('auth.emailVerification.title')}
          </h2>
          <p className={styles.description}>
            {t('auth.emailVerification.description')}
          </p>
        </div>

        <div className={styles.emailSentInfo}>
          <span>{t('email_sent_to')}</span>
          <span className={styles.emailAddress}>
            {email.toLowerCase()}
          </span>
        </div>

        <div className={styles.emailClientList}>
          {emailClients.map((client) => (
            <a 
              key={client.name}
              href={client.url}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.emailClientButton}
            >
              <img 
                src={client.icon} 
                alt={client.name} 
                className={styles.emailClientIcon} 
              />
              <span>{t('auth.emailVerification.openClient', { 
                client: client.name 
              })}</span>
            </a>
          ))}
        </div>

        <div className={styles.resendSection}>
          <p className={styles.resendText}>
            {t('auth.emailVerification.noEmailReceived')}
          </p>
          <button 
            onClick={handleResendEmail} 
            className={styles.resendButton}
            disabled={loading || isResending}
          >
            {isResending 
              ? t('auth.emailVerification.resending')
              : t('auth.emailVerification.resendEmail')
            }
          </button>
        </div>
      </div>
    </div>
  );
};

EmailVerification.propTypes = {
  email: PropTypes.string.isRequired,
  onResendEmail: PropTypes.func.isRequired,
  onVerify: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.object,
  onErrorClose: PropTypes.func
};

export default React.memo(EmailVerification);