// File: frontend/src/pages/AI1/ChatHistory/ChatHistory.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiService } from '../../../../services/api';
import { API_ENDPOINTS } from '../../../../config/constants';
import Pagination from '../../../../components/Pagination/Pagination';
import styles from './ChatHistory.module.css';

const ITEMS_PER_PAGE = 10;

const ChatHistory = () => {
  const [chats, setChats] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    fetchChatHistory();
  }, []);

  const fetchChatHistory = async () => {
    try {
      setIsLoading(true);
      const response = await apiService.get(API_ENDPOINTS.AGENTS.CHAT.HISTORY);
      
      // Sort chats by created_at in descending order (newest first)
      const sortedChats = [...(response || [])].sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      });
      
      setChats(sortedChats);
    } catch (error) {
      console.error('Error fetching chat history:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const yesterday = new Date(now);
    yesterday.setDate(yesterday.getDate() - 1);
    
    if (date.toDateString() === now.toDateString()) {
      return new Intl.DateTimeFormat('en-US', {
        hour: '2-digit',
        minute: '2-digit'
      }).format(date);
    }
    
    if (date.toDateString() === yesterday.toDateString()) {
      return `Yesterday, ${new Intl.DateTimeFormat('en-US', {
        hour: '2-digit',
        minute: '2-digit'
      }).format(date)}`;
    }
    
    return new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: 'numeric',
      year: new Date().getFullYear() !== date.getFullYear() ? 'numeric' : undefined,
      hour: '2-digit',
      minute: '2-digit'
    }).format(date);
  };

  const getPreviewText = (chat) => {
    if (!chat.messages || chat.messages.length === 0) return 'No messages';
    
    const firstUserMessage = chat.messages.find(msg => msg.role === 'user');
    if (!firstUserMessage) return 'No preview available';
    
    const content = firstUserMessage.content;
    let previewText = '';
    
    if (Array.isArray(content)) {
      previewText = content[0]?.text || 'No preview available';
    } else {
      previewText = content || 'No preview available';
    }
    
    return previewText.length > 100 
      ? `${previewText.substring(0, 100)}...` 
      : previewText;
  };

  const formatAgentName = (agentName) => {
    return agentName
      .replace('ai_', '')
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  // Pagination calculations
  const totalPages = Math.ceil(chats.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentChats = chats.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    // Scroll to top of the chat list when page changes
    window.scrollTo(0, 0);
  };

  if (isLoading) {
    return (
      <div className={styles.loadingContainer}>
        <div className={styles.loader} />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>Chat History</h1>
        <button 
          className={styles.newChatButton}
          onClick={() => navigate('/ai1')}
        >
          New Chat
        </button>
      </div>

      <div className={styles.chatList}>
        {chats.length === 0 ? (
          <div className={styles.emptyState}>
            <p>No chat history found</p>
            <button 
              className={styles.startChatButton}
              onClick={() => navigate('/ai1')}
            >
              Start a new chat
            </button>
          </div>
        ) : (
          <>
            {currentChats.map((chat) => (
              <div 
                key={chat.chat_id}
                className={styles.chatItem}
                onClick={() => navigate(`/ai1/chat/${chat.chat_id}`)}
              >
                <div className={styles.chatItemHeader}>
                  <span className={styles.timestamp}>
                    {formatDate(chat.created_at)}
                  </span>
                  <span className={styles.agent}>
                    {formatAgentName(chat.agent_name)}
                  </span>
                </div>
                <p className={styles.preview}>{getPreviewText(chat)}</p>
              </div>
            ))}
            
            {totalPages > 1 && (
              <div className={styles.paginationWrapper}>
                <Pagination
                  current={currentPage}
                  total={totalPages}
                  onChange={handlePageChange}
                  size="medium"
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ChatHistory;