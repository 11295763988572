// File: frontend/src/pages/CMS/CMS.js

import React from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import styles from './CMS.module.css';

const CMS = () => {
  const { t } = useLanguage();

  return <div><h1 className={styles.title}>{t('cms')}</h1></div>;
};

export default CMS;


