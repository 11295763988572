// File: frontend/src/contexts/SignInContext.js

import React, { createContext, useState, useContext, useCallback, useEffect } from 'react';
import { useAuth } from './AuthContextV2';
import { AUTH_FLOW_STATES } from './AuthContextV2';

const SignInContext = createContext();

export const SignInProvider = ({ children }) => {
  const { flowState } = useAuth();
  
  const [formData, setFormData] = useState({
    email: '',
    phone: {
      country_phone_code: '+1',
      area_code: '',
      phone_number: '',
    },
    rememberMe: false,
  });
  
  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false);

  // Reset form when auth state changes
  useEffect(() => {
    if (flowState !== AUTH_FLOW_STATES.SIGNIN.INITIAL) {
      setFormData({
        email: '',
        phone: {
          country_phone_code: '+1',
          area_code: '',
          phone_number: '',
        },
        rememberMe: false,
      });
      setErrors({});
    }
  }, [flowState]);

  const updateFormData = useCallback((newData) => {
    setFormData(prevData => ({ ...prevData, ...newData }));
  }, []);

  // Validate form data
  const validateForm = useCallback(() => {
    const newErrors = {};
    
    if (flowState === AUTH_FLOW_STATES.SIGNIN.INITIAL) {
      // Email validation
      if (formData.email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.email)) {
          newErrors.email = 'Invalid email format';
        }
      }
      
      // Phone validation
      const phone = formData.phone;
      if (phone.area_code || phone.phone_number) {
        if (!phone.area_code || !phone.phone_number) {
          newErrors.phone = 'Complete phone number required';
        }
      }
      
      // At least one field must be filled
      if (!formData.email && !phone.area_code && !phone.phone_number) {
        newErrors.general = 'Either email or phone number is required';
      }
    }

    setErrors(newErrors);
    setIsValid(Object.keys(newErrors).length === 0);
    return Object.keys(newErrors).length === 0;
  }, [formData, flowState]);

  // Validate on form data change
  useEffect(() => {
    validateForm();
  }, [formData, validateForm]);

  const getFormattedData = useCallback(() => {
    if (formData.email) {
      return formData.email;
    }
    
    if (formData.phone.area_code && formData.phone.phone_number) {
      return {
        identifier: `${formData.phone.country_phone_code}${formData.phone.area_code}${formData.phone.phone_number}`.replace(/\s+/g, '')
      };
    }
    
    return null;
  }, [formData]);

  const clearForm = useCallback(() => {
    setFormData({
      email: '',
      phone: {
        country_phone_code: '+1',
        area_code: '',
        phone_number: '',
      },
      rememberMe: false,
    });
    setErrors({});
  }, []);

  return (
    <SignInContext.Provider value={{
      formData,
      errors,
      isValid,
      updateFormData,
      validateForm,
      getFormattedData,
      clearForm
    }}>
      {children}
    </SignInContext.Provider>
  );
};

export const useSignIn = () => {
  const context = useContext(SignInContext);
  if (context === undefined) {
    throw new Error('useSignIn must be used within a SignInProvider');
  }
  return context;
};