// File: frontend/src/pages/Auth/SignOutInactivity.js

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../contexts/LanguageContext';
import { useAuth } from '../../contexts/AuthContextV2';
import AuthLayout from '../../components/layout/AuthLayout/AuthLayout';
import StatusScreen from '../../components/Auth/StatusScreen';
import styles from './SignOutInactivity.module.css';

const SignOutInactivity = () => {
  const { t } = useLanguage();
  const navigate = useNavigate();
  const { signOut } = useAuth();
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    const handleInactivitySignOut = async () => {
      try {
        await signOut();
        
        const countdownInterval = setInterval(() => {
          setCountdown(prev => Math.max(0, prev - 1));
        }, 1000);

        setTimeout(() => {
          navigate('/sign-in', { 
            state: { inactivityTimeout: true },
            replace: true
          });
        }, 5000);

        return () => clearInterval(countdownInterval);
      } catch (error) {
        console.error('Inactivity sign out error:', error);
        navigate('/sign-in', { replace: true });
      }
    };

    handleInactivitySignOut();
  }, [signOut, navigate]);

  const additionalInfo = (
    <div className={styles.inactivityInfo}>
      <div className={styles.lastActiveTime}>
        <span className={styles.infoLabel}>
          {t('auth.signOutInactivity.lastActiveLabel')}
        </span>
        <span className={styles.infoValue}>
          {new Date().toLocaleTimeString()}
        </span>
      </div>
      
      <div className={styles.countdownWrapper}>
        <div className={styles.countdown}>
          <div 
            className={styles.countdownBar} 
            style={{ width: `${(countdown / 5) * 100}%` }} 
          />
        </div>
        <p className={styles.countdownText}>
          {t('auth.signOutInactivity.redirecting', { seconds: countdown })}
        </p>
      </div>
    </div>
  );

  return (
    <AuthLayout
      title={t('auth.signOutInactivity.title')}
      showHomeButton={true}
    >
      <div className={styles.inactivityContainer}>
        <div className={styles.inactivityContent}>
          <StatusScreen
            icon="/icons/18/alerts/warning.svg"
            title={t('auth.signOutInactivity.title')}
            message={t('auth.signOutInactivity.message')}
            type="warning"
            additionalInfo={additionalInfo}
          />
        </div>
      </div>
    </AuthLayout>
  );
};

export default React.memo(SignOutInactivity);