// File: frontend/src/hooks/useNewsletter.js

import { useState } from 'react';
import { API_ENDPOINTS } from '../config/constants';
import apiService from '../services/api';

export const useNewsletter = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState(null);

  const mapErrorToCode = (error) => {
    switch (error.code) {
      case 'VALIDATION_ERROR':
        return 'invalid_email';
      case 'CONFLICT':
        return 'already_subscribed';
      case 'NETWORK_ERROR':
        return 'connection_error';
      default:
        return 'subscription_failed';
    }
  };

  const subscribe = async ({ emailAddress, firstName, lastName }) => {
    setLoading(true);
    setError(null);
    setSuccess(false);
    setSubscriptionData(null);

    try {
      const response = await apiService.post(API_ENDPOINTS.ENGAGEMENT.SUBSCRIBE, {
        emailAddress,
        firstName,
        lastName,
        tags: {
          source: 'website',
          platform: 'web',
          timestamp: new Date().toISOString()
        }
      });

      setSuccess(true);
      setSubscriptionData(response);
      return response;
    } catch (err) {
      const errorCode = mapErrorToCode(err);
      setError(errorCode);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const unsubscribe = async (emailAddress) => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const response = await apiService.post(API_ENDPOINTS.ENGAGEMENT.UNSUBSCRIBE, {
        emailAddress
      });

      setSuccess(true);
      return response;
    } catch (err) {
      const errorCode = mapErrorToCode(err);
      setError(errorCode);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return {
    subscribe,
    unsubscribe,
    loading,
    error,
    success,
    subscriptionData,
    clearError: () => setError(null),
    clearSuccess: () => {
      setSuccess(false);
      setSubscriptionData(null);
    }
  };
};