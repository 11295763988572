// File: frontend/src/pages/PropertyDetail/components/PropertyValuation/index.js

import React from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import styles from './PropertyValuation.module.css';

// Helper function to format date
const formatDate = (dateString) => {
  if (!dateString) return 'N/A';
  
  const date = new Date(dateString);
  
  // Check if date is valid
  if (isNaN(date.getTime())) return 'Invalid Date';
  
  return date.toLocaleDateString();
};

// Helper function to format currency
const formatCurrency = (amount, currency = 'USD') => {
  if (amount === null || amount === undefined) return 'N/A';
  
  return new Intl.NumberFormat('en-US', { 
    style: 'currency', 
    currency: currency 
  }).format(amount);
};

const PropertyValuation = ({ property }) => {
  const { t } = useLanguage();

  return (
    <div className={styles.container}>
      <div className={styles.grid}>
        <div className={styles.card}>
          <h3 className={styles.cardTitle}>{t('replacement_cost')}</h3>
          <div className={styles.cardContent}>
            {property.replacementCost ? (
              <div className={styles.valuationCurrent}>
                <div className={styles.valuationValue}>
                  {formatCurrency(property.replacementCost.replacement_cost_amount, property.replacementCost.replacement_cost_amount_currency)}
                </div>
                
                <div className={styles.valuationDetails}>
                  <div className={styles.valuationDetail}>
                    <span className={styles.valuationDetailLabel}>{t('provider')}:</span>
                    <span className={styles.valuationDetailValue}>
                      {property.replacementCost.replacement_cost_provider}
                    </span>
                  </div>
                  
                  <div className={styles.valuationDetail}>
                    <span className={styles.valuationDetailLabel}>{t('valuation_date')}:</span>
                    <span className={styles.valuationDetailValue}>
                      {formatDate(property.replacementCost.replacement_cost_date)}
                    </span>
                  </div>
                  
                  <div className={styles.valuationDetail}>
                    <span className={styles.valuationDetailLabel}>{t('last_updated')}:</span>
                    <span className={styles.valuationDetailValue}>
                      {formatDate(property.replacementCost.updated_at)}
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.noData}>{t('no_replacement_cost_available')}</div>
            )}
          </div>
        </div>
        
        <div className={styles.card}>
          <h3 className={styles.cardTitle}>{t('mortgage_information')}</h3>
          <div className={styles.cardContent}>
            {property.mortgage ? (
              <div className={styles.mortgageInfo}>
                <div className={styles.mortgageAmount}>
                  {formatCurrency(property.mortgage.mortgage_amount, property.mortgage.mortgage_amount_currency)}
                </div>
                
                <div className={styles.mortgageDetails}>
                  <div className={styles.mortgageDetail}>
                    <span className={styles.mortgageDetailLabel}>{t('lender')}:</span>
                    <span className={styles.mortgageDetailValue}>
                      {property.mortgage.lender_name}
                    </span>
                  </div>
                  
                  <div className={styles.mortgageDetail}>
                    <span className={styles.mortgageDetailLabel}>{t('start_date')}:</span>
                    <span className={styles.mortgageDetailValue}>
                      {formatDate(property.mortgage.mortgage_start_date)}
                    </span>
                  </div>
                  
                  <div className={styles.mortgageDetail}>
                    <span className={styles.mortgageDetailLabel}>{t('term')}:</span>
                    <span className={styles.mortgageDetailValue}>
                      {property.mortgage.mortgage_term} {t('months')}
                    </span>
                  </div>
                  
                  <div className={styles.mortgageDetail}>
                    <span className={styles.mortgageDetailLabel}>{t('interest_rate')}:</span>
                    <span className={styles.mortgageDetailValue}>
                      {property.mortgage.interest_rate}%
                    </span>
                  </div>
                  
                  <div className={styles.mortgageDetail}>
                    <span className={styles.mortgageDetailLabel}>{t('mortgage_type')}:</span>
                    <span className={styles.mortgageDetailValue}>
                      {property.mortgage.mortgage_type}
                    </span>
                  </div>
                  
                  <div className={styles.mortgageDetail}>
                    <span className={styles.mortgageDetailLabel}>{t('primary_mortgage')}:</span>
                    <span className={styles.mortgageDetailValue}>
                      {property.mortgage.is_primary_mortgage ? t('yes') : t('no')}
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.noData}>{t('no_mortgage_information_available')}</div>
            )}
          </div>
        </div>
      </div>
      
      <div className={styles.card}>
        <h3 className={styles.cardTitle}>{t('market_valuation')}</h3>
        <div className={styles.cardContent}>
          <div className={styles.marketValuationGrid}>
            <div className={styles.valuationItem}>
              <div className={styles.valuationItemTitle}>{t('estimated_market_value')}</div>
              <div className={styles.valuationItemValue}>
                {formatCurrency(property.replacementCost ? property.replacementCost.replacement_cost_amount * 0.9 : null)}
              </div>
              <div className={styles.valuationItemNote}>
                {t('estimated_based_on_replacement_cost')}
              </div>
            </div>
            
            <div className={styles.valuationItem}>
              <div className={styles.valuationItemTitle}>{t('land_value')}</div>
              <div className={styles.valuationItemValue}>
                {formatCurrency(property.replacementCost ? property.replacementCost.replacement_cost_amount * 0.4 : null)}
              </div>
              <div className={styles.valuationItemNote}>
                {t('estimated_based_on_location')}
              </div>
            </div>
            
            <div className={styles.valuationItem}>
              <div className={styles.valuationItemTitle}>{t('building_value')}</div>
              <div className={styles.valuationItemValue}>
                {formatCurrency(property.replacementCost ? property.replacementCost.replacement_cost_amount * 0.5 : null)}
              </div>
              <div className={styles.valuationItemNote}>
                {t('estimated_based_on_building_characteristics')}
              </div>
            </div>
          </div>
          
          <div className={styles.valuationNote}>
            {t('valuation_disclaimer')}
          </div>
        </div>
      </div>
      
      <div className={styles.card}>
        <h3 className={styles.cardTitle}>{t('valuation_history')}</h3>
        <div className={styles.cardContent}>
          {property.replacementCost ? (
            <div className={styles.tableContainer}>
              <table className={styles.table}>
                <thead>
                  <tr>
                    <th>{t('date')}</th>
                    <th>{t('valuation_type')}</th>
                    <th>{t('value')}</th>
                    <th>{t('provider')}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{formatDate(property.replacementCost.replacement_cost_date)}</td>
                    <td>{t('replacement_cost')}</td>
                    <td>{formatCurrency(property.replacementCost.replacement_cost_amount, property.replacementCost.replacement_cost_amount_currency)}</td>
                    <td>{property.replacementCost.replacement_cost_provider}</td>
                  </tr>
                  {/* Mock historical data */}
                  <tr>
                    <td>{formatDate(new Date(new Date(property.replacementCost.replacement_cost_date).setFullYear(new Date(property.replacementCost.replacement_cost_date).getFullYear() - 1)))}</td>
                    <td>{t('replacement_cost')}</td>
                    <td>{formatCurrency(property.replacementCost.replacement_cost_amount * 0.95, property.replacementCost.replacement_cost_amount_currency)}</td>
                    <td>{property.replacementCost.replacement_cost_provider}</td>
                  </tr>
                  <tr>
                    <td>{formatDate(new Date(new Date(property.replacementCost.replacement_cost_date).setFullYear(new Date(property.replacementCost.replacement_cost_date).getFullYear() - 2)))}</td>
                    <td>{t('replacement_cost')}</td>
                    <td>{formatCurrency(property.replacementCost.replacement_cost_amount * 0.9, property.replacementCost.replacement_cost_amount_currency)}</td>
                    <td>{property.replacementCost.replacement_cost_provider}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : (
            <div className={styles.noData}>{t('no_valuation_history_available')}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PropertyValuation;