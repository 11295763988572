// File: frontend/src/pages/Auth/ProductPromotion.js

import React from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import Button from '../../components/Button/Button';
import styles from './ProductPromotion.module.css';

const ProductPromotion = () => {
  const { t } = useLanguage();

  return (
    <div className={styles.productPromotion}>
      <div className={styles.content}>
        <h2 className={styles.title}>
          {t('auth.signIn.promotionTitle')}
        </h2>
        
        <p className={styles.description}>
          {t('auth.signIn.promotionDescription')}
        </p>

        <Button
          variant="secondary"
          size="small"
          onClick={() => window.location.href = '/news'}
          className={styles.learnMoreButton}
        >
          {t('auth.signIn.learnMore')}
        </Button>
      </div>
    </div>
  );
};

export default React.memo(ProductPromotion);