// File: frontend/src/pages/EmbeddedInsurance/components/ApiExplorer/ApiEndpoint.js

import React, { useState } from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import ApiParameters from './ApiParameters';
import ApiResponses from './ApiResponses';
import CodeBlock from '../common/CodeBlock';
import styles from './styles/ApiEndpoint.module.css';

const ApiEndpoint = ({ endpoint, components }) => {
  const { t } = useLanguage();
  const [activeTab, setActiveTab] = useState('documentation');

  const getMethodColor = (method) => {
    const colors = {
      get: styles.methodGet,
      post: styles.methodPost,
      put: styles.methodPut,
      delete: styles.methodDelete,
      patch: styles.methodPatch,
    };
    return colors[method.toLowerCase()] || styles.methodDefault;
  };

  // Generate example request body if available
  const getRequestBodyExample = () => {
    if (!endpoint.requestBody) return null;
    
    const content = endpoint.requestBody.content;
    if (!content) return null;
    
    const contentType = Object.keys(content)[0];
    if (!contentType) return null;
    
    return content[contentType].example || null;
  };

  // Generate code samples
  const generateCodeSamples = () => {
    if (!endpoint) return {};
    
    const requestBody = getRequestBodyExample();
    const path = endpoint.path;
    const method = endpoint.method.toUpperCase();
    
    // Generate cURL sample
    const curlSample = `curl -X ${method} https://api.allegory.app/v1${path} \\
  -H "Content-Type: application/json" \\
  -H "Authorization: Bearer YOUR_API_KEY"${requestBody ? ` \\
  -d '${JSON.stringify(requestBody, null, 2)}'` : ''}`;
    
    // Generate JavaScript sample
    const jsSample = `const axios = require('axios');

${requestBody ? `const data = ${JSON.stringify(requestBody, null, 2)};

` : ''}axios.${method.toLowerCase()}('https://api.allegory.app/v1${path}'${requestBody ? ', data' : ''}, {
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer YOUR_API_KEY'
  }
})
.then(response => {
  console.log(response.data);
})
.catch(error => {
  console.error(error);
});`;
    
    // Generate Python sample
    const pythonSample = `import requests
import json

url = "https://api.allegory.app/v1${path}"
headers = {
    "Content-Type": "application/json",
    "Authorization": "Bearer YOUR_API_KEY"
}
${requestBody ? `data = ${JSON.stringify(requestBody, null, 2)}

response = requests.${method.toLowerCase()}(url, headers=headers, data=json.dumps(data))` : `response = requests.${method.toLowerCase()}(url, headers=headers)`}
print(response.json())`;
    
    return {
      curl: curlSample,
      javascript: jsSample,
      python: pythonSample,
      activeLanguage: 'curl'
    };
  };

  const codeSamples = generateCodeSamples();

  const renderDocumentation = () => (
    <div className={styles.documentation}>
      {endpoint.description && (
        <div className={styles.description}>
          {endpoint.description.split('\n').map((line, i) => (
            <p key={i}>{line}</p>
          ))}
        </div>
      )}

      {endpoint.parameters && endpoint.parameters.length > 0 && (
        <ApiParameters parameters={endpoint.parameters} />
      )}

      {endpoint.requestBody && (
        <div className={styles.section}>
          <h3 className={styles.sectionTitle}>{t('request_body') || 'Request Body'}</h3>
          <div className={styles.requestBody}>
            {getRequestBodyExample() && (
              <CodeBlock 
                code={JSON.stringify(getRequestBodyExample(), null, 2)} 
                language="json"
                title={t('request_example') || 'Request Example'}
              />
            )}
            {endpoint.requestBody.content && Object.keys(endpoint.requestBody.content).map(contentType => {
              const schema = endpoint.requestBody.content[contentType].schema;
              if (schema) {
                return (
                  <div key={contentType} className={styles.schema}>
                    <h4 className={styles.schemaTitle}>{t('schema') || 'Schema'}</h4>
                    <CodeBlock 
                      code={JSON.stringify(schema, null, 2)} 
                      language="json"
                    />
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>
      )}

      <ApiResponses responses={endpoint.responses} components={components} />
    </div>
  );

  const renderCodeSamples = () => (
    <div className={styles.codeSamples}>
      <div className={styles.codeSampleTabs}>
        {['curl', 'javascript', 'python'].map(lang => (
          <button
            key={lang}
            className={`${styles.codeSampleTab} ${codeSamples.activeLanguage === lang ? styles.activeCodeSampleTab : ''}`}
            onClick={() => {
              // Update active language
              codeSamples.activeLanguage = lang;
              // Force re-render
              setActiveTab('code-samples');
            }}
          >
            {lang}
          </button>
        ))}
      </div>
      
      <div className={styles.codeSampleContent}>
        <CodeBlock
          code={codeSamples[codeSamples.activeLanguage]}
          language={codeSamples.activeLanguage === 'curl' ? 'bash' : codeSamples.activeLanguage}
        />
      </div>
    </div>
  );

  const renderTryIt = () => (
    <div className={styles.tryIt}>
      <div className={styles.tryItHeader}>
        <h3 className={styles.tryItTitle}>{t('try_this_api') || 'Try This API'}</h3>
        <p className={styles.tryItDescription}>
          {t('try_api_description') || 'Test this endpoint with your own parameters and see the response.'}
        </p>
      </div>
      
      <div className={styles.tryItForm}>
        {endpoint.parameters && endpoint.parameters.length > 0 && (
          <div className={styles.tryItParameters}>
            <h4 className={styles.tryItSectionTitle}>{t('parameters') || 'Parameters'}</h4>
            {endpoint.parameters.map((param, index) => (
              <div key={index} className={styles.tryItParameter}>
                <label className={styles.tryItParameterLabel}>
                  {param.name}
                  {param.required && <span className={styles.required}>*</span>}
                </label>
                <input
                  type="text"
                  className={styles.tryItParameterInput}
                  placeholder={param.description}
                />
              </div>
            ))}
          </div>
        )}
        
        {endpoint.requestBody && (
          <div className={styles.tryItRequestBody}>
            <h4 className={styles.tryItSectionTitle}>{t('request_body') || 'Request Body'}</h4>
            <textarea
              className={styles.tryItRequestBodyInput}
              defaultValue={getRequestBodyExample() ? JSON.stringify(getRequestBodyExample(), null, 2) : ''}
              rows={10}
            />
          </div>
        )}
        
        <div className={styles.tryItActions}>
          <button className={styles.tryItButton}>
            <i className="fas fa-play"></i>
            <span>{t('send_request') || 'Send Request'}</span>
          </button>
        </div>
        
        <div className={styles.tryItResponse}>
          <h4 className={styles.tryItSectionTitle}>{t('response') || 'Response'}</h4>
          <div className={styles.tryItResponsePlaceholder}>
            <i className="fas fa-arrow-right"></i>
            <span>{t('response_will_appear_here') || 'Response will appear here'}</span>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.endpoint}>
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <div className={styles.methodAndPath}>
            <span className={`${styles.methodBadge} ${getMethodColor(endpoint.method)}`}>
              {endpoint.method.toUpperCase()}
            </span>
            <code className={styles.path}>{endpoint.path}</code>
          </div>
          <div className={styles.summary}>{endpoint.summary}</div>
        </div>
      </div>

      <div className={styles.tabs}>
        <button
          className={`${styles.tab} ${activeTab === 'documentation' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('documentation')}
        >
          <i className="fas fa-book"></i>
          <span>{t('documentation') || 'Documentation'}</span>
        </button>
        <button
          className={`${styles.tab} ${activeTab === 'code-samples' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('code-samples')}
        >
          <i className="fas fa-code"></i>
          <span>{t('code_samples') || 'Code Samples'}</span>
        </button>
        <button
          className={`${styles.tab} ${activeTab === 'try-it' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('try-it')}
        >
          <i className="fas fa-play"></i>
          <span>{t('try_it') || 'Try It'}</span>
        </button>
      </div>

      <div className={styles.content}>
        {activeTab === 'documentation' && renderDocumentation()}
        {activeTab === 'code-samples' && renderCodeSamples()}
        {activeTab === 'try-it' && renderTryIt()}
      </div>
    </div>
  );
};

export default ApiEndpoint;