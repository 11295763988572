// File: frontend/src/components/form/FormGroup/FormGroup.js

import React from 'react';
import PropTypes from 'prop-types';
import styles from './FormGroup.module.css';

const FormGroup = ({ children, title, description }) => {
  return (
    <fieldset className={styles.formGroup}>
      {title && <legend className={styles.title}>{title}</legend>}
      {description && <p className={styles.description}>{description}</p>}
      <div className={styles.fields}>{children}</div>
    </fieldset>
  );
};

FormGroup.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
};

export default FormGroup;