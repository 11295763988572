// File: frontend/src/pages/QMS/QuoteDetail/components/QuoteConversation/index.js

import React from 'react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from './QuoteConversation.module.css';

const QuoteConversation = ({ quote }) => {
  const { t } = useLanguage();

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    return new Date(dateString).toLocaleString();
  };

  // Check if conversation exists and has messages
  const hasConversation = quote.conversation && (
    (Array.isArray(quote.conversation) && quote.conversation.length > 0) ||
    (quote.conversation.messages && quote.conversation.messages.length > 0)
  );

  if (!hasConversation) {
    return (
      <div className={styles.noData}>
        <p>{t('no_conversation_information')}</p>
      </div>
    );
  }

  // Determine if conversation is an array or an object with messages
  const isConversationArray = Array.isArray(quote.conversation);
  const messages = isConversationArray ? quote.conversation : (quote.conversation.messages || []);
  const messageCount = isConversationArray ? messages.length : (quote.conversation.message_count || messages.length);

  return (
    <div className={styles.container}>
      <div className={styles.conversationInfo}>
        <div className={styles.card}>
          <div className={styles.cardHeader}>
            <h3 className={styles.cardTitle}>{t('conversation_details')}</h3>
          </div>
          <div className={styles.cardContent}>
            <div className={styles.infoGrid}>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('chat_id')}:</div>
                <div className={styles.infoValue}>{quote.chat_id || 'N/A'}</div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('message_count')}:</div>
                <div className={styles.infoValue}>{messageCount}</div>
              </div>
              
              {!isConversationArray && quote.conversation.agent_name && (
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('agent_name')}:</div>
                  <div className={styles.infoValue}>{quote.conversation.agent_name}</div>
                </div>
              )}
              
              {!isConversationArray && quote.conversation.completion_status && (
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('completion_status')}:</div>
                  <div className={styles.infoValue}>
                    <span className={styles[quote.conversation.completion_status]}>
                      {quote.conversation.completion_status}
                    </span>
                  </div>
                </div>
              )}
              
              {!isConversationArray && quote.conversation.completion_time && (
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('completion_time')}:</div>
                  <div className={styles.infoValue}>
                    {quote.conversation.completion_time} {t('seconds')}
                  </div>
                </div>
              )}
              
              {!isConversationArray && quote.conversation.return_url && (
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('return_url')}:</div>
                  <div className={styles.infoValue}>
                    <a href={quote.conversation.return_url} target="_blank" rel="noopener noreferrer">
                      {quote.conversation.return_url}
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      
      {messages.length > 0 ? (
        <div className={styles.messagesSection}>
          <h3 className={styles.sectionTitle}>{t('messages')}</h3>
          
          <div className={styles.messagesList}>
            {messages.map((message, index) => {
              // Extract text content from the message
              let textContent = '';
              if (Array.isArray(message.content)) {
                // Find the first text content
                const textItem = message.content.find(item => item.type === 'text');
                textContent = textItem?.text || '';
              } else {
                textContent = message.content || '';
              }
              
              return (
                <div 
                  key={index} 
                  className={`${styles.messageCard} ${styles[message.role]}`}
                >
                  <div className={styles.messageHeader}>
                    <div className={styles.messageRole}>
                      {message.role === 'user' ? t('user') : t('agent')}
                    </div>
                    <div className={styles.messageTime}>
                      {formatDate(message.timestamp)}
                    </div>
                  </div>
                  <div className={styles.messageContent}>
                    {textContent}
                  </div>
                  {message.usage && (
                    <div className={styles.messageUsage}>
                      <span className={styles.usageLabel}>{t('tokens')}: </span>
                      <span className={styles.usageValue}>
                        {message.usage.total_tokens} 
                        ({t('input')}: {message.usage.input_tokens}, 
                        {t('output')}: {message.usage.output_tokens})
                      </span>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className={styles.systemPromptSection}>
          <h3 className={styles.sectionTitle}>{t('system_prompt')}</h3>
          <div className={styles.systemPrompt}>
            {quote.conversation.system_prompt || t('no_system_prompt_available')}
          </div>
        </div>
      )}
    </div>
  );
};

export default QuoteConversation;