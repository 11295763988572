// File: frontend/src/pages/InvestorPitch/components/slides/GoToMarketSlide/DeviceMockups.js

import React from 'react';
import styles from './DeviceMockups.module.css';

export const MacbookMockup = ({ children }) => (
  <div className={styles.macbookContainer}>
    <div className={styles.macbookTop}>
      <div className={styles.macbookScreen}>
        {children}
      </div>
    </div>
    <div className={styles.macbookBottom}>
      <div className={styles.macbookTrackpad}></div>
    </div>
  </div>
);

export const IPadMockup = ({ children }) => (
  <div className={styles.ipadContainer}>
    <div className={styles.ipadCamera}></div>
    <div className={styles.ipadScreen}>
      {children}
    </div>
    <div className={styles.ipadHomeButton}></div>
  </div>
);

export const IPhoneMockup = ({ children }) => (
  <div className={styles.iphoneContainer}>
    <div className={styles.iphoneNotch}></div>
    <div className={styles.iphoneScreen}>
      {children}
    </div>
    <div className={styles.iphoneHomeIndicator}></div>
  </div>
);

export const ApiMockup = ({ children }) => (
  <div className={styles.apiContainer}>
    <div className={styles.apiHeader}>
      <div className={styles.apiDot} style={{ backgroundColor: '#ff5f57' }}></div>
      <div className={styles.apiDot} style={{ backgroundColor: '#febc2e' }}></div>
      <div className={styles.apiDot} style={{ backgroundColor: '#28c840' }}></div>
    </div>
    <div className={styles.apiScreen}>
      <div className={styles.apiSidebar}>
        <div className={styles.apiSidebarItem}></div>
        <div className={styles.apiSidebarItem}></div>
        <div className={styles.apiSidebarItem}></div>
      </div>
      <div className={styles.apiContent}>
        {children}
      </div>
    </div>
  </div>
);