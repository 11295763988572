// File: frontend/src/pages/InvestorPitch/components/slides/GoToMarketSlide/ChannelCard.js

import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import styles from './ChannelCard.module.css';

const ChannelCard = ({ title, children }) => {
  return (
    <motion.div 
      className={styles.card}
      whileHover={{ scale: 1.02 }}
      transition={{ type: 'spring', stiffness: 300, damping: 30 }}
    >
      <h3 className={styles.title}>{title}</h3>
      <div className={styles.content}>
        {children}
      </div>
    </motion.div>
  );
};

ChannelCard.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default ChannelCard;