// File: frontend/src/services/enumService.js

import { ENUMS } from '../config/constants';

export const enumService = {
  // Get key by value from an enum object
  getKeyFromValue: (enumObj, value) => {
    const entry = Object.entries(enumObj).find(([_, val]) => val === value);
    return entry ? entry[0] : null;
  },

  // Transform raw user data into a version with resolved enum values
  resolveUserEnums: (userData) => {
    if (!userData) return null;

    return {
      ...userData,
      _raw: { ...userData }, // Keep original values
      // Resolve each enum to its string value
      admin_verification_status_key: enumService.getKeyFromValue(
        ENUMS.SUPER_ADMIN_VERIFICATION_STATUS,
        userData.admin_verification_status
      ),
      preferred_measurement_unit_key: enumService.getKeyFromValue(
        ENUMS.PREFERRED_MEASUREMENT_UNIT,
        userData.preferred_measurement_unit_id
      ),
      primary_language_key: enumService.getKeyFromValue(
        ENUMS.PRIMARY_LANGUAGE,
        userData.primary_language_id
      ),
      role_key: enumService.getKeyFromValue(
        ENUMS.USER_ROLE,
        userData.role_id
      ),
      user_type_key: enumService.getKeyFromValue(
        ENUMS.USER_TYPE,
        userData.user_type_id
      ),
      verification_status_key: enumService.getKeyFromValue(
        ENUMS.USER_VERIFICATION_STATUS,
        userData.verification_status
      )
    };
  }
};