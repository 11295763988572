// File: frontend/src/pages/Auth/SignOut.js

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../contexts/LanguageContext';
import { useAuth } from '../../contexts/AuthContextV2';
import AuthLayout from '../../components/layout/AuthLayout/AuthLayout';
import StatusScreen from '../../components/Auth/StatusScreen';
import styles from './SignOut.module.css';

const SignOut = () => {
  const { t } = useLanguage();
  const navigate = useNavigate();
  const { signOut, user } = useAuth();
  const [countdown, setCountdown] = useState(3);

  useEffect(() => {
    const handleSignOut = async () => {
      try {
        await signOut();
        
        const countdownInterval = setInterval(() => {
          setCountdown(prev => Math.max(0, prev - 1));
        }, 1000);

        setTimeout(() => {
          navigate('/', { replace: true });
        }, 3000);

        return () => clearInterval(countdownInterval);
      } catch (error) {
        console.error('SignOut error:', error);
        navigate('/', { replace: true });
      }
    };

    handleSignOut();
  }, [signOut, navigate, user]);

  const additionalInfo = (
    <div className={styles.signOutInfo}>
      <div className={styles.countdownWrapper}>
        <div className={styles.countdown}>
          <div 
            className={styles.countdownBar} 
            style={{ width: `${(countdown / 3) * 100}%` }} 
          />
        </div>
        <p className={styles.countdownText}>
          {t('auth.signOut.redirecting', { seconds: countdown })}
        </p>
      </div>
    </div>
  );

  return (
    <AuthLayout
      title={t('auth.signOut.title')}
      showHomeButton={true}
    >
      <div className={styles.signOutContainer}>
        <div className={styles.signOutContent}>
          <StatusScreen
            icon="/icons/18/alerts/warning.svg"
            title={t('auth.signOut.title')}
            message={t('auth.signOut.message')}
            type="warning"
            additionalInfo={additionalInfo}
          />
        </div>
      </div>
    </AuthLayout>
  );
};

export default React.memo(SignOut);