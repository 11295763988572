// File: frontend/src/pages/InvestorPitch/components/slides/ScientificArticlesSlide/ScientificArticlesSlide.js

import React from 'react';
import { motion } from 'framer-motion';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import { ANIMATION_VARIANTS } from '../../../constants/animations';
import { ExternalLink } from 'lucide-react';
import styles from './ScientificArticlesSlide.module.css';

const articles = [
  {
    title: 'Atomic Expense Map',
    subtitle: 'Revolutionizing Insurance: AI Reduces Operations Cost by 80%',
    url: 'https://allegory.app/en-ca/news/revolutionizing-insurance-ai-reduces-operations-cost',
    date: 'Feb 5, 2025',
    author: 'Onur Gungor',
    image: 'https://allegory.app/images/atomic-expense-analysis.png'
  },
  {
    title: 'Advanced Actuarial Analysis',
    subtitle: 'Multi-Modal LLM-Based Approach to Insurance Operations',
    url: 'https://allegory.app/en-ca/news/a-multi-modal-llm-based-advanced-actuarial-analysis-on-insurance-operations',
    date: 'Feb 6, 2025',
    author: 'Onur Gungor',
    image: 'https://allegory.app/images/actuarial-analysis-diagram.png'
  },
  {
    title: 'Quantified Impact of AI on Software Development',
    subtitle: 'How AI-1 Accelerates Insurance Software Development by 10x',
    url: 'https://allegory.app/en-ca/news/quantified-impact-of-ai-on-software-development',
    date: 'Feb 13, 2025',
    author: 'Onur Gungor',
    image: 'https://allegory.app/images/ai-development-metrics.png'
  }
];

const ScientificArticlesSlide = () => {
  const { t } = useLanguage();

  return (
    <motion.div
      className={styles.container}
      variants={ANIMATION_VARIANTS.stagger.container}
      initial="hidden"
      animate="visible"
    >
      <motion.div className={styles.header} variants={ANIMATION_VARIANTS.fadeIn}>
        <h2 className={styles.title}>
          {t('investor_pitch.research.title', 'Pioneering Research Driving Innovation')}
        </h2>
        <p className={styles.subtitle}>
          {t('investor_pitch.research.subtitle', 'Scientific breakthroughs fueling Allegory\'s AI advantage')}
        </p>
      </motion.div>

      <motion.div 
        className={styles.cardGrid}
        variants={ANIMATION_VARIANTS.stagger.container}
      >
        {articles.map((article, idx) => (
          <motion.div
            key={idx}
            className={styles.cardWrapper}
            variants={ANIMATION_VARIANTS.stagger.item}
            custom={idx}
          >
            <motion.a
              href={article.url}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.card}
              whileHover={{ scale: 1.02 }}
              transition={{ type: 'spring', stiffness: 300, damping: 30 }}
            >
              <div className={styles.imageContainer}>
                <img src={article.image} alt={article.title} className={styles.image} />
                <div className={styles.overlay}>
                  <ExternalLink className={styles.externalIcon} />
                </div>
              </div>
              <div className={styles.cardBody}>
                <h3 className={styles.articleTitle}>{article.title}</h3>
                <p className={styles.articleSubtitle}>{article.subtitle}</p>
                <p className={styles.articleMeta}>
                  <span className={styles.date}>Published {article.date}</span>
                  <span className={styles.author}>{article.author}</span>
                </p>
              </div>
            </motion.a>
          </motion.div>
        ))}
      </motion.div>
    </motion.div>
  );
};

export default ScientificArticlesSlide;