// File: frontend/src/pages/InvestorPitch/components/slides/GrowthSlide/MetricCard.js

import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import * as LucideIcons from 'lucide-react';
import styles from './MetricCard.module.css';

const MetricCard = ({ title, description, impact, icon }) => {
  const Icon = LucideIcons[icon];

  return (
    <motion.div 
      className={styles.container}
      whileHover={{ scale: 1.02 }}
    >
      <div className={styles.iconWrapper}>
        <Icon className={styles.icon} />
      </div>
      <div className={styles.content}>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.description}>{description}</p>
        <p className={styles.impact}>{impact}</p>
      </div>
    </motion.div>
  );
};

MetricCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

export default MetricCard;