// File: frontend/src/pages/InvestorPitch/components/slides/InvestmentSlide/ProjectionCard.js

import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from './ProjectionCard.module.css';

const ProjectionCard = ({ year, gwp, revenuePercentage, revenue, highlights, color }) => {
  const { t } = useLanguage();

  return (
    <motion.div 
      className={styles.container}
      whileHover={{ scale: 1.05 }}
      transition={{ type: 'spring', stiffness: 300, damping: 30 }}
    >
      <div className={styles.header}>
        <div className={styles.year}>
          {t('investor_pitch.projection.year_label', { year })}
        </div>
        <div className={styles.metrics}>
          <div className={styles.gwp}>
            <div className={styles.value} style={{ color }}>
              ${gwp}
            </div>
            <div className={styles.label}>
              {t('investor_pitch.projection.gwp_label')}
            </div>
          </div>
          <div className={styles.revenue}>
            <div className={styles.percentage} style={{ color }}>
              {t('investor_pitch.projection.revenue_percentage', { percentage: revenuePercentage })}
            </div>
            <div className={styles.value} style={{ color }}>
              ${revenue}
            </div>
            <div className={styles.label}>
              {t('investor_pitch.projection.revenue_label')}
            </div>
          </div>
        </div>
      </div>
      <ul className={styles.highlights}>
        {highlights.map((highlight, index) => (
          <li key={index}>
            {t(`investor_pitch.projection.highlights.${highlight}`)}
          </li>
        ))}
      </ul>
    </motion.div>
  );
};

ProjectionCard.propTypes = {
  year: PropTypes.number.isRequired,
  gwp: PropTypes.string.isRequired,
  revenuePercentage: PropTypes.number.isRequired,
  revenue: PropTypes.string.isRequired,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  color: PropTypes.string.isRequired,
};

export default ProjectionCard;