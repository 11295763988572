
import React, { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { MAPBOX_ACCESS_TOKEN } from '../../../config/constants';

mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;

const AccelerationMap = ({ width, height, tripData }) => {
  const mapContainer = useRef(null);
  const map = useRef(null);

  useEffect(() => {
    if (map.current) return; // Initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/dark-v10',
      center: [tripData.tripMidPointLongitudeFitted, tripData.tripMidPointLatitudeFitted],
      zoom: 9
    });

    map.current.on('load', () => {
      // Add layers for acceleration events
      // Example:
      // map.current.addLayer({
      //   id: 'acceleration-events',
      //   type: 'circle',
      //   source: {
      //     type: 'geojson',
      //     data: {
      //       type: 'FeatureCollection',
      //       features: [] // Add features based on tripData
      //     }
      //   },
      //   paint: {
      //     'circle-radius': 6,
      //     'circle-color': '#ff0000'
      //   }
      // });
    });

    return () => map.current.remove();
  }, [tripData]);

  return <div ref={mapContainer} style={{ width, height }} />;
};

export default AccelerationMap;