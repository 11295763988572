// File: frontend/src/config/errorMessages.js

// src/config/errorMessages.js
export const ERROR_CODES = {
    // 400 Bad Request
    VALIDATION: {
        PASSWORD: {
            LENGTH: 'error.validation.password.length',
            UPPERCASE: 'error.validation.password.uppercase',
            LOWERCASE: 'error.validation.password.lowercase',
            NUMBER: 'error.validation.password.number',
            SPECIAL: 'error.validation.password.special'
        },
        PHONE: {
            FORMAT: 'error.validation.phone.format',
            MISMATCH: 'error.validation.phone.components_mismatch',
            REQUIRED: 'error.validation.phone.required',
            IN_USE: 'error.validation.phone.in_use'
        },
        EMAIL: {
            FORMAT: 'error.validation.email.format',
            REQUIRED: 'error.validation.email.required'
        },
        ORGANIZATION: {
            NAME_REQUIRED: 'error.validation.organization.name_required',
            SIZE_INVALID: 'error.validation.organization.invalid_size',
            LANGUAGE_INVALID: 'error.validation.organization.invalid_language'
        },
        ENTERPRISE: {
            USER_ID_REQUIRED: 'error.validation.enterprise.user_id_required',
            ID_REQUIRED: 'error.validation.enterprise.id_required',
            ROLE_REQUIRED: 'error.validation.enterprise.role_required',
            ROLE_INVALID: 'error.validation.enterprise.role_invalid'
        },
        IDENTIFIER_REQUIRED: 'error.validation.identifier_required'
    },
  
    AUTH: {
      // Signup related
        SIGNUP: {
            NO_PENDING: 'error.auth.signup.no_pending',
            USER_EXISTS: 'error.auth.signup.user_exists',
            USER_EXISTS_COGNITO: 'error.auth.signup.user_exists_cognito',
            ALREADY_VERIFIED: 'error.auth.signup.already_verified',
            VERIFICATION_EXPIRED: 'error.auth.signup.verification_expired'
        },
      
        // Token related
        TOKEN: {
            MISSING: 'error.auth.token.missing',
            INVALID: 'error.auth.token.invalid',
            EXPIRED: 'error.auth.token.expired',
            REFRESH_REQUIRED: 'error.auth.token.refresh_required',
            REFRESH_INVALID: 'error.auth.token.refresh_invalid',
            REFRESH_MISSING: 'error.auth.token.refresh_missing'
        },
      
        // OTP related
        OTP: {
            INVALID: 'error.auth.otp.invalid',
            EXPIRED: 'error.auth.otp.expired',
            MAX_ATTEMPTS: 'error.auth.otp.max_attempts',
            FORMAT_INVALID: 'error.auth.otp.format_invalid'
        },
      
        // Password related
        PASSWORD: {
            INCORRECT: 'error.auth.password.incorrect',
            CHANGE_REQUIRED: 'error.auth.password.change_required'
        },
      
        // User configuration
        CONFIG: {
          INVALID: 'error.auth.config.invalid',
          NOT_CONFIRMED: 'error.auth.config.not_confirmed'
        }
    },
  
    // 401 Unauthorized
    UNAUTHORIZED: {
        DEFAULT: 'error.unauthorized.default',
        NO_TOKEN: 'error.unauthorized.no_token',
        INVALID_CREDENTIALS: 'error.unauthorized.invalid_credentials',
        SESSION_EXPIRED: 'error.unauthorized.session_expired',
        MISSING_CREDENTIALS: 'error.unauthorized.missing_credentials'
    },
  
    // 403 Forbidden
    FORBIDDEN: {
        DEFAULT: 'error.forbidden.default',
        INSUFFICIENT_PRIVILEGES: 'error.forbidden.insufficient_privileges',
        ACCOUNT: {
            PENDING_APPROVAL: 'error.forbidden.account.pending_approval',
            REJECTED: 'error.forbidden.account.rejected',
            SUSPENDED: 'error.forbidden.account.suspended',
            ALREADY_VERIFIED: 'error.forbidden.account.already_verified'
        },
      
        VERIFICATION: {
            REQUIRED: 'error.forbidden.verification.required',
            EMAIL_REQUIRED: 'error.forbidden.verification.email_required',
            PHONE_REQUIRED: 'error.forbidden.verification.phone_required',
            INCOMPLETE: 'error.forbidden.verification.incomplete'
        }
    },
  
    // 404 Not Found
    NOT_FOUND: {
        DEFAULT: 'error.not_found.default',
        USER: 'error.not_found.user',
        SIGNUP: 'error.not_found.signup',
        VERIFICATION: 'error.not_found.verification'
    },
  
    // 429 Too Many Requests
    RATE_LIMIT: {
        DEFAULT: 'error.rate_limit.default',
        SIGNIN_ATTEMPTS: 'error.rate_limit.signin_attempts',
        OTP_ATTEMPTS: 'error.rate_limit.otp_attempts',
        VERIFICATION_ATTEMPTS: 'error.rate_limit.verification_attempts'
    },
  
    // 500 Internal Server Error
    SERVER_ERROR: {
        DEFAULT: 'error.server.default',
      
        AUTH: {
            SIGNUP_FAILED: 'error.server.auth.signup_failed',
            SIGNIN_FAILED: 'error.server.auth.signin_failed',
            SIGNOUT_FAILED: 'error.server.auth.signout_failed',
            VERIFICATION_FAILED: 'error.server.auth.verification_failed',
            TOKEN_REFRESH_FAILED: 'error.server.auth.token_refresh_failed'
      },
      
        VERIFICATION: {
            EMAIL_FAILED: 'error.server.verification.email_failed',
            PHONE_FAILED: 'error.server.verification.phone_failed',
            USER_FAILED: 'error.server.verification.user_failed'
        }
    },

    NETWORK: {
        DEFAULT: 'error.network.default',
        CONNECTION_FAILED: 'error.network.connection_failed',
        TIMEOUT: 'error.network.timeout'
    },
    
    NOT_FOUND: {
        DEFAULT: 'error.not_found.default',
        USER_NOT_FOUND: 'error.not_found.user',
        RESOURCE_NOT_FOUND: 'error.not_found.resource'
    },
};