// File: frontend/src/pages/Blog/Blog.js

import React from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import styles from './Blog.module.css';

const Blog = () => {
  const { t } = useLanguage();

  return (
    <div >
    </div>
  );
};

export default Blog;