// File: frontend/src/utils/storage.js

/**
 * Safe storage utility that falls back to memory storage if localStorage is unavailable
 */
const memoryStorage = new Map();

export const safeStorage = {
  getItem: (key) => {
    try {
      const value = localStorage.getItem(key);
      return value;
    } catch (error) {
      console.warn('localStorage access failed, using memory storage:', error);
      return memoryStorage.get(key);
    }
  },
  
  setItem: (key, value) => {
    try {
      localStorage.setItem(key, value);
      return true;
    } catch (error) {
      console.warn('localStorage access failed, using memory storage:', error);
      memoryStorage.set(key, value);
      return true;
    }
  },
  
  removeItem: (key) => {
    try {
      localStorage.removeItem(key);
      return true;
    } catch (error) {
      console.warn('localStorage access failed, using memory storage:', error);
      memoryStorage.delete(key);
      return true;
    }
  },
  
  clear: () => {
    try {
      localStorage.clear();
      memoryStorage.clear();
      return true;
    } catch (error) {
      console.warn('localStorage access failed, using memory storage:', error);
      memoryStorage.clear();
      return true;
    }
  }
};

export default safeStorage;