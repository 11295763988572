// File: frontend/src/pages/Capabilities/Capabilities.js

import React, { useState, useMemo, useRef, useEffect } from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import { useSEO } from '../../contexts/SEOContext';
import { Helmet } from 'react-helmet-async';
import * as LucideIcons from 'lucide-react';
import styles from './Capabilities.module.css';
import capabilitiesData from './capabilitiesData.json';

const Capabilities = () => {
  const { t, language, supportedLanguages } = useLanguage();
  const { updateSEO } = useSEO();
  const [selectedIndustry, setSelectedIndustry] = useState('Safe Superintelligence');
  const tableWrapperRef = useRef(null);
  const tableRef = useRef(null);
  const headerRef = useRef(null);

  const industries = useMemo(() => ['All', ...capabilitiesData.industries], []);
  const baseUrl = 'https://www.allegory.app';
  const currentPath = '/capabilities';

  const filteredData = useMemo(() => {
    return capabilitiesData.capabilities.filter(item => 
      selectedIndustry === 'All' || item.industry === selectedIndustry
    );
  }, [selectedIndustry]);

  useEffect(() => {
    const structuredData = {
      '@context': 'https://schema.org',
      '@type': 'TechArticle',
      headline: t('capabilities_title'),
      description: t('capabilities_description'),
      keywords: t('capabilities_keywords'),
      datePublished: '2024-10-28',
      dateModified: new Date().toISOString(),
      mainEntityOfPage: {
        '@type': 'WebPage',
        '@id': `${baseUrl}${currentPath}`
      },
      author: {
        '@type': 'Organization',
        name: 'Allegory Insurance Technologies',
        url: baseUrl
      },
      about: {
        '@type': 'SoftwareApplication',
        name: 'Allegory AI Platform',
        applicationCategory: 'Insurance Software',
        operatingSystem: 'Web-based',
        offers: {
          '@type': 'Offer',
          availability: 'https://schema.org/OnlineOnly'
        },
        featureList: filteredData.map(item => t(`capabilities.${item.id}`)).join(', ')
      }
    };

    updateSEO({
      title: t('capabilities_title'),
      description: t('capabilities_description'),
      keywords: t('capabilities_keywords'),
      canonicalUrl: `${baseUrl}/${language}${currentPath}`,
      ogImage: `${baseUrl}/images/capabilities-og.jpg`,
      structuredData
    });
  }, [t, language, updateSEO, filteredData]);

  useEffect(() => {
    const handleScroll = () => {
      if (!tableWrapperRef.current || !tableRef.current || !headerRef.current) return;

      const wrapperRect = tableWrapperRef.current.getBoundingClientRect();
      const tableRect = tableRef.current.getBoundingClientRect();
      const headerRect = headerRef.current.getBoundingClientRect();
      const headerHeight = document.querySelector('header')?.offsetHeight || 0;
      
      if (wrapperRect.top <= headerHeight && wrapperRect.bottom >= headerRect.height + headerHeight) {
        headerRef.current.style.position = 'fixed';
        headerRef.current.style.top = `${headerHeight}px`;
        headerRef.current.style.left = `${wrapperRect.left}px`;
        headerRef.current.style.width = `${tableRect.width}px`;
        headerRef.current.style.zIndex = '10';
        headerRef.current.style.background = 'var(--color-gray-1200)';
      } else {
        headerRef.current.style.position = 'static';
        headerRef.current.style.left = 'auto';
        headerRef.current.style.width = 'auto';
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, []);

  return (
    <>
      <Helmet>
        <html lang={language} />
        <title>{t('capabilities_title')} | Allegory</title>
        <meta name="description" content={t('capabilities_description')} />
        <meta name="keywords" content={t('capabilities_keywords')} />
        
        {/* Language alternates */}
        <link rel="canonical" href={`${baseUrl}/${language}${currentPath}`} />
        {supportedLanguages.map(lang => (
          <link 
            key={lang}
            rel="alternate" 
            hreflang={lang} 
            href={`${baseUrl}/${lang}${currentPath}`}
          />
        ))}
        <link 
          rel="alternate" 
          hreflang="x-default" 
          href={`${baseUrl}/en-ca${currentPath}`}
        />

        {/* Open Graph */}
        <meta property="og:title" content={t('capabilities_title')} />
        <meta property="og:description" content={t('capabilities_description')} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={`${baseUrl}/${language}${currentPath}`} />
        <meta property="og:locale" content={language} />
        <meta property="article:modified_time" content={new Date().toISOString()} />
        
        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={t('capabilities_title')} />
        <meta name="twitter:description" content={t('capabilities_description')} />
      </Helmet>

      <div className={styles.capabilitiesContainer}>
        <h1 className={styles.title}>{t('capabilities_title_extended')}</h1>
        <p className={styles.description}>{t('capabilities_description')}</p>
        
        <nav className={styles.tabs} role="tablist" aria-label="Industry Selection">
          {industries.map(industry => (
            <button
              key={industry}
              role="tab"
              aria-selected={selectedIndustry === industry}
              aria-controls="capabilities-table"
              className={`${styles.tab} ${selectedIndustry === industry ? styles.activeTab : ''}`}
              onClick={() => setSelectedIndustry(industry)}
            >
              {industry === 'All' 
                ? t('all_industries')
                : t(`${industry}`)}
            </button>
          ))}
        </nav>

        <div className={styles.tableWrapper} ref={tableWrapperRef}>
          <div className={styles.tableContainer} ref={tableRef}>
            <table 
              id="capabilities-table"
              className={styles.capabilitiesTable}
              role="grid"
              aria-label="Capabilities Matrix"
            >
              <thead ref={headerRef}>
                <tr>
                  <th scope="col">{t('category')}</th>
                  <th scope="col">{t('capability')}</th>
                  <th scope="col">AI-1 (2024-Q4)</th>
                  <th scope="col">AI-2 (2025-Q3)</th>
                  <th scope="col">AI-3 (2025-Q3)</th>
                  <th scope="col">AI-4 (2026-Q3)</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((item) => (
                  <tr key={item.id}>
                    <td>{t(`categories.${item.category}`, 'capabilities')}</td>
                    <td>{t(`capabilities.${item.id}`, 'capabilities')}</td>
                    <td>{item.ai1}</td>
                    <td>{item.ai2}</td>
                    <td>{item.ai3}</td>
                    <td>{item.ai4}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Capabilities;