// File: frontend/src/pages/EmbeddedInsurance/index.js

import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import Header from './components/common/Header';
import Tabs from './components/common/Tabs';
import ApiExplorer from './components/ApiExplorer';
import ProductBuilder from './components/ProductBuilder';
import ChatbotIntegration from './components/ChatbotIntegration';
import { getProducts } from './data/apiEndpoints'; // Import to get default product if needed
import styles from './EmbeddedInsurance.module.css';

const EmbeddedInsurance = () => {
  const { t } = useLanguage();
  const [activeTab, setActiveTab] = useState('api-explorer');
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedCoverage, setSelectedCoverage] = useState(null);
  const [selectedJurisdiction, setSelectedJurisdiction] = useState(null);

  // Initialize with default product when component mounts
  useEffect(() => {
    if (!selectedProduct) {
      const products = getProducts();
      if (products && products.length > 0) {
        setSelectedProduct(products[0].id);
      }
    }
  }, [selectedProduct]);

  // Reset selected coverage when product changes
  useEffect(() => {
    // If product changes, we might need to reset coverage
    // as the available coverages depend on the selected product
    setSelectedCoverage(null);
  }, [selectedProduct]);

  const tabs = [
    { id: 'api-explorer', label: t('api_explorer') || 'API Explorer', icon: 'code' },
    { id: 'product-builder', label: t('product_builder') || 'Product Builder', icon: 'tools' },
    { id: 'chatbot-integration', label: t('chatbot_integration') || 'Chatbot Integration', icon: 'robot' },
  ];

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };

  const handleProductSelect = (product) => {
    if (product !== selectedProduct) {
      console.log('Product selected:', product);
      setSelectedProduct(product);
      // Reset coverage when product changes
      setSelectedCoverage(null);
    }
  };

  const handleCoverageSelect = (coverage) => {
    console.log('Coverage selected:', coverage);
    setSelectedCoverage(coverage);
  };

  const handleJurisdictionSelect = (jurisdiction) => {
    setSelectedJurisdiction(jurisdiction);
  };

  return (
    <div className={styles.container}>
      <Header 
        title={t('embedded_insurance') || 'Embedded Insurance'}
        description={t('embedded_insurance_description') || 'Create and manage embedded insurance products with our powerful API and chatbot integration.'}
      />
      
      <Tabs 
        tabs={tabs}
        activeTab={activeTab}
        onChange={handleTabChange}
      />
      
      <div className={styles.content}>
        {activeTab === 'api-explorer' && (
          <ApiExplorer 
            selectedProduct={selectedProduct}
            onProductSelect={handleProductSelect}
            selectedCoverage={selectedCoverage}
            onCoverageSelect={handleCoverageSelect}
            selectedJurisdiction={selectedJurisdiction}
            onJurisdictionSelect={handleJurisdictionSelect}
          />
        )}
        
        {activeTab === 'product-builder' && (
          <ProductBuilder 
            selectedProduct={selectedProduct}
            onProductSelect={handleProductSelect}
            selectedCoverage={selectedCoverage}
            onCoverageSelect={handleCoverageSelect}
            selectedJurisdiction={selectedJurisdiction}
            onJurisdictionSelect={handleJurisdictionSelect}
          />
        )}
        
        {activeTab === 'chatbot-integration' && (
          <ChatbotIntegration 
            selectedProduct={selectedProduct}
            selectedCoverage={selectedCoverage}
            selectedJurisdiction={selectedJurisdiction}
          />
        )}
      </div>
    </div>
  );
};

export default EmbeddedInsurance;