// File: frontend/src/pages/InvestorPitch/components/slides/SecuritySlide/ComplianceStandards.js

import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import * as LucideIcons from 'lucide-react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from './ComplianceStandards.module.css';

const ComplianceStandards = ({ standards }) => {
  const { t } = useLanguage();

  return (
    <div className={styles.container}>
      {standards.map((standard, index) => {
        const Icon = LucideIcons[standard.icon];
        return (
          <motion.div 
            key={standard.standard} 
            className={styles.standard}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
            aria-label={t('investor_pitch.security.compliance.standard.aria_label', {
              standard: standard.standard,
              status: standard.status
            }, `${standard.standard}: ${standard.status}`)}
          >
            <div className={styles.header}>
              <div className={styles.standardInfo}>
                <h4 className={styles.title}>{standard.standard}</h4>
                <div 
                  className={styles.status}
                  style={{ color: standard.color }}
                >
                  <Icon className={styles.icon} aria-hidden="true" />
                  <span>{standard.status}</span>
                </div>
              </div>
            </div>
            <ul className={styles.details}>
              {standard.details.map((detail, index) => (
                <li key={index} className={styles.detailItem}>{detail}</li>
              ))}
            </ul>
          </motion.div>
        );
      })}
    </div>
  );
};

ComplianceStandards.propTypes = {
  standards: PropTypes.arrayOf(
    PropTypes.shape({
      standard: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      details: PropTypes.arrayOf(PropTypes.string).isRequired,
      icon: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default ComplianceStandards;