// File: frontend/src/contexts/CSRFContext.js

import React, { createContext, useState, useContext, useEffect } from 'react';

const CSRFContext = createContext();

export const CSRFProvider = ({ children }) => {
  const [csrfToken, setCsrfToken] = useState(null);

  // Function to fetch new CSRF token
  const fetchCsrfToken = async () => {
    try {
      // When you have the API endpoint, uncomment and modify this
      // const response = await fetch('/api/csrf-token');
      // const data = await response.json();
      // setCsrfToken(data.token);
      
      // For now, just set a dummy token
      setCsrfToken('dummy-csrf-token');
    } catch (error) {
      console.error('Failed to fetch CSRF token:', error);
    }
  };

  // Initialize CSRF token when component mounts
  useEffect(() => {
    fetchCsrfToken();
  }, []);

  const updateCsrfToken = async () => {
    await fetchCsrfToken();
  };

  return (
    <CSRFContext.Provider 
      value={{ 
        csrfToken, 
        updateCsrfToken,
        // Helper method to get headers with CSRF token
        getHeaders: () => ({
          'X-CSRF-Token': csrfToken,
          'Content-Type': 'application/json'
        })
      }}
    >
      {children}
    </CSRFContext.Provider>
  );
};

export const useCSRF = () => {
  const context = useContext(CSRFContext);
  if (context === undefined) {
    throw new Error('useCSRF must be used within a CSRFProvider');
  }
  return context;
};