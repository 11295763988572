// File: frontend/src/pages/InvestorPitch/components/slides/GoToMarketSlide/GoToMarketSlide.js

import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import { ANIMATION_VARIANTS } from '../../../constants/animations';
import ChannelCard from './ChannelCard';
import { MacbookMockup, IPadMockup, IPhoneMockup, ApiMockup } from './DeviceMockups';
import styles from './GoToMarketSlide.module.css';

const GoToMarketSlide = () => {
  const { t } = useLanguage();
  const containerRef = useRef(null);
  
  const videoRefs = {
    desktop: useRef(null),
    mobile: useRef(null),
    tablet: useRef(null)
  };
  
  const [videosLoaded, setVideosLoaded] = useState({
    desktop: false,
    mobile: false,
    tablet: false
  });
  
  const [videosPlaying, setVideosPlaying] = useState({
    desktop: false,
    mobile: false,
    tablet: false
  });
  
  const [videosError, setVideosError] = useState({
    desktop: false,
    mobile: false,
    tablet: false
  });
  
  const [isMobile, setIsMobile] = useState(false);

  // Detect mobile devices
  useEffect(() => {
    const checkMobile = () => {
      const mobile = window.innerWidth <= 768;
      setIsMobile(mobile);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const handleVideoLoaded = (device) => {
    setVideosLoaded(prev => ({
      ...prev,
      [device]: true
    }));
    setVideosError(prev => ({
      ...prev,
      [device]: false
    }));
  };

  const handleVideoError = (device) => {
    console.error(`Video failed to load for ${device}`);
    setVideosError(prev => ({
      ...prev,
      [device]: true
    }));
    setVideosLoaded(prev => ({
      ...prev,
      [device]: false
    }));
  };

  const togglePlayPause = (device) => {
    if (videoRefs[device].current) {
      if (videosPlaying[device]) {
        videoRefs[device].current.pause();
      } else {
        videoRefs[device].current.play().catch(err => {
          console.warn(`Playback failed for ${device}:`, err);
        });
      }
    }
  };

  const handlePlay = (device) => {
    setVideosPlaying(prev => ({
      ...prev,
      [device]: true
    }));
  };

  const handlePause = (device) => {
    setVideosPlaying(prev => ({
      ...prev,
      [device]: false
    }));
  };

  // Intersection Observer to play videos when in view
  useEffect(() => {
    if (!containerRef.current) return;

    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.3,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // Try to play all loaded videos when slide comes into view
          Object.entries(videoRefs).forEach(([device, ref]) => {
            if (ref.current && videosLoaded[device]) {
              ref.current.play().catch(e => console.log(`Autoplay prevented for ${device}:`, e));
            }
          });
        } else {
          // Pause all videos when slide goes out of view
          Object.entries(videoRefs).forEach(([device, ref]) => {
            if (ref.current && videosPlaying[device]) {
              ref.current.pause();
            }
          });
        }
      });
    }, options);

    observer.observe(containerRef.current);

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, [videosLoaded, videosPlaying]);

  // Set up videos once loaded
  useEffect(() => {
    Object.entries(videoRefs).forEach(([device, ref]) => {
      if (ref.current && videosLoaded[device]) {
        ref.current.loop = true;
        
        // Add event listeners for play/pause state
        ref.current.addEventListener('play', () => handlePlay(device));
        ref.current.addEventListener('pause', () => handlePause(device));
        
        return () => {
          if (ref.current) {
            ref.current.removeEventListener('play', () => handlePlay(device));
            ref.current.removeEventListener('pause', () => handlePause(device));
          }
        };
      }
    });
  }, [videosLoaded]);

  // Get video source based on device
  const getVideoSource = (device, originalSrc) => {
    if (isMobile) {
      // Extract the base filename and add -mobile suffix
      const parts = originalSrc.split('.');
      const extension = parts.pop();
      const basePath = parts.join('.');
      return `${basePath}-mobile.${extension}`;
    }
    return originalSrc;
  };

  const channels = [
    {
      title: t('investor_pitch.go_to_market.desktop', 'Desktop'),
      content: (
        <div className={styles.deviceWrapper}>
          <MacbookMockup>
            <div 
              className={styles.videoWrapper}
              onClick={() => togglePlayPause('desktop')}
            >
              {videosError.desktop ? (
                <div className={styles.errorMessage}>
                  <p>Video could not be loaded</p>
                  <button 
                    className={styles.retryButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (videoRefs.desktop.current) {
                        videoRefs.desktop.current.load();
                      }
                      setVideosError(prev => ({...prev, desktop: false}));
                    }}
                  >
                    Retry
                  </button>
                </div>
              ) : (
                <>
                  <video 
                    ref={videoRefs.desktop}
                    className={styles.channelVideo}
                    src={getVideoSource('desktop', "https://allegory-public.s3.ca-central-1.amazonaws.com/pitch/pitch-sales-desktop.mp4")}
                    muted
                    playsInline
                    preload="auto"
                    poster="https://allegory-public.s3.ca-central-1.amazonaws.com/pitch/pitch-sales-desktop-poster.jpg"
                    onLoadedData={() => handleVideoLoaded('desktop')}
                    onError={() => handleVideoError('desktop')}
                  />
                  {!videosLoaded.desktop && (
                    <div className={styles.loadingOverlay}>
                      <div className={styles.loadingSpinner} />
                    </div>
                  )}
                  {videosLoaded.desktop && !videosPlaying.desktop && (
                    <div className={styles.playOverlay}>
                      <div className={styles.playButton}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M8 5V19L19 12L8 5Z" fill="white"/>
                        </svg>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </MacbookMockup>
        </div>
      )
    },
    {
      title: t('investor_pitch.go_to_market.tablet', 'Tablet'),
      content: (
        <div className={styles.deviceWrapper}>
          <IPadMockup>
            <div 
              className={styles.videoWrapper}
              onClick={() => togglePlayPause('tablet')}
            >
              {videosError.tablet ? (
                <div className={styles.errorMessage}>
                  <p>Video could not be loaded</p>
                  <button 
                    className={styles.retryButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (videoRefs.tablet.current) {
                        videoRefs.tablet.current.load();
                      }
                      setVideosError(prev => ({...prev, tablet: false}));
                    }}
                  >
                    Retry
                  </button>
                </div>
              ) : (
                <>
                  <video 
                    ref={videoRefs.tablet}
                    className={styles.channelVideo}
                    src={getVideoSource('tablet', "https://allegory-public.s3.ca-central-1.amazonaws.com/pitch/pitch-sales-tablet.mp4")}
                    muted
                    playsInline
                    preload="auto"
                    poster="https://allegory-public.s3.ca-central-1.amazonaws.com/pitch/pitch-sales-tablet-poster.jpg"
                    onLoadedData={() => handleVideoLoaded('tablet')}
                    onError={() => handleVideoError('tablet')}
                  />
                  {!videosLoaded.tablet && (
                    <div className={styles.loadingOverlay}>
                      <div className={styles.loadingSpinner} />
                    </div>
                  )}
                  {videosLoaded.tablet && !videosPlaying.tablet && (
                    <div className={styles.playOverlay}>
                      <div className={styles.playButton}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M8 5V19L19 12L8 5Z" fill="white"/>
                        </svg>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </IPadMockup>
        </div>
      )
    },
    {
      title: t('investor_pitch.go_to_market.mobile', 'Mobile'),
      content: (
        <div className={styles.deviceWrapper}>
          <IPhoneMockup>
            <div 
              className={styles.videoWrapper}
              onClick={() => togglePlayPause('mobile')}
            >
              {videosError.mobile ? (
                <div className={styles.errorMessage}>
                  <p>Video could not be loaded</p>
                  <button 
                    className={styles.retryButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (videoRefs.mobile.current) {
                        videoRefs.mobile.current.load();
                      }
                      setVideosError(prev => ({...prev, mobile: false}));
                    }}
                  >
                    Retry
                  </button>
                </div>
              ) : (
                <>
                  <video 
                    ref={videoRefs.mobile}
                    className={styles.channelVideo}
                    src={getVideoSource('mobile', "https://allegory-public.s3.ca-central-1.amazonaws.com/pitch/pitch-sales-mobile.mp4")}
                    muted
                    playsInline
                    preload="auto"
                    poster="https://allegory-public.s3.ca-central-1.amazonaws.com/pitch/pitch-sales-mobile-poster.jpg"
                    onLoadedData={() => handleVideoLoaded('mobile')}
                    onError={() => handleVideoError('mobile')}
                  />
                  {!videosLoaded.mobile && (
                    <div className={styles.loadingOverlay}>
                      <div className={styles.loadingSpinner} />
                    </div>
                  )}
                  {videosLoaded.mobile && !videosPlaying.mobile && (
                    <div className={styles.playOverlay}>
                      <div className={styles.playButton}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M8 5V19L19 12L8 5Z" fill="white"/>
                        </svg>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </IPhoneMockup>
        </div>
      )
    },
    {
      title: t('investor_pitch.go_to_market.api', 'API Embedded'),
      content: (
        <div className={styles.deviceWrapper}>
          <ApiMockup>
            <div className={styles.apiContent}>
              <div className={styles.apiCodeLines}>
                <div className={styles.apiCodeLine}>
                  <span className={styles.apiFunction}>GET</span> /api/v1/enterprise
                </div>
                <div className={styles.apiCodeLine}>
                  <span className={styles.apiFunction}>POST</span> /api/v1/quotes
                </div>
                <div className={styles.apiCodeLine}>
                  <span className={styles.apiFunction}>PUT</span> /api/v1/policies
                </div>
                <div className={styles.apiCodeLine}>
                  <span className={styles.apiComment}>//250+ endpoints</span>
                </div>
              </div>
            </div>
          </ApiMockup>
        </div>
      )
    }
  ];

  const stats = [
    {
      value: '25+',
      label: t('investor_pitch.go_to_market.products', 'Insurance Products')
    },
    {
      value: '8',
      label: t('investor_pitch.go_to_market.countries', 'Countries')
    },
    {
      value: '250+',
      label: t('investor_pitch.go_to_market.apis', 'APIs Ready')
    },
    {
      value: '25%',
      label: t('investor_pitch.go_to_market.discount', 'Premium Discount Advantage')
    }
  ];

  return (
    <motion.div
      className={styles.container}
      variants={ANIMATION_VARIANTS.stagger.container}
      initial="hidden"
      animate="visible"
      ref={containerRef}
    >
      <motion.div 
        className={styles.header}
        variants={ANIMATION_VARIANTS.fadeIn}
      >
        <h2 className={styles.title}>
          {t('investor_pitch.go_to_market.title', 'Multi-Channel Insurance Distribution')}
        </h2>
        <p className={styles.subtitle}>
          {t('investor_pitch.go_to_market.subtitle', 'Seamless experience across all platforms')}
        </p>
      </motion.div>

      <motion.div 
        className={styles.content}
        variants={ANIMATION_VARIANTS.stagger.container}
      >
        <div className={styles.channelsGrid}>
          {channels.map((channel, index) => (
            <motion.div
              key={channel.title}
              variants={ANIMATION_VARIANTS.stagger.item}
              custom={index}
              className={styles.channelItem}
            >
              <ChannelCard title={channel.title}>
                {channel.content}
              </ChannelCard>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </motion.div>
  );
};

export default GoToMarketSlide;