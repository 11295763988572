// File: frontend/src/pages/InvestorPitch/components/slides/TechnologySlide/TechCard.js

import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from './TechCard.module.css';

const TechCard = ({ title, description, icon: Icon, gradient }) => {
  const { t } = useLanguage();

  return (
    <motion.div 
      className={styles.card}
      whileHover={{ scale: 1.02 }}
      transition={{ type: 'spring', stiffness: 300, damping: 30 }}
      aria-label={t('investor_pitch.technology.card.aria_label', { title })}
    >
      <div 
        className={styles.iconWrapper}
        style={{ background: gradient }}
      >
        <Icon 
          className={styles.icon} 
          aria-hidden="true"
        />
      </div>
      <div className={styles.content}>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.description}>{description}</p>
      </div>
    </motion.div>
  );
};

TechCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.elementType.isRequired,
  gradient: PropTypes.string.isRequired,
};

export default TechCard;