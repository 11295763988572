// File: frontend/src/pages/EmbeddedInsurance/components/ProductBuilder/UnderwritingRules.js

import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import CodeBlock from '../common/CodeBlock';
import styles from './styles/UnderwritingRules.module.css';

const UnderwritingRules = ({
  selectedProduct,
  selectedCoverage,
  selectedJurisdiction,
  underwritingRules,
  onChange
}) => {
  const { t } = useLanguage();
  const [activeRule, setActiveRule] = useState(null);
  const [editingRule, setEditingRule] = useState(null);
  const [showAddForm, setShowAddForm] = useState(false);

  // Sample underwriting rule templates
  const ruleTemplates = [
    {
      id: 'age_eligibility',
      name: 'Age Eligibility',
      description: 'Set minimum and maximum age requirements',
      template: {
        type: 'age_eligibility',
        min_age: 18,
        max_age: 65,
        action: 'decline',
        message: 'Customer must be between 18 and 65 years old'
      }
    },
    {
      id: 'device_age',
      name: 'Device Age',
      description: 'Set maximum device age for coverage',
      template: {
        type: 'device_age',
        max_age_months: 24,
        action: 'decline',
        message: 'Device must be less than 24 months old'
      }
    },
    {
      id: 'location_restriction',
      name: 'Location Restriction',
      description: 'Restrict coverage to specific locations',
      template: {
        type: 'location_restriction',
        allowed_countries: ['US', 'CA'],
        allowed_states: ['CA', 'NY', 'TX', 'ON', 'BC'],
        action: 'refer',
        message: 'Coverage only available in select regions'
      }
    }
  ];

  // Initialize with a default rule if none exist
  useEffect(() => {
    if (underwritingRules.length === 0) {
      const defaultRule = {
        ...ruleTemplates[0].template,
        id: `rule_${Date.now()}`
      };
      onChange([defaultRule]);
      setActiveRule(defaultRule.id);
    } else if (!activeRule && underwritingRules.length > 0) {
      setActiveRule(underwritingRules[0].id);
    }
  }, [underwritingRules, activeRule, onChange]);

  const handleAddRule = (templateId) => {
    const template = ruleTemplates.find(t => t.id === templateId);
    if (template) {
      const newRule = {
        ...template.template,
        id: `rule_${Date.now()}`
      };
      const updatedRules = [...underwritingRules, newRule];
      onChange(updatedRules);
      setActiveRule(newRule.id);
      setShowAddForm(false);
    }
  };

  const handleEditRule = (ruleId) => {
    const rule = underwritingRules.find(r => r.id === ruleId);
    if (rule) {
      setEditingRule({ ...rule });
    }
  };

  const handleSaveRule = () => {
    if (editingRule) {
      const updatedRules = underwritingRules.map(rule => 
        rule.id === editingRule.id ? editingRule : rule
      );
      onChange(updatedRules);
      setEditingRule(null);
    }
  };

  const handleDeleteRule = (ruleId) => {
    const updatedRules = underwritingRules.filter(rule => rule.id !== ruleId);
    onChange(updatedRules);
    if (activeRule === ruleId && updatedRules.length > 0) {
      setActiveRule(updatedRules[0].id);
    } else if (updatedRules.length === 0) {
      setActiveRule(null);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditingRule(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleArrayChange = (field, value) => {
    // Split comma-separated values into an array
    const arrayValue = value.split(',').map(item => item.trim());
    
    setEditingRule(prev => ({
      ...prev,
      [field]: arrayValue
    }));
  };

  const getActiveRuleDetails = () => {
    return underwritingRules.find(rule => rule.id === activeRule);
  };

  const renderRuleEditor = () => {
    if (!editingRule) return null;

    switch (editingRule.type) {
      case 'age_eligibility':
        return (
          <div className={styles.ruleEditor}>
            <div className={styles.formRow}>
              <div className={styles.formGroup}>
                <label className={styles.formLabel}>{t('min_age') || 'Minimum Age'}</label>
                <input
                  type="number"
                  name="min_age"
                  className={styles.formInput}
                  value={editingRule.min_age}
                  onChange={handleInputChange}
                  min="0"
                />
              </div>
              <div className={styles.formGroup}>
                <label className={styles.formLabel}>{t('max_age') || 'Maximum Age'}</label>
                <input
                  type="number"
                  name="max_age"
                  className={styles.formInput}
                  value={editingRule.max_age}
                  onChange={handleInputChange}
                  min="0"
                />
              </div>
            </div>
            <div className={styles.formGroup}>
              <label className={styles.formLabel}>{t('action') || 'Action'}</label>
              <select
                name="action"
                className={styles.formSelect}
                value={editingRule.action}
                onChange={handleInputChange}
              >
                <option value="decline">{t('decline') || 'Decline'}</option>
                <option value="refer">{t('refer') || 'Refer'}</option>
                <option value="approve">{t('approve') || 'Approve'}</option>
              </select>
            </div>
            <div className={styles.formGroup}>
              <label className={styles.formLabel}>{t('message') || 'Message'}</label>
              <input
                type="text"
                name="message"
                className={styles.formInput}
                value={editingRule.message}
                onChange={handleInputChange}
              />
            </div>
          </div>
        );
      
      case 'device_age':
        return (
          <div className={styles.ruleEditor}>
            <div className={styles.formGroup}>
              <label className={styles.formLabel}>{t('max_age_months') || 'Maximum Age (months)'}</label>
              <input
                type="number"
                name="max_age_months"
                className={styles.formInput}
                value={editingRule.max_age_months}
                onChange={handleInputChange}
                min="0"
              />
            </div>
            <div className={styles.formGroup}>
              <label className={styles.formLabel}>{t('action') || 'Action'}</label>
              <select
                name="action"
                className={styles.formSelect}
                value={editingRule.action}
                onChange={handleInputChange}
              >
                <option value="decline">{t('decline') || 'Decline'}</option>
                <option value="refer">{t('refer') || 'Refer'}</option>
                <option value="approve">{t('approve') || 'Approve'}</option>
              </select>
            </div>
            <div className={styles.formGroup}>
              <label className={styles.formLabel}>{t('message') || 'Message'}</label>
              <input
                type="text"
                name="message"
                className={styles.formInput}
                value={editingRule.message}
                onChange={handleInputChange}
              />
            </div>
          </div>
        );
      
      case 'location_restriction':
        return (
          <div className={styles.ruleEditor}>
            <div className={styles.formGroup}>
              <label className={styles.formLabel}>{t('allowed_countries') || 'Allowed Countries'}</label>
              <input
                type="text"
                className={styles.formInput}
                value={editingRule.allowed_countries.join(', ')}
                onChange={(e) => handleArrayChange('allowed_countries', e.target.value)}
                placeholder="US, CA, UK (comma separated)"
              />
            </div>
            <div className={styles.formGroup}>
              <label className={styles.formLabel}>{t('allowed_states') || 'Allowed States/Provinces'}</label>
              <input
                type="text"
                className={styles.formInput}
                value={editingRule.allowed_states.join(', ')}
                onChange={(e) => handleArrayChange('allowed_states', e.target.value)}
                placeholder="CA, NY, TX, ON (comma separated)"
              />
            </div>
            <div className={styles.formGroup}>
              <label className={styles.formLabel}>{t('action') || 'Action'}</label>
              <select
                name="action"
                className={styles.formSelect}
                value={editingRule.action}
                onChange={handleInputChange}
              >
                <option value="decline">{t('decline') || 'Decline'}</option>
                <option value="refer">{t('refer') || 'Refer'}</option>
                <option value="approve">{t('approve') || 'Approve'}</option>
              </select>
            </div>
            <div className={styles.formGroup}>
              <label className={styles.formLabel}>{t('message') || 'Message'}</label>
              <input
                type="text"
                name="message"
                className={styles.formInput}
                value={editingRule.message}
                onChange={handleInputChange}
              />
            </div>
          </div>
        );
      
      default:
        return (
          <div className={styles.ruleEditor}>
            <p>{t('unsupported_rule_type') || 'Unsupported rule type'}</p>
          </div>
        );
    }
  };

  return (
    <div className={styles.underwritingRules}>
      <div className={styles.sectionHeader}>
        <h2 className={styles.sectionTitle}>{t('underwriting_rules') || 'Underwriting Rules'}</h2>
        <p className={styles.sectionDescription}>
          {t('underwriting_rules_description') || 'Define eligibility criteria and restrictions for this insurance product.'}
        </p>
      </div>

      <div className={styles.rulesContainer}>
        <div className={styles.rulesList}>
          <div className={styles.rulesHeader}>
            <h3 className={styles.rulesTitle}>{t('active_rules') || 'Active Rules'}</h3>
            <button 
              className={styles.addRuleButton}
              onClick={() => setShowAddForm(true)}
            >
              <i className="fas fa-plus"></i>
              <span>{t('add_rule') || 'Add Rule'}</span>
            </button>
          </div>

          {showAddForm && (
            <div className={styles.addRuleForm}>
              <h4 className={styles.addRuleTitle}>{t('select_rule_template') || 'Select Rule Template'}</h4>
              <div className={styles.ruleTemplates}>
                {ruleTemplates.map(template => (
                  <div 
                    key={template.id} 
                    className={styles.ruleTemplate}
                    onClick={() => handleAddRule(template.id)}
                  >
                    <h5 className={styles.templateName}>{template.name}</h5>
                    <p className={styles.templateDescription}>{template.description}</p>
                  </div>
                ))}
              </div>
              <button 
                className={styles.cancelButton}
                onClick={() => setShowAddForm(false)}
              >
                {t('cancel') || 'Cancel'}
              </button>
            </div>
          )}

          <div className={styles.rulesListItems}>
            {underwritingRules.map(rule => (
              <div 
                key={rule.id} 
                className={`${styles.ruleItem} ${activeRule === rule.id ? styles.activeRule : ''}`}
                onClick={() => setActiveRule(rule.id)}
              >
                <div className={styles.ruleInfo}>
                  <div className={styles.ruleType}>
                    {rule.type === 'age_eligibility' && (t('age_eligibility') || 'Age Eligibility')}
                    {rule.type === 'device_age' && (t('device_age') || 'Device Age')}
                    {rule.type === 'location_restriction' && (t('location_restriction') || 'Location Restriction')}
                  </div>
                  <div className={styles.rulePreview}>
                    <span className={`${styles.actionBadge} ${styles[rule.action]}`}>
                      {rule.action}
                    </span>
                  </div>
                </div>
                <div className={styles.ruleActions}>
                  <button 
                    className={styles.editButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEditRule(rule.id);
                    }}
                  >
                    <i className="fas fa-edit"></i>
                  </button>
                  <button 
                    className={styles.deleteButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteRule(rule.id);
                    }}
                  >
                    <i className="fas fa-trash-alt"></i>
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className={styles.ruleDetails}>
          {editingRule ? (
            <div className={styles.editRuleForm}>
              <h3 className={styles.editRuleTitle}>
                {editingRule.type === 'age_eligibility' && (t('edit_age_eligibility') || 'Edit Age Eligibility')}
                {editingRule.type === 'device_age' && (t('edit_device_age') || 'Edit Device Age')}
                {editingRule.type === 'location_restriction' && (t('edit_location_restriction') || 'Edit Location Restriction')}
              </h3>
              
              {renderRuleEditor()}
              
              <div className={styles.editActions}>
                <button 
                  className={styles.cancelButton}
                  onClick={() => setEditingRule(null)}
                >
                  {t('cancel') || 'Cancel'}
                </button>
                <button 
                  className={styles.saveButton}
                  onClick={handleSaveRule}
                >
                  {t('save_changes') || 'Save Changes'}
                </button>
              </div>
            </div>
          ) : activeRule ? (
            <div className={styles.viewRuleDetails}>
              <h3 className={styles.viewRuleTitle}>
                {getActiveRuleDetails().type === 'age_eligibility' && (t('age_eligibility') || 'Age Eligibility')}
                {getActiveRuleDetails().type === 'device_age' && (t('device_age') || 'Device Age')}
                {getActiveRuleDetails().type === 'location_restriction' && (t('location_restriction') || 'Location Restriction')}
              </h3>
              
              <div className={styles.rulePreview}>
                <CodeBlock 
                  code={JSON.stringify(getActiveRuleDetails(), null, 2)}
                  language="json"
                />
              </div>
              
              <button 
                className={styles.editButton}
                onClick={() => handleEditRule(activeRule)}
              >
                <i className="fas fa-edit"></i>
                <span>{t('edit_rule') || 'Edit Rule'}</span>
              </button>
            </div>
          ) : (
            <div className={styles.noRuleSelected}>
              <i className="fas fa-arrow-left"></i>
              <p>{t('select_rule_or_add_new') || 'Select a rule from the list or add a new one'}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UnderwritingRules;