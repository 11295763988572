// File: frontend/src/components/MileMonitor/MileMonitorContainer.js

import React, { useState } from 'react';
import DrivingScore from './DrivingScore';
import UserEngagement from './UserEngagement';
import EventsChart from './EventsChart';
import EventSelector from './EventSelector';
import UBIHeatmap from './UBIHeatmap';
import StatisticsCard from './StatisticsCard';
import mile_monitor_by_index_data from '../../data/mile_monitor_by_index_data.json';
import styles from './MileMonitorContainer.module.css';

const MileMonitorContainer = () => {
  const [selectedEvent, setSelectedEvent] = useState('acceleration');
  const [activeChart, setActiveChart] = useState('trips');

  return (
    <div className={styles.mileMonitorContainer}>
      <section className={styles.section}>
        <h2>Mile Monitor</h2>
        <div className={styles.chart}>
          <h3>Driving Score</h3>
          <DrivingScore data={mile_monitor_by_index_data} />
        </div>
      </section>

      <section className={styles.section}>
        <div className={styles.chart}>
          <h3>User Engagement</h3>
          <UserEngagement data={mile_monitor_by_index_data} />
        </div>
      </section>

      <section className={styles.section}>
        <h3>Harsh and Risky Events Trends</h3>
        <EventSelector selectedEvent={selectedEvent} onEventChange={setSelectedEvent} />
        <div className={styles.chartTabs}>
          <button className={activeChart === 'trips' ? styles.active : ''} onClick={() => setActiveChart('trips')}>vs. Trips</button>
          <button className={activeChart === 'distance' ? styles.active : ''} onClick={() => setActiveChart('distance')}>vs. Distance</button>
          <button className={activeChart === 'expDistance' ? styles.active : ''} onClick={() => setActiveChart('expDistance')}>vs. Expected Distance</button>
        </div>
        <EventsChart data={mile_monitor_by_index_data} chartType={activeChart} />
      </section>

      <section className={styles.section}>
        <h3>Usage-based Insurance (UBI) Portfolio</h3>
        <UBIHeatmap />
      </section>

      <section className={styles.section}>
        <StatisticsCard />
      </section>

      <section className={styles.insightSection}>
        <div className={styles.insightIcon}>
          <div className={styles.iconInner}></div>
        </div>
        <div className={styles.insightText}>iA users engage 87% less distracted driving event than an average Allegory user.</div>
      </section>
    </div>
  );
};

export default MileMonitorContainer;