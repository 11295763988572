// File: frontend/src/pages/AllegoryApp/AllegoryApp.js

import React, { useState } from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import PixelatedImage from '../../components/PixelatedImage/PixelatedImage';
import AppStoreButtons from '../../components/AppStoreButtons/AppStoreButtons';
import styles from './AllegoryApp.module.css';

const AllegoryApp = () => {
  const { t } = useLanguage();
  const [animationComplete, setAnimationComplete] = useState(false);

  return (
    <div className={styles.container}>
      <PixelatedImage 
        src="/images/allegory-marketing-image-0001.png" 
        pixelSize={8} 
        animationDuration={3000}
        onAnimationComplete={() => setAnimationComplete(true)}
      />
      <div className={`${styles.contentOverlay} ${animationComplete ? styles.visible : ''}`}>
        <div className={styles.content}>
          <h1 className={styles.title}>
            <span className={`${styles.highlight} ${styles.animateTitle}`}>{t('drive_safe')}</span>
            <span className={`${styles.highlight} ${styles.animateTitle}`}>{t('save_more')}</span>
          </h1>
          <p className={styles.description}>
            {t('allegory_app_description')}
          </p>
          <AppStoreButtons />
        </div>
      </div>
    </div>
  );
};

export default AllegoryApp;