// File: frontend/src/pages/TripDetail/components/TripEngagement/index.js

import React, { useEffect, useRef, useState } from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import styles from './TripEngagement.module.css';

const TripEngagement = ({ trip }) => {
  const { t } = useLanguage();
  const chartRef = useRef(null);
  const [chartLoaded, setChartLoaded] = useState(false);
  const [chartError, setChartError] = useState(null);
  const [activeVersion, setActiveVersion] = useState('v2');

  useEffect(() => {
    // This would be replaced with actual chart initialization code
    const initializeChart = async () => {
      try {
        // Simulate chart loading
        await new Promise(resolve => setTimeout(resolve, 800));
        
        // Check if we have engagement data
        if (!trip.engagement || !trip.engagement.engagement_scores) {
          setChartError(t('no_engagement_data_available'));
          return;
        }
        
        // In a real implementation, this is where you would:
        // 1. Initialize the chart library
        // 2. Create a radar chart with engagement factors
        // 3. Configure axes, legends, etc.
        
        setChartLoaded(true);
      } catch (error) {
        console.error('Error initializing chart:', error);
        setChartError(t('failed_to_load_engagement_chart'));
      }
    };

    if (trip && chartRef.current) {
      initializeChart();
    }
  }, [trip, t, activeVersion]);

  // Get engagement data for the active version
  const getEngagementData = () => {
    if (!trip.engagement || !trip.engagement.engagement_scores) {
      return null;
    }
    
    return trip.engagement.engagement_scores[activeVersion] || null;
  };

  const engagementData = getEngagementData();
  
  // Get available versions
  const getAvailableVersions = () => {
    if (!trip.engagement || !trip.engagement.engagement_scores) {
      return [];
    }
    
    return Object.keys(trip.engagement.engagement_scores);
  };

  const availableVersions = getAvailableVersions();

  return (
    <div className={styles.container}>
      {availableVersions.length > 1 && (
        <div className={styles.versionSelector}>
          <div className={styles.versionLabel}>{t('engagement_version')}:</div>
          <div className={styles.versionButtons}>
            {availableVersions.map(version => (
              <button
                key={version}
                className={`${styles.versionButton} ${activeVersion === version ? styles.activeVersion : ''}`}
                onClick={() => setActiveVersion(version)}
              >
                {version.toUpperCase()}
              </button>
            ))}
          </div>
        </div>
      )}
      
      {engagementData ? (
        <div className={styles.engagementContent}>
          <div className={styles.scoreCard}>
            <div className={styles.scoreValue}>{engagementData.engagement_score}</div>
            <div className={styles.scoreLabel}>{t('engagement_score')}</div>
          </div>
          
          <div className={styles.chartContainer} ref={chartRef}>
            {!chartLoaded && !chartError && (
              <div className={styles.loading}>
                <div className={styles.loadingSpinner}></div>
                <p>{t('loading_engagement_chart')}</p>
              </div>
            )}
            
            {chartError && (
              <div className={styles.chartError}>
                <p>{chartError}</p>
              </div>
            )}
            
            {chartLoaded && (
              <div className={styles.chartPlaceholder}>
                {/* This would be replaced by the actual chart */}
                <div className={styles.mockChart}>
                  <div className={styles.mockChartText}>
                    {t('engagement_chart_would_be_displayed_here')}
                  </div>
                </div>
              </div>
            )}
          </div>
          
          <div className={styles.factorsContainer}>
            <h3 className={styles.factorsTitle}>{t('engagement_factors')}</h3>
            
            <div className={styles.factorsGrid}>
              {engagementData.engagement_factors && Object.entries(engagementData.engagement_factors).map(([key, value]) => (
                <div key={key} className={styles.factorCard}>
                  <div className={styles.factorName}>{t(key.replace(/_/g, '_'))}</div>
                  <div className={styles.factorBar}>
                    <div 
                      className={styles.factorProgress} 
                      style={{ width: `${value}%` }}
                    ></div>
                  </div>
                  <div className={styles.factorValue}>{value}%</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.noEngagement}>
          <p>{t('no_engagement_data_available')}</p>
        </div>
      )}
      
      <div className={styles.engagementInfo}>
        <h3 className={styles.infoTitle}>{t('about_engagement_score')}</h3>
        <p className={styles.infoText}>
          {t('engagement_score_explanation')}
        </p>
        <div className={styles.infoFactors}>
          <div className={styles.infoFactor}>
            <div className={styles.infoFactorTitle}>{t('trip_completion')}</div>
            <p className={styles.infoFactorText}>{t('trip_completion_explanation')}</p>
          </div>
          <div className={styles.infoFactor}>
            <div className={styles.infoFactorTitle}>{t('feedback_provided')}</div>
            <p className={styles.infoFactorText}>{t('feedback_provided_explanation')}</p>
          </div>
          <div className={styles.infoFactor}>
            <div className={styles.infoFactorTitle}>{t('app_interaction')}</div>
            <p className={styles.infoFactorText}>{t('app_interaction_explanation')}</p>
          </div>
          {activeVersion === 'v2' && (
            <div className={styles.infoFactor}>
              <div className={styles.infoFactorTitle}>{t('social_sharing')}</div>
              <p className={styles.infoFactorText}>{t('social_sharing_explanation')}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TripEngagement;