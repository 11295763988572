// src/components/KnowledgeTransfer/RecentKnowledgeTable.js
import React from 'react';
import styles from './RecentKnowledgeTable.module.css';

const RecentKnowledgeTable = ({ recentKnowledge, onPublish, onSaveAsDraft, onDelete }) => {
  return (
    <div className={styles.recentKnowledgeTable}>
      <div className={styles.tableHeader}>
        <h3>Recent Knowledge</h3>
        <div className={styles.searchInput}>
          <input type="text" placeholder="Search knowledge..." />
          <button className={styles.searchButton}>
            <img src="/icons/18/misc/search.png" alt="Search" />
          </button>
        </div>
      </div>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Title</th>
            <th>Summary</th>
            <th>Created At</th>
            <th>Edited At</th>
            <th>Bucket</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {recentKnowledge.map((item) => (
            <tr key={item.id}>
              <td>{item.title}</td>
              <td>{item.summary}</td>
              <td>{new Date(item.createdAt).toLocaleString()}</td>
              <td>{new Date(item.editedAt).toLocaleString()}</td>
              <td>
                <a href={item.bucketUrl} target="_blank" rel="noopener noreferrer">
                  {item.bucket}
                </a>
              </td>
              <td>
                <button onClick={() => onPublish(item.id)} className={styles.actionButton}>
                  Publish
                </button>
                <button onClick={() => onSaveAsDraft(item.id)} className={styles.actionButton}>
                  Save as Draft
                </button>
                <button onClick={() => onDelete(item.id)} className={styles.actionButton}>
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className={styles.pagination}>
        {/* Add pagination controls here */}
      </div>
    </div>
  );
};

export default RecentKnowledgeTable;