// File: frontend/src/hooks/useJobApplication.js

// File: frontend/src/hooks/useJobApplication.js

import { useState } from 'react';
import { API_ENDPOINTS } from '../config/constants';
import apiService from '../services/api';
import { useLanguage } from '../contexts/LanguageContext';
import { SUPPORTED_LOCALES } from '../config/i18n.config';

export const JOB_APPLICATION_ERROR_CODES = {
  INVALID_EMAIL: 'invalid_email',
  INVALID_PHONE: 'invalid_phone',
  FILE_TOO_LARGE: 'file_too_large',
  INVALID_FILE_TYPE: 'invalid_file_type',
  MISSING_REQUIRED_FIELDS: 'missing_required_fields',
  INVALID_POSITION_COUNT: 'invalid_position_count',
  INVALID_COUNTRY: 'invalid_country',
  SUBMISSION_FAILED: 'submission_failed'
};

export const useJobApplication = () => {
  const { t, language } = useLanguage();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [applicationId, setApplicationId] = useState(null);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      throw new Error('INVALID_EMAIL');
    }
    return true;
  };

  const validatePhone = (phone) => {
    if (!phone.country_phone_code || !phone.area_code || !phone.phone_number) {
      throw new Error('INVALID_PHONE');
    }
    // Add more specific phone validation if needed
    return true;
  };

  const validatePositions = (positions) => {
    if (!Array.isArray(positions) || positions.length === 0) {
      throw new Error('MISSING_REQUIRED_FIELDS');
    }
    if (positions.length > 3) {
      throw new Error('INVALID_POSITION_COUNT');
    }
    return true;
  };

  const validateCountries = (countryOfResidence, preferredWorkLocation) => {
    if (!countryOfResidence) {
      throw new Error('INVALID_COUNTRY_RESIDENCE');
    }
    if (!preferredWorkLocation) {
      throw new Error('INVALID_COUNTRY_WORK');
    }
    return true;
  };

  const validateFile = (file) => {
    if (!file) {
      throw new Error('FILE_REQUIRED');
    }
  
    const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB in bytes
    if (file.size > MAX_FILE_SIZE) {
      throw new Error('FILE_TOO_LARGE');
    }
  
    if (file.type !== 'application/pdf') {
      throw new Error('INVALID_FILE_TYPE');
    }
  
    return true;
  };

  const sanitizeFilename = (filename) => {
    // Replace non-ASCII characters with underscores
    return filename.replace(/[^\x00-\x7F]/g, '_');
  };
  
  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result.split(',')[1];
        resolve({
          filename: sanitizeFilename(file.name),
          content: base64String,
          contentType: file.type
        });
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const getPositionName = (positionId) => {
    return t(`positions.list.${positionId}`);
  };

  const submit = async (formData) => {
    setIsLoading(true);
    setError(null);
    setSuccess(false);
    setApplicationId(null);
  
    try {
      // Validate all fields
      validateEmail(formData.email);
      validatePhone(formData.phone);
      validatePositions(formData.positions);
      validateFile(formData.resume);
      validateFile(formData.cover_letter);
      validateCountries(formData.country_of_residence, formData.preferred_work_location);

      if (!formData.country_of_residence || !formData.preferred_work_location) {
        // console.log("Error",formData.country_of_residence,formData.preferred_work_location )
        throw new Error('INVALID_COUNTRY');
      }
  
      // Convert files to base64
      const [resumeFile, coverLetterFile] = await Promise.all([
        convertFileToBase64(formData.resume),
        convertFileToBase64(formData.cover_letter)
      ]);

      const positionNames = formData.positions.map(positionId => getPositionName(positionId));
  
      const payload = {
        firstName: formData.first_name.trim(),
        lastName: formData.last_name.trim(),
        email: formData.email.trim(),
        phone: {
          country_phone_code: formData.phone.country_phone_code,
          area_code: formData.phone.area_code,
          phone_number: formData.phone.phone_number
        },
        positions: positionNames,
        country_of_residence: formData.country_of_residence,
        preferred_work_location: formData.preferred_work_location,
        resume: {
          filename: resumeFile.filename,
          content: resumeFile.content,
          contentType: 'application/pdf'
        },
        coverLetter: {
          filename: coverLetterFile.filename,
          content: coverLetterFile.content,
          contentType: 'application/pdf'
        },
        consent: formData.consent,
        primary_language_id: SUPPORTED_LOCALES[language].categoryValueId
      };
  
    //   console.log('Final payload to be sent:', payload);

      const response = await apiService.post(
        API_ENDPOINTS.ENGAGEMENT.JOIN_SUBMIT,
        payload
      );
  
      setSuccess(true);
      setApplicationId(response.applicationId);
      return response;
    } catch (err) {
      let errorCode;
  
      switch (err.message) {
        case 'INVALID_EMAIL':
          errorCode = JOB_APPLICATION_ERROR_CODES.INVALID_EMAIL;
          break;
        case 'INVALID_PHONE':
          errorCode = JOB_APPLICATION_ERROR_CODES.INVALID_PHONE;
          break;
        case 'FILE_REQUIRED':
          errorCode = JOB_APPLICATION_ERROR_CODES.MISSING_REQUIRED_FIELDS;
          break;
        case 'FILE_TOO_LARGE':
          errorCode = JOB_APPLICATION_ERROR_CODES.FILE_TOO_LARGE;
          break;
        case 'INVALID_FILE_TYPE':
          errorCode = JOB_APPLICATION_ERROR_CODES.INVALID_FILE_TYPE;
          break;
        case 'INVALID_POSITION_COUNT':
          errorCode = JOB_APPLICATION_ERROR_CODES.INVALID_POSITION_COUNT;
          break;
        case 'INVALID_COUNTRY':
          errorCode = JOB_APPLICATION_ERROR_CODES.INVALID_COUNTRY;
          break;
        default:
          errorCode = JOB_APPLICATION_ERROR_CODES.SUBMISSION_FAILED;
      }
  
      setError(errorCode);
      throw err;
    } finally {
        setIsLoading(false);
    }
  };

  return {
    submit,
    isLoading,
    error,
    success,
    applicationId,
    clearError: () => setError(null),
    clearSuccess: () => {
      setSuccess(false);
      setApplicationId(null);
    }
  };
};