// File: frontend/src/pages/About/About.js

import React from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import styles from './About.module.css';

const About = () => {
  const { t } = useLanguage();

  const about = {
    intro: 'about.intro',
    approach: 'about.approach',
    flagship_product: 'about.flagship_product',
    ai1: 'about.ai1',
    closing: 'about.closing'
  };

  return (
    <div className={styles.aboutContainer}>
      <h1 className={styles.title}>{t('about_allegory')}</h1>

      <section className={styles.mission}>
        <h2>{t('our_mission')}</h2>
        <p>{t(about.intro)}</p>
        <p>{t(about.approach)}</p>
        <p>{t(about.flagship_product)}</p>
        <p>{t(about.ai1)}</p>
        <p>{t(about.closing)}</p>
      </section>

    </div>
  );
};

export default About;