// File: frontend/src/pages/AutoInsight/VehicleDetail/components/VehicleHeader/index.js

import React from 'react';
import { useLanguage } from '../../../../../../contexts/LanguageContext';
import styles from './VehicleHeader.module.css';

const VehicleHeader = ({ vehicle, onBack }) => {
  const { t } = useLanguage();

  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <h1 className={styles.title}>
          {vehicle.details?.vehicle_year} {vehicle.details?.vehicle_make} {vehicle.details?.vehicle_model}
        </h1>
        
        <div className={styles.meta}>
          <div className={styles.metaItem}>
            <span className={styles.metaLabel}>{t('vin')}:</span>
            <span className={styles.metaValue}>{vehicle.vin}</span>
          </div>
          
          <div className={styles.metaItem}>
            <span className={styles.metaLabel}>{t('license_plate')}:</span>
            <span className={styles.metaValue}>{vehicle.license_plate}</span>
          </div>
          
          {vehicle.is_primary_vehicle && (
            <div className={styles.primaryBadge}>
              {t('primary_vehicle')}
            </div>
          )}
        </div>
      </div>
      
      <div className={styles.actions}>
        <button className={styles.backButton} onClick={onBack}>
          {t('back_to_list')}
        </button>
        
        <button className={styles.editButton}>
          {t('edit_vehicle')}
        </button>
      </div>
    </div>
  );
};

export default VehicleHeader;