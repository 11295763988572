// File: frontend/src/pages/InvestorPitch/components/slides/MarketEntrySlide/MarketEntrySlide.js

import React from 'react';
import { motion } from 'framer-motion';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import EntryPhase from './EntryPhase';
import ExecutionPlan from './ExecutionPlan';
import MarketReadiness from './MarketReadiness';
import { ANIMATION_VARIANTS } from '../../../constants/animations';
import styles from './MarketEntrySlide.module.css';

const MarketEntrySlide = () => {
  const { t } = useLanguage();

  const entryPhases = [
    {
      title: t('investor_pitch.market_entry.phases.cold_plunge.title'),
      description: t('investor_pitch.market_entry.phases.cold_plunge.description'),
      steps: [
        {
          title: t('investor_pitch.market_entry.phases.cold_plunge.steps.product.title'),
          description: t('investor_pitch.market_entry.phases.cold_plunge.steps.product.description'),
          icon: "Package",
          color: "var(--color-blue-310)"
        },
        {
          title: t('investor_pitch.market_entry.phases.cold_plunge.steps.deployment.title'),
          description: t('investor_pitch.market_entry.phases.cold_plunge.steps.deployment.description'),
          icon: "Globe",
          color: "var(--color-green-310)"
        },
        {
          title: t('investor_pitch.market_entry.phases.cold_plunge.steps.sales.title'),
          description: t('investor_pitch.market_entry.phases.cold_plunge.steps.sales.description'),
          icon: "Users",
          color: "var(--color-orange-310)"
        }
      ],
      gradient: "linear-gradient(135deg, var(--color-blue-210), var(--color-blue-510))"
    }
  ];

  const executionSteps = [
    {
      phase: t('investor_pitch.market_entry.execution.month1.phase'),
      title: t('investor_pitch.market_entry.execution.month1.title'),
      actions: [
        t('investor_pitch.market_entry.execution.month1.actions.funding'),
        t('investor_pitch.market_entry.execution.month1.actions.hiring'),
        t('investor_pitch.market_entry.execution.month1.actions.entities')
      ],
      status: t('investor_pitch.market_entry.status.ready'),
      color: "var(--color-blue-310)"
    },
    {
      phase: t('investor_pitch.market_entry.execution.month2_3.phase'),
      title: t('investor_pitch.market_entry.execution.month2_3.title'),
      actions: [
        t('investor_pitch.market_entry.execution.month2_3.actions.processing'),
        t('investor_pitch.market_entry.execution.month2_3.actions.datacenters'),
        t('investor_pitch.market_entry.execution.month2_3.actions.compliance')
      ],
      status: t('investor_pitch.market_entry.status.ready'),
      color: "var(--color-green-310)"
    },
    {
      phase: t('investor_pitch.market_entry.execution.month4.phase'),
      title: t('investor_pitch.market_entry.execution.month4.title'),
      actions: [
        t('investor_pitch.market_entry.execution.month4.actions.deployment'),
        t('investor_pitch.market_entry.execution.month4.actions.sales'),
        t('investor_pitch.market_entry.execution.month4.actions.partners')
      ],
      status: t('investor_pitch.market_entry.status.planned'),
      color: "var(--color-orange-310)"
    }
  ];

  const marketReadiness = {
    metrics: [
      {
        title: t('investor_pitch.market_entry.readiness.metrics.market.title'),
        value: 100,
        icon: "CheckCircle",
        color: "var(--color-blue-310)"
      },
      {
        title: t('investor_pitch.market_entry.readiness.metrics.team.title'),
        value: 30,
        icon: "Users",
        color: "var(--color-green-310)"
      },
      {
        title: t('investor_pitch.market_entry.readiness.metrics.infrastructure.title'),
        value: 95,
        icon: "Server",
        color: "var(--color-orange-310)"
      }
    ],
    highlights: [
      t('investor_pitch.market_entry.readiness.highlights.regulatory'),
      t('investor_pitch.market_entry.readiness.highlights.partnerships'),
      t('investor_pitch.market_entry.readiness.highlights.team'),
      t('investor_pitch.market_entry.readiness.highlights.infrastructure')
    ]
  };

  return (
    <motion.div
      className={styles.container}
      variants={ANIMATION_VARIANTS.stagger.container}
      initial="hidden"
      animate="visible"
    >
      <motion.div 
        className={styles.header}
        variants={ANIMATION_VARIANTS.fadeIn}
      >
        <h2 className={styles.title}>{t('investor_pitch.market_entry.title')}</h2>
        <p className={styles.subtitle}>{t('investor_pitch.market_entry.subtitle')}</p>
      </motion.div>

      <div className={styles.content}>
        <motion.div 
          className={styles.phaseSection}
          variants={ANIMATION_VARIANTS.stagger.container}
        >
          {entryPhases.map((phase, index) => (
            <motion.div
              key={phase.title}
              variants={ANIMATION_VARIANTS.stagger.item}
              custom={index}
            >
              <EntryPhase {...phase} />
            </motion.div>
          ))}
        </motion.div>

        <div className={styles.detailsSection}>
          <motion.div 
            className={styles.executionPlan}
            variants={ANIMATION_VARIANTS.fadeIn}
          >
            <h3 className={styles.sectionTitle}>
              {t('investor_pitch.market_entry.sections.execution')}
            </h3>
            <ExecutionPlan steps={executionSteps} />
          </motion.div>

          <motion.div 
            className={styles.readinessSection}
            variants={ANIMATION_VARIANTS.fadeIn}
          >
            <h3 className={styles.sectionTitle}>
              {t('investor_pitch.market_entry.sections.readiness')}
            </h3>
            <MarketReadiness {...marketReadiness} />
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
};

export default MarketEntrySlide;