// File: frontend/src/pages/InvestorPitch/components/slides/SecuritySlide/SecuritySlide.js

import React from 'react';
import { motion } from 'framer-motion';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import SecurityFramework from './SecurityFramework';
import ComplianceStandards from './ComplianceStandards';
import DataProtection from './DataProtection';
import { ANIMATION_VARIANTS } from '../../../constants/animations';
import styles from './SecuritySlide.module.css';

const SecuritySlide = () => {
  const { t } = useLanguage();

  const securityFeatures = [
    {
      title: t('investor_pitch.security.features.encryption.title', 'End-to-End Encryption'),
      description: t('investor_pitch.security.features.encryption.description', 'All data encrypted in transit and at rest'),
      icon: "Lock",
      metrics: [
        { 
          label: t('investor_pitch.security.features.encryption.metrics.standard.label', 'Encryption Standard'), 
          value: t('investor_pitch.security.features.encryption.metrics.standard.value', 'AES-256')
        },
        { 
          label: t('investor_pitch.security.features.encryption.metrics.key.label', 'Key Management'), 
          value: t('investor_pitch.security.features.encryption.metrics.key.value', 'AWS KMS')
        }
      ],
      gradient: "linear-gradient(135deg, var(--color-blue-310), var(--color-blue-510))"
    },
    {
      title: t('investor_pitch.security.features.access.title', 'Access Control'),
      description: t('investor_pitch.security.features.access.description', 'Role-based access with MFA'),
      icon: "Shield",
      metrics: [
        { 
          label: t('investor_pitch.security.features.access.metrics.auth.label', 'Authentication'), 
          value: t('investor_pitch.security.features.access.metrics.auth.value', 'Multi-factor')
        },
        { 
          label: t('investor_pitch.security.features.access.metrics.levels.label', 'Access Levels'), 
          value: t('investor_pitch.security.features.access.metrics.levels.value', '5 tiers')
        }
      ],
      gradient: "linear-gradient(135deg, var(--color-green-310), var(--color-green-510))"
    },
    {
      title: t('investor_pitch.security.features.infrastructure.title', 'Secure Infrastructure'),
      description: t('investor_pitch.security.features.infrastructure.description', 'Cloud-native security architecture'),
      icon: "Server",
      metrics: [
        { 
          label: t('investor_pitch.security.features.infrastructure.metrics.monitoring.label', 'Monitoring'), 
          value: t('investor_pitch.security.features.infrastructure.metrics.monitoring.value', '24/7 real-time')
        },
        { 
          label: t('investor_pitch.security.features.infrastructure.metrics.isolation.label', 'Data Isolation'), 
          value: t('investor_pitch.security.features.infrastructure.metrics.isolation.value', 'Complete')
        }
      ],
      gradient: "linear-gradient(135deg, var(--color-orange-310), var(--color-orange-510))"
    }
  ];

  const complianceStandards = [
    {
      standard: t('investor_pitch.security.compliance.soc2.standard', 'SOC 2 Type II'),
      status: t('investor_pitch.security.compliance.soc2.status', 'Compliant'),
      details: [
        t('investor_pitch.security.compliance.soc2.details.security', 'Security controls implementation'),
        t('investor_pitch.security.compliance.soc2.details.availability', 'System availability monitoring'),
        t('investor_pitch.security.compliance.soc2.details.integrity', 'Processing integrity verification'),
        t('investor_pitch.security.compliance.soc2.details.confidentiality', 'Data confidentiality measures')
      ],
      icon: "Shield",
      color: "var(--color-blue-310)"
    },
    {
      standard: t('investor_pitch.security.compliance.gdpr.standard', 'GDPR'),
      status: t('investor_pitch.security.compliance.gdpr.status', 'Compliant'),
      details: [
        t('investor_pitch.security.compliance.gdpr.details.protection', 'Data protection by design'),
        t('investor_pitch.security.compliance.gdpr.details.rights', 'User rights management'),
        t('investor_pitch.security.compliance.gdpr.details.transfers', 'Secure international transfers'),
        t('investor_pitch.security.compliance.gdpr.details.breach', 'Breach notification process')
      ],
      icon: "CheckCircle",
      color: "var(--color-success)"
    },
    {
      standard: t('investor_pitch.security.compliance.iso.standard', 'ISO 27001'),
      status: t('investor_pitch.security.compliance.iso.status', 'In Progress'),
      details: [
        t('investor_pitch.security.compliance.iso.details.security', 'Information security management'),
        t('investor_pitch.security.compliance.iso.details.risk', 'Risk assessment framework'),
        t('investor_pitch.security.compliance.iso.details.asset', 'Asset management protocols'),
        t('investor_pitch.security.compliance.iso.details.access', 'Access control implementation')
      ],
      icon: "Clock",
      color: "var(--color-warning)"
    }
  ];

  const dataProtection = {
    title: t('investor_pitch.security.data_protection.title', 'Data Protection'),
    features: [
      {
        title: t('investor_pitch.security.data_protection.features.datacenter.title', 'Regional Data Centers'),
        description: t('investor_pitch.security.data_protection.features.datacenter.description', 'Data stored in country-specific regions to meet local regulations'),
        icon: "Database",
        gradient: "linear-gradient(135deg, var(--color-blue-110), var(--color-blue-310))"
      },
      {
        title: t('investor_pitch.security.data_protection.features.audit.title', 'Audit Logging'),
        description: t('investor_pitch.security.data_protection.features.audit.description', 'Comprehensive audit trails for all data access and modifications'),
        icon: "ScrollText",
        gradient: "linear-gradient(135deg, var(--color-green-110), var(--color-green-310))"
      },
      {
        title: t('investor_pitch.security.data_protection.features.lifecycle.title', 'Data Lifecycle Management'),
        description: t('investor_pitch.security.data_protection.features.lifecycle.description', 'Automated data retention and secure deletion processes'),
        icon: "RotateCcw",
        gradient: "linear-gradient(135deg, var(--color-orange-110), var(--color-orange-310))"
      }
    ]
  };

  return (
    <motion.div
      className={styles.container}
      variants={ANIMATION_VARIANTS.stagger.container}
      initial="hidden"
      animate="visible"
    >
      <motion.div 
        className={styles.header}
        variants={ANIMATION_VARIANTS.fadeIn}
      >
        <h2 className={styles.title}>
          {t('investor_pitch.security.title', 'Enterprise-Grade Security & Compliance')}
        </h2>
        <p className={styles.subtitle}>
          {t('investor_pitch.security.subtitle', 'Protecting sensitive insurance data with industry-leading security measures')}
        </p>
      </motion.div>

      <motion.div 
        className={styles.content}
        variants={ANIMATION_VARIANTS.stagger.container}
      >
        <motion.div 
          className={styles.securitySection}
          variants={ANIMATION_VARIANTS.fadeIn}
        >
          <h3 className={styles.sectionTitle}>
            {t('investor_pitch.security.sections.framework', 'Security Framework')}
          </h3>
          <SecurityFramework features={securityFeatures} />
        </motion.div>

        <div className={styles.detailsSection}>
          <motion.div 
            className={styles.complianceSection}
            variants={ANIMATION_VARIANTS.fadeIn}
          >
            <h3 className={styles.sectionTitle}>
              {t('investor_pitch.security.sections.compliance', 'Compliance Standards')}
            </h3>
            <ComplianceStandards standards={complianceStandards} />
          </motion.div>

          <motion.div 
            className={styles.dataSection}
            variants={ANIMATION_VARIANTS.fadeIn}
          >
            <h3 className={styles.sectionTitle}>
              {t('investor_pitch.security.sections.data_protection', 'Data Protection')}
            </h3>
            <DataProtection {...dataProtection} />
          </motion.div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default SecuritySlide;