// File: frontend/src/pages/AutoInsight/VehicleDetail/components/VehicleRecalls/index.js

import React from 'react';
import { useLanguage } from '../../../../../../contexts/LanguageContext';
import styles from './VehicleRecalls.module.css';

// Helper function to format date
const formatDate = (dateString) => {
  if (!dateString) return 'N/A';
  
  const date = new Date(dateString);
  
  // Check if date is valid
  if (isNaN(date.getTime())) return 'Invalid Date';
  
  return date.toLocaleDateString();
};

const VehicleRecalls = ({ vehicle }) => {
  const { t } = useLanguage();

  // Sort recalls by date (newest first)
  const sortedRecalls = vehicle.recalls 
    ? [...vehicle.recalls].sort((a, b) => {
        return new Date(b.recall_date) - new Date(a.recall_date);
      })
    : [];

  // Separate open and closed recalls
  const openRecalls = sortedRecalls.filter(recall => recall.recall_status === 'OPEN' || recall.recall_status === 'REMEDY_AVAILABLE');
  const closedRecalls = sortedRecalls.filter(recall => recall.recall_status === 'CLOSED');

  return (
    <div className={styles.container}>
      <div className={styles.recallSummary}>
        <div className={styles.summaryCard}>
          <div className={styles.summaryTitle}>{t('total_recalls')}</div>
          <div className={styles.summaryValue}>{sortedRecalls.length}</div>
        </div>
        
        <div className={styles.summaryCard}>
          <div className={styles.summaryTitle}>{t('open_recalls')}</div>
          <div className={`${styles.summaryValue} ${openRecalls.length > 0 ? styles.openRecalls : ''}`}>
            {openRecalls.length}
          </div>
        </div>
        
        <div className={styles.summaryCard}>
          <div className={styles.summaryTitle}>{t('closed_recalls')}</div>
          <div className={styles.summaryValue}>{closedRecalls.length}</div>
        </div>
      </div>
      
      {openRecalls.length > 0 && (
        <div className={styles.card}>
          <h3 className={styles.cardTitle}>{t('open_recalls')}</h3>
          <div className={styles.cardContent}>
            <div className={styles.recallsList}>
              {openRecalls.map((recall) => (
                <div key={recall.recall_id} className={styles.recallItem}>
                  <div className={styles.recallHeader}>
                    <div className={styles.recallComponent}>{recall.recall_component}</div>
                    <div className={`${styles.recallStatus} ${styles[recall.recall_status.toLowerCase()]}`}>
                      {t(recall.recall_status.toLowerCase())}
                    </div>
                  </div>
                  
                  <div className={styles.recallDescription}>
                    {recall.recall_description}
                  </div>
                  
                  <div className={styles.recallDetails}>
                    <div className={styles.recallDetail}>
                      <span className={styles.recallDetailLabel}>{t('recall_date')}:</span>
                      <span className={styles.recallDetailValue}>{formatDate(recall.recall_date)}</span>
                    </div>
                    
                    <div className={styles.recallDetail}>
                      <span className={styles.recallDetailLabel}>{t('nhtsa_campaign_number')}:</span>
                      <span className={styles.recallDetailValue}>{recall.nhtsa_campaign_number}</span>
                    </div>
                    
                    <div className={styles.recallDetail}>
                      <span className={styles.recallDetailLabel}>{t('manufacturer_recall_number')}:</span>
                      <span className={styles.recallDetailValue}>{recall.manufacturer_recall_number}</span>
                    </div>
                    
                    {recall.recall_status === 'REMEDY_AVAILABLE' && (
                      <div className={styles.recallDetail}>
                        <span className={styles.recallDetailLabel}>{t('remedy_date')}:</span>
                        <span className={styles.recallDetailValue}>{formatDate(recall.remedy_date)}</span>
                      </div>
                    )}
                    
                    <div className={styles.recallDetail}>
                      <span className={styles.recallDetailLabel}>{t('affected_units')}:</span>
                      <span className={styles.recallDetailValue}>{recall.affected_units}</span>
                    </div>
                  </div>
                  
                  <div className={styles.recallSections}>
                    <div className={styles.recallSection}>
                      <div className={styles.recallSectionTitle}>{t('consequence')}:</div>
                      <div className={styles.recallSectionContent}>{recall.recall_consequence}</div>
                    </div>
                    
                    <div className={styles.recallSection}>
                      <div className={styles.recallSectionTitle}>{t('remedy')}:</div>
                      <div className={styles.recallSectionContent}>{recall.recall_remedy}</div>
                    </div>
                    
                    {recall.recall_notes && (
                      <div className={styles.recallSection}>
                        <div className={styles.recallSectionTitle}>{t('notes')}:</div>
                        <div className={styles.recallSectionContent}>{recall.recall_notes}</div>
                      </div>
                    )}
                  </div>
                  
                  <div className={styles.recallActions}>
                    <div className={styles.recallActionStatus}>
                      <span className={styles.recallActionLabel}>{t('remedied_for_vehicle')}:</span>
                      <span className={`${styles.recallActionValue} ${recall.is_remedied_for_vehicle ? styles.remedied : styles.notRemedied}`}>
                        {recall.is_remedied_for_vehicle ? t('yes') : t('no')}
                      </span>
                    </div>
                    
                    {recall.is_remedied_for_vehicle && (
                      <div className={styles.recallActionDate}>
                        <span className={styles.recallActionLabel}>{t('remedy_date_for_vehicle')}:</span>
                        <span className={styles.recallActionValue}>{formatDate(recall.remedy_date_for_vehicle)}</span>
                      </div>
                    )}
                    
                    {!recall.is_remedied_for_vehicle && (
                      <button className={styles.scheduleButton}>
                        {t('schedule_service')}
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      
      {closedRecalls.length > 0 && (
        <div className={styles.card}>
          <h3 className={styles.cardTitle}>{t('closed_recalls')}</h3>
          <div className={styles.cardContent}>
            <div className={styles.tableContainer}>
              <table className={styles.table}>
                <thead>
                  <tr>
                    <th>{t('component')}</th>
                    <th>{t('recall_date')}</th>
                    <th>{t('nhtsa_number')}</th>
                    <th>{t('description')}</th>
                    <th>{t('remedy_date')}</th>
                  </tr>
                </thead>
                <tbody>
                  {closedRecalls.map((recall) => (
                    <tr key={recall.recall_id}>
                      <td>{recall.recall_component}</td>
                      <td>{formatDate(recall.recall_date)}</td>
                      <td>{recall.nhtsa_campaign_number}</td>
                      <td>{recall.recall_description}</td>
                      <td>{formatDate(recall.remedy_date_for_vehicle)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
      
      {sortedRecalls.length === 0 && (
        <div className={styles.noRecalls}>
          <p>{t('no_recalls_found')}</p>
          <div className={styles.noRecallsDescription}>
            {t('no_recalls_description')}
          </div>
        </div>
      )}
    </div>
  );
};

export default VehicleRecalls;