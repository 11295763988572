// File: frontend/src/pages/SuperAdmin/components/CommunicationManagement/EmailTemplates/index.js

import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import SuperAdminTable from '../../common/SuperAdminTable';
import SuperAdminSearch from '../../common/SuperAdminSearch';
import SuperAdminPagination from '../../common/SuperAdminPagination';
import styles from './EmailTemplates.module.css';

const EmailTemplates = ({ templateId, isEditMode, isCreateMode, onViewChange }) => {
  const { t } = useLanguage();
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterCategory, setFilterCategory] = useState('all');
  
  // Form state for create/edit
  const [formData, setFormData] = useState({
    template_name: '',
    template_description: '',
    subject_line: '',
    body_html: '',
    body_text: '',
    template_category: 'general',
    template_status: 'active',
    variables: []
  });

  useEffect(() => {
    const fetchTemplates = async () => {
      setLoading(true);
      setError(null);
      
      try {
        // This would be replaced with an actual API call
        // const response = await communicationService.listEmailTemplates({
        //   page: currentPage,
        //   search: searchTerm,
        //   category: filterCategory !== 'all' ? filterCategory : undefined
        // });
        
        // Mock data for now
        await new Promise(resolve => setTimeout(resolve, 800));
        
        const mockTemplates = [
          {
            template_id: 'email-12345',
            template_name: 'Welcome Email',
            template_description: 'Email sent to new users upon registration',
            subject_line: 'Welcome to {{company_name}}!',
            body_html: '<h1>Welcome, {{user_name}}!</h1><p>Thank you for joining {{company_name}}. We\'re excited to have you on board.</p>',
            body_text: 'Welcome, {{user_name}}! Thank you for joining {{company_name}}. We\'re excited to have you on board.',
            variables: ['user_name', 'company_name'],
            template_category: 'onboarding',
            template_status: 'active',
            created_at: '2023-01-15T10:00:00Z',
            updated_at: '2023-01-15T10:00:00Z'
          },
          {
            template_id: 'email-23456',
            template_name: 'Password Reset',
            template_description: 'Email sent when a user requests a password reset',
            subject_line: 'Reset Your {{company_name}} Password',
            body_html: '<h1>Password Reset Request</h1><p>Hello {{user_name}},</p><p>Click the link below to reset your password:</p><p><a href="{{reset_link}}">Reset Password</a></p>',
            body_text: 'Hello {{user_name}}, Click the link below to reset your password: {{reset_link}}',
            variables: ['user_name', 'company_name', 'reset_link'],
            template_category: 'account',
            template_status: 'active',
            created_at: '2023-01-20T14:30:00Z',
            updated_at: '2023-01-20T14:30:00Z'
          },
          {
            template_id: 'email-34567',
            template_name: 'Subscription Renewal',
            template_description: 'Email sent before a subscription is about to renew',
            subject_line: 'Your {{company_name}} Subscription is Renewing Soon',
            body_html: '<h1>Subscription Renewal Notice</h1><p>Hello {{user_name}},</p><p>Your subscription will renew on {{renewal_date}}. The amount of {{renewal_amount}} will be charged to your payment method on file.</p>',
            body_text: 'Hello {{user_name}}, Your subscription will renew on {{renewal_date}}. The amount of {{renewal_amount}} will be charged to your payment method on file.',
            variables: ['user_name', 'company_name', 'renewal_date', 'renewal_amount'],
            template_category: 'billing',
            template_status: 'active',
            created_at: '2023-02-05T09:15:00Z',
            updated_at: '2023-02-05T09:15:00Z'
          },
          {
            template_id: 'email-45678',
            template_name: 'New Feature Announcement',
            template_description: 'Email announcing new features to users',
            subject_line: 'New Features Available in {{company_name}}',
            body_html: '<h1>Exciting New Features!</h1><p>Hello {{user_name}},</p><p>We\'re excited to announce the following new features:</p><ul>{{feature_list}}</ul>',
            body_text: 'Hello {{user_name}}, We\'re excited to announce the following new features: {{feature_list}}',
            variables: ['user_name', 'company_name', 'feature_list'],
            template_category: 'marketing',
            template_status: 'active',
            created_at: '2023-03-10T11:45:00Z',
            updated_at: '2023-03-10T11:45:00Z'
          },
          {
            template_id: 'email-56789',
            template_name: 'Account Deactivation',
            template_description: 'Email sent when an account is deactivated',
            subject_line: 'Your {{company_name}} Account Has Been Deactivated',
            body_html: '<h1>Account Deactivated</h1><p>Hello {{user_name}},</p><p>Your account has been deactivated. If you believe this is an error, please contact support.</p>',
            body_text: 'Hello {{user_name}}, Your account has been deactivated. If you believe this is an error, please contact support.',
            variables: ['user_name', 'company_name'],
            template_category: 'account',
            template_status: 'inactive',
            created_at: '2023-04-15T16:20:00Z',
            updated_at: '2023-04-15T16:20:00Z'
          }
        ];
        
        // Filter based on search term and category
        let filteredTemplates = mockTemplates;
        
        if (searchTerm) {
          const searchLower = searchTerm.toLowerCase();
          filteredTemplates = filteredTemplates.filter(template => 
            template.template_name.toLowerCase().includes(searchLower) ||
            template.template_description.toLowerCase().includes(searchLower) ||
            template.subject_line.toLowerCase().includes(searchLower)
          );
        }
        
        if (filterCategory !== 'all') {
          filteredTemplates = filteredTemplates.filter(template => 
            template.template_category === filterCategory
          );
        }
        
        setTemplates(filteredTemplates);
        setTotalPages(Math.ceil(filteredTemplates.length / 10)); // Assuming 10 items per page
        
        // If we have a templateId, find the selected template
        if (templateId) {
          const template = filteredTemplates.find(t => t.template_id === templateId);
          if (template) {
            setSelectedTemplate(template);
            
            // If in edit mode, initialize form data
            if (isEditMode) {
              setFormData({
                template_name: template.template_name,
                template_description: template.template_description,
                subject_line: template.subject_line,
                body_html: template.body_html,
                body_text: template.body_text,
                template_category: template.template_category,
                template_status: template.template_status,
                variables: template.variables
              });
            }
          } else {
            setError(t('template_not_found'));
          }
        }
        
        // If in create mode, initialize empty form
        if (isCreateMode) {
          setFormData({
            template_name: '',
            template_description: '',
            subject_line: '',
            body_html: '',
            body_text: '',
            template_category: 'general',
            template_status: 'active',
            variables: []
          });
        }
      } catch (err) {
        console.error('Error fetching email templates:', err);
        setError(t('failed_to_load_email_templates'));
      } finally {
        setLoading(false);
      }
    };
    
    fetchTemplates();
  }, [templateId, isEditMode, isCreateMode, currentPage, searchTerm, filterCategory, t]);

  const handleSearch = (term) => {
    setSearchTerm(term);
    setCurrentPage(1);
  };

  const handleCategoryFilterChange = (e) => {
    setFilterCategory(e.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    }).format(date);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleVariableChange = (e) => {
    const variableText = e.target.value;
    const variables = variableText.split(',')
      .map(v => v.trim())
      .filter(v => v !== '');
    
    setFormData(prev => ({
      ...prev,
      variables
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    try {
      if (isEditMode) {
        // This would be replaced with an actual API call
        // await communicationService.updateEmailTemplate(templateId, formData);
        
        // Mock update for now
        await new Promise(resolve => setTimeout(resolve, 800));
        
        // Return to detail view
        onViewChange('email', templateId);
      } else {
        // This would be replaced with an actual API call
        // const response = await communicationService.createEmailTemplate(formData);
        
        // Mock response for now
        await new Promise(resolve => setTimeout(resolve, 800));
        const newTemplateId = `email-${Math.random().toString(36).substring(2, 10)}`;
        
        // Return to list view
        onViewChange('email');
      }
    } catch (err) {
      console.error('Error saving email template:', err);
      setError(t('failed_to_save_email_template'));
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    if (!window.confirm(t('confirm_delete_template'))) return;
    
    setLoading(true);
    
    try {
      // This would be replaced with an actual API call
      // await communicationService.deleteEmailTemplate(templateId);
      
      // Mock deletion for now
      await new Promise(resolve => setTimeout(resolve, 800));
      
      // Return to list view
      onViewChange('email');
    } catch (err) {
      console.error('Error deleting email template:', err);
      setError(t('failed_to_delete_template'));
    } finally {
      setLoading(false);
    }
  };

  const getStatusBadgeClass = (status) => {
    switch (status) {
      case 'active':
        return styles.statusActive;
      case 'inactive':
        return styles.statusInactive;
      default:
        return '';
    }
  };

  const getCategoryBadgeClass = (category) => {
    switch (category) {
      case 'onboarding':
        return styles.categoryOnboarding;
      case 'account':
        return styles.categoryAccount;
      case 'billing':
        return styles.categoryBilling;
      case 'marketing':
        return styles.categoryMarketing;
      case 'general':
      default:
        return styles.categoryGeneral;
    }
  };

  const columns = [
    {
      header: t('template_name'),
      accessor: 'template_name',
      cell: (row) => <span className={styles.nameCell}>{row.template_name}</span>
    },
    {
      header: t('subject'),
      accessor: 'subject_line',
      cell: (row) => <span className={styles.subjectCell}>{row.subject_line}</span>
    },
    {
      header: t('category'),
      accessor: 'template_category',
      cell: (row) => (
        <span className={`${styles.categoryBadge} ${getCategoryBadgeClass(row.template_category)}`}>
          {row.template_category}
        </span>
      )
    },
    {
      header: t('status'),
      accessor: 'template_status',
      cell: (row) => (
        <span className={`${styles.statusBadge} ${getStatusBadgeClass(row.template_status)}`}>
          {row.template_status}
        </span>
      )
    },
    {
      header: t('updated_at'),
      accessor: 'updated_at',
      cell: (row) => <span>{formatDate(row.updated_at)}</span>
    },
    {
      header: t('actions'),
      accessor: 'actions',
      cell: (row) => (
        <div className={styles.actions}>
          <button 
            className={styles.actionButton}
            onClick={() => onViewChange('email', row.template_id)}
          >
            <i className="fas fa-eye"></i>
            <span>{t('view')}</span>
          </button>
          <button 
            className={styles.actionButton}
            onClick={() => onViewChange('email', row.template_id, 'edit')}
          >
            <i className="fas fa-edit"></i>
            <span>{t('edit')}</span>
          </button>
        </div>
      )
    }
  ];

  // Render template detail view
  if (templateId && !isEditMode && !isCreateMode && selectedTemplate) {
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <button 
            className={styles.backButton} 
            onClick={() => onViewChange('email')}
          >
            <i className="fas fa-arrow-left"></i>
            <span>{t('back')}</span>
          </button>
          
          <div className={styles.headerActions}>
            <button 
              className={styles.editButton}
              onClick={() => onViewChange('email', templateId, 'edit')}
            >
              <i className="fas fa-edit"></i>
              <span>{t('edit')}</span>
            </button>
            <button 
              className={styles.deleteButton}
              onClick={handleDelete}
            >
              <i className="fas fa-trash-alt"></i>
              <span>{t('delete')}</span>
            </button>
          </div>
        </div>
        
        {error && (
          <div className={styles.error}>
            <i className="fas fa-exclamation-circle"></i>
            <span>{error}</span>
          </div>
        )}
        
        <div className={styles.templateDetail}>
          <div className={styles.templateHeader}>
            <h2 className={styles.templateName}>{selectedTemplate.template_name}</h2>
            <div className={styles.templateMeta}>
              <span className={`${styles.categoryBadge} ${getCategoryBadgeClass(selectedTemplate.template_category)}`}>
                {selectedTemplate.template_category}
              </span>
              <span className={`${styles.statusBadge} ${getStatusBadgeClass(selectedTemplate.template_status)}`}>
                {selectedTemplate.template_status}
              </span>
            </div>
          </div>
          
          <div className={styles.templateDescription}>
            {selectedTemplate.template_description}
          </div>
          
          <div className={styles.section}>
            <h3 className={styles.sectionTitle}>{t('subject_line')}</h3>
            <div className={styles.subjectLine}>
              {selectedTemplate.subject_line}
            </div>
          </div>
          
          <div className={styles.section}>
            <h3 className={styles.sectionTitle}>{t('email_body_html')}</h3>
            <div className={styles.htmlPreview}>
              <div dangerouslySetInnerHTML={{ __html: selectedTemplate.body_html }} />
            </div>
          </div>
          
          <div className={styles.section}>
            <h3 className={styles.sectionTitle}>{t('email_body_text')}</h3>
            <div className={styles.textPreview}>
              {selectedTemplate.body_text}
            </div>
          </div>
          
          <div className={styles.section}>
            <h3 className={styles.sectionTitle}>{t('template_variables')}</h3>
            <div className={styles.variablesList}>
              {selectedTemplate.variables.map((variable) => (
                <span key={variable} className={styles.variableBadge}>
                  {`{{${variable}}}`}
                </span>
              ))}
            </div>
          </div>
          
          <div className={styles.section}>
            <h3 className={styles.sectionTitle}>{t('template_information')}</h3>
            <div className={styles.infoGrid}>
              <div className={styles.infoItem}>
                <div className={styles.infoLabel}>{t('template_id')}</div>
                <div className={styles.infoValue}>{selectedTemplate.template_id}</div>
              </div>
              <div className={styles.infoItem}>
                <div className={styles.infoLabel}>{t('created_at')}</div>
                <div className={styles.infoValue}>{formatDate(selectedTemplate.created_at)}</div>
              </div>
              <div className={styles.infoItem}>
                <div className={styles.infoLabel}>{t('updated_at')}</div>
                <div className={styles.infoValue}>{formatDate(selectedTemplate.updated_at)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Render create/edit form
  if (isEditMode || isCreateMode) {
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <button 
            className={styles.backButton} 
            onClick={() => isEditMode ? onViewChange('email', templateId) : onViewChange('email')}
            disabled={loading}
          >
            <i className="fas fa-arrow-left"></i>
            <span>{t('back')}</span>
          </button>
        </div>
        
        {error && (
          <div className={styles.error}>
            <i className="fas fa-exclamation-circle"></i>
            <span>{error}</span>
          </div>
        )}
        
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.formSection}>
            <h3 className={styles.sectionTitle}>{t('template_details')}</h3>
            <div className={styles.formGrid}>
              <div className={styles.formGroup}>
                <label htmlFor="template_name" className={styles.label}>
                  {t('template_name')} <span className={styles.required}>*</span>
                </label>
                <input
                  type="text"
                  id="template_name"
                  name="template_name"
                  className={styles.input}
                  value={formData.template_name}
                  onChange={handleChange}
                  required
                  disabled={loading}
                />
              </div>
              
              <div className={styles.formGroup}>
                <label htmlFor="template_category" className={styles.label}>
                  {t('category')} <span className={styles.required}>*</span>
                </label>
                <select
                  id="template_category"
                  name="template_category"
                  className={styles.select}
                  value={formData.template_category}
                  onChange={handleChange}
                  required
                  disabled={loading}
                >
                  <option value="general">{t('general')}</option>
                  <option value="onboarding">{t('onboarding')}</option>
                  <option value="account">{t('account')}</option>
                  <option value="billing">{t('billing')}</option>
                  <option value="marketing">{t('marketing')}</option>
                </select>
              </div>
              
              <div className={styles.formGroup}>
                <label htmlFor="template_status" className={styles.label}>
                  {t('status')} <span className={styles.required}>*</span>
                </label>
                <select
                  id="template_status"
                  name="template_status"
                  className={styles.select}
                  value={formData.template_status}
                  onChange={handleChange}
                  required
                  disabled={loading}
                >
                  <option value="active">{t('active')}</option>
                  <option value="inactive">{t('inactive')}</option>
                </select>
              </div>
              
              <div className={styles.formGroupFull}>
                <label htmlFor="template_description" className={styles.label}>
                  {t('description')} <span className={styles.required}>*</span>
                </label>
                <textarea
                  id="template_description"
                  name="template_description"
                  className={styles.textarea}
                  value={formData.template_description}
                  onChange={handleChange}
                  rows={2}
                  required
                  disabled={loading}
                ></textarea>
              </div>
            </div>
          </div>
          
          <div className={styles.formSection}>
            <h3 className={styles.sectionTitle}>{t('email_content')}</h3>
            <div className={styles.formGrid}>
              <div className={styles.formGroupFull}>
                <label htmlFor="subject_line" className={styles.label}>
                  {t('subject_line')} <span className={styles.required}>*</span>
                </label>
                <input
                  type="text"
                  id="subject_line"
                  name="subject_line"
                  className={styles.input}
                  value={formData.subject_line}
                  onChange={handleChange}
                  required
                  disabled={loading}
                />
              </div>
              
              <div className={styles.formGroupFull}>
                <label htmlFor="body_html" className={styles.label}>
                  {t('email_body_html')} <span className={styles.required}>*</span>
                </label>
                <textarea
                  id="body_html"
                  name="body_html"
                  className={styles.textarea}
                  value={formData.body_html}
                  onChange={handleChange}
                  rows={8}
                  required
                  disabled={loading}
                ></textarea>
              </div>
              
              <div className={styles.formGroupFull}>
                <label htmlFor="body_text" className={styles.label}>
                  {t('email_body_text')} <span className={styles.required}>*</span>
                </label>
                <textarea
                  id="body_text"
                  name="body_text"
                  className={styles.textarea}
                  value={formData.body_text}
                  onChange={handleChange}
                  rows={5}
                  required
                  disabled={loading}
                ></textarea>
              </div>
              
              <div className={styles.formGroupFull}>
                <label htmlFor="variables" className={styles.label}>
                  {t('template_variables')} <span className={styles.required}>*</span>
                </label>
                <input
                  type="text"
                  id="variables"
                  className={styles.input}
                  value={formData.variables.join(', ')}
                  onChange={handleVariableChange}
                  placeholder="user_name, company_name, etc."
                  required
                  disabled={loading}
                />
                <div className={styles.helpText}>
                  {t('variables_help_text')}
                </div>
              </div>
            </div>
          </div>
          
          <div className={styles.formActions}>
            <button 
              type="button" 
              className={styles.cancelButton} 
              onClick={() => isEditMode ? onViewChange('email', templateId) : onViewChange('email')}
              disabled={loading}
            >
              {t('cancel')}
            </button>
            <button 
              type="submit" 
              className={styles.submitButton}
              disabled={loading}
            >
              {loading ? (
                <>
                  <span className={styles.spinner}></span>
                  <span>{isEditMode ? t('updating') : t('creating')}</span>
                </>
              ) : (
                isEditMode ? t('update_template') : t('create_template')
              )}
            </button>
          </div>
        </form>
      </div>
    );
  }

  // Render template list view
  return (
    <div className={styles.container}>
      <div className={styles.toolbar}>
        <div className={styles.filters}>
          <SuperAdminSearch 
            placeholder={t('search_email_templates')}
            onSearch={handleSearch}
          />
          
          <div className={styles.filterGroup}>
            <label htmlFor="categoryFilter" className={styles.filterLabel}>
              {t('category')}:
            </label>
            <select
              id="categoryFilter"
              className={styles.filterSelect}
              value={filterCategory}
              onChange={handleCategoryFilterChange}
            >
              <option value="all">{t('all_categories')}</option>
              <option value="general">{t('general')}</option>
              <option value="onboarding">{t('onboarding')}</option>
              <option value="account">{t('account')}</option>
              <option value="billing">{t('billing')}</option>
              <option value="marketing">{t('marketing')}</option>
            </select>
          </div>
        </div>
        
        <button 
          className={styles.createButton}
          onClick={() => onViewChange('email', null, 'create')}
        >
          <i className="fas fa-plus"></i>
          <span>{t('create_template')}</span>
        </button>
      </div>
      
      {error && <div className={styles.error}>{error}</div>}
      
      <SuperAdminTable
        columns={columns}
        data={templates}
        loading={loading}
        emptyMessage={t('no_email_templates_found')}
      />
      
      <div className={styles.pagination}>
        <SuperAdminPagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default EmailTemplates;