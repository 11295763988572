// File: frontend/src/components/StepIndicator/StepIndicator.js

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames'; // Add classnames utility
import { useLanguage } from '../../contexts/LanguageContext';
import styles from './StepIndicator.module.css';

const StepIndicator = ({ steps, currentStep, className }) => {
  const { t } = useLanguage();

  return (
    <div 
      className={classNames(styles.stepSignupGroup, className)} 
      aria-label={t('step_indicator_label')}
      role="group"
    >
      {steps.map((step, index) => {
        const isActive = step.number === currentStep;
        const isCompleted = step.number < currentStep;

        return (
          <div
            key={index}
            className={classNames(styles.stepSignup, {
              [styles.active]: isActive,
              [styles.completed]: isCompleted
            })}
            aria-current={isActive ? 'step' : undefined}
          >
            <div className={styles.stepContent}>
              <div className={styles.badgeSignup}>
                {isCompleted ? (
                  <span className={styles.checkmark}>✓</span>
                ) : (
                  <div className={styles.badgeNumber}>{step.number}</div>
                )}
              </div>
              <div className={styles.stepLabel}>{step.label}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

StepIndicator.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      number: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  currentStep: PropTypes.number.isRequired,
  className: PropTypes.string,
};

export default React.memo(StepIndicator);