// File: frontend/src/pages/InvestorPitch/configs/InvestorSeriesAConfig.js

import React from 'react';
import * as Slides from '../slides';

// Recommended slide order with title/subtitle suggestions in comments
export const getInvestorSeriesASlides = (t) => [
  // 1. Lead with the big vision
  // title_text: "The Future of Insurance is Here"
  // subtitle_text: "Allegory AI-1: The world's first autonomously engineered Safe Super Intelligence Platform for Insurance"
  {
    id: 'intro',
    title: t('investor_pitch.slides.intro.title'),
    content: <Slides.IntroSlide />
  },
  
  // 2. Show the massive revenue opportunity
  // title_text: "$1,000 B Gross Written Premium in Ten Years"
  // subtitle_text: "Autonomous underwriting by Allegory AI-1 gives us an unfair advantage: generating $3.2 B in revenue in three years at one-fifth the cost of traditional insurers"
  {
    id: 'revenue-projections',
    title: t('investor_pitch.slides.revenue_projection.title'),
    content: <Slides.RevenueProjection />
  },
  
  // 3. Explain what you actually sell
  // title_text: "We Sell Insurance & Technology"
  // subtitle_text: "One platform, unlimited possibilities: Allegory AI-1 offers more capabilities than 18 companies with a combined market value of $50 B"
  {
    id: 'market-position',
    title: t('investor_pitch.slides.market_position.title'),
    content: <Slides.MarketPositionSlide />
  },
  
  // 4. Show the customer value proposition
  // title_text: "Saving Has No Language"
  // subtitle_text: "Everyone will buy from Allegory AI-1: we guarantee a 25% discount on every policy while ensuring profitable portfolios"
  {
    id: 'saving-has-no-language',
    title: t('investor_pitch.slides.saving_has_no_language.title'),
    content: <Slides.SavingHasNoLanguageSlide />
  },
  
  // 5. How we reach the market
  // title_text: "Insurance Will Never Be the Same Again"
  // subtitle_text: "Powered by Allegory AI-1, we offer better service, better prices, better profits, and higher conversion no matter where customers are"
  {
    id: 'go-to-market',
    title: t('investor_pitch.slides.go_to_market.title'),
    content: <Slides.GoToMarketSlide />
  },
  
  // 6. Data as competitive advantage
  // title_text: "4,000+ Data Points. One Brain."
  // subtitle_text: "Every insurance quote, policy, score, insight, and decision—powered by Allegory AI-1's real-time data engine"
  {
    id: 'obsessed-with-data',
    title: t('investor_pitch.slides.obsessed_with_data.title'),
    content: <Slides.ObsessedWithDataSlide />
  },
  
  // 7. Engineering prowess
  // title_text: "Allegory AI-1: An Engineering Marvel"
  // subtitle_text: "It would take 30 engineers, $25 M, and 5 years to reproduce our capabilities"
  {
    id: 'ai1-engineering',
    title: t('investor_pitch.slides.ai1_engineering.title'),
    content: <Slides.AI1Engineering />
  },
  
  // 8. Technical validation
  // title_text: "$25 M+ in Pure Software Value"
  // subtitle_text: "Built through simulation, powered by real-world data, and impossible to replicate by hand"
  {
    id: 'engineering-metrics',
    title: t('investor_pitch.slides.engineering_metrics.title'),
    content: <Slides.EngineeringMetrics />
  },
  
  // 9. Investment opportunity
  // title_text: "Once in a Lifetime Investment Opportunity"
  // subtitle_text: "Series A: $200 M with 100x return potential in 5 years"
  {
    id: 'investment-opportunity',
    title: t('investor_pitch.slides.investment_opportunity.title'),
    content: <Slides.InvestmentOpportunitySlide />
  },
  
  // 10. Leadership credibility
  // title_text: "Leadership & Vision"
  // subtitle_text: "Building an Allegorical Future of Insurance with proven industry expertise"
  {
    id: 'leadership',
    title: t('investor_pitch.slides.leadership.title'),
    content: <Slides.LeadershipSlide />
  },
  
  // 11. Future product roadmap
  // title_text: "Product Evolution"
  // subtitle_text: "From P&C to complete insurance intelligence: our roadmap to the trillion-dollar company"
  {
    id: 'product-evolution',
    title: t('investor_pitch.slides.product_evolution.title'),
    content: <Slides.ProductEvolutionSlide />
  },
  
  // NEW - go_to_market_execution
  {
    id: 'go-to-market-execution',
    title: t('investor_pitch.slides.go_to_market_execution.title'),
    content: <Slides.GoToMarketExecutionSlide />
  },
  
  // 16. Call to action
  // title_text: "Let's Build the World's First Trillion-Dollar Insurance Company"
  // subtitle_text: "Schedule a meeting to secure your allocation in the most transformative insurance opportunity of the century"
  {
    id: 'closing',
    title: t('investor_pitch.slides.closing.title'),
    content: <Slides.ClosingSlideInv />
  },
  
  // 17. Appendix with technical validation
  // title_text: "Appendix: The Deep Tech Behind Allegory's AI-1"
  // subtitle_text: "Public research validating the defensibility and scalability of our AI-driven approach"
  {
    id: 'research',
    title: t('investor_pitch.slides.research.title'),
    content: <Slides.ScientificArticlesSlide />
  },

  // 13. Security and compliance (important for enterprise)
  // title_text: "Security & Compliance"
  // subtitle_text: "Enterprise-grade security with global compliance standards"
  {
    id: 'security',
    title: t('investor_pitch.slides.security.title'),
    content: <Slides.SecuritySlide />
  },

  // 12. Market size and penetration
  // title_text: "Market Impact & Scaling"
  // subtitle_text: "Global reach with local expertise across 10 countries and 10 languages"
  // {
  //   id: 'market-impact',
  //   title: t('investor_pitch.slides.market_impact.title'),
  //   content: <Slides.MarketImpactSlide />
  // },
  
  // 14. Market entry specifics
  // title_text: "Market Entry Strategy"
  // subtitle_text: "The Cold Plunge Effect: Simultaneous multi-market entry with full feature set"
  // {
  //   id: 'market-entry',
  //   title: t('investor_pitch.slides.market_entry.title'),
  //   content: <Slides.MarketEntrySlide />
  // }, 
  
  // 15. Team building
  // title_text: "Global Hiring Strategy"
  // subtitle_text: "Building elite teams across key markets to support our rapid expansion"
  {
    id: 'hiring',
    title: t('investor_pitch.slides.hiring.title'),
    content: <Slides.HiringSlide />
  },
  {
    id: 'valuation-model',
    title: t('investor_pitch.slides.valuation_model.title'),
    content: <Slides.ValuationModelSlide />
  },
  {
    id: 'data-room',
    title: t('investor_pitch.slides.data_room.title'),
    content: <Slides.DataRoomSlide />
  },
  // {
  //   id: 'closing',
  //   title: t('investor_pitch.slides.closing.title'),
  //   content: <Slides.ClosingSlide />
  // }
];