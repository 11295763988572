// File: frontend/src/pages/UMS/UserPortfolio/components/PortfolioOverview/EngHeatMap.js

import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import Plot from 'react-plotly.js';
import styles from './PortfolioOverview.module.css';

const EngHeatMap = () => {
  const { t } = useLanguage();
  const [heatmapData, setHeatmapData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Simulating API call to fetch data
    setLoading(true);
    
    // Replace with actual API call
    setTimeout(() => {
      try {
        // Mock data - replace with actual data from your API
        const mockData = {
          chartAxisDetails: {
            x: ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5', 'Week 6', 'Week 7', 'Week 8'],
            y: ['Cohort 1', 'Cohort 2', 'Cohort 3', 'Cohort 4', 'Cohort 5', 'Cohort 6'],
            z: [
              [100, 90, 80, 70, 65, 60, 55, 50],
              [0, 100, 85, 75, 70, 65, 60, 55],
              [0, 0, 100, 80, 75, 70, 65, 60],
              [0, 0, 0, 100, 85, 75, 70, 65],
              [0, 0, 0, 0, 100, 90, 80, 70],
              [0, 0, 0, 0, 0, 100, 90, 80]
            ]
          }
        };
        
        setHeatmapData(mockData);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    }, 1000);
  }, []);

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <div className={styles.spinner}></div>
        <p>{t('loading_heatmap_data')}</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.errorContainer}>
        <i className="fas fa-exclamation-triangle"></i>
        <p>{t('error_loading_heatmap')}: {error}</p>
      </div>
    );
  }

  if (!heatmapData) {
    return (
      <div className={styles.noDataContainer}>
        <p>{t('no_heatmap_data_available')}</p>
      </div>
    );
  }

  const { x, y, z } = heatmapData.chartAxisDetails;
  
  const data = [
    {
      x,
      y,
      z,
      type: 'heatmap',
      colorscale: [
        [0.0, '#FBB1BD'],
        [0.1, '#FF99AC'],
        [0.2, '#FF99AC'],
        [0.3, '#FF85A1'],
        [0.4, '#FF85A1'],
        [0.5, '#FF7096'],
        [0.6, '#FF7096'],
        [0.7, '#FF477E'],
        [0.8, '#FF477E'],
        [0.9, '#FF0A54'],
        [1.0, '#FF0A54']
      ],
      showscale: true,
      colorbar: {
        title: t('retention_percentage'),
        titleside: 'right',
        thickness: 20
      }
    }
  ];

  const layout = {
    title: t('user_retention_by_cohort'),
    xaxis: { 
      title: t('weeks_since_signup'),
      autorange: true
    },  
    yaxis: { 
      title: t('user_cohorts'),
      autorange: true
    },
    margin: { t: 50, r: 50, l: 100, b: 100 },
    paper_bgcolor: 'rgba(0,0,0,0)',
    plot_bgcolor: 'rgba(0,0,0,0)',
    font: {
      family: 'var(--font-family)',
      size: 12,
      color: 'var(--text-primary)'
    }
  };

  return (
    <div className={styles.heatmapContainer}>
      <Plot
        data={data}
        layout={layout}
        style={{ width: '100%', height: '400px' }}
        config={{ responsive: true }}
      />
    </div>
  );
};

export default EngHeatMap;