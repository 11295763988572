// File: frontend/src/pages/SuperAdmin/components/ReportManagement/index.js

import React, { useState } from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import ReportList from './ReportList';
import ReportDetail from './ReportDetail';
import styles from './ReportManagement.module.css';

const ReportManagement = ({ updateBreadcrumbs }) => {
  const { t } = useLanguage();
  const [view, setView] = useState('list');
  const [selectedReportId, setSelectedReportId] = useState(null);
  const [isCreateMode, setIsCreateMode] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  // Handle view changes
  const handleViewChange = (newView, reportId = null) => {
    setView(newView);
    setSelectedReportId(reportId);
    setIsCreateMode(newView === 'create');
    setIsEditMode(newView === 'edit');
    
    // Update breadcrumbs based on view
    const baseBreadcrumbs = [
      { label: 'Dashboard', path: '/super-admin' },
      { label: 'Report Management', path: '/super-admin/reports' }
    ];
    
    switch (newView) {
      case 'detail':
        updateBreadcrumbs([
          ...baseBreadcrumbs,
          { label: 'Report Details', path: `/super-admin/reports/${reportId}` }
        ]);
        break;
      case 'create':
        updateBreadcrumbs([
          ...baseBreadcrumbs,
          { label: 'Create Report', path: '/super-admin/reports/create' }
        ]);
        break;
      case 'edit':
        updateBreadcrumbs([
          ...baseBreadcrumbs,
          { label: 'Edit Report', path: `/super-admin/reports/${reportId}/edit` }
        ]);
        break;
      default:
        updateBreadcrumbs(baseBreadcrumbs);
    }
  };

  // Handle successful creation or update
  const handleSuccess = (reportId) => {
    handleViewChange('detail', reportId);
  };

  // Render the appropriate view
  const renderView = () => {
    switch (view) {
      case 'detail':
        return (
          <ReportDetail 
            reportId={selectedReportId} 
            onBack={() => handleViewChange('list')}
            onEdit={() => handleViewChange('edit', selectedReportId)}
            isEditMode={false}
          />
        );
      case 'create':
        return (
          <ReportList 
            onBack={() => handleViewChange('list')}
            isCreateView={true}
            onSuccess={handleSuccess}
          />
        );
      case 'edit':
        return (
          <ReportDetail 
            reportId={selectedReportId} 
            onBack={() => handleViewChange('detail', selectedReportId)}
            isEditMode={true}
            onSuccess={() => handleSuccess(selectedReportId)}
          />
        );
      default:
        return (
          <ReportList 
            onViewDetail={(reportId) => handleViewChange('detail', reportId)}
            onCreateNew={() => handleViewChange('create')}
          />
        );
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>
        {view === 'list' && t('report_management')}
        {view === 'detail' && t('report_details')}
        {view === 'create' && t('create_report')}
        {view === 'edit' && t('edit_report')}
      </h1>
      
      {renderView()}
    </div>
  );
};

export default ReportManagement;