// File: frontend/src/pages/AutoInsight/VehicleDetail/components/VehicleUsage/index.js

import React from 'react';
import { useLanguage } from '../../../../../../contexts/LanguageContext';
import styles from './VehicleUsage.module.css';

// Helper function to format date
const formatDate = (dateString) => {
  if (!dateString) return 'N/A';
  
  const date = new Date(dateString);
  
  // Check if date is valid
  if (isNaN(date.getTime())) return 'Invalid Date';
  
  return date.toLocaleDateString();
};

const VehicleUsage = ({ vehicle }) => {
  const { t } = useLanguage();

  // Sort odometer readings by date (newest first)
  const sortedOdometerReadings = vehicle.odometer_readings 
    ? [...vehicle.odometer_readings].sort((a, b) => {
        return new Date(b.vehicle_odometer_timestamp) - new Date(a.vehicle_odometer_timestamp);
      })
    : [];

  // Get the latest odometer reading
  const latestOdometerReading = sortedOdometerReadings.length > 0 ? sortedOdometerReadings[0] : null;

  return (
    <div className={styles.container}>
      <div className={styles.grid}>
        <div className={styles.card}>
          <h3 className={styles.cardTitle}>{t('usage_information')}</h3>
          <div className={styles.cardContent}>
            {vehicle.usage ? (
              <div className={styles.usageInfo}>
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('primary_use_type')}:</div>
                  <div className={styles.infoValue}>{vehicle.usage.vehicle_primary_use_type || 'N/A'}</div>
                </div>
                
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('secondary_use_type')}:</div>
                  <div className={styles.infoValue}>{vehicle.usage.vehicle_secondary_use_type || 'N/A'}</div>
                </div>
                
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('measurement_unit')}:</div>
                  <div className={styles.infoValue}>{vehicle.usage.vehicle_use_measurement_unit || 'N/A'}</div>
                </div>
                
                <div className={styles.usageSection}>
                  <h4 className={styles.usageSectionTitle}>{t('daily_distance')}</h4>
                  <div className={styles.usageGrid}>
                    <div className={styles.usageItem}>
                      <div className={styles.usageLabel}>{t('personal')}:</div>
                      <div className={styles.usageValue}>
                        {vehicle.usage.vehicle_personal_use_daily_distance !== null
                          ? `${vehicle.usage.vehicle_personal_use_daily_distance} ${vehicle.usage.vehicle_use_measurement_unit}`
                          : 'N/A'}
                      </div>
                    </div>
                    
                    <div className={styles.usageItem}>
                      <div className={styles.usageLabel}>{t('business')}:</div>
                      <div className={styles.usageValue}>
                        {vehicle.usage.vehicle_business_use_daily_distance !== null
                          ? `${vehicle.usage.vehicle_business_use_daily_distance} ${vehicle.usage.vehicle_use_measurement_unit}`
                          : 'N/A'}
                      </div>
                    </div>
                    
                    <div className={styles.usageItem}>
                      <div className={styles.usageLabel}>{t('commercial')}:</div>
                      <div className={styles.usageValue}>
                        {vehicle.usage.vehicle_commercial_use_daily_distance !== null
                          ? `${vehicle.usage.vehicle_commercial_use_daily_distance} ${vehicle.usage.vehicle_use_measurement_unit}`
                          : 'N/A'}
                      </div>
                    </div>
                    
                    <div className={styles.usageItem}>
                      <div className={styles.usageLabel}>{t('farm')}:</div>
                      <div className={styles.usageValue}>
                        {vehicle.usage.vehicle_farm_use_daily_distance !== null
                          ? `${vehicle.usage.vehicle_farm_use_daily_distance} ${vehicle.usage.vehicle_use_measurement_unit}`
                          : 'N/A'}
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className={styles.usageSection}>
                  <h4 className={styles.usageSectionTitle}>{t('monthly_distance')}</h4>
                  <div className={styles.usageGrid}>
                    <div className={styles.usageItem}>
                      <div className={styles.usageLabel}>{t('personal')}:</div>
                      <div className={styles.usageValue}>
                        {vehicle.usage.vehicle_personal_use_monthly_distance !== null
                          ? `${vehicle.usage.vehicle_personal_use_monthly_distance} ${vehicle.usage.vehicle_use_measurement_unit}`
                          : 'N/A'}
                      </div>
                    </div>
                    
                    <div className={styles.usageItem}>
                      <div className={styles.usageLabel}>{t('business')}:</div>
                      <div className={styles.usageValue}>
                        {vehicle.usage.vehicle_business_use_monthly_distance !== null
                          ? `${vehicle.usage.vehicle_business_use_monthly_distance} ${vehicle.usage.vehicle_use_measurement_unit}`
                          : 'N/A'}
                      </div>
                    </div>
                    
                    <div className={styles.usageItem}>
                      <div className={styles.usageLabel}>{t('commercial')}:</div>
                      <div className={styles.usageValue}>
                        {vehicle.usage.vehicle_commercial_use_monthly_distance !== null
                          ? `${vehicle.usage.vehicle_commercial_use_monthly_distance} ${vehicle.usage.vehicle_use_measurement_unit}`
                          : 'N/A'}
                      </div>
                    </div>
                    
                    <div className={styles.usageItem}>
                      <div className={styles.usageLabel}>{t('farm')}:</div>
                      <div className={styles.usageValue}>
                        {vehicle.usage.vehicle_farm_use_monthly_distance !== null
                          ? `${vehicle.usage.vehicle_farm_use_monthly_distance} ${vehicle.usage.vehicle_use_measurement_unit}`
                          : 'N/A'}
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className={styles.usageSection}>
                  <h4 className={styles.usageSectionTitle}>{t('annual_distance')}</h4>
                  <div className={styles.usageGrid}>
                    <div className={styles.usageItem}>
                      <div className={styles.usageLabel}>{t('personal')}:</div>
                      <div className={styles.usageValue}>
                        {vehicle.usage.vehicle_personal_use_annual_distance !== null
                          ? `${vehicle.usage.vehicle_personal_use_annual_distance} ${vehicle.usage.vehicle_use_measurement_unit}`
                          : 'N/A'}
                      </div>
                    </div>
                    
                    <div className={styles.usageItem}>
                      <div className={styles.usageLabel}>{t('business')}:</div>
                      <div className={styles.usageValue}>
                        {vehicle.usage.vehicle_business_use_annual_distance !== null
                          ? `${vehicle.usage.vehicle_business_use_annual_distance} ${vehicle.usage.vehicle_use_measurement_unit}`
                          : 'N/A'}
                      </div>
                    </div>
                    
                    <div className={styles.usageItem}>
                      <div className={styles.usageLabel}>{t('commercial')}:</div>
                      <div className={styles.usageValue}>
                        {vehicle.usage.vehicle_commercial_use_annual_distance !== null
                          ? `${vehicle.usage.vehicle_commercial_use_annual_distance} ${vehicle.usage.vehicle_use_measurement_unit}`
                          : 'N/A'}
                      </div>
                    </div>
                    
                    <div className={styles.usageItem}>
                      <div className={styles.usageLabel}>{t('farm')}:</div>
                      <div className={styles.usageValue}>
                        {vehicle.usage.vehicle_farm_use_annual_distance !== null
                          ? `${vehicle.usage.vehicle_farm_use_annual_distance} ${vehicle.usage.vehicle_use_measurement_unit}`
                          : 'N/A'}
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className={styles.usageSection}>
                  <h4 className={styles.usageSectionTitle}>{t('annual_percentage')}</h4>
                  <div className={styles.usageGrid}>
                    <div className={styles.usageItem}>
                      <div className={styles.usageLabel}>{t('personal')}:</div>
                      <div className={styles.usageValue}>
                        {vehicle.usage.vehicle_personal_use_annual_distance_percentage !== null
                          ? `${vehicle.usage.vehicle_personal_use_annual_distance_percentage}%`
                          : 'N/A'}
                      </div>
                    </div>
                    
                    <div className={styles.usageItem}>
                      <div className={styles.usageLabel}>{t('business')}:</div>
                      <div className={styles.usageValue}>
                        {vehicle.usage.vehicle_business_use_annual_distance_percentage !== null
                          ? `${vehicle.usage.vehicle_business_use_annual_distance_percentage}%`
                          : 'N/A'}
                      </div>
                    </div>
                    
                    <div className={styles.usageItem}>
                      <div className={styles.usageLabel}>{t('commercial')}:</div>
                      <div className={styles.usageValue}>
                        {vehicle.usage.vehicle_commercial_use_annual_distance_percentage !== null
                          ? `${vehicle.usage.vehicle_commercial_use_annual_distance_percentage}%`
                          : 'N/A'}
                      </div>
                    </div>
                    
                    <div className={styles.usageItem}>
                      <div className={styles.usageLabel}>{t('farm')}:</div>
                      <div className={styles.usageValue}>
                        {vehicle.usage.vehicle_farm_use_annual_distance_percentage !== null
                          ? `${vehicle.usage.vehicle_farm_use_annual_distance_percentage}%`
                          : 'N/A'}
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('last_updated')}:</div>
                  <div className={styles.infoValue}>{formatDate(vehicle.usage.updated_at)}</div>
                </div>
              </div>
            ) : (
              <div className={styles.noData}>{t('no_usage_information_available')}</div>
            )}
          </div>
        </div>
        
        <div className={styles.card}>
          <h3 className={styles.cardTitle}>{t('current_odometer')}</h3>
          <div className={styles.cardContent}>
            {latestOdometerReading ? (
              <div className={styles.odometerCurrent}>
                <div className={styles.odometerValue}>
                  {latestOdometerReading.vehicle_odometer_value} 
                  <span className={styles.odometerUnit}>
                    {latestOdometerReading.vehicle_odometer_measurement_unit}
                  </span>
                </div>
                
                <div className={styles.odometerDetails}>
                  <div className={styles.odometerDetail}>
                    <span className={styles.odometerDetailLabel}>{t('as_of')}:</span>
                    <span className={styles.odometerDetailValue}>
                      {formatDate(latestOdometerReading.vehicle_odometer_timestamp)}
                    </span>
                  </div>
                  
                  <div className={styles.odometerDetail}>
                    <span className={styles.odometerDetailLabel}>{t('source')}:</span>
                    <span className={styles.odometerDetailValue}>
                      {latestOdometerReading.vehicle_odometer_source}
                    </span>
                  </div>
                  
                  <div className={styles.odometerDetail}>
                    <span className={styles.odometerDetailLabel}>{t('confidence')}:</span>
                    <span className={styles.odometerDetailValue}>
                      {latestOdometerReading.vehicle_odometer_source_confidence_level}%
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.noData}>{t('no_odometer_readings_available')}</div>
            )}
          </div>
        </div>
      </div>
      
      <div className={styles.card}>
        <h3 className={styles.cardTitle}>{t('odometer_history')}</h3>
        <div className={styles.cardContent}>
          {sortedOdometerReadings.length > 0 ? (
            <div className={styles.tableContainer}>
              <table className={styles.table}>
                <thead>
                  <tr>
                    <th>{t('date')}</th>
                    <th>{t('reading')}</th>
                    <th>{t('source')}</th>
                    <th>{t('confidence')}</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedOdometerReadings.map((reading) => (
                    <tr key={reading.vehicle_odometer_id}>
                      <td>{formatDate(reading.vehicle_odometer_timestamp)}</td>
                      <td>
                        {reading.vehicle_odometer_value} {reading.vehicle_odometer_measurement_unit}
                      </td>
                      <td>{reading.vehicle_odometer_source}</td>
                      <td>{reading.vehicle_odometer_source_confidence_level}%</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className={styles.noData}>{t('no_odometer_history_available')}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VehicleUsage;