// File: frontend/src/services/api.js
// *********************************************************************************************************
// Key Changes Made Recently:
// - Added custom APIError class for better error handling
// - Integrated with tokenService for token management
// - Added request ID generation for tracking
// - Improved error categorization and handling
// - Added detailed error responses based on HTTP status codes
// - Added central error handling function
// - Improved type safety and error consistency
// - Added request/response logging capabilities
// Testing 3
// *********************************************************************************************************

import axios from 'axios';
import { API_URL, API_KEY, API_ENDPOINTS, RETRY_CONFIG } from '../config/constants';
import { tokenService } from './tokenService';


// Custom error class for API errors
export class APIError extends Error {
  constructor(status, message, code, details = null) {
    super(message);
    this.name = 'APIError';
    this.status = status;
    this.code = code;
    this.details = details;
    this.timestamp = new Date().toISOString();
    this.requestId = null;
  }

  static fromResponse(error) {
    // If there's no response (network error, 404, etc.)
    if (!error.response) {
      return new APIError(
        error.status || 404, // Default to 404 if no status
        error.message || 'Network error',
        'NETWORK_ERROR',
        {
          originalError: error,
          type: 'network'
        }
      );
    }

    const { status, data, config } = error.response;

    // Create error instance with proper data
    const errorInstance = new APIError(
      status,
      data?.error || data?.message || error.message || 'An error occurred',
      data?.code || `HTTP_${status}`,
      {
        details: data?.details || null,
        errors: data?.errors || null,
        reason: data?.reason || null,
        retryAfter: data?.retryAfter || null,
        type: 'api'
      }
    );

    // Add request context if available
    if (config) {
      errorInstance.requestId = config.headers?.['X-Request-ID'];
      errorInstance.endpoint = config.url;
      errorInstance.method = config.method?.toUpperCase();
    }

    return errorInstance;
  }

  // Helper methods for error type checking
  isValidationError() {
    return this.status === 400 && Array.isArray(this.details?.errors);
  }

  isAuthenticationError() {
    return this.status === 401;
  }

  isAuthorizationError() {
    return this.status === 403;
  }

  isNotFoundError() {
    return this.status === 404;
  }

  isRateLimitError() {
    return this.status === 429;
  }

  isServerError() {
    return this.status >= 500;
  }

  // Get retry after time for rate limiting
  getRetryAfter() {
    return this.details?.retryAfter || null;
  }

  // Get validation errors if any
  getValidationErrors() {
    return this.details?.errors || [];
  }

  // Format error for logging
  toLog() {
    return {
      status: this.status,
      code: this.code,
      message: this.message,
      details: this.details,
      requestId: this.requestId,
      endpoint: this.endpoint,
      method: this.method,
      timestamp: this.timestamp
    };
  }
}

const api = axios.create({
  baseURL: API_URL,
  timeout: 30000,
  // withCredentials: true,
  headers: {
    // 'Content-Type': 'application/json',
    'Accept': 'application/json',
    'x-api-key': API_KEY
    // 'Authorization': 'Bearer token'
  }
});

// Helper function to detect if payload contains file data
const containsFileData = (data) => {
  return data && (
    (data.resume && data.resume.content) || 
    (data.coverLetter && data.coverLetter.content)
  );
};

const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

// Request interceptor
api.interceptors.request.use(
  (config) => {
    // Generate request ID
    config.headers['X-Request-ID'] = generateRequestId();

    // Set default content type if not already set
    if (!config.headers['Content-Type']) {
      config.headers['Content-Type'] = 'application/json';
    }
    
    // Only add Authorization header for non-auth endpoints
    const isAuthEndpoint = config.url.includes('/auth/');
    const tokens = tokenService.getTokens();

    if (!isAuthEndpoint && tokens.id_token) {
      config.headers.Authorization = `Bearer ${tokens.id_token}`;
    }
    
    return config;
  },
  (error) => {
    return Promise.reject(new APIError(
      'Request configuration error',
      'REQUEST_ERROR',
      error
    ));
  }
);

// Response interceptor
api.interceptors.response.use(
  response => response.data,
  error => {
    console.log('API Error Interceptor:', error);

    // If it's already an APIError instance, just throw it
    if (error instanceof APIError) {
      throw error;
    }

    // Handle network errors or non-response errors
    if (!error.response) {
      if (error.message === 'Network Error') {
        throw new APIError(
          0,
          'Unable to connect to server',
          'NETWORK_ERROR',
          { type: 'network' }
        );
      }
      
      // Handle timeout
      if (error.code === 'ECONNABORTED') {
        throw new APIError(
          408,
          'Request timeout',
          'TIMEOUT_ERROR',
          { type: 'timeout' }
        );
      }
    }

    // Handle normal API errors
    throw APIError.fromResponse(error);
  }
);

// Helper function to generate request ID
const generateRequestId = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = Math.random() * 16 | 0;
    const v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
};

// API service methods with improved error handling
export const apiService = {
  get: async (url, config = {}) => {
    try {
      return await api.get(url, config);
    } catch (error) {
      handleApiError(error);
    }
  },

  post: async (url, data = {}, config = {}) => {
    try {
      // Add validation for required fields based on endpoint
      if (url === API_ENDPOINTS.AGENTS.CHAT.INITIATE) {
        if (!data.template_id) {
          throw new APIError(
            'Template ID is required',
            'VALIDATION_ERROR',
            { field: 'template_id' }
          );
        }
      }
  
      console.log('API Request:', {
        url: API_URL + url,
        data,
        headers: {
          ...api.defaults.headers,
          ...config.headers
        }
      });
      
      const response = await api.post(url, data, {
        ...config,
        headers: {
          ...config.headers,
          'Content-Type': 'application/json'
        }
      });
      
      // console.log('API Response:', response);
      return response;
    } catch (error) {
      handleApiError(error);
    }
  },

  put: async (url, data = {}, config = {}) => {
    try {
      return await api.put(url, data, config);
    } catch (error) {
      handleApiError(error);
    }
  },

  delete: async (url, config = {}) => {
    try {
      return await api.delete(url, config);
    } catch (error) {
      handleApiError(error);
    }
  }
};

// Central error handling function
const handleApiError = (error) => {
  // If it's not already an APIError, convert it
  const apiError = error instanceof APIError ? error : APIError.fromResponse(error);
  
  console.error('API Error:', {
    message: apiError.message,
    code: apiError.code,
    details: apiError.details
  });

  throw apiError;
};

export default apiService;