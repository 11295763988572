// File: frontend/src/components/Dashboard/WelcomeCard.js

import React from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import { Link } from 'react-router-dom';
import { useRoleBasedAccess } from '../../contexts/RoleBasedAccessContext';
import styles from './WelcomeCard.module.css';

// Import icons from react-icons
import { 
  // Main section icons
  FiTool, 
  FiShoppingBag, 
  FiRefreshCw,
  // Management section icons
  FiUsers, 
  FiSettings, 
  FiPieChart,
  // Admin section icons
  FiShield, 
  FiCpu, 
  FiBarChart2,
  // Item icons - can use different sets for variety
  FiMapPin, 
  FiArrowRight,
  FiDatabase,
  FiClipboard,
  FiFileText,
  FiTarget,
  FiDollarSign,
  FiTruck,
  FiActivity,
  FiPaperclip,
  FiMessageSquare,
  FiMonitor,
  FiCode,
  FiLock,
  FiAward,
  FiHelpCircle,
  FiMessageCircle,
  FiBookOpen,
  FiNavigation,
  FiTrendingUp,
  FiZap
} from 'react-icons/fi';

const WelcomeCard = () => {
  const { t } = useLanguage();
  const { hasRole } = useRoleBasedAccess();
  
  // Main sections with added descriptions and item descriptions
  const mainSections = [
    {
      title: 'build_insurance_products_title',
      description: 'build_insurance_products_desc',
      icon: <FiTool size={20} />,
      items: [
        { 
          name: 'embedded_insurance', 
          description: 'embedded_insurance_desc',
          link: '/embedded-insurance', 
          icon: <FiDatabase size={14} /> 
        },
        { 
          name: 'usage_based_insurance', 
          description: 'usage_based_insurance_desc',
          link: '/mile-monitor', 
          icon: <FiActivity size={14} /> 
        },
        { 
          name: 'location_based_insurance', 
          description: 'location_based_insurance_desc',
          link: '/route-safe', 
          icon: <FiMapPin size={14} /> 
        },
      ],
    },
    {
      title: 'new_business_title',
      description: 'new_business_desc',
      icon: <FiShoppingBag size={20} />,
      items: [
        { 
          name: 'property_risk_analysis', 
          description: 'property_risk_analysis_desc',
          link: '/property-insight', 
          icon: <FiTruck size={14} /> 
        },
        { 
          name: 'vehicle_risk_analysis', 
          description: 'vehicle_risk_analysis_desc',
          link: '/auto-insight', 
          icon: <FiTruck size={14} /> 
        },
        { 
          name: 'risk_based_targeting', 
          description: 'risk_based_targeting_desc',
          link: '/driving-score', 
          icon: <FiTarget size={14} /> 
        },
      ],
    },
    {
      title: 'renewal_title',
      description: 'renewal_desc',
      icon: <FiRefreshCw size={20} />,
      items: [
        { 
          name: 'cross_sell_upsell', 
          description: 'cross_sell_upsell_desc',
          link: '/ai1', 
          icon: <FiTrendingUp size={14} /> 
        },
        { 
          name: 'exposure_portfolio_analysis', 
          description: 'exposure_portfolio_analysis_desc',
          link: '/cave', 
          icon: <FiPieChart size={14} /> 
        },
        { 
          name: 'trip_tracking', 
          description: 'trip_tracking_desc',
          link: '/trips', 
          icon: <FiNavigation size={14} /> 
        },
      ],
    },
  ];
  
  // Management systems with added descriptions and item descriptions
  const managementSections = [
    {
      title: 'customer_management_title',
      description: 'customer_management_desc',
      icon: <FiUsers size={20} />,
      items: [
        { 
          name: 'users_ums', 
          description: 'users_ums_desc',
          link: '/ums', 
          icon: <FiUsers size={14} /> 
        },
        { 
          name: 'quotes_qms', 
          description: 'quotes_qms_desc',
          link: '/qms', 
          icon: <FiClipboard size={14} /> 
        },
        { 
          name: 'customers_cms', 
          description: 'customers_cms_desc',
          link: '/cms', 
          icon: <FiMessageSquare size={14} /> 
        },
      ],
    },
    {
      title: 'operations_management_title',
      description: 'operations_management_desc',
      icon: <FiSettings size={20} />,
      items: [
        { 
          name: 'ups', 
          description: 'underwriting_pricing_desc',
          link: '/ups',
          icon: <FiDollarSign size={14} /> 
        },
        { 
          name: 'policies_pms', 
          description: 'policies_pms_desc',
          link: '/pms', 
          icon: <FiFileText size={14} /> 
        },
        { 
          name: 'claims_fraud_lms', 
          description: 'claims_fraud_lms_desc',
          link: '/lms', 
          icon: <FiShield size={14} /> 
        },
        { 
          name: 'documents_dms', 
          description: 'documents_dms_desc',
          link: '/dms', 
          icon: <FiPaperclip size={14} /> 
        },
      ],
    },
    {
      title: 'business_intelligence_title',
      description: 'business_intelligence_desc',
      icon: <FiPieChart size={20} />,
      items: [
        { 
          name: 'treasury_tms', 
          description: 'treasury_tms_desc',
          link: '/tms', 
          icon: <FiDollarSign size={14} /> 
        },
        { 
          name: 'reporting_rms', 
          description: 'reporting_rms_desc',
          link: '/rms', 
          icon: <FiBarChart2 size={14} /> 
        },
        { 
          name: 'marketing_mms', 
          description: 'marketing_mms_desc',
          link: '/mms', 
          icon: <FiTarget size={14} /> 
        },
      ],
    },
  ];

  // Super admin tools with added descriptions and item descriptions
  const superAdminSections = [
    {
      title: 'admin_tools_title',
      description: 'admin_tools_desc',
      icon: <FiShield size={20} />,
      items: [
        { 
          name: 'source_code', 
          description: 'source_code_desc',
          link: '/source-code', 
          icon: <FiCode size={14} /> 
        },
        { 
          name: 'admin_panel', 
          description: 'admin_panel_desc',
          link: '/super-admin-panel', 
          icon: <FiMonitor size={14} /> 
        },
        { 
          name: 'plato', 
          description: 'plato_desc',
          link: '/plato', 
          icon: <FiBookOpen size={14} /> 
        },
      ],
    },
    {
      title: 'ai_tools_title',
      description: 'ai_tools_desc',
      icon: <FiCpu size={20} />,
      items: [
        { 
          name: 'ai_assistant', 
          description: 'ai_assistant_desc',
          link: '/ai1', 
          icon: <FiHelpCircle size={14} /> 
        },
        { 
          name: 'chat_history', 
          description: 'chat_history_desc',
          link: '/ai1/chat', 
          icon: <FiMessageCircle size={14} /> 
        },
        { 
          name: 'socrates', 
          description: 'socrates_desc',
          link: '/socrates', 
          icon: <FiAward size={14} /> 
        },
      ],
    },
    {
      title: 'advanced_analytics_title',
      description: 'advanced_analytics_desc',
      icon: <FiBarChart2 size={20} />,
      items: [
        { 
          name: 'driving_score', 
          description: 'driving_score_desc',
          link: '/driving-score', 
          icon: <FiActivity size={14} /> 
        },
        { 
          name: 'mile_monitor', 
          description: 'mile_monitor_desc',
          link: '/mile-monitor', 
          icon: <FiTruck size={14} /> 
        },
        { 
          name: 'auto_insight', 
          description: 'auto_insight_desc',
          link: '/auto-insight', 
          icon: <FiZap size={14} /> 
        },
      ],
    },
  ];

  // Determine which sections to display based on user role
  let displaySections = [...mainSections];
  
  if (hasRole('manager')) {
    displaySections = [...mainSections, ...managementSections];
  }
  
  if (hasRole('super_admin')) {
    displaySections = [...mainSections, ...managementSections, ...superAdminSections];
  }

  return (
    <div className={styles.welcomeCard}>
      <h2 className={styles.welcomeTitle}>{t('welcome_message')}</h2>
      <p className={styles.welcomeSubtitle}>{t('help_message')}</p>
      <div className={styles.cardContent}>
        {displaySections.map((section, sectionIndex) => (
          <div 
            key={sectionIndex} 
            className={styles.section}
            data-section={section.title}
          >
            <div className={styles.vectorIcon} />
            <h3 className={styles.sectionTitle}>
              {section.icon}
              {t(section.title)}
            </h3>
            {section.description && (
              <p className={styles.sectionDescription}>
                {t(section.description)}
              </p>
            )}
            <div className={styles.sectionItems}>
              {section.items.map((item, itemIndex) => (
                <Link 
                  key={itemIndex} 
                  to={item.link} 
                  className={styles.sectionItem}
                >
                  <div className={styles.dotIcon} />
                  <div className={styles.sectionItemContent}>
                    <div className={styles.sectionItemTextContainer}>
                      <span className={styles.sectionItemText}>
                        {t(item.name)}
                      </span>
                      {item.description && (
                        <span className={styles.sectionItemDescription}>
                          {t(item.description)}
                        </span>
                      )}
                    </div>
                    <div className={styles.sectionItemIcon}>
                      <FiArrowRight size={14} />
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WelcomeCard;