// File: frontend/src/pages/Plato/Plato.js

import React, { useState, useEffect } from 'react';
import PageTitle from '../../components/layout/DashboardLayout/PageTitle';
import InputContainer from '../../components/Plato/InputContainer';
import ParameterSelector from '../../components/Plato/ParameterSelector';
import TemplateManager from '../../components/Plato/TemplateManager';
import RecentKnowledgeTable from '../../components/Plato/RecentKnowledgeTable';
import EditModal from '../../components/Plato/EditModal';
// import { processWithAI } from '../../services/aiService';
// import { saveToS3, listS3Objects, getFromS3 } from '../../services/s3Service';
import { useAuth } from '../../contexts/AuthContextV2';
import { useLanguage } from '../../contexts/LanguageContext';
import styles from './Plato.module.css';
import platoConfig from '../../config/platoConfig.json';

const Plato = () => {
  const { user } = useAuth();
  const { t } = useLanguage();
  const [outputText, setOutputText] = useState('');
  const [title, setTitle] = useState('');
  const [summary, setSummary] = useState('');
  const [recentKnowledge, setRecentKnowledge] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  
  const [parameters, setParameters] = useState(() => {
    const initialParameters = {};
    Object.entries(platoConfig.parameters).forEach(([key, config]) => {
      initialParameters[key] = config.type === 'object' ? {} : config.default;
    });
    return initialParameters;
  });
  const [templates, setTemplates] = useState(platoConfig.templates);

  const handleParameterChange = (paramName, value) => {
    setParameters(prev => ({ ...prev, [paramName]: value }));
  };

  const handleTemplateSelect = (templateIndex) => {
    const selectedTemplate = templates[templateIndex];
    setParameters(selectedTemplate.parameters);
  };

  const handleTemplateSave = () => {
    // Implement logic to save current parameters as a new template
  };

  // useEffect(() => {
  //   fetchRecentKnowledge();
  // }, []);

  // const fetchRecentKnowledge = async () => {
  //   try {
  //     const objects = await listS3Objects('knowledge/');
  //     const knowledgeItems = await Promise.all(
  //       objects.map(async (obj) => {
  //         const data = await getFromS3(obj.Key);
  //         return {
  //           id: obj.Key,
  //           ...data,
  //           createdAt: obj.LastModified,
  //           editedAt: obj.LastModified,
  //           bucket: 'knowledge',
  //           bucketUrl: `https://${process.env.S3_PLATO}.s3.${process.env.AWS_REGION_CANADA_CENTRAL_1}.amazonaws.com/${obj.Key}`
  //         };
  //       })
  //     );
  //     setRecentKnowledge(knowledgeItems);
  //   } catch (error) {
  //     console.error('Error fetching recent knowledge:', error);
  //     setError('Failed to fetch recent knowledge. Please try again.');
  //   }
  // };

  // const handleSubmit = async ({ inputText, selectedModel }) => {
  //   setIsLoading(true);
  //   setError(null);
  //   try {
  //     const processedData = await processWithAI(inputText, selectedModel, parameters);
  //     setOutputText(processedData.output);
  //     setTitle(processedData.title);
  //     setSummary(processedData.summary);
  //   } catch (error) {
  //     console.error('Error processing knowledge:', error);
  //     setError('An error occurred while processing your input. Please try again.');
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const processFileContent = async (file) => {
    // Implement file processing logic here based on file type
    const text = await file.text();
    return text;
  };

  // const handleSave = async () => {
  //   try {
  //     const key = `knowledge/${Date.now()}_${title.replace(/\s+/g, '_')}.json`;
  //     const data = { title, summary, content: outputText };
  //     await saveToS3(key, data);
  //     await fetchRecentKnowledge();
  //     setError('Saved successfully!');
  //     setTimeout(() => setError(null), 3000);
  //   } catch (error) {
  //     console.error('Error saving to S3:', error);
  //     setError('Failed to save to S3. Please try again.');
  //   }
  // };

  const handleClear = () => {
    setOutputText('');
    setTitle('');
    setSummary('');
  };

  // const handlePublish = async (id) => {
  //   try {
  //     const data = await getFromS3(id);
  //     const publishedKey = id.replace('knowledge/', 'published/');
  //     await saveToS3(publishedKey, data);
  //     await fetchRecentKnowledge();
  //     setError('Published successfully!');
  //     setTimeout(() => setError(null), 3000);
  //   } catch (error) {
  //     console.error(`Error publishing item ${id}:`, error);
  //     setError('Failed to publish. Please try again.');
  //   }
  // };

  // const handleSaveAsDraft = async (id) => {
  //   try {
  //     const data = await getFromS3(id);
  //     const draftKey = id.replace('knowledge/', 'drafts/');
  //     await saveToS3(draftKey, data);
  //     await fetchRecentKnowledge();
  //     setError('Saved as draft successfully!');
  //     setTimeout(() => setError(null), 3000);
  //   } catch (error) {
  //     console.error(`Error saving item ${id} as draft:`, error);
  //     setError('Failed to save as draft. Please try again.');
  //   }
  // };

  const handleDelete = async (id) => {
    // Implement S3 delete functionality here
    setRecentKnowledge(prev => prev.filter(item => item.id !== id));
  };

  const handleEdit = (item) => {
    setEditingItem(item);
    setIsEditModalOpen(true);
  };

  // const handleSaveEdit = async (editedData) => {
  //   try {
  //     await saveToS3(editingItem.id, {
  //       ...editedData,
  //       editedAt: new Date().toISOString()
  //     });
  //     setIsEditModalOpen(false);
  //     setEditingItem(null);
  //     await fetchRecentKnowledge();
  //   } catch (error) {
  //     console.error('Error saving edited knowledge:', error);
  //     setError('Failed to save edited knowledge. Please try again.');
  //   }
  // };

  return (
    <div className={styles.container}>
      <PageTitle title={t('plato')} />
      {/* <InputContainer onSubmit={handleSubmit} /> */}
      <InputContainer  />
      {templates && <TemplateManager templates={templates} onTemplateSelect={handleTemplateSelect} onTemplateSave={handleTemplateSave} />}
      {parameters && <ParameterSelector parameters={parameters} onParameterChange={handleParameterChange} />}
      {isLoading && <div className={styles.loader}>Processing...</div>}
      {error && <div className={styles.error}>{error}</div>}
      <div className={styles.outputContainer}>
        <h2>{title}</h2>
        <div className={styles.summary}>{summary}</div>
        <div className={styles.mainOutput}>{outputText}</div>
      </div>
      <div className={styles.actionButtons}>
        {/* <button onClick={handleSave} className={styles.saveButton}>Save to S3</button> */}
        <button className={styles.saveButton}>Save to S3</button>
        <button onClick={handleClear} className={styles.clearButton}>Clear</button>
      </div>
      <RecentKnowledgeTable
        recentKnowledge={recentKnowledge}
        // onPublish={handlePublish}
        // onSaveAsDraft={handleSaveAsDraft}
        onDelete={handleDelete}
        onEdit={handleEdit}
      />
      {isEditModalOpen && (
        <EditModal
          data={editingItem}
          // onSave={handleSaveEdit}
          onClose={() => {
            setIsEditModalOpen(false);
            setEditingItem(null);
          }}
        />
      )}
    </div>
  );
};

export default Plato;