// File: frontend/src/pages/InvestorPitch/CustomerBrokers.js

import React from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import PitchDeck from './PitchDeck';
import { getCustomerBrokersSlides } from './configs/CustomerBrokersConfig';

const CustomerBrokers = () => {
  const { t } = useLanguage();
  const slides = getCustomerBrokersSlides(t);
  
  return <PitchDeck slides={slides} deckId="customer-brokers" />;
};

export default CustomerBrokers;