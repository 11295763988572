// File: frontend/src/pages/PropertyInsight/index.js (updated)

import React, { useState } from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import PropertyStatistics from './components/PropertyStatistics';
import PropertySearchResults from './components/PropertySearchResults';
import PropertyDistributionChart from './components/PropertyDistributionChart';
import InsuranceStatistics from './components/InsuranceStatistics';
import PropertySearchPanel from './components/PropertySearchPanel';
import PropertySearch from './components/PropertySearch'; // Add this import
import LatestReports from './components/LatestReports';
import styles from './PropertyInsight.module.css';

const PropertyInsight = () => {
  const { t } = useLanguage();
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const [lastSearchParams, setLastSearchParams] = useState(null);
  const [hasSearched, setHasSearched] = useState(false);

  const handleSearch = async (searchParams) => {
    setLoading(true);
    setError(null);
    setHasSearched(true);
    
    try {
      // Add pagination parameters
      const params = {
        ...searchParams,
        page: 1,
        limit: 10
      };
      
      setLastSearchParams(params);
      setCurrentPage(1);
      
      // Call the API - this will be replaced with actual API call
      // const response = await propertyService.searchProperty(params);
      
      // Mock response for now
      const mockResponse = {
        properties: [
          {
            property_id: "11661bf1-e5af-4565-8b93-d9c9268c54cc",
            address_id: "addr-12345",
            property_type: "Residential",
            zoning_classification: "R1",
            is_income_property: false,
            masterAddress: {
              address_line_1: "123 Main St",
              city: "Toronto",
              province_state_name: "Ontario",
              postal_code: "M5V 2N4",
              country_code: "CA"
            },
            building: {
              year_built: 2005,
              building_type: "Single Family",
              total_area: 2500,
              area_measure: "sq ft"
            }
          }
        ],
        total: 1
      };
      
      if (mockResponse && mockResponse.properties) {
        setSearchResults(mockResponse.properties);
        setTotalResults(mockResponse.total || mockResponse.properties.length);
        setTotalPages(Math.ceil((mockResponse.total || mockResponse.properties.length) / 10));
      } else {
        setSearchResults([]);
        setTotalResults(0);
        setTotalPages(1);
        setError(t('no_properties_found'));
      }
    } catch (err) {
      console.error('Error searching properties:', err);
      setError(t('failed_to_search_properties'));
      setSearchResults([]);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = async (page) => {
    if (page === currentPage || !lastSearchParams) return;
    
    setLoading(true);
    setError(null);
    
    try {
      // Implementation similar to handleSearch but with updated page
      // This would be replaced with actual API call
      
      // Mock implementation for now
      setCurrentPage(page);
      setLoading(false);
    } catch (err) {
      console.error('Error changing page:', err);
      setError(t('error_loading_page'));
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.pageHeader}>
        <div className={styles.pageTitle}>
          <div className={styles.pageIconArea}>
            <img className={styles.pageIcon} alt="" src="/icons/property-icon.svg" />
          </div>
          <h1 className={styles.title}>{t('property_insight')}</h1>
        </div>
      </div>
      
      <div className={styles.searchPanels}>
        <PropertySearchPanel onSearch={handleSearch} loading={loading} />
      </div>
      
      {/* Add PropertySearch component here */}
      <PropertySearch onSearch={handleSearch} loading={loading} />
      
      {error && <div className={styles.error}>{error}</div>}
      
      {hasSearched && (
        <PropertySearchResults 
          results={searchResults} 
          loading={loading}
          onPageChange={handlePageChange}
          currentPage={currentPage}
          totalPages={totalPages}
          totalResults={totalResults}
        />
      )}
      
      <div className={styles.statsSection}>
        <PropertyStatistics />
        <InsuranceStatistics />
      </div>
      
      <div className={styles.chartSection}>
        <PropertyDistributionChart />
      </div>
      
      <div className={styles.reportsSection}>
        <LatestReports />
      </div>
    </div>
  );
};

export default PropertyInsight;