// /charts/v2/types/PieChart.js
import React from 'react';
import {
  PieChart as RechartsPieChart,
  Pie,
  Cell,
  Tooltip,
  Legend
} from 'recharts';
import ChartWrapper from '../core/ChartWrapper';
import ChartContainer from '../core/ChartContainer';
import styles from './PieChart.module.css';

const CustomTooltip = ({ active, payload }) => {
  if (!active || !payload || !payload.length) return null;

  return (
    <div className={styles.tooltip}>
      <p className={styles.tooltipLabel}>{payload[0].name}</p>
      <p className={styles.tooltipValue}>
        {payload[0].value.toLocaleString()} lines
      </p>
    </div>
  );
};

const CustomLegend = ({ payload }) => (
  <div className={styles.legendContainer}>
    {payload.map((entry, index) => (
      <div key={index} className={styles.legendItem}>
        <span 
          className={styles.legendColor}
          style={{ backgroundColor: entry.color }}
        />
        <span className={styles.legendText}>{entry.value}</span>
      </div>
    ))}
  </div>
);

const PieChart = ({
  data,
  title,
  subtitle,
  options = {}
}) => {
  const {
    height = 600,
    width = 600,
    pieConfig = {
      innerRadius: "45%",
      outerRadius: "75%",
      paddingAngle: 2,
      cornerRadius: 3
    }
  } = options;

  // Transform data for Recharts
  const chartData = data.labels.map((label, index) => ({
    name: label,
    value: data.datasets[0].data[index]
  }));

  return (
    <ChartWrapper title={title} subtitle={subtitle}>
      <div className={styles.pieChartContainer}>
        <ChartContainer height={height}>
          <RechartsPieChart 
            width={width} 
            height={height}
            margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
          >
            <Pie
              data={chartData}
              dataKey="value"
              nameKey="name"
              cx={width / 2}
              cy={height / 2}
              innerRadius={pieConfig.innerRadius}
              outerRadius={pieConfig.outerRadius}
              paddingAngle={pieConfig.paddingAngle}
              cornerRadius={pieConfig.cornerRadius}
            >
              {chartData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={data.datasets[0].backgroundColor[index]}
                />
              ))}
            </Pie>
            <Tooltip content={<CustomTooltip />} />
            <Legend
              content={<CustomLegend />}
              verticalAlign="middle"
              align="center"
              layout="vertical"
            />
          </RechartsPieChart>
        </ChartContainer>
      </div>
    </ChartWrapper>
  );
};

export default PieChart;