// File: frontend/src/components/Sidebar/SidebarNavItem.js

import React from 'react';
import { Link } from 'react-router-dom';
import { useLanguage } from '../../contexts/LanguageContext';
import styles from './SidebarNavItem.module.css';

const SidebarNavItem = ({ item, isActive, isExpanded }) => {
  const { t } = useLanguage();

  return (
    <li className={`${styles.navItem} ${isActive ? styles.active : ''}`}>
      <Link 
        to={item.path} 
        className={styles.navLink}
        title={!isExpanded ? t(item.name) : undefined}
      >
        <div className={styles.iconWrapper}>
          <img 
            src={isActive ? item.activeIcon : item.icon} 
            alt={t(item.name)} 
            className={styles.icon} 
          />
        </div>
        {isExpanded && (
          <div className={styles.navContent}>
            <span className={styles.label}>{t(item.name)}</span>
            {item.description && (
              <span className={styles.description}>{t(item.description)}</span>
            )}
          </div>
        )}
      </Link>
    </li>
  );
};

export default SidebarNavItem;