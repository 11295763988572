// File: frontend/src/pages/UMS/UserPortfolio/components/PortfolioOverview/index.js

import React, { useState } from 'react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import Users from './Users';
import Trips from './Trips';
import DrivingScore from './DrivingScore';
import UwDecisions from './UwDecisions';
import EngHeatMap from './EngHeatMap';
import styles from './PortfolioOverview.module.css';

const PortfolioOverview = () => {
  const { t } = useLanguage();
  const [activeTab, setActiveTab] = useState('users');

  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <div className={styles.sectionHeader}>
          <h3 className={styles.sectionTitle}>{t('user_metrics')}</h3>
        </div>
        <div className={styles.sectionContent}>
          <div className={styles.tabs}>
            <button 
              className={`${styles.tab} ${activeTab === 'users' ? styles.activeTab : ''}`}
              onClick={() => setActiveTab('users')}
            >
              {t('users')}
            </button>
            <button 
              className={`${styles.tab} ${activeTab === 'trips' ? styles.activeTab : ''}`}
              onClick={() => setActiveTab('trips')}
            >
              {t('trips')}
            </button>
            <button 
              className={`${styles.tab} ${activeTab === 'drivingScore' ? styles.activeTab : ''}`}
              onClick={() => setActiveTab('drivingScore')}
            >
              {t('driving_score')}
            </button>
          </div>
          
          <div className={styles.chartContainer}>
            {activeTab === 'users' && <Users />}
            {activeTab === 'trips' && <Trips />}
            {activeTab === 'drivingScore' && <DrivingScore />}
          </div>
        </div>
      </div>
      
      <div className={styles.insightCard}>
        <div className={styles.insightIcon}>
          <i className="fas fa-lightbulb"></i>
        </div>
        <div className={styles.insightContent}>
          <p>{t('user_insight_message')}</p>
        </div>
      </div>
      
      <div className={styles.section}>
        <div className={styles.sectionHeader}>
          <h3 className={styles.sectionTitle}>{t('underwriting_decisions_matrix')}</h3>
        </div>
        <div className={styles.sectionContent}>
          <UwDecisions />
        </div>
      </div>
      
      <div className={styles.insightCard}>
        <div className={styles.insightIcon}>
          <i className="fas fa-lightbulb"></i>
        </div>
        <div className={styles.insightContent}>
          <p>{t('underwriting_insight_message')}</p>
        </div>
      </div>
      
      <div className={styles.section}>
        <div className={styles.sectionHeader}>
          <h3 className={styles.sectionTitle}>{t('user_retention_heat_map')}</h3>
        </div>
        <div className={styles.sectionContent}>
          <EngHeatMap />
        </div>
      </div>
      
      <div className={styles.insightCard}>
        <div className={styles.insightIcon}>
          <i className="fas fa-lightbulb"></i>
        </div>
        <div className={styles.insightContent}>
          <p>{t('retention_insight_message')}</p>
        </div>
      </div>
    </div>
  );
};

export default PortfolioOverview;