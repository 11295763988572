// /charts/v2/types/StackedBarChart.js
import React from 'react';
import {
    BarChart as RechartsBarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from 'recharts';
import ChartWrapper from '../core/ChartWrapper';
import ChartContainer from '../core/ChartContainer';
import styles from './StackedBarChart.module.css';

const CustomTooltip = ({ active, payload }) => {
    if (!active || !payload || !payload.length) return null;

    return (
        <div className={styles.tooltip}>
            <p className={styles.tooltipCategory}>{payload[0].payload.name}</p>
            {payload.map((entry, index) => (
                <div key={index} className={styles.tooltipRow}>
                    <span 
                        className={styles.tooltipLabel} 
                        style={{ '--color': entry.color }}
                    >
                        {entry.name}
                    </span>
                    <span className={styles.tooltipValue}>
                        {entry.value.toFixed(1)}%
                    </span>
                </div>
            ))}
        </div>
    );
};

const CustomLegend = ({ payload, onLegendClick }) => (
    <div className={styles.legendContainer}>
        {payload.map((entry, index) => (
            <div 
                key={index} 
                className={styles.legendItem}
                onClick={() => onLegendClick && onLegendClick(entry.value)}
                role="button"
                tabIndex={0}
            >
                <span 
                    className={styles.legendColor}
                    style={{ backgroundColor: entry.color }}
                />
                <span className={styles.legendText}>{entry.value}</span>
            </div>
        ))}
    </div>
);

const StackedBarChart = ({ 
    data, 
    title, 
    subtitle, 
    orientation = 'horizontal'
}) => {
    const chartData = data.categories.map((category, index) => ({
        name: category,
        ...data.metrics.reduce((acc, metric) => {
            acc[metric.name] = metric.values[index];
            return acc;
        }, {})
    }));

    const handleLegendClick = (dataKey) => {
        // Optional: Add legend click handling if needed
        console.log('Legend clicked:', dataKey);
    };

    return (
        <ChartWrapper title={title} subtitle={subtitle}>
            <div className={styles.stackedBarChartContainer}>
                <ChartContainer height={orientation === 'horizontal' ? 500 : 400}>
                    <ResponsiveContainer width="100%" height="100%">
                        <RechartsBarChart
                            data={chartData}
                            layout={orientation === 'horizontal' ? 'vertical' : 'horizontal'}
                            margin={
                                orientation === 'horizontal' 
                                    ? { top: 20, right: 30, left: 10, bottom: 20 }
                                    : { top: 20, right: 30, left: 10, bottom: 40 }
                            }
                            barSize={50}
                        >
                            <CartesianGrid 
                                strokeDasharray="3 3" 
                                horizontal={orientation !== 'horizontal'}
                                vertical={orientation === 'horizontal'}
                                stroke="var(--color-border)"
                                strokeOpacity={0.5}
                            />
                            <XAxis
                                type={orientation === 'horizontal' ? "number" : "category"}
                                dataKey={orientation === 'horizontal' ? undefined : "name"}
                                axisLine={false}
                                tickLine={false}
                                domain={orientation === 'horizontal' ? [0, 100] : undefined}
                                tickFormatter={orientation === 'horizontal' ? (value) => `${value}%` : undefined}
                                tick={{
                                    fill: 'var(--text-secondary)',
                                    fontSize: 12,
                                    fontFamily: 'var(--font-primary)'
                                }}
                                dy={8}
                            />
                            <YAxis
                                type={orientation === 'horizontal' ? "category" : "number"}
                                dataKey={orientation === 'horizontal' ? "name" : undefined}
                                axisLine={false}
                                tickLine={false}
                                width={110}
                                domain={orientation === 'horizontal' ? undefined : [0, 100]}
                                tickFormatter={orientation === 'horizontal' ? undefined : (value) => `${value}%`}
                                tick={{
                                    fill: 'var(--text-secondary)',
                                    fontSize: 12,
                                    fontFamily: 'var(--font-primary)'
                                }}
                                dx={orientation === 'horizontal' ? -8 : 0}
                            />
                            <Tooltip 
                                content={<CustomTooltip />}
                                cursor={{
                                    fill: 'var(--color-border)',
                                    opacity: 0.1
                                }}
                            />
                            <Legend 
                                content={<CustomLegend onLegendClick={handleLegendClick} />}
                                wrapperStyle={{
                                    paddingTop: 'var(--spacing-md)'
                                }}
                            />
                            {data.metrics.map((metric) => (
                                <Bar
                                    key={metric.name}
                                    dataKey={metric.name}
                                    stackId="a"
                                    fill={metric.color}
                                    radius={[2, 2, 2, 2]}
                                />
                            ))}
                        </RechartsBarChart>
                    </ResponsiveContainer>
                </ChartContainer>
            </div>
        </ChartWrapper>
    );
};

export default StackedBarChart;