// File: frontend/src/pages/InvestorPitch/constants/slideData.js

import React from 'react';
import * as LucideIcons from 'lucide-react';
import IntroSlide from '../components/slides/IntroSlide/IntroSlide';
import TechnologySlide from '../components/slides/TechnologySlide/TechnologySlide';
import GrowthSlide from '../components/slides/GrowthSlide/GrowthSlide';
import TechnicalArchitectureSlide from '../components/slides/TechnicalArchitectureSlide/TechnicalArchitectureSlide';
import InvestmentSlide from '../components/slides/InvestmentSlide/InvestmentSlide';
import MarketImpactSlide from '../components/slides/MarketImpactSlide/MarketImpactSlide';
import LeadershipSlide from '../components/slides/LeadershipSlide/LeadershipSlide';
import GlobalHiringSlide from '../components/slides/GlobalHiringSlide/GlobalHiringSlide';
import PartnershipsSlide from '../components/slides/PartnershipsSlide/PartnershipsSlide';
import SecuritySlide from '../components/slides/SecuritySlide/SecuritySlide';
import ProductEvolutionSlide from '../components/slides/ProductEvolutionSlide/ProductEvolutionSlide';
import MarketEntrySlide from '../components/slides/MarketEntrySlide/MarketEntrySlide';
import CompetitiveAdvantageSlide from '../components/slides/CompetitiveAdvantageSlide/CompetitiveAdvantageSlide';
import ClosingSlide from '../components/slides/ClosingSlide/ClosingSlide';
import DemoSlide from '../components/slides/DemoSlide/DemoSlide';
import InsuranceFutureSlide from '../components/slides/MarketingSlides/InsuranceFutureSlide/InsuranceFutureSlide';
import VisionSlide from '../components/slides/MarketingSlides/InsuranceFutureSlide/VisionSlide';
import ExpenseAnalysisSlide from '../components/slides/MarketingSlides/InsuranceFutureSlide/ExpenseAnalysisSlide';
import MethodologySlide from '../components/slides/MarketingSlides/InsuranceFutureSlide/MethodologySlide';
import DistributionModelSlide from '../components/slides/MarketingSlides/InsuranceFutureSlide/DistributionModelSlide';
import WaterfallSlide from '../components/slides/MarketingSlides/InsuranceFutureSlide/WaterfallSlide';
import ModelParametersSlide from '../components/slides/MarketingSlides/InsuranceFutureSlide/ModelParametersSlide';
import InteractiveBubbleSystem from '../components/slides/MarketingSlides/InsuranceFutureSlide/InteractiveBubbleSystem';
import AnimatedHiringSlide from '../components/slides/GlobalHiringSlide/AnimatedHiringSlide';

export const slides = [
    {
      id: 'intro',
      title: "The Future of Insurance is Here",
      icon: <LucideIcons.Globe className="w-8 h-8" />,
      content: <IntroSlide />
    },
    {
      id: 'technology',
      title: "Core Technology Stack",
      icon: <LucideIcons.Shield className="w-8 h-8" />,
      content: <TechnologySlide />
    },
    {
      id: 'growth',
      title: "Growth Trajectory",
      icon: <LucideIcons.TrendingUp className="w-8 h-8" />,
      content: <GrowthSlide />
    },
    {
      id: 'architecture',
      title: "Technical Architecture",
      icon: <LucideIcons.Cpu className="w-8 h-8" />,
      content: <TechnicalArchitectureSlide />
    },
    {
      id: 'investment',
      title: "Investment Opportunity",
      icon: <LucideIcons.DollarSign className="w-8 h-8" />,
      content: <InvestmentSlide />
    },
    {
      id: 'market-impact',
      title: "Market Impact & Scaling",
      icon: <LucideIcons.Globe2 className="w-8 h-8" />,
      content: <MarketImpactSlide />
    },
    {
      id: 'leadership',
      title: "Leadership & Vision",
      icon: <LucideIcons.Users className="w-8 h-8" />,
      content: <LeadershipSlide />
    },
    {
      id: 'hiring',
      title: "Global Hiring Strategy",
      icon: <LucideIcons.UserPlus className="w-8 h-8" />,
      content: <GlobalHiringSlide />
    },
    {
      id: 'partnerships',
      title: "Strategic Partnerships",
      icon: <LucideIcons.Handshake className="w-8 h-8" />,
      content: <PartnershipsSlide />
    },
    {
      id: 'security',
      title: "Security",
      icon: <LucideIcons.Handshake className="w-8 h-8" />,
      content: <SecuritySlide />
    },
    {
      id: 'product-evolution',
      title: "Product Evolution",
      icon: <LucideIcons.Handshake className="w-8 h-8" />,
      content: <ProductEvolutionSlide />
    },
    {
      id: 'market-entry',
      title: "Market Entry",
      icon: <LucideIcons.Handshake className="w-8 h-8" />,
      content: <MarketEntrySlide />
    },
    {
      id: 'competitive-advantage',
      title: "Competitive Advantage",
      icon: <LucideIcons.Handshake className="w-8 h-8" />,
      content: <CompetitiveAdvantageSlide />
    },
    {
      id: 'demo',
      title: "Demo",
      icon: <LucideIcons.Handshake className="w-8 h-8" />,
      content: <DemoSlide />
    },
    {
      id: 'closing',
      title: "Closing",
      icon: <LucideIcons.Handshake className="w-8 h-8" />,
      content: <ClosingSlide />
    },
  ];

export const getSlides = (t) => [
  {
    id: 'intro',
    title: t('investor_pitch.slides.intro.title'),
    icon: <LucideIcons.Globe className="w-8 h-8" aria-hidden="true" />,
    content: <IntroSlide />
  },
  {
    id: 'technology',
    title: t('investor_pitch.slides.technology.title'),
    icon: <LucideIcons.Shield className="w-8 h-8" aria-hidden="true" />,
    content: <TechnologySlide />
  },
  {
    id: 'growth',
    title: t('investor_pitch.slides.growth.title'),
    icon: <LucideIcons.TrendingUp className="w-8 h-8" aria-hidden="true" />,
    content: <GrowthSlide />
  },
  {
    id: 'architecture',
    title: t('investor_pitch.slides.architecture.title'),
    icon: <LucideIcons.Cpu className="w-8 h-8" aria-hidden="true" />,
    content: <TechnicalArchitectureSlide />
  },
  {
    id: 'investment',
    title: t('investor_pitch.slides.investment.title'),
    icon: <LucideIcons.DollarSign className="w-8 h-8" aria-hidden="true" />,
    content: <InvestmentSlide />
  },
  {
    id: 'market-impact',
    title: t('investor_pitch.slides.market_impact.title'),
    icon: <LucideIcons.Globe2 className="w-8 h-8" aria-hidden="true" />,
    content: <MarketImpactSlide />
  },
  {
    id: 'leadership',
    title: t('investor_pitch.slides.leadership.title'),
    icon: <LucideIcons.Users className="w-8 h-8" aria-hidden="true" />,
    content: <LeadershipSlide />
  },
  {
    id: 'hiring',
    title: t('investor_pitch.slides.hiring.title'),
    icon: <LucideIcons.UserPlus className="w-8 h-8" aria-hidden="true" />,
    content: <GlobalHiringSlide />
  },
  {
    id: 'partnerships',
    title: t('investor_pitch.slides.partnerships.title'),
    icon: <LucideIcons.Handshake className="w-8 h-8" aria-hidden="true" />,
    content: <PartnershipsSlide />
  },
  {
    id: 'security',
    title: t('investor_pitch.slides.security.title'),
    icon: <LucideIcons.Shield className="w-8 h-8" aria-hidden="true" />,
    content: <SecuritySlide />
  },
  {
    id: 'product-evolution',
    title: t('investor_pitch.slides.product_evolution.title'),
    icon: <LucideIcons.GitBranch className="w-8 h-8" aria-hidden="true" />,
    content: <ProductEvolutionSlide />
  },
  {
    id: 'market-entry',
    title: t('investor_pitch.slides.market_entry.title'),
    icon: <LucideIcons.ArrowRight className="w-8 h-8" aria-hidden="true" />,
    content: <MarketEntrySlide />
  },
  {
    id: 'competitive-advantage',
    title: t('investor_pitch.slides.competitive_advantage.title'),
    icon: <LucideIcons.Trophy className="w-8 h-8" aria-hidden="true" />,
    content: <CompetitiveAdvantageSlide />
  },
  {
    id: 'demo',
    title: t('investor_pitch.slides.demo.title'),
    icon: <LucideIcons.Play className="w-8 h-8" aria-hidden="true" />,
    content: <DemoSlide />
  },
  {
    id: 'closing',
    title: t('investor_pitch.slides.closing.title'),
    icon: <LucideIcons.Flag className="w-8 h-8" aria-hidden="true" />,
    content: <ClosingSlide />
  },
  {
    id: 'insurance-future',
    title: t('investor_pitch.slides.future.title'),
    icon: <LucideIcons.Flag className="w-8 h-8" aria-hidden="true" />,
    content: <InsuranceFutureSlide />
},
{
    id: 'insurance-future-vision',
    title: t('investor_pitch.slides.vision.title'),
    icon: <LucideIcons.Flag className="w-8 h-8" aria-hidden="true" />,
    content: <VisionSlide />
},
{
    id: 'insurance-future-expense-analysis',
    title: t('investor_pitch.slides.expense_analysis.title'),
    icon: <LucideIcons.Flag className="w-8 h-8" aria-hidden="true" />,
    content: <ExpenseAnalysisSlide />
},
{
    id: 'insurance-future-methodology-slide',
    title: t('investor_pitch.slides.methodology.title'),
    icon: <LucideIcons.Flag className="w-8 h-8" aria-hidden="true" />,
    content: <MethodologySlide />
},
{
    id: 'insurance-future-distribution',
    title: t('investor_pitch.slides.distribution_model.title'),
    icon: <LucideIcons.Flag className="w-8 h-8" aria-hidden="true" />,
    content: <DistributionModelSlide />
},
{
    id: 'insurance-future-waterfall-slide',
    title: t('investor_pitch.slides.waterfall.title'),
    icon: <LucideIcons.Flag className="w-8 h-8" aria-hidden="true" />,
    content: <WaterfallSlide />
},
{
    id: 'insurance-future-model-parameters',
    title: t('investor_pitch.slides.model_parameters.title'),
    icon: <LucideIcons.Flag className="w-8 h-8" aria-hidden="true" />,
    content: <ModelParametersSlide />
},
{
    id: 'insurance-future-bubble-system',
    title: t('investor_pitch.slides.bubble_system.title'),
    icon: <LucideIcons.Flag className="w-8 h-8" aria-hidden="true" />,
    content: <InteractiveBubbleSystem />
},
{
    id: 'insurance-future-hiring',
    title: t('investor_pitch.slides.hiring_demo.title'),
    icon: <LucideIcons.Flag className="w-8 h-8" aria-hidden="true" />,
    content: <AnimatedHiringSlide />
}
];