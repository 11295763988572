// File: frontend/src/pages/UMS/UserDetail/components/UserOverview/index.js

import React from 'react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from './UserOverview.module.css';

// Helper function to format date in YYYY-MM-DD hh:mm AM/PM format
const formatDateTime = (dateString) => {
  if (!dateString) return 'N/A';
  
  const date = new Date(dateString);
  
  // Check if date is valid
  if (isNaN(date.getTime())) return 'Invalid Date';
  
  // Format date as YYYY-MM-DD
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  
  // Format time as hh:mm AM/PM
  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  
  // Convert hours to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const formattedHours = String(hours).padStart(2, '0');
  
  return `${year}-${month}-${day} ${formattedHours}:${minutes} ${ampm}`;
};

const UserOverview = ({ user }) => {
  const { t } = useLanguage();

  const getUserTypeText = (type) => {
    switch (type) {
      case 1: return t('standard_user');
      case 2: return t('premium_user');
      case 3: return t('admin_user');
      case 4: return t('system_user');
      default: return t('unknown');
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.grid}>
        <div className={styles.card}>
          <h3 className={styles.cardTitle}>{t('basic_information')}</h3>
          <div className={styles.cardContent}>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('user_id')}:</div>
              <div className={styles.infoValue}>{user.user_id}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('user_type')}:</div>
              <div className={styles.infoValue}>{getUserTypeText(user.user_type)}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('prefix')}:</div>
              <div className={styles.infoValue}>{user.prefix || 'N/A'}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('first_name')}:</div>
              <div className={styles.infoValue}>{user.first_name || 'N/A'}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('middle_name')}:</div>
              <div className={styles.infoValue}>{user.middle_name || 'N/A'}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('last_name')}:</div>
              <div className={styles.infoValue}>{user.last_name || 'N/A'}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('suffix')}:</div>
              <div className={styles.infoValue}>{user.suffix || 'N/A'}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('gender')}:</div>
              <div className={styles.infoValue}>{user.gender || 'N/A'}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('date_of_birth')}:</div>
              <div className={styles.infoValue}>{user.date_of_birth || 'N/A'}</div>
            </div>
          </div>
        </div>
        
        <div className={styles.card}>
          <h3 className={styles.cardTitle}>{t('contact_information')}</h3>
          <div className={styles.cardContent}>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('email_address')}:</div>
              <div className={styles.infoValue}>{user.email_address || 'N/A'}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('email_verified')}:</div>
              <div className={styles.infoValue}>
                <span className={user.is_email_verified ? styles.verified : styles.notVerified}>
                  {user.is_email_verified ? t('yes') : t('no')}
                </span>
              </div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('secondary_email')}:</div>
              <div className={styles.infoValue}>{user.email_address_secondary || 'N/A'}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('mobile_phone')}:</div>
              <div className={styles.infoValue}>
                {user.country_phone_code && user.area_code && user.mobile_phone_number 
                  ? `+${user.country_phone_code} (${user.area_code}) ${user.mobile_phone_number}`
                  : user.mobile_phone_number || 'N/A'}
              </div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('phone_verified')}:</div>
              <div className={styles.infoValue}>
                <span className={user.is_phone_verified ? styles.verified : styles.notVerified}>
                  {user.is_phone_verified ? t('yes') : t('no')}
                </span>
              </div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('home_phone')}:</div>
              <div className={styles.infoValue}>{user.home_phone_number_full || 'N/A'}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('work_phone')}:</div>
              <div className={styles.infoValue}>
                {user.work_phone_number_full 
                  ? user.work_phone_number_extension 
                    ? `${user.work_phone_number_full} ext. ${user.work_phone_number_extension}`
                    : user.work_phone_number_full
                  : 'N/A'}
              </div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('fax')}:</div>
              <div className={styles.infoValue}>{user.fax_phone_number_full || 'N/A'}</div>
            </div>
          </div>
        </div>
      </div>
      
      <div className={styles.grid}>
        <div className={styles.card}>
          <h3 className={styles.cardTitle}>{t('personal_information')}</h3>
          <div className={styles.cardContent}>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('primary_language')}:</div>
              <div className={styles.infoValue}>{user.primary_language || 'N/A'}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('country_of_residence')}:</div>
              <div className={styles.infoValue}>{user.country_of_residence || 'N/A'}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('marital_status')}:</div>
              <div className={styles.infoValue}>{user.marital_status || 'N/A'}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('occupation')}:</div>
              <div className={styles.infoValue}>{user.occupation || 'N/A'}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('employment_status')}:</div>
              <div className={styles.infoValue}>{user.employment_status || 'N/A'}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('is_retired')}:</div>
              <div className={styles.infoValue}>{user.is_retired ? t('yes') : t('no')}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('primary_transportation')}:</div>
              <div className={styles.infoValue}>{user.primary_mode_transportation || 'N/A'}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('preferred_measurement')}:</div>
              <div className={styles.infoValue}>{user.preferred_measurement_unit || 'N/A'}</div>
            </div>
          </div>
        </div>
        
        <div className={styles.card}>
          <h3 className={styles.cardTitle}>{t('account_information')}</h3>
          <div className={styles.cardContent}>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('account_status')}:</div>
              <div className={styles.infoValue}>
                {user.account_status === 495 ? t('active') : 
                 user.account_status === 494 ? t('pending_activation') : 
                 user.account_status === 496 ? t('suspended') : 
                 user.account_status === 497 ? t('deactivated') : 
                 user.account_status === 498 ? t('locked') : t('unknown')}
              </div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('user_loyalty_status')}:</div>
              <div className={styles.infoValue}>{user.user_loyalty_status || 'N/A'}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('is_allegory_tester')}:</div>
              <div className={styles.infoValue}>{user.is_allegory_tester ? t('yes') : t('no')}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('is_allegory_featured_driver')}:</div>
              <div className={styles.infoValue}>{user.is_allegory_featured_driver ? t('yes') : t('no')}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('is_allegory_premium_user')}:</div>
              <div className={styles.infoValue}>{user.is_allegory_premium_user ? t('yes') : t('no')}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('is_allegory_premium_family_share')}:</div>
              <div className={styles.infoValue}>{user.is_allegory_premium_family_share ? t('yes') : t('no')}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('privacy_protection')}:</div>
              <div className={styles.infoValue}>{user.privacy_protection ? t('yes') : t('no')}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('training_mode')}:</div>
              <div className={styles.infoValue}>{user.training_mode ? t('yes') : t('no')}</div>
            </div>
          </div>
        </div>
      </div>
      
      <div className={styles.card}>
        <h3 className={styles.cardTitle}>{t('system_information')}</h3>
        <div className={styles.cardContent}>
          <div className={styles.infoGrid}>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('created_at')}:</div>
              <div className={styles.infoValue}>{formatDateTime(user.created_at)}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('created_by')}:</div>
              <div className={styles.infoValue}>{user.created_by || 'N/A'}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('updated_at')}:</div>
              <div className={styles.infoValue}>{formatDateTime(user.updated_at)}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('updated_by')}:</div>
              <div className={styles.infoValue}>{user.updated_by || 'N/A'}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserOverview;