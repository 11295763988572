// File: frontend/src/pages/InvestorPitch/components/slides/MarketEntrySlide/ExecutionPlan.js

import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from './ExecutionPlan.module.css';

const ExecutionPlan = ({ steps }) => {
  const { t } = useLanguage();

  return (
    <motion.div 
      className={styles.container}
      whileHover={{ scale: 1.02 }}
      transition={{ type: 'spring', stiffness: 300, damping: 30 }}
    >
      {steps.map((step, index) => (
        <div 
          key={step.phase} 
          className={styles.step}
          aria-label={t('investor_pitch.market_entry.execution.step.aria_label', { phase: step.phase })}
        >
          <div className={styles.timeline}>
            <div 
              className={styles.timelinePoint}
              style={{ backgroundColor: step.color }}
            />
            {index < steps.length - 1 && (
              <div className={styles.timelineLine} />
            )}
          </div>

          <div className={styles.content}>
            <div className={styles.header}>
              <div className={styles.phaseInfo}>
                <div className={styles.phase}>{step.phase}</div>
                <div 
                  className={`${styles.status} ${styles[step.status.toLowerCase()]}`}
                >
                  {step.status}
                </div>
              </div>
              <h4 className={styles.title}>{step.title}</h4>
            </div>

            <ul className={styles.actions}>
              {step.actions.map((action, actionIndex) => (
                <li key={actionIndex}>{action}</li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </motion.div>
  );
};

ExecutionPlan.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      phase: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      actions: PropTypes.arrayOf(PropTypes.string).isRequired,
      status: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default ExecutionPlan;