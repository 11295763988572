// src/components/charts/Geography/GeographyCoordinates.js
import React, { useEffect, useState } from 'react';
import MapboxWrapper from '../../Map/MapboxWrapper';

const GeographyCoordinates = ({ width, height }) => {
  const [pointsData, setPointsData] = useState(null);

  useEffect(() => {
    fetch('https://allegory-dashboard-maps-geojson-data.s3.ca-central-1.amazonaws.com/ofa9a9/driving-score/all_time/b968ca52-cebf-430e-a028-a74f871f3c18.json')
      .then(response => response.json())
      .then(data => setPointsData(data.features))
      .catch(error => console.error("Error loading the data: ", error));
  }, []);

  if (!pointsData) {
    return <div>Data is loading. This may take a few seconds...</div>;
  }

  const data = [{
    type: "scattermapbox",
    lat: pointsData.map(point => point.geometry.coordinates[1]),
    lon: pointsData.map(point => point.geometry.coordinates[0]),
    mode: 'markers',
    marker: {
      size: 9,
      color: pointsData.map(point => point.properties.percentage),
      colorscale: [
        [0.0, '#FBB1BD'], [0.1, '#FF99AC'], [0.2, '#FF99AC'],
        [0.3, '#FF85A1'], [0.4, '#FF85A1'], [0.5, '#FF7096'],
        [0.6, '#FF7096'], [0.7, '#FF477E'], [0.8, '#FF477E'],
        [0.9, '#FF0A54'], [1.0, '#FF0A54']
      ],
      cmin: 0,
      cmax: 100,
      colorbar: {
        title: 'Percentage',
        thickness: 20
      }
    },
    text: pointsData.map(point => `dc: ${point.properties.dc}, %: ${point.properties.percentage.toFixed(2)}`),
  }];

  const layout = {
    mapbox: {
      center: { 
        lat: 43.9423260884896,
        lon: -79.39566431444186
      },
      zoom: 5
    },
    width: width,
    height: height,
    margin: { t: 0, b: 50 }
  };

  return (
    <MapboxWrapper
      data={data}
      layout={layout}
      style={{ width: "100%", height: "100%" }}
    />
  );
};

export default GeographyCoordinates;