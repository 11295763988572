// File: frontend/src/pages/UMS/UserPortfolio/components/AIInsights/index.js

import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from './AIInsights.module.css';

const AIInsights = () => {
  const { t } = useLanguage();
  const [insights, setInsights] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Simulating API call to fetch AI insights
    setLoading(true);
    
    // Replace with actual API call
    setTimeout(() => {
      try {
        // Mock data - replace with actual data from your API
        const mockInsights = [
          {
            id: 1,
            title: 'User Engagement Trend',
            description: 'User engagement has increased by 23% over the last quarter, primarily driven by new feature adoption in the mobile app.',
            category: 'engagement',
            severity: 'positive',
            date: new Date().toISOString(),
            recommendations: [
              'Continue promoting the new features through in-app notifications',
              'Consider expanding similar features to other parts of the application'
            ]
          },
          {
            id: 2,
            title: 'Geographic Expansion Opportunity',
            description: 'There is a significant concentration of users in the Greater Toronto Area, but relatively low penetration in Montreal and Vancouver markets.',
            category: 'geographic',
            severity: 'neutral',
            date: new Date().toISOString(),
            recommendations: [
              'Develop targeted marketing campaigns for Montreal and Vancouver',
              'Consider localization efforts for French-speaking regions'
            ]
          },
          {
            id: 3,
            title: 'Retention Risk Alert',
            description: 'Users who signed up in the last 30 days are showing 15% lower retention rates compared to previous cohorts.',
            category: 'retention',
            severity: 'negative',
            date: new Date().toISOString(),
            recommendations: [
              'Review the onboarding process for recent changes that might affect user experience',
              'Implement a re-engagement campaign targeting users from the last 30 days',
              'Consider offering incentives to boost early engagement'
            ]
          }
        ];
        
        setInsights(mockInsights);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    }, 1500);
  }, []);

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <div className={styles.spinner}></div>
        <p>{t('analyzing_user_data')}</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.errorContainer}>
        <i className="fas fa-exclamation-triangle"></i>
        <p>{t('error_loading_insights')}: {error}</p>
      </div>
    );
  }

  if (!insights || insights.length === 0) {
    return (
      <div className={styles.noDataContainer}>
        <p>{t('no_insights_available')}</p>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3 className={styles.title}>{t('ai_generated_insights')}</h3>
        <p className={styles.subtitle}>{t('ai_insights_description')}</p>
      </div>
      
      <div className={styles.insightsList}>
        {insights.map(insight => (
          <div 
            key={insight.id} 
            className={`${styles.insightCard} ${styles[insight.severity]}`}
          >
            <div className={styles.insightHeader}>
              <div className={styles.insightCategory}>
                <i className={`fas fa-${getCategoryIcon(insight.category)}`}></i>
                <span>{t(insight.category)}</span>
              </div>
              <div className={styles.insightDate}>
                {new Date(insight.date).toLocaleDateString()}
              </div>
            </div>
            
            <h4 className={styles.insightTitle}>{insight.title}</h4>
            <p className={styles.insightDescription}>{insight.description}</p>
            
            {insight.recommendations && insight.recommendations.length > 0 && (
              <div className={styles.recommendationsSection}>
                <h5 className={styles.recommendationsTitle}>{t('recommendations')}</h5>
                <ul className={styles.recommendationsList}>
                  {insight.recommendations.map((recommendation, index) => (
                    <li key={index} className={styles.recommendationItem}>
                      {recommendation}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            
            <div className={styles.insightActions}>
              <button className={styles.actionButton}>
                <i className="fas fa-thumbs-up"></i>
                {t('helpful')}
              </button>
              <button className={styles.actionButton}>
                <i className="fas fa-thumbs-down"></i>
                {t('not_helpful')}
              </button>
              <button className={styles.actionButton}>
                <i className="fas fa-share"></i>
                {t('share')}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

// Helper function to get icon based on category
const getCategoryIcon = (category) => {
  switch (category) {
    case 'engagement':
      return 'chart-line';
    case 'geographic':
      return 'map-marker-alt';
    case 'retention':
      return 'user-clock';
    case 'conversion':
      return 'exchange-alt';
    case 'performance':
      return 'tachometer-alt';
    default:
      return 'lightbulb';
  }
};

export default AIInsights;