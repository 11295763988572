// File: frontend/src/pages/NotFound/NotFound.js

import React from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import { Link } from 'react-router-dom';
import LandingButton from '../../components/Button/LandingButton';
import styles from './NotFound.module.css';

const NotFound = () => {
  const { t } = useLanguage();

  const reasons = [
    { title: '404_reason_adverse_selection_title', description: '404_reason_adverse_selection_description' },
    { title: '404_reason_moral_hazard_title', description: '404_reason_moral_hazard_description' },
    { title: '404_reason_actuarial_miscalculation_title', description: '404_reason_actuarial_miscalculation_description' },
    { title: '404_reason_black_swan_event_title', description: '404_reason_black_swan_event_description' },
    { title: '404_reason_risk_pooling_gone_wrong_title', description: '404_reason_risk_pooling_gone_wrong_description' },
    { title: '404_reason_ai_optimization_title', description: '404_reason_ai_optimization_description' },
  ];

  return (
    <div className={styles.notFoundContainer}>
      <div className={styles.mainContent}>
        <h1 className={styles.title}>{t('404_title')}</h1>
        <p className={styles.description}>{t('404_description')}</p>
        <div className={styles.actionButtons}>
          <Link to="/">
            <LandingButton variant="primary" size="large">
              {t('auth.signInFailure.buttonText')}
            </LandingButton>
          </Link>
          <Link to="/contact">
            <LandingButton variant="secondary" size="large">
              {t('send_us_a_message')}
            </LandingButton>
          </Link>
        </div>
      </div>

      <div className={styles.expertAnalysis}>
        <h2 className={styles.expertTitle}>{t('404_expert_opinion_title')}</h2>
        <p className={styles.expertSubtitle}>{t('404_expert_opinion_subtitle')}</p>
        
        <div className={styles.reasonsGrid}>
          {reasons.map((reason, index) => (
            <div key={index} className={styles.reasonCard}>
              <h3 className={styles.reasonTitle}>{t(reason.title)}</h3>
              <p className={styles.reasonDescription}>{t(reason.description)}</p>
            </div>
          ))}
        </div>
      </div>

      <p className={styles.apology}>{t('404_apology')}</p>
    </div>
  );
};

export default NotFound;