// File: frontend/src/pages/SuperAdmin/components/ProductManagement/ProductFeatures/index.js

import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from './ProductFeatures.module.css';

const ProductFeatures = ({ productId, onBack }) => {
  const { t } = useLanguage();
  const [product, setProduct] = useState(null);
  const [features, setFeatures] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAddForm, setShowAddForm] = useState(false);
  const [editingFeatureId, setEditingFeatureId] = useState(null);
  const [formData, setFormData] = useState({
    feature_name: '',
    feature_type: 'core',
    feature_status: 'active',
    feature_description: ''
  });

  useEffect(() => {
    const fetchProductAndFeatures = async () => {
      setLoading(true);
      setError(null);
      
      try {
        // This would be replaced with actual API calls
        // const productResponse = await productService.getProductDetails(productId);
        // const featuresResponse = await productService.getProductFeatures(productId);
        
        // Mock data for now
        await new Promise(resolve => setTimeout(resolve, 800));
        
        const mockProduct = {
          product_id: productId,
          product_name: 'Analytics Dashboard',
          product_category: 'analytics',
          product_status: 'active'
        };
        
        const mockFeatures = [
          {
            feature_id: 'feat-12345',
            product_id: productId,
            feature_name: 'Real-time Dashboard',
            feature_type: 'core',
            feature_status: 'active',
            feature_description: 'Real-time data visualization with automatic updates',
            created_at: '2023-01-20T10:30:00Z',
            updated_at: '2023-01-20T10:30:00Z'
          },
          {
            feature_id: 'feat-23456',
            product_id: productId,
            feature_name: 'Custom Reports',
            feature_type: 'core',
            feature_status: 'active',
            feature_description: 'Create and save custom reports with various visualization options',
            created_at: '2023-01-20T10:35:00Z',
            updated_at: '2023-01-20T10:35:00Z'
          },
          {
            feature_id: 'feat-34567',
            product_id: productId,
            feature_name: 'Data Export',
            feature_type: 'core',
            feature_status: 'active',
            feature_description: 'Export data in multiple formats (CSV, Excel, PDF)',
            created_at: '2023-01-20T10:40:00Z',
            updated_at: '2023-01-20T10:40:00Z'
          },
          {
            feature_id: 'feat-45678',
            product_id: productId,
            feature_name: 'API Integration',
            feature_type: 'advanced',
            feature_status: 'active',
            feature_description: 'Connect to external systems via REST API',
            created_at: '2023-01-20T10:45:00Z',
            updated_at: '2023-01-20T10:45:00Z'
          },
          {
            feature_id: 'feat-56789',
            product_id: productId,
            feature_name: 'AI Insights',
            feature_type: 'premium',
            feature_status: 'active',
            feature_description: 'Automated insights and anomaly detection using AI',
            created_at: '2023-01-20T10:50:00Z',
            updated_at: '2023-01-20T10:50:00Z'
          }
        ];
        
        setProduct(mockProduct);
        setFeatures(mockFeatures);
      } catch (err) {
        console.error('Error fetching product and features:', err);
        setError(t('failed_to_load_product_features'));
      } finally {
        setLoading(false);
      }
    };
    
    if (productId) {
      fetchProductAndFeatures();
    }
  }, [productId, t]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleAddFeature = () => {
    setFormData({
      feature_name: '',
      feature_type: 'core',
      feature_status: 'active',
      feature_description: ''
    });
    setShowAddForm(true);
    setEditingFeatureId(null);
  };

  const handleEditFeature = (feature) => {
    setFormData({
      feature_name: feature.feature_name,
      feature_type: feature.feature_type,
      feature_status: feature.feature_status,
      feature_description: feature.feature_description
    });
    setShowAddForm(true);
    setEditingFeatureId(feature.feature_id);
  };

  const handleCancelForm = () => {
    setShowAddForm(false);
    setEditingFeatureId(null);
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    try {
      if (editingFeatureId) {
        // This would be replaced with an actual API call
        // await productService.updateProductFeature(productId, editingFeatureId, formData);
        
        // Mock update for now
        await new Promise(resolve => setTimeout(resolve, 500));
        
        // Update the feature in the local state
        setFeatures(features.map(feature => 
          feature.feature_id === editingFeatureId
            ? { ...feature, ...formData, updated_at: new Date().toISOString() }
            : feature
        ));
      } else {
        // This would be replaced with an actual API call
        // const response = await productService.addProductFeature(productId, formData);
        
        // Mock response for now
        await new Promise(resolve => setTimeout(resolve, 500));
        
        const newFeatureId = `feat-${Math.random().toString(36).substring(2, 10)}`;
        const timestamp = new Date().toISOString();
        
        // Add the new feature to the local state
        setFeatures([
          ...features,
          {
            feature_id: newFeatureId,
            product_id: productId,
            ...formData,
            created_at: timestamp,
            updated_at: timestamp
          }
        ]);
      }
      
      // Reset form and state
      setShowAddForm(false);
      setEditingFeatureId(null);
    } catch (err) {
      console.error('Error saving feature:', err);
      setError(t('failed_to_save_feature'));
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteFeature = async (featureId) => {
    if (!window.confirm(t('confirm_delete_feature'))) return;
    
    setLoading(true);
    
    try {
      // This would be replaced with an actual API call
      // await productService.deleteProductFeature(productId, featureId);
      
      // Mock deletion for now
      await new Promise(resolve => setTimeout(resolve, 500));
      
      // Remove the feature from the local state
      setFeatures(features.filter(feature => feature.feature_id !== featureId));
    } catch (err) {
      console.error('Error deleting feature:', err);
      setError(t('failed_to_delete_feature'));
    } finally {
      setLoading(false);
    }
  };

  const getFeatureTypeClass = (type) => {
    switch (type) {
      case 'core':
        return styles.typeCore;
      case 'advanced':
        return styles.typeAdvanced;
      case 'premium':
        return styles.typePremium;
      default:
        return '';
    }
  };

  const getStatusBadgeClass = (status) => {
    switch (status) {
      case 'active':
        return styles.statusActive;
      case 'inactive':
        return styles.statusInactive;
      default:
        return '';
    }
  };

  if (loading && !product) {
    return (
      <div className={styles.loading}>
        <div className={styles.spinner}></div>
        <p>{t('loading_product_features')}</p>
      </div>
    );
  }

  if (error && !product) {
    return (
      <div className={styles.error}>
        <p>{error}</p>
        <button className={styles.backButton} onClick={onBack}>
          {t('back')}
        </button>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <button className={styles.backButton} onClick={onBack} disabled={loading}>
          <i className="fas fa-arrow-left"></i>
          <span>{t('back')}</span>
        </button>
        
        <div className={styles.productInfo}>
          <h2 className={styles.productName}>{product?.product_name}</h2>
          <div className={styles.productMeta}>
            <span className={styles.productId}>{product?.product_id}</span>
            <span className={styles.separator}>•</span>
            <span className={styles.productCategory}>{product?.product_category}</span>
          </div>
        </div>
        
        <button 
          className={styles.addButton} 
          onClick={handleAddFeature}
          disabled={loading || showAddForm}
        >
          <i className="fas fa-plus"></i>
          <span>{t('add_feature')}</span>
        </button>
      </div>
      
      {error && (
        <div className={styles.errorMessage}>
          <i className="fas fa-exclamation-circle"></i>
          <span>{error}</span>
        </div>
      )}
      
      {showAddForm && (
        <div className={styles.formContainer}>
          <h3 className={styles.formTitle}>
            {editingFeatureId ? t('edit_feature') : t('add_new_feature')}
          </h3>
          
          <form className={styles.form} onSubmit={handleSubmitForm}>
            <div className={styles.formGrid}>
              <div className={styles.formGroup}>
                <label htmlFor="feature_name" className={styles.label}>
                  {t('feature_name')} <span className={styles.required}>*</span>
                </label>
                <input
                  type="text"
                  id="feature_name"
                  name="feature_name"
                  className={styles.input}
                  value={formData.feature_name}
                  onChange={handleChange}
                  required
                  disabled={loading}
                />
              </div>
              
              <div className={styles.formGroup}>
                <label htmlFor="feature_type" className={styles.label}>
                  {t('feature_type')} <span className={styles.required}>*</span>
                </label>
                <select
                  id="feature_type"
                  name="feature_type"
                  className={styles.select}
                  value={formData.feature_type}
                  onChange={handleChange}
                  required
                  disabled={loading}
                >
                  <option value="core">{t('core')}</option>
                  <option value="advanced">{t('advanced')}</option>
                  <option value="premium">{t('premium')}</option>
                </select>
              </div>
              
              <div className={styles.formGroup}>
                <label htmlFor="feature_status" className={styles.label}>
                  {t('status')} <span className={styles.required}>*</span>
                </label>
                <select
                  id="feature_status"
                  name="feature_status"
                  className={styles.select}
                  value={formData.feature_status}
                  onChange={handleChange}
                  required
                  disabled={loading}
                >
                  <option value="active">{t('active')}</option>
                  <option value="inactive">{t('inactive')}</option>
                </select>
              </div>
              
              <div className={styles.formGroupFull}>
                <label htmlFor="feature_description" className={styles.label}>
                  {t('description')} <span className={styles.required}>*</span>
                </label>
                <textarea
                  id="feature_description"
                  name="feature_description"
                  className={styles.textarea}
                  value={formData.feature_description}
                  onChange={handleChange}
                  rows={3}
                  required
                  disabled={loading}
                ></textarea>
              </div>
            </div>
            
            <div className={styles.formActions}>
              <button 
                type="button" 
                className={styles.cancelButton} 
                onClick={handleCancelForm}
                disabled={loading}
              >
                {t('cancel')}
              </button>
              <button 
                type="submit" 
                className={styles.submitButton}
                disabled={loading}
              >
                {loading ? (
                  <>
                    <span className={styles.spinner}></span>
                    <span>{editingFeatureId ? t('updating') : t('adding')}</span>
                  </>
                ) : (
                  editingFeatureId ? t('update_feature') : t('add_feature')
                )}
              </button>
            </div>
          </form>
        </div>
      )}
      
      <div className={styles.featuresContainer}>
        <h3 className={styles.featuresTitle}>
          {t('product_features')} 
          <span className={styles.featureCount}>({features.length})</span>
        </h3>
        
        {features.length > 0 ? (
          <div className={styles.featuresList}>
            {features.map((feature) => (
              <div key={feature.feature_id} className={styles.featureItem}>
                <div className={styles.featureHeader}>
                  <div className={styles.featureName}>{feature.feature_name}</div>
                  <div className={styles.featureBadges}>
                    <span className={`${styles.featureTypeBadge} ${getFeatureTypeClass(feature.feature_type)}`}>
                      {feature.feature_type}
                    </span>
                    <span className={`${styles.statusBadge} ${getStatusBadgeClass(feature.feature_status)}`}>
                      {feature.feature_status}
                    </span>
                  </div>
                </div>
                <div className={styles.featureDescription}>
                  {feature.feature_description}
                </div>
                <div className={styles.featureActions}>
                  <button 
                    className={styles.editButton}
                    onClick={() => handleEditFeature(feature)}
                    disabled={loading || showAddForm}
                  >
                    <i className="fas fa-edit"></i>
                    <span>{t('edit')}</span>
                  </button>
                  <button 
                    className={styles.deleteButton}
                    onClick={() => handleDeleteFeature(feature.feature_id)}
                    disabled={loading || showAddForm}
                  >
                    <i className="fas fa-trash-alt"></i>
                    <span>{t('delete')}</span>
                  </button>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className={styles.emptyState}>
            <p>{t('no_features_found')}</p>
            <button 
              className={styles.addFirstButton}
              onClick={handleAddFeature}
              disabled={loading || showAddForm}
            >
              {t('add_first_feature')}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductFeatures;