// File: frontend/src/pages/QMS/components/QuoteSearchResults/index.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../../../contexts/LanguageContext';
import styles from './QuoteSearchResults.module.css';

// Helper function to format date in YYYY-MM-DD hh:mm AM/PM format
const formatDateTime = (dateString) => {
  if (!dateString) return 'N/A';
  
  const date = new Date(dateString);
  
  // Check if date is valid
  if (isNaN(date.getTime())) return 'Invalid Date';
  
  // Format date as YYYY-MM-DD
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  
  // Format time as hh:mm AM/PM
  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  
  // Convert hours to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const formattedHours = String(hours).padStart(2, '0');
  
  return `${year}-${month}-${day} ${formattedHours}:${minutes} ${ampm}`;
};

const QuoteSearchResults = ({ 
  results, 
  loading, 
  onPageChange, 
  currentPage, 
  totalPages, 
  totalResults 
}) => {
  const { t } = useLanguage();
  const navigate = useNavigate();

  const handleViewQuote = (quoteId) => {
    navigate(`/qms/${quoteId}`);
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        {t('searching_quotes')}
      </div>
    );
  }

  if (!results || results.length === 0) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.resultsHeader}>
        <h2 className={styles.title}>{t('search_results')}</h2>
        {totalResults > 0 && (
          <div className={styles.resultCount}>
            {t('showing_results', { 
              count: results.length, 
              total: totalResults || results.length 
            })}
          </div>
        )}
      </div>
      
      <div className={styles.resultsList}>
        {results.map((quote) => (
          <div key={quote.quote_id} className={styles.resultCard}>
            <div className={styles.resultInfo}>
              <div className={styles.resultRow}>
                <div className={styles.resultField}>
                  <span className={styles.fieldLabel}>{t('quote_id')}:</span>
                  <span className={styles.fieldValue}>{quote.quote_id_masked || quote.quote_id}</span>
                </div>
                <div className={styles.resultField}>
                  <span className={styles.fieldLabel}>{t('qnr')}:</span>
                  <span className={styles.fieldValue}>{quote.qnr || 'N/A'}</span>
                </div>
              </div>
              
              <div className={styles.resultRow}>
                <div className={styles.resultField}>
                  <span className={styles.fieldLabel}>{t('name')}:</span>
                  <span className={styles.fieldValue}>
                    {quote.contact?.first_name || 'N/A'} {quote.contact?.last_name || ''}
                  </span>
                </div>
                <div className={styles.resultField}>
                  <span className={styles.fieldLabel}>{t('email')}:</span>
                  <span className={styles.fieldValue}>{quote.contact?.email_address || 'N/A'}</span>
                </div>
              </div>
              
              <div className={styles.resultRow}>
                <div className={styles.resultField}>
                  <span className={styles.fieldLabel}>{t('phone')}:</span>
                  <span className={styles.fieldValue}>{quote.contact?.mobile_phone_number || 'N/A'}</span>
                </div>
                <div className={styles.resultField}>
                  <span className={styles.fieldLabel}>{t('status')}:</span>
                  <span className={`${styles.fieldValue} ${styles[quote.meta?.quote_status || 'unknown']}`}>
                    {t(quote.meta?.quote_status || 'unknown')}
                  </span>
                </div>
              </div>
              
              {quote.meta?.product_types && (
                <div className={styles.resultRow}>
                  <div className={styles.resultField}>
                    <span className={styles.fieldLabel}>{t('product_types')}:</span>
                    <span className={styles.fieldValue}>
                      {quote.meta.product_types.join(', ')}
                    </span>
                  </div>
                  {quote.timestamps?.created_at && (
                    <div className={styles.resultField}>
                      <span className={styles.fieldLabel}>{t('created_at')}:</span>
                      <span className={styles.fieldValue}>
                        {formatDateTime(quote.timestamps.created_at)}
                      </span>
                    </div>
                  )}
                </div>
              )}
            </div>
            
            <div className={styles.resultActions}>
              <button 
                className={styles.viewButton}
                onClick={() => handleViewQuote(quote.quote_id)}
              >
                {t('view_quote')}
              </button>
            </div>
          </div>
        ))}
      </div>
      
      {totalPages > 1 && (
        <div className={styles.pagination}>
          <button 
            className={styles.paginationButton}
            disabled={currentPage <= 1}
            onClick={() => onPageChange(currentPage - 1)}
          >
            {t('previous')}
          </button>
          
          <span className={styles.pageInfo}>
            {t('page_of_total', { page: currentPage, total: totalPages })}
          </span>
          
          <button 
            className={styles.paginationButton}
            disabled={currentPage >= totalPages}
            onClick={() => onPageChange(currentPage + 1)}
          >
            {t('next')}
          </button>
        </div>
      )}
    </div>
  );
};

export default QuoteSearchResults;