// File: frontend/src/components/layout/AuthLayout/AuthLayout.js

import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useLanguage } from '../../../contexts/LanguageContext';
import { useAuth } from '../../../contexts/AuthContextV2';
import LanguageSelector from '../../form/LanguageSelector/LanguageSelector';
import styles from './AuthLayout.module.css';

const AuthLayout = ({ 
  children, 
  title,
  showHomeButton = true,
  showBackButton = false,
  showNextButton = false,
  onBackClick,
  onNextClick,
  nextButtonText = "Next",
  backButtonText = "Back",
  isNextButtonDisabled = false,
  isVerifying = false,
  alignNextButtonRight = false
}) => {
  const { t } = useLanguage();
  const { loading: authLoading } = useAuth();

  // Add debug logging
  // console.debug('AuthLayout render:', {
  //   showBackButton,
  //   showNextButton,
  //   isVerifying,
  //   authLoading,
  //   hasChildren: !!children
  // });

  // Combine loading states
  const isLoading = isVerifying || authLoading;

  // Memoize handlers to prevent unnecessary re-renders
  const handleNextClick = useCallback((e) => {
    e.preventDefault();
    if (!isNextButtonDisabled && !isLoading && onNextClick) {
      onNextClick();
    }
  }, [isNextButtonDisabled, isLoading, onNextClick]);

  const handleBackClick = useCallback((e) => {
    e.preventDefault();
    if (!isLoading && onBackClick) {
      onBackClick();
    }
  }, [isLoading, onBackClick]);

  // Memoize class names
  const containerClassName = useMemo(() => {
    const classes = [styles.authLayout];
    if (isLoading) classes.push(styles.transitioning);
    return classes.join(' ');
  }, [isLoading]);

  const footerClassName = useMemo(() => {
    const classes = [styles.footerRow];
    if (alignNextButtonRight) classes.push(styles.alignNextRight);
    return classes.join(' ');
  }, [alignNextButtonRight]);

  return (
    <div className={containerClassName}>
      <div className={styles.authContainer}>
        <div className={styles.headerRow}>
          <h1 className={styles.pageTitle}>{title}</h1>
          {showHomeButton && (
            <Link 
              to="/" 
              className={styles.homeButton}
              tabIndex={isLoading ? -1 : 0}
            >
              <img 
                src="/icons/18/misc/homepage.svg" 
                alt="Home" 
                className={styles.buttonIcon} 
              />
              <span>{t('homepage')}</span>
            </Link>
          )}
        </div>

        <div className={styles.contentRow}>
          {children}
        </div>

        {(showBackButton || showNextButton) && (
          <div className={footerClassName}>
            {showBackButton && (
              <button 
                className={`${styles.backButton} ${isLoading ? styles.disabled : ''}`}
                onClick={handleBackClick}
                disabled={isLoading}
                aria-disabled={isLoading}
              >
                <img 
                  className={styles.buttonIcon} 
                  alt="" 
                  src="/icons/18/misc/chevron-left-black.svg" 
                />
                <span>{backButtonText}</span>
              </button>
            )}

            {showNextButton && (
              <button 
                className={`${styles.nextButton} ${
                  isLoading ? styles.verifying : ''
                } ${
                  isNextButtonDisabled ? styles.disabled : ''
                }`}
                onClick={handleNextClick}
                disabled={isNextButtonDisabled || isLoading}
                aria-disabled={isNextButtonDisabled || isLoading}
              >
                <span>{nextButtonText}</span>
                <img 
                  className={styles.buttonIcon} 
                  alt="" 
                  src="/icons/18/misc/chevron-right-white.svg" 
                />
                {isLoading && (
                  <div className={styles.verifyingAnimation} />
                )}
              </button>
            )}
          </div>
        )}
      </div>

      <div className={styles.bottomSection}>
        <LanguageSelector disabled={isLoading} />
        <div className={styles.poweredByAllegory}>
          <span>{t('powered_by')}</span>
          <img src="/logos/allegory-logo-gray.svg" alt="Allegory" />
        </div>
      </div>
    </div>
  );
};

AuthLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  showHomeButton: PropTypes.bool,
  showBackButton: PropTypes.bool,
  showNextButton: PropTypes.bool,
  onBackClick: PropTypes.func,
  onNextClick: PropTypes.func,
  nextButtonText: PropTypes.string,
  backButtonText: PropTypes.string,
  isNextButtonDisabled: PropTypes.bool,
  isVerifying: PropTypes.bool,
  alignNextButtonRight: PropTypes.bool,
};

export default React.memo(AuthLayout);