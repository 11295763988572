// We'll use ColumnChart as base but create a new component for this specific use case
// /charts/v2/types/MetricsChart.js
import React from 'react';
import {
  BarChart as RechartsBarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';
import ChartWrapper from '../core/ChartWrapper';
import ChartContainer from '../core/ChartContainer';
import styles from './MetricsChart.module.css';

const MetricsChart = ({
  data,
  title,
  subtitle,
  height = 300,
}) => {
  // Transform data for the chart
  const chartData = data.metrics.map(metric => ({
    name: metric.label,
    value: metric.categories[0].value,
    color: metric.color
  }));

  const InsightsSection = () => (
    <div className={styles.insightsContainer}>
      {data.insights.map((insight, index) => (
        <div key={index} className={styles.insightCard}>
          <h4 className={styles.insightTitle}>{insight.title}</h4>
          <ul className={styles.insightList}>
            {insight.items.map((item, itemIndex) => (
              <li key={itemIndex} className={styles.insightItem}>{item}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );

  return (
    <ChartWrapper title={title} subtitle={subtitle}>
      <div className={styles.chartWithInsights}>
        <div className={styles.chartSection}>
          <ChartContainer height={height}>
            <RechartsBarChart
              data={chartData}
              margin={{ top: 10, right: 30, left: 60, bottom: 10 }}
              barSize={60}
            >
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis
                dataKey="name"
                axisLine={false}
                tickLine={false}
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                tickFormatter={(value) => `${value.toLocaleString()} lines`}
                tick={{ fontSize: 12, dx: -10 }}
              />
              <Tooltip
                formatter={(value) => [`${value.toLocaleString()} lines`]}
              />
              {chartData.map((entry, index) => (
                <Bar
                  key={index}
                  dataKey="value"
                  fill={entry.color}
                  radius={0}
                  name={entry.name}
                />
              ))}
            </RechartsBarChart>
          </ChartContainer>
        </div>
        <InsightsSection />
      </div>
    </ChartWrapper>
  );
};

export default MetricsChart;