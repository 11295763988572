// File: frontend/src/pages/EmbeddedInsurance/components/common/CodeBlock.js

import React, { useState } from 'react';
import styles from './styles/CodeBlock.module.css';

const CodeBlock = ({ code, language = 'json', title }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(code);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className={styles.codeBlockContainer}>
      {title && (
        <div className={styles.codeBlockHeader}>
          <span className={styles.codeBlockTitle}>{title}</span>
          <div className={styles.codeBlockLanguage}>{language}</div>
        </div>
      )}
      <div className={styles.codeBlock}>
        <pre className={styles.pre}>
          <code className={styles.code}>{code}</code>
        </pre>
        <button 
          className={styles.copyButton} 
          onClick={handleCopy}
          aria-label="Copy code"
        >
          {copied ? (
            <i className="fas fa-check"></i>
          ) : (
            <i className="fas fa-copy"></i>
          )}
        </button>
      </div>
    </div>
  );
};

export default CodeBlock;