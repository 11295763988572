// File: frontend/src/pages/PropertyDetail/components/PropertyOwnership/index.js

import React from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import styles from './PropertyOwnership.module.css';

// Helper function to format date
const formatDate = (dateString) => {
  if (!dateString) return 'N/A';
  
  const date = new Date(dateString);
  
  // Check if date is valid
  if (isNaN(date.getTime())) return 'Invalid Date';
  
  return date.toLocaleDateString();
};

// Helper function to format currency
const formatCurrency = (amount, currency = 'USD') => {
  if (amount === null || amount === undefined) return 'N/A';
  
  return new Intl.NumberFormat('en-US', { 
    style: 'currency', 
    currency: currency 
  }).format(amount);
};

const PropertyOwnership = ({ property }) => {
  const { t } = useLanguage();

  return (
    <div className={styles.container}>
      <div className={styles.grid}>
        <div className={styles.card}>
          <h3 className={styles.cardTitle}>{t('ownership_information')}</h3>
          <div className={styles.cardContent}>
            {property.occupancyAndBusiness ? (
              <div className={styles.ownershipInfo}>
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('owner_occupied')}:</div>
                  <div className={styles.infoValue}>
                    {property.occupancyAndBusiness.is_owner_occupied ? t('yes') : t('no')}
                  </div>
                </div>
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('occupancy_type')}:</div>
                  <div className={styles.infoValue}>{property.occupancyAndBusiness.occupancy_type}</div>
                </div>
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('rental_situation')}:</div>
                  <div className={styles.infoValue}>{property.occupancyAndBusiness.rental_situation}</div>
                </div>
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('ownership_type')}:</div>
                  <div className={styles.infoValue}>{property.occupancyAndBusiness.ownership_type}</div>
                </div>
                {property.occupancyAndBusiness.number_of_rented_units > 0 && (
                  <div className={styles.infoGroup}>
                    <div className={styles.infoLabel}>{t('number_of_rented_units')}:</div>
                    <div className={styles.infoValue}>{property.occupancyAndBusiness.number_of_rented_units}</div>
                  </div>
                )}
                {property.occupancyAndBusiness.number_of_rented_rooms > 0 && (
                  <div className={styles.infoGroup}>
                    <div className={styles.infoLabel}>{t('number_of_rented_rooms')}:</div>
                    <div className={styles.infoValue}>{property.occupancyAndBusiness.number_of_rented_rooms}</div>
                  </div>
                )}
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('multi_unit')}:</div>
                  <div className={styles.infoValue}>
                    {property.occupancyAndBusiness.is_multi_unit ? t('yes') : t('no')}
                  </div>
                </div>
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('in_law_apartment')}:</div>
                  <div className={styles.infoValue}>
                    {property.occupancyAndBusiness.has_in_law_apartment ? t('yes') : t('no')}
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.noData}>{t('no_ownership_information_available')}</div>
            )}
          </div>
        </div>
        
        <div className={styles.card}>
          <h3 className={styles.cardTitle}>{t('mortgage_information')}</h3>
          <div className={styles.cardContent}>
            {property.mortgage ? (
              <div className={styles.mortgageInfo}>
                <div className={styles.mortgageAmount}>
                  {formatCurrency(property.mortgage.mortgage_amount, property.mortgage.mortgage_amount_currency)}
                </div>
                
                <div className={styles.mortgageDetails}>
                  <div className={styles.mortgageDetail}>
                    <span className={styles.mortgageDetailLabel}>{t('lender')}:</span>
                    <span className={styles.mortgageDetailValue}>
                      {property.mortgage.lender_name}
                    </span>
                  </div>
                  
                  <div className={styles.mortgageDetail}>
                    <span className={styles.mortgageDetailLabel}>{t('start_date')}:</span>
                    <span className={styles.mortgageDetailValue}>
                      {formatDate(property.mortgage.mortgage_start_date)}
                    </span>
                  </div>
                  
                  <div className={styles.mortgageDetail}>
                    <span className={styles.mortgageDetailLabel}>{t('term')}:</span>
                    <span className={styles.mortgageDetailValue}>
                      {property.mortgage.mortgage_term} {t('months')}
                    </span>
                  </div>
                  
                  <div className={styles.mortgageDetail}>
                    <span className={styles.mortgageDetailLabel}>{t('interest_rate')}:</span>
                    <span className={styles.mortgageDetailValue}>
                      {property.mortgage.interest_rate}%
                    </span>
                  </div>
                  
                  <div className={styles.mortgageDetail}>
                    <span className={styles.mortgageDetailLabel}>{t('mortgage_type')}:</span>
                    <span className={styles.mortgageDetailValue}>
                      {property.mortgage.mortgage_type}
                    </span>
                  </div>
                  
                  <div className={styles.mortgageDetail}>
                    <span className={styles.mortgageDetailLabel}>{t('primary_mortgage')}:</span>
                    <span className={styles.mortgageDetailValue}>
                      {property.mortgage.is_primary_mortgage ? t('yes') : t('no')}
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.noData}>{t('no_mortgage_information_available')}</div>
            )}
          </div>
        </div>
      </div>
      
      {property.occupants && property.occupants.length > 0 && (
        <div className={styles.card}>
          <h3 className={styles.cardTitle}>{t('property_occupants')}</h3>
          <div className={styles.cardContent}>
            <div className={styles.occupantsGrid}>
              {property.occupants.map((occupant, index) => (
                <div key={occupant.occupant_id || index} className={styles.occupantItem}>
                  <div className={styles.occupantHeader}>
                    <div className={styles.occupantName}>
                      {occupant.first_name} {occupant.last_name}
                    </div>
                    {occupant.is_primary_resident && (
                      <div className={styles.primaryBadge}>{t('primary')}</div>
                    )}
                  </div>
                  
                  <div className={styles.occupantDetails}>
                    <div className={styles.occupantDetail}>
                      <span className={styles.occupantDetailLabel}>{t('date_of_birth')}:</span>
                      <span className={styles.occupantDetailValue}>
                        {formatDate(occupant.date_of_birth)}
                      </span>
                    </div>
                    <div className={styles.occupantDetail}>
                      <span className={styles.occupantDetailLabel}>{t('gender')}:</span>
                      <span className={styles.occupantDetailValue}>{occupant.gender}</span>
                    </div>
                    <div className={styles.occupantDetail}>
                      <span className={styles.occupantDetailLabel}>{t('marital_status')}:</span>
                      <span className={styles.occupantDetailValue}>{occupant.marital_status}</span>
                    </div>
                    <div className={styles.occupantDetail}>
                      <span className={styles.occupantDetailLabel}>{t('smoker')}:</span>
                      <span className={styles.occupantDetailValue}>
                        {occupant.is_smoker ? t('yes') : t('no')}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      
      <div className={styles.businessSection}>
        {property.occupancyAndBusiness && property.occupancyAndBusiness.business_info_type && (
          <div className={styles.card}>
            <h3 className={styles.cardTitle}>{t('business_information')}</h3>
            <div className={styles.cardContent}>
              <div className={styles.businessInfo}>
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('business_info_type')}:</div>
                  <div className={styles.infoValue}>{property.occupancyAndBusiness.business_info_type}</div>
                </div>
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('number_of_units')}:</div>
                  <div className={styles.infoValue}>{property.occupancyAndBusiness.number_of_units}</div>
                </div>
                {/* Additional business info would be displayed here */}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PropertyOwnership;