// File: frontend/src/pages/SuperAdmin/components/ProductManagement/ProductDetail/index.js

import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from './ProductDetail.module.css';

const ProductDetail = ({ productId, onBack, onViewFeatures }) => {
  const { t } = useLanguage();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('overview');

  useEffect(() => {
    const fetchProductDetails = async () => {
      setLoading(true);
      setError(null);
      
      try {
        // This would be replaced with an actual API call
        // const response = await productService.getProductDetails(productId);
        
        // Mock data for now
        await new Promise(resolve => setTimeout(resolve, 800));
        
        const mockProduct = {
          product_id: productId,
          enterprise_id: 'ent-12345',
          product_name: 'Analytics Dashboard',
          product_description: 'Comprehensive analytics dashboard with real-time data visualization and reporting capabilities. Includes customizable widgets, data export options, and automated insights.',
          product_category: 'analytics',
          product_status: 'active',
          pricing_details: {
            base_price: 99.99,
            currency: 'USD',
            billing_cycle: 'monthly',
            setup_fee: 199.99,
            discount_options: [
              {
                type: 'annual',
                percentage: 15
              },
              {
                type: 'volume',
                threshold: 10,
                percentage: 10
              }
            ]
          },
          technical_specifications: {
            platform: 'web',
            requirements: 'Modern browser with JavaScript enabled',
            api_access: true,
            data_storage: '50GB per account',
            uptime_sla: '99.9%',
            backup_frequency: 'Daily'
          },
          created_at: '2023-01-20T10:00:00Z',
          updated_at: '2023-06-15T14:30:00Z',
          created_by: 'admin-user-1',
          updated_by: 'admin-user-2',
          
          // Additional related data
          enterprise: {
            enterprise_id: 'ent-12345',
            enterprise_name: 'Acme Corporation',
            enterprise_status: 'active'
          },
          
          features: [
            {
              feature_id: 'feat-12345',
              feature_name: 'Real-time Dashboard',
              feature_type: 'core',
              feature_status: 'active',
              feature_description: 'Real-time data visualization with automatic updates'
            },
            {
              feature_id: 'feat-23456',
              feature_name: 'Custom Reports',
              feature_type: 'core',
              feature_status: 'active',
              feature_description: 'Create and save custom reports with various visualization options'
            },
            {
              feature_id: 'feat-34567',
              feature_name: 'Data Export',
              feature_type: 'core',
              feature_status: 'active',
              feature_description: 'Export data in multiple formats (CSV, Excel, PDF)'
            },
            {
              feature_id: 'feat-45678',
              feature_name: 'API Integration',
              feature_type: 'advanced',
              feature_status: 'active',
              feature_description: 'Connect to external systems via REST API'
            },
            {
              feature_id: 'feat-56789',
              feature_name: 'AI Insights',
              feature_type: 'premium',
              feature_status: 'active',
              feature_description: 'Automated insights and anomaly detection using AI'
            }
          ],
          
          subscriptions: [
            {
              subscription_id: 'subs-12345',
              enterprise_id: 'ent-23456',
              enterprise_name: 'Global Industries',
              subscription_status: 'active',
              subscription_start_date: '2023-02-01',
              subscription_end_date: '2024-01-31'
            },
            {
              subscription_id: 'subs-23456',
              enterprise_id: 'ent-34567',
              enterprise_name: 'Tech Solutions',
              subscription_status: 'active',
              subscription_start_date: '2023-03-15',
              subscription_end_date: '2024-03-14'
            },
            {
              subscription_id: 'subs-34567',
              enterprise_id: 'ent-45678',
              enterprise_name: 'Innovative Systems',
              subscription_status: 'active',
              subscription_start_date: '2023-04-10',
              subscription_end_date: '2024-04-09'
            }
          ]
        };
        
        setProduct(mockProduct);
      } catch (err) {
        console.error('Error fetching product details:', err);
        setError(t('failed_to_load_product_details'));
      } finally {
        setLoading(false);
      }
    };
    
    if (productId) {
      fetchProductDetails();
    }
  }, [productId, t]);

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }).format(date);
  };

  const formatCurrency = (price, currency) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency || 'USD'
    }).format(price);
  };

  const getStatusBadgeClass = (status) => {
    switch (status) {
      case 'active':
        return styles.statusActive;
      case 'inactive':
        return styles.statusInactive;
      case 'development':
        return styles.statusDevelopment;
      default:
        return '';
    }
  };

  const getFeatureTypeClass = (type) => {
    switch (type) {
      case 'core':
        return styles.typeCore;
      case 'advanced':
        return styles.typeAdvanced;
      case 'premium':
        return styles.typePremium;
      default:
        return '';
    }
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <div className={styles.spinner}></div>
        <p>{t('loading_product_details')}</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.error}>
        <p>{error}</p>
        <button className={styles.backButton} onClick={onBack}>
          {t('back_to_products')}
        </button>
      </div>
    );
  }

  if (!product) {
    return (
      <div className={styles.notFound}>
        <p>{t('product_not_found')}</p>
        <button className={styles.backButton} onClick={onBack}>
          {t('back_to_products')}
        </button>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <h2 className={styles.productName}>{product.product_name}</h2>
          <div className={styles.productId}>ID: {product.product_id}</div>
          <div className={styles.statusContainer}>
            <span className={`${styles.statusBadge} ${getStatusBadgeClass(product.product_status)}`}>
              {product.product_status}
            </span>
            <span className={styles.categoryBadge}>{product.product_category}</span>
          </div>
        </div>
        <div className={styles.headerActions}>
          <button className={styles.backButton} onClick={onBack}>
            <i className="fas fa-arrow-left"></i>
            <span>{t('back')}</span>
          </button>
          <button className={styles.featuresButton} onClick={onViewFeatures}>
            <i className="fas fa-puzzle-piece"></i>
            <span>{t('manage_features')}</span>
          </button>
          <button className={styles.editButton}>
            <i className="fas fa-edit"></i>
            <span>{t('edit')}</span>
          </button>
        </div>
      </div>
      
      <div className={styles.tabs}>
        <button 
          className={`${styles.tab} ${activeTab === 'overview' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('overview')}
        >
          {t('overview')}
        </button>
        <button 
          className={`${styles.tab} ${activeTab === 'features' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('features')}
        >
          {t('features')}
        </button>
        <button 
          className={`${styles.tab} ${activeTab === 'pricing' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('pricing')}
        >
          {t('pricing')}
        </button>
        <button 
          className={`${styles.tab} ${activeTab === 'subscriptions' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('subscriptions')}
        >
          {t('subscriptions')}
        </button>
        <button 
          className={`${styles.tab} ${activeTab === 'technical' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('technical')}
        >
          {t('technical_specs')}
        </button>
      </div>
      
      <div className={styles.tabContent}>
        {activeTab === 'overview' && (
          <div className={styles.overviewTab}>
            <div className={styles.section}>
              <h3 className={styles.sectionTitle}>{t('product_information')}</h3>
              <div className={styles.infoGrid}>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('product_id')}</div>
                  <div className={styles.infoValue}>{product.product_id}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('product_name')}</div>
                  <div className={styles.infoValue}>{product.product_name}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('category')}</div>
                  <div className={styles.infoValue}>{product.product_category}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('status')}</div>
                  <div className={styles.infoValue}>
                    <span className={`${styles.statusBadge} ${getStatusBadgeClass(product.product_status)}`}>
                      {product.product_status}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            
            <div className={styles.section}>
              <h3 className={styles.sectionTitle}>{t('description')}</h3>
              <p className={styles.description}>{product.product_description}</p>
            </div>
            
            <div className={styles.section}>
              <h3 className={styles.sectionTitle}>{t('enterprise_information')}</h3>
              <div className={styles.infoGrid}>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('enterprise_id')}</div>
                  <div className={styles.infoValue}>{product.enterprise_id}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('enterprise_name')}</div>
                  <div className={styles.infoValue}>{product.enterprise.enterprise_name}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('enterprise_status')}</div>
                  <div className={styles.infoValue}>
                    <span className={`${styles.statusBadge} ${getStatusBadgeClass(product.enterprise.enterprise_status)}`}>
                      {product.enterprise.enterprise_status}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            
            <div className={styles.section}>
              <h3 className={styles.sectionTitle}>{t('audit_information')}</h3>
              <div className={styles.infoGrid}>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('created_at')}</div>
                  <div className={styles.infoValue}>{formatDate(product.created_at)}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('created_by')}</div>
                  <div className={styles.infoValue}>{product.created_by}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('updated_at')}</div>
                  <div className={styles.infoValue}>{formatDate(product.updated_at)}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('updated_by')}</div>
                  <div className={styles.infoValue}>{product.updated_by}</div>
                </div>
              </div>
            </div>
          </div>
        )}
        
        {activeTab === 'features' && (
          <div className={styles.featuresTab}>
            <div className={styles.section}>
              <div className={styles.sectionHeader}>
                <h3 className={styles.sectionTitle}>{t('product_features')}</h3>
                <button className={styles.addFeatureButton}>
                  <i className="fas fa-plus"></i>
                  <span>{t('add_feature')}</span>
                </button>
              </div>
              
              {product.features && product.features.length > 0 ? (
                <div className={styles.featuresList}>
                  {product.features.map((feature) => (
                    <div key={feature.feature_id} className={styles.featureItem}>
                      <div className={styles.featureHeader}>
                        <div className={styles.featureName}>{feature.feature_name}</div>
                        <div className={styles.featureBadges}>
                          <span className={`${styles.featureTypeBadge} ${getFeatureTypeClass(feature.feature_type)}`}>
                            {feature.feature_type}
                          </span>
                          <span className={`${styles.statusBadge} ${getStatusBadgeClass(feature.feature_status)}`}>
                            {feature.feature_status}
                          </span>
                        </div>
                      </div>
                      <div className={styles.featureDescription}>
                        {feature.feature_description}
                      </div>
                      <div className={styles.featureActions}>
                        <button className={styles.featureActionButton}>
                          <i className="fas fa-edit"></i>
                          <span>{t('edit')}</span>
                        </button>
                        <button className={styles.featureActionButton}>
                          <i className="fas fa-trash-alt"></i>
                          <span>{t('delete')}</span>
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className={styles.emptyState}>
                  <p>{t('no_features_found')}</p>
                </div>
              )}
            </div>
          </div>
        )}
        
        {activeTab === 'pricing' && (
          <div className={styles.pricingTab}>
            <div className={styles.section}>
              <h3 className={styles.sectionTitle}>{t('pricing_information')}</h3>
              <div className={styles.pricingCard}>
                <div className={styles.pricingHeader}>
                  <div className={styles.pricingAmount}>
                    {formatCurrency(product.pricing_details.base_price, product.pricing_details.currency)}
                    <span className={styles.billingCycle}>/{product.pricing_details.billing_cycle}</span>
                  </div>
                </div>
                <div className={styles.pricingDetails}>
                  <div className={styles.pricingDetail}>
                    <span className={styles.pricingDetailLabel}>{t('setup_fee')}:</span>
                    <span className={styles.pricingDetailValue}>
                      {formatCurrency(product.pricing_details.setup_fee, product.pricing_details.currency)}
                    </span>
                  </div>
                  <div className={styles.pricingDetail}>
                    <span className={styles.pricingDetailLabel}>{t('billing_cycle')}:</span>
                    <span className={styles.pricingDetailValue}>{product.pricing_details.billing_cycle}</span>
                  </div>
                  <div className={styles.pricingDetail}>
                    <span className={styles.pricingDetailLabel}>{t('currency')}:</span>
                    <span className={styles.pricingDetailValue}>{product.pricing_details.currency}</span>
                  </div>
                </div>
              </div>
            </div>
            
            <div className={styles.section}>
              <h3 className={styles.sectionTitle}>{t('discount_options')}</h3>
              
              {product.pricing_details.discount_options && product.pricing_details.discount_options.length > 0 ? (
                <div className={styles.discountsList}>
                  {product.pricing_details.discount_options.map((discount, index) => (
                    <div key={index} className={styles.discountItem}>
                      <div className={styles.discountType}>
                        {discount.type === 'annual' ? t('annual_discount') : t('volume_discount')}
                      </div>
                      <div className={styles.discountDetails}>
                        {discount.type === 'annual' ? (
                          <div className={styles.discountDescription}>
                            {t('annual_discount_description', { percentage: discount.percentage })}
                          </div>
                        ) : (
                          <div className={styles.discountDescription}>
                            {t('volume_discount_description', { 
                              threshold: discount.threshold,
                              percentage: discount.percentage 
                            })}
                          </div>
                        )}
                      </div>
                      <div className={styles.discountValue}>
                        {discount.percentage}%
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className={styles.emptyState}>
                  <p>{t('no_discounts_available')}</p>
                </div>
              )}
            </div>
          </div>
        )}
        
        {activeTab === 'subscriptions' && (
          <div className={styles.subscriptionsTab}>
            <div className={styles.section}>
              <h3 className={styles.sectionTitle}>{t('active_subscriptions')}</h3>
              
              {product.subscriptions && product.subscriptions.length > 0 ? (
                <div className={styles.subscriptionsList}>
                  <table className={styles.subscriptionsTable}>
                    <thead>
                      <tr>
                        <th>{t('subscription_id')}</th>
                        <th>{t('enterprise')}</th>
                        <th>{t('status')}</th>
                        <th>{t('start_date')}</th>
                        <th>{t('end_date')}</th>
                        <th>{t('actions')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {product.subscriptions.map((subscription) => (
                        <tr key={subscription.subscription_id}>
                          <td>{subscription.subscription_id}</td>
                          <td>{subscription.enterprise_name}</td>
                          <td>
                            <span className={`${styles.statusBadge} ${getStatusBadgeClass(subscription.subscription_status)}`}>
                              {subscription.subscription_status}
                            </span>
                          </td>
                          <td>{formatDate(subscription.subscription_start_date)}</td>
                          <td>{formatDate(subscription.subscription_end_date)}</td>
                          <td>
                            <div className={styles.tableActions}>
                              <button className={styles.actionButton}>
                                <i className="fas fa-eye"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className={styles.emptyState}>
                  <p>{t('no_subscriptions_found')}</p>
                </div>
              )}
            </div>
          </div>
        )}
        
        {activeTab === 'technical' && (
          <div className={styles.technicalTab}>
            <div className={styles.section}>
              <h3 className={styles.sectionTitle}>{t('technical_specifications')}</h3>
              <div className={styles.infoGrid}>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('platform')}</div>
                  <div className={styles.infoValue}>{product.technical_specifications.platform}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('requirements')}</div>
                  <div className={styles.infoValue}>{product.technical_specifications.requirements}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('api_access')}</div>
                  <div className={styles.infoValue}>
                    {product.technical_specifications.api_access ? t('yes') : t('no')}
                  </div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('data_storage')}</div>
                  <div className={styles.infoValue}>{product.technical_specifications.data_storage}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('uptime_sla')}</div>
                  <div className={styles.infoValue}>{product.technical_specifications.uptime_sla}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoLabel}>{t('backup_frequency')}</div>
                  <div className={styles.infoValue}>{product.technical_specifications.backup_frequency}</div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductDetail;