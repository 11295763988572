// File: frontend/src/pages/Contact/Contact.js

import React from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import ContactForm from '../../components/form/ContactForm/ContactForm';
import LandingButton from '../../components/Button/LandingButton';
import Divider from '../../components/Divider/Divider';
import styles from './Contact.module.css';

const Contact = () => {
  const { t } = useLanguage();

  const handleSubmit = (formData) => {
    // Handle form submission logic here
    console.log(formData);
  };

  return (
    <div className={styles.contactContainer}>
      <h1 className={styles.title}>{t('contact_us')}</h1>

      <div className={styles.contentWrapper}>
        <section className={styles.calendlySection}>
          <h2 className={styles.sectionTitle}>{t('schedule_a_meeting')}</h2>
          <p className={styles.description}>{t('schedule_a_meeting_description')}</p>
          <LandingButton
            variant="primary"
            size="large"
            href="https://calendly.com/meet-with-allegory/30min"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('schedule_on_calendly')}
          </LandingButton>
        </section>
        <Divider vertical={true} childrenKey="auth.signIn.or" />
        <section className={styles.formSection}>
          <h2 className={styles.sectionTitle}>{t('send_us_a_message')}</h2>
          <ContactForm onSubmit={handleSubmit} />
        </section>
      </div>
    </div>
  );
};

export default Contact;

// Ensure you have this setup - calendly is sending back the event details.
// https://www.allegory.app/?assigned_to=Onur%20Gungor&event_type_uuid=66af7def-07a1-4555-aeba-7605b7746c76&event_type_name=30%20Minute%20Meeting&event_start_time=2024-10-23T11%3A00%3A00-04%3A00&event_end_time=2024-10-23T11%3A30%3A00-04%3A00&invitee_uuid=0c15ace8-e530-4433-94b8-86201e71d235&invitee_full_name=Onur%20Gungor&invitee_email=onurgungor176%40hotmail.com&answer_1=This%20is%20a%20test%20invite.