// File: frontend/src/pages/TripInsight/components/TripStatistics/index.js

import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import styles from './TripStatistics.module.css';

const TripStatistics = () => {
  const { t } = useLanguage();
  const [stats, setStats] = useState({
    totalTrips: 0,
    completedTrips: 0,
    totalDistance: 0,
    totalDuration: 0
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch statistics data
    const fetchStats = async () => {
      try {
        // This would be replaced with an actual API call
        await new Promise(resolve => setTimeout(resolve, 500));
        
        setStats({
          totalTrips: 28750,
          completedTrips: 27890,
          totalDistance: 345000,
          totalDuration: 12500
        });
      } catch (error) {
        console.error('Error fetching statistics:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, []);

  // Helper function to format duration
  const formatDuration = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    
    return `${hours}h ${minutes}m`;
  };

  return (
    <div className={styles.container}>
      {loading ? (
        <div className={styles.loading}>{t('loading_statistics')}</div>
      ) : (
        <div className={styles.statsGrid}>
          <div className={`${styles.statCard} ${styles.total}`}>
            <div className={styles.statValue}>{stats.totalTrips.toLocaleString()}</div>
            <div className={styles.statLabel}>{t('total_trips')}</div>
          </div>
          
          <div className={`${styles.statCard} ${styles.completed}`}>
            <div className={styles.statValue}>{stats.completedTrips.toLocaleString()}</div>
            <div className={styles.statLabel}>{t('completed_trips')}</div>
          </div>
          
          <div className={`${styles.statCard} ${styles.distance}`}>
            <div className={styles.statValue}>{stats.totalDistance.toLocaleString()} km</div>
            <div className={styles.statLabel}>{t('total_distance')}</div>
          </div>
          
          <div className={`${styles.statCard} ${styles.duration}`}>
            <div className={styles.statValue}>{formatDuration(stats.totalDuration)}</div>
            <div className={styles.statLabel}>{t('total_duration')}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TripStatistics;