// File: frontend/src/pages/InvestorPitch/components/navigation/SlideQuickNav.js

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { motion, AnimatePresence } from 'framer-motion';
import { useLanguage } from '../../../../contexts/LanguageContext';
import styles from './SlideQuickNav.module.css';

const Logo = () => (
  <svg 
    width="32" 
    height="32" 
    viewBox="0 0 32 40" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    className="text-white"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 34.4415L11.2851 2.94549C11.7867 1.53302 12.0374 0.710535 12.0374 0.477995C12.0374 0.245456 12.0288 0.103351 12.0115 0.0516752L12.0374 0C13.1616 0.0516754 14.355 0.0775128 15.6175 0.0775128C16.6553 0.0775128 17.9005 0.0602879 19.3533 0.0258376L19.3792 0.0775128C19.3447 0.146413 19.3274 0.24115 19.3274 0.361726C19.3274 0.689004 19.5868 1.55886 20.1056 2.97132L31.6242 34.519C31.5075 34.5132 27.8679 34.5025 23.0964 34.4908L15.6962 8.65487L8.50098 34.4586C3.66562 34.4486 0 34.4415 0 34.4415ZM8.50098 34.4586L7.0509 39.6589H24.5767L23.0964 34.4908C18.5694 34.4798 13.0235 34.4679 8.50098 34.4586Z"
      fill="currentColor"
    />
  </svg>
);

const SlideQuickNav = ({ currentSlide, totalSlides, slides, onSlideSelect }) => {
  const { t } = useLanguage();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.container}>
        <button 
          className={styles.counter}
          onClick={() => setIsOpen(!isOpen)}
          aria-label={t('investor_pitch.navigation.aria.open_slide_nav')}
        >
          {currentSlide + 1} / {totalSlides}
        </button>

        <AnimatePresence>
          {isOpen && (
            <motion.div 
              className={styles.menu}
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 10 }}
            >
              {slides.map((slide, index) => (
                <motion.button
                  key={slide.id}
                  className={styles.menuItem}
                  onClick={() => {
                    onSlideSelect(index);
                    setIsOpen(false);
                  }}
                  variants={{
                    initial: { opacity: 0, x: -20 },
                    animate: { opacity: 1, x: 0 },
                  }}
                  transition={{ delay: index * 0.03 }}
                >
                  {slide.title}
                </motion.button>
              ))}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <div className={styles.logoContainer}>
        <Logo />
      </div>
    </div>
  );
};

SlideQuickNav.propTypes = {
  currentSlide: PropTypes.number.isRequired,
  totalSlides: PropTypes.number.isRequired,
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  onSlideSelect: PropTypes.func.isRequired,
};

export default SlideQuickNav;