// File: frontend/src/pages/TripInsight/components/ReportGenerator/index.js

import React, { useState } from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import styles from './ReportGenerator.module.css';

const ReportGenerator = ({ isOpen, onClose }) => {
  const { t } = useLanguage();
  const [reportType, setReportType] = useState('summary');
  const [timeRange, setTimeRange] = useState('month');
  const [includeCharts, setIncludeCharts] = useState(true);
  const [fileFormat, setFileFormat] = useState('csv');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  // Mock user and vehicle data
  const users = [
    { id: 'user-1', name: 'John Doe' },
    { id: 'user-2', name: 'Jane Smith' },
    { id: 'user-3', name: 'Robert Johnson' },
    { id: 'user-4', name: 'Emily Davis' },
    { id: 'user-5', name: 'Michael Wilson' }
  ];

  const vehicles = [
    { id: 'vehicle-1', name: '2022 Toyota Camry' },
    { id: 'vehicle-2', name: '2021 Honda Accord' },
    { id: 'vehicle-3', name: '2023 Tesla Model 3' },
    { id: 'vehicle-4', name: '2020 Ford F-150' },
    { id: 'vehicle-5', name: '2022 Chevrolet Malibu' }
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(false);
    
    try {
      // This would be replaced with an actual API call
      // const response = await reportService.generateReport({
      //   reportType,
      //   timeRange,
      //   includeCharts,
      //   fileFormat,
      //   selectedUsers,
      //   selectedVehicles
      // });
      
      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 1500));
      
      // Simulate success
      setSuccess(true);
      
      // In a real implementation, you might want to close the modal after a delay
      setTimeout(() => {
        onClose();
        setSuccess(false);
      }, 2000);
    } catch (err) {
      console.error('Error generating report:', err);
      setError(t('failed_to_generate_report'));
    } finally {
      setLoading(false);
    }
  };

  const toggleUser = (userId) => {
    setSelectedUsers(prev => 
      prev.includes(userId) 
        ? prev.filter(id => id !== userId)
        : [...prev, userId]
    );
  };

  const toggleVehicle = (vehicleId) => {
    setSelectedVehicles(prev => 
      prev.includes(vehicleId) 
        ? prev.filter(id => id !== vehicleId)
        : [...prev, vehicleId]
    );
  };

  const selectAllUsers = () => {
    setSelectedUsers(users.map(user => user.id));
  };

  const deselectAllUsers = () => {
    setSelectedUsers([]);
  };

  const selectAllVehicles = () => {
    setSelectedVehicles(vehicles.map(vehicle => vehicle.id));
  };

  const deselectAllVehicles = () => {
    setSelectedVehicles([]);
  };

  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContainer}>
        <div className={styles.modalHeader}>
          <h2 className={styles.modalTitle}>{t('generate_report')}</h2>
          <button className={styles.closeButton} onClick={onClose}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </div>
        
        <div className={styles.modalContent}>
          {success ? (
            <div className={styles.successMessage}>
              <div className={styles.successIcon}>✓</div>
              <h3 className={styles.successTitle}>{t('report_generation_started')}</h3>
              <p className={styles.successText}>{t('report_generation_success_message')}</p>
            </div>
          ) : (
            <form onSubmit={handleSubmit} className={styles.reportForm}>
              {error && <div className={styles.errorMessage}>{error}</div>}
              
              <div className={styles.formSection}>
                <h3 className={styles.sectionTitle}>{t('report_type')}</h3>
                <div className={styles.reportTypeOptions}>
                  <label className={`${styles.reportTypeOption} ${reportType === 'summary' ? styles.selectedType : ''}`}>
                    <input
                      type="radio"
                      name="reportType"
                      value="summary"
                      checked={reportType === 'summary'}
                      onChange={() => setReportType('summary')}
                      className={styles.radioInput}
                    />
                    <div className={styles.reportTypeIcon}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                        <polyline points="14 2 14 8 20 8"></polyline>
                        <line x1="16" y1="13" x2="8" y2="13"></line>
                        <line x1="16" y1="17" x2="8" y2="17"></line>
                        <polyline points="10 9 9 9 8 9"></polyline>
                      </svg>
                    </div>
                    <div className={styles.reportTypeContent}>
                      <div className={styles.reportTypeName}>{t('summary_report')}</div>
                      <div className={styles.reportTypeDescription}>{t('summary_report_description')}</div>
                    </div>
                  </label>
                  
                  <label className={`${styles.reportTypeOption} ${reportType === 'behavior' ? styles.selectedType : ''}`}>
                    <input
                      type="radio"
                      name="reportType"
                      value="behavior"
                      checked={reportType === 'behavior'}
                      onChange={() => setReportType('behavior')}
                      className={styles.radioInput}
                    />
                    <div className={styles.reportTypeIcon}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                        <polyline points="22 4 12 14.01 9 11.01"></polyline>
                      </svg>
                    </div>
                    <div className={styles.reportTypeContent}>
                      <div className={styles.reportTypeName}>{t('behavior_report')}</div>
                      <div className={styles.reportTypeDescription}>{t('behavior_report_description')}</div>
                    </div>
                  </label>
                  
                  <label className={`${styles.reportTypeOption} ${reportType === 'scoring' ? styles.selectedType : ''}`}>
                    <input
                      type="radio"
                      name="reportType"
                      value="scoring"
                      checked={reportType === 'scoring'}
                      onChange={() => setReportType('scoring')}
                      className={styles.radioInput}
                    />
                    <div className={styles.reportTypeIcon}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M12 20V10"></path>
                        <path d="M18 20V4"></path>
                        <path d="M6 20v-4"></path>
                      </svg>
                    </div>
                    <div className={styles.reportTypeContent}>
                      <div className={styles.reportTypeName}>{t('scoring_report')}</div>
                      <div className={styles.reportTypeDescription}>{t('scoring_report_description')}</div>
                    </div>
                  </label>
                  
                  <label className={`${styles.reportTypeOption} ${reportType === 'route' ? styles.selectedType : ''}`}>
                    <input
                      type="radio"
                      name="reportType"
                      value="route"
                      checked={reportType === 'route'}
                      onChange={() => setReportType('route')}
                      className={styles.radioInput}
                    />
                    <div className={styles.reportTypeIcon}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <circle cx="12" cy="12" r="10"></circle>
                        <polyline points="8 12 12 16 16 12"></polyline>
                        <line x1="12" y1="8" x2="12" y2="16"></line>
                      </svg>
                    </div>
                    <div className={styles.reportTypeContent}>
                      <div className={styles.reportTypeName}>{t('route_report')}</div>
                      <div className={styles.reportTypeDescription}>{t('route_report_description')}</div>
                    </div>
                  </label>
                </div>
              </div>
              
              <div className={styles.formRow}>
                <div className={styles.formGroup}>
                  <label className={styles.formLabel}>{t('time_range')}</label>
                  <select 
                    className={styles.formSelect}
                    value={timeRange}
                    onChange={(e) => setTimeRange(e.target.value)}
                  >
                    <option value="week">{t('last_week')}</option>
                    <option value="month">{t('last_month')}</option>
                    <option value="quarter">{t('last_quarter')}</option>
                    <option value="year">{t('last_year')}</option>
                    <option value="all">{t('all_time')}</option>
                  </select>
                </div>
                
                <div className={styles.formGroup}>
                  <label className={styles.formLabel}>{t('file_format')}</label>
                  <select 
                    className={styles.formSelect}
                    value={fileFormat}
                    onChange={(e) => setFileFormat(e.target.value)}
                  >
                    <option value="csv">CSV</option>
                    <option value="xlsx">Excel (XLSX)</option>
                    <option value="pdf">PDF</option>
                    <option value="json">JSON</option>
                  </select>
                </div>
              </div>
              
              <div className={styles.formGroup}>
                <div className={styles.checkboxContainer}>
                  <label className={styles.checkboxLabel}>
                    <input 
                      type="checkbox" 
                      checked={includeCharts} 
                      onChange={() => setIncludeCharts(!includeCharts)}
                      className={styles.checkbox}
                    />
                    {t('include_charts_and_visualizations')}
                  </label>
                </div>
              </div>
              
              <div className={styles.formSection}>
                <div className={styles.sectionHeader}>
                  <h3 className={styles.sectionTitle}>{t('select_users')}</h3>
                  <div className={styles.selectionActions}>
                    <button 
                      type="button" 
                      className={styles.selectionAction}
                      onClick={selectAllUsers}
                    >
                      {t('select_all')}
                    </button>
                    <button 
                      type="button" 
                      className={styles.selectionAction}
                      onClick={deselectAllUsers}
                    >
                      {t('deselect_all')}
                    </button>
                  </div>
                </div>
                <div className={styles.selectionGrid}>
                  {users.map(user => (
                    <label key={user.id} className={styles.selectionItem}>
                      <input 
                        type="checkbox" 
                        checked={selectedUsers.includes(user.id)} 
                        onChange={() => toggleUser(user.id)}
                        className={styles.checkbox}
                      />
                      {user.name}
                    </label>
                  ))}
                </div>
              </div>
              
              <div className={styles.formSection}>
                <div className={styles.sectionHeader}>
                  <h3 className={styles.sectionTitle}>{t('select_vehicles')}</h3>
                  <div className={styles.selectionActions}>
                    <button 
                      type="button" 
                      className={styles.selectionAction}
                      onClick={selectAllVehicles}
                    >
                      {t('select_all')}
                    </button>
                    <button 
                      type="button" 
                      className={styles.selectionAction}
                      onClick={deselectAllVehicles}
                    >
                      {t('deselect_all')}
                    </button>
                  </div>
                </div>
                <div className={styles.selectionGrid}>
                  {vehicles.map(vehicle => (
                    <label key={vehicle.id} className={styles.selectionItem}>
                      <input 
                        type="checkbox" 
                        checked={selectedVehicles.includes(vehicle.id)} 
                        onChange={() => toggleVehicle(vehicle.id)}
                        className={styles.checkbox}
                      />
                      {vehicle.name}
                    </label>
                  ))}
                </div>
              </div>
              
              <div className={styles.formActions}>
                <button 
                  type="button" 
                  className={styles.cancelButton}
                  onClick={onClose}
                  disabled={loading}
                >
                  {t('cancel')}
                </button>
                <button 
                  type="submit" 
                  className={styles.submitButton}
                  disabled={loading}
                >
                  {loading ? t('generating') : t('generate_report')}
                  {loading && <div className={styles.buttonSpinner}></div>}
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReportGenerator;