// File: frontend/src/components/DrivingScore/DrivingScoreDistribution.js

import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import Card from '../Card/Card';
import driving_score_main_dist_data from '../../data/driving_score_main_dist_data.json';
import { hexToRGBA } from '../../utils/chartHelpers';
import styles from './DrivingScoreDistribution.module.css';

const DrivingScoreDistribution = () => {
  if (!driving_score_main_dist_data || !driving_score_main_dist_data.length) {
    return <div>No data available</div>;
  }

  return (
    <Card
      title="Allegory Driving Score Distribution"
      subtitle="Visualize the distribution of driving scores across users and trips."
    >
      <div className={styles.chartContainer}>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart
            data={driving_score_main_dist_data}
            margin={{top: 30, right: 15, left: 15, bottom: 0,}}
          >
            <CartesianGrid strokeDasharray="0 1"/>
            <XAxis dataKey="x_values" height={60} />
            <YAxis 
              type='number'
              domain={[0, 'auto']}
              allowDataOverflow 
              allowDecimals={true} 
              axisLine={true} 
              scale="linear" 
              tickFormatter={(value) => `${value}%`}
            />
            <Tooltip/>
            <Bar dataKey="value_attributes.percentage" name="Overall Driving Score (Decile)">
              {
                driving_score_main_dist_data.map((entry, index) => (
                  <Bar key={`cell-${index}`} fill={hexToRGBA(entry.value_attributes.colourHexCode, 1)}/>
                ))
              }
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div className={styles.insightContainer}>
        <div className={styles.insightIcon}>
          <img src="/icons/18/alerts/lamp.svg" alt="Insight" />
          <span>Insight</span>
        </div>
        <p className={styles.insightText}>
          Two-thirds of users in this group are categorized as Very Low Risk drivers.
        </p>
      </div>
    </Card>
  );
};

export default DrivingScoreDistribution;