// File: frontend/src/pages/PropertyInsight/components/PropertySearch/index.js

import React, { useState } from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import styles from './PropertySearch.module.css';

const PropertySearch = ({ onSearch, loading }) => {
  const { t } = useLanguage();
  const [searchField, setSearchField] = useState('address');
  const [searchQuery, setSearchQuery] = useState('');
  const [propertyType, setPropertyType] = useState('');
  const [yearBuilt, setYearBuilt] = useState('');
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!searchQuery.trim() && !propertyType && !yearBuilt) return;
    
    const searchParams = {};
    
    // Only add search field and query if they're provided
    if (searchQuery.trim()) {
      searchParams.field = searchField;
      searchParams.query = searchQuery;
    }
    
    // Add optional filters if they're provided
    if (propertyType) searchParams.property_type = propertyType;
    if (yearBuilt) searchParams.year_built = yearBuilt;
    
    onSearch(searchParams);
  };

  const handleClearFilters = () => {
    setSearchQuery('');
    setPropertyType('');
    setYearBuilt('');
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>{t('search_properties')}</h2>
      
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.formGroup}>
          <label htmlFor="searchField" className={styles.label}>
            {t('search_by')}
          </label>
          <select
            id="searchField"
            className={styles.select}
            value={searchField}
            onChange={(e) => setSearchField(e.target.value)}
            disabled={loading}
          >
            <option value="address">{t('address')}</option>
            <option value="property_id">{t('property_id')}</option>
            <option value="postal_code">{t('postal_code')}</option>
            <option value="city">{t('city')}</option>
            <option value="owner_id">{t('owner_id')}</option>
          </select>
        </div>
        
        <div className={styles.formGroup}>
          <label htmlFor="searchQuery" className={styles.label}>
            {t('search_term')}
          </label>
          <input
            id="searchQuery"
            type="text"
            className={styles.input}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder={t('enter_search_term')}
            disabled={loading}
          />
        </div>
        
        <div className={styles.formActions}>
          <button 
            type="button" 
            className={styles.advancedButton}
            onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}
          >
            {showAdvancedFilters ? t('hide_advanced_filters') : t('show_advanced_filters')}
          </button>
          
          <button 
            type="submit" 
            className={styles.button}
            disabled={loading || (!searchQuery.trim() && !propertyType && !yearBuilt)}
          >
            {loading ? t('searching') : t('search')}
          </button>
        </div>
        
        {showAdvancedFilters && (
          <div className={styles.advancedFilters}>
            <div className={styles.formGroup}>
              <label htmlFor="propertyType" className={styles.label}>
                {t('property_type')}
              </label>
              <select
                id="propertyType"
                className={styles.select}
                value={propertyType}
                onChange={(e) => setPropertyType(e.target.value)}
                disabled={loading}
              >
                <option value="">{t('select_property_type')}</option>
                <option value="Residential">{t('residential')}</option>
                <option value="Commercial">{t('commercial')}</option>
                <option value="Industrial">{t('industrial')}</option>
                <option value="Land">{t('land')}</option>
                <option value="Multi-Family">{t('multi_family')}</option>
              </select>
            </div>
            
            <div className={styles.formGroup}>
              <label htmlFor="yearBuilt" className={styles.label}>
                {t('year_built')}
              </label>
              <select
                id="yearBuilt"
                className={styles.select}
                value={yearBuilt}
                onChange={(e) => setYearBuilt(e.target.value)}
                disabled={loading}
              >
                <option value="">{t('select_year_built')}</option>
                <option value="before_1950">{t('before_1950')}</option>
                <option value="1950_1975">{t('1950_to_1975')}</option>
                <option value="1976_2000">{t('1976_to_2000')}</option>
                <option value="2001_2010">{t('2001_to_2010')}</option>
                <option value="after_2010">{t('after_2010')}</option>
              </select>
            </div>
            
            <button 
              type="button" 
              className={styles.clearButton}
              onClick={handleClearFilters}
              disabled={loading}
            >
              {t('clear_filters')}
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

export default PropertySearch;