// File: frontend/src/pages/TripDetail/components/TripVehicle/index.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../../../contexts/LanguageContext';
import styles from './TripVehicle.module.css';

const TripVehicle = ({ trip }) => {
  const { t } = useLanguage();
  const navigate = useNavigate();
  const [vehicle, setVehicle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchVehicleDetails = async () => {
      if (!trip.vehicle_id) {
        setLoading(false);
        return;
      }

      setLoading(true);
      setError(null);
      
      try {
        // This would be replaced with an actual API call
        // const response = await vehicleService.getVehicleDetails(trip.vehicle_id);
        
        // Mock data for now
        await new Promise(resolve => setTimeout(resolve, 800));
        
        const mockVehicleData = {
          vehicle_id: trip.vehicle_id,
          vin: "1HGCM82633A123456",
          license_plate: "ABC123",
          make_model_year: "TOYOTA#CAMRY#2022",
          owner_id: "owner-12345",
          is_primary_vehicle: true,
          details: {
            vehicle_year: 2022,
            vehicle_make: "TOYOTA",
            vehicle_model: "CAMRY",
            vehicle_color: "Blue",
            vehicle_body_type: "Sedan"
          }
        };
        
        setVehicle(mockVehicleData);
      } catch (err) {
        console.error('Error fetching vehicle details:', err);
        setError(t('failed_to_load_vehicle_details'));
      } finally {
        setLoading(false);
      }
    };

    fetchVehicleDetails();
  }, [trip.vehicle_id, t]);

  const handleViewVehicle = () => {
    if (vehicle) {
      navigate(`/auto-insight/vehicle/${vehicle.vehicle_id}`);
    }
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <div className={styles.loadingSpinner}></div>
        <p>{t('loading_vehicle_details')}</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.error}>
        <p>{error}</p>
      </div>
    );
  }

  if (!vehicle) {
    return (
      <div className={styles.noVehicle}>
        <p>{t('no_vehicle_associated_with_trip')}</p>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.vehicleCard}>
        <div className={styles.vehicleHeader}>
          <h3 className={styles.vehicleTitle}>
            {vehicle.details?.vehicle_year} {vehicle.details?.vehicle_make} {vehicle.details?.vehicle_model}
          </h3>
          {vehicle.is_primary_vehicle && (
            <div className={styles.primaryBadge}>{t('primary_vehicle')}</div>
          )}
        </div>
        
        <div className={styles.vehicleDetails}>
          <div className={styles.detailsGrid}>
            <div className={styles.detailItem}>
              <div className={styles.detailLabel}>{t('vehicle_id')}:</div>
              <div className={styles.detailValue}>{vehicle.vehicle_id}</div>
            </div>
            
            <div className={styles.detailItem}>
              <div className={styles.detailLabel}>{t('vin')}:</div>
              <div className={styles.detailValue}>{vehicle.vin}</div>
            </div>
            
            <div className={styles.detailItem}>
              <div className={styles.detailLabel}>{t('license_plate')}:</div>
              <div className={styles.detailValue}>{vehicle.license_plate}</div>
            </div>
            
            <div className={styles.detailItem}>
              <div className={styles.detailLabel}>{t('owner_id')}:</div>
              <div className={styles.detailValue}>{vehicle.owner_id}</div>
            </div>
            
            <div className={styles.detailItem}>
              <div className={styles.detailLabel}>{t('color')}:</div>
              <div className={styles.detailValue}>{vehicle.details?.vehicle_color}</div>
            </div>
            
            <div className={styles.detailItem}>
              <div className={styles.detailLabel}>{t('body_type')}:</div>
              <div className={styles.detailValue}>{vehicle.details?.vehicle_body_type}</div>
            </div>
          </div>
          
          <div className={styles.vehicleActions}>
            <button 
              className={styles.viewVehicleButton}
              onClick={handleViewVehicle}
            >
              {t('view_vehicle_details')}
            </button>
          </div>
        </div>
      </div>
      
      <div className={styles.tripVehicleInfo}>
        <h3 className={styles.infoTitle}>{t('trip_vehicle_information')}</h3>
        
        <div className={styles.infoGrid}>
          <div className={styles.infoItem}>
            <div className={styles.infoLabel}>{t('is_driver')}:</div>
            <div className={styles.infoValue}>{trip.is_driver ? t('yes') : t('no')}</div>
          </div>
          
          <div className={styles.infoItem}>
            <div className={styles.infoLabel}>{t('trip_type_id')}:</div>
            <div className={styles.infoValue}>{trip.trip_type_id}</div>
          </div>
          
          <div className={styles.infoItem}>
            <div className={styles.infoLabel}>{t('trip_mode_transportation_id')}:</div>
            <div className={styles.infoValue}>{trip.trip_mode_transportation_id}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TripVehicle;