// File: frontend/src/pages/UMS/UserDetail/components/UserAddresses/index.js

import React, { useState } from 'react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from './UserAddresses.module.css';

const UserAddresses = ({ user }) => {
  const { t } = useLanguage();
  const [showAddAddressForm, setShowAddAddressForm] = useState(false);

  // Check if user has addresses
  const hasAddresses = user.addresses && user.addresses.length > 0;

  const getAddressTypeText = (type) => {
    switch (type) {
      case 1: return t('home');
      case 2: return t('work');
      case 3: return t('mailing');
      case 4: return t('billing');
      case 5: return t('shipping');
      default: return t('other');
    }
  };

  const handleAddAddress = () => {
    setShowAddAddressForm(true);
  };

  const handleCancelAddAddress = () => {
    setShowAddAddressForm(false);
  };

  const handleSubmitAddress = (e) => {
    e.preventDefault();
    // Handle address submission logic here
    setShowAddAddressForm(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2 className={styles.title}>{t('user_addresses')}</h2>
        {!showAddAddressForm && (
          <button 
            className={styles.addButton}
            onClick={handleAddAddress}
          >
            {t('add_address')}
          </button>
        )}
      </div>

      {showAddAddressForm && (
        <div className={styles.formCard}>
          <h3 className={styles.formTitle}>{t('add_new_address')}</h3>
          <form onSubmit={handleSubmitAddress} className={styles.form}>
            <div className={styles.formRow}>
              <div className={styles.formGroup}>
                <label htmlFor="addressType" className={styles.label}>{t('address_type')}</label>
                <select id="addressType" className={styles.select} required>
                  <option value="">{t('select_address_type')}</option>
                  <option value="1">{t('home')}</option>
                  <option value="2">{t('work')}</option>
                  <option value="3">{t('mailing')}</option>
                  <option value="4">{t('billing')}</option>
                  <option value="5">{t('shipping')}</option>
                </select>
              </div>
              <div className={styles.formGroup}>
                <label htmlFor="isPrimary" className={styles.label}>{t('is_primary')}</label>
                <select id="isPrimary" className={styles.select}>
                  <option value="false">{t('no')}</option>
                  <option value="true">{t('yes')}</option>
                </select>
              </div>
            </div>

            <div className={styles.formRow}>
              <div className={styles.formGroup}>
                <label htmlFor="streetAddress" className={styles.label}>{t('street_address')}</label>
                <input type="text" id="streetAddress" className={styles.input} required />
              </div>
              <div className={styles.formGroup}>
                <label htmlFor="unit" className={styles.label}>{t('unit')}</label>
                <input type="text" id="unit" className={styles.input} />
              </div>
            </div>

            <div className={styles.formRow}>
              <div className={styles.formGroup}>
                <label htmlFor="city" className={styles.label}>{t('city')}</label>
                <input type="text" id="city" className={styles.input} required />
              </div>
              <div className={styles.formGroup}>
                <label htmlFor="state" className={styles.label}>{t('state_province')}</label>
                <input type="text" id="state" className={styles.input} required />
              </div>
            </div>

            <div className={styles.formRow}>
              <div className={styles.formGroup}>
                <label htmlFor="postalCode" className={styles.label}>{t('postal_code')}</label>
                <input type="text" id="postalCode" className={styles.input} required />
              </div>
              <div className={styles.formGroup}>
                <label htmlFor="country" className={styles.label}>{t('country')}</label>
                <select id="country" className={styles.select} required>
                  <option value="">{t('select_country')}</option>
                  <option value="CA">{t('canada')}</option>
                  <option value="US">{t('united_states')}</option>
                </select>
              </div>
            </div>

            <div className={styles.formActions}>
              <button 
                type="button" 
                className={styles.cancelButton}
                onClick={handleCancelAddAddress}
              >
                {t('cancel')}
              </button>
              <button type="submit" className={styles.submitButton}>
                {t('save_address')}
              </button>
            </div>
          </form>
        </div>
      )}

      {hasAddresses ? (
        <div className={styles.addressList}>
          {user.addresses.map((address, index) => (
            <div key={address.user_address_id || index} className={styles.addressCard}>
              <div className={styles.addressHeader}>
                <h3 className={styles.addressTitle}>
                  {getAddressTypeText(address.address_type)}
                </h3>
                {address.is_primary && (
                  <span className={styles.primaryBadge}>{t('primary')}</span>
                )}
              </div>
              <div className={styles.addressContent}>
                <div className={styles.addressDetails}>
                  {address.address_id && address.address_id.street_number && address.address_id.street_name && (
                    <div className={styles.addressLine}>
                      {address.address_id.street_number} {address.address_id.street_name}
                      {address.address_id.unit_number && `, ${address.address_id.unit_number}`}
                    </div>
                  )}
                  {address.address_id && address.address_id.city && address.address_id.province && (
                    <div className={styles.addressLine}>
                      {address.address_id.city}, {address.address_id.province}
                    </div>
                  )}
                  {address.address_id && address.address_id.postal_code && address.address_id.country && (
                    <div className={styles.addressLine}>
                      {address.address_id.postal_code}, {address.address_id.country}
                    </div>
                  )}
                </div>
                <div className={styles.addressMeta}>
                  {address.is_billing && (
                    <span className={styles.addressTag}>{t('billing')}</span>
                  )}
                  {address.is_shipping && (
                    <span className={styles.addressTag}>{t('shipping')}</span>
                  )}
                </div>
                <div className={styles.addressActions}>
                  <button className={styles.editAddressButton}>
                    {t('edit')}
                  </button>
                  <button className={styles.deleteAddressButton}>
                    {t('delete')}
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        !showAddAddressForm && (
          <div className={styles.noAddresses}>
            <p>{t('no_addresses_found')}</p>
            <p>{t('add_address_message')}</p>
          </div>
        )
      )}
    </div>
  );
};

export default UserAddresses;