// File: frontend/src/pages/AutoInsight/components/VehicleSearchResults/index.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../../../contexts/LanguageContext';
import styles from './VehicleSearchResults.module.css';

const VehicleSearchResults = ({ 
  results, 
  loading, 
  onPageChange, 
  currentPage, 
  totalPages, 
  totalResults 
}) => {
  const { t } = useLanguage();
  const navigate = useNavigate();

  const handleViewVehicle = (vehicleId) => {
    navigate(`/auto-insight/vehicle/${vehicleId}`);
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        {t('searching_vehicles')}
      </div>
    );
  }

  if (!results || results.length === 0) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.resultsHeader}>
        <h2 className={styles.title}>{t('search_results')}</h2>
        {totalResults > 0 && (
          <div className={styles.resultCount}>
            {t('showing_results', { 
              count: results.length, 
              total: totalResults || results.length 
            })}
          </div>
        )}
      </div>
      
      <div className={styles.resultsList}>
        {results.map((vehicle) => (
          <div key={vehicle.vehicle_id} className={styles.resultCard}>
            <div className={styles.resultInfo}>
              <div className={styles.resultRow}>
                <div className={styles.resultField}>
                  <span className={styles.fieldLabel}>{t('vehicle_id')}:</span>
                  <span className={styles.fieldValue}>{vehicle.vehicle_id}</span>
                </div>
                <div className={styles.resultField}>
                  <span className={styles.fieldLabel}>{t('vin')}:</span>
                  <span className={styles.fieldValue}>{vehicle.vin || 'N/A'}</span>
                </div>
              </div>
              
              <div className={styles.resultRow}>
                <div className={styles.resultField}>
                  <span className={styles.fieldLabel}>{t('vehicle')}:</span>
                  <span className={styles.fieldValue}>
                    {vehicle.details?.vehicle_year || ''} {vehicle.details?.vehicle_make || ''} {vehicle.details?.vehicle_model || ''}
                  </span>
                </div>
                <div className={styles.resultField}>
                  <span className={styles.fieldLabel}>{t('license_plate')}:</span>
                  <span className={styles.fieldValue}>{vehicle.license_plate || 'N/A'}</span>
                </div>
              </div>
              
              <div className={styles.resultRow}>
                <div className={styles.resultField}>
                  <span className={styles.fieldLabel}>{t('owner_id')}:</span>
                  <span className={styles.fieldValue}>{vehicle.owner_id || 'N/A'}</span>
                </div>
                <div className={styles.resultField}>
                  <span className={styles.fieldLabel}>{t('primary_vehicle')}:</span>
                  <span className={styles.fieldValue}>
                    {vehicle.is_primary_vehicle ? t('yes') : t('no')}
                  </span>
                </div>
              </div>
              
              <div className={styles.resultRow}>
                <div className={styles.resultField}>
                  <span className={styles.fieldLabel}>{t('color')}:</span>
                  <span className={styles.fieldValue}>{vehicle.details?.vehicle_color || 'N/A'}</span>
                </div>
                <div className={styles.resultField}>
                  <span className={styles.fieldLabel}>{t('body_type')}:</span>
                  <span className={styles.fieldValue}>{vehicle.details?.vehicle_body_type || 'N/A'}</span>
                </div>
              </div>
            </div>
            
            <div className={styles.resultActions}>
              <button 
                className={styles.viewButton}
                onClick={() => handleViewVehicle(vehicle.vehicle_id)}
              >
                {t('view_vehicle')}
              </button>
            </div>
          </div>
        ))}
      </div>
      
      {totalPages > 1 && (
        <div className={styles.pagination}>
          <button 
            className={styles.paginationButton}
            disabled={currentPage <= 1}
            onClick={() => onPageChange(currentPage - 1)}
          >
            {t('previous')}
          </button>
          
          <span className={styles.pageInfo}>
            {t('page_of_total', { page: currentPage, total: totalPages })}
          </span>
          
          <button 
            className={styles.paginationButton}
            disabled={currentPage >= totalPages}
            onClick={() => onPageChange(currentPage + 1)}
          >
            {t('next')}
          </button>
        </div>
      )}
    </div>
  );
};

export default VehicleSearchResults;