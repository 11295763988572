// File: frontend/src/pages/InvestorPitch/components/slides/TechnicalArchitectureSlide/SystemMetric.js

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { motion, AnimatePresence } from 'framer-motion';
import * as LucideIcons from 'lucide-react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from './SystemMetric.module.css';

const SystemMetric = ({ icon, title, value, unit, color, details }) => {
  const { t } = useLanguage();
  const Icon = LucideIcons[icon];
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    if (details) {
      setIsExpanded(!isExpanded);
    }
  };

  return (
    <motion.div 
      className={`${styles.container} ${details ? styles.hasDetails : ''}`}
      whileHover={{ scale: details ? 1.02 : 1 }}
      whileTap={{ scale: details ? 0.98 : 1 }}
      transition={{ type: 'spring', stiffness: 300, damping: 30 }}
      onClick={toggleExpand}
      role={details ? "button" : "article"}
      tabIndex={details ? 0 : undefined}
      onKeyPress={(e) => {
        if (details && (e.key === 'Enter' || e.key === ' ')) {
          toggleExpand();
        }
      }}
      aria-label={t('investor_pitch.technical.metrics.aria_label', { 
        title,
        value: unit ? `${value} ${unit}` : value
      })}
      aria-expanded={details ? isExpanded : undefined}
    >
      <div 
        className={styles.iconWrapper}
        style={{ backgroundColor: `${color}15`, color: color }}
      >
        <Icon className={styles.icon} aria-hidden="true" />
      </div>
      <div className={styles.content}>
        <div className={styles.value}>
          <span>{value}</span>
          {unit && <span className={styles.unit}>{unit}</span>}
        </div>
        <div className={styles.title}>{title}</div>
      </div>

      {details && (
        <AnimatePresence>
          {isExpanded && (
            <motion.div
              className={styles.details}
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.2 }}
            >
              <h4 className={styles.detailsTitle}>{details.title}</h4>
              <p className={styles.detailsDescription}>{details.description}</p>
              
              {details.projects && (
                <div className={styles.projectsSection}>
                  <ul className={styles.projectsList}>
                    {details.projects.map((project, index) => (
                      <li key={index}>{project}</li>
                    ))}
                  </ul>
                </div>
              )}

              {details.regions && (
                <div className={styles.regionsSection}>
                  {Object.entries(details.regions).map(([region, locations]) => (
                    <div key={region} className={styles.regionGroup}>
                      <h5 className={styles.regionTitle}>
                        {t(`investor_pitch.technical.metrics.regions.${region}`)}
                      </h5>
                      <ul className={styles.locationsList}>
                        {locations.map((location, index) => (
                          <li key={index}>{location}</li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              )}

              {details.stats && (
                <div className={styles.statsSection}>
                  <div className={styles.statsGrid}>
                    {Object.entries(details.stats).map(([key, stat]) => (
                      <div key={key} className={styles.statItem}>
                        <div className={styles.statValue}>{stat.value}</div>
                        <div className={styles.statLabel}>{stat.label}</div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </motion.div>
  );
};

SystemMetric.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  unit: PropTypes.string,
  color: PropTypes.string.isRequired,
  details: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    projects: PropTypes.arrayOf(PropTypes.string),
    regions: PropTypes.object,
    stats: PropTypes.objectOf(PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })),
  }),
};

export default SystemMetric;