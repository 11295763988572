// File: frontend/src/components/Sidebar/SidebarBot.js

import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useLanguage } from '../../contexts/LanguageContext';
import { useAuth } from '../../contexts/AuthContextV2';
import { useUser } from '../../contexts/UserContext';
import SettingsModal from '../Settings/SettingsModal';
import styles from './SidebarBot.module.css';

const SidebarBot = ({ isExpanded }) => {
  const { t } = useLanguage();
  const { signOut, user, loading } = useAuth();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  // const { user, isLoading } = useUser();

  const toggleMenu = () => {
    if (isExpanded) {
      setIsMenuOpen(!isMenuOpen);
    }
  };

  const handleSettingsClick = (e) => {
    e.preventDefault();
    setIsSettingsOpen(true);
    setIsMenuOpen(false); // Close the menu when opening settings
  };

  const handleSignOut = async (e) => {
    e.preventDefault();
    console.log('Sign out initiated');
    try {
      await signOut();
      setIsMenuOpen(false);
      navigate('/sign-out');
    } catch (error) {
      console.error('Sign out error:', error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    // If there's no user, avoid referencing user.first_name
    return null;
  }

  return (
    <>
      <div className={styles.sidebarBot}>
        <button 
          className={`${styles.profileButton} ${isMenuOpen ? styles.active : ''}`}
          onClick={toggleMenu}
        >
          <div className={styles.avatarContainer}>
            <span className={styles.initialAvatar}>
              {user.first_name ? user.first_name[0].toUpperCase() : '?'}
            </span>
          </div>
          {isExpanded && (
            <>
              <div className={styles.userInfo}>
                <div className={styles.userName}>{user.first_name} {user.last_name }</div>
                <div className={styles.userRole}>{t(user.role_key)}</div>
              </div>
              <div className={styles.chevronWrapper}>
                <img 
                  src={`/icons/18/misc/chevron-${isMenuOpen ? 'up' : 'down'}.svg`}
                  alt=""
                  className={styles.chevronIcon}
                />
              </div>
            </>
          )}
        </button>

        {isExpanded && isMenuOpen && (
          <div className={styles.menu}>
            <Link to="/account" className={styles.menuItem}>
              <div className={styles.menuIconWrapper}>
                <img src="/icons/18/misc/user.svg" alt="" className={styles.menuIcon} />
              </div>
              <span>{t('my_account')}</span>
            </Link>
            
            <button 
              className={styles.menuItem}
              onClick={handleSettingsClick}
            >
              <div className={styles.menuIconWrapper}>
                <img src="/icons/18/misc/settings.svg" alt="" className={styles.menuIcon} />
              </div>
              <span>{t('navigation.settings')}</span>
            </button>

            <Link to="/help" className={styles.menuItem}>
              <div className={styles.menuIconWrapper}>
                <img src="/icons/18/misc/question-mark-circled.svg" alt="" className={styles.menuIcon} />
              </div>
              <span>{t('navigation.help')}</span>
            </Link>

            <button 
              className={`${styles.menuItem} ${styles.signOut}`}
              onClick={handleSignOut}
              type="button"
            >
              <div className={styles.menuIconWrapper}>
                <img src="/icons/18/misc/logout.svg" alt="" className={styles.menuIcon} />
              </div>
              <span>{t('sign_out')}</span>
            </button>
          </div>
        )}
      </div>

      <SettingsModal 
        isOpen={isSettingsOpen}
        onClose={() => setIsSettingsOpen(false)}
      />
    </>
  );
};

export default SidebarBot;