// File: frontend/src/pages/SuperAdmin/components/AgreementManagement/AgreementCreate/index.js

import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from './AgreementCreate.module.css';

const AgreementCreate = ({ onBack, onSuccess }) => {
  const { t } = useLanguage();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [enterprises, setEnterprises] = useState([]);
  const [formData, setFormData] = useState({
    enterprise_id: '',
    agreement_type: 'production',
    agreement_status: 'active',
    agreement_start_date: '',
    agreement_end_date: '',
    agreement_notes: '',
    terms_and_conditions: '',
    auto_renew: true,
    renewal_period: '1 year',
    price_increase_cap: '5%',
    renewal_notification_days: 60
  });
  
  useEffect(() => {
    const fetchEnterprises = async () => {
      try {
        // This would be replaced with an actual API call
        // const response = await enterpriseService.listEnterprises();
        
        // Mock data for now
        await new Promise(resolve => setTimeout(resolve, 500));
        
        const mockEnterprises = [
          {
            enterprise_id: 'ent-12345',
            enterprise_name: 'Acme Corporation',
            enterprise_status: 'active'
          },
          {
            enterprise_id: 'ent-23456',
            enterprise_name: 'Global Industries',
            enterprise_status: 'active'
          },
          {
            enterprise_id: 'ent-34567',
            enterprise_name: 'Tech Solutions',
            enterprise_status: 'active'
          },
          {
            enterprise_id: 'ent-45678',
            enterprise_name: 'Innovative Systems',
            enterprise_status: 'active'
          }
        ];
        
        setEnterprises(mockEnterprises);
      } catch (err) {
        console.error('Error fetching enterprises:', err);
        setError(t('failed_to_load_enterprises'));
      }
    };
    
    fetchEnterprises();
  }, [t]);
  
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    
    try {
      // This would be replaced with an actual API call
      // const response = await agreementService.createAgreement({
      //   enterprise_id: formData.enterprise_id,
      //   agreement_type: formData.agreement_type,
      //   agreement_status: formData.agreement_status,
      //   agreement_start_timestamp: `${formData.agreement_start_date}T00:00:00Z`,
      //   agreement_end_timestamp: `${formData.agreement_end_date}T23:59:59Z`,
      //   agreement_notes: {
      //     notes: formData.agreement_notes,
      //     terms: ''
      //   },
      //   terms_and_conditions: formData.terms_and_conditions,
      //   renewal_terms: {
      //     auto_renew: formData.auto_renew,
      //     renewal_period: formData.renewal_period,
      //     price_increase_cap: formData.price_increase_cap,
      //     renewal_notification_days: parseInt(formData.renewal_notification_days)
      //   },
      //   document_links: {}
      // });
      
      // Mock response for now
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      const mockResponse = {
        success: true,
        agreement_id: `agree-${Math.random().toString(36).substring(2, 10)}`
      };
      
      // Call the success callback with the new agreement ID
      onSuccess(mockResponse.agreement_id);
    } catch (err) {
      console.error('Error creating agreement:', err);
      setError(t('failed_to_create_agreement'));
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <button className={styles.backButton} onClick={onBack} disabled={loading}>
          <i className="fas fa-arrow-left"></i>
          <span>{t('back')}</span>
        </button>
      </div>
      
      {error && (
        <div className={styles.error}>
          <i className="fas fa-exclamation-circle"></i>
          <span>{error}</span>
        </div>
      )}
      
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.formSection}>
          <h3 className={styles.sectionTitle}>{t('agreement_details')}</h3>
          <div className={styles.formGrid}>
            <div className={styles.formGroup}>
              <label htmlFor="enterprise_id" className={styles.label}>
                {t('enterprise')} <span className={styles.required}>*</span>
              </label>
              <select
                id="enterprise_id"
                name="enterprise_id"
                className={styles.select}
                value={formData.enterprise_id}
                onChange={handleChange}
                required
                disabled={loading}
              >
                <option value="">{t('select_enterprise')}</option>
                {enterprises.map((enterprise) => (
                  <option key={enterprise.enterprise_id} value={enterprise.enterprise_id}>
                    {enterprise.enterprise_name}
                  </option>
                ))}
              </select>
            </div>
            
            <div className={styles.formGroup}>
              <label htmlFor="agreement_type" className={styles.label}>
                {t('agreement_type')} <span className={styles.required}>*</span>
              </label>
              <select
                id="agreement_type"
                name="agreement_type"
                className={styles.select}
                value={formData.agreement_type}
                onChange={handleChange}
                required
                disabled={loading}
              >
                <option value="production">{t('production')}</option>
                <option value="trial">{t('trial')}</option>
                <option value="pilot">{t('pilot')}</option>
              </select>
            </div>
            
            <div className={styles.formGroup}>
              <label htmlFor="agreement_status" className={styles.label}>
                {t('status')} <span className={styles.required}>*</span>
              </label>
              <select
                id="agreement_status"
                name="agreement_status"
                className={styles.select}
                value={formData.agreement_status}
                onChange={handleChange}
                required
                disabled={loading}
              >
                <option value="active">{t('active')}</option>
                <option value="pending">{t('pending')}</option>
              </select>
            </div>
            
            <div className={styles.formGroup}>
              <label htmlFor="agreement_start_date" className={styles.label}>
                {t('start_date')} <span className={styles.required}>*</span>
              </label>
              <input
                type="date"
                id="agreement_start_date"
                name="agreement_start_date"
                className={styles.input}
                value={formData.agreement_start_date}
                onChange={handleChange}
                required
                disabled={loading}
              />
            </div>
            
            <div className={styles.formGroup}>
              <label htmlFor="agreement_end_date" className={styles.label}>
                {t('end_date')} <span className={styles.required}>*</span>
              </label>
              <input
                type="date"
                id="agreement_end_date"
                name="agreement_end_date"
                className={styles.input}
                value={formData.agreement_end_date}
                onChange={handleChange}
                required
                disabled={loading}
              />
            </div>
            
            <div className={styles.formGroupFull}>
              <label htmlFor="agreement_notes" className={styles.label}>
                {t('agreement_notes')}
              </label>
              <textarea
                id="agreement_notes"
                name="agreement_notes"
                className={styles.textarea}
                value={formData.agreement_notes}
                onChange={handleChange}
                rows={3}
                disabled={loading}
              ></textarea>
            </div>
          </div>
        </div>
        
        <div className={styles.formSection}>
          <h3 className={styles.sectionTitle}>{t('terms_and_conditions')}</h3>
          <div className={styles.formGroupFull}>
            <textarea
              id="terms_and_conditions"
              name="terms_and_conditions"
              className={styles.textarea}
              value={formData.terms_and_conditions}
              onChange={handleChange}
              rows={6}
              disabled={loading}
            ></textarea>
          </div>
        </div>
        
        <div className={styles.formSection}>
          <h3 className={styles.sectionTitle}>{t('renewal_terms')}</h3>
          <div className={styles.formGrid}>
            <div className={styles.formGroup}>
              <div className={styles.checkboxContainer}>
                <input
                  type="checkbox"
                  id="auto_renew"
                  name="auto_renew"
                  className={styles.checkbox}
                  checked={formData.auto_renew}
                  onChange={handleChange}
                  disabled={loading}
                />
                <label htmlFor="auto_renew" className={styles.checkboxLabel}>
                  {t('auto_renew')}
                </label>
              </div>
            </div>
            
            <div className={styles.formGroup}>
              <label htmlFor="renewal_period" className={styles.label}>
                {t('renewal_period')}
              </label>
              <input
                type="text"
                id="renewal_period"
                name="renewal_period"
                className={styles.input}
                value={formData.renewal_period}
                onChange={handleChange}
                disabled={loading}
              />
            </div>
            
            <div className={styles.formGroup}>
              <label htmlFor="price_increase_cap" className={styles.label}>
                {t('price_increase_cap')}
              </label>
              <input
                type="text"
                id="price_increase_cap"
                name="price_increase_cap"
                className={styles.input}
                value={formData.price_increase_cap}
                onChange={handleChange}
                disabled={loading}
              />
            </div>
            
            <div className={styles.formGroup}>
              <label htmlFor="renewal_notification_days" className={styles.label}>
                {t('renewal_notification_days')}
              </label>
              <input
                type="number"
                id="renewal_notification_days"
                name="renewal_notification_days"
                className={styles.input}
                value={formData.renewal_notification_days}
                onChange={handleChange}
                min="0"
                disabled={loading}
              />
            </div>
          </div>
        </div>
        
        <div className={styles.formActions}>
          <button 
            type="button" 
            className={styles.cancelButton} 
            onClick={onBack}
            disabled={loading}
          >
            {t('cancel')}
          </button>
          <button 
            type="submit" 
            className={styles.submitButton}
            disabled={loading}
          >
            {loading ? (
              <>
                <span className={styles.spinner}></span>
                <span>{t('creating')}</span>
              </>
            ) : (
              t('create_agreement')
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AgreementCreate;