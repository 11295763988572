// File: frontend/src/pages/UMS/UserDetail/index.js

import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useLanguage } from '../../../contexts/LanguageContext';
import PageTitle from '../../../components/layout/DashboardLayout/PageTitle';
import PageTitleControls from '../../../components/layout/DashboardLayout/PageTitleControls';
import useUserDetail from '../../../hooks/useUserDetail';
import UserHeader from './components/UserHeader';
import UserOverview from './components/UserOverview';
import UserAddresses from './components/UserAddresses';
import UserPermissions from './components/UserPermissions';
import UserConnections from './components/UserConnections';
import UserActions from './components/UserActions';
import styles from './UserDetail.module.css';

const UserDetail = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const { t } = useLanguage();
  const [activeTab, setActiveTab] = useState('overview');
  
  const { user, loading, error } = useUserDetail(userId);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleBack = () => {
    navigate('/ums');
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <div className={styles.spinner}></div>
        <p>{t('loading_user_details')}</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.error}>
        <h2>{t('error_loading_user')}</h2>
        <p>{error}</p>
        <button className={styles.backButton} onClick={handleBack}>
          {t('back_to_user_management')}
        </button>
      </div>
    );
  }

  if (!user) {
    return (
      <div className={styles.notFound}>
        <h2>{t('user_not_found')}</h2>
        <p>{t('user_not_found_message')}</p>
        <button className={styles.backButton} onClick={handleBack}>
          {t('back_to_user_management')}
        </button>
      </div>
    );
  }

  const userDetailTitle = `${user.first_name} ${user.last_name}`;

  return (
    <div className={styles.container}>
      <PageTitle title={userDetailTitle}>
        <PageTitleControls>
          <PageTitleControls.Button
            icon={
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.99998 12.6667L5.33331 8.00001L9.99998 3.33334" stroke="#14151A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            }
            label={t('back_to_list')}
            onClick={handleBack}
          />
          <PageTitleControls.Button
            icon={
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.66669 2.33334H6.00002C3.33335 2.33334 2.00002 3.66668 2.00002 6.33334V9.66668C2.00002 12.3333 3.33335 13.6667 6.00002 13.6667H9.33335C12 13.6667 13.3334 12.3333 13.3334 9.66668V7.00001" stroke="#14151A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M9.66669 2.33334L5.66669 6.33334" stroke="#14151A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M9.66669 5.00001V2.33334H12.3334" stroke="#14151A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            }
            label={t('edit_user')}
          />
        </PageTitleControls>
      </PageTitle>
      
      <UserHeader user={user} />
      
      <div className={styles.tabs}>
        <button 
          className={`${styles.tab} ${activeTab === 'overview' ? styles.activeTab : ''}`}
          onClick={() => handleTabChange('overview')}
        >
          {t('overview')}
        </button>
        <button 
          className={`${styles.tab} ${activeTab === 'addresses' ? styles.activeTab : ''}`}
          onClick={() => handleTabChange('addresses')}
        >
          {t('addresses')}
        </button>
        <button 
          className={`${styles.tab} ${activeTab === 'permissions' ? styles.activeTab : ''}`}
          onClick={() => handleTabChange('permissions')}
        >
          {t('permissions')}
        </button>
        <button 
          className={`${styles.tab} ${activeTab === 'connections' ? styles.activeTab : ''}`}
          onClick={() => handleTabChange('connections')}
        >
          {t('connections')}
        </button>
        <button 
          className={`${styles.tab} ${activeTab === 'actions' ? styles.activeTab : ''}`}
          onClick={() => handleTabChange('actions')}
        >
          {t('actions')}
        </button>
      </div>
      
      <div className={styles.tabContent}>
        {activeTab === 'overview' && <UserOverview user={user} />}
        {activeTab === 'addresses' && <UserAddresses user={user} />}
        {activeTab === 'permissions' && <UserPermissions user={user} />}
        {activeTab === 'connections' && <UserConnections user={user} />}
        {activeTab === 'actions' && <UserActions user={user} />}
      </div>
    </div>
  );
};

export default UserDetail;