// File: frontend/src/pages/InvestorPitch/components/slides/GlobalHiringSlide/CompensationCard.js

import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { DollarSign, Award, Gift } from 'lucide-react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from './CompensationCard.module.css';

const CompensationCard = ({ base, equity, benefits }) => {
  const { t } = useLanguage();

  const items = [
    { ...base, icon: DollarSign, title: t('investor_pitch.compensation.base.title') },
    { ...equity, icon: Award, title: t('investor_pitch.compensation.equity.title') },
    { ...benefits, icon: Gift, title: t('investor_pitch.compensation.benefits.title') },
  ];

  return (
    <motion.div 
      className={styles.container}
      whileHover={{ scale: 1.02 }}
      transition={{ type: 'spring', stiffness: 300, damping: 30 }}
    >
      {items.map((item, index) => {
        const Icon = item.icon;
        return (
          <div key={item.title} className={styles.item}>
            <div className={styles.itemHeader}>
              <Icon className={styles.icon} />
              <h4 className={styles.itemTitle}>{item.title}</h4>
            </div>
            <div className={styles.range}>{item.range}</div>
            <div className={styles.details}>{item.details}</div>
          </div>
        );
      })}
    </motion.div>
  );
};

CompensationCard.propTypes = {
  base: PropTypes.shape({
    range: PropTypes.string.isRequired,
    details: PropTypes.string.isRequired,
  }).isRequired,
  equity: PropTypes.shape({
    range: PropTypes.string.isRequired,
    details: PropTypes.string.isRequired,
  }).isRequired,
  benefits: PropTypes.shape({
    range: PropTypes.string.isRequired,
    details: PropTypes.string.isRequired,
  }).isRequired,
};

export default CompensationCard;