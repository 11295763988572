// File: frontend/src/components/MileMonitor/UserEngagement.js

import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { hexToRGBA } from '../../utils/chartHelpers';

const UserEngagement = ({ data }) => {
  if (!data || !data.length) {
    return <div>No data available</div>;
  }

  const firstEntryDetails = data[0].chartAxisDetails;

  return (
    <ResponsiveContainer width={541} height={400}>
      <BarChart
        data={data}
        margin={{top: 30, right: 15, left: 15, bottom: 0,}}
      >
        <CartesianGrid strokeDasharray="0 1"/>
        <XAxis dataKey="chartAxisDetails.x1_axis_name" height={60}/>
        <YAxis 
          type='number' 
          domain={[firstEntryDetails.y2_axis_min_value, firstEntryDetails.y2_axis_max_value]} 
          allowDataOverflow 
          allowDecimals={false} 
          axisLine={true} 
          scale="linear" 
          label={{ value: 'Retained User Percentage', angle: -90, position: 'insideBottomLeft' }}
        />
        <Tooltip/>
        <Legend verticalAlign="bottom"/>
        <Bar 
          dataKey="chartAxisDetails.y2_axis_value"
          stackId="a" 
          fill={hexToRGBA(firstEntryDetails.y2_axis_hex, firstEntryDetails.y2_axis_transparency)} 
          name={firstEntryDetails.y2_axis_name}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default UserEngagement;