// File: frontend/src/pages/TripInsight/components/DrivingBehaviorStatistics/index.js

import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import styles from './DrivingBehaviorStatistics.module.css';

const DrivingBehaviorStatistics = () => {
  const { t } = useLanguage();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [stats, setStats] = useState({
    averageOverallScore: 0,
    averageAccelerationScore: 0,
    averageBrakingScore: 0,
    averageSpeedingScore: 0,
    averageCorneringScore: 0,
    nightDrivingPercentage: 0,
    peakDrivingPercentage: 0,
    weekendDrivingPercentage: 0
  });

  useEffect(() => {
    const fetchDrivingStats = async () => {
      setLoading(true);
      setError(null);
      
      try {
        // This would be replaced with an actual API call
        // const response = await tripService.getDrivingBehaviorStatistics();
        
        // Mock data for now
        await new Promise(resolve => setTimeout(resolve, 600));
        
        const mockStats = {
          averageOverallScore: 87.5,
          averageAccelerationScore: 85.2,
          averageBrakingScore: 82.7,
          averageSpeedingScore: 90.3,
          averageCorneringScore: 88.9,
          nightDrivingPercentage: 15.8,
          peakDrivingPercentage: 42.3,
          weekendDrivingPercentage: 28.6
        };
        
        setStats(mockStats);
      } catch (err) {
        console.error('Error fetching driving behavior statistics:', err);
        setError(t('failed_to_load_driving_statistics'));
      } finally {
        setLoading(false);
      }
    };
    
    fetchDrivingStats();
  }, [t]);

  if (loading) {
    return (
      <div className={styles.loading}>
        <div className={styles.loadingSpinner}></div>
        <p>{t('loading_driving_statistics')}</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.error}>
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{t('driving_behavior_statistics')}</h3>
      
      <div className={styles.statsGrid}>
        <div className={styles.statCard}>
          <div className={styles.statIcon}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"></path>
              <path d="M12 6v6l4 2"></path>
            </svg>
          </div>
          <div className={styles.statContent}>
            <div className={styles.statValue}>{stats.averageOverallScore.toFixed(1)}</div>
            <div className={styles.statLabel}>{t('average_overall_score')}</div>
          </div>
        </div>
        
        <div className={styles.statCard}>
          <div className={styles.statIcon}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <polyline points="22 7 13.5 15.5 8.5 10.5 2 17"></polyline>
              <polyline points="16 7 22 7 22 13"></polyline>
            </svg>
          </div>
          <div className={styles.statContent}>
            <div className={styles.statValue}>{stats.averageAccelerationScore.toFixed(1)}</div>
            <div className={styles.statLabel}>{t('acceleration_score')}</div>
          </div>
        </div>
        
        <div className={styles.statCard}>
          <div className={styles.statIcon}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <polyline points="22 17 13.5 8.5 8.5 13.5 2 7"></polyline>
              <polyline points="16 17 22 17 22 11"></polyline>
            </svg>
          </div>
          <div className={styles.statContent}>
            <div className={styles.statValue}>{stats.averageBrakingScore.toFixed(1)}</div>
            <div className={styles.statLabel}>{t('braking_score')}</div>
          </div>
        </div>
        
        <div className={styles.statCard}>
          <div className={styles.statIcon}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M19 5L5 19"></path>
              <circle cx="6.5" cy="6.5" r="2.5"></circle>
              <circle cx="17.5" cy="17.5" r="2.5"></circle>
            </svg>
          </div>
          <div className={styles.statContent}>
            <div className={styles.statValue}>{stats.averageCorneringScore.toFixed(1)}</div>
            <div className={styles.statLabel}>{t('cornering_score')}</div>
          </div>
        </div>
      </div>
      
      <div className={styles.additionalStats}>
        <div className={styles.additionalStat}>
          <span className={styles.additionalStatLabel}>{t('night_driving')}:</span>
          <span className={styles.additionalStatValue}>{stats.nightDrivingPercentage}%</span>
        </div>
        <div className={styles.additionalStat}>
          <span className={styles.additionalStatLabel}>{t('peak_driving')}:</span>
          <span className={styles.additionalStatValue}>{stats.peakDrivingPercentage}%</span>
        </div>
        <div className={styles.additionalStat}>
          <span className={styles.additionalStatLabel}>{t('weekend_driving')}:</span>
          <span className={styles.additionalStatValue}>{stats.weekendDrivingPercentage}%</span>
        </div>
      </div>
    </div>
  );
};

export default DrivingBehaviorStatistics;