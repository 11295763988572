// File: frontend/src/pages/Quote/components/ChatInterface/ChatInterface.js

import React, { useState, useRef, useEffect } from 'react';
import { quoteService } from '../../../../services/quote';
import { useLanguage } from '../../../../contexts/LanguageContext';
import { v4 as uuidv4 } from 'uuid';
import safeStorage from '../../../../utils/storage';
import styles from './ChatInterface.module.css';
import LoadingContainer from '../../../../components/LoadingSpinner/LoadingContainer';

// Import sub-components
import ChatHeader from './components/ChatHeader';
import ChatMessages from './components/ChatMessages';
import ChatInput from './components/ChatInput';
import QuoteSummary from './components/QuoteSummary';
import MarketComparison from './components/MarketComparison';
import LockMessage from './components/LockMessage';

/**
 * Enhanced ChatInterface component that takes over the entire viewport
 * 
 * @param {Object} props - Component props
 * @param {boolean} props.isOpen - Whether the chat interface is open
 * @param {Function} props.onClose - Callback function when chat is closed
 * @param {Function} props.onQuoteComplete - Callback function when quote is completed
 * @param {string} props.quoteId - The ID of the current quote
 * @param {string} props.chatId - The ID of the current chat
 * @param {Object} props.quoteData - Data for the current quote
 * @param {boolean} props.isLoading - Whether the quote is currently loading
 * @returns {JSX.Element} ChatInterface component
 */
const ChatInterface = ({ isOpen, onClose, onQuoteComplete, quoteId, chatId, quoteData, isLoading }) => {
  const { t, language } = useLanguage();
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [quoteStage, setQuoteStage] = useState('initial');
  const [localQuoteData, setLocalQuoteData] = useState({
    insuranceType: '',
    currentPremium: 0,
    estimatedSavings: 0
  });
  const [showSummary, setShowSummary] = useState(false);
  const [showMarketComparison, setShowMarketComparison] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [lockReason, setLockReason] = useState('');
  const [chatInitialized, setChatInitialized] = useState(false);
  const [initializing, setInitializing] = useState(false);
  
  const messagesEndRef = useRef(null);
  const chatContainerRef = useRef(null);
  
  // Update local quote data when prop changes
  useEffect(() => {
    if (quoteData) {
      setLocalQuoteData(prevData => ({
        ...prevData,
        ...quoteData
      }));
    }
  }, [quoteData]);
  
  // Initialize chat with quote ID if available
  useEffect(() => {
    if (isOpen && quoteId && !chatInitialized) {
      initializeChat(quoteId);
    } else if (isOpen && !quoteId && !chatInitialized) {
      // If no quote ID, we'll just set the chat as initialized
      // but won't show any initial message
      setChatInitialized(true);
    }
  }, [isOpen, quoteId, chatInitialized, language]);
  
  // Auto-scroll to bottom of messages
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  // Load saved quote data on component mount
  useEffect(() => {
    // Check for pre-selected insurance type in session storage
    const selectedInsuranceType = sessionStorage.getItem('selectedInsuranceType');
    
    if (selectedInsuranceType && localQuoteData.insuranceType === '') {
      // Update quote data with the selected insurance type
      setLocalQuoteData(prev => ({ 
        ...prev, 
        insuranceType: selectedInsuranceType 
      }));
    }
  }, [quoteId, chatInitialized, language, localQuoteData.insuranceType]);

  // Handle escape key to close the chat
  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === 'Escape' && isOpen) {
        onClose();
      }
    };

    window.addEventListener('keydown', handleEscKey);
    return () => window.removeEventListener('keydown', handleEscKey);
  }, [isOpen, onClose]);

  // Update the initializeChat function
  const initializeChat = async (quoteId) => {
    setChatInitialized(true);
    setInitializing(true);
    
    try {
      setIsLocked(true);
      setLockReason(t('Loading your quote...'));
      
      // Fetch messages from the backend
      const response = await quoteService.getMessages(quoteId);
      
      // Store the chat_id if it's in the response
      if (response && response.chat_id) {
        console.log('Storing chat_id from messages response:', response.chat_id);
        safeStorage.setItem('chat_id', response.chat_id);
      }
      
      if (response && response.messages && response.messages.length > 0) {
        // Filter out any messages that start with "_INITIATED_"
        const filteredMessages = response.messages.filter(msg => 
          !(msg.role === 'user' && 
            (Array.isArray(msg.content) 
              ? msg.content[0].text.startsWith('_INITIATED_') 
              : msg.content.startsWith('_INITIATED_')))
        );
        
        if (filteredMessages.length > 0) {
          // Format messages for display
          const formattedMessages = filteredMessages.map((msg, index) => ({
            id: index + 1,
            content: Array.isArray(msg.content) ? msg.content[0].text : msg.content,
            sender: msg.role === 'assistant' ? 'ai' : msg.role,
            timestamp: msg.timestamp
          }));
          
          setMessages(formattedMessages);
        } else {
          // If all messages were filtered out (only had initialization messages)
          // We'll send a new initialization message
          await sendInitializationMessage(quoteId);
        }
      } else {
        // If no messages found, send initialization message
        await sendInitializationMessage(quoteId);
      }
    } catch (error) {
      console.error('Error initializing chat:', error);
      
      // Provide specific error handling for different error types
      if (error.statusCode === 404) {
        console.log('Messages not found for this quote. Creating new messages...');
        
        // Send initialization message
        await sendInitializationMessage(quoteId);
      } else {
        // General error message for other types of errors
        addMessage({
          content: 'I apologize, but I had trouble loading your previous conversation. Let\'s start fresh!',
          sender: 'ai',
          timestamp: new Date().toISOString()
        });
        
        // Still try to initialize
        await sendInitializationMessage(quoteId);
      }
    } finally {
      setIsLocked(false);
      setLockReason('');
      setInitializing(false);
    }
  };

  // New function to send initialization message
  const sendInitializationMessage = async (quoteId) => {
    try {
      setIsTyping(true);
      
      // Get the selected insurance type from session storage
      const selectedInsuranceType = sessionStorage.getItem('selectedInsuranceType');
      
      // Create the initialization message
      const initMessage = selectedInsuranceType 
        ? `_INITIATED_ Interested products: ${selectedInsuranceType}`
        : `_INITIATED_ New quote request`;
      
      // Get or create a chat ID
      let currentChatId = chatId || safeStorage.getItem('chat_id') || quoteData?.chat_id;
      
      if (!currentChatId) {
        // Generate a new chat ID
        currentChatId = uuidv4();
        safeStorage.setItem('chat_id', currentChatId);
        
        // If we have a quote ID, associate this chat with it
        if (quoteId) {
          try {
            const initResponse = await quoteService.initiateQuote({
              quote_id: quoteId,
              chat_id: currentChatId,
              source: 'web',
              primary_language: language || 'en-ca'
            });
            
            if (initResponse && initResponse.chat_id) {
              currentChatId = initResponse.chat_id;
              safeStorage.setItem('chat_id', currentChatId);
            }
          } catch (error) {
            console.error('Error initializing quote:', error);
          }
        }
      }
      
      // Send the initialization message to the backend
      const response = await quoteService.sendMessage(currentChatId, initMessage);
      
      // If the response includes a chat_id, store it
      if (response && response.chat_id) {
        safeStorage.setItem('chat_id', response.chat_id);
      }
      
      // Add the AI response as the first message the user sees
      if (response && response.message) {
        setMessages([{
          id: 1,
          content: response.message,
          sender: 'ai',
          timestamp: new Date().toISOString()
        }]);
      }
    } catch (error) {
      console.error('Error sending initialization message:', error);
      
      // Add a fallback message if the initialization fails
      setMessages([{
        id: 1,
        content: 'I apologize, but I had trouble starting our conversation. How can I help you with your insurance needs today?',
        sender: 'ai',
        timestamp: new Date().toISOString()
      }]);
    } finally {
      setIsTyping(false);
    }
  };

  // Update the handleSendMessage function
  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!inputValue.trim() || isLocked) return;
  
    // Add user's message
    const userMessage = {
      id: messages.length + 1,
      content: inputValue,
      sender: 'user',
      timestamp: new Date().toISOString()
    };
    
    setMessages(prev => [...prev, userMessage]);
    setInputValue('');
    setIsTyping(true);
  
    try {
      // Get the chat_id from props, localStorage, or quoteData
      let currentChatId = chatId || safeStorage.getItem('chat_id') || quoteData?.chat_id;
      console.log('Sending message with chat_id:', currentChatId);
      
      if (!currentChatId) {
        console.error('No chat_id available for sending message');
        
        // Instead of throwing an error, try to recover by using the quote_id
        // to initialize a new chat session if we have a quote_id
        const currentQuoteId = quoteId || safeStorage.getItem('quote_id');
        
        if (currentQuoteId) {
          console.log('Attempting to initialize new chat with quote_id:', currentQuoteId);
          
          // Set a temporary locked state with message
          setIsLocked(true);
          setLockReason('Creating new chat session...');
          
          try {
            // Try to initialize a new conversation for this quote
            const initResponse = await quoteService.initiateQuote({
              quote_id: currentQuoteId,
              chat_id: uuidv4(), // Generate a new chat ID
              source: 'web',
              primary_language: language || 'en-ca'
            });
            
            // If successful, get the new chat_id
            if (initResponse && initResponse.chat_id) {
              currentChatId = initResponse.chat_id;
              safeStorage.setItem('chat_id', currentChatId);
              console.log('New chat session created with ID:', currentChatId);
            } else {
              throw new Error('Failed to create new chat session');
            }
          } catch (initError) {
            console.error('Error creating new chat session:', initError);
            throw new Error('Unable to send message: No valid chat session available');
          } finally {
            setIsLocked(false);
            setLockReason('');
          }
        }
      }
  
      // Now that we should have a chat_id, attempt to send the message
      const response = await quoteService.sendMessage(currentChatId, inputValue);
      
      // If the response includes a chat_id, store it
      if (response && response.chat_id) {
        console.log('Updating chat_id from response:', response.chat_id);
        safeStorage.setItem('chat_id', response.chat_id);
      }
      
      // Add the AI response
      if (response && response.message) {
        addMessage({
          content: response.message,
          sender: 'ai',
          timestamp: new Date().toISOString()
        });
      }
    } catch (error) {
      console.error('Error sending message:', error);
      
      // More helpful error messages based on error type
      if (error.statusCode === 404) {
        addMessage({
          content: 'I\'m sorry, but I can\'t find your chat session. Let\'s start a new conversation.',
          sender: 'ai',
          timestamp: new Date().toISOString()
        });
        
        // Try to restart the chat
        const currentQuoteId = quoteId || safeStorage.getItem('quote_id');
        if (currentQuoteId) {
          setTimeout(() => initializeChat(currentQuoteId), 1000);
        }
      } else {
        addMessage({
          content: 'I apologize, but I encountered an error processing your request. Please try again.',
          sender: 'ai',
          timestamp: new Date().toISOString()
        });
      }
    } finally {
      setIsTyping(false);
    }
  };

  const addMessage = (messageData) => {
    setMessages(prev => [...prev, {
      id: prev.length + 1,
      ...messageData,
      timestamp: messageData.timestamp || new Date().toISOString()
    }]);
  };

  const handleLockToggle = () => {
    if (!isLocked) {
      // Locking the chat
      setIsLocked(true);
      setLockReason('Quote is currently being processed. Please wait.');
    } else {
      // Unlocking the chat
      setIsLocked(false);
      setLockReason('');
    }
  };

  if (!isOpen) return null;

  return (
    <div className={styles.chatOverlay}>
      <div className={styles.chatContainer} ref={chatContainerRef}>
        <ChatHeader 
          quoteId={quoteId}
          showSummary={showSummary}
          setShowSummary={setShowSummary}
          showMarketComparison={showMarketComparison}
          setShowMarketComparison={setShowMarketComparison}
          isLocked={isLocked}
          handleLockToggle={handleLockToggle}
          onClose={onClose}
          localQuoteData={localQuoteData}
        />
        
        <div className={styles.chatContent}>
          {initializing ? (
            <LoadingContainer 
              message={t('Loading your conversation...')}
              spinnerSize="medium"
              transparent={false}
            />
          ) : (
            <ChatMessages 
              messages={messages}
              isTyping={isTyping}
              isLoading={isLoading}
              messagesEndRef={messagesEndRef}
            />
          )}
          
          {/* Side panels */}
          {showSummary && (
            <QuoteSummary 
              localQuoteData={localQuoteData}
              quoteStage={quoteStage}
              onQuoteComplete={onQuoteComplete}
              onClose={() => setShowSummary(false)}
            />
          )}
          
          {showMarketComparison && (
            <MarketComparison 
              localQuoteData={localQuoteData}
              onClose={() => setShowMarketComparison(false)}
            />
          )}
          
          {/* Lock status message */}
          {isLocked && <LockMessage lockReason={lockReason} />}
        </div>
        
        <ChatInput 
          inputValue={inputValue}
          setInputValue={setInputValue}
          handleSendMessage={handleSendMessage}
          isTyping={isTyping}
          isLocked={isLocked || initializing}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default ChatInterface;