// File: frontend/src/pages/UMS/UserDetail/components/UserHeader/index.js

import React from 'react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from './UserHeader.module.css';

const UserHeader = ({ user }) => {
  const { t } = useLanguage();

  const getStatusClass = (status) => {
    switch (status) {
      case 495: return styles.statusActive;
      case 494: return styles.statusPending;
      case 496: return styles.statusSuspended;
      case 497: return styles.statusDeactivated;
      case 498: return styles.statusLocked;
      default: return '';
    }
  };

  const getStatusText = (status) => {
    switch (status) {
      case 495: return t('active');
      case 494: return t('pending_activation');
      case 496: return t('suspended');
      case 497: return t('deactivated');
      case 498: return t('locked');
      default: return t('unknown');
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.userInfo}>
        {user.profile_image_url ? (
          <img 
            src={user.profile_image_url} 
            alt={`${user.first_name} ${user.last_name}`} 
            className={styles.userAvatar}
          />
        ) : (
          <div className={styles.userInitials}>
            {user.first_name.charAt(0)}{user.last_name.charAt(0)}
          </div>
        )}
        
        <div className={styles.userDetails}>
          <div className={styles.userMeta}>
            <div className={styles.metaItem}>
              <span className={styles.metaLabel}>{t('user_id')}:</span>
              <span className={styles.metaValue}>{user.user_id}</span>
            </div>
            
            <div className={styles.metaItem}>
              <span className={styles.metaLabel}>{t('status')}:</span>
              <span className={`${styles.metaValue} ${getStatusClass(user.account_status)}`}>
                {getStatusText(user.account_status)}
              </span>
            </div>
          </div>
          
          <div className={styles.contactInfo}>
            <div className={styles.contactItem}>
              <i className="fas fa-envelope"></i>
              <span>{user.email_address}</span>
              {user.is_email_verified && <span className={styles.verifiedBadge}>{t('verified')}</span>}
            </div>
            
            <div className={styles.contactItem}>
              <i className="fas fa-phone"></i>
              <span>{user.mobile_phone_number}</span>
              {user.is_phone_verified && <span className={styles.verifiedBadge}>{t('verified')}</span>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserHeader;