// File: frontend/src/pages/Help/Help.js

import React from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import PageTitle from '../../components/layout/DashboardLayout/PageTitle';
import styles from './Help.module.css';

const Help = () => {
  const { t } = useLanguage();

  return (
    <div className={styles.HelpPage}>
      <PageTitle 
        title={t('Help')}
      />
      <div className={styles.pageContent}>
      </div>
    </div>
  );
};

export default Help;