// File: frontend/src/pages/InvestorPitch/components/slides/CompetitiveAdvantageSlide/MarketPosition.js

import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import * as LucideIcons from 'lucide-react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from './MarketPosition.module.css';

const MarketPosition = ({ highlights }) => {
  const { t } = useLanguage();

  return (
    <motion.div 
      className={styles.container}
      whileHover={{ scale: 1.02 }}
      transition={{ type: 'spring', stiffness: 300, damping: 30 }}
      aria-label={t('investor_pitch.market_position.aria.container')}
    >
      {highlights.map((highlight, index) => {
        const Icon = LucideIcons[highlight.icon];
        return (
          <div 
            key={highlight.title}
            className={styles.highlight}
            aria-label={t('investor_pitch.market_position.aria.highlight')}
          >
            <div 
              className={styles.iconWrapper}
              style={{ color: highlight.color }}
            >
              <Icon className={styles.icon} aria-hidden="true" />
            </div>
            <div className={styles.content}>
              <h4 className={styles.title}>{highlight.title}</h4>
              <p className={styles.value}>{highlight.value}</p>
            </div>
          </div>
        );
      })}
    </motion.div>
  );
};

MarketPosition.propTypes = {
  highlights: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default MarketPosition;