// File: frontend/src/components/MileMonitor/UBIHeatmap.js

import React from 'react';
// eslint-disable-next-line import/no-unresolved
import Plot from 'react-plotly.js';
import mile_monitor_ubi_heatmap_data from '../../data/mile_monitor_ubi_heatmap_data.json';

const UBIHeatmap = () => {
    const { chartAxisDetails } = mile_monitor_ubi_heatmap_data[0];
    const { x, y, z } = chartAxisDetails;
    
    const data = [
        {
            x,
            y,
            z,
            type: 'heatmap',
            colorscale: [
                [0.0, '#3667F8'], [0.1, '#2348ED'], [0.2, '#2348ED'],
                [0.3, '#1B35DA'], [0.4, '#1B35DA'], [0.5, '#1C2CB1'],
                [0.6, '#1C2CB1'], [0.7, '#1D2B8B'], [0.8, '#1D2B8B'],
                [0.9, '#161D55'], [1.0, '#161D55']
            ],
            showscale: true
        }
    ];

    const layout = {
        margin: {t: 30, r: 0, l: 50, b: 50},
        xaxis: { 
            title: 'Usage-based Bands',
            autorange: true
        },  
        yaxis: { 
            title: 'Selected Risk Decile',
            autorange: true,
            tickvals: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
        },
        annotations: [],
    };

    return (
        <Plot
            data={data}
            layout={layout}
            displayModeBar={true}
            style={{ width: 541, height: 541 }}
        />
    );
};

export default UBIHeatmap;