// File: frontend/src/components/Sidebar/SidebarTop.js

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { X } from 'lucide-react';
import Logo from '../Logo/Logo';
import styles from './SidebarTop.module.css';

const SidebarTop = ({ isExpanded, setIsExpanded, setIsMobileMenuOpen }) => {
  const handleClose = (e) => {  // Added event parameter 'e' here
    e.stopPropagation();
    setIsExpanded(false);
    setIsMobileMenuOpen(false);
  };

  const isMobile = window.innerWidth <= 768;

  return (
    <div className={styles.sidebarTop}>
      <Link to="/" className={styles.logoContainer}>
        <Logo isCollapsed={!isExpanded && !isMobile} className={styles.logo} />
      </Link>
      
      <button 
        onClick={handleClose}
        className={styles.closeButton}
        aria-label="Close sidebar"
      >
        <X size={25} />
      </button>
    </div>
  );
};

export default SidebarTop;

SidebarTop.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  setIsExpanded: PropTypes.func.isRequired,
  setIsMobileMenuOpen: PropTypes.func.isRequired,
};