// File: frontend/src/pages/InvestorPitch/components/slides/GoToMarketExecutionSlide/GoToMarketExecutionSlide.js

import React from 'react';
import { motion } from 'framer-motion';
import * as LucideIcons from 'lucide-react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import { ANIMATION_VARIANTS } from '../../../constants/animations';
import styles from './GoToMarketExecutionSlide.module.css';

const GoToMarketExecutionSlide = () => {
  const { t } = useLanguage();

  const unfairAdvantages = [
    {
      title: t('investor_pitch.go_to_market_execution.advantages.cost.title', 'Lower Operating Costs'),
      description: t('investor_pitch.go_to_market_execution.advantages.cost.description', 'AI-1 reduces operating expenses to 15% of GWP vs. industry average of 30%'),
      icon: "TrendingDown",
      color: "var(--color-green-310)"
    },
    {
      title: t('investor_pitch.go_to_market_execution.advantages.integration.title', 'Rapid Integration'),
      description: t('investor_pitch.go_to_market_execution.advantages.integration.description', 'API-first architecture enables 2-week integration with any partner'),
      icon: "Zap",
      color: "var(--color-blue-310)"
    },
    {
      title: t('investor_pitch.go_to_market_execution.advantages.platform.title', 'Multi-Channel Platform'),
      description: t('investor_pitch.go_to_market_execution.advantages.platform.description', 'Seamless experience across web, mobile, API, and agent portals'),
      icon: "Users",
      color: "var(--color-orange-310)"
    }
  ];

  const executionPhases = [
    {
      phase: t('investor_pitch.go_to_market_execution.phases.regulatory.phase', 'Phase 1'),
      title: t('investor_pitch.go_to_market_execution.phases.regulatory.title', 'Regulatory Approval'),
      actions: [
        t('investor_pitch.go_to_market_execution.phases.regulatory.actions.shell', 'Establish insurance shell company'),
        t('investor_pitch.go_to_market_execution.phases.regulatory.actions.reinsurance', 'Secure reinsurance partnerships'),
        t('investor_pitch.go_to_market_execution.phases.regulatory.actions.filing', 'Complete regulatory filings')
      ],
      status: t('investor_pitch.go_to_market_execution.status.month_1_3', 'Months 1-3'),
      color: "var(--color-blue-310)"
    },
    {
      phase: t('investor_pitch.go_to_market_execution.phases.acquisition.phase', 'Phase 2'),
      title: t('investor_pitch.go_to_market_execution.phases.acquisition.title', 'Customer Acquisition'),
      actions: [
        t('investor_pitch.go_to_market_execution.phases.acquisition.actions.direct', 'Launch direct-to-consumer channel'),
        t('investor_pitch.go_to_market_execution.phases.acquisition.actions.embedded', 'Deploy embedded insurance APIs'),
        t('investor_pitch.go_to_market_execution.phases.acquisition.actions.agents', 'Onboard independent agents')
      ],
      status: t('investor_pitch.go_to_market_execution.status.month_4_6', 'Months 4-6'),
      color: "var(--color-green-310)"
    },
    {
      phase: t('investor_pitch.go_to_market_execution.phases.scaling.phase', 'Phase 3'),
      title: t('investor_pitch.go_to_market_execution.phases.scaling.title', 'Scaling Operations'),
      actions: [
        t('investor_pitch.go_to_market_execution.phases.scaling.actions.bundling', 'Implement product bundling'),
        t('investor_pitch.go_to_market_execution.phases.scaling.actions.churn', 'Optimize retention & reduce churn'),
        t('investor_pitch.go_to_market_execution.phases.scaling.actions.expansion', 'Expand to additional states/provinces')
      ],
      status: t('investor_pitch.go_to_market_execution.status.month_7_12', 'Months 7-12'),
      color: "var(--color-orange-310)"
    }
  ];

  const successMetrics = [
    {
      metric: t('investor_pitch.go_to_market_execution.metrics.bundling.metric', 'Product Bundling'),
      target: t('investor_pitch.go_to_market_execution.metrics.bundling.target', '2.5 products/customer'),
      current: t('investor_pitch.go_to_market_execution.metrics.bundling.current', '1.2 products/customer'),
      icon: "Package",
      color: "var(--color-blue-310)"
    },
    {
      metric: t('investor_pitch.go_to_market_execution.metrics.retention.metric', 'Retention Rate'),
      target: t('investor_pitch.go_to_market_execution.metrics.retention.target', '95%'),
      current: t('investor_pitch.go_to_market_execution.metrics.retention.current', '87%'),
      icon: "RefreshCw",
      color: "var(--color-green-310)"
    },
    {
      metric: t('investor_pitch.go_to_market_execution.metrics.opex.metric', 'Operating Expenses'),
      target: t('investor_pitch.go_to_market_execution.metrics.opex.target', '15% of GWP'),
      current: t('investor_pitch.go_to_market_execution.metrics.opex.current', '18% of GWP'),
      icon: "TrendingDown",
      color: "var(--color-orange-310)"
    }
  ];

  const partnerships = [
    {
      title: t('investor_pitch.go_to_market_execution.partnerships.call_center', 'Call Center Services'),
      icon: "Headphones",
      color: "var(--color-blue-310)"
    },
    {
      title: t('investor_pitch.go_to_market_execution.partnerships.repair', 'Repair Networks'),
      icon: "Wrench",
      color: "var(--color-green-310)"
    },
    {
      title: t('investor_pitch.go_to_market_execution.partnerships.reinsurance', 'Reinsurance Partners'),
      icon: "Shield",
      color: "var(--color-orange-310)"
    },
    {
      title: t('investor_pitch.go_to_market_execution.partnerships.legal', 'Legal & Compliance'),
      icon: "Scale",
      color: "var(--color-purple-200)"
    }
  ];

  return (
    <motion.div
      className={styles.container}
      variants={ANIMATION_VARIANTS.stagger.container}
      initial="hidden"
      animate="visible"
    >
      <motion.div 
        className={styles.header}
        variants={ANIMATION_VARIANTS.fadeIn}
      >
        <h2 className={styles.title}>
          {t('investor_pitch.go_to_market_execution.title', 'Go-to-Market Execution Plan')}
        </h2>
        <p className={styles.subtitle}>
          {t('investor_pitch.go_to_market_execution.subtitle', 'How we\'ll build a $3.2B insurance company in 24 months')}
        </p>
      </motion.div>

      <motion.div 
        className={styles.content}
        variants={ANIMATION_VARIANTS.stagger.container}
      >
        {/* Unfair Advantages Section */}
        <motion.section 
          className={styles.advantagesSection}
          variants={ANIMATION_VARIANTS.fadeIn}
        >
          <h3 className={styles.sectionTitle}>
            {t('investor_pitch.go_to_market_execution.sections.advantages', 'Our Unfair Advantages')}
          </h3>
          <div className={styles.advantagesGrid}>
            {unfairAdvantages.map((advantage, index) => {
              const Icon = LucideIcons[advantage.icon];
              return (
                <motion.div
                  key={advantage.title}
                  className={styles.advantageCard}
                  variants={ANIMATION_VARIANTS.stagger.item}
                  custom={index}
                  whileHover={{ scale: 1.01 }}
                  transition={{ type: 'spring', stiffness: 300, damping: 30 }}
                >
                  <div 
                    className={styles.iconWrapper}
                    style={{ color: advantage.color }}
                  >
                    <Icon className={styles.icon} />
                  </div>
                  <div className={styles.advantageContent}>
                    <h4 className={styles.advantageTitle}>{advantage.title}</h4>
                    <p className={styles.advantageDescription}>{advantage.description}</p>
                  </div>
                </motion.div>
              );
            })}
          </div>
        </motion.section>

        <div className={styles.middleSection}>
          {/* Execution Timeline */}
          <motion.section 
            className={styles.executionSection}
            variants={ANIMATION_VARIANTS.fadeIn}
          >
            <h3 className={styles.sectionTitle}>
              {t('investor_pitch.go_to_market_execution.sections.execution', '12-Month Execution Plan')}
            </h3>
            <div className={styles.executionTimeline}>
              {executionPhases.map((phase, index) => (
                <div key={phase.phase} className={styles.executionPhase}>
                  <div className={styles.phaseHeader}>
                    <div className={styles.phaseInfo}>
                      <div 
                        className={styles.phaseIndicator}
                        style={{ backgroundColor: phase.color }}
                      />
                      <span className={styles.phaseName}>{phase.phase}</span>
                      <span 
                        className={styles.phaseStatus}
                        style={{ borderColor: phase.color }}
                      >
                        {phase.status}
                      </span>
                    </div>
                    <h4 className={styles.phaseTitle}>{phase.title}</h4>
                  </div>
                  <ul className={styles.phaseActions}>
                    {phase.actions.map((action, actionIndex) => (
                      <li key={actionIndex} className={styles.phaseAction}>{action}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </motion.section>

          {/* Success Metrics */}
          <motion.section 
            className={styles.metricsSection}
            variants={ANIMATION_VARIANTS.fadeIn}
          >
            <h3 className={styles.sectionTitle}>
              {t('investor_pitch.go_to_market_execution.sections.metrics', 'Key Success Metrics')}
            </h3>
            <div className={styles.metricsGrid}>
              {successMetrics.map((metricItem, index) => {
                const Icon = LucideIcons[metricItem.icon];
                return (
                  <div key={metricItem.metric} className={styles.metricCard}>
                    <div className={styles.metricHeader}>
                      <div 
                        className={styles.metricIconWrapper}
                        style={{ color: metricItem.color }}
                      >
                        <Icon className={styles.metricIcon} />
                      </div>
                      <span className={styles.metricName}>{metricItem.metric}</span>
                    </div>
                    <div className={styles.metricValues}>
                      <div className={styles.targetValue}>
                        <span className={styles.valueLabel}>
                          {t('investor_pitch.go_to_market_execution.metrics.target_label', 'Target')}
                        </span>
                        <span 
                          className={styles.value}
                          style={{ color: metricItem.color }}
                        >
                          {metricItem.target}
                        </span>
                      </div>
                      <div className={styles.currentValue}>
                        <span className={styles.valueLabel}>
                          {t('investor_pitch.go_to_market_execution.metrics.current_label', 'Current')}
                        </span>
                        <span className={styles.value}>{metricItem.current}</span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </motion.section>
        </div>

        {/* Partnerships Section */}
        <motion.section 
          className={styles.partnershipsSection}
          variants={ANIMATION_VARIANTS.fadeIn}
        >
          <h3 className={styles.sectionTitle}>
            {t('investor_pitch.go_to_market_execution.sections.partnerships', 'Strategic Partnerships')}
          </h3>
          <div className={styles.partnershipsGrid}>
            {partnerships.map((partnership, index) => {
              const Icon = LucideIcons[partnership.icon];
              return (
                <motion.div
                  key={index}
                  className={styles.partnershipCard}
                  variants={ANIMATION_VARIANTS.stagger.item}
                  custom={index}
                  whileHover={{ scale: 1.01 }}
                  transition={{ type: 'spring', stiffness: 300, damping: 30 }}
                >
                  <div 
                    className={styles.partnershipIconWrapper}
                    style={{ color: partnership.color }}
                  >
                    <Icon className={styles.partnershipIcon} />
                  </div>
                  <p className={styles.partnershipTitle}>{partnership.title}</p>
                </motion.div>
              );
            })}
          </div>
        </motion.section>
      </motion.div>
    </motion.div>
  );
};

export default GoToMarketExecutionSlide;