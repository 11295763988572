// File: frontend/src/services/tokenService.js 

import { jwtDecode } from 'jwt-decode';
import { AUTH_CONFIG } from '../config/constants';

// Token-related types and constants
const TokenType = {
  ACCESS: 'access_token',
  ID: 'id_token',
  REFRESH: 'refresh_token'
};

export const TokenStatus = {
  VALID: 'valid',
  EXPIRED: 'expired',
  NEEDS_REFRESH: 'needs_refresh',
  INVALID: 'invalid'
};

// Secure in-memory storage implementation
class SecureTokenStorage {
  #tokenStore = new Map();

  setToken(key, value) {
    console.log(`Setting ${key} token`);
    this.#tokenStore.set(key, value);
  }

  getToken(key) {
    const token = this.#tokenStore.get(key);
    console.log(`Getting ${key} token:`, !!token);
    return token;
  }

  clearTokens() {
    this.#tokenStore.clear();
  }
}

// Main TokenService implementation
class TokenService {
  static #instance;
  #secureStorage;
  #refreshTimer;
  #lastValidation = 0;
  #lastValidationResult = null;
  #validationThreshold = 60000; // 60 seconds minimum between validations

  constructor() {
    if (TokenService.#instance) {
        return TokenService.#instance;
    }
    
    this.#secureStorage = new SecureTokenStorage();
    this.#refreshTimer = null;
    TokenService.#instance = this;
  }

  // Add this method to verify singleton behavior during development
  static getInstance() {
    if (!TokenService.#instance) {
        TokenService.#instance = new TokenService();
    }
    return TokenService.#instance;
  }

  
  // Do we have the tokens?
  hasValidTokens() {
    try {
      const tokens = this.getTokens();
      return tokens?.status === TokenStatus.VALID;
    } catch (error) {
      console.error('Error checking token validity:', error);
      return false;
    }
  }

  // Token Storage Operations
  setTokens(tokens) {
    try {
      console.log('Setting tokens:', {
        hasAccessToken: !!tokens.access_token,
        hasIdToken: !!tokens.id_token,
        hasRefreshToken: !!tokens.refresh_token,
        timestamp: new Date().toLocaleString()
      });

      if (!this.#validateTokenSet(tokens)) {
        throw new Error('Invalid token set provided');
      }

      // Store all tokens in localStorage for consistency
      localStorage.setItem(TokenType.ACCESS, tokens.access_token);
      localStorage.setItem(TokenType.ID, tokens.id_token);
      localStorage.setItem(TokenType.REFRESH, tokens.refresh_token);

      // Add expiry time logging
      const decoded = jwtDecode(tokens.access_token);
      console.log('Token expiry details:', {
        expiresAt: new Date(decoded.exp * 1000).toLocaleString(),
        refreshThreshold: new Date(decoded.exp * 1000 - AUTH_CONFIG.TOKEN_REFRESH_THRESHOLD).toLocaleString()
      });

      const idTokenClaims = this.getTokenClaims(TokenType.ID);
      if (idTokenClaims?.sub) {
        localStorage.setItem('cognito_sub', idTokenClaims.sub);
      }

      return true;
    } catch (error) {
      console.error('Error setting tokens:', error);
      this.clearTokens();
      return false;
    }
  }

  getTokens() {
    try {
      // Get all tokens from localStorage for consistency
      const tokens = {
        access_token: localStorage.getItem(TokenType.ACCESS),
        id_token: localStorage.getItem(TokenType.ID),
        refresh_token: localStorage.getItem(TokenType.REFRESH)
      };

      // Add debugging
      console.log('Token check:', {
        hasAccessToken: !!tokens.access_token,
        hasIdToken: !!tokens.id_token,
        hasRefreshToken: !!tokens.refresh_token,
        timestamp: new Date().toLocaleString('en-US', { dateStyle: 'medium', timeStyle: 'medium' })
      });

      // Only decode if we have an access token
      if (tokens.access_token) {
        try {
          const decoded = jwtDecode(tokens.access_token);
          console.log('Token expiry details:', {
            expiresAt: new Date(decoded.exp * 1000).toLocaleString(),
            refreshThreshold: new Date(decoded.exp * 1000 - AUTH_CONFIG.TOKEN_REFRESH_THRESHOLD).toLocaleString()
          });
        } catch (decodeError) {
          console.error('Error decoding token:', decodeError);
          // Invalid token, clear it
          this.clearTokens();
          return null;
        }
      }

      const status = this.#validateTokenStatus(tokens);
      return {
        ...tokens,
        status,
        needsRefresh: status === TokenStatus.NEEDS_REFRESH
      };
    } catch (error) {
      console.error('Error getting tokens:', error);
      return null;
    }
  }

  clearTokens() {
    this.#secureStorage.clearTokens();
    Object.values(TokenType).forEach(key => localStorage.removeItem(key));
    localStorage.removeItem('cognito_sub');
    this.#clearRefreshTimer();
  }

  // Token Validation
  #validateTokenSet(tokens) {
    return tokens?.access_token 
      && tokens?.id_token 
      && tokens?.refresh_token 
      && this.#validateTokenStructure(tokens.access_token)
      && this.#validateTokenStructure(tokens.id_token);
  }

  #validateTokenStructure(token) {
    try {
      const decoded = jwtDecode(token);
      return decoded?.exp && decoded?.iat && decoded?.sub;
    } catch {
      return false;
    }
  }

  // Add this method to maintain compatibility with existing code
  validateSession() {
    const now = Date.now();
    // If we validated recently, return the last result
    if (now - this.#lastValidation < this.#validationThreshold) {
        return this.#lastValidationResult;
    }

    try {
        const tokens = this.getTokens();
        this.#lastValidation = now;
        
        // Store the result
        this.#lastValidationResult = tokens ? (
            tokens.status === TokenStatus.NEEDS_REFRESH ? 'needs_refresh' :
            tokens.status === TokenStatus.VALID
        ) : false;

        // Only log occasionally in development
        if (process.env.NODE_ENV === 'dev') {
            console.debug('Session validation:', {
                hasTokens: !!tokens,
                status: tokens?.status
            });
        }

        return this.#lastValidationResult;
    } catch (error) {
        console.error('Session validation failed:', error);
        this.#lastValidationResult = false;
        return false;
    }
  }
  
  // Internal validation method
  #validateTokenStatus(tokens) {
    console.log('Validating token status:', {
      hasAccessToken: !!tokens?.access_token,
      hasRefreshToken: !!tokens?.refresh_token,
      timestamp: new Date().toLocaleString('en-US', { dateStyle: 'medium', timeStyle: 'medium' })
    });

    if (!tokens?.access_token && !tokens?.refresh_token) {
      console.log('No tokens found', {timestamp: new Date().toLocaleString('en-US', { dateStyle: 'medium', timeStyle: 'medium' })});  
      return TokenStatus.INVALID;
    }

    try {
        // If we have refresh token but no access token, we need refresh
        if (!tokens.access_token && tokens.refresh_token) {
            return TokenStatus.NEEDS_REFRESH;
        }

        // If we have access token, check its expiry
        if (tokens.access_token) {
            const decoded = jwtDecode(tokens.access_token);
            const currentTime = Date.now() / 1000;
            const timeToExpiry = decoded.exp - currentTime;

            if (timeToExpiry <= 0) {
                return tokens.refresh_token ? TokenStatus.NEEDS_REFRESH : TokenStatus.EXPIRED;
            }
            if (timeToExpiry <= AUTH_CONFIG.TOKEN_REFRESH_THRESHOLD / 1000) {
                return TokenStatus.NEEDS_REFRESH;
            }
            return TokenStatus.VALID;
        }

        return TokenStatus.INVALID;
    } catch {
        return tokens.refresh_token ? TokenStatus.NEEDS_REFRESH : TokenStatus.INVALID;
    }
  }

  // Token Claims and Information
  getTokenClaims(tokenType = TokenType.ID) {
    try {
      // Use localStorage consistently
      const token = localStorage.getItem(tokenType);
      
      if (!token) {
        return null;
      }

      try {
        return jwtDecode(token);
      } catch (decodeError) {
        console.error(`Invalid ${tokenType} token:`, decodeError);
        // Invalid token, clear it
        localStorage.removeItem(tokenType);
        return null;
      }
    } catch (error) {
      console.error(`Failed to get ${tokenType} claims:`, error);
      return null;
    }
  }

  getCognitoSub() {
    try {
      return localStorage.getItem('cognito_sub') || null;
    } catch (error) {
      console.error('Error getting Cognito sub:', error);
      return null;
    }
  }

  // Token Refresh Management
  setupTokenRefresh(tokens, refreshCallback, signOutCallback) {
    try {
      if (!tokens?.access_token) {
        throw new Error('No access token provided for refresh setup');
      }

      const decoded = jwtDecode(tokens.access_token);
      const now = Date.now();
      const expiry = decoded.exp * 1000;
      const refreshTime = expiry - now - AUTH_CONFIG.TOKEN_REFRESH_THRESHOLD;

      console.log('Token refresh calculation:', {
        currentTime: new Date(now).toISOString(),
        expiryTime: new Date(expiry).toISOString(),
        refreshIn: `${refreshTime/1000} seconds`,
        threshold: `${AUTH_CONFIG.TOKEN_REFRESH_THRESHOLD/1000} seconds`
      });

      if (refreshTime <= 0) {
        console.log('Token expired or near expiration, refreshing immediately');
        this.#executeTokenRefresh(refreshCallback, signOutCallback);
        return;
      }

      console.log(`Scheduling token refresh in ${refreshTime/1000} seconds`);
      this.#clearRefreshTimer();

      const timerStart = Date.now();
      let retryCount = 0;
      const MAX_RETRIES = 3;
      
      this.#refreshTimer = setTimeout(async () => {
        console.log('Refresh timer fired:', {
          scheduledAt: new Date(timerStart).toLocaleString(),
          firedAt: new Date().toLocaleString(),
          elapsedSeconds: (Date.now() - timerStart) / 1000
        });

        const attemptRefresh = async () => {
          try {
            await this.#executeTokenRefresh(refreshCallback, signOutCallback);
          } catch (error) {
            console.error(`Refresh attempt ${retryCount + 1} failed:`, error);
            if (retryCount < MAX_RETRIES) {
              retryCount++;
              // Retry after short delay
              setTimeout(attemptRefresh, 5000);
            } else {
              signOutCallback().catch(console.error);
            }
          }
        };

        await attemptRefresh();
      }, refreshTime);

      console.log('Refresh timer details:', {
        timerId: this.#refreshTimer,
        scheduledFor: new Date(Date.now() + refreshTime).toLocaleString()
      });
    } catch (error) {
      console.error('Token refresh setup failed:', error);
      // Don't sign out immediately on setup failure
      console.error('Token refresh setup failed, will retry on next check');
    }
  }

  #executeTokenRefresh(refreshCallback, signOutCallback) {
    console.log('Starting token refresh execution');
    
    // Get the Cognito sub and refresh token
    const cognitoSub = this.getCognitoSub();
    const refreshToken = localStorage.getItem(TokenType.REFRESH);
    
    if (!cognitoSub || !refreshToken) {
      console.error('Missing refresh credentials');
      signOutCallback().catch(console.error);
      return;
    }
    
    refreshCallback(refreshToken, cognitoSub)
      .then(newTokens => {
        console.log('Received new tokens, updating...');
        if (this.setTokens(newTokens)) {
          console.log('New tokens set successfully, setting up next refresh');
          this.setupTokenRefresh(newTokens, refreshCallback, signOutCallback);
        } else {
          throw new Error('Failed to set new tokens after refresh');
        }
      })
      .catch(error => {
        console.error('Token refresh failed:', error);
        this.#clearRefreshTimer();
        signOutCallback().catch(console.error);
      });
  }

  #clearRefreshTimer() {
    if (this.#refreshTimer) {
      clearTimeout(this.#refreshTimer);
      this.#refreshTimer = null;
    }
  }
}

// Export singleton instance
export const tokenService = new TokenService();