// File: frontend/src/pages/InvestorPitch/PitchDeck.js (update)

import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import useSlideNavigation from './hooks/useSlideNavigation';
import SlideNavigation from './components/navigation/SlideNavigation';
import SlideQuickNav from './components/navigation/SlideQuickNav';
import { useLanguage } from '../../contexts/LanguageContext';
import { API_ENDPOINTS } from '../../config/constants';
import styles from './PitchDeck.module.css';
import axios from 'axios';

const PitchDeck = ({ slides, deckId, requireAuth = false }) => {
  const { t } = useLanguage();
  const [isAuthorized, setIsAuthorized] = useState(!requireAuth);
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [accessData, setAccessData] = useState(null);

  const {
    currentSlide,
    direction,
    slideVariants,
    goToNextSlide,
    goToPreviousSlide,
    goToSlide,
    handleKeyboardNavigation
  } = useSlideNavigation(slides.length);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyboardNavigation);
    return () => window.removeEventListener('keydown', handleKeyboardNavigation);
  }, [handleKeyboardNavigation]);

  // Check if user has access from localStorage
  useEffect(() => {
    if (!requireAuth) return;
    
    const storedAccess = localStorage.getItem(`pitch_access_${deckId}`);
    if (storedAccess) {
      try {
        const accessData = JSON.parse(storedAccess);
        const now = new Date();
        const expiryDate = new Date(accessData.expires_at);
        
        if (now < expiryDate) {
          setIsAuthorized(true);
          setAccessData(accessData);
          
          // Update access count in the background
          updateAccessCount(accessData.access_id).catch(err => {
            console.error('Error updating access count:', err);
          });
        }
      } catch (err) {
        console.error('Error parsing stored access:', err);
      }
    }
  }, [deckId, requireAuth]);

  const updateAccessCount = async (accessId) => {
    try {
      await axios.post(API_ENDPOINTS.PITCH.UPDATE_ACCESS_COUNT, {
        accessId
      });
    } catch (error) {
      console.error('Failed to update access count:', error);
      // Non-critical error, so we don't need to show it to the user
    }
  };

  const handleVerifyAccess = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    
    try {
      const response = await axios.post(API_ENDPOINTS.PITCH.VERIFY_ACCESS, {
        email,
        deckId
      });
      
      const { data } = response;
      
      if (data.success) {
        setIsAuthorized(true);
        setAccessData(data.access);
        
        // Store access in localStorage with expiry
        localStorage.setItem(`pitch_access_${deckId}`, JSON.stringify(data.access));
        
        // Update access count
        updateAccessCount(data.access.access_id);
      } else {
        setError(data.message || 'Access denied. Please contact the administrator.');
      }
    } catch (err) {
      if (err.response && err.response.status === 403) {
        setError('Access denied. Please contact the administrator.');
      } else {
        setError('An error occurred. Please try again.');
        console.error('Error verifying access:', err);
      }
    } finally {
      setIsLoading(false);
    }
  };

  if (!isAuthorized) {
    return (
      <div className={styles.authContainer}>
        <div className={styles.authCard}>
          <h2 className={styles.authTitle}>{t('investor_pitch.auth.title')}</h2>
          <p className={styles.authDescription}>{t('investor_pitch.auth.description')}</p>
          
          <form onSubmit={handleVerifyAccess} className={styles.authForm}>
            <div className={styles.formGroup}>
              <label htmlFor="email" className={styles.label}>
                {t('investor_pitch.auth.email_label')}
              </label>
              <input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={styles.input}
                placeholder={t('investor_pitch.auth.email_placeholder')}
                required
              />
            </div>
            
            {error && <div className={styles.error}>{error}</div>}
            
            <button 
              type="submit" 
              className={styles.submitButton}
              disabled={isLoading}
            >
              {isLoading ? t('investor_pitch.auth.loading') : t('investor_pitch.auth.submit')}
            </button>
          </form>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <div className={styles.headerContent}>
          <div className={styles.slideQuickNav}>
            <SlideQuickNav
              currentSlide={currentSlide}
              totalSlides={slides.length}
              slides={slides}
              onSlideSelect={goToSlide}
            />
          </div>
        </div>
      </header>

      <main className={styles.main}>
        <AnimatePresence mode="wait" custom={direction}>
          <motion.div
            key={currentSlide}
            custom={direction}
            variants={slideVariants}
            initial="enter"
            animate="center"
            exit="exit"
            className={styles.slideContainer}
          >
            {slides[currentSlide].content}
          </motion.div>
        </AnimatePresence>
      </main>

      <SlideNavigation
        currentSlide={currentSlide}
        totalSlides={slides.length}
        onNext={goToNextSlide}
        onPrevious={goToPreviousSlide}
      />

      <footer className={styles.footer}>
        <div className={styles.footerInfo}>
          <div className={styles.confidentialInfo}>
            <span className={styles.confidential}>
              {t('investor_pitch.master.common.confidential')}
            </span>
            <span className={styles.separator}>•</span>
            <span>
              {t('investor_pitch.master.common.prepared_for')} {process.env.REACT_APP_ENTERPRISE_NAME}
            </span>
            <span className={styles.separator}>•</span>
            <span className={styles.enterprise_id}>
              ID: {process.env.REACT_APP_ENTERPRISE_ID}
            </span>
          </div>
          <div className={styles.copyright}>
            © {new Date().getFullYear()} {t('investor_pitch.master.common.company')}
          </div>
        </div>
      </footer>
    </div>
  );
};

export default PitchDeck;