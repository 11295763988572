// File: frontend/src/pages/EmbeddedInsurance/components/ProductBuilder/ProductPreview.js

import React from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import { getProducts, getCoverages, getJurisdictions } from '../../data/apiEndpoints';
import CodeBlock from '../common/CodeBlock';
import styles from './styles/ProductPreview.module.css';

const ProductPreview = ({
  product,
  coverage,
  jurisdiction,
  pricingRules,
  underwritingRules,
  onClose
}) => {
  const { t } = useLanguage();
  
  const productDetails = getProducts().find(p => p.id === product) || {};
  const coverageDetails = getCoverages(product).find(c => c.id === coverage) || {};
  const jurisdictionDetails = getJurisdictions().find(j => j.id === jurisdiction) || {};

  const productConfig = {
    product_id: product,
    product_name: productDetails.name,
    coverage_id: coverage,
    coverage_name: coverageDetails.name,
    jurisdiction_id: jurisdiction,
    jurisdiction_name: jurisdictionDetails.name,
    pricing_rules: pricingRules,
    underwriting_rules: underwritingRules,
    created_at: new Date().toISOString(),
    status: 'active'
  };

  const handleCopyConfig = () => {
    navigator.clipboard.writeText(JSON.stringify(productConfig, null, 2));
    // You could add a toast notification here
  };

  const handleDeploy = () => {
    // In a real implementation, this would call an API to deploy the product
    alert('Product deployed successfully!');
    onClose();
  };

  return (
    <div className={styles.overlay}>
      <div className={styles.modal}>
        <div className={styles.modalHeader}>
          <h2 className={styles.modalTitle}>{t('product_preview') || 'Product Preview'}</h2>
          <button className={styles.closeButton} onClick={onClose}>
            <i className="fas fa-times"></i>
          </button>
        </div>
        
        <div className={styles.modalContent}>
          <div className={styles.previewSection}>
            <h3 className={styles.previewTitle}>{t('product_configuration') || 'Product Configuration'}</h3>
            <div className={styles.productSummary}>
              <div className={styles.summaryItem}>
                <div className={styles.summaryLabel}>{t('product') || 'Product'}</div>
                <div className={styles.summaryValue}>{productDetails.name}</div>
              </div>
              <div className={styles.summaryItem}>
                <div className={styles.summaryLabel}>{t('coverage') || 'Coverage'}</div>
                <div className={styles.summaryValue}>{coverageDetails.name}</div>
              </div>
              <div className={styles.summaryItem}>
                <div className={styles.summaryLabel}>{t('jurisdiction') || 'Jurisdiction'}</div>
                <div className={styles.summaryValue}>{jurisdictionDetails.name}</div>
              </div>
              <div className={styles.summaryItem}>
                <div className={styles.summaryLabel}>{t('pricing_rules') || 'Pricing Rules'}</div>
                <div className={styles.summaryValue}>{pricingRules.length}</div>
              </div>
              <div className={styles.summaryItem}>
                <div className={styles.summaryLabel}>{t('underwriting_rules') || 'Underwriting Rules'}</div>
                <div className={styles.summaryValue}>{underwritingRules.length}</div>
              </div>
            </div>
          </div>
          
          <div className={styles.configCode}>
            <div className={styles.codeHeader}>
              <h3 className={styles.codeTitle}>{t('configuration_json') || 'Configuration JSON'}</h3>
              <button className={styles.copyButton} onClick={handleCopyConfig}>
                <i className="fas fa-copy"></i>
                <span>{t('copy') || 'Copy'}</span>
              </button>
            </div>
            <CodeBlock 
              code={JSON.stringify(productConfig, null, 2)}
              language="json"
            />
          </div>
          
          <div className={styles.deploySection}>
            <h3 className={styles.deployTitle}>{t('ready_to_deploy') || 'Ready to Deploy'}</h3>
            <p className={styles.deployDescription}>
              {t('deploy_description') || 'Your product configuration is ready to be deployed. Once deployed, it will be available for use in your embedded insurance applications.'}
            </p>
            
            <div className={styles.deployActions}>
              <button className={styles.cancelButton} onClick={onClose}>
                {t('cancel') || 'Cancel'}
              </button>
              <button className={styles.deployButton} onClick={handleDeploy}>
                <i className="fas fa-rocket"></i>
                <span>{t('deploy_product') || 'Deploy Product'}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductPreview;