// File: frontend/src/pages/PropertyBulkUpload/index.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../contexts/LanguageContext';
import styles from './PropertyBulkUpload.module.css';

const PropertyBulkUpload = () => {
  const { t } = useLanguage();
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [uploadResult, setUploadResult] = useState(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setError(null);
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    
    if (!file) {
      setError(t('please_select_a_file'));
      return;
    }
    
    if (file.type !== 'text/csv' && !file.name.endsWith('.csv')) {
      setError(t('please_upload_a_csv_file'));
      return;
    }
    
    setUploading(true);
    setUploadProgress(0);
    setError(null);
    
    try {
      // Simulate upload progress
      const progressInterval = setInterval(() => {
        setUploadProgress(prev => {
          if (prev >= 95) {
            clearInterval(progressInterval);
            return prev;
          }
          return prev + 5;
        });
      }, 300);
      
      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 3000));
      
      clearInterval(progressInterval);
      setUploadProgress(100);
      setUploadComplete(true);
      
      // Mock result
      setUploadResult({
        totalRecords: 150,
        successfulRecords: 142,
        failedRecords: 8,
        errors: [
          { row: 5, message: 'Invalid address format' },
          { row: 23, message: 'Missing required field: property_type' },
          { row: 47, message: 'Invalid year_built value' },
          { row: 62, message: 'Duplicate property ID' },
          { row: 89, message: 'Invalid postal code format' },
          { row: 103, message: 'Missing required field: city' },
          { row: 128, message: 'Invalid land_area value' },
          { row: 145, message: 'Missing required field: province_state_code' }
        ]
      });
    } catch (err) {
      console.error('Error uploading file:', err);
      setError(t('failed_to_upload_file'));
    } finally {
      setUploading(false);
    }
  };

  const handleBack = () => {
    navigate('/property-insight');
  };

  const handleNewUpload = () => {
    setFile(null);
    setUploadProgress(0);
    setUploadComplete(false);
    setUploadResult(null);
    setError(null);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.titleContainer}>
          <h1 className={styles.title}>{t('property_bulk_upload')}</h1>
          <p className={styles.subtitle}>{t('upload_multiple_properties_at_once')}</p>
        </div>
        <button className={styles.backButton} onClick={handleBack}>
          {t('back_to_property_insight')}
        </button>
      </div>
      
      {!uploadComplete ? (
        <div className={styles.uploadContainer}>
          <div className={styles.uploadInstructions}>
            <h2 className={styles.instructionsTitle}>{t('upload_instructions')}</h2>
            <ol className={styles.instructionsList}>
              <li>{t('prepare_csv_file_with_property_data')}</li>
              <li>{t('ensure_csv_follows_required_format')}</li>
              <li>{t('select_file_and_click_upload')}</li>
              <li>{t('review_results_after_upload')}</li>
            </ol>
            
            <div className={styles.templateSection}>
              <h3 className={styles.templateTitle}>{t('csv_template')}</h3>
              <p className={styles.templateDescription}>
                {t('download_template_description')}
              </p>
              <a href="/templates/property_upload_template.csv" className={styles.templateLink}>
                {t('download_csv_template')}
              </a>
            </div>
          </div>
          
          <div className={styles.uploadForm}>
            <h2 className={styles.uploadTitle}>{t('upload_file')}</h2>
            
            <form onSubmit={handleUpload}>
              <div className={styles.fileInputContainer}>
                <label htmlFor="file-upload" className={styles.fileInputLabel}>
                  {file ? file.name : t('choose_file')}
                </label>
                <input
                  id="file-upload"
                  type="file"
                  accept=".csv"
                  onChange={handleFileChange}
                  className={styles.fileInput}
                />
              </div>
              
              {error && <div className={styles.error}>{error}</div>}
              
              {uploading && (
                <div className={styles.progressContainer}>
                  <div className={styles.progressBar}>
                    <div 
                      className={styles.progressFill} 
                      style={{ width: `${uploadProgress}%` }}
                    ></div>
                  </div>
                  <div className={styles.progressText}>
                    {uploadProgress}% {t('uploaded')}
                  </div>
                </div>
              )}
              
              <button 
                type="submit" 
                className={styles.uploadButton}
                disabled={!file || uploading}
              >
                {uploading ? t('uploading') : t('upload')}
              </button>
            </form>
          </div>
        </div>
      ) : (
        <div className={styles.resultsContainer}>
          <div className={styles.resultsSummary}>
            <h2 className={styles.resultsTitle}>{t('upload_complete')}</h2>
            
            <div className={styles.resultsStats}>
              <div className={styles.resultsStat}>
                <div className={styles.resultsStatLabel}>{t('total_records')}</div>
                <div className={styles.resultsStatValue}>{uploadResult.totalRecords}</div>
              </div>
              
              <div className={styles.resultsStat}>
                <div className={styles.resultsStatLabel}>{t('successful_records')}</div>
                <div className={`${styles.resultsStatValue} ${styles.successValue}`}>
                  {uploadResult.successfulRecords}
                </div>
              </div>
              
              <div className={styles.resultsStat}>
                <div className={styles.resultsStatLabel}>{t('failed_records')}</div>
                <div className={`${styles.resultsStatValue} ${styles.failedValue}`}>
                  {uploadResult.failedRecords}
                </div>
              </div>
            </div>
            
            <div className={styles.resultsActions}>
              <button className={styles.newUploadButton} onClick={handleNewUpload}>
                {t('upload_another_file')}
              </button>
              <button className={styles.viewPropertiesButton} onClick={handleBack}>
                {t('view_properties')}
              </button>
            </div>
          </div>
          
          {uploadResult.errors.length > 0 && (
            <div className={styles.errorsContainer}>
              <h3 className={styles.errorsTitle}>{t('upload_errors')}</h3>
              
              <div className={styles.errorsTable}>
                <table className={styles.table}>
                  <thead>
                    <tr>
                      <th>{t('row')}</th>
                      <th>{t('error_message')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {uploadResult.errors.map((error, index) => (
                      <tr key={index}>
                        <td>{error.row}</td>
                        <td>{error.message}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              
              <div className={styles.downloadErrorsContainer}>
                <button className={styles.downloadErrorsButton}>
                  {t('download_error_report')}
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PropertyBulkUpload;