// File: frontend/src/components/JobApplicationForm/jobApplicationConstants.js

export const POSITION_CATEGORIES = {
    ACTUARIAL: {
      labelKey: 'positions.categories.actuarial',
      positions: [0, 1, 2, 3, 4, 5]
    },
    RISK_MANAGEMENT: {
      labelKey: 'positions.categories.riskManagement',
      positions: [6, 7, 8]
    },
    INVESTMENT: {
      labelKey: 'positions.categories.investment',
      positions: [9, 10, 11]
    },
    TECHNICAL: {
      labelKey: 'positions.categories.technical',
      positions: [12, 13, 14, 15, 16, 17, 18, 51, 52]
    },
    SCIENCE: {
      labelKey: 'positions.categories.science',
      positions: [19, 20, 21, 22, 23]
    },
    LEADERSHIP: {
      labelKey: 'positions.categories.leadership',
      positions: [24, 25, 26, 27, 53, 54, 55, 56, 57, 58]
    },
    INSURANCE_LEADERSHIP: {
      labelKey: 'positions.categories.insuranceLeadership',
      positions: [33, 34, 35, 36, 37]
    },
    UNDERWRITING: {
      labelKey: 'positions.categories.underwriting',
      positions: [38, 39, 40, 41]
    },
    CLAIMS: {
      labelKey: 'positions.categories.claims',
      positions: [42, 43, 44]
    },
    INSURANCE_OPERATIONS: {
      labelKey: 'positions.categories.insuranceOperations',
      positions: [45, 46, 47, 48, 49, 50]
    }
};
  
  export const COUNTRIES = {
    RESIDENCE: [
      { value: 'CA', labelKey: 'countries.canada', flag: '🇨🇦' },
      { value: 'US', labelKey: 'countries.unitedStates', flag: '🇺🇸' },
      { value: 'NL', labelKey: 'countries.netherlands', flag: '🇳🇱' },
      { value: 'FR', labelKey: 'countries.france', flag: '🇫🇷' },
      { value: 'ES', labelKey: 'countries.spain', flag: '🇪🇸' },
      { value: 'UK', labelKey: 'countries.unitedKingdom', flag: '🇬🇧' },
      { value: 'DE', labelKey: 'countries.germany', flag: '🇩🇪' },
      { value: 'MX', labelKey: 'countries.mexico', flag: '🇲🇽' },
      { value: 'TR', labelKey: 'countries.turkey', flag: '🇹🇷' }
    ],
    WORK_LOCATIONS: [
      { value: 'CA', labelKey: 'countries.canada', flag: '🇨🇦' },
      { value: 'US', labelKey: 'countries.unitedStates', flag: '🇺🇸' },
      { value: 'UK', labelKey: 'countries.unitedKingdom', flag: '🇬🇧' }
    ]
  };

  export const JOB_APPLICATION_ERROR_CODES = {
    INVALID_EMAIL: 'invalid_email',
    FILE_TOO_LARGE: 'file_too_large',
    INVALID_FILE_TYPE: 'invalid_file_type',
    MISSING_REQUIRED_FIELDS: 'missing_required_fields',
    SUBMISSION_FAILED: 'submission_failed',
    MAX_POSITIONS_EXCEEDED: 'max_positions_exceeded'
  };