// File: frontend/src/pages/InvestorPitch/components/slides/ProductEvolutionSlide/ProductEvolutionSlide.js

import React from 'react';
import { motion } from 'framer-motion';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import RoadmapCard from './RoadmapCard';
import CapabilityMatrix from './CapabilityMatrix';
import FeatureTimeline from './FeatureTimeline';
import { ANIMATION_VARIANTS } from '../../../constants/animations';
import styles from './ProductEvolutionSlide.module.css';

const ProductEvolutionSlide = () => {
  const { t } = useLanguage();

  const roadmapStages = [
    {
      version: "AI-1",
      date: t('investor_pitch.product_evolution.roadmap.ai1.date', 'Now'),
      focus: t('investor_pitch.product_evolution.roadmap.ai1.focus', 'Core Insurance Platform'),
      features: [
        t('investor_pitch.product_evolution.roadmap.ai1.features.telematics', 'Telematics & IoT Integration'),
        t('investor_pitch.product_evolution.roadmap.ai1.features.language', 'Multi-language Support'),
        t('investor_pitch.product_evolution.roadmap.ai1.features.platform', 'Insurance Platform'),
        t('investor_pitch.product_evolution.roadmap.ai1.features.risk', 'Risk Assessment')
      ],
      gradient: "linear-gradient(135deg, var(--color-blue-310), var(--color-blue-510))"
    },
    {
      version: "AI-2",
      date: t('investor_pitch.product_evolution.roadmap.ai2.date', 'Q3 2024'),
      focus: t('investor_pitch.product_evolution.roadmap.ai2.focus', 'Advanced Risk Models'),
      features: [
        t('investor_pitch.product_evolution.roadmap.ai2.features.migration', 'Legacy System Migration'),
        t('investor_pitch.product_evolution.roadmap.ai2.features.property', 'Property Risk Assessment'),
        t('investor_pitch.product_evolution.roadmap.ai2.features.language', 'Additional Languages'),
        t('investor_pitch.product_evolution.roadmap.ai2.features.risk', 'Enhanced Risk Models')
      ],
      gradient: "linear-gradient(135deg, var(--color-green-310), var(--color-green-510))"
    },
    {
      version: "AI-3",
      date: t('investor_pitch.product_evolution.roadmap.ai3.date', 'Q1 2025'),
      focus: t('investor_pitch.product_evolution.roadmap.ai3.focus', 'Capital Optimization'),
      features: [
        t('investor_pitch.product_evolution.roadmap.ai3.features.capital', 'Capital Allocation'),
        t('investor_pitch.product_evolution.roadmap.ai3.features.reinsurance', 'Reinsurance Optimization'),
        t('investor_pitch.product_evolution.roadmap.ai3.features.analytics', 'Advanced Analytics'),
        t('investor_pitch.product_evolution.roadmap.ai3.features.modeling', 'Catastrophe Modeling')
      ],
      gradient: "linear-gradient(135deg, var(--color-orange-310), var(--color-orange-510))"
    },
    {
      version: "AI-4",
      date: t('investor_pitch.product_evolution.roadmap.ai4.date', 'Q4 2025'),
      focus: t('investor_pitch.product_evolution.roadmap.ai4.focus', 'Full Automation'),
      features: [
        t('investor_pitch.product_evolution.roadmap.ai4.features.pension', 'Pension & Life Products'),
        t('investor_pitch.product_evolution.roadmap.ai4.features.upload', 'Document Processing'),
        t('investor_pitch.product_evolution.roadmap.ai4.features.language', 'All Global Languages'),
        t('investor_pitch.product_evolution.roadmap.ai4.features.automation', 'Full Process Automation')
      ],
      gradient: "linear-gradient(135deg, var(--color-purple-200), var(--color-blue-510))"
    }
  ];

  const capabilities = {
    categories: [
      {
        name: t('investor_pitch.product_evolution.capabilities.insurance.name', 'Insurance Products'),
        features: [
          { 
            name: t('investor_pitch.product_evolution.capabilities.insurance.features.general', 'General Liability'),
            ai1: true, ai2: true, ai3: true, ai4: true 
          },
          { 
            name: t('investor_pitch.product_evolution.capabilities.insurance.features.purpose', 'Special Purpose'),
            ai1: true, ai2: true, ai3: true, ai4: true 
          },
          { 
            name: t('investor_pitch.product_evolution.capabilities.insurance.features.build_1', 'Auto Insurance'),
            ai1: true, ai2: true, ai3: true, ai4: true 
          },
          { 
            name: t('investor_pitch.product_evolution.capabilities.insurance.features.build_2', 'Property Insurance'),
            ai1: false, ai2: true, ai3: true, ai4: true 
          },
          { 
            name: t('investor_pitch.product_evolution.capabilities.insurance.features.build_3', 'Commercial Lines'),
            ai1: false, ai2: false, ai3: true, ai4: true 
          },
          { 
            name: t('investor_pitch.product_evolution.capabilities.insurance.features.build_4', 'Life & Pension'),
            ai1: false, ai2: false, ai3: false, ai4: true 
          }
        ]
      },
      {
        name: t('investor_pitch.product_evolution.capabilities.iot.name', 'IoT Integration'),
        features: [
          { 
            name: t('investor_pitch.product_evolution.capabilities.iot.features.telematics', 'Telematics'),
            ai1: true, ai2: true, ai3: true, ai4: true 
          },
          { 
            name: t('investor_pitch.product_evolution.capabilities.iot.features.accident', 'Accident Detection'),
            ai1: false, ai2: true, ai3: true, ai4: true 
          },
          { 
            name: t('investor_pitch.product_evolution.capabilities.iot.features.property', 'Property Sensors'),
            ai1: false, ai2: true, ai3: true, ai4: true 
          },
          { 
            name: t('investor_pitch.product_evolution.capabilities.iot.features.commercial', 'Commercial IoT'),
            ai1: false, ai2: false, ai3: true, ai4: true 
          }
        ]
      }
    ]
  };

  const keyFeatures = [
    {
      title: t('investor_pitch.product_evolution.features.language.title', 'Language Support'),
      milestones: [
        { version: "AI-1", value: t('investor_pitch.product_evolution.features.language.ai1', '6 Languages') },
        { version: "AI-2", value: t('investor_pitch.product_evolution.features.language.ai2', '12 Languages') },
        { version: "AI-3", value: t('investor_pitch.product_evolution.features.language.ai3', '20 Languages') },
        { version: "AI-4", value: t('investor_pitch.product_evolution.features.language.ai4', 'All Languages') }
      ],
      icon: "Globe",
      color: "var(--color-blue-310)"
    },
    {
      title: t('investor_pitch.product_evolution.features.processing.title', 'Processing Power'),
      milestones: [
        { version: "AI-1", value: t('investor_pitch.product_evolution.features.processing.ai1', '1,000 TPS') },
        { version: "AI-2", value: t('investor_pitch.product_evolution.features.processing.ai2', '5,000 TPS') },
        { version: "AI-3", value: t('investor_pitch.product_evolution.features.processing.ai3', '20,000 TPS') },
        { version: "AI-4", value: t('investor_pitch.product_evolution.features.processing.ai4', '100,000 TPS') }
      ],
      icon: "Cpu",
      color: "var(--color-green-310)"
    }
  ];

  return (
    <motion.div
      className={styles.container}
      variants={ANIMATION_VARIANTS.stagger.container}
      initial="hidden"
      animate="visible"
    >
      <motion.div 
        className={styles.header}
        variants={ANIMATION_VARIANTS.fadeIn}
      >
        <h2 className={styles.title}>
          {t('investor_pitch.product_evolution.title', 'Product Evolution')}
        </h2>
        <p className={styles.subtitle}>
          {t('investor_pitch.product_evolution.subtitle', 'Our roadmap from AI-1 to AI-4')}
        </p>
      </motion.div>

      <motion.div 
        className={styles.content}
        variants={ANIMATION_VARIANTS.stagger.container}
      >
        <motion.div 
          className={styles.roadmapSection}
          variants={ANIMATION_VARIANTS.fadeIn}
        >
          {/* <h3 className={styles.sectionTitle}>
            {t('investor_pitch.product_evolution.sections.roadmap', 'Development Roadmap')}
          </h3> */}
          <div className={styles.roadmapGrid}>
            {roadmapStages.map((stage, index) => (
              <motion.div
                key={stage.version}
                variants={ANIMATION_VARIANTS.stagger.item}
                custom={index}
                className={styles.roadmapItem}
              >
                <RoadmapCard {...stage} />
              </motion.div>
            ))}
          </div>
        </motion.div>

        <div className={styles.detailsSection}>
          <motion.div 
            className={styles.capabilitySection}
            variants={ANIMATION_VARIANTS.fadeIn}
          >
            <h3 className={styles.sectionTitle}>
              {t('investor_pitch.product_evolution.sections.capabilities', 'Capability Matrix')}
            </h3>
            <div className={styles.capabilityWrapper}>
              <CapabilityMatrix categories={capabilities.categories} />
            </div>
          </motion.div>

          <motion.div 
            className={styles.featureSection}
            variants={ANIMATION_VARIANTS.fadeIn}
          >
            <h3 className={styles.sectionTitle}>
              {t('investor_pitch.product_evolution.sections.features', 'Key Features Evolution')}
            </h3>
            <div className={styles.featureWrapper}>
              <FeatureTimeline features={keyFeatures} />
            </div>
          </motion.div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default ProductEvolutionSlide;