// File: frontend/src/pages/PropertyDetail/components/PropertyImages/index.js

import React, { useState } from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import styles from './PropertyImages.module.css';

const PropertyImages = ({ property }) => {
  const { t } = useLanguage();
  const [activeImage, setActiveImage] = useState(property.images && property.images.length > 0 ? 0 : null);

  if (!property.images || property.images.length === 0) {
    return (
      <div className={styles.noImages}>
        <p>{t('no_property_images_available')}</p>
        <button className={styles.uploadButton}>
          {t('upload_images')}
        </button>
      </div>
    );
  }

  // Helper function to render the correct media based on type
  const renderMedia = (media) => {
    if (media.property_image_type === 'video') {
      return (
        <video 
          src={media.property_image_url} 
          alt={`Property at ${property.masterAddress?.address_line_1 || 'Unknown Address'}`}
          className={styles.mainMedia}
          controls
        />
      );
    } else {
      return (
        <img 
          src={media.property_image_url} 
          alt={`Property at ${property.masterAddress?.address_line_1 || 'Unknown Address'}`}
          className={styles.mainImage}
        />
      );
    }
  };

  // Helper function to render thumbnail based on type
  const renderThumbnail = (media, index) => {
    if (media.property_image_type === 'video') {
      return (
        <div className={styles.thumbnailVideo}>
          <video 
            src={media.property_image_url} 
            className={styles.thumbnailImage}
          />
          <div className={styles.videoIcon}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="24" height="24">
              <path d="M8 5v14l11-7z" />
            </svg>
          </div>
        </div>
      );
    } else {
      return (
        <img 
          src={media.property_image_url} 
          alt={`Thumbnail ${index + 1}`}
          className={styles.thumbnailImage}
        />
      );
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.mainImageContainer}>
        {activeImage !== null && renderMedia(property.images[activeImage])}
      </div>
      
      <div className={styles.thumbnailsContainer}>
        {property.images.map((media, index) => (
          <div 
            key={media.property_image_id} 
            className={`${styles.thumbnail} ${activeImage === index ? styles.activeThumbnail : ''}`}
            onClick={() => setActiveImage(index)}
          >
            {renderThumbnail(media, index)}
          </div>
        ))}
        
        <button className={styles.uploadButton}>
          {t('add_more_images')}
        </button>
      </div>
      
      {activeImage !== null && property.images[activeImage].property_image_ai_assessment && (
        <div className={styles.imageDetails}>
          <h3 className={styles.imageDetailsTitle}>
            {property.images[activeImage].property_image_type === 'video' 
              ? t('video_assessment') 
              : t('image_assessment')}
          </h3>
          
          <div className={styles.imageDetailsContent}>
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('condition')}:</div>
              <div className={styles.infoValue}>
                {property.images[activeImage].property_image_ai_assessment.condition || 'N/A'}
              </div>
            </div>
            
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('damage_detected')}:</div>
              <div className={styles.infoValue}>
                {property.images[activeImage].property_image_ai_assessment.damage_detected ? t('yes') : t('no')}
              </div>
            </div>
            
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('confidence')}:</div>
              <div className={styles.infoValue}>
                {property.images[activeImage].property_image_ai_assessment.confidence 
                  ? `${(property.images[activeImage].property_image_ai_assessment.confidence * 100).toFixed(0)}%` 
                  : 'N/A'}
              </div>
            </div>
            
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('source')}:</div>
              <div className={styles.infoValue}>
                {property.images[activeImage].property_image_source || 'N/A'}
              </div>
            </div>
            
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('date_taken')}:</div>
              <div className={styles.infoValue}>
                {new Date(property.images[activeImage].property_image_timestamp).toLocaleDateString()}
              </div>
            </div>
            
            <div className={styles.infoGroup}>
              <div className={styles.infoLabel}>{t('type')}:</div>
              <div className={styles.infoValue}>
                {property.images[activeImage].property_image_type === 'video' ? t('video') : t('image')}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PropertyImages;