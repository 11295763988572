// File: frontend/src/pages/Auth/VerificationSuccess.js

import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // Add useLocation
import { useLanguage } from '../../contexts/LanguageContext';
import { useAuth } from '../../contexts/AuthContextV2';
import { AUTH_FLOW_STATES } from '../../contexts/AuthContextV2';
import AuthLayout from '../../components/layout/AuthLayout/AuthLayout';
import StatusScreen from '../../components/Auth/StatusScreen';
import styles from './VerificationSuccess.module.css';

const VerificationSuccess = () => {
  const { t } = useLanguage();
  const navigate = useNavigate();
  const location = useLocation(); // Add this
  const { flowState, updateFlowState } = useAuth(); // Add updateFlowState
  const [countdown, setCountdown] = useState(5);

  // Check both flow state and navigation state
  useEffect(() => {
    // If we didn't come from verification process and aren't in the right flow state
    if (!location.state?.fromVerification && 
        flowState !== AUTH_FLOW_STATES.SIGNUP.ACCOUNT_CREATION) {
      console.log('Invalid verification success access, redirecting to sign-in');
      navigate('/sign-in', { replace: true });
      return;
    }

    // Ensure we're in the correct flow state
    if (flowState !== AUTH_FLOW_STATES.SIGNUP.ACCOUNT_CREATION) {
      updateFlowState(AUTH_FLOW_STATES.SIGNUP.ACCOUNT_CREATION, {
        email_verified: true,
        email: location.state?.email,
        verification_timestamp: new Date().toISOString()
      });
    }
  }, [flowState, location.state, navigate, updateFlowState]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown(prev => {
        const newCount = Math.max(0, prev - 1);
        return newCount;
      });
    }, 1000);

    const redirect = setTimeout(() => {
      handleContinue();
    }, 5000);

    return () => {
      clearInterval(timer);
      clearTimeout(redirect);
    };
  }, []);

  const handleContinue = () => {
    navigate('/account-creation', { 
      replace: true,
      state: {
        fromVerification: true,
        email: location.state?.email,
        verificationTimestamp: new Date().toISOString()
      }
    });
  };

  const additionalInfo = (
    <div className={styles.successInfo}>
      <div className={styles.verificationDetails}>
        <div className={styles.infoItem}>
          <span className={styles.infoLabel}>
            {t('auth.verificationSuccess.verifiedAt')}
          </span>
          <span className={styles.infoValue}>
            {new Date().toLocaleString()}
          </span>
        </div>
        {location.state?.email && (
          <div className={styles.infoItem}>
            <span className={styles.infoLabel}>
              {t('auth.verificationSuccess.verifiedEmail')}
            </span>
            <span className={styles.infoValue}>
              {location.state.email}
            </span>
          </div>
        )}
      </div>

      <div className={styles.countdownWrapper}>
        <div className={styles.countdown}>
          <div 
            className={styles.countdownBar} 
            style={{ width: `${(countdown / 5) * 100}%` }} 
          />
        </div>
        <p className={styles.countdownText}>
          {t('auth.verificationSuccess.redirecting', { seconds: countdown })}
        </p>
      </div>

      {/* Since we're auto directing, there is no need for this one. */}
      {/* <button 
        className={styles.continueButton}
        onClick={handleContinue}
      >
        {t('auth.verificationSuccess.continue')}
      </button> */}
    </div>
  );

  return (
    <AuthLayout
      title={t('auth.signUp.title')}
      showHomeButton={true}
    >
      <div className={styles.verificationContainer}>
        <div className={styles.verificationContent}>
          <StatusScreen
            icon="/icons/18/alerts/success.svg"
            title={t('auth.verificationSuccess.title')}
            message={t('auth.verificationSuccess.message')}
            type="success"
            additionalInfo={additionalInfo}
          />
        </div>
      </div>
    </AuthLayout>
  );
};

export default React.memo(VerificationSuccess);