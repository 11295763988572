// File: frontend/src/pages/QMS/index.js

import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import QuoteSearchForm from './components/QuoteSearchForm';
import QuoteStatistics from './components/QuoteStatistics';
import QuoteSearchResults from './components/QuoteSearchResults';
import quoteService from '../../services/quote';
import styles from './QMS.module.css';

const QMS = () => {
  const { t } = useLanguage();
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const [lastSearchParams, setLastSearchParams] = useState(null);

  const handleSearch = async (searchParams) => {
    setLoading(true);
    setError(null);
    
    try {
      // Add pagination parameters
      const params = {
        ...searchParams,
        page: 1,
        limit: 10
      };
      
      setLastSearchParams(params);
      setCurrentPage(1);
      
      // Convert the search parameters to the format expected by the API
      const queryParams = {};
      
      // Map the field to the appropriate query parameter
      if (searchParams.field && searchParams.query) {
        switch (searchParams.field) {
          case 'quote_id':
            queryParams.quote_id = searchParams.query;
            break;
          case 'qnr':
            queryParams.qnr = searchParams.query;
            break;
          case 'user_id':
            queryParams.user_id = searchParams.query;
            break;
          case 'first_name':
            queryParams.first_name = searchParams.query;
            break;
          case 'last_name':
            queryParams.last_name = searchParams.query;
            break;
          case 'email_address':
            queryParams.email_address = searchParams.query;
            break;
          case 'mobile_phone_number':
            queryParams.mobile_phone_number = searchParams.query;
            break;
          default:
            queryParams[searchParams.field] = searchParams.query;
        }
      }
      
      // Add date range if provided
      if (searchParams.date_range) {
        queryParams.date_range = searchParams.date_range;
      }
      
      // Add product type if provided
      if (searchParams.product_type) {
        queryParams.product_type = searchParams.product_type;
      }
      
      // Add quote status if provided
      if (searchParams.quote_status) {
        queryParams.quote_status = searchParams.quote_status;
      }
      
      // Add pagination
      queryParams.page = params.page;
      queryParams.limit = params.limit;
      
      // Call the API
      const response = await quoteService.searchQuote(queryParams);
      
      if (response && response.quotes) {
        setSearchResults(response.quotes);
        setTotalResults(response.total || response.quotes.length);
        setTotalPages(Math.ceil((response.total || response.quotes.length) / 10));
      } else {
        setSearchResults([]);
        setTotalResults(0);
        setTotalPages(1);
        setError(t('no_quotes_found'));
      }
    } catch (err) {
      console.error('Error searching quotes:', err);
      setError(t('failed_to_search_quotes'));
      setSearchResults([]);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = async (page) => {
    if (page === currentPage || !lastSearchParams) return;
    
    setLoading(true);
    setError(null);
    
    try {
      const params = {
        ...lastSearchParams,
        page,
        limit: 10
      };
      
      // Convert the search parameters to the format expected by the API
      const queryParams = {};
      
      // Map the field to the appropriate query parameter
      if (lastSearchParams.field && lastSearchParams.query) {
        switch (lastSearchParams.field) {
          case 'quote_id':
            queryParams.quote_id = lastSearchParams.query;
            break;
          case 'qnr':
            queryParams.qnr = lastSearchParams.query;
            break;
          case 'user_id':
            queryParams.user_id = lastSearchParams.query;
            break;
          case 'first_name':
            queryParams.first_name = lastSearchParams.query;
            break;
          case 'last_name':
            queryParams.last_name = lastSearchParams.query;
            break;
          case 'email_address':
            queryParams.email_address = lastSearchParams.query;
            break;
          case 'mobile_phone_number':
            queryParams.mobile_phone_number = lastSearchParams.query;
            break;
          default:
            queryParams[lastSearchParams.field] = lastSearchParams.query;
        }
      }
      
      // Add date range if provided
      if (lastSearchParams.date_range) {
        queryParams.date_range = lastSearchParams.date_range;
      }
      
      // Add product type if provided
      if (lastSearchParams.product_type) {
        queryParams.product_type = lastSearchParams.product_type;
      }
      
      // Add quote status if provided
      if (lastSearchParams.quote_status) {
        queryParams.quote_status = lastSearchParams.quote_status;
      }
      
      // Add pagination
      queryParams.page = page;
      queryParams.limit = 10;
      
      // Call the API
      const response = await quoteService.searchQuote(queryParams);
      
      if (response && response.quotes) {
        setSearchResults(response.quotes);
        setCurrentPage(page);
      } else {
        setError(t('error_loading_page'));
      }
    } catch (err) {
      console.error('Error changing page:', err);
      setError(t('error_loading_page'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{t('qms')}</h1>
      
      <QuoteStatistics />
      
      <QuoteSearchForm onSearch={handleSearch} loading={loading} />
      
      {error && <div className={styles.error}>{error}</div>}
      
      <QuoteSearchResults 
        results={searchResults} 
        loading={loading}
        onPageChange={handlePageChange}
        currentPage={currentPage}
        totalPages={totalPages}
        totalResults={totalResults}
      />
    </div>
  );
};

export default QMS;