// File: frontend/src/pages/AI1/components/AgentSelector/AgentSelector.js

import React, { useState, useMemo } from 'react';
import { useRoleBasedAccess } from '../../../../contexts/RoleBasedAccessContext';
import styles from './AgentSelector.module.css';
import { defaultAgents } from './defaultAgents';


// PLEASE NOTE THAT YOU MUST UPDATE YOUR APIS AND THIS PART BELOW TO MAKE SURE IT WORKS
// CURRENTLY, I WILL MANAGE THIS PART MANUALLY
const AgentSelector = ({ 
  onSelect, 
  onClose, 
  selectedAgent,
  userSubscription = { isPremium: true , isEnterprise: true }
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const { hasRole, userRole } = useRoleBasedAccess();

  const canAccessAgent = (agent) => {
    const hasRequiredRole = hasRole(agent.minRole);
    const hasPremiumAccess = !agent.isPremium || userSubscription.isPremium;
    const hasEnterpriseAccess = !agent.isEnterprise || userSubscription.isEnterprise;
    return hasRequiredRole && hasPremiumAccess && hasEnterpriseAccess;
  };

  const getSubscriptionMessage = (agent) => {
    if (!agent.isPremium && !agent.isEnterprise) return null;
    
    if (agent.isPremium && agent.isEnterprise) {
      return "Requires Premium and Enterprise subscription";
    }
    if (agent.isPremium) {
      return "Requires Premium subscription";
    }
    if (agent.isEnterprise) {
      return "Requires Enterprise subscription";
    }
    return null;
  };

  const renderAgentTags = (agent) => {
    if (!agent.isPremium && !agent.isEnterprise) return null;
    
    return (
      <div className={styles.agentTags}>
        {agent.isPremium && (
          <span className={styles.tagIcon} title="Premium">
            <img src="/icons/18/misc/premium.svg" alt="Premium" />
          </span>
        )}
        {agent.isEnterprise && (
          <span className={styles.tagIcon} title="Enterprise">
            <img src="/icons/18/misc/enterprise.svg" alt="Enterprise" />
          </span>
        )}
      </div>
    );
  };

  // First, let's make sure these agents are only shown to the appropriate roles:
  const groupedAndFilteredAgents = useMemo(() => {
    const filtered = defaultAgents.filter(agent => {
      // First check role-based visibility
      if (!hasRole(agent.minRole)) {
        return false;
      }
      
      // Then check search term if role check passes
      return (
        agent.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        agent.description.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });

    return filtered.reduce((acc, agent) => {
      const category = agent.key.split('_')[1];
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(agent);
      return acc;
    }, {});
  }, [searchTerm, hasRole]);

  const renderAgentButton = (agent) => {
    const isAccessible = canAccessAgent(agent);
    const buttonClasses = `${styles.agentButton} 
      ${selectedAgent?.key === agent.key ? styles.selected : ''} 
      ${!isAccessible ? styles.disabled : ''}`;
    const subscriptionMessage = getSubscriptionMessage(agent);

    return (
      <button
        key={agent.key}
        className={buttonClasses}
        onClick={() => {
          if (isAccessible) {
            onSelect(agent);
            onClose();
          }
        }}
        disabled={!isAccessible}
      >
        <div className={styles.agentInfo}>
          <div className={styles.agentHeader}>
            <span className={styles.agentName}>{agent.name}</span>
            {renderAgentTags(agent)}
          </div>
          <span className={styles.agentDescription}>
            {agent.description}
          </span>
          {!isAccessible && subscriptionMessage && (
            <div className={styles.accessRequirement}>
              {subscriptionMessage}
            </div>
          )}
        </div>
      </button>
    );
  };

  // Rest of the component remains the same
  return (
    <div className={styles.overlay} onClick={onClose}>
      <div className={styles.modal} onClick={e => e.stopPropagation()}>
        <div className={styles.header}>
          <h3>Select Agent</h3>
          <button className={styles.closeButton} onClick={onClose}>
            <img src="/icons/18/misc/close.svg" alt="Close" />
          </button>
        </div>
        
        <div className={styles.searchContainer}>
          <input
            type="text"
            placeholder="Search agents..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className={styles.searchInput}
          />
        </div>

        <div className={styles.content}>
          {Object.entries(groupedAndFilteredAgents).map(([category, agents]) => (
            <div key={category} className={styles.categoryGroup}>
              <h4 className={styles.categoryTitle}>
                {category.split('_').map(word => 
                  word.charAt(0).toUpperCase() + word.slice(1)
                ).join(' ')}
              </h4>
              
              {agents.map(agent => renderAgentButton(agent))}
            </div>
          ))}
          
          {Object.keys(groupedAndFilteredAgents).length === 0 && (
            <div className={styles.noResults}>
              No agents found matching "{searchTerm}"
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AgentSelector;