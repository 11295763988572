// File: frontend/src/contexts/UserContext.js

import React, { createContext, useContext, useState, useEffect } from 'react';
import { userService } from '../services/userService';
import { tokenService } from '../services/tokenService';
import { useAuth } from './AuthContextV2';
import { TokenStatus } from '../services/tokenService';

const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
    const { isAuthenticated } = useAuth(); // Get auth state
    const [user, setUser] = useState(() => userService.getUser());
    const [isLoading, setIsLoading] = useState(true);

    // React to auth state changes
    useEffect(() => {
        console.log('UserContext: Auth state changed', { 
            user,
            isAuthenticated 
        });

        if (!isAuthenticated && !tokenService.hasValidTokens()) {
            console.log('UserContext: Clearing user state');
            setUser(null);
            userService.clearUser();
        } else {
            // If authenticated, rely on the transformed user_data from localStorage
            const storedUser = userService.getUser();
            if (storedUser) {
                console.log('UserContext: Using stored user data');
                setUser(storedUser);
            }
        }
        
        setIsLoading(false);
    }, [isAuthenticated]);

    const updateUser = (updates) => {
        console.log('UserContext: Updating user', updates);
        const updatedUser = userService.updateUser(updates);
        setUser(updatedUser);
        return updatedUser;
    };

    const clearUser = () => {
        console.log('UserContext: Clearing user');
        userService.clearUser();
        setUser(null);
    };

    return (
        <UserContext.Provider 
            value={{ 
                user,
                setUser,
                updateUser,
                isLoading,
                clearUser,
                isAuthenticated
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context;
};