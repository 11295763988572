// File: frontend/src/pages/InvestorPitch/components/slides/GlobalHiringSlide/LocationCard.js

import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { MapPin, Calendar, Users } from 'lucide-react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import ProgressBar from '../../common/ProgressBar';
import styles from './LocationCard.module.css';

const LocationCard = ({ city, country, timeline, headcount, roles, gradient }) => {
  const { t } = useLanguage();

  const formatHeadcount = (count) => {
    return `${t('investor_pitch.location.headcount.target')}: ${count}`;
  };

  return (
    <motion.div 
      className={styles.container}
      whileHover={{ scale: 1.02 }}
      transition={{ type: 'spring', stiffness: 300, damping: 30 }}
    >
      <div className={styles.header}>
        <div 
          className={styles.iconWrapper}
          style={{ background: gradient }}
        >
          <MapPin className={styles.icon} />
        </div>
        <div className={styles.location}>
          <h3 className={styles.city}>{city}</h3>
          <p className={styles.country}>{country}</p>
        </div>
      </div>

      <div className={styles.timeline}>
        <Calendar className={styles.timelineIcon} />
        <span>{timeline}</span>
      </div>

      <div className={styles.headcount}>
        <div className={styles.headcountHeader}>
          <Users className={styles.headcountIcon} />
          <span>{formatHeadcount(headcount)}</span>
        </div>
        <ProgressBar 
          value={50} 
          max={100} 
          gradient={gradient}
        />
      </div>

      <div className={styles.roles}>
        {roles.map((role, index) => (
          <div key={index} className={styles.role}>
            {role}
          </div>
        ))}
      </div>
    </motion.div>
  );
};

LocationCard.propTypes = {
  city: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  timeline: PropTypes.string.isRequired,
  headcount: PropTypes.number.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  gradient: PropTypes.string.isRequired,
};

export default LocationCard;