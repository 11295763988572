// File: frontend/src/pages/UMS/UserPortfolio/components/PortfolioMaps/index.js

import React, { useState, useEffect, useRef } from 'react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import GeographyProvinces from './GeographyProvinces';
import GeographyPostalCodes from './GeographyPostalCodes';
import GeographyCoordinates from './GeographyCoordinates';
import styles from './PortfolioMaps.module.css';

const PortfolioMaps = () => {
  const { t } = useLanguage();
  const [activeMap, setActiveMap] = useState('provinces');
  const [mapDimensions, setMapDimensions] = useState({ width: 0, height: 500 });
  const mapContainerRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (mapContainerRef.current) {
        const { width } = mapContainerRef.current.getBoundingClientRect();
        setMapDimensions({ width, height: 500 });
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const renderMap = () => {
    const props = {
      width: mapDimensions.width,
      height: mapDimensions.height,
    };

    switch (activeMap) {
      case 'provinces':
        return <GeographyProvinces {...props} />;
      case 'postalCodes':
        return <GeographyPostalCodes {...props} />;
      case 'coordinates':
        return <GeographyCoordinates {...props} />;
      default:
        return null;
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.mapOptions}>
        <div 
          className={`${styles.mapOption} ${activeMap === 'provinces' ? styles.active : ''}`}
          onClick={() => setActiveMap('provinces')}
        >
          <div className={styles.mapOptionIcon}>
            <i className="fas fa-map"></i>
          </div>
          <div className={styles.mapOptionContent}>
            <h3 className={styles.mapOptionTitle}>{t('provinces_states')}</h3>
            <p className={styles.mapOptionDescription}>{t('provinces_description')}</p>
          </div>
        </div>
        
        <div 
          className={`${styles.mapOption} ${activeMap === 'postalCodes' ? styles.active : ''}`}
          onClick={() => setActiveMap('postalCodes')}
        >
          <div className={styles.mapOptionIcon}>
            <i className="fas fa-mail-bulk"></i>
          </div>
          <div className={styles.mapOptionContent}>
            <h3 className={styles.mapOptionTitle}>{t('postal_codes')}</h3>
            <p className={styles.mapOptionDescription}>{t('postal_codes_description')}</p>
          </div>
        </div>
        
        <div 
          className={`${styles.mapOption} ${activeMap === 'coordinates' ? styles.active : ''}`}
          onClick={() => setActiveMap('coordinates')}
        >
          <div className={styles.mapOptionIcon}>
            <i className="fas fa-map-marker-alt"></i>
          </div>
          <div className={styles.mapOptionContent}>
            <h3 className={styles.mapOptionTitle}>{t('coordinates')}</h3>
            <p className={styles.mapOptionDescription}>{t('coordinates_description')}</p>
          </div>
        </div>
      </div>
      
      <div className={styles.mapContainer} ref={mapContainerRef}>
        {renderMap()}
      </div>
    </div>
  );
};

export default PortfolioMaps;