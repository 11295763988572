// File: frontend/src/pages/EmbeddedInsurance/components/common/Header.js

import React from 'react';
import styles from './styles/Header.module.css';

const Header = ({ title, description }) => {
  return (
    <div className={styles.header}>
      <div className={styles.titleContainer}>
        <h1 className={styles.title}>{title}</h1>
        <span className={styles.badge}>Beta</span>
      </div>
      <p className={styles.description}>{description}</p>
    </div>
  );
};

export default Header;