// File: frontend/src/components/Divider/Divider.js

import React from 'react';
import PropTypes from 'prop-types';
import { useLanguage } from '../../contexts/LanguageContext';
import styles from './Divider.module.css';

const Divider = ({ vertical, childrenKey }) => {
  const { t } = useLanguage();
  const dividerClass = `${styles.divider} ${vertical ? styles['divider--vertical'] : ''}`;

  return (
    <div className={dividerClass}>
      <div className={styles.divider__line} />
      {childrenKey && <div className={styles.divider__content}>{t(childrenKey)}</div>}
      <div className={styles.divider__line} />
    </div>
  );
};

Divider.propTypes = {
  vertical: PropTypes.bool,
  childrenKey: PropTypes.string,
};

export default Divider;