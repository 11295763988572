// File: frontend/src/pages/InvestorPitch/components/slides/SecuritySlide/DataProtection.js

import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import * as LucideIcons from 'lucide-react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from './DataProtection.module.css';

const DataProtection = ({ title, features }) => {
  const { t } = useLanguage();

  return (
    <div className={styles.container}>
      <div className={styles.features}>
        {features.map((feature, index) => {
          const Icon = LucideIcons[feature.icon];
          return (
            <motion.div 
              key={feature.title}
              className={styles.feature}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              aria-label={t('investor_pitch.security.data_protection.feature.aria_label', {
                title: feature.title
              }, `Data protection feature: ${feature.title}`)}
            >
              <div 
                className={styles.iconWrapper}
                style={{ background: feature.gradient }}
              >
                <Icon className={styles.icon} aria-hidden="true" />
              </div>
              <div className={styles.content}>
                <h4 className={styles.title}>{feature.title}</h4>
                <p className={styles.description}>{feature.description}</p>
              </div>
            </motion.div>
          );
        })}
      </div>
    </div>
  );
};

DataProtection.propTypes = {
  title: PropTypes.string.isRequired,
  features: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      gradient: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default DataProtection;