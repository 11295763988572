// File: frontend/src/pages/PropertyDetail/components/PropertyOccupancy/index.js

import React from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import styles from './PropertyOccupancy.module.css';

// Helper function to format date
const formatDate = (dateString) => {
  if (!dateString) return 'N/A';
  
  const date = new Date(dateString);
  
  // Check if date is valid
  if (isNaN(date.getTime())) return 'Invalid Date';
  
  return date.toLocaleDateString();
};

// Helper function to format currency
const formatCurrency = (amount, currency = 'USD') => {
  if (amount === null || amount === undefined) return 'N/A';
  
  return new Intl.NumberFormat('en-US', { 
    style: 'currency', 
    currency: currency 
  }).format(amount);
};

const PropertyOccupancy = ({ property }) => {
  const { t } = useLanguage();

  return (
    <div className={styles.container}>
      <div className={styles.grid}>
        <div className={styles.card}>
          <h3 className={styles.cardTitle}>{t('occupancy_information')}</h3>
          <div className={styles.cardContent}>
            {property.occupancyAndBusiness ? (
              <div className={styles.occupancyInfo}>
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('occupancy_type')}:</div>
                  <div className={styles.infoValue}>{property.occupancyAndBusiness.occupancy_type}</div>
                </div>
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('owner_occupied')}:</div>
                  <div className={styles.infoValue}>
                    {property.occupancyAndBusiness.is_owner_occupied ? t('yes') : t('no')}
                  </div>
                </div>
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('rental_situation')}:</div>
                  <div className={styles.infoValue}>{property.occupancyAndBusiness.rental_situation}</div>
                </div>
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('multi_unit')}:</div>
                  <div className={styles.infoValue}>
                    {property.occupancyAndBusiness.is_multi_unit ? t('yes') : t('no')}
                  </div>
                </div>
                {property.occupancyAndBusiness.is_multi_unit && (
                  <div className={styles.infoGroup}>
                    <div className={styles.infoLabel}>{t('number_of_units')}:</div>
                    <div className={styles.infoValue}>{property.occupancyAndBusiness.number_of_units}</div>
                  </div>
                )}
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('in_law_apartment')}:</div>
                  <div className={styles.infoValue}>
                    {property.occupancyAndBusiness.has_in_law_apartment ? t('yes') : t('no')}
                  </div>
                </div>
                {property.occupancyAndBusiness.number_of_rented_units > 0 && (
                  <div className={styles.infoGroup}>
                    <div className={styles.infoLabel}>{t('number_of_rented_units')}:</div>
                    <div className={styles.infoValue}>{property.occupancyAndBusiness.number_of_rented_units}</div>
                  </div>
                )}
                {property.occupancyAndBusiness.number_of_rented_rooms > 0 && (
                  <div className={styles.infoGroup}>
                    <div className={styles.infoLabel}>{t('number_of_rented_rooms')}:</div>
                    <div className={styles.infoValue}>{property.occupancyAndBusiness.number_of_rented_rooms}</div>
                  </div>
                )}
              </div>
            ) : (
              <div className={styles.noData}>{t('no_occupancy_information_available')}</div>
            )}
          </div>
        </div>
        
        <div className={styles.card}>
          <h3 className={styles.cardTitle}>{t('unit_information')}</h3>
          <div className={styles.cardContent}>
            {property.unit ? (
              <div className={styles.unitInfo}>
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('unit_type')}:</div>
                  <div className={styles.infoValue}>{property.unit.unit_type}</div>
                </div>
                {property.unit.unit_number && (
                  <div className={styles.infoGroup}>
                    <div className={styles.infoLabel}>{t('unit_number')}:</div>
                    <div className={styles.infoValue}>{property.unit.unit_number}</div>
                  </div>
                )}
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('floor')}:</div>
                  <div className={styles.infoValue}>{property.unit.floor}</div>
                </div>
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('living_area')}:</div>
                  <div className={styles.infoValue}>
                    {property.unit.living_area} {property.unit.area_measure}
                  </div>
                </div>
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('bedrooms')}:</div>
                  <div className={styles.infoValue}>{property.unit.number_of_bedrooms}</div>
                </div>
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('full_bathrooms')}:</div>
                  <div className={styles.infoValue}>{property.unit.number_of_full_bathrooms}</div>
                </div>
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('half_bathrooms')}:</div>
                  <div className={styles.infoValue}>{property.unit.number_of_half_bathrooms}</div>
                </div>
                <div className={styles.infoGroup}>
                  <div className={styles.infoLabel}>{t('kitchens')}:</div>
                  <div className={styles.infoValue}>{property.unit.number_of_kitchens}</div>
                </div>
              </div>
            ) : (
              <div className={styles.noData}>{t('no_unit_information_available')}</div>
            )}
          </div>
        </div>
      </div>
      
      {property.occupants && property.occupants.length > 0 && (
        <div className={styles.card}>
          <h3 className={styles.cardTitle}>{t('property_occupants')}</h3>
          <div className={styles.cardContent}>
            <div className={styles.occupantsGrid}>
              {property.occupants.map((occupant, index) => (
                <div key={occupant.occupant_id || index} className={styles.occupantItem}>
                  <div className={styles.occupantHeader}>
                    <div className={styles.occupantName}>
                      {occupant.first_name} {occupant.last_name}
                    </div>
                    {occupant.is_primary_resident && (
                      <div className={styles.primaryBadge}>{t('primary')}</div>
                    )}
                  </div>
                  
                  <div className={styles.occupantDetails}>
                    <div className={styles.occupantDetail}>
                      <span className={styles.occupantDetailLabel}>{t('date_of_birth')}:</span>
                      <span className={styles.occupantDetailValue}>
                        {formatDate(occupant.date_of_birth)}
                      </span>
                    </div>
                    <div className={styles.occupantDetail}>
                      <span className={styles.occupantDetailLabel}>{t('gender')}:</span>
                      <span className={styles.occupantDetailValue}>{occupant.gender}</span>
                    </div>
                    <div className={styles.occupantDetail}>
                      <span className={styles.occupantDetailLabel}>{t('marital_status')}:</span>
                      <span className={styles.occupantDetailValue}>{occupant.marital_status}</span>
                    </div>
                    <div className={styles.occupantDetail}>
                      <span className={styles.occupantDetailLabel}>{t('smoker')}:</span>
                      <span className={styles.occupantDetailValue}>
                        {occupant.is_smoker ? t('yes') : t('no')}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            
            <div className={styles.addOccupantContainer}>
              <button className={styles.addOccupantButton}>
                {t('add_occupant')}
              </button>
            </div>
          </div>
        </div>
      )}
      
      {property.occupancyAndBusiness && property.occupancyAndBusiness.business_info_type && (
        <div className={styles.card}>
          <h3 className={styles.cardTitle}>{t('business_information')}</h3>
          <div className={styles.cardContent}>
            <div className={styles.businessInfo}>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('business_info_type')}:</div>
                <div className={styles.infoValue}>{property.occupancyAndBusiness.business_info_type}</div>
              </div>
              <div className={styles.infoGroup}>
                <div className={styles.infoLabel}>{t('number_of_units')}:</div>
                <div className={styles.infoValue}>{property.occupancyAndBusiness.number_of_units}</div>
              </div>
              {/* Additional business info would be displayed here */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PropertyOccupancy;