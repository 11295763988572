// File: frontend/src/pages/SuperAdmin/components/SystemSettings/index.js

import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import styles from './SystemSettings.module.css';

const SystemSettings = ({ updateBreadcrumbs }) => {
  const { t, changeLanguage, currentLanguage } = useLanguage();
  const [activeTab, setActiveTab] = useState('language');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  
  // Language Management
  const [languageStrings, setLanguageStrings] = useState([]);
  const [filteredLanguageStrings, setFilteredLanguageStrings] = useState([]);
  const [languageSearchTerm, setLanguageSearchTerm] = useState('');
  const [selectedLanguageString, setSelectedLanguageString] = useState(null);
  const [editingLanguageString, setEditingLanguageString] = useState(false);
  const [newLanguageString, setNewLanguageString] = useState({
    string_id: '',
    pages: [],
    en_ca: '',
    fr_ca: '',
    es_es: ''
  });
  
  // Category Management
  const [categoryTypes, setCategoryTypes] = useState([]);
  const [selectedCategoryType, setSelectedCategoryType] = useState(null);
  const [categoryValues, setCategoryValues] = useState([]);
  const [editingCategoryType, setEditingCategoryType] = useState(false);
  const [editingCategoryValue, setEditingCategoryValue] = useState(false);
  const [newCategoryType, setNewCategoryType] = useState({
    category_name: '',
    description: ''
  });
  const [newCategoryValue, setNewCategoryValue] = useState({
    value_key: '',
    is_active: 'true',
    description: ''
  });
  
  // Geography Management
  const [geoData, setGeoData] = useState([]);
  const [filteredGeoData, setFilteredGeoData] = useState([]);
  const [geoSearchTerm, setGeoSearchTerm] = useState('');
  const [geoTypes, setGeoTypes] = useState(['country', 'state', 'county', 'city']);
  const [selectedGeoType, setSelectedGeoType] = useState('all');
  const [selectedGeo, setSelectedGeo] = useState(null);
  const [editingGeo, setEditingGeo] = useState(false);
  const [newGeo, setNewGeo] = useState({
    geo_type: 'country',
    geo_name: '',
    geo_name_fr: '',
    geo_code: '',
    parent_geo_id: '',
    properties: {}
  });
  const [geoFile, setGeoFile] = useState(null);

  // Update breadcrumbs when component mounts
  useEffect(() => {
    updateBreadcrumbs([
      { label: 'Dashboard', path: '/super-admin' },
      { label: 'System Settings', path: '/super-admin/system-settings' }
    ]);
  }, [updateBreadcrumbs]);

  // Fetch language strings
  useEffect(() => {
    const fetchLanguageStrings = async () => {
      setLoading(true);
      setError(null);
      
      try {
        // This would be replaced with an actual API call
        // const response = await masterService.getLanguageStrings();
        
        // Mock data for now
        await new Promise(resolve => setTimeout(resolve, 800));
        
        const mockLanguageStrings = [
          {
            language_id: 'lang-12345',
            string_id: 'welcome_message',
            pages: ['home', 'login'],
            en_ca: 'Welcome to our platform',
            fr_ca: 'Bienvenue sur notre plateforme',
            es_es: 'Bienvenido a nuestra plataforma',
            created_at: '2023-01-15T10:30:00Z',
            updated_at: '2023-06-20T14:45:00Z'
          },
          {
            language_id: 'lang-23456',
            string_id: 'login_button',
            pages: ['login'],
            en_ca: 'Log In',
            fr_ca: 'Connexion',
            es_es: 'Iniciar sesión',
            created_at: '2023-01-15T10:35:00Z',
            updated_at: '2023-06-20T14:50:00Z'
          },
          {
            language_id: 'lang-34567',
            string_id: 'signup_button',
            pages: ['login', 'signup'],
            en_ca: 'Sign Up',
            fr_ca: 'S\'inscrire',
            es_es: 'Registrarse',
            created_at: '2023-01-15T10:40:00Z',
            updated_at: '2023-06-20T14:55:00Z'
          },
          {
            language_id: 'lang-45678',
            string_id: 'dashboard_title',
            pages: ['dashboard'],
            en_ca: 'Dashboard',
            fr_ca: 'Tableau de bord',
            es_es: 'Panel de control',
            created_at: '2023-01-15T10:45:00Z',
            updated_at: '2023-06-20T15:00:00Z'
          },
          {
            language_id: 'lang-56789',
            string_id: 'settings_title',
            pages: ['settings'],
            en_ca: 'Settings',
            fr_ca: 'Paramètres',
            es_es: 'Configuración',
            created_at: '2023-01-15T10:50:00Z',
            updated_at: '2023-06-20T15:05:00Z'
          }
        ];
        
        setLanguageStrings(mockLanguageStrings);
        setFilteredLanguageStrings(mockLanguageStrings);
      } catch (err) {
        console.error('Error fetching language strings:', err);
        setError(t('failed_to_load_language_strings'));
      } finally {
        setLoading(false);
      }
    };
    
    if (activeTab === 'language') {
      fetchLanguageStrings();
    }
  }, [activeTab, t]);

  // Fetch category types and values
  useEffect(() => {
    const fetchCategoryData = async () => {
      setLoading(true);
      setError(null);
      
      try {
        // This would be replaced with actual API calls
        // const typesResponse = await masterService.getCategoryTypes();
        // const valuesResponse = selectedCategoryType 
        //   ? await masterService.getCategoryValues(selectedCategoryType.category_type_id)
        //   : { data: [] };
        
        // Mock data for now
        await new Promise(resolve => setTimeout(resolve, 800));
        
        const mockCategoryTypes = [
          {
            category_type_id: 1,
            category_name: 'User Roles',
            description: 'Different roles users can have in the system',
            created_at: '2023-01-10T09:30:00Z',
            updated_at: '2023-06-15T14:45:00Z'
          },
          {
            category_type_id: 2,
            category_name: 'Subscription Tiers',
            description: 'Available subscription tiers for enterprises',
            created_at: '2023-01-10T09:35:00Z',
            updated_at: '2023-06-15T14:50:00Z'
          },
          {
            category_type_id: 3,
            category_name: 'Agreement Types',
            description: 'Types of agreements that can be created',
            created_at: '2023-01-10T09:40:00Z',
            updated_at: '2023-06-15T14:55:00Z'
          }
        ];
        
        setCategoryTypes(mockCategoryTypes);
        
        if (selectedCategoryType) {
          const mockCategoryValues = [
            {
              category_value_id: 101,
              category_type_id: selectedCategoryType.category_type_id,
              value_key: 'admin',
              is_active: 'true',
              description: 'Administrator role with full access',
              created_at: '2023-01-12T10:30:00Z',
              updated_at: '2023-06-18T15:45:00Z'
            },
            {
              category_value_id: 102,
              category_type_id: selectedCategoryType.category_type_id,
              value_key: 'manager',
              is_active: 'true',
              description: 'Manager role with limited administrative access',
              created_at: '2023-01-12T10:35:00Z',
              updated_at: '2023-06-18T15:50:00Z'
            },
            {
              category_value_id: 103,
              category_type_id: selectedCategoryType.category_type_id,
              value_key: 'user',
              is_active: 'true',
              description: 'Standard user role',
              created_at: '2023-01-12T10:40:00Z',
              updated_at: '2023-06-18T15:55:00Z'
            }
          ];
          
          setCategoryValues(mockCategoryValues);
        } else {
          setCategoryValues([]);
        }
      } catch (err) {
        console.error('Error fetching category data:', err);
        setError(t('failed_to_load_category_data'));
      } finally {
        setLoading(false);
      }
    };
    
    if (activeTab === 'categories') {
      fetchCategoryData();
    }
  }, [activeTab, selectedCategoryType, t]);

  // Fetch geography data
  useEffect(() => {
    const fetchGeoData = async () => {
      setLoading(true);
      setError(null);
      
      try {
        // This would be replaced with an actual API call
        // const response = await masterService.getGeographyData();
        
        // Mock data for now
        await new Promise(resolve => setTimeout(resolve, 800));
        
        const mockGeoData = [
          {
            geo_id: 'geo-12345',
            geo_type: 'country',
            geo_name: 'Canada',
            geo_name_fr: 'Canada',
            geo_code: 'CA',
            parent_geo_id: null,
            properties: {
              population: 38000000,
              area: 9984670,
              capital: 'Ottawa'
            },
            created_at: '2023-01-05T09:30:00Z',
            updated_at: '2023-06-10T14:45:00Z'
          },
          {
            geo_id: 'geo-23456',
            geo_type: 'country',
            geo_name: 'United States',
            geo_name_fr: 'États-Unis',
            geo_code: 'US',
            parent_geo_id: null,
            properties: {
              population: 331000000,
              area: 9833520,
              capital: 'Washington, D.C.'
            },
            created_at: '2023-01-05T09:35:00Z',
            updated_at: '2023-06-10T14:50:00Z'
          },
          {
            geo_id: 'geo-34567',
            geo_type: 'state',
            geo_name: 'California',
            geo_name_fr: 'Californie',
            geo_code: 'CA',
            parent_geo_id: 'geo-23456',
            properties: {
              population: 39500000,
              area: 423970,
              capital: 'Sacramento'
            },
            created_at: '2023-01-05T09:40:00Z',
            updated_at: '2023-06-10T14:55:00Z'
          },
          {
            geo_id: 'geo-45678',
            geo_type: 'state',
            geo_name: 'Ontario',
            geo_name_fr: 'Ontario',
            geo_code: 'ON',
            parent_geo_id: 'geo-12345',
            properties: {
              population: 14500000,
              area: 1076395,
              capital: 'Toronto'
            },
            created_at: '2023-01-05T09:45:00Z',
            updated_at: '2023-06-10T15:00:00Z'
          },
          {
            geo_id: 'geo-56789',
            geo_type: 'city',
            geo_name: 'Toronto',
            geo_name_fr: 'Toronto',
            geo_code: 'TOR',
            parent_geo_id: 'geo-45678',
            properties: {
              population: 2930000,
              area: 630,
              isCapital: true
            },
            created_at: '2023-01-05T09:50:00Z',
            updated_at: '2023-06-10T15:05:00Z'
          }
        ];
        
        setGeoData(mockGeoData);
        
        // Apply filters
        let filtered = [...mockGeoData];
        
        if (geoSearchTerm) {
          const searchLower = geoSearchTerm.toLowerCase();
          filtered = filtered.filter(geo => 
            geo.geo_name.toLowerCase().includes(searchLower) ||
            geo.geo_code.toLowerCase().includes(searchLower)
          );
        }
        
        if (selectedGeoType !== 'all') {
          filtered = filtered.filter(geo => geo.geo_type === selectedGeoType);
        }
        
        setFilteredGeoData(filtered);
      } catch (err) {
        console.error('Error fetching geography data:', err);
        setError(t('failed_to_load_geography_data'));
      } finally {
        setLoading(false);
      }
    };
    
    if (activeTab === 'geography') {
      fetchGeoData();
    }
  }, [activeTab, geoSearchTerm, selectedGeoType, t]);

  // Filter language strings when search term changes
  useEffect(() => {
    if (languageStrings.length > 0) {
      if (languageSearchTerm) {
        const searchLower = languageSearchTerm.toLowerCase();
        const filtered = languageStrings.filter(lang => 
          lang.string_id.toLowerCase().includes(searchLower) ||
          lang.en_ca.toLowerCase().includes(searchLower) ||
          lang.fr_ca.toLowerCase().includes(searchLower) ||
          lang.es_es.toLowerCase().includes(searchLower)
        );
        setFilteredLanguageStrings(filtered);
      } else {
        setFilteredLanguageStrings(languageStrings);
      }
    }
  }, [languageSearchTerm, languageStrings]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setError(null);
    setSuccess(null);
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }).format(date);
  };

  // Language Management Functions
  const handleLanguageSearch = (e) => {
    setLanguageSearchTerm(e.target.value);
  };

  const handleSelectLanguageString = (langString) => {
    setSelectedLanguageString(langString);
    setEditingLanguageString(false);
  };

  const handleEditLanguageString = () => {
    if (selectedLanguageString) {
      setNewLanguageString({
        string_id: selectedLanguageString.string_id,
        pages: [...selectedLanguageString.pages],
        en_ca: selectedLanguageString.en_ca,
        fr_ca: selectedLanguageString.fr_ca,
        es_es: selectedLanguageString.es_es
      });
      setEditingLanguageString(true);
    }
  };

  const handleNewLanguageString = () => {
    setSelectedLanguageString(null);
    setNewLanguageString({
      string_id: '',
      pages: [],
      en_ca: '',
      fr_ca: '',
      es_es: ''
    });
    setEditingLanguageString(true);
  };

  const handleLanguageStringChange = (e) => {
    const { name, value } = e.target;
    setNewLanguageString(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleLanguagePageChange = (e) => {
    const value = e.target.value;
    if (e.target.checked) {
      setNewLanguageString(prev => ({
        ...prev,
        pages: [...prev.pages, value]
      }));
    } else {
      setNewLanguageString(prev => ({
        ...prev,
        pages: prev.pages.filter(page => page !== value)
      }));
    }
  };

  const handleSaveLanguageString = async () => {
    setLoading(true);
    setError(null);
    setSuccess(null);
    
    try {
      // Validate required fields
      if (!newLanguageString.string_id || !newLanguageString.en_ca) {
        throw new Error(t('string_id_and_english_required'));
      }
      
      // This would be replaced with actual API calls
      if (selectedLanguageString) {
        // Update existing string
        // await masterService.updateLanguageString(selectedLanguageString.language_id, newLanguageString);
      } else {
        // Create new string
        // await masterService.createLanguageString(newLanguageString);
      }
      
      // Mock update for now
      await new Promise(resolve => setTimeout(resolve, 800));
      
      // Update local state
      if (selectedLanguageString) {
        setLanguageStrings(prev => 
          prev.map(lang => 
            lang.language_id === selectedLanguageString.language_id 
              ? { ...lang, ...newLanguageString, updated_at: new Date().toISOString() }
              : lang
          )
        );
        setSuccess(t('language_string_updated_successfully'));
      } else {
        const newLang = {
          language_id: `lang-${Math.random().toString(36).substring(2, 10)}`,
          ...newLanguageString,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString()
        };
        setLanguageStrings(prev => [...prev, newLang]);
        setSuccess(t('language_string_created_successfully'));
      }
      
      setEditingLanguageString(false);
      setSelectedLanguageString(null);
    } catch (err) {
      console.error('Error saving language string:', err);
      setError(err.message || t('failed_to_save_language_string'));
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteLanguageString = async () => {
    if (!selectedLanguageString) return;
    
    if (!window.confirm(t('confirm_delete_language_string'))) return;
    
    setLoading(true);
    setError(null);
    setSuccess(null);
    
    try {
      // This would be replaced with an actual API call
      // await masterService.deleteLanguageString(selectedLanguageString.language_id);
      
      // Mock delete for now
      await new Promise(resolve => setTimeout(resolve, 800));
      
      // Update local state
      setLanguageStrings(prev => 
        prev.filter(lang => lang.language_id !== selectedLanguageString.language_id)
      );
      
      setSelectedLanguageString(null);
      setSuccess(t('language_string_deleted_successfully'));
    } catch (err) {
      console.error('Error deleting language string:', err);
      setError(t('failed_to_delete_language_string'));
    } finally {
      setLoading(false);
    }
  };

  // Category Management Functions
  const handleSelectCategoryType = (catType) => {
    setSelectedCategoryType(catType);
    setEditingCategoryType(false);
    setEditingCategoryValue(false);
  };

  const handleEditCategoryType = () => {
    if (selectedCategoryType) {
      setNewCategoryType({
        category_name: selectedCategoryType.category_name,
        description: selectedCategoryType.description
      });
      setEditingCategoryType(true);
    }
  };

  const handleNewCategoryType = () => {
    setSelectedCategoryType(null);
    setNewCategoryType({
      category_name: '',
      description: ''
    });
    setEditingCategoryType(true);
  };

  const handleCategoryTypeChange = (e) => {
    const { name, value } = e.target;
    setNewCategoryType(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSaveCategoryType = async () => {
    setLoading(true);
    setError(null);
    setSuccess(null);
    
    try {
      // Validate required fields
      if (!newCategoryType.category_name) {
        throw new Error(t('category_name_required'));
      }
      
      // This would be replaced with actual API calls
      if (selectedCategoryType) {
        // Update existing category type
        // await masterService.updateCategoryType(selectedCategoryType.category_type_id, newCategoryType);
      } else {
        // Create new category type
        // await masterService.createCategoryType(newCategoryType);
      }
      
      // Mock update for now
      await new Promise(resolve => setTimeout(resolve, 800));
      
      // Update local state
      if (selectedCategoryType) {
        setCategoryTypes(prev => 
          prev.map(cat => 
            cat.category_type_id === selectedCategoryType.category_type_id 
              ? { ...cat, ...newCategoryType, updated_at: new Date().toISOString() }
              : cat
          )
        );
        setSuccess(t('category_type_updated_successfully'));
      } else {
        const newCat = {
          category_type_id: Math.floor(Math.random() * 1000) + 10,
          ...newCategoryType,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString()
        };
        setCategoryTypes(prev => [...prev, newCat]);
        setSuccess(t('category_type_created_successfully'));
      }
      
      setEditingCategoryType(false);
    } catch (err) {
      console.error('Error saving category type:', err);
      setError(err.message || t('failed_to_save_category_type'));
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteCategoryType = async () => {
    if (!selectedCategoryType) return;
    
    if (!window.confirm(t('confirm_delete_category_type'))) return;
    
    setLoading(true);
    setError(null);
    setSuccess(null);
    
    try {
      // This would be replaced with an actual API call
      // await masterService.deleteCategoryType(selectedCategoryType.category_type_id);
      
      // Mock delete for now
      await new Promise(resolve => setTimeout(resolve, 800));
      
      // Update local state
      setCategoryTypes(prev => 
        prev.filter(cat => cat.category_type_id !== selectedCategoryType.category_type_id)
      );
      
      setSelectedCategoryType(null);
      setCategoryValues([]);
      setSuccess(t('category_type_deleted_successfully'));
    } catch (err) {
      console.error('Error deleting category type:', err);
      setError(t('failed_to_delete_category_type'));
    } finally {
      setLoading(false);
    }
  };

  const handleNewCategoryValue = () => {
    setNewCategoryValue({
      value_key: '',
      is_active: 'true',
      description: ''
    });
    setEditingCategoryValue(true);
  };

  const handleEditCategoryValue = (catValue) => {
    setNewCategoryValue({
      category_value_id: catValue.category_value_id,
      value_key: catValue.value_key,
      is_active: catValue.is_active,
      description: catValue.description
    });
    setEditingCategoryValue(true);
  };

  const handleCategoryValueChange = (e) => {
    const { name, value } = e.target;
    setNewCategoryValue(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSaveCategoryValue = async () => {
    if (!selectedCategoryType) return;
    
    setLoading(true);
    setError(null);
    setSuccess(null);
    
    try {
      // Validate required fields
      if (!newCategoryValue.value_key) {
        throw new Error(t('value_key_required'));
      }
      
      // This would be replaced with actual API calls
      if (newCategoryValue.category_value_id) {
        // Update existing category value
        // await masterService.updateCategoryValue(newCategoryValue.category_value_id, newCategoryValue);
      } else {
        // Create new category value
        // await masterService.createCategoryValue(selectedCategoryType.category_type_id, newCategoryValue);
      }
      
      // Mock update for now
      await new Promise(resolve => setTimeout(resolve, 800));
      
      // Update local state
      if (newCategoryValue.category_value_id) {
        setCategoryValues(prev => 
          prev.map(val => 
            val.category_value_id === newCategoryValue.category_value_id 
              ? { ...val, ...newCategoryValue, updated_at: new Date().toISOString() }
              : val
          )
        );
        setSuccess(t('category_value_updated_successfully'));
      } else {
        const newVal = {
          category_value_id: Math.floor(Math.random() * 1000) + 100,
          category_type_id: selectedCategoryType.category_type_id,
          ...newCategoryValue,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString()
        };
        setCategoryValues(prev => [...prev, newVal]);
        setSuccess(t('category_value_created_successfully'));
      }
      
      setEditingCategoryValue(false);
    } catch (err) {
      console.error('Error saving category value:', err);
      setError(err.message || t('failed_to_save_category_value'));
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteCategoryValue = async (catValue) => {
    if (!window.confirm(t('confirm_delete_category_value'))) return;
    
    setLoading(true);
    setError(null);
    setSuccess(null);
    
    try {
      // This would be replaced with an actual API call
      // await masterService.deleteCategoryValue(catValue.category_value_id);
      
      // Mock delete for now
      await new Promise(resolve => setTimeout(resolve, 800));
      
      // Update local state
      setCategoryValues(prev => 
        prev.filter(val => val.category_value_id !== catValue.category_value_id)
      );
      
      setSuccess(t('category_value_deleted_successfully'));
    } catch (err) {
      console.error('Error deleting category value:', err);
      setError(t('failed_to_delete_category_value'));
    } finally {
      setLoading(false);
    }
  };

  // Geography Management Functions
  const handleGeoSearch = (e) => {
    setGeoSearchTerm(e.target.value);
  };

  const handleGeoTypeFilter = (e) => {
    setSelectedGeoType(e.target.value);
  };

  const handleSelectGeo = (geo) => {
    setSelectedGeo(geo);
    setEditingGeo(false);
  };

  const handleEditGeo = () => {
    if (selectedGeo) {
      setNewGeo({
        geo_type: selectedGeo.geo_type,
        geo_name: selectedGeo.geo_name,
        geo_name_fr: selectedGeo.geo_name_fr,
        geo_code: selectedGeo.geo_code,
        parent_geo_id: selectedGeo.parent_geo_id || '',
        properties: { ...selectedGeo.properties }
      });
      setEditingGeo(true);
    }
  };

  const handleNewGeo = () => {
    setSelectedGeo(null);
    setNewGeo({
      geo_type: 'country',
      geo_name: '',
      geo_name_fr: '',
      geo_code: '',
      parent_geo_id: '',
      properties: {}
    });
    setEditingGeo(true);
  };

  const handleGeoChange = (e) => {
    const { name, value } = e.target;
    setNewGeo(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleGeoPropertyChange = (e) => {
    const { name, value } = e.target;
    setNewGeo(prev => ({
      ...prev,
      properties: {
        ...prev.properties,
        [name]: value
      }
    }));
  };

  const handleGeoFileChange = (e) => {
    setGeoFile(e.target.files[0]);
  };

  const handleSaveGeo = async () => {
    setLoading(true);
    setError(null);
    setSuccess(null);
    
    try {
      // Validate required fields
      if (!newGeo.geo_name || !newGeo.geo_code) {
        throw new Error(t('geo_name_and_code_required'));
      }
      
      // This would be replaced with actual API calls
      if (selectedGeo) {
        // Update existing geo
        // await masterService.updateGeography(selectedGeo.geo_id, newGeo);
      } else {
        // Create new geo
        // await masterService.createGeography(newGeo);
      }
      
      // Mock update for now
      await new Promise(resolve => setTimeout(resolve, 800));
      
      // Update local state
      if (selectedGeo) {
        setGeoData(prev => 
          prev.map(geo => 
            geo.geo_id === selectedGeo.geo_id 
              ? { ...geo, ...newGeo, updated_at: new Date().toISOString() }
              : geo
          )
        );
        setSuccess(t('geography_updated_successfully'));
      } else {
        const newGeoEntry = {
          geo_id: `geo-${Math.random().toString(36).substring(2, 10)}`,
          ...newGeo,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString()
        };
        setGeoData(prev => [...prev, newGeoEntry]);
        setSuccess(t('geography_created_successfully'));
      }
      
      setEditingGeo(false);
      setSelectedGeo(null);
    } catch (err) {
      console.error('Error saving geography:', err);
      setError(err.message || t('failed_to_save_geography'));
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteGeo = async () => {
    if (!selectedGeo) return;
    
    if (!window.confirm(t('confirm_delete_geography'))) return;
    
    setLoading(true);
    setError(null);
    setSuccess(null);
    
    try {
      // This would be replaced with an actual API call
      // await masterService.deleteGeography(selectedGeo.geo_id);
      
      // Mock delete for now
      await new Promise(resolve => setTimeout(resolve, 800));
      
      // Update local state
      setGeoData(prev => 
        prev.filter(geo => geo.geo_id !== selectedGeo.geo_id)
      );
      
      setSelectedGeo(null);
      setSuccess(t('geography_deleted_successfully'));
    } catch (err) {
      console.error('Error deleting geography:', err);
      setError(t('failed_to_delete_geography'));
    } finally {
      setLoading(false);
    }
  };

  const handleUploadGeoFile = async () => {
    if (!geoFile) return;
    
    setLoading(true);
    setError(null);
    setSuccess(null);
    
    try {
      // This would be replaced with an actual API call
      // await masterService.uploadGeographyFile(geoFile);
      
      // Mock upload for now
      await new Promise(resolve => setTimeout(resolve, 1500));
      
      setSuccess(t('geography_file_uploaded_successfully'));
      setGeoFile(null);
    } catch (err) {
      console.error('Error uploading geography file:', err);
      setError(t('failed_to_upload_geography_file'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{t('system_settings')}</h1>
      
      <div className={styles.tabs}>
        <button 
          className={`${styles.tab} ${activeTab === 'language' ? styles.activeTab : ''}`}
          onClick={() => handleTabChange('language')}
        >
          <i className="fas fa-language"></i>
          <span>{t('language_management')}</span>
        </button>
        <button 
          className={`${styles.tab} ${activeTab === 'categories' ? styles.activeTab : ''}`}
          onClick={() => handleTabChange('categories')}
        >
          <i className="fas fa-list"></i>
          <span>{t('category_management')}</span>
        </button>
        <button 
          className={`${styles.tab} ${activeTab === 'geography' ? styles.activeTab : ''}`}
          onClick={() => handleTabChange('geography')}
        >
          <i className="fas fa-globe-americas"></i>
          <span>{t('geography_management')}</span>
        </button>
      </div>
      
      {error && (
        <div className={styles.error}>
          <i className="fas fa-exclamation-circle"></i>
          <span>{error}</span>
        </div>
      )}
      
      {success && (
        <div className={styles.success}>
          <i className="fas fa-check-circle"></i>
          <span>{success}</span>
        </div>
      )}
      
      <div className={styles.tabContent}>
        {/* Language Management Tab */}
        {activeTab === 'language' && (
          <div className={styles.languageTab}>
            <div className={styles.languageHeader}>
              <div className={styles.searchContainer}>
                <input
                  type="text"
                  className={styles.searchInput}
                  placeholder={t('search_language_strings')}
                  value={languageSearchTerm}
                  onChange={handleLanguageSearch}
                />
                <i className="fas fa-search"></i>
              </div>
              
              <button 
                className={styles.addButton}
                onClick={handleNewLanguageString}
                disabled={loading}
              >
                <i className="fas fa-plus"></i>
                <span>{t('add_language_string')}</span>
              </button>
            </div>
            
            <div className={styles.languageContent}>
              <div className={styles.languageList}>
                {loading && !editingLanguageString ? (
                  <div className={styles.loading}>
                    <div className={styles.spinner}></div>
                    <p>{t('loading_language_strings')}</p>
                  </div>
                ) : filteredLanguageStrings.length > 0 ? (
                  <div className={styles.languageTable}>
                    <div className={styles.languageTableHeader}>
                      <div className={styles.languageTableCell}>{t('string_id')}</div>
                      <div className={styles.languageTableCell}>{t('english')}</div>
                      <div className={styles.languageTableCell}>{t('french')}</div>
                      <div className={styles.languageTableCell}>{t('spanish')}</div>
                    </div>
                    <div className={styles.languageTableBody}>
                      {filteredLanguageStrings.map(langString => (
                        <div 
                          key={langString.language_id} 
                          className={`${styles.languageTableRow} ${selectedLanguageString?.language_id === langString.language_id ? styles.selectedRow : ''}`}
                          onClick={() => handleSelectLanguageString(langString)}
                        >
                          <div className={styles.languageTableCell}>{langString.string_id}</div>
                          <div className={styles.languageTableCell}>{langString.en_ca}</div>
                          <div className={styles.languageTableCell}>{langString.fr_ca}</div>
                          <div className={styles.languageTableCell}>{langString.es_es}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className={styles.emptyState}>
                    <i className="fas fa-search"></i>
                    <p>{t('no_language_strings_found')}</p>
                  </div>
                )}
              </div>
              
              <div className={styles.languageDetail}>
                {editingLanguageString ? (
                  <div className={styles.languageForm}>
                    <h3 className={styles.formTitle}>
                      {selectedLanguageString ? t('edit_language_string') : t('add_language_string')}
                    </h3>
                    
                    <div className={styles.formGroup}>
                      <label htmlFor="string_id" className={styles.formLabel}>
                        {t('string_id')} <span className={styles.required}>*</span>
                      </label>
                      <input
                        type="text"
                        id="string_id"
                        name="string_id"
                        className={styles.formInput}
                        value={newLanguageString.string_id}
                        onChange={handleLanguageStringChange}
                        disabled={loading || selectedLanguageString}
                        required
                      />
                      <div className={styles.formHelp}>{t('string_id_help')}</div>
                    </div>
                    
                    <div className={styles.formGroup}>
                      <label className={styles.formLabel}>{t('pages')}</label>
                      <div className={styles.checkboxGroup}>
                        {['home', 'login', 'signup', 'dashboard', 'settings'].map(page => (
                          <label key={page} className={styles.checkboxLabel}>
                            <input
                              type="checkbox"
                              name="pages"
                              value={page}
                              checked={newLanguageString.pages.includes(page)}
                              onChange={handleLanguagePageChange}
                              disabled={loading}
                              className={styles.checkbox}
                            />
                            {page}
                          </label>
                        ))}
                      </div>
                    </div>
                    
                    <div className={styles.formGroup}>
                      <label htmlFor="en_ca" className={styles.formLabel}>
                        {t('english')} (en_ca) <span className={styles.required}>*</span>
                      </label>
                      <input
                        type="text"
                        id="en_ca"
                        name="en_ca"
                        className={styles.formInput}
                        value={newLanguageString.en_ca}
                        onChange={handleLanguageStringChange}
                        disabled={loading}
                        required
                      />
                    </div>
                    
                    <div className={styles.formGroup}>
                      <label htmlFor="fr_ca" className={styles.formLabel}>
                        {t('french')} (fr_ca)
                      </label>
                      <input
                        type="text"
                        id="fr_ca"
                        name="fr_ca"
                        className={styles.formInput}
                        value={newLanguageString.fr_ca}
                        onChange={handleLanguageStringChange}
                        disabled={loading}
                      />
                    </div>
                    
                    <div className={styles.formGroup}>
                      <label htmlFor="es_es" className={styles.formLabel}>
                        {t('spanish')} (es_es)
                      </label>
                      <input
                        type="text"
                        id="es_es"
                        name="es_es"
                        className={styles.formInput}
                        value={newLanguageString.es_es}
                        onChange={handleLanguageStringChange}
                        disabled={loading}
                      />
                    </div>
                    
                    <div className={styles.formActions}>
                      <button
                        type="button"
                        className={styles.cancelButton}
                        onClick={() => setEditingLanguageString(false)}
                        disabled={loading}
                      >
                        {t('cancel')}
                      </button>
                      <button
                        type="button"
                        className={styles.saveButton}
                        onClick={handleSaveLanguageString}
                        disabled={loading}
                      >
                        {loading ? (
                          <>
                            <div className={styles.spinner}></div>
                            <span>{t('saving')}</span>
                          </>
                        ) : (
                          <>
                            <i className="fas fa-save"></i>
                            <span>{t('save')}</span>
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                ) : selectedLanguageString ? (
                  <div className={styles.languageDetails}>
                    <div className={styles.detailsHeader}>
                      <h3 className={styles.detailsTitle}>{selectedLanguageString.string_id}</h3>
                      <div className={styles.detailsActions}>
                        <button
                          className={styles.editButton}
                          onClick={handleEditLanguageString}
                          disabled={loading}
                        >
                          <i className="fas fa-edit"></i>
                          <span>{t('edit')}</span>
                        </button>
                        <button
                          className={styles.deleteButton}
                          onClick={handleDeleteLanguageString}
                          disabled={loading}
                        >
                          <i className="fas fa-trash-alt"></i>
                          <span>{t('delete')}</span>
                        </button>
                      </div>
                    </div>
                    
                    <div className={styles.detailsContent}>
                      <div className={styles.detailsSection}>
                        <h4 className={styles.detailsSectionTitle}>{t('translations')}</h4>
                        <div className={styles.translationsList}>
                          <div className={styles.translationItem}>
                            <div className={styles.translationLanguage}>
                              <span className={styles.languageCode}>en_ca</span>
                              <span className={styles.languageName}>{t('english')}</span>
                            </div>
                            <div className={styles.translationValue}>{selectedLanguageString.en_ca}</div>
                          </div>
                          <div className={styles.translationItem}>
                            <div className={styles.translationLanguage}>
                              <span className={styles.languageCode}>fr_ca</span>
                              <span className={styles.languageName}>{t('french')}</span>
                            </div>
                            <div className={styles.translationValue}>{selectedLanguageString.fr_ca}</div>
                          </div>
                          <div className={styles.translationItem}>
                            <div className={styles.translationLanguage}>
                              <span className={styles.languageCode}>es_es</span>
                              <span className={styles.languageName}>{t('spanish')}</span>
                            </div>
                            <div className={styles.translationValue}>{selectedLanguageString.es_es}</div>
                          </div>
                        </div>
                      </div>
                      
                      <div className={styles.detailsSection}>
                        <h4 className={styles.detailsSectionTitle}>{t('pages')}</h4>
                        <div className={styles.pagesList}>
                          {selectedLanguageString.pages.map(page => (
                            <span key={page} className={styles.pageTag}>{page}</span>
                          ))}
                        </div>
                      </div>
                      
                      <div className={styles.detailsSection}>
                        <h4 className={styles.detailsSectionTitle}>{t('metadata')}</h4>
                        <div className={styles.metadataGrid}>
                          <div className={styles.metadataItem}>
                            <div className={styles.metadataLabel}>{t('created_at')}</div>
                            <div className={styles.metadataValue}>{formatDate(selectedLanguageString.created_at)}</div>
                          </div>
                          <div className={styles.metadataItem}>
                            <div className={styles.metadataLabel}>{t('updated_at')}</div>
                            <div className={styles.metadataValue}>{formatDate(selectedLanguageString.updated_at)}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={styles.noSelectionState}>
                    <i className="fas fa-language"></i>
                    <p>{t('select_language_string_or_create_new')}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        
        {/* Category Management Tab */}
        {activeTab === 'categories' && (
          <div className={styles.categoriesTab}>
            <div className={styles.categoriesContent}>
              <div className={styles.categoriesSidebar}>
                <div className={styles.sidebarHeader}>
                  <h3 className={styles.sidebarTitle}>{t('category_types')}</h3>
                  <button
                    className={styles.addCategoryButton}
                    onClick={handleNewCategoryType}
                    disabled={loading}
                  >
                    <i className="fas fa-plus"></i>
                  </button>
                </div>
                
                {loading && !editingCategoryType && !editingCategoryValue ? (
                  <div className={styles.loading}>
                    <div className={styles.spinner}></div>
                    <p>{t('loading_categories')}</p>
                  </div>
                ) : categoryTypes.length > 0 ? (
                  <div className={styles.categoryTypesList}>
                    {categoryTypes.map(catType => (
                      <div
                        key={catType.category_type_id}
                        className={`${styles.categoryTypeItem} ${selectedCategoryType?.category_type_id === catType.category_type_id ? styles.selectedCategoryType : ''}`}
                        onClick={() => handleSelectCategoryType(catType)}
                      >
                        <span className={styles.categoryTypeName}>{catType.category_name}</span>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className={styles.emptyState}>
                    <p>{t('no_category_types_found')}</p>
                  </div>
                )}
              </div>
              
              <div className={styles.categoriesMain}>
                {editingCategoryType ? (
                  <div className={styles.categoryTypeForm}>
                    <h3 className={styles.formTitle}>
                      {selectedCategoryType ? t('edit_category_type') : t('add_category_type')}
                    </h3>
                    
                    <div className={styles.formGroup}>
                      <label htmlFor="category_name" className={styles.formLabel}>
                        {t('category_name')} <span className={styles.required}>*</span>
                      </label>
                      <input
                        type="text"
                        id="category_name"
                        name="category_name"
                        className={styles.formInput}
                        value={newCategoryType.category_name}
                        onChange={handleCategoryTypeChange}
                        disabled={loading}
                        required
                      />
                    </div>
                    
                    <div className={styles.formGroup}>
                      <label htmlFor="description" className={styles.formLabel}>
                        {t('description')}
                      </label>
                      <textarea
                        id="description"
                        name="description"
                        className={styles.formTextarea}
                        value={newCategoryType.description}
                        onChange={handleCategoryTypeChange}
                        disabled={loading}
                        rows={4}
                      ></textarea>
                    </div>
                    
                    <div className={styles.formActions}>
                      <button
                        type="button"
                        className={styles.cancelButton}
                        onClick={() => setEditingCategoryType(false)}
                        disabled={loading}
                      >
                        {t('cancel')}
                      </button>
                      <button
                        type="button"
                        className={styles.saveButton}
                        onClick={handleSaveCategoryType}
                        disabled={loading}
                      >
                        {loading ? (
                          <>
                            <div className={styles.spinner}></div>
                            <span>{t('saving')}</span>
                          </>
                        ) : (
                          <>
                            <i className="fas fa-save"></i>
                            <span>{t('save')}</span>
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                ) : editingCategoryValue ? (
                  <div className={styles.categoryValueForm}>
                    <h3 className={styles.formTitle}>
                      {newCategoryValue.category_value_id ? t('edit_category_value') : t('add_category_value')}
                    </h3>
                    
                    <div className={styles.formGroup}>
                      <label htmlFor="value_key" className={styles.formLabel}>
                        {t('value_key')} <span className={styles.required}>*</span>
                      </label>
                      <input
                        type="text"
                        id="value_key"
                        name="value_key"
                        className={styles.formInput}
                        value={newCategoryValue.value_key}
                        onChange={handleCategoryValueChange}
                        disabled={loading}
                        required
                      />
                    </div>
                    
                    <div className={styles.formGroup}>
                      <label htmlFor="is_active" className={styles.formLabel}>
                        {t('status')}
                      </label>
                      <select
                        id="is_active"
                        name="is_active"
                        className={styles.formSelect}
                        value={newCategoryValue.is_active}
                        onChange={handleCategoryValueChange}
                        disabled={loading}
                      >
                        <option value="true">{t('active')}</option>
                        <option value="false">{t('inactive')}</option>
                      </select>
                    </div>
                    
                    <div className={styles.formGroup}>
                      <label htmlFor="value_description" className={styles.formLabel}>
                        {t('description')}
                      </label>
                      <textarea
                        id="value_description"
                        name="description"
                        className={styles.formTextarea}
                        value={newCategoryValue.description}
                        onChange={handleCategoryValueChange}
                        disabled={loading}
                        rows={4}
                      ></textarea>
                    </div>
                    
                    <div className={styles.formActions}>
                      <button
                        type="button"
                        className={styles.cancelButton}
                        onClick={() => setEditingCategoryValue(false)}
                        disabled={loading}
                      >
                        {t('cancel')}
                      </button>
                      <button
                        type="button"
                        className={styles.saveButton}
                        onClick={handleSaveCategoryValue}
                        disabled={loading}
                      >
                        {loading ? (
                          <>
                            <div className={styles.spinner}></div>
                            <span>{t('saving')}</span>
                          </>
                        ) : (
                          <>
                            <i className="fas fa-save"></i>
                            <span>{t('save')}</span>
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                ) : selectedCategoryType ? (
                  <div className={styles.categoryTypeDetails}>
                    <div className={styles.categoryTypeHeader}>
                      <div className={styles.categoryTypeInfo}>
                        <h3 className={styles.categoryTypeName}>{selectedCategoryType.category_name}</h3>
                        <p className={styles.categoryTypeDescription}>{selectedCategoryType.description}</p>
                      </div>
                      <div className={styles.categoryTypeActions}>
                        <button
                          className={styles.editButton}
                          onClick={handleEditCategoryType}
                          disabled={loading}
                        >
                          <i className="fas fa-edit"></i>
                          <span>{t('edit')}</span>
                        </button>
                        <button
                          className={styles.deleteButton}
                          onClick={handleDeleteCategoryType}
                          disabled={loading}
                        >
                          <i className="fas fa-trash-alt"></i>
                          <span>{t('delete')}</span>
                        </button>
                      </div>
                    </div>
                    
                    <div className={styles.categoryValuesSection}>
                      <div className={styles.categoryValuesHeader}>
                        <h4 className={styles.categoryValuesSectionTitle}>{t('category_values')}</h4>
                        <button
                          className={styles.addValueButton}
                          onClick={handleNewCategoryValue}
                          disabled={loading}
                        >
                          <i className="fas fa-plus"></i>
                          <span>{t('add_value')}</span>
                        </button>
                      </div>
                      
                      {categoryValues.length > 0 ? (
                        <div className={styles.categoryValuesTable}>
                          <div className={styles.categoryValuesTableHeader}>
                            <div className={styles.categoryValuesTableCell}>{t('value_key')}</div>
                            <div className={styles.categoryValuesTableCell}>{t('status')}</div>
                            <div className={styles.categoryValuesTableCell}>{t('description')}</div>
                            <div className={styles.categoryValuesTableCell}>{t('actions')}</div>
                          </div>
                          <div className={styles.categoryValuesTableBody}>
                            {categoryValues.map(catValue => (
                              <div key={catValue.category_value_id} className={styles.categoryValuesTableRow}>
                                <div className={styles.categoryValuesTableCell}>{catValue.value_key}</div>
                                <div className={styles.categoryValuesTableCell}>
                                  <span className={`${styles.statusBadge} ${catValue.is_active === 'true' ? styles.statusActive : styles.statusInactive}`}>
                                    {catValue.is_active === 'true' ? t('active') : t('inactive')}
                                  </span>
                                </div>
                                <div className={styles.categoryValuesTableCell}>{catValue.description}</div>
                                <div className={styles.categoryValuesTableCell}>
                                  <div className={styles.categoryValueActions}>
                                    <button
                                      className={styles.editValueButton}
                                      onClick={() => handleEditCategoryValue(catValue)}
                                      disabled={loading}
                                    >
                                      <i className="fas fa-edit"></i>
                                    </button>
                                    <button
                                      className={styles.deleteValueButton}
                                      onClick={() => handleDeleteCategoryValue(catValue)}
                                      disabled={loading}
                                    >
                                      <i className="fas fa-trash-alt"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : (
                        <div className={styles.emptyState}>
                          <p>{t('no_category_values_found')}</p>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className={styles.noSelectionState}>
                    <i className="fas fa-list"></i>
                    <p>{t('select_category_type_or_create_new')}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        
        {/* Geography Management Tab */}
        {activeTab === 'geography' && (
          <div className={styles.geographyTab}>
            <div className={styles.geographyHeader}>
              <div className={styles.geographyFilters}>
                <div className={styles.searchContainer}>
                  <input
                    type="text"
                    className={styles.searchInput}
                    placeholder={t('search_geography')}
                    value={geoSearchTerm}
                    onChange={handleGeoSearch}
                  />
                  <i className="fas fa-search"></i>
                </div>
                
                <div className={styles.filterContainer}>
                  <label htmlFor="geoTypeFilter" className={styles.filterLabel}>
                    {t('geo_type')}:
                  </label>
                  <select
                    id="geoTypeFilter"
                    className={styles.filterSelect}
                    value={selectedGeoType}
                    onChange={handleGeoTypeFilter}
                  >
                    <option value="all">{t('all_types')}</option>
                    {geoTypes.map(type => (
                      <option key={type} value={type}>{type}</option>
                    ))}
                  </select>
                </div>
              </div>
              
              <div className={styles.geographyActions}>
                <button
                  className={styles.uploadButton}
                  onClick={() => document.getElementById('geoFileInput').click()}
                  disabled={loading}
                >
                  <i className="fas fa-upload"></i>
                  <span>{t('upload_geo_file')}</span>
                </button>
                <input
                  type="file"
                  id="geoFileInput"
                  className={styles.fileInput}
                  onChange={handleGeoFileChange}
                  accept=".json,.geojson,.csv"
                />
                
                <button
                  className={styles.addButton}
                  onClick={handleNewGeo}
                  disabled={loading}
                >
                  <i className="fas fa-plus"></i>
                  <span>{t('add_geography')}</span>
                </button>
              </div>
            </div>
            
            {geoFile && (
              <div className={styles.fileUploadBar}>
                <div className={styles.fileInfo}>
                  <i className="fas fa-file"></i>
                  <span className={styles.fileName}>{geoFile.name}</span>
                  <span className={styles.fileSize}>({Math.round(geoFile.size / 1024)} KB)</span>
                </div>
                <div className={styles.fileActions}>
                  <button
                    className={styles.uploadFileButton}
                    onClick={handleUploadGeoFile}
                    disabled={loading}
                  >
                    {loading ? (
                      <>
                        <div className={styles.spinner}></div>
                        <span>{t('uploading')}</span>
                      </>
                    ) : (
                      <>
                        <i className="fas fa-cloud-upload-alt"></i>
                        <span>{t('upload')}</span>
                      </>
                    )}
                  </button>
                  <button
                    className={styles.cancelUploadButton}
                    onClick={() => setGeoFile(null)}
                    disabled={loading}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            )}
            
            <div className={styles.geographyContent}>
              <div className={styles.geographyList}>
                {loading && !editingGeo ? (
                  <div className={styles.loading}>
                    <div className={styles.spinner}></div>
                    <p>{t('loading_geography_data')}</p>
                  </div>
                ) : filteredGeoData.length > 0 ? (
                  <div className={styles.geoTable}>
                    <div className={styles.geoTableHeader}>
                      <div className={styles.geoTableCell}>{t('geo_name')}</div>
                      <div className={styles.geoTableCell}>{t('geo_type')}</div>
                      <div className={styles.geoTableCell}>{t('geo_code')}</div>
                      <div className={styles.geoTableCell}>{t('parent')}</div>
                    </div>
                    <div className={styles.geoTableBody}>
                      {filteredGeoData.map(geo => (
                        <div 
                          key={geo.geo_id} 
                          className={`${styles.geoTableRow} ${selectedGeo?.geo_id === geo.geo_id ? styles.selectedRow : ''}`}
                          onClick={() => handleSelectGeo(geo)}
                        >
                          <div className={styles.geoTableCell}>{geo.geo_name}</div>
                          <div className={styles.geoTableCell}>
                            <span className={styles.geoTypeBadge}>{geo.geo_type}</span>
                          </div>
                          <div className={styles.geoTableCell}>{geo.geo_code}</div>
                          <div className={styles.geoTableCell}>
                            {geo.parent_geo_id ? (
                              geoData.find(g => g.geo_id === geo.parent_geo_id)?.geo_name || geo.parent_geo_id
                            ) : '-'}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className={styles.emptyState}>
                    <i className="fas fa-globe-americas"></i>
                    <p>{t('no_geography_data_found')}</p>
                  </div>
                )}
              </div>
              
              <div className={styles.geographyDetail}>
                {editingGeo ? (
                  <div className={styles.geoForm}>
                    <h3 className={styles.formTitle}>
                      {selectedGeo ? t('edit_geography') : t('add_geography')}
                    </h3>
                    
                    <div className={styles.formGroup}>
                      <label htmlFor="geo_type" className={styles.formLabel}>
                        {t('geo_type')} <span className={styles.required}>*</span>
                      </label>
                      <select
                        id="geo_type"
                        name="geo_type"
                        className={styles.formSelect}
                        value={newGeo.geo_type}
                        onChange={handleGeoChange}
                        disabled={loading}
                        required
                      >
                        {geoTypes.map(type => (
                          <option key={type} value={type}>{type}</option>
                        ))}
                      </select>
                    </div>
                    
                    <div className={styles.formGroup}>
                      <label htmlFor="geo_name" className={styles.formLabel}>
                        {t('geo_name')} <span className={styles.required}>*</span>
                      </label>
                      <input
                        type="text"
                        id="geo_name"
                        name="geo_name"
                        className={styles.formInput}
                        value={newGeo.geo_name}
                        onChange={handleGeoChange}
                        disabled={loading}
                        required
                      />
                    </div>
                    
                    <div className={styles.formGroup}>
                      <label htmlFor="geo_name_fr" className={styles.formLabel}>
                        {t('geo_name_fr')}
                      </label>
                      <input
                        type="text"
                        id="geo_name_fr"
                        name="geo_name_fr"
                        className={styles.formInput}
                        value={newGeo.geo_name_fr}
                        onChange={handleGeoChange}
                        disabled={loading}
                      />
                    </div>
                    
                    <div className={styles.formGroup}>
                      <label htmlFor="geo_code" className={styles.formLabel}>
                        {t('geo_code')} <span className={styles.required}>*</span>
                      </label>
                      <input
                        type="text"
                        id="geo_code"
                        name="geo_code"
                        className={styles.formInput}
                        value={newGeo.geo_code}
                        onChange={handleGeoChange}
                        disabled={loading}
                        required
                      />
                    </div>
                    
                    <div className={styles.formGroup}>
                      <label htmlFor="parent_geo_id" className={styles.formLabel}>
                        {t('parent_geo')}
                      </label>
                      <select
                        id="parent_geo_id"
                        name="parent_geo_id"
                        className={styles.formSelect}
                        value={newGeo.parent_geo_id}
                        onChange={handleGeoChange}
                        disabled={loading}
                      >
                        <option value="">{t('none')}</option>
                        {geoData
                          .filter(geo => geo.geo_type !== 'city' && (!selectedGeo || geo.geo_id !== selectedGeo.geo_id))
                          .map(geo => (
                            <option key={geo.geo_id} value={geo.geo_id}>
                              {geo.geo_name} ({geo.geo_type})
                            </option>
                          ))}
                      </select>
                    </div>
                    
                    <div className={styles.formGroup}>
                      <label className={styles.formLabel}>{t('properties')}</label>
                      <div className={styles.propertiesForm}>
                        <div className={styles.propertyRow}>
                          <label htmlFor="population" className={styles.propertyLabel}>
                            {t('population')}
                          </label>
                          <input
                            type="number"
                            id="population"
                            name="population"
                            className={styles.propertyInput}
                            value={newGeo.properties.population || ''}
                            onChange={handleGeoPropertyChange}
                            disabled={loading}
                          />
                        </div>
                        <div className={styles.propertyRow}>
                          <label htmlFor="area" className={styles.propertyLabel}>
                            {t('area')}
                          </label>
                          <input
                            type="number"
                            id="area"
                            name="area"
                            className={styles.propertyInput}
                            value={newGeo.properties.area || ''}
                            onChange={handleGeoPropertyChange}
                            disabled={loading}
                          />
                        </div>
                        <div className={styles.propertyRow}>
                          <label htmlFor="capital" className={styles.propertyLabel}>
                            {t('capital')}
                          </label>
                          <input
                            type="text"
                            id="capital"
                            name="capital"
                            className={styles.propertyInput}
                            value={newGeo.properties.capital || ''}
                            onChange={handleGeoPropertyChange}
                            disabled={loading}
                          />
                        </div>
                      </div>
                    </div>
                    
                    <div className={styles.formActions}>
                      <button
                        type="button"
                        className={styles.cancelButton}
                        onClick={() => setEditingGeo(false)}
                        disabled={loading}
                      >
                        {t('cancel')}
                      </button>
                      <button
                        type="button"
                        className={styles.saveButton}
                        onClick={handleSaveGeo}
                        disabled={loading}
                      >
                        {loading ? (
                          <>
                            <div className={styles.spinner}></div>
                            <span>{t('saving')}</span>
                          </>
                        ) : (
                          <>
                            <i className="fas fa-save"></i>
                            <span>{t('save')}</span>
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                ) : selectedGeo ? (
                  <div className={styles.geoDetails}>
                    <div className={styles.geoDetailsHeader}>
                      <div className={styles.geoDetailsTitle}>
                        <h3 className={styles.geoName}>{selectedGeo.geo_name}</h3>
                        <span className={styles.geoTypeBadge}>{selectedGeo.geo_type}</span>
                      </div>
                      <div className={styles.geoDetailsActions}>
                        <button
                          className={styles.editButton}
                          onClick={handleEditGeo}
                          disabled={loading}
                        >
                          <i className="fas fa-edit"></i>
                          <span>{t('edit')}</span>
                        </button>
                        <button
                          className={styles.deleteButton}
                          onClick={handleDeleteGeo}
                          disabled={loading}
                        >
                          <i className="fas fa-trash-alt"></i>
                          <span>{t('delete')}</span>
                        </button>
                      </div>
                    </div>
                    
                    <div className={styles.geoDetailsContent}>
                      <div className={styles.geoDetailsSection}>
                        <h4 className={styles.geoDetailsSectionTitle}>{t('basic_information')}</h4>
                        <div className={styles.geoDetailsGrid}>
                          <div className={styles.geoDetailsItem}>
                            <div className={styles.geoDetailsLabel}>{t('geo_id')}</div>
                            <div className={styles.geoDetailsValue}>{selectedGeo.geo_id}</div>
                          </div>
                          <div className={styles.geoDetailsItem}>
                            <div className={styles.geoDetailsLabel}>{t('geo_code')}</div>
                            <div className={styles.geoDetailsValue}>{selectedGeo.geo_code}</div>
                          </div>
                          <div className={styles.geoDetailsItem}>
                            <div className={styles.geoDetailsLabel}>{t('geo_name')}</div>
                            <div className={styles.geoDetailsValue}>{selectedGeo.geo_name}</div>
                          </div>
                          <div className={styles.geoDetailsItem}>
                            <div className={styles.geoDetailsLabel}>{t('geo_name_fr')}</div>
                            <div className={styles.geoDetailsValue}>{selectedGeo.geo_name_fr || '-'}</div>
                          </div>
                          <div className={styles.geoDetailsItem}>
                            <div className={styles.geoDetailsLabel}>{t('parent_geo')}</div>
                            <div className={styles.geoDetailsValue}>
                              {selectedGeo.parent_geo_id ? (
                                geoData.find(g => g.geo_id === selectedGeo.parent_geo_id)?.geo_name || selectedGeo.parent_geo_id
                              ) : '-'}
                            </div>
                          </div>
                        </div>
                      </div>
                      
                      <div className={styles.geoDetailsSection}>
                        <h4 className={styles.geoDetailsSectionTitle}>{t('properties')}</h4>
                        <div className={styles.geoDetailsGrid}>
                          {Object.entries(selectedGeo.properties).map(([key, value]) => (
                            <div key={key} className={styles.geoDetailsItem}>
                              <div className={styles.geoDetailsLabel}>{key}</div>
                              <div className={styles.geoDetailsValue}>{value}</div>
                            </div>
                          ))}
                        </div>
                      </div>
                      
                      <div className={styles.geoDetailsSection}>
                        <h4 className={styles.geoDetailsSectionTitle}>{t('metadata')}</h4>
                        <div className={styles.geoDetailsGrid}>
                          <div className={styles.geoDetailsItem}>
                            <div className={styles.geoDetailsLabel}>{t('created_at')}</div>
                            <div className={styles.geoDetailsValue}>{formatDate(selectedGeo.created_at)}</div>
                          </div>
                          <div className={styles.geoDetailsItem}>
                            <div className={styles.geoDetailsLabel}>{t('updated_at')}</div>
                            <div className={styles.geoDetailsValue}>{formatDate(selectedGeo.updated_at)}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={styles.noSelectionState}>
                    <i className="fas fa-globe-americas"></i>
                    <p>{t('select_geography_or_create_new')}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SystemSettings;