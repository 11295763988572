// File: frontend/src/pages/InvestorPitch/components/slides/InvestmentSlide/FundingCard.js

import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import * as LucideIcons from 'lucide-react';
import styles from './FundingCard.module.css';

const FundingCard = ({ title, amount, icon, details, gradient }) => {
  const Icon = LucideIcons[icon];

  return (
    <motion.div 
      className={styles.container}
      whileHover={{ scale: 1.05 }}
      transition={{ type: 'spring', stiffness: 300, damping: 30 }}
    >
      <div 
        className={styles.iconWrapper}
        style={{ background: gradient }}
      >
        <Icon className={styles.icon} />
      </div>
      <h4 className={styles.title}>{title}</h4>
      <div className={styles.amount}>${amount}M</div>
      <ul className={styles.details}>
        {details.map((detail, index) => (
          <li key={index}>{detail}</li>
        ))}
      </ul>
    </motion.div>
  );
};

FundingCard.propTypes = {
  title: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  icon: PropTypes.string.isRequired,
  details: PropTypes.arrayOf(PropTypes.string).isRequired,
  gradient: PropTypes.string.isRequired,
};

export default FundingCard;