// File: frontend/src/pages/AutoInsight/VehicleDetail/components/VehicleClaims/index.js

import React from 'react';
import { useLanguage } from '../../../../../../contexts/LanguageContext';
import styles from './VehicleClaims.module.css';

// Helper function to format date
const formatDate = (dateString) => {
  if (!dateString) return 'N/A';
  
  const date = new Date(dateString);
  
  // Check if date is valid
  if (isNaN(date.getTime())) return 'Invalid Date';
  
  return date.toLocaleDateString();
};

// Helper function to format currency
const formatCurrency = (amount, currency = 'USD') => {
  if (amount === null || amount === undefined) return 'N/A';
  
  return new Intl.NumberFormat('en-US', { 
    style: 'currency', 
    currency: currency 
  }).format(amount);
};

const VehicleClaims = ({ vehicle }) => {
  const { t } = useLanguage();

  // Sort claims by date (newest first)
  const sortedClaims = vehicle.claims 
    ? [...vehicle.claims].sort((a, b) => {
        return new Date(b.claim_date) - new Date(a.claim_date);
      })
    : [];

  // Calculate total claim amount
  const totalClaimAmount = sortedClaims.reduce((total, claim) => {
    return total + (claim.claim_amount || 0);
  }, 0);

  // Group claims by status
  const openClaims = sortedClaims.filter(claim => claim.claim_status === 'Open' || claim.claim_status === 'In Progress');
  const closedClaims = sortedClaims.filter(claim => claim.claim_status === 'Closed' || claim.claim_status === 'Settled');
  const deniedClaims = sortedClaims.filter(claim => claim.claim_status === 'Denied');

  return (
    <div className={styles.container}>
      <div className={styles.claimSummary}>
        <div className={styles.summaryCard}>
          <div className={styles.summaryTitle}>{t('total_claims')}</div>
          <div className={styles.summaryValue}>{sortedClaims.length}</div>
        </div>
        
        <div className={styles.summaryCard}>
          <div className={styles.summaryTitle}>{t('open_claims')}</div>
          <div className={`${styles.summaryValue} ${openClaims.length > 0 ? styles.openClaims : ''}`}>
            {openClaims.length}
          </div>
        </div>
        
        <div className={styles.summaryCard}>
          <div className={styles.summaryTitle}>{t('total_claim_amount')}</div>
          <div className={styles.summaryValue}>
            {formatCurrency(totalClaimAmount, sortedClaims[0]?.claim_currency || 'USD')}
          </div>
        </div>
      </div>
      
      {sortedClaims.length > 0 ? (
        <div className={styles.card}>
          <h3 className={styles.cardTitle}>{t('claims_history')}</h3>
          <div className={styles.cardContent}>
            <div className={styles.claimsList}>
              {sortedClaims.map((claim) => (
                <div key={claim.claim_id} className={styles.claimItem}>
                  <div className={styles.claimHeader}>
                    <div className={styles.claimType}>{claim.claim_type}</div>
                    <div className={`${styles.claimStatus} ${styles[claim.claim_status.toLowerCase()]}`}>
                      {claim.claim_status}
                    </div>
                  </div>
                  
                  <div className={styles.claimDetails}>
                    <div className={styles.claimDetail}>
                      <span className={styles.claimDetailLabel}>{t('claim_id')}:</span>
                      <span className={styles.claimDetailValue}>{claim.claim_id}</span>
                    </div>
                    
                    <div className={styles.claimDetail}>
                      <span className={styles.claimDetailLabel}>{t('claim_date')}:</span>
                      <span className={styles.claimDetailValue}>{formatDate(claim.claim_date)}</span>
                    </div>
                    
                    <div className={styles.claimDetail}>
                      <span className={styles.claimDetailLabel}>{t('claim_amount')}:</span>
                      <span className={styles.claimDetailValue}>
                        {formatCurrency(claim.claim_amount, claim.claim_currency)}
                      </span>
                    </div>
                    
                    <div className={styles.claimDetail}>
                      <span className={styles.claimDetailLabel}>{t('last_updated')}:</span>
                      <span className={styles.claimDetailValue}>{formatDate(claim.updated_at)}</span>
                    </div>
                  </div>
                  
                  <div className={styles.claimDescription}>
                    <div className={styles.claimDescriptionLabel}>{t('description')}:</div>
                    <div className={styles.claimDescriptionValue}>{claim.claim_description}</div>
                  </div>
                  
                  {claim.claim_status === 'Open' || claim.claim_status === 'In Progress' ? (
                    <div className={styles.claimActions}>
                      <button className={styles.viewDetailsButton}>
                        {t('view_details')}
                      </button>
                      <button className={styles.updateButton}>
                        {t('update_claim')}
                      </button>
                    </div>
                  ) : (
                    <div className={styles.claimActions}>
                      <button className={styles.viewDetailsButton}>
                        {t('view_details')}
                      </button>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.noClaims}>
          <p>{t('no_claims_found')}</p>
          <div className={styles.noClaimsDescription}>
            {t('no_claims_description')}
          </div>
        </div>
      )}
      
      <div className={styles.fileClaimContainer}>
        <button className={styles.fileClaimButton}>
          {t('file_new_claim')}
        </button>
      </div>
    </div>
  );
};

export default VehicleClaims;