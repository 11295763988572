// File: frontend/src/pages/Quote/components/ChatInterface/components/ChatMessages.js

import React from 'react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from '../ChatInterface.module.css';

const ChatMessages = ({ messages, isTyping, isLoading, messagesEndRef }) => {
  const { t } = useLanguage();

  // Filter out initialization messages
  const visibleMessages = messages.filter(message => 
    !(message.sender === 'user' && 
      message.content.startsWith('_INITIATED_'))
  );

  const renderMessage = (message) => {
    if (typeof message.content === 'object' && message.content.type === 'visualization') {
      return (
        <div className={styles.visualization}>
          <div className={styles.savingsComparison}>
            <div className={styles.comparisonItem}>
              <div className={styles.comparisonAmount}>${message.content.currentPremium}/mo</div>
              <div className={styles.comparisonLabel}>Your Current Premium</div>
            </div>
            <div className={styles.arrow}>→</div>
            <div className={styles.comparisonItem}>
              <div className={styles.comparisonAmount}>${message.content.newPremium}/mo</div>
              <div className={styles.comparisonLabel}>Allegory Premium</div>
            </div>
          </div>
          <div className={styles.savingsSummary}>
            <div className={styles.savingsBadge}>Save ${message.content.savings}/mo</div>
            <div className={styles.savingsPercentage}>
              That's {Math.round((message.content.savings / message.content.currentPremium) * 100)}% in savings!
            </div>
          </div>
        </div>
      );
    }
    
    return <p>{message.content}</p>;
  };

  return (
    <div className={styles.messagesContainer}>
      {visibleMessages.map((message) => (
        <div 
          key={message.id} 
          className={`${styles.message} ${styles[message.sender]}`}
        >
          <div className={styles.messageBubble}>
            {renderMessage(message)}
          </div>
          <div className={styles.messageTime}>
            {new Date(message.timestamp).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
          </div>
        </div>
      ))}
      
      {isTyping && (
        <div className={`${styles.message} ${styles.ai}`}>
          <div className={styles.messageBubble}>
            <div className={styles.typingIndicator}>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      )}
      
      {isLoading && (
        <div className={`${styles.message} ${styles.system}`}>
          <div className={styles.messageBubble}>
            <p>{t('Loading your quote data...')}</p>
          </div>
        </div>
      )}
      
      <div ref={messagesEndRef} />
    </div>
  );
};

export default ChatMessages;