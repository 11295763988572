// File: frontend/src/pages/EmbeddedInsurance/data/apiEndpoints.js

import products from "./products";
import { jurisdictions } from "./jurisdictions";

// Mock API endpoints data
const apiEndpoints = [
  {
    id: 'quote-initiate',
    path: '/quote/initiate',
    method: 'POST',
    category: 'Quote Management',
    summary: 'Initiate a new insurance quote',
    description: 'This endpoint initiates a new insurance quote process. It creates a new quote session and returns a session ID that can be used in subsequent requests.',
    parameters: [],
    requestBody: {
      required: true,
      example: {
        product_type: "device_protection",
        coverage_type: "standard",
        device_info: {
          make: "Apple",
          model: "iPhone 13",
          purchase_date: "2023-01-15",
          purchase_price: 999.99
        },
        customer_info: {
          email: "customer@example.com",
          zip_code: "94105"
        }
      },
      schema: {
        type: "object",
        required: ["product_type", "coverage_type", "device_info"],
        properties: {
          product_type: {
            type: "string",
            description: "Type of insurance product"
          },
          coverage_type: {
            type: "string",
            description: "Type of coverage"
          },
          device_info: {
            type: "object",
            required: ["make", "model"],
            properties: {
              make: {
                type: "string",
                description: "Device manufacturer"
              },
              model: {
                type: "string",
                description: "Device model"
              },
              purchase_date: {
                type: "string",
                format: "date",
                description: "Date of purchase (YYYY-MM-DD)"
              },
              purchase_price: {
                type: "number",
                description: "Purchase price in USD"
              }
            }
          },
          customer_info: {
            type: "object",
            properties: {
              email: {
                type: "string",
                format: "email",
                description: "Customer email address"
              },
              zip_code: {
                type: "string",
                description: "Customer ZIP code"
              }
            }
          }
        }
      }
    },
    responses: {
      "200": {
        description: "Quote initiated successfully",
        content: {
          "application/json": {
            example: {
              session_id: "quote-123456789",
              expires_at: "2023-06-15T15:30:00Z",
              quote_details: {
                product_type: "device_protection",
                coverage_type: "standard",
                premium: {
                  amount: 7.99,
                  currency: "USD",
                  frequency: "monthly"
                },
                coverage_start_date: "2023-06-16",
                coverage_summary: "Standard protection for your device"
              }
            }
          }
        }
      },
      "400": {
        description: "Invalid request parameters",
        content: {
          "application/json": {
            example: {
              error: "invalid_request",
              message: "Invalid request parameters",
              details: [
                {
                  field: "device_info.make",
                  message: "Device make is required"
                }
              ]
            }
          }
        }
      }
    },
    codeSamples: {
      activeLanguage: "curl",
      curl: `curl -X POST https://api.allegory.app/v1/quote/initiate \\
  -H "Content-Type: application/json" \\
  -H "Authorization: Bearer YOUR_API_KEY" \\
  -d '{
    "product_type": "device_protection",
    "coverage_type": "standard",
    "device_info": {
      "make": "Apple",
      "model": "iPhone 13",
      "purchase_date": "2023-01-15",
      "purchase_price": 999.99
    },
    "customer_info": {
      "email": "customer@example.com",
      "zip_code": "94105"
    }
  }'`,
      javascript: `const axios = require('axios');

const data = {
  product_type: "device_protection",
  coverage_type: "standard",
  device_info: {
    make: "Apple",
    model: "iPhone 13",
    purchase_date: "2023-01-15",
    purchase_price: 999.99
  },
  customer_info: {
    email: "customer@example.com",
    zip_code: "94105"
  }
};

axios.post('https://api.allegory.app/v1/quote/initiate', data, {
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer YOUR_API_KEY'
  }
})
.then(response => {
  console.log(response.data);
})
.catch(error => {
  console.error(error);
});`,
      python: `import requests
import json

url = "https://api.allegory.app/v1/quote/initiate"
headers = {
    "Content-Type": "application/json",
    "Authorization": "Bearer YOUR_API_KEY"
}
data = {
    "product_type": "device_protection",
    "coverage_type": "standard",
    "device_info": {
        "make": "Apple",
        "model": "iPhone 13",
        "purchase_date": "2023-01-15",
        "purchase_price": 999.99
    },
    "customer_info": {
        "email": "customer@example.com",
        "zip_code": "94105"
    }
}

response = requests.post(url, headers=headers, data=json.dumps(data))
print(response.json())`,
      ruby: `require 'uri'
require 'net/http'
require 'json'

url = URI("https://api.allegory.app/v1/quote/initiate")
http = Net::HTTP.new(url.host, url.port)
http.use_ssl = true

request = Net::HTTP::Post.new(url)
request["Content-Type"] = "application/json"
request["Authorization"] = "Bearer YOUR_API_KEY"
request.body = {
  product_type: "device_protection",
  coverage_type: "standard",
  device_info: {
    make: "Apple",
    model: "iPhone 13",
    purchase_date: "2023-01-15",
    purchase_price: 999.99
  },
  customer_info: {
    email: "customer@example.com",
    zip_code: "94105"
  }
}.to_json

response = http.request(request)
puts response.read_body`
    }
  },
  {
    id: 'quote-pay',
    path: '/quote/pay',
    method: 'POST',
    category: 'Quote Management',
    summary: 'Process payment for a quote',
    description: 'This endpoint processes payment for a previously initiated quote. It requires a valid session ID and payment details.',
    parameters: [],
    requestBody: {
      required: true,
      example: {
        session_id: "quote-123456789",
        payment_method: {
          type: "credit_card",
          card_number: "4111111111111111",
          expiry_month: 12,
          expiry_year: 2025,
          cvc: "123",
          billing_address: {
            line1: "123 Main St",
            city: "San Francisco",
            state: "CA",
            postal_code: "94105",
            country: "US"
          }
        }
      }
    },
    responses: {
      "200": {
        description: "Payment processed successfully",
        content: {
          "application/json": {
            example: {
              payment_id: "pay-987654321",
              status: "succeeded",
              amount: 7.99,
              currency: "USD",
              receipt_url: "https://receipts.allegory.app/pay-987654321"
            }
          }
        }
      },
      "400": {
        description: "Invalid payment details",
        content: {
          "application/json": {
            example: {
              error: "invalid_payment",
              message: "Payment processing failed",
              details: "Invalid card number"
            }
          }
        }
      }
    }
  },
  {
    id: 'quote-bind',
    path: '/quote/bind',
    method: 'POST',
    category: 'Quote Management',
    summary: 'Bind a quote to create a policy',
    description: 'This endpoint binds a quote to create an active insurance policy. It requires a valid session ID and confirmation of terms.',
    parameters: [],
    requestBody: {
      required: true,
      example: {
        session_id: "quote-123456789",
        accept_terms: true,
        start_date: "2023-06-16"
      }
    },
    responses: {
      "200": {
        description: "Quote bound successfully",
        content: {
          "application/json": {
            example: {
              policy_id: "pol-123456789",
              status: "active",
              policy_number: "DEV-12345-67890",
              coverage_start_date: "2023-06-16",
              coverage_end_date: "2024-06-15",
              documents: [
                {
                  type: "policy_certificate",
                  url: "https://docs.allegory.app/policies/pol-123456789/certificate.pdf"
                }
              ]
            }
          }
        }
      },
      "400": {
        description: "Invalid request",
        content: {
          "application/json": {
            example: {
              error: "invalid_request",
              message: "Cannot bind quote",
              details: "Terms must be accepted"
            }
          }
        }
      }
    }
  },
  {
    id: 'quote-explain',
    path: '/quote/explain',
    method: 'POST',
    category: 'Quote Management',
    summary: 'Get detailed explanation of a quote',
    description: 'This endpoint provides a detailed explanation of how a quote was calculated, including all factors that influenced the premium.',
    parameters: [],
    requestBody: {
      required: true,
      example: {
        session_id: "quote-123456789"
      }
    },
    responses: {
      "200": {
        description: "Quote explanation retrieved successfully",
        content: {
          "application/json": {
            example: {
              base_premium: 5.99,
              adjustments: [
                {
                  factor: "device_value",
                  impact: 1.50,
                  description: "Premium adjusted based on device value"
                },
                {
                  factor: "device_age",
                  impact: 0.50,
                  description: "Premium adjusted based on device age"
                }
              ],
              final_premium: 7.99,
              coverage_details: {
                deductible: 99.00,
                coverage_limit: 1000.00,
                key_exclusions: [
                  "Intentional damage",
                  "Loss due to negligence"
                ]
              }
            }
          }
        }
      },
      "404": {
        description: "Quote not found",
        content: {
          "application/json": {
            example: {
              error: "not_found",
              message: "Quote not found",
              details: "The specified session ID does not exist or has expired"
            }
          }
        }
      }
    }
  }
];

// Mock products data
// const products = [
//   {
//     id: 'device_protection',
//     name: 'Device Protection',
//     description: 'Insurance for smartphones, tablets, and other electronic devices',
//     icon: 'mobile-alt'
//   },
//   {
//     id: 'travel_insurance',
//     name: 'Travel Insurance',
//     description: 'Coverage for trip cancellations, medical emergencies, and lost luggage',
//     icon: 'plane'
//   },
//   {
//     id: 'home_warranty',
//     name: 'Home Warranty',
//     description: 'Protection for home appliances and systems',
//     icon: 'home'
//   },
//   {
//     id: 'pet_insurance',
//     name: 'Pet Insurance',
//     description: 'Medical coverage for pets including accidents and illnesses',
//     icon: 'paw'
//   }
// ];

// Mock coverages data
const coverages = {
  device_protection: [
    {
      id: 'basic',
      name: 'Basic Protection',
      description: 'Essential coverage for your device',
      premium: '$4.99/month',
      benefits: [
        'Accidental damage protection',
        'Mechanical breakdown coverage',
        '24/7 claims support'
      ]
    },
    {
      id: 'standard',
      name: 'Standard Protection',
      description: 'Comprehensive coverage with theft protection',
      premium: '$7.99/month',
      benefits: [
        'All Basic Protection benefits',
        'Theft and loss coverage',
        'Worldwide protection',
        'Next-day replacement'
      ]
    },
    {
      id: 'premium',
      name: 'Premium Protection',
      description: 'Complete protection with additional benefits',
      premium: '$11.99/month',
      benefits: [
        'All Standard Protection benefits',
        'No deductible for repairs',
        'Accessory coverage up to $150',
        'Identity theft protection',
        'Tech support included'
      ]
    }
  ],
  travel_insurance: [
    {
      id: 'basic_travel',
      name: 'Basic Travel',
      description: 'Essential coverage for your trips',
      premium: '$5.99/trip',
      benefits: [
        'Trip cancellation up to $1,000',
        'Emergency medical coverage up to $10,000',
        '24/7 travel assistance'
      ]
    },
    {
      id: 'comprehensive_travel',
      name: 'Comprehensive Travel',
      description: 'Full coverage for worry-free travel',
      premium: '$9.99/trip',
      benefits: [
        'Trip cancellation up to $3,000',
        'Emergency medical coverage up to $50,000',
        'Lost luggage reimbursement',
        'Travel delay protection',
        'Rental car coverage'
      ]
    }
  ],
  home_warranty: [
    {
      id: 'appliance_plan',
      name: 'Appliance Plan',
      description: 'Coverage for major home appliances',
      premium: '$24.99/month',
      benefits: [
        'Refrigerator coverage',
        'Washer and dryer protection',
        'Dishwasher coverage',
        'Microwave protection',
        'Free service calls'
      ]
    },
    {
      id: 'systems_plan',
      name: 'Systems Plan',
      description: 'Coverage for home systems',
      premium: '$29.99/month',
      benefits: [
        'HVAC system coverage',
        'Electrical system protection',
        'Plumbing system coverage',
        'Water heater protection',
        'Free service calls'
      ]
    },
    {
      id: 'complete_plan',
      name: 'Complete Home Plan',
      description: 'Comprehensive coverage for appliances and systems',
      premium: '$49.99/month',
      benefits: [
        'All Appliance Plan benefits',
        'All Systems Plan benefits',
        'Roof leak coverage',
        'Pool and spa equipment',
        'Priority service'
      ]
    }
  ],
  pet_insurance: [
    {
      id: 'accident_only',
      name: 'Accident Only',
      description: 'Coverage for injuries and emergencies',
      premium: '$9.99/month',
      benefits: [
        'Accident-related injuries',
        'Emergency care',
        'Surgery for accidents',
        'X-rays and diagnostics',
        'Hospitalization'
      ]
    },
    {
      id: 'comprehensive_pet',
      name: 'Comprehensive Pet',
      description: 'Complete coverage for accidents and illnesses',
      premium: '$29.99/month',
      benefits: [
        'All Accident Only benefits',
        'Illness coverage',
        'Chronic condition treatment',
        'Prescription medications',
        'Specialist care',
        'Alternative therapies'
      ]
    }
  ]
};

// Mock jurisdictions data
// const jurisdictions = [
//   { id: 'us-ca', name: 'California', code: 'CA', country: 'US', flag: '🇺🇸' },
//   { id: 'us-ny', name: 'New York', code: 'NY', country: 'US', flag: '🇺🇸' },
//   { id: 'us-tx', name: 'Texas', code: 'TX', country: 'US', flag: '🇺🇸' },
//   { id: 'us-fl', name: 'Florida', code: 'FL', country: 'US', flag: '🇺🇸' },
//   { id: 'ca-on', name: 'Ontario', code: 'ON', country: 'CA', flag: '🇨🇦' },
//   { id: 'ca-qc', name: 'Quebec', code: 'QC', country: 'CA', flag: '🇨🇦' },
//   { id: 'ca-bc', name: 'British Columbia', code: 'BC', country: 'CA', flag: '🇨🇦' },
//   { id: 'uk-eng', name: 'England', code: 'ENG', country: 'UK', flag: '🇬🇧' },
//   { id: 'uk-sct', name: 'Scotland', code: 'SCT', country: 'UK', flag: '🇬🇧' }
// ];

// Export functions to get data
export const getApiEndpoints = (productId, coverageId, jurisdictionId) => {
  // In a real implementation, this would filter endpoints based on the selected product, coverage, and jurisdiction
  return apiEndpoints;
};

export const getProducts = () => {
  return products;
};

export const getCoverages = (productId) => {
  if (!productId) return [];
  return coverages[productId] || [];
};

export const getJurisdictions = () => {
  return jurisdictions;
};