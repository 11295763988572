// File: frontend/src/pages/InvestorPitch/components/slides/ClosingSlide/ClosingSlide.js

import React from 'react';
import { motion } from 'framer-motion';
import * as LucideIcons from 'lucide-react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from './ClosingSlide.module.css';

const ClosingSlide = () => {
  const { t } = useLanguage();

  const contactInfo = [
    {
      type: 'ai_inquiries',
      email: "ai@allegory.app",
      icon: "Brain",
      color: "var(--color-blue-310)"
    },
    {
      type: 'support_inquiries',
      email: "support@allegory.app",
      icon: "HeartHandshake",
      color: "var(--color-green-310)"
    },
    {
      type: 'press_media',
      email: "press@allegory.app",
      icon: "Newspaper",
      color: "var(--color-orange-310)"
    }
  ];

  return (
    <motion.div
      className={styles.container}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className={styles.content}>
        <motion.div 
          className={styles.logo}
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ delay: 0.2 }}
        >
          <img 
            src="/logos/allegory-logo-white.svg" 
            alt={t('investor_pitch.closing.logo.alt')} 
            className={styles.logoImage}
          />
        </motion.div>

        <motion.div 
          className={styles.contactSection}
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.4 }}
        >
          <h3 className={styles.contactTitle}>
            {t('investor_pitch.closing.contact.title')}
          </h3>
          <div className={styles.contactGrid}>
            {contactInfo.map((info) => {
              const Icon = LucideIcons[info.icon];
              return (
                <div 
                  key={info.type} 
                  className={styles.contactCard}
                >
                  <div 
                    className={styles.iconWrapper}
                    style={{ color: info.color }}
                  >
                    <Icon className={styles.icon} />
                  </div>
                  <div className={styles.contactInfo}>
                    <h4 className={styles.contactType}>
                      {t(`investor_pitch.closing.contact.types.${info.type}`)}
                    </h4>
                    <a 
                      href={`mailto:${info.email}`}
                      className={styles.email}
                    >
                      {info.email}
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        </motion.div>

        <motion.div 
          className={styles.disclaimerSection}
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.6 }}
        >
          <h3 className={styles.disclaimerTitle}>
            {t('investor_pitch.closing.disclaimer.title')}
          </h3>
          <p className={styles.disclaimer}>
            {t('investor_pitch.closing.disclaimer.text', {
              year: new Date().getFullYear()
            })}
          </p>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default ClosingSlide;