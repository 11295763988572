// File: frontend/src/pages/InvestorPitch/components/slides/GlobalHiringSlide/HiringTimelineCard.js

import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import ProgressBar from '../../common/ProgressBar';
import styles from './HiringTimelineCard.module.css';

const HiringTimelineCard = ({ phases }) => {
  const { t } = useLanguage();

  const formatHiresText = (count) => {
    return `${count} ${t('investor_pitch.hiring.timeline.hires')}`;
  };

  return (
    <motion.div 
      className={styles.container}
      whileHover={{ scale: 1.02 }}
      transition={{ type: 'spring', stiffness: 300, damping: 30 }}
    >
      {phases.map((phase, index) => (
        <div key={phase.period} className={styles.phase}>
          <div className={styles.phaseHeader}>
            <div className={styles.period}>{phase.period}</div>
            <div className={styles.hires}>{formatHiresText(phase.hires)}</div>
          </div>
          <div className={styles.phaseContent}>
            <div className={styles.phaseName}>{phase.phase}</div>
            <ProgressBar 
              value={phase.progress} 
              max={100} 
              gradient={`linear-gradient(90deg, ${phase.color}, ${phase.color})`}
            />
          </div>
        </div>
      ))}
    </motion.div>
  );
};

HiringTimelineCard.propTypes = {
  phases: PropTypes.arrayOf(
    PropTypes.shape({
      period: PropTypes.string.isRequired,
      phase: PropTypes.string.isRequired,
      hires: PropTypes.number.isRequired,
      progress: PropTypes.number.isRequired,
      color: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default HiringTimelineCard;