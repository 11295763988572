// File: src/utils/seoHelpers.js

import { SUPPORTED_LOCALES, DEFAULT_LOCALE } from '../config/i18n.config';

export const generateAlternateUrls = (path) => {
  const cleanPath = path.startsWith('/') ? path : `/${path}`;
  
  return Object.keys(SUPPORTED_LOCALES).reduce((acc, locale) => {
    acc[locale] = `https://www.allegory.app/${locale}${cleanPath}`;
    return acc;
  }, {});
};

export const getCanonicalUrl = (path, locale = DEFAULT_LOCALE) => {
  const cleanPath = path.startsWith('/') ? path : `/${path}`;
  return `https://www.allegory.app/${locale}${cleanPath}`;
};