// src/components/SourceCode/ProjectMindMap.js
import React, { useState, useEffect } from 'react';
import ReactFlow, { Background, Controls, MiniMap } from 'react-flow-renderer';
import axios from 'axios';
import ReactFlowWrapper from './ReactFlowWrapper';


const colorPalette = {
  lightBlue1: '#EFF4FF',
  lightBlue2: '#DBE6FE',
  lightBlue3: '#BED3FF',
  mediumBlue1: '#92B7FE',
  mediumBlue2: '#5E91FC',
  mediumBlue3: '#3667F8',
  darkBlue1: '#2348ED',
  darkBlue2: '#1B35DA',
  darkBlue3: '#1C2CB1',
  darkBlue4: '#1D2B8B',
  darkestBlue: '#161D55',
  white: '#FFFFFF',
  lightGray: '#F7F7F8',
  borderGray: '#E9EAEC',
  textGray: '#9B9B9B'
};

const getColorForNode = (nodeName) => {
  const fileExtension = nodeName.split('.').pop().toLowerCase();
  
  switch(fileExtension) {
    case 'js':
      return colorPalette.mediumBlue2;
    case 'css':
      return colorPalette.lightBlue3;
    case 'json':
      return colorPalette.darkBlue1;
    case 'svg':
      return colorPalette.mediumBlue1;
    case 'png':
    case 'jpg':
    case 'ico':
      return colorPalette.lightBlue1;
    case 'md':
      return colorPalette.darkBlue3;
    case 'html':
      return colorPalette.darkBlue2;
    case 'py':
      return colorPalette.darkestBlue;
    default:
      return colorPalette.lightGray;
  }
};

const ProjectMindMap = () => {
    const [elements, setElements] = useState([]);
    const [expandedNodes, setExpandedNodes] = useState(new Set());
  
    useEffect(() => {
      const fetchProjectStructure = async () => {
        try {
          const response = await axios.get('/api/source-code/structure');
          if (response.data && typeof response.data === 'object') {
            const newElements = createElements(response.data);
            console.log('Created elements:', newElements);
            setElements(newElements);
          } else {
            console.error('Invalid project structure data:', response.data);
          }
        } catch (error) {
          console.error('Error fetching project structure:', error);
        }
      };
  
      fetchProjectStructure();
    }, []);
  
    const createElements = (structure, parentId = null, x = 0, y = 0, depth = 0) => {
        let elements = [];
        let childY = y;
      
        if (structure && typeof structure === 'object' && depth < 2) {
          Object.entries(structure).forEach(([key, value], index) => {
            const id = parentId ? `${parentId}-${key}` : key;
            const isExpandable = value && typeof value === 'object' && Object.keys(value).length > 0;
            
            elements.push({
              id,
              type: 'default',  // Add this line
              data: { 
                label: key,
                isExpandable,
              },
              position: { x, y: childY },
              style: { 
                backgroundColor: getColorForNode(key),
                padding: 10,
                borderRadius: 5,
                border: '1px solid #ccc',
                width: 150,  // Add this line
                height: 40,  // Add this line
              },
            });
      
            if (parentId) {
              elements.push({
                id: `e-${parentId}-${id}`,
                source: parentId,
                target: id,
                type: 'smoothstep',
              });
            }
      
            if (expandedNodes.has(id) && isExpandable) {
              elements = [
                ...elements,
                ...createElements(value, id, x + 200, childY, depth + 1),
              ];
            }
      
            childY += 60;  // Increase this value
          });
        }
      
        return elements;
      };
  
    const handleExpand = (event, node) => {
      if (node.data && node.data.isExpandable) {
        setExpandedNodes(prev => {
          const newSet = new Set(prev);
          if (newSet.has(node.id)) {
            newSet.delete(node.id);
          } else {
            newSet.add(node.id);
          }
          return newSet;
        });
      }
    };
  
  return (
    <div style={{ height: '800px', width: '100%', border: '1px solid #ccc' }}>
    {elements.length > 0 ? (
        <ReactFlowWrapper 
        elements={elements}
        onNodeClick={handleExpand}
        nodesDraggable={false}
        nodesConnectable={false}
        elementsSelectable={true}
        zoomOnScroll={false}
        panOnScroll={true}
        panOnScrollMode="free"
        minZoom={0.1}
        maxZoom={1.5}
        defaultZoom={0.5}
        defaultPosition={[0, 0]}
        fitView  // Add this line
        fitViewOptions={{ padding: 0.2 }}  // Add this line
        >
        <Background />
        <Controls />
        <MiniMap />
        </ReactFlowWrapper>
      ) : (
        <p>Loading project structure...</p>
      )}
    </div>
  );
};
  
  export default ProjectMindMap;