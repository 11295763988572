// File: frontend/src/pages/SuperAdmin/components/ProductManagement/ProductList/index.js

import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import SuperAdminTable from '../../common/SuperAdminTable';
import SuperAdminSearch from '../../common/SuperAdminSearch';
import SuperAdminPagination from '../../common/SuperAdminPagination';
import styles from './ProductList.module.css';

const ProductList = ({ onViewDetail, onCreateNew }) => {
  const { t } = useLanguage();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterCategory, setFilterCategory] = useState('all');
  const [filterStatus, setFilterStatus] = useState('all');

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      setError(null);
      
      try {
        // This would be replaced with an actual API call
        // const response = await productService.listProducts({
        //   page: currentPage,
        //   search: searchTerm,
        //   category: filterCategory !== 'all' ? filterCategory : undefined,
        //   status: filterStatus !== 'all' ? filterStatus : undefined
        // });
        
        // Mock data for now
        await new Promise(resolve => setTimeout(resolve, 800));
        
        const mockProducts = [
          {
            product_id: 'prod-12345',
            enterprise_id: 'ent-12345',
            product_name: 'Analytics Dashboard',
            product_description: 'Comprehensive analytics dashboard with real-time data visualization',
            product_category: 'analytics',
            product_status: 'active',
            pricing_details: {
              base_price: 99.99,
              currency: 'USD',
              billing_cycle: 'monthly'
            },
            created_at: '2023-01-20T10:00:00Z',
            updated_at: '2023-06-15T14:30:00Z',
            features_count: 8
          },
          {
            product_id: 'prod-23456',
            enterprise_id: 'ent-12345',
            product_name: 'Risk Assessment Tool',
            product_description: 'Advanced risk assessment and management platform',
            product_category: 'risk',
            product_status: 'active',
            pricing_details: {
              base_price: 149.99,
              currency: 'USD',
              billing_cycle: 'monthly'
            },
            created_at: '2023-02-15T09:30:00Z',
            updated_at: '2023-06-20T11:45:00Z',
            features_count: 12
          },
          {
            product_id: 'prod-34567',
            enterprise_id: 'ent-23456',
            product_name: 'Compliance Manager',
            product_description: 'Regulatory compliance tracking and management system',
            product_category: 'compliance',
            product_status: 'active',
            pricing_details: {
              base_price: 199.99,
              currency: 'USD',
              billing_cycle: 'monthly'
            },
            created_at: '2023-03-10T13:15:00Z',
            updated_at: '2023-06-25T16:20:00Z',
            features_count: 15
          },
          {
            product_id: 'prod-45678',
            enterprise_id: 'ent-34567',
            product_name: 'Customer Portal',
            product_description: 'Self-service customer portal for account management',
            product_category: 'portal',
            product_status: 'inactive',
            pricing_details: {
              base_price: 79.99,
              currency: 'USD',
              billing_cycle: 'monthly'
            },
            created_at: '2023-04-05T11:30:00Z',
            updated_at: '2023-05-15T09:45:00Z',
            features_count: 6
          },
          {
            product_id: 'prod-56789',
            enterprise_id: 'ent-45678',
            product_name: 'Data Integration Suite',
            product_description: 'Comprehensive data integration and ETL toolset',
            product_category: 'data',
            product_status: 'development',
            pricing_details: {
              base_price: 249.99,
              currency: 'USD',
              billing_cycle: 'monthly'
            },
            created_at: '2023-05-20T15:45:00Z',
            updated_at: '2023-07-01T10:30:00Z',
            features_count: 10
          }
        ];
        
        // Filter based on search term, category, and status
        let filteredProducts = mockProducts;
        
        if (searchTerm) {
          const searchLower = searchTerm.toLowerCase();
          filteredProducts = filteredProducts.filter(product => 
            product.product_name.toLowerCase().includes(searchLower) ||
            product.product_description.toLowerCase().includes(searchLower) ||
            product.product_id.toLowerCase().includes(searchLower)
          );
        }
        
        if (filterCategory !== 'all') {
          filteredProducts = filteredProducts.filter(product => 
            product.product_category === filterCategory
          );
        }
        
        if (filterStatus !== 'all') {
          filteredProducts = filteredProducts.filter(product => 
            product.product_status === filterStatus
          );
        }
        
        setProducts(filteredProducts);
        setTotalPages(Math.ceil(filteredProducts.length / 10)); // Assuming 10 items per page
      } catch (err) {
        console.error('Error fetching products:', err);
        setError(t('failed_to_load_products'));
      } finally {
        setLoading(false);
      }
    };
    
    fetchProducts();
  }, [currentPage, searchTerm, filterCategory, filterStatus, t]);

  const handleSearch = (term) => {
    setSearchTerm(term);
    setCurrentPage(1);
  };

  const handleCategoryFilterChange = (e) => {
    setFilterCategory(e.target.value);
    setCurrentPage(1);
  };

  const handleStatusFilterChange = (e) => {
    setFilterStatus(e.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    }).format(date);
  };

  const formatCurrency = (price, currency) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency || 'USD'
    }).format(price);
  };

  const getStatusBadgeClass = (status) => {
    switch (status) {
      case 'active':
        return styles.statusActive;
      case 'inactive':
        return styles.statusInactive;
      case 'development':
        return styles.statusDevelopment;
      default:
        return '';
    }
  };

  const columns = [
    {
      header: t('product_id'),
      accessor: 'product_id',
      cell: (row) => <span className={styles.idCell}>{row.product_id}</span>
    },
    {
      header: t('product_name'),
      accessor: 'product_name',
      cell: (row) => <span className={styles.nameCell}>{row.product_name}</span>
    },
    {
      header: t('category'),
      accessor: 'product_category',
      cell: (row) => <span className={styles.categoryCell}>{row.product_category}</span>
    },
    {
      header: t('status'),
      accessor: 'product_status',
      cell: (row) => (
        <span className={`${styles.statusBadge} ${getStatusBadgeClass(row.product_status)}`}>
          {row.product_status}
        </span>
      )
    },
    {
      header: t('price'),
      accessor: 'pricing_details.base_price',
      cell: (row) => (
        <span>
          {formatCurrency(row.pricing_details.base_price, row.pricing_details.currency)}
          <span className={styles.billingCycle}>/{row.pricing_details.billing_cycle}</span>
        </span>
      )
    },
    {
      header: t('features'),
      accessor: 'features_count',
      cell: (row) => <span>{row.features_count}</span>
    },
    {
      header: t('created_at'),
      accessor: 'created_at',
      cell: (row) => <span>{formatDate(row.created_at)}</span>
    },
    {
      header: t('actions'),
      accessor: 'actions',
      cell: (row) => (
        <div className={styles.actions}>
          <button 
            className={styles.actionButton}
            onClick={() => onViewDetail(row.product_id)}
          >
            <i className="fas fa-eye"></i>
            <span>{t('view')}</span>
          </button>
        </div>
      )
    }
  ];

  return (
    <div className={styles.container}>
      <div className={styles.toolbar}>
        <div className={styles.filters}>
          <SuperAdminSearch 
            placeholder={t('search_products')}
            onSearch={handleSearch}
          />
          
          <div className={styles.filterGroup}>
            <label htmlFor="categoryFilter" className={styles.filterLabel}>
              {t('category')}:
            </label>
            <select
              id="categoryFilter"
              className={styles.filterSelect}
              value={filterCategory}
              onChange={handleCategoryFilterChange}
            >
              <option value="all">{t('all_categories')}</option>
              <option value="analytics">{t('analytics')}</option>
              <option value="risk">{t('risk')}</option>
              <option value="compliance">{t('compliance')}</option>
              <option value="portal">{t('portal')}</option>
              <option value="data">{t('data')}</option>
            </select>
          </div>
          
          <div className={styles.filterGroup}>
            <label htmlFor="statusFilter" className={styles.filterLabel}>
              {t('status')}:
            </label>
            <select
              id="statusFilter"
              className={styles.filterSelect}
              value={filterStatus}
              onChange={handleStatusFilterChange}
            >
              <option value="all">{t('all_statuses')}</option>
              <option value="active">{t('active')}</option>
              <option value="inactive">{t('inactive')}</option>
              <option value="development">{t('development')}</option>
            </select>
          </div>
        </div>
        
        <button className={styles.createButton} onClick={onCreateNew}>
          <i className="fas fa-plus"></i>
          <span>{t('create_product')}</span>
        </button>
      </div>
      
      {error && <div className={styles.error}>{error}</div>}
      
      <SuperAdminTable
        columns={columns}
        data={products}
        loading={loading}
        emptyMessage={t('no_products_found')}
      />
      
      <div className={styles.pagination}>
        <SuperAdminPagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default ProductList;