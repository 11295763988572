// File: frontend/src/pages/Quote/components/ChatInterface/components/ChatHeader.js

import React from 'react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from '../ChatInterface.module.css';

const ChatHeader = ({ 
  quoteId, 
  showSummary, 
  setShowSummary, 
  showMarketComparison, 
  setShowMarketComparison, 
  isLocked, 
  handleLockToggle, 
  onClose,
  localQuoteData
}) => {
  const { t } = useLanguage();

  return (
    <div className={styles.chatHeader}>
      <div className={styles.headerContent}>
        <div className={styles.aiLogo}>AI</div>
        <div className={styles.headerText}>
          <h2>{t('Allegory Quote Assistant')}</h2>
          <p>{t('Powered by AI • Saves you 25%')}</p>
          {quoteId && <small className={styles.quoteId}>{t('Quote')} #{quoteId.substring(0, 8)}</small>}
        </div>
      </div>
      
      <div className={styles.headerControls}>
        {/* Quote Summary Button */}
        <button 
          className={`${styles.controlButton} ${showSummary ? styles.active : ''}`}
          onClick={() => {
            setShowSummary(!showSummary);
            setShowMarketComparison(false);
          }}
          title={t('Quote Summary')}
        >
          <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 7h18M3 12h18M3 17h10" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
          </svg>
        </button>
        
        {/* Market Comparison Button */}
        <button 
          className={`${styles.controlButton} ${showMarketComparison ? styles.active : ''}`}
          onClick={() => {
            setShowMarketComparison(!showMarketComparison);
            setShowSummary(false);
          }}
          title={t('Market Comparison')}
          disabled={!localQuoteData.currentPremium}
        >
          <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 20h20M5 4v16M19 4v16M9 9h6M9 13h6" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
          </svg>
        </button>
        
        {/* Lock Button */}
        <button 
          className={`${styles.controlButton} ${isLocked ? styles.locked : ''}`}
          onClick={handleLockToggle}
          title={isLocked ? t("Unlock chat") : t("Lock chat")}
        >
          <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 2a6 6 0 0 0-6 6v3H4v10h16V11h-2V8a6 6 0 0 0-6-6zm0 2a4 4 0 0 1 4 4v3H8V8a4 4 0 0 1 4-4z" fill="currentColor"/>
          </svg>
        </button>
        
        {/* Close Button */}
        <button 
          className={styles.controlButton}
          onClick={onClose}
          title={t("Close chat")}
        >
          <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 6L6 18M6 6l12 12" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
          </svg>
        </button>
      </div>
    </div>
  );
};

export default ChatHeader;