// File: frontend/src/pages/AI1/components/TemplateSelector/TemplateSelector.js

import React from 'react';
import styles from './TemplateSelector.module.css';

export const defaultTemplates = [
  { id: '2c806623-dc74-4f8b-961b-c7dd82529dd4', name: 'Technical Analysis' },
  { id: '2269703c-96ac-4797-a549-98c794ae5507', name: 'Narrative Engagement' },
  { id: 'f00167a5-12f4-4d8c-afc2-e51790b98f61', name: 'Executive Brief' },
];

const TemplateSelector = ({ onSelect, onClose, selectedTemplate }) => {
  const handleTemplateSelect = (template) => {
    console.log('Selecting template:', template);
    onSelect(template);
    onClose();
  };

  return (
    <div className={styles.overlay} onClick={onClose}>
      <div className={styles.modal} onClick={e => e.stopPropagation()}>
        <div className={styles.header}>
          <h3>Select Template</h3>
          <button className={styles.closeButton} onClick={onClose}>
            <img src="/icons/18/misc/close.svg" alt="Close" />
          </button>
        </div>
        <div className={styles.content}>
          {defaultTemplates.map(template => (
            <button
              key={template.id}
              className={`${styles.templateButton} ${
                selectedTemplate?.id === template.id ? styles.selected : ''
              }`}
              onClick={() => handleTemplateSelect(template)}
            >
              {template.name}
            </button>
          ))}
          <button className={styles.createButton} disabled>
            <img src="/icons/18/misc/plus.svg" alt="Create" />
            Create New Template
          </button>
        </div>
      </div>
    </div>
  );
};

export default TemplateSelector;