// File: frontend/src/pages/InvestorPitch/components/slides/PartnershipsSlide/FlagshipPartner.js

import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { Building2, CheckCircle } from 'lucide-react';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from './FlagshipPartner.module.css';

const FlagshipPartner = ({ name, type, details, gradient }) => {
  const { t } = useLanguage();

  return (
    <motion.div 
      className={styles.container}
      whileHover={{ scale: 1.02 }}
      transition={{ type: 'spring', stiffness: 300, damping: 30 }}
    >
      <div className={styles.header}>
        <div 
          className={styles.iconWrapper}
          style={{ background: gradient }}
        >
          <Building2 
            className={styles.icon} 
            aria-label={t('investor_pitch.partnerships.flagship.icon.aria_label')}
          />
        </div>
        <div className={styles.info}>
          <h3 className={styles.name}>{name}</h3>
          <p className={styles.type}>{type}</p>
        </div>
      </div>

      <div className={styles.details}>
        {details.map((detail, index) => (
          <div key={index} className={styles.detail}>
            <CheckCircle 
              className={styles.checkIcon}
              aria-hidden="true"
            />
            <span>{detail}</span>
          </div>
        ))}
      </div>
    </motion.div>
  );
};

FlagshipPartner.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  details: PropTypes.arrayOf(PropTypes.string).isRequired,
  gradient: PropTypes.string.isRequired,
};

export default FlagshipPartner;