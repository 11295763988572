// File: frontend/src/pages/AI1/AI1Container.js

import React from 'react';
import { useHeaderChatControls } from '../../hooks/useHeaderChatControls';
import AI1 from './AI1';

const AI1Container = () => {
  const { chatControls, updateHeaderControls } = useHeaderChatControls();
  
  return (
    <AI1 
      onMount={updateHeaderControls}
      {...chatControls}
    />
  );
};

export default AI1Container;