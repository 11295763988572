// File: frontend/src/pages/InvestorPitch/components/slides/CompetitiveAdvantageSlide/CompetitiveAdvantageSlide.js

import React from 'react';
import { motion } from 'framer-motion';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import DifferentiatorCard from './DifferentiatorCard';
import ComparisonMatrix from './ComparisonMatrix';
import MarketPosition from './MarketPosition';
import { ANIMATION_VARIANTS } from '../../../constants/animations';
import styles from './CompetitiveAdvantageSlide.module.css';

const CompetitiveAdvantageSlide = () => {
  const { t } = useLanguage();

  const differentiators = [
    {
      title: t('investor_pitch.competitive_advantage.differentiators.ai.title'),
      comparison: t('investor_pitch.competitive_advantage.differentiators.ai.comparison'),
      description: t('investor_pitch.competitive_advantage.differentiators.ai.description'),
      icon: "Brain",
      metrics: [
        { 
          label: t('investor_pitch.competitive_advantage.differentiators.ai.metrics.speed.label'), 
          value: t('investor_pitch.competitive_advantage.differentiators.ai.metrics.speed.value')
        },
        { 
          label: t('investor_pitch.competitive_advantage.differentiators.ai.metrics.accuracy.label'), 
          value: t('investor_pitch.competitive_advantage.differentiators.ai.metrics.accuracy.value')
        }
      ],
      gradient: "linear-gradient(135deg, var(--color-blue-310), var(--color-blue-510))"
    },
    {
      title: t('investor_pitch.competitive_advantage.differentiators.market.title'),
      comparison: t('investor_pitch.competitive_advantage.differentiators.market.comparison'),
      description: t('investor_pitch.competitive_advantage.differentiators.market.description'),
      icon: "Globe",
      metrics: [
        { 
          label: t('investor_pitch.competitive_advantage.differentiators.market.metrics.coverage.label'), 
          value: t('investor_pitch.competitive_advantage.differentiators.market.metrics.coverage.value')
        },
        { 
          label: t('investor_pitch.competitive_advantage.differentiators.market.metrics.deployment.label'), 
          value: t('investor_pitch.competitive_advantage.differentiators.market.metrics.deployment.value')
        }
      ],
      gradient: "linear-gradient(135deg, var(--color-green-310), var(--color-green-510))"
    },
    {
      title: t('investor_pitch.competitive_advantage.differentiators.integration.title'),
      comparison: t('investor_pitch.competitive_advantage.differentiators.integration.comparison'),
      description: t('investor_pitch.competitive_advantage.differentiators.integration.description'),
      icon: "Layers",
      metrics: [
        { 
          label: t('investor_pitch.competitive_advantage.differentiators.integration.metrics.points.label'), 
          value: t('investor_pitch.competitive_advantage.differentiators.integration.metrics.points.value')
        },
        { 
          label: t('investor_pitch.competitive_advantage.differentiators.integration.metrics.models.label'), 
          value: t('investor_pitch.competitive_advantage.differentiators.integration.metrics.models.value')
        }
      ],
      gradient: "linear-gradient(135deg, var(--color-orange-310), var(--color-orange-510))"
    }
  ];

  const competitiveMatrix = {
    categories: [
      {
        name: t('investor_pitch.competitive_advantage.matrix.technical.name'),
        features: [
          { 
            name: t('investor_pitch.competitive_advantage.matrix.technical.features.ai.name'), 
            us: t('investor_pitch.competitive_advantage.matrix.technical.features.ai.us'), 
            others: t('investor_pitch.competitive_advantage.matrix.technical.features.ai.others') 
          },
          { 
            name: t('investor_pitch.competitive_advantage.matrix.technical.features.deployment.name'), 
            us: t('investor_pitch.competitive_advantage.matrix.technical.features.deployment.us'), 
            others: t('investor_pitch.competitive_advantage.matrix.technical.features.deployment.others') 
          },
          { 
            name: t('investor_pitch.competitive_advantage.matrix.technical.features.coverage.name'), 
            us: t('investor_pitch.competitive_advantage.matrix.technical.features.coverage.us'), 
            others: t('investor_pitch.competitive_advantage.matrix.technical.features.coverage.others') 
          }
        ]
      },
      {
        name: t('investor_pitch.competitive_advantage.matrix.business.name'),
        features: [
          { 
            name: t('investor_pitch.competitive_advantage.matrix.business.features.cost.name'), 
            us: t('investor_pitch.competitive_advantage.matrix.business.features.cost.us'), 
            others: t('investor_pitch.competitive_advantage.matrix.business.features.cost.others') 
          },
          { 
            name: t('investor_pitch.competitive_advantage.matrix.business.features.processing.name'), 
            us: t('investor_pitch.competitive_advantage.matrix.business.features.processing.us'), 
            others: t('investor_pitch.competitive_advantage.matrix.business.features.processing.others') 
          },
          { 
            name: t('investor_pitch.competitive_advantage.matrix.business.features.integration.name'), 
            us: t('investor_pitch.competitive_advantage.matrix.business.features.integration.us'), 
            others: t('investor_pitch.competitive_advantage.matrix.business.features.integration.others') 
          }
        ]
      }
    ]
  };

  const marketPosition = {
    highlights: [
      {
        title: t('investor_pitch.competitive_advantage.position.leadership.title'),
        value: t('investor_pitch.competitive_advantage.position.leadership.value'),
        icon: "Trophy",
        color: "var(--color-blue-310)"
      },
      {
        title: t('investor_pitch.competitive_advantage.position.edge.title'),
        value: t('investor_pitch.competitive_advantage.position.edge.value'),
        icon: "Zap",
        color: "var(--color-green-310)"
      },
      {
        title: t('investor_pitch.competitive_advantage.position.reach.title'),
        value: t('investor_pitch.competitive_advantage.position.reach.value'),
        icon: "Globe2",
        color: "var(--color-orange-310)"
      }
    ]
  };

  return (
    <motion.div
      className={styles.container}
      variants={ANIMATION_VARIANTS.stagger.container}
      initial="hidden"
      animate="visible"
    >
      <motion.div 
        className={styles.header}
        variants={ANIMATION_VARIANTS.fadeIn}
      >
        <h2 className={styles.title}>{t('investor_pitch.competitive_advantage.title')}</h2>
        <p className={styles.subtitle}>{t('investor_pitch.competitive_advantage.subtitle')}</p>
      </motion.div>

      <div className={styles.content}>
        <motion.div 
          className={styles.differentiators}
          variants={ANIMATION_VARIANTS.stagger.container}
        >
          {differentiators.map((differentiator, index) => (
            <motion.div
              key={differentiator.title}
              variants={ANIMATION_VARIANTS.stagger.item}
              custom={index}
            >
              <DifferentiatorCard {...differentiator} />
            </motion.div>
          ))}
        </motion.div>

        <div className={styles.detailsSection}>
          <motion.div 
            className={styles.matrixSection}
            variants={ANIMATION_VARIANTS.fadeIn}
          >
            <h3 className={styles.sectionTitle}>{t('investor_pitch.competitive_advantage.matrix.title')}</h3>
            <ComparisonMatrix {...competitiveMatrix} />
          </motion.div>

          <motion.div 
            className={styles.positionSection}
            variants={ANIMATION_VARIANTS.fadeIn}
          >
            <h3 className={styles.sectionTitle}>{t('investor_pitch.competitive_advantage.position.title')}</h3>
            <MarketPosition {...marketPosition} />
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
};

export default CompetitiveAdvantageSlide;